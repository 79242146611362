import actions from "./actions";
const initState = {
  virtualClass: [],
  isVirtualClassPageLoading: false,
  virtualClassChan: undefined,
  error: false,
  seenCount: [],
  taggedStudent: [],
  operationType: undefined,
  filterClassroom: [],
  trialStudentsMap: new Map(),
};
export default function virtualClassReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_VIRTUAL_CLASS:
      return {
        ...state,
        isVirtualClassPageLoading: true,
        operationType: undefined,
        virtualClass: [],
      };

    case actions.GET_VIRTUAL_CLASS_SUCCESSFUL:
      return {
        ...state,
        isVirtualClassPageLoading: false,
        virtualClass: action.virtualClass,
        virtualClassChan: action.virtualClassChan,
        operationType: action.operationType,
      };

    case actions.VIRTUAL_REQUEST_FAILED:
      return {
        ...state,
        isVirtualClassPageLoading: false,
      };

    case actions.GET_VIRTUAL_SEEN_COUNT_SUCCESSFUL:
      return {
        ...state,
        seenCount: action.seenCount,
      };

    case actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL:
      return {
        ...state,
      };
    case actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL:
      return {
        ...state,
        taggedStudent: action.taggedStudent,
      };

    case actions.UPDATE_VIRTUAL_ACTIVITY:
      return {
        ...state,
        isVirtualClassPageLoading: true,
      };
    case actions.UPDATE_VIRTUAL_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isVirtualClassPageLoading: false,
      };

    case actions.DELETE_VIRTUAL_ACTIVITY:
      return {
        ...state,
        isVirtualClassPageLoading: true,
      };
    case actions.DELETE_VIRTUAL_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isVirtualClassPageLoading: false,
      };

    case actions.GET_FILTER_CLASSROOM:
      return {
        ...state,
      };
    case actions.GET_FILTER_CLASSROOM_SUCCESS:
      return {
        ...state,
        filterClassroom: action.filterClassroom,
      };

    case actions.STOP_V_CLASS_REPETITION:
      return {
        ...state,
        isVirtualClassPageLoading: true,
      };
    case actions.STOP_V_CLASS_REPETITION_SUCCESS:
      return {
        ...state,
        operationType: "STOP_REPEAT",
        isVirtualClassPageLoading: false,
      };

    case actions.APPROVE_V_CLASS:
      return {
        ...state,
        isVirtualClassPageLoading: true,
      };
    case actions.APPROVE_V_CLASS_SUCCESS:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isVirtualClassPageLoading: false,
      };

    case actions.RESET_V_CLASS_OPERATION:
      return {
        ...state,
        operationType: undefined,
      };

    case actions.ADD_STUDENT_TO_MEETING:
      return {
        ...state,
        isVirtualClassPageLoading: true,
      };
    case actions.ADD_STUDENT_TO_MEETING_SUCCESS:
      return {
        ...state,
        operationType: "ADD_STUDENT_TO_MEETING",
        isVirtualClassPageLoading: false,
      };

    case actions.GET_V_CLASS_TRIAL_STUDENT_COUNT:
      return {
        ...state,
        trialStudentsMap: action.trialStudentsMap,
      };

    case actions.FETCH_CLASS_LABEL:
      return {
        ...state,
      };

    default:
      return state;
  }
}
