import { Card, Col, Row, Select, Spin } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import FilterAction from "../../Utility/FilterAction";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import { notification } from "../../components";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";
import { withFirebase } from "../../components/firebase/context";
import Box from "../../components/utility/box";
import formatMsg from "../../components/utility/formatMessageUtil";
import IntlMessages from "../../components/utility/intlMessages";
import { ButtonHolders } from "../../containers/Classroom/classroom.style";
import chatIcon from "../../image/Comments.png";
import followUp from "../../image/Data Pending.png";
import markdone from "../../image/Done (2).png";
import Email from "../../image/Icon material-e (1).png";
import deleteImage from "../../image/deleteImage.png";
import editImage from "../../image/editImage.png";
import activity from "../../image/sidebar_dashboard.png";
import leadsActions from "../../redux/leads/actions";
import FilterLeads from "../Leads/filterLeads";
import CardWrapper from "./cardWrapper";
// import InfiniteScroll from "react-infinite-scroller";
import { default as createFollowUp, default as createTask } from "../../image/Add File.png";
import completeMarkDone from "../../image/Done (1).png";
import NextFollowUpModal from "../Leads/nextFollowUpModal";
import CollectionAddActivityForm from "./LeadsAddActivityModal";
import "./leadStyle.css";
class LeadActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFolderName: undefined,
      config: undefined,

      activityDropDown: "All Activities",
      leadActivity: [],
      popVisible: false,
      popVisible2: null,
      toggleVal: "Auto",
      leadDetail: undefined,
      isAddActivityModalVisible: false,
      isModalVisible: false,
      activityEditableRecord: undefined,
      nextFollowUpOption: true,
      sortByFilter: ["Activity Date", "Created on"],
      sortByFilterDropDown: "Created on"
    };
    this.markDone = this.markDone.bind(this);
    this.getCommentDiv = this.getCommentDiv.bind(this);
    this.getTeacherNameById = this.getTeacherNameById.bind(this);
    this.renderPopContent = this.renderPopContent.bind(this);

    this.getStatus = this.getStatus.bind(this);
    this.addActivity = this.addActivity.bind(this);
    this.handleAddActivityCancel = this.handleAddActivityCancel.bind(this);
    this.handleCancelNextFollowUp = this.handleCancelNextFollowUp.bind(this);
    this.saveNextFollowUp = this.saveNextFollowUp.bind(this);
    this.deleteLeadFollowUp = this.deleteLeadFollowUp.bind(this)
  }
  componentDidMount() {
    const url = new URL(window.location.href);

    if (url.searchParams.get("dropdown") === undefined) {
      url.searchParams.set("dropdown", "All Activities");
    }
    const urlParams = new URLSearchParams(window.location.search);
    let category = urlParams.get("dropdown");
    if (!category) {
      this.setState(
        {
          activityDropDown: "All Activities",
        },
        () => {
          this.filterDataWithParams();
        }
      );
    } else {
      this.setState(
        {
          activityDropDown: category,
        },
        () => {
          this.filterDataWithParams();
        }
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      {
        leadActivity: nextProps.leadActivity,
      },
      () => {
        this.filterDataWithParams();
      }
    );
  }

  markDone(item, type) {
    const { firebase } = this.props;
    var lead = this.getLeadObj();
    // let lead = this.getLeadObj();
    // const { firebase } = this.props;

    // item["completed"] = true;
    // item["additionalDescription"] = item.description ? item.description : null;
    // this.props.updateTask(item, item, firebase, lead);
    if (type) {
      confirmAlert({
        title: (
          <p
            style={{
              fontSize: 20,
              color: colors.v2_fiord,
            }}
          >
            {formatMsg("unMarkTask")}
          </p>
        ),
        message: formatMsg("confirmModal.areYouSure"),
        buttons: [
          {
            label: formatMsg("activity.no"),
          },
          {
            label: formatMsg("activity.yes"),
            onClick: () => this.props.markDone(item, type),
          },
        ],
      });
    }
    else {
      this.props.markDone(item)
    }

  }
  getLeadObj() {
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");

    return { id: id };
  }


  getActivityType(item) {
    let value = item.activityType.toLowerCase();
    let type;
    switch (value) {
      case "phone call":
        type = "PHONE_CALL";
        break;
      case "email":
        type = "EMAIL";
        break;
      case "invoice":
        type = "LEAD_INVOICE";
        break;
      case "show around":
        type = "SHOW_AROUND";
        break;
      case "quote send":
        type = "QUOTE_SENT";
        break;
      case "note":
        type = "LEAD_NOTE";
        break;
      case "text":
        type = "LEAD_TEXT";
        break;
      case "follow up":
        type = "LEAD_FOLLOWUP";
        break;
      case "OTHER":
        type = "LEAD_OTHER";
        break;

    }
    return type;
  }

  getCommentDiv(item) {
    const { pathTo, leadActivityComment } = this.props;
    let activityType = this.getActivityType(item);
    let leadId = FilterAction.getUrlParam("id");
    let studentId = this.props.leadDetail && this.props.leadDetail.studentId ? this.props.leadDetail.studentId : "undefined";
    return (
      <Link
        style={styles.link}
        to={{
          pathname: "comments",
          search:
            "?activityId=" +
            item.id +
            "&pathHistory=leadsDetailedView" + "&id=" + leadId +
            "&commentType=" +
            "LEADS" +
            "&activityName=" +
            "LEADS" +
            "&itemId=" +
            item.id +
            "&studentId=" +
            studentId +
            "&leadId=" + leadId
        }}
      >
        <div
          style={{
            ...styles.inline,
            ...{ cursor: "pointer" },
          }}
        >
          <img src={chatIcon} alt="chat" style={styles.chatIcon} />
          <p style={styles.comment}>
            {leadActivityComment.has(item.id)

              ? leadActivityComment.get(item.id).length > 0
                ? leadActivityComment.get(item.id).length
                : null
              : 0}{" "}
            {formatMsg("events.comments")}
          </p>
        </div>
      </Link>
    );
  }

  getStatus(item) {
    return (
      <div
        style={{
          ...styles.statusDiv,
          ...{
            backgroundColor: FilterAction.getBackGroundColor(item),
            color: FilterAction.getColor(item),
          },
        }}
      >
        <p
          style={{
            ...styles.status,
            ...{
              color: FilterAction.getColor(item),
            },
          }}
        >
          {FilterAction.getStatusForTask(item)}
        </p>
      </div>
    );
  }
  filterDataWithParams() {
    const { activityDropDown, sortByFilterDropDown } = this.state;
    const { leadActivity } = this.props;

    const url = new URL(window.location.href);
    url.searchParams.set("dropdown", activityDropDown);
    window.history.replaceState(null, null, url);
    let filteredLeads = FilterLeads.filterActivity(activityDropDown, leadActivity);
    if (sortByFilterDropDown) {
      filteredLeads = FilterLeads.sortByFilter(filteredLeads, sortByFilterDropDown)
    }
    this.setState({
      leadActivity: filteredLeads,
    });
  }
  createActivity() {
    this.props.activityFormShow();
  }
  createLogEmail() {
    this.props.logEmail();
  }

  createTask() {
    this.props.taskFormShow();
  }

  showEmptyDiv() {
    const { leadActivity } = this.props;
    if (leadActivity && leadActivity.length > 0) {
      return (
        <div
          style={{
            ...styles.centered,
            ...{ marginTop: 50 },
          }}
        >
          <p style={styles.emptyText}>{formatMsg("leads.noActivityRecorded")}</p>
        </div>
      );
    }
    else {
      return (
        <Card
          onClick={() => this.createActivity()}
          style={{ cursor: "pointer" }}
        >
          <div style={{
            ...styles.centered,
          }}

          >


            <img src={createTask} alt="icon"
              style={{ height: 40, width: 40, objectFit: "contain", cursor: "pointer" }}

            />
            <p style={{ marginTop: 10, color: colors.v2_fiord, fontSize: "17px" }}>{formatMsg("createActivity")}</p>

          </div></Card>

      );
    }
  }
  allActivityList() {
    const { leadActivity } = this.props;

    let list = [];
    leadActivity.forEach((item) => {
      list.push(item.activityType);
    });
    this.setState({
      allActivityList: list,
    });
  }
  searchLeads(event, type) {
    if (type === "activity") {
      this.setState({ activityDropDown: event }, () => {
        this.filterDataWithParams();
      });
    }
    if (type === "sortBy") {
      this.setState({ sortByFilterDropDown: event }, () => {
        this.filterDataWithParams()
      })
    }
  }
  deleteActivity(item, firebase) {
    this.props.deleteLeadActivity(item, firebase)
  }

  renderPopContent(record) {

    const { firebase } = this.props;
    let list = ["Status Update"]
    return (
      <ButtonHolders>
        <div style={styles.actionButtonsView}>
          {record.activityType.toLowerCase() === "task" && !record.completed ? (
            <div style={styles.unCompleteButtonView}
              // style={styles.deleteButtonView}
              onClick={() => this.markDone(record)}
            >
              <img src={markdone} style={styles.buttton} alt="icon" />
            </div>
          ) : record.activityType.toLowerCase() === "task" && record.completed ? (
            <div style={styles.buttonView}
              // style={styles.deleteButtonView}
              onClick={() => this.markDone(record, "unMark")}
            >
              <img src={completeMarkDone} style={styles.buttton} alt="icon" />
            </div>
          ) : null}

          {!record.subject && !list.includes(record.activityType) ?
            <div
              style={styles.editButtonView}
              // onClick={() => this.showFollowup(record)}
              onClick={() => this.props.showUpdateModal(record, firebase)}
            >
              <img src={editImage} style={styles.editButton} alt="edit" />
            </div> : null}
          <div
            style={styles.deleteButtonView}
            onClick={() => this.deleteActivity(record, firebase)}
          >
            <img src={deleteImage} style={styles.deleteButton} alt="delete" />
          </div>
        </div>
      </ButtonHolders>
    );
  }
  renderPopForFollowUp(record) {
    const { firebase } = this.props;
    return (
      <div>
        <div className="actionButtonsView">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={styles.unCompleteButtonViewFollowUp}
              // style={styles.deleteButtonView}
              onClick={() => this.showFollowup()}
            >
              <img src={markdone} alt="edit" style={styles.buttton} />
            </div>
            <div
              style={styles.editButtonView}
              onClick={() => this.showUpdateNextFollowUp()}
            >
              <img src={editImage} style={styles.editButton} alt="edit" />
            </div>
          </div>

          <div
            style={styles.deleteButtonView}
            onClick={() => this.deleteLeadFollowUp()}
          >
            <img src={deleteImage} style={styles.deleteButton} alt="delete" />
          </div>

        </div>
      </div>
    );
  }

  onVisibleChange(visible, record) {
    if (visible) {
      this.setState({
        popVisible: record.id,
      });
    } else {
      this.setState({
        popVisible: false,
      });
    }
  }

  getTeacherNameById(id) {
    let teacher = FilterAction.getTeacherNameById(id, this.props.firebase);
    return teacher;
  }
  onLoadMore() {
    const { leadActivity } = this.state;
    if (leadActivity.length > 3) {
      this.state({
        toggleVal: "Auto",
      });
    }
  }

  checkForParentEmail(item) {
    let check = false;

    if (item.parentOne && item.parentOne.email) {
      check = true;
    } else if (item.parentTwo && item.parentTwo.email) {
      check = true;
    }
    return check;
  }
  noParentEmail() {
    notification("error", "Please add parent email ");
    return;
  }
  evaluateDay(date) {
    if (date == 1) return date + " day";
    else return date + " days";
  }
  showFollowup(record) {
    const { leadDetail } = this.props;
    this.setState({
      isAddActivityModalVisible: true,
      selectedLeadDetail: leadDetail,
      activityEditableRecord: record,
      nextFollowUpOption: record ? false : true
    });
  }
  saveFollowUpFormRef = (FollowUpFormRef) => {
    this.FollowUpFormRef = FollowUpFormRef;
  };

  handleAddActivityCancel() {
    const form = this.FollowUpFormRef.props.form;
    form.resetFields();
    this.setState({
      isAddActivityModalVisible: false,
      editableRecord: undefined,
    });
  }

  addActivity() {
    const form = this.FollowUpFormRef.props.form;
    const { firebase, nextFollowUpMarkDone, nextFollowUpOption, addActivity } = this.props;
    const { selectedLeadDetail } = this.state;
    var lead = this.getLeadObj();
    form.validateFields((err, values) => {

      if (err) {
        notification("error", formatMsg("notif.errorForMissingDetails"));
        return;
      }
      values["time"] = values["date"];

      nextFollowUpMarkDone(values, selectedLeadDetail, firebase);
      // }
      this.handleAddActivityCancel();
    });
  }

  showUpdateNextFollowUp() {
    const { leadDetail } = this.props;
    this.setState({
      isModalVisible: true,
      selectedLeadDetail: leadDetail,
    });
  }

  nextFollowUpDateFromRef = (nextformRef) => {
    this.nextformRef = nextformRef;
  };
  saveNextFollowUp() {
    const { firebase, } = this.props;
    const { selectedLeadDetail } = this.state;
    const form = this.nextformRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        notification("error", formatMsg("error.selectDateRange"));
        return;
      }
      this.props.updateNextFollowUpForLead(values, selectedLeadDetail);
    });
    this.handleCancelNextFollowUp();
  }


  handleCancelNextFollowUp() {
    const form = this.nextformRef.props.form;
    form.resetFields();
    this.setState({
      isModalVisible: false,

      selectedLeadDetail: undefined,
    });
  }

  deleteLeadFollowUp(item) {
    const { leadDetail, firebase } = this.props;
    var lead = this.getLeadObj();
    confirmAlert({
      title: (
        <p
          style={{
            fontSize: 20,
            color: colors.v2_fiord,
          }}
        >
          {formatMsg("deleteFollowUp")}
        </p>
      ),
      message: formatMsg("confirmModal.areYouSure"),
      buttons: [
        {
          label: formatMsg("activity.no"),
        },
        {
          label: formatMsg("activity.yes"),
          onClick: () => this.props.deleteLeadFollowUp(leadDetail)

        },
      ],
    });
  }

  render() {
    const {
      isLoading,
      firebase,
      noteFormShow,
      externalLeadId,
      allActivityList,
      leadDetail,
    } = this.props;

    const { currentFolderName, config, activityDropDown, leadActivity, popVisible, toggleVal } =
      this.state;
    const isLgOrMdScreenSize = window.innerWidth >= 992 && window.innerWidth < 1200;

    return (
      <Box style={{ overflow: "auto" }}>
        <Spin spinning={isLoading}>
          {leadDetail && leadDetail.nextFollowUpDate ?
            <>
              <p className="followUp">{formatMsg("leadFollowUp")}</p>
              <Card
                style={{
                  borderRadius: 5,
                  marginBottom: 10,
                  borderColor: colors.v2_Cerulean,
                }}
                bodyStyle={{
                  paddingLeft: 0,
                  paddingTop: 0,
                  paddingRight: 25,
                  paddingBottom: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    minHeight: 116,
                  }}
                >
                  <div
                    style={{
                      borderRadius: 5,
                      backgroundColor: "rgba(0, 170, 204, 0.2)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10%",
                      marginRight: "2%",
                      borderRight: "1px solid #ccc",
                      borderColor: colors.v2_Cerulean,
                    }}
                  >
                    <img src={followUp} style={styles.followUpIcon} alt="icon" />
                  </div>

                  <div style={{ paddingRight: "1%", width: "100%" }}>
                    <Row justify="start">
                      <Col lg={9} md={24} sm={24} xs={24} style={{ paddingTop: "2%" }}>
                        <div style={styles.spacedInline}>
                          <p style={styles.activityType}>{formatMsg("followUpScheduled")}</p>
                          {moment(leadDetail.nextFollowUpDate).endOf("days").isSameOrBefore(moment()) ? (
                            <p>{this.getStatus(this.props.leadDetail)}</p>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={1} md={0} sm={0} xs={0} >
                        <div className="followUpDivLine">
                        </div>
                      </Col>
                      <Col lg={7} md={24} sm={24} xs={24} style={{ paddingTop: "3%", }}>
                        {leadDetail && leadDetail.nextFollowUpDate ? (
                          <div>
                            <div style={{ marginBottom: "1.5%" }}>
                              <span style={{ color: colors.v2_alt_fiord, fontSize: "16px", fontWeight: "bold" }}>
                                {formatMsg("studentLesson.dueDate")}:
                              </span>{" "}
                              <span style={{ color: colors.v2_Cerulean, fontSize: "16px" }}>
                                {moment(leadDetail.nextFollowUpDate).format("MMM Do, YYYY")}
                              </span>
                            </div>
                            {!moment(leadDetail.nextFollowUpDate).endOf("days").isSameOrBefore(moment()) ? (
                              <div>
                                <span style={{ color: colors.v2_alt_fiord, fontSize: webFont.large, fontWeight: "bold" }}>
                                  {formatMsg("followUpDueIn")}:
                                </span>
                                <span style={{ color: "#D71313", fontSize: webFont.large, marginLeft: "1em" }}>
                                  {this.evaluateDay(moment(leadDetail.nextFollowUpDate).endOf("days").diff(moment(), "days"))}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <span style={{ color: colors.v2_alt_fiord, fontSize: webFont.large, fontWeight: "bold" }}>
                                  {formatMsg("overDueSince")}:
                                </span>
                                <span style={{ color: "#D71313", fontSize: webFont.large, marginLeft: "1em" }}>
                                  {this.evaluateDay(moment().endOf("days").diff(moment(leadDetail.nextFollowUpDate), "days"))}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={1} md={0} sm={0} xs={0}>
                        <div className="followUpDivLine"></div>
                      </Col>
                      <Col lg={6} md={24} sm={24} xs={24} style={{ padding: 42 }}>
                        <div >{this.renderPopForFollowUp(leadDetail)}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>

            </>
            :
            <Card
              style={{
                borderRadius: 5,
                marginBottom: 10,
                height: 100
              }}
              bodyStyle={{
                paddingLeft: 0,
                paddingTop: 0,
                paddingRight: 10,
                paddingBottom: 0,
              }}>
              <div
                onClick={() => this.showUpdateNextFollowUp()}
                style={styles.createFollowUpCard}>
                <div>
                  <img src={createFollowUp} style={styles.createFollowUpIcon} />
                </div>
                <div style={{ marginTop: 10 }}>
                  <p style={{ fontSize: 17, color: colors.v2_alt_fiord }}>{formatMsg("createFollowUp")}</p>
                </div>
              </div>
            </Card>
          }


          <Row
            style={{
              marginBottom: 30,
              marginTop: 40,
            }}
          >
            <Col lg={6} md={6} sm={24} xs={24} style={styles.colStyle}>
              <Select
                dropdownMatchSelectWidth={false}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={activityDropDown}
                size="large"
                showSearch
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "normal",

                }}
                placeholder={formatMsg("placeholder.searchByActivity")}
                onSelect={(value) => this.searchLeads(value, "activity")}
              >
                <Option
                  value="all activities"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal"
                  }}
                >
                  {formatMsg("allActivity")}
                </Option>
                {allActivityList.map((item) => (
                  <Option
                    value={item}
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal"
                    }}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col lg={6} md={6} sm={24} xs={24} style={styles.colStyle}>
              <Select
                dropdownMatchSelectWidth={false}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={this.state.sortByFilterDropDown}
                size="large"
                showSearch
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "normal"
                }}
                placeholder={formatMsg("placeHolder.sortBy")}
                onSelect={(value) => this.searchLeads(value, "sortBy")}
              >


                {this.state.sortByFilter.map((item) => (
                  <Option
                    value={item}
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal"
                    }}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col lg={6} md={6} xs={24} />

            <Col lg={6} md={6} sm={24} xs={24} style={styles.colStyle}>
              <ButtonHolders style={styles.inlineView}>
                {leadDetail && this.checkForParentEmail(leadDetail) === true ? (
                  <Link
                    to={{
                      pathname: "sendLeadEmail",
                      search: "?leadsId=" + externalLeadId,
                    }}
                  >
                    <PrefixIconButton
                      title={formatMsg("application.sendEmail")}
                      onClick={() => null}
                      imgSrc={Email}
                      backgroundColor={colors.white}
                      fontColor={colors.v2_Cerulean}
                      borderColor={colors.v2_Cerulean}
                      fontWeight={"500"}
                      fontSize={18}
                      border={true}
                    />
                  </Link>
                ) : (
                  <PrefixIconButton
                    title={formatMsg("application.sendEmail")}
                    onClick={() => this.noParentEmail()}
                    imgSrc={Email}
                    backgroundColor={colors.white}
                    fontColor={colors.v2_Cerulean}
                    borderColor={colors.v2_Cerulean}
                    fontWeight={"500"}
                    fontSize={18}
                    border={true}
                  />
                )}

                <PrefixIconButton
                  title={<IntlMessages id="leads.addActivity" />}
                  onClick={() => this.createActivity()}
                  backgroundColor={colors.white}
                  fontColor={colors.v2_Cerulean}
                  borderColor={colors.v2_Cerulean}
                  imgSrc={activity}
                  fontWeight={"500"}
                  fontSize={18}
                  border={true}
                />
              </ButtonHolders>
            </Col>
          </Row>



          {leadActivity.length > 0
            ? leadActivity.map((item, index) => (
              <CardWrapper
                key={item.id}
                item={item}
                getIcon={(item) => FilterAction.getIcon(item.activityType)}
                getCommentDiv={this.getCommentDiv}
                markDone={this.markDone}
                getTeacherNameById={this.getTeacherNameById}
                getStatus={this.getStatus}
                renderPopContent={this.renderPopContent}
                firebase={firebase}
              />
            ))
            : this.showEmptyDiv()}
          {/* </InfiniteScroll> */}
          {/* </div> */}
          <CollectionAddActivityForm
            wrappedComponentRef={this.saveFollowUpFormRef}
            isActivityModalVisible={this.state.isAddActivityModalVisible}
            saveActivity={this.addActivity}
            handleAddActivityCancel={this.handleAddActivityCancel}
            handleActivity={(value) => this.handleActivity(value)}
            editablenextFollowUp={this.state.selectedLeadDetail}
            editableRecord={this.state.activityEditableRecord}
            followup={this.state.nextFollowUpOption}
          />
          <NextFollowUpModal
            wrappedComponentRef={this.nextFollowUpDateFromRef}
            isModalVisible={this.state.isModalVisible}
            editablenextFollowUp={this.state.selectedLeadDetail}
            handleCancel={this.handleCancelNextFollowUp}
            saveNextFollowUp={this.saveNextFollowUp}
          />
        </Spin>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.LeadActivity,
  };
}
const a = compose(connect(mapStateToProps, leadsActions), withFirebase);
export default a(LeadActivity);

const styles = {


  colStyle: {
    paddingRight: 8,
    paddingBottom: 8,
  },

  infoContent: {
    fontSize: webFont.lm,
    color: colors.v2_fiord,
    marginBottom: 2,
  },
  infoContentAct: {
    fontSize: webFont.lm,
    color: colors.v2_Cerulean,
    marginBottom: 2,
  },
  pfContent: {
    fontSize: webFont.lm,
    color: colors.v2_grey_chateou,
    marginBottom: 2,
  },
  infoCard: {
    borderRadius: 5,
    boxShadow: "0 3px 5px 0 rgba(0,0,0,0.08)",
  },
  cardStyle: {
    borderRadius: 5,
    marginBottom: 10,
  },
  spacedInline: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    // marginLeft: 10,

    // borderBottom: "1px solid",
    // borderColor: colors.v2_gallery,
  },
  activityType: {
    color: colors.v2_Cerulean,
    fontWeight: 500,
    fontSize: "18px",
    // marginLeft: 15,
    marginRight: 8,
    marginTop: 10,
    marginBottom: 15,
  },
  leadTime: {
    color: colors.v2_grey_chateou,
    fontSize: webFont.lm,
    margin: 0,
    textAlign: "right",
  },
  actions: {
    color: colors.v2_Cerulean,

    textAlign: "right",

    marginRight: 0,
  },
  desc: {
    color: colors.v2_fiord,
    fontSize: webFont.medium,
    marginBottom: 15,
    whiteSpace: "pre",
    whiteSpace: "break-spaces",
  },
  followUpDate: {
    color: colors.v2_fiord,
    fontSize: webFont.medium,
  },
  createdDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  activeTab: {
    color: colors.v2_Cerulean,
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
    fontSize: webFont.medium,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  tab: {
    cursor: "pointer",
    marginLeft: 10,
    marginRight: 10,
    fontSize: webFont.medium,
    color: colors.v2_grey_chateou,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  activityIcon: {
    height: 25,
    width: 25,
    objectFit: "contain",
  },
  followUpIcon: {
    height: 36,
    width: 36,
    objectFit: "contain",
  },
  leadsNavigation: {
    fontSize: webFont.medium,
    color: colors.v2_Cerulean,
    fontWeight: "bold",
    marginottom: 2,
    cursor: "pointer",
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyText: {
    color: colors.v2_grey_chateou,
    fontSize: webFont.fll,
  },

  deleteButton: {
    height: 20,
    width: 20,
    objectFit: "contain",
  },
  vertical: {
    backgroundColor: "#E4F6F9",
  },
  background: {
    backgroundColor: colors.v2_base,
    marginRight: 10,
  },
  listItemIcon: {
    height: 15,
    width: 15,
    marginRight: 10,
    objectFit: "contain",
  },
  rowStyle: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
  },
  colStyle2: {
    marginBottom: "0px",
  },
  chatIcon: {
    height: 20,
    width: 20,
    objectFit: "contain",
    marginRight: 5,
  },
  comment: {
    marginBottom: "0px",
    color: "#8D8D8D",
  },
  statusDiv: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
    borderRadius: 5,
    border: "1px solid",

  },
  status: {
    fontSize: webFont.defaultFont,
    textTransform: "uppercase",
  },
  actionButtonsView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  editButtonView: {
    backgroundColor: colors.v2_polar,
    borderRadius: "8px",
    marginRight: "20px",
    marginLeft: 10,
    padding: 10,
    cursor: "pointer",
  },
  deleteButtonView: {
    backgroundColor: colors.v2_red_background,
    borderRadius: "8px",
    padding: 10,
    cursor: "pointer",
    marginRight: "5px"
  },
  editButton: {
    height: 20,
    width: 20,
    objectFit: "contain",
  },
  deleteButton: {
    height: 20,
    width: 20,
    objectFit: "contain",
  },
  dividerStyle: {
    width: "1px",
    height: '100%',
    backgroundColor: '#ccc',
    margin: '0 12px',
  },
  infoTitle: {
    fontSize: webFont.medium,
    color: colors.v2_Cerulean,
    marginBottom: 2,
  },
  createFollowUpCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 116,
    cursor: "pointer"

  },
  createFollowUpIcon: {
    height: 30,
    width: 30,
    objectFit: "contain",
    cursor: "pointer"
  },
  buttton: {
    height: 18,
    width: 18,
    objectFit: "contain",
  },
  buttonView: {
    backgroundColor: "#DFF7EE",
    borderRadius: "8px",
    marginRight: "25px",
    marginLeft: 10,
    padding: 10,
    cursor: "pointer",
    // color:"#008556",
    // border: "1px solid"
  },
  unCompleteButtonView: {
    // backgroundColor: colors.v2_red_background,
    borderRadius: "8px",
    marginRight: "20px",
    marginLeft: 10,
    padding: 10,
    cursor: "pointer",
    color: "#8D8D8D",
    border: "1px solid"
  },
  unCompleteButtonViewFollowUp: {
    // backgroundColor: colors.v2_red_background,
    borderRadius: "8px",
    marginRight: "10px",
    marginLeft: 10,
    // padding: "7px 8px 0px 9px",
    cursor: "pointer",
    color: "#8D8D8D",
    border: "1px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "-4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    height: "40px",
    marginTop: "1px",
  },
  inlineView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};
