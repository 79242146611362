import antdAr from "antd/lib/locale-provider/en_US";
// import appLocaleData from "react-intl/locale-data/en";
import appLocaleData from "react-intl/locale-data/ar";
import arMessages from "../locales/ar_ARABIC.json";

const ArLang = {
  messages: {
    ...arMessages,
  },
  antd: antdAr,
  locale: "ar",
  data: appLocaleData,
};
export default ArLang;
