import React from "react";
import { Link } from "react-router-dom";
import smallIllumineLogo from "../../image/illumine_logo.png";
import colors from "../../Utility/colorFactory";
import { Icon } from "antd";
import webFont from "../../Utility/fontSizes";

export default ({ collapsed, schoolLogoUrl, toggleCollapsed }) => {
  return (
    <div
      className="isoLogoWrapper"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {collapsed ? (
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Link to="/dashboard">
              <img
                src={smallIllumineLogo}
                alt="logo"
                style={{
                  width: "30px",
                  heigth: "30px",
                }}
              />
            </Link>
          </h3>
        </div>
      ) : (
        <>
          <div>
            <Link to="/dashboard">
              <img
                src={schoolLogoUrl}
                alt="illumineLogo"
                style={{
                  width: "75%",
                  height: "80px",
                  padding: "10px",
                  objectFit: "contain",
                }}
              />
            </Link>
          </div>
          <div
            style={{
              position: "absolute",
              right: 10,
              height: "70px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Icon
              style={{
                color: colors.v2_Cerulean,
                cursor: "pointer",
                fontSize: "26px",
              }}
              type="double-left"
              onClick={toggleCollapsed}
            />
          </div>
        </>
      )}
    </div>
  );
};
const styles = {
  logoStyle: {
    height: "70px",
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // alignItems: "center"
    alignItems: "stretch",
  },
};
