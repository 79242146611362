import actions from "./actions";
const initState = {
  // schedules: [],
  schedules: new Map(),
  classrooms: [],
  schedulesChannel: undefined,
  isLoading: false,
  operationType: undefined,
  error: false,
  deleteMultipleDaySchedule: {},
  activeActivities: [],
  loadingInCaseOfGet: false
};

export default function schedulesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SCHEDULES:
      return {
        ...state,
        //isLoading: true,
        loadingInCaseOfGet: true,
        operationType: undefined,
      };
    case actions.GET_SCHEDULES_SUCCESSFUL:
      return {
        ...state,
        schedules: action.schedules,
        schedulesChannel: action.schedulesChannel,
        //isLoading: false,
        loadingInCaseOfGet: false,
        operationType: "INITIAL_FETCH",
      };

    case actions.SCHEDULE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case actions.GET_SCHEDULE_CLASSROOM:
      return {
        ...state,
      };
    case actions.GET_SCHEDULE_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        operationType: "CLASS_FETCH",
      };

    case actions.RESET_SCHEDULE_OPERATION_TYPE:
      return {
        ...state,
        operationType: undefined,
      };

    case actions.DELETE_SCHEDULE_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SCHEDULE_ITEM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_SCHEDULE_ITEM",
      };

    case actions.ADD_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_SCHEDULE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_SCHEDULE_ITEM",
      };

    case actions.EDIT_SCHEDULE:

      return {
        ...state,
        isLoading: true,
        numberOfSchedulesUpdated: 0,
      };
    case actions.EDIT_SCHEDULE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "EDIT_SCHEDULE_ITEM",
        numberOfSchedulesUpdated: action.numberOfSchedulesUpdated,
      };

    case actions.EDIT_SELETCED_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.EDIT_SELETCED_SCHEDULE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "EDIT_SCHEDULE_ITEM",
      };

    case actions.DELETE_MULTIPLEDAYS_SCHEDULE:
      return {
        ...state,
        numberOfSchedulesUpdated: 0,
        isLoading: true,
      };

    case actions.DELETE_MULTIPLEDAYS_SCHEDULE_SUCCESSFUL:
      return {
        ...state,
        operationType: "DELETE_SCHEDULE_ITEM",
        numberOfSchedulesUpdated: action.numberOfSchedulesUpdated,
        isLoading: false,
      };

    case actions.GET_ACTIVE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activeActivities: [],
      };
    case actions.GET_ACTIVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeActivities: action.activeActivities,
      };

    case actions.DELETE_SCHEDULE_FOR_DATES:
      return {
        ...state,
        isLoading: true,
        numberOfSchedulesUpdated: 0,
      };
    case actions.DELETE_SCHEDULE_FOR_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_SCHEDULE_ITEM",
        numberOfSchedulesUpdated: action.numberOfSchedulesUpdated,
      };

    case actions.RESET_COUNTER:
      return {
        ...state,
        numberOfSchedulesUpdated: undefined,
      };
    default:
      return state;
  }
}
