const actions = {
  GET_VIRTUAL_CLASS: "GET_VIRTUAL_CLASS",
  GET_VIRTUAL_CLASS_SUCCESSFUL: "GET_VIRTUAL_CLASS_SUCCESSFUL",
  VIRTUAL_REQUEST_FAILED: "VIRTUAL_REQUEST_FAILED",
  GET_VIRTUAL_SEEN_COUNT_SUCCESSFUL: "GET_VIRTUAL_SEEN_COUNT_SUCCESSFUL",
  GET_VIRTUAL_TAGGED_STUDENT_DETAIL: "GET_VIRTUAL_TAGGED_STUDENT_DETAIL",
  GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL: "GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL",
  UPDATE_VIRTUAL_ACTIVITY: "UPDATE_VIRTUAL_ACTIVITY",
  UPDATE_VIRTUAL_ACTIVITY_SUCCESSFUL: "UPDATE_VIRTUAL_ACTIVITY_SUCCESSFUL",

  DELETE_VIRTUAL_ACTIVITY: "DELETE_VIRTUAL_ACTIVITY",
  DELETE_VIRTUAL_ACTIVITY_SUCCESSFUL: "DELETE_VIRTUAL_ACTIVITY_SUCCESSFUL",

  GET_FILTER_CLASSROOM: "GET_FILTER_CLASSROOM",
  GET_FILTER_CLASSROOM_SUCCESS: "GET_FILTER_CLASSROOM_SUCCESS",

  STOP_V_CLASS_REPETITION: "STOP_V_CLASS_REPETITION",
  STOP_V_CLASS_REPETITION_SUCCESS: "STOP_V_CLASS_REPETITION_SUCCESS",

  APPROVE_V_CLASS: "APPROVE_V_CLASS",
  APPROVE_V_CLASS_SUCCESS: "APPROVE_V_CLASS_SUCCESS",

  RESET_V_CLASS_OPERATION: "RESET_V_CLASS_OPERATION",

  ADD_STUDENT_TO_MEETING: "ADD_STUDENT_TO_MEETING",
  ADD_STUDENT_TO_MEETING_SUCCESS: "ADD_STUDENT_TO_MEETING_SUCCESS",

  GET_V_CLASS_TRIAL_STUDENT_COUNT: "GET_V_CLASS_TRIAL_STUDENT_COUNT",

  REMOVE_STUDENT_FROM_CLASS: "REMOVE_STUDENT_FROM_CLASS",
  REMOVE_STUDENT_FROM_CLASSS_SUCCESS: "REMOVE_STUDENT_FROM_CLASSS_SUCCESS",

  FETCH_CLASS_LABEL: "FETCH_CLASS_LABEL",

  getVirtualClasses: (fetchAll, firebase, classroom, date, initialCall) => ({
    type: actions.GET_VIRTUAL_CLASS,
    fetchAll,
    firebase,
    classroom,
    date,
    initialCall,
  }),

  getVirtualTaggedStudentName: (activityId, firebase, trial, record) => ({
    type: actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL,
    activityId,
    firebase,
    trial,
    record,
  }),

  updateVirtualActivity: (values, record, firebase) => ({
    type: actions.UPDATE_VIRTUAL_ACTIVITY,
    values,
    record,
    firebase,
  }),

  deleteVirtualActivity: (record, firebase) => ({
    type: actions.DELETE_VIRTUAL_ACTIVITY,
    record,
    firebase,
  }),

  getFilterClassrooms: (firebase) => ({
    type: actions.GET_FILTER_CLASSROOM,
    firebase,
  }),

  stopVirtualClassRepetition: (record, firebase, repeatVirtualClass) => ({
    type: actions.STOP_V_CLASS_REPETITION,
    record,
    firebase,
    repeatVirtualClass,
  }),

  approveVirtualCLass: (item, firebase) => ({
    type: actions.APPROVE_V_CLASS,
    item,
    firebase,
  }),

  resetVirtualClassOperation: () => ({
    type: actions.RESET_V_CLASS_OPERATION,
  }),

  addStudentToMeeting: (activity, students, firebase, selectedTimezone) => ({
    type: actions.ADD_STUDENT_TO_MEETING,
    activity,
    students,
    firebase,
    selectedTimezone,
  }),

  removeStudentFromClass: (student, activity, studentList, firebase, trial, activityList) => ({
    type: actions.REMOVE_STUDENT_FROM_CLASS,
    student,
    activity,
    studentList,
    firebase,
    trial,
    activityList,
  }),

  fetchClassLabel: (firebase) => ({
    type: actions.FETCH_CLASS_LABEL,
    firebase,
  }),
};
export default actions;
