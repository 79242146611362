import antdPt from "antd/lib/locale-provider/pt_PT";
import appLocaleData from "react-intl/locale-data/pt";
import ptMessages from "../locales/pt_PT.json";

const Ptlang = {
  messages: {
    ...ptMessages,
  },
  antd: antdPt,
  locale: "pt",
  data: appLocaleData,
};
export default Ptlang;
