import actions from "./actions";
const initState = {
  isZoomConfigLoading: false,
  status: undefined,
  configChan: undefined,
  teacherChan: undefined,
};
export default function zoomConfigReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.ADD_ZOOM_CONFIG:
      return {
        ...state,
        isZoomConfigLoading: true,
      };

    case actions.ADD_ZOOM_CONFIG_SUCCESSFUL:
      return {
        ...state,
        isZoomConfigLoading: false,
        status: action.status,
      };

    case actions.REQUEST_FAILED:
      return {
        ...state,
        isZoomConfigLoading: false,
      };
    case actions.DISCONNECT_ZOOM_CONFIG:
      return {
        ...state,
        isZoomConfigLoading: true,
      };

    case actions.DISCONNECT_ZOOM_CONFIG_SUCCESSFUL:
      return {
        ...state,
        isZoomConfigLoading: false,
        status: action.status,
      };

    case actions.INIT_FIREBASE_CONFIG:
      return {
        ...state,
      };

    case actions.INIT_FIREBASE_CONFIG_SUCCESS:
      return {
        ...state,
        configChan: action.configChan,
      };

    case actions.GET_TEACHER:
      return {
        ...state,
      };

    case actions.GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacherChan: action.teacherChan,
      };

    default:
      return state;
  }
}
