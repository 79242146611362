import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";

function updateDeleteStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadForm/" + record.id);
  myRef.update({ deleted: true });
}

function updateLeadForm(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadForm/" + record.id);
  myRef.update(record);
}

function getTableData(firebase) {
  let forms = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadForm");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.orderByChild("createdDate").once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null && !element.val().deleted) {
          forms.push(element.val());
        }
      });
      forms.reverse()
      resolve(forms);
    });
  });
  return promise1;
}

function getAutomationData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules");

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      let data = [];

      snap.forEach((element) => {
        if (element.val() !== null) {
          data.push(element.val());
        }
      });
      setTimeout(() => {
        emit(data);
      }, 400);
    });
    return () => {
      myRef.off();
    };
  });
}

function getActionData(actionId, firebase) {
  let ruleActionData = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationSteps/" + actionId);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        ruleActionData = snap.val();
      }
      resolve(ruleActionData);
    });
  });
  return promise1;
}

function generateUniqueAutomationId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/automationRules").push().key;

  return key;
}

function generateUniqueActionId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/automationSteps").push().key;

  return key;
}

function createLeadAutomation(values, refId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules/" + refId);
  myRef.set(values);
}

function getRuleDetails(ruleId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules/" + ruleId);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      let data = {};
      if (snap.val()) {
        data = snap.val();
      }
      setTimeout(() => {
        emit(data);
      }, 400);
    });
    return () => {
      myRef.off();
    };
  });
}

function getRuleActionDetails(ruleId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationSteps");
  return eventChannel((emit) => {
    myRef
      .orderByChild("ruleId")
      .equalTo(ruleId)
      .on("value", (snap) => {
        let actions = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            actions.push(element.val());
          }
        });
        emit(actions);
      });
    return () => {
      myRef.off();
    };
  });
}

function createAction(values, refId, actionId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  if (actionId) {
    const myRef = rsf.ref(branchPath + "/automationSteps/" + actionId);
    myRef.update(values);
  } else {
    const myRef = rsf.ref(branchPath + "/automationSteps/" + refId);
    myRef.set(values);
  }
}

function getLeadActionById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = {};
  const myRef = rsf.ref(branchPath + "/automationSteps/" + id);
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      if (snapshot.val()) {
        data = snapshot.val();
      }
      resolve(data);
    });
  });
  return promise;
}

function fetchStepsData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = [];
  const myRef = rsf.ref(branchPath + "/automationSteps");

  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null) {
          data.push(element.val());
        }
      });
      resolve(data);
    });
  });
  return promise;
}

function deleteAutomationRule(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules/" + id);
  myRef.set(null);
}

function deleteAutomationAction(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationSteps/" + id);
  myRef.set(null);
}

function updateAutomationRule(id, key, value, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules/" + id);
  if (key.toLowerCase() === "status") {
    myRef.update({ status: value });
  } else if (key.toLowerCase() === "title") {
    myRef.update({ rule: value });
  } else if (key.toLowerCase() === "leadstatus") {
    myRef.update({ leadStatus: value });
  }
}

export const leadFormsApi = {
  getTableData,
  getAutomationData,
  updateDeleteStatus,
  updateLeadForm,
  generateUniqueAutomationId,
  generateUniqueActionId,
  createLeadAutomation,
  createAction,
  getRuleDetails,
  getRuleActionDetails,
  getLeadActionById,
  deleteAutomationRule,
  deleteAutomationAction,
  updateAutomationRule,
  getActionData,
  fetchStepsData,
};
