// import logo from '../../../logo.png';
export default {
  apiUrl: "http://yoursite.com/api/",
};
const siteConfig = {
  siteName: "Illumine",
  // siteIcon: 'https://myillumine.com/img/logo.png',
  footerText: "©Illumine Labs Pvt Ltd",
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";
const AlgoliaSearchConfig = {
  appId: "",
  apiKey: "",
};
const Auth0Config = {
  domain: "",
  clientID: "",
  allowedConnections: ["Username-Password-Authentication"],
  rememberLastLogin: true,
  language: "en",
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: "http://localhost:3000/auth0loginCallback",
    },
    languageDictionary: {
      title: "Isomorphic",
      emailInputPlaceholder: "demo@gmail.com",
      passwordInputPlaceholder: "demodemo",
    },
    theme: {
      labeledSubmitButton: true,
      logo: "",
      primaryColor: "#E14615",
      authButtons: {
        connectionName: {
          primaryColor: "#b7b7b7",
          foregroundColor: "#000000",
        },
      },
    },
  },
};
const firebaseConfig = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
};
const googleConfig = {
  apiKey: "", //
};
const mapboxConfig = {
  tileLayer: "",
  maxZoom: "",
  defaultZoom: "",
  center: [],
};
const youtubeSearchApi = "";
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
};
