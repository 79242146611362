import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import centerSagas from "./center/saga";
import classroomSagas from "./classroom/saga";
import curriculumSagas from "./curriculum/saga";
import parentSagas from "./parent/saga";
import studentSagas from "./student/saga";
import foodSagas from "./food/saga";
import teacherSagas from "./teacher/saga";
// import billingSagas from "./billing/saga";
// import transactionsSagas from "./transactions/saga";
// import newScheduleSagas from "./newSchedule/saga";
import newsletterSagas from "./Newsletter/saga";
import sentNewsletterSagas from "./SentNewsletter/saga";
import tagSagas from "./tag/saga";
import learningSagas from "./learning/saga";
import programSagas from "./program/saga";
import assessmentSagas from "./assessment/saga";
import selectedStudentAssessmentSagas from "./selectedStudentAssessment/saga";
import feeSagas from "./fee/saga";
import detailedFeePlanSagas from "./detailedFeePlan/saga";
import detailedProgramSagas from "./detailedProgram/saga";
import onboardingSagas from "./onboarding/saga";
import userSettingSagas from "./userSetting/saga";
import invoiceSagas from "./invoice/saga";
import leadsSagas from "./leads/saga";
import landingPageSagas from "./landingPage/saga";
import teacherDocumentSagas from "./teacherDocument/saga";
import activitiesSagas from "./activities/saga";
import invoiceTemplateSagas from "./invoiceTemplate/saga";
import studentAttendanceSagas from "./studentAttendance/saga";
import staffAttendanceSagas from "./staffAttendance/saga";
import newActivitySagas from "./newActivity/saga";
import complainsSagas from "./complains/saga";
import leavesSagas from "./leaves/saga";
import parentNotesSagas from "./parentNotes/saga";
import holidaySagas from "./holiday/saga";
import eventSagas from "./events/saga";
import schoolDetailsSagas from "./schoolDetails/saga";
import scheduleSagas from "./schedule/saga";
import zoomConfigSagas from "./zoomConfig/saga";
import studentTimelineSagas from "./studentTimeline/saga";
import virtualClassSagas from "./virtualClass/saga";
import assignmentsSagas from "./assignments/saga";
import commentSagas from "./comment/saga";
import submissionsSagas from "./submissions/saga";
import lessonStatsSagas from "./lessonStats/saga";
import singleSubmissionSagas from "./singleSubmission/saga";
import viewLessonSagas from "./viewLesson/saga";
import studentLessonSagas from "./studentLesson/saga";
import querySagas from "./query/saga";
import permissionSagas from "./permissions/saga";
import reportSagas from "./reports/saga";
import applicationSagas from "./application/saga";
import staffToStudentLiveAttendanceSagas from "./staffToStudentAttendanceLiveRatio/saga";
import allReportsSagas from "./allReports/saga";
import customActivitiesSaga from "./customActivity/saga";
import leadFormsSagas from "./leadForms/saga";
import expenseSaga from "./ExpenseManagement/saga";
import feeAutomationSaga from "./feeAutomation/saga";
import emailTemplateSaga from "./emailTemplates/saga";
import staffScheduleSaga from "./staffScheduleTable/saga";
import staffInstallationSaga from "./staffInstallations/saga";
import studentImmunizationSaga from "./studentImmunization/saga";
import newFeePlan from "./newFeePlan/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    centerSagas(),
    classroomSagas(),
    curriculumSagas(),
    parentSagas(),
    studentSagas(),
    foodSagas(),
    teacherSagas(),
    // billingSagas(),
    // transactionsSagas(),
    // newScheduleSagas(),
    newsletterSagas(),
    sentNewsletterSagas(),
    tagSagas(),
    learningSagas(),
    programSagas(),
    assessmentSagas(),
    selectedStudentAssessmentSagas(),
    feeSagas(),
    detailedFeePlanSagas(),
    detailedProgramSagas(),
    onboardingSagas(),
    userSettingSagas(),
    invoiceSagas(),
    leadsSagas(),
    landingPageSagas(),
    teacherDocumentSagas(),
    activitiesSagas(),
    invoiceTemplateSagas(),
    studentAttendanceSagas(),
    staffAttendanceSagas(),
    newActivitySagas(),
    complainsSagas(),
    leavesSagas(),
    parentNotesSagas(),
    holidaySagas(),
    eventSagas(),
    schoolDetailsSagas(),
    scheduleSagas(),
    zoomConfigSagas(),
    studentTimelineSagas(),
    virtualClassSagas(),
    assignmentsSagas(),
    commentSagas(),
    submissionsSagas(),
    lessonStatsSagas(),
    singleSubmissionSagas(),
    viewLessonSagas(),
    studentLessonSagas(),
    querySagas(),
    permissionSagas(),
    reportSagas(),
    applicationSagas(),
    staffToStudentLiveAttendanceSagas(),
    allReportsSagas(),
    customActivitiesSaga(),
    leadFormsSagas(),
    expenseSaga(),
    feeAutomationSaga(),
    emailTemplateSaga(),
    staffScheduleSaga(),
    staffInstallationSaga(),
    studentImmunizationSaga(),
    newFeePlan()
  ]);
}
