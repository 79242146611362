import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
import { values } from "lodash";
function createNewTemplateNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/emailTemplates/").push().key;
  return key;
}

function getAllEmailTemplate(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/emailTemplates");
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var templates = [];
      snap.forEach((element) => {
        if (element !== null) {
          templates.push(element.val());
        }
      });
      emit(templates);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe email templates");
    };
  });
}
function getAllEmailTemplateByType(firebase, templateType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/emailTemplates")
    .orderByChild("type")
    .equalTo(templateType);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var templates = [];
      snap.forEach((element) => {
        if (element !== null) {
          templates.push(element.val());
        }
      });
      emit(templates);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe email templates  by type");
    };
  });
}
function addTemplate(value, nodeId, firebase, htmlText, opreationType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {};

  if (opreationType?.toLowerCase() == "edit") {
    obj = {
      templateName: value.templateName,
      subject: value.subject,
      html: htmlText,
      id: nodeId,
      platform: "web",
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
      label: value.label ? value.label : null,
      type: value.type,
      replyTo: value.replyTo,
      attachment: value.attachment ? value.attachment : null
    };
  } else {
    obj = {
      templateName: value.templateName,
      subject: value.subject,
      html: htmlText,
      id: nodeId,
      platform: "web",
      createBy: firebase.teacher.name,
      createdOn: moment().valueOf(),
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
      label: value.label ? value.label : null,
      type: value.type,
      replyTo: value.replyTo,
      attachment: value.attachment ? value.attachment : null
    };
  }

  rsf.ref(branchPath + "/emailTemplates/" + nodeId).update(obj);
}

function deleteEmailTemplateStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/emailTemplates/" + record.id).set(null);
}

function getEmailTemplateById(firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/emailTemplates/" + id);
  var templatePromise = new Promise(function (resolve, reject) {
    myRef.once("value", (snap) => {
      if (snap.val()) {
        resolve(snap.val());
      }
    });
  });
  return templatePromise;
}
function getCustomLabelByCategory(category, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = [];

  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/labelRef/" + category)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          data = snapshot.val();
        }
        resolve(data);
      });
  });

  return promise;
}

export const emailTemplateApi = {
  getAllEmailTemplate,
  addTemplate,
  createNewTemplateNode,
  deleteEmailTemplateStatus,
  getAllEmailTemplateByType,
  getCustomLabelByCategory,
  getEmailTemplateById,
};
