import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { round } from "mathjs";

function getSchoolDetails(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var details = {};
  var detailsPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/schoolDetails")
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          details = snap.val();
        }
        resolve(details);
      });
  });
  return detailsPromise;
}

function updateSchoolDetail(values, firebase) {
  let totalWorkingHours = null;
  if (values.startTime && values.endTime) {
    let schoolStartTime = moment(values.startTime, "HH:mm:ss a");
    let schoolEndTime = moment(values.endTime, "HH:mm:ss a");
    const duration = moment.duration(schoolEndTime.diff(schoolStartTime));

    const hours = duration.asHours();

    totalWorkingHours = round(hours, 2);
  }

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/schoolDetails").update({
    address: values.schoolAddress ? values.schoolAddress : null,
    bankAccount: values.bankAccount ? values.bankAccount : null,
    contactNumber: values.contact ? values.contact : null,
    email: values.email ? values.email : null,
    fb: values.fbLink ? values.fbLink : null,
    insta: values.instaLink ? values.instaLink : null,
    web: values.websiteLink ? values.websiteLink : null,
    schoolCapacity: values.schoolCapacity ? parseInt(values.schoolCapacity) : null,
    daysOfWeek: values.daysOfWeek ? values.daysOfWeek : null,
    startTime: values.startTime ? moment(values.startTime).valueOf() : null,
    endTime: values.endTime ? moment(values.endTime).valueOf() : null,
    totalWorkingHours: totalWorkingHours ? round(totalWorkingHours, 2) : null,
  });
}

export const SchoolDetailApi = {
  getSchoolDetails,
  updateSchoolDetail,
};
