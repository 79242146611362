const actions = {
    // Ajit Start
    // Action define
    GET_DISCOUNT_DATA: "GET_DISCOUNT_DATA",
    GET_DISCOUNT_DATA_SUCCESS: "GET_DISCOUNT_DATA_SUCCESS",

    ADD_DISCOUNT_DATA: "ADD_DISCOUNT_DATA",
    ADD_DISCOUNT_DATA_SUCCESS: "ADD_DISCOUNT_DATA_SUCCESS",

    CHECK_IF_DISCOUNT_IS_BEING_USED: "CHECK_IF_DISCOUNT_IS_BEING_USED",
    CHECK_IF_DISCOUNT_IS_BEING_USED_SUCCESS: "CHECK_IF_DISCOUNT_IS_BEING_USED_SUCCESS",
    RESET_DISCOUNT_CHECK: "RESET_DISCOUNT_CHECK",

    DELETE_DISCOUNT: "DELETE_DISCOUNT",
    DELETE_DISCOUNT_SUCCESS: "DELETE_DISCOUNT_SUCCESS",

    GET_TAX_DATA: "GET_TAX_DATA",
    GET_TAX_DATA_SUCCESS: "GET_TAX_DATA_SUCCESS",

    ADD_TAX_DATA: "ADD_TAX_DATA",
    ADD_TAX_DATA_SUCCESS: "ADD_TAX_DATA_SUCCESS",

    CHECK_IF_TAX_IS_BEING_USED: "CHECK_IF_TAX_IS_BEING_USED",
    CHECK_IF_TAX_IS_BEING_USED_SUCCESS: "CHECK_IF_TAX_IS_BEING_USED_SUCCESS",
    RESET_TAX_CHECK: "RESET_TAX_CHECK",

    DELETE_TAX: "DELETE_TAX",
    DELETE_TAX_SUCCESS: "DELETE_TAX_SUCCESS",

    GET_FEE_COMPONENT_LIST_DATA: "GET_FEE_COMPONENT_LIST_DATA",
    GET_FEE_COMPONENT_LIST_DATA_SUCCESS: "GET_FEE_COMPONENT_LIST_DATA_SUCCESS",

    ADD_NEW_FEE_COMPONENT: "ADD_NEW_FEE_COMPONENT",
    ADD_NEW_FEE_COMPONENT_SUCCESS: "ADD_NEW_FEE_COMPONENT_SUCCESS",

    DELETE_NEW_FEE_COMPONENT: "DELETE_NEW_FEE_COMPONENT",
    DELETE_NEW_FEE_COMPONENT_SUCCESS: "DELETE_NEW_FEE_COMPONENT_SUCCESS",

    CHECK_IF_FEE_COMPONENT_IS_BEING_USED: "CHECK_IF_FEE_COMPONENT_IS_BEING_USED",
    CHECK_IF_FEE_COMPONENT_IS_BEING_USED_SUCCESS: "CHECK_IF_FEE_COMPONENT_IS_BEING_USED_SUCCESS",
    RESET_FEE_COMPONENT_CHECK: "RESET_FEE_COMPONENT_CHECK",

    FETCH_NEW_FEE_PLAN_WITH_ID: "FETCH_NEW_FEE_PLAN_WITH_ID",
    FETCH_NEW_FEE_PLAN_WITH_ID_SUCCESS: "FETCH_NEW_FEE_PLAN_WITH_ID_SUCCESS",

    CHANGE_ASSIGN_STUDENT_STATUS: "CHANGE_ASSIGN_STUDENT_STATUS",
    CHANGE_ASSIGN_STUDENT_STATUS_SUCCESS: "CHANGE_ASSIGN_STUDENT_STATUS_SUCCESS",

    DELETE_FEE_PLAN_ASSIGN_STUDENT: "DELETE_FEE_PLAN_ASSIGN_STUDENT",
    DELETE_FEE_PLAN_ASSIGN_STUDENT_SUCCESS: "DELETE_FEE_PLAN_ASSIGN_STUDENT_SUCCESS",

    FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID: "FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID",
    FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID_SUCCESS: "FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID_SUCCESS",

    FETCH_PLAN_UPCOMING_INVOICES: "FETCH_PLAN_UPCOMING_INVOICES",
    FETCH_PLAN_UPCOMING_INVOICES_SUCCESS: "FETCH_PLAN_UPCOMING_INVOICES_SUCCESS",

    SAVE_SCHOOL_INVOICE_PDF_STYLE: "SAVE_SCHOOL_INVOICE_PDF_STYLE",
    SAVE_SCHOOL_INVOICE_PDF_STYLE_SUCCESS: "SAVE_SCHOOL_INVOICE_PDF_STYLE_SUCCESS",
    GET_SCHOOL_INVOICE_PDF_STYLE: "GET_SCHOOL_INVOICE_PDF_STYLE",
    GET_SCHOOL_INVOICE_PDF_STYLE_SUCCESS: "GET_SCHOOL_INVOICE_PDF_STYLE_SUCCESS",

    SAVE_FEEPLAN_INVOICE_PDF_STYLE: "SAVE_FEEPLAN_INVOICE_PDF_STYLE",
    SAVE_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS: "SAVE_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS",
    GET_FEEPLAN_INVOICE_PDF_STYLE: "GET_FEEPLAN_INVOICE_PDF_STYLE",
    GET_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS: "GET_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS",

    getDiscountData: (firebase) => ({
        type: actions.GET_DISCOUNT_DATA,
        firebase
    }),

    saveDiscountData: (discountItem, firebase) => ({
        type: actions.ADD_DISCOUNT_DATA,
        discountItem,
        firebase
    }),

    checkIfDiscountIsBeingUsed: (firebase, discountId) => ({
        type: actions.CHECK_IF_DISCOUNT_IS_BEING_USED,
        firebase,
        discountId
    }),

    resetDiscountCheck: () => ({
        type: actions.RESET_DISCOUNT_CHECK
    }),

    deleteDiscount: (firebase, discountId) => ({
        type: actions.DELETE_DISCOUNT,
        firebase,
        discountId
    }),

    getTaxData: (firebase) => ({
        type: actions.GET_TAX_DATA,
        firebase,
    }),

    saveTaxData: (taxItem, firebase) => ({
        type: actions.ADD_TAX_DATA,
        taxItem,
        firebase,
    }),

    checkIfTaxIsBeingUsed: (firebase, taxId) => ({
        type: actions.CHECK_IF_TAX_IS_BEING_USED,
        firebase,
        taxId,
    }),

    resetTaxCheck: () => ({
        type: actions.RESET_TAX_CHECK,
    }),

    deleteTax: (firebase, taxId) => ({
        type: actions.DELETE_TAX,
        firebase,
        taxId,
    }),

    getFeeComponentListData: (firebase, currentBillingType) => ({
        type: actions.GET_FEE_COMPONENT_LIST_DATA,
        firebase,
        currentBillingType
    }),

    addNewFeeComponent: (feeComponentItem, firebase) => ({
        type: actions.ADD_NEW_FEE_COMPONENT,
        feeComponentItem,
        firebase
    }),

    deleteFeeComponent: (firebase, feeComponentId) => ({
        type: actions.DELETE_NEW_FEE_COMPONENT,
        firebase,
        feeComponentId
    }),

    checkIfFeeComponentIsBeingUsed: (firebase, feeComponentId) => ({
        type: actions.CHECK_IF_FEE_COMPONENT_IS_BEING_USED,
        firebase,
        feeComponentId,
    }),

    resetFeeComponentCheck: () => ({
        type: actions.RESET_FEE_COMPONENT_CHECK,
    }),

    fetchNewFeePlanWithId: (firebase, feePlanId) => ({
        type: actions.FETCH_NEW_FEE_PLAN_WITH_ID,
        firebase,
        feePlanId
    }),

    changeAssignStudentStatus: (firebase, updatedAssignStudent) => ({
        type: actions.CHANGE_ASSIGN_STUDENT_STATUS,
        firebase,
        updatedAssignStudent
    }),

    deleteFeePlanAssignStudent: (firebase, id) => ({
        type: actions.DELETE_FEE_PLAN_ASSIGN_STUDENT,
        firebase,
        id
    }),

    fetchGeneratedInvoicesWithFeePlanId: (firebase, feePlanId) => ({
        type: actions.FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID,
        firebase,
        feePlanId
    }),

    fetchFeePlanUpcomingInvoices: (firebase, feePlanId) => ({
        type: actions.FETCH_PLAN_UPCOMING_INVOICES,
        firebase,
        feePlanId
    }),

    saveSchoolInvoicePDFStyle: (firebase, styleObj) => ({
        type: actions.SAVE_SCHOOL_INVOICE_PDF_STYLE,
        firebase,
        styleObj
    }),

    getSchoolInvoicePDFStyle: (firebase) => ({
        type: actions.GET_SCHOOL_INVOICE_PDF_STYLE,
        firebase,
    }),

    saveFeePlanInvoicePDFStyle: (firebase, styleObj, feePlanId) => ({
        type: actions.SAVE_FEEPLAN_INVOICE_PDF_STYLE,
        firebase,
        styleObj,
        feePlanId
    }),

    getFeePlanInvoicePDFStyle: (firebase, feePlanId) => ({
        type: actions.GET_FEEPLAN_INVOICE_PDF_STYLE,
        firebase,
        feePlanId,
    }),
































































































    // Ajit End

    // Sagar Start
    GET_PROGRAMS: "GET_PROGRAMS",
    GET_PROGRAMS_SUCCESS: "GET_PROGRAMS_SUCCESS",

    GET_LABELS: "GET_LABELS",
    GET_LABELS_SUCCESS: "GET_LABELS_SUCCESS",

    GET_FEE_PLAN_DISCOUNTS: "GET_FEE_PLAN_DISCOUNTS",
    GET_FEE_PLAN_DISCOUNTS_SUCCESS: "GET_FEE_PLAN_DISCOUNTS_SUCCESS",

    GET_FEE_PLAN_TAXES: "GET_FEE_PLAN_TAXES",
    GET_FEE_PLAN_TAXES_SUCCESS: "GET_FEE_PLAN_TAXES_SUCCESS",

    SAVE_FEE_PLAN: "SAVE_FEE_PLAN",
    SAVE_FEE_PLAN_SUCCESS: "SAVE_FEE_PLAN_SUCCESS",

    GET_FEE_PLAN_LIST: "GET_FEE_PLAN_LIST",
    GET_FEE_PLAN_LIST_SUCCESS: "GET_FEE_PLAN_LIST_SUCCESS",

    GET_FEE_COMPONENT_LIST: "GET_FEE_COMPONENT_LIST",
    GET_FEE_COMPONENT_LIST_SUCCESS: "GET_FEE_COMPONENT_LIST_SUCCESS",

    DELETE_SELECTED_FEE_PLAN: "DELETE_SELECTED_FEE_PLAN",
    DELETE_SELECTED_FEE_PLAN_SUCCESS: "DELETE_SELECTED_FEE_PLAN_SUCCESS",

    PAUSE_OR_ACTIVE_FEE_PLAN: "PAUSE_OR_ACTIVE_FEE_PLAN",
    PAUSE_OR_ACTIVE_FEE_PLAN_SUCCESS: "PAUSE_OR_ACTIVE_FEE_PLAN_SUCCESS",

    UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN: "UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN",
    UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN_SUCCESS: "UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN_SUCCESS",

    UPDATE_ONLY_ONE_ASSIGN_STUDENT: "UPDATE_ONLY_ONE_ASSIGN_STUDENT",
    UPDATE_ONLY_ONE_ASSIGN_STUDENT_SUCCESS: "UPDATE_ONLY_ONE_ASSIGN_STUDENT_SUCCESS",

    FEE_PLAN_API_REQUEST_FAILED: "FEE_PLAN_API_REQUEST_FAILED",

    RESET_FEE_PLAN_REDUCER_STATE: "RESET_FEE_PLAN_REDUCER_STATE",

    RESET_OPERATION_TYPE: "RESET_OPERATION_TYPE",

    GET_LINE_ITEMS_BY_FEE_PLAN_ID: "GET_LINE_ITEMS_BY_FEE_PLAN_ID",
    GET_LINE_ITEMS_BY_FEE_PLAN_ID_SUCCESS: "GET_LINE_ITEMS_BY_FEE_PLAN_ID_SUCCESS",


    getPrograms: (firebase) => ({
        type: actions.GET_PROGRAMS,
        firebase
    }),
    getLabels: (firebase, labelTypeName) => ({
        type: actions.GET_LABELS,
        firebase,
        labelTypeName
    }),
    getFeePlanDiscounts: (firebase) => ({
        type: actions.GET_FEE_PLAN_DISCOUNTS,
        firebase,
    }),
    getFeePlanTaxes: (firebase) => ({
        type: actions.GET_FEE_PLAN_TAXES,
        firebase,
    }),
    saveFeePlan: (firebase, values) => ({
        type: actions.SAVE_FEE_PLAN,
        firebase,
        values
    }),
    getFeePlanList: (firebase) => ({
        type: actions.GET_FEE_PLAN_LIST,
        firebase
    }),
    getFeeComponentList: (firebase, currentBillingType) => ({
        type: actions.GET_FEE_COMPONENT_LIST,
        firebase,
        currentBillingType
    }),
    deleteFeePlan: (firebase, feePlanId) => ({
        type: actions.DELETE_SELECTED_FEE_PLAN,
        firebase,
        feePlanId
    }),
    pauseOrActiveFeePlan: (firebase, feePlanId) => ({
        type: actions.PAUSE_OR_ACTIVE_FEE_PLAN,
        firebase,
        feePlanId
    }),
    updateAssignStudentsToFeePlan: (firebase, assignStudentListObj) => ({
        type: actions.UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN,
        firebase,
        assignStudentListObj
    }),
    updateOnlyOneAssignStudentsToFeePlan: (firebase, assignStudentObj) => ({
        type: actions.UPDATE_ONLY_ONE_ASSIGN_STUDENT,
        firebase,
        assignStudentObj
    }),
    resetOperationType: () => ({
        type: actions.RESET_OPERATION_TYPE
    }),
    resetReducerState: () => ({
        type: actions.RESET_FEE_PLAN_REDUCER_STATE
    }),
    getAllLineItemByFeePlan: (firebase, feePlanId) => ({
        type: actions.GET_LINE_ITEMS_BY_FEE_PLAN_ID,
        firebase,
        feePlanId
    }),


























































































    // Sagar End









};
export default actions;