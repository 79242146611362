const actions = {
  GET_STUDENT_ATTENDANCE: "GET_STUDENT_ATTENDANCE",
  GET_STUDENT_ATTENDANCE_SUCCESSFUL: "GET_STUDENT_ATTENDANCE_SUCCESSFUL",

  GET_STUDENT: "GET_STUDENT",
  GET_STUDENT_SUCCESSFUL: "GET_STUDENT_SUCCESSFUL",

  GET_STAFF_ATTENDANCE: "GET_STAFF_ATTENDANCE",
  GET_STAFF_ATTENDANCE_SUCCESSFUL: "GET_STAFF_ATTENDANCE_SUCCESSFUL",
  GET_STAFF: "GET_STAFF",
  GET_STAFF_SUCCESSFUL: "GET_STAFF_SUCCESSFUL",

  GET_AGGREGATED_INVOICE: "GET_AGGREGATED_INVOICE",
  GET_AGGREGATED_INVOICE_SUCCESSFUL: "GET_AGGREGATED_INVOICE_SUCCESSFUL",

  GET_DAILY_REPORT: "GET_DAILY_REPORT",
  GET_DAILY_REPORT_SUCCESSFUL: "GET_DAILY_REPORT_SUCCESSFUL",

  GET_TODAYS_POST: "GET_TODAYS_POST",
  GET_TODAYS_POST_SUCCESSFUL: "GET_TODAYS_POST_SUCCESSFUL",

  GET_CLASSROOM: "GET_CLASSROOM",
  GET_CLASSROOM_SUCCESSFUL: "GET_CLASSROOM_SUCCESSFUL",

  GET_ACTIVITY_STAT: "GET_ACTIVITY_STAT",
  GET_ACTIVITY_STAT_SUCCESSFUL: "GET_ACTIVITY_STAT_SUCCESSFUL",

  GET_INVOICE_STATS_SUCCESSFUL: "GET_INVOICE_STATS_SUCCESSFUL",

  GET_OPEN_CONCERNS_SUCCESSFFUL: "GET_OPEN_CONCERNS_SUCCESSFFUL",

  GET_OPEN_NOTES_SUCCESSFFUL: "GET_OPEN_NOTES_SUCCESSFFUL",

  GET_UNREAD_PARENT_MSG_SUCCESSFFUL: "GET_UNREAD_PARENT_MSG_SUCCESSFFUL",

  GET_UNREAD_ALERT_NOTIFICATION: "GET_UNREAD_ALERT_NOTIFICATION",
  GET_UNREAD_ALERT_NOTIFICATION_SUCCESSFUL: "GET_UNREAD_ALERT_NOTIFICATION_SUCCESSFUL",

  GET_ALERT_NOTIFICATIONS: "GET_ALERT_NOTIFICATIONS",
  GET_ALERT_NOTIFICATIONS_SUCCESSFUL: "GET_ALERT_NOTIFICATIONS_SUCCESSFUL",

  UPDATE_ALERT_NOTIFICATION_READ_STATUS: "UPDATE_ALERT_NOTIFICATION_READ_STATUS",

  REQUEST_ATTENDANCE: "REQUEST_ATTENDANCE",

  GET_DASHBOARD_LEADS: "GET_DASHBOARD_LEADS",
  GET_DASHBOARD_LEADS_SUCCESSFUL: "GET_DASHBOARD_LEADS_SUCCESSFUL",

  INITIAL_FIREBASE_CONFIG_SUCCESS: "INITIAL_FIREBASE_CONFIG_SUCCESS",
  GET_INIT_TEACHER_SUCCESS: "GET_INIT_TEACHER_SUCCESS",
  DASHBOARD_REQ_FAILED: "DASHBOARD_REQ_FAILED",

  GET_UPCOMING_MEETING_SUCCESS: "GET_UPCOMING_MEETING_SUCCESS",

  GET_DASHBOARD_MEETINGS: "GET_DASHBOARD_MEETINGS",
  GET_DASHBOARD_MEETINGS_SUCCESS: "GET_DASHBOARD_MEETINGS_SUCCESS",

  GET_DASHBOARD_STATS: "GET_DASHBOARD_STATS",
  GET_DASHBOARD_STATS_SUCCESS: "GET_DASHBOARD_STATS_SUCCESS",
  GET_DASHBOARD_EVENT_SUCCESS: "GET_DASHBOARD_EVENT_SUCCESS",
  GET_DASHBOARD_BIRTHDAY_SUCCESS: "GET_DASHBOARD_BIRTHDAY_SUCCESS",
  GET_DASHBOARD_STUDENT_LEAVES: "GET_DASHBOARD_STUDENT_LEAVES",
  GET_DASHBOARD_STAFF_LEAVES: "GET_DASHBOARD_STAFF_LEAVES",
  GET_NEW_DASHBOARD_STATS: "GET_NEW_DASHBOARD_STATS",
  GET_NEW_DASHBOARD_ATTENDANCE_STATS: "GET_NEW_DASHBOARD_ATTENDANCE_STATS",
  GET_NEW_DASHBOARD_STAFF_ATTENDANCE_STATS: "GET_NEW_DASHBOARD_STAFF_ATTENDANCE_STATS",

  GET_NEW_DASHBOARD_ACTIVITY_STATS: "GET_NEW_DASHBOARD_ACTIVITY_STATS",
  GET_NEW_DASHBOARD_ACTIVITY_STATS_SUCCESS: "GET_NEW_DASHBOARD_ACTIVITY_STATS_SUCCESS",

  GET_NEW_DASHBOARD_LEARNING_STATS: "GET_NEW_DASHBOARD_LEARNING_STATS",
  GET_NEW_DASHBOARD_LEARNING_STATS_SUCCESS: "GET_NEW_DASHBOARD_LEARNING_STATS_SUCCESS",
  GET_NEW_DASHBOARD_LEARNING_PLAN_SUCCESS: "GET_NEW_DASHBOARD_LEARNING_PLAN_SUCCESS",

  GET_NEW_DASHBOARD_LEADS_STATS: "GET_NEW_DASHBOARD_LEADS_STATS",
  GET_NEW_DASHBOARD_LEADS_STATS_SUCCESS: "GET_NEW_DASHBOARD_LEADS_STATS_SUCCESS",

  GET_NEW_DASHBOARD_FINANCE_STATS: "GET_NEW_DASHBOARD_FINANCE_STATS",
  GET_NEW_DASHBOARD_FINANCE_STATS_SUCCESS: "GET_NEW_DASHBOARD_FINANCE_STATS_SUCCESS",

  FORCE_REFRESH: "FORCE_REFRESH",
  FORCE_REFRESH_RESET: "FORCE_REFRESH_RESET",
  GET_STUDENT_ABSENT_MORE_THAN_THREE_DAYS: "GET_STUDENT_ABSENT_MORE_THAN_THREE_DAYS",
  GET_STUDENT_ABSENT_MORE_THAN_THREE_DAYS_SUCCESS:
    "GET_STUDENT_ABSENT_MORE_THAN_THREE_DAYS_SUCCESS",

  getStudentForAttendanceComputation: (firebase) => ({
    type: actions.GET_STUDENT,
    firebase,
  }),

  getStudentAbsentFormThreeDays: (firebase) => ({
    type: actions.GET_STUDENT_ABSENT_MORE_THAN_THREE_DAYS,
    firebase,
  }),

  getStudentAttendance: (firebase) => ({
    type: actions.GET_STUDENT_ATTENDANCE,
    firebase,
  }),

  getStaffForAttendanceComputation: (firebase) => ({
    type: actions.GET_STAFF,
    firebase,
  }),

  getStaffAttendance: (firebase) => ({
    type: actions.GET_STAFF_ATTENDANCE,
    firebase,
  }),

  getAggregatedInvoice: (firebase) => ({
    type: actions.GET_AGGREGATED_INVOICE,
    firebase,
  }),

  getDailyReport: (firebase) => ({
    type: actions.GET_DAILY_REPORT,
    firebase,
  }),

  getTodaysPost: (date, firebase) => ({
    type: actions.GET_TODAYS_POST,
    date,
    firebase,
  }),

  getClassrooms: (firebase) => ({
    type: actions.GET_CLASSROOM,
    firebase,
  }),

  getActivityStat: (startDate, endDate, firebase, branches) => ({
    type: actions.GET_ACTIVITY_STAT,
    startDate,
    endDate,
    firebase,
    branches,
  }),

  requestAttendance: (date, firebase) => ({
    type: actions.REQUEST_ATTENDANCE,
    date,
    firebase,
  }),

  getAlertNotification: (firebase, limit) => ({
    type: actions.GET_ALERT_NOTIFICATIONS,
    firebase,
    limit,
  }),

  getUnreadAlertNotification: (firebase) => ({
    type: actions.GET_UNREAD_ALERT_NOTIFICATION,
    firebase,
  }),

  updateNotificationReadStatus: (notification, firebase) => ({
    type: actions.UPDATE_ALERT_NOTIFICATION_READ_STATUS,
    notification,
    firebase,
  }),

  getDashboardMeetings: (firebase, date, branchPaths) => ({
    type: actions.GET_DASHBOARD_MEETINGS,
    firebase,
    date,
    branchPaths,
  }),

  getDashboardStatistics: (firebase, date, branchPaths) => ({
    type: actions.GET_DASHBOARD_STATS,
    firebase,
    date,
    branchPaths,
  }),

  getActivityDashboardStats: (firebase, date, branchPaths) => ({
    type: actions.GET_NEW_DASHBOARD_ACTIVITY_STATS,
    firebase,
    date,
    branchPaths,
  }),

  getLearningDashboardStats: (firebase, date, branchPaths) => ({
    type: actions.GET_NEW_DASHBOARD_LEARNING_STATS,
    firebase,
    date,
    branchPaths,
  }),

  getLeadDashboardStats: (firebase, date, branchPaths) => ({
    type: actions.GET_NEW_DASHBOARD_LEADS_STATS,
    firebase,
    date,
    branchPaths,
  }),

  getFinanceDashboardStats: (firebase, date, branchPaths) => ({
    type: actions.GET_NEW_DASHBOARD_FINANCE_STATS,
    firebase,
    date,
    branchPaths,
  }),

  callBrowserRefresh: () => ({
    type: actions.FORCE_REFRESH,
  }),

  resetBrowserRefresh: () => ({
    type: actions.FORCE_REFRESH_RESET,
  }),
};
export default actions;
