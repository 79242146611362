import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { NotificationApi } from "../../firestore-api/notification";
import actions from "./actions";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import { callApi } from "../../Utility/superAgentUntil";
import { TeacherApi } from "../../firestore-api/teacher"
import formatMsg from "../../components/utility/formatMessageUtil";
import { UserSettingApi } from "../../firestore-api/userSetting";

function* sendTeacherInvite({ teachers, reminderMode, firebase, forcedUpdate }) {
    try {
        let schoolName = firebase.sbDbName ? firebase.sbDbName : firebase.schoolName;
        let textMessage =
            "Welcome to " +
            schoolName +
            ". Click on http://bit.ly/myillumine (android) , https://apple.co/2DgqUhE (IOS) to download the illumine app. Login with your phone number";
        let endpoint = "woodlandApi/staffInstallation?centerId=" + firebase.sbDbName;
        if (reminderMode.toLowerCase() == "email") {
            let inviteObj = {
                teachers: teachers,
                message: textMessage
            }
            let response = yield call(TeacherApi.sendInviteToTeacher, teachers, firebase);

            if (response.status === 200) {
                yield put({
                    type: actions.SEND_STAFF_INVITE_SUCCESSFULL,
                });
                notification("success", formatMsg("installationReport.inviteSentSuccessfully"))
            } else {
                notification("error", formatMsg("installationReport.inviteSentfail"))
                throw new Error("Failed to send invitation");
            }
        }
    } catch (err) {
        bugsnagClient.notify(err);
        notification("error", formatMsg("installationReport.inviteSentfail"))
        console.log("failed to send invite reminder", err);
    }
}

function* fetchEmailDeliveryStatus({ data, firebase }) {
    try {
        let taskList = [];
        var deliveryObj = {};

        for (let i in data) {
            if (data[i].teacherMailResult) {
                let task = call(UserSettingApi.getMailDeliveryReport, data[i].teacherMailResult, firebase);

                taskList.push(task);
            }
        }

        let newVal = yield all([taskList]);

        for (let i in newVal[0]) {
            let val = newVal[0][i];
            if (val.id) {
                deliveryObj[val.id] = val;
            }
        }

        yield put({
            type: actions.GET_EMAIL_DELIVERY_STATUS_SUCCESS,
            emailDeliveryStatus: deliveryObj,
        });
    } catch (err) {
        console.log("error in fetching delivery report ", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.SEND_STAFF_INVITE, sendTeacherInvite),
        yield takeLatest(actions.GET_EMAIL_DELIVERY_STATUS, fetchEmailDeliveryStatus),

    ])
}