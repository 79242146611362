import { all, takeEvery, put, call, take } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { NewsletterApi } from "../../firestore-api/newsletter";

export function* getAllSentNewsletter({ firebase }) {
  const chan = yield call(NewsletterApi.getSentNewsletter, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      var activities = data;
      activities.sort(function (a, b) {
        var dateA = a.date.time,
          dateB = b.date.time;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_SENT_NEWSLETTER_SUCCESS,
        allSentNewspaper: activities,
        sentNewsletterChannel: chan,
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_ALL_SENT_NEWSLETTER, getAllSentNewsletter)]);
}
