const actions = {
  SHOW_REPORT: "SHOW_REPORT",
  SHOW_REPORT_SUCCESS: "SHOW_REPORT_SUCCESS",

  SUBMIT_FORM: "SUBMIT_FORM",
  SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",

  DELETE_REPORT: "DELETE_REPORT",
  DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",

  showReports: (firebase) => ({
    type: actions.SHOW_REPORT,
    firebase,
  }),

  submitFormValues: (reportName, values, firebase, endpoint) => ({
    type: actions.SUBMIT_FORM,
    reportName,
    values,
    firebase,
    endpoint,
  }),

  deleteGeneratedReport: (id, firebase) => ({
    type: actions.DELETE_REPORT,
    id,
    firebase,
  }),
};

export default actions;
