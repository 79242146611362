// import clone from 'clone';
import actions from "./actions";

const initState = {
  centers: [],
  initialCenters: false,
  editableCenter: {},
  isNewCenter: false,
  enableEditView: false,
  isModalVisible: false,
  isUpdateModalVisible: false,
  autoCompleteData: [],
  error: false,
  errorInfo: "",
  isLoading: false,
  addRequestSucessful: false,
  updateRequestSuccessful: false,
  toggleRequestSuccessful: false,
};

export default function centerReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_CENTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LIST_CENTER:
      return {
        ...state,
        centers: action.centers,
        initialCenters: true,
        enableEditView: false,
        isLoading: false,
      };
    case actions.CENTER_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };
    case actions.ADD_CENTER:
      return {
        ...state,
        addRequestSucessful: false,
        isLoading: true,
      };
    case actions.ADD_CENTER_SUCCESSFUL:
      return {
        ...state,
        addRequestSucessful: true,
        isLoading: false,
      };
    case actions.UPDATE_CENTER:
      return {
        ...state,
        isLoading: true,
        updateRequestSuccessful: false,
      };
    case actions.UPDATE_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateRequestSuccessful: true,
      };
    case actions.TOGGLE_CENTER_DATA:
      return {
        ...state,
        isLoading: true,
        toggleRequestSuccessful: false,
      };
    case actions.TOGGLE_CENTER_DATA_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        toggleRequestSuccessful: true,
      };
    default:
      return state;
  }
}
