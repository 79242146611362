import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import FilterAction from "../Utility/FilterAction";
const superagent = require("superagent");

function signupAccount(
  email,
  password,
  countryCode,
  phoneNumber,
  name,
  address,
  schoolName,
  firebase,
  gender
) {
  let userEmail = replaceAll(email, "@", "%40");
  let encodedEmail = replaceAll(userEmail, ".", "%2E");
  let endPointUrl = firebase.endPointUrl;
  let signUpEndPointUrl = endPointUrl + "signup";

  //let localTimezone = firebase.schoolConfig.timezone ? firebase.schoolConfig.timezone : moment.tz.guess()
  console.log("encoded email", encodedEmail);
  let obj = {
    address: address,
    email: email,
    schoolName: schoolName,
    countryCode: countryCode,
    phoneNumber: phoneNumber,
    password: password,
    name: name,
    encodedEmail: encodedEmail,
    timezone: moment.tz.guess(),
    gender: gender,
  };
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(signUpEndPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        console.log("res", res);
        if (res) {
          resolve(res);
        }

        if (err) {
          reject(err);
        }
      });
  });
  return p1;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

export const SignupApi = {
  signupAccount,
};
