import actions from "./actions";
const initState = {
  attendanceLiveRatio: [],
  liveStudentAttd: {},
  liveStaffAttd: {},
  liveStudentAttdChannel: undefined,
  liveStaffAttdChannel: undefined,
  liveRatioClassroom: [],
  liveRatioTeacher: [],
  liveAlert: {},
  fetchAllAlert: [],
  isLoading: false,
  AlertOperationType: "",
  updateAlertSuccessful: false,
  error: false,
  fetchAlertChannel: undefined,
};
export default function staffToStudentAttendanceLiveRatioReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.GET_LIVE_ATTENDANCE_RATIO:
      return {
        ...state,
      };

    case actions.GET_LIVE_ATTENDANCE_RATIO_SUCCESSFUL:
      return {
        ...state,
      };

    case actions.GET_STUDENT_LIVE_ATTD_SUCCESS:
      return {
        ...state,
        liveStudentAttd: action.liveStudentAttd,
        liveStudentAttdChannel: action.liveStudentAttdChannel,
        isloading: true,
      };
    case actions.GET_STAFF_LIVE_ATTD_SUCCESS:
      return {
        ...state,
        liveStaffAttd: action.liveStaffAttd,
        liveStaffAttdChannel: action.liveStaffAttdChannel,
      };
    case actions.GET_LIVE_RATIO_CLASSROOM:
      return {
        ...state,
      };
    case actions.GET_LIVE_RATIO_CLASSROOM_SUCCESSFULL:
      return {
        ...state,

        liveRatioClassroom: action.liveRatioClassroom,
      };
    case actions.GET_LIVE_RATIO_TEACHER:
      return {
        ...state,
      };
    case actions.GET_LIVE_RATIO_TEACHER_SUCESS:
      return {
        ...state,

        liveRatioTeacher: action.liveRatioTeacher,
      };
    case actions.GET_ALERT:
      return {
        ...state,
      };
    case actions.GET_ALERT_SUCCESS:
      return {
        ...state,
        liveAlert: action.liveAlert,
      };
    case actions.FETCH_ALERT:
      return {
        ...state,
      };
    case actions.FETCH_ALERT_SUCCESS:
      return {
        ...state,
        fetchAllAlert: action.fetchAllAlert,
        fetchAlertChannel: action.fetchAlertChannel,
        isloading: true,
      };
    case actions.DELETE_SELECTED_ALERT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_SELECTED_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AlertOperationType: "DELETE_ALERT",
      };
    case actions.UPDATE_ALERT:
      return {
        ...state,
        isLoading: true,
        updateAlertSuccessful: false,
        AlertOperationType: "",
      };
    case actions.UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateAlertSuccessful: true,
        AlertOperationType: action.AlertOperationType,
      };
    case actions.RESET_ALERT_OPERATION:
      return {
        ...state,
        isLoading: false,
        error: false,
        AlertOperationType: undefined,
      };

    default:
      return state;
  }
}
