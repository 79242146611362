import actions from "./actions";
const initState = {
  studentTimeline: [],
  studentVirtualClass: [],
  isLoading: false,
  timelineChannel: undefined,
  error: false,
  errorMessage: undefined,
  virtualClassChannel: undefined,
  studentProfile: {},
  previousTimelineMessage: undefined,
  activityComments: new Map(),
  bookableMeetings: [],
  studentTimelineOperation: undefined,
  studentVirtualClasses: [],
  schoolSettingActivities: [],
  bookingReferences: [],
  studentOnlineClasses: [],
  bookingReferenceMap: new Map(),
  studentHolidays: [],
  likesCount: new Map(),
  teachers: new Map(),
  getStudentLoader: false,
};
export default function studentTimelineReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_STUDENT_TIMELINE:
      return {
        ...state,
        isLoading: true,
        studentTimeline: [],
        previousTimelineMessage: undefined,
      };

    case actions.CHANGE_LIKE_STATUS:
      return {
        ...state,
      };

    case actions.GET_STUDENT_TIMELINE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        studentTimeline: action.studentTimeline,
        timelineChannel: action.timelineChannel,
        previousTimelineMessage: action.previousTimelineMessage,
      };

    case actions.GET_STUDENT_TIMELINE_BY_ID:
      return {
        ...state,
        isLoading: true,
        studentTimeline: [],
        previousTimelineMessage: undefined,
      };

    case actions.GET_PREVIOUS_TIMELINE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_PREVIOUS_TIMELINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentTimeline: action.studentTimeline,
        previousTimelineMessage: action.previousTimelineMessage,
      };

    case actions.GET_STUDENT_VIRTUAL_CLASS:
      return {
        ...state,
      };
    case actions.GET_STUDENT_VIRTUAL_CLASS_SUCCESSFUL:
      return {
        ...state,
        studentVirtualClass: action.studentVirtualClass,
        virtualClassChannel: action.virtualClassChannel,
      };

    case actions.GET_STUDENT_DETAIL:
      return {
        ...state,
        getStudentLoader: true,
      };
    case actions.GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        teachers: action.map,
        studentProfile: action.studentProfile,
        studentTimelineOperation: action.studentTimelineOperation,
        getStudentLoader: false,
      };

    case actions.GET_ACTIVITY_COMMENTS_SUCCESS:
      return {
        ...state,
        activityComments: action.activityComments,
      };

    case actions.GET_ACTIVITY_LIKES_SUCCESS:
      return {
        ...state,
        likesCount: action.likesCount,
      };

    case actions.GET_BOOKABLE_MEETINGS:
      return {
        ...state,
        isLoading: true,
        bookableMeetings: [],
      };
    case actions.GET_BOOKABLE_MEETINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookableMeetings: action.bookableMeetings,
        bookingReferences: action.bookingReferences,
        bookingReferenceMap: action.bookingReferenceMap,
      };

    case actions.UPDATE_BOOKING_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_BOOKING_STATUS_SUCCESS:
      return {
        ...state,
        studentTimelineOperation: "REFRESH",
        isLoading: false,
      };

    case actions.RESET_STUDENT_TIMELINE_OPERATION:
      return {
        ...state,
        studentTimelineOperation: undefined,
        isLoading: false,
        error: false,
        errorMessage: undefined,
      };

    case actions.UPDATE_JOINED_MEETING:
      return {
        ...state,
      };

    case actions.GET_STUDENT_VIRTUAL_CLASS_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_VIRTUAL_CLASS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentVirtualClass: action.studentVirtualClass,
      };

    case actions.GET_SCHOOL_SETTING_ACTIVITIES:
      return {
        ...state,
      };
    case actions.GET_SCHOOL_SETTING_ACTIVITIES_SUCCESS:
      return {
        ...state,
        schoolSettingActivities: action.schoolSettingActivities,
      };

    case actions.GET_ONLINE_CLASS_REMINDER:
      return {
        ...state,
        // isLoading: true,
      };
    case actions.GET_ONLINE_CLASS_REMINDER_SUCCESS:
      return {
        ...state,
        // isLoading: false,
        studentOnlineClasses: action.studentOnlineClasses,
      };

    case actions.TIMELINE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };

    case actions.UPLOAD_PROFILE_PIC:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_STUDENT_HOLIDAYS:
      return {
        ...state,
      };
    case actions.GET_STUDENT_HOLIDAYS_SUCCESS:
      return {
        ...state,
        studentHolidays: action.studentHolidays,
      };

    default:
      return state;
  }
}
