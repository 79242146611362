import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { ActivityApi } from "../../firestore-api/activity";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ApplicationApi } from "../../firestore-api/application";
import { leadFormsApi } from "../../firestore-api/leadForms";
import { LeadsApi } from "../../firestore-api/leads";
import actions from "./actions";

function* fetchLeadsTableData({ firebase }) {
  try {
    let data = yield call(leadFormsApi.getTableData, firebase);
    yield put({
      type: actions.GET_LEADS_TABLE_DATA_SUCCESSFUL,
      leadsTableData: data,
    });
  } catch (err) {
    console.log("failed to fetch leadForm", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAutomationTableData({ firebase }) {
  const chan = yield call(leadFormsApi.getAutomationData, firebase);
  try {
    while (true) {
      let detail = yield take(chan);
      yield put({
        type: actions.GET_AUTOMATION_TABLE_DATA_SUCCESS,
        automationTableData: detail,
        automationTableChan: chan,
      });
    }
  } finally {
  }
}

function* getAllSteps({ firebase }) {
  try {
    let data = yield call(leadFormsApi.fetchStepsData, firebase);
    yield put({
      type: actions.GET_ALL_STEPS_SUCCESS,
      allSteps: data,
    });
  } catch (err) {
    console.log("failed to fetch automation steps", err);
    bugsnagClient.notify(err);
  }
}

function* updateAppSetting({ values, record, firebase }) {
  try {
    record.leadStatus = values.leadStatus ? values.leadStatus : null;
    record.status = values.status;
    record.teacherIds = values.teachers ? values.teachers : null;
    record.alwaysAvailable = values.alwaysAvailable ? values.alwaysAvailable : false;
    record.updatedBy = firebase.teacher.name;
    record.platform = "web";
    record.updatedOn = moment().valueOf();

    yield call(leadFormsApi.updateLeadForm, record, firebase);
    notification("success", formatMsg("success.updateLeadFormSetting"));
    yield put({
      type: actions.UPDATE_LEAD_FORM_SETTING_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update lead form setting", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_FORM_ERROR,
    });
  }
}

function* deleteLeadRecord({ record, firebase }) {
  try {
    record.deleted = true;
    record.updatedBy = firebase.teacher.name;
    record.platform = "web";
    record.updatedOn = moment().valueOf();

    let url = "woodlandApi/deleteLeadsForm/" + record.id + "?centerId=";

    let response = yield call(ApplicationApi.deleteApi, record, url, firebase);
    if (response && response.status === 200) {
      // yield call(leadFormsApi.updateLeadForm, record, firebase);
      notification("success", formatMsg("success.deleteLeadForm"));
      yield put({
        type: actions.DELETE_LEAD_FORM_SUCCESS,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.message
          ? response.body.message
          : formatMsg("error.deleteForm")
      );
    }
  } catch (err) {
    console.log("failed to fetch leadForm", err);
    bugsnagClient.notify(err);
  }
}

function* fetchLeadStatus({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsStatus, firebase);
    if (data) {
      yield put({
        type: actions.GET_LEAD_STATUS_SUCCESS,
        leadStatusList: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch lead status", err);
    bugsnagClient.notify(err);
  }
}

function* fetchActionDetails({ actionId, firebase }) {
  try {
    var data = yield call(leadFormsApi.getActionData, actionId, firebase);

    if (data) {
      yield put({
        type: actions.GET_ACTION_DETAIL_SUCCESS,
        actionDetails: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch action details", err);
    bugsnagClient.notify(err);
  }
}

function* saveLeadsAutomation({ values, firebase }) {
  try {
    values.updatedBy = firebase.teacher.name;
    values.platform = "web";
    values.createdOn = values.createdOn ? values.createdOn : moment().valueOf();
    values.status = values.status ? values.status : "inactive";
    values.actions = [];

    var refId = yield call(leadFormsApi.generateUniqueAutomationId, firebase);

    values.id = values.id ? values.id : refId;

    yield call(leadFormsApi.createLeadAutomation, values, values.id, firebase);
    notification("success", formatMsg("success.leadCreation"));
    yield put({
      type: actions.SAVE_LEAD_AUTOMATION_SUCCESS,
      newRuleRedirect: true,
      ruleRefId: refId,
    });
  } catch (err) {
    console.log("failed to create lead automation", err);
    bugsnagClient.notify(err);
  }
}

function* saveActionDetails({ actionId, values, firebase, prevAttachments }) {
  try {
    var refId = null;
    var storagePath = undefined;
    var attachmentUrl;
    let attachmentArray = [];
    if (values.attachment) {

      let file = { fileList: values.attachment };

      attachmentUrl = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        file,
        firebase
      );


      attachmentUrl?.forEach((item) => {
        let attachmentObj = {};
        attachmentObj.url = item.path;
        attachmentObj.fileName = item.fileName;
        attachmentObj.type = item.type === "file" ? "application/pdf" : item.type === "video" ? "video" : "image",
          attachmentArray.push(attachmentObj);
      });

    }
    if (prevAttachments?.length > 0) {
      for (let index in prevAttachments) {
        attachmentArray.push(prevAttachments[index]);
      }
    }
    if (!actionId) {

      values.attachment = attachmentArray.length > 0 ? attachmentArray : null;
      values.time = moment(values.time).valueOf();
      values.updatedBy = firebase.teacher.name;
      values.platform = "web";
      values.createdOn = moment().valueOf();
      values.status = "active";
      values.label = values.label ? values.label : null;
      values.teacher = values.teacher ? values.teacher : null;



      refId = yield call(leadFormsApi.generateUniqueActionId, firebase);

      values.id = actionId ? actionId : refId;
    } else {
      values.attachment = attachmentArray.length > 0 ? attachmentArray : null;
      values.time = moment(values.time).valueOf();
      values.updatedBy = firebase.teacher.name;
      values.platform = "web";
      values.updatedOn = moment().valueOf();
    }

    yield call(leadFormsApi.createAction, values, refId, actionId, firebase);
    notification("success", formatMsg("success.saveLead"));
    yield put({
      type: actions.SAVE_ACTION_DETAILS_SUCCESS,
      newActionRedirect: true,
    });
  } catch (err) {
    console.log("failed to save lead action", err);
    notification("error", formatMsg("error.addAction"));
    bugsnagClient.notify(err);
  }
}

function* fetchRuleData({ ruleId, firebase }) {
  const chan = yield call(leadFormsApi.getRuleDetails, ruleId, firebase);
  try {
    while (true) {
      let detail = yield take(chan);
      yield put({
        type: actions.GET_RULE_DATA_SUCCESS,
        ruleDetails: detail,
        ruleDetailChan: chan,
      });
    }
  } finally {
  }
}

function* fetchRuleActions({ ruleId, firebase }) {
  const chan = yield call(leadFormsApi.getRuleActionDetails, ruleId, firebase);
  try {
    while (true) {
      let detail = yield take(chan);

      yield put({
        type: actions.GET_RULE_ACTIONS_SUCCESS,
        ruleActions: detail,
        ruleActionsChan: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch rule details", err);
    bugsnagClient.notify(err);
  }
}

function* changeAutomationRuleDetails({ key, value, id, firebase }) {
  try {
    if (key.toLowerCase() === "delete") {
      if (value.toLowerCase() === "rule") {
        yield call(leadFormsApi.deleteAutomationRule, id, firebase);
        notification("success", formatMsg("ruleDeleted"));
      }
      if (value.toLowerCase() === "action") {
        yield call(leadFormsApi.deleteAutomationAction, id, firebase);
        notification("success", formatMsg("deleteAction"));
      }
    } else {
      yield call(leadFormsApi.updateAutomationRule, id, key, value, firebase);
      notification("success", formatMsg("updateRule"));
    }
    yield put({
      type: actions.CHANGE_RULE_DETAILS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update rule", err);
    notification("error", formatMsg("error.updateRule"));
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_LEADS_TABLE_DATA, fetchLeadsTableData),
    yield takeLatest(actions.GET_AUTOMATION_TABLE_DATA, fetchAutomationTableData),
    yield takeLatest(actions.UPDATE_LEAD_FORM_SETTING, updateAppSetting),
    yield takeLatest(actions.DELETE_LEAD_FORM, deleteLeadRecord),
    yield takeLatest(actions.GET_LEAD_STATUS, fetchLeadStatus),
    yield takeLatest(actions.SAVE_LEAD_AUTOMATION, saveLeadsAutomation),
    yield takeLatest(actions.SAVE_ACTION_DETAILS, saveActionDetails),
    yield takeLatest(actions.GET_RULE_DATA, fetchRuleData),
    yield takeLatest(actions.GET_RULE_ACTIONS, fetchRuleActions),
    yield takeLatest(actions.GET_ACTION_DETAIL, fetchActionDetails),
    yield takeLatest(actions.CHANGE_RULE_DETAILS, changeAutomationRuleDetails),
    yield takeLatest(actions.GET_ALL_STEPS, getAllSteps),
  ]);
}
