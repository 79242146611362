import englishLang from "../../image/flag/uk.svg";
import chineseLang from "../../image/flag/china.svg";
import spanishLang from "../../image/flag/spain.svg";
import frenchLang from "../../image/flag/france.svg";
import italianLang from "../../image/flag/italy.svg";

import { setItem, getItem, clear, removeItem } from "../../Utility/encryptedStorage";

import FilterAction from "../../Utility/FilterAction";

const config = {
  defaultLanguage: FilterAction.getDefaultLanguage(),

  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
    },
    {
      languageId: "arabic",
      locale: "ar",
      text: "Arabic",
      icon: englishLang,
    },
    {
      languageId: "chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang,
    },
    {
      languageId: "spanish",
      locale: "es",
      text: "Spanish",
      icon: spanishLang,
    },
    {
      languageId: "french",
      locale: "fr",
      text: "French",
      icon: frenchLang,
    },
    {
      languageId: "italian",
      locale: "it",
      text: "Italian",
      icon: italianLang,
    },
    {
      languageId: "portuguese",
      locale: "pt",
      text: "Portuguese",
      icon: italianLang,
    },
    {
      languageId: "hungarian",
      locale: "hu",
      text: "Hungarian",
      icon: italianLang,
    },
    {
      languageId: "russian",
      locale: "ru",
      text: "Russian",
      icon: italianLang,
    },
    {
      languageId: "german",
      locale: "de",
      text: "German",
      icon: italianLang,
    },
    {
      languageId: "lithuanian",
      locale: "lt",
      text: "Lithuanian",
      icon: englishLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
