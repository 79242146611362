import actions from "./actions";

const initState = {
    isLoading: false,
    programList: [],
    operationType: undefined,
    feePlanList: [],
    feePlanListChannel: undefined,
    discountList: [],
    discountMap: new Map(),
    taxList: [],
    taxMap: new Map(),
    activeFeePlanMapByStudent: new Map(),
    feeComponentList: [],
    discountData: [],
    discountChannel: undefined,
    errorMsg: undefined,
    isDiscountBeingUsed: undefined,
    isTaxBeingUsed: undefined,
    taxData: [],
    taxChannel: undefined,
    feeComponentListData: [],
    feeComponentChannel: undefined,
    isFeeComponentBeingUsed: undefined,
    fetchedFeePlan: null,
    refetchAssignStudent: undefined,
    fetchedGeneratedInvoices: [],
    fetchedUpcomingInvoices: [],
    feePlanLoading: false,
    fetchedGeneratedInvoicesChannel: undefined,
    lineItemListByFeePlan: [],
    schoolPDFStyle: undefined,
    feePlanStyle: undefined
};

const newFeePlan = (state = initState, action) => {
    switch (action.type) {
        // Ajit Start
        case actions.GET_DISCOUNT_DATA:
            return {
                ...state,
                isLoading: true,
            }
        case actions.GET_DISCOUNT_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                discountData: action.discountData,
                discountChannel: action.discountChannel
            }
        case actions.ADD_DISCOUNT_DATA:
            return {
                ...state,
                isLoading: true
            }
        case actions.ADD_DISCOUNT_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case actions.CHECK_IF_DISCOUNT_IS_BEING_USED:
            return {
                ...state,
                isLoading: true
            }
        case actions.CHECK_IF_DISCOUNT_IS_BEING_USED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDiscountBeingUsed: action.isDiscountBeingUsed,
            }
        case actions.RESET_DISCOUNT_CHECK:
            return {
                ...state,
                isDiscountBeingUsed: undefined,
            }
        case actions.DELETE_DISCOUNT:
            return {
                ...state,
                isLoading: true,
            }
        case actions.DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case actions.GET_TAX_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case actions.GET_TAX_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                taxData: action.taxData,
                taxChannel: action.taxChannel,
            };

        case actions.ADD_TAX_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case actions.ADD_TAX_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actions.CHECK_IF_TAX_IS_BEING_USED:
            return {
                ...state,
                isLoading: true,
            };
        case actions.CHECK_IF_TAX_IS_BEING_USED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isTaxBeingUsed: action.isTaxBeingUsed,
            };
        case actions.RESET_TAX_CHECK:
            return {
                ...state,
                isTaxBeingUsed: undefined,
            };

        case actions.DELETE_TAX:
            return {
                ...state,
                isLoading: true,
            };
        case actions.DELETE_TAX_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actions.GET_FEE_COMPONENT_LIST_DATA:
            return {
                ...state,
                isLoading: true,
            }
        case actions.GET_FEE_COMPONENT_LIST_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                feeComponentListData: action.feeComponentList,
                feeComponentChannel: action.feeComponentChannel
            }
        case actions.ADD_NEW_FEE_COMPONENT:
            return {
                ...state,
                isLoading: true
            }
        case actions.ADD_NEW_FEE_COMPONENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case actions.DELETE_NEW_FEE_COMPONENT:
            return {
                ...state,
                isLoading: true,
            };
        case actions.DELETE_NEW_FEE_COMPONENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actions.CHECK_IF_FEE_COMPONENT_IS_BEING_USED:
            return {
                ...state,
                isLoading: true,
            };
        case actions.CHECK_IF_FEE_COMPONENT_IS_BEING_USED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFeeComponentBeingUsed: action.isFeeComponentBeingUsed,
            };
        case actions.RESET_FEE_COMPONENT_CHECK:
            return {
                ...state,
                isFeeComponentBeingUsed: undefined,
            };

        case actions.FETCH_NEW_FEE_PLAN_WITH_ID:
            return {
                ...state,
                isLoading: true,
                feePlanLoading: true,
            }
        case actions.FETCH_NEW_FEE_PLAN_WITH_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchedFeePlan: action.data,
                refetchAssignStudent: undefined,
                operationType: "FETCH_NEW_FEE_PLAN_WITH_ID_SUCCESS",
                feePlanLoading: false
            }

        case actions.CHANGE_ASSIGN_STUDENT_STATUS:
            return {
                ...state,
                isLoading: true,
            }
        case actions.CHANGE_ASSIGN_STUDENT_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                refetchAssignStudent: true
            }
        case actions.DELETE_FEE_PLAN_ASSIGN_STUDENT:
            return {
                ...state,
                isLoading: true,
            }
        case actions.DELETE_FEE_PLAN_ASSIGN_STUDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                refetchAssignStudent: true
            }

        case actions.FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID:
            return {
                ...state,
                isLoading: true,
            }
        case actions.FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchedGeneratedInvoices: action.fetchedGeneratedInvoices,
                fetchedGeneratedInvoicesChannel: action.fetchedGeneratedInvoicesChannel,
            }

        case actions.FETCH_PLAN_UPCOMING_INVOICES:
            return {
                ...state,
                isLoading: true,
            }

        case actions.FETCH_PLAN_UPCOMING_INVOICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchedUpcomingInvoices: action.invoices ? action.invoices : [],
                operationType: "FETCH_PLAN_UPCOMING_INVOICES_SUCCESS"
            }

        case actions.SAVE_SCHOOL_INVOICE_PDF_STYLE:
            return {
                ...state,
                isLoading: true,
            }
        case actions.SAVE_SCHOOL_INVOICE_PDF_STYLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case actions.GET_SCHOOL_INVOICE_PDF_STYLE:
            return {
                ...state,
                isLoading: true
            }

        case actions.GET_SCHOOL_INVOICE_PDF_STYLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                schoolPDFStyle: action.schoolPDFStyle,
            }

        case actions.SAVE_FEEPLAN_INVOICE_PDF_STYLE:
            return {
                ...state,
                isLoading: true,
            }
        case actions.SAVE_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case actions.GET_FEEPLAN_INVOICE_PDF_STYLE:
            return {
                ...state,
                isLoading: true
            }

        case actions.GET_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                feePlanStyle: action.feePlanStyle,
            }



























































        // Ajit end
        // Sagar Start
        case actions.GET_PROGRAMS_SUCCESS:
            return {
                ...state,
                programList: action.programList
            }
        case actions.GET_LABELS_SUCCESS:
            return {
                ...state,
                labelList: action.labelList
            }
        case actions.GET_FEE_PLAN_DISCOUNTS_SUCCESS:
            return {
                ...state,
                discountList: action.discountList,
                discountMap: action.discountMap
            }
        case actions.GET_FEE_PLAN_TAXES_SUCCESS:
            return {
                ...state,
                taxList: action.taxList,
                taxMap: action.taxMap
            }
        case actions.SAVE_FEE_PLAN:
            return {
                ...state,
                isLoading: true,
            }
        case actions.SAVE_FEE_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "SAVE_FEE_PLAN_SUCCESS"
            }
        case actions.GET_FEE_PLAN_LIST:
            return {
                ...state,
                isLoading: true,
            }
        case actions.GET_FEE_PLAN_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                feePlanList: action.feePlanList,
                feePlanListChannel: action.feePlanListChannel,
                operationType: "GET_FEE_PLAN_LIST_SUCCESS"
            }
        case actions.GET_FEE_COMPONENT_LIST_SUCCESS:
            return {
                ...state,
                feeComponentList: action.feeComponentList
            }
        case actions.DELETE_SELECTED_FEE_PLAN:
            return {
                ...state,
                isLoading: true,
            }
        case actions.DELETE_SELECTED_FEE_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "DELETE_SELECTED_FEE_PLAN_SUCCESS"
            }
        case actions.PAUSE_OR_ACTIVE_FEE_PLAN:
            return {
                ...state,
                isLoading: true,
            }
        case actions.PAUSE_OR_ACTIVE_FEE_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "PAUSE_OR_ACTIVE_FEE_PLAN_SUCCESS"
            }
        case actions.UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN:
            return {
                ...state,
                isLoading: true
            }
        case actions.UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN_SUCCESS"
            }
        case actions.UPDATE_ONLY_ONE_ASSIGN_STUDENT:
            return {
                ...state,
                isLoading: true
            }
        case actions.UPDATE_ONLY_ONE_ASSIGN_STUDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                operationType: "UPDATE_ONLY_ONE_ASSIGN_STUDENT_SUCCESS"
            }
        case actions.GET_LINE_ITEMS_BY_FEE_PLAN_ID:
            return {
                ...state,
                isLoading: true
            }
        case actions.GET_LINE_ITEMS_BY_FEE_PLAN_ID_SUCCESS:
            return {
                ...state,
                lineItemListByFeePlan: action.lineItemListByFeePlan,
                isLoading: false,
                operationType: "GET_LINE_ITEMS_BY_FEE_PLAN_ID_SUCCESS"
            }
        case actions.RESET_OPERATION_TYPE:
            return {
                ...state,
                operationType: undefined
            }
        case actions.FEE_PLAN_API_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                operationType: undefined,
                isDiscountBeingUsed: undefined,
                isTaxBeingUsed: undefined,
                refetchAssignStudent: undefined,
                fetchedFeePlan: null,
                refetchAssignStudent: undefined,
                feePlanLoading: false,
            }
        case actions.RESET_FEE_PLAN_REDUCER_STATE:
            return {
                ...initState
            }



























































        // Sagar End

        default:
            return state;
    }
};
export default newFeePlan;