import React, { Component } from "react";
import { Form, DatePicker, Modal, Select } from "antd";
import moment from "moment";
import colors from "../../Utility/colorFactory";
import { Fieldset, Label } from "../../settings/myComponent.style";
import IntlMessages from "../../components/utility/intlMessages";
import formatMsg from "../../components/utility/formatMessageUtil";
import FilterAction from "../../Utility/FilterAction";

const Option = Select.Option;
const { RangePicker } = DatePicker;
const NextFollowUpModal = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      const { form, isModalVisible, editablenextFollowUp, handleCancel, saveNextFollowUp } =
        this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          title={"Set Next Follow Up Date"}
          visible={isModalVisible}
          onOk={saveNextFollowUp}
          onCancel={handleCancel}
          okText={formatMsg("submit")}
          cancelText={formatMsg("cancel")}
        >
          <Form.Item label={<span>{formatMsg("studentLesson.dueDate")}</span>}>
            {getFieldDecorator("nextFollowUpDate", {
               initialValue: editablenextFollowUp && editablenextFollowUp.nextFollowUpDate ? moment(editablenextFollowUp.nextFollowUpDate) :null,
              rules: [
                {
                  required: true,
                  message: formatMsg("message.selectDate"),
                },
              ],
            })(
              <DatePicker
                style={{
                  width: "100%",
                }}
                getCalendarContainer={(trigger) => trigger.parentNode}
                disabledDate={(d) => d.isBefore(moment().startOf("day"))}
                format={FilterAction.getDateFormat(this.props.firebase)}
              />
            )}
          </Form.Item>
        </Modal>
      );
    }
  }
);
export default NextFollowUpModal;
