import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";


function generateUniqueLeavesId(isStaff, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  if (isStaff) {
    var key = rsf.ref(branchPath + "/staffLeaveReference").push().key;
    return key;
  } else {
    var key = rsf.ref(branchPath + "/leaves").push().key;
    return key;
  }
}

function fetchSelectedLeaveDetails(leaveRefId, isStaff, firebase) {
  var x = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";

  if (isStaff) {
    myRef = rsf.ref(branchPath + "/staffLeaveReference/" + leaveRefId);
  } else {
    myRef = rsf.ref(branchPath + "/leaves/" + leaveRefId);
  }

  var leaveDetailPromise = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        x = snap.val();
      }
      resolve(x);
    });
  });
  return leaveDetailPromise;
}

function submitNewStaffLeave(values, startDate, endDate, selectedStaff, leaveRefId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/staffLeaveReference/" + leaveRefId);

  var leaveValues = {
    appliedDate: moment().valueOf(),
    classlist: selectedStaff.classList,
    createdBy: firebase.teacher.name,
    endDate: moment(endDate).endOf("day").valueOf(),
    id: leaveRefId,
    includeSaturday: false,
    leaveType: values.leaveType,
    mediaType: null,
    platform: "web",
    reason: values.reason,
    reopened: false,
    repeat: false,
    repeatEndDate: 0,
    repeatStartDate: 0,
    staff: true,
    startDate: moment(startDate).startOf("day").valueOf(),
    status: "pending",
    student: selectedStaff.name,
    studentId: selectedStaff.id,
    type: "leave",
    uploadStatus: "",
  };

  myRef.set(leaveValues);
}

function submitNewStudentLeave(values, startDate, endDate, selectedStudent, leaveRefId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leaves/" + leaveRefId);

  var leaveValues = {
    appliedDate: moment().valueOf(),
    classRoom: selectedStudent.classList[0],
    createdBy: firebase.teacher.name,
    endDate: moment(endDate).endOf("day").valueOf(),
    id: leaveRefId,
    includeSaturday: false,
    leaveType: values.leaveType,
    mediaType: null,
    motherId: selectedStudent.motherProfileId ? selectedStudent.motherProfileId : null,
    fatherId: selectedStudent.motherProfileId
      ? null
      : selectedStudent.fatherProfileId
      ? selectedStudent.fatherProfileId
      : null,
    platform: "web",
    reason: values.reason,
    reopened: false,
    repeat: false,
    repeatEndDate: 0,
    repeatStartDate: 0,
    staff: false,
    startDate: moment(startDate).startOf("day").valueOf(),
    status: "pending",
    student: selectedStudent.name,
    studentId: selectedStudent.id,
    type: "leave",
    uploadStatus: "",
  };

  myRef.set(leaveValues);
}

function updateSelectedLeave(values, startDate, endDate, leaveRefId, isStaff, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";

  if (isStaff) {
    myRef = rsf.ref(branchPath + "/staffLeaveReference/" + leaveRefId);
  } else {
    myRef = rsf.ref(branchPath + "/leaves/" + leaveRefId);
  }

  var leaveValues = {
    endDate: moment(endDate).endOf("day").valueOf(),
    leaveType: values.leaveType,
    reason: values.reason,
    startDate: moment(startDate).startOf("day").valueOf(),
    status: "pending",
  };

  myRef.update(leaveValues);
}

function deleteLeave(item, firebase, isStaff) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";
  if (isStaff) {
    myRef = rsf.ref(branchPath + "/staffLeaveReference/" + item.id);
  } else {
    myRef = rsf.ref(branchPath + "/leaves/" + item.id);
  }

  myRef.set(null);
}

export const LeavesApi = {
  submitNewStaffLeave,
  submitNewStudentLeave,
  generateUniqueLeavesId,
  fetchSelectedLeaveDetails,

  updateSelectedLeave,
  deleteLeave,
};
