import { IntlProvider, addLocaleData } from "react-intl";
import { currentAppLocale } from "../../dashApp";

const locale = currentAppLocale.locale; //my own methods to retrieve locale
const messages = currentAppLocale.messages; //getting messages from the json file.
const intlProvider = new IntlProvider({
  locale,
  messages,
});
const { intl } = intlProvider.getChildContext();

const formatMsg = (key) => {
  return intl.formatMessage({
    id: `${key}`,
  });
};

export default formatMsg;
