import addIcon from "../../image/addIcon.png";
import appFormIcon from "../../image/appFormIcon.png";
import virtualClass from "../../image/sidebar-virtualClass.png";
import attendance from "../../image/sidebar_attendance.png";
import billing from "../../image/sidebar_billing.png";
import tools from "../../image/sidebar_tools.png";
import calendar from "../../image/sidebar_calendar.png";
import crm from "../../image/sidebar_crm.png";
import dashboardIcon from "../../image/sidebar_dashboard_1.png";
import learning from "../../image/sidebar_learning.png";
import management from "../../image/sidebar_management.png";
import parentCommunication from "../../image/sidebar_parentCommunication.png";
import template from "../../image/template.png";
import staffCommunication from "../../image/v2_people_icon.png";
import support from "../../image/v2_support.png";
import newAssessment from "../../image/newAssessment.png";

const options = [
  {
    key: "",
    label: "sidebar.dashboard",
    leftImage: dashboardIcon,
  },
  {
    key: "management",
    label: "sidebar.management",
    leftImage: management,
    children: [
      {
        key: "students",
        label: "sidebar.students",
      },
      {
        key: "staff",
        label: "sidebar.staff",
      },
      {
        key: "rooms",
        label: "sidebar.rooms",
      },
      {
        key: "program",
        label: "sidebar.program",
      },

      {
        key: "fteReport",
        label: "sidebar.fteReport",
        leftImage: dashboardIcon,
      },
      {
        key: "tags",
        label: "sidebar.tags",
      },
      {
        key: "customLabels",
        label: "sidebar.customLabels",
      },
      {
        key: "emailTemplates",
        label: "sidebar.emailTemplates",
      },
      {
        key: "upcomingBirthday",
        label: "sidebar.birthDayCalendar",
      },

    ],
  },
  {
    key: "attendance",
    label: "sidebar.attendance",
    leftImage: attendance,
    children: [
      {
        key: "studentAttendance",
        label: "sidebar.studentAttendance",
      },
      {
        key: "studentProgram",
        label: "sidebar.programForecast",
        leftImage: dashboardIcon,
      },
      {
        key: "staffAttendance",
        label: "sidebar.staffAttendance",
      },
      {
        key: "leaves",
        label: "sidebar.studentLeaves",
      },
      {
        key: "staffLeaves",
        label: "sidebar.staffLeaves",
      },
    ],
  },
  {
    key: "attendanceRecord",
    label: "sidebar.attendance",
    leftImage: attendance,
    children: [
      {
        key: "studentAttendanceRecord",
        label: "sidebar.studentAttendance",
      },
      {
        key: "staffAttendanceRecord",
        label: "sidebar.staffAttendance",
      },
      {
        key: "studentProgram",
        label: "sidebar.programForecast",
        leftImage: dashboardIcon,
      },
      {
        key: "studentTostafftAttendanceRatio",
        label: "sidebar.studentTostafftAttendanceRatio",
      },
      {
        key: "leaves",
        label: "sidebar.studentLeaves",
      },
      {
        key: "staffLeaves",
        label: "sidebar.staffLeaves",
      },
    ],
  },
  {
    key: "connect",
    label: "sidebar.parentCommunication",
    leftImage: parentCommunication,
    children: [
      {
        key: "activities",
        label: "sidebar.activities",
      },
      {
        key: "studentLevelView",
        label: "sidebar.studentLevelView",
      },

      {
        key: "messages",
        label: "sidebar.Messages",
      },
      {
        key: "privateMessages",
        label: "sidebar.privateMessages",
      },
      {
        key: "groupMessages",
        label: "sidebar.groupMessages",
      },
      {
        key: "complains",
        label: "sidebar.Concern",
      },

      {
        key: "parentNotes",
        label: "sidebar.ParentNotes",
      },

      {
        key: "newsletter",
        label: "sidebar.draftNewsetter",
        leftImage: template,
      },
      {
        key: "virtualClasses",
        label: "sidebar.virtualClasses",
        leftImage: virtualClass,
      },
    ],
  },
  {
    key: "staffCommunication",
    label: "sidebar.staffCommunication",
    leftImage: staffCommunication,
    children: [
      {
        key: "staffMessages",
        label: "sidebar.staffMessage",
      },
      {
        key: "staffActivities",
        label: "activity.staffAnnouncement",
      },
    ],
  },
  {
    key: "godMode",
    label: "sidebar.godMode",
    leftIcon: "ion-stats-bars",
  },
  {
    key: "fee",
    label: "sidebar.fee",
    leftImage: billing,
    children: [
      {
        key: "invoiceDashboard",
        label: "sidebar.dashboard",
      },
      {
        key: "studentBilling",
        label: "sidebar.studentBilling",
      },
      {
        key: "invoice",
        label: "sidebar.invoice",
      },
      {
        key: "payments",
        label: "sidebar.payments",
      },
      {
        key: "feeStructure",
        label: "sidebar.feeStructure",
      },
      {
        key: "invoiceTemplate",
        label: "sidebar.feeTemplate",
      },
      {
        key: "expenseManagement",
        label: "sidebar.ExpenseManagement",
      },
      {
        key: "feeSetting",
        label: "sidebar.feeSetting",
      },
    ],
  },
  {
    key: "feeplan",
    label: "sidebar.feeplan",
    leftImage: management,
    children: [
      {
        key: "feeplan",
        label: "sidebar.feeplan",
      },
      {
        key: "feePlanDiscount",
        label: "sidebar.discount",
      },
      {
        key: "feePlanTax",
        label: "sidebar.tax",
      },
      {
        key: "feeComponent",
        label: "sidebar.feeComponent",
      },
      {
        key: "feePlanPdf",
        label: "sidebar.newFeePlanPdf"
      }
    ],
  },

  {
    key: "leads",
    label: "sidebar.leads",
    leftImage: crm,
    children: [
      {
        key: "leadDashboard",
        label: "sidebar.dashboard",
      },
      {
        key: "allLeads",
        label: "leads.allLeads",
      },
      {
        key: "allTasks",
        label: "leads.allTasks",
      },
      {
        key: "leadForms",
        label: "leads.leadForms",
      },

      {
        key: "leadSettings",
        label: "leads.leadSettings",
      },
    ],
  },
  {
    key: "calendar",
    label: "sidebar.calendar",
    leftImage: calendar,
    children: [

      {
        key: "staffSchedule",
        label: "sidebar.StaffSchedule"
      },

      {
        key: "schedule",
        label: "sidebar.schedule",
      },
      {
        key: "events",
        label: "sidebar.events",
      },
      {
        key: "holiday",
        label: "sidebar.holiday",
      },
      {
        key: "staffHoliday",
        label: "sidebar.staffHoliday",
      },
    ],
  },

  {
    key: "learning",
    label: "sidebar.learning",
    leftImage: learning,
    children: [
      {
        key: "lessons",
        label: "sidebar.schoolLessons",
      },
      {
        key: "assignments",
        label: "sidebar.assignments",
      },
      {
        key: "submissionStats",
        label: "sidebar.submissions",
      },
      {
        key: "lessonPlans",
        label: "sidebar.weeklyLessonPlan",
      },
      {
        key: "assessments",
        label: "sidebar.studentAssessments",
      },
      {
        key: "milestones",
        label: "sidebar.milestones",
      },
      {
        key: "document",
        label: "sidebar.document",
      },

    ],
  },
  {
    key: "studentAssessments",
    label: "sidebar.studentAssessments",
    leftImage: newAssessment,
    children: [
      {
        key: "assessmentBuilder",
        label: "sidebar.assessmentBuilder",
      },
      {
        key: "assessmentDashboard",
        label: "accessControl.assessment"
      },
      {
        key: "studentObservationTracker",
        label: "student.observation.tracker"
      },
      {
        key: "observationDashboard",
        label: "Records"
      },
      {
        key: "assessmentReports",
        label: "Reports"
      }
    ]
  },

  {
    key: "forms",
    label: "sidebar.forms",
    leftImage: appFormIcon,
    children: [
      {
        key: "applications",
        label: "sidebar.applications",
      },
      {
        key: "teacherApplications",
        label: "sidebar.teacherApplications",
      },
      {
        key: "admissionProcess",
        label: "sidebar.admissionProcess",
        leftImage: addIcon,
      },
    ],
  },
  {
    key: "tools",
    label: "sidebar.tools",
    leftImage: tools,
    labelRightText: "new",
    children: [
      {
        key: "idCards",
        label: "sidebar.idCards",
        childLabelRightText: "new",
      },
      {
        key: "certificates",
        label: "sidebar.certificates",
      },
    ]
  },
  {
    key: "reports",
    label: "sidebar.allReports",
    leftImage: billing,
    children: [
      {
        key: "allReports",
        label: "sidebar.excelReports",
      },
      ,
      {
        key: "installations",
        label: "sidebar.installationReport",
        leftIcon: "ion-android-phone-portrait",
      },
      {
        key: "staffInstallations",
        label: "sidebar.staffInstallationReport",
        leftIcon: "ion-android-phone-portrait",
      },
    ],
  },

  {
    key: "settings",
    label: "sidebar.settings",
    leftIcon: "ion-android-settings",
    children: [
      {
        key: "permissions",
        label: "sidebar.accessControl",
      },
      {
        key: "activitySetting",
        label: "sidebar.activity",
      },
      {
        key: "attendanceSetting",
        label: "sidebar.attendance",
      },
      {
        key: "billingSetting",
        label: "sidebar.billing",
      },
      {
        key: "cctvSetting",
        label: "sidebar.cctv",
      },
      {
        key: "forms",
        label: "sidebar.forms",
      },
      {
        key: "leaveSetting",
        label: "sidebar.leave",
      },
      {
        key: "lessonSetting",
        label: "sidebar.lesson",
      },
      {
        key: "parentHandbookSetting",
        label: "sidebar.parentHandbook",
      },
      {
        key: "roles",
        label: "sidebar.roles"
      },
      {
        key: "schoolDetails",
        label: "sidebar.schoolDetails",
      },
      {
        key: "staffLeaveManagement",
        label: "sidebar.staffLeaveManagement",
      },
      {
        key: "studentImmunization",
        label: "sidebar.studentImmunization",
      },
      {
        key: "otherSettings",
        label: "sidebar.otherSettings",
      },
    ],
  },
  {
    key: "help",
    label: "topbar.help",
    leftIcon: "ion-help",
    children: [
      {
        key: "help",
        label: "sidebar.help",
        leftIcon: "ion-help",
      },
      {
        key: "support",
        label: "sidebar.support",
        leftImage: support,
      },
    ],
  },
  {
    key: "parentMode",
    label: "sidebar.parentMode",
    leftIcon: "ion-android-person",
  },

  {
    key: "virtualLibrary",
    label: "sidebar.virtualLibrary",
    leftIcon: "ion-ios-videocam",
  },
  {
    key: "language",
    label: "activity.language",
    leftIcon: "",
    children: [
      {
        key: "en",
        label: "English",
      },
      {
        key: "ar",
        label: "Arabic",
      },
      {
        key: "es",
        label: "Spanish",
      },
      {
        key: "ru",
        label: "Russian",
      },
      {
        key: "pt",
        label: "Portuguese",
      },
      {
        key: "de",
        label: "German",
      },
      {
        key: "fr",
        label: "French",
      },
      {
        key: "hu",
        label: "Hungarian",
      },
      {
        key: "it",
        label: "Italian",
      },
      {
        key: "lt",
        label: "Lithuanian",
      },
    ],
  },
  // {
  //   key: "customActivity",
  //   label: "sidebar.customActivity",
  //   leftIcon: "ion-home",
  // },

  // {
  //   key: "contactUs",
  //   label: "sidebar.contactUs",
  //   leftIcon: "ion-ios-telephone",
  // },
];
export default options;
