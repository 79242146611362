const actions = {
  RESET_ACTION_REDIRECT: "RESET_ACTION_REDIRECT",
  RESET_ACTION_REDIRECT_SUCCESS: "RESET_ACTION_REDIRECT_SUCCESS",

  GET_FEE_ACTIONS: "GET_FEE_ACTIONS",
  GET_FEE_ACTIONS_SUCCESS: "GET_FEE_ACTIONS_SUCCESS",

  SAVE_FEE_EMAIL_DETAILS: "SAVE_FEE_EMAIL_DETAILS",
  SAVE_FEE_EMAIL_DETAILS_SUCCESS: "SAVE_FEE_EMAIL_DETAILS_SUCCESS",

  CHANGE_FEE_EMAIL_DETAILS: "CHANGE_FEE_EMAIL_DETAILS",
  CHANGE_FEE_EMAIL_DETAILS_SUCCESS: "CHANGE_FEE_EMAIL_DETAILS_SUCCESS",

  GET_ACTION: "GET_ACTION",
  GET_ACTION_SUCCESS: "GET_ACTION_SUCCESS",

  RESET_ACTION_DATA: "RESET_ACTION_DATA",
  RESET_ACTION_DATA_SUCCESS: "RESET_ACTION_DATA_SUCCESS",

  resetActionData: () => ({
    type: actions.RESET_ACTION_DATA,
  }),

  getActionDetail: (actionId, firebase) => ({
    type: actions.GET_ACTION,
    firebase,
    actionId,
  }),

  getFeeActions: (firebase) => ({
    type: actions.GET_FEE_ACTIONS,

    firebase,
  }),

  changeRuleDetails: (key, value, id, firebase, record) => ({
    type: actions.CHANGE_FEE_EMAIL_DETAILS,
    key,
    value,
    id,
    firebase,
    record,
  }),

  saveActionDetails: (values, firebase, emailActionId) => ({
    type: actions.SAVE_FEE_EMAIL_DETAILS,
    values,
    firebase,
    actionId: emailActionId,
  }),

  resetActionRedirect: () => ({
    type: actions.RESET_ACTION_REDIRECT,
  }),
};
export default actions;
