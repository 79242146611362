import React from "react";
import { Button, Icon } from "antd";
import colors from "../../../Utility/colorFactory";
import webFont from "../../../Utility/fontSizes";

const PrefixIconButton = (props) => {
  return (
    <div>
      <Button
        onClick={() => (props.onClick ? props.onClick() : null)}
        size={props.size ? "default" : "large"}
        style={{
          borderRadius: props.borderRadius ? props.borderRadius : 4,
          marginRight: props.marginRight ? props.marginRight : 8,
          marginLeft: props.marginLeft ? props.marginLeft : null,
          fontWeight: props.fontWeight ? props.fontWeight : null,
          fontSize: props.fontSize ? props.fontSize : webFont.medium,
          color: props.fontColor ? props.fontColor : colors.v2_fiord,
          backgroundColor: props.backgroundColor ? props.backgroundColor : colors.white,
          border: props.border ? "1px solid" : "none",
          borderColor: props.borderColor ? props.borderColor : "none",
          paddingLeft: props.paddingLeft ? props.paddingLeft : "none",
          paddingRight: props.paddingRight ? props.paddingRight : "none",
          whiteSpace: props.whiteSpace ? props.whiteSpace : "none",
          height: props.height ? props.height : "none",
          width: props.width ? props.width : "none"
        }}
      >
        <div
          style={{
            ...styles.inline,
            ...{
              textTransform: props.textTransform ? props.textTransform : undefined,
            },
          }}
        >
          {props.imgSrc ? (
            <img
              src={props.imgSrc}
              alt="icon"
              style={
                props.imageStyle
                  ? {
                    ...props.imageStyle,
                    ...{
                      marginLeft: 5,
                    },
                  }
                  : {
                    ...styles.image,
                    ...{
                      marginLeft: 5,
                    },
                  }
              }
            />
          ) : null}

          {props.title}
          {props.icon ? (
            <Icon
              type={props.icon}
              style={{
                marginLeft: props.iconMarginLeft ? props.iconMarginLeft : "2px",
              }}
            />
          ) : null}
        </div>
      </Button>
    </div>
  );
};
export default PrefixIconButton;
const styles = {
  inline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  image: {
    height: 20,
    width: 20,
    marginRight: 8,
    objectFit: "contain",
  },
};
