import React from "react";
import FilterAction from "../../Utility/FilterAction";

export const TeacherDetailView = (props) => {
  return (
    <div style={styles.mainDiv}>
      <div style={styles.avtarDiv}>
        <img
          src={FilterAction.getStudentIcon({ profileImageUrl: props.profileImageUrl ? props.profileImageUrl : "", gender: props.gender })}
          style={styles.statusImageStyle}
          alt="icon"
        />
      </div>
      <div style={styles.dataDiv}>
        <div style={styles.nameDiv}>{props.teacherName}</div>
        <div style={styles.belowText}>{props.totalTime+" | "+props.totalShifts}</div>
      </div>
    </div>
  );
};

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    marginLeft: "20px",
  },
  avtarDiv: {
    alignItems: "center",
  },
  nameDiv: {
    color: "#5B5B5B",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "30px"
  },
  dataDiv: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  statusImageStyle: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    marginRight: 8,
  },
  belowText : {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "27px",
    color: "#00AACC"
  }
};
