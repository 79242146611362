import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { withFirebase } from "../../components/firebase";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import userpic from "../../image/profile-user.svg";
import colors from "../../Utility/colorFactory";
import { getItem } from "../../Utility/encryptedStorage";
import FilterPermission from "../../Utility/FilterPermission";
import webFont from "../../Utility/fontSizes";
import PermissionStrings from "../../Utility/PermissionStrings";
import TopbarDropdownWrapper from "./topbarDropdown.style";
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      redirect: false,
      visible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.refresh();
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  refresh() {
    const { firebase } = this.props;
    let teacher = JSON.parse(getItem("teacher"));
    if (teacher && teacher.id !== firebase.teacher.id) {
      window.location.reload(false);
    }
  }

  logout() {
    var superThis = this;
    this.props.firebase.isLoggedIn = false;
    this.props.firebase.signOut().then(function () {

      superThis.setState({
        redirect: true,
      });
    });
  }

  render() {
    const { firebase } = this.props;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/signin",
          }}
        />
      );
    }
    const content = (
      <TopbarDropdownWrapper
        className="isoUserDropdown"
        onClick={() =>
          this.setState({
            visible: !this.state.visible,
          })
        }
      >
        {firebase.isParent ? null : (
          <div>
            {firebase && firebase.teacher ? (
              <div className="isoDropdownLink" style={styles.divBorder}>
                <p style={styles.userName}>{firebase.teacher.name}</p>
                {firebase.loggedInEmail ? (
                  <p style={styles.loggedUserId}>{firebase.loggedInEmail}</p>
                ) : null}
              </div>
            ) : null}

            {(firebase && firebase.selectedMode !== "parent" && firebase.teacher && firebase.teacher.id) &&
              <div>
                <Link className="isoDropdownLink" 
                  to={{
                    pathname: "/dashboard/teacherDetailedView",
                    search: "?teacherId=" + firebase.teacher.id,
                  }}
                >
                  <IntlMessages id="staff.viewProfile" />
                </Link>
              </div>
            }

            {FilterPermission.checkIfPermission(PermissionStrings.SETTINGS_VIEW_CHANGE, firebase) &&
              firebase.selectedMode !== "parent" ? (
              <div>
                <Link className="isoDropdownLink" to="/dashboard/schoolDetails">
                  <IntlMessages id="themeSwitcher.settings" />
                </Link>
              </div>
            ) : null}

            {FilterPermission.checkIfPermission(PermissionStrings.SM_CREATE, firebase) &&
              firebase.selectedMode !== "parent" ? (
              <Link className="isoDropdownLink" to="/dashboard/onboarding">
                <IntlMessages id="topbar.Onboarding" />
              </Link>
            ) : null}
          </div>
        )}

        <Link className="isoDropdownLink" to="/resetPassword">
          <IntlMessages id="topbar.resetPassword" />
        </Link>

        <a className="isoDropdownLink" onClick={() => this.logout()} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    let profileImg = userpic;
    let externalProfile = false;
    if (firebase && firebase.teacher && firebase.teacher.profileImageUrl) {
      profileImg = firebase.teacher.profileImageUrl;
      externalProfile = true;
    }

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={profileImg} style={externalProfile ? styles.bordered : null} />
          <span
            style={{
              color: colors.v2_fiord,
            }}
            className="userActivity online"
          />
        </div>
      </Popover>
    );
  }
}
export default withFirebase(TopbarUser);

const styles = {
  bordered: {
    borderRadius: "20%",
    width: "55px",
  },
  userName: {
    margin: 0,
    fontWeight: "bold",
  },
  divBorder: {
    borderBottom: "1px solid",
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: 10,
  },
  loggedUserId: {
    fontSize: webFont.defaultFont,
    maxWidth: "100%",
    margin: 0,
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
