const actions = {
  ADD_NEW_REGISTER: "ADD_NEW_REGISTER",
  SETLOADING: "SETLOADING",
  GET_REGISTERS: "GET_REGISTERS",
  GET_REGISTERS_SUCCESS: "GET_REGISTERS_SUCCESS",
  ADD_EXPENSE_LOG: "ADD_EXPENSELOG",
  GET_EXPENSE_LOG: "GET_EXPENSE_LOG",
  GET_EXPENSE_LOG_SUCCESS: "GET_EXPENSE_LOG_SUCCESS",
  EDIT_EXPENSE_LOG: "EDIT_EXPENSE_LOG",
  EDIT_REGISTER: "EDIT_REGISTER",
  DELETE_REGISTER: "DELETE_REGISTER",
  DELETE_EXPENSE_LOG: "DELETE_EXPENSE_LOG",
  FETCH_LABEL: "FETCH_LABEL",
  GET_LABEL_SUCCESS: "GET_LABEL_SUCCESS",
  DOWNLOAD_LOG_EXCEL: "DOWNLOAD_LOG_EXCEL",
  DOWNLOAD_REGISTER_EXCEL: "DOWNLOAD_REGISTER_EXCEL",
  FETCH_EXPENSE_BY_ID: "FETCH_EXPENSE_BY_ID",
  GET_EXPENSE_BY_ID_SUCCESS: "GET_EXPENSE_BY_ID_SUCCESS",
  GET_REGISTER_BY_ID: "GET_REGISTER_BY_ID",
  GET_REGISTER_BY_ID_SUCCESS: "GET_REGISTER_BY_ID_SUCCESS",
  RESET: "RESET",

  getRegisterById: (firebase, id) => ({
    type: actions.GET_REGISTER_BY_ID,
    id,
    firebase
  }),
  addRegister: (register, firebase) => ({
    type: actions.ADD_NEW_REGISTER,
    register,
    firebase,
  }),
  fetchExpenseById: (id, firebase) => ({
    type: actions.FETCH_EXPENSE_BY_ID,
    id,
    firebase,
  }),

  addExpenseLog: (expenseLog, firebase) => ({
    type: actions.ADD_EXPENSE_LOG,
    expenseLog,
    firebase,
  }),

  getRegisters: (firebase, startDate, endDate, selectedFrequency) => ({
    type: actions.GET_REGISTERS,
    firebase,
    startDate,
    endDate,
    selectedFrequency,
  }),

  setLoading: (payLoad) => ({
    type: actions.SETLOADING,
    payLoad,
  }),

  getExpenseLog: (firebase, startDate, endDate, selectedFrequency, registerId) => ({
    type: actions.GET_EXPENSE_LOG,
    firebase,
    startDate,
    endDate,
    selectedFrequency,
    registerId,
  }),

  deleteExpenseLog: (firebase, expenseLogId) => ({
    type: actions.DELETE_EXPENSE_LOG,
    firebase,
    expenseLogId,
  }),

  deleteRegister: (firebase, registerId, register) => ({
    type: actions.DELETE_REGISTER,
    firebase,
    registerId,
    register,
  }),
  editRegister: (firebase, register) => ({
    type: actions.EDIT_REGISTER,
    firebase,
    register,
  }),
  editExpenseLog: (firebase, expenseLogId, log, isUpload) => ({
    type: actions.EDIT_EXPENSE_LOG,
    firebase,
    id: expenseLogId,
    values: log,
    isUpload,
  }),
  fetchLabels: (firebase, category) => ({
    type: actions.FETCH_LABEL,
    firebase,
    category,
  }),
  downloadExcel: (firebase, values, registerId) => ({
    type: actions.DOWNLOAD_LOG_EXCEL,
    firebase,
    values,
    registerId,
  }),
  downloadRegisterExcel: (firebase, registers, startDate, endDate, selectedFrequency) => ({
    type: actions.DOWNLOAD_REGISTER_EXCEL,
    firebase,
    registers,
    startDate,
    endDate,
    selectedFrequency,
  }),
  reset: () => ({
    type: actions.RESET
  })
};
export default actions;
