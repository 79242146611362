const actions = {
  GET_LIVE_ATTENDANCE_RATIO: "GET_LIVE_ATTENDANCE_RATIO",
  GET_LIVE_ATTENDANCE_RATIO_SUCCESSFUL: "GET_LIVE_ATTENDANCE_RATIO_SUCCESSFUL",
  GET_STUDENT_LIVE_ATTD_SUCCESS: "GET_STUDENT_LIVE_ATTD_SUCCESS",
  GET_STAFF_LIVE_ATTD_SUCCESS: "GET_STAFF_LIVE_ATTD_SUCCESS",
  GET_LIVE_RATIO_CLASSROOM: "GET_LIVE_RATIO_CLASSROOM",
  GET_LIVE_RATIO_CLASSROOM_SUCCESSFULL: "GET_LIVE_RATIO_CLASSROOM_SUCCESSFULL",
  GET_LIVE_RATIO_TEACHER: "GET_LIVE_RATIO_TEACHER",
  GET_LIVE_RATIO_TEACHER_SUCESS: "GET_LIVE_RATIO_TEACHER_SUCESS",
  GET_ALERT: "GET_ALERT",
  GET_ALERT_SUCCESS: "GET_ALERT_SUCCESS",
  FETCH_ALERT: "FETCH_ALERT",
  FETCH_ALERT_SUCCESS: "FETCH_ALERT_SUCCESS",
  DELETE_SELECTED_ALERT: "DELETE_SELECTED_ALERT",
  DELETE_SELECTED_ALERT_SUCCESS: "DELETE_SELECTED_ALERT_SUCCESS",
  UPDATE_ALERT: "UPDATE_ALERT",
  UPDATE_ALERT_SUCCESS: "UPDATE_ALERT_SUCCESS",
  RESET_ALERT_OPERATION: "RESET_ALERT_OPERATION",

  getLiveRatio: (firebase) => ({
    type: actions.GET_LIVE_ATTENDANCE_RATIO,
    firebase,
  }),
  fetchAlert: (firebase) => ({
    type: actions.FETCH_ALERT,
    firebase,
  }),
  deletedAlert: (record, firebase) => ({
    type: actions.DELETE_SELECTED_ALERT,
    record,
    firebase,
  }),
  resetAlertOperation: () => ({
    type: actions.RESET_ALERT_OPERATION,
  }),

  updateAlert: (
    addStudent,
    classRoom,
    daysOfWeek,
    teacherList,
    startTime,
    endTime,
    alertName,
    record,
    firebase
  ) => ({
    type: actions.UPDATE_ALERT,
    addStudent,
    classRoom,
    daysOfWeek,
    teacherList,
    startTime,
    endTime,
    alertName,
    record,
    firebase,
  }),
  getLiveRatioClassroom: () => ({
    type: actions.GET_LIVE_RATIO_CLASSROOM,
  }),
  getLiveRatioTeacher: () => ({
    type: actions.GET_LIVE_RATIO_TEACHER,
  }),
  alertSubmit: (
    addStudent,
    classRoom,
    daysOfWeek,
    teacherList,
    startTime,
    endTime,
    alertName,
    firebase
  ) => ({
    type: actions.GET_ALERT,
    addStudent,
    classRoom,
    daysOfWeek,
    teacherList,
    startTime,
    endTime,
    alertName,
    // values,
    firebase,
  }),
};
export default actions;
