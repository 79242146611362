import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import * as FileSaver from "file-saver";
import { floor, round } from "mathjs";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import notification from "../components/notification";
import formatMsg from "../components/utility/formatMessageUtil";
import { UserSettingApi } from "../firestore-api/userSetting";
import { default as notes, default as taskIcon } from "../image/appFormIcon.png";
import boyIcon from "../image/boy.svg";
import statusIcon from "../image/charging-circle(1).png";
import emailIcon from "../image/email.png";
import girlIcon from "../image/girl.svg";
import PhoneCall from "../image/Icon awesome-ph.png";
import othersIcon from "../image/Icon material-n.png";
import groupIcon from "../image/meeting.png";
import quoteIcon from "../image/sidebar_billing.png";
import userColored from "../image/userColored.png";
import showArouncIcon from "../image/visitor (1).png";
import { getItem } from "../Utility/encryptedStorage";
import { countriesCode, timeZones } from "./momentTimezone";
import UserFilterAction from "./UserFilterActions";
import FileSizeCalculator from "../Utility/fileSizeCalculator";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import mixpanel from "mixpanel-browser";
const superagent = require("superagent");
import bugsnagClient from "@bugsnag/js";



export default class FilterAction {
  constructor() { }
}

FilterAction.shortUrl = (copyText) => {

  let endPointUrl =
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB775InMuZ5ylr7usQKlDeRFMVDFKnG8UE";

  let obj = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://illumine.page.link",
      link: copyText,
    },
    suffix: {
      option: "SHORT",
    },
  };

  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .send(obj)
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else if (err) {
          reject(err);
        }
      });
  });

  return p1;
};

FilterAction.getAllActivitiesName = (customActivities, activeActivities, activityName) => {
  var activityNameset = new Set();
  if (customActivities && customActivities.length > 0) {
    customActivities.forEach((ele) => {
      if (ele.activityType) {
        let newString = ele.activityType.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.activityDisplayName) {
        let newString = ele.activityDisplayName.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.title) {
        let newString = ele.title.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
    });
  }
  if (activeActivities && activeActivities.length > 0) {
    activeActivities.forEach((ele) => {
      if (ele.activityType) {
        let newString = ele.activityType.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.activityDisplayName) {
        let newString = ele.activityDisplayName.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.title) {
        let newString = ele.title.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
    });
  }
  if (activityName && activityName.length > 0) {
    activityName.forEach((ele) => {
      if (ele.activityType) {
        let newString = ele.activityType.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.activityDisplayName) {
        let newString = ele.activityDisplayName.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
      if (ele.title) {
        let newString = ele.title.split(" ").join("").toLowerCase();
        activityNameset.add(newString);
      }
    });
  }

  return activityNameset;
};

FilterAction.filterStudentByClassroom = (className, students) => {
  let classroomName = className.toLowerCase();
  if (
    classroomName === "all room" ||
    classroomName === "all classroom" ||
    classroomName === "all classrooms"
  ) {
    return students;
  } else {
    let firstStageFilter = students.filter((item) => {
      return item.classList && item.classList.includes(className);
    });
    return firstStageFilter;
  }
};

FilterAction.getAmount = (records) => {
  let totalAmount = 0;
  let totalAdded = 0;
  let totalWithdrawn = 0;
  if (records?.length > 0) {
    records.forEach((record) => {
      totalAdded = totalAdded + record.amountAdded;
      totalWithdrawn = totalWithdrawn + record.amountWithdrawn;
    })
  }

  totalAmount = FilterAction.getFloorDecimalToTwo(totalAdded) - FilterAction.getFloorDecimalToTwo(totalWithdrawn);
  return FilterAction.getFloorDecimalToTwo(totalAmount);
};


FilterAction.filterStudentByClassAndGroup = (className, groupName, students) => {
  if (className !== "All Classrooms") {
    let firstStageFilter = students.filter((item) => {
      return item.classList && item.classList.includes(className);
    });

    if (groupName !== "All Group") {
      let secondStageFilter = firstStageFilter.filter((ele) => {
        if (ele.tags && ele.tags.length > 0) {
          let studentTag = ele.tags;

          let groupStudents = studentTag.filter((secEle) => {
            return secEle.name === groupName;
          });

          if (groupStudents && groupStudents.length > 0) {
            return ele;
          }
        }
      });
      return secondStageFilter;
    } else {
      return firstStageFilter;
    }
  } else {
    if (groupName !== "All Group") {
      let secondStageFilter = students.filter((ele) => {
        if (ele.tags && ele.tags.length > 0) {
          let studentTag = ele.tags;

          let groupStudents = studentTag.filter((secEle) => {
            return secEle.name === groupName;
          });

          if (groupStudents && groupStudents.length > 0) {
            return ele;
          }
        }
      });
      return secondStageFilter;
    } else {
      return students;
    }
  }
};
FilterAction.filterTeacherByGroups = (groupName, teachers) => {
  if (groupName !== "All Group") {
    let filteredTeachers = teachers?.filter((teacher) => {
      return teacher?.groups?.includes(groupName)
    })
    return filteredTeachers
  }
  return teachers
}

FilterAction.loginPageErrorMsg = (errorCode) => {
  let after = errorCode.substring(errorCode.indexOf("/") + 1);
  const authErrors = {
    "admin-restricted-operation": "This operation is restricted to administrators only.",
    "argument-error": "",
    "app-not-authorized":
      "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    "app-not-installed":
      "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.",
    "captcha-check-failed":
      "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
    "code-expired": "The SMS code has expired. Please re-send the verification code to try again.",
    "cordova-not-ready": "Cordova framework is not ready.",
    "cors-unsupported": "This browser is not supported.",
    "credential-already-in-use":
      "This credential is already associated with a different user account.",
    "custom-token-mismatch": "The custom token corresponds to a different audience.",
    "requires-recent-login":
      "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
    "dynamic-link-not-activated":
      "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
    "email-change-needs-verification": "Multi-factor users must always have a verified email.",
    "email-already-in-use": "The email address is already in use by another account.",
    "expired-action-code": "The action code has expired. ",
    "cancelled-popup-request":
      "This operation has been cancelled due to another conflicting popup being opened.",
    "internal-error": "An internal error has occurred.",
    "invalid-app-credential":
      "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    "invalid-app-id": "The mobile app identifier is not registed for the current project.",
    "invalid-user-token":
      "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    "invalid-auth-event": "An internal error has occurred.",
    "invalid-verification-code":
      "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.",
    "invalid-continue-uri": "The continue URL provided in the request is invalid.",
    "invalid-cordova-configuration":
      "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
    "invalid-custom-token": "The custom token format is incorrect. Please check the documentation.",
    "invalid-dynamic-link-domain":
      "The provided dynamic link domain is not configured or authorized for the current project.",
    "invalid-email": "The email address is badly formatted.",
    "invalid-api-key": "Your API key is invalid, please check you have copied it correctly.",
    "invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
    "invalid-credential": "The supplied auth credential is malformed or has expired.",
    "invalid-message-payload":
      "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-multi-factor-session":
      "The request does not contain a valid proof of first factor successful sign-in.",
    "invalid-oauth-provider":
      "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
    "invalid-oauth-client-id":
      "The OAuth client ID provided is either invalid or does not match the specified API key.",
    "unauthorized-domain":
      "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
    "invalid-action-code":
      "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
    "wrong-password": "The password is invalid or the user does not have a password.",
    "invalid-persistence-type":
      "The specified persistence type is invalid. It can only be local, session or none.",
    "invalid-phone-number":
      "The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].",
    "invalid-provider-id": "The specified provider ID is invalid.",
    "invalid-recipient-email":
      "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
    "invalid-sender":
      "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-verification-id":
      "The verification ID used to create the phone auth credential is invalid.",
    "invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
    "multi-factor-info-not-found":
      "The user does not have a second factor matching the identifier provided.",
    "multi-factor-auth-required":
      "Proof of ownership of a second factor is required to complete sign-in.",
    "missing-android-pkg-name":
      "An Android Package Name must be provided if the Android App is required to be installed.",
    "auth-domain-config-required":
      "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
    "missing-app-credential":
      "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
    "missing-verification-code":
      "The phone auth credential was created with an empty SMS verification code.",
    "missing-continue-uri": "A continue URL must be provided in the request.",
    "missing-iframe-start": "An internal error has occurred.",
    "missing-ios-bundle-id": "An iOS Bundle ID must be provided if an App Store ID is provided.",
    "missing-multi-factor-info": "No second factor identifier is provided.",
    "missing-multi-factor-session":
      "The request is missing proof of first factor successful sign-in.",
    "missing-or-invalid-nonce":
      "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
    "missing-phone-number": "To send verification codes, provide a phone number for the recipient.",
    "missing-verification-id":
      "The phone auth credential was created with an empty verification ID.",
    "app-deleted": "This instance of FirebaseApp has been deleted.",
    "account-exists-with-different-credential":
      "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
    "network-request-failed":
      "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
    "no-auth-event": "An internal error has occurred.",
    "no-such-provider": "User was not linked to an account with the given provider.",
    "null-user":
      "A null user object was provided as the argument for an operation which requires a non-null user object.",
    "operation-not-allowed":
      "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
    "operation-not-supported-in-this-environment":
      'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    "popup-blocked":
      "Unable to establish a connection with the popup. It may have been blocked by the browser.",
    "popup-closed-by-user":
      "The popup has been closed by the user before finalizing the operation.",
    "provider-already-linked": "User can only be linked to one identity for the given provider.",
    "quota-exceeded": "The project's quota for this operation has been exceeded.",
    "redirect-cancelled-by-user":
      "The redirect operation has been cancelled by the user before finalizing.",
    "redirect-operation-pending": "A redirect sign-in operation is already pending.",
    "rejected-credential": "The request contains malformed or mismatching credentials.",
    "second-factor-already-in-use": "The second factor is already enrolled on this account.",
    "maximum-second-factor-count-exceeded":
      "The maximum allowed number of second factors on a user has been exceeded.",
    "tenant-id-mismatch": "The provided tenant ID does not match the Auth instance's tenant ID",
    timeout: "The operation has timed out.",
    "user-token-expired": "The user's credential is no longer valid. The user must sign in again.",
    "too-many-requests":
      "We have blocked all requests from this device due to unusual activity. Try again later.",
    "unauthorized-continue-uri":
      "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
    "unsupported-first-factor":
      "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
    "unsupported-persistence-type":
      "The current environment does not support the specified persistence type.",
    "unsupported-tenant-operation": "This operation is not supported in a multi-tenant context.",
    "unverified-email": "The operation requires a verified email.",
    "user-cancelled": "The user did not grant your application the permissions it requested.",
    "user-not-found":
      "There is no user record corresponding to this identifier. The user may have been deleted.",
    "user-disabled": "The user account has been disabled by an administrator.",
    "user-mismatch": "The supplied credentials do not correspond to the previously signed in user.",
    "user-signed-out": "",
    "weak-password": "The password must be 6 characters long or more.",
    "web-storage-unsupported":
      "This browser is not supported or 3rd party cookies and data may be disabled.",
  };

  if (authErrors[after]) {
    return authErrors[after];
  } else {
    return "Login failed";
  }
};

FilterAction.searchStudent = (event, students) => {
  var x = students;
  var regex = /^[0-9a-zA-Z_@.\u0621-\u064A\u0660-\u0669 ]+$/;
  if (event) {
    let text = event.toLowerCase();
    let allStudents = x;
    let filteredStudent = allStudents.filter((item) => {
      return item.name.toLowerCase().includes(text);
    });
    if (!text || text === "") {
      return x;
    } else if (!Array.isArray(filteredStudent) && !filteredStudent.length) {
      console.log("No data");
    } else if (Array.isArray(filteredStudent)) {
      return filteredStudent;
    }
  } else {
    return x;
  }
};
FilterAction.getClassList = () => {
  let classrooms = JSON.parse(getItem("classList"));

  return classrooms;
};
FilterAction.getTeacherList = () => {
  let teachers = JSON.parse(getItem("teacherList"));
  return teachers;
};

FilterAction.getActiveTeacherList = () => {
  let list = [];
  let teachers = JSON.parse(getItem("teacherList"));
  list = teachers;
  if (!list)
    return [];
  list = list.filter((std) => {
    return !std.deactivated;
  })
  return list;
}

FilterAction.getGroupList = () => {
  let groups = JSON.parse(getItem("groupList"));
  return groups;
};
FilterAction.getTeacherNameById = (id, firebase) => {
  let teacher;
  if (id) {
    teacher = firebase.teachersMap[id]?.name;

  }
  return teacher;

};
FilterAction.FilterSearch = (filterObj, name) => {
  let text = name
    .trim()
    .toLowerCase()
    .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  let filter = filterObj.filter((item) => {
    // return item.name && item.name.toLowerCase() === text;
    return (
      item.name.toLowerCase().match(text) ||
      item.name.toLowerCase().includes(text) ||
      (item.email && item.email.toLowerCase().match(text)) ||
      (item.phoneNumber && item.phoneNumber.toString().match(text)) ||
      (item.role && item.role.toLowerCase().match(text))
    );
  });

  return filter;
};

FilterAction.getChatProfileIcon = (item, firebase, userIcon) => {
  let studentId = item.chatListModel.studentId ? item.chatListModel.studentId : undefined;

  let studentsMap = firebase.studentsMap;
  let student = studentsMap.get(studentId);

  let relation = item.chatListModel.relation.toLowerCase();

  if (student) {
    switch (relation) {
      case "parent 2 ":
        if (student.fatherImage) {
          return student.fatherImage;
        } else {
          return userIcon;
        }
      case "father":
        if (student.fatherImage) {
          return student.fatherImage;
        } else {
          return userIcon;
        }

      case "parent 1":
        if (student.motherImage) {
          return student.motherImage;
        } else {
          return userIcon;
        }

      case "mother":
        if (student.motherImage) {
          return student.motherImage;
        } else {
          return userIcon;
        }

      default:
        return userIcon;
    }
  } else {
    return userIcon;
  }
};
FilterAction.getPrivateChatProfileIcon = (item, firebase, userIcon) => {
  if (item.mode.toLowerCase() == "single" || item.mode.toLowerCase() == "both") {
    let relation = item.relation.toLowerCase().trim();
    let studentId = item.studentId ? item.studentId : undefined;

    let studentsMap = firebase.studentsMap;
    let student = studentsMap.get(studentId);
    if (item.mode.toLowerCase() == "both" && student && student.profileImageUrl) {
      return student.profileImageUrl;
    }

    if (student) {
      switch (relation) {
        case "parent 2":
          if (student.fatherImage) {
            return student.fatherImage;
          } else {
            return userIcon;
          }
        case "father":
          if (student.fatherImage) {
            return student.fatherImage;
          } else {
            return userIcon;
          }

        case "parent 1":
          if (student.motherImage) {
            return student.motherImage;
          } else {
            return userIcon;
          }

        case "mother":
          if (student.motherImage) {
            return student.motherImage;
          } else {
            return userIcon;
          }

        default:
          return userIcon;
      }
    } else {
      return userIcon;
    }
  } else if (item.mode.toLowerCase() == "group") {
    if (item.groupImageUrl) {
      return item.groupImageUrl;
    } else {
      return groupIcon;
    }
  }
};
FilterAction.getProgramAvailability = (selectedProgram) => {
  let currentDate = moment().startOf("day");
  let programStart = selectedProgram.startDate;
  let programEnd = selectedProgram.endDate;
  let students = selectedProgram.student ? selectedProgram.student : [];
  if (currentDate >= programStart && currentDate <= programEnd) {
    if (students.length > 0) {
      let activeCount = 0;
      for (let index in students) {
        let studentStart = students[index].startDate;
        let studentEnd = students[index].endDate;
        if (studentStart >= programStart && studentEnd <= programEnd) {
          activeCount++;
        }
      }

      return selectedProgram.maxCapacity - activeCount;
    } else {
      return selectedProgram.maxCapacity ? selectedProgram.maxCapacity : 0;
    }
  } else {
    return 0;
  }
};

FilterAction.searchTemplate = (event, templates) => {
  var regex = /^[0-9a-zA-Z_@.\u0621-\u064A\u0660-\u0669 ]+$/;
  if (event) {
    let text = event.toLowerCase();

    let filteredTemplates = templates.filter((item) => {
      return item.name && item.name.toLowerCase().includes(text);
    });

    if (!text || text === "") {
      return templates;
    } else if (!Array.isArray(filteredTemplates) && !filteredTemplates.length) {
      console.log("No data");
    } else if (Array.isArray(filteredTemplates)) {
      return filteredTemplates;
    }
  } else {
    return templates;
  }
};

FilterAction.filterActivityByClassroom = (event, activities) => {
  if (
    event.toLowerCase() === "all classrooms" ||
    event.toLowerCase() === "all classroom" ||
    event.toLowerCase() === "all room" ||
    event.toLowerCase() === "all rooms"
  ) {
    return activities;
  } else {
    let tempActivities = activities.filter((item) => {
      return item.classNames && item.classNames.includes(event);
    });

    return tempActivities;
  }
};

FilterAction.filterActiveStudents = (students) => {
  let filteredStudent = [];
  if (students && students.length > 0) {
    filteredStudent = students.filter((item) => {
      return !item.status || item.status.toLowerCase() === "active";
    });
  }

  return filteredStudent;
};
FilterAction.getGender = () => {
  let gender = ["Male", "Female", "Prefer not to say"];
  return gender;
};
FilterAction.getStudentIcon = (item) => {
  let gender = item?.gender?.toLowerCase();
  let icon = userColored;
  if (item?.profileImageUrl) {
    icon = item.profileImageUrl;
  } else {
    switch (gender) {
      case "male":
        icon = boyIcon;
        break;
      case "female":
        icon = girlIcon;
        break;

      default:
        icon = userColored;
    }
  }
  return icon;
};
FilterAction.sortIntegerValue = (a, b, key) => {
  if (!a || !a[key]) {
    return 1;
  }

  if (!b || !b[key]) {
    return -1;
  }

  return a[key] - b[key];
};

FilterAction.sortStringValue = (a, b, key) => {
  if (!a || !a[key]) {
    return 1;
  }

  if (!b || !b[key]) {
    return -1;
  }

  return a[key].toString().localeCompare(b[key].toString());
};

FilterAction.getSortedUpcomingBirthday = (a, b) => {
  let first = moment(a.birthDate, UserFilterAction.getDateFormatList()).month();
  let second = moment(b.birthDate, UserFilterAction.getDateFormatList()).month();
  if (first === second) {
    first = moment(a.birthDate, UserFilterAction.getDateFormatList()).date();
    second = moment(b.birthDate, UserFilterAction.getDateFormatList()).date();
    return first - second;
  } else {
    return first - second;
  }
};

FilterAction.searchByKey = (list, key, value, studentsActiveName) => {
  let text = value
    .trim()
    .toLowerCase()
    .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  if (!text || text === "") {
    return list;
  } else {
    return list.filter((item) => {
      if (item[key] && item[key].toLowerCase().match(text)) {
        return true;
      }
      if (
        item[studentsActiveName] &&
        item[studentsActiveName].some((name) => name.toLowerCase().match(text))
      ) {
        return true;
      }
    });
  }
};
FilterAction.downloadCsvAsExcel = (fileName, csvData, fields) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  var ws = XLSX.utils.json_to_sheet(csvData, {
    header: fields,
    dateNF: "DD-MMM-YYYY",
  });

  const wb = {
    Sheets: { data: ws },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const newData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(newData, fileName + fileExtension);
};

FilterAction.getStudentClasses = (id, firebase) => {
  let classList = [];

  let studentsMap = firebase.studentsMap;
  if (studentsMap.has(id)) {
    let student = studentsMap.get(id);
    if (student && student.id) {
      classList = student.classList ? student.classList : [student.classroomName];
    }
  }

  return classList;
};

FilterAction.getSetOfExtensions = (selectedActivity) => {
  if (selectedActivity === "Photo") {
    return [
      "image/jpg",
      "image/bmp",
      "image/gif",
      "image/png",
      // "image/svg+xml",
      "image/apng",
      "image/x-icon",
      "image/tiff",
      "image/webp",
    ];
  } else if (selectedActivity === "Video") {
    return [
      "video/3gpp",
      "video/mp4",
      "video/MP4V-ES",
      "video/MPV",
      "video/mpeg",
      "video/ogg",
      "video/quicktime",
      "video/webm",
      "video/x-m4v",
      "video/ms-asf",
      "video/x-ms-wmv",
      "video/x-msvideo",
    ];
  } else if (selectedActivity === "File Upload") {
    return [
      "application/xlsx",
      "application/xls",
      "application/doc",
      "application/docx",
      "application/ppt",
      "application/pptx",
      "application/txt",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/vnd.ms-word.document.macroEnabled.12",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
    ];
  } else {
    return [
      "image/jpg",
      "image/bmp",
      "image/gif",
      "image/png",
      // "image/svg+xml",
      "image/apng",
      "image/x-icon",
      "image/tiff",
      "image/webp",
      "application/xlsx",
      "application/xls",
      "application/doc",
      "application/docx",
      "application/ppt",
      "application/pptx",
      "application/txt",
      "application/pdf",
      "video/3gpp",
      "video/mp4",
      "video/MP4V-ES",
      "video/MPV",
      "video/mpeg",
      "video/ogg",
      "video/quicktime",
      "video/webm",
      "video/x-m4v",
      "video/ms-asf",
      "video/x-ms-wmv",
      "video/x-msvideo",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/vnd.ms-word.document.macroEnabled.12",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
    ];
  }
};

FilterAction.getStudentStatus = (id, firebase) => {
  let status = "Active";

  let studentsMap = firebase.studentsMap;
  if (studentsMap.has(id)) {
    let student = studentsMap.get(id);
    if (student && student.id) {
      status = student.status ? student.status : "Active";
    }
  }

  return status;
};

FilterAction.getTeacherObject = (teacherId) => {
  let teachers = JSON.parse(getItem("teacherList"));
  let teacher = teachers.filter((staffItem) => {
    return staffItem.id === teacherId;
  });
  return teacher;
};

FilterAction.updateNextGenerationDate = (feePlan, generationDate) => {
  var nextGenerationDate = generationDate;
  if (feePlan.frequency == "Monthly") {
    nextGenerationDate = generationDate.add(1, "months").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  } else if (feePlan.frequency == "Quaterly") {
    nextGenerationDate = generationDate.add(3, "months").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  } else if (feePlan.frequency == "Quarterly") {
    nextGenerationDate = generationDate.add(3, "months").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  } else if (feePlan.frequency == "Half Yearly") {
    nextGenerationDate = generationDate.add(6, "months").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  } else if (feePlan.frequency == "Yearly") {
    nextGenerationDate = generationDate.add(1, "years").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  } else if (feePlan.frequency == "Custom Date") {
    var sequence = feePlan.sequence ? feePlan.sequence + 1 : 0;
    if (feePlan.generationDates.length > sequence) {
      nextGenerationDate = feePlan.generationDates[sequence];
    } else {
      nextGenerationDate = moment().subtract(1, "days");
    }
  } else if (feePlan.frequency == "Weekly") {
    nextGenerationDate = generationDate.add(1, "weeks").set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  return nextGenerationDate;
};

FilterAction.getStudentAdmissionNumber = (id, firebase) => {
  var students = firebase.studentsMap;
  var student = students.get(id);
  let admissionNumber;
  if (student && student.admissionNumber) {
    admissionNumber = student.admissionNumber;
  }
  return admissionNumber;
};

FilterAction.getCustomFormFields = (type, customActivitySetting) => {
  let val = [];
  let actionType = "fieldConfigModels";
  if (customActivitySetting && customActivitySetting[type]) {
    let formSetting = customActivitySetting[type];

    if (formSetting[actionType]) {
      val = formSetting[actionType];
    }
  }

  return val;
};

FilterAction.getActivityApprovalStatus = (type, customActivitySetting) => {
  let val = false;
  let actionType = "noApproval";
  if (customActivitySetting && customActivitySetting[type]) {
    let formSetting = customActivitySetting[type];

    if (formSetting[actionType]) {
      val = formSetting[actionType];
    }
  }

  return val;
};
FilterAction.getCustomDropdowns = (id) => {
  let values = [];
  let customFields = JSON.parse(getItem("customFields"));
  if (customFields[id]) {
    values = customFields[id].dropdownValues ? customFields[id].dropdownValues : [];
  }

  return values;
};

FilterAction.getStudentList = (firebase) => {
  let list = [];
  if (firebase) {
    list = firebase.studentsList;
  }

  return list;
};

FilterAction.getActiveStudentList = (firebase) => {
  let list = [];
  if (firebase) {
    list = firebase.studentsList;
    list = list.filter((std) => {
      return (!std.status || std.status.toLowerCase() === "active") && !std.deactivated;
    });
  }

  return list;
};

FilterAction.getActiveAndSignedUpStudentList = (firebase) => {
  let list = [];
  if (firebase) {
    list = firebase.studentsList;
    list = list.filter((std) => {
      return std.status && (std.status.toLowerCase() === "active" || std.status.toLowerCase() === "signed up") && !std.deactivated;

    });
  }
  return list;
}

FilterAction.getProgramCapacity = (
  selectedProgram,
  studentList,
  firebase,
  selectedDateRange,
  selectedWeekDays,
  noValidation
) => {
  let programStartDate = moment(selectedProgram.startDate);
  let programEndDate = moment(selectedProgram.endDate);
  let startDate = selectedDateRange[0];
  let endDate = selectedDateRange[1];

  let weekdays = selectedWeekDays;
  var errorObject = {};
  if (!startDate) {
    if (!noValidation) {
      notification("error", formatMsg("error.chooseValidDateRange"));
    }
    errorObject.error = "Choose start date";
    return errorObject;
  }
  if (!endDate) {
    if (!noValidation) {
      notification("error", "Choose end date ");
    }
    errorObject.error = "Choose end date";
    return errorObject;
  }

  if (weekdays.length === 0) {
    notification("error", formatMsg("error.chooseDaysOfWeek"));
    (errorObject.error = "error"), "Choose days of week";
    return errorObject;
  }

  if (
    moment(startDate).isAfter(programEndDate, "day", undefined, "[]") ||
    moment(startDate).isBefore(programStartDate, "day", undefined, "[]")
  ) {
    if (!noValidation) {
      notification(
        "error",
        "Start date is not between program's validity date range of" +
        moment(selectedProgram.startDate).format("DD[-]MM[-]YY") +
        "to  " +
        moment(selectedProgram.endDate).format("DD[-]MM[-]YY")
      );
    }
    errorObject.error =
      formatMsg("error.programStartDate") +
      moment(selectedProgram.startDate).format("DD[-]MM[-]YY") +
      formatMsg("to") +
      moment(selectedProgram.endDate).format("DD[-]MM[-]YY");

    return errorObject;
  }

  if (
    moment(endDate).isAfter(programEndDate, "day", undefined, "[]") ||
    moment(endDate).isBefore(programStartDate, "day", undefined, "[]")
  ) {
    notification("error", formatMsg("error.programEndDate"));
    errorObject.error = "End date is not between program's validity date range";

    return errorObject;
  }

  let studentsMap = firebase.studentsMap;
  let enrollmentCount = 0;
  if (studentList && studentList.length > 0) {
    for (let index in studentList) {
      let s = studentList[index];
      let studentStartDate = moment(s.startDate);
      let studentEndDate = moment(s.endDate);
      if (
        studentsMap.get(s.studentId) &&
        studentsMap.get(s.studentId).status &&
        (studentsMap.get(s.studentId).status.toLowerCase() === "active" ||
          studentsMap.get(s.studentId).status.toLowerCase() === "signed up")
      ) {
        if (
          studentStartDate.isSameOrBefore(moment(endDate), "day") &&
          studentEndDate.isSameOrAfter(moment(startDate), "day")
        ) {
          if (s.weekdays) {
            for (let index in weekdays) {
              let w = weekdays[index];
              if (s.weekdays.includes(w)) {
                enrollmentCount = enrollmentCount + 1;
                break;
              }
            }
          } else {
            enrollmentCount = enrollmentCount + 1;
          }
        }
      }
    }
  }

  return enrollmentCount;
};

FilterAction.getAssignedStudentId = (studentId, startTime, endTime) => {
  return studentId + "**" + startTime + "**" + endTime;
};

FilterAction.getStudentObj = (id, firebase, key) => {
  let student = undefined;

  let studentsMap = firebase.studentsMap;
  if (studentsMap.has(id)) {
    student = studentsMap.get(id);
  }
  if (key) {
    return student ? student[key] : undefined;
  } else {
    return student;
  }
};
FilterAction.findProgramById = (id, firebase) => {
  let filterList = firebase.programList.filter((element) => {
    return element.id == id;
  });
  if (filterList) return filterList[0];
  else return undefined;
};

FilterAction.searchProgramStudentByDateRange = (students, startTime, endTime, status) => {
  let std = [];
  students.forEach((element) => {
    let sTime = moment(element.startDate);
    let eTime = moment(element.endDate);
    if (
      sTime.isBetween(startTime, endTime, "day", "[]") ||
      eTime.isBetween(startTime, endTime, "day", "[]")
    ) {
      if (element.studentStatus == status) std.push(element);
    }
  });

  return std;
};

FilterAction.getActiveStudentInProgram = (selectedProgram, firebase) => {
  var activeCount = 0;
  if (selectedProgram.student !== undefined) {
    let x = FilterAction.getActiveFeePlanStudents(selectedProgram.student, firebase);
    for (let i = 0; i < x.length; i++) {
      var status = "Completed";
      if (x[i].endDate) {
        if (moment(moment.now()).isBetween(x[i].startDate, x[i].endDate, "day", "[]")) {
          status = "Active";
          activeCount = activeCount + 1;
        }
      }
    }
  }
  return activeCount;
};

FilterAction.getActiveFeePlanStudents = (obj, firebase) => {
  let studentsMap = firebase.studentsMap;
  let val = [];
  obj.forEach((ele) => {
    if (studentsMap.has(ele.studentId)) {
      val.push(ele);
    }
  });

  return val;
};

FilterAction.getRelation = (relation, firebase) => {
  if (firebase.schoolConfig && firebase.schoolConfig.genderNeutral) {
    if (relation.toLowerCase() === "mother") {
      return "Parent One";
    } else if (relation.toLowerCase() === "father") {
      return "Parent Two";
    } else {
      return relation;
    }
  } else {
    return relation;
  }
};

FilterAction.getSuperagentBody = (obj, firebase, godMode) => {
  obj.platform = "web";
  // obj.version = packageJson.version;
  obj.updatedOn = moment().valueOf();
  if (firebase.teacher) {
    obj.updatedBy = firebase.teacher.name;
  }

  if (godMode) {
    obj.updatedBy = "GOD";
  }

  return obj;
};

FilterAction.getFieldRenderSetting = (item, firebase) => {
  let val = "readWrite";
  if (firebase.selectedMode.toLowerCase() === "parent" && item.parentSettings) {
    let settingVal = item.parentSettings.toLowerCase();
    if (settingVal === "visible") {
      val = "readOnly";
    } else if (settingVal === "visible and editable") {
      val = "readWrite";
    }
  }

  return val;
};

FilterAction.getUserObj = (id, firebase, type) => {
  let user;

  if (type.toLowerCase() === "student") {
    let studentsMap = firebase.studentsMap;
    if (studentsMap.has(id)) {
      user = studentsMap.get(id);
    }
  } else if (type.toLowerCase() === "teacher") {
    let teachersMap = firebase.teachersMap;
    if (teachersMap[id]) {
      user = teachersMap[id];
    }
  }

  return user;
};

FilterAction.getStatusType = () => {
  const url = new URL(window.location.href);
  if (url.searchParams.get("statusType") === null) {
    url.searchParams.set("statusType", "active");
    window.history.replaceState(null, null, url);
    return url.searchParams.get("statusType");
  } else {
    return url.searchParams.get("statusType");
  }
};

FilterAction.getCountryDialCode = (firebase) => {
  let countryNameCode = FilterAction.getCountryCode(firebase);
  let skip = false;
  var dialCode;
  if (countriesCode && countriesCode.length > 0) {
    countriesCode.forEach((ele) => {
      if (ele.code && ele.code === countryNameCode && !skip) {
        dialCode = ele.dial_code;
        skip = true;
      }
    });
  }

  return dialCode;
};

FilterAction.getCountryCode = (firebase) => {
  if (firebase.schoolConfig && firebase.schoolConfig.timezone) {
    var zone = firebase.schoolConfig.timezone;
  } else {
    var zone = moment.tz.guess();
  }
  if (timeZones && zone && timeZones.countries) {
    const keys = Object.keys(timeZones.countries);
    var countryCode;

    let skip = false;
    keys.forEach((key, index) => {
      if (timeZones.countries[key].zones.includes(zone) && !skip) {
        if (firebase && firebase.schoolConfig && firebase.schoolConfig.country) {
          if (
            timeZones.countries[key].name.toLowerCase() ===
            firebase.schoolConfig.country.toLowerCase()
          ) {
            countryCode = key;
            skip = true;
          }
        } else {
          countryCode = key;
          skip = true;
        }
      }
    });
    return countryCode;
  }
};

FilterAction.getCountryName = (countryCode) => {
  var countryName = "in";
  if (countriesCode.length > 0) {
    countriesCode.map((element) => {
      if (element.dial_code.substring(1) === countryCode) {
        countryName = element.code;
        return countryName;
      }
    });
  }

  return countryName;
};
FilterAction.imageTypeAllowed = () => {
};

FilterAction.getNotePlainContent = (editorState) => {
  if (editorState) {
    let rawContentState = convertToRaw(editorState.getCurrentContent());

    if (rawContentState.blocks && rawContentState.blocks.length > 0) {
      let allBlocks = rawContentState.blocks;
      let data = "";
      allBlocks.map((b, i) => {
        if (b.text) {
          if (i === 0) {
            data = b.text + " \n";
          } else {
            data = data + b.text + " \n";
          }
        }
      });

      return data;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

FilterAction.getNoteHtml = (editorState) => {
  if (editorState) {
    let rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(rawContentState);

    const linkedMarkUp = FilterAction.linkify(markup);
    return FilterAction.replaceAll(linkedMarkUp, "</p>\n<p>", "<br>");
    // return linkedMarkUp;
  } else {
    return null;
  }
};

FilterAction.replaceAll = (str, term, replacement) => {
  return str.replace(new RegExp(FilterAction.escapeRegExp(term), "g"), replacement);
};

FilterAction.escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

FilterAction.linkify = (inputText) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};
FilterAction.getNextDay = (dayINeed) => {
  const today = moment().isoWeekday();
  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, "weeks").isoWeekday(dayINeed);
  }
};

FilterAction.getCustomDateRangeForFeeplan = (frequency, dayOfWeek, dueAfter, generationDate) => {
  dayOfWeek = dayOfWeek ? dayOfWeek : "Monday";
  generationDate = generationDate ? generationDate : moment().add(1, "day");
  dueAfter = dueAfter ? dueAfter : 1;
  let list = [];
  var dayMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  if (frequency === "Weekly") {
    list = [
      FilterAction.getNextDay(dayMap[dayOfWeek] + dueAfter).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      FilterAction.getNextDay(dayMap[dayOfWeek] + dueAfter)
        .add(1, "week")
        .format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).subtract(1, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).subtract(1, "week").add(1, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(1, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(1, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(2, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(2, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(3, "week").format("DD[, ]MMM[, ]YYYY"),
    ];
  } else if (frequency === "Bi Weekly") {
    list = [
      moment(generationDate).subtract(4, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).subtract(2, "week").add(1, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).subtract(2, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(2, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(2, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(4, "week").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(4, "week").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(6, "week").format("DD[, ]MMM[, ]YYYY"),
    ];
  } else if (frequency === "Monthly") {
    list = [
      moment(generationDate).subtract(2, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).subtract(1, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).subtract(1, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(1, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(1, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(2, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(2, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(3, "month").format("DD[, ]MMM[, ]YYYY"),
    ];
  } else if (frequency === "Quarterly") {
    list = [
      moment(generationDate).subtract(6, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).subtract(3, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).subtract(3, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(3, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(3, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(6, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(6, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(9, "month").format("DD[, ]MMM[, ]YYYY"),
    ];
  } else if (frequency === "Half Yearly") {
    list = [
      moment(generationDate).subtract(12, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).subtract(6, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).subtract(6, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(6, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(6, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(12, "month").format("DD[, ]MMM[, ]YYYY"),
      moment(generationDate).add(12, "month").format("DD[, ]MMM[, ]YYYY") +
      " - " +
      moment(generationDate).add(18, "month").format("DD[, ]MMM[, ]YYYY"),
    ];
  }

  return list;
};

FilterAction.checkEmailTags = (content, tags) => {
  var regex = /\{{(.*?)\}}/g;
  var results = [],
    m;
  let value = true;

  while ((m = regex.exec(content))) {
    results.push(m[1]);
  }

  for (let index in results) {
    let tag = results[index];
    if (!tags.includes(tag)) {
      value = false;
      notification("error", formatMsg("error.useMentionedTags"));
      break;
    }
  }
  return value;
};

FilterAction.getDefaultLanguage = () => {
  let lng;
  let storedKey = getItem("lng");
  console.log("language--> ", storedKey)

  switch (storedKey) {
    case "ar":
      lng = "arabic";
      break;

    case "es":
      lng = "spanish";
      break;

    case "pt":
      lng = "portuguese";
      break;

    case "hu":
      lng = "hungarian";
      break;

    case "it":
      lng = "italian";
      break;

    case "ru":
      lng = "russian";
      break;

    case "de":
      lng = "german";
      break;

    case "fr":
      lng = "french";
      break;

    case "lt":
      lng = "lithuanian";
      break;

    default:
      lng = "english";
  }

  return lng;
};

FilterAction.getDefaultLanguageKey = () => {
  let storedKey = getItem("lng");
  return storedKey ? storedKey : "en";
};

FilterAction.resetWebSignout = async (incomingEndPointUrl, sbDbName, user, firebase) => {
  let obj = {
    userId: user.id,
    platform: "webSignout",
  };

  let endPointUrl = incomingEndPointUrl + "woodlandApi/signoutReset/" + "?centerId=" + sbDbName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  superagent
    .post(endPointUrl)
    .send(obj)
    .set("accept", "json")
    .set({
      Authorization: "Bearer " + authToken,
    })
    .end((err, res) => {
      console.log("resetWebSignout response", res);
      if (res) {
        console.log("reset response", res);
      } else if (err) {
        console.log("reset err", err);
      }
    });
};
FilterAction.downloadTableAsExcel = (fileName, csvData, fields, tableRows, tableColumns) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  var ws = XLSX.utils.json_to_sheet(csvData, {
    header: fields,
    dateNF: "DD-MMM-YYYY",
  });

  var tableSheet = undefined
  if (tableRows.length > 0 && tableColumns.length > 0) {
    tableSheet = XLSX.utils.json_to_sheet(tableRows, {
      header: tableColumns,
      dateNF: "DD-MMM-YYYY",
    })
  }

  const wb = {
    Sheets: { data: ws, table: tableSheet },
    SheetNames: ["data", "table"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });

  const newData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(newData, fileName + fileExtension);
};

FilterAction.downloadLeadsExcel = (data, startDate, endDate) => {
  if (data) {
    let fields = [
      "StudentName",
      "StudentGender",
      "StudentBirthDate",
      "ParentOneName",
      "ParentOnePhone",
      "ParentOneEmail",
      "ParentTwoName",
      "ParentTwoPhone",
      "ParentTwoEmail",
      "LeadStatus",
      "Source",
      "SubSource",
      "Program",
      "CreatedOn",
      "ExpectedStartDate",
      "NextFollowUp",
    ];

    let leadsList = [];
    for (let index in data) {
      let val = data[index];
      if (val && val.id && val.student) {
        let status = false;
        if (startDate && endDate) {
          if (moment(val.createdDate).isBetween(moment(startDate), moment(endDate), "day", "[]")) {
            status = true;
          }
        } else {
          status = true;
        }

        if (status) {
          var row = {};
          row.StudentName = val.student.name;
          row.StudentGender = val.student.gender ? val.student.gender : "";
          row.StudentBirthDate = val.student.birthDate
            ? moment(val.student.birthDate).format("DD/MMM/YYYY")
            : "";
          row.ParentOneName = val.parentOne && val.parentOne.name ? val.parentOne.name : "";
          row.ParentOnePhone = val.parentOne && val.parentOne.phone ? val.parentOne.phone : "";
          row.ParentOneEmail = val.parentOne && val.parentOne.email ? val.parentOne.email : "";

          row.ParentTwoName = val.parentTwo && val.parentTwo.name ? val.parentTwo.name : "";
          row.ParentTwoPhone = val.parentTwo && val.parentTwo.phone ? val.parentTwo.phone : "";
          row.ParentTwoEmail = val.parentTwo && val.parentTwo.email ? val.parentTwo.email : "";

          row.LeadStatus = val.leadStatus;
          row.Source = val.source ? val.source : "";
          row.SubSource = val.subSource ? val.subSource : "";
          row.Program = val.feeProgram ? val.feeProgram : "";
          row.CreatedOn = val.createdDate ? moment(val.createdDate).format("DD/MMM/YYYY") : "";
          row.ExpectedStartDate = val.expectedStartDate
            ? moment(val.expectedStartDate).format("DD/MMM/YYYY")
            : "";
          row.NextFollowUp = val.nextFollowUpDate
            ? moment(val.nextFollowUpDate).format("DD/MMM/YYYY")
            : "";

          if (val.additionalProps) {
            for (let i in val.additionalProps) {
              let propVal = val.additionalProps[i];
              let propsName = propVal.name;
              let propsValue = propVal.value;
              if (!fields.includes(propsName)) {
                fields.push(propsName);
              }

              if (propVal.dataType.toLowerCase() === "list") {
                row[propsName] = propsValue ? propsValue.toString() : "";
              } else {
                row[propsName] = propsValue ? propsValue : "";
              }
            }
          }

          leadsList.push(row);
        }
      }
    }

    FilterAction.downloadCsvAsExcel("leads.csv", leadsList, fields);
  }
};

FilterAction.downloadInvoiceExcel = (data, startDate, endDate) => {
  const month = Object.keys(data);
  if (data) {
    let fields = [
      "Month",
      "BilledAmount",
      "CollectedAmount",
      "ExpenseAmount",
    ];

    let leadsList = [];
    let i = 0;
    for (let ind in data) {
      // let val = data[ind];
      var row = {};
      row.Month = month[i];
      row.BilledAmount = data[ind].billedAmount;
      row.CollectedAmount = data[ind].collectedAmount;
      row.ExpenseAmount = data[ind].expenseAmount;
      if (data[ind].additionalProps) {
        for (let i in data[ind].additionalProps) {
          let propVal = val.additionalProps[i];
          let propsName = propVal.name;
          let propsValue = propVal.value;
          if (!fields.includes(propsName)) {
            fields.push(propsName);
          }

          if (propVal.dataType.toLowerCase() === "list") {
            row[propsName] = propsValue ? propsValue.toString() : "";
          } else {
            row[propsName] = propsValue ? propsValue : "";
          }
        }
      }
      leadsList.push(row);
      i++;
    }

    FilterAction.downloadCsvAsExcel("invoice.csv", leadsList, fields);
  }
}

FilterAction.permissionDeleteComment = (item, firebase, mode) => {
  let check = false;
  if (mode.toLowerCase() === "parent") {
    if (item && item.authorId === firebase?.user?.id) {
      check = true;
    }
    return check;
  } else {
    if (
      (item && item.authorId === firebase?.user?.id) ||
      (firebase.teacher &&
        firebase.teacher.role &&
        firebase.teacher.role.trim().split(" ").join("").toLowerCase() === "superadmin")
    ) {
      check = true;
    }
    return check;
  }
};

FilterAction.checkIfCustomPermission = (customActivitySetting, type, actionType) => {
  let status = false;
  if (customActivitySetting && customActivitySetting[type]) {
    let formSetting = customActivitySetting[type];

    if (formSetting[actionType]) {
      status = formSetting[actionType];
    }
  } else {
    if (actionType === "file" || actionType === "media") {
      status = true;
    }
  }
  return status;
};

FilterAction.getFilteredLeadStatusList = (status) => {
  // status.filter((ele) => {
  //   return ele.name && ele.name.toLowerCase() !== "admitted";
  // });
  let exists = false;
  status.forEach((ele) => {
    if (ele.name && ele.name.toLowerCase() === "unassigned") {
      exists = true;
      return;
    }
  });

  if (!exists) {
    status.push({
      name: "Unassigned",
      id: "Unassigned",
    });
  }
  return status;
};

FilterAction.getIcon = (type) => {
  let activityType = type.toLowerCase();
  let val = othersIcon;

  switch (activityType) {
    case "phone call":
      val = PhoneCall;
      break;
    case "email":
      val = emailIcon;
      break;
    case "task":
      val = taskIcon;
      break;
    case "show around":
      val = showArouncIcon;
      break;
    case "quote sent":
      val = quoteIcon;
      break;
    case "status update":
      val = statusIcon;
      break;
    case "note":
      val = notes;
      break;
    case "invoice":
      val = quoteIcon;
      break;
    case "log email":
      val = emailIcon;
      break;
    default:
      val = othersIcon;
  }
  return val;
};

FilterAction.getUrlParam = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};
FilterAction.setUrlParam = (key, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState(null, null, url);
};
FilterAction.filterAllOverdue = (list) => {
  let filteredList = list?.filter((obj) => {
    return moment(obj.dueDate).isBefore(moment().startOf("day"));
  });
  return filteredList;
};
FilterAction.getShareRefByEntityType = (entityType) => {
  if (!entityType)
    entityType = "student"
  entityType = entityType.toLowerCase();
  switch (entityType) {
    case "teacher":
      return "teacherApplicationShareRef";
    case "student":
      return "studentApplicationShareRef";
    case "booking":
      return "bookingApplicationShareRef";
    case "lead":
      return "leadsApplicationShareRef";
    default:
      return "studentApplicationShareRef";
  }
};

FilterAction.getParentRelation = (userId, firebase) => {
  let author = "Parent";
  let student = firebase.student;
  if (student.fatherProfileId && student.fatherProfileId === userId) {
    author = "Father of " + student.name;
  } else if (student.motherProfileId && student.motherProfileId === userId) {
    author = "Mother of " + student.name;
  }
  return author;
};
FilterAction.getparentType = (userId, firebase) => {
  let type = "None";
  let student = firebase.student;
  if (student.fatherProfileId && student.fatherProfileId === userId) {
    type = "parent2";
  } else if (student.motherProfileId && student.motherProfileId === userId) {
    type = "parent1";
  }
  return type;
};

FilterAction.roundOfDecimalToTwo = (number) => {
  return round(number, 2);
};

FilterAction.getFloorDecimalToTwo = (number) => {
  number = Number(number);
  return floor(number, 2);
};

FilterAction.getCurrencyFloorDecimalToTwo = (number) => {
  number = floor(Number(number), 2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

FilterAction.validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  } else {
    return false;
  }
};

FilterAction.getDateFormat = (firebase) => {
  if (firebase?.schoolSetting && firebase.schoolSetting.dateFormat) {
    return firebase.schoolSetting.dateFormat;
  } else {
    return "DD MMM YYYY";
  }
};

FilterAction.valueComparisonStatus = (fieldVal, item, ruleValue, operator) => {
  let status = false;
  let inputVal = fieldVal.value;
  let itemVal = ruleValue;

  if (fieldVal.dataType.toLowerCase() === "date") {
    inputVal = moment(inputVal, UserFilterAction.getDateFormatList()).format(
      "DD/MM/YYYY"
    );
    itemVal = moment(itemVal, UserFilterAction.getDateFormatList()).format(
      "DD/MM/YYYY"
    );
  }
  if (fieldVal.dataType.toLowerCase() === "time") {
    inputVal = moment(inputVal).format(
      "h:mm A"
    );
    itemVal = moment(itemVal).format(
      "h:mm A"
    );
  }

  if (operator === "equal") {
    if (inputVal && itemVal && inputVal.toLowerCase() === itemVal.toLowerCase()) {
      status = true;
    }
  } else if (operator === "not equal") {
    if (inputVal !== itemVal) {
      status = true;
    }
  }
  else if (operator === "greater than") {
    if (inputVal > itemVal) {
      status = true;
    }
  }
  else if (operator === "less than") {
    if (inputVal < itemVal) {
      status = true;
    }
  }
  return status;
};

FilterAction.getFieldVisibilityStatus = (item, formMap) => {
  let conditions = [];
  item.dependency.rules.forEach((ele) => {
    let operator = ele.operator.toLowerCase();
    let fieldVal = formMap[ele.fieldId];
    if (item.value && item.id === ele.fieldId) {
      fieldVal.value = item.value;
    }
    let ruleValue = ele.value;
    if (
      fieldVal &&
      fieldVal.value &&
      FilterAction.valueComparisonStatus(fieldVal, item, ruleValue, operator)
    ) {
      conditions.push(true);
    } else {
      conditions.push(false);
    }
  });
  return conditions;
};

FilterAction.getFormFieldsMap = (formList) => {
  let fieldObj = {};
  formList.forEach((ele) => {
    fieldObj[ele.id] = ele;
  });

  return fieldObj;
};


FilterAction.filterFormFields = (singleItem, formList, itemVal) => {
  let item = singleItem;
  if (itemVal) {
    item.value = itemVal;
  }
  let status = false;
  let statusList = [];
  if (item.dependency) {
    let formMap = FilterAction.getFormFieldsMap(formList);
    let condition = item.dependency.condition.toLowerCase();
    switch (condition) {
      case "or":
        statusList = FilterAction.getFieldVisibilityStatus(item, formMap);
        if (statusList.includes(true)) {
          status = true;
        }

        break;

      case "and":
        statusList = FilterAction.getFieldVisibilityStatus(item, formMap);
        if (statusList.includes(false)) {
          status = false;
        } else {
          status = true;
        }
        break;
    }
  } else {
    status = true;
  }
  return status;
};


FilterAction.getAppLanguage = () => {
  var language = getItem("lng");
  if (!language)
    return "English";
  switch (language) {
    case "ar":
      return "Arabic";
    case "es":
      return "Spanish";
    case "en":
      return "English";
    case "ru":
      return "Russian";
    case "pt":
      return "Portuguese";
    case "fr":
      return "French";
    case "hu":
      return "Hungarian";
    case "it":
      return "Italian";
    case "de":
      return "German";
    case "lt":
      return "Lithuanian";
  }
};

FilterAction.importDefaultStyle = () => {
  import("../styles/antd.css");
}

FilterAction.getDropdownValues = (item) => {
  if (item.toLowerCase() === "male") {
    return (formatMsg("forms.male"));
  } else if (item.toLowerCase() === "female") {
    return (formatMsg("forms.female"));
  } else if (item.toLowerCase() === "prefer not to say") {
    return (formatMsg("forms.preferNotToSay"));
  } else {
    return (item);
  }
}

FilterAction.checkFolderName = (folderName, currentFilePath, documents) => {

  if (!currentFilePath) {
    currentFilePath = "";
  }
  const folderExists = documents.some((obj) => {
    if (obj.folder && obj.filePath === currentFilePath) {
      return obj.name === folderName;
    }
    return false;
  });
  return folderExists;
}

FilterAction.checkIfMediaProfileInForm = (application) => {
  let status = false;
  if (application && application.response) {
    for (let index in application.response) {
      status = application.response[index] && application.response[index].mediaProfiles ? true : false;
      if (status) {
        break;
      }
    }
  }
  return status;
}

FilterAction.getSharedApplicationsOptions = (record) => {

  let operationTypes = [
    "studentInvoice.view",
    "application.copyFormLink",
    "application.unshare",
    "application.sendEmail"
  ];
  if (record.status) {
    switch (record.status.toLowerCase()) {
      case "approved":
        operationTypes.push("application.reject", "application.requestChanges");
        break;
      case "pending":
        operationTypes.push();
        break;
      case "in-review":
        operationTypes.push("activity.approve", "application.reject", "application.requestChanges",);
        break;
      case "rejected":
        operationTypes.push("application.requestChanges", "activity.approve");
        break;
      case "submitted":
        operationTypes.push("activity.approve", "application.reject", "application.requestChanges",);
    }

  }
  if (record.status === "approved" && FilterAction.checkIfMediaProfileInForm(record)
    // this.haveDocumentRequestField(record)
  ) {
    operationTypes.push("application.copyFiles");
  }
  if (record.status === "approved" && (record?.entityType?.toLowerCase() === "lead" || record?.entityType?.toLowerCase() === "student")) {
    operationTypes.push("application.sync");
  }
  return operationTypes;
}

FilterAction.searchValueByKey = (dataObj, key, searchStr, dataType) => {
  let status = false;

  switch (dataType) {
    case "string":
      if (dataObj && dataObj[key] && dataObj[key].toLowerCase().match(searchStr)) {
        status = true;
      };
      break;

    case "number":
      if (dataObj && dataObj[key] && dataObj[key].toString().match(searchStr)) {
        status = true;
      };
      break;
  }

  return status;
}

FilterAction.checkBranchEditAccess = (record, loggedInTeacher) => {
  if (!record.newBranches) {
    record.newBranches = [];
  }

  if (!loggedInTeacher.newBranches) {
    loggedInTeacher.newBranches = [];
  }


  let loggedinTeacherDbnameList = [];
  loggedInTeacher.newBranches.forEach(b => {
    loggedinTeacherDbnameList.push(b.dbName)
  });

  let recordDbNameList = [];
  record.newBranches.forEach(r => {
    recordDbNameList.push(r.dbName)
  });

  let allowEdit = true;

  for (let index in recordDbNameList) {
    if (loggedinTeacherDbnameList.includes(recordDbNameList[index])) {
      allowEdit = true;
    } else {
      allowEdit = false;
      break;
    }
  }
  return allowEdit;
}

FilterAction.getMediaType = (contentType, file) => {
  let mediaType;
  let incomingContentType = contentType;
  if (!incomingContentType) {
    incomingContentType = file.type;
  }

  switch (incomingContentType) {
    case "application/octet-stream":
      mediaType = "file";
      break;
    case "text/csv":
      mediaType = "file";
      break;
      mediaType = "image";
      break;
    case "image/png":
      mediaType = "image";
      break;
    case "image/bmp":
      mediaType = "image";
      break;
    case "image/gif":
      mediaType = "image";
      break;
    case "image/webp":
      mediaType = "image";
      break;
    case "video/mp4":
      mediaType = "video";
      break;
    case "video/3gpp":
      mediaType = "video";
      break;
    case "video/mpeg":
      mediaType = "video";
      break;
    case "video/quicktime":
      mediaType = "video";
      break;
    default:
      mediaType = "image";
  }

  if (incomingContentType && incomingContentType.includes("application")) {
    mediaType = "file";
  }

  return mediaType;
}

FilterAction.checkForCreateAccount = (record) => {
  let check = false;
  if (record.student && record.student.name) {
    check = true;
  }
  return check;
}

FilterAction.getBackGroundColor = (item) => {
  if (item.completed) {
    return "#DFF7EE";
  } else if (
    !item.completed &&
    moment(item.nextFollowUpDate).isSameOrAfter(moment(), "day")
  ) {
    return "#FFF5EC";
  } else if (!item.completed && moment(item.nextFollowUpDate).isBefore(moment(), "day")) {
    return "#FFEAEA"
  }
}

FilterAction.getColor = (item) => {
  if (item.completed) {
    return "#008556";
  } else if (
    !item.completed &&
    moment(item.nextFollowUpDate).isSameOrAfter(moment(), "day")
    // !moment(item.nextFollowUpDate).endOf("days").isSameOrBefore(moment(), "day")
  ) {
    return "#CC823E";
  } else if (!item.completed && moment(item.nextFollowUpDate).isBefore(moment(), "day")) {
    return "#C53A3A";
  }
}

FilterAction.getStatusForTask = (record) => {
  var status;

  if (record.completed) {
    status = "Done";
  } else if (
    !record.completed &&
    moment(record.nextFollowUpDate).isSameOrAfter(moment(), "day")
    // !moment(record.nextFollowUpDate).endOf("days").isSameOrBefore(moment(), "day")
  ) {
    status = "Pending";
  } else if (!record.completed && moment(record.nextFollowUpDate).isBefore(moment(), "day")) {
    status = "Overdue";
  }
  return status;
}

FilterAction.getPasswordRegex = () => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
}

FilterAction.getFormLink = (record, firebase, entityType, entityId) => {
  let linkText =
    "https://school.illumine.app/publicForm/?applicationId=" + record.applicationId +
    "&centerId=" + firebase.sbDbName +
    "&entityType=" + entityType +
    "&entityId=" + entityId +
    "&sharedRefId=" + record.id;

  let shortUrl = FilterAction.shortUrl(linkText);
  return shortUrl;
}

FilterAction.getPermittedFileUploadSize = () => {
  return 5242880;
}

FilterAction.getLeadAutomationFieldValue = (key) => {
  switch (key) {
    case "name":
      return "name"
    case "school":
      return "school"
    case "gender":
      return "gender"
    case "source":
      return "source"
    case "subSource":
      return "Sub source"
    case "feeProgram":
      return "program"
    case "expectedStartDate":
      return "expected start date"
    case "birthDate":
      return "date of birth"
    default:
      return undefined
  }
}

FilterAction.getLeadAutomationOperationValue = (key) => {
  switch (key) {
    case "<":
      return "Less than"
    case ">":
      return "Greater than"
    case "<=":
      return "Less than equal to"
    case ">=":
      return "Greater than equal to"
    case "==":
      return "Equal to"
    case "!=":
      return "Not equal to"
    default:
      return undefined
  }
}
FilterAction.validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  } else {
    return false;
  }
};
FilterAction.checkFileSize = (attachedFile, firebase) => {
  let size = FilterAction.getPermittedFileUploadSize();
  let permissibleSizeExceeded = FileSizeCalculator.calculateFileSize(attachedFile, size, firebase);
  if (permissibleSizeExceeded) {
    notification(
      "error",
      formatMsg("error.largeAttachmentFile") +
      " 5 mb"
    );
    return false;
  }
  return true;
}
FilterAction.getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
FilterAction.validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

FilterAction.getDevelopmentAreaById = (devId, devList) => {
  let obj = devList && devList.filter((dev) => dev.id == devId)
  return obj[0]
}
FilterAction.getMilestonesById = (mileStoneId, milestoneList) => {
  let obj = milestoneList && milestoneList.filter((mil) => mil.id == mileStoneId)
  return obj[0]
}
FilterAction.checkDevAreas = (e, id, record, checkedRecord) => {
  e.stopPropagation()
  let developmentAreas = record.developmentAreas
  let subDevelopmentAreas = record.subDevelopmentAreas
  let milestones = record.milestones

  let checkedDevArea = checkedRecord.checkedDevArea
  let checkedSubDevArea = checkedRecord.checkedSubDevArea
  let checkedMilestones = checkedRecord.checkedMilestones

  let checkedDevAreaIds = new Set(checkedDevArea)
  let checkedSubDevAreaIds = new Set(checkedSubDevArea)
  let checkedMilestonesIds = new Set(checkedMilestones)
  let currDevArea = developmentAreas && developmentAreas.filter((dev) => dev.id == id)
  if (e.target.checked) {
    checkedDevAreaIds.add(currDevArea[0].id)
    if (currDevArea[0].milestones && currDevArea[0].milestones.length > 0) {
      currDevArea[0].milestones.forEach((ele) => {
        checkedMilestonesIds.add(ele)
      })
    }
    if (currDevArea[0].subDevelopmentAreaList && currDevArea[0].subDevelopmentAreaList.length > 0) {
      currDevArea[0].subDevelopmentAreaList.forEach(element => {
        checkedSubDevAreaIds.add(element)
        let currSubDevArea = subDevelopmentAreas && subDevelopmentAreas.filter((subDev) => subDev.id == element)
        if (currSubDevArea[0] && currSubDevArea[0].milestones && currSubDevArea[0].milestones.length > 0) {
          currSubDevArea[0].milestones.forEach((ele) => {
            checkedMilestonesIds.add(ele)
          })
        }
      });
    }
  }
  else {
    checkedDevAreaIds.delete(id);
    if (currDevArea[0].subDevelopmentAreaList && currDevArea[0].subDevelopmentAreaList.length > 0) {
      currDevArea[0].subDevelopmentAreaList.forEach((ele) => {
        checkedSubDevAreaIds.delete(ele);
        let currSubDevArea = subDevelopmentAreas && subDevelopmentAreas.filter((subDev) => subDev.id == ele)
        if (currSubDevArea[0] && currSubDevArea[0].milestones && currSubDevArea[0].milestones.length > 0) {
          currSubDevArea[0].milestones.forEach((ele) => {
            checkedMilestonesIds.delete(ele)
          })
        }
      })
    }
    if (currDevArea[0].milestones && currDevArea[0].milestones.length > 0) {
      currDevArea[0].milestones.forEach((ele) => {
        checkedMilestonesIds.delete(ele)
      })
    }
  }
  let checkedRecordObj = {
    checkedDevArea: checkedDevAreaIds,
    checkedSubDevArea: checkedSubDevAreaIds,
    checkedMilestones: checkedMilestonesIds
  }
  return checkedRecordObj
}
FilterAction.checkSubDevAreas = (e, id, parentId, record, checkedRecord) => {
  e.stopPropagation()
  let developmentAreas = record.developmentAreas
  let subDevelopmentAreas = record.subDevelopmentAreas
  let milestones = record.milestones
  let checkedDevArea = checkedRecord.checkedDevArea
  let checkedSubDevArea = checkedRecord.checkedSubDevArea
  let checkedMilestones = checkedRecord.checkedMilestones

  let checkedDevAreaIds = new Set(checkedDevArea)
  let checkedSubDevAreaIds = new Set(checkedSubDevArea)
  let checkedMilestonesIds = new Set(checkedMilestones)
  let currSubDevArea = subDevelopmentAreas && subDevelopmentAreas.filter((dev) => dev.id == id)
  if (e.target.checked) {
    checkedSubDevAreaIds.add(currSubDevArea[0].id)
    if (currSubDevArea[0].milestones && currSubDevArea[0].milestones.length > 0) {
      currSubDevArea[0].milestones.forEach((ele) => {
        checkedMilestonesIds.add(ele)
      })
    }
    if (!checkedDevAreaIds.has(parentId)) {
      checkedDevAreaIds.add(parentId)
    }
  } else {
    checkedSubDevAreaIds.delete(id);
    if (currSubDevArea[0].milestones && currSubDevArea[0].milestones.length > 0) {
      currSubDevArea[0].milestones.forEach((ele) => {
        checkedMilestonesIds.delete(ele)
      })
    }
    let childIsSelected = false
    if (checkedDevAreaIds.has(parentId)) {
      let currDevArea = developmentAreas && developmentAreas.filter((dev) => dev.id == parentId)
      if (currDevArea[0].subDevelopmentAreaList && currDevArea[0].subDevelopmentAreaList.length > 0) {
        currDevArea[0].subDevelopmentAreaList.forEach((ele) => {
          if (checkedSubDevAreaIds.has(ele)) {
            childIsSelected = true
            return
          }
        })
      }
      if (currDevArea[0].milestones && currDevArea[0].milestones.length > 0) {
        currDevArea[0].milestones.forEach((ele) => {
          if (checkedMilestonesIds.has(ele)) {
            childIsSelected = true
            return
          }
        })
      }
    }
    if (!childIsSelected) {
      checkedDevAreaIds.delete(parentId)
    }
  }
  let checkedRecordObj = {
    checkedDevArea: checkedDevAreaIds,
    checkedSubDevArea: checkedSubDevAreaIds,
    checkedMilestones: checkedMilestonesIds
  }
  return checkedRecordObj
}
FilterAction.checkMilestones = (e, id, devId, subDevId, parentType, record, checkedRecord) => {
  let developmentAreas = record.developmentAreas
  let subDevelopmentAreas = record.subDevelopmentAreas
  let milestones = record.milestones
  let checkedDevArea = checkedRecord.checkedDevArea
  let checkedSubDevArea = checkedRecord.checkedSubDevArea
  let checkedMilestones = checkedRecord.checkedMilestones

  e.stopPropagation()
  let checkedDevAreaIds = new Set(checkedDevArea)
  let checkedSubDevAreaIds = new Set(checkedSubDevArea)
  let checkedMilestonesIds = new Set(checkedMilestones)
  if (e.target.checked) {
    checkedMilestonesIds.add(id)
    if (parentType.toLowerCase() == "developmentarea") {
      if (!checkedDevAreaIds.has(devId)) {
        checkedDevAreaIds.add(devId)
      }
    } else if (parentType.toLowerCase() == "subdevelopmentarea") {
      if (!checkedSubDevAreaIds.has(subDevId)) {
        checkedSubDevAreaIds.add(subDevId)
      }
      if (!checkedDevAreaIds.has(devId)) {
        checkedDevAreaIds.add(devId)
      }
    }
  } else {
    checkedMilestonesIds.delete(id)
    if (subDevId) {
      let childIsSelectedSubDev = false
      if (checkedSubDevAreaIds.has(subDevId)) {
        let currSubDevArea = subDevelopmentAreas && subDevelopmentAreas.filter((dev) => dev.id == subDevId)
        if (currSubDevArea[0].milestones && currSubDevArea[0].milestones.length > 0) {
          currSubDevArea[0].milestones.forEach((ele) => {
            if (checkedMilestonesIds.has(ele)) {
              childIsSelectedSubDev = true
              return
            }
          })
        }
      }
      if (!childIsSelectedSubDev) {
        checkedSubDevAreaIds.delete(subDevId)
      }
    }
    if (devId) {
      let childIsSelected = false
      if (checkedDevAreaIds.has(devId)) {
        let currDevArea = developmentAreas && developmentAreas.filter((dev) => dev.id == devId)
        if (currDevArea[0].subDevelopmentAreaList && currDevArea[0].subDevelopmentAreaList.length > 0) {
          currDevArea[0].subDevelopmentAreaList.forEach((ele) => {
            if (checkedSubDevAreaIds.has(ele)) {
              childIsSelected = true
              return
            }
          })
        }
        if (currDevArea[0].milestones && currDevArea[0].milestones.length > 0) {
          currDevArea[0].milestones.forEach((ele) => {
            if (checkedMilestonesIds.has(ele)) {
              childIsSelected = true
              return
            }
          })
        }
      }
      if (!childIsSelected) {
        checkedDevAreaIds.delete(devId)
      }
    }
  }
  let checkedRecordObj = {
    checkedDevArea: checkedDevAreaIds,
    checkedSubDevArea: checkedSubDevAreaIds,
    checkedMilestones: checkedMilestonesIds
  }
  return checkedRecordObj
}
FilterAction.dragDevAreas = (result, developmentAreas) => {
  if (!result.destination) return;
  const items = Array.from(developmentAreas);
  const [reorderedItems] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItems);
  return items
}
FilterAction.dragSubDevAreas = (result, subDevelopmentAreas) => {
  if (!result.destination) return;
  const items = Array.from(subDevelopmentAreas);
  const [reorderedItems] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItems);
  return items
}
FilterAction.copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    notification("success", formatMsg("notification.copyToClip"));
  }).catch((err) => {
    FilterAction.handleCopyToClipboardError(err, text)
  })

};
FilterAction.generateDayPairs = (totalNumberDays) => {
  let dayPairs = [];

  for (let i = 1; i <= totalNumberDays; i++) {
    let dayLabel;

    if (i <= 15) {
      if (i === 1) {
        dayLabel = '1st day';
      } else if (i === 2) {
        dayLabel = '2nd day';
      } else if (i === 3) {
        dayLabel = '3rd day';
      } else {
        dayLabel = `${i}th day`;
      }
    } else {
      let dayNumber = totalNumberDays - i + 1;
      if (dayNumber == 2) {
        dayLabel = `${dayNumber}nd last day`;
      }
      else if (dayNumber == 3) {
        dayLabel = `${dayNumber}rd last day`;
      }
      else {
        dayLabel = `${dayNumber}th last day`;
      }
    }

    if (i == totalNumberDays) {
      dayPairs.push({ "day": i - 1, "dayName": "last day" });
    }
    else {
      dayPairs.push({ "day": i - 1, "dayName": dayLabel });
    }
  }

  return dayPairs;
}
FilterAction.generateDayPairsAscending = (totalNumberDays) => {
  let dayPairs = [];

  for (let i = 1; i <= totalNumberDays; i++) {
    let dayLabel;

    if (i === 1) {
      dayLabel = '1st day';
    } else if (i === 2) {
      dayLabel = '2nd day';
    } else if (i === 3) {
      dayLabel = '3rd day';
    } else {
      dayLabel = `${i}th day`;
    }

    dayPairs.push({ "day": i - 1, "dayName": dayLabel });
  }

  return dayPairs;
}
FilterAction.generateDayWithDayName = () => {
  let dayPairs = [];
  let date = moment().startOf("week");
  for (let i = 0; i < 7; i++) {
    dayPairs.push({ "day": i, "dayName": moment(date).format("dddd") });
    date = moment(date).add(1, "day");
  }

  return dayPairs;
}

FilterAction.getCurrencySymbol = (firebase) => {
  const currency = firebase?.schoolConfig?.currency ? firebase.schoolConfig.currency : "INR";
  const currencyMap = {
    AED: 'د.إ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: '$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب',
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BOV: 'BOV',
    BRL: 'R$',
    BSD: '$',
    BTC: '₿',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BYR: 'Br',
    BZD: 'BZ$',
    CAD: '$',
    CDF: 'FC',
    CHE: 'CHE',
    CHF: '₣',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: '$',
    CNH: '¥',
    CNY: '¥',
    COP: '$',
    COU: 'COU',
    CRC: '₡',
    CUC: '$',
    CUP: '₱',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'دج',
    EEK: 'kr',
    EGP: 'E£',
    ERN: 'Nfk',
    ETB: 'Br',
    ETH: 'Ξ',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHC: '₵',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'лв',
    KHR: '៛',
    KMF: 'CF',
    KPW: '₩',
    KRW: '₩',
    KWD: 'KD',
    KYD: '$',
    KZT: '₸',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'M',
    LTC: 'Ł',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'lei',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$',
    MRO: 'UM',
    MRU: 'UM',
    MUR: '₨',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: '$',
    MXV: 'MXV',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    QAR: '﷼',
    RMB: '￥',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'R₣',
    SAR: '﷼',
    SBD: '$',
    SCR: '₨',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: '$',
    SSP: '£',
    STD: 'Db',
    STN: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TND: 'د.ت',
    TOP: 'T$',
    TRL: '₤',
    TRY: '₺',
    TTD: 'TT$',
    TVD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYI: 'UYI',
    UYU: '$U',
    UYW: 'UYW',
    UZS: 'лв',
    VEF: 'Bs',
    VES: 'Bs.S',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XBT: 'Ƀ',
    XCD: '$',
    XOF: 'CFA',
    XPF: '₣',
    XSU: 'Sucre',
    XUA: 'XUA',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWD: 'Z$',
    ZWL: '$'
  }
  return currencyMap[currency] ? currencyMap[currency] : currency;
}
FilterAction.handleCopyToClipboardError = (err, text) => {
  switch (err?.name) {
    case 'PermissionDeniedError':
      console.error('Clipboard access denied. Please grant permission.');
      notification("error", formatMsg("copy.permissionDenied"))
      break;
    case 'SecurityError':
      console.error('Clipboard access is only available in secure contexts (HTTPS).');
      //  notification("error", formatMsg("copy.failedToCopy"))
      break;
    case 'ClipboardWriteFailed':
      console.error('Failed to write text to clipboard.');
      // notification("error", formatMsg("copy.failedToCopy"))
      break;
    case 'DataTransferError':
      console.error('Data transfer to clipboard failed.');
      // notification("error", formatMsg("copy.failedToCopy"))
      break;
    case 'UnsupportedClipboardOperation':
      console.error('Clipboard operation is not supported.');
      //notification("error", formatMsg("copy.failedToCopy"))
      break;
    default:
      console.error('An unknown error occurred:', err);

      // notification("error", formatMsg("copy.failedToCopy"))
      break;
  }
  let message = formatMsg("copy.failedToCopy") + " " + formatMsg("copy.manually") + "\n\n" + text
  alert(message)
}

FilterAction.googleSignIn = (firebase) => {
  return firebase
    .doSignInWithGoogle()
    .then(function (resolve, reject) {
      return true
    })
    .catch(function (error) {
      if (error.code !== undefined) {
        let errorCode = FilterAction.loginPageErrorMsg(error.code);
        console.log("errore", errorCode);
        notification("error", errorCode);
      } else {
        console.log("verification error", error);
        notification("error", error);
      }
      return false
    });
}

FilterAction.isStaff = (firebase) => {
  return firebase?.selectedMode?.toLowerCase() === "teacher"
}
FilterAction.getClassIntersection = (classList1, classList2) => {
  if (!classList1)
    return false;
  for (let name of classList2) {
    if (classList1.includes(name)) {
      return true;
    }
  }
  return false;
}

FilterAction.getBackgroundColorFromHex = (hex) => {
  if (!hex) {
    return
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${0.12})`;
}

FilterAction.generateComponentPdf = async (element, reactToPdf) => {
  if (reactToPdf) {
    const options = {
      method: 'open',
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
        format: 'letter',
        orientation: 'landscape',
      },
      canvas: {
        mimeType: 'image/png',
        qualityRatio: 1
      },
      overrides: {
        pdf: {
          compress: true
        },
        canvas: {
          useCORS: true
        }
      },
    };
    generatePDF(element, options)
  } else {
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      foreignObjectRendering: false
    });
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF("portrait", "pt", "a4");
    pdf.deletePage(1);
    const pdfPageWidth = canvas.width * 0.2646;
    const pdfPageHeight = canvas.height * 0.2646;
    pdf.addPage([pdfPageWidth, pdfPageHeight], pdfPageHeight > pdfPageWidth ? "p" : "l");
    pdf.addImage(data, "PNG", 0, 0, pdfPageWidth, pdfPageHeight, undefined, "FAST");
    pdf.save('print.pdf');
  }
}

FilterAction.triggerMixpanelEvent = (eventName, firebase) => {
  if (!firebase) {
    bugsnagClient.notify(Error("firebase undefined in mixpanel event"));
  } else {
    mixpanel.track(eventName, {
      'schoolName': firebase.schoolName,
      'userType': firebase.user ? firebase.userType : "TEACHER"
    })
  }
}
