import "@firebase/firestore"; // 👈 If you're using firestore
//import moment from 'moment';
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import { getItem } from "../Utility/encryptedStorage";
import FilterAction from "../Utility/FilterAction";
import UserImageAction from "../Utility/userImageAction";
import { UserSettingApi } from "./userSetting";
const superagent = require("superagent");

function getAllInvoice(firebase) {
  let studentsMap = firebase.studentsMap;
  let invoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, newEle.val().studentId, firebase)) {
                if (studentsMap.has(newEle.val().studentId)) {
                  invoices.push(newEle.val());
                }
              }
            }
          } else {
            if (studentsMap.has(newEle.val().studentId)) {
              invoices.push(newEle.val());
            }
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getFeeReminder(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {};
  const myRef = rsf.ref(branchPath + "/emails/feeReminderEmail");
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        obj = snapshot.val();
      }
      resolve(obj);
    });
  });
  return promise;
}

function updateFeeReminderEmail(updatedEmail, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/emails/feeReminderEmail");
  myRef.update({
    updatedEmail: updatedEmail,
  });
}

function getAllAggregatedInvoice(firebase) {
  let studentsMap = firebase.studentsMap;

  let aggregatedInvoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                if (studentsMap.has(element.val().studentId)) {
                  aggregatedInvoices.push(element.val());
                }
              }
            } else {
              if (studentsMap.has(element.val().studentId)) {
                aggregatedInvoices.push(element.val());
              }
            }
          } else {
            if (studentsMap.has(element.val().studentId)) {
              aggregatedInvoices.push(element.val());
            }
          }
        }
      });
      resolve(aggregatedInvoices);
    });
  });
  return promise1;
}

function getInvoiceDownloadUrl(record, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoicePdf/" + record.id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        downloadUrl = snap.val();
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function generateInvoiceNode(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/invoices/" + studentId).push().key;
  return key;
}

function addNewInvoice(invoiceObject, studentData, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoices/" + studentData.id + "/" + nodeId).update(invoiceObject);
}

function getAggregatedInvoice(studentId, firebase) {
  let aggregatedInvoice = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices/" + studentId);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        aggregatedInvoice = snap.val();
      } else {
        aggregatedInvoice = false;
      }
      resolve(aggregatedInvoice);
    });
  });
  return promise1;
}

function updatedAmountToAggregatedInvoice(
  aggregatedInvoice,
  studentId,
  totalAmount,
  firebase,
  invoiceActivityType,
  receivedPayment,
  availableCredit,
  pendingAmt,
  paidAmt
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var record = {};
  if (invoiceActivityType === "addInvoice") {
    // record = {
    //   paid: Number(aggregatedInvoice.paid),
    //   pending: Number(aggregatedInvoice.pending + totalAmount),
    //   total: Number(aggregatedInvoice.total + totalAmount),
    // };
    record = {
      paid: Number(aggregatedInvoice.paid + (!isNaN(paidAmt) ? paidAmt : 0)),
      pending: !isNaN(pendingAmt)
        ? Number(aggregatedInvoice.pending + pendingAmt)
        : Number(aggregatedInvoice.pending + totalAmount),
      total: Number(aggregatedInvoice.total + totalAmount),
      creditAmt: !isNaN(availableCredit)
        ? Number(availableCredit)
        : aggregatedInvoice.creditAmt
          ? aggregatedInvoice.creditAmt
          : null,
    };
  } else if (invoiceActivityType === "receivePayment") {
    record = {
      paid: Number(aggregatedInvoice.paid + receivedPayment),
      pending: Number(aggregatedInvoice.pending - receivedPayment),
    };
  } else if (invoiceActivityType === "deleteInvoice") {
    record = {
      paid: Number(aggregatedInvoice.paid - receivedPayment),
      pending: Number(aggregatedInvoice.pending - (totalAmount - receivedPayment)),
      total: Number(aggregatedInvoice.total - totalAmount),
    };
  } else if (invoiceActivityType === "refund") {
    let refundAmt = aggregatedInvoice.refund ? aggregatedInvoice.refund : 0;
    record = {
      refund: Number(refundAmt + receivedPayment),
    };
  }
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentId).update(record);
}

function addAmountToNewAggregatedInvoice(
  studentData,
  nodeId,
  totalAmount,
  firebase,
  availableCredit,
  pendingAmt,
  paidAmt
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentData.id).update({
    classList: studentData.classList ? studentData.classList : [],
    classname: studentData.classroomName ? studentData.classroomName : null,
    fatherNumber: studentData.fatherNumber ? studentData.fatherNumber : 0,
    gender: studentData.gender,
    id: nodeId,
    motherNumber: studentData.motherNumber ? studentData.motherNumber : 0,
    // paid: 0,
    paid: !isNaN(paidAmt) ? paidAmt : 0,
    // pending: Number(totalAmount),
    pending: !isNaN(pendingAmt) ? pendingAmt : FilterAction.getFloorDecimalToTwo(totalAmount),
    studentId: studentData.id,
    studentName: studentData.name,
    admissionNumber: studentData.admissionNumber ? studentData.admissionNumber : null,
    total: FilterAction.getFloorDecimalToTwo(totalAmount),
    creditAmt: !isNaN(availableCredit) ? FilterAction.getFloorDecimalToTwo(availableCredit) : null,
  });
}

function updateInvoice(invoiceObject, studentId, invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId).update(invoiceObject);
  rsf.ref(branchPath + "/invoiceList/" + invoiceId).update(invoiceObject);
}

function recordPayment(selectedInvoice, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // let newPaymentRecord = [];
  // if (selectedInvoice.paymentRecords) {
  //   newPaymentRecord = selectedInvoice.paymentRecords
  // }

  // newPaymentRecord.push({
  //   afterDiscountValue: 0,
  //   amount: values.amount,
  //   description: "Paid by " + values.mode + " on",
  //   discountValue: 0,
  //   mode: values.mode,
  //   timestamp: new Date().getTime(),
  //   referenceNumber: values.referenceNumber ? values.referenceNumber : null
  // })
  rsf
    .ref(branchPath + "/invoices/" + selectedInvoice.studentId + "/" + selectedInvoice.id)
    .update(selectedInvoice);
  //   {
  //   paid: (selectedInvoice.paid + values.amount),
  //   pending: (selectedInvoice.pending - values.amount),
  //   paymentRecords: newPaymentRecord
  // });
}

function getInvoiceById(studentId, invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId);
  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      let invoiceList = [];
      if (snap.val()) {
        invoiceList.push(snap.val())
      }
      emitter(invoiceList);
    });
    return () => {
      myRef.off();
    };
  });


}

function deleteInvoiceFromList(invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoiceList/" + invoiceId).update({
    deleted: true,
    updatedBy: firebase.teacher.name,
    platform: "web",
    updatedOn: moment().valueOf(),
  });
}

function deleteInvoice(studentId, invoiceId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId).set(null);
}

async function sendEmailOfInvoiceToParent(invoiceObject, firebase, noParentEmail) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "invoiceEmail";

  let schoolConfiguration = getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    schoolName: config.schoolName,
    schoolAdress: config.address,
    bucket: config.storageBucket,
    schoolLogoImage: config.logoUrl,
    ccEmail: config.adminEmail,
    branchPath: firebase.sbp,
    accountName: firebase.sbDbName,
    timezone: moment.tz.guess(),
    currency: config.currency,
    currencyCode: config.currencyCode,
    email: config.adminEmail,
    invoice: invoiceObject,
    timezone: localTimezone,
    sendEmail: noParentEmail ? false : true,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

async function sendEmailOfInvoiceReceiptToParent(transaction, firebase, sendEmail) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "receiptEmail";

  let schoolConfiguration = getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    schoolName: config.schoolName,
    schoolAdress: config.address,
    bucket: config.storageBucket,
    schoolLogoImage: config.logoUrl,
    ccEmail: config.adminEmail,
    branchPath: firebase.sbp,
    accountName: firebase.sbDbName,
    timezone: moment.tz.guess(),
    currency: config.currency,
    currencyCode: config.currencyCode,
    email: config.adminEmail,
    transaction: transaction,
    timezone: localTimezone,
    sendEmail: sendEmail ? sendEmail : false,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function createTransactionUniqueId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/transactions/").push().key;
  return key;
}

function recordTransaction(transaction, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/transactions/" + nodeId).update(transaction);
}

function getInvoiceReceiptDownloadUrl(record, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdf/" + record.id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        downloadUrl = snap.val();
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getInvoiceReceiptDownloadUrlAlt(record, firebase) {
  let downloadUrl = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdf/" + record.id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        downloadUrl.push(snap.val());
      }
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getInvoiceByDateRange(dateRange, firebase) {
  let startTime = moment(dateRange[0]).startOf("day").valueOf();
  let endTime = moment(dateRange[1]).endOf("day").valueOf();

  let invoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().invoiceRaiseDate >= startTime &&
            newEle.val().invoiceRaiseDate < endTime
          ) {
            invoices.push(newEle.val());
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getInvoiceByBillingPeriod(dateRange, firebase) {
  let startTime = moment(dateRange[0]).startOf("day").valueOf();
  let endTime = moment(dateRange[1]).endOf("day").valueOf();

  let invoices = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().startPeriod &&
            newEle.val().endPeriod &&
            newEle.val().startPeriod >= startTime &&
            newEle.val().endPeriod <= endTime
          ) {
            invoices.push(newEle.val());
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function updateAggregatedInvoiceAfterPaymentRecordDeletion(studentId, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentId).update(record);
}

function deleteTransactionRecord(transactionId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/transactions/" + transactionId).set(null);
}

function getInvoiceCounter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var counter = rsf
    .ref(branchPath + "/invoiceCounter")
    .once("value")
    .then(function (snap) {
      return snap.val();
    });
  return counter;
}

function incrementCounter(prevCounter, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/invoiceCounter").set(prevCounter + 1);
}

function getInvoiceByFeePlanId(feePlanId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let invoices = [];
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (newEle !== null && newEle.val().feePlanId && newEle.val().feePlanId === feePlanId) {
            invoices.push(newEle.val());
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getInvoiceByFeePlanIdAndDateRange(feePlanId, startDate, endDate, firebase, componentName) {
  let startTime = moment(startDate).startOf("month").valueOf();

  let endTime = moment(endDate).endOf("month").valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let invoices = [];
  const myRef = rsf.ref(branchPath + "/invoices");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        element.forEach((newEle) => {
          if (
            newEle !== null &&
            newEle.val().feePlanId &&
            newEle.val().feePlanId === feePlanId &&
            newEle.val().invoiceRaiseDate > startTime &&
            newEle.val().invoiceRaiseDate < endTime
          ) {
            if (newEle.val().lineItems) {
              let items = newEle.val().lineItems;
              for (let l in items) {
                if (items[l].description.toLowerCase() === componentName.toLowerCase()) {
                  invoices.push(newEle.val());
                  break;
                }
              }
            }
          }
        });
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getInvoiceReceiptWithName(record, firebase) {
  let downloadUrl = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf
    .ref(branchPath + "/receiptList")
    .orderByChild("invoiceId")
    .equalTo(record.id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          downloadUrl.push(snap.val());
        }
      });
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getInvoiceReceiptList(record, firebase) {
  let downloadUrl = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptPdfUrls/" + record.id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() !== null) {
          downloadUrl.push(snap.val());
        }
      });
      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getStudentAggregatedInvoices(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/aggregratedInvoices");
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var aggregatedInvoices = [];
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                aggregatedInvoices.push(element.val());
              }
            } else {
              aggregatedInvoices.push(element.val());
            }
          } else {
            aggregatedInvoices.push(element.val());
          }
        }
      });
      emit(aggregatedInvoices);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe student billing aggregated invoices");
    };
  });
}

function getInvoiceByStudentId(startDate, endDate, studentId, firebase) {
  let start = moment(startDate).valueOf();
  let end = moment(endDate).valueOf();
  let invoices = [];

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let invoiceRef;
  if (startDate && endDate) {
    invoiceRef = rsf
      .ref(branchPath + "/invoices/" + studentId)
      .orderByChild("invoiceRaiseDate")
      .startAt(start)
      .endAt(end);
  } else {
    invoiceRef = rsf.ref(branchPath + "/invoices/" + studentId).orderByChild("inverseDate");
  }
  var promise1 = new Promise(function (resolve, reject) {
    invoiceRef.once("value").then(function (snap) {
      snap.forEach((ele) => {
        if (ele.val() !== null) {
          invoices.push(ele.val());
        }
      });
      resolve(invoices);
    });
  });
  return promise1;
}

function getTransactionById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let transaction = {};
  var promise1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/transactions/" + id)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          transaction = snap.val();
        }
        resolve(transaction);
      });
  });
  return promise1;
}

function generateAggInvoiceNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/aggregratedInvoices").push().key;
  return key;
}

function getCreditHistory(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  const creditRef = rsf.ref(branchPath + "/creditHistory/" + id);
  return eventChannel((emit) => {
    creditRef.on("value", (snapshot) => {
      let history = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && !snap.val().deleted) {
          history.push(snap.val());
        }
      });
      emit(history);
    });
    return () => {
      creditRef.off();

      console.log("unsubscribe Credits");
    };
  });
}

function getAllCreditHistory(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let history = [];
  var promise1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/creditHistory")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((element) => {
          element.forEach((snap) => {
            if (
              snap.val() !== null &&
              !snap.val().deleted &&
              snap.val().studentId &&
              firebase.studentsMap.has(snap.val().studentId)
            ) {
              let tempVal = snap.val();
              tempVal.studentId = element.key;
              history.push(tempVal);
            }
          });
        });
        resolve(history);
      });
  });
  return promise1;
}


function saveCardDetails(record, firebase, country) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/savedCardDetails/" + record.studentId).set({
    address: record.address ? record.address : null,
    cardHolderName: record.name ? record.name : null,
    postalCode: record.postalCode ? record.postalCode : null,
    city: record.city ? record.city : null,
    state: record.state ? record.state : null,
    country: country ? country : null,
  });
}

function saveInvoiceNotes(values, firebase, invoiceId, studentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var obj = {};
  rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId).once("value", (snap) => {
    snap.forEach((item) => {
      obj = item.val();
    });
  });
  let tempObj = {
    ...obj,
    invoiceNote: values.invoiceNote,
    schoolNote: values.schoolNote,
  };
  rsf.ref(branchPath + "/invoices/" + studentId + "/" + invoiceId).update(tempObj);
}

function getCardDetails(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let cardDetail = {};
  var promise1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/savedCardDetails/" + studentId)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          cardDetail = snap.val();
        }
        resolve(cardDetail);
      });
  });
  return promise1;
}

function getTaxReference(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let taxRef = [];
  var promise1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/taxReference")
      .once("value")
      .then(function (snap) {
        snap.forEach((ele) => {
          if (ele) {
            taxRef.push(ele.val());
          }
        });
        resolve(taxRef);
      });
  });
  return promise1;
}

async function generateInvoiceReceipt(invoice, firebase) {
  let endPointUrl = firebase.endPointUrl;
  let sendInviteEndPointUrl = endPointUrl + "duplicateReciept";

  let schoolConfiguration = getItem("schoolConfig");
  let config = JSON.parse(schoolConfiguration);

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    schoolName: config.schoolName,
    schoolAdress: config.address,
    bucket: config.storageBucket,
    schoolLogoImage: config.logoUrl,
    ccEmail: config.adminEmail,
    branchPath: firebase.sbp,
    accountName: firebase.sbDbName,
    timezone: moment.tz.guess(),
    currency: config.currency,
    currencyCode: config.currencyCode,
    email: config.adminEmail,
    invoice: invoice,
    timezone: localTimezone,
  };

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(sendInviteEndPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        //console.log("res", res.status);
        resolve(res);
      });
  });
  return p1;
}

function getCreditPdf(id, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/creditNotePdf/" + id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        downloadUrl.pdfUrl = snap.val();
      }

      resolve(downloadUrl);
    });
  });
  return promise1;
}

function getDepositPdf(id, firebase) {
  let downloadUrl = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/depositNotePdf/" + id);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        downloadUrl.pdfUrl = snap.val();
      }

      resolve(downloadUrl);
    });
  });
  return promise1;
}


function getAllDepositHistory(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let history = [];
  var promise1 = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/depositHistory")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((element) => {
          element.forEach((snap) => {
            if (
              snap.val() !== null &&
              !snap.val().deleted &&
              snap.val().studentId &&
              firebase.studentsMap.has(snap.val().studentId)
            ) {
              let tempVal = snap.val();
              tempVal.studentId = element.key;
              history.push(tempVal);
            }
          });
        });
        resolve(history);
      });
  });
  return promise1;
}



function getDepositHistory(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let depositRef = rsf.ref(branchPath + "/depositHistory/" + id);
  return eventChannel((emit) => {
    depositRef.on("value", (snapshot) => {
      let history = [];
      snapshot.forEach((snap, index) => {
        console.log("index", index);
        if (snap.val() !== null && !snap.val().deleted) {
          history.push(snap.val());
        }
      });
      emit(history);
    });
    return () => {
      depositRef.off();
      console.log("unsubscribe Deposit");
    };
  });
}


function deleteReceipts(transactionId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf
    .ref(branchPath + "/receiptList")
    .orderByChild("transactionId")
    .equalTo(transactionId)
    .once("value")
    .then((snap) => {
      snap.forEach((ele) => {
        if (ele.val() && ele.val().id) {
          rsf.ref(branchPath + "/receiptList/" + ele.val().id).set(null);
        }
      });
    });
}
async function getPaymentMethod(studentId, firebase) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/paymentMethods/" + studentId + "?centerId=" + accountName;

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .get(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })

      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res?.body?.response ?? []);
        } else {
          if (err) {
            console.log("failed to get payment Methods =>", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}
async function getUpcommingInvoice(studentId, firebase, feePlanId) {
  let obj = {
    studentId: studentId,
    feePlanId: feePlanId,
  };
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/feePlan/nextInvoice/" + "?centerId=" + accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(obj)
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res.body.response);
        } else {
          if (err) {
            console.log("failed to get upcoming invoice=>", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}
function updateAggInvoice(studentId, firebase, aggregatedInvoice) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/aggregratedInvoices/" + studentId).set(aggregatedInvoice);
}

function fetchPayments(startDate, endDate, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let studentsMap = firebase.studentsMap;

  let startTime = moment(startDate).valueOf();
  let endTime = moment(endDate).valueOf();

  let paymentsRef;

  if (startDate && endDate) {
    paymentsRef = rsf
      .ref(branchPath + "/receiptList")
      .orderByChild("paymentDate")
      .startAt(startTime)
      .endAt(endTime);
  } else {
    paymentsRef = rsf
      .ref(branchPath + "/receiptList")
      .orderByChild("paymentDate");
  }
  var receipts = [];
  let promise = new Promise(function (resolve, reject) {
    paymentsRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val()) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                if (studentsMap.has(element.val().studentId)) {
                  receipts.push(element.val());
                }
              }
            }
          } else {
            if (studentsMap.has(element.val().studentId)) {
              receipts.push(element.val());
            }
          }
        }
      });
      receipts.reverse()
      resolve(receipts);
    });
  });
  return promise;
}

export const InvoiceApi = {
  updateAggInvoice,
  getUpcommingInvoice,
  getAllInvoice,
  getPaymentMethod,
  getAllAggregatedInvoice,
  getInvoiceDownloadUrl,
  getInvoiceReceiptDownloadUrl,
  generateInvoiceNode,
  addNewInvoice,
  getAggregatedInvoice,
  addAmountToNewAggregatedInvoice,
  updatedAmountToAggregatedInvoice,
  updateInvoice,
  recordPayment,
  getInvoiceById,
  deleteInvoice,
  deleteInvoiceFromList,
  sendEmailOfInvoiceToParent,
  sendEmailOfInvoiceReceiptToParent,
  createTransactionUniqueId,
  recordTransaction,
  getInvoiceByDateRange,
  updateAggregatedInvoiceAfterPaymentRecordDeletion,
  deleteTransactionRecord,
  getInvoiceCounter,
  incrementCounter,
  getInvoiceByFeePlanId,
  getInvoiceByFeePlanIdAndDateRange,
  getInvoiceReceiptList,
  getInvoiceReceiptWithName,
  getStudentAggregatedInvoices,
  getInvoiceByStudentId,
  getTransactionById,
  getInvoiceReceiptDownloadUrlAlt,
  getCreditHistory,
  generateAggInvoiceNode,
  getAllCreditHistory,
  saveCardDetails,
  getCardDetails,
  getTaxReference,
  generateInvoiceReceipt,
  getCreditPdf,
  getDepositPdf,
  getInvoiceByBillingPeriod,
  getAllDepositHistory,
  getDepositHistory,
  deleteReceipts,
  getFeeReminder,
  updateFeeReminderEmail,
  saveInvoiceNotes,
  fetchPayments,
};
