import { all, call, takeLatest, put, take, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore

import moment from "moment";
import bugsnagClient from "@bugsnag/js";
import UserFilterAction from "../../Utility/UserFilterActions";
import notification from "../../components/notification";
import FilterAction from "../../Utility/FilterAction";
import { expenseApi } from "../../firestore-api/expense";
import { ActivityApi } from "../../firestore-api/activity";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import virtualClassReducer from "../virtualClass/reducer";
import selectedStudentAssessmentReducer from "../selectedStudentAssessment/reducer";
import { values } from "lodash";
import formatMsg from "../../components/utility/formatMessageUtil";
const { Parser } = require("json2csv");

function* addNewRegisterSaga({ register, firebase }) {
  try {
    let key = yield call(expenseApi.createRegisterUniqueNodeId, firebase);

    yield call(expenseApi.addNewRegister, register, firebase, key);

    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  } catch (err) {
    console.log("failed to add new Register", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* addExpenseLog({ expenseLog, firebase }) {
  try {
    let key = yield call(expenseApi.createExpenseLogUniqueNodeId, firebase);
    let storagePath = firebase.sbp + "/media/mediaProfiles/";
    let urls = [];
    if (expenseLog.fileList.length > 0) {
      urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        {
          fileList: expenseLog.fileList,
        },
        firebase,
        undefined,
        undefined,
        undefined,
        undefined
      );
    }

    if (urls) {
      yield call(expenseApi.addNewExpenseLog, expenseLog, firebase, key, urls);
    }

    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
    notification("success", formatMsg("success.addExpenseLog"));
  } catch (err) {
    notification("error", formatMsg("error.addLog"));
    console.log("failed to add Expense Log", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* getRegistesSaga({ firebase, startDate, endDate, selectedFrequency }) {
  try {
    let chan = yield call(expenseApi.getAllRegisters, firebase);
    while (true) {
      const response = yield take(chan);
      let logsOfSelectedDateRange = yield call(
        expenseApi.getExpenseLogOfDateRange,
        firebase,
        {
          start: startDate,
          end: endDate,
        },
        selectedFrequency
      );
      let RegisterWithLogAmount = response.map((register) => {
        let amount = logsOfSelectedDateRange
          .filter((log) => log.registerId == register.id)
          .reduce((total, log) => total + FilterAction.getFloorDecimalToTwo(log.amount), 0);
        return {
          ...register,
          amount,
        };
      });

      yield put({
        type: actions.GET_REGISTERS_SUCCESS,
        payLoad: {
          RegisterWithLogAmount,
          chan,
        },
      });
    }
  } catch (err) {
    console.log("failed to Fetch Registers", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* getExpenseLogSaga({ firebase, startDate, endDate, selectedFrequency, registerId }) {
  const chan = yield call(
    expenseApi.getExpenseLogs,
    firebase,
    startDate,
    endDate,
    selectedFrequency,
    registerId
  );

  try {
    while (true) {
      const data = yield take(chan);
      data.sort((a, b) => b.createdDate - a.createdDate);
      yield put({
        type: actions.GET_EXPENSE_LOG_SUCCESS,
        payLoad: { data, chan },
      });
    }
  } catch (err) {
    notification("error", formatMsg("error.fetchLog"));
    console.log("failed to fetch Expense Log", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* editExpenseLogSaga({ firebase, id, values, isUpload }) {
  try {
    var urls;

    var fileUrls = [];
    if (isUpload) {
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      fileUrls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        { fileList: values.fileList },
        firebase,
        undefined,
        undefined,
        undefined,
        undefined
      );
    }

    urls = [...fileUrls, ...values.attachedFilesUrl];

    if (urls) {
      yield call(expenseApi.editExpenseLog, firebase, id, values, urls);
    }

    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
    notification("success", formatMsg("success.updateExpenseLog"));
  } catch (err) {
    notification("error", formatMsg("error.editLog"));
    console.log("failed to edit Expense Log", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* editRegisterSaga({ firebase, register }) {
  try {
    yield call(expenseApi.editRegister, firebase, register);
    yield put({
      type: actions.GET_EXPENSE_LOG_SUCCESS,
      payLoad: undefined,
    });
    yield put({
      type: actions.GET_REGISTERS,
      firebase,
    });
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* deleteExpenseLogSaga({ firebase, expenseLogId }) {
  try {
    let response = yield call(expenseApi.deleteExpenseLog, firebase, expenseLogId);
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* deleteRegisterSaga({ firebase, registerId, register }) {
  try {
    let response = yield call(expenseApi.deteteRegister, firebase, registerId, register);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* fetchLabelSaga({ firebase, category }) {
  try {
    let labels = yield call(ActivityApi.getCustomLabelByCategory, category, firebase);

    yield put({
      type: actions.GET_LABEL_SUCCESS,
      payLoad: labels,
    });
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}
function* getRegister({ firebase, id }) {
  try {
    let register = yield call(expenseApi.getRegisterById, id, firebase);
    yield put({
      type: actions.GET_REGISTER_BY_ID_SUCCESS,
      payLoad: { register },
    });
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}

function* downloadExcelSaga({ firebase, values, registerId }) {
  try {
    let data = yield call(
      expenseApi.getExpenseLogOfDateRange,
      firebase,
      values,
      undefined,
      registerId
    );

    const fields = ["Title", "Discription", "Amount", "Label", "Date", "Created By"];
    let logs = [];
    let timezone = moment.tz.guess();
    data.forEach((log) => {
      var row = {};

      row.title = log.logTitle;
      row.description = log.description;
      row.amount = log.amount;
      row.label = log.label;
      row.createdOn = moment.tz(log.date, timezone).format("DD-MMM-YY");
      row.createdBy = log.createdBy;
      logs.push(row);
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "expenseLogs-" + values.start + "-" + values.end;

    var ws = XLSX.utils.json_to_sheet(logs, {
      dateNF: "DD-MMM-YYYY",
    });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const newData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(newData, fileName + fileExtension);

    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  } catch (err) {
    console.log("failed to Download Excel logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}

function* fetchExpenseByIdSaga({ firebase, id }) {
  try {
    let expenseChanById = yield call(expenseApi.getExpenseById, id, firebase);

    while (true) {
      const expense = yield take(expenseChanById);
      let register = yield call(expenseApi.getRegisterById, expense.registerId, firebase);
      yield put({
        type: actions.GET_EXPENSE_BY_ID_SUCCESS,
        payLoad: {
          expense,
          register,
          expenseChanById,
        },
      });
    }
  } catch (err) {
    console.log("failed to fetch expense by id", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}

function* downloadRegisterExcelSaga({
  firebase,
  registers,
  startDate,
  endDate,
  selectedFrequency,
}) {
  try {
    let data = [];
    let timezone = moment.tz.guess();
    registers.forEach((register) => {
      var row = {};

      row.title = register.registerName;

      row.amount = register.amount;

      row.createdOn = moment.tz(register.createdDate, timezone).format("DD-MMM-YY");
      row.createdBy = register.createdBy;
      data.push(row);
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let fileName;
    if (selectedFrequency != "Life Time")
      fileName =
        "Registers-" +
        moment.tz(startDate, timezone).format("DD-MMM-YY") +
        "-To-" +
        moment.tz(endDate, timezone).format("DD-MMM-YY");
    else fileName = "Registers-" + selectedFrequency;
    var ws = XLSX.utils.json_to_sheet(data, {
      dateNF: "DD-MMM-YYYY",
    });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const newData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(newData, fileName + fileExtension);

    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  } catch (err) {
    console.log("failed to Download Registes Excel", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SETLOADING,
      payLoad: false,
    });
  }
}


export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.ADD_NEW_REGISTER, addNewRegisterSaga),
    yield takeLatest(actions.GET_REGISTERS, getRegistesSaga),
    yield takeLatest(actions.ADD_EXPENSE_LOG, addExpenseLog),
    yield takeLatest(actions.GET_EXPENSE_LOG, getExpenseLogSaga),
    yield takeLatest(actions.EDIT_EXPENSE_LOG, editExpenseLogSaga),
    yield takeLatest(actions.EDIT_REGISTER, editRegisterSaga),
    yield takeLatest(actions.DELETE_EXPENSE_LOG, deleteExpenseLogSaga),
    yield takeLatest(actions.DELETE_REGISTER, deleteRegisterSaga),
    yield takeLatest(actions.FETCH_LABEL, fetchLabelSaga),
    yield takeLatest(actions.DOWNLOAD_LOG_EXCEL, downloadExcelSaga),
    yield takeLatest(actions.DOWNLOAD_REGISTER_EXCEL, downloadRegisterExcelSaga),
    yield takeLatest(actions.FETCH_EXPENSE_BY_ID, fetchExpenseByIdSaga),
    yield takeLatest(actions.GET_REGISTER_BY_ID, getRegister)
  ]);
}
