import { getItem, setItem } from "./encryptedStorage";
export default class SearchedStringActions {
  constructor() { }
}

SearchedStringActions.setSearchValue = (value) => {
  const url = new URL(window.location.href);
  url.searchParams.set("string", value);
  window.history.replaceState(null, null, url);
  setItem("searchedString", value);
};

SearchedStringActions.getSearchValue = () => {
  let val = getItem("searchedString");
  return val && val !== "null" && val !== "undefined" ? val : "";
};

SearchedStringActions.resetSearchValue = () => {
  setItem("searchedString", "");
};
SearchedStringActions.resetOthervalue = () => {
  // setItem("lastBillingFilter", "");
  setItem("classFilter", "");
  setItem("programFilter", "");
  setItem("groupFilter", "");
};
SearchedStringActions.setValues = (freq, classFilter, program, group) => {
  const url = new URL(window.location.href);
  if (freq) {
    url.searchParams.set("lastBillingFilter", freq);
    window.history.replaceState(null, null, url);
    setItem("lastBillingFilter", freq);
  }
  if (classFilter) {
    url.searchParams.set("classFilter", classFilter);
    window.history.replaceState(null, null, url);
    setItem("classFilter", classFilter);
  }
  if (program) {
    url.searchParams.set("programFilter", program);
    window.history.replaceState(null, null, url);
    setItem("programFilter", program);
  }
  if (group) {
    url.searchParams.set("groupFilter", group);
    window.history.replaceState(null, null, url);
    setItem("groupFilter", group);
  }
};

SearchedStringActions.setPageSize = (value) => {
  setItem("currentPageSize", Number(value));
};

SearchedStringActions.getPageSize = () => {
  let val = getItem("currentPageSize");
  if (val) {
    return Number(val);
  } else {
    return 30;
  }
};
