import { Popover, List } from "antd";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import { withFirebase } from "../../components/firebase/context";
import formatMsg from "../../components/utility/formatMessageUtil";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import { confirmAlert } from "react-confirm-alert";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";
import FilterAction from "../../Utility/FilterAction";
import { getItem } from "../../Utility/encryptedStorage";


function StudentVaccineAction(props) {
    let vaccine = props.vaccine;
    const [popover, setPopover] = useState(false);

    function popAction() {
        return (
            <List
                split={false}
                style={{ cursor: "pointer" }}
                size="small"
                dataSource={["a"]}
                renderItem={(item, index) => (
                    <div>
                        {
                            !vaccine.vaccineLevelExempted ?
                                <List.Item
                                    style={{
                                        ...styles.inline,
                                        ...{
                                            color: colors.v2_Cerulean,
                                            borderBottom: ".8px solid " + colors.v2_light_grey
                                        },
                                    }}
                                    key="sendReminder"
                                    onClick={() => sendReminder()}
                                >
                                    {formatMsg("immunization.sendReminder")}
                                </List.Item>
                                :
                                null
                        }
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.v2_Cerulean,
                                },
                            }}
                            key="exemptVaccine"
                            onClick={() => exemptVaccine()}
                        >
                            {vaccine.vaccineLevelExempted ? formatMsg("immunization.removeExemption") : formatMsg("immunization.exemptVaccine")}
                        </List.Item>
                    </div>
                )}
            />
        )
    };

    function getStudentId(firebase) {
        let studentId = null;
        if (firebase.selectedMode.toLowerCase() === "parent") {
            let localStudentId = getItem("currentStudentId");
            if (localStudentId) {
                studentId = localStudentId;
            }
        }
        else {
            studentId = FilterAction.getUrlParam("studentId");
        }
        return studentId;
    }


    function exemptVaccine() {
        let updatedVaccine = { ...vaccine };
        updatedVaccine.vaccineLevelExempted = !vaccine.vaccineLevelExempted;
        const { updateStudentVaccination, firebase } = props;
        updatedVaccine.studentId = getStudentId(firebase);
        updateStudentVaccination(firebase, updatedVaccine);
        setPopover(false);
    }
    function sendReminder() {
        let message = formatMsg("immunization.confirmToSend");
        confirmAlert({
            closeOnClickOutside: false,
            title: (
                <p
                    style={{
                        fontSize: 20,
                        color: colors.v2_fiord,
                    }}
                >
                    {formatMsg("immunization.sendVaccineReminder")}
                </p>
            ),
            message: message,
            buttons: [
                {
                    label: formatMsg("lesson.yes"),
                    onClick: () => {
                        const { sendVaccineReminder, firebase } = props;
                        let studentId = getStudentId(firebase);
                        sendVaccineReminder(firebase, studentId, vaccine.vaccineId);
                    },
                },
                {
                    label: formatMsg("activity.no"),
                },
            ],
        });
    }



    return (
        <>
            {!props?.studentLevelExempted && props?.firebase?.selectedMode?.toLowerCase() === "teacher" ?
                <div style={styles.headerDiv}>
                    <Popover
                        content={popAction()}
                        trigger="click"
                        visible={popover}
                        onVisibleChange={() =>
                            setPopover(!popover)
                        }
                        placement="bottomLeft"
                    >
                        <PrefixIconButton
                            title={formatMsg("invoice.action")}
                            onClick={() => null}
                            backgroundColor={colors.white}
                            icon={!popover ? "caret-down" : "caret-up"}
                            fontColor={colors.v2_Cerulean}
                            border={true}
                            borderColor={colors.v2_Cerulean}
                            fontWeight={650}
                            paddingLeft={15}
                            paddingRight={19}
                            fontSize={webFont.medium}
                            size={"default"}
                        />
                    </Popover>
                </div> : null
            }


        </>
    )
}

function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);
export default a(StudentVaccineAction);
const styles = {
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    listItemIcon: {
        height: 15,
        width: 15,
        marginRight: 10,
        objectFit: "contain",
    },
    actionStyle: {
        border: "1.5px solid #D0D0D0",
        backgroundColor: "#F0F0F0",
        borderRadius: 6,
        display: "flex",
        width: 26,
        height: 26,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 8
    }
}