import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { NotificationApi } from "../../firestore-api/notification";
import { StaffAttendanceApi } from "../../firestore-api/staffAttendance";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import actions from "./actions";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ComplainsApi } from "../../firestore-api/consult";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";

function* fetchStaffNewAttendance({ date, firebase, operationType }) {
  try {
    const chan = yield call(StaffAttendanceApi.getNewStaffAttendance, date, firebase);
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_STAFF_NEW_ATTENDANCE_SUCCESS,
        staffNewAttendance: data,
        newStaffAttendanceChannel: chan,
        operationType: operationType,
      });
    }
  } finally {
    console.log("end attendance channel");
  }
}

function* fetchStaffAttendance({ date, firebase, operationType }) {
  const chan = yield call(StaffAttendanceApi.getStaffAttendance, date, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STAFF_ATTENDANCE_SUCCESSFUL,
        staffAttendance: data,
        staffAttendanceChannel: chan,
        operationType: operationType,
        // operationType: "INITIAL_STAFF_ATD_FETCH"
      });
    }
  } finally {
    console.log("end staff attendance channel");
  }
}

function* getStaffs({ firebase, date }) {
  try {
    let startTime = moment(date).startOf("day").valueOf();
    let endTime = moment(date).endOf("day").valueOf();

    let leaves = yield call(ComplainsApi.getCurrentDayStaffLeaves, firebase, startTime, endTime);

    let leaveIdsForStaff = [];
    let leaveIds = [];
    leaves.forEach((leave) => {
      leaveIds.push(leave.studentId);
      if (!leave.halfDayLeave) {
        leaveIdsForStaff.push(leave.studentId);
      }
    });

    let staffs = JSON.parse(getItem("teacherList"));
    let data = [];
    if (staffs && staffs.length > 0) {
      data = staffs.filter((d) => {
        return !leaveIdsForStaff.includes(d.id) && !d.deactivated;
      });
    }

    if (staffs) {
      let staffsMap = new Map();
      staffs.forEach((std) => {
        if (!std.deactivated) {
          staffsMap.set(std.id, std);
        }
      });

      yield put({
        type: actions.FETCH_ALL_STAFF_SUCCESSFUL,
        allStaffs: data,
        staffsMap: staffsMap,
        staffsOnLeave: leaveIds,
      });
    }
  } catch (error) {
    console.log("failed to fetch staffs", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* getClassrooms({ firebase }) {
  try {
    // let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffRecordPresent(
  selectedStaff,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
  note,
  selectedClassroom
) {
  try {
    let selectedTime = moment(time);
    let atdTime = moment(date)
      .set("hour", selectedTime.get("hour"))
      .set("minute", selectedTime.get("minute"))
      .set("second", selectedTime.get("second"));

    let staffIds = [];

    selectedStaff.forEach((std) => {
      staffIds.push(std.id);
    });

    if (selectedClassroom) {
      let obj = {
        date: atdTime,
        staffIds: staffIds,
        roomRecord: {
          name: selectedClassroom,
          updatedBy: firebase.teacher.name,
          checkinTime: atdTime.valueOf(),
          temperature: temperature,
          note:note,
          late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
        },
      };

      let url = "woodlandApi/checkin/staff?centerId=";
      let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.MARK_STAFF_PRESENT_SUCCESSFUL,
        });
      } else {
        if (response && response.body && response.body.response) {
          notification("error", response.body.response);
        }
        yield put({
          type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
        });
      }
    } else {
      for (let i = 0; i < selectedStaff.length; i++) {
        var classRoom = selectedStaff[i].classList[0];

        var obj = {
          date: atdTime,
          staffIds: [selectedStaff[i].id],
          roomRecord: {
            name: classRoom,
            updatedBy: firebase.teacher.name,
            checkinTime: atdTime.valueOf(),
            temperature: temperature,
            note:note,
            late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
          },
        };

        var url = "woodlandApi/checkin/staff?centerId=";
        var response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

        if (response && response.status && response.status === 200) {
          yield put({
            type: actions.MARK_STAFF_PRESENT_SUCCESSFUL,
          });
        } else {
          if (response && response.body && response.body.response) {
            notification("error", response.body.response);
          }
          yield put({
            type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
          });
        }
      }
    }
  } catch (err) {
    console.log("failed to mark staff record present", err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsPresent({
  selectedStaff,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
  note,
  newAttendance,
  selectedClassroom,
}) {
  if (newAttendance) {
    yield fork(
      markStaffRecordPresent,
      selectedStaff,
      date,
      time,
      firebase,
      pending,
      checkInOutStatus,
      temperature,
      note,
      selectedClassroom
    );
  } else {
    try {
      for (let i = 0; i < selectedStaff.length; i++) {
        // let nodeId = yield call(StaffAttendanceApi.createUniqueNodeInStaffCheckInOutReference, date, firebase);
        let nodeId = selectedStaff[i].id;

        let selectedTime = moment(time);
        let atdTime = moment(date)
          .set("hour", selectedTime.get("hour"))
          .set("minute", selectedTime.get("minute"))
          .set("second", selectedTime.get("second"));

        var checkInOutObject = {
          absent: false,
          checkInEpoch: moment(atdTime).valueOf(),
          checkOutEpoch: 0,
          checkInTime: moment(atdTime).format("h:mm a"),
          className: selectedStaff[i].className ? selectedStaff[i].className : null,
          classrooms: selectedStaff[i].classList ? selectedStaff[i].classList : null,
          createdBy: firebase.teacher.name,
          date: moment(date).valueOf(),
          deactivated: selectedStaff[i].deactivated,
          gender: selectedStaff[i].gender,
          id: nodeId,
          userId: selectedStaff[i].id,
          userName: selectedStaff[i].name,
          role: selectedStaff[i].role ? selectedStaff[i].role : null,
          late: checkInOutStatus && checkInOutStatus === "late" ? true : false,
          lateCheckout: false,
          platform: "web",
          updatedBy: [firebase.teacher.name],
          temperature: temperature ? temperature : null,
          note: note ? note : null
        };
        yield call(
          StaffAttendanceApi.markPresentToCheckInOutReference,
          date,
          selectedStaff[i].id,
          checkInOutObject,
          firebase
        );
        yield call(
          StaffAttendanceApi.markPresentToTeacherAttendanceUpdates,
          date,
          selectedStaff[i].id,
          checkInOutObject,
          firebase
        );
        yield call(
          StaffAttendanceApi.addRecordToStaffAttendance,
          date,
          checkInOutObject,
          selectedStaff[i],
          firebase
        );
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          checkInOutObject,
          firebase,
          "updateAttendanceStaff"
        );

        if (moment(date).isSame(moment(), "day")) {
          let dateFormat = moment(atdTime).format(" DD[-]MM[-]YY");
          yield fork(
            StaffAttendanceApi.updateTeacherLastAtd,
            selectedStaff[i].id,
            dateFormat,
            firebase
          );
        }
      }

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "staff-attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateAttendanceStatsStaff", firebase);
      yield put({
        type: actions.MARK_STAFF_PRESENT_SUCCESSFUL,
      });
    } catch (err) {
      console.log("failed to mark staff present", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* markStaffRecordCheckout(
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus,
  temperature,
  note,
  selectedClassroom
) {
  try {
    let selectedTime = moment(time);
    let atdTime = moment(date)
      .set("hour", selectedTime.get("hour"))
      .set("minute", selectedTime.get("minute"))
      .set("second", selectedTime.get("second"));

    let staffIds = [];
    selectedStaff.forEach((std) => {
      staffIds.push(std.id);
    });
    let obj = {
      date: atdTime,
      staffIds: staffIds,
      roomRecord: {
        name: selectedClassroom,
        updatedBy: firebase.teacher.name,
        checkoutTime: atdTime.valueOf(),
        temperature: temperature,
        note: note,
        late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
      },
    };

    let url = "woodlandApi/checkout/staff?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_STAFF_CHECKOUT_SUCCESSFUL,
      });
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark staff record checkout", err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsCheckoutTime({
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus,
  newAttendance,
  temperature,
  note,
  selectedClassroom,
}) {
  if (newAttendance) {
    yield fork(
      markStaffRecordCheckout,
      selectedStaff,
      date,
      time,
      firebase,
      checkInOutStatus,
      temperature,
      note,
      selectedClassroom
    );
  } else {
    try {
      let selectedTime = moment(time);
      let atdTime = moment(date)
        .set("hour", selectedTime.get("hour"))
        .set("minute", selectedTime.get("minute"))
        .set("second", selectedTime.get("second"));

      for (let i = 0; i < selectedStaff.length; i++) {
        yield call(
          StaffAttendanceApi.updateCheckOutTimeInStaffCheckInOutReference,
          selectedStaff[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );
        yield call(
          StaffAttendanceApi.updateCheckOutTimeInTeacherAttendanceUpdate,
          selectedStaff[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );
        yield call(
          StaffAttendanceApi.updateCheckOutTimeToStaffAttendance,
          selectedStaff[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );

        if (moment(date).isSame(moment(), "day")) {
          let dateFormat = moment(atdTime).format(" DD[-]MM[-]YY");
          yield fork(
            StaffAttendanceApi.updateTeacherLastAtdCheckout,
            selectedStaff[i].id,
            dateFormat,
            firebase
          );
        }
      }

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "staff-attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateAttendanceStatsStaff", firebase);
      yield fork(updateCheckoutStaffRecord, selectedStaff, firebase, date);
      yield put({
        type: actions.MARK_STAFF_CHECKOUT_SUCCESSFUL,
      });
    } catch (err) {
      console.log("failed to mark staff checkout time", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* updateCheckoutStaffRecord(selectedStaffs, firebase, date, atdAction) {
  try {
    for (let index in selectedStaffs) {
      let data = yield call(
        StaffAttendanceApi.getStaffAttendanceById,
        date,
        selectedStaffs[index].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStaff",
          atdAction
        );
      }
    }
  } catch (err) {
    console.log("failed to call and  update checked out staff attendance record api", err);
    bugsnagClient.notify(err);
  }
}

function* markStaffRecordAbsent(selectedStaff, date, firebase, formValues) {
  try {
    let staffIds = [];

    selectedStaff.forEach((std) => {
      staffIds.push(std.id);
    });

    let obj = {
      date: moment(date).valueOf(),
      staffIds: staffIds,
      absentReason: formValues.absentReason ? formValues.absentReason : null,
      absentNote: formValues.absentNote ? formValues.absentNote : null,
    };

    let url = "woodlandApi/absent/staff?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_STAFF_ABSENT_SUCCESSFUL,
      });
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark staff record absent", err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsAbsent({ selectedStaff, date, firebase, newAttendance, formValues }) {
  if (newAttendance) {
    yield fork(markStaffRecordAbsent, selectedStaff, date, firebase, formValues);
  } else {
    try {
      for (let i = 0; i < selectedStaff.length; i++) {
        yield call(
          StaffAttendanceApi.removeStaffRecordFromStaffCheckInOutReference,
          selectedStaff[i],
          date,
          firebase
        );

        let nodeId = selectedStaff[i].id;
        var checkInOutObject = {
          absent: true,
          checkInEpoch: 0,
          checkOutEpoch: 0,
          checkInTime: null,
          className: selectedStaff[i].className ? selectedStaff[i].className : null,
          classrooms: selectedStaff[i].classList ? selectedStaff[i].classList : null,
          createdBy: firebase.teacher.name,
          date: moment(date).valueOf(),
          deactivated: selectedStaff[i].deactivated,
          gender: selectedStaff[i].gender,
          id: nodeId,
          userId: selectedStaff[i].id,
          userName: selectedStaff[i].name,
          late: false,
          lateCheckout: false,
          platform: "web",
          updatedBy: [firebase.teacher.name],
        };

        yield call(
          StaffAttendanceApi.updateRecordToTeacherAttendanceUpdates,
          selectedStaff[i],
          date,
          firebase,
          checkInOutObject
        );
        yield call(
          StaffAttendanceApi.updateRecordToStaffAttendance,
          selectedStaff[i],
          date,
          firebase,
          checkInOutObject
        );

        if (moment(date).isSame(moment(), "day")) {
          yield fork(
            StaffAttendanceApi.updateTeacherLastAtd,
            selectedStaff[i].id,
            undefined,
            firebase
          );

          yield fork(
            StaffAttendanceApi.updateTeacherLastAtdCheckout,
            selectedStaff[i].id,
            undefined,
            firebase
          );
        }
      }

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "staff-attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateAttendanceStatsStaff", firebase);
      yield fork(updateCheckoutStaffRecord, selectedStaff, firebase, date, "markPending");
      yield put({
        type: actions.MARK_STAFF_ABSENT_SUCCESSFUL,
      });
    } catch (err) {
      console.log("failed to mark staff absent", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* getStaffByClassroomName({ className, firebase, date }) {
  try {
    // let data = yield call(StaffAttendanceApi.getStaffByClassName, className, firebase);
    let startTime = moment(date).startOf("day").valueOf();
    let endTime = moment(date).endOf("day").valueOf();

    let leaveIds = yield call(ComplainsApi.getCurrentDayStaffLeaves, firebase, startTime, endTime);

    let teachers = JSON.parse(getItem("teacherList"));
    let data = teachers.filter((ele) => {
      if (
        ele.classList &&
        ele.classList.includes(className) &&
        !leaveIds.includes(ele.id) &&
        !ele.deactivated
      ) {
        return ele;
      }
    });
    if (data) {
      yield put({
        type: actions.FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL,
        allStaffs: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch staff from classname", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchStaffAttendanceByClassName({ className, date, firebase }) {
  const chan = yield call(
    StaffAttendanceApi.getStaffAttendanceByClassName,
    className,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL,
        staffAttendance: data,
        staffAttendanceChannel: chan,
      });
    }
  } finally {
    console.log("end staff attendance channel");
  }
}

// function* requestStaffAttendanceEmail({ value, atdType, firebase }) {
//   try {
//     yield call(
//       NotificationApi.sendAttendanceEmail,
//       value.dateRange[0],
//       value.dateRange[1],
//       atdType,
//       firebase
//     );
//     // if (response && response.status === 200) {
//     notification("success", "Email sent");
//     // } else {
//     //     notification('error', "Failed to send email");
//     // }
//   } catch (err) {
//     console.log("failed to send staff attendance email", err);
//     bugsnagClient.notify(err);
//   }
// }

function getStaffsMap() {
  let teachers = JSON.parse(getItem("teacherList"));
  let teacherMap = new Map();
  teachers.forEach((std) => {
    teacherMap.set(std.id, std);
  });

  return teacherMap;
}

function* downloadStaffAttendanceRecordDaywise(value, atdType, downloadType, firebase) {
  let teachersMap = getStaffsMap();
  let startDate = value.dateRange[0];
  let endDate = value.dateRange[1];
  var checkInRef = firebase.secondaryDb
    .ref(firebase.sbp + "/staffRoomRecords")
    .orderByChild("date")
    .startAt(moment(startDate).startOf("day").valueOf())
    .endAt(moment(endDate).endOf("day").valueOf());

  var report = [];

  if (downloadType === "checkInOut") {
    let selectedClass = value.selectedClassroom;
    const fields = ["staffName", "className"];

    for (var m = moment(startDate); m.diff(endDate, "days") <= 0; m.add(1, "days")) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log("Processing Attendance Report for Date range: " + startDate + " - " + endDate);

    checkInRef.once("value", function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() && snap.val().roomRecords && snap.val().entityId) {
          if (teachersMap.has(snap.val().entityId)) {
            let teacher = teachersMap.get(snap.val().entityId);
            if (teacher) {
              let roomRecords = snap.val().roomRecords;
              var teacherRow = {};

              teacherRow["staffName"] = teacher.name;
              teacherRow["className"] = selectedClass;
              roomRecords.forEach((rec) => {
                if (rec.name.toLowerCase() === selectedClass.toLowerCase()) {
                  if (rec.checkinTime) {
                    if (!teacherRow[moment(snap.val().date).format("YYYY-MM-DD") + "-IN"]) {
                      teacherRow[moment(snap.val().date).format("YYYY-MM-DD") + "-IN"] = moment(
                        rec.checkinTime
                      ).format("hh:mm A");
                    }
                  }

                  if (rec.checkoutTime) {
                    teacherRow[moment(snap.val().date).format("YYYY-MM-DD") + "-OUT"] = moment(
                      rec.checkoutTime
                    ).format("hh:mm A");
                  }
                }
              });
              report.push(teacherRow);
            }
          }
        }
      });
      console.log("teacher report", report);
      executeExcelDownload(report, fields, "StaffCheckInOutAttendance_", startDate, endDate);
    });
  } else {
    const fields = [
      "Classname",
      "StaffName",
      "AttendanceDate",
      "Time",
      "Type",
      "Attendance",
      "Temperature",
      "Late",
      "AbsentReason",
      "AbsentNote",
    ];

    checkInRef.once("value", function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() && snap.val().entityId) {
          if (teachersMap.has(snap.val().entityId)) {
            let teacher = teachersMap.get(snap.val().entityId);
            if (teacher) {
              if (snap.val().roomRecords) {
                let roomRecords = snap.val().roomRecords;

                roomRecords.forEach((rec) => {
                  let teacherRow = {};
                  teacherRow["Classname"] = rec.name;
                  teacherRow["StaffName"] = teacher.name; //get name from entity id;
                  teacherRow["AttendanceDate"] = moment(snap.val().date).format("YYYY-MM-DD");
                  teacherRow["Time"] = rec.checkinTime
                    ? moment(rec.checkinTime).format("hh:mm A")
                    : moment(rec.checkoutTime).format("hh:mm A");
                  teacherRow["Type"] = rec.checkinTime ? "Check in" : "Check out";
                  teacherRow["Attendance"] = "PRESENT";
                  teacherRow["Temperature"] = rec.temperature ? rec.temperature : "";
                  teacherRow["Late"] = rec.late ? rec.late : "";
                  report.push(teacherRow);
                });
              } else {
                let teacherRow = {};
                teacherRow["Classname"] = teacher.classList.toString();
                teacherRow["StaffName"] = teacher.name;
                teacherRow["Attendance"] = "ABSENT";
                teacherRow["AbsentReason"] = snap.val().absentReason ? snap.val().absentReason : "";
                teacherRow["AbsentNote"] = snap.val().absentNote ? snap.val().absentNote : "";
                teacherRow["AttendanceDate"] = moment(snap.val().date).format("YYYY-MM-DD");
                report.push(teacherRow);
              }
            }
          }
        }
      });
    });
    var p = processStaffLeaveData(
      firebase.secondaryDb,
      report,
      startDate,
      endDate,
      firebase.schoolConfig.timezone,
      firebase
    );

    p.then((response) => {
      executeExcelDownload(report, fields, "StaffAttendance", startDate, endDate);
    });
    try {
    } catch (err) {
      console.log("failed to fetch staff attendance record", err);
    }
  }
}

function executeExcelDownload(report, fields, fName, startDate, endDate) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName =
    fName + moment(startDate).format("DD-MMM-YY") + "-" + moment(endDate).format("DD-MMM-YY");

  var ws = XLSX.utils.json_to_sheet(report, { header: fields });

  const wb = {
    Sheets: { data: ws },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], {
    type: fileType,
  });
  FileSaver.saveAs(data, fileName + fileExtension);
}

function* downloadStaffAttendanceDaywise({
  value,
  atdType,
  downloadType,
  firebase,
  newAttendance,
}) {
  if (newAttendance) {
    yield fork(downloadStaffAttendanceRecordDaywise, value, atdType, downloadType, firebase);
  } else {
    let startDate = value.dateRange[0];
    let endDate = value.dateRange[1];

    if (downloadType === "checkInOut") {
      yield fork(downloadStaffCheckInOutDaywise, startDate, endDate, firebase);
    } else {
      try {
        var report = [];
        var checkInRef = firebase.secondaryDb.ref(firebase.sbp + "/teacherAttendanceUpdates");

        checkInRef.once("value", function (snapshot) {
          if (snapshot) {
            snapshot.forEach(function (dateSnap) {
              dateSnap.forEach(function (data) {
                // console.log("staff record", data.val());
                if (data.val() && data.val().id) {
                  processStaffCheckInForDate(
                    data,
                    report,
                    startDate,
                    endDate,
                    firebase.schoolConfig.timezone,
                    snapshot.key
                  );
                }
              });
            });
            var reportLeave = [];
            var p = processStaffLeaveData(
              firebase.secondaryDb,
              reportLeave,
              startDate,
              endDate,
              firebase.schoolConfig.timezone,
              firebase
            );

            p.then((results) => {
              report = report.concat(reportLeave);
              // console.log("Final Report", report);
              const fields = [
                "Classname",
                "StaffName",
                "AttendanceDate",
                "CheckInTime",
                "CheckOutTime",
                "Attendance",
                "Temperature",
              ];

              const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const fileExtension = ".xlsx";
              const fileName =
                "StaffAttendance" +
                moment(startDate).format("DD-MMM-YY") +
                "-" +
                moment(endDate).format("DD-MMM-YY");
              var ws = XLSX.utils.json_to_sheet(report, { header: fields });

              const wb = {
                Sheets: { data: ws },
                SheetNames: ["data"],
              };
              const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
              });
              const data = new Blob([excelBuffer], { type: fileType });
              FileSaver.saveAs(data, fileName + fileExtension);
            });
          }
        });
      } catch (err) {
        console.log("failed to download monthly attendance", err);
        bugsnagClient.notify(err);
        yield put({
          type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
        });
      }
    }
  }
}

function computeLeavesDays(leaves, date) {
  let endDateOfMonth = moment(date).endOf("month");
  let startOfMonth = moment(date).startOf("month");
  let leavesDaysMap = new Map();
  leaves.forEach((l) => {
    let leaveStartDate = moment(l.startDate);
    let leaveEndDate = moment(l.endDate);
    let daysOfThisMonth;
    console.log("leaveStart date ", moment(leaveStartDate).format("DD-MM-YY"));

    // check leave

    let wholeLeaveOfSameMonth =
      moment(leaveStartDate).isBetween(startOfMonth, endDateOfMonth, "day", "[]") &&
      moment(leaveEndDate).isBetween(startOfMonth, endDateOfMonth, "[]", "day");
    let leaveStartFromSameMonth =
      moment(leaveStartDate).isBetween(startOfMonth, endDateOfMonth, "day", "[]") &&
      !moment(leaveEndDate).isBetween(startOfMonth, endDateOfMonth, "day", "[]");
    let leaveEndInSameMonth =
      moment(leaveEndDate).isBetween(startOfMonth, endDateOfMonth, "day", "[]") &&
      !moment(leaveStartDate).isBetween(startOfMonth, endDateOfMonth, "day", "[]");

    //

    if (wholeLeaveOfSameMonth) {
      daysOfThisMonth = Math.ceil(moment.duration(leaveEndDate.diff(leaveStartDate)).asDays() + 1);
    } else if (leaveStartFromSameMonth) {
      daysOfThisMonth = Math.ceil(moment.duration(endDateOfMonth.diff(leaveStartDate)).asDays());
    } else if (leaveEndInSameMonth) {
      daysOfThisMonth = Math.ceil(moment.duration(leaveEndDate.diff(startOfMonth)).asDays());
    } else {
      daysOfThisMonth = Math.ceil(moment.duration(endDateOfMonth.diff(startOfMonth)).asDays());
    }

    if (leavesDaysMap.has(l.studentId)) {
      let totalDays = daysOfThisMonth + leavesDaysMap.get(l.studentId);
      leavesDaysMap.set(l.studentId, totalDays);
    } else {
      leavesDaysMap.set(l.studentId, daysOfThisMonth);
    }
  });

  return leavesDaysMap;
}
function getAllDatesBetween(startDate, endDate, date) {
  var now = startDate.clone(),
    dates = [];
  let startOfMonth = moment(date).startOf("month");
  let endOfMonth = moment(date).endOf("month");
  while (now.isSameOrBefore(endDate)) {
    if (moment(now).isBetween(startOfMonth, endOfMonth, "day", "[]"))
      dates.push({
        date: now.valueOf(),
        type: "leave",
      });
    now.add(1, "days");
  }
  return dates;
}
function leavesDetail(leaves, date) {
  let leaveDates = [];
  leaves.forEach((l) => {
    let dates = getAllDatesBetween(moment(l.startDate), moment(l.endDate), date);
    leaveDates = [...leaveDates, ...dates];
  });
  return leaveDates;
}
function* fetchStaffLeaves({ date, firebase, leaveType, id }) {
  try {
    if (leaveType.toLowerCase() === "teacher") {
      let obj = {
        staffId: id,
        startDate: moment(date).startOf("month").valueOf(),
        endDate: moment(date).endOf("month").valueOf(),
      };

      let url = "woodlandApi/totalLeaveStatsOfStaffsForDateRange/" + "?centerId=";

      let resp = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);
      if (resp && resp.status && resp.status === 200) {
        resp.body.staffLeavesCount = new Map(Object.entries(resp.body.staffLeavesCount));

        let response = resp.body.appliedLeaves;
        let leavesDaysData = resp.body.staffLeavesCount;
        let leavesDate = resp.body.staffLeavesDates;
        yield put({
          type: actions.GET_STAFF_LEAVES_SUCCESS,
          payLoad: {
            response,
            leavesDaysData,
            leavesDate,
          },
        });
      } else {
        notification(
          "error",
          resp && resp.body && resp.body.response
            ? resp.body.response
            : "Failed to fetch leave stats for the date range. Contact school or Illumine"
        );
        yield put({
          type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
          payLoad: false,
        });
      }
    } else {
      let response = yield call(
        StaffAttendanceApi.getStaffLeavesOfMonth,
        date,
        firebase,
        leaveType,
        id
      );
      let leavesDaysData = computeLeavesDays(response, date);
      let leavesDate = leavesDetail(response, date);
      yield put({
        type: actions.GET_STAFF_LEAVES_SUCCESS,
        payLoad: {
          response,
          leavesDaysData,
          leavesDate,
        },
      });
    }
  } catch (err) {
    console.log("failed to fetch logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      payLoad: false,
    });
  }
}

function* downloadStaffCheckInOutDaywise(startDate, endDate, firebase) {
  try {
    const fields = ["staffName", "className"];
    var report = [];
    for (var m = moment(startDate); m.diff(endDate, "days") <= 0; m.add(1, "days")) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log("Processing Attendance Report for Date range: " + startDate + " - " + endDate);
    var checkInRef = firebase.secondaryDb.ref(firebase.sbp + "/staffAttendanceReference");
    checkInRef.once("value", function (snapshot) {
      if (snapshot) {
        //iterate for each student now
        snapshot.forEach(function (stuSnap) {
          processAttendanceForStaff(
            startDate,
            endDate,
            stuSnap,
            firebase.schoolConfig.timezone,
            report
          );
        });
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName =
          "StaffCheckInOutAttendance" +
          moment(startDate).format("DD-MMM-YY") +
          "-" +
          moment(endDate).format("DD-MMM-YY");

        var ws = XLSX.utils.json_to_sheet(report, { header: fields });

        const wb = {
          Sheets: { data: ws },
          SheetNames: ["data"],
        };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        //var p = processLeaveData(firebase.secondaryApp,report, startDate, endDate, timezone);

        // p.then(results =>{
        //     console.log("Final Report", report);
        //     const parser = new Json2csvParser(opts);
        //     const csv = parser.parse(report);
        //     console.log(csv);
        //     sendEmailCSVReport(email, ccEmail, csv);
        //     response.status(200).send();
        // })
      }
    });
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}
function processAttendanceForStaff(startDate, endDate, staffAttendanceSnapList, timezone, report) {
  var staffRow = {};
  staffAttendanceSnapList.forEach((monthSnap) => {
    //console.log("month key",monthSnap.key);
    monthSnap.forEach((attSnap) => {
      //  console.log("student",attSnap.numChildren());
      var attRecord = attSnap.val();
      if (attRecord && attRecord.id) {
        if (
          moment
            .tz(attRecord.date, timezone)
            .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "day", "[]")
        ) {
          staffRow["staffName"] = attRecord.userName;
          staffRow["className"] = attRecord.classrooms ? attRecord.classrooms.toString() : "";
          var dateString = moment.tz(attRecord.date, timezone).format("YYYY-MM-DD");
          staffRow[dateString + "-IN"] = attRecord.checkInTime ? attRecord.checkInTime : "";
          staffRow[dateString + "-OUT"] = attRecord.checkOutTime ? attRecord.checkOutTime : "";
          // console.log("student");
        }
      }
    });
  });
  console.log("staff row", staffRow);
  if (JSON.stringify(staffRow) != JSON.stringify({})) report.push(staffRow);
}
function processStaffCheckInForDate(
  checkInRecordSnap,
  report,
  startDate,
  endDate,
  timezone,
  dateString
) {
  var data1 = checkInRecordSnap.val();
  console.log("Checkin Date", data1, moment.tz(data1.date, timezone).format("DD-MM-YYYY"));
  if (
    moment
      .tz(data1.date, timezone)
      .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "day", "[]")
  ) {
    var checkIn = {};
    checkIn.Classname = data1.classrooms ? data1.classrooms.toString() : "";
    checkIn.StaffName = data1.userName;
    checkIn.AttendanceDate = moment.tz(data1.date, timezone).format("DD/MM/YYYY");
    if (!data1.absent) {
      checkIn.CheckInTime = data1.checkInTime;
      checkIn.CheckOutTime = data1.checkOutTime;
      checkIn.Attendance = "Present";
      checkIn.Temperature = data1.temperature;
    } else {
      checkIn.Attendance = "Absent";
      checkIn.Temperature = "NA";
    }
    report.push(checkIn);
  }
  console.log("Final Report", report);
}

function processStaffLeaveData(secondaryDb, report, startDate, endDate, timezone, firebase) {
  return secondaryDb
    .ref(firebase.sbp + "/staffLeaveReference")
    .once("value")
    .then((snap) => {
      if (snap.val()) {
        console.log("got leaves data", snap.numChildren());
        snap.forEach((sn1) => {
          var record = sn1.val();
          if (
            moment
              .tz(record.startDate, timezone)
              .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "day", "[]")
          ) {
            var diffDays = moment
              .tz(record.startDate, timezone)
              .diff(moment.tz(record.endDate, timezone), "days");
            for (var i = 0; i <= diffDays; i++) {
              var leaveDate = moment.tz(record.startDate, timezone).add(i, "day");
              if (leaveDate.isAfter(moment.tz(endDate, timezone))) {
                //console.log("ignore as date is not indate range");
              } else {
                var checkIn = {};
                checkIn.Classname = record.classRoom;
                checkIn.StaffName = record.student;
                checkIn.AttendanceDate = leaveDate.format("DD/MM/YYYY");
                checkIn.Attendance = "Leave";
                checkIn.Temperature = "NA";
                report.push(checkIn);
                //console.log("adding leave", checkIn);
              }
            }
          } else {
            // console.log(
            //   "leave is not between start & end",
            //   moment.tz(startDate, timezone).format("DD/MM/YY"),
            //   moment.tz(endDate, timezone).format("DD/MM/YY"),
            //   moment.tz(record.startDate, timezone).format("DD/MM/YY")
            // );
          }
        });
      }
    });
}

function* fetchStaffMonthlyAttendance({ date, firebase }) {
  try {
    let atdRecord = [];
    let data = yield call(StaffAttendanceApi.getStaffAttendanceByMonth, date, firebase);
    if (data) {
      for (let [k, value] of data) {
        console.log(k);
        let data = {};
        let daysAttended = 0;
        let hoursAttended = 0;
        let daysUnattended = 0;
        let lateCheckInCount = 0;
        let lateCheckOutCount = 0;

        for (let index in value) {
          let eleVal = value[index];

          data.name = eleVal.userName;
          data.className = eleVal.classrooms;
          data.gender = eleVal.gender;
          data.userId = eleVal.userId;
          if (!eleVal.absent && eleVal.checkInEpoch && eleVal.checkInEpoch > 0) {
            daysAttended++;
          } else if (eleVal.absent) {
            daysUnattended++;
          }

          if (eleVal.late !== undefined && eleVal.late === true) {
            lateCheckInCount++;
          }

          if (eleVal.lateCheckout !== undefined && eleVal.lateCheckout === true) {
            lateCheckOutCount++;
          }

          if (eleVal.checkInEpoch && eleVal.checkOutEpoch && !eleVal.absent) {
            let a = moment(eleVal.checkOutEpoch);
            let b = moment(eleVal.checkInEpoch);
            let diff = moment.duration(a.diff(b)).as("hours");
            hoursAttended = hoursAttended + diff;
          }

          data.daysAttended = daysAttended;
          data.hoursAttended = hoursAttended;
          data.daysUnattended = daysUnattended;
          data.lateCheckInCount = lateCheckInCount;
          data.lateCheckOutCount = lateCheckOutCount;
        }
        atdRecord.push(data);
      }
    }
    yield put({
      type: actions.GET_STAFF_MONTHLY_ATD_SUCCESS,
      staffMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch staff monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch staff monthly attendance" + err.message ? err.message : err
    );
  }
}

function* markStaffRecordPending(selectedStaff, date, firebase) {
  try {
    let staffIds = [];

    selectedStaff.forEach((std) => {
      staffIds.push(std.id);
    });

    let obj = {
      date: moment(date).valueOf(),
      staffIds: staffIds,
    };

    let url = "woodlandApi/attendancereset/staff?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_STAFF_PENDING_SUCCESS,
      });
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark staff record pending", err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStaffsPending({ selectedStaff, date, firebase, newAttendance }) {
  if (newAttendance) {
    yield fork(markStaffRecordPending, selectedStaff, date, firebase);
  } else {
    try {
      for (let i = 0; i < selectedStaff.length; i++) {
        let data = yield call(
          StaffAttendanceApi.getStaffAttendanceById,
          date,
          selectedStaff[i].id,
          firebase
        );
        if (data) {
          yield fork(
            StudentAttendanceApi.updatedAttendanceRecordApi,
            data,
            firebase,
            "updateAttendanceStaff",
            "markPending"
          );
        }

        yield call(StaffAttendanceApi.markStaffPending, selectedStaff[i], date, firebase);
        yield call(
          StaffAttendanceApi.removeStaffRecordFromStaffCheckInOutReference,
          selectedStaff[i],
          date,
          firebase
        );

        if (moment(date).isSame(moment(), "day")) {
          yield fork(
            StaffAttendanceApi.updateTeacherLastAtd,
            selectedStaff[i].id,
            undefined,
            firebase
          );

          yield fork(
            StaffAttendanceApi.updateTeacherLastAtdCheckout,
            selectedStaff[i].id,
            undefined,
            firebase
          );
        }
        //fetch and add this call incase of pending
        //yield fork(StudentAttendanceApi.updatedAttendanceRecordApi, checkInOutObject, firebase, "updateAttendanceStaff");
      }
      yield put({
        type: actions.MARK_STAFF_PENDING_SUCCESS,
      });
    } catch (err) {
      console.log("failed to mark staffs back to pending list", err);
      bugsnagClient.notify(err);
    }
  }
}

function* downloadAttendanceMonthly({ record, firebase, newAttendance }) {
  try {
    console.log(":record", record);
    let report = [];
    var fields = [];
    if (newAttendance) {
      fields = ["name", "classroom", "present", "absent", "leave", "presentPercentage"];

      for (let index in record) {
        let singleRecord = record[index];
        var row = {};
        row.name = singleRecord.name;
        row.classroom = singleRecord.classList.toString();
        row.present = singleRecord.daysAttended;
        row.absent = singleRecord.daysUnattended;
        row.leave = singleRecord.leaveDays;
        row.presentPercentage = singleRecord.presentPercentage;
        report.push(row);
      }
    } else {
      fields = [
        "name",
        "classroom",
        "present",
        "absent",
        "lateCheckIn",
        "lateCheckOut",
        "hoursAttended",
      ];

      for (let index in record) {
        let singleRecord = record[index];
        var row = {};
        row.name = singleRecord.name;
        row.classroom = singleRecord.className ? singleRecord.className.toString() : "";
        row.present = singleRecord.daysAttended;
        row.absent = singleRecord.daysUnattended;
        row.lateCheckIn = singleRecord.lateCheckInCount;
        row.lateCheckOut = singleRecord.lateCheckOutCount;
        row.hoursAttended = singleRecord.hoursAttended;
        report.push(row);
      }
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "StaffMonthlyAttendance";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (err) {
    console.log("failed to download staff attendance monthly", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STAFF_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchStaffNewMonthlyAttendance({ date, firebase }) {
  try {
    let teachers = JSON.parse(getItem("teacherList"));
    let teachersMap = new Map();
    teachers.forEach((std) => {
      teachersMap.set(std.id, std);
    });

    let atdRecord = [];
    let data = yield call(StaffAttendanceApi.getStaffNewAttendanceByMonth, date, firebase);

    if (data && data.size > 0) {
      for (let [k, value] of data) {
        let data = {};
        let daysAttended = 0;
        let daysUnattended = 0;

        if (teachersMap.has(k)) {
          let teacher = teachersMap.get(k);
          for (let index in value) {
            let eleVal = value[index];

            data.name = teacher.name;
            data.classList = teacher.classList ? teacher.classList : [];
            data.gender = teacher.gender;
            data.userId = teacher.id;
            data.role = teacher.role;

            if (eleVal.roomRecords) {
              daysAttended++;
            } else {
              if (eleVal.absent) {
                daysUnattended++;
              }
            }

            data.daysAttended = daysAttended;
            data.daysUnattended = daysUnattended;
            let presentPercent = (daysAttended / value.length) * 100;
            if (presentPercent > 0) {
              data.presentPercentage = Math.round(presentPercent);
            } else {
              data.presentPercentage = 0;
            }
          }
          atdRecord.push(data);
        }
      }
    } else {
      teachers.forEach((teacher) => {
        let data = {};
        data.name = teacher.name;
        data.classList = teacher.classList ? teacher.classList : [];
        data.gender = teacher.gender;
        data.userId = teacher.id;
        data.role = teacher.role;
        data.daysAttended = "-";
        data.daysUnattended = "-";
        data.presentPercentage;
        ("-");
        atdRecord.push(data);
      });
    }

    yield put({
      type: actions.GET_STAFF_NEW_MONTHLY_ATD_SUCCESS,
      staffNewMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch staff new monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch staff monthly attendance" + err.message ? err.message : err
    );
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_STAFF_ATTENDANCE, fetchStaffAttendance),
    yield takeLatest(actions.FETCH_ALL_STAFF, getStaffs),
    yield takeLatest(actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE, getClassrooms),
    yield takeLatest(actions.MARK_STAFF_PRESENT, markStaffsPresent),
    yield takeLatest(actions.MARK_STAFF_CHECKOUT, markStaffsCheckoutTime),
    yield takeLatest(actions.MARK_STAFF_ABSENT, markStaffsAbsent),
    yield takeLatest(actions.FETCH_STAFF_BY_CLASSNAME, getStaffByClassroomName),
    yield takeLatest(actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME, fetchStaffAttendanceByClassName),
    yield takeLatest(actions.EMAIL_STAFF_ATTENDANCE, downloadStaffAttendanceDaywise),
    yield takeLatest(actions.GET_STAFF_MONTHLY_ATD, fetchStaffMonthlyAttendance),
    yield takeLatest(actions.MARK_STAFF_PENDING, markStaffsPending),
    yield takeLatest(actions.DOWNLOAD_STAFF_ATD_MONTHLY, downloadAttendanceMonthly),
    yield takeLatest(actions.GET_STAFF_NEW_ATTENDANCE, fetchStaffNewAttendance),
    yield takeLatest(actions.GET_STAFF_NEW_MONTHLY_ATD, fetchStaffNewMonthlyAttendance),
    yield takeLatest(actions.GET_STAFF_LEAVES, fetchStaffLeaves),
  ]);
}
