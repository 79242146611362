import actions from "./actions";
const initState = {
  isLoading: true,
  registers: [],
  error: false,
  expenseLogs: [],
  labels: [],
  logChannel: undefined,
  registerChannel: undefined,
  expenseById: {},
  registerById: {},
  expenseChanById: {},
};

export default function ExpenseManagementReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SETLODING:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case actions.GET_REGISTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        registers: action.payLoad.RegisterWithLogAmount,
        registerChannel: action.payLoad.chan,
      };
    case actions.GET_EXPENSE_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expenseLogs: action.payLoad.data,
        logChannel: action.payLoad.chan,
      };
    case actions.GET_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        labels: action.payLoad,
      };
    case actions.GET_EXPENSE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expenseById: action.payLoad.expense,
        registerById: action.payLoad.register,
        expenseChanById: action.payLoad.expenseChanById,
      };
    case actions.GET_REGISTER_BY_ID_SUCCESS: {
      return {
        ...state,
        registerById: action.payLoad.register,
        isLoading: false
      }
    }
    case actions.RESET: {
      return initState
    }
    default:

      return state;
  }
}
