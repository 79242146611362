import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";

function createNewScheduleNode(classroom, date, firebase) {
  let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/schedule/" + classroom + "/" + newDate).push().key;
  return key;
}

function uploadScheduleCsv(classroom, scheduleCsvData, date, nodeId, firebase) {
  let actDate = moment(date, [
    "D/MM/YYYY",
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    "YYYY/M/D",
    "YYYY-M-D",
  ]);
  let newDate = moment(actDate).format("YYYY[-]MM[-]DD");


  /**new change */

  let scheduleDate = new Date(actDate);
  let dateString = scheduleCsvData.startTime;

  let splittedDateString = dateString.split(":");
  let hour = splittedDateString[0];

  let formattedHour = hour === "12" ? "0" : hour;

  let isAMPM = splittedDateString[1].toLowerCase().includes("am") ? "am" : "pm";

  let splittedMinuteString = splittedDateString[1]
    .substring(0, splittedDateString[1].toLowerCase().indexOf(isAMPM))
    .trim();

  let minute = splittedMinuteString;

  let formattedhr = isAMPM === "pm" ? Number(formattedHour) + 12 : hour;

  let scheduleTime = moment(newDate).set("hour", formattedhr).set("minute", minute).valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/schedule/" + classroom + "/" + newDate + "/" + nodeId)
    .update({
      id: nodeId,
      description: scheduleCsvData.description,
      classname: classroom,
      name: scheduleCsvData.title,
      scheduleType: scheduleCsvData.category,
      timestamp: scheduleTime,
    })
    .catch((err) => { });
}


function deleteMultipleSchedules(values, classes, editableRecord, firebase) {
  let initialDate = values.startDate.clone();

  var endDate = values.endDate
    ? values.endDate.clone()
    : values.startDate.clone();

  if (editableRecord) {
    var classList = classes;
    var type = [editableRecord.scheduleType];
    var title = editableRecord.name;
    var scheduleNewStartDate = values.startDate.clone().format("YYYY-MM-DD");
    var scheduleNewEndDate = endDate.format("YYYY-MM-DD");
    var description = editableRecord.description ? editableRecord.description : null;
  } else {
    var classList = values.classroom;
    var type = values.type;
    var title = values.title;
    var description = values.description ? values.description : null;
  }

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  for (let index in classList) {
    let startDate = initialDate.clone();
    while (moment(startDate, "day").isSameOrBefore(endDate, "day")) {
      rsf
        .ref(
          branchPath +
          "/schedule/" +
          classList[index] +
          "/" +
          startDate.format("YYYY-MM-DD")
        )
        .once("value", (snap) => {
          snap.forEach((item) => {
            var schedule = item.val();

            if (
              editableRecord &&
              moment(editableRecord.timestamp).format("hh:mm a") !==
              moment(schedule.timestamp).format("hh:mm a")
            ) {
              return;
            }

            if (!type.includes(schedule.scheduleType)) {
              return;
            }

            if (
              description &&
              schedule.description &&
              schedule.description.toLowerCase().trim() !==
              description.toLowerCase().trim()
            ) {
              return;
            }

            if (title && schedule.name.toLowerCase().trim() !== title.toLowerCase().trim()) {
              return;
            }
            var node = rsf
              .ref(
                branchPath +
                "/schedule/" +
                classList[index] +
                "/" +
                startDate.format("YYYY-MM-DD")
              )
              .child(schedule.id);
            if (!editableRecord) {
              node.set(null);
            } else {
              node.update({
                classname: classList[index],
                description: values.description ? values.description : null,
                id: schedule.id,
                name: values.title,
                scheduleType: values.type,
                lastUpdatedOn: moment().valueOf(),
                platform: "web",
                teacherIds: values.taggedTeachers ? values.taggedTeachers : null,
                timestamp: moment(startDate)
                  .set("hour", moment(values.startTime).get("hour"))
                  .set("minute", moment(values.startTime).get("minute"))
                  .set("second", moment(values.startTime).get("second"))
                  .valueOf(),

                endTime: values.endTime
                  ? moment(startDate)
                    .set("hour", moment(values.endTime).get("hour"))
                    .set("minute", moment(values.endTime).get("minute"))
                    .set("second", moment(values.endTime).get("second"))
                    .valueOf()
                  : null,
                startDate: values.startDate.format("YYYY-MM-DD"),
                endDate: values.endDate.format("YYYY-MM-DD"),
              });
            }
          });
        });

      startDate = startDate.add(1, "day");
    }
  }
}

function getScheduleByDate(date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/schedule").on("value", (snap) => {
      var allSchedule = new Map();
      snap.forEach((item) => {
        let scheduleValue = [];
        item.forEach((ele) => {
          if (ele.key === date) {
            ele.forEach((newEle) => {
              if (newEle.val() !== null) {
                scheduleValue.push(newEle.val());
              }
            });
          }
        });

        allSchedule.set(item.key, scheduleValue);
      });

      emitter(allSchedule);
    });
    return () => {
      rsf.ref(branchPath + "/schedule").off();
      console.log("END");
    };
  });
}

function deleteSchedule(schedule, date, className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/schedule/" + className + "/" + date + "/" + schedule.id).set(null);
}

function addSchedule(
  className,
  date,
  type,
  title,
  description,
  startTime,
  endTime,
  id,
  firebase,
  teacherIdList,
  startDate,
  endDate
) {

  let startScheduleTime = moment(startTime);
  let scheduleStartTime = moment(date)
    .set("hour", startScheduleTime.get("hour"))
    .set("minute", startScheduleTime.get("minute"))
    .set("second", startScheduleTime.get("second"))
    .valueOf();
  if (endTime) {
    let endScheduleTime = moment(endTime);
    var scheduleEndTime = moment(date)
      .set("hour", endScheduleTime.get("hour"))
      .set("minute", endScheduleTime.get("minute"))
      .set("second", endScheduleTime.get("second"))
      .valueOf();
  }

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/schedule/" + className + "/" + date + "/" + id).update({
    classname: className,
    description: description ? description : null,
    id: id,
    name: title,
    scheduleType: type,
    timestamp: scheduleStartTime,
    endTime: scheduleEndTime ? scheduleEndTime : null,
    lastUpdatedOn: moment().valueOf(),
    platform: "web",
    teacherIds: teacherIdList ? teacherIdList : null,
    startDate: startDate,
    endDate: endDate,
  });
}

function deleteSelectedDatesScheduleForClasses(start, end, classVal, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  while (start.isSameOrBefore(end, "day")) {
    let dateFormat = start.format("YYYY[-]MM[-]DD");
    rsf.ref(branchPath + "/schedule/" + classVal + "/" + dateFormat).set(null);
    start = moment(start).add(1, "day");
  }
}

export const ScheduleApi = {
  uploadScheduleCsv,
  createNewScheduleNode,
  getScheduleByDate,
  deleteSchedule,
  addSchedule,
  deleteMultipleSchedules,
  deleteSelectedDatesScheduleForClasses,
};
