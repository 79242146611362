import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import * as FileSaver from "file-saver";
import moment from "moment";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import * as XLSX from "xlsx";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { ComplainsApi } from "../../firestore-api/consult";
import { NotificationApi } from "../../firestore-api/notification";
import { ProgramApi } from "../../firestore-api/program";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { getItem } from "../../Utility/encryptedStorage";
import FilterAction from "../../Utility/FilterAction";
import actions from "./actions";

function* fetchStudentNewAttendance({ date, firebase, operationType }) {
  try {
    const chan = yield call(StudentAttendanceApi.getNewAttendance, date, firebase);
    while (true) {
      let data = yield take(chan);
      // console.log("fetchStudentNewAttendance------", data);
      yield put({
        type: actions.GET_STUDENT_NEW_ATTENDANCE_SUCCESS,
        studentNewAttendance: data,
        newAttendanceChannel: chan,
        operationType: operationType,
      });
    }
  } finally {
    console.log("end attendance channel");
  }
}

function* fetchStudentAttendance({ date, firebase, operationType }) {
  const chan = yield call(StudentAttendanceApi.getAttendance, date, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STUDENT_ATTENDANCE_SUCCESSFUL,
        studentAttendance: data,
        attendanceChannel: chan,
        operationType: operationType,
        //operationType: "INITIAL_STUDENT_ATD_FETCH"
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

function* getStudents({ firebase, date }) {
  try {
    //let data = yield call(AssessmentApi.getAllStudents, firebase);
    let startTime = moment(date).startOf("day").valueOf();
    let endTime = moment(date).endOf("day").valueOf();

    let leaveIds = yield call(
      ComplainsApi.getCurrentDateStudentLeave,
      firebase,
      startTime,
      endTime
    );

    let students = FilterAction.getStudentList(firebase);
    let data = students.filter((item) => {
      return (
        (!item.status || item.status.toLowerCase() === "active") &&
        !item.deactivated &&
        !leaveIds.includes(item.id)
      );
    });

    let studentsMap = new Map();
    students.forEach((std) => {
      if ((!std.status || std.status.toLowerCase() === "active") && !std.deactivated) {
        studentsMap.set(std.id, std);
      }
    });

    if (students) {
      yield put({
        type: actions.FETCH_ALL_STUDENT_SUCCESSFUL,
        allStudents: data,
        studentsMap: studentsMap,
        studentsOnLeave: leaveIds,
      });
    }
  } catch (error) {
    console.log("failed to fetch studens", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* sendNotificationToTeacher(
  selectedTeachers,
  selectedStudent,
  selectedClassroom,
  atdType,
  atdTime,
  studentIds,
  firebase
) {
  try {
    let alertMsg = "";

    if (selectedStudent.length > 1) {
      if (atdType === "checked-in") {
        alertMsg =
          selectedStudent.length +
          " students have been checked-in to " +
          selectedClassroom +
          "  on " +
          moment(atdTime).format("DD MMM YY");
      } else if (atdType === "checked-out") {
        alertMsg =
          selectedStudent.length +
          " students have been checked-out on " +
          moment(atdTime).format("DD MMM YY");
      }
    } else {
      if (atdType === "checked-in") {
        alertMsg =
          selectedStudent[0].name +
          " has been checked-in to " +
          selectedClassroom +
          "  on " +
          moment(atdTime).format("DD MMM YY");
      } else if (atdType === "checked-out") {
        alertMsg =
          selectedStudent[0].name +
          " has been checked-out on " +
          moment(atdTime).format("DD MMM YY");
      }
    }

    let teachers = JSON.parse(getItem("teacherList"));
    for (let index in selectedTeachers) {
      let teacherId = selectedTeachers[index];
      let filteredTeachers = teachers.filter((t) => {
        return t.id === teacherId;
      });

      if (filteredTeachers && filteredTeachers.length > 0) {
        let singleTeacher = filteredTeachers[0];

        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          singleTeacher.id,
          firebase
        );

        let obj = {
          activityName: "Room Notification",
          androidId: singleTeacher.uid ? singleTeacher.uid : null,
          iosId: singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
          body: alertMsg,
          id: alertNode,
          inverseTime: -new Date().getTime(),
          nodeId: "",
          read: false,
          senderName: firebase.teacher.name,
          type: "Room Notification",
          userType: "teacher",
          teacherId: singleTeacher.id,
          ids: studentIds,
          room: selectedClassroom ? selectedClassroom : null,
        };

        yield fork(
          NotificationApi.createSimpleAlertNotification,
          obj.activityName,
          obj.nodeId,
          obj.androidId,
          obj.body,
          alertNode,
          obj.iosId,
          undefined,
          obj.teacherId,
          obj.type,
          undefined,
          firebase,
          undefined,
          obj.userType,
          undefined,
          obj.room,
          obj.ids
        );

        if (obj.androidId !== undefined || obj.iosId !== undefined) {
          yield fork(
            NotificationApi.sendSimplePushNotification,
            obj.activityName,
            obj.nodeId,
            obj.androidId,
            obj.body,
            alertNode,
            obj.iosId,
            undefined,
            obj.teacherId,
            obj.type,
            undefined,
            firebase,
            undefined,
            obj.userType,
            undefined,
            obj.room,
            obj.ids
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to send attendance notification to teacher", err);
    bugsnagClient.notify(err);
  }
}

function* markStudentRecordPresent(
  selectedStudent,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
  note,
  selectedClassroom,
  sendNotification,
  selectedTeachers
) {
  try {

    let selectedTime = moment(time);
    let atdTime = moment(date)
      .set("hour", selectedTime.get("hour"))
      .set("minute", selectedTime.get("minute"))
      .set("second", selectedTime.get("second"));

    let studentIds = [];
    let classroomList = [];
    selectedStudent.forEach((std) => {
      studentIds.push(std.id);
    });


    if (selectedClassroom) {
      yield doAttendanceOperation(
        firebase,
        atdTime,
        studentIds,
        selectedClassroom,
        temperature,
        note,
        checkInOutStatus,
        selectedStudent,
        selectedTeachers,
        sendNotification,
        date
      );
    } else {
      yield doAttendanceBulkOperation(
        firebase,
        atdTime,
        studentIds,
        temperature,
        note,
        checkInOutStatus,
        selectedStudent,
        selectedTeachers,
        sendNotification,
        date
      );
    }
  } catch (err) {
    console.log("failed to mark student record present", err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* doAttendanceBulkOperation(
  firebase,
  atdTime,
  studentIds,
  temperature,
  note,
  checkInOutStatus,
  selectedStudent,
  selectedTeachers,
  sendNotification,
  date
) {
  var requestList = [];
  for (let i = 0; i < selectedStudent.length; i++) {
    var selectedClassroom = selectedStudent[i].classList[0];
    let obj = {
      date: atdTime,
      studentIds: [selectedStudent[i].id],
      roomRecord: {
        name: selectedClassroom,
        updatedBy: firebase.teacher.name,
        checkinTime: atdTime.valueOf(),
        temperature: temperature,
        note: note,
        late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
      },
    };
    requestList.push(obj);
  }

  let url = "woodlandApi/checkinList/student?centerId=";
  let response = yield call(StudentAttendanceApi.requestApi, requestList, url, firebase);

  if (response && response.status && response.status === 200) {
    yield put({
      type: actions.MARK_PRESENT_SUCCESSFUL,
    });

    if (selectedTeachers) {
      yield fork(
        sendNotificationToTeacher,
        selectedTeachers,
        selectedStudent,
        selectedClassroom,
        "checked-in",
        date,
        studentIds,
        firebase
      );
    }

    if (sendNotification) {
      for (let i = 0; i < selectedStudent.length; i++) {
        yield sendNotificationForAttendance(firebase, selectedStudent[i], date);
      }
    }
  } else {
    if (response && response.body && response.body.response) {
      notification("error", response.body.response);
    }
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* doAttendanceOperation(
  firebase,
  atdTime,
  studentIds,
  selectedClassroom,
  temperature,
  note,
  checkInOutStatus,
  selectedStudent,
  selectedTeachers,
  sendNotification,
  date
) {
  let obj = {
    date: atdTime,
    studentIds: studentIds,
    roomRecord: {
      name: selectedClassroom,
      updatedBy: firebase.teacher.name,
      checkinTime: atdTime.valueOf(),
      temperature: temperature,
      note: note,
      late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
    },
  };

  let url = "woodlandApi/checkin/student?centerId=";
  let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

  if (response && response.status && response.status === 200) {
    yield put({
      type: actions.MARK_PRESENT_SUCCESSFUL,
    });

    if (selectedTeachers) {
      yield fork(
        sendNotificationToTeacher,
        selectedTeachers,
        selectedStudent,
        selectedClassroom,
        "checked-in",
        date,
        studentIds,
        firebase
      );
    }

    if (sendNotification) {
      for (let i = 0; i < selectedStudent.length; i++) {
        yield sendNotificationForAttendance(firebase, selectedStudent[i], date);
      }
    }
  }
}
function* sendNotificationForAttendance(firebase, selectedStudent, date) {
  if (selectedStudent.fatherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
    let alertNode = yield call(
      NotificationApi.createAlertReferenceNode,
      selectedStudent.fatherProfileId,
      firebase
    );
    yield fork(
      NotificationApi.createAlertNotification,
      "Attendance",
      null,
      selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
      selectedStudent.name + " was marked present " + "on " + moment(date).format("DD MMM YY"),
      alertNode,
      selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
      selectedStudent.id,
      selectedStudent.fatherProfileId,
      firebase
    );

    if (selectedStudent.fatherUUid !== undefined || selectedStudent.ios_fatherUUid !== undefined) {
      yield fork(
        NotificationApi.sendPushNotification,
        "Attendance",
        null,
        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
        selectedStudent.name + " was marked present " + "on " + moment(date).format("DD MMM YY"),
        alertNode,
        selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
        selectedStudent.id,
        selectedStudent.fatherProfileId,
        firebase
      );
    }
  }

  if (selectedStudent.motherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
    let alertNode = yield call(
      NotificationApi.createAlertReferenceNode,
      selectedStudent.motherProfileId,
      firebase
    );
    yield fork(
      NotificationApi.createAlertNotification,
      "Attendance",
      null,
      selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
      selectedStudent.name + " was marked present " + "on " + moment(date).format("DD MMM YY"),
      alertNode,
      selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
      selectedStudent.id,
      selectedStudent.motherProfileId,
      firebase
    );

    if (selectedStudent.motherUUid !== undefined || selectedStudent.ios_motherUUid !== undefined) {
      yield fork(
        NotificationApi.sendPushNotification,
        "Attendance",
        null,
        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
        selectedStudent.name + " was marked present " + "on " + moment(date).format("DD MMM YY"),
        alertNode,
        selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
        selectedStudent.id,
        selectedStudent.motherProfileId,
        firebase
      );
    }
  }
}

function* markStudentsPresent({
  selectedStudent,
  date,
  time,
  firebase,
  pending,
  checkInOutStatus,
  temperature,
  note,
  newAttendance,
  selectedClassroom,
  sendNotification,
  selectedTeachers,
}) {
  if (newAttendance) {
    yield fork(
      markStudentRecordPresent,
      selectedStudent,
      date,
      time,
      firebase,
      pending,
      checkInOutStatus,
      temperature,
      note,
      selectedClassroom,
      sendNotification,
      selectedTeachers
    );
  } else {
    try {
      for (let i = 0; i < selectedStudent.length; i++) {
        // let nodeId = yield call(StudentAttendanceApi.createUniqueNode, date, firebase);
        let nodeId = selectedStudent[i].id;

        let selectedTime = moment(time);
        let atdTime = moment(date)
          .set("hour", selectedTime.get("hour"))
          .set("minute", selectedTime.get("minute"))
          .set("second", selectedTime.get("second"));

        var checkInOutObject = {
          absent: false,
          checkInTime: moment(atdTime).format("h:mm a"),
          checkInEpoch: moment(atdTime).valueOf(),
          checkOutEpoch: 0,
          className: selectedStudent[i].classroomName
            ? selectedStudent[i].classroomName
            : selectedStudent[i].classList
              ? selectedStudent[i].classList[0]
              : null,
          classList: selectedStudent[i].classList ? selectedStudent[i].classList : [],
          createdBy: firebase.teacher.name,
          date: moment(date).valueOf(),
          deactivated: false,
          fatherProfileId: selectedStudent[i].fatherProfileId
            ? selectedStudent[i].fatherProfileId
            : null,
          fatherUuid: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          motherProfileId: selectedStudent[i].motherProfileId
            ? selectedStudent[i].motherProfileId
            : null,
          motherUuid: selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          gender: selectedStudent[i].gender,
          id: nodeId,
          profileImageUrl: selectedStudent[i].profileImageUrl
            ? selectedStudent[i].profileImageUrl
            : null,
          late: checkInOutStatus && checkInOutStatus === "late" ? true : false,
          lateCheckout: false,
          tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
          userId: selectedStudent[i].id,
          userName: selectedStudent[i].name,
          platform: "web",
          updatedBy: [firebase.teacher.name],
          temperature: temperature ? temperature : null,
          note: note ? note : "",
        };
        yield fork(
          StudentAttendanceApi.markPresentToCheckInOut,
          selectedStudent[i],
          date,
          time,
          nodeId,
          firebase,
          checkInOutObject
        );
        yield fork(
          StudentAttendanceApi.addRecordToStudentAttendance,
          date,
          checkInOutObject,
          selectedStudent[i],
          firebase
        );
        yield fork(
          StudentAttendanceApi.markPresentToAttendanceUpdates,
          selectedStudent[i],
          date,
          time,
          nodeId,
          firebase,
          checkInOutObject
        );

        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          checkInOutObject,
          firebase,
          "updateAttendanceStudent"
        );

        if (moment(date).isSame(moment(), "day")) {
          let dateFormat = moment(atdTime).format(" DD[-]MM[-]YY");
          yield fork(
            StudentAttendanceApi.updateStudentLastAtd,
            selectedStudent[i].id,
            dateFormat,
            firebase
          );
        }

        if (selectedStudent[i].fatherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Attendance",
            null,
            selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            selectedStudent[i].name +
            " was marked present " +
            "on " +
            moment(date).format("DD MMM YY"),
            alertNode,
            selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );

          if (
            selectedStudent[i].fatherUUid !== undefined ||
            selectedStudent[i].ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              "Attendance",
              null,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedStudent[i].name +
              " was marked present" +
              "on" +
              moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent[i].motherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Attendance",
            null,
            selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
            selectedStudent[i].name +
            " was marked present " +
            "on " +
            moment(date).format("DD MMM YY"),
            alertNode,
            selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );

          if (
            selectedStudent[i].motherUUid !== undefined ||
            selectedStudent[i].ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              "Attendance",
              null,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedStudent[i].name +
              " was marked present " +
              "on " +
              moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );
          }
        }

        if (firebase && firebase.schoolConfig && firebase.schoolConfig.attendanceUpdate) {
          yield fork(sendAttendanceUpdateToParent, selectedStudent[i], atdTime, firebase);
        }
      }

      yield put({
        type: actions.MARK_PRESENT_SUCCESSFUL,
      });
      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateDailyPostStats", firebase);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, time, firebase);

      /**no need to auto update as upsent */
      // yield call(updateUnSelectedStudentAttendance, pending, date, firebase);
    } catch (err) {
      console.log("failed to mark student present", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* sendAttendanceUpdateToParent(student, time, firebase) {
  try {
    let activityId = yield call(ActivityApi.generateNewActivityNode, firebase);
    let activityDate = new Date(time);
    let activityType = "Attendance";
    let classNames = student.classList ? student.classList : [student.classroomName];
    let mediaPaths = [];
    let meetingId = null;
    let meetingTime = null;
    let message = student.name + " was marked present";
    let name = "Attendance";
    let templateMessage = null;
    let staffOnly = false;
    let studentIds = [student.id];
    let foodSource = null;
    let meal = null;
    let quantity = null;
    let foodMenu = null;
    let pottyDestination = null;
    let pottyType = null;
    let napStart = null;
    let meetingUrl = null;
    let joinUrl = null;
    let mediaFormat = null;
    let thumbNail = null;
    let youtubeUrlCode = null;
    let repeatStartDate = null;
    let repeatEndDate = null;
    let includeSaturday = false;
    let daysOfWeek = null;
    let meetingCapacity = null;
    let meetingBookable = null;
    let meetingDuration = null;
    let htmlText = null;
    let enableParentComments = false;
    let studentId = student.id;
    let taggedTeachers = [];
    let medicineGivenBy = null;
    let lessonMilestoneValues = null;
    let fieldConfigModels = [];
    let noApproval = false;
    let napEnd = false;
    let newMessage = null;

    yield call(
      ActivityApi.addNewActivityToActivities,
      activityDate,
      activityType,
      classNames,
      mediaPaths,
      meetingId,
      meetingTime,
      message,
      name,
      templateMessage,
      staffOnly,
      studentIds,
      activityId,
      foodSource,
      meal,
      quantity,
      foodMenu,
      pottyDestination,
      pottyType,
      napStart,
      firebase,
      undefined,
      meetingUrl,
      joinUrl,
      mediaFormat,
      thumbNail,
      youtubeUrlCode,
      repeatStartDate,
      repeatEndDate,
      includeSaturday,
      daysOfWeek,
      meetingCapacity,
      meetingBookable,
      meetingDuration,
      htmlText,
      enableParentComments,
      null,
      taggedTeachers,
      medicineGivenBy,
      lessonMilestoneValues,
      false,
      fieldConfigModels,
      noApproval,
      napEnd,
      newMessage
    );

    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", activityDate);

    let studentTimeline = yield call(
      ActivityApi.getStudentTimeline,
      activityDate,
      studentId,
      firebase
    );

    if (studentTimeline) {
      let newActivityIds =
        studentTimeline.activityIds && studentTimeline.activityIds.length > 0
          ? studentTimeline.activityIds
          : [];
      newActivityIds.push(activityId);

      let newTimelineData = {
        activityIds: newActivityIds,
        date: moment().valueOf(),
        inverseDate: -moment().valueOf(),
      };

      yield call(
        ActivityApi.updateStudentTimeline,
        activityDate,
        studentId,
        newTimelineData,
        firebase
      );
    }

    let activityTypeNodeId = yield call(
      ActivityApi.generateActivityTypeNode,
      activityType,
      studentId,
      firebase
    );

    if (activityTypeNodeId) {
      let creator = firebase.teacher.name;
      yield call(
        ActivityApi.updateActivityType,
        activityDate,
        studentId,
        activityId,
        activityTypeNodeId,
        activityType,
        firebase,
        undefined,
        undefined,
        creator
      );
    }

    let names = [
      {
        className: student.classroomName
          ? student.classroomName
          : student.classList
            ? student.classList[0]
            : null,
        classList: student.classList ? student.classList : [],
        date: moment().valueOf(),
        fatherAndroidId: student.fatherUUid ? student.fatherUUid : null,
        fatherId: student.fatherProfileId ? student.fatherProfileId : null,
        fatherIosId: student.ios_fatherUUid ? student.ios_fatherUUid : null,
        gender: student.gender ? student.gender : "Male",
        motherId: student.motherProfileId ? student.motherProfileId : null,
        parentName: firebase.teacher.name,
        profileImageUrl: student.profileImageUrl ? student.profileImageUrl : null,
        studentId: student.id,
        studentName: student.name,
      },
    ];

    yield fork(ActivityApi.addUpdatedStudent, activityId, names, firebase);
  } catch (err) {
    console.log("failed to send attendance update to parent", err);
    bugsnagClient.notify(err);
  }
}

// function* updateUnSelectedStudentAttendance(students, atdDate, firebase) {
//     try {
//         let selectedStudent = students;
//         let date = atdDate;

//         for (let i = 0; i < selectedStudent.length; i++) {
//             if (selectedStudent[i].id) {
//                 // let nodeId = yield call(activityApi.createUniqueAttendanceNode, date);
//                 let nodeId = selectedStudent[i].id;
//                 var checkInOutObject = {
//                     absent: false,
//                     checkInTime: null,
//                     checkInEpoch: 0,
//                     checkOutEpoch: 0,
//                     className: selectedStudent[i].classroomName,
//                     createdBy: firebase.teacher.name,
//                     date: new Date(date).getTime(),
//                     deactivated: false,
//                     fatherProfileId: selectedStudent[i].fatherProfileId ? selectedStudent[i].fatherProfileId : null,
//                     fatherUuid: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
//                     motherProfileId: selectedStudent[i].motherProfileId ? selectedStudent[i].motherProfileId : null,
//                     motherUuid: selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
//                     gender: selectedStudent[i].gender,
//                     id: nodeId,
//                     profileImageUrl: selectedStudent[i].profileImageUrl ? selectedStudent[i].profileImageUrl : null,
//                     tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
//                     userId: selectedStudent[i].id,
//                     userName: selectedStudent[i].name,
//                     late: false,
//                     lateCheckout: false,
//                     platform: "web",
//                     updatedBy: [firebase.teacher.name]
//                 }
//                 yield fork(StudentAttendanceApi.addRecordToStudentAttendance, date, checkInOutObject, selectedStudent[i], firebase);
//             }
//         }
//     } catch (err) {
//         console.log("failed to update unselected student attendance status", err);
//         bugsnagClient.notify(err);
//     }
// }

function* markStudentRecordAbsent(selectedStudent, date, firebase, formValues) {
  try {
    let studentIds = [];

    selectedStudent.forEach((std) => {
      studentIds.push(std.id);
    });

    let obj = {
      date: moment(date).valueOf(),
      studentIds: studentIds,
      absentReason: formValues.absentReason ? formValues.absentReason : null,
      absentNote: formValues.absentNote ? formValues.absentNote : null,
    };

    let url = "woodlandApi/absent/student?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_ABSENT_SUCCESSFUL,
      });
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark student record absent", err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStudentsAbsent({ selectedStudent, date, firebase, newAttendance, formValues }) {
  if (newAttendance) {
    yield fork(markStudentRecordAbsent, selectedStudent, date, firebase, formValues);
  } else {
    try {
      for (let i = 0; i < selectedStudent.length; i++) {
        yield fork(
          StudentAttendanceApi.removeStudentRecordFromCheckInOut,
          selectedStudent[i],
          date,
          firebase
        );

        let nodeId = selectedStudent[i].id;
        var checkInOutObject = {
          absent: true,
          checkInTime: null,
          checkInEpoch: 0,
          checkOutEpoch: 0,
          className: selectedStudent[i].classList
            ? selectedStudent[i].classList[0]
            : selectedStudent[i].classroomName,
          classList: selectedStudent[i].classList ? selectedStudent[i].classList : [],
          createdBy: firebase.teacher.name,
          date: moment(date).valueOf(),
          deactivated: false,
          fatherProfileId: selectedStudent[i].fatherProfileId
            ? selectedStudent[i].fatherProfileId
            : null,
          fatherUuid: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          motherProfileId: selectedStudent[i].motherProfileId
            ? selectedStudent[i].motherProfileId
            : null,
          motherUuid: selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          gender: selectedStudent[i].gender,
          id: nodeId,
          profileImageUrl: selectedStudent[i].profileImageUrl
            ? selectedStudent[i].profileImageUrl
            : null,
          tags: selectedStudent[i].tags ? selectedStudent[i].tags : null,
          userId: selectedStudent[i].id,
          userName: selectedStudent[i].name,
          late: false,
          lateCheckout: false,
          platform: "web",
          updatedBy: [firebase.teacher.name],
        };
        yield fork(
          StudentAttendanceApi.updateRecordToStudentAttendance,
          selectedStudent[i],
          date,
          firebase,
          checkInOutObject
        );
        yield fork(
          StudentAttendanceApi.markPresentToAttendanceUpdates,
          selectedStudent[i],
          date,
          undefined,
          nodeId,
          firebase,
          checkInOutObject
        );

        if (moment(date).isSame(moment(), "day")) {
          yield fork(
            StudentAttendanceApi.updateStudentLastAtd,
            selectedStudent[i].id,
            undefined,
            firebase,
            checkInOutObject
          );

          yield fork(
            StudentAttendanceApi.updateStudentLastAtdCheckout,
            selectedStudent[i].id,
            undefined,
            firebase
          );
        }
      }
      yield put({
        type: actions.MARK_ABSENT_SUCCESSFUL,
      });

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "attendance", date);
      yield call(attendanceCountUpdate, selectedStudent, date, firebase, "markPending");
      yield fork(NotificationApi.sendStats, date, "updateDailyPostStats", firebase);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, date, firebase);
    } catch (err) {
      console.log("failed to mark student absent", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* attendanceCountUpdate(students, date, firebase, atdAction) {
  for (let index in students) {
    let data = yield call(
      StudentAttendanceApi.getStudentAttendanceById,
      date,
      students[index].id,
      firebase
    );
    // in case of pending send a flag
    yield fork(
      StudentAttendanceApi.updatedAttendanceRecordApi,
      data,
      firebase,
      "updateAttendanceStudent",
      atdAction
    );
  }
}

function* getClassrooms({ firebase, showActivePrograms, date }) {
  try {
    //let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_ALL_CLASSROOMS_SUCCESSFUL,
        classrooms: data,
      });
    }

    yield fork(fetchPrograms, firebase, showActivePrograms, date);
  } catch (err) {
    console.log("failed to fetch classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchPrograms(firebase, showActivePrograms, date) {
  try {
    let programs = yield call(ProgramApi.fetchPrograms, firebase);
    if (programs) {
      let activePrograms = [];
      if (programs && programs.length > 0 && showActivePrograms) {
        programs.forEach((d) => {
          let startDate = moment(d.startDate);
          let endDate = moment(d.endDate);

          if (moment(date).isBetween(startDate, endDate, "day", "[]")) {
            activePrograms.push(d);
          }
        });
      }

      if (!showActivePrograms) {
        activePrograms = programs;
      }

      yield put({
        type: actions.GET_PROGRAMS_LIST,
        programList: activePrograms,
      });
    }
  } catch (err) {
    console.log("failed to fetch programs", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentByClassroomName({ className, firebase, date }) {
  try {
    //let data = yield call(StudentAttendanceApi.getStudentByClassName, className, firebase);
    let startTime = moment(date).startOf("day").valueOf();
    let endTime = moment(date).endOf("day").valueOf();
    let leaveIds = yield call(
      ComplainsApi.getCurrentDateStudentLeave,
      firebase,
      startTime,
      endTime
    );
    let students = FilterAction.getStudentList(firebase);
    let data = students.filter((item) => {
      return (
        item.classList.includes(className) &&
        (!item.status || item.status.toLowerCase() === "active") &&
        !item.deactivated &&
        !leaveIds.includes(item.id)
      );
    });

    if (data) {
      let studentsMap = new Map();
      data.forEach((std) => {
        studentsMap.set(std.id, std);
      });

      yield put({
        type: actions.FETCH_STUDENT_BY_CLASSNAME_SUCCESSFUL,
        allStudents: data,
        studentsMap: studentsMap,
      });
    }
  } catch (err) {
    console.log("failed to fetch student from classname", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* fetchStudentAttendanceByClassName({ className, date, firebase }) {
  const chan = yield call(
    StudentAttendanceApi.getStudentAttendanceByClassName,
    className,
    date,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL,
        studentAttendance: data,
        attendanceChannel: chan,
      });
    }
  } finally {
    console.log("end student attendance channel");
  }
}

function* markStudentRecordCheckout(
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus,
  temperature,
  note,
  selectedClassroom,
  sendNotification,
  selectedTeachers
) {
  try {
    let selectedTime = moment(time);
    let atdTime = moment(date)
      .set("hour", selectedTime.get("hour"))
      .set("minute", selectedTime.get("minute"))
      .set("second", selectedTime.get("second"));

    let studentIds = [];
    selectedStudent.forEach((std) => {
      studentIds.push(std.id);
    });
    let obj = {
      date: atdTime,
      studentIds: studentIds,
      roomRecord: {
        // name: selectedClassroom,
        updatedBy: firebase.teacher.name,
        checkoutTime: atdTime.valueOf(),
        temperature: temperature,
        note: note,
        late: checkInOutStatus && checkInOutStatus.toLowerCase() === "ontime" ? false : true,
      },
    };

    let url = "woodlandApi/checkout/student?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_CHECKOUT_SUCCESSFUL,
      });

      if (selectedTeachers) {
        yield fork(
          sendNotificationToTeacher,
          selectedTeachers,
          selectedStudent,
          undefined,
          "checked-out",
          date,
          studentIds,
          firebase
        );
      }

      if (sendNotification) {
        for (let i = 0; i < selectedStudent.length; i++) {
          if (
            selectedStudent[i].fatherProfileId &&
            !firebase.schoolConfig.noNotificationAttendance
          ) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              "Attendance",
              null,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedStudent[i].name + " left school" + " on " + moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );


            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                "Attendance",
                null,
                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                selectedStudent[i].name +
                " left school" +
                " on " +
                moment(date).format("DD MMM YY"),
                alertNode,
                selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase
              );
            }
          }

          if (
            selectedStudent[i].motherProfileId &&
            !firebase.schoolConfig.noNotificationAttendance
          ) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              "Attendance",
              null,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedStudent[i].name + " left school" + " on " + moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );


            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                "Attendance",
                null,
                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                selectedStudent[i].name +
                " left school" +
                " on " +
                moment(date).format("DD MMM YY"),
                alertNode,
                selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase
              );
            }
          }
        }
      }
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark student record checkout", err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStudentsCheckoutTime({
  selectedStudent,
  date,
  time,
  firebase,
  checkInOutStatus,
  temperature,
  note,
  newAttendance,
  selectedClassroom,
  sendNotification,
  selectedTeachers,
}) {
  if (newAttendance) {
    yield fork(
      markStudentRecordCheckout,
      selectedStudent,
      date,
      time,
      firebase,
      checkInOutStatus,
      temperature,
      note,
      selectedClassroom,
      sendNotification,
      selectedTeachers
    );
  } else {
    try {
      let selectedTime = moment(time);
      let atdTime = moment(date)
        .set("hour", selectedTime.get("hour"))
        .set("minute", selectedTime.get("minute"))
        .set("second", selectedTime.get("second"));

      for (let i = 0; i < selectedStudent.length; i++) {
        yield fork(
          StudentAttendanceApi.updateCheckOutTimeInCheckInOut,
          selectedStudent[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );
        yield fork(
          StudentAttendanceApi.updateCheckOutTimeToStudentAttendance,
          selectedStudent[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );
        yield fork(
          StudentAttendanceApi.updateCheckOutTimeInAttendanceUpdates,
          selectedStudent[i],
          date,
          atdTime,
          firebase,
          checkInOutStatus
        );

        if (moment(date).isSame(moment(), "day")) {
          let dateFormat = moment(time).format(" DD[-]MM[-]YY");
          yield fork(
            StudentAttendanceApi.updateStudentLastAtdCheckout,
            selectedStudent[i].id,
            dateFormat,
            firebase
          );
        }

        if (selectedStudent[i].fatherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Attendance",
            null,
            selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            selectedStudent[i].userName +
            " left school" +
            " on " +
            moment(date).format("DD MMM YY"),
            alertNode,
            selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );

          if (
            selectedStudent[i].fatherUUid !== undefined ||
            selectedStudent[i].ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              "Attendance",
              null,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedStudent[i].userName +
              " left school" +
              " on " +
              moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent[i].motherProfileId && !firebase.schoolConfig.noNotificationAttendance) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent[i].motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            "Attendance",
            null,
            selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
            selectedStudent[i].userName +
            " left school" +
            " on " +
            moment(date).format("DD MMM YY"),
            alertNode,
            selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );

          if (
            selectedStudent[i].motherUUid !== undefined ||
            selectedStudent[i].ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              "Attendance",
              null,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedStudent[i].userName +
              " left school" +
              " on " +
              moment(date).format("DD MMM YY"),
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase
            );
          }
        }
      }
      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateDailyPostStats", firebase);
      yield fork(updateCheckoutStudentRecord, selectedStudent, firebase, date);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, date, firebase);

      yield put({
        type: actions.MARK_CHECKOUT_SUCCESSFUL,
      });
    } catch (err) {
      console.log("failed to mark student checkout time", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function* updateCheckoutStudentRecord(selectedStudents, firebase, date) {
  try {
    for (let index in selectedStudents) {
      let data = yield call(
        StudentAttendanceApi.getStudentAttendanceById,
        date,
        selectedStudents[index].id,
        firebase
      );
      if (data) {
        yield fork(
          StudentAttendanceApi.updatedAttendanceRecordApi,
          data,
          firebase,
          "updateAttendanceStudent"
        );
      }
    }
  } catch (err) {
    console.log("failed to call and  update checked out student attendance record api", err);
    bugsnagClient.notify(err);
  }
}

function* requestStudentAttendance({ value, atdType, firebase }) {
  try {
    yield call(
      NotificationApi.sendAttendanceEmail,
      value.dateRange[0],
      value.dateRange[1],
      atdType,
      firebase
    );
    // if (response && response.status === 200) {
    notification("success", formatMsg("success.sendEmail"));
    // } else {
    //     notification('error', "Failed to send email");
    // }
  } catch (err) {
    console.log("failed to send student attendance email", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentNewMonthlyAttendance({ date, firebase }) {
  try {
    let students = FilterAction.getStudentList(firebase);
    let studentsMap = new Map();
    students.forEach((std) => {
      studentsMap.set(std.id, std);
    });

    let atdRecord = [];
    let data = yield call(StudentAttendanceApi.getStudentNewAttendanceByMonth, date, firebase);
    if (data && data.size > 0) {
      for (let [k, value] of data) {
        let data = {};
        let daysAttended = 0;
        let daysUnattended = 0;

        if (studentsMap.has(k)) {
          let student = studentsMap.get(k);
          for (let index in value) {
            let eleVal = value[index];

            data.name = student.name;
            data.className = student.classroomName;
            data.classList = student.classList ? student.classList : [];
            data.gender = student.gender;
            data.userId = student.id;

            if (eleVal.roomRecords) {
              daysAttended++;
            } else {
              if (eleVal.absent) {
                daysUnattended++;
              }
            }

            data.daysAttended = daysAttended;
            data.daysUnattended = daysUnattended;
            let presentPercent = (daysAttended / value.length) * 100;
            if (presentPercent > 0) {
              data.presentPercentage = Math.round(presentPercent);
            } else {
              data.presentPercentage = 0;
            }
          }
          atdRecord.push(data);
        }
      }
    } else {
      students.forEach((student) => {
        let data = {};
        data.name = student.name;
        data.className = student.classroomName;
        data.classList = student.classList ? student.classList : [];
        data.gender = student.gender;
        data.userId = student.id;
        data.daysAttended = "-";
        data.daysUnattended = "-";
        data.presentPercentage;
        ("-");
        atdRecord.push(data);
      });
    }

    yield put({
      type: actions.GET_NEW_MONTHLY_ATD_SUCCESS,
      studentNewMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch student new monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch student monthly attendance" + err.message ? err.message : err
    );
  }
}

function* fetchStudentMonthlyAttendance({ date, firebase }) {
  try {
    let atdRecord = [];
    let data = yield call(StudentAttendanceApi.getStudentAttendanceByMonth, date, firebase);
    if (data) {
      for (let [k, value] of data) {
        console.log(k, value);
        let data = {};
        let daysAttended = 0;
        let hoursAttended = 0;
        let daysUnattended = 0;
        let lateCheckInCount = 0;
        let lateCheckOutCount = 0;

        for (let index in value) {
          let eleVal = value[index];
          if (eleVal.userId) {
            data.name = eleVal.userName;
            data.className = eleVal.className;
            data.classList = eleVal.classList ? eleVal.classList : [];
            data.gender = eleVal.gender;
            data.userId = eleVal.userId;
            if (!eleVal.absent && eleVal.checkInEpoch && eleVal.checkInEpoch > 0) {
              daysAttended++;
            } else if (eleVal.absent) {
              daysUnattended++;
            }

            if (eleVal.late !== undefined && eleVal.late === true) {
              lateCheckInCount++;
            }

            if (eleVal.lateCheckout !== undefined && eleVal.lateCheckout === true) {
              lateCheckOutCount++;
            }

            if (eleVal.checkInEpoch && eleVal.checkOutEpoch && !eleVal.absent) {
              let a = moment(eleVal.checkOutEpoch);
              let b = moment(eleVal.checkInEpoch);
              let diff = moment.duration(a.diff(b)).as("hours");
              hoursAttended = hoursAttended + diff;
            }

            data.daysAttended = daysAttended;
            data.hoursAttended = hoursAttended;
            data.daysUnattended = daysUnattended;
            data.lateCheckInCount = lateCheckInCount;
            data.lateCheckOutCount = lateCheckOutCount;
          }
        }
        atdRecord.push(data);
      }
    }
    yield put({
      type: actions.GET_MONTHLY_ATD_SUCCESS,
      studentMonthlyAttendance: atdRecord,
    });
  } catch (err) {
    console.log("failed to fetch student monthly attendance", err);
    bugsnagClient.notify(
      "failed to fetch student monthly attendance" + err.message ? err.message : err
    );
  }
}

function* markStudentRecordPending(selectedStudent, date, firebase) {
  try {
    let studentIds = [];

    selectedStudent.forEach((std) => {
      studentIds.push(std.id);
    });

    let obj = {
      date: moment(date).valueOf(),
      studentIds: studentIds,
    };

    let url = "woodlandApi/attendancereset/student?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.MARK_STUDENT_PENDING_SUCCESS,
      });
    } else {
      if (response && response.body && response.body.response) {
        notification("error", response.body.response);
      }
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to mark student record pending", err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function* markStudentsPending({ selectedStudent, date, firebase, newAttendance }) {
  if (newAttendance) {
    yield fork(markStudentRecordPending, selectedStudent, date, firebase);
  } else {
    try {
      for (let i = 0; i < selectedStudent.length; i++) {
        let data = yield call(
          StudentAttendanceApi.getStudentAttendanceById,
          date,
          selectedStudent[i].id,
          firebase
        );
        if (data) {
          yield fork(
            StudentAttendanceApi.updatedAttendanceRecordApi,
            data,
            firebase,
            "updateAttendanceStudent",
            "markPending"
          );
        }
        yield call(StudentAttendanceApi.markStudentPending, selectedStudent[i], date, firebase);
        yield fork(
          StudentAttendanceApi.removeStudentRecordFromCheckInOut,
          selectedStudent[i],
          date,
          firebase
        );

        if (moment(date).isSame(moment(), "day")) {
          yield fork(
            StudentAttendanceApi.updateStudentLastAtd,
            selectedStudent[i].id,
            undefined,
            firebase
          );

          yield fork(
            StudentAttendanceApi.updateStudentLastAtdCheckout,
            selectedStudent[i].id,
            undefined,
            firebase
          );
        }
      }
      yield put({
        type: actions.MARK_STUDENT_PENDING_SUCCESS,
      });

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "attendance", date);
      yield fork(NotificationApi.sendStats, date, "updateDailyPostStats", firebase);
    } catch (err) {
      console.log("failed to mark student absent", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
      });
    }
  }
}

function getStudentsMap(firebase) {
  let students = FilterAction.getStudentList(firebase);
  let studentMap = new Map();
  students.forEach((std) => {
    studentMap.set(std.id, std);
  });

  return studentMap;
}

function* downloadStudentAttendanceRecordDaywise(value, atdType, downloadType, firebase) {
  let studentsMap = firebase.studentsMap;
  let startDate = value.dateRange[0];
  let endDate = value.dateRange[1];
  var checkInRef = firebase.secondaryDb
    .ref(firebase.sbp + "/studentRoomRecords")
    .orderByChild("date")
    .startAt(moment(startDate).startOf("day").valueOf())
    .endAt(moment(endDate).endOf("day").valueOf());

  var report = [];

  if (downloadType === "checkInOut") {
    let selectedClass = value.selectedClassroom;
    const fields = ["studentName", "className"];

    for (var m = moment(startDate); m.diff(endDate, "days") <= 0; m.add(1, "days")) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log("Processing Attendance Report for Date range: " + startDate + " - " + endDate);

    checkInRef.once("value", function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() && snap.val().roomRecords && snap.val().entityId) {
          if (studentsMap.has(snap.val().entityId)) {
            let student = studentsMap.get(snap.val().entityId);
            if (student) {
              let roomRecords = snap.val().roomRecords;
              var studentRow = {};

              studentRow["studentName"] = student.name;
              studentRow["className"] = selectedClass;
              roomRecords.forEach((rec) => {
                if (rec.name.toLowerCase() === selectedClass.toLowerCase()) {
                  if (rec.checkinTime) {
                    if (!studentRow[moment(snap.val().date).format("YYYY-MM-DD") + "-IN"]) {
                      studentRow[moment(snap.val().date).format("YYYY-MM-DD") + "-IN"] = moment(
                        rec.checkinTime
                      ).format("hh:mm A");
                    }
                  }

                  if (rec.checkoutTime) {
                    studentRow[moment(snap.val().date).format("YYYY-MM-DD") + "-OUT"] = moment(
                      rec.checkoutTime
                    ).format("hh:mm A");
                  }
                }
              });

              report.push(studentRow);
            }
          }
        }
      });

      executeExcelDownload(report, fields, "StudentCheckInOutAttendance_", startDate, endDate);
    });
  } else {
    const fields = [
      "Classname",
      "StudentName",
      "AttendanceDate",
      "Time",
      "Type",
      "Attendance",
      "Temperature",
      "Late",
      "AbsentReason",
      "AbsentNote",
    ];

    checkInRef.once("value", function (snapshot) {
      snapshot.forEach((snap) => {
        if (snap.val() && snap.val().entityId) {
          if (studentsMap.has(snap.val().entityId)) {
            let student = studentsMap.get(snap.val().entityId);
            if (student) {
              if (snap.val().roomRecords) {
                let roomRecords = snap.val().roomRecords;

                roomRecords.forEach((rec) => {
                  let studentRow = {};
                  studentRow["Classname"] = rec.name;
                  studentRow["StudentName"] = student.name; //get name from entity id;
                  studentRow["AttendanceDate"] = moment(snap.val().date).format("YYYY-MM-DD");
                  studentRow["Time"] = rec.checkinTime
                    ? moment(rec.checkinTime).format("hh:mm A")
                    : moment(rec.checkoutTime).format("hh:mm A");
                  studentRow["Type"] = rec.checkinTime ? "Check in" : "Check out";
                  studentRow["Attendance"] = "PRESENT";
                  studentRow["Temperature"] = rec.temperature ? rec.temperature : "";
                  studentRow["Late"] = rec.late ? rec.late : "";
                  report.push(studentRow);
                });
              } else {
                let studentRow = {};
                studentRow["Classname"] = student.classList.toString();
                studentRow["StudentName"] = student.name;
                studentRow["Attendance"] = "ABSENT";
                studentRow["AbsentReason"] = snap.val().absentReason ? snap.val().absentReason : "";
                studentRow["AbsentNote"] = snap.val().absentNote ? snap.val().absentNote : "";
                studentRow["AttendanceDate"] = moment(snap.val().date).format("YYYY-MM-DD");
                report.push(studentRow);
              }
            }
          }
        }
      });
    });
    var p = processLeaveData(
      firebase.secondaryDb,
      report,
      startDate,
      endDate,
      firebase.schoolConfig.timezone,
      firebase
    );

    p.then((response) => {
      executeExcelDownload(report, fields, "StudentAttendance", startDate, endDate);
    });
    try {
    } catch (err) {
      console.log("failed to download student record excel", err);
    }
  }
}

function executeExcelDownload(report, fields, fName, startDate, endDate) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName =
    fName + moment(startDate).format("DD-MMM-YY") + "-" + moment(endDate).format("DD-MMM-YY");

  var ws = XLSX.utils.json_to_sheet(report, { header: fields });

  const wb = {
    Sheets: { data: ws },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], {
    type: fileType,
  });
  FileSaver.saveAs(data, fileName + fileExtension);
}

function* downloadStudentAttendanceDaywise({
  value,
  atdType,
  downloadType,
  firebase,
  newAttendance,
}) {
  if (newAttendance) {
    yield fork(downloadStudentAttendanceRecordDaywise, value, atdType, downloadType, firebase);
  } else {
    let startDate = value.dateRange[0];
    let endDate = value.dateRange[1];

    if (downloadType === "checkInOut") {
      yield fork(downloadCheckInOutDaywise, startDate, endDate, firebase);
    } else {
      try {
        var report = [];
        var checkInRef = firebase.secondaryDb.ref(firebase.sbp + "/attendanceUpdates");
        checkInRef.once("value", function (snapshot) {
          if (snapshot) {
            snapshot.forEach(function (dateSnap) {
              dateSnap.forEach(function (data) {
                console.log("student record", data.val());
                if (data.val() && data.val().id) {
                  processCheckInForDate(
                    data,
                    report,
                    startDate,
                    endDate,
                    firebase.schoolConfig.timezone,
                    snapshot.key
                  );
                }
              });
            });
            var p = processLeaveData(
              firebase.secondaryDb,
              report,
              startDate,
              endDate,
              firebase.schoolConfig.timezone,
              firebase
            );

            p.then((results) => {
              console.log("Final Report", report);
              const fields = [
                "Classname",
                "StudentName",
                "AttendanceDate",
                "CheckInTime",
                "CheckOutTime",
                "Attendance",
                "Temperature",
              ];

              const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const fileExtension = ".xlsx";
              const fileName =
                "StudentAttendance" +
                moment(startDate).format("DD-MMM-YY") +
                "-" +
                moment(endDate).format("DD-MMM-YY");
              var ws = XLSX.utils.json_to_sheet(report, { header: fields });

              const wb = {
                Sheets: { data: ws },
                SheetNames: ["data"],
              };
              const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
              });
              const data = new Blob([excelBuffer], { type: fileType });
              FileSaver.saveAs(data, fileName + fileExtension);
            });
          }
        });
      } catch (err) {
        console.log("failed to download monthly attendance", err);
        bugsnagClient.notify(err);
        yield put({
          type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
        });
      }
    }
  }
}

function* downloadCheckInOutDaywise(startDate, endDate, firebase) {
  try {
    const fields = ["studentName", "className"];
    var report = [];
    for (var m = moment(startDate); m.diff(endDate, "days") <= 0; m.add(1, "days")) {
      console.log(m.format("YYYY-MM-DD"));
      fields.push(m.format("YYYY-MM-DD") + "-IN");
      fields.push(m.format("YYYY-MM-DD") + "-OUT");
    }
    console.log("Processing Attendance Report for Date range: " + startDate + " - " + endDate);
    var checkInRef = firebase.secondaryDb.ref(firebase.sbp + "/student-attendance");
    checkInRef.once("value", function (snapshot) {
      if (snapshot) {
        //iterate for each student now
        snapshot.forEach(function (stuSnap) {
          processAttendanceForStudent(
            startDate,
            endDate,
            stuSnap,
            firebase.schoolConfig.timezone,
            report
          );
        });
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName =
          "StudentCheckInOutAttendance_" +
          moment(startDate).format("DD-MMM-YY") +
          "-" +
          moment(endDate).format("DD-MMM-YY");

        var ws = XLSX.utils.json_to_sheet(report, { header: fields });

        const wb = {
          Sheets: { data: ws },
          SheetNames: ["data"],
        };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        //var p = processLeaveData(firebase.secondaryApp,report, startDate, endDate, timezone);

        // p.then(results =>{
        //     console.log("Final Report", report);
        //     const parser = new Json2csvParser(opts);
        //     const csv = parser.parse(report);
        //     console.log(csv);
        //     sendEmailCSVReport(email, ccEmail, csv);
        //     response.status(200).send();
        // })
      }
    });
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

function processAttendanceForStudent(
  startDate,
  endDate,
  studentAttendanceSnapList,
  timezone,
  report
) {
  var studentRow = {};
  studentAttendanceSnapList.forEach((monthSnap) => {
    //console.log("month key",monthSnap.key);
    monthSnap.forEach((attSnap) => {
      //  console.log("student",attSnap.numChildren());
      var attRecord = attSnap.val();
      if (attRecord && attRecord.id) {
        if (
          moment
            .tz(attRecord.date, timezone)
            .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "day", "[]")
        ) {
          studentRow["studentName"] = attRecord.userName;
          studentRow["className"] = attRecord.className;
          var dateString = moment.tz(attRecord.date, timezone).format("YYYY-MM-DD");
          studentRow[dateString + "-IN"] = attRecord.checkInTime ? attRecord.checkInTime : "";
          studentRow[dateString + "-OUT"] = attRecord.checkOutTime ? attRecord.checkOutTime : "";
          console.log("student");
        }
      }
    });
  });
  console.log("student row", studentRow);
  if (JSON.stringify(studentRow) != JSON.stringify({})) report.push(studentRow);
}

function processCheckInForDate(
  checkInRecordSnap,
  report,
  startDate,
  endDate,
  timezone,
  dateString
) {
  //  console.log("Checkin Date", dateString);
  var data1 = checkInRecordSnap.val();
  if (
    moment
      .tz(data1.date, timezone)
      .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "day", "[]")
  ) {
    var checkIn = {};
    checkIn.Classname = data1.className;
    checkIn.StudentName = data1.userName;
    checkIn.AttendanceDate = moment.tz(data1.date, timezone).format("DD/MM/YYYY");
    if (!data1.absent) {
      checkIn.CheckInTime = data1.checkInTime;
      checkIn.CheckOutTime = data1.checkOutTime;
      checkIn.Attendance = "Present";
      checkIn.Temperature = data1.temperature;
    } else {
      checkIn.Attendance = "Absent";
      checkIn.Temperature = "NA";
    }
    report.push(checkIn);
  }
  //console.log("Final Report", report);
}

function processLeaveData(secondaryDb, report, startDate, endDate, timezone, firebase) {
  return secondaryDb
    .ref(firebase.sbp + "/leaves")
    .once("value")
    .then((snap) => {
      if (snap.val()) {
        console.log("got leaves data", snap.numChildren());
        snap.forEach((sn1) => {
          var record = sn1.val();
          if (
            moment
              .tz(record.startDate, timezone)
              .isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), "days", "[]")
          ) {
            var diffDays = moment
              .tz(record.startDate, timezone)
              .diff(moment.tz(record.endDate, timezone), "days");
            for (var i = 0; i <= diffDays; i++) {
              var leaveDate = moment.tz(record.startDate, timezone).add(i, "day");
              if (leaveDate.isAfter(moment.tz(endDate, timezone))) {
                console.log("ignore as date is not indate range");
              } else {
                var checkIn = {};
                checkIn.Classname = record.classRoom;
                checkIn.StudentName = record.student;
                checkIn.AttendanceDate = leaveDate.format("DD/MM/YYYY");
                checkIn.Attendance = "Leave";
                checkIn.Temperature = "NA";
                report.push(checkIn);
                console.log("adding leave", checkIn);
              }
            }
          } else {
            console.log(
              "leave is not between start & end",
              moment.tz(startDate, timezone).format("DD/MM/YY"),
              moment.tz(endDate, timezone).format("DD/MM/YY"),
              moment.tz(record.startDate, timezone).format("DD/MM/YY")
            );
          }
        });
      }
    });
}

async function writeBufferToFile(workbook, fileType, fileName, fileExtension) {
  const excelBuffer1 = await workbook.xlsx.writeBuffer();
  const data1 = new Blob([excelBuffer1], { type: fileType });
  FileSaver.saveAs(data1, "new" + fileName + fileExtension);
}

function* downloadAttedanceMonthly({ record, firebase, newAttendance }) {
  try {
    let report = [];
    var fields = [];
    if (newAttendance) {
      fields = ["name", "classroom", "present", "absent", "leave", "presentPercentage"];

      for (let index in record) {
        let singleRecord = record[index];
        var row = {};
        row.name = singleRecord.name;
        row.classroom = singleRecord.classList.toString();
        row.present = singleRecord.daysAttended;
        row.absent = singleRecord.daysUnattended;
        row.leave = singleRecord.leaveDays;
        row.presentPercentage = singleRecord.presentPercentage;
        report.push(row);
      }
    } else {
      fields = [
        "name",
        "classroom",
        "present",
        "absent",
        "lateCheckIn",
        "lateCheckOut",
        "hoursAttended",
      ];

      for (let index in record) {
        let singleRecord = record[index];
        var row = {};
        row.name = singleRecord.name;
        row.classroom = singleRecord.className;
        row.present = singleRecord.daysAttended;
        row.absent = singleRecord.daysUnattended;
        row.lateCheckIn = singleRecord.lateCheckInCount;
        row.lateCheckOut = singleRecord.lateCheckOutCount;
        row.hoursAttended = singleRecord.hoursAttended;
        report.push(row);
      }
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "StudentMonthlyAttendance";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (err) {
    console.log("failed to download monthly attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.STUDENT_ATTENDANCE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_STUDENT_ATTENDANCE, fetchStudentAttendance),
    yield takeLatest(actions.FETCH_ALL_STUDENT, getStudents),
    yield takeLatest(actions.FETCH_ALL_CLASSROOMS, getClassrooms),
    yield takeLatest(actions.MARK_PRESENT, markStudentsPresent),
    yield takeLatest(actions.MARK_ABSENT, markStudentsAbsent),
    yield takeLatest(actions.MARK_CHECKOUT, markStudentsCheckoutTime),
    yield takeLatest(actions.FETCH_STUDENT_BY_CLASSNAME, getStudentByClassroomName),
    yield takeLatest(
      actions.FETCH_STUDENT_ATTENDANCE_BY_CLASSNAME,
      fetchStudentAttendanceByClassName
    ),
    yield takeLatest(
      actions.EMAIL_STUDENT_ATTENDANCE,
      downloadStudentAttendanceDaywise
      // requestStudentAttendance
    ),
    yield takeLatest(actions.GET_MONTHLY_ATD, fetchStudentMonthlyAttendance),
    yield takeLatest(actions.MARK_STUDENT_PENDING, markStudentsPending),
    yield takeLatest(
      actions.DOWNLOAD_MONTHLY_ATTENDANCE,
      downloadAttedanceMonthly
      // downloadStudentAttendanceDaywise
    ),
    yield takeLatest(actions.GET_STUDENT_NEW_ATTENDANCE, fetchStudentNewAttendance),
    yield takeLatest(actions.GET_NEW_MONTHLY_ATD, fetchStudentNewMonthlyAttendance),
  ]);
}
