import React from "react";
// import notification from '../notification';

const GlobalContext = new React.createContext();

export const withNotification = (Component) => (props) =>
  (
    <GlobalContext.Consumer>
      {(notification) => {
        return <Component {...props} notification={notification} />;
      }}
    </GlobalContext.Consumer>
  );

export default GlobalContext;
