import { getStudentList } from "../Newsletter/saga";
import actions from "./actions";
const initState = {
  applicationForms: [],
  applicationFormChan: undefined,
  applicationOperationType: undefined,
  isLoading: false,
  studentFormLoading: false,
  studentForms: new Map(),
  singleApplicationForm: [],
  shareProcessWithStudent: [],
  shareProcessWithStudentChannel: undefined,
  studentApplication: {},
  error: false,
  singleStudentForms: [],
  formDetail: {},
  externalKey: undefined,
  leadStatusList: [],
  classrooms: [],
  students: [],
  appLogLoading: false,
  appLogs: [],
  formItem: [],
  operationRequest: "",
  // saveStyleLoading: false,
  formDetailChan: undefined,
  process: [],
  fetchAllApplicationShare: [],
  processChannel: undefined,
  selectedProcess: {},
  selectedProcessChannel: undefined,
  fetchProcessShareInfo: [],
  fetchProcessShareInfoChannel: undefined,
  deleteRedirect: false,
  studentPrograms: [],
  classroomData: [],
  group: [],
  allAdmissionprocess: [],
  studentProgressData: [],
  stepLength: undefined,
  studentList: [],
  studentProgressChannel: undefined,
  mediaFile: undefined,
  shareLoading: false,
  isColumnLoading: false,
  teacherApplicationChan: undefined,
  teacherApplications: [],
  sharedApplications: [],
  formChannel: undefined,
  alreadyShared: false,
  alreadySharedIds: undefined,
  applicationSharedSuccess: false,
  singleTeacherForms: [],
  singleApplicationFormChan: undefined,
  formUserName: undefined,
  additionalFieldLoader: false,
};

export default function applicationReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_APPLICATIONS:
      return {
        ...state,
        isLoading: true,
        applicationOperationType: undefined,
      };
    case actions.CLONE_APPLICATION:
      return { ...state, isLoading: true }
    case actions.CLONE_APPLICATION_SUCCESS:
      return { ...state, isLoading: false }
    case actions.GET_TEACHER_APPLICATION:
      return {
        ...state,
        isLoading: true,
        applicationOperationType: undefined,
      };
    case actions.GET_TEACHER_APPLICATION_SUCCESS:
      return {
        ...state,
        teacherApplications: action.teacherApplications,
        isLoading: false,
        teacherApplicationChan: action.teacherApplicationChan,
      };
    case actions.GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applicationForms: action.applicationForms,
        isLoading: false,
        applicationFormChan: action.applicationFormChan,
      };
    case actions.UPDATE_FORM_STYLE:
      return {
        ...state,
        // saveStyleLoading: true,
      };
    case actions.UPDATE_FORM_STYLE_SUCCESS:
      return {
        ...state,
        // saveStyleLoading: false,
      };

    case actions.GET_STUDENT_FORMS:
      return {
        ...state,
        studentFormLoading: true,
      };
    case actions.GET_STUDENT_FORMS_SUCCESS:
      return {
        ...state,
        studentForms: action.studentForms,
        studentFormLoading: false,
      };

    case actions.RESET_APPLICATION_OPERATION:
      return {
        ...state,
        isLoading: false,
        studentFormLoading: false,
        applicationOperationType: undefined,
        error: false,
        operationRequest: "",
      };

    case actions.GET_SINGLE_APPLICATION_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SINGLE_APPLICATION_FORM_SUCCESS:
      return {
        ...state,
        singleApplicationForm: action.singleApplicationForm,
        isLoading: false,
        singleApplicationFormChan: action.singleApplicationFormChan
      };

    case actions.GET_APP_BY_ID:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_APP_BY_ID_SUCCESS:
      return {
        ...state,
        studentApplication: action.studentApplication,
        isLoading: false,
      };

    case actions.UPDATE_APPLICATION_SETTING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_APPLICATION_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applicationOperationType: "SETTING_UPDATED",
      };
    case actions.DISABLE_ACCEPTING_RESPONSES_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        applicationOperationType: action.msg,
      }

    case actions.APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        additionalFieldLoader: false,
      };

    case actions.UPDATE_FORM_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_APPLICATIONS:
      return {
        ...state,
        isLoading: true
      }
    case actions.GET_SHARED_APPLICATIONS:
      return {
        ...state,
        isLoading: true
      }

    case actions.GET_SHARED_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sharedApplications: action.sharedApplications,
        formChannel: action.chan
      }
    case actions.UPDATE_FORM_ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applicationOperationType: action.applicationOperationType,
      };
    case actions.SHARE_APPLICATION_ALREADY_SHARED:
      return {
        ...state,
        isLoading: false,
        alreadyShared: true,
        alreadySharedIds: action.alreadySharedIds
      }

    case actions.GET_SINGLE_STUDENT_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SINGLE_STUDENT_FORM_SUCCESS:
      return {
        ...state,
        singleStudentForms: action.singleStudentForms,
        isLoading: false,
      };

    case actions.GET_SINGLE_TEACHER_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SINGLE_TEACHER_FORM_SUCCESS:
      return {
        ...state,
        singleTeacherForms: action.singleTeacherForms,
        isLoading: false,
      };
    case actions.DOWNLOAD_APP_FORM_SUBMISSION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DOWNLOAD_APP_FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_FORM_FIELDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_FORM_FIELDS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        externalKey: action.externalKey,
      };

    case actions.RESET_EXTERNAL_KEY:
      return {
        ...state,
        externalKey: undefined,
      };

    case actions.FETCH_BUILDING_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_BUILDING_FORM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        formDetail: action.formDetail,
        formDetailChan: action.formDetailChan,
      };
    case actions.EDIT_BUILDING_FORM:
      return {
        ...state,
        isLoading: true,
        formDetail: {},
      };
    case actions.EDIT_BUILDING_FORM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.RESET_FORM_DETAIL:
      return {
        ...state,
        formDetail: {},
        isLoading: false,
      };
    case actions.COPY_APPLICATION_FILES:
      return {
        ...state,
        isLoading: true
      }
    case actions.COPY_APPLICATION_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.SYNC_FORM_DATA:
      return {
        ...state,
        isLoading: true
      }
    case actions.SYNC_FORM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.FETCH_LEAD_STATUS_LIST:
      return {
        ...state,
      };

    case actions.FETCH_LEAD_STATUS_LIST_SUCCESS:
      return {
        ...state,
        leadStatusList: action.leadStatusList,
      };

    case actions.SHARE_APPLICATION:
      return {
        ...state,
        isLoading: true,
        shareLoading: true,
        alreadyShared: false
      };
    case actions.SHARE_APPLICATION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        shareLoading: false,
        alreadyShared: false,
        applicationSharedSuccess: true
      };
    case actions.GET_CLASSROOM_FOR_APPLICATION:
      return {
        ...state,
      };
    case actions.UNSHARE_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applicationOperationType: "UNSHARE_SUCCESS"
      }
    case actions.UNSHARE_APPLICATION:
      return {
        ...state,
        isLoading: true
      }
    case actions.RESET_APPLICATION_SHARE:
      return {
        ...state,
        applicationSharedSuccess: false,
        alreadyShared: false
      }
    case actions.GET_CLASSROOM_FOR_APPLICATION_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
      };
    case actions.GET_STUDENT_FOR_APPLICATION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_FOR_APPLICATION_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        students: action.students,
      };
    case actions.APPLICATION_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        uploadStatus: "failed",
        shareLoading: false
      };
    case actions.FETCH_APP_LOG:
      return {
        ...state,
        appLogLoading: true,
        appLogs: [],
      };

    case actions.FETCH_APP_LOG_SUCCESS:
      return {
        ...state,
        appLogLoading: false,
        appLogs: action.appLogs,
      };

    case actions.SAVE_FORM_ITEMS:
      return {
        ...state,
        isLoading: true,
        operationRequest: "",
        additionalFieldLoader: true,
      };

    case actions.SAVE_FORM_ITEMS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
        additionalFieldLoader: false,
      };

    case actions.DELETE_FORM_ITEM:
      return {
        ...state,
        isLoading: true,
        operationRequest: "",
        additionalFieldLoader: true,
      };

    case actions.DELETE_FORM_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
        additionalFieldLoader: false,
      };
    case actions.FETCH_FORM_ITEMS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.FETCH_FORM_ITEMS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        formItem: action.formItem,
      };

    case actions.ADD_ADMISSION_FORM:
      return {
        ...state,
        isLoading: true,
        operationRequest: "",
      };

    case actions.ADD_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };
    case actions.UPDATE_ADMISSION_FORM:
      return {
        ...state,
        isLoading: true,
        operationRequest: "",
      };

    case actions.UPDATE_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };

    case actions.ADMISSION_PROCESS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADMISSION_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.FETCH_ADMISSION_PROCESS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.FETCH_ADMISSION_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        process: action.process,
        processChannel: action.processChannel,
        fetchAllApplicationShare: action.fetchAllApplicationShare,
      };
    case actions.FETCH_SELECTED_ADMISSION_PROCESS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.FETCH_SELECTED_ADMISSION_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedProcess: action.selectedProcess,
        selectedProcessChannel: action.selectedProcessChannel,
      };

    case actions.DELETE_ADMISSION_PROCESS:
      return {
        ...state,
        isLoading: true,
        deleteRedirect: false,
      };
    case actions.DELETE_ADMISSION_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteRedirect: action.deleteRedirect,
      };

    case actions.GET_TOTAL_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_TOTAL_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shareProcessWithStudent: action.shareProcessWithStudent,
        shareProcessWithStudentChannel: action.shareProcessWithStudentChannel,
      };

    case actions.RESET_DELETE_REDIRECT:
      return {
        ...state,
        deleteRedirect: false,
      };
    case actions.GET_STUDENT_PROGRAMS:
      return {
        ...state,
      };

    case actions.GET_STUDENT_PROGRAMS_SUCCESS:
      return {
        ...state,
        studentPrograms: action.studentPrograms,
      };

    case actions.DELETE_SELECTED_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SELECTED_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_ALL_PROCESS_FOR_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_PROCESS_FOR_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allAdmissionprocess: action.allAdmissionprocess,
      };

    case actions.GET_STUDENT_PROGRESS_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentProgressData: action.studentProgressData,
        studentProgressChannel: action.studentProgressChannel,
        stepLength: action.stepLength,
        studentList: action.studentList,
      };
    case actions.RESET_STUDENT_PROGRESS:
      return {
        ...state,
        studentProgressData: [],

        stepLength: undefined,
        studentList: [],
      };
    case actions.UPLOAD_CONTRACT_IN_FORM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPLOAD_CONTRACT_IN_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mediaFile: action.mediaFile,
      };
    case actions.RESET_UPLOAD_CONTRACT:
      return {
        ...state,
        mediaFile: undefined,
      };
    case actions.SHARE_ADMISSION_PROCESS:
      return {
        ...state,
        isLoading: true,
        shareLoading: true,
      };
    case actions.SHARE_ADMISSION_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shareLoading: false,
      };
    case actions.SAVE_TABLE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_TABLE_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.ADD_COLUMNS:
      return {
        ...state,
        isColumnLoading: true,
      };
    case actions.ADD_COLUMNS_SUCCESSFULL:
      return {
        ...state,
        isColumnLoading: false,
      };
    case actions.DELETE_COLUMN:
      return {
        ...state,
        isColumnLoading: true,
      };
    case actions.DELETE_COLUMN_SUCCESSFULL:
      return {
        ...state,
        isColumnLoading: false,
      };
    case actions.DELETE_APPLICATION_ERROR:
      return {
        ...state,
        isLoading: false
      }
    case actions.DELETE_APPLICATION:
      return {
        ...state,
        isLoading: true
      }
    case actions.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.SEND_APPLICATION_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case actions.SEND_APPLICATION_EMAIL:
      return {
        ...state,
        isLoading: true,

      }
    case actions.SEND_APPLICATION_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applicationOperationType: "SEND_EMAIL"
      }
    case actions.RESET_SINGLE_APPLICATION_FORM:
      return {
        ...state,
        singleApplicationForm: [],
        isLoading: false,
      };
    case actions.GET_FORM_USER_NAME:
      return {
        ...state
      }
    case actions.GET_FORM_USER_NAME_SUCCESS:
      return {
        ...state,
        formUserName: action.formUserName
      }

    case actions.RESET_FORM_USER_NAME:
      return {
        ...state,
        formUserName: undefined
      }
    default:
      return state;
  }
}
