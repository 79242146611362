import actions from "./actions";
const initState = {
  parentNotes: [],
  parentNoteComments: [],
  parentNotesChannel: undefined,
  isLoading: false,
  operationType: undefined,
  error: false,
  notesComment: new Map(),
  check: undefined,
};

export default function parentNotesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_PARENT_NOTES:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        check: undefined,
      };
    case actions.GET_ALL_PARENT_NOTES_SUCCESSFUL:
      return {
        ...state,
        parentNotes: action.parentNotes,
        parentNotesChannel: action.parentNotesChannel,
        isLoading: false,
        operationType: action.operationType,
        check: action.check,
      };

    case actions.GET_NOTIFICATION_NOTE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_COMMENT_FOR_PARENT_NOTE:
      return {
        ...state,
        operationType: undefined,
      };
    case actions.GET_COMMENT_FOR_PARENT_NOTE_SUCCESSFUL:
      return {
        ...state,
        notesComment: action.notesComment,
      };

    case actions.PARENT_NOTE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case actions.RESOLVE_NOTE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.RESOLVE_NOTE_SUCCESSFUL:
      return {
        ...state,
        operationType: undefined,
        isLoading: false,
      };

    case actions.SAVE_NOTE_COMMENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.SAVE_NOTE_COMMENT_SUCCESSFUL:
      return {
        ...state,
        operationType: "COMMENT_SAVED",
        isLoading: false,
      };

    case actions.RESET_NOTE_OPERATION_TYPE:
      return {
        ...state,
        operationType: undefined,
        isLoading: false,
      };

    default:
      return state;
  }
}
