import React, { Component } from "react";
import { Popover } from "antd";
import { connect } from "react-redux";
import IntlMessages from "../../components/utility/intlMessages";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { withFirebase } from "./../../components/firebase/context";
import { compose } from "redux";
import landingPageActions from "../../redux/landingPage/actions";
import { Link } from "react-router-dom";
import colors from "../../Utility/colorFactory";
import ActivityIconSelector from "../../components/ActivityIconSelector";
import moment from "moment-timezone";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import FilterAction from "../../Utility/FilterAction";

const { updateNotificationReadStatus, getAlertNotification } = landingPageActions;
class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      limit: undefined,
      loadingTrial: 0,
    };
  }

  loadMore() {
    const { limit, loadingTrial } = this.state;
    const { allAlerts } = this.props;

    if (loadingTrial === allAlerts.length) {
      notification("info", formatMsg("success.fetchAllNotifications"));
    }
    let tempLimit = limit;
    if (!tempLimit) {
      tempLimit = 50;
    } else {
      tempLimit = tempLimit + 50;
    }

    this.props.getAlertNotification(this.props.firebase, tempLimit);
    this.setState({
      limit: tempLimit,
      loadingTrial: allAlerts.length,
    });
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    const { updateNotificationReadStatus, getAlertNotification, alertNotification, firebase } =
      this.props;

    updateNotificationReadStatus(alertNotification, firebase);
    getAlertNotification(firebase);
    this.setState({
      visible: !this.state.visible,
    });
  }

  getActivityIcon(activityType) {
    let val = {
      activityType: activityType,
    };

    return <ActivityIconSelector activityIcon={val} externalStyle={styles.activityIcon} />;
  }

  getTime(item) {
    var time = Math.abs(item.inverseTime);
    var alertDate = new Date(time).setHours(0, 0, 0, 0);
    var dateToday = new Date().setHours(0, 0, 0, 0);
    if (alertDate === dateToday) {
      return "Today";
    } else {
      var formattedTime = moment(time).format(FilterAction.getDateFormat(this.props.firebase));
      return formattedTime;
    }
  }

  render() {
    const { selectedNotification, limit } = this.state;
    const { customizedTheme, alertNotification, allAlerts } = this.props;

    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {allAlerts.length > 0 ? (
            allAlerts.map((notification, index) => (
              <Link
                onClick={() => this.hide()}
                to={{
                  pathname: "/dashboard/notifications",
                  state: {
                    item: notification,
                  },
                }}
                key={index}
              >
                {/* <div className="isoDropdownListItem">
                <div style={styles.inline}>
                  {this.getActivityIcon(notification.type)}
                  <p style={{ margin: 0 }}>{notification.activityName}</p>
                </div>
              </div> */}

                <div className="isoDropdownListItem">
                  <h5>{notification.body}</h5>
                  <p>{this.getTime(notification)}</p>
                </div>
              </Link>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "50px",
              }}
            >
              <p>No notifications found</p>
            </div>
          )}
        </div>

        <a className="isoViewAllBtn" onClick={() => this.loadMore()}>
          <IntlMessages id="topbar.viewAll" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <div style={styles.iconDiv}>
            <i
              className="ion-android-notifications"
              style={{
                color: colors.white,
              }}
            />

            {alertNotification.length ? (
              <p
                style={{
                  margin: 0,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: colors.v2_fuzzy_brown,
                  borderRadius: "50%",
                  color: colors.white,
                  border: "1px solid",
                  borderColor: colors.v2_fuzzy_brown,
                  height: 20,
                  minWidth: 20,
                  textAlign: "center",
                }}
              >
                {alertNotification.length > 0 ? alertNotification.length : ""}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </Popover>
    );
  }
}

const a = compose(
  connect(
    (state) => ({
      ...state.LandingPage,
    }),
    {
      updateNotificationReadStatus,
      getAlertNotification,
    }
  ),
  withFirebase
);
export default a(TopbarNotification);
const styles = {
  iconDiv: {
    height: 40,
    width: 40,
    backgroundColor: colors.v2_scooter,
    borderRadius: 8,
    border: "1px solid",
    borderColor: colors.v2_scooter,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  activityIcon: {
    height: "25px",
    width: "25px",
    marginRight: 20,
    objectFit: "contain",
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
