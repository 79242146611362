import actions from "./actions";

const initState = {
  customActivities: [],
  isLoading: false,
};

export default function curriculumReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_CUSTOM_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_CUSTOM_ACTIVITIES_SUCCESS:
      return {
        ...state,
        customActivities: action.payLoad,
        isLoading: false,
      };
    case actions.GET_CUSTOM_ACTIVITIES_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SETLODING:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    default:
      return state;
  }
}
