import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import notification from "../components/notification";
import formatMsg from "../components/utility/formatMessageUtil";
import { getItem, setItem } from "../Utility/encryptedStorage";
import FilterAction from "../Utility/FilterAction";

function getUserSchoolDetails(firebase) {
  var schoolName = firebase.sbDbName;
  var x = {};
  var userSettingPromise = new Promise(function (resolve, reject) {
    firebase.db
      .ref("schools/" + schoolName)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          x = snap.val();
        }
        resolve(x);
      });
  });
  return userSettingPromise;
}

function resetZoomSchoolDetails(firebase) {
  var schoolName = firebase.sbDbName;
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomAccessToken")
    .set(null);
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomGrantCode")
    .set(null);
  firebase.db
    .ref("schools/" + schoolName)
    .child("zoomRefreshToken")
    .set(null);
}
function resetZoomUserDetail(firebase) {
  var teacher = firebase.teacher;
  firebase.db
    .ref("teacher/" + teacher.id)
    .child("zoomAccessToken")
    .set(null);
  firebase.db
    .ref("schools/" + teacher.id)
    .child("zoomGrantCode")
    .set(null);
  firebase.db
    .ref("schools/" + teacher.id)
    .child("zoomRefreshToken")
    .set(null);
}

function updateSchoolLogoToStorage(file, firebase) {
  let branchPath = firebase.sbp;
  var uploadedImageUrl = [];
  if (file !== undefined) {
    var fileName = new Date().getTime() + file.name;
    var metadata = {
      contentType: file.type,
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    const attachmentRef = storageRef.child(branchPath + "/media/" + fileName);
    var uploadPromise = new Promise(function (resolve, reject) {
      attachmentRef
        .put(file, metadata)
        .then(function (snapshot) {
          attachmentRef.getDownloadURL().then((url) => {
            uploadedImageUrl.push(url);
            resolve(uploadedImageUrl);
          });
        })
        .catch((error) => {
          console.log("failed to upload logo", error);
          reject(error);
        });
    });
  }
  return uploadPromise;
}

function updateUserSchoolDetail(schoolName, logoUrl, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    logoUrl: logoUrl,
  });
}

function updateUserInfo(userInfo, schoolName, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    address: userInfo.address,
    adminEmail: userInfo.email,
    schoolName: userInfo.schoolName,
    invoicePrefix: userInfo.invoicePrefix ? userInfo.invoicePrefix : null,
    receiptPrefix: userInfo.receiptPrefix ? userInfo.receiptPrefix : null,
  });
}

function getUserDetail(firebase) {
  let teacher = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/teachers/" + firebase.user.id)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          teacher = snap.val();
        }
        resolve(teacher);
      });
  });
  return myPromise;
}
function getTaxDetail(firebase) {
  let taxes = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/taxReference");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null) {
          taxes.push(element.val());
        }
      });
      resolve(taxes);
    });
  });
  return promise1;
}
function updateTeacherDetail(value, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + firebase.user.id).update({
    name: value.name,
    email: value.email,
  });
}

function getAllCameraData(firebase) {
  let teacher = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/cameraRefrence")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            teacher.push(snap.val());
          }
        });
        resolve(teacher);
      });
  });
  return myPromise;
}

function updateCameraToClass(values, cameraId, firebase) {
  let newClassrooms = values.classroom;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/cameraRefrence/" + cameraId).update({
    classroom: newClassrooms,
  });
}

function updateActivityConfig(
  selectedActivityConfig,
  selectedActivity,
  checkedObj,
  formItems,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  if (selectedActivityConfig && selectedActivity) {
    selectedActivityConfig["file"] = checkedObj["fileUploadChecked"];
    selectedActivityConfig["media"] = checkedObj["mediaChecked"];
    selectedActivityConfig["draftState"] = checkedObj["draftChecked"];
    selectedActivityConfig["lesson"] = checkedObj["lessonChecked"];

    selectedActivityConfig["fieldConfigModels"] = formItems;

    selectedActivityConfig["noApproval"] = checkedObj["noApprovalChecked"];

    selectedActivityConfig["updatedOn"] = moment().valueOf();
    rsf.ref(branchPath + "/customActivityForm/" + selectedActivity).update(selectedActivityConfig);

    updateAdditionalActivityConfig(selectedActivity, selectedActivityConfig, firebase);
  } else {
    var obj = {};
    obj["file"] = checkedObj["fileUploadChecked"];
    obj["media"] = checkedObj["mediaChecked"];
    obj["draftState"] = checkedObj["draftChecked"];
    obj["lesson"] = checkedObj["lessonChecked"];

    obj["fieldConfigModels"] = formItems;

    obj["noApproval"] = checkedObj["noApprovalChecked"];

    let tempObj = FilterAction.getSuperagentBody(obj, firebase);
    rsf.ref(branchPath + "/customActivityForm/" + selectedActivity).update(tempObj);
    updateAdditionalActivityConfig(selectedActivity, tempObj, firebase);
  }
}

function updateAdditionalActivityConfig(selectedActivity, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let extraActivity;
  let incomingActivity = selectedActivity.toLowerCase();
  switch (incomingActivity) {
    case "blockhour":
      extraActivity = "Block Hour";
      break;

    case "grossmotor":
      extraActivity = "Gross Motor";
      break;

    case "finemotor":
      extraActivity = "Fine Motor";
      break;

    case "selfreliance":
      extraActivity = "Self Reliance";
      break;

    case "artcraft":
      extraActivity = "Art & Craft";
      break;

    case "circletime":
      extraActivity = "Circle Time";
      break;
  }

  if (extraActivity) {
    rsf.ref(branchPath + "/customActivityForm/" + extraActivity).update(obj);
  }
}

function updateInvoiceCounter(invoiceCounterText, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/invoiceCounter").set(Number(invoiceCounterText));
}

function updateTax(values, firebase, nodeId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  values["id"] = nodeId;

  rsf.ref(branchPath + "/taxReference/" + nodeId).update(values);
}

function getTaxId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/taxReference").push().key;
  return key;
}

function getLeaveId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/teacherLeaveSettings").push().key;
  return key;
}

function getHolidayId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/staffHolidayCalendar").push().key;
  return key;
}

function updateReceiptCounter(receiptCounterText, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/receiptCounter").set(Number(receiptCounterText));
}

function getUserSchoolConfig(firebase) {
  var schoolName = firebase.sbDbName;
  var x = {};
  return eventChannel((emitter) => {
    firebase.db.ref("schools/" + schoolName).on("value", (snap) => {
      if (snap.val() !== null) {
        x = snap.val();
      }
      emitter(x);
    });
    return () => {
      firebase.db.ref("schools/" + schoolName).off();
      console.log("END");
    };
  });
}

function fetchTeacher(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/teachers/" + teacherId).on("value", (snap) => {
      var teacher = {};
      if (snap.val() !== null) {
        teacher = snap.val();
      }
      emitter(teacher);
    });
    return () => {
      rsf.ref(branchPath + "/teachers/" + teacherId).off();
      console.log("END");
    };
  });
}

function updateSchoolConfig(config, firebase) {
  let schoolName = firebase.sbDbName;
  firebase.db.ref("schools/" + schoolName).set(config, (error) => {
    if (error) {
      notification("error", formatMsg("error.sessionExpiredRelogin"));
      firebase.signOut();
    } else {
      // Data saved successfully!
    }
  });
}

function getSchoolConfiguration(firebase, sbDbName) {
  let schoolName = sbDbName ? sbDbName : firebase.sbDbName;
  let x = {};

  var myPromise = new Promise(function (resolve, reject) {
    firebase.db
      .ref("schools/" + schoolName)
      .once("value")
      .then(function (snap) {
        if (snap.val() !== null) {
          x = snap.val();
        }
        resolve(x);
      });
  });
  return myPromise;
}

function getActivityConfiguration(firebase) {
  let x = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/customActivityForm");

  var myPromise = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        x = snap.val();
      }
      resolve(x);
    });
  });
  return myPromise;
}

function delSelectedTax(val, firebase) {
  let node = val.id;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/taxReference/" + node).set(null);
}

function delSelectedHoliday(record, firebase) {
  let node = record.id;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/staffHolidayCalendar/" + node).set(null);
}

function saveSettingForLeave(val, nodeId, firebase, rows) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teacherLeaveSettings/" + nodeId).update(obj);
}

function saveStaffHoliday(value, nodeId, firebase) {
  var startDate = value && value.startDate ? moment(value.startDate).valueOf() : null;
  var endDate = value && value.endDate ? moment(value.endDate).valueOf() : null;

  let obj = {};
  obj.startDate = startDate;
  obj.endDate = endDate;
  obj.name = value.name ? value.name : null;
  obj.id = nodeId ? nodeId : null;
  obj.updatedBy = firebase.teacher.name;
  obj.platform = "web";

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/staffHolidayCalendar/" + nodeId).update(obj);
}

function getInvoiceCounter(firebase) {
  let x = 0;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/invoiceCounter");

  var myPromise = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        x = snap.val();
      }
      resolve(x);
    });
  });

  return myPromise;
}

function getReceiptCounter(firebase) {
  let x = 0;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/receiptCounter");

  var myPromise = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        x = snap.val();
      }
      resolve(x);
    });
  });

  return myPromise;
}

function getPermissionList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var permRef = rsf.ref(branchPath + "/permissionList");

  return eventChannel((emitter) => {
    permRef.on("value", (snapshot) => {
      let permissions = new Map();
      let roles = [];
      let permissionTypes = [];
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().type) {
          roles = [...roles, ...snap.val().roles];
          permissionTypes = [...permissionTypes, snap.val().type];

          let key;
          if (snap.val().permissionName) {
            key = snap.val().type + " " + snap.val().permissionName;
          } else {
            key = snap.val().type;
          }
          let pValue = snap.val();
          permissions.set(key, pValue);
        }
      });

      let exportedVal = {};
      exportedVal.permissions = permissions;
      exportedVal.roles = [...new Set(roles)];
      exportedVal.permissionTypes = [...new Set(permissionTypes)];
      emitter(exportedVal);
    });
    return () => {
      permRef.off();
      console.log("permission channel closed");
    };
  });
}

function savePermissions(permissions, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/permissionList").set(permissions);
}

function saveActiveActivies(activities, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/schoolSettingActivity").set(activities);
}
function addRelations(firebase, relations) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf.ref(branchPath + "/otherSettings/relations").set(relations);
}
function getRelations(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var myPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/otherSettings/relations")
      .once("value")
      .then((snap) => {
        if (snap.val()) {
          resolve(snap.val());
        }
      });
  });
  return myPromise;
}
function saveActiveCommentActivies(activities, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/commentSettings").set(activities);
}

function updateDefCapacity(schoolName, capacity, firebase) {
  firebase.db.ref("schools/" + schoolName).update({
    defaultMeetingCapacity: capacity ? Number(capacity) : null,
  });
}

function getMailDeliveryReport(id, firebase) {
  var status = { event: "Not found" };
  var userSettingPromise = new Promise(function (resolve, reject) {
    firebase.db
      .ref("emailDeliveryReport")
      .orderByChild("id")
      .equalTo(id)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            status = snap.val();
          }
        });

        resolve(status);
      });
  });
  return userSettingPromise;
}

function catchTokenError(err, firebase){
  notification("error", formatMsg("error.sessionExpired"))
  bugsnagClient.notify(err);
  setTimeout(() =>
  firebase.signOut(true)
  , 100);
}

function isTokenExpired(token){
    var decoded = jwt_decode(token);
    if (decoded.exp.toString().length == 10) {
      decoded.exp = decoded.exp * 1000;
    } else {
      console.log("length is more than 10")
    }
    
    let isExpired = false;
    if (moment(decoded.exp).subtract(10,'minute').isBefore(moment(), 'minutes')) {
      isExpired = true;
    };
    return isExpired;
}

function getAuthToken(firebase){
  let existingToken = getItem("authToken");
  let isExpired = false;
  if(existingToken){
    isExpired = isTokenExpired(existingToken);
  } else {
    isExpired = true;
  }



let promise = new Promise(function(resolve, reject){
  {/**test cases */}
  // user not found
  // token expired
  // network error
  // other failure
  // reset password
  if(!isExpired && existingToken) {resolve(existingToken); return;};
  var unsubscribeListner = firebase.app.auth().onAuthStateChanged(user =>{
      if(user){
        user
          .getIdToken(true)
          .then(function (token) {
            console.log("Token on interval-->", token);
            resolve(token);
            setItem("authToken",token);
            unsubscribeListner();
          }).catch((err) => {
            if (err && err.code && err.code === "auth/network-request-failed") {
              console.error("error in getting token on interval", err);
              resolve(existingToken);
            } else {
              reject(err);
              catchTokenError(err, firebase)
            }
          });
      } else {
        catchTokenError(new Error("User not found"), firebase)
      }
    });
});
return promise;
}



export const UserSettingApi = {
  getUserSchoolDetails,
  updateSchoolLogoToStorage,
  updateUserSchoolDetail,
  updateUserInfo,
  getUserDetail,
  getTaxDetail,
  updateTeacherDetail,
  getAllCameraData,
  updateCameraToClass,
  updateActivityConfig,
  updateInvoiceCounter,
  updateTax,
  getTaxId,

  updateReceiptCounter,
  resetZoomSchoolDetails,
  resetZoomUserDetail,
  getUserSchoolConfig,
  fetchTeacher,
  updateSchoolConfig,
  getSchoolConfiguration,
  getActivityConfiguration,
  delSelectedTax,
  delSelectedHoliday,
  saveSettingForLeave,
  saveStaffHoliday,
  getInvoiceCounter,
  getReceiptCounter,

  getPermissionList,
  savePermissions,
  saveActiveActivies,
  saveActiveCommentActivies,
  updateDefCapacity,
  getMailDeliveryReport,
  getLeaveId,
  getHolidayId,
  addRelations,
  getRelations,
  getAuthToken
};
