import annoucementIcon from "../image/icon_notification.png";
import grossMotorIcon from "../image/icon_gross_motor.png";
import videoPlayerIcon from "../image/video-player.svg";
import blockHourIcon from "../image/icon_block_hour.png";
import rhymeIcon from "../image/icon_rhyme.png";
import languageIcon from "../image/icon_language.png";
import selfRelianceIcon from "../image/icon_self_reliance.png";
import mathIcon from "../image/icon_math.png";
import stemIcon from "../image/icon_stem.png";
import photoIcon from "../image/icon_photo.png";
import storiesIcon from "../image/icon_stories.png";
import diaryIcon from "../image/icon_diary.png";
import foodIcon from "../image/icon_food_icon.png";
import napIcon from "../image/iconn_nap.png";
import pottyIcon from "../image/icon_potty.png";
import milestoneIcon from "../image/icon_milestone.png";
import kudosIcon from "../image/icon_sensorial.png";
import artAndCraftIcon from "../image/icon_art.png";
import medicineIcon from "../image/icon_medicine_icon.png";
import incidentIcon from "../image/icon_incident.png";
import fileUploadIcon from "../image/icon_file_upload.png";
import newsletterIcon from "../image/newsletterActivity.png";
import fineMotorIcon from "../image/icon_fine_motor.png";
import dramaticsIcon from "../image/icon_dramatics.png";
import eplIcon from "../image/icon_sensorial.png";
import reminderIcon from "../image/icon_reminder.png";
import happyIcon from "../image/iconn_mood.png";
import excitedIcon from "../image/iconn_mood.png";
import sleepyIcon from "../image/iconn_mood.png";
import sadIcon from "../image/iconn_mood.png";
import grumpyIcon from "../image/iconn_mood.png";
import circleTimeIcon from "../image/icon_circle_time.png";
import sensorialIcon from "../image/icon_sensorial.png";
import videoIcon from "../image/icon_video.png";
import boyImageIcon from "../image/boy.svg";
import girlImageIcon from "../image/girl.svg";
import notificationIcon from "../components/notification";
import studentAttendanceIcon from "../image/studentAttendance.svg";
import staffAttendanceIcon from "../image/staffAttendance.svg";
import technologyIcon from "../image/icon_technology.png";
import environmentIcon from "../image/icon_environment.png";
import observationsIcon from "../image/icon_observation.png";
import researchIcon from "../image/icon_research.png";
import virtualClassCamera from "../image/icon_virtual_class.png";
import MedicalRecord from "../image/icon_medical_log.png";
import portfolio from "../image/icon_photo.png";
import assignment from "../image/icon_assignment.png";
import accidentIcon from "../image/accident_icon.png";

const activityIcon = {
  announcement: annoucementIcon,
  photo: photoIcon,
  video: videoIcon,
  grossMotor: grossMotorIcon,
  fineMotor: fineMotorIcon,
  blockHour: blockHourIcon,
  rhyme: rhymeIcon,
  language: languageIcon,
  selfReliance: selfRelianceIcon,
  math: mathIcon,
  stem: stemIcon,
  stories: storiesIcon,
  diary: diaryIcon,
  food: foodIcon,
  nap: napIcon,
  potty: pottyIcon,
  milestone: milestoneIcon,
  kudos: kudosIcon,
  artAndCraft: artAndCraftIcon,
  art: artAndCraftIcon,
  medicine: medicineIcon,
  incident: incidentIcon,
  accident: accidentIcon,
  fileUpload: fileUploadIcon,
  newsletter: newsletterIcon,
  dramatics: dramaticsIcon,
  epl: eplIcon,
  reminder: reminderIcon,
  happy: happyIcon,
  excited: excitedIcon,
  sleepy: sleepyIcon,
  sad: sadIcon,
  grumpy: grumpyIcon,
  circleTime: circleTimeIcon,
  sensorial: sensorialIcon,
  boy: boyImageIcon,
  girl: girlImageIcon,
  notification: notificationIcon,
  videoPlayer: videoPlayerIcon,
  studentAttendance: studentAttendanceIcon,
  staffAttendance: staffAttendanceIcon,
  technology: technologyIcon,
  environment: environmentIcon,
  observations: observationsIcon,
  research: researchIcon,
  virtualClass: virtualClassCamera,
  medicalRecord: MedicalRecord,
  portfolio: portfolio,
  assignment: assignment,
};
export default activityIcon;
