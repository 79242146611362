import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import bugsnagClient from "@bugsnag/js";

function* requestReport({ values, firebase }) {
  console.log("form values", values);

  yield put({
    type: actions.REQUEST_REPORT_SUCCESSFUL,
    reports: [],
    reportChan: undefined,
  });
}

export default function* rootSaga() {
  yield all([yield takeLatest(actions.REQUEST_REPORT, requestReport)]);
}
