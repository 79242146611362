import { eventChannel } from "redux-saga";
import actions from "../redux/app/actions";
import { store } from "../redux/store";
import moment from "moment-timezone";
import { node } from "prop-types";
import { values } from "lodash";
var Firebase = require("firebase/compat/app");

function getMilestones(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let milestoneRef = rsf.ref(branchPath + "/assessment/milestones").orderByChild("inverseTime");
  return eventChannel((emit) => {
    milestoneRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      milestoneRef.off();
      console.log("unsubscribe curriculum-milestone");
    };
  });
}

function getLessons(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/lessons")
      .orderByChild("inverseCreatedDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().deleted === false) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                if (element.val().classroom) {
                  let lessonClasses = element.val().classroom;
                  for (let index in classList) {
                    let filteredVal = lessonClasses.filter((f) => {
                      return f.name.toLowerCase() === classList[index].toLowerCase();
                    });

                    if (filteredVal && filteredVal.length > 0) {
                      x.push(element.val());
                      break;
                    }
                  }
                } else {
                  x.push(element.val());
                }
              } else {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/lessons").off();
      console.log("unsubscribe lessons");
    };
  });
}

function createMilestoneDomainNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/curriculum-milestone/").push().key;
  return key;
}

function saveMilestoneDomain(values, nodeId, firebase, parentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  if (values.label !== undefined) {
    rsf.ref(branchPath + "/developmentAreas/" + nodeId).set({
      id: nodeId,
      name: values.title,
      shortName: values.shortName,
      labelName: values.label,
      parentId: parentId ? parentId : null,
      classrooms: values.classrooms && values.classrooms.length > 0 ? values.classrooms : null,
    });
  } else {
    rsf.ref(branchPath + "/developmentAreas/" + nodeId).set({
      id: nodeId,
      name: values.title,
      shortName: values.shortName,
      parentId: parentId ? parentId : null,
      classrooms: values.classrooms && values.classrooms.length > 0 ? values.classrooms : null,
    });
  }
}

function saveMilestone(values, firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/curriculum-milestone/" + id).update({
    id: id,
    name: values.description,
    age: values.age ? values.age : null
  });
}

function updatedMilestoneInDA(id, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let milestones = [];
  if (record.milestones) {
    milestones = record.milestones;
  }

  record.milestones = milestones;

  milestones.push(id);
  rsf.ref(branchPath + "/developmentAreas/" + record.id).update(record);
}

function createLabelNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/labels/").push().key;
  return key;
}

function addLabel(value, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/labels/" + nodeId).set({
    id: nodeId,
    name: value,
  });
}

function getLabel(category, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/labelRef/" + category).on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/labelRef/" + category).off();
      console.log("unsubscribe labels");
    };
  });
}

function updateMilestoneDomain(values, record, firebase, subDomainIds) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  if (values.label !== undefined) {
    rsf.ref(branchPath + "/developmentAreas/" + record.id).update({
      id: record.id,
      name: values.title,
      shortName: values.shortName,
      labelName: values.label,
      inverseTime: -new Date(),
      subDevelopmentArea: subDomainIds && subDomainIds.length > 0 ? subDomainIds : null,
      classrooms: values.classrooms && values.classrooms.length > 0 ? values.classrooms : null,
    });
  } else {
    rsf.ref(branchPath + "/developmentAreas/" + record.id).update({
      id: record.id,
      name: values.title,
      shortName: values.shortName,
      inverseTime: -new Date(),
      subDevelopmentArea: subDomainIds && subDomainIds.length > 0 ? subDomainIds : null,
      classrooms: values.classrooms && values.classrooms.length > 0 ? values.classrooms : null,
    });
  }
}

function updateMilestoneDomainWithSubDomain(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/developmentAreas/" + record.id).update(record);
}

function updateMilestone(values, editableMilestone, index, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {
    name: values.description,
  }

  if (values.age || values.age == "") {
    obj["age"] = values.age
  }
  rsf.ref(branchPath + "/curriculum-milestone/" + editableMilestone.id).update(obj);
}

function createCategoryNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/categories/").push().key;
  return key;
}

function addCategory(value, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/categories/" + nodeId).set({
    id: nodeId,
    name: value,
  });
}

function getCategory(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var categories = [];
  var categoryPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/categories")
      .once("value")
      .then(function (snap) {
        snap.forEach((item) => {
          if (item.val() !== null) {
            categories.push(item.val());
          }
        });
        resolve(categories);
      });
  });
  return categoryPromise;
}

function getLearningClassroom(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var classroom = [];
  var classroomPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/classrooms")
      .once("value")
      .then(function (snap) {
        snap.forEach((item) => {
          if (item.val() !== null) {
            classroom.push(item.val());
          }
        });
        resolve(classroom);
      });
  });
  return classroomPromise;
}

function createLessonsNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/lessons/").push().key;
  return key;
}

function addLesson(value, selectedMilestones, classroom, nodeId, firebase, bPath,fieldRef) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let createdTime = moment().startOf("day").valueOf();
  let x = [];
  var data = {};
  data.id = value.lessonCategory[1];
  data.name = value.lessonCategory[0];
  x.push(data);
  rsf.ref(branchPath + "/lessons/" + nodeId).set({
    id: nodeId,
    name: value.lessonTitle.charAt(0).toUpperCase() + value.lessonTitle.slice(1),
    description: value.description
      ? value.description.charAt(0).toUpperCase() + value.description.slice(1)
      : null,
    htmlText: value.htmlText ? value.htmlText : null,
    labelName: value.label ? value.label : null,
    category: x,
    milestones: selectedMilestones.length > 0 ? selectedMilestones : null,
    duration: value.duration ? value.duration : null,
    classroom: classroom ? classroom : null,
    deleted: false,
    createdDate: createdTime,
    inverseCreatedDate: -createdTime,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    platform: "web",
    mediaType: null,
    mediaUrls: null,
    mediaProfiles: null,
    fieldRef:fieldRef ? fieldRef : null
  });
}

function updateLesson(
  value,
  editableLessonRecord,
  selectedMilestones,
  classroom,
  attachedFilesUrl,
  firebase,
  bPath,
  fieldRef
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let x = [];
  var data = {};
  data.id = value.lessonCategory[1] ? value.lessonCategory[1] : null;
  data.name = value.lessonCategory[0];
  x.push(data);

  let urlList = [];
  let tempMediaType = "none";
  attachedFilesUrl &&
    attachedFilesUrl.map((m) => {
      urlList.push(m.path);
      tempMediaType = m.type;
    });

  rsf.ref(branchPath + "/lessons/" + editableLessonRecord.id).update({
    id: editableLessonRecord.id,
    name: value.lessonTitle.charAt(0).toUpperCase() + value.lessonTitle.slice(1),
    description: value.description
      ? value.description.charAt(0).toUpperCase() + value.description.slice(1)
      : null,
    htmlText: value.htmlText ? value.htmlText : null,
    labelName: value.label ? value.label : null,
    category: x,
    milestones: selectedMilestones.length > 0 ? selectedMilestones : null,
    duration: value.duration ? value.duration : null,
    classroom: classroom ? classroom : null,
    mediaProfiles: attachedFilesUrl.length > 0 ? attachedFilesUrl : null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    youtubeUrl: editableLessonRecord.youtubeUrl ? editableLessonRecord.youtubeUrl : null,
    platform: "web",
    mediaUrls: urlList && urlList.length > 0 ? urlList : null,
    mediaType: urlList.length > 0 ? tempMediaType : null,
    deleted: editableLessonRecord.deleted ? editableLessonRecord.deleted : false,
    createdDate: editableLessonRecord.createdDate
      ? editableLessonRecord.createdDate
      : moment().valueOf(),
    inverseCreatedDate: editableLessonRecord.createdDate
      ? -editableLessonRecord.createdDate
      : -moment().valueOf(),
      fieldRef: fieldRef ? fieldRef : null
  });
}

function updateLessonAttachment(
  files,
  editableLessonRecord,
  attachedFilesUrl,
  firebase,
  bPath,
  mediaType
) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var promiseList = [];
  var urls = [];

  if (attachedFilesUrl.length > 0) {
    urls = attachedFilesUrl;
  }

  if (files !== undefined) {
    files.fileList.forEach(function (singleFile, index) {
      var file = singleFile.originFileObj;
      var fileName = new Date().getTime() + file.name;
      var metadata = {
        contentType: singleFile.type,
      };

      const storage = firebase.secondaryStorage;
      const storageRef = storage.ref();
      let attachmentRef;
      if (mediaType === "file") {
        attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
      } else {
        attachmentRef = storageRef.child(branchPath + "/media/lessons/" + fileName);
      }

      var p2 = new Promise(function (resolve, reject) {
        attachmentRef.put(file, metadata).on(
          Firebase.default.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            // notification('success', 'Upload is ' + progress + '% done');
            store.dispatch({
              type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
              attachmentProgress: progress,
            });
          },
          function (error) {
            reject(error);
          },
          function () {
            var p2 = attachmentRef.getDownloadURL().then(function (downloadURL) {
              //console.log('File available at', downloadURL);
              urls.push(downloadURL);
              resolve(p2);
            });
          }
        );
      });

      promiseList.push(p2);
    });
  }
  return Promise.all(promiseList)
    .then(function () {
      store.dispatch({
        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
        attachmentProgress: 100,
        allFilesUploaded: true,
      });
      return urls;
    })
    .catch((error) => {
      throw error;
    });
}

function createWeeklyPlanNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/weeklyPlan/").push().key;
  return key;
}

function addWeeklyPlan(
  selectedLesson,
  selectedDate,
  weeklyPlanData,
  selectedDropdownValue,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var newDate = selectedDate.toDateString();
  var lessonId = [];
  for (let i = 0; i < weeklyPlanData.length; i++) {
    if (
      newDate === weeklyPlanData[i].date.toDateString().trim() &&
      weeklyPlanData[i].lessons !== undefined &&
      weeklyPlanData[i].lessons.length > 0
    ) {
      var allLessons = weeklyPlanData[i].lessons;

      for (let j = 0; j < allLessons.length; j++) {
        lessonId.push(allLessons[j][0].id);
      }
    }
  }

  selectedLesson.map((item) => {
    lessonId.push(item.id);
  });

  let shareReq = true;
  if (firebase.schoolConfig.disableAutomaticLessonShare) {
    shareReq = false;
  }

  if (firebase.schoolConfig.studentLessonShare) {
    shareReq = firebase.schoolConfig.studentLessonShare;
  }

  rsf.ref(branchPath + "/weeklyPlan/" + selectedDropdownValue + "/" + newDate).set({
    id: selectedDropdownValue,
    lessons: lessonId,
    date: selectedDate.toDateString(),
    shared: shareReq,
    sharedOn: moment().valueOf(),
  });
}

function getWeeklyLesson(from, to, selectedRoom, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel((emit) => {
    rsf.ref(branchPath + "/weeklyPlan/" + selectedRoom).on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/weeklyPlan/" + selectedRoom).off();
      console.log("unsubscribe weekly lessons");
    };
  });
}

function getSharedWeeklyLesson(selectedRoom, firebase, studentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let studentLessonShare =
    firebase && firebase.schoolConfig.studentLessonShare
      ? firebase.schoolConfig.studentLessonShare
      : false;

  let myref;
  if (studentLessonShare) {
    myref = rsf.ref(branchPath + "/weeklyPlan");
  } else {
    myref = rsf.ref(branchPath + "/weeklyPlan/" + selectedRoom);
  }

  return eventChannel((emit) => {
    myref.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (studentLessonShare) {
          element.forEach((el) => {
            if (el.val() !== null && el.val().students && el.val().students.includes(studentId)) {
              x.push(el.val());
            }
          });
        } else {
          if (element.val() !== null && element.val().shared) {
            x.push(element.val());
          }
        }
      });

      emit(x);
    });
    return () => {
      myref.off();
      console.log("unsubscribe weekly lessons");
    };
  });
}

function deleteWeeklyPlan(searchableDate, classDropdown, lessons, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/weeklyPlan/" + classDropdown + "/" + searchableDate).update({
    lessons: lessons,
  });
}

function deleteLessonRecord(value, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/lessons/" + value.id).update({
    deleted: true,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    platform: "web",
  });
}

function deleteSingleMilestone(element, index, item, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  element.deleted = true;
  rsf.ref(branchPath + "/curriculum-milestone/" + element.id).update(element);
}

function deleteSingleDomain(item, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/developmentAreas/" + item.id).set(null);
  if (item.subDevelopmentArea) {
    item.subDevelopmentArea.forEach((sId) => {
      rsf.ref(branchPath + "/developmentAreas/" + sId).set(null);
    });
  }
}

function getDownloadUrlForAttachment(files, firebase, bPath, mediaType, updateprogress) {
  let branchPath = bPath ? bPath : firebase.sbp;

  var promiseList = [];
  var urls = [];
  files.fileList.forEach(function (singleFile, index) {
    // var file = singleFile;
    var file = singleFile.originFileObj;

    var fileName = new Date().getTime() + file.name;
    var metadata = {
      contentType: singleFile.type,
    };

    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    let attachmentRef;
    if (mediaType === "file") {
      attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
    } else {
      attachmentRef = storageRef.child(branchPath + "/media/lessons/" + fileName);
    }

    var p2 = new Promise(function (resolve, reject) {
      attachmentRef.put(file, metadata).on(
        Firebase.default.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          //notification('success', 'Upload is ' + progress + '% done');
          store.dispatch({
            type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
            attachmentProgress: progress,
          });
        },
        function (error) {
          reject(error);
        },
        function () {
          var p2 = attachmentRef.getDownloadURL().then(function (downloadURL) {
            urls.push(downloadURL);
            resolve(p2);
          });
        }
      );
    });
    promiseList.push(p2);
  });
  return Promise.all(promiseList)
    .then(function () {
      store.dispatch({
        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
        attachmentProgress: 100,
        allFilesUploaded: true,
      });
      return urls;
    })
    .catch((error) => {
      console.log("error uploading lesson attachhment", error);
      throw error;
    });
}

function updateShareLesson(lesson, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/lessons/" + lesson.id).update(lesson);
}

function createLessonRefNode(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/studentLessonRef/" + studentId).push().key;
  return key;
}

function updateStudentLessonRef(studentId, lessonId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let sharedTime = moment().valueOf();

  rsf.ref(branchPath + "/studentLessonRef/" + studentId + "/" + lessonId).update({
    done: false,
    lessonId: lessonId,
    seen: false,
    sharedOn: sharedTime,
    inversedSharedDate: -sharedTime,
  });
}

function generateSchoolAssignmentNode(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/schoolAssignment").push().key;
  return key;
}

function updateSchoolAssignment(obj, id, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf
    .ref()
    .child(branchPath + "/schoolAssignment/" + id)
    .update(obj);
}

function generateStudentAssignmentNode(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/studentAssignments").push().key;
  return key;
}

function updateStudentAssignment(obj, id, studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf
    .ref()
    .child(branchPath + "/studentAssignments/" + studentId + "/" + id)
    .update(obj);
}

function getLessonById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var lessonPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/lessons/" + id)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() !== null && !snapshot.val().deleted) {
          resolve(snapshot.val());
        } else {
          var data = {};
          resolve(data);
        }
      });
  });
  return lessonPromise;
}

function updateYoutubeToLesson(id, url, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf.ref(branchPath + "/lessons/" + id).update({
    youtubeUrl: url,
    platform: "web",
  });
}

function updateLessonMedia(id, urls, mediaType, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let urlList = [];
  let tempMediaType = "none";
  urls.map((m) => {
    urlList.push(m.path);
    tempMediaType = m.type ? m.type : "none";
  });

  rsf.ref(branchPath + "/lessons/" + id).update({
    mediaProfiles: urls,
    mediaUrls: urlList,
    mediaType: urls.length > 0 ? tempMediaType : null,
    platform: "web",
  });
}

function deleteCategory(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/categories/" + record.id).set(null);
}

function createMilestoneId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/curriculum-milestone").push().key;
  return key;
}

function updateDAreas(obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/developmentAreasEyfs/" + obj.id).update(obj);
}

function saveMilestoneAlt(values, firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/curriculum-milestone-eyfs/" + id).update({
    id: id,
    name: values.description,
  });
}

function deleteLabel(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/labels/" + record.id).set(null);
}

function updateweeklyPlanShareStatus(shareStatus, lesson, classDropdown, firebase, studentIds) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let date = lesson.date;
  let searchableDate = date.toDateString();

  rsf.ref(branchPath + "/weeklyPlan/" + classDropdown + "/" + searchableDate).update({
    shared: shareStatus ? false : true,
    sharedOn: shareStatus ? null : moment().valueOf(),
    students: studentIds && studentIds.length > 0 ? studentIds : null,
  });
}

function deleteStudentAssignment(id, studentId, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  rsf
    .ref()
    .child(branchPath + "/studentAssignments/" + studentId + "/" + id)
    .set(null);
}

function getLessonAdditionalFields(firebase){
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var formField = [];
  var formPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/lessonFieldRef")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null) {
            formField.push(item.val());
          }
        });
        resolve(formField);
      });
  });
  return formPromise;
}

function getDevelopmentAreas(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/developmentAreas")
      .on("value", (snap) => {
        var devAreas = [];
        snap.forEach((element) => {
          devAreas.push(element.val())
        });
        emit(devAreas);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/developmentAreas").off();
    };
  });

}

function getSubdevelopmentAreas(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/subDevelopmentAreas")
      .on("value", (snap) => {
        var subDevAreas = [];
        snap.forEach((element) => {
          subDevAreas.push(element.val())
        });
        emit(subDevAreas);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/subDevelopmentAreas").off();
    };
  });
}

function getObservationData(firebase, category) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let dataList = [];
  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/assessment-testDasboard/" + category).once("value").then(snapshot => {
        console.log("snapshot", snapshot.val())
        if (snapshot.val()) {
          dataList = snapshot.val()
        }
        resolve(dataList);
      })
  });
  return promise;
}

function getAssessmentTemplates(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/templates")
      .on("value", (snap) => {
        var assessmentTemplates = [];
        snap.forEach((element) => {
          assessmentTemplates.push(element.val())
        });
        emit(assessmentTemplates);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/templates").off();
      console.log("END");
    };
  });

}

function getTerminologyTerms(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/terminologyData")
      .on("value", (snap) => {
        let terminologyTerms = [];
        snap.forEach((element) => {
          terminologyTerms.push(element.val())
        });
        emit(terminologyTerms);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/terminologyData").off();
      console.log("terminology terms channel closed")
    };
  });
}

function getProgressName(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/assessmentProgressStatus")
      .on("value", (snap) => {
        let progressName = [];
        snap.forEach((element) => {
          progressName.push(element.val())
        });
        emit(progressName);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/assessmentProgressStatus").off();
      console.log("progress name channel closed")
    };
  });
}
function getProgressStatus(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      // .ref(branchPath + "/assessment-developmentAreaProgressStatus")
      .ref(branchPath + "/assessment/developmentAreaProgressStatus")
      .on("value", (snap) => {
        let progressStatus = [];
        snap.forEach((element) => {
          progressStatus.push(element.val())
        });
        emit(progressStatus);
      });
    return () => {
      // rsf.ref(branchPath + "/assessment-developmentAreaProgressStatus").off();
      rsf.ref(branchPath + "/assessment/developmentAreaProgressStatus").off();
      console.log("progress status channel closed")
    };
  });
}
function getDevelopmentAreaNames(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/developmentAreas")
      .on("value", (snap) => {
        let developmentAreas = [];
        snap.forEach((element) => {
          developmentAreas.push(element.val())
        });
        emit(developmentAreas);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/developmentAreas").off();
      console.log("progress status channel closed")
    };
  });
}
function getStudentAssessmentData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/studentAssessmentData")
      .on("value", (snap) => {
        let assessments = [];
        snap.forEach((element) => {
          assessments.push(element.val())
        });
        emit(assessments);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/studentAssessmentData").off();
      console.log("studentAssessmentData status channel closed")
    };
  });
}
function getStudentAssessmentById(firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var assessmentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/assessment/studentAssessmentData/" + id)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() !== null && !snapshot.val().deleted) {
          resolve(snapshot.val());
        } else {
          var data = {};
          resolve(data);
        }
      });
  });
  return assessmentPromise;
}
// function getStatusNameById(firebase) {
//   const rsf = firebase.secondaryDb;
//   let branchPath = firebase.sbp;
//   return eventChannel((emit) => {
//     rsf
//       .ref(branchPath + "/assessment-developmentAreaProgressStatus")
//       .on("value", (snap) => {
//         let progressStatus = [];
//         snap.forEach((element) => {
//           progressStatus.push(element.val())
//         });
//         emit(progressStatus);
//       });
//     return () => {
//       rsf.ref(branchPath + "/assessment-developmentAreaProgressStatus").off();
//       console.log("progress status channel closed")
//     };
//   });
// }

function getDevelopmentAreaProgressStatus(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/developmentAreaProgressStatus")
      .on("value", (snap) => {
        let progressStatus = [];
        snap.forEach((element) => {
          progressStatus.push(element.val())
        });
        emit(progressStatus);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/developmentAreaProgressStatus").off();
      console.log("progress status channel closed")
    };
  });
}
function getProgressData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/assessment/assessmentProgressStatus")
      .on("value", (snap) => {
        let progressData = [];
        snap.forEach((element) => {
          progressData.push(element.val());
        });
        emit(progressData);
      });
    return () => {
      rsf.ref(branchPath + "/assessment/assessmentProgressStatus").off();
      console.log("Progress Data Channel off");
    }
  });
}

function getAssessmentNodeById(id, firebase, nodeCategory) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let data = {};
  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/" + nodeCategory + "/" + id).once("value").then(snapshot => {
        if (snapshot.val()) {
          data = snapshot.val();
        }
        resolve(data)
      })
  });
  return promise;
}

export const LearningApi = {
  getMilestones,
  getLessons,
  saveMilestoneDomain,
  saveMilestone,
  updatedMilestoneInDA,
  createMilestoneDomainNodeId,
  createLabelNodeId,
  addLabel,
  getLabel,
  updateMilestoneDomain,
  updateMilestoneDomainWithSubDomain,
  updateMilestone,
  createCategoryNodeId,
  addCategory,
  getCategory,
  getLearningClassroom,
  createLessonsNodeId,
  addLesson,
  updateLesson,
  updateLessonAttachment,
  createWeeklyPlanNodeId,
  addWeeklyPlan,
  getWeeklyLesson,
  deleteWeeklyPlan,
  deleteLessonRecord,
  deleteSingleMilestone,
  deleteSingleDomain,
  getDownloadUrlForAttachment,
  updateShareLesson,
  createLessonRefNode,
  updateStudentLessonRef,
  generateSchoolAssignmentNode,
  updateSchoolAssignment,
  generateStudentAssignmentNode,
  updateStudentAssignment,
  getLessonById,
  updateYoutubeToLesson,
  updateLessonMedia,
  deleteCategory,
  createMilestoneId,
  updateDAreas,
  saveMilestoneAlt,
  deleteLabel,
  updateweeklyPlanShareStatus,
  getSharedWeeklyLesson,
  deleteStudentAssignment,
  getLessonAdditionalFields,
  getDevelopmentAreas,
  getSubdevelopmentAreas,
  getAssessmentTemplates,
  getObservationData,
  getTerminologyTerms,
  getProgressName,
  getProgressStatus,
  getDevelopmentAreaNames,
  // getStatusNameById,
  getStudentAssessmentData,
  getStudentAssessmentById,
  getDevelopmentAreaProgressStatus,
  getProgressData,
  getAssessmentNodeById

};
