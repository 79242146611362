import ActionButton from "antd/lib/modal/ActionButton";
import actions from "./actions";
const initState = {
  leaves: [],
  leavesChannel: undefined,
  isLoading: false,
  operationType: undefined,

  error: false,
  leavesComment: new Map(),
  studentLeaveToday: [],
  studentLeaveWeek: [],

  staffLeaves: [],
  staffLeavesChannel: undefined,
  staffLeaveToday: [],
  staffLeaveWeek: [],

  allTeachersList: [],
  allStudentsList: [],
  classrooms: [],
  leaveLeftObjPerTeacher: {},
  redirectstatus: false,
  moreThanAllocatedLeavesTaken: false,
  unsuccesfulAppliedstaffs: [],
  leaveDetails: {},
  check: undefined,
  checkforStaff: undefined,
};

export default function leavesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESET_REDIRECT:
      return {
        ...state,
        redirectStatus: false,
        leaveDetails: {},
      };

    case actions.GET_ALL_TEACHERS_FOR_LEAVE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_TEACHERS_FOR_LEAVE_SUCCESSFUL:
      return {
        ...state,
        allTeachersList: action.allTeachersList,
        isLoading: false,
      };
    case actions.GET_ALL_STUDENTS_FOR_LEAVE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_STUDENTS_FOR_LEAVE_SUCCESSFUL:
      return {
        ...state,
        allStudentsList: action.allStudentsList,
        classrooms: action.classrooms,
        isLoading: false,
      };
    case actions.SUBMIT_STAFF_LEAVE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUBMIT_STAFF_LEAVE_SUCCESSFUL:
      return {
        ...state,
        redirectStatus: action.redirectStatus,
        isLoading: false,
      };
    case actions.FETCH_LEAVE_DETAILS:
      return {
        ...state,
        leaveDetails: {},
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_LEAVE_DETAILS_SUCCESSFUL:
      return {
        ...state,
        leaveDetails: action.leaveDetails,
        isLoading: false,
        operationType: "LEAVE_FETCHED",
      };
    case actions.SUBMIT_STUDENT_LEAVE:
      return {
        ...state,
      };
    case actions.SUBMIT_STUDENT_LEAVE_SUCCESSFUL:
      return {
        ...state,
        redirectStatus: action.redirectStatus,
      };
    case actions.GET_ALL_LEAVES:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        check: undefined,
      };
    case actions.GET_ALL_LEAVES_SUCCESSFUL:
      return {
        ...state,
        leaves: action.leaves,
        leavesChannel: action.leavesChannel,
        isLoading: false,
        operationType: action.operationType,
        check: action.check,
      };

    case actions.GET_NOTIFICATION_LEAVE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_NOTIFICATION_STAFF_LEAVE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_COMMENT_FOR_LEAVE:
      return {
        ...state,
        operationType: undefined,
      };
    case actions.GET_COMMENT_FOR_LEAVE_SUCCESSFUL:
      return {
        ...state,
        leavesComment: action.leavesComment,
      };

    case actions.LEAVE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        moreThanAllocatedLeavesTaken: action.moreThanAllocatedLeavesTaken,
        unsuccesfulAppliedstaffs: action.unsuccesfulAppliedstaffs,
      };

    case actions.RESOLVE_LEAVE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.RESOLVE_LEAVE_SUCCESSFUL:
      return {
        ...state,
        operationType: action.operationType,
        isLoading: false,
      };

    case actions.SAVE_LEAVE_COMMENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.SAVE_LEAVE_COMMENT_SUCCESSFUL:
      return {
        ...state,
        operationType: "COMMENT_SAVED",
        isLoading: false,
      };

    case actions.RESET_COMPLAINT_OPERATION_TYPE:
      return {
        ...state,
        operationType: undefined,
        isLoading: false,
      };

    case actions.GET_STUDENTS_ON_LEAVE:
      return {
        ...state,
        studentLeaveToday: [],
        studentLeaveWeek: [],
      };

    case actions.GET_STUDENTS_ON_LEAVE_SUCCESS:
      return {
        ...state,
        studentLeaveToday: action.studentLeaveToday,
        studentLeaveWeek: action.studentLeaveWeek,
      };

    case actions.GET_ALL_STAFF_LEAVES:
      return {
        ...state,
        isLoading: true,
        staffLeaves: [],
        operationType: undefined,
        checkforStaff: undefined,
      };
    case actions.GET_ALL_STAFF_LEAVES_SUCCESSFUL:
      return {
        ...state,
        staffLeaves: action.staffLeaves,
        staffLeavesChannel: action.staffLeavesChannel,
        isLoading: false,
        operationType: action.operationType,
        checkforStaff: action.checkforStaff,
      };

    case actions.RESOLVE_STAFF_LEAVE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.RESOLVE_STAFF_LEAVE_SUCCESSFUL:
      return {
        ...state,
        operationType: undefined,
        isLoading: false,
      };

    case actions.GET_STAFFS_ON_LEAVE:
      return {
        ...state,
        staffLeaveToday: [],
        staffLeaveWeek: [],
      };

    case actions.GET_STAFFS_ON_LEAVE_SUCCESS:
      return {
        ...state,
        staffLeaveToday: action.staffLeaveToday,
        staffLeaveWeek: action.staffLeaveWeek,
      };

    case actions.DELETE_LEAVE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_LEAVE_SUCCESS:
      return {
        ...state,
        operationType: action.operationType,
        isLoading: false,
      };

    case actions.RESET_LEAVE_OPERATION_TYPE:
      return {
        ...state,
        operationType: undefined,
        isLoading: false,
        moreThanAllocatedLeavesTaken: false,
        unsuccesfulAppliedstaffs: [],
      };

    default:
      return state;
  }
}
