const actions = {
  LIST_CURRICULUMS: "LIST_CURRICULUMS",
  LIST_CURRICULUM: "LIST_CURRICULUM",
  ADD_CURRICULUM: "ADD_CURRICULUM",
  ADD_CURRICULUM_SUCCESFUL: "ADD_CURRICULUM_SUCCESSFUL",
  UPDATE_CURRICULUM: "UPDATE_CURRICULUM",
  UPDATE_CURRICULUM_SUCCESSFUL: "UPDATE_CURRICULUM_SUCCESSFUL",
  TOGGLE_CURRICULUM: "TOGGLE_CURRICULUM",
  TOGGLE_CURRICULUM_SUCCESSFUL: "TOGGLE_CURRICULUM_SUCCESSFUL",

  initData: () => ({
    type: actions.LIST_CURRICULUMS,
  }),

  addCurriculum: (
    developmentArea,
    subArea,
    date,
    title,
    image,
    video,
    duration,
    materialRequired,
    description,
    instruction
  ) => ({
    type: actions.ADD_CURRICULUM,
    developmentArea,
    subArea,
    date,
    title,
    image,
    video,
    duration,
    materialRequired,
    description,
    instruction,
  }),

  updateCurriculum: (
    id,
    developmentArea,
    subArea,
    date,
    title,
    image,
    video,
    duration,
    materialRequired,
    description,
    instruction
  ) => ({
    type: actions.UPDATE_CURRICULUM,
    id,
    developmentArea,
    subArea,
    date,
    title,
    image,
    video,
    duration,
    materialRequired,
    description,
    instruction,
  }),

  toggleCurriculumData: (status, id) => ({
    type: actions.TOGGLE_CURRICULUM,
    status,
    id,
  }),
};
export default actions;
