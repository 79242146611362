const actions = {
  UPDATE_LEAD_FORM_SETTING: "UPDATE_LEAD_FORM_SETTING",
  UPDATE_LEAD_FORM_SETTING_SUCCESS: "UPDATE_LEAD_FORM_SETTING_SUCCESS",

  GET_LEADS_TABLE_DATA: "GET_LEADS_TABLE_DATA",
  GET_LEADS_TABLE_DATA_SUCCESSFUL: "GET_LEADS_TABLE_DATA_SUCCESSFUL",

  DELETE_LEAD_FORM: "DELETE_LEAD_FORM",
  DELETE_LEAD_FORM_SUCCESS: "DELETE_LEAD_FORM_SUCCESS",

  LEAD_FORM_ERROR: "LEAD_FORM_ERROR",

  RESET_LEAD_FORM_OPERATION: "RESET_LEAD_FORM_OPERATION",

  GET_LEAD_STATUS: "GET_LEAD_STATUS",
  GET_LEAD_STATUS_SUCCESS: "GET_LEAD_STATUS_SUCCESS",

  SAVE_LEAD_AUTOMATION: "SAVE_LEAD_AUTOMATION",
  SAVE_LEAD_AUTOMATION_SUCCESS: "SAVE_LEAD_AUTOMATION_SUCCESS",

  GET_AUTOMATION_TABLE_DATA: "GET_AUTOMATION_TABLE_DATA",
  GET_AUTOMATION_TABLE_DATA_SUCCESS: "GET_AUTOMATION_TABLE_DATA_SUCCESS",

  RESET_NEW_REDIRECT: "RESET_NEW_REDIRECT",
  RESET_NEW_REDIRECT_SUCCESS: "RESET_NEW_REDIRECT_SUCCESS",

  RESET_ACTION_REDIRECT: "RESET_ACTION_REDIRECT",
  RESET_ACTION_REDIRECT_SUCCESS: "RESET_ACTION_REDIRECT_SUCCESS",

  GET_RULE_DATA: "GET_RULE_DATA",
  GET_RULE_DATA_SUCCESS: "GET_RULE_DATA_SUCCESS",

  GET_RULE_ACTIONS: "GET_RULE_ACTIONS",
  GET_RULE_ACTIONS_SUCCESS: "GET_RULE_ACTIONS_SUCCESS",

  SAVE_ACTION_DETAILS: "SAVE_ACTION_DETAILS",
  SAVE_ACTION_DETAILS_SUCCESS: "SAVE_ACTION_DETAILS_SUCCESS",

  GET_ALL_STEPS: "GET_ALL_STEPS",
  GET_ALL_STEPS_SUCCESS: "GET_ALL_STEPS_SUCCESS",

  CHANGE_RULE_DETAILS: "CHANGE_RULE_DETAILS",
  CHANGE_RULE_DETAILS_SUCCESS: "CHANGE_RULE_DETAILS_SUCCESS",

  GET_ACTION_DETAIL: "GET_ACTION_DETAIL",
  GET_ACTION_DETAIL_SUCCESS: "GET_ACTION_DETAIL_SUCCESS",

  RESET_ACTION_DATA: "RESET_ACTION_DATA",
  RESET_ACTION_DATA_SUCCESS: "RESET_ACTION_DATA_SUCCESS",

  getLeadsTableData: (firebase) => ({
    type: actions.GET_LEADS_TABLE_DATA,
    firebase,
  }),

  getAutomationSteps: (firebase) => ({
    type: actions.GET_ALL_STEPS,
    firebase,
  }),

  resetActionData: () => ({
    type: actions.RESET_ACTION_DATA,
  }),

  getAutomationTableData: (firebase) => ({
    type: actions.GET_AUTOMATION_TABLE_DATA,
    firebase,
  }),

  getRuleData: (ruleId, firebase) => ({
    type: actions.GET_RULE_DATA,
    ruleId,
    firebase,
  }),

  getActionDetail: (actionId, firebase) => ({
    type: actions.GET_ACTION_DETAIL,
    actionId,
    firebase,
  }),

  getRuleActions: (ruleId, firebase) => ({
    type: actions.GET_RULE_ACTIONS,
    ruleId,
    firebase,
  }),

  changeRuleDetails: (key, value, id, firebase) => ({
    type: actions.CHANGE_RULE_DETAILS,
    key,
    value,
    id,
    firebase,
  }),

  updateLeadFormSetting: (values, record, firebase) => ({
    type: actions.UPDATE_LEAD_FORM_SETTING,
    values,
    record,
    firebase,
  }),

  deleteLeadForm: (record, firebase) => ({
    type: actions.DELETE_LEAD_FORM,
    record,
    firebase,
  }),

  saveLeadAutomation: (values, firebase) => ({
    type: actions.SAVE_LEAD_AUTOMATION,
    values,
    firebase,
  }),

  saveActionDetails: (actionId, values, firebase, prevAttachments) => ({
    type: actions.SAVE_ACTION_DETAILS,
    actionId,
    values,
    firebase,
    prevAttachments
  }),

  resetLeadFormOperation: () => ({
    type: actions.RESET_LEAD_FORM_OPERATION,
  }),

  getLeadStatus: (firebase) => ({
    type: actions.GET_LEAD_STATUS,
    firebase,
  }),

  resetRedirect: () => ({
    type: actions.RESET_NEW_REDIRECT,
  }),

  resetActionRedirect: () => ({
    type: actions.RESET_ACTION_REDIRECT,
  }),
};
export default actions;
