import actions from "./actions";
const initState = {
    isLoading: false,
    emailDeliveryStatus: {},
    isInviteSent: false
};
export default function staffInstallations(state = initState, { type, ...action }) {
    switch (type) {
        case actions.SEND_STAFF_INVITE:
            return {
                ...state,
                isLoading: true
            }
        case actions.SEND_STAFF_INVITE_SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                isInviteSent: true
            }
        case actions.GET_EMAIL_DELIVERY_STATUS:
            return {
                ...state,
            }
        case actions.GET_EMAIL_DELIVERY_STATUS_SUCCESS:
            return {
                ...state,
                emailDeliveryStatus: action.emailDeliveryStatus
            }
        case actions.RESET_IS_INVITE_SENT:
            return {
                ...state,
                isInviteSent: false
            }
        default:
            return state
    }
}