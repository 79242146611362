import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";

function createDocumentNode(bPath, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var key = rsf.ref(branchPath + "/documents/teacher").push().key;
  return key;
}

function getAllDocuments(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/documents/teacher")
      .orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });
        setTimeout(() => {
          emit(x);
        }, 1500);
      });
    return () => {
      rsf.ref(branchPath + "/documents/teacher").off();
      console.log("unsubscribe documents");
    };
  });
}

// function addDocument(files, uniqueNodeId, firebase) {
//   const rsf = firebase.secondaryDb;
//   let branchPath = firebase.sbp;

//   let attachmentRef;

//   if (files !== undefined) {
//     var file = files[0].originFileObj;
//     var fileName = file.name + new Date().getTime();
//     var metadata = {
//       contentType: files[0].type
//     };
//     const storage = firebase.secondaryStorage;
//     const storageRef = storage.ref();
//     if (metadata.contentType.match("image")) {
//       attachmentRef = storageRef.child(branchPath + "/media/images/" + fileName);
//     } else {
//       attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
//     }

//     attachmentRef.put(file, metadata).then(function (snapshot) {
//       attachmentRef.getDownloadURL().then(url => {
//         rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
//           createdBy: firebase.teacher.name,
//           createdOn: new Date().getTime(),
//           downloadUrl: url,
//           id: uniqueNodeId,
//           inverseDate: -new Date().getTime(),
//           lastModifiedBy: firebase.teacher.name,
//           name: files[0].name,
//           nameWithExtension: files[0].name + " " + files[0].type,
//           type: metadata.contentType.match("image") ? "image" : "doc"
//         });
//       });
//     });
//   }
// }

function addDocument(url, uniqueNodeId, mediaType, firebase, files, filePath, index) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
    filePath: filePath ? filePath : null,
    createdBy: firebase.teacher.name,
    createdOn: moment().valueOf(),
    downloadUrl: url.path,
    id: uniqueNodeId,
    inverseDate: -moment().valueOf(),
    lastModifiedBy: firebase.teacher.name,
    name: url.fileName,
    nameWithExtension: url.fileName,
    type: url.type && url.type === "file" ? "doc" : "image",
  });

  rsf.ref(branchPath + "/documents/admin/" + uniqueNodeId).update({
    filePath: filePath ? filePath : null,
    createdBy: firebase.teacher.name,
    createdOn: moment().valueOf(),
    downloadUrl: url.path,
    id: uniqueNodeId,
    inverseDate: -moment().valueOf(),
    lastModifiedBy: firebase.teacher.name,
    name: url.fileName,
    nameWithExtension: url.fileName,
    type: url.type && url.type === "file" ? "doc" : "image",
  });
}

function deleteDocument(record, firebase, filePath) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let teacherNode = "/documents/teacher/";
  let adminNode = "/documents/admin/";

  rsf.ref(branchPath + teacherNode + record.id).set(null);
  rsf.ref(branchPath + adminNode + record.id).set(null);

  if (record.type && record.type.toLowerCase() === "folder" && filePath) {
    rsf.ref(branchPath + teacherNode).once("value").then(snapshot => {
      snapshot.forEach(snap => {
        if (snap.val() !== null && !snap.val().deleted && snap.val().filePath && snap.val().filePath.includes(filePath)) {
          rsf.ref(branchPath + teacherNode + snap.val().id).set(null);
        }
      });
    });

    rsf.ref(branchPath + adminNode).once("value").then(snapshot => {
      snapshot.forEach(snap => {
        if (snap.val() !== null && !snap.val().deleted && snap.val().filePath &&  snap.val().filePath.includes(filePath)) {
          rsf.ref(branchPath + adminNode + snap.val().id).set(null);
        }
      });
    });
  }
}

function createDocumentNodeByCenter(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var key = rsf.ref(branchPath + "/documents/teacher").push().key;
  return key;
}

function uploadDocumentByCenter(files, bPath, firebase) {
  let branchPath = bPath;
  let attachmentRef;

  if (files !== undefined) {
    var file = files[0].originFileObj;
    var fileName = new Date().getTime() + file.name;
    var metadata = {
      contentType: files[0].type,
    };
    const storage = firebase.secondaryStorage;
    const storageRef = storage.ref();
    if (metadata.contentType.match("image")) {
      attachmentRef = storageRef.child(branchPath + "/media/images/" + fileName);
    } else {
      attachmentRef = storageRef.child(branchPath + "/media/file/" + fileName);
    }

    var p = new Promise(function (resolve, reject) {
      attachmentRef.put(file, metadata).then(function (snapshot) {
        attachmentRef.getDownloadURL().then((url) => {
          resolve(url);
        });
      });
    });
  }
  return p;
}

function addDocumentByCenter(url, files, uniqueNodeId, bPath, firebase, mediaType, filePath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  if (files !== undefined) {
    rsf.ref(branchPath + "/documents/teacher/" + uniqueNodeId).update({
      filePath: filePath ? filePath : null,
      createdBy: firebase.teacher.name,
      createdOn: moment().valueOf(),
      downloadUrl: url,
      id: uniqueNodeId,
      inverseDate: -moment().valueOf(),
      lastModifiedBy: firebase.teacher.name,
      name: files[0].name,
      nameWithExtension: files[0].name + " " + files[0].type,
      type: mediaType === "Photo" ? "image" : "doc",
    });
  }
}

export const DocumentApi = {
  getAllDocuments,
  addDocument,
  createDocumentNode,
  deleteDocument,
  addDocumentByCenter,
  createDocumentNodeByCenter,
  uploadDocumentByCenter,
};
