import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { eventChannel } from "redux-saga";

function getStaffSchedule(firebase, startDate, endDate) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  startDate = moment(startDate).startOf('day').valueOf();
  endDate = moment(endDate).endOf("day").valueOf();
  var myRef = rsf
    .ref(branchPath + "/staffSchedule")
    .orderByChild("startDate")
    .startAt(startDate).endAt(endDate);
  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      let scheduleValue = [];
      snap.forEach((item) => {
        if (item.val()) {
          scheduleValue.push(item.val());
        }
      });
      emitter(scheduleValue);
    });
    return () => {
      myRef.off();
    };
  });
}
function getSelectedStaffSchedule(scheduleId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(branchPath + "/staffSchedule/" + scheduleId)
  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      let scheduleValue = [];
      if (snap.val()) {
        scheduleValue.push(snap.val())
      }
      emitter(scheduleValue);
    });
    return () => {
      myRef.off();
    };
  });
}

function getAffectedSchedules(firebase, startDate, endDate, id, idType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  startDate = moment(startDate).startOf('day').valueOf();
  endDate = moment(endDate).endOf("day").valueOf();

  var myRef = rsf
    .ref(branchPath + "/staffSchedule")
    .orderByChild(idType)
    .equalTo(id);

  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      let scheduleValue = [];
      snap.forEach((item) => {
        if (item.val()) {
          if (idType == "id") {
            scheduleValue.push(item.val());
          }
          else if (idType == "parentId" && moment(item.val().startDate).isBetween(startDate, endDate, "days", "[]")) {
            scheduleValue.push(item.val());
          }
        }
      });
      emitter(scheduleValue);
    });
    return () => {
      console.log("affected schedule closed")
      myRef.off();
    };
  });
}
function getAllClasses(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/classrooms")
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val().className);
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export const staffScheduleApi = {
  getStaffSchedule,
  getSelectedStaffSchedule,
  getAffectedSchedules,
  getAllClasses
};