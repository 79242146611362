const actions = {
  LIST_MILESTONES: "LIST_MILESTONES",
  LIST_MILESTONES_SUCCESSFUL: "LIST_MILESTONES_SUCCESSFUL",

  LIST_DEV_AREA: "LIST_DEV_AREA",
  LIST_DEV_AREA_SUCCESS: "LIST_DEV_AREA_SUCCESS",

  LIST_LESSONS: "LIST_LESSONS",
  LIST_LESSONS_SUCCESSFUL: "LIST_LESSONS_SUCCESSFUL",
  SAVE_MILESTONE_DOMAIN: "SAVE_MILESTONE_DOMAIN",
  SAVE_MILESTONE_DOMAIN_SUCCESSFUL: "SAVE_MILESTONE_DOMAIN_SUCCESSFUL",
  SAVE_MILESTONE: "SAVE_MILESTONE",
  SAVE_MILESTONE_SUCCESSFUL: "SAVE_MILESTONE_SUCCESSFUL",
  GET_LABEL: "GET_LABEL",
  GET_LABEL_SUCCESSFUL: "GET_LABEL_SUCCESSFUL",
  ADD_LABEL: "ADD_LABEL",
  ADD_LABEL_SUCCESSFUL: "ADD_LABEL_SUCCESSFUL",
  UPDATE_MILESTONE_DOMAIN: "UPDATE_MILESTONE_DOMAIN",
  UPDATE_MILESTONE_DOMAIN_SUCCESSFUL: "UPDATE_MILESTONE_DOMAIN_SUCCESSFUL",
  UPDATE_MILESTONE: "UPDATE_MILESTONE",
  UPDATE_MILESTONE_SUCCESSFUL: "UPDATE_MILESTONE_SUCCESSFUL",
  LEARNING_REQUEST_FAILED: "LEARNING_REQUEST_FAILED",
  ADD_CATEGORY: "ADD_CATEGORY",
  ADD_CATEGORY_SUCCESSFUL: "ADD_CATEGORY_SUCCESSFUL",
  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_SUCCESSFUL: "GET_CATEGORY_SUCCESSFUL",
  GET_LEARNING_CLASSROOM: "GET_LEARNING_CLASSROOM",
  GET_LEARNING_CLASSROOM_SUCCESSFUL: "GET_LEARNING_CLASSROOM_SUCCESSFUL",
  ADD_LESSON: "ADD_LESSON",
  ADD_LESSON_SUCCESSFUL: "ADD_LESSON_SUCCESSFUL",
  ADD_LESSON_TO_ALL_CENTERS: "ADD_LESSON_TO_ALL_CENTERS",
  ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL: "ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL",
  UPDATE_LESSON: "UPDATE_LESSON",
  UPDATE_LESSON_SUCCESSFUL: "UPDATE_LESSON_SUCCESSFUL",
  UPDATE_LESSON_TO_ALL_CENTERS: "UPDATE_LESSON_TO_ALL_CENTERS",
  UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL: "UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL",
  ADD_WEEKLY_PLAN: "ADD_WEEKLY_PLAN",
  ADD_WEEKLY_PLAN_SUCCESSFUL: "ADD_WEEKLY_PLAN_SUCCESSFUL",
  GET_WEEKLY_PLAN: "GET_WEEKLY_PLAN",
  GET_WEEKLY_PLAN_SUCCESSFUL: "GET_WEEKLY_PLAN_SUCCESSFUL",
  DELETE_PLAN: "DELETE_PLAN",
  DELETE_PLAN_SUCCESSFUL: "DELETE_PLAN_SUCCESSFUL",
  DELETE_LESSON_RECORD: "DELETE_LESSON_RECORD",
  DELETE_LESSON_RECORD_SUCCESSFUL: "DELETE_LESSON_RECORD_SUCCESSFUL",
  DELETE_LESSON_RECORD_FOR_ALL_CENTER: "DELETE_LESSON_RECORD_FOR_ALL_CENTER",
  DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL: "DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL",
  DELETE_SINGLE_MILESTONE: "DELETE_SINGLE_MILESTONE",
  DELETE_SINGLE_MILESTONE_SUCCESSFUL: "DELETE_SINGLE_MILESTONE_SUCCESSFUL",
  DELETE_SINGLE_DOMAIN: "DELETE_SINGLE_DOMAIN",
  DELETE_SINGLE_DOMAIN_SUCCESSFUL: "DELETE_SINGLE_DOMAIN_SUCCESSFUL",
  RESET_LEARNING_OPERATION_TYPE: "RESET_LEARNING_OPERATION_TYPE",
  GET_STUDENT_FOR_LESSON: "GET_STUDENT_FOR_LESSON",
  GET_STUDENT_FOR_LESSON_SUCCESSFUL: "GET_STUDENT_FOR_LESSON_SUCCESSFUL",
  SHARE_LESSON: "SHARE_LESSON",
  SHARE_LESSON_SUCCESSFUL: "SHARE_LESSON_SUCCESSFUL",
  UPLOAD_LESSON_DATA: "UPLOAD_LESSON_DATA",
  UPLOAD_LESSON_DATA_SUCCESSFUl: "UPLOAD_LESSON_DATA_SUCCESSFUl",

  UPLOAD_START: "UPLOAD_START",
  UPLOAD_FINISH: "UPLOAD_FINISH",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  UPLOAD_RESET: "UPLOAD_RESET",

  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",

  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",

  ADD_SUB_DOMAIN: "ADD_SUB_DOMAIN",
  ADD_SUB_DOMAIN_SUCCESS: "ADD_SUB_DOMAIN_SUCCESS",

  UPDATE_SUB_DOMAIN: "UPDATE_SUB_DOMAIN",
  UPDATE_SUB_DOMAIN_SUCCESS: "UPDATE_SUB_DOMAIN_SUCCESS",

  GET_ASSESSMENT_PROGRESS_PARAM: "GET_ASSESSMENT_PROGRESS_PARAM",
  GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS: "GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS",

  SAVE_ASS_PROGRESS_PARAM: "SAVE_ASS_PROGRESS_PARAM",
  SAVE_ASS_PROGRESS_PARAM_SUCCESS: "SAVE_ASS_PROGRESS_PARAM_SUCCESS",

  DELETE_ASS_PROGRESS_PARAM: "DELETE_ASS_PROGRESS_PARAM",
  DELETE_ASS_PROGRESS_PARAM_SUCCESS: "DELETE_ASS_PROGRESS_PARAM_SUCCESS",

  DELETE_LABEL: "DELETE_LABEL",
  DELETE_LABEL_SUCCESS: "DELETE_LABEL_SUCCESS",

  FETCH_COMPLETE_SHARE_HISTORY: "FETCH_COMPLETE_SHARE_HISTORY",
  FETCH_COMPLETE_SHARE_HISTORY_SUCCESS: "FETCH_COMPLETE_SHARE_HISTORY_SUCCESS",

  UPDATE_SHARE_STATUS: "UPDATE_SHARE_STATUS",
  UPDATE_SHARE_STATUS_SUCCESS: "UPDATE_SHARE_STATUS_SUCCESS",

  GET_PLAN_EXTRA_DETAIL: "GET_PLAN_EXTRA_DETAIL",
  GET_PLAN_COMMENT_SUCCESS: "GET_PLAN_COMMENT_SUCCESS",
  GET_PLAN_SUBMISSION_SUCCESS: "GET_PLAN_SUBMISSION_SUCCESS",

  COPY_LESSON: "COPY_LESSON",
  COPY_LESSON_SUCCESS: "COPY_LESSON_SUCCESS",

  REPLACE_STUDENT: "REPLACE_STUDENT",
  REPLACE_STUDENT_SUCCESS: "REPLACE_STUDENT_SUCCESS",

  GET_LESSON_EXTRA_FIELDS: "GET_LESSON_EXTRA_FIELDS",
  GET_LESSON_EXTRA_FIELDS_SUCCESS: "GET_LESSON_EXTRA_FIELDS_SUCCESS",
  LIST_SUB_DEV_AREA: "LIST_SUB_DEV_AREA",
  LIST_SUB_DEV_AREA_SUCCESS: "LIST_SUB_DEV_AREA_SUCCESS",
  GET_DEVELOPMENTAREAS_BY_LABELS: "GET_DEVELOPMENTAREAS_BY_LABELS",
  GET_DEVELOPMENTAREAS_BY_LABELS_SUCCESSFUL: "GET_DEVELOPMENTAREAS_BY_LABELS_SUCCESSFUL",
  SAVE_FORM: "SAVE_FORM",
  SAVE_FORM_SUCCESSFUL: "SAVE_FORM_SUCCESSFUL",
  SAVE_FORM_FAILURE: "SAVE_FORM_FAILURE",
  RESET_SAVE_FORM_BOOLEAN: "RESET_SAVE_FORM_BOOLEAN",

  GET_ASSESSMENT_TEMPLATES: "GET_ASSESSMENT_TEMPLATES",
  GET_ASSESSMENT_TEMPLATES_SUCCESS: "GET_ASSESSMENT_TEMPLATES_SUCCESS",

  GET_SELECTED_DEVELOPMENT_AREAS: "GET_SELECTED_DEVELOPMENT_AREAS_DATA",
  GET_SELECTED_DEVELOPMENT_AREAS_SUCCESS: "GET_SELECTED_DEVELOPMENT_AREAS_DATA_SUCCESS",

  SAVE_TEMPLATE: "SAVE_TEMPLATE",
  SAVE_TEMPLATE_SUCCESS: "SAVE_TEMPLATE_SUCCESS",

  DELETE_ASSESSMENT_TEMPLATE: "DELETE_ASSESSMENT_TEMPLATE",
  DELETE_ASSESSMENT_TEMPLATE_SUCCESS: "DELETE_ASSESSMENT_TEMPLATE_SUCCESS",

  SET_ASSESSMENT_TEMPLATE_DATA_EMPTY: "SET_ASSESSMENT_TEMPLATE_DATA_EMPTY",

  GET_ASSESSMENT_TEMPLATE_DATA: "GET_ASSESSMENT_TEMPLATE_DATA",
  GET_ASSESSMENT_TEMPLATE_DATA_SUCCESS: "GET_ASSESSMENT_TEMPLATE_DATA_SUCCESS",

  SET_IS_CLONE: "SET_IS_CLONE",
  SET_SELECTED_DEVELOPMENT_AREAS_FROM_PREFIL: "SET_SELECTED_DEVELOPMENT_AREAS_FROM_PREFIL",
  GET_STUDENT_OBSERVATION_ATTACHEMENTS: "GET_STUDENT_OBSERVATION_ATTACHEMENTS",
  GET_STUDENT_OBSERVATION_ATTACHEMENTS_SUCCESSFUL: "GET_STUDENT_OBSERVATION_ATTACHEMENTS_SUCCESSFUL",
  SAVE_ASSESSMENT_FORM: "SAVE_ASSESSMENT_FORM",
  SAVE_ASSESSMENT_SUCCESSFUL: "SAVE_ASSESSMENT_SUCCESSFUL",
  SAVE_ASSESSMENT_FORM_FAILURE: "SAVE_ASSESSMENT_FORM_FAILURE",
  GET_OBSERVATION_DATA: "GET_OBSERVATION_DATA",
  GET_OBSERVATION_DATA_FAIL: "GET_OBSERVATION_DATA_FAIL",
  GET_OBSERVATION_DATA_SUCCESS: "GET_OBSERVATION_DATA_SUCCESS",

  GET_STUDENT_ASSESSMENT_DATA: "GET_STUDENT_ASSESSMENT_DATA",
  GET_STUDENT_ASSESSMENT_DATA_SUCCESS: "GET_STUDENT_ASSESSMENT_DATA_SUCCESS",

  GET_STUDENT_OBSERVATION: "GET_STUDENT_OBSERVATION",
  GET_STUDENT_OBSERVATION_SUCCESS: "GET_STUDENT_OBSERVATION_SUCCESS",
  GET_STUDENT_OBSERVATION_FAILURE: "GET_STUDENT_OBSERVATION_FAILURE",
  GET_DEVELOPMENT_AREAS_NAMES_BY_ID: "GET_DEVELOPMENT_AREAS_NAMES_BY_ID",
  GET_DEVELOPMENT_AREAS_NAMES_BY_ID_SUCCESS: "GET_DEVELOPMENT_AREAS_NAMES_BY_ID_SUCCESS",
  GET_DEVELOPMENT_AREAS_NAMES_BY_ID_FAILURE: "GET_DEVELOPMENT_AREAS_NAMES_BY_ID_FAILURE",
  GET_STATUS_NAME_BY_ID: "GET_STATUS_NAME_BY_ID",

  POPULATE_PROGRESS_NAME: "POPULATE_PROGRESS_NAME",
  POPULATE_PROGRESS_NAME_SUCCESS: "POPULATE_PROGRESS_NAME_SUCCESS",
  ADD_PROGRESS_NAME: "ADD_PROGRESS_NAME",
  ADD_PROGRESS_NAME_SUCCESS: "ADD_PROGRESS_NAME_SUCCESS",
  POPULATE_PROGRESS_STATUS: "POPULATE_PROGRESS_STATUS",
  POPULATE_PROGRESS_STATUS_SUCCESS: "POPULATE_PROGRESS_STATUS_SUCCESS",
  ADD_PROGRESS_NAME_FAILURE: "ADD_PROGRESS_NAME_FAILURE",
  DELETE_PROGRESS_NAME: "DELETE_PROGRESS_NAME",
  DELETE_PROGRESS_NAME_SUCCESS: "DELETE_PROGRESS_NAME_SUCCESS",
  DELETE_PROGRESS_NAME_FAILURE: "DELETE_PROGRESS_NAME_FAILURE",
  ADD_PROGRESS_STATUS_VALUE: "ADD_PROGRESS_STATUS_VALUE",
  ADD_PROGRESS_STATUS_VALUE_SUCCESS: "ADD_PROGRESS_STATUS_VALUE_SUCCESS",
  ADD_PROGRESS_STATUS_VALUE_FAILURE: "ADD_PROGRESS_STATUS_VALUE_FAILURE",
  DELETE_PROGRESS_STATUS_VALUE: "DELETE_PROGRESS_STATUS_VALUE",
  DELETE_PROGRESS_STATUS_VALUE_SUCCESS: "DELETE_PROGRESS_STATUS_VALUE_SUCCESS",
  DELETE_PROGRESS_STATUS_VALUE_FAILURE: "DELETE_PROGRESS_STATUS_VALUE_FAILURE",
  SET_DEFAULT_STATUS_VALUE: "SET_DEFAULT_STATUS_VALUE",
  SET_DEFAULT_STATUS_VALUE_SUCCESS: "SET_DEFAULT_STATUS_VALUE_SUCCESS",
  SET_DEFAULT_STATUS_VALUE_FAILURE: "SET_DEFAULT_STATUS_VALUE_FAILURE",
  POPULATE_TERMINOLOGY_TERMS: "POPULATE_TERMINOLOGY_TERMS",
  POPULATE_TERMINOLOGY_TERMS_SUCCESS: "POPULATE_TERMINOLOGY_TERMS_SUCCESS",
  POPULATE_TERMINOLOGY_TERMS_FAILURE: "POPULATE_TERMINOLOGY_TERMS_FAILURE",
  EDIT_TERMINOLOGY_TERM: "EDIT_TERMINOLOGY_TERM",
  EDIT_TERMINOLOGY_TERM_SUCCESS: "EDIT_TERMINOLOGY_TERM_SUCCESS",
  EDIT_TERMINOLOGY_TERM_FAILURE: "EDIT_TERMINOLOGY_TERM_FAILURE",
  UPDATE_PROGRESS_NAME_COLOR_CODE: "UPDATE_PROGRESS_NAME_COLOR_CODE",
  UPDATE_PROGRESS_NAME_COLOR_CODE_SUCCESS: "UPDATE_PROGRESS_NAME_COLOR_CODE",
  UPDATE_PROGRESS_NAME_COLOR_CODE_FAILURE: "UPDATE_PROGRESS_NAME_COLOR_CODE",
  UPDATE_PROGRESS_STATUS_COLOR_CODE: "UPDATE_PROGRESS_STATUS_COLOR_CODE",
  UPDATE_PROGRESS_STATUS_COLOR_CODE_SUCCESS: "UPDATE_PROGRESS_STATUS_COLOR_CODE_SUCCESS",
  UPDATE_PROGRESS_STATUS_COLOR_CODE_FAILURE: "UPDATE_PROGRESS_STATUS_COLOR_CODE_FAILURE",

  GET_PROGRESS_DATA: "GET_PROGRESS_DATA",
  GET_PROGRESS_DATA_SUCCESS: "GET_PROGRESS_DATA_SUCCESS",

  GET_STUDENT_ASSESSMENT_BY_ID: "GET_STUDENT_ASSESSMENT_BY_ID",
  GET_STUDENT_ASSESSMENT_BY_ID_SUCCESSFUL: "GET_STUDENT_ASSESSMENT_BY_ID_SUCCESSFUL",
  GET_STUDENT_ASSESSMENT_BY_ID_FAILURE: "GET_STUDENT_ASSESSMENT_BY_ID_FAILURE",
  DELETE_STUDENT_ASSESSMENT: "DELETE_STUDENT_ASSESSMENT",
  DELETE_STUDENT_ASSESSMENT_SUCCESS: "DELETE_STUDENT_ASSESSMENT_SUCCESS",
  DELETE_STUDENT_ASSESSMENT_FAILURE: "DELETE_STUDENT_ASSESSMENT_FAILURE",
  RESET_ASSESSMENT_FORM_FIELDS: "RESET_ASSESSMENT_FORM_FIELDS",
  DELETE_OBSERVATION: "DELETE_OBSERVATION",
  DELETE_OBSERVATION_SUCCESS: "DELETE_OBSERVATION_SUCCESS",
  GET_FORM_DATA_BY_ID: "GET_FORM_DATA_BY_ID",
  GET_FORM_DATA_BY_ID_SUCCESS: "GET_FORM_DATA_BY_ID_SUCCESS",
  VIEW_OBSERVATIONS: "VIEW_OBSERVATIONS",
  VIEW_OBSERVATIONS_SUCCESS: "VIEW_OBSERVATIONS_SUCCESS",
  GET_OBSERVATION_COMMENT_SUCCESS: "GET_OBSERVATION_COMMENT_SUCCESS",

  ADD_NOTE_PROGRESS_STATUS: "ADD_NOTE_PROGRESS_STATUS",
  GET_DEVELOPMENT_AREA_PROGRESS_STATUS: "GET_DEVELOPMENT_AREA_PROGRESS_STATUS",

  RESET_SAVED_SUCCESS: "RESET_SAVED_SUCCESS",
  RESET_TEMPLATE_CREATE_SUCCESS: "RESET_TEMPLATE_CREATE_SUCCESS",
  GET_OBSERVATION_DATA_BY_IDS: "GET_OBSERVATION_DATA_BY_IDS",
  GET_OBSERVATION_DATA_BY_IDS_SUCCESS: "GET_OBSERVATION_DATA_BY_IDS_SUCCESS",
  GET_DEVELOPMENT_AREA_PROGRESS_STATUS_SUCCESS: "GET_DEVELOPMENT_AREA_PROGRESS_STATUS_SUCCESS",

  ADD_NOTE_PROGRESS_STATUS_SUCCESS: "ADD_NOTE_PROGRESS_STATUS_SUCCESS",
  ADD_NOTE_PROGRESS_STATUS_FAILURE: "ADD_NOTE_PROGRESS_STATUS_FAILURE",
  RESET_SAVED_SUCCESS: "RESET_SAVED_SUCCESS",
  GET_STUDENT_PROGRESS_TRACK: "GET_STUDENT_PROGRESS_TRACK",
  GET_STUDENT_PROGRESS_TRACK_SUCCESS: "GET_STUDENT_PROGRESS_TRACK_SUCCESS",
  RESET_TEMPLATE_CREATE_SUCCESS: "RESET_TEMPLATE_CREATE_SUCCESS",
  SET_SELECTED_FORM_DATA_EMPTY: "SET_SELECTED_FORM_DATA_EMPTY",
  RESET_SAVED_SUCCESS: "RESET_SAVED_SUCCESS",

  GET_STUDENT_OBSERVATION_DATA: "GET_STUDENT_OBSERVATION_DATA",
  GET_STUDENT_OBSERVATION_DATA_SUCCESS: "GET_STUDENT_OBSERVATION_DATA_SUCCESS",
  GET_STUDENT_OBSERVATION_DATA_FAILURE: "GET_STUDENT_OBSERVATION_DATA_FAILURE",

  GET_STUDENT_REFLECTION_DATA: "GET_STUDENT_REFLECTION_DATA",
  GET_STUDENT_REFLECTION_DATA_SUCCESS: "GET_STUDENT_REFLECTION_DATA_SUCCESS",
  GET_STUDENT_REFLECTION_DATA_FAILURE: "GET_STUDENT_REFLECTION_DATA_FAILURE",

  GET_STUDENT_CONCERN_DATA: "GET_STUDENT_CONCERN_DATA",
  GET_STUDENT_CONCERN_DATA_SUCCESS: "GET_STUDENT_CONCERN_DATA_SUCCESS",
  GET_STUDENT_CONCERN_DATA_FAILURE: "GET_STUDENT_CONCERN_DATA_FAILURE",
  GET_STUDENT_OBSERVATION_BY_DEVAREA: "GET_STUDENT_OBSERVATION_BY_DEVAREA",
  GET_STUDENT_OBSERVATION_BY_DEVAREA_SUCCESS: "GET_STUDENT_OBSERVATION_BY_DEVAREA_SUCCESS",
  GET_STUDENT_OBSERVATION_BY_DEVAREA_FAILURE: "GET_STUDENT_OBSERVATION_BY_DEVAREA_FAILURE",
  RESET_OBSERVATION_FORM: "RESET_OBSERVATION_FORM",

  RESET_AREA_DELETED: "RESET_AREA_DELETED",
  RESET_AREA_EDITED: "RESET_AREA_EDITED",
  RESET_TEMPLATE_DELETED: "RESET_TEMPLATE_DELETED",
  SAVE_PDF_TEMPLATE: "SAVE_PDF_TEMPLATE",
  SAVE_PDF_TEMPLATE_SUCCESS: "SAVE_PDF_TEMPLATE_SUCCESS",
  SAVE_PDF_TEMPLATE_FAILURE: "SAVE_PDF_TEMPLATE_FAILURE",
  GET_PDF_TEMPLATE: "GET_PDF_TEMPLATE",
  GET_PDF_TEMPLATE_SUCCESS: "GET_PDF_TEMPLATE_SUCCESS",
  GET_PDF_TEMPLATE_FAILURE: "GET_PDF_TEMPLATE_FAILURE",
  RESET_PDF_TEMPLATE: "RESET_PDF_TEMPLATE",
  SHARE_PDF: "SHARE_PDF",

  MIGRATE_DEVELOPMENT_AREA_DATA: "MIGRATE_DEVELOPMENT_AREA_DATA",
  MIGRATE_DEVELOPMENT_AREA_DATA_SUCCESS: "MIGRATE_DEVELOPMENT_AREA_DATA_SUCCESS",
  MIGRATE_DEVELOPMENT_AREA_DATA_FAILURE: "MIGRATE_DEVELOPMENT_AREA_DATA_FAILURE",
  RESET_MIGRATE_STATE: "RESET_MIGRATE_STATE",

  RESET_SAVE_LESSON_SUCCESSFUL: "RESET_SAVE_LESSON_SUCCESSFUL",

  STORE_ASSESSMENT_TERMINOLOGY_DATA: "STORE_ASSESSMENT_TERMINOLOGY_DATA",

  GET_SUGGESTIONS: "GET_SUGGESTIONS",
  GET_SUGGESTIONS_SUCCESS: "GET_SUGGESTIONS_SUCCESS",
  CLEAR_SUGGESTIONS: "CLEAR_SUGGESTIONS",
  GPT_REQUEST_FAILED: "GPT_REQUEST_FAILED",
  GET_PARAPHRASE_TEXT: "GET_PARAPHRASE_TEXT",
  GET_PARAPHRASE_TEXT_SUCCESS: "GET_PARAPHRASE_TEXT_SUCCESS",
  GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT: "GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT",
  GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_SUCCESS: "GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_SUCCESS",
  GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_FAILURE: "GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_FAILURE",
  UPDATE_COLOR_CODE_SUCCESS: "UPDATE_COLOR_CODE_SUCCESS",
  UPDATE_COLOR_CODE_FAILURE: "UPDATE_COLOR_CODE_FAILURE",
  GET_DEV_AREA_FOR_STUDENT_ASSESSMENT: "GET_DEV_AREA_FOR_STUDENT_ASSESSMENT",
  GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_SUCCESS: "GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_SUCCESS",
  GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_FAIL: "GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_FAIL",
  EDIT_PROGRESS_VALUE: "EDIT_PROGRESS_VALUE",
  EDIT_PROGRESS_VALUE_SUCCESS: "EDIT_PROGRESS_VALUE_SUCCESS",
  EDIT_PROGRESS_VALUE_FAILURE: "EDIT_PROGRESS_VALUE_FAILURE",
  GET_PROGRESS_REPORT_DATA: "GET_PROGRESS_REPORT_DATA",
  GET_PROGRESS_REPORT_DATA_SUCCESS: "GET_PROGRESS_REPORT_DATA_SUCCESS",
  GET_PROGRESS_REPORT_DATA_FAIL: "GET_PROGRESS_REPORT_DATA_FAIL",
  GET_ASSESSMENT_REPORT_DATA: "GET_ASSESSMENT_REPORT_DATA",
  GET_ASSESSMENT_REPORT_DATA_SUCCESS: "GET_ASSESSMENT_REPORT_DATA_SUCCESS",
  GET_ASSESSMENT_REPORT_DATA_FAIL: "GET_ASSESSMENT_REPORT_DATA_FAIL",
  GET_COMPARISON_REPORT: "GET_COMPARISON_REPORT",
  GET_COMPARISON_REPORT_SUCCESS: "GET_COMPARISON_REPORT_SUCCESS",
  GET_COMPARISON_REPORT_FAIL: "GET_COMPARISON_REPORT_FAIL",
  RESET_DELETE_OPERATION_TYPE: "RESET_DELETE_OPERATION_TYPE",

  getMilestones: (firebase, milestones) => ({
    type: actions.LIST_MILESTONES,
    firebase,
    milestones,
  }),

  getDevelopmentAreas: (firebase) => ({
    type: actions.LIST_DEV_AREA,
    firebase,
  }),

  getLessons: (firebase, lessons, operationType) => ({
    type: actions.LIST_LESSONS,
    firebase,
    lessons,
    operationType,
  }),

  saveMilestoneDomain: (values, firebase) => ({
    type: actions.SAVE_MILESTONE_DOMAIN,
    values,
    firebase,
  }),

  updateSubDomain: (values, record, firebase) => ({
    type: actions.UPDATE_SUB_DOMAIN,
    values,
    record,
    firebase,
  }),

  addSubDomain: (values, record, firebase) => (
    {
      type: actions.ADD_SUB_DOMAIN,
      values,
      record,
      firebase,
    }),

  saveMilestone: (values, selectedMilestoneData, parentType, firebase) => (
    {
      type: actions.SAVE_MILESTONE,
      values,
      selectedMilestoneData,
      parentType,
      firebase,
    }),

  addLabel: (value, firebase) => ({
    type: actions.ADD_LABEL,
    value,
    firebase,
  }),

  getLabel: (category, firebase) => ({
    type: actions.GET_LABEL,
    category,
    firebase,
  }),

  updateMilestoneDomain: (values, record, firebase) => ({
    type: actions.UPDATE_MILESTONE_DOMAIN,
    values,
    record,
    firebase,
  }),

  updateMilestone: (values, editableMilestone, index, firebase) => ({
    type: actions.UPDATE_MILESTONE,
    values,
    editableMilestone,
    index,
    firebase,
  }),

  addCategory: (value, firebase) => ({
    type: actions.ADD_CATEGORY,
    value,
    firebase,
  }),

  getCategory: (firebase) => ({
    type: actions.GET_CATEGORY,
    firebase,
  }),

  getClassroom: (firebase) => ({
    type: actions.GET_LEARNING_CLASSROOM,
    firebase,
  }),

  addLesson: (value, selectedMilestones, classroom, firebase, lessonAdditionalFields) => ({
    type: actions.ADD_LESSON,
    value,
    selectedMilestones,
    classroom,
    firebase,
    lessonAdditionalFields
  }),

  addLessonToAllCenter: (value, selectedMilestones, branchList, firebase, lessonAdditionalFields) => ({
    type: actions.ADD_LESSON_TO_ALL_CENTERS,
    value,
    selectedMilestones,
    branchList,
    firebase,
    lessonAdditionalFields
  }),

  updateLesson: (
    values,
    editableLessonRecord,
    selectedMilestones,
    classroom,
    attachedFilesUrl,
    firebase,
    updateStoreLast,
    lessonAdditionalFields
  ) => ({
    type: actions.UPDATE_LESSON,
    values,
    editableLessonRecord,
    selectedMilestones,
    classroom,
    attachedFilesUrl,
    firebase,
    updateStoreLast,
    lessonAdditionalFields
  }),

  updateLessonToAllCenter: (
    values,
    editableLessonRecord,
    selectedMilestones,
    branchList,
    attachedFilesUrl,
    firebase,
    lessonAdditionalFields
  ) => ({
    type: actions.UPDATE_LESSON_TO_ALL_CENTERS,
    values,
    editableLessonRecord,
    selectedMilestones,
    branchList,
    attachedFilesUrl,
    firebase,
    lessonAdditionalFields
  }),

  saveWeeklyPlan: (
    selectedLesson,
    selectedDate,
    weeklyPlanData,
    selectedDropdownValue,
    firebase,
    roomList
  ) => ({
    type: actions.ADD_WEEKLY_PLAN,
    selectedLesson,
    selectedDate,
    weeklyPlanData,
    selectedDropdownValue,
    firebase,
    roomList,
  }),

  getWeeklyLesson: (from, to, selectedRoom, firebase) => ({
    type: actions.GET_WEEKLY_PLAN,
    from,
    to,
    selectedRoom,
    firebase,
  }),

  deleteLesson: (selectedItem, item, weeklyPlan, classDropdown, firebase, studentList) => ({
    type: actions.DELETE_PLAN,
    selectedItem,
    item,
    weeklyPlan,
    classDropdown,
    firebase,
    studentList,
  }),

  deleteLessonRecord: (value, firebase) => ({
    type: actions.DELETE_LESSON_RECORD,
    value,
    firebase,
  }),

  deleteLessonRecordAllCenter: (value, firebase, branchList) => ({
    type: actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER,
    value,
    firebase,
    branchList,
  }),

  deleteSelectedMilestone: (element, index, item, firebase) => ({
    type: actions.DELETE_SINGLE_MILESTONE,
    element,
    index,
    item,
    firebase,
  }),

  deleteEntireSelectedDomain: (item, firebase, area) => {
    return {
      type: actions.DELETE_SINGLE_DOMAIN,
      item,
      firebase,
      area,
    }
  },

  resetLearningOperation: () => ({
    type: actions.RESET_LEARNING_OPERATION_TYPE,
  }),

  getStudent: (firebase) => ({
    type: actions.GET_STUDENT_FOR_LESSON,
    firebase,
  }),

  shareLesson: (students, record, values, selectedClass, firebase, shareDate) => ({
    type: actions.SHARE_LESSON,
    students,
    record,
    values,
    selectedClass,
    firebase,
    shareDate,
  }),

  uploadLessonCsvData: (data, category, firebase) => ({
    type: actions.UPLOAD_LESSON_DATA,
    data,
    category,
    firebase,
  }),

  resetUpload: () => ({
    type: actions.UPLOAD_RESET,
  }),

  updateCategory: (name, record, firebase) => ({
    type: actions.UPDATE_CATEGORY,
    name,
    record,
    firebase,
  }),

  deleteSelectedCategory: (record, firebase) => ({
    type: actions.DELETE_CATEGORY,
    record,
    firebase,
  }),

  getAssessmentProgressParam: (firebase) => ({
    type: actions.GET_ASSESSMENT_PROGRESS_PARAM,
    firebase,
  }),

  saveAssessmentProgressParam: (name, firebase, background, textColor) => ({
    type: actions.SAVE_ASS_PROGRESS_PARAM,
    name,
    firebase,
    background,
    textColor,
  }),

  deleteAssProgressParam: (record, firebase) => ({
    type: actions.DELETE_ASS_PROGRESS_PARAM,
    record,
    firebase,
  }),

  deleteSelectedLabel: (record, firebase) => ({
    type: actions.DELETE_LABEL,
    record,
    firebase,
  }),

  fetchShareHistory: (lessons, firebase) => ({
    type: actions.FETCH_COMPLETE_SHARE_HISTORY,
    lessons,
    firebase,
  }),

  updateShareStatus: (sharedStatus, lesson, classroom, firebase, students) => ({
    type: actions.UPDATE_SHARE_STATUS,
    sharedStatus,
    lesson,
    classroom,
    firebase,
    students,
  }),

  fetchPlanExtraDetails: (plan, firebase) => ({
    type: actions.GET_PLAN_EXTRA_DETAIL,
    plan,
    firebase,
  }),

  copyLesson: (lesson, firebase) => ({
    type: actions.COPY_LESSON,
    lesson,
    firebase,
  }),

  replacedStudent: (students) => ({
    type: actions.REPLACE_STUDENT,
    students,
  }),

  getLessonAdditionalFields: (firebase) => ({
    type: actions.GET_LESSON_EXTRA_FIELDS,
    firebase
  }),

  getSubdevelopmentAreas: (firebase) => ({
    type: actions.LIST_SUB_DEV_AREA,
    firebase,
  }),

  getDevelopmentAreasByLabels: (labels, selectedDevelopmentAreaList, selectedSubDevelopmentAreaList, selectedMilestoneList, firebase) => ({
    type: actions.GET_DEVELOPMENTAREAS_BY_LABELS,
    labels,
    selectedDevelopmentAreaList,
    selectedSubDevelopmentAreaList,
    selectedMilestoneList,
    firebase,
  }),
  saveForm: (requestObj, formType, firebase) => (
    {
      type: actions.SAVE_FORM,
      requestObj,
      formType,
      firebase
    }),
  resetSaveFormBoolean: () => ({
    type: actions.RESET_SAVE_FORM_BOOLEAN
  }),
  getAssessmentTemplates: (firebase) => ({
    type: actions.GET_ASSESSMENT_TEMPLATES,
    firebase,
  }),
  getSelectedDevelopmentAreas: (firebase, preset) => ({
    type: actions.GET_SELECTED_DEVELOPMENT_AREAS,
    firebase,
    preset,
  }),
  saveTemplate: (firebase, template) => ({
    type: actions.SAVE_TEMPLATE,
    firebase,
    template,
  }),
  deleteAssessmentTemplate: (firebase, id) => ({
    type: actions.DELETE_ASSESSMENT_TEMPLATE,
    firebase,
    id,
  }),
  setAssessmentTemplateDataEmpty: () => ({
    type: actions.SET_ASSESSMENT_TEMPLATE_DATA_EMPTY,
  }),
  getAssessmentTemplateData: (id, firebase) => ({
    type: actions.GET_ASSESSMENT_TEMPLATE_DATA,
    id,
    firebase,
  }),
  setIsClone: (value) => ({
    type: actions.SET_IS_CLONE,
    value,
  }),
  setSelectedDevelopmentAreasFromPrefil: (data) => ({
    type: actions.SET_SELECTED_DEVELOPMENT_AREAS_FROM_PREFIL,
    data,
  }),
  getStudentObservationAttachments: (studentId, developmentAreaId, firebase) => ({
    type: actions.GET_STUDENT_OBSERVATION_ATTACHEMENTS,
    studentId,
    developmentAreaId,
    firebase
  }),
  saveAssessmentForm: (requestObj, firebase) => (
    {
      type: actions.SAVE_ASSESSMENT_FORM,
      requestObj,
      firebase
    }),
  getObservationData: (firebase, dateRange) => ({
    type: actions.GET_OBSERVATION_DATA,
    firebase,
    dateRange,
  }),
  getObservationDataByIds: (firebase, ids, field) => ({
    type: actions.GET_OBSERVATION_DATA_BY_IDS,
    firebase,
    ids,
    field,
  }),
  getStudentAssessmentData: (firebase) => ({
    type: actions.GET_STUDENT_ASSESSMENT_DATA,
    firebase
  }),

  populateProgressName: (firebase) => ({
    type: actions.POPULATE_PROGRESS_NAME,
    firebase,
  }),

  addProgressName: (val, firebase) => {
    return {
      type: actions.ADD_PROGRESS_NAME,
      val,
      firebase,
    };
  },

  deleteProgressName: (id, firebase) => ({
    type: actions.DELETE_PROGRESS_NAME,
    id,
    firebase,
  }),

  populateProgressStatus: (firebase) => ({
    type: actions.POPULATE_PROGRESS_STATUS,
    firebase,
  }),

  addStatusValue: (val, isDefault, firebase) => {
    return {
      type: actions.ADD_PROGRESS_STATUS_VALUE,
      val,
      isDefault,
      firebase,
    };
  },

  deleteStatusValue: (id, firebase) => ({
    type: actions.DELETE_PROGRESS_STATUS_VALUE,
    id,
    firebase,
  }),

  setDefaultStatusValue: (prevId, newId, firebase) => ({
    type: actions.SET_DEFAULT_STATUS_VALUE,
    prevId,
    newId,
    firebase,
  }),

  populateTerminologyTerms: (firebase) => ({
    type: actions.POPULATE_TERMINOLOGY_TERMS,
    firebase,
  }),

  editTerminologyTerm: (key, newTerm, firebase) => ({
    type: actions.EDIT_TERMINOLOGY_TERM,
    key,
    newTerm,
    firebase,
  }),

  updateProgressNameColorCode: (item, color, firebase) => ({
    type: actions.UPDATE_PROGRESS_NAME_COLOR_CODE,
    item,
    color,
    firebase,
  }),

  updateProgressStatusColorCode: (item, color, firebase) => ({
    type: actions.UPDATE_PROGRESS_STATUS_COLOR_CODE,
    item,
    color,
    firebase,
  }),

  getStudentObservation: (firebase, startDate, endDate) => ({
    type: actions.GET_STUDENT_OBSERVATION,
    firebase, startDate, endDate
  }),

  getDevelopmentAreasNamebyId: (firebase) => ({
    type: actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID,
    firebase,
  }),

  // getStatusNameById : (firebase) => ({
  //   type : actions.GET_STATUS_NAME_BY_ID,
  //   firebase,
  // }),
  getProgressData: (firebase) => ({
    type: actions.GET_PROGRESS_DATA,
    firebase,
  }),
  getStudentAssessmentById: (firebase, id) => (
    console.log("actions-----", id), {
      type: actions.GET_STUDENT_ASSESSMENT_BY_ID,
      firebase,
      id
    }),
  deleteStudentAssessment: (firebase, id) => ({
    type: actions.DELETE_STUDENT_ASSESSMENT,
    firebase,
    id
  }),
  resetAssessmentFormFields: () => ({
    type: actions.RESET_ASSESSMENT_FORM_FIELDS,
  }),
  getDevelopmentAreaProgressStatus: (firebase) => ({
    type: actions.GET_DEVELOPMENT_AREA_PROGRESS_STATUS,
    firebase,
  }),
  addNoteProgressStatus: (note, statusId, timestamp, timelineData, firebase, isNoteEdit, timelineDataNode,
    isTimelineDataNull, secondCallObj) => ({
      type: actions.ADD_NOTE_PROGRESS_STATUS,
      note, statusId, timestamp, timelineData, firebase, isNoteEdit, timelineDataNode, isTimelineDataNull, secondCallObj
    }),
  deleteObservation: (item, firebase, dateRange) => ({
    type: actions.DELETE_OBSERVATION,
    item,
    firebase,
    dateRange,
  }),
  getFormDataById: (id, formType, firebase) => ({
    type: actions.GET_FORM_DATA_BY_ID,
    id,
    formType,
    firebase
  }),
  viewObservations: (developmentAreaId, studentId, firebase) => ({
    type: actions.VIEW_OBSERVATIONS,
    developmentAreaId,
    studentId,
    firebase
  }),
  setSelectedFormDataEmpty: () => ({
    type: actions.SET_SELECTED_FORM_DATA_EMPTY,
  }),
  resetSavedSuccess: () => ({
    type: actions.RESET_SAVED_SUCCESS,
  }),
  resetTemplateCreateSuccess: () => ({
    type: actions.RESET_TEMPLATE_CREATE_SUCCESS,
  }),
  getStudentProgressTracker: (studentId, firebase) => ({
    type: actions.GET_STUDENT_PROGRESS_TRACK,
    studentId,
    firebase
  }),
  getStudentObservationData: (studentId, firebase) => ({
    type: actions.GET_STUDENT_OBSERVATION_DATA,
    studentId,
    firebase
  }),
  getStudentReflectionData: (studentId, firebase) => ({
    type: actions.GET_STUDENT_REFLECTION_DATA,
    studentId,
    firebase
  }),
  getStudentConcernData: (studentId, firebase) => ({
    type: actions.GET_STUDENT_CONCERN_DATA,
    studentId,
    firebase
  }),
  getStudentObservationByDevarea: (studentId, devId, firebase) => (
    {
      type: actions.GET_STUDENT_OBSERVATION_BY_DEVAREA,
      studentId,
      devId,
      firebase
    }),
  resetObservationForm: () => (
    {
      type: actions.RESET_OBSERVATION_FORM
    }
  ),
  resetAreaDeleted: () => ({
    type: actions.RESET_AREA_DELETED,
  }),
  resetAreaEdited: () => ({
    type: actions.RESET_AREA_EDITED,
  }),
  resetTemplateDeleted: () => ({
    type: actions.RESET_TEMPLATE_DELETED,
  }),
  savePdfTemplate: (pdfTemplateObj, firebase) => ({
    type: actions.SAVE_PDF_TEMPLATE,
    pdfTemplateObj,
    firebase

  }),
  getPdfTemplate: (assessmentTemplateId, firebase) => ({
    type: actions.GET_PDF_TEMPLATE,
    assessmentTemplateId,
    firebase
  }),
  resetPdfTemplate: () => ({
    type: actions.RESET_PDF_TEMPLATE
  }),
  sharePdf: (pdfObj, assessmentId, emailTemplate, studentId, firebase) => (
    {
      type: actions.SHARE_PDF,
      pdfObj,
      assessmentId,
      emailTemplate,
      studentId,
      firebase
    }),
  resetTemplateDeleted: () => ({
    type: actions.RESET_TEMPLATE_DELETED,
  }),
  migrateDevelopmentAreaData: (firebase, developmentAreaData, removePreviousDevAreas) => ({
    type: actions.MIGRATE_DEVELOPMENT_AREA_DATA,
    firebase,
    developmentAreaData,
    removePreviousDevAreas
  }),
  resetMigrateState: () => ({
    type: actions.RESET_MIGRATE_STATE,
  }),
  resetSaveLessonSuccessful: () => ({
    type: actions.RESET_SAVE_LESSON_SUCCESSFUL,
  }),

  getSuggestions: (suggestionData, firebase) => ({
    type: actions.GET_SUGGESTIONS,
    suggestionData,
    firebase,
  }),
  getParaphraseText: (text, firebase) => ({
    type: actions.GET_PARAPHRASE_TEXT,
    text,
    firebase,
  }),
  clearSuggestions: () => ({
    type: actions.CLEAR_SUGGESTIONS,
  }),
  getStudentAttendance: (startDate, endDate, studentId, firebase) => ({
    type: actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT,
    startDate,
    endDate,
    studentId,
    firebase
  }),
  getDevareaforStudentAssessment: (assessmentTemplateId, firebase) => ({
    type: actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT,
    assessmentTemplateId,
    firebase
  }),
  editProgressValue: (obj, value, firebase, valueType) => ({
    type: actions.EDIT_PROGRESS_VALUE,
    obj,
    value,
    firebase,
    valueType
  }),

  getProgressReportData: (classList, assessmentIds, firebase) => ({
    type: actions.GET_PROGRESS_REPORT_DATA,
    classList,
    assessmentIds,
    firebase
  }),
  getAssessmentReportData: (classList, firebase) => ({
    type: actions.GET_ASSESSMENT_REPORT_DATA,
    classList,
    firebase
  }),
  getComparisonReport: (requestBody, firebase) => ({
    type: actions.GET_COMPARISON_REPORT,
    requestBody,
    firebase
  }),
  resetDeleteOperationType: () => ({
    type: actions.RESET_DELETE_OPERATION_TYPE,
  })
};
export default actions;
