import actions from "./actions";
const initState = {
  isLoading: false,
  uploadOperationType: "",
  error: false,
  errorMessage: null,
  classrooms: [],
};
export default function onboardingReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPLOAD_STUDENT_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_STUDENT_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_STUDENT_CSV",
      };
    case actions.UPLOAD_HOLIDAY_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_HOLIDAY_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_HOLIDAY_CSV",
      };
    case actions.UPLOAD_TEACHER_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_TEACHER_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_TEACHER_CSV",
      };
    case actions.UPLOAD_SCHEDULE_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_SCHEDULE_CSV",
      };
    case actions.UPLOAD_SINGLE_DAY_SCHEDULE_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_SINGLE_DAY_SCHEDULE_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_SCHEDULE_CSV",
      };
    case actions.UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "ADD_SCHEDULE_CSV",
      };
    case actions.ONBOARDINGG_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage ? action.errorMessage : "Oops! Something went wrong",
      };
    case actions.GET_ONBOARDING_CLASSROOM:
      return {
        ...state,
        isLoading: true,
        uploadOperationType: "",
        errorMessage: null,
      };
    case actions.GET_ONBOARDING_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        uploadOperationType: "",
        classrooms: action.classrooms,
      };

    case actions.RESET_OPERATION:
      return {
        ...state,
        uploadOperationType: "",
        error: false,
      };

    default:
      return state;
  }
}
