import actions from "./actions";
const initState = {
  staffAttendance: [],
  allStaffs: [],
  classrooms: [],
  isLoading: false,
  isLeavesLoading: false,
  error: false,
  staffAttendanceChannel: undefined,
  operationType: undefined,
  staffMonthlyAttendance: [],
  staffNewAttendance: [],
  newStaffAttendanceChannel: undefined,
  staffsMap: new Map(),
  staffNewMonthlyAttendance: [],
  staffsOnLeave: [],
  staffLeaves: [],
  staffLeavesdaysData: new Map(),
  leaveDates: [],
};
export default function staffAttendanceReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_STAFF_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STAFF_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        staffAttendance: action.staffAttendance,
        isLoading: false,
        staffAttendanceChannel: action.staffAttendanceChannel,
        operationType: action.operationType,
      };

    case actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STAFF_ATTENDANCE_BY_CLASSNAME_SUCCESSFUL:
      return {
        ...state,
        staffAttendance: action.staffAttendance,
        isLoading: false,
        staffAttendanceChannel: action.staffAttendanceChannel,
        operationType: undefined,
      };

    case actions.FETCH_ALL_STAFF:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_ALL_STAFF_SUCCESSFUL:
      return {
        ...state,
        allStaffs: action.allStaffs,
        staffsMap: action.staffsMap,
        isLoading: false,
        operationType: undefined,
        staffsOnLeave: action.staffsOnLeave,
      };

    case actions.MARK_STAFF_PRESENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_STAFF_PRESENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.MARK_STAFF_ABSENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_STAFF_ABSENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.GET_STAFF_LEAVES:
      return {
        ...state,
        isLeavesLoading: true,
      };
    case actions.GET_STAFF_LEAVES_SUCCESS:
      return {
        ...state,
        isLeavesLoading: false,
        staffLeaves: action.payLoad.response,
        staffLeavesdaysData: action.payLoad.leavesDaysData,
        leaveDates: action.payLoad.leavesDate,
      };

    case actions.MARK_STAFF_CHECKOUT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_STAFF_CHECKOUT_SUCCESSFUL:
      return {
        ...state,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE:
      return {
        ...state,
        operationType: undefined,
      };
    case actions.FETCH_ALL_CLASSROOMS_FOR_STAFF_ATTENDANCE_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        operationType: undefined,
      };

    case actions.FETCH_STAFF_BY_CLASSNAME:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.FETCH_STAFF_BY_CLASSNAME_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allStaffs: action.allStaffs,
        operationType: undefined,
      };

    case actions.STAFF_ATTENDANCE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        isLeavesLoading: false,
      };

    case actions.RESET_STAFF_ATD_OPERATION:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.EMAIL_STAFF_ATTENDANCE:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.GET_STAFF_MONTHLY_ATD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_STAFF_MONTHLY_ATD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffMonthlyAttendance: action.staffMonthlyAttendance,
      };

    case actions.MARK_STAFF_PENDING:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.MARK_STAFF_PENDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_COMPONENT",
      };

    case actions.GET_STAFF_NEW_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_STAFF_NEW_ATTENDANCE_SUCCESS:
      return {
        ...state,
        staffNewAttendance: action.staffNewAttendance,
        isLoading: false,
        newStaffAttendanceChannel: action.newStaffAttendanceChannel,
        operationType: action.operationType,
      };

    case actions.GET_STAFF_NEW_MONTHLY_ATD:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_STAFF_NEW_MONTHLY_ATD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staffNewMonthlyAttendance: action.staffNewMonthlyAttendance,
      };

    default:
      return state;
  }
}
