const actions = {
    FETCH_ALL_VACCINE: "FETCH_ALL_VACCINE",
    FETCH_ALL_VACCINE_SUCCESS: "FETCH_ALL_VACCINE_SUCCESS",

    SAVE_VACCINE: "SAVE_VACCINE",
    SAVE_VACCINE_SUCCESS: "SAVE_VACCINE_SUCCESS",

    SAVE_DOSE: "SAVE_DOSE",
    SAVE_DOSE_SUCCESS: "SAVE_DOSE_SUCCESS",

    REQUEST_FAILED: "REQUEST_FAILED",

    RESET_OPERATION: "RESET_OPERATION",

    GET_IMMUNIZATION_FOR_STUDENT: "GET_IMMUNIZATION_FOR_STUDENT",
    GET_IMMUNIZATION_FOR_STUDENT_SUCCESS: "GET_IMMUNIZATION_FOR_STUDENT_SUCCESS",

    UPDATE_STUDENT_DOSE: "UPDATE_STUDENT_DOSE",
    UPDATE_STUDENT_DOSE_SUCCESS: "UPDATE_STUDENT_DOSE_SUCCESS",

    UPDATE_STUDENT_VACCINATION: "UPDATE_STUDENT_VACCINATION",
    UPDATE_STUDENT_VACCINATION_SUCCESS: "UPDATE_STUDENT_VACCINATION_SUCCESS",

    DELETE_VACCINE: "DELETE_VACCINE",
    DELETE_VACCINE_SUCCESS: "DELETE_VACCINE_SUCCESS",

    SEND_VACCINES_REMINDER: "SEND_VACCINES_REMINDER",
    SEND_VACCINES_REMINDER_SUCCESS: "SEND_VACCINES_REMINDER_SUCCESS",

    SEND_VACCINE_REMINDER: "SEND_VACCINE_REMINDER",
    SEND_VACCINE_REMINDER_SUCCESS: "SEND_VACCINE_REMINDER_SUCCESS",

    DELETE_DOSE: "DELETE_DOSE",
    DELETE_DOSE_SUCCESS: "DELETE_DOSE_SUCCESS",

    RESET_STORE_DATA: "RESET_STORE_DATA",

    EXEMPT_STUDENT_VACCINES: "EXEMPT_STUDENT_VACCINES",
    EXEMPT_STUDENT_VACCINES_SUCCESS: "EXEMPT_STUDENT_VACCINES_SUCCESS",

    fetchAllVaccine: (firebase) => ({
        type: actions.FETCH_ALL_VACCINE,
        firebase,
    }),
    saveVaccine: (firebase, values, operationType) => ({
        type: actions.SAVE_VACCINE,
        firebase,
        values,
        operationType
    }),
    saveDose: (firebase, values, operationType) => ({
        type: actions.SAVE_DOSE,
        firebase,
        values,
        operationType
    }),
    resetOperation: () => ({
        type: actions.RESET_OPERATION
    }),
    getImmunizationForStudent: (studentId, firebase) => ({
        type: actions.GET_IMMUNIZATION_FOR_STUDENT,
        studentId,
        firebase
    }),
    updateStudentVaccination: (firebase, studentVaccination) => ({
        type: actions.UPDATE_STUDENT_VACCINATION,
        firebase,
        studentVaccination
    }),
    deleteVaccine: (firebase, vaccineId) => ({
        type: actions.DELETE_VACCINE,
        firebase,
        vaccineId
    }),
    sendVaccinesReminder: (firebase, studentId) => ({
        type: actions.SEND_VACCINES_REMINDER,
        firebase,
        studentId
    }),
    sendVaccineReminder: (firebase, studentId, vaccineId) => ({
        type: actions.SEND_VACCINE_REMINDER,
        firebase,
        studentId,
        vaccineId
    }),
    resetStoreData: () => ({
        type: actions.RESET_STORE_DATA
    }),
    deleteDose: (firebase, doseId) => ({
        type: actions.DELETE_DOSE,
        firebase,
        doseId
    }),
    updateStudentDose: (firebase, doseRecord, updateType, vaccineId, studentId, vaccineName) => ({
        type: actions.UPDATE_STUDENT_DOSE,
        firebase,
        doseRecord,
        updateType,
        vaccineId,
        studentId,
        vaccineName
    }),
    exemptStudentVaccines: (firebase, studentId, value) => ({
        type: actions.EXEMPT_STUDENT_VACCINES,
        firebase,
        studentId,
        value
    })
};
export default actions;
