const actions = {
    SEND_STAFF_INVITE: "SEND_STAFF_INVITE",
    SEND_STAFF_INVITE_SUCCESSFULL: "SEND_STAFF_INVITE_SUCCESSFULL",
    GET_EMAIL_DELIVERY_STATUS: "GET_EMAIL_DELIVERY_STATUS",
    GET_EMAIL_DELIVERY_STATUS_SUCCESS: "GET_EMAIL_DELIVERY_STATUS_SUCCESS",
    RESET_IS_INVITE_SENT: "RESET_IS_INVITE_SENT",
    sendInvite: (teachers, reminderMode, firebase, forcedUpdate) => (
        {
            type: actions.SEND_STAFF_INVITE,
            teachers,
            reminderMode,
            firebase,
            forcedUpdate,
        }),

    getStaffDeliveryReport: (data, firebase) => ({
        type: actions.GET_EMAIL_DELIVERY_STATUS,
        data,
        firebase,
    }),
    resetIsInviteSent: () => ({
        type: actions.RESET_IS_INVITE_SENT
    })
};
export default actions;