const url = {
  studentDetailedView: "students",
  teacherDetailedView: "staff",
  detailedProgram: "program",
  comments: "dashboard",
  lessonView: "lessons",
  shareHistory: "lessons",
  weeklyPlanSubmission: "lessons",
  selectedStudentAssessment: "assessments",
  savedAssessments: "assessments",
  studentInvoice: "studentBilling",
  expenseLogs: "expensesManagement",
  detailedTemplate: "invoiceTemplate",
  detailedFeePlan: "feeStructure",
  applicaForms: "applications",
  leadsDetailedView: "allLeads",
  formBuilder: ["applications", "leadForms"],
  feeStructure: "feeStructure",
  invoiceTemplate: "invoiceTemplate",
  applicationForms: "applications",
  formPreview: ["applications", "leadForms"],
  form: "forms",
  invoiceDetail: "invoice",
  paymentRecord: "invoice",
  manageLead: "allLeads",
  feeActionForm: "feeSetting",
  leadAboutDetailEdit: "allLeads",
  automationRuleSteps: "leadsAutomation",
  actionDetail: "leadsAutomation",
  addStep: "admissionProcess",
  creategroup: "messages"
};

export default url;
