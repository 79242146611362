import Enlang from "./entries/en-US";
import Zhlang from "./entries/zh-Hans-CN";
import Salang from "./entries/ar_SA";
import Itlang from "./entries/it_IT";
import Eslang from "./entries/es_ES";
import Arlan from "./entries/ar_ME";
import Ptlan from "./entries/pt_PT";
import Hulan from "./entries/hu_HU";
import Rulan from "./entries/ru_RU";
import Delan from "./entries/de_DE";
import Frlan from "./entries/fr_FR";
import Ltlan from "./entries/lt_LT";
import { addLocaleData } from "react-intl";

const AppLocale = {
  en: Enlang,
  zh: Zhlang,
  sa: Salang,
  it: Itlang,
  es: Eslang,
  ar: Arlan,
  pt: Ptlan,
  hu: Hulan,
  ru: Rulan,
  de: Delan,
  fr: Frlan,
  lt: Ltlan,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.sa.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.ar.data);
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.hu.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.lt.data);

export default AppLocale;
