import actions from "./actions";
import "@firebase/firestore";
import { callApi } from "../../Utility/superAgentUntil";
import { newFeePlanApi } from "../../firestore-api/newFeePlan";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import bugsnagClient from "@bugsnag/js";
import notification from "../../components/notification";
import formatMsg from '../../components/utility/formatMessageUtil';
import FilterAction from "../../Utility/FilterAction";


// AJit Start

function* getDiscountData({ firebase }) {

    const chan = yield call(newFeePlanApi.getDiscountData, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            yield put({
                type: actions.GET_DISCOUNT_DATA_SUCCESS,
                discountChannel: chan,
                discountData: data,
            })
        }

    } finally {
        console.log("fetched discount data");
    }
}

function* addDiscountData({ discountItem, firebase }) {
    try {
        let obj = {
            ...discountItem,
            redemptions: discountItem.redemptions ? discountItem.redemptions : 0
        }
        let endpoint = "woodlandApi/discount?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, obj);
        if (response && response.status && response.status === 200) {
            notification("success", discountItem.id ? formatMsg("success.editDiscount") : formatMsg("success.addDiscount"));
            yield put({
                type: actions.ADD_DISCOUNT_DATA_SUCCESS,
            })
        }
        else {
            notification("error", discountItem.id ? formatMsg("failed.editDiscount") : formatMsg("failed.addDiscount"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            })
        }
    } catch (e) {
        bugsnagClient.notify(e);
        notification("error", discountItem.id ? formatMsg("failed.editDiscount") : formatMsg("failed.addDiscount"));
        console.log("failed to add discount data", e);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        })
    }
}

function* checkIfDiscountIsBeingUsed({ firebase, discountId }) {
    try {
        let isPresent = yield call(newFeePlanApi.isDiscountAssigned, firebase, discountId);
        yield put({
            type: actions.CHECK_IF_DISCOUNT_IS_BEING_USED_SUCCESS,
            isDiscountBeingUsed: isPresent,
        })
    } catch (err) {
        console.log("failed check discount assigned", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* deleteDiscount({ firebase, discountId }) {
    try {
        let obj = {
            id: discountId
        };
        let endPointUrl =
            "woodlandApi/discount/" + discountId + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "delete", endPointUrl, obj);

        if (response.body.code == 200) {
            notification("success", formatMsg("success.discountDeleted"));
            yield put({
                type: actions.DELETE_DISCOUNT_SUCCESS,
            });
        } else {
            notification("error", formatMsg("failed.deleteDiscount"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        notification("error", formatMsg("failed.deleteDiscount"));
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* getTaxData({ firebase }) {

    const chan = yield call(newFeePlanApi.getTaxData, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            yield put({
                type: actions.GET_TAX_DATA_SUCCESS,
                taxChannel: chan,
                taxData: data,
            });
        }
    } finally {
        console.log("fetched tax data");
    }
}

function* addTaxData({ taxItem, firebase }) {
    try {
        let obj = {
            ...taxItem,
        };
        let endpoint = "woodlandApi/tax?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, obj);
        if (response && response.status && response.status === 200) {
            notification("success", taxItem.id ? formatMsg("success.editTax") : formatMsg("success.addTax"));
            yield put({
                type: actions.ADD_TAX_DATA_SUCCESS,
            });
        } else {
            notification("error", taxItem.id ? formatMsg("failed.editTax") : formatMsg("failed.addTax"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (e) {
        bugsnagClient.notify(e);
        notification("error", taxItem.id ? formatMsg("failed.editTax") : formatMsg("failed.addTax"));
        console.log("failed to add tax data", e);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* checkIfTaxIsBeingUsed({ firebase, taxId }) {
    try {
        let isPresent = yield call(newFeePlanApi.isTaxAssigned, firebase, taxId);
        yield put({
            type: actions.CHECK_IF_TAX_IS_BEING_USED_SUCCESS,
            isTaxBeingUsed: isPresent,
        });
    } catch (err) {
        console.log("failed check tax assigned", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* deleteTax({ firebase, taxId }) {
    try {
        let obj = {
            id: taxId,
        };
        let endPointUrl =
            "woodlandApi/tax/" + taxId + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "delete", endPointUrl, obj);

        if (response.body.code == 200) {
            notification("success", formatMsg("success.taxDeleted"));
            yield put({
                type: actions.DELETE_TAX_SUCCESS,
            });
        } else {
            notification("error", formatMsg("failed.deleteTax"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        notification("error", formatMsg("failed.deleteTax"));
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* getFeeComponentListData({ firebase }) {

    const chan = yield call(newFeePlanApi.getFeeComponents, firebase);
    try {
        while (true) {
            let data = yield take(chan);
            yield put({
                type: actions.GET_FEE_COMPONENT_LIST_DATA_SUCCESS,
                feeComponentChannel: chan,
                feeComponentList: data,
            });
        }
    } finally {
        console.log("fetched fee components");
    }
}

function* addFeeComponent({ feeComponentItem, firebase }) {
    try {
        let endpoint = "woodlandApi/feeComponent?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, feeComponentItem);
        if (response && response.status && response.status === 200) {
            notification("success", feeComponentItem.id ? formatMsg("success.editFeeComponent") : formatMsg("success.addedFeeComponent"));
            yield put({
                type: actions.ADD_NEW_FEE_COMPONENT_SUCCESS,
            });
        } else {
            notification("error", feeComponentItem.id ? formatMsg("failed.editFeeComponent") : formatMsg("failed.addFeeComponent"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (e) {
        bugsnagClient.notify(e);
        notification("error", feeComponentItem.id ? formatMsg("failed.editFeeComponent") : formatMsg("failed.addFeeComponent"));
        console.log("failed to add fee component", e);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* deleteFeeComponent({ firebase, feeComponentId }) {
    try {
        let obj = {
            id: feeComponentId,
        };
        let endPointUrl =
            "woodlandApi/newFeeComponent/" + feeComponentId + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "delete", endPointUrl, obj);

        if (response.body.code == 200) {
            notification("success", formatMsg("success.removeFeeComponent"));
            yield put({
                type: actions.DELETE_NEW_FEE_COMPONENT_SUCCESS,
            });
        } else {
            notification("error", formatMsg("failed.removeFeeComponent"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        notification("error", formatMsg("failed.removeFeeComponent"));
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* checkIfFeeComponentIsBeingUsed({ firebase, feeComponentId }) {
    try {
        let isPresent = yield call(newFeePlanApi.isFeeComponentUsed, firebase, feeComponentId);
        yield put({
            type: actions.CHECK_IF_FEE_COMPONENT_IS_BEING_USED_SUCCESS,
            isFeeComponentBeingUsed: isPresent,
        });
    } catch (err) {
        console.log("failed check fee component in line items", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* fetchSelectedFeePlan({ firebase, feePlanId }) {
    try {
        let obj = {
            id: feePlanId,
        };
        let endPointUrl =
            "woodlandApi/newFeePlan/" + feePlanId + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "get", endPointUrl, obj);
        // let response = {
        //     body: {
        //         "code": 200,
        //         "response": {
        //             "billingPeriod": "Current",
        //             "dueAfterDays": 9,
        //             "frequency": "Quarterly",
        //             "generationDate": 9,
        //             "id": "-NgOVeJiLDGoHSEYuv1g",
        //             "label": [
        //                 "fee template 1"
        //             ],
        //             "name": "Shiv",
        //             "nextGenerationDate": 1696876200000,
        //             "parentNote": "Parent notes",
        //             "paused": false,
        //             "platform": "web",
        //             "programId": "-NRlkiOFNAUbBwOr3WCu",
        //             "schoolNote": "School Notes",
        //             "sendInvoiceMail": true,
        //             "sendPushNotification": true,
        //             "startDate": 1696098600000,
        //             "totalAssignedStudents": 4,
        //             "type": "fixedDate",
        //             "updatedBy": "Racheal Singh Ahluwaliaa",
        //             "updatedOn": 1696940072485,
        //             "assignStudents": [
        //                 {
        //                     "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "id": "-NgOVf12e_2B06M-ZpfC",
        //                     "paused": true,
        //                     "planId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "statusChangedDate": 1696940238569,
        //                     "stuPlanId": "-N_3o1fVfrL4hx2RZ2w3-NgOVeJiLDGoHSEYuv1g",
        //                     "studentId": "-N_3o1fVfrL4hx2RZ2w3",
        //                     "discounts": []
        //                 },
        //                 {
        //                     "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "id": "-NgOVf12e_2B06M-ZpfB",
        //                     "lastGenerationDate": 1696940456604,
        //                     "paused": false,
        //                     "planId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "statusChangedDate": 1696940072452,
        //                     "stuPlanId": "-NaGWYx0GgRDxzj2ryVD-NgOVeJiLDGoHSEYuv1g",
        //                     "studentId": "-NaGWYx0GgRDxzj2ryVD",
        //                     "discounts": [
        //                         {
        //                             "type": "feePlan",
        //                             "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                             "discountId": "-Nf_cruocitoCl5F33IC"
        //                         }
        //                     ]
        //                 },
        //                 {
        //                     "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "id": "-NgOVf119WLXMtWfS5W3",
        //                     "lastGenerationDate": 1696940454541,
        //                     "paused": false,
        //                     "planId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "statusChangedDate": 1696940072452,
        //                     "stuPlanId": "-NaGYwmLq0hJv1JNpbZ8-NgOVeJiLDGoHSEYuv1g",
        //                     "studentId": "-NaGYwmLq0hJv1JNpbZ8",
        //                     "discounts": [
        //                         {
        //                             "type": "feePlan",
        //                             "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                             "discountId": "-Nf_cruocitoCl5F33IC"
        //                         }
        //                     ]
        //                 },
        //                 {
        //                     "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "id": "-NgOVf10peEpdC1uOb5D",
        //                     "paused": false,
        //                     "planId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "startDate": 1697135400000,
        //                     "statusChangedDate": 1696940072452,
        //                     "stuPlanId": "-N_hK215Mm00oTf0dzCi-NgOVeJiLDGoHSEYuv1g",
        //                     "studentId": "-N_hK215Mm00oTf0dzCi",
        //                     "discounts": []
        //                 }
        //             ],
        //             "lineItems": [
        //                 {
        //                     "category": "One Time",
        //                     "description": "nfknwe",
        //                     "feePlanId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "id": "-NgOUWFQ1KvSiRwIbqrI",
        //                     "name": "njfiew",
        //                     "planId": "-NgOVeJiLDGoHSEYuv1g",
        //                     "presetItemId": "-NgOVeOXTUFT3hUt5mVv",
        //                     "quantity": 20,
        //                     "rate": 200,
        //                     "total": 0,
        //                     "type": "feeComponent",
        //                     "discounts": [
        //                         "-Nf_cruocitoCl5F33IC"
        //                     ],
        //                     "taxes": [
        //                         "-Nf_cv_6LRhzEavyoBlh",
        //                         "-Nf_d51cGkV8dSKiARVc"
        //                     ]
        //                 }
        //             ],
        //             "taxes": [
        //                 "-Nf_cv_6LRhzEavyoBlh"
        //             ],
        //             "discounts": [
        //                 "-Nf_cp01SqzDu0p2m8aM"
        //             ]
        //         }
        //     }
        // }
        if (response.body.code && response.body.code == 200) {
            yield put({
                type: actions.FETCH_NEW_FEE_PLAN_WITH_ID_SUCCESS,
                data: response.body.response
            });
        } else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* fetchFeePlanUpcomingInvoice({ firebase, feePlanId }) {
    try {
        let obj = {
            id: feePlanId,
        };
        let endPointUrl =
            "woodlandApi/newFeePlan/upcommingInvoice/" + feePlanId + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "get", endPointUrl, obj);

        if (response.body.code && response.body.code == 200) {
            yield put({
                type: actions.FETCH_PLAN_UPCOMING_INVOICES_SUCCESS,
                invoices: response.body.invoices
            });
        } else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* changeAssignStudentStatus({ firebase, updatedAssignStudent }) {
    try {
        let endPointUrl =
            "woodlandApi/newFeePlan/student/changePauseStatus?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "post", endPointUrl, updatedAssignStudent);

        if (response.body.code && response.body.code == 200) {
            notification("success", formatMsg("success.statusUpdate"));
            yield put({
                type: actions.CHANGE_ASSIGN_STUDENT_STATUS_SUCCESS,
            });
        } else {
            notification("error", formatMsg("failed.changeStatus"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        bugsnagClient.notify(err);
        notification("error", formatMsg("failed.changeStatus"));
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* deleteFeePlanAssignStudent({ firebase, id }) {
    try {
        let obj = {
            id
        };
        let endPointUrl =
            "woodlandApi/newFeePlan/student/" + id + "?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "delete", endPointUrl, obj);

        if (response.body.code && response.body.code == 200) {
            notification("success", formatMsg("success.delete"));
            yield put({
                type: actions.DELETE_FEE_PLAN_ASSIGN_STUDENT_SUCCESS,
                data: response.body.response
            });
        } else {
            notification("error", formatMsg("failed.delete"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log(err);
        bugsnagClient.notify(err);
        notification("error", formatMsg("failed.delete"));
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* fetchGeneratedInvoicesWithFeePlanId({ firebase, feePlanId }) {
    try {
        let fetchedGeneratedInvoicesChannel = yield call(newFeePlanApi.getGeneratedInvoicesWithFeePlanId, firebase, feePlanId);
        while (true) {
            const fetchedGeneratedInvoices = yield take(fetchedGeneratedInvoicesChannel);
            yield put({
                type: actions.FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID_SUCCESS,
                fetchedGeneratedInvoices,
                fetchedGeneratedInvoicesChannel
            });
        }
    }
    catch (err) {
        console.log("failed to get invoice list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* saveSchoolCustomizedInvoicePDFStyle({ firebase, styleObj }) {
    try {
        if (styleObj.QRLink && styleObj.QRLink.includes("base64")) {
            styleObj.QRLink = yield call(newFeePlanApi.uploadImageAttachment, styleObj.QRLink, firebase);
        }
        let url = "woodlandApi/schoolInvoicePDFStyle?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "post", url, styleObj);
        if (response?.body?.code === 200) {
            notification("success", formatMsg("success.saveAdmissionProcess"));
            yield put({ type: actions.SAVE_SCHOOL_INVOICE_PDF_STYLE_SUCCESS });
        }
        else {
            notification("error", formatMsg("error.failedRequest"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    }
    catch (err) {
        console.log("failed to save school pdf style", err);
        notification("error", formatMsg("error.failedRequest"));
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* fetchSchoolInvoicePDFStyle({ firebase }) {
    try {
        let styleObj1 = yield call(newFeePlanApi.getSchoolInvoicePdfStyle, firebase);
        yield put({
            type: actions.GET_SCHOOL_INVOICE_PDF_STYLE_SUCCESS,
            schoolPDFStyle: styleObj1,
        });
    } catch (err) {
        console.log("failed get pdf style", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* fetchFeePlanInvoicePDFStyle({ firebase, feePlanId }) {
    try {
        let styleObj2 = yield call(newFeePlanApi.getFeePlanInvoicePdfStyle, firebase, feePlanId);
        yield put({
            type: actions.GET_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS,
            feePlanStyle: styleObj2,
        });
    } catch (err) {
        console.log("failed get pdf style", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* saveFeePlanCustomizedInvoicePDFStyle({ firebase, styleObj, feePlanId }) {
    styleObj.feePlanId = feePlanId;
    try {
        if (styleObj.QRLink && styleObj.QRLink.includes("base64")) {
            styleObj.QRLink = yield call(newFeePlanApi.uploadImageAttachment, styleObj.QRLink, firebase);
        }
        let url = "woodlandApi/feePlanInvoicePDFStyle?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "post", url, styleObj);
        if (response?.body?.code === 200) {
            notification("success", formatMsg("success.saveAdmissionProcess"));
            yield put({ type: actions.SAVE_FEEPLAN_INVOICE_PDF_STYLE_SUCCESS });
        }
        else {
            notification("error", formatMsg("error.failedRequest"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    }
    catch (err) {
        console.log("failed to save fee plan pdf style", err);
        notification("error", formatMsg("error.failedRequest"));
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

















































































// Ajit end
// Sagar start

function* getProgramList({ firebase }) {
    try {
        let programList = yield call(newFeePlanApi.getProgramList, firebase);
        yield put({
            type: actions.GET_PROGRAMS_SUCCESS,
            programList: programList
        });
    }
    catch (err) {
        console.log("failed to get program list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}
function* getLabelList({ firebase, labelTypeName }) {
    try {
        let labelList = yield call(newFeePlanApi.getLabelList, firebase, labelTypeName);
        yield put({
            type: actions.GET_LABELS_SUCCESS,
            labelList: labelList
        });
    }
    catch (err) {
        console.log("failed to get label list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}
function* getDiscountList({ firebase }) {
    try {
        let discountList = yield call(newFeePlanApi.getDiscountList, firebase);
        let discountMap = new Map();
        discountList.map((discount) => {
            discountMap.set(discount.id, discount);
        });
        yield put({
            type: actions.GET_FEE_PLAN_DISCOUNTS_SUCCESS,
            discountList: discountList,
            discountMap: discountMap
        });
    }
    catch (err) {
        console.log("failed to get discount list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}
function* getTaxList({ firebase }) {
    try {
        let taxList = yield call(newFeePlanApi.getTaxList, firebase);
        let taxMap = new Map();
        taxList.map((tax) => {
            taxMap.set(tax.id, tax);
        });
        yield put({
            type: actions.GET_FEE_PLAN_TAXES_SUCCESS,
            taxList: taxList,
            taxMap: taxMap
        });
    }
    catch (err) {
        console.log("failed to get tax list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}
function* saveFeePlan({ firebase, values }) {
    try {
        let url = "woodlandApi/newFeePlan?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "post", url, values);
        if (response?.body?.code === 200) {
            notification("success", formatMsg("message.feePlanSavedSuccessfully"));
            yield put({ type: actions.SAVE_FEE_PLAN_SUCCESS });
        }
        else {
            notification("error", formatMsg("failedToSaveFeePlan"));
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    }
    catch (err) {
        console.log("failed to save fee plan", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}

function* getFeePlanList({ firebase }) {

    try {
        let feePlanListChannel = yield call(newFeePlanApi.getFeePlanList, firebase);
        while (true) {
            let feePlanList = yield take(feePlanListChannel);
            console.log("->>>>>>>>>", feePlanList);
            feePlanList.sort((a, b) => { return FilterAction.sortIntegerValue(b, a, "updatedOn") });
            yield put({
                type: actions.GET_FEE_PLAN_LIST_SUCCESS,
                feePlanList: feePlanList,
                feePlanListChannel: feePlanListChannel,
            });
        }
    } finally {
        console.log("terminating fee plan list channel");
    }
}

function* getFeeComponentList({ firebase, currentBillingType }) {
    try {
        let feeComponentList = yield call(newFeePlanApi.getFeeComponentList, firebase, currentBillingType);
        feeComponentList.unshift({ id: "New Item", name: "New Item" });
        yield put({
            type: actions.GET_FEE_COMPONENT_LIST_SUCCESS,
            feeComponentList: feeComponentList
        });
    }
    catch (err) {
        console.log("failed to get fee component list", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}

function* deleteFeePlan({ firebase, feePlanId }) {
    try {
        let endpoint = "woodlandApi/newFeePlan/" + feePlanId + "?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response?.body?.code === 200) {
            notification("success", formatMsg("feePlanDeletedSuccessfully"));
            yield put({ type: actions.DELETE_SELECTED_FEE_PLAN_SUCCESS });
        }
        else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
            notification("error", formatMsg("failedToDeleteFeePlan"));
        }
    }
    catch (err) {
        console.log("failed to delete fee plan", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}
function* pauseOrActiveFeePlan({ firebase, feePlanId }) {
    try {
        let endpoint = "woodlandApi/newFeePlan/changePauseStatus/" + feePlanId + "?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, {});
        if (response?.body?.code === 200) {
            notification("success", formatMsg("notification.statusChange"));
            yield put({ type: actions.PAUSE_OR_ACTIVE_FEE_PLAN_SUCCESS });
        }
        else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
            notification("error", formatMsg("failedToChangeStatus"));
        }
    }
    catch (err) {
        console.log("failed to do pause or active fee plan", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }

}

function* updateAssignStudentToFeePlan({ firebase, assignStudentListObj }) {

    try {
        let endpoint = "woodlandApi/newFeePlan/assignStudents?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, assignStudentListObj);
        if (response?.body?.code === 200) {
            yield put({ type: actions.UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN_SUCCESS });
        }
        else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    }
    catch (err) {
        console.log("failed to do update assign students to fee plan", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}
function* updateOnlyOneAssignStudentToFeePlan({ firebase, assignStudentObj }) {
    try {
        let endpoint = "woodlandApi/newFeePlan/updateAssignStudent?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, assignStudentObj);
        if (response?.body?.code === 200) {
            yield put({ type: actions.UPDATE_ONLY_ONE_ASSIGN_STUDENT_SUCCESS });
        }
        else {
            yield put({
                type: actions.FEE_PLAN_API_REQUEST_FAILED,
            });
        }
    }
    catch (err) {
        console.log("failed to do update only one assign students to fee plan", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}

function* getAllLineItemByFeePlanId({ firebase, feePlanId }) {
    try {
        let lineItemListByFeePlan = yield call(newFeePlanApi.getAllLineItemByFeePlanId, firebase, feePlanId);
        yield put({
            type: actions.GET_LINE_ITEMS_BY_FEE_PLAN_ID_SUCCESS,
            lineItemListByFeePlan: lineItemListByFeePlan
        });
    }
    catch (err) {
        console.log("failed to get line items by fee plan id", err);
        bugsnagClient.notify(err);
        yield put({
            type: actions.FEE_PLAN_API_REQUEST_FAILED,
        });
    }
}





























































































// sagar end
// 




export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.GET_PROGRAMS, getProgramList),
        yield takeLatest(actions.GET_LABELS, getLabelList),
        yield takeLatest(actions.GET_FEE_PLAN_DISCOUNTS, getDiscountList),
        yield takeLatest(actions.GET_FEE_PLAN_TAXES, getTaxList),
        yield takeLatest(actions.SAVE_FEE_PLAN, saveFeePlan),
        yield takeLatest(actions.GET_FEE_PLAN_LIST, getFeePlanList),
        yield takeLatest(actions.GET_FEE_COMPONENT_LIST, getFeeComponentList),
        yield takeLatest(actions.GET_DISCOUNT_DATA, getDiscountData),
        yield takeLatest(actions.ADD_DISCOUNT_DATA, addDiscountData),
        yield takeLatest(actions.CHECK_IF_DISCOUNT_IS_BEING_USED, checkIfDiscountIsBeingUsed),
        yield takeLatest(actions.DELETE_DISCOUNT, deleteDiscount),
        yield takeLatest(actions.GET_TAX_DATA, getTaxData),
        yield takeLatest(actions.ADD_TAX_DATA, addTaxData),
        yield takeLatest(actions.CHECK_IF_TAX_IS_BEING_USED, checkIfTaxIsBeingUsed),
        yield takeLatest(actions.DELETE_TAX, deleteTax),
        yield takeLatest(actions.DELETE_NEW_FEE_COMPONENT, deleteFeeComponent),
        yield takeLatest(actions.GET_FEE_COMPONENT_LIST_DATA, getFeeComponentListData),
        yield takeLatest(actions.ADD_NEW_FEE_COMPONENT, addFeeComponent),
        yield takeLatest(actions.CHECK_IF_FEE_COMPONENT_IS_BEING_USED, checkIfFeeComponentIsBeingUsed),
        yield takeLatest(actions.FETCH_NEW_FEE_PLAN_WITH_ID, fetchSelectedFeePlan),
        yield takeLatest(actions.CHANGE_ASSIGN_STUDENT_STATUS, changeAssignStudentStatus),
        yield takeLatest(actions.DELETE_FEE_PLAN_ASSIGN_STUDENT, deleteFeePlanAssignStudent),
        yield takeLatest(actions.DELETE_SELECTED_FEE_PLAN, deleteFeePlan),
        yield takeLatest(actions.PAUSE_OR_ACTIVE_FEE_PLAN, pauseOrActiveFeePlan),
        yield takeLatest(actions.UPDATE_ASSIGN_STUDENTS_TO_FEE_PLAN, updateAssignStudentToFeePlan),
        yield takeLatest(actions.UPDATE_ONLY_ONE_ASSIGN_STUDENT, updateOnlyOneAssignStudentToFeePlan),
        yield takeLatest(actions.GET_LINE_ITEMS_BY_FEE_PLAN_ID, getAllLineItemByFeePlanId),
        yield takeLatest(actions.SAVE_SCHOOL_INVOICE_PDF_STYLE, saveSchoolCustomizedInvoicePDFStyle),
        yield takeLatest(actions.GET_SCHOOL_INVOICE_PDF_STYLE, fetchSchoolInvoicePDFStyle),
        yield takeLatest(actions.SAVE_FEEPLAN_INVOICE_PDF_STYLE, saveFeePlanCustomizedInvoicePDFStyle),
        yield takeLatest(actions.GET_FEEPLAN_INVOICE_PDF_STYLE, fetchFeePlanInvoicePDFStyle),









        //

        yield takeLatest(actions.FETCH_GENERATED_INVOICES_WITH_FEE_PLAN_ID, fetchGeneratedInvoicesWithFeePlanId),
        yield takeLatest(actions.FETCH_PLAN_UPCOMING_INVOICES, fetchFeePlanUpcomingInvoice)
    ]);
}
