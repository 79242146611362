const actions = {
  SHOW_ALL_STAFF_SCHEDULE: "SHOW_ALL_STAFF_SCHEDULE",
  GET_ALL_STAFF_SCHEDULE: "GET_ALL_STAFF_SCHEDULE",

  DELETE_STAFF_SCHEDULE: "DELETE_STAFF_SCHEDULE",
  DELETE_STAFF_SCHEDULE_SUCCESS: "DELETE_STAFF_SCHEDULE_SUCCESS",

  EXPORT_STAFF_SCHEDULE: "EXPORT_STAFF_SCHEDULE",

  CREATE_STAFF_SCHEDULE: "CREATE_STAFF_SCHEDULE",
  CREATE_STAFF_SCHEDULE_SUCCESS: "CREATE_STAFF_SCHEDULE_SUCCESS",

  FILTER_BY_STAFF_AND_BY_CLASSLIST: "FILTER_BY_STAFF_AND_BY_CLASSLIST",
  FILTER_BY_STAFF_AND_BY_CLASSLIST_SUCCESS: "FILTER_BY_STAFF_AND_BY_CLASSLIST_SUCCESS",

  SAVE_ALL_SCHEDULES: "SAVE_ALL_SCHEDULES",

  GET_SELECTED_SCHEDULE: "GET_SELECTED_SCHEDULE",
  GET_SELECTED_SCHEDULE_SUCCESSFUL: "GET_SELECTED_SCHEDULE_SUCCESSFUL",

  RESET_SCHEDULE_OPERATION: "RESET_SCHEDULE_OPERATION",

  GET_AFFECTED_SCHEDULES: "GET_AFFECTED_SCHEDULES",
  GET_AFFECTED_SCHEDULES_SUCCESS: "GET_AFFECTED_SCHEDULES_SUCCESS",

  GET_ALL_CLASSES: "GET_ALL_CLASSES",
  GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",

  showAllStaffSchedule: (
    firebase,
    currentStaffId,
    startDate,
    endDate,
    deleteStaffSchedule,
    classList,
  ) => {
    return {
      type: actions.SHOW_ALL_STAFF_SCHEDULE,
      firebase,
      currentStaffId,
      startDate,
      endDate,
      deleteStaffSchedule,
      classList,
    };
  },
  fetchSelectedSchedule: (scheduleId, firebase) => {
    return {
      type: actions.GET_SELECTED_SCHEDULE,
      scheduleId,
      firebase
    }
  },
  setDataToState: () => {
    return {
      type: actions.GET_ALL_STAFF_SCHEDULE,
    };
  },
  deleteStaffSchedule: (id, startDate, endDate, firebase) => {
    return {
      type: actions.DELETE_STAFF_SCHEDULE,
      id,
      startDate,
      endDate,
      firebase,
    };
  },
  exportStaffSchedule: (filteredSchedules, calenderDate) => {
    return {
      type: actions.EXPORT_STAFF_SCHEDULE,
      filteredSchedules,
      calenderDate,
    };
  },
  createStaffSchedule: (scheduleObj, firebase) => {
    return {
      type: actions.CREATE_STAFF_SCHEDULE,
      scheduleObj,
      firebase,
    };
  },
  filterByTeacherAndByClasslist: (firebase, staffId, classList, schedules, deleteStaffSchedule, startDate, endDate) => {
    return {
      type: actions.FILTER_BY_STAFF_AND_BY_CLASSLIST,
      firebase,
      staffId,
      classList,
      schedules,
      deleteStaffSchedule,
      startDate,
      endDate
    }
  },
  resetScheduleOperation: () => {
    return {
      type: actions.RESET_SCHEDULE_OPERATION
    }
  },
  getAffectedSchedules: (firebase, startDate, endDate, id, idType) => {
    return {
      type: actions.GET_AFFECTED_SCHEDULES,
      firebase,
      startDate,
      endDate,
      id,
      idType
    }
  },
  getAllClasses: (firebase) => {
    return {
      type: actions.GET_ALL_CLASSES,
      firebase
    }
  }
};

export default actions;
