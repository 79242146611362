import colors from "../../Utility/colorFactory";
import virtualClass from "../../image/sidebar-virtualClass.png";
import mySchool from "../../image/sidebar_dashboard.png";
import learning from "../../image/sidebar_learning.png";
import parentCommunication from "../../image/sidebar_parentCommunication.png";
import billing from "../../image/sidebar_billing.png";
import dashboardIcon from "../../image/sidebar_dashboard_1.png";
import support from "../../image/v2_support.png";


const parentOptions = [
  {
    key: "teacherMode",
    label: "sidebar.teacherMode",
    leftImage: dashboardIcon,
  },
  {
    key: "godMode",
    label: "sidebar.godMode",
    leftIcon: "ion-stats-bars",
  },
  {
    key: "virtualClassManagement",
    label: "sidebar.manageVirtualClass",
    leftImage: virtualClass,
  },
  {
    key: "timeline",
    label: "sidebar.parentDashboard",
    leftImage: mySchool,
  },
  {
    key: "virtualClass",
    label: "sidebar.virtualClass",
    leftImage: virtualClass,
  },
  {
    key: "studentLesson",
    label: "sidebar.studentLesson",
    leftImage: learning,
  },
  {
    key: "parentMessages",
    label: "sidebar.newMessage",
    leftImage: parentCommunication,
  },
  {
    key: "studentInvoices",
    label: "sidebar.studentInvoices",
    leftImage: billing,
  },
  {
    key: "studentDetailedView",
    label: "sidebar.studentProfile",
    leftIcon: "ion-android-person",
  },
  {
    key: "parentHandbook",
    label: "parent_handbook_pdf",
    leftImage: learning,
  },
  {
    key: "virtualLibrary",
    label: "sidebar.virtualLibrary",
    leftImage: virtualClass,
  },
  {
    key: "parentSupport",
    label: "sidebar.support",
    leftImage: support,
  },
];
export default parentOptions;
