import { all, put, call, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { LearningApi } from "../../firestore-api/learning";
import bugsnagClient from "@bugsnag/js";
import FilterAction from "../../Utility/FilterAction";
import { callApi } from "../../Utility/superAgentUntil";

// function* fetchLessonDetail({ lessonId, firebase }) {
//   try {
//     let lesson = yield call(LearningApi.getLessonById, lessonId, firebase);
//     if (lesson && lesson.id) {
//       yield put({
//         type: actions.VIEW_LESSON_SUCCESSFUL,
//         editableLessonRecord: lesson,
//       });
//     }
//   } catch (err) {
//     console.log("failed to fetch lesson view detail", err);
//     bugsnagClient.notify(err);
//     yield put({
//       type: actions.VIEW_REQUEST_FAILED,
//     });
//   }
// }
function* fetchLessonDetail({ lessonId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/lesson/${lessonId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.VIEW_LESSON_SUCCESSFUL,
        editableLessonRecord: response.body.response,
      });
    }
  } catch (err) {
    console.log("failed to fetch lesson view detail", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIEW_REQUEST_FAILED,
    });
  }
}

function* fetchViewLessonStudents({ firebase }) {
  try {
    let data = FilterAction.getStudentList(firebase);
    if (data) {
      data = data.filter((std) => {
        return std.status && std.status.toLowerCase() === "active";
      });

      yield put({
        type: actions.GET_VIEW_LESSON_STUDENT_SUCCESS,
        viewLessonStudents: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch students", err);
    yield put({
      type: actions.VIEW_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.VIEW_LESSON, fetchLessonDetail),
    yield takeLatest(actions.GET_VIEW_LESSON_STUDENT, fetchViewLessonStudents),
  ]);
}
