const actions = {
  GET_ALL_TEACHERS_FOR_LEAVE: "GET_ALL_TEACHERS_FOR_LEAVE",
  GET_ALL_TEACHERS_FOR_LEAVE_SUCCESSFUL: "GET_ALL_TEACHERS_FOR_LEAVE_SUCCESSFUL",

  GET_ALL_STUDENTS_FOR_LEAVE: "GET_ALL_STUDENTS_FOR_LEAVE",
  GET_ALL_STUDENTS_FOR_LEAVE_SUCCESSFUL: "GET_ALL_STUDENTS_FOR_LEAVE_SUCCESSFUL",

  GET_ALL_LEAVES: "GET_ALL_LEAVES",
  GET_ALL_LEAVES_SUCCESSFUL: "GET_ALL_LEAVES_SUCCESSFUL",

  GET_COMMENT_FOR_LEAVE: "GET_COMMENT_FOR_LEAVE",
  GET_COMMENT_FOR_LEAVE_SUCCESSFUL: "GET_COMMENT_FOR_LEAVE_SUCCESSFUL",

  LEAVE_REQUEST_FAILED: "LEAVE_REQUEST_FAILED",

  RESOLVE_LEAVE: "RESOLVE_LEAVE",
  RESOLVE_LEAVE_SUCCESSFUL: "RESOLVE_LEAVE_SUCCESSFUL",

  SAVE_LEAVE_COMMENT: "SAVE_LEAVE_COMMENT",
  SAVE_LEAVE_COMMENT_SUCCESSFUL: "SAVE_LEAVE_COMMENT_SUCCESSFUL",

  RESET_LEAVE_OPERATION_TYPE: "RESET_LEAVE_OPERATION_TYPE",

  GET_NOTIFICATION_LEAVE: "GET_NOTIFICATION_LEAVE",
  GET_NOTIFICATION_STAFF_LEAVE: "GET_NOTIFICATION_STAFF_LEAVE",

  GET_STUDENTS_ON_LEAVE: "GET_STUDENTS_ON_LEAVE",
  GET_STUDENTS_ON_LEAVE_SUCCESS: "GET_STUDENTS_ON_LEAVE_SUCCESS",

  GET_ALL_STAFF_LEAVES: "GET_ALL_STAFF_LEAVES",
  GET_ALL_STAFF_LEAVES_SUCCESSFUL: "GET_ALL_STAFF_LEAVES_SUCCESSFUL",

  RESOLVE_STAFF_LEAVE: "RESOLVE_STAFF_LEAVE",
  RESOLVE_STAFF_LEAVE_SUCCESSFUL: "RESOLVE_STAFF_LEAVE_SUCCESSFUL",

  GET_STAFFS_ON_LEAVE: "GET_STAFFS_ON_LEAVE",
  GET_STAFFS_ON_LEAVE_SUCCESS: "GET_STAFFS_ON_LEAVE_SUCCESS",

  SUBMIT_STAFF_LEAVE: "SUBMIT_STAFF_LEAVE",
  SUBMIT_STAFF_LEAVE_SUCCESSFUL: "SUBMIT_STAFF_LEAVE_SUCCESSFUL",

  SUBMIT_STUDENT_LEAVE: "SUBMIT_STUDENT_LEAVE",
  SUBMIT_STUDENT_LEAVE_SUCCESSFUL: "SUBMIT_STUDENT_LEAVE_SUCCESSFUL",

  FETCH_LEAVE_DETAILS: "FETCH_LEAVE_DETAILS",
  FETCH_LEAVE_DETAILS_SUCCESSFUL: "FETCH_LEAVE_DETAILS_SUCCESSFUL",

  RESET_REDIRECT: "RESET_REDIRECT",
  RESET_REDIRECT_SUCCESS: "RESET_REDIRECT_SUCCESS",

  DELETE_LEAVE: "DELETE_LEAVE",
  DELETE_LEAVE_SUCCESS: "DELETE_LEAVE_SUCCESS",

  getAllTeachersForLeave: (firebase) => ({
    type: actions.GET_ALL_TEACHERS_FOR_LEAVE,
    firebase,
  }),

  getAllStudentsForLeave: (firebase) => ({
    type: actions.GET_ALL_STUDENTS_FOR_LEAVE,
    firebase,
  }),

  resetRedirect: () => ({
    type: actions.RESET_REDIRECT,
  }),

  submitStaffLeave: (
    values,
    startDate,
    endDate,
    selectedStaffForLeave,
    leaveId,
    firebase,
    halfDayLeave,
    selectedTeacherLeave,
    leaveSetting
  ) => ({
    type: actions.SUBMIT_STAFF_LEAVE,
    values,
    startDate,
    endDate,
    selectedStaffForLeave,
    leaveId,
    firebase,
    halfDayLeave,
    selectedTeacherLeave,
    leaveSetting,
  }),

  fetchLeave: (leaveId, isStaff, firebase) => ({
    type: actions.FETCH_LEAVE_DETAILS,
    leaveId,
    isStaff,
    firebase,
  }),

  submitStudentLeave: (values, startDate, endDate, selectedStudentList, leaveId, firebase) => ({
    type: actions.SUBMIT_STUDENT_LEAVE,
    values,
    startDate,
    endDate,
    selectedStudentList,
    leaveId,
    firebase,
  }),

  getAllLeaves: (
    firebase,
    startDate,
    endDate,
    initialCall,
    selectedFrequency,
    dayLevelFilter,
    check
  ) => ({
    type: actions.GET_ALL_LEAVES,
    firebase,
    startDate,
    endDate,
    initialCall,
    selectedFrequency,
    dayLevelFilter,
    check,
  }),

  getLeaveComments: (leaves, firebase) => ({
    type: actions.GET_COMMENT_FOR_LEAVE,
    leaves,
    firebase,
  }),

  resolveLeave: (record, status, firebase) => ({
    type: actions.RESOLVE_LEAVE,
    record,
    status,
    firebase,
  }),

  saveLeaveComment: (comment, dataSource, firebase) => ({
    type: actions.SAVE_LEAVE_COMMENT,
    comment,
    dataSource,
    firebase,
  }),

  resetLeaveOperationType: () => ({
    type: actions.RESET_LEAVE_OPERATION_TYPE,
  }),

  getNotificationLeave: (id, firebase) => ({
    type: actions.GET_NOTIFICATION_LEAVE,
    id,
    firebase,
  }),

  studentLeaveStatus: (firebase) => ({
    type: actions.GET_STUDENTS_ON_LEAVE,
    firebase,
  }),

  getAllStaffLeaves: (
    firebase,
    startDate,
    endDate,
    initialCall,
    selectedFrequency,
    dayLevelFilter,
    check
  ) => ({
    type: actions.GET_ALL_STAFF_LEAVES,
    firebase,
    startDate,
    endDate,
    initialCall,
    selectedFrequency,
    dayLevelFilter,
    check,
  }),

  resolveStaffLeave: (record, status, firebase) => ({
    type: actions.RESOLVE_STAFF_LEAVE,
    record,
    status,
    firebase,
  }),

  staffLeaveStatus: (firebase) => ({
    type: actions.GET_STAFFS_ON_LEAVE,
    firebase,
  }),

  deleteLeave: (item, firebase, isStaff) => ({
    type: actions.DELETE_LEAVE,
    item,
    firebase,
    isStaff,
  }),

  getNotificationStaffLeave: (id, firebase) => ({
    type: actions.GET_NOTIFICATION_STAFF_LEAVE,
    id,
    firebase,
  }),
};
export default actions;
