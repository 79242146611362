import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { FoodMenuApi } from "../../firestore-api/foodMenu";

export function* listFoodMenu() {
  var fullFoodData = [];
  var csvFormat = "";

  try {
    fullFoodData = yield call(FoodMenuApi.getAllFoodMenu);
    csvFormat = yield call(FoodMenuApi.getFoodMenuFormat);

    yield put({
      type: actions.LIST_FOOD,
      foods: fullFoodData,
      foodMenuLayout: csvFormat,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FOOD_MENU_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

export function* addFoodMenu({ category, date, foodItem, nutrient, allergy }) {
  try {
    yield call(FoodMenuApi.addNewFoodMenu, category, date, foodItem, nutrient, allergy);
    const foodMenuList = yield* listFoodMenu();
    yield put({
      foodMenuList,
      type: actions.ADD_FOOD_SUCCESSFUL,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FOOD_MENU_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

export function* updateFoodMenu({
  updatedCategory,
  updatedDate,
  updatedFoodItem,
  updatedNutrient,
  updatedAllergy,
  docId,
}) {
  try {
    yield call(
      FoodMenuApi.updateExistingFoodMenu,
      updatedCategory,
      updatedDate,
      updatedFoodItem,
      updatedNutrient,
      updatedAllergy,
      docId
    );
    const foodMenuList = yield* listFoodMenu();
    yield put({
      foodMenuList,
      type: actions.UPDATE_FOOD_SUCCESSFUL,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FOOD_MENU_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

export function* addMenuCsv({ csvData }) {
  // const firebaseName = localStorage.getItem('projectId');
  // const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));

  // for (var i = 1; i < csvData.length; i++) {

  //   var date = moment(csvData[i][0], "DD/MM/YYYY");
  //   var newDate = new Date(date).toDateString();
  //   var category = csvData[i][1];
  //   var foodItem = csvData[i][2];
  //   var nutrient = csvData[i][3];
  //   var allergy = csvData[i][4];
  try {
    yield call(FoodMenuApi.addNewMenuCsv, csvData);
    const foodMenuList = yield* listFoodMenu();
    yield put({ foodMenuList });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FOOD_MENU_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_FOODS, listFoodMenu),
    yield takeEvery(actions.ADD_FOOD, addFoodMenu),
    yield takeEvery(actions.UPDATE_FOOD, updateFoodMenu),
    yield takeEvery(actions.ADD_CSV_MENU, addMenuCsv),
  ]);
}
