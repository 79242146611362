const actions = {
  GET_ALL_HOLIDAYS: "GET_ALL_HOLIDAYS",
  GET_ALL_HOLIDAYS_SUCCESSFUL: "GET_ALL_HOLIDAYS_SUCCESSFUL",

  GET_ALL_CLASSROOMS_FOR_HOLIDAY: "GET_ALL_CLASSROOMS_FOR_HOLIDAY",
  GET_ALL_CLASSROOMS_FOR_HOLIDAY_SUCCESSFUL: "GET_ALL_CLASSROOMS_FOR_HOLIDAY_SUCCESSFUL",

  ADD_HOLIDAY: "ADD_HOLIDAY",
  ADD_HOLIDAY_SUCCESSFUL: "ADD_HOLIDAY_SUCCESSFUL",

  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  UPDATE_HOLIDAY_SUCCESSFUL: "UPDATE_HOLIDAY_SUCCESSFUL",

  HOLIDAY_REQUEST_FAILED: "HOLIDAY_REQUEST_FAILED",

  RESET_HOLIDAY_OPERATION: "RESET_HOLIDAY_OPERATION",

  DELETE_HOLIDAY: "DELETE_HOLIDAY",
  DELETE_HOLIDAY_SUCCESS: "DELETE_HOLIDAY_SUCCESS",

  getAllHolidays: (firebase, initialCall) => ({
    type: actions.GET_ALL_HOLIDAYS,
    firebase,
    initialCall,
  }),

  getAllClassrooms: (firebase) => ({
    type: actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY,
    firebase,
  }),

  addHoliday: (formValue, firebase, classrooms) => ({
    type: actions.ADD_HOLIDAY,
    formValue,
    firebase,
    classrooms,
  }),

  updateHoliday: (formValue, selectedRecord, classrooms, holidayClassMapArray, firebase) => ({
    type: actions.UPDATE_HOLIDAY,
    formValue,
    selectedRecord,
    classrooms,
    holidayClassMapArray,
    firebase,
  }),

  resetHolidayOperation: () => ({
    type: actions.RESET_HOLIDAY_OPERATION,
  }),

  deleteHoliday: (selectedRecord, holidayClassMapArray, firebase, deleteAll) => ({
    type: actions.DELETE_HOLIDAY,
    selectedRecord,
    holidayClassMapArray,
    firebase,
    deleteAll,
  }),
};
export default actions;
