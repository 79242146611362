const actions = {
  ADD_EMAIL: "ADD_EMAIL",
  ADD_EMAIL_SUCCESSFUL: "ADD_EMAIL_SUCCESSFUL",

  ADD_EMAIL_TEMPLATE: " ADD_EMAIL_TEMPLATE",
  ADD_EMAIL_TEMPLATE_SUCCESSFUL: " ADD_EMAIL_TEMPLATE_SUCCESSFUL",

  GET_EMAIL_TEMPLATE: "GET_EMAIL_TEMPLATE",
  GET_EMAIL_TEMPLATE_SUCCESSFUL: "GET_EMAIL_TEMPLATE_SUCCESSFUL",

  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  DELETE_TEMPLATE_SUCCESSFUL: "DELETE_TEMPLATE_SUCCESSFUL",

  UPDATE_EMAIL: "UPDATE_EMAIL",
  UPDATE_EMAIL_SUCCESSFUL: "UPDATE_EMAIL_SUCCESSFUL",

  DELETE_LEAD_EMAIL: "DELETE_LEAD_EMAIL",
  DELETE_LEAD_EMAIL_SUCCESS: "DELETE_LEAD_EMAIL_SUCCESS",

  FETCH_EMAIL_TEMPLATE: "FETCH_EMAIL_TEMPLATE",
  FETCH_EMAIL_TEMPLATE_SUCCESSFUL: "FETCH_EMAIL_TEMPLATE_SUCCESSFUL",
  GET_EMAIL_TEMPLATE_BY_TYPE: "GET_EMAIL_TEMPLATE_BY_TYPE",
  GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESSFUL: "GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESSFUL",
  GET_EMAIL_TEMPLATE_BY_ID_SUCCESSFUL: "GET_EMAIL_TEMPLATE_BY_ID_SUCCESSFUL",
  GET_EMAIL_TEMPLATE_BY_ID: "GET_EMAIL_TEMPLATE_BY_ID",
  GET_LABEL: "GET_LABEL",
  GET_LABEL_SUCCESS: "GET_LABEL_SUCCESS",
  REQUEST_FAILED: "REQUEST_FAILED",

  getLabels: (firebase, labelType) => ({
    type: actions.GET_LABEL,
    firebase,
    labelType,
  }),

  addEmail: (value, leadRecord, firebase, editorState, isLog) => ({
    type: actions.ADD_EMAIL,
    value,
    leadRecord,
    firebase,
    editorState,
    isLog,
  }),
  addTemplate: (value, firebase, editorState,) => ({
    type: actions.ADD_EMAIL_TEMPLATE,
    value,
    firebase,
    editorState,

  }),

  getEmailTemplateRegister: (firebase) => ({
    type: actions.GET_EMAIL_TEMPLATE,
    firebase,
  }),
  getEmailTemplate: (firebase, id) => ({
    type: actions.GET_EMAIL_TEMPLATE_BY_ID,
    firebase,
    id,
  }),

  deleteEmailTemplate: (record, firebase) => ({
    type: actions.DELETE_TEMPLATE,
    record,
    firebase,
  }),

  addTemplateChanges: (value, editorState, firebase, id, prevAttachments) => ({
    type: actions.UPDATE_EMAIL,
    value,
    editorState,
    firebase,
    id,
    prevAttachments,
  }),
  getEmailTemplateByType: (firebase, templateType) => ({
    type: actions.GET_EMAIL_TEMPLATE_BY_TYPE,
    firebase,
    templateType,
  }),
};

export default actions;
