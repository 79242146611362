let webFont = {
  small: "12px",
  defaultFont: "13px",
  medium: "14px",
  lm: "15px",
  large: "16px",
  extraLarge: "17px",
  fl: "18px",
  fll: "20px",
  doubePica: "24px",
  flll: "30px",
  largest: "40px",
  xLargest: "50px",
};
export default webFont;
