export default class FileSizeCalculator {
  constructor() {}
}

FileSizeCalculator.calculateSize = (files, firebase) => {
  let permissibleSize = 524288000;
  let schoolConfig = firebase.schoolConfig;
  if (schoolConfig && schoolConfig.schoolFileSize) {
    permissibleSize = schoolConfig.schoolFileSize * 1048576;
  }

  let totalFileSize = 0;
  for (let index in files) {
    totalFileSize = totalFileSize + (files[index].size ? files[index].size : 0);
  }

    console.log("totalFileSize ---", totalFileSize);
    if (totalFileSize > permissibleSize) {
        return true;
    } else {
        return false;
    }
};

FileSizeCalculator.calculateFileSize = (files, permissibleSize, firebase) => {

    let totalFileSize = 0;
    for (let index in files) {
        totalFileSize = totalFileSize + (files[index].size ? files[index].size : 0);
    }

    if (totalFileSize > permissibleSize) {
        return true;
    } else {
        return false;
    }
};
