import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { feeAutomationApi } from "../../firestore-api/feeAutomation";
import actions from "./actions";
import formatMsg from "../../components/utility/formatMessageUtil";

function* fetchActionDetails({ actionId, firebase }) {
  try {
    var data = yield call(feeAutomationApi.getActionData, actionId, firebase);

    if (data) {
      yield put({
        type: actions.GET_ACTION_SUCCESS,
        actionDetails: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch action details", err);
    bugsnagClient.notify(err);
  }
}

function* saveActionDetails({ actionId, values, firebase }) {
  try {
    var refId = null;
    if (!actionId) {
      values.updatedBy = firebase.teacher.name;
      values.platform = "web";
      values.createdOn = moment().valueOf();
      values.status = "active";
      values.label = values.label ? values.label : null;
      values.teacher = values.teacher ? values.teacher : null;

      refId = yield call(feeAutomationApi.generateUniqueActionId, firebase);

      values.id = refId;
    } else {
      values.updatedBy = firebase.teacher.name;
      values.platform = "web";

      values.label = values.label ? values.label : null;
      values.teacher = values.teacher ? values.teacher : null;
      values.updatedBy = firebase.teacher.name;
      values.platform = "web";
      values.updatedOn = moment().valueOf();
      values.id = actionId;
    }

    yield call(feeAutomationApi.createAction, values, actionId ? actionId : refId, firebase);
    notification("success", formatMsg("notification.feeEmailSaved"));
    yield put({
      type: actions.SAVE_FEE_EMAIL_DETAILS_SUCCESS,
      newActionRedirect: true,
      // ruleRefId: refId,
    });
  } catch (err) {
    console.log("failed to save lead action", err);
    notification("error", formatMsg("notification.feeEmailFailed"));
    bugsnagClient.notify(err);
  }
}

function* fetchRuleActions({ firebase }) {
  const chan = yield call(feeAutomationApi.getFeeActions, firebase);
  try {
    while (true) {
      let detail = yield take(chan);

      yield put({
        type: actions.GET_FEE_ACTIONS_SUCCESS,
        feeActions: detail,
        feeActionsChan: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch rule details", err);
    bugsnagClient.notify(err);
  }
}

function* changeAutomationRuleDetails({ key, value, id, firebase, record }) {
  try {
    if (key.toLowerCase() === "delete") {
      if (value.toLowerCase() === "action") {
        yield call(feeAutomationApi.deleteAutomationAction, id, firebase);
        notification("success", formatMsg("notification.emailDeleted"));
      }
    } else if (key.toLowerCase() == "statuschange") {
      yield call(feeAutomationApi.changeStatus, record, firebase);
      notification("success", formatMsg("notification.statusChange"));
    }
    yield put({
      type: actions.CHANGE_FEE_EMAIL_DETAILS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete email", err);
    notification("error", formatMsg("error.performAction"));
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.SAVE_FEE_EMAIL_DETAILS, saveActionDetails),

    yield takeLatest(actions.GET_FEE_ACTIONS, fetchRuleActions),
    yield takeLatest(actions.GET_ACTION, fetchActionDetails),
    yield takeLatest(actions.CHANGE_FEE_EMAIL_DETAILS, changeAutomationRuleDetails),
  ]);
}
