import "@firebase/firestore"; // 👈 If you're using firestore
import * as FileSaver from "file-saver";
import moment from "moment-timezone";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as XLSX from "xlsx";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { TeacherApi } from "../../firestore-api/teacher";
import { getItem } from "../../Utility/encryptedStorage";
import { callApi } from "../../Utility/superAgentUntil";
import UserFilterAction from "../../Utility/UserFilterActions";
import actions from "./actions";

function mapParentAdditionalField(studentObj, values, formFields, type) {
  let parentConfig = [];
  let fieldConfig = formFields.filter((i) => {
    return i.fieldType.toLowerCase() === "parent" && !i.deleted;
  });

  for (let i = 0; i < fieldConfig.length; i++) {
    let configVal = fieldConfig[i];
    if (type === "father") {
      if (configVal?.dataType?.toLowerCase() === "date") {
        configVal.value = values[configVal.name]
          ? moment(values[configVal.name],UserFilterAction.getDateFormatList()).format(UserFilterAction.getApiDateFormat())
          : null;
      }else{
      configVal.value = values["father" + configVal.name]
        ? values["father" + configVal.name]
        : null;
      }
    } else {
      if (configVal?.dataType?.toLowerCase() === "date") {
        configVal.value = values[configVal.name]
          ? moment(values[configVal.name],UserFilterAction.getDateFormatList()).format(UserFilterAction.getApiDateFormat())
          : null;
      }else{
      configVal.value = values["mother" + configVal.name]
        ? values["mother" + configVal.name]
        : null;
      }
    }

    parentConfig.push(configVal);
  }

  if (type === "father") {
    studentObj.fatherConfig = parentConfig;
  } else {
    studentObj.motherConfig = parentConfig;
  }
  return studentObj;
}

function mapAdditionalField(studentObj, values, formFields) {
  let childConfig = [];
  let studentConfig = formFields.filter((i) => {
    return i.fieldType.toLowerCase() === "student" && !i.deleted;
  });

  for (let i = 0; i < studentConfig.length; i++) {
    let configVal = studentConfig[i];
    if (configVal?.dataType?.toLowerCase() === "date") {
      configVal.value = values[configVal.name]
        ? moment(values[configVal.name],UserFilterAction.getDateFormatList()).format(UserFilterAction.getApiDateFormat())
        : null;
    }else{
      configVal.value = values[configVal.name] ? values[configVal.name] : null;
    }
    childConfig.push(configVal);
  }

  studentObj.additionalFields = childConfig;
  return studentObj;
}

function* onboardStudents({ studentCsvData, firebase }) {
  let fields = [];
  fields = [
    "Name",
    "Student_Added",
    "Student_Failed",
    "Father_Added",
    "Father_Failed",
    "Father_Error",
    "Mother_Added",
    "Mother_Failed",
    "Mother_Error",
  ];
  let formFields = yield call(StudentApi.getAllFormFields, firebase);
  let studentList = [];
  for (let i = 0; i < studentCsvData.length; i++) {
    var row = {};
    row.Name = studentCsvData[i].studentName;

    let classroom = yield call(
      StudentApi.getClassroomByName,
      studentCsvData[i].classroom.trim(),
      firebase
    );
    if (classroom.length > 0) {
      let siblingRequest = false;
      var studentNodeId;

      let values = studentCsvData[i];

      let formattedDOB = values.birthDate ? moment(values.birthDate, UserFilterAction.getDateFormatList()).toDate(): null;
      let studentObj = {
        admissionDate: values.admissionDate
          ? moment(values.admissionDate,UserFilterAction.getDateFormatList() ).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        birthDate: formattedDOB ? moment(new Date(formattedDOB)).format("YYYY[-]MM[-]DD"): null,
        address: values.address ? values.address : null,
        classroomName: values.classroom.trim(),
        classList: [values.classroom.trim()],
        dateCreated: new Date().getTime(),
        deactivated: false,
        deactivationDate: 0,
        deleted: false,
        deletedOn: 0,
        gender: values.gender,
        id: studentNodeId,
        name: values.studentName,
        napEndTime: 0,
        napStartTime: 0,
        present: false,
        section: false,
        platform: "web_onboarding",
        status: values.status ? values.status : "Active",
        updatedBy: firebase.teacher.name,
        updatedOn: new Date().getTime(),
        preferredName: values.preferredName ? values.preferredName : null,
        allergies: values["allergies(Optional)"] ? values["allergies(Optional)"] : null,
        emergencyContactName: values["emergencyContactName(Optional)"]
          ? values["emergencyContactName(Optional)"]
          : null,
        emergencyNumber: values["emergencyNumber(Optional)"]
          ? values["emergencyNumber(Optional)"]
          : null,
        admissionNumber: values["admissionNumber(Optional)"]
          ? values["admissionNumber(Optional)"]
          : null,
        bloodGroup: values["bloodGroup(Optional)"] ? values["bloodGroup(Optional)"] : null,
      };

      // process std additional field
      studentObj = mapAdditionalField(studentObj, values, formFields);

      let addResponse = yield call(StudentApi.addFullStudentApi, firebase, studentObj);

      if (addResponse && addResponse.status === 200) {
        studentNodeId = addResponse.body.response.id;
        row.Student_Added = "Yes";
        row.Student_Failed = "";

        if (values.fatherNumber || values.fatherEmail) {
          studentObj = mapParentAdditionalField(studentObj, values, formFields, "father");

          let addResponse = yield call(
            StudentApi.addParentApi,
            firebase,
            values.studentName,
            studentNodeId,
            values.fatherName,
            values.fatherNumber ? Number(values.fatherNumber) : null,
            values.fatherEmail ? values.fatherEmail.trim() : null,
            "parent2",
            studentObj,
            undefined,
            studentObj.fatherConfig
          );

          if (addResponse && addResponse.status === 200) {
            // father number added successfully
            row.Father_Added = "Yes";
            row.Father_Failed = "";
            row.Father_Error = "";
          } else if (addResponse && addResponse.status === 201) {
            let parentId = addResponse.body.parentId;
            let linkResponse = yield call(
              StudentApi.linkParentApi,
              firebase,
              parentId,
              studentNodeId,
              "parent2"
            );

            console.log("linkResponse --->", linkResponse);
            if (linkResponse && linkResponse.status === 200) {
              row.Father_Added = "Yes";
              row.Father_Failed = "";
              row.Father_Error = "";
            } else {
              row.Father_Added = "";
              row.Father_Failed = "Yes";
              row.Father_Error =
                linkResponse.body && linkResponse.body.message
                  ? linkResponse.body.message
                  : "Failed to add";
            }
          } else {
            row.Father_Added = "";
            row.Father_Failed = "Yes";
            row.Father_Error =
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add";
          }
        }

        if (values.motherNumber || values.motherEmail) {
          studentObj = mapParentAdditionalField(studentObj, values, formFields, "mother");
          let addResponse = yield call(
            StudentApi.addParentApi,
            firebase,
            values.studentName,
            studentNodeId,
            values.motherName,
            values.motherNumber ? Number(values.motherNumber) : null,
            values.motherEmail ? values.motherEmail.trim() : null,
            "parent1",
            studentObj,
            undefined,
            studentObj.motherConfig
          );

          if (addResponse && addResponse.status === 200) {
            // mother number added successfully
            row.Mother_Added = "Yes";
            row.Mother_Failed = "";
            row.Mother_Error = "";
          } else if (addResponse && addResponse.status === 201) {
            let parentId = addResponse.body.parentId;
            let linkResponse = yield call(
              StudentApi.linkParentApi,
              firebase,
              parentId,
              studentNodeId,
              "parent1"
            );
            console.log("linkResponse --->", linkResponse);
            if (linkResponse && linkResponse.status === 200) {
              row.Mother_Added = "Yes";
              row.Mother_Failed = "";
              row.Mother_Error = "";
            } else {
              row.Mother_Added = "";
              row.Mother_Failed = "Yes";
              row.Mother_Error =
                linkResponse.body && linkResponse.body.message
                  ? linkResponse.body.message
                  : "Failed to add";
            }
          } else {
            // log error
            row.Mother_Added = "";
            row.Mother_Failed = "Yes";
            row.Mother_Error =
              addResponse.body && addResponse.body.message
                ? addResponse.body.message
                : "Failed to add";
          }
        }
      } else {
        console.log("failed student addition");
        row.Student_Added = "";
        row.Student_Failed = "Yes";
      }
      studentList.push(row);
    } else {
      row.Mother_Failed = "Yes";
      row.Mother_Error = "Classroom name mismatch";
      row.Father_Failed = "Yes";
      row.Father_Error = "Classroom name mismatch";
      studentList.push(row);

      console.log("classroom not found");
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
        errorMessage: "classroom not found",
      });
    }
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "Status_Student_Onboarding_";

  var ws = XLSX.utils.json_to_sheet(studentList, {
    header: fields,
    dateNF: "DD-MMM-YYYY",
  });

  const wb = {
    Sheets: { data: ws },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const newData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(newData, fileName + fileExtension);

  yield put({
    type: actions.UPLOAD_STUDENT_CSV_SUCCESSFUL,
  });
}

function* createParentEmailLogin(parentEmail, contact, firebase) {
  let email = parentEmail.trim();
  let editedId = replaceAll(email, "@", "%40");
  let encodedEmail = replaceAll(editedId, ".", "%2E");

  let existingUser = yield call(TeacherApi.checkIfUserExistInNewUser, contact, firebase);
  if (existingUser.status) {
    let existingEmailUser = yield call(
      TeacherApi.checkIfUserExistInNewUser,
      encodedEmail,
      firebase
    );
    let ifUpdateNeeded = false;
    if (existingEmailUser.status && existingEmailUser.id === existingUser.id) {
      ifUpdateNeeded = true;
    } else if (!existingEmailUser.status) {
      ifUpdateNeeded = true;
    }

    if (ifUpdateNeeded) {
      let existingObj = JSON.parse(JSON.stringify(existingUser));
      existingObj.status = null;
      yield call(StudentApi.updateNewUser, encodedEmail, existingObj, firebase);
      addFirebaseAuth(email, firebase);
      // yield fork(StudentApi.sendLoginInviteToParents, [parentEmail], firebase);
      /**send invite  */
    }
  }
}

function addFirebaseAuth(email, firebase) {
  var username = email.split("@")[0];
  var password = username.substr(0, 8);
  if (password.length < 8) {
    password = password + "123456";
  }

  firebase.auth.createUserWithEmailAndPassword(email, password).then((res) => {
    console.log("auth ---", res);
  });
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function* uploadHolidays({ holidayCsvData, classrooms, firebase }) {
  for (let j = 0; j < holidayCsvData.length; j++) {
    let values = holidayCsvData[j];
    let startDate = moment(values.startDate, [
      "DD/MM/YY",
      "DD-MM-YY",
      "DD-MM-YYYY",
      "DD/MM/YYYY",
      "DD, MMMM, YYYY",
      "DD.MM.yyyy",
      "YYYY-MM-DD",
      "DD[-]MM[-]YYYY",
    ]).toDate();
    let endDate = null;
    let fromDate = moment(startDate).valueOf();

    if (values["endDate(Optional)"]) {
      endDate = moment(values["endDate(Optional)"], "DD[/]MM[/]YYYY").toDate();
      endDate = moment(endDate).valueOf();
    } else {
      endDate = moment(startDate).valueOf();
    }

    let holidayObj = {
      classList: classrooms,
      fromDate: fromDate,
      endDate: endDate ? endDate : null,
      name: values.holidayName,
      timestamp: startDate.getTime(),
      platform: "web",
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let endpoint = "woodlandApi/holiday?centerId=" + firebase.sbDbName;
    let response = yield fork(callApi, firebase, "post", endpoint, holidayObj);

    if (response?.body?.code === 200) {
      console.log("success");
    } else {
      console.log("failed to add holiday", response);
    }
  }

  yield put({
    type: actions.UPLOAD_HOLIDAY_CSV_SUCCESSFUL,
  });
}

function* fetchOnboardingClassroom({ firebase }) {
  try {
    //let classrooms = yield call(StudentApi.getAutoCompleteClassrooms, firebase);
    let classrooms = JSON.parse(getItem("classList"));
    if (classrooms) {
      yield put({
        type: actions.GET_ONBOARDING_CLASSROOM_SUCCESSFUL,
        classrooms: classrooms,
      });
    }
  } catch (err) {
    console.log("failed to fetch onboarding classrooms", err);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* onboardTeachers({ teacherCsvData, firebase }) {
  try {
    let fields = [];
    fields = ["Name", "Added", "Failed", "Error"];

    let teacherList = [];

    let classroomData = yield call(StudentApi.getAutoCompleteClassrooms, firebase);
    let classNames = [];
    if (classroomData) {
      classroomData.map((c) => {
        classNames.push(c.className);
      });
    }

    if (classNames && classNames.length > 0) {
      for (let i = 0; i < teacherCsvData.length; i++) {
        var row = {};
        row.Name = teacherCsvData[i].teacherName;

        let response = yield call(
          TeacherApi.addTeacherApi,
          firebase,
          teacherCsvData[i].teacherName,
          teacherCsvData[i].phoneNumber,
          teacherCsvData[i].emailAddress,
          teacherCsvData[i].gender,
          teacherCsvData[i].classroom.split(","),
          teacherCsvData[i].role,
          teacherCsvData[i].address,
          undefined, //country code,
          undefined, // newBranches,
          undefined, //groups,
          teacherCsvData[i].birthDate
            ? moment(teacherCsvData[i].birthDate).format(UserFilterAction.getApiDateFormat())
            : null, //birthDate,
          teacherCsvData[i].joiningDate
            ? moment(teacherCsvData[i].joiningDate, UserFilterAction.getDateFormatList())
            : null
        );
        if (response && response.status) {
          if (response.status === 200 || response.status === 201) {
            row.Added = "Yes";
            row.Failed = "";
            row.Error = "";
          } else {
            row.Added = "";
            row.Failed = "Yes";
            row.Error =
              response.body && response.body.message
                ? response.body.message
                : "Failed to add teacher";
          }
        }
        teacherList.push(row);
      }

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "Status_Teacher_Onboarding_";

      var ws = XLSX.utils.json_to_sheet(teacherList, {
        header: fields,
        dateNF: "DD-MMM-YYYY",
      });

      const wb = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const newData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(newData, fileName + fileExtension);

      yield put({
        type: actions.UPLOAD_TEACHER_CSV_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
        errorMessage: "Class list could not be fetched or is empty",
      });
    }
  } catch (error) {
    console.log("failed to upload teacher csv data", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* updateTeacherEmailLogin(teacherEmail, contact, firebase) {
  // let email = teacherEmail.trim();
  // let editedId = replaceAll(email, "@", "%40");
  // let encodedEmail = replaceAll(editedId, ".", "%2E");
  // let existingUser = yield call(
  //   TeacherApi.checkIfUserExistInNewUser,
  //   contact,
  //   firebase
  // );
  // if (existingUser.status) {
  //   let existingEmailUser = yield call(
  //     TeacherApi.checkIfUserExistInNewUser,
  //     encodedEmail,
  //     firebase
  //   );
  //   let ifUpdateNeeded = false;
  //   if (existingEmailUser.status && existingEmailUser.id === existingUser.id) {
  //     ifUpdateNeeded = true;
  //   } else if (!existingEmailUser.status) {
  //     ifUpdateNeeded = true;
  //   }
  //   if (ifUpdateNeeded) {
  //     let existingObj = JSON.parse(JSON.stringify(existingUser));
  //     existingObj.status = null;
  //     yield call(StudentApi.updateNewUser, encodedEmail, existingObj, firebase);
  //     yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
  //   }
  // }
}

function* uploadSchedules({ scheduleCsvData, formValue, firebase }) {
  let startDate = new Date();
  let endDate = new Date();
  try {
    let classrooms = formValue.classroom;
    if (formValue.scheduleFrequency.trim() === "Multiple Day") {
      startDate = moment(formValue.date[0]);
      endDate = moment(formValue.date[1]);
    } else {
      startDate = moment(formValue.date);
      endDate = moment(formValue.date);
    }
    var count = 0;
    let url = "woodlandApi/addSchedule/" + "?centerId=";

    for (let j = 0; j < scheduleCsvData.length; j++) {
      //let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");

      /**new change */

      let dateString = scheduleCsvData[j].startTime;

      let splittedDateString = dateString.split(":");
      let hour = splittedDateString[0];

      let formattedHour = hour === "12" ? "0" : hour;
      console.log("splittedDateString", splittedDateString);

      let isAMPM = splittedDateString[1].toLowerCase().includes("am")
        ? "am"
        : "pm";

      let splittedMinuteString = splittedDateString[1]
        .substring(0, splittedDateString[1].toLowerCase().indexOf(isAMPM))
        .trim();

      let minute = splittedMinuteString;

      /**added to check hour is AM or PM */
      let formattedhr = isAMPM === "pm" ? Number(formattedHour) + 12 : hour;

      let scheduleTime = moment(startDate)
        .set("hour", formattedhr)
        .set("minute", minute)
        .valueOf();


      {/**end time */}
      let endDateString = scheduleCsvData[j]?.endTime;
      let scheduleEndTime;
      if(endDateString){
      let splittedEndDateString = endDateString.split(":");
      let hour2 = splittedEndDateString[0];
      let formattedHour2 = hour2 === "12" ? "0" : hour2;
      console.log("splittedDateString", splittedEndDateString);
      let isAMPM2 = splittedEndDateString[1].toLowerCase().includes("am")
        ? "am"
        : "pm";
      let splittedMinuteString2 = splittedEndDateString[1]
        .substring(0, splittedEndDateString[1].toLowerCase().indexOf(isAMPM2))
        .trim();

      let minute2 = splittedMinuteString2;

      /**added to check hour is AM or PM */
      let formattedhr2 = isAMPM2 === "pm" ? Number(formattedHour2) + 12 : hour2;

      scheduleEndTime = moment(startDate)
        .set("hour", formattedhr2)
        .set("minute", minute2)
        .valueOf();
      }

      let obj = {
        classrooms: classrooms,
        type: scheduleCsvData[j].category,
        title: scheduleCsvData[j].title,
        description: scheduleCsvData[j].description,
        startTime: scheduleTime,
        startDate: startDate,
        endDate: endDate,
        daysOfWeek: [scheduleCsvData[j].day],
        endTime: scheduleEndTime ? scheduleEndTime : null,
      };

      let response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );

      if (response && response.status && response.status === 200) {
        count = count + 1;
      }
    }

    if (count === scheduleCsvData.length) {
      yield put({
        type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
      });
    }
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* uploadSchedulesForSingleDay({ scheduleCsvData, formValue, firebase }) {
  try {
    let classrooms = formValue.classroom;
    var selectedDate = moment(formValue.date);
    var endDate;

    if (formValue.repeatScheduleFrequencyFor7Day) {
      endDate = selectedDate.add(7, "day");
    } else if (formValue.repeatScheduleFrequencyFor15Day) {
      endDate = selectedDate.add(15, "day");
    } else if (formValue.repeatScheduleFrequencyFor30Day) {
      endDate = selectedDate.add(30, "day");
    } else {
      endDate = selectedDate;
    }
    var daysOfWeek = [];
    if (!formValue.saturdaySelect) {
      daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    } else {
      daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    var count = 0;
    let url = "woodlandApi/addSchedule/" + "?centerId=";

    for (let j = 0; j < scheduleCsvData.length; j++) {
      //let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");

      /**new change */

      let dateString = scheduleCsvData[j].startTime;

      let splittedDateString = dateString.split(":");
      let hour = splittedDateString[0];

      let formattedHour = hour === "12" ? "0" : hour;

      let isAMPM = splittedDateString[1].toLowerCase().includes("am")
        ? "am"
        : "pm";

      let splittedMinuteString = splittedDateString[1]
        .substring(0, splittedDateString[1].toLowerCase().indexOf(isAMPM))
        .trim();

      let minute = splittedMinuteString;

      /**added to check hour is AM or PM */
      let formattedhr = isAMPM === "pm" ? Number(formattedHour) + 12 : hour;

      let scheduleTime = moment(selectedDate)
        .set("hour", formattedhr)
        .set("minute", minute)
        .valueOf();

      let obj = {
        classrooms: classrooms,
        type: scheduleCsvData[j].category,
        title: scheduleCsvData[j].title,
        description: scheduleCsvData[j].description,
        startTime: scheduleTime,
        startDate: selectedDate,
        endDate: endDate,
        daysOfWeek: daysOfWeek,
      };

      let response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );

      if (response && response.status && response.status === 200) {
        count = count + 1;
      }
    }

    if (count === scheduleCsvData.length) {
      yield put({
        type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
      });
    }
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

function* uploadSchedulesForSpecificDateRange({ scheduleCsvData, formValue, firebase }) {
  try {
    let classrooms = formValue.classroom;
    var count = 0;
    let url = "woodlandApi/addSchedule/" + "?centerId=";


    for (let j = 0; j < scheduleCsvData.length; j++) {
      //let newDate = moment(new Date(date)).format("YYYY[-]MM[-]DD");

      /**new change */
      var selectedDate = moment(scheduleCsvData[j].date, UserFilterAction.getDateFormatList());
      var endDate = moment(scheduleCsvData[j].date, UserFilterAction.getDateFormatList());
      let dateString = scheduleCsvData[j].startTime;
      let splittedDateString = dateString.split(":");
      let hour = splittedDateString[0];

      let formattedHour = hour === "12" ? "0" : hour;

      let isAMPM = splittedDateString[1].toLowerCase().includes("am")
        ? "am"
        : "pm";

      let splittedMinuteString = splittedDateString[1]
        .substring(0, splittedDateString[1].toLowerCase().indexOf(isAMPM))
        .trim();

      let minute = splittedMinuteString;

      /**added to check hour is AM or PM */
      let formattedhr = isAMPM === "pm" ? Number(formattedHour) + 12 : hour;

      let scheduleTime = moment(selectedDate)
        .set("hour", formattedhr)
        .set("minute", minute)
        .valueOf();


        {/**end time */}
      let endDateString = scheduleCsvData[j]?.endTime;
      let scheduleEndTime;
      if(endDateString){
      let splittedEndDateString = endDateString.split(":");
      let hour2 = splittedEndDateString[0];
      let formattedHour2 = hour2 === "12" ? "0" : hour2;
      console.log("splittedDateString", splittedEndDateString);
      let isAMPM2 = splittedEndDateString[1].toLowerCase().includes("am")
        ? "am"
        : "pm";
      let splittedMinuteString2 = splittedEndDateString[1]
        .substring(0, splittedEndDateString[1].toLowerCase().indexOf(isAMPM2))
        .trim();

      let minute2 = splittedMinuteString2;

      /**added to check hour is AM or PM */
      let formattedhr2 = isAMPM2 === "pm" ? Number(formattedHour2) + 12 : hour2;

      scheduleEndTime = moment(selectedDate)
        .set("hour", formattedhr2)
        .set("minute", minute2)
        .valueOf();
      }


      let obj = {
        classrooms: classrooms,
        type: scheduleCsvData[j].category,
        title: scheduleCsvData[j].title,
        description: scheduleCsvData[j].description,
        startTime: scheduleTime,
        startDate: selectedDate.valueOf(),
        endDate: endDate.valueOf(),
        endTime: scheduleEndTime ? scheduleEndTime : null,
      };
      let response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );

      if (response && response.status && response.status === 200) {
        count = count + 1;
      }
    }

    if (count === scheduleCsvData.length) {
      yield put({
        type: actions.UPLOAD_SCHEDULE_CSV_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.ONBOARDINGG_REQUEST_FAILED,
      });
    }
  } catch (error) {
    console.log("failed to upload schedule", error);
    yield put({
      type: actions.ONBOARDINGG_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.UPLOAD_STUDENT_CSV, onboardStudents),
    yield takeLatest(actions.UPLOAD_HOLIDAY_CSV, uploadHolidays),
    yield takeLatest(actions.GET_ONBOARDING_CLASSROOM, fetchOnboardingClassroom),
    yield takeLatest(actions.UPLOAD_TEACHER_CSV, onboardTeachers),
    yield takeLatest(actions.UPLOAD_SCHEDULE_CSV, uploadSchedules),
    yield takeLatest(actions.UPLOAD_SINGLE_DAY_SCHEDULE_CSV, uploadSchedulesForSingleDay),
    yield takeLatest(
      actions.UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV,
      uploadSchedulesForSpecificDateRange
    ),
  ]);
}
