const colors = {
  skyBlue: "#23b7e4",
  lightYellow: "#fbe165",
  lightBrown: "#f8ca73",
  voilet: "#361e54",
  limeGreen: "#8ca545",
  brown: "#c55719",
  blue: "#206ce3",
  deepBlue: "#34486A",
  lightBlue: "#8FA0B9",
  header_primary: "#445DA2",
  voilet_sec: "#745191",
  deepBlue34486A: "#34486A",
  lightBlue8FA0B9: "#8FA0B9",
  blue2D4D84: "#2D4D84",
  grey_border_primary: "#e6e6e6",
  circle_border: "#03A9F4",
  pink: "#aa66cc",
  black636E80: "#636E80",
  red_primary: "#e15249",
  orangeDD6B55: "#DD6B55",
  grey_primary: "#666666",
  v2_Cerulean: "#00AACC",
  v2_deep_cerulean: "#00839D",
  v2_base: "#F6FBFF",
  v2_fiord: "#485473",
  v2_gallery: "#F0F0F0",
  v2_dodger_blue: "#2B99F7",
  v2_coral: "#FD8E56",
  v2_casablanca: "#F8C545",
  v2_shamrock: "#22D792",
  v2_purple_heart: "#9822D7",
  v2_rum_swizzle: "#F8F2E0",
  v2_corn: "#E5A700",
  v2_polar: "#E0F8F8",
  v2_teal: "#008C8C",
  v2_bondiBlue: "#008CBF",
  v2_polar_alt: "#E0F2F8",
  v2_cornFlowerBlue: " #00AACC",
  v2_robinsBlue: "#00BCCC",
  white: "white",
  yellowHeader: "#F9F3E1",
  blueHeader: "#E1F2F9",
  redHeader: "#F8E0E0",
  greenHeader: "#E1F9F9",
  pinkHeader: "#F6E0F8",
  blueTextHeader: "#008CBF",
  redTextHeader: "#A80000",
  greenTextHeader: "#008C8C",
  pinkTextHeader: "#A900B7",
  yellowTextHeader: "#E5A700",
  bombay: "#ABABAC",
  gallery_alt: "#EFEFEF",
  v2_mountain_meadow: "#14CCA1",
  v2_fuzzy_brown: "#C24E4E",
  v2_scorpion: "#5D5D5D",
  v2_linen: "#F9F2E1",
  v2_red_opacity: "#f9e0df",
  v2_pink_opacity: "#f5e1f7",
  v2_silver: "#C5C5C5",
  v2_alt_fiord: "#485473",
  v2_grey_chateou: "#A3A9B9",
  v2_red_background: "#F8ECEC",
  v2_athens_grey: "#ECEDF1",
  v2_waterloo: "#7F869C",
  v2_regent_grey: "#7F879D",
  v2_athens_alt: "#F4F5F7",
  v2_boulder: "#7C7C7C",
  v2_pearl_lusta: "#FCF6E5",
  v2_blizzard_blue: "#B3E5F0",
  v2_scooter: "#33BBD6",
  v2_emperor: "#505050",
  v2_pie_pink: "#BE45FF",
  v2_cerise: "#D93DA3",
  v2_cloud: "#c1c0be",
  v2_border_bottom: "#CED5DA",
  v2_peru_border: "#CC823E",
  v2_light_grey: "#D0D0D0",
  v2_time_grey:"#8D8D8D",
  v2_grey_asparagus:"#5B5B5B"
};
export default colors;
