const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  PASSWORD_RESET_LINK: "PASSWORD_RESET_LINK",
  PASSWORD_RESET_LINK_SUCCESSFULLY_SENT: "PASSWORD_RESET_LINK_SUCCESSFULLY_SENT",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_REQUEST_SUCCESS: "SIGNUP_REQUEST_SUCCESS",
  LOGOUT: "LOGOUT",
  FAILED_REQUEST: "FAILED_REQUEST",
  RESET_OPERATION: "RESET_OPERATION",

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION,
  }),

  signUpAccount: (
    email,
    password,
    countryCode,
    phoneNumber,
    name,
    address,
    schoolName,
    firebase,
    gender
  ) => ({
    type: actions.SIGNUP_REQUEST,
    email,
    password,
    countryCode,
    phoneNumber,
    name,
    address,
    schoolName,
    firebase,
    gender,
  }),

  login: (userEmail, userPassword) => ({
    type: actions.LOGIN_REQUEST,
    userEmail,
    userPassword,
  }),

  resetPassword: (resetEmail) => ({
    type: actions.PASSWORD_RESET_LINK,
    resetEmail,
  }),

  logout: () => ({
    type: actions.LOGOUT,
  }),
  resetOperation: () => ({
    type: actions.RESET_OPERATION
  })
};
export default actions;
