import React from "react";
import colors from "../../Utility/colorFactory";
import formatMsg from "../../components/utility/formatMessageUtil";
import notification from "../../components/notification";
import { Link } from "react-router-dom";

export const StaffScheduleEmpty = (props) => {
  const styles = {
    staffDiv: {
      borderRadius: "5px",
      backgroundColor: colors.white,
      height: props.newHeight,
      width: "270px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#C53A3A",
    },
  };
  function changeBackgroundToBlue(e) {
    e.target.style.background = "rgba(179, 230, 240, 1)";
  }
  function changeBackgroundToWhite(e) {
    e.target.style.background = colors.white;
  }

  return (
    !props.onLeave ? (
      <Link
        to={{
          pathname: "staffScheduleForm",
          search: "?type=addUsingEmptyField&staffId=" + props.shiftObject.teacherId + "&selectedDate=" + props.shiftObject.currentDate
        }}
      >
        <div
          style={styles.staffDiv}
          onMouseOver={changeBackgroundToBlue}
          onMouseOut={changeBackgroundToWhite}
        />
      </Link>
    ) : (
      <div
        style={styles.staffDiv}
        onClick={() => notification("warning", formatMsg("staffLeaves.staffOnLeave"))}
      >
        { props.newHeight!==30 && props.onLeave ? formatMsg("onLeave") : "" }
      </div>
    )
  );
};
