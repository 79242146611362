const actions = {
  LIST_STUDENT: "LIST_STUDENT",
  LIST_STUDENT_SUCCESSFUL: "LIST_STUDENT_SUCCESSFUL",

  FETCH_STUDENT_DETAILS: "FETCH_STUDENT_DETAILS",
  FETCH_STUDENT_DETAILS_SUCCESSFUL: "FETCH_STUDENT_DETAILS_SUCCESSFUL",

  SUBMIT_STUDENT: "SUBMIT_STUDENT",
  SUBMIT_STUDENT_SUCCESSFUL: "SUBMIT_STUDENT_SUCCESSFUL",

  INVITE_PARENT: "INVITE_PARENT",
  INVITE_PARENT_SUCCESSFUL: "INVITE_PARENT_SUCCESSFUL",

  LINK_FOUND: "LINK_FOUND",

  REQUEST_FAILED: "REQUEST_FAILED",

  GET_STUDENT_CLASSROOM_DATA: "GET_STUDENT_CLASSROOM_DATA",
  GET_STUDENT_CLASSROOM_DATA_SUCCESSFUL: "GET_STUDENT_CLASSROOM_DATA_SUCCESSFUL",

  GET_TAGS: "GET_TAGS",
  GET_TAGS_SUCCESSFUL: "GET_TAGS_SUCCESSFUL",

  GET_INVOICETEMPLATE: "GET_INVOICETEMPLATE",
  GET_INVOICETEMPLATE_SUCCESFUL: "GET_INVOICETEMPLATE_SUCCESFUL",

  UPDATE_STUDENT: "UPDATE_STUDENT",
  UPDATE_STUDENT_SUCCESSFUL: "UPDATE_STUDENT_SUCCESSFUL",

  DELETE_STUDENT: "DELETE_STUDENT",
  DELETE_STUDENT_SUCCESSFFUL: "DELETE_STUDENT_SUCCESSFFUL",

  GET_PARENT_DETAIL_OF_SELECTED_STUDENT: "GET_PARENT_DETAIL_OF_SELECTED_STUDENT",
  GET_PARENT_DETAIL_OF_SELECTED_STUDENT_SUCCESSFUL:
    "GET_PARENT_DETAIL_OF_SELECTED_STUDENT_SUCCESSFUL",

  GET_STUDENT_DETAIL: "GET_STUDENT_DETAIL",
  GET_STUDENT_DETAIL_SUCCESSFUL: "GET_STUDENT_DETAIL_SUCCESSFUL",

  GET_USER_VERSION: "GET_USER_VERSION",
  GET_USER_VERSION_SUCCESSFUL: "GET_USER_VERSION_SUCCESSFUL",

  SEND_INVITE: "SEND_INVITE",
  SEND_INVITE_SUCCESSFUL: "SEND_INVITE_SUCCESSFUL",

  GET_SELECTED_STUDENT_ATTENDANCE: "GET_SELECTED_STUDENT_ATTENDANCE",
  GET_SELECTED_STUDENT_ATTENDANCE_SUCCESSFUL: "GET_SELECTED_STUDENT_ATTENDANCE_SUCCESSFUL",

  UPDATE_STUDENT_MEDICATION: "UPDATE_STUDENT_MEDICATION",
  UPDATE_STUDENT_MEDICATION_SUCCESSFUL: "UPDATE_STUDENT_MEDICATION_SUCCESSFUL",

  UPDATE_STUDENT_COMMUNICATION_EMAIL: "UPDATE_STUDENT_COMMUNICATION_EMAIL",
  UPDATE_STUDENT_COMMUNICATION_EMAIL_SUCCESSFUL: "UPDATE_STUDENT_COMMUNICATION_EMAIL_SUCCESSFUL",

  UPDATE_STUDENT_ALLERGY: "UPDATE_STUDENT_ALLERGY",
  UPDATE_STUDENT_ALLERGY_SUCCESSFUL: "UPDATE_STUDENT_ALLERGY_SUCCESSFUL",

  UPDATE_BLOOD_GROUP: "UPDATE_BLOOD_GROUP",
  UPDATE_BLOOD_GROUP_SUCCESSFUL: "UPDATE_BLOOD_GROUP_SUCCESSFUL",

  UPDATE_ADMISSION_NUMBER: "UPDATE_ADMISSION_NUMBER",
  UPDATE_ADMISSION_NUMBER_SUCCESSFUL: "UPDATE_ADMISSION_NUMBER_SUCCESSFUL",

  UPDATE_STUDENT_ACTIVE_STATUS: "UPDATE_STUDENT_ACTIVE_STATUS",
  UPDATE_STUDENT_ACTIVE_STATUS_SUCCESSFUL: "UPDATE_STUDENT_ACTIVE_STATUS_SUCCESSFUL",

  SAVE_PARENT_EXTRA_DETAILS: "SAVE_PARENT_EXTRA_DETAILS",
  SAVE_PARENT_EXTRA_DETAILS_SUCCESSFUL: "SAVE_PARENT_EXTRA_DETAILS_SUCCESSFUL",

  REFRESH_FORM_FIELDS: "REFRESH_FORM_FIELDS",
  REFRESH_FORM_FIELDS_SUCCESSFUL: "REFRESH_FORM_FIELDS_SUCCESSFUL",

  RESET_SELECTED_STUDENT: "RESET_SELECTED_STUDENT",
  RESET_SELECTED_STUDENT_SUCCESSFUL: "RESET_SELECTED_STUDENT_SUCCESSFUL",

  REFRESH_CHILD_FORM_FIELDS: "REFRESH_CHILD_FORM_FIELDS",
  REFRESH_CHILD_FORM_FIELDS_SUCCESSFUL: "REFRESH_CHILD_FORM_FIELDS_SUCCESSFUL",

  NUMBER_ALREADY_EXISTS: "NUMBER_ALREADY_EXISTS",

  NUMBER_ALREADY_EXISTS_FOR_TEACHER: "NUMBER_ALREADY_EXISTS_FOR_TEACHER",

  NUMBER_FAILED_TO_UPDATE: "NUMBER_FAILED_TO_UPDATE",

  RESET_STUDENT_OPERATION: "RESET_STUDENT_OPERATION",

  SAVE_PARENT_IF_SIBLING: "SAVE_PARENT_IF_SIBLING",
  SAVE_PARENT_IF_SIBLING_SUCCESSFUL: "SAVE_PARENT_IF_SIBLING_SUCCESSFUL",

  SAVE_PARENT_IF_TEACHER: "SAVE_PARENT_IF_TEACHER",
  SAVE_PARENT_IF_TEACHER_SUCCESSFUL: "SAVE_PARENT_IF_TEACHER_SUCCESSFUL",

  ADD_SIBLING: "ADD_SIBLING",
  ADD_SIBLING_SUCCESSFUL: "ADD_SIBLING_SUCCESSFUL",

  STUDENT_CLASS_CHANGE: "STUDENT_CLASS_CHANGE",
  STUDENT_CLASS_CHANGE_SUCCESSFUL: "STUDENT_CLASS_CHANGE_SUCCESSFUL",

  UPLOAD_PROFILE: "UPLOAD_PROFILE",
  UPLOAD_PROFILE_SUCCESSFUL: "UPLOAD_PROFILE_SUCCESSFUL",

  UPLOAD_PARENT_PROFILE: "UPLOAD_PARENT_PROFILE",
  UPLOAD_PARENT_PROFILE_SUCCESSFUL: "UPLOAD_PARENT_PROFILE_SUCCESSFUL",

  DOWNLOAD_STUDENT_EXCEL_SHEET: "DOWNLOAD_STUDENT_EXCEL_SHEET",
  DOWNLOAD_STUDENT_EXCEL_SHEET_SUCCESSFUL: "DOWNLOAD_STUDENT_EXCEL_SHEET_SUCCESSFUL",

  DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET: "DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET",
  DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET_SUCCESSFUL:
    "DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET_SUCCESSFUL",

  GET_STUDENT_FEE_PLAN: "GET_STUDENT_FEE_PLAN",
  GET_STUDENT_FEE_PLAN_SUCCESS: "GET_STUDENT_FEE_PLAN_SUCCESS",

  VERIFY_USER_ACCOUNTS_CREATION: "VERIFY_USER_ACCOUNTS_CREATION",

  GET_STUDENT_DOC: "GET_STUDENT_DOC",
  GET_STUDENT_DOC_SUCCESS: "GET_STUDENT_DOC_SUCCESS",

  UPLOAD_STUDENT_DOC: "UPLOAD_STUDENT_DOC",
  UPLOAD_STUDENT_DOC_SUCCESS: "UPLOAD_STUDENT_DOC_SUCCESS",

  DELETE_STUDENT_DOC: "DELETE_STUDENT_DOC",
  DELETE_STUDENT_DOC_SUCCESS: "DELETE_STUDENT_DOC_SUCCESS",

  CHANGE_STUDENT_STATUS: "CHANGE_STUDENT_STATUS",
  CHANGE_STUDENT_STATUS_SUCCESS: "CHANGE_STUDENT_STATUS_SUCCESS",

  GET_STUDENT_PROGRAMS: "GET_STUDENT_PROGRAMS",
  GET_STUDENT_PROGRAMS_SUCCESS: "GET_STUDENT_PROGRAMS_SUCCESS",

  SEND_INVITE_SMS_REMINDER: "SEND_INVITE_SMS_REMINDER",

  UPDATE_EMAIL_INVITE: "UPDATE_EMAIL_INVITE",
  UPDATE_EMAIL_INVITE_SUCCESS: "UPDATE_EMAIL_INVITE_SUCCESS",
  UPDATE_EMAIL_INVITE_FAILED: "UPDATE_EMAIL_INVITE_FAILED",

  GET_STUDENT_ONE_TIME_FEE_COMPONENT: "GET_STUDENT_ONE_TIME_FEE_COMPONENT",
  GET_STUDENT_ONE_TIME_FEE_COMPONENT_SUCCESS: "GET_STUDENT_ONE_TIME_FEE_COMPONENT_SUCCESS",

  SAVE_AUTHORISED_PICKUP: "SAVE_AUTHORISED_PICKUP",
  SAVE_AUTHORISED_PICKUP_SUCCESS: "SAVE_AUTHORISED_PICKUP_SUCCESS",

  EDIT_AUTHORISED_PICKUP: "EDIT_AUTHORISED_PICKUP",
  EDIT_AUTHORISED_PICKUP_SUCCESS: "EDIT_AUTHORISED_PICKUP_SUCCESS",

  DELETE_AUTHORISED_PICKUP: "DELETE_AUTHORISED_PICKUP",
  DELETE_AUTHORISED_PICKUP_SUCCESS: "DELETE_AUTHORISED_PICKUP_SUCCESS",

  START_STUDENT_LOADER: "START_STUDENT_LOADER",

  DOWNLOAD_TERMS_CONDITIONS_REPORT: "DOWNLOAD_TERMS_CONDITIONS_REPORT",
  DOWNLOAD_TERMS_CONDITIONS_REPORT_SUCCESS: "DOWNLOAD_TERMS_CONDITIONS_REPORT_SUCCESS",

  UPDATE_TRIAL_COUNT: "UPDATE_TRIAL_COUNT",
  UPDATE_TRIAL_COUNT_SUCCESS: "UPDATE_TRIAL_COUNT_SUCCESS",

  GET_PROGRAM_BY_STUDENT_ID: "GET_PROGRAM_BY_STUDENT_ID",

  ADD_STUDENT_NOTE: "ADD_STUDENT_NOTE",
  ADD_STUDENT_NOTE_SUCCESS: "ADD_STUDENT_NOTE_SUCCESS",

  GET_STUDENT_NOTE: "GET_STUDENT_NOTE",
  GET_STUDENT_NOTE_SUCCESS: "GET_STUDENT_NOTE_SUCCESS",

  DELETE_STUDENT_SELECTED_FEE_PLAN: "DELETE_STUDENT_SELECTED_FEE_PLAN",
  DELETE_STUDENT_SELECTED_FEE_PLAN_SUCCESS: "DELETE_STUDENT_SELECTED_FEE_PLAN_SUCCESS",

  ADD_DOC_FOLDER: "ADD_DOC_FOLDER",
  ADD_DOC_FOLDER_SUCCESS: "ADD_DOC_FOLDER_SUCCESS",

  RENAME_STUDENT_DOC_FOLDER: "RENAME_STUDENT_DOC_FOLDER",
  RENAME_STUDENT_DOC_FOLDER_SUCCESS: "RENAME_STUDENT_DOC_FOLDER_SUCCESS",

  DELETE_PARENT: "DELETE_PARENT",
  DELETE_PARENT_SUCCESS: "DELETE_PARENT_SUCCESS",

  UNLINK_PARENT: "UNLINK_PARENT",
  UNLINK_PARENT_SUCCESS: "UNLINK_PARENT_SUCCESS",

  LINK_PARENT: "LINK_PARENT",
  LINK_PARENT_SUCCESS: "LINK_PARENT_SUCCESS",

  RESET_EXISTING_DETAIL: "RESET_EXISTING_DETAIL",

  FORCE_LOADER_START: "FORCE_LOADER_START",
  FORCE_LOADER_STOP: "FORCE_LOADER_STOP",

  GET_STUDENT_NOTE_COUNT: "GET_STUDENT_NOTE_COUNT",

  GET_STUDENT_MEDIA: "GET_STUDENT_MEDIA",
  GET_STUDENT_MEDIA_SUCCESS: " GET_STUDENT_MEDIA_SUCCESS",

  FETCH_STUDENT_LOG: "FETCH_STUDENT_LOG",
  FETCH_STUDENT_LOG_SUCESS: "FETCH_STUDENT_LOG_SUCESS",

  FETCH_PARENT_LOG: "FETCH_PARENT_LOG",
  FETCH_PARENT_LOG_SUCESS: "FETCH_PARENT_LOG_SUCESS",

  FETCH_INVITE_LOG: "FETCH_INVITE_LOG",
  FETCH_INVITE_LOG_SUCCESS: "FETCH_INVITE_LOG_SUCCESS",

  FETCH_INVITE_DEL_STATUS: "FETCH_INVITE_DEL_STATUS",
  FETCH_INVITE_DEL_STATUS_SUCCESS: "FETCH_INVITE_DEL_STATUS_SUCCESS",

  FETCH_DOC_LOG: "FETCH_DOC_LOG",
  FETCH_DOC_LOG_SUCCESS: "FETCH_DOC_LOG_SUCCESS",
  GET_STUDENTS: "GET_STUDENTS",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  RESET_STUDENT_DELETE: "RESET_STUDENT_DELETE",

  RESET_PARENT_EMAIL_CHECK: "RESET_PARENT_EMAIL_CHECK",

  ADD_STUDENT_IN_NEW_BRANCH: "ADD_STUDENT_IN_NEW_BRANCH",

  ADD_STUDENT_IN_NEW_BRANCH_SUCESS: "ADD_STUDENT_IN_NEW_BRANCH_SUCESS",
  GET_STUDENT_FAVT_MEDIA: "GET_STUDENT_FAVT_MEDIA",
  GET_STUDENT_FAVT_MEDIA_SUCCESS: " GET_STUDENT_FAVT_MEDIA_SUCCESS",
  ADD_STUDENT_IN_NEW_BRANCH: "ADD_STUDENT_IN_NEW_BRANCH",

  ADD_STUDENT_IN_NEW_BRANCH_SUCESS: "ADD_STUDENT_IN_NEW_BRANCH_SUCESS",

  RESET_STUDENT_REDIRECT: "RESET_STUDENT_REDIRECT",
  RESET_STUDENT_REDIRECT_SUCCESS: "RESET_STUDENT_REDIRECT_SUCCESS",

  UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO: "UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO",
  UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO_SUCCESS: "UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO_SUCCESS",

  REMOVE_PROFILE_IMAGE: "REMOVE_PROFILE_IMAGE",
  REMOVE_PROFILE_IMAGE_SUCCESS: "REMOVE_PROFILE_IMAGE_SUCCESS",

  UNSET_SELECTED_INFO_CARD: "UNSET_SELECTED_INFO_CARD",

  REFRESH_PAGE: "REFRESH_PAGE",
  REFRESH_PAGE_SUCCESS: "REFRESH_PAGE_SUCCESS",

  BULK_STUDENT_OPERATION: "BULK_STUDENT_OPERATION",
  BULK_STUDENT_OPERATION_SUCCESS: "BULK_STUDENT_OPERATION_SUCCESS",
  DELETE_GALLERY_MEDIA: "DELETE_GALLERY_MEDIA",
  DELETE_GALLERY_MEDIA_SUCCESS: "DELETE_GALLERY_MEDIA_SUCCESS",

  studentMediaDetail: (studentId, firebase) => ({
    type: actions.GET_STUDENT_MEDIA,
    studentId,
    firebase,
  }),
  fetchFavtmediaDetail: (studentId, firebase) => ({
    type: actions.GET_STUDENT_FAVT_MEDIA,
    studentId,
    firebase,
  }),

  addStudentInNewBranch: (values, existingStudent, firebase) => ({
    type: actions.ADD_STUDENT_IN_NEW_BRANCH,
    values,
    existingStudent,
    firebase,
  }),
  resetStudentDelete: () => ({
    type: actions.RESET_STUDENT_DELETE,
  }),

  initStudentData: (fetchLimit, firebase, initialCall) => ({
    type: actions.LIST_STUDENT,
    fetchLimit,
    firebase,
    initialCall,
  }),

  getClassroomDetails: (firebase) => ({
    type: actions.GET_STUDENT_CLASSROOM_DATA,
    firebase,
  }),

  getStudentDetails: (studentId, firebase, mode) => ({
    type: actions.FETCH_STUDENT_DETAILS,
    studentId,
    firebase,
    mode,
  }),

  getTags: (firebase) => ({
    type: actions.GET_TAGS,
    firebase,
  }),

  getInvoiceTemplate: (firebase) => ({
    type: actions.GET_INVOICETEMPLATE,
    firebase,
  }),

  studentLogsRecord: (studentId, firebase) => ({
    type: actions.FETCH_STUDENT_LOG,
    studentId,
    firebase,
  }),
  parentLogsRecord: (parentId, firebase) => ({
    type: actions.FETCH_PARENT_LOG,
    // node,
    parentId,
    firebase,
  }),

  // submitStudent: (values, tags, firebase) => ({
  //   type: actions.SUBMIT_STUDENT,
  //   values,
  //   tags,
  //   firebase
  // }),

  submitStudent: (values, firebase) => ({
    type: actions.SUBMIT_STUDENT,
    values,
    firebase,
  }),

  updateStudent: (values, record, tags, firebase, updatedBy) => ({
    type: actions.UPDATE_STUDENT,
    values,
    record,
    tags,
    firebase,
    updatedBy,
  }),

  deleteStudent: (studentRecord, firebase) => ({
    type: actions.DELETE_STUDENT,
    studentRecord,
    firebase,
  }),
  getStudents: (firebase) => ({
    type: actions.GET_STUDENTS,
    firebase,
  }),

  getParentDetail: (studentRecord, firebase) => ({
    type: actions.GET_PARENT_DETAIL_OF_SELECTED_STUDENT,
    studentRecord,
    firebase,
  }),

  getLoggedInUserVersion: (firebase) => ({
    type: actions.GET_USER_VERSION,
    firebase,
  }),

  sendInvite: (parentList, firebase, changeEmail, relation) => ({
    type: actions.SEND_INVITE,
    parentList,
    firebase,
    changeEmail,
    relation,
  }),

  getSelectedStudentAttendance: (studentId, month, firebase, oldAttendance) => ({
    type: actions.GET_SELECTED_STUDENT_ATTENDANCE,
    studentId,
    month,
    firebase,
    oldAttendance,
  }),

  updateMedication: (medicines, student, firebase) => ({
    type: actions.UPDATE_STUDENT_MEDICATION,
    medicines,
    student,
    firebase,
  }),

  updateAllergy: (allergy, student, firebase) => ({
    type: actions.UPDATE_STUDENT_ALLERGY,
    allergy,
    student,
    firebase,
  }),

  updateBloodGroup: (bloodGroup, student, firebase) => ({
    type: actions.UPDATE_BLOOD_GROUP,
    bloodGroup,
    student,
    firebase,
  }),

  updateAdmissionNumber: (admissionNumber, student, firebase) => ({
    type: actions.UPDATE_ADMISSION_NUMBER,
    admissionNumber,
    student,
    firebase,
  }),

  updateStudentActiveStatus: (studentRecord, firebase) => ({
    type: actions.UPDATE_STUDENT_ACTIVE_STATUS,
    studentRecord,
    firebase,
  }),

  saveParentExtraDetail: (values, selectedParentType, editableRecord, firebase) => ({
    type: actions.SAVE_PARENT_EXTRA_DETAILS,
    values,
    selectedParentType,
    editableRecord,
    firebase,
  }),

  refreshFormFields: (record, firebase) => ({
    type: actions.REFRESH_FORM_FIELDS,
    record,
    firebase,
  }),

  resetSelectedStudent: () => ({
    type: actions.RESET_SELECTED_STUDENT,
  }),

  getRefreshedChildConfig: (record, firebase) => ({
    type: actions.REFRESH_CHILD_FORM_FIELDS,
    record,
    firebase,
  }),

  inviteParent: (values, relation, studentRecord, firebase) => ({
    type: actions.INVITE_PARENT,
    values,
    relation,
    studentRecord,
    firebase,
  }),

  resetStudentOperation: () => ({
    type: actions.RESET_STUDENT_OPERATION,
  }),

  saveParentDetailIfSibling: (siblingStudent, originalStudent, firebase) => ({
    type: actions.SAVE_PARENT_IF_SIBLING,
    siblingStudent,
    originalStudent,
    firebase,
  }),

  submitParentAsTeacher: (studentData, teacherData, relation, firebase) => ({
    type: actions.SAVE_PARENT_IF_TEACHER,
    studentData,
    teacherData,
    relation,
    firebase,
  }),

  submitSibling: (values, existingStudent, firebase) => ({
    type: actions.ADD_SIBLING,
    values,
    existingStudent,
    firebase,
  }),

  saveStudentWithClassChange: (students, classroom, firebase) => ({
    type: actions.STUDENT_CLASS_CHANGE,
    students,
    classroom,
    firebase,
  }),

  uploadStudentProfilePic: (file, studentDetail, firebase) => ({
    type: actions.UPLOAD_PROFILE,
    file,
    studentDetail,
    firebase,
  }),

  uploadParentProfilePic: (file, parentId, firebase, parentType, studentDetail) => ({
    type: actions.UPLOAD_PARENT_PROFILE,
    file,
    parentId,
    firebase,
    parentType,
    studentDetail,
  }),

  downloadStudentExcel: (students, firebase) => ({
    type: actions.DOWNLOAD_STUDENT_EXCEL_SHEET,
    students,
    firebase,
  }),

  downloadStudentAllergyExcel: (students, firebase) => ({
    type: actions.DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET,
    students,
    firebase,
  }),

  getStudentFeePlan: (studentId, firebase) => ({
    type: actions.GET_STUDENT_FEE_PLAN,
    studentId,
    firebase,
  }),

  verifyUserAccounts: (students, firebase) => ({
    type: actions.VERIFY_USER_ACCOUNTS_CREATION,
    students,
    firebase,
  }),

  getStudentDocument: (studentId, firebase) => ({
    type: actions.GET_STUDENT_DOC,
    studentId,
    firebase,
  }),

  uploadUserDocument: (fileList, mediaType, id, firebase, filePath) => ({
    type: actions.UPLOAD_STUDENT_DOC,
    fileList,
    mediaType,
    id,
    firebase,
    filePath,
  }),

  deleteUserDocument: (record, id, firebase, filePath) => ({
    type: actions.DELETE_STUDENT_DOC,
    record,
    id,
    firebase,
    filePath
  }),

  updateSelectedStudentStatus: (student, firebase) => ({
    type: actions.CHANGE_STUDENT_STATUS,
    student,
    firebase,
  }),

  getStudentProgram: (firebase) => ({
    type: actions.GET_STUDENT_PROGRAMS,
    firebase,
  }),

  sendInviteReminder: (students, reminderMode, firebase, forcedUpdate) => ({
    type: actions.SEND_INVITE_SMS_REMINDER,
    students,
    reminderMode,
    firebase,
    forcedUpdate,
  }),

  updateEmailInvite: (updatedEmail, firebase) => ({
    type: actions.UPDATE_EMAIL_INVITE,
    updatedEmail,
    firebase,
  }),

  getOneTimeFeeComponent: (studentId, firebase) => ({
    type: actions.GET_STUDENT_ONE_TIME_FEE_COMPONENT,
    studentId,
    firebase,
  }),

  saveAuthorisedPickup: (value, selectedStudent, firebase, pickupImageInfo) => ({
    type: actions.SAVE_AUTHORISED_PICKUP,
    value,
    selectedStudent,
    firebase,
    pickupImageInfo,
  }),

  editAuthorisedPickup: (value, index, selectedStudent, firebase, pickupImageInfo) => ({
    type: actions.EDIT_AUTHORISED_PICKUP,
    value,
    index,
    selectedStudent,
    firebase,
    pickupImageInfo,
  }),

  deleteAuthPickup: (value, studentDetail, firebase) => ({
    type: actions.DELETE_AUTHORISED_PICKUP,
    value,
    studentDetail,
    firebase,
  }),

  downloadTermsConditionExcel: (students, firebase) => ({
    type: actions.DOWNLOAD_TERMS_CONDITIONS_REPORT,
    students,
    firebase,
  }),

  updateTrialCountNumber: (trialCountText, student, firebase) => ({
    type: actions.UPDATE_TRIAL_COUNT,
    trialCountText,
    student,
    firebase,
  }),

  updateCommunicationEmail: (communicationText, student, firebase) => ({
    type: actions.UPDATE_STUDENT_COMMUNICATION_EMAIL,
    communicationText,
    student,
    firebase,
  }),

  addStudentNote: (values, studentId, firebase, record, attachedImage, operation) => ({
    type: actions.ADD_STUDENT_NOTE,
    values,
    studentId,
    firebase,
    record,
    attachedImage,
    operation,
  }),

  getStudentNotes: (studentId, firebase) => ({
    type: actions.GET_STUDENT_NOTE,
    studentId,
    firebase,
  }),

  deleteStudentSelectedFeePlan: (record, studentId, firebase) => ({
    type: actions.DELETE_STUDENT_SELECTED_FEE_PLAN,
    record,
    studentId,
    firebase,
  }),

  addNewFolder: (folderName, filePath, firebase, id, privateFolderRecord) => ({
    type: actions.ADD_DOC_FOLDER,
    folderName,
    filePath,
    firebase,
    id,
    privateFolderRecord
  }),

  renameDocument: (docName, record, firebase, id, documents, currentFilePath) => ({
    type: actions.RENAME_STUDENT_DOC_FOLDER,
    docName,
    record,
    firebase,
    id,
    documents,
    currentFilePath
  }),

  deleteParent: (firebase, relation, student) => ({
    type: actions.DELETE_PARENT,
    firebase,
    relation,
    student,
  }),

  unlinkParent: (firebase, relation, student) => ({
    type: actions.UNLINK_PARENT,
    firebase,
    relation,
    student,
  }),

  linkParent: (firebase, parentId, student, relation) => ({
    type: actions.LINK_PARENT,
    firebase,
    parentId,
    student,
    relation,
  }),

  resetExistingDetail: () => ({
    type: actions.RESET_EXISTING_DETAIL,
  }),

  forceLoaderStop: () => ({
    type: actions.FORCE_LOADER_STOP,
  }),

  getInviteLog: (firebase) => ({
    type: actions.FETCH_INVITE_LOG,
    firebase,
  }),

  getDeliveryReport: (id, firebase) => ({
    type: actions.FETCH_INVITE_DEL_STATUS,
    id,
    firebase,
  }),

  getDocumentLog: (id, firebase) => ({
    type: actions.FETCH_DOC_LOG,
    id,
    firebase,
  }),
  //here
  resetparentEmailInvite: () => ({
    type: actions.RESET_PARENT_EMAIL_CHECK,
  }),
  resetStudentRedirect: () => ({
    type: actions.RESET_STUDENT_REDIRECT,
  }),
  updateHealthAndEmergencyInfo: (values, student, firebase) => ({
    type: actions.UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO,
    values,
    student,
    firebase,
  }),
  removeProfileImage: (parentType, student, firebase) => ({
    type: actions.REMOVE_PROFILE_IMAGE,
    parentType,
    student,
    firebase
  }),
  unsetSelectedInfoCase: () => ({
    type: actions.UNSET_SELECTED_INFO_CARD
  }),
  refreshPage: () => ({
    type: actions.REFRESH_PAGE
  }),
  bulkStudentOperation: (firebase, studentIdsList, operationType, value, extraObj) => ({
    type: actions.BULK_STUDENT_OPERATION,
    firebase,
    studentIdsList,
    operationType,
    value,
    extraObj
  }),
  deleteGalleryMedia: (studentId, firebase, media) => ({
    type: actions.DELETE_GALLERY_MEDIA,
    studentId,
    firebase,
    media,
  })

};

export default actions;
