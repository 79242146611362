import actions from "./actions";
const initState = {
  isLoading: false,
  detailedProgramStudent: [],
  detailedProgramFeePlan: [],
  error: true,
  selectedProgram: {},
};

export default function detailedProgramReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SELECTED_PROGRAM_DETAIL:
      return {
        ...state,
        isLoading: true,
        selectedProgram: {},
      };
    case actions.GET_SELECTED_PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedProgram: action.selectedProgram,
      };

    case actions.DELETE_STUDENT_FROM_PROGRAM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_STUDENT_FROM_PROGRAM_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_STUDENT",
      };
    case actions.DETAILED_PROGRAM_FETCH_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DETAILED_PROGRAM_FETCH_STUDENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        detailedProgramStudent: action.detailedProgramStudent,
      };
    case actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ASSIGN_STUDENT",
      };
    case actions.DETAILED_PROGRAM_FETCH_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.DETAILED_PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        detailedProgramFeePlan: action.detailedProgramFeePlan,
      };
    case actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DETAILED_PROGRAM_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.RESET_PROGRAM_OPERATION:
      return {
        ...state,
        operationType: undefined,
      };

    default:
      return state;
  }
}
