import { all, put, call, take, takeLatest, fork, race } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { CommentApi } from "../../firestore-api/comment";
import { NotificationApi } from "../../firestore-api/notification";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import bugsnagClient from "@bugsnag/js";
import { ActivityApi } from "../../firestore-api/activity";
import UserImageAction from "../../Utility/userImageAction";
import FilterAction from "../../Utility/FilterAction";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { callApi } from "../../Utility/superAgentUntil";


function* fetchComments({ id, firebase }) {
  const chan = yield call(CommentApi.getComments, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_COMMENTS_SUCCESSFULL,
        comments: data,
        commentsChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* addNewComment({
  commentObj,
  firebase,
  commentType,
  activityName,
  studentId,
  itemId,
  studentIds,
  classList
}) {
  try {
    let newCommentObject = commentObj;

    let img = commentObj.uploadedImage ? commentObj.uploadedImage : undefined;
    if (commentObj.staffOnly) {
      img = commentObj.staffUploadedImage ? commentObj.staffUploadedImage : null;
    }
    if (img && img.fileList) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        if (commentObj.staffOnly) {
          newCommentObject.staffUploadedImage = mediaPaths[0];
        }
        else {
          newCommentObject.uploadedImage = mediaPaths[0];
        }
      }
    }

    newCommentObject.studentId = studentId;
    if (studentIds && studentIds.length > 0) {
      // activity case
      newCommentObject.studentIds = studentIds;
      newCommentObject.studentId = null;
    }
    if (classList.length > 0) {
      newCommentObject.classList = classList;
      newCommentObject.studentId = null;
      newCommentObject.studentIds = null;
      newCommentObject.teacherIdForNotification = null;
    }
    newCommentObject.activityName = activityName;
    newCommentObject.nodeId = itemId;

    let endpoint = "woodlandApi/addComment?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, { ...newCommentObject });
    if (response && response.status === 200) {
      yield put({
        type: actions.ADD_COMMENT_SUCCESS
      });
    }
  } catch (err) {
    console.log("failed to add comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.COMMENT_REQ_FAILED,
    });
  }
}

function* addNewParentComment({
  commentObj,
  firebase,
  commentType,
  selectedActivityName,
  itemId,
  student,
  disableNotification,
  lessonId,
  teacherId,
}) {
  try {
    var newCommentObject = commentObj;
    let img = commentObj.uploadedImage ? commentObj.uploadedImage : undefined;
    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        newCommentObject.uploadedImage = mediaPaths[0];
      }
    }
    newCommentObject.activityName = selectedActivityName;
    newCommentObject.nodeId = itemId;
    newCommentObject.updatedBy = newCommentObject.author;
    let endpoint = "woodlandApi/addComment?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, { ...newCommentObject });

    if (response && response.status === 200) {
      yield put({
        type: actions.ADD_PARENT_COMMENT_SUCCESS
      });
    }
  } catch (err) {
    console.log("failed to add parent comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.COMMENT_REQ_FAILED,
    });
  }
}

function* deleteSelectedComment({ item, activityId, firebase }) {
  try {
    let endpoint = "woodlandApi/deleteComment?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, { id: item.id, activityId: activityId, nodeId: activityId, });
    if (response && response.status === 200) {
      yield put({
        type: actions.DELETE_COMMENT_SUCCESS
      });
    }
  } catch (err) {
    console.log("failed to delete comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.COMMENT_REQ_FAILED,
    });
  }
}


export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_COMMENTS, fetchComments),
    yield takeLatest(actions.ADD_COMMENT, addNewComment),
    yield takeLatest(actions.ADD_PARENT_COMMENT, addNewParentComment),
    yield takeLatest(actions.DELETE_COMMENT, deleteSelectedComment),
  ]);
}
