import actions from "./actions";

const initState = {
  classrooms: [],
  initialClassrooms: false,
  editableClassroom: {},
  isNewClassroom: false,
  enableEditView: false,
  isModalVisible: false,
  isUpdateModalVisible: false,
  autoCompleteData: [],
  error: false,
  errorInfo: "",
  isLoading: false,
  classroomAddRequestSuccessful: false,
  classroomUpdateRequestSuccessful: false,
  classroomToggleRequestSuccessful: false,
  classChannel: undefined,
  operationType: "",
  addClassToTeachers: {},
};

export default function classRoomReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_CLASSROOMS:
      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.LIST_CLASSROOM:
      return {
        ...state,
        classrooms: action.classrooms,
        classChannel: action.classChannel,
        initialClassrooms: true,
        enableEditView: false,
        isLoading: false,
        operationType: "",
      };
    case actions.GET_CENTER_DATA:
      return {
        ...state,
      };
    case actions.GET_CENTER_DATA_SUCCESSFUL:
      return {
        ...state,
        autoCompleteData: action.autoCompleteData,
      };
    case actions.ADD_CLASSROOM:
      return {
        ...state,
        initialClassrooms: false,
        classroomAddRequestSuccessful: false,
        isLoading: true,
      };
    case actions.ADD_CLASSROOM_COMPLETED:
      return {
        ...state,
        initialClassrooms: false,
        classroomAddRequestSuccessful: true,
        isLoading: false,
        operationType: action.operationType,
      };
    case actions.REQUEST_FAILED:
      return {
        ...state,
        error: true,
        errorInfo: action.errorInfo,
        isLoading: false
      };
    case actions.UPDATE_CLASSROOM:
      return {
        ...state,
        initialClassrooms: false,
        isLoading: true,
        classroomUpdateRequestSuccessful: false,
      };
    case actions.UPDATE_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        initialClassrooms: false,
        isLoading: false,
        classroomUpdateRequestSuccessful: true,
        operationType: action.operationType,
      };
    case actions.TOGGLE_DATA:
      return {
        ...state,
        initialClassrooms: false,
        isLoading: true,
        classroomToggleRequestSuccessful: false,
      };
    case actions.TOGGLE_DATA_SUCCESSFUL:
      return {
        ...state,
        initialClassrooms: false,
        isLoading: false,
        classroomToggleRequestSuccessful: true,
      };

    case actions.DELETE_CLASS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.START_CLASS_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_CLASS_TO_TEACHERS:
      return {
        ...state,
      };

    case actions.ADD_CLASS_TO_TEACHERS_SUCCESSFUL:
      return {
        ...state,
        operationType: "ALL_TEACHER_UPDATED",
      };

    case actions.RESET_CLASSROOM_OPERATION:
      return {
        ...state,
        operationType: undefined,
        error: false,
      };

    default:
      return state;
  }
}
