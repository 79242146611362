const actions = {
  GET_ALL_SUBMISSION_ACTIVITY: "GET_ALL_SUBMISSION_ACTIVITY",
  GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL: "GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL",

  ALL_SUBMISSION_REQUEST_FAIL: "ALL_SUBMISSION_REQUEST_FAIL",

  RESET_SUBMISSIONS_OPERATION_TYPE: "RESET_SUBMISSIONS_OPERATION_TYPE",

  GET_SUBMISSIONS_CLASSES: "GET_SUBMISSIONS_CLASSES",
  GET_SUBMISSIONS_CLASSES_SUCCESS: "GET_SUBMISSIONS_CLASSES_SUCCESS",

  GET_SUBMISSIONS_STUDENT: "GET_SUBMISSIONS_STUDENT",
  GET_SUBMISSIONS_STUDENT_SUCCESS: "GET_SUBMISSIONS_STUDENT_SUCCESS",

  GET_SUBMISSION_LIST_COMMENT: "GET_SUBMISSION_LIST_COMMENT",
  GET_SUBMISSION_LIST_COMMENT_SUCCESS: "GET_SUBMISSION_LIST_COMMENT_SUCCESS",

  MARK_ASSIGNMENT_STAR: "MARK_ASSIGNMENT_STAR",
  MARK_ASSIGNMENT_STAR_SUCCESS: "MARK_ASSIGNMENT_STAR_SUCCESS",

  GET_STUDENT_SUBMISSION_LIST_STATS: "GET_STUDENT_SUBMISSION_LIST_STATS",
  GET_SUBMISSION_STUDENTS_SUCCESS: "GET_SUBMISSION_STUDENTS_SUCCESS",
  GET_STUDENT_SUBMISSION_LIST_STATS_SUCCESS: "GET_STUDENT_SUBMISSION_LIST_STATS_SUCCESS",

  getAllSubmissionActivity: (assignmentId, firebase) => ({
    type: actions.GET_ALL_SUBMISSION_ACTIVITY,
    assignmentId,
    firebase,
  }),

  resetSubmissionsOperation: () => ({
    type: actions.RESET_SUBMISSIONS_OPERATION_TYPE,
  }),

  getClassesForSubmissions: (firebase) => ({
    type: actions.GET_SUBMISSIONS_CLASSES,
    firebase,
  }),

  getAllStudents: (firebase) => ({
    type: actions.GET_SUBMISSIONS_STUDENT,
    firebase,
  }),

  getSubmissionListComments: (submissionList, firebase) => ({
    type: actions.GET_SUBMISSION_LIST_COMMENT,
    submissionList,
    firebase,
  }),

  markAssignmentStar: (activity, firebase) => ({
    type: actions.MARK_ASSIGNMENT_STAR,
    activity,
    firebase,
  }),

  getStudentSubmissionStats: (startDate, endDate, firebase, allStudent, refresh) => ({
    type: actions.GET_STUDENT_SUBMISSION_LIST_STATS,
    startDate,
    endDate,
    firebase,
    allStudent,
    refresh
  }),
};
export default actions;
