export default function getColor(color) {
  if (color === "Red") {
    return {
      headerColor: "#F8E0E0",
      headerTextColor: "#A80000",
    };
  } else if (color === "Pink") {
    return {
      headerColor: "#F6E0F8",
      headerTextColor: "#A900B7",
    };
  } else if (color === "Green") {
    return {
      headerColor: "#E1F9F9",
      headerTextColor: "#008C8C",
    };
  } else if (color === "Blue") {
    return {
      headerColor: "#E1F2F9",
      headerTextColor: "#008CBF",
    };
  } else if (color === "Yellow") {
    return {
      headerColor: "#F9F3E1",
      headerTextColor: "#E5A700",
    };
  }
}
