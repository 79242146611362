const actions = {
  GET_ALL_PARENT_NOTES: "GET_ALL_PARENT_NOTES",
  GET_ALL_PARENT_NOTES_SUCCESSFUL: "GET_ALL_PARENT_NOTES_SUCCESSFUL",

  GET_COMMENT_FOR_PARENT_NOTE: "GET_COMMENT_FOR_PARENT_NOTE",
  GET_COMMENT_FOR_PARENT_NOTE_SUCCESSFUL: "GET_COMMENT_FOR_PARENT_NOTE_SUCCESSFUL",

  PARENT_NOTE_REQUEST_FAILED: "PARENT_NOTE_REQUEST_FAILED",

  RESOLVE_NOTE: "RESOLVE_NOTE",
  RESOLVE_NOTE_SUCCESSFUL: "RESOLVE_NOTE_SUCCESSFUL",

  SAVE_NOTE_COMMENT: "SAVE_NOTE_COMMENT",
  SAVE_NOTE_COMMENT_SUCCESSFUL: "SAVE_NOTE_COMMENT_SUCCESSFUL",

  RESET_NOTE_OPERATION_TYPE: "RESET_NOTE_OPERATION_TYPE",

  GET_NOTIFICATION_NOTE: "GET_NOTIFICATION_NOTE",

  getAllParentNotes: (firebase, startDate, endDate, initialCall, selectedFrequency, check) => ({
    type: actions.GET_ALL_PARENT_NOTES,
    firebase,
    startDate,
    endDate,
    initialCall,
    selectedFrequency,
    check,
  }),

  getNotesComments: (notes, firebase) => ({
    type: actions.GET_COMMENT_FOR_PARENT_NOTE,
    notes,
    firebase,
  }),

  resolveNote: (record, firebase) => ({
    type: actions.RESOLVE_NOTE,
    record,
    firebase,
  }),

  saveNoteComment: (comment, dataSource, firebase) => ({
    type: actions.SAVE_NOTE_COMMENT,
    comment,
    dataSource,
    firebase,
  }),

  resetNoteOperationType: () => ({
    type: actions.RESET_NOTE_OPERATION_TYPE,
  }),

  getNotificationNote: (id, firebase) => ({
    type: actions.GET_NOTIFICATION_NOTE,
    id,
    firebase,
  }),
};
export default actions;
