import actions from "./actions";
const initState = {
  submissionLoading: false,
  error: false,
  allSubmissionActivity: [],
  submissionsClasses: [],
  submissionsStudents: [],
  submissionsOperationType: undefined,
  showAllSubmissionFlag: false,
  submissionComments: new Map(),
  submissionStatsMap: new Map(),
  submissionStatsMapChannel: undefined,
  submissionStudentList: [],
  submissionClassroom: [],
};

export default function submissionsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_SUBMISSION_ACTIVITY:
      return {
        ...state,
        submissionLoading: true,
        allSubmissionActivity: [],
      };

    case actions.GET_ALL_SUBMISSION_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        submissionLoading: false,
        allSubmissionActivity: action.allSubmissionActivity,
        // submissionsOperationType: action.submissionsOperationType,
        // showAllSubmissionFlag: action.showAllSubmissionFlag
      };

    case actions.ALL_SUBMISSION_REQUEST_FAIL:
      return {
        ...state,
        submissionLoading: false,
        error: true,
      };

    case actions.RESET_SUBMISSIONS_OPERATION_TYPE:
      return {
        ...state,
        submissionsOperationType: undefined,
        submissionLoading: false,
        error: false,
      };

    case actions.GET_SUBMISSIONS_CLASSES:
      return {
        ...state,
      };

    case actions.GET_SUBMISSIONS_CLASSES_SUCCESS:
      return {
        ...state,
        submissionsClasses: action.submissionsClasses,
      };

    case actions.GET_SUBMISSIONS_STUDENT:
      return {
        ...state,
      };

    case actions.GET_SUBMISSIONS_STUDENT_SUCCESS:
      return {
        ...state,
        submissionsStudents: action.submissionsStudents,
      };

    case actions.GET_SUBMISSION_LIST_COMMENT:
      return {
        ...state,
        submissionComments: new Map(),
      };

    case actions.GET_SUBMISSION_LIST_COMMENT_SUCCESS:
      return {
        ...state,
        submissionComments: action.submissionComments,
      };

    case actions.MARK_ASSIGNMENT_STAR:
      return {
        ...state,
      };

    case actions.MARK_ASSIGNMENT_STAR_SUCCESS:
      return {
        ...state,
        submissionsOperationType: "star_marked",
      };

    case actions.GET_STUDENT_SUBMISSION_LIST_STATS:
      return {
        ...state,
        submissionLoading: true,
      };

    case actions.GET_STUDENT_SUBMISSION_LIST_STATS_SUCCESS:
      return {
        ...state,
        submissionLoading: false,
        submissionStatsMap: action.submissionStatsMap,
        submissionStatsMapChannel: action.submissionStatsMapChannel,
      };

    case actions.GET_SUBMISSION_STUDENTS_SUCCESS:
      return {
        ...state,
        submissionStudentList: action.submissionStudentList,
        submissionClassroom: action.submissionClassroom,
      };

    default:
      return state;
  }
}
