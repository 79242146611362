const actions = {
  GET_TODAYS_POST_FOR_ACTIVITY: "GET_TODAYS_POST_FOR_ACTIVITY",
  GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL: "GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL",

  GET_CLASSROOM_FOR_ACTIVITY: "GET_CLASSROOM_FOR_ACTIVITY",
  GET_CLASSROOM_FOR_ACTIVITY_SUCCESSFUL: "GET_CLASSROOM_FOR_ACTIVITY_SUCCESSFUL",

  GET_ACTIVITIES_BY_CLASSROOM: "GET_ACTIVITIES_BY_CLASSROOM",
  GET_ACTIVITIES_BY_CLASSROOM_SUCCESSFUL: "GET_ACTIVITIES_BY_CLASSROOM_SUCCESSFUL",

  GET_TAGGED_STUDENT_DETAIL: "GET_TAGGED_STUDENT_DETAIL",
  GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL: "GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL",

  APPROVE_ACTIVITY: "APPROVE_ACTIVITY",
  APPROVE_ACTIVITY_SUCCESSFUL: "APPROVE_ACTIVITY_SUCCESSFFUL",

  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_SUCCESSFUL: "UPDATE_ACTIVITY_SUCCESSFUL",

  DELETE_ACTIVITY: "DELETE_ACTIVITY",
  DELETE_ACTIVITY_SUCCESSFUL: "DELETE_ACTIVITY_SUCCESSFUL",

  ACTIVITY_REQUEST_FAILED: "ACTIVITY_REQUEST_FAILED",

  RESET_ACTIVITY_OPERATION: "RESET_ACTIVITY_OPERATION",

  GET_SEEN_COUNT_SUCCESSFUL: "GET_SEEN_COUNT_SUCCESSFUL",

  GET_LIKES_COUNT_SUCCESSFUL: "GET_LIKES_COUNT_SUCCESSFUL",

  GET_MEETING_REMINDER: "GET_MEETING_REMINDER",
  GET_MEETING_REMINDER_SUCCESSFUL: "GET_MEETING_REMINDER_SUCCESSFUL",

  STOP_CLASS_REPETITION: "STOP_CLASS_REPETITION",
  STOP_CLASS_REPETITION_SUCCESS: "STOP_CLASS_REPETITION_SUCCESS",

  GET_ACTIVITY_COMMENT_SUCCESSFUL: "GET_ACTIVITY_COMMENT_SUCCESSFUL",

  GET_NOTIFICATION_ACTIVITY: "GET_NOTIFICATION_ACTIVITY",

  GET_ACTIVITY_MAP: "GET_ACTIVITY_MAP",
  GET_ACTIVITY_MAP_SUCCESS: "GET_ACTIVITY_MAP_SUCCESS",

  GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW: "GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW",
  GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW_SUCCESS:
    "GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW_SUCCESS",

  SET_IND_STUDENT_ACTIVITIES: "SET_IND_STUDENT_ACTIVITIES",
  SET_IND_STUDENT_ACTIVITIES_SUCCESS: "SET_IND_STUDENT_ACTIVITIES_SUCCESS",

  GET_TRIAL_STUDENT_COUNT: "GET_TRIAL_STUDENT_COUNT",

  REMOVE_STUDENT_FROM_V_CLASS: "REMOVE_STUDENT_FROM_V_CLASS",
  REMOVE_STUDENT_FROM_V_CLASS_SUCCESS: "REMOVE_STUDENT_FROM_V_CLASS_SUCCESS",

  GET_BOOKING_ACTIVITY: "GET_BOOKING_ACTIVITY",
  GET_BOOKING_ACTIVITY_SUCCESS: "GET_BOOKING_ACTIVITY_SUCCESS",

  ADD_NEW_STUDENT_TO_MEETING: "ADD_NEW_STUDENT_TO_MEETING",
  ADD_NEW_STUDENT_TO_MEETING_SUCCESS: "ADD_NEW_STUDENT_TO_MEETING_SUCCESS",

  REMOVE_TRIAL_STUDENT: "REMOVE_TRIAL_STUDENT",
  REMOVE_TRIAL_STUDENT_SUCCESS: "REMOVE_TRIAL_STUDENT_SUCCESS",

  BOOKING_NOT_FOUND: "BOOKING_NOT_FOUND",

  GET_MEETING_REPORT: "GET_MEETING_REPORT",
  GET_MEETING_REPORT_SUCCESS: "GET_MEETING_REPORT_SUCCESS",

  GET_MEETING_ACTIVITY: "GET_MEETING_ACTIVITY",
  GET_MEETING_ACTIVITY_SUCCESS: "GET_MEETING_ACTIVITY_SUCCESS",

  UPDATE_CAPACITY: "UPDATE_CAPACITY",
  UPDATE_CAPACITY_SUCCESS: "UPDATE_CAPACITY_SUCCESS",

  GET_CUSTOM_LABELS: "GET_CUSTOM_LABELS",
  GET_CUSTOM_LABELS_SUCCESS: "GET_CUSTOM_LABELS_SUCCESS",

  DELETE_CUSTOM_LABELS: "DELETE_CUSTOM_LABELS",
  DELETE_CUSTOM_LABELS_SUCCESS: "DELETE_CUSTOM_LABELS_SUCCESS",

  EDIT_CUSTOM_LABELS: "EDIT_CUSTOM_LABELS",
  EDIT_CUSTOM_LABELS_SUCCESS: "EDIT_CUSTOM_LABELS_SUCCESS",

  ADD_CUSTOM_LABEL_CATEGORY: "ADD_CUSTOM_LABEL_CATEGORY",
  ADD_CUSTOM_LABEL_CATEGORY_SUCCESS: "ADD_CUSTOM_LABEL_CATEGORY_SUCCESS",

  UPDATE_LAST_STUDENT_LEVEL_ACTIVITY: "UPDATE_LAST_STUDENT_LEVEL_ACTIVITY",
  UPDATE_LAST_STUDENT_LEVEL_ACTIVITY_SUCCESS: "UPDATE_LAST_STUDENT_LEVEL_ACTIVITY_SUCCESS",

  GET_ACTIVITY_LABEL: "GET_ACTIVITY_LABEL",
  GET_ACTIVITY_LABEL_SUCCESS: "GET_ACTIVITY_LABEL_SUCCESS",

  GET_STAFF_POSTS: "GET_STAFF_POSTS",
  GET_STAFF_POSTS_SUCCESS: "GET_STAFF_POSTS_SUCCESS",

  APPROVE_ALL_ACTIVITY: "APPROVE_ALL_ACTIVITY",
  APPROVE_ALL_ACTIVITY_SUCCESS: "APPROVE_ALL_ACTIVITY_SUCCESS",
  GETPOST: "GETPOST",
  HASLAST: "HASLAST",
  GET_ASSESSMENT_ACTIVITIES: "GET_ASSESSMENT_ACTIVITIES",
  GET_ASSESSMENT_ACTIVITIES_SUCCESS: "GET_ASSESSMENT_ACTIVITIES_SUCCESS",
  GET_ASSESSMENT_ACTIVITIES_FAILURE: "GET_ASSESSMENT_ACTIVITIES_FAILURE",

  getActivityTodaysPost: (startDate, endDate, firebase, todaysPost) => ({
    type: actions.GET_TODAYS_POST_FOR_ACTIVITY,
    startDate,
    endDate,
    firebase,
    todaysPost,
  }),

  getStaffActivityTodaysPost: (startDate, endDate, firebase, todaysPost, nodeId) => ({
    type: actions.GET_STAFF_POSTS,
    startDate,
    endDate,
    firebase,
    todaysPost,
    nodeId,
  }),

  getStudentLevelActivities: (
    startDate,
    endDate,
    firebase,
    todayActivityMap,
    initActivityList
  ) => ({
    type: actions.GET_ACTIVITY_MAP,
    startDate,
    endDate,
    firebase,
    todayActivityMap,
    initActivityList,
  }),

  getActivityClassrooms: (firebase) => ({
    type: actions.GET_CLASSROOM_FOR_ACTIVITY,
    firebase,
  }),

  fetchFilteredActivityByClassroom: (
    classroom,
    startDate,
    endDate,
    firebase,
    limit,
    lastId,
    loadFirst,
    todaysPost
  ) => ({
    type: actions.GET_ACTIVITIES_BY_CLASSROOM,
    classroom,
    startDate,
    endDate,
    firebase,
    limit,
    lastId,
    loadFirst,
    prevActivities: todaysPost,
  }),

  getPosts: (
    classroom,
    startDate,
    endDate,
    firebase,
    limit,
    lastId,
    loadFirst,
    todaysPost,
    initial
  ) => ({
    type: actions.GETPOST,
    classroom,
    startDate,
    endDate,
    firebase,
    limit,
    lastId,
    loadFirst,
    prevActivities: todaysPost,
    initial,
  }),
  getTaggedStudentName: (activityId, firebase, trial, record, staffActivity) => ({
    type: actions.GET_TAGGED_STUDENT_DETAIL,
    activityId,
    firebase,
    trial,
    record,
    staffActivity,
  }),

  approveSelectedActivity: (item, firebase) => ({
    type: actions.APPROVE_ACTIVITY,
    item,
    firebase,
  }),

  updateActivity: (values, record, firebase) => ({
    type: actions.UPDATE_ACTIVITY,
    values,
    record,
    firebase,
  }),

  deleteActivity: (record, firebase) => ({
    type: actions.DELETE_ACTIVITY,
    record,
    firebase,
  }),

  resetActivityOperation: () => ({
    type: actions.RESET_ACTIVITY_OPERATION,
  }),

  getUpcomingMeetingReminders: (startDate, endDate, firebase) => ({
    type: actions.GET_MEETING_REMINDER,
    startDate,
    endDate,
    firebase,
  }),

  stopClassRepetition: (record, firebase, repeatVirtualClass) => ({
    type: actions.STOP_CLASS_REPETITION,
    record,
    firebase,
    repeatVirtualClass,
  }),

  getNotificationActivity: (nodeId, firebase) => ({
    type: actions.GET_NOTIFICATION_ACTIVITY,
    nodeId,
    firebase,
  }),

  getStudentForActivityView: (date, firebase) => ({
    type: actions.GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW,
    date,
    firebase,
  }),

  setIndStudentActivities: (activities, requestType, firebase) => ({
    type: actions.SET_IND_STUDENT_ACTIVITIES,
    activities,
    requestType,
    firebase,
  }),

  removeStudent: (student, activity, studentList, firebase, trial, activityList) => ({
    type: actions.REMOVE_STUDENT_FROM_V_CLASS,
    student,
    activity,
    studentList,
    firebase,
    trial,
    activityList,
  }),

  fetchBookingActivity: (students, firebase) => ({
    type: actions.GET_BOOKING_ACTIVITY,
    students,
    firebase,
  }),

  addNewStudentToMeeting: (activity, students, firebase, selectedTimezone) => ({
    type: actions.ADD_NEW_STUDENT_TO_MEETING,
    activity,
    students,
    firebase,
    selectedTimezone,
  }),

  removeTrialStudent: (student, firebase) => ({
    type: actions.REMOVE_TRIAL_STUDENT,
    student,
    firebase,
  }),

  getMeetingReport: (startDate, firebase) => ({
    type: actions.GET_MEETING_REPORT,
    startDate,
    firebase,
  }),

  getMeetingActivity: (date, firebase) => ({
    type: actions.GET_MEETING_ACTIVITY,
    date,
    firebase,
  }),

  updateDefaultCapacity: (capacity, firebase) => ({
    type: actions.UPDATE_CAPACITY,
    capacity,
    firebase,
  }),

  getCustomLabels: (firebase, category) => ({
    type: actions.GET_CUSTOM_LABELS,
    firebase,
    category,
  }),

  deleteCustomLabel: (record, firebase) => ({
    type: actions.DELETE_CUSTOM_LABELS,
    record,
    firebase,
  }),

  addCustomLabel: (record, firebase) => ({
    type: actions.EDIT_CUSTOM_LABELS,
    record,
    firebase,
  }),

  addCustomLabelCategory: (val, label, firebase) => ({
    type: actions.ADD_CUSTOM_LABEL_CATEGORY,
    val,
    label,
    firebase,
  }),

  updateLastActivityList: (list, firebase) => ({
    type: actions.UPDATE_LAST_STUDENT_LEVEL_ACTIVITY,
    list,
    firebase,
  }),

  getActivityLabel: (firebase) => ({
    type: actions.GET_ACTIVITY_LABEL,
    firebase,
  }),

  approveAllActivities: (activities, firebase) => ({
    type: actions.APPROVE_ALL_ACTIVITY,
    activities,
    firebase,
  }),
  getAssessmentActivities: (activityIds, firebase) => ({
    type: actions.GET_ASSESSMENT_ACTIVITIES,
    activityIds,
    firebase
  })
};
export default actions;
