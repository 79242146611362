const actions = {
  LIST_INVOICE: "LIST_INVOICE",
  LIST_INVOICE_SUCCESSFULL: "LIST_INVOICE_SUCCESSFULL",
  GET_UPCOMMING_INVOICE_SUCCESS: "GET_UPCOMMING_INVOICE_SUCCESS",
  GET_INVOICE_AGGREGATED: "GET_INVOICE_AGGREGATED",
  GET_INVOICE_AGGREGATED_SUCCESSFULL: "GET_INVOICE_AGGREGATED_SUCCESSFULL",

  GET_INVOICE_DOWNLOAD_URL: "GET_INVOICE_DOWNLOAD_URL",
  GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL: "GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL",

  GET_STUDENT_FOR_INVOICE: "GET_STUDENT_FOR_INVOICE",
  GET_STUDENT_FOR_INVOICE_SUCCESSFUL: "GET_STUDENT_FOR_INVOICE_SUCCESSFUL",

  GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL: "GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL",

  ADD_NEW_INVOICE: "ADD_NEW_INVOICE",
  ADD_NEW_INVOICE_SUCCESSFUL: "ADD_NEW_INVOICE_SUCCESSFUL",

  UPDATE_SELECTED_INVOICE: "UPDATE_SELECTED_INVOICE",
  UPDATE_SELECTED_INVOICE_SUCCESSFFUL: "UPDATE_SELECTED_INVOICE_SUCCESSFFUL",

  RECEIVE_PAYMENT: "RECEIVE_PAYMENT",
  RECEIVE_PAYMENT_SUCCESSFUL: "RECEIVE_PAYMENT_SUCCESSFUL",

  DELETE_INVOICE: "DELETE_INVOICE",
  DELETE_INVOICE_SUCCESSFFUL: "DELETE_INVOICE_SUCCESSFFUL",

  RESET_INVOICE_URL: "RESET_INVOICE_URL",

  INVOICE_REQUEST_FAILED: "INVOICE_REQUEST_FAILED",

  GET_INVOICE_TEMPLATES: "GET_INVOICE_TEMPLATES",
  GET_INVOICE_TEMPLATES_SUCCESSFUL: "GET_INVOICE_TEMPLATES_SUCCESSFUL",

  DOWNLOAD_INVOICE_EXCEL_SHEET: "DOWNLOAD_INVOICE_EXCEL_SHEET",
  DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL: "DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL",

  DOWNLOAD_LINEITEM_EXCEL_SHEET: "DOWNLOAD_LINEITEM_EXCEL_SHEET",
  DOWNLOAD_LINEITEM_EXCEL_SHEET_SUCCESSFUL: "DOWNLOAD_LINEITEM_EXCEL_SHEET_SUCCESSFUL",

  SEND_PAYMENT_REMINDER: "SEND_PAYMENT_REMINDER",
  SEND_PAYMENT_REMINDER_SUCCESSFUL: "SEND_PAYMENT_REMINDER_SUCCESSFUL",

  DELETE_PAYMENT_RECORD: "DELETE_PAYMENT_RECORD",
  DELETE_PAYMENT_RECORD_SUCCESSFUL: "DELETE_PAYMENT_RECORD_SUCCESSFUL",

  REFUND_AMT: "REFUND_AMT",
  REFUND_AMT_SUCCESS: "REFUND_AMT_SUCCESS",

  GET_RECEIPT_LIST: "GET_RECEIPT_LIST",
  GET_RECEIPT_LIST_SUCCESS: "GET_RECEIPT_LIST_SUCCESS",

  GET_STUDENT_AGGREAGTED_INVOICES: "GET_STUDENT_AGGREAGTED_INVOICES",
  GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS: "GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS",

  GET_SINGLE_STUDENT_INVOICES: "GET_SINGLE_STUDENT_INVOICES",
  GET_SINGLE_STUDENT_INVOICES_SUCCESS: "GET_SINGLE_STUDENT_INVOICES_SUCCESS",


  REMOVE_REFUND: "REMOVE_REFUND",
  REMOVE_REFUND_SUCCESS: "REMOVE_REFUND_SUCCESS",

  REMOVE_ALL_REFUND: "REMOVE_ALL_REFUND",
  REMOVE_ALL_REFUND_SUCCESS: "REMOVE_ALL_REFUND_SUCCESS",

  SAVE_CREDIT: "SAVE_CREDIT",
  SAVE_CREDIT_SUCCESS: "SAVE_CREDIT_SUCCESS",
  SAVE_CREDIT_FAILED: "SAVE_CREDIT_FAILED",

  GET_CREDIT_HISTORY: "GET_CREDIT_HISTORY",
  GET_CREDIT_HISTORY_SUCCESS: "GET_CREDIT_HISTORY_SUCCESS",

  GET_ALL_CREDIT_HISTORY: "GET_ALL_CREDIT_HISTORY",
  GET_ALL_CREDIT_HISTORY_SUCCESS: "GET_ALL_CREDIT_HISTORY_SUCCESS",

  DOWNLOAD_ALL_CREDIT_HISTORY: "DOWNLOAD_ALL_CREDIT_HISTORY",
  DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS: "DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS",
  DOWNLOAD_ALL_CREDIT_HISTORY_FAILED: "DOWNLOAD_ALL_CREDIT_HISTORY_FAILED",

  DELETE_CREDIT: "DELETE_CREDIT",
  DELETE_CREDIT_SUCCESS: "DELETE_CREDIT_SUCCESS",
  DELETE_CREDIT_DENIED: "DELETE_CREDIT_DENIED",

  GET_STUDENT_ONLINE_CLASS_INVOICE: "GET_STUDENT_ONLINE_CLASS_INVOICE",
  GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS: "GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS",

  GENERATE_PAYMENT_TRANSACTION_ID: "GENERATE_PAYMENT_TRANSACTION_ID",
  GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS: "GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS",

  SETTLE_PAYMENT: "SETTLE_PAYMENT",
  SETTLE_PAYMENT_SUCCESS: "SETTLE_PAYMENT_SUCCESS",

  UPDATED_FAILED_PAYMENT: "UPDATED_FAILED_PAYMENT",
  UPDATED_FAILED_PAYMENT_SUCCESS: "UPDATED_FAILED_PAYMENT_SUCCESS",

  GET_ONLINE_CLASS_PLAN_DETAIL: "GET_ONLINE_CLASS_PLAN_DETAIL",
  GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS: "GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS",

  SAVE_CARD_DETAILS: "SAVE_CARD_DETAILS",

  FETCH_CARD_DETAILS: "FETCH_CARD_DETAILS",
  FETCH_CARD_DETAILS_SUCCESS: "FETCH_CARD_DETAILS_SUCCESS",

  AUTO_CHARGE_PAYMENT: "AUTO_CHARGE_PAYMENT",
  AUTO_CHARGE_PAYMENT_SUCCESS: "AUTO_CHARGE_PAYMENT_SUCCESS",

  GET_STUDENTS_FEE_PLAN: "GET_STUDENTS_FEE_PLAN",
  GET_STUDENTS_FEE_PLAN_SUCCESS: "GET_STUDENTS_FEE_PLAN_SUCCESS",

  GET_TAX_REF_SUCCESS: "GET_TAX_REF_SUCCESS",

  REFRESH_PDF: "REFRESH_PDF",
  REFRESH_PDF_SUCCESS: "REFRESH_PDF_SUCCESS",

  GET_CREDIT_PDF: "GET_CREDIT_PDF",
  GET_CREDIT_PDF_SUCCESS: "GET_CREDIT_PDF_SUCCESS",

  GET_DEPOSIT_PDF: "GET_DEPOSIT_PDF",
  GET_DEPOSIT_PDF_SUCCESS: "GET_DEPOSIT_PDF_SUCCESS",

  SAVE_DEPOSIT: "SAVE_DEPOSIT",
  SAVE_DEPOSIT_SUCCESS: "SAVE_DEPOSIT_SUCCESS",

  GET_ALL_DEPOSIT_HISTORY: "GET_ALL_DEPOSIT_HISTORY",
  GET_ALL_DEPOSIT_HISTORY_SUCCESS: "GET_ALL_DEPOSIT_HISTORY_SUCCESS",

  DELETE_DEPOSIT: "DELETE_DEPOSIT",
  DELETE_DEPOSIT_SUCCESS: "DELETE_DEPOSIT_SUCCESS",
  DELETE_DEPOSIT_DENIED: "DELETE_DEPOSIT_DENIED",

  DOWNLOAD_ALL_DEPOSIT_HISTORY: "DOWNLOAD_ALL_DEPOSIT_HISTORY",
  DOWNLOAD_ALL_DEPOSIT_HISTORY_SUCCESS: "DOWNLOAD_ALL_DEPOSIT_HISTORY_SUCCESS",
  DOWNLOAD_ALL_DEPOSIT_HISTORY_FAILED: "DOWNLOAD_ALL_DEPOSIT_HISTORY_FAILED",

  GET_DEPOSIT_HISTORY: "GET_DEPOSIT_HISTORY",
  GET_DEPOSIT_HISTORY_SUCCESS: "GET_DEPOSIT_HISTORY_SUCCESS",

  REFUND_DEPOSIT: "REFUND_DEPOSIT",
  REFUND_DEPOSIT_SUCCESS: "REFUND_DEPOSIT_SUCCESS",

  DEPOSIT_REQUEST_FAILED: "DEPOSIT_REQUEST_FAILED",

  TRANSFER_CREDIT: "TRANSFER_CREDIT",
  TRANSFER_CREDIT_SUCCESS: "TRANSFER_CREDIT_SUCCESS",
  TRANSFER_CREDIT_FAILED: "TRANSFER_CREDIT_FAILED",

  UPDATE_FEE_REMINDER: "UPDATE_FEE_REMINDER",

  SETTLE_INVOICE_WITH_DEPOSIT: "SETTLE_INVOICE_WITH_DEPOSIT",
  SETTLE_INVOICE_WITH_DEPOSIT_SUCCESS: "SETTLE_INVOICE_WITH_DEPOSIT_SUCCESS",

  SEND_PAYMENT_REMINDER_TO_ALL: "SEND_PAYMENT_REMINDER_TO_ALL",
  SEND_PAYMENT_REMINDER_TO_ALL_SUCCESS: "SEND_PAYMENT_REMINDER_TO_ALL_SUCCESS",

  EDIT_FEE_REMINDER: "EDIT_FEE_REMINDER",
  EDIT_FEE_REMINDER_SUCCESS: "EDIT_FEE_REMINDER_SUCCESS",

  FETCH_TAX_REF_LIST: "FETCH_TAX_REF_LIST",
  GET_UPCOMMING_INVOICE: "GET_UPCOMMING_INVOICE",
  GET_PAYMENT_METHOD: "GET_PAYMENT_METHOD",
  GET_PAYMENT_METHOD_SUCCESS: "GET_PAYMENT_METHOD_SUCCESS",
  RESET_UPCOMMING_INVOICE: "RESET_UPCOMMING_INVOICE",
  DELETE_CREDIT_DEPOSIT: "DELETE_CREDIT_DEPOSIT",
  DELETE_CREDIT_DEPOSIT_SUCCESS: "DELETE_CREDIT_DEPOSIT_SUCCESS",
  DELETE_CREDIT_DEPOSIT_FAILURE: "DELETE_CREDIT_DEPOSIT_FAILURE",
  IGNORE_LATE_FEE: "IGNORE_LATE_FEE",
  IGNORE_LATE_FEE_SUCCESS: "IGNORE_LATE_FEE_SUCCESS",
  IGNORE_LATE_FEE_FAILURE: "IGNORE_LATE_FEE_FAILURE",
  GET_INV_FORM_DATA: "GET_INV_FORM_DATA",
  SAVE_INVOICE_NOTES: "SAVE_INVOICE_NOTES",
  SAVE_INVOICE_NOTES_SUCCESS: "SAVE_INVOICE_NOTES_SUCCESS",
  SAVE_DEPOSIT_FAILED: "SAVE_DEPOSIT_FAILED",
  RESET_INVOICE_OPERATION_TYPE: "RESET_INVOICE_OPERATION_TYPE",

  GET_NEW_INVOICE_DASHBOARD_STATS: "GET_NEW_INVOICE_DASHBOARD_STATS",
  START_INVOICE_DASHBOARD_SPINNER: "START_INVOICE_DASHBOARD_SPINNER",
  STOP_INVOICE_DASHBOARD_SPINNER: "STOP_INVOICE_DASHBOARD_SPINNER",
  NEW_DASHBOARD_TOTAL_INVOICE: "NEW_DASHBOARD_TOTAL_INVOICE",
  NEW_DASHBOARD_TOTAL_COLLECTED_COUNT: "NEW_DASHBOARD_TOTAL_COLLECTED_COUNT",
  NEW_DASHBOARD_TOTAL_OVERDUE_COUNT: "NEW_DASHBOARD_TOTAL_OVERDUE_COUNT",
  NEW_DASHBOARD_TOTAL_OUTSTANDING_COUNT: "NEW_DASHBOARD_TOTAL_OUTSTANDING_COUNT",
  NEW_DASHBOARD_CREATED_TODAY: "NEW_DASHBOARD_CREATED_TODAY",
  NEW_DASHBOARD_MONTH_ON_MONTH: "NEW_DASHBOARD_MONTH_ON_MONTH",
  NEW_DASHBOARD_AGEING_REPORT: "NEW_DASHBOARD_AGEING_REPORT",
  NEW_DASHBOARD_RECENT_TRANSACTIONS: "NEW_DASHBOARD_RECENT_TRANSACTIONS",
  NEW_DASHBOARD_RECENT_DUE: "NEW_DASHBOARD_RECENT_DUE",

  ignoreLateFee: (firebase, invoice) => ({
    type: actions.IGNORE_LATE_FEE,
    firebase,
    invoice,
  }),

  RESEND_INVOICE_EMAIL: "RESEND_INVOICE_EMAIL",
  RESEND_INVOICE_EMAIL_SUCCESS: "RESEND_INVOICE_EMAIL_SUCCESS",

  GET_PAYMENTS_DATA: "GET_PAYMENTS_DATA",
  GET_PAYMENTS_DATA_SUCCESS: "GET_PAYMENTS_DATA_SUCCESS",

  getUpcommingInvoice: (firebase, studentId, feePlanId) => ({
    type: actions.GET_UPCOMMING_INVOICE,
    firebase,
    studentId,
    feePlanId,
  }),

  saveNotes: (values, firebase, invoiceId, studentId, selectedInvoice, sendEmailToParents) => ({
    type: actions.SAVE_INVOICE_NOTES,
    values,
    firebase,
    invoiceId,
    studentId,
    selectedInvoice,
    sendEmailToParents,
  }),

  initInvoiceData: (firebase) => ({
    type: actions.LIST_INVOICE,
    firebase,
  }),

  editFeeReminder: (firebase) => ({
    type: actions.EDIT_FEE_REMINDER,
    firebase,
  }),

  updateFeeReminder: (updatedEmail, firebase) => ({
    type: actions.UPDATE_FEE_REMINDER,
    updatedEmail,
    firebase,
  }),
  getPaymentMethod: (studentId, firebase) => ({
    type: actions.GET_PAYMENT_METHOD,
    studentId,
    firebase,
  }),
  deleteCreditDeposit: (deleteType, firebase, record, studentId, currentAmount) => ({
    type: actions.DELETE_CREDIT_DEPOSIT,
    deleteType,
    studentId,
    firebase,
    record,
    currentAmount
  }),

  getInvoiceAggregated: (firebase) => ({
    type: actions.GET_INVOICE_AGGREGATED,
    firebase,
  }),

  getInvoiceDownloadUrl: (record, firebase, userType) => ({
    type: actions.GET_INVOICE_DOWNLOAD_URL,
    record,
    firebase,
    userType,
  }),

  fetchStudentData: (firebase) => ({
    type: actions.GET_STUDENT_FOR_INVOICE,
    firebase,
  }),

  addNewInvoice: (
    values,
    firebase,
    rows,
    selectedStudentCheckbox,
    autoChargeChecked,
    invDiscounts,
    invTaxes,
    settleUsingCredit,
    creditMap,
    sendInvoiceEmail,
    invoiceStudentId,
    sendPushNotification

  ) => (console.log("ac", invoiceStudentId), {
    type: actions.ADD_NEW_INVOICE,
    values,
    firebase,
    rows,
    selectedStudentCheckbox,
    autoChargeChecked,
    invDiscounts,
    invTaxes,
    settleUsingCredit,
    creditMap,
    sendInvoiceEmail,
    invoiceStudentId,
    sendPushNotification

  }),

  updateInvoice: (
    values,
    firebase,
    rows,
    studentId,
    invoiceId,
    invDiscounts,
    invTaxes,
    sendInvoiceEmail,
    invoiceToEdit,
    sendPushNotification

  ) => ({
    type: actions.UPDATE_SELECTED_INVOICE,
    values,
    firebase,
    rows,
    studentId,
    invoiceId,
    invDiscounts,
    invTaxes,
    sendInvoiceEmail,
    invoiceToEdit,
    sendPushNotification

  }),

  addPayment: (values, selectedInvoice, firebase, sendRecordEmail) => ({
    type: actions.RECEIVE_PAYMENT,
    values,
    selectedInvoice,
    firebase,
    sendRecordEmail,
  }),

  deleteInvoice: (invoiceRecord, firebase) => ({
    type: actions.DELETE_INVOICE,
    invoiceRecord,
    firebase,
  }),

  resetInvoiceUrl: (firebase) => ({
    type: actions.RESET_INVOICE_URL,
    firebase,
  }),

  getAllInvoiceTemplates: (firebase) => ({
    type: actions.GET_INVOICE_TEMPLATES,
    firebase,
  }),

  downloadExcelSheet: (formValue, firebase, downloadType) => ({
    type: actions.DOWNLOAD_INVOICE_EXCEL_SHEET,
    formValue,
    firebase,
    downloadType,
  }),

  downloadLineItemExcelSheet: (formValue, firebase, downloadType) => ({
    type: actions.DOWNLOAD_LINEITEM_EXCEL_SHEET,
    formValue,
    firebase,
    downloadType,
  }),

  sendPaymentReminder: (email, sms, invoice, firebase) => ({
    type: actions.SEND_PAYMENT_REMINDER,
    email,
    sms,
    invoice,
    firebase,
  }),
  sendPaymentReminderToAll: (email, sms, invoiceList, firebase, reminderStatus) => ({
    type: actions.SEND_PAYMENT_REMINDER_TO_ALL,
    email,
    sms,
    invoiceList,
    firebase,
    reminderStatus,
  }),

  deletePaymentRecord: (lineItem, invoice, firebase, addCredit, addDeposit) => ({
    type: actions.DELETE_PAYMENT_RECORD,
    lineItem,
    invoice,
    firebase,
    addCredit,
    addDeposit
  }),

  refundPayment: (values, selectedInvoice, firebase) => ({
    type: actions.REFUND_AMT,
    values,
    selectedInvoice,
    firebase,
  }),

  getReceiptList: (record, firebase) => ({
    type: actions.GET_RECEIPT_LIST,
    record,
    firebase,
  }),

  getAggregatedInvoiceDetail: (startDate, endDate, firebase, initialCall) => ({
    type: actions.GET_STUDENT_AGGREAGTED_INVOICES,
    startDate,
    endDate,
    firebase,
    initialCall,
  }),

  getStudentInvoices: (studentId, firebase) => ({
    type: actions.GET_SINGLE_STUDENT_INVOICES,
    studentId,
    firebase,
  }),



  removeRefund: (record, index, invoice, firebase) => ({
    type: actions.REMOVE_REFUND,
    record,
    index,
    invoice,
    firebase,
  }),

  removeAllRefund: (invoice, firebase) => ({
    type: actions.REMOVE_ALL_REFUND,
    invoice,
    firebase,
  }),

  saveCredit: (amount, students, firebase, approvedBy, reason, sendCreditEmail) => ({
    type: actions.SAVE_CREDIT,
    amount,
    students,
    firebase,
    approvedBy,
    reason,
    sendCreditEmail,
  }),

  getCreditHistory: (studentId, firebase) => ({
    type: actions.GET_CREDIT_HISTORY,
    studentId,
    firebase,
  }),

  getAllCreditHistory: (firebase) => ({
    type: actions.GET_ALL_CREDIT_HISTORY,
    firebase,
  }),

  downloadAllCreditHistory: (creditHistory, firebase) => ({
    type: actions.DOWNLOAD_ALL_CREDIT_HISTORY,
    creditHistory,
    firebase,
  }),

  deleteCredit: (record, firebase, currentAmount) => ({
    type: actions.DELETE_CREDIT,
    record,
    firebase,
    currentAmount
  }),

  getStudentOnlineClassInvoice: (studentId, firebase) => ({
    type: actions.GET_STUDENT_ONLINE_CLASS_INVOICE,
    studentId,
    firebase,
  }),

  generateTransactionId: (
    actualTransferAmount,
    amount,
    invoiceId,
    studentId,
    userParentId,
    firebase
  ) => ({
    type: actions.GENERATE_PAYMENT_TRANSACTION_ID,
    actualTransferAmount,
    amount,
    invoiceId,
    studentId,
    userParentId,
    firebase,
  }),

  paymentSuccessful: (invoice, paymentMode, parentName, firebase) => ({
    type: actions.SETTLE_PAYMENT,
    invoice,
    paymentMode,
    parentName,
    firebase,
  }),

  paymentFailed: (invoice, parentName, firebase) => ({
    type: actions.UPDATED_FAILED_PAYMENT,
    invoice,
    parentName,
    firebase,
  }),

  getPlanDetail: (feePlanId, firebase) => ({
    type: actions.GET_ONLINE_CLASS_PLAN_DETAIL,
    feePlanId,
    firebase,
  }),

  saveCardDetails: (record, firebase, country) => ({
    type: actions.SAVE_CARD_DETAILS,
    record,
    firebase,
    country,
  }),

  fetchCardDetail: (studentId, firebase) => ({
    type: actions.FETCH_CARD_DETAILS,
    studentId,
    firebase,
  }),

  autoChargePayment: (invoice, firebase) => ({
    type: actions.AUTO_CHARGE_PAYMENT,
    invoice,
    firebase,
  }),

  fetchStudentsFeePlan: (firebase) => ({
    type: actions.GET_STUDENTS_FEE_PLAN,
    firebase,
  }),

  refreshPdf: (invoice, firebase) => ({
    type: actions.REFRESH_PDF,
    invoice,
    firebase,
  }),

  getCreditPdf: (record, firebase) => ({
    type: actions.GET_CREDIT_PDF,
    record,
    firebase,
  }),

  getDepositPdf: (record, firebase) => ({
    type: actions.GET_DEPOSIT_PDF,
    record,
    firebase,
  }),

  saveDeposit: (
    amount,
    students,
    firebase,

    reason,
    sendDepositEmail,
    approvedBy
  ) => ({
    type: actions.SAVE_DEPOSIT,
    amount,
    students,
    firebase,
    approvedBy,
    reason,
    sendDepositEmail,
  }),

  getAllDepositHistory: (firebase) => ({
    type: actions.GET_ALL_DEPOSIT_HISTORY,
    firebase,
  }),

  deleteDeposit: (record, firebase, currentDepositAmount) => ({
    type: actions.DELETE_DEPOSIT,
    record,
    firebase,
    currentDepositAmount
  }),

  downloadAllDepositHistory: (depositHistory, firebase) => ({
    type: actions.DOWNLOAD_ALL_DEPOSIT_HISTORY,
    depositHistory,
    firebase,
  }),

  getDepositHistory: (studentId, firebase) => ({
    type: actions.GET_DEPOSIT_HISTORY,
    studentId,
    firebase,
  }),

  refundDeposit: (record, firebase, currentDepositAmount) => ({
    type: actions.REFUND_DEPOSIT,
    record,
    firebase,
    currentDepositAmount
  }),

  transferToCredit: (record, firebase, currentDepositAmount) => ({
    type: actions.TRANSFER_CREDIT,
    record,
    firebase,
    currentDepositAmount
  }),

  settleInvoiceWithDeposit: (deposit, invoice, firebase, currentDepositAmount, sendEmail) => ({
    type: actions.SETTLE_INVOICE_WITH_DEPOSIT,
    deposit,
    invoice,
    firebase,
    currentDepositAmount,
    sendEmail
  }),

  fetchTaxRefList: (firebase) => ({
    type: actions.FETCH_TAX_REF_LIST,
    firebase,
  }),

  formInvoiceData: (invoiceId, studentId, firebase) => ({
    type: actions.GET_INV_FORM_DATA,
    invoiceId,
    studentId,
    firebase,
  }),

  resendInvoiceEmail: (obj, firebase) => ({
    type: actions.RESEND_INVOICE_EMAIL,
    obj,
    firebase,
  }),

  getNewInvoiceDashboardStats: (start, end, firebase, category) => ({
    type: actions.GET_NEW_INVOICE_DASHBOARD_STATS,
    start,
    end,
    firebase,
    category,
  }),

  startDashboardSpinner: () => ({
    type: actions.START_INVOICE_DASHBOARD_SPINNER,
  }),

  resetInvoiceOperationType: () => ({
    type: actions.RESET_INVOICE_OPERATION_TYPE,
  }),

  getPaymentsData: (startDate, endDate, firebase) => ({
    type: actions.GET_PAYMENTS_DATA,
    startDate,
    endDate,
    firebase,
  }),
};

export default actions;
