import { getDefaultPath } from "../../helpers/urlSync";
import actions, { getView } from "./actions";

// const preKeys = getDefaultPath();

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  // openKeys: preKeys,
  // current: preKeys,
  openKeys: ["connect"],
  current: ["connect"],
  branchName: null,
  attachmentProgress: undefined,
  allFilesUploaded: false,
  branchChangeLoader: false,
  sidebarOptionColor: null,
  onMouseHoverToggle: "NO",
};
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      if (!state.collapsed && state.onMouseHoverToggle == "OPEN") {
        return {
          ...state,
          collapsed: !state.collapsed,
          openDrawer: false,
          onMouseHoverToggle: "NO",
        };
      }
      if (!state.collapsed) {
        return {
          ...state,
          collapsed: true,
          openDrawer: false,
        };
      }
      return {
        ...state,
        collapsed: !state.collapsed,
        openDrawer: true,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      if (state.collapsed && state.onMouseHoverToggle == "NO") {
        return {
          ...state,
          collapsed: !state.collapsed,
          openDrawer: !state.openDrawer,
          onMouseHoverToggle: "OPEN",
        };
      }
      if (state.onMouseHoverToggle == "NO") {
        return { ...state };
      }
      return {
        ...state,
        collapsed: !state.collapsed,
        openDrawer: !state.openDrawer,
        onMouseHoverToggle: "NO",
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height: height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CLOSE_ALL:
      return {
        ...state,
        current: [],
        openKeys: [],
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    case actions.SWITCH_BRANCH_NAME:
      return {
        ...state,
        branchName: action.branchName,
      };

    case actions.ATTACHHMENT_UPLOAD_PROGRESS:
      return {
        ...state,
        attachmentProgress: action.attachmentProgress,
        allFilesUploaded: action.allFilesUploaded,
      };

    case actions.RESET_ATTACHMENT_PROGRESS:
      return {
        ...state,
        attachmentProgress: undefined,
        allFilesUploaded: false,
      };

    case actions.INIT_BRANCH_CHANGE_LOADER:
      return {
        ...state,
        branchChangeLoader: true,
      };

    case actions.STOP_BRANCH_CHANGE_LOADER:
      return {
        ...state,
        branchChangeLoader: false,
      };
    case actions.SIDEBAR_OPTION_COLOR:
      return {
        ...state,
        sidebarOptionColor: action.currentOptionSelected,
      };
    default:
      return state;
  }
  return state;
}
