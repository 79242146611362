const actions = {
  LIST_TAGS: "LIST_TAGS",
  LIST_TAGS_SUCCESSFUL: "LIST_TAGS_SUCCESSFUL",
  ADD_TAG: "ADD_TAG",
  ADD_TAG_SUCCESSFUL: "ADD_TAG_SUCCESSFUL",
  UPDATE_TAG: "UPDATE_TAG",
  UPDATE_TAG_SUCCESSFUL: "UPDATE_TAG_SUCCESSFUL",
  TAG_REQUEST_FAILED: "TAG_REQUEST_FAILED",
  GET_STUDENT_FOR_TAG: "GET_STUDENT_FOR_TAG",
  GET_STUDENT_FOR_TAG_SUCCESSFUL: "GET_STUDENT_FOR_TAG_SUCCESSFUL",
  ASSIGN_STUDENT_TO_TAG: "ASSIGN_STUDENT_TO_TAG",
  ASSIGN_STUDENT_TO_TAG_SUCCESSFUL: "ASSIGN_STUDENT_TO_TAG_SUCCESSFUL",
  GET_TAG_CLASSES: "GET_TAG_CLASSES",
  GET_TAG_CLASSES_SUCCESSFUL: "GET_TAG_CLASSES_SUCCESSFUL",
  DELETE_TAG: "DELETE_TAG",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  RESET_TAG_OPERATION: "RESET_TAG_OPERATION",
  START_TAG_LOADER: "START_TAG_LOADER",
  DELETE_STUDENT_ASSIGNED_GROUP: "DELETE_STUDENT_ASSIGNED_GROUP",

  initTagData: (firebase) => ({
    type: actions.LIST_TAGS,
    firebase,
  }),

  addTag: (value, firebase) => ({
    type: actions.ADD_TAG,
    value,
    firebase,
  }),

  deleteStudentAssignedGroup: (firebase, group) => ({
    type: actions.DELETE_STUDENT_ASSIGNED_GROUP,
    firebase,
    group,
  }),

  updateTag: (value, record, firebase) => ({
    type: actions.UPDATE_TAG,
    value,
    record,
    firebase,
  }),

  getStudentsToAssignTag: (firebase) => ({
    type: actions.GET_STUDENT_FOR_TAG,
    firebase,
  }),

  assignStudentToTag: (editableRecord, selectedStudentCheckbox, firebase, existingStudents) => ({
    type: actions.ASSIGN_STUDENT_TO_TAG,
    editableRecord,
    selectedStudentCheckbox,
    firebase,
    existingStudents,
  }),

  getClassroomForTag: (firebase) => ({
    type: actions.GET_TAG_CLASSES,
    firebase,
  }),

  deleteGroup: (record, firebase) => ({
    type: actions.DELETE_TAG,
    record,
    firebase,
  }),

  resetTagOperation: () => ({
    type: actions.RESET_TAG_OPERATION,
  }),
};
export default actions;
