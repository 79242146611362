import { getItem, setItem } from "./Utility/encryptedStorage";
export default function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then((res) => {
        setItem("page-has-been-force-refreshed", "no");
        resolve(res);
      })
      .catch((error) => {
        console.log("componentLoader exception", error);
        let pageHasAlreadyBeenForceRefreshed = getItem("page-has-been-force-refreshed");

        if (!pageHasAlreadyBeenForceRefreshed) {
          setItem("page-has-been-force-refreshed", "yes");
          return window.location.reload();
        }
        reject(error);
      });
  });
}

// export default function componentLoader(lazyComponent, attemptsLeft) {
//   return new Promise((resolve, reject) => {
//     lazyComponent()
//       .then(resolve)
//       .catch((error) => {
//         // let us retry after 00 ms
//         setTimeout(() => {
//           if (attemptsLeft === 1) {
//             reject(error);
//             return;
//           }
//           componentLoader(lazyComponent, attemptsLeft - 1).then(
//             resolve,
//             reject
//           );
//         }, 0);
//       });
//   });
// }
