const actions = {
  REQUEST_REPORT: "REQUEST_REPORT",
  REQUEST_REPORT_SUCCESSFUL: "REQUEST_REPORT_SUCCESSFUL",

  submitReportRequest: (values, firebase) => ({
    type: actions.REQUEST_REPORT,
    values,
    firebase,
  }),
};
export default actions;
