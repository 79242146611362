import actions from "./actions";

const initState = {
  // milestones: [],
  milestones: [],
  developmentAreas: [],
  developmentAreasMap: new Map(),
  lessons: [],
  isLoading: false,
  labels: [],
  categories: [],
  classrooms: [],
  weeklyPlan: [],
  students: [],
  weeklyPlanLessonChannel: undefined,
  saveMilestoneDomainSuccessful: false,
  saveMilestoneSuccessful: false,
  addLabelSuccessful: false,
  updateMilestoneDomainSuccessful: false,
  updateMilestoneSuccessful: false,
  updateLessonSuccessful: false,
  error: false,
  addCategorySuccessful: false,
  addLessonSuccessful: false,
  addWeeklyPlanSuccessful: false,
  learningMilestoneChannel: undefined,
  learningLessonChannel: undefined,
  learningOperationType: "",
  labelChannel: undefined,
  feedbackMessage: undefined,
  uploadStatus: undefined,
  assessmentProgressParams: [],
  shareHistoryMap: new Map(),
  planComment: new Map(),
  planSubmission: new Map(),
  developmentAreasChannel: undefined,
  subDevelopmentArea: [],
  subDevelopmentAreaChannel: undefined,
  isDevLoading: false,
  saveFormSuccessful: false,
  assessmentTemplates: [],
  loadingAssessmentTemplates: false,
  assessmentTemplatesChannel: undefined,

  selectedDevelopmentAreas: [],
  selectedSubDevelopmentAreas: [],
  selectedMilestones: [],
  selectedDevelopmentAreasLoader: false,

  assessmentTemplateFormData: "",
  assessmentTemplateData: {},
  templateDataLoading: false,
  isClone: false,
  attachmentsLoader: false,
  observationAttachments: [],
  observationDataLoading: false,
  observationData: [],
  studentsAssessmentsData: [],
  progessValues: [],
  progressNameChannel: undefined,
  statusValues: [],
  defaultStatus: '',
  progressStatusChannel: undefined,
  ages: [],
  terminologyTerms: [],
  terminologyTermsChannel: undefined,
  studentObservation: [],
  developmentAreaData: {},
  // developmentAreasNamebyId: new Map(), 
  // statusNameById: new Map()
  developmentAreasNamebyId: [],
  developmentAreasChannel: undefined,
  statusNameById: [],
  developmentAreasProgressStatus: [],
  developmentAreaProgressStatusChannel: undefined,
  studentAssessmentById: {},
  assessmentLoader: false,
  assesmentSaved: false,
  progressDataLoading: false,
  selectedFormData: {},
  viewObservationsByStd: [],
  progressData: [],
  progressDataChannel: undefined,
  observationComment: new Map(),
  savedSuccess: false,
  studentProgressTracker: [],
  studentProgressTrackerLoading: false,
  savedSuccess: false,
  templateCreateSuccess: false,
  templateFormSubmitting: false,
  observationComment: new Map(),
  isLoadingStdObs: false,
  studentObservationData: [],
  studentReflectionData: [],
  studentConcernData: [],
  profileAssessmentDataLoading: false,
  studentObservationBydevarea: [],
  isNoteLoading: false,
  observationFormLoader: false,
  areaDeleted: "",
  areaEdited: "",
  templateDeleted: false,
  pdfTemplateLoader: false,
  pdfStyle: undefined,
  assessmentId: undefined,
  areaDeleted: "",
  areaEdited: "",
  templateDeleted: false,
  migrateDevelopmentAreaData: {},
  assessmentFrameworkLoading: false,
  migrateSuccessfullOrFailure: false,
  ages: [],
  lessonAdditionalFields: [],
  saveLessonSuccessful: false,
  assessmentTerminology: {},
  suggestionLoader: false,
  staffNoteSuggestions: [],
  paraphraseText: "",
  paraphraseLoader: false,
  studentAttendanceData: [],
  devareaForStudentAssessment: {},
  progressReportData: [],
  progressReportLoader: false,
  assessmentReportData: [],
  comparsionReport: [],
  deleteOperationType: "",
};
export default function learningReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.LIST_MILESTONES:
      return {
        ...state,
      };
    case actions.LIST_MILESTONES_SUCCESSFUL:
      return {
        ...state,
        milestones: action.milestones,
        learningMilestoneChannel: action.learningMilestoneChannel,
        ages: action.ages ? action.ages : state.ages
      };

    case actions.LIST_DEV_AREA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LIST_DEV_AREA_SUCCESS:
      return {
        ...state,
        developmentAreas: action.developmentAreas,
        developmentAreasChannel: action.developmentAreasChannel,
        isLoading: false
      };
    case actions.LIST_SUB_DEV_AREA_SUCCESS:
      return {
        ...state,
        subDevelopmentArea: action.subDevelopmentArea,
        subDevelopmentAreaChannel: action.subDevelopmentAreaChannel,
        isLoading: false
      };

    case actions.LIST_LESSONS:
      return {
        ...state,
        isLoading: true,
        learningOperationType: "",
        uploadStatus: undefined,
      };
    case actions.LIST_LESSONS_SUCCESSFUL:
      return {
        ...state,
        lessons: action.lessons,
        isLoading: false,
        learningOperationType: action.learningOperationType,
      };
    case actions.SAVE_MILESTONE_DOMAIN:
      return {
        ...state,
        isLoading: true,
        saveMilestoneDomainSuccessful: false,
      };
    case actions.SAVE_MILESTONE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        saveMilestoneDomainSuccessful: true,
        learningOperationType: action.areaEdited === "dev" ? "" : "SAVE_MILESTONE_DOMAIN",
        areaEdited: action.areaEdited,
      };

    case actions.ADD_SUB_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_SUB_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: action.areaEdited === "subdev" ? "" : "SAVE_MILESTONE_SUB_DOMAIN",
        areaEdited: action.areaEdited,
      };

    case actions.UPDATE_SUB_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SUB_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "SAVE_MILESTONE_SUB_DOMAIN",
      };

    case actions.SAVE_MILESTONE:
      return {
        ...state,
        isLoading: true,
        saveMilestoneSuccessful: false,
      };
    case actions.SAVE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        saveMilestoneSuccessful: true,
        learningOperationType: action.areaEdited === "milestone" ? "" : "SAVE_MILESTONE",
        areaEdited: action.areaEdited,
      };
    case actions.ADD_LABEL:
      return {
        ...state,
        isLoading: true,
        addLabelSuccessful: false,
      };
    case actions.ADD_LABEL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addLabelSuccessful: true,
        learningOperationType: "ADD_LABEL",
      };
    case actions.GET_LABEL:
      return {
        ...state,
      };
    case actions.GET_LABEL_SUCCESSFUL:
      return {
        ...state,
        labels: action.labels,
        labelChannel: action.labelChannel,
      };
    case actions.UPDATE_MILESTONE_DOMAIN:
      return {
        ...state,
        isLoading: true,
        updateMilestoneDomainSuccessful: false,
      };
    case actions.UPDATE_MILESTONE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateMilestoneDomainSuccessful: true,
        learningOperationType: "UPDATE_MILESTONE_DOMAIN",
      };
    case actions.UPDATE_MILESTONE:
      return {
        ...state,
        isLoading: true,
        updateMilestoneSuccessful: false,
      };
    case actions.UPDATE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateMilestoneSuccessful: true,
        learningOperationType: "UPDATE_MILESTONE",
      };
    case actions.LEARNING_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        uploadStatus: "failed",
        observationDataLoading: false,
        studentProgressTrackerLoading: false,
        profileAssessmentDataLoading: false
      };
    case actions.ADD_CATEGORY:
      return {
        ...state,
        isLoading: true,
        addCategorySuccessful: false,
      };
    case actions.ADD_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addCategorySuccessful: true,
      };
    case actions.GET_CATEGORY:
      return {
        ...state,
      };
    case actions.GET_CATEGORY_SUCCESSFUL:
      return {
        ...state,
        categories: action.categories,
      };
    case actions.GET_LEARNING_CLASSROOM:
      return {
        ...state,
      };
    case actions.GET_LEARNING_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
      };
    case actions.ADD_LESSON:
      return {
        ...state,
        isLoading: true,
        addLessonSuccessful: false,
      };
    case actions.ADD_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addLessonSuccessful: true,
        learningOperationType: "ADD_LESSON",
        saveLessonSuccessful: true,
      };

    case actions.ADD_LESSON_TO_ALL_CENTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "ADD_LESSON_TO_ALL_CENTER",
        saveLessonSuccessful: true,
      };
    case actions.UPDATE_LESSON:
      return {
        ...state,
        isLoading: true,
        updateLessonSuccessful: false,
      };
    case actions.UPDATE_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        updateLessonSuccessful: true,
        learningOperationType: "UPDATE_LESSON",
      };

    case actions.UPDATE_LESSON_TO_ALL_CENTERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "UPDATE_LESSON_TO_ALL_CENTER",
      };

    case actions.ADD_WEEKLY_PLAN:
      return {
        ...state,
        isLoading: true,
        addWeeklyPlanSuccessful: false,
      };
    case actions.ADD_WEEKLY_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        addWeeklyPlanSuccessful: true,
        learningOperationType: "ADD_WEEKLY_PLAN",
      };
    case actions.GET_WEEKLY_PLAN:
      return {
        ...state,
        isLoading: true,
        learningOperationType: "",
        weeklyPlan: [],
      };
    case actions.GET_WEEKLY_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        weeklyPlan: action.weeklyPlan,
        weeklyPlanLessonChannel: action.weeklyPlanLessonChannel,
        learningOperationType: "WEEKLY_PLAN_FETCHED",
      };
    case actions.DELETE_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_LESSON_RECORD:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_LESSON_RECORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "DELETE_LESSON_TO_SINGLE_CENTER",
        saveLessonSuccessful: true,
      };

    case actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "DELETE_LESSON_TO_ALL_CENTER",
      };
    case actions.DELETE_SINGLE_MILESTONE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        areaDeleted: action.area,
      };
    case actions.DELETE_SINGLE_DOMAIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SINGLE_DOMAIN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        areaDeleted: action.areaDeleted
      };

    case actions.RESET_LEARNING_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        error: false,
        learningOperationType: "",
        feedbackMessage: undefined,
        errorMessage: undefined,
      };

    case actions.GET_STUDENT_FOR_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_FOR_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        students: action.students,
      };

    case actions.SHARE_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SHARE_LESSON_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "LESSON_SHARED",
      };

    case actions.UPLOAD_LESSON_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPLOAD_LESSON_DATA_SUCCESSFUl:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "LESSON_UPLOADED",
        feedbackMessage: action.feedbackMessage,
      };

    case actions.UPLOAD_START:
      return {
        ...state,
        uploadStatus: "started",
      };

    case actions.UPLOAD_FINISH:
      return {
        ...state,
        uploadStatus: "finished",
      };

    case actions.UPLOAD_FAILED:
      return {
        ...state,
        uploadStatus: "failed",
      };

    case actions.UPLOAD_RESET:
      return {
        ...state,
        uploadStatus: undefined,
      };

    case actions.UPDATE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "CATEGORY_UPDATE",
      };

    case actions.DELETE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "CATEGORY_DELETE",
      };

    case actions.GET_ASSESSMENT_PROGRESS_PARAM:
      return {
        ...state,
      };
    case actions.GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        assessmentProgressParams: action.assessmentProgressParams,
      };

    case actions.SAVE_ASS_PROGRESS_PARAM:
      return {
        ...state,
      };

    case actions.SAVE_ASS_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        learningOperationType: "PROGRESS_PARAM_ADD",
      };

    case actions.DELETE_ASS_PROGRESS_PARAM:
      return {
        ...state,
      };

    case actions.DELETE_ASS_PROGRESS_PARAM_SUCCESS:
      return {
        ...state,
        learningOperationType: "PROGRESS_PARAM_DELETE",
      };

    case actions.DELETE_LABEL:
      return {
        ...state,
      };

    case actions.DELETE_LABEL_SUCCESS:
      return {
        ...state,
      };

    case actions.FETCH_COMPLETE_SHARE_HISTORY:
      return {
        ...state,
      };

    case actions.FETCH_COMPLETE_SHARE_HISTORY_SUCCESS:
      return {
        ...state,
        shareHistoryMap: action.shareHistoryMap,
      };

    case actions.UPDATE_SHARE_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SHARE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "UPDATE_SHARE_STATUS",
      };

    case actions.GET_PLAN_EXTRA_DETAIL:
      return {
        ...state,
      };

    case actions.GET_PLAN_COMMENT_SUCCESS:
      return {
        ...state,
        planComment: action.planComment,
      };

    case actions.GET_PLAN_SUBMISSION_SUCCESS:
      return {
        ...state,
        planSubmission: action.planSubmission,
      };

    case actions.COPY_LESSON:
      return {
        ...state,
        isLoading: true,
      };
    case actions.COPY_LESSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningOperationType: "COPY_LESSON_SUCCESS",
        saveLessonSuccessful: true,
      };

    case actions.REPLACE_STUDENT:
      return {
        ...state,
        isLoading: true,
        students: [],
      };
    case actions.REPLACE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.students,
      };
    case actions.GET_DEVELOPMENTAREAS_BY_LABELS:
      return {
        ...state,
        isDevLoading: true,
      };
    case actions.GET_DEVELOPMENTAREAS_BY_LABELS_SUCCESSFUL:
      return {
        ...state,
        developmentAreas: action.developmentAreas,
        subDevelopmentArea: action.subDevelopmentAreas,
        milestones: action.milestones,
        isDevLoading: false,
      };
    case actions.SAVE_FORM:
      return {
        ...state,
        observationFormLoader: true,
        saveFormSuccessful: false
      };
    case actions.SAVE_FORM_SUCCESSFUL:
      return {
        ...state,
        observationFormLoader: false,
        saveFormSuccessful: true,
        savedSuccess: true,
      }
    case actions.SAVE_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case actions.RESET_SAVE_FORM_BOOLEAN:
      return {
        ...state,
        saveFormSuccessful: false
      }
    case actions.GET_ASSESSMENT_TEMPLATES:
      return {
        ...state,
        loadingAssessmentTemplates: true,
      };
    case actions.GET_ASSESSMENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        assessmentTemplates: action.assessmentTemplates,
        assessmentTemplatesChannel: action.assessmentTemplatesChannel,
        loadingAssessmentTemplates: false,
      };

    case actions.GET_SELECTED_DEVELOPMENT_AREAS:
      return {
        ...state,
        selectedDevelopmentAreasLoader: true,
      };
    case actions.GET_SELECTED_DEVELOPMENT_AREAS_SUCCESS:
      return {
        ...state,
        selectedDevelopmentAreas: action.selectedDevelopmentAreas,
        selectedSubDevelopmentAreas: action.selectedSubDevelopmentAreas,
        selectedMilestones: action.selectedMilestones,
        selectedDevelopmentAreasLoader: false,
      };

    case actions.SAVE_TEMPLATE:

      return {
        ...state,
        loadingAssessmentTemplates: true,
        templateFormSubmitting: true,
      };
    case actions.SAVE_TEMPLATE_SUCCESS:

      return {
        ...state,
        loadingAssessmentTemplates: false,
        templateFormSubmitting: false,
        templateCreateSuccess: true,
        assessmentTemplateData: {},
        selectedDevelopmentAreas: [],
        selectedSubDevelopmentAreas: [],
        selectedMilestones: [],
      };
    case actions.DELETE_ASSESSMENT_TEMPLATE:
      return {
        ...state,
        loadingAssessmentTemplates: true,
      };
    case actions.DELETE_ASSESSMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loadingAssessmentTemplates: false,
        templateDeleted: true,
      };
    case actions.SET_TEMPLATE_FORM_DATA:
      return {
        ...state,
        assessmentTemplateFormData: action.formData,
      };
    case actions.SET_ASSESSMENT_TEMPLATE_DATA_EMPTY:
      return {
        ...state,
        assessmentTemplateData: {},
        selectedDevelopmentAreas: [],
        selectedSubDevelopmentAreas: [],
        selectedMilestones: [],

      };
    case actions.GET_ASSESSMENT_TEMPLATE_DATA:
      return {
        ...state,
        templateDataLoading: true,
      };
    case actions.GET_ASSESSMENT_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        templateDataLoading: false,
        assessmentTemplateData: action.templateData,
      };
    case actions.SET_IS_CLONE:
      return {
        ...state,
        isClone: action.value,
      }

    case actions.SET_SELECTED_DEVELOPMENT_AREAS_FROM_PREFIL: {
      return {
        ...state,
        selectedDevelopmentAreas: action.data.developmentAreasList ? action.data.developmentAreasList : [],
        selectedSubDevelopmentAreas: action.data.subDevelopmentAreasList ? action.data.subDevelopmentAreasList : [],
        selectedMilestones: action.data.milestonesList ? action.data.milestonesList : [],
      }
    }
    case actions.GET_STUDENT_OBSERVATION_ATTACHEMENTS: {
      return {
        ...state,
        attachmentsLoader: true
      }
    }
    case actions.GET_STUDENT_OBSERVATION_ATTACHEMENTS_SUCCESSFUL: {
      return {
        ...state,
        attachmentsLoader: false,
        observationAttachments: action.observationAttachments
      }
    }
    case actions.SAVE_ASSESSMENT_FORM: {
      return {
        ...state,
        assessmentLoader: true,
      }
    }
    case actions.SAVE_ASSESSMENT_SUCCESSFUL: {
      return {
        ...state,
        assessmentLoader: false,
        assesmentSaved: true,
        assessmentId: action.assessmentId
      }
    }
    case actions.SAVE_ASSESSMENT_FORM_FAILURE: {
      return {
        ...state,
        assessmentLoader: false,
        assesmentSaved: false,
      }
    }
    case actions.GET_OBSERVATION_DATA:
      return {
        ...state,
        observationDataLoading: true,
        observationData: []
      };
    case actions.GET_OBSERVATION_DATA_SUCCESS:
      return {
        ...state,
        observationDataLoading: false,
        observationData: action.observationData
      };
    case actions.GET_OBSERVATION_DATA_FAIL:
      return {
        ...state,
        observationDataLoading: false,
      };
    case actions.GET_OBSERVATION_DATA_BY_IDS:
      return {
        ...state,
        observationDataLoading: true,
        observationData: []
      };
    case actions.GET_OBSERVATION_DATA_BY_IDS_SUCCESS:
      return {
        ...state,
        observationDataLoading: false,
        observationData: action.observationData
      };
    case actions.GET_STUDENT_ASSESSMENT_DATA:
    case actions.ADD_PROGRESS_NAME:
      return {
        ...state,
        // progessValues: [...state.progessValues, { val: action.val, id: uuidv4() }],
        isLoading: true,
      };
    case actions.ADD_PROGRESS_NAME_SUCCESS:
      return {
        ...state,
        // progessValues: [...state.progessValues, { val: action.val, id: uuidv4() }],
        isLoading: false,
      };
    case actions.ADD_PROGRESS_NAME_FAILURE:
      return {
        ...state,
        // progessValues: [...state.progessValues, { val: action.val, id: uuidv4() }],
        isLoading: false,
      };
    case actions.POPULATE_PROGRESS_NAME_SUCCESS:
      return {
        ...state,
        // progessValues: [...state.progessValues, { val: action.val, id: uuidv4() }],
        progessValues: action.progressName,
        progressNameChannel: action.progressNameChannel,
      };
    case actions.DELETE_PROGRESS_NAME:
      return {
        ...state,
        isLoading: true,
      }
    case actions.DELETE_PROGRESS_NAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.DELETE_PROGRESS_NAME_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case actions.ADD_PROGRESS_STATUS_VALUE:
      return {
        ...state,
        // statusValues: [...state.statusValues, { val: action.val, id: uuidv4() }],
        isLoading: true,
      };
    case actions.ADD_PROGRESS_STATUS_VALUE_SUCCESS:
      return {
        ...state,
        // statusValues: [...state.statusValues, { val: action.val, id: uuidv4() }],
        isLoading: false,
      };
    case actions.ADD_PROGRESS_STATUS_VALUE_FAILURE:
      return {
        ...state,
        // statusValues: [...state.statusValues, { val: action.val, id: uuidv4() }],
        isLoading: false,
      };
    case actions.POPULATE_PROGRESS_STATUS_SUCCESS:
      return {
        ...state,
        // progessValues: [...state.progessValues, { val: action.val, id: uuidv4() }],
        statusValues: action.progressName,
        progressStatusChannel: action.progressNameChannel,
      };
    case actions.DELETE_PROGRESS_STATUS_VALUE:
      return {
        ...state,
        // statusValues: state.statusValues.filter((v) => v.id !== action.id),
        isLoading: true,
      };
    case actions.DELETE_PROGRESS_STATUS_VALUE_SUCCESS:
      return {
        ...state,
        // statusValues: state.statusValues.filter((v) => v.id !== action.id),
        isLoading: false,
      };
    case actions.DELETE_PROGRESS_STATUS_VALUE_FAILURE:
      return {
        ...state,
        // statusValues: state.statusValues.filter((v) => v.id !== action.id),
        isLoading: false,
      };
    case actions.SET_DEFAULT_STATUS_VALUE:
      return {
        ...state,
        defaultStatus: action.newId,
        isLoading: true,
      };
    case actions.SET_DEFAULT_STATUS_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SET_DEFAULT_STATUS_VALUE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.POPULATE_TERMINOLOGY_TERMS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STUDENT_ASSESSMENT_DATA_SUCCESS:
      return {
        ...state,
        studentsAssessmentsData: action.studentsAssessmentsData,
        studentsAssessmentsDataChannel: action.studentsAssessmentsDataChannel,
        isLoading: false
      }

    case actions.POPULATE_TERMINOLOGY_TERMS_SUCCESS:
      return {
        ...state,
        terminologyTerms: action.terminologyTerms,
        terminologyTermsChannel: action.terminologyTermsChannel,
        isLoading: false,
      };
    case actions.POPULATE_TERMINOLOGY_TERMS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.EDIT_TERMINOLOGY_TERM:
      return {
        ...state,
        isLoading: true,
      };
    case actions.EDIT_TERMINOLOGY_TERM_SUCCESS:
      let updatedAssessmentTerminology = { ...state.assessmentTerminology };
      updatedAssessmentTerminology[action.terminologyData.key].customKey = action.terminologyData.customKey;
      return {
        ...state,
        isLoading: false,
        assessmentTerminology: updatedAssessmentTerminology,
      };
    case actions.EDIT_TERMINOLOGY_TERM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_STUDENT_OBSERVATION:
      return {
        ...state,
        // isLoading: true,
        isLoadingStdObs: true,
      };
    case actions.GET_STUDENT_OBSERVATION_SUCCESS:
      return {
        ...state,
        studentObservation: action.studentObservation,
        isLoadingStdObs: false,
        isNoteLoading: false
      };
    case actions.GET_STUDENT_OBSERVATION_FAILURE:
      return {
        ...state,
        // isLoading: false,
        isLoadingStdObs: false,
      };
    case actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID_SUCCESS:
      return {
        ...state,
        developmentAreasNamebyId: action.data,
        developmentAreasChannel: action.channel,
        isLoading: false,
      };
    case actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_DEVELOPMENT_AREA_PROGRESS_STATUS:
      return {
        ...state,
      };
    case actions.GET_DEVELOPMENT_AREA_PROGRESS_STATUS_SUCCESS:
      return {
        ...state,
        developmentAreasProgressStatus: action.data,
        developmentAreaProgressStatusChannel: action.channel,
      };
    case actions.ADD_NOTE_PROGRESS_STATUS:
      return {
        ...state,
        isNoteLoading: true,
      };
    case actions.ADD_NOTE_PROGRESS_STATUS_SUCCESS:
      return {
        ...state,
        isNoteLoading: false,
      };
    case actions.ADD_NOTE_PROGRESS_STATUS_FAILURE:
      return {
        ...state,
        isNoteLoading: false,
      };
    case actions.GET_STUDENT_ASSESSMENT_BY_ID:
      return {
        ...state,
        templateDataLoading: true,
        assessmentLoader: true
      }
    case actions.GET_STUDENT_ASSESSMENT_BY_ID_FAILURE:
      return {
        ...state,
        templateDataLoading: false,
        assessmentLoader: false
      }
    case actions.GET_STUDENT_ASSESSMENT_BY_ID_SUCCESSFUL:
      return {
        ...state,
        studentAssessmentById: action.studentAssessmentById,
        templateDataLoading: false,
        progressDataLoading: false,
        assessmentLoader: false
      }
    case actions.DELETE_STUDENT_ASSESSMENT:
      return {
        ...state,
        assessmentLoader: true
      }
    case actions.DELETE_STUDENT_ASSESSMENT_FAILURE:
      return {
        ...state,
        assessmentLoader: false
      }
    case actions.DELETE_STUDENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentLoader: false,
        //observationDataLoading : false,
        deleteOperationType: action.deleteOperationType,
      }
    case actions.RESET_ASSESSMENT_FORM_FIELDS:
      return {
        ...state,
        assessmentTemplateData: {},
        studentAssessmentById: {},
        assesmentSaved: false,
        assessmentId: undefined,
        devareaForStudentAssessment: {}

      }
    case actions.VIEW_OBSERVATIONS:
      return {
        ...state,
        isLoading: true
      }
    case actions.VIEW_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        viewObservationsByStd: action.viewObservations
      }

    case actions.GET_PROGRESS_DATA:
      return {
        ...state,
        progressDataLoading: true,
      }
    case actions.GET_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        progressDataLoading: false,
        progressData: action.progressData,
        progressDataChannel: action.progressDataChannel,
      }

    case actions.DELETE_OBSERVATION:
      return {
        ...state,
        observationDataLoading: true
      }

    case actions.DELETE_OBSERVATION_SUCCESS:
      return {
        ...state,
        observationDataLoading: false
      }

    case actions.GET_FORM_DATA_BY_ID:
      return {
        ...state,
        observationFormLoader: true
      }

    case actions.GET_FORM_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        observationFormLoader: false,
        selectedFormData: action.selectedFormData
      }
    case actions.GET_OBSERVATION_COMMENT_SUCCESS:
      return {
        ...state,
        observationComment: action.observationComment
      }
    case actions.SET_SELECTED_FORM_DATA_EMPTY:
      return {
        ...state,
        selectedFormData: {},
      }
    case actions.RESET_SAVED_SUCCESS:
      return {
        ...state,
        savedSuccess: false,
      }

    case actions.GET_STUDENT_PROGRESS_TRACK:
      return {
        ...state,
        studentProgressTrackerLoading: true,
        studentProgressTracker: []
      }

    case actions.GET_STUDENT_PROGRESS_TRACK_SUCCESS:
      return {
        ...state,
        studentProgressTrackerLoading: false,
        studentProgressTracker: action.studentProgressTracker
      }

    case actions.RESET_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        templateCreateSuccess: false,
      }

    case actions.GET_STUDENT_OBSERVATION_DATA:
      return {
        ...state,
        profileAssessmentDataLoading: true
      }

    case actions.GET_STUDENT_OBSERVATION_DATA_SUCCESS:
      return {
        ...state,
        studentObservationData: action.studentObservationData,
        profileAssessmentDataLoading: false
      }

    case actions.GET_STUDENT_REFLECTION_DATA:
      return {
        ...state,
        profileAssessmentDataLoading: true
      }

    case actions.GET_STUDENT_REFLECTION_DATA_SUCCESS:
      return {
        ...state,
        studentReflectionData: action.studentReflectionData,
        profileAssessmentDataLoading: false
      }

    case actions.GET_STUDENT_CONCERN_DATA:
      return {
        ...state,
        profileAssessmentDataLoading: true
      }

    case actions.GET_STUDENT_CONCERN_DATA_SUCCESS:
      return {
        ...state,
        studentConcernData: action.studentConcernData,
        profileAssessmentDataLoading: false
      }
    case actions.GET_STUDENT_OBSERVATION_BY_DEVAREA:
      return {
        ...state,
        observationDataLoading: true,
      }
    case actions.GET_STUDENT_OBSERVATION_BY_DEVAREA_SUCCESS:
      return {
        ...state,
        observationDataLoading: false,
        studentObservationBydevarea: action.studentObservationBydevarea
      }
    case actions.GET_STUDENT_OBSERVATION_BY_DEVAREA_FAILURE:
      return {
        ...state,
        observationDataLoading: false,
      }
    case actions.RESET_OBSERVATION_FORM:
      return {
        ...state,
        selectedFormData: {}
      }
    case actions.RESET_AREA_DELETED:
      return {
        ...state,
        areaDeleted: "",
      }
    case actions.RESET_AREA_EDITED:
      return {
        ...state,
        areaEdited: "",
      }
    case actions.RESET_TEMPLATE_DELETED:
      return {
        ...state,
        templateDeleted: false,
      }
    case actions.SAVE_PDF_TEMPLATE:
      return {
        ...state,
        pdfTemplateLoader: true,
      }
    case actions.SAVE_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        pdfTemplateLoader: false,
      }
    case actions.SAVE_PDF_TEMPLATE_FAILURE:
      return {
        ...state,
        pdfTemplateLoader: false,
      }
    case actions.GET_PDF_TEMPLATE:
      return {
        ...state,
        pdfTemplateLoader: true,
      }
    case actions.GET_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        pdfStyle: action.pdfStyle,
        pdfTemplateLoader: false,
      }
    case actions.GET_PDF_TEMPLATE_FAILURE:
      return {
        ...state,
        pdfTemplateLoader: false,
      }
    case actions.RESET_PDF_TEMPLATE:
      return {
        ...state,
        pdfStyle: undefined
      }
    case actions.MIGRATE_DEVELOPMENT_AREA_DATA:
      return {
        ...state,
        assessmentFrameworkLoading: true,
      }
    case actions.MIGRATE_DEVELOPMENT_AREA_DATA_SUCCESS:
      return {
        ...state,
        assessmentFrameworkLoading: false,
        migrateSuccessfullOrFailure: true,
      }
    case actions.MIGRATE_DEVELOPMENT_AREA_DATA_FAILURE:
      return {
        ...state,
        assessmentFrameworkLoading: false,
        migrateSuccessfullOrFailure: true,
      }
    case actions.RESET_MIGRATE_STATE:
      return {
        ...state,
        migrateSuccessfullOrFailure: false,
      }
    case actions.GET_LESSON_EXTRA_FIELDS:
      return {
        ...state,
        lessonAdditionalFields: []
      }

    case actions.GET_LESSON_EXTRA_FIELDS_SUCCESS:
      return {
        ...state,
        lessonAdditionalFields: action.lessonAdditionalFields
      }
    case actions.RESET_SAVE_LESSON_SUCCESSFUL:
      return {
        ...state,
        saveLessonSuccessful: false,
      }
    case actions.STORE_ASSESSMENT_TERMINOLOGY_DATA:
      return {
        ...state,
        assessmentTerminology: action.terminologyData,
      }

    case actions.GET_SUGGESTIONS:

      return {
        ...state,
        suggestionLoader: true,
      }
    case actions.GET_SUGGESTIONS_SUCCESS:

      return {
        ...state,
        suggestionLoader: false,
        staffNoteSuggestions: action.staffNoteSuggestions,
      }

    case actions.GET_PARAPHRASE_TEXT:
      return {
        ...state,
        paraphraseLoader: true,

      }
    case actions.GET_PARAPHRASE_TEXT_SUCCESS:
      return {
        ...state,
        paraphraseLoader: false,
        paraphraseText: action.text,

      }
    case actions.CLEAR_SUGGESTIONS:
      return {
        ...state,
        staffNoteSuggestions: "",
      }
    case actions.GPT_REQUEST_FAILED:
      return {
        ...state,
        suggestionLoader: false,
        paraphraseLoader: false,
      }
    case actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_SUCCESS:
      return {
        ...state,
        studentAttendanceData: action.studentAttendanceData
      }
    case actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_FAILURE:
      return {
        ...state,
        assessmentLoader: false
      }
    case actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT:
      return {
        ...state,
        templateDataLoading: true
      }
    case actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        templateDataLoading: false,
        devareaForStudentAssessment: action.devareaForStudentAssessment
      }
    case actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_FAIL:
      return {
        ...state,
        templateDataLoading: false
      }

    case actions.EDIT_PROGRESS_VALUE:
      return {
        ...state,
        isLoading: true,
      }
    case actions.EDIT_PROGRESS_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.GET_PROGRESS_REPORT_DATA:
      return {
        ...state,
        progressReportLoader: true
      }
    case actions.GET_PROGRESS_REPORT_DATA_SUCCESS:
      return {
        ...state,
        progressReportData: action.progressReportData,
        progressReportLoader: false
      }
    case actions.GET_PROGRESS_REPORT_DATA_FAIL:
      return {
        ...state,
        progressReportLoader: false
      }
    case actions.GET_ASSESSMENT_REPORT_DATA:
      return {
        ...state,
        progressReportLoader: true
      }
    case actions.GET_ASSESSMENT_REPORT_DATA_SUCCESS:
      return {
        ...state,
        assessmentReportData: action.assessmentReportData,
        progressReportLoader: false
      }
    case actions.GET_ASSESSMENT_REPORT_DATA_FAIL:
      return {
        ...state,
        progressReportLoader: false
      }
    case actions.GET_COMPARISON_REPORT:
      return {
        ...state,
        progressReportLoader: true,

      }
    case actions.GET_COMPARISON_REPORT_SUCCESS:
      return {
        ...state,
        progressReportLoader: false,
        comparsionReport: action.comparsionReport
      }
    case actions.GET_COMPARISON_REPORT_FAIL:
      return {
        ...state,
        progressReportLoader: false,
      }
    case actions.EDIT_PROGRESS_VALUE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case actions.RESET_DELETE_OPERATION_TYPE:
      return {
        ...state,
        deleteOperationType: "",
      }
    default:
      return state;
  }
}
