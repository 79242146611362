import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import actions from "./actions";
import { callApi } from "../../Utility/superAgentUntil";
import StudentDoseView from "../../containers/StudentImmmunization/StudentDoseView";
import { ActivityApi } from "../../firestore-api/activity";
import TitleView from "../../containers/StudentImmmunization/TitleView";
import DoseView from "../../containers/StudentImmmunization/DoseView";
import VaccineTableAction from "../../containers/StudentImmmunization/VaccineTableAction";
import StudentVaccineAction from "../../containers/StudentImmmunization/StudentVaccineAction";
import { imageExtension } from "../../Utility/mimeTypeDirectory";


function getHeaderRow(list) {
    let maxLength = 0;
    list.map((vaccine) => {
        maxLength = Math.max(maxLength, vaccine.length);
    })

    let columns = [
        {
            title: formatMsg("immunization.vaccineTitleC"),
            dataIndex: "col0",
            key: "col0",
        },
    ];
    for (let i = 0; i < maxLength - 1; i++) {
        let obj = {
            title: formatMsg("immunization.dose") + " #" + "" + (i + 1),
            dataIndex: "col" + (i + 1),
            key: "col" + (i + 1),
        }
        columns.push(obj);
    }
    // for actions
    let obj = {
        title: "",
        dataIndex: "col" + (maxLength).toString(),
        key: "col" + (maxLength).toString(),
    }
    columns.push(obj);
    return { columns, maxLength };
}
function getModifiedData(list, viewType, maxLength) {
    let result = [];
    let columnObject = {};
    list.map((vaccineRow) => {
        columnObject = {};
        let doseOrder = 0;
        vaccineRow.map((obj, index) => {
            if (index == 0) {
                columnObject = {
                    id: obj.vaccineId,
                    "col0":
                        <TitleView
                            vaccine={obj}
                        />
                }
            }
            else {
                if (viewType == "studentView") {
                    columnObject = {
                        ...columnObject,
                        ["col" + (index).toString()]:
                            <StudentDoseView
                                doseRecord={obj}
                                vaccine={vaccineRow[0]}
                            />
                    };
                    doseOrder = obj.doseOrder;
                }
                else {
                    columnObject = {
                        ...columnObject,
                        ["col" + (index).toString()]:
                            <DoseView
                                dose={obj}
                            />
                    };
                    doseOrder = obj.doseOrder;
                }
            }
        })
        if (viewType == "studentView") {
            columnObject = {
                ...columnObject,
                ["col" + (maxLength).toString()]:
                    <StudentVaccineAction
                        vaccine={vaccineRow[0]}
                    />
            }
        }
        else {
            columnObject = {
                ...columnObject,
                ["col" + (maxLength).toString()]:
                    <VaccineTableAction
                        vaccine={vaccineRow[0]}
                        doseOrder={doseOrder + 1}
                    />
            }
        }
        result.push(columnObject);
    })
    return result;
}

function* fetchAllVaccine({ firebase }) {

    try {
        let endpoint = "woodlandApi/vaccineDoseInfo?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status === 200) {
            let data = response.body.response;
            let { columns, maxLength } = getHeaderRow(data);
            let modidifiedData = getModifiedData(data, "vaccineTable", maxLength);
            yield put({
                type: actions.FETCH_ALL_VACCINE_SUCCESS,
                vaccineTableColumns: columns,
                vaccineList: modidifiedData
            });
        }
        else {
            notification("error", formatMsg("immunization.failedToFetchVaccines"));
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to fetch Vaccines data", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}


function* saveVaccine({ firebase, values, operationType }) {
    try {
        let endpoint = "woodlandApi/vaccine?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response && response.status === 200) {
            let msg = formatMsg("notif.vaccineAddedSuccessfully");
            if (operationType == "edit") {
                msg = formatMsg("notif.vaccineUpdatedSuccessfully");
            }
            notification("success", msg);
            yield put({
                type: actions.SAVE_VACCINE_SUCCESS,
            });
            yield fork(fetchAllVaccine, {
                firebase: firebase,
            });
        }
        else {
            notification("error", formatMsg("error.failedToAddVaccine"));
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to save Vaccine", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* saveDose({ firebase, values, operationType }) {
    try {
        let endpoint = "woodlandApi/dose?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response && response.status === 200) {
            let msg = formatMsg("notif.doseAddedSuccessfully");
            if (operationType == "edit") {
                msg = formatMsg("notif.doseUpdatedSuccessfully");
            }
            notification("success", msg);
            yield put({
                type: actions.SAVE_DOSE_SUCCESS,
            });
            yield fork(fetchAllVaccine, {
                firebase: firebase,
            });
        }
        else {
            notification("error", formatMsg("error.failedToAddDose"));
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to save dose", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* updateStudentDose({ firebase, doseRecord, updateType, vaccineId, studentId, vaccineName }) {
    doseRecord["id"] = doseRecord["studentDoseId"];
    delete doseRecord["studentDoseId"];
    delete doseRecord["proofMandatory"];
    delete doseRecord["scheduledDate"];
    delete doseRecord["doseOrder"];
    try {
        let urls = [];
        if (updateType == "updateProof" && doseRecord.proof) {
            let type = doseRecord?.proof?.file?.type;
            if (type) {
                let storagePath;
                if (imageExtension.includes(type)) {
                    storagePath = firebase.sbp + "/media/file/";
                }
                else {
                    storagePath = firebase.sbp + "/media/profileimages/";
                }
                urls = yield call(ActivityApi.getMediaPath, storagePath, doseRecord.proof, firebase);
            }
            else {
                urls[0] = doseRecord.proof;
            }
        }
        if (urls.length > 0) {
            doseRecord.proof = urls[0];
        }
        if (!doseRecord["studentVaccinationRecordId"]) {
            let obj = {};
            obj["vaccineId"] = vaccineId;
            obj["studentId"] = studentId;
            obj["vaccineLevelExempted"] = false;
            obj["studentLevelExempted"] = false;
            let endpoint = "woodlandApi/studentVaccineRecord?centerId=" + firebase.sbDbName;
            let response = yield call(callApi, firebase, "post", endpoint, obj);
            if (response) {
                doseRecord["studentVaccinationRecordId"] = response.body.response.studentVaccinationRecord;
            }
        }
        doseRecord["studentId"] = studentId;
        let endpoint = "woodlandApi/studentDosageRecord/" + firebase.selectedMode + "/" + vaccineName + "?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, doseRecord);
        if (doseRecord["studentVaccinationRecordId"] && response && response.status === 200) {
            notification("success", formatMsg("notif.doseUpdatedSuccessfully"));
            yield put({
                type: actions.UPDATE_STUDENT_DOSE_SUCCESS,
            });
            yield fork(getImmunizationRecordForStudent, {
                firebase: firebase,
                studentId: studentId
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to update student dose", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* getImmunizationRecordForStudent({ studentId, firebase }) {
    try {
        let endpoint = "woodlandApi/studentImmunization/" + studentId + "?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status === 200) {
            if (response.body.response && response.body.response.length > 0) {
                let data = response.body.response;
                let { columns, maxLength } = getHeaderRow(data)
                let result = getModifiedData(data, "studentView", maxLength);
                yield put({
                    type: actions.GET_IMMUNIZATION_FOR_STUDENT_SUCCESS,
                    studentImmunizationRecordList: result,
                    columns: columns,
                    studentLevelExempted: data[0] && data[0][0] ? data[0][0].studentLevelExempted : false

                });
            }
            else {
                let { columns, maxLength } = getHeaderRow(response.body.vaccineList ? response.body.vaccineList : []);
                yield put({
                    type: actions.GET_IMMUNIZATION_FOR_STUDENT_SUCCESS,
                    studentImmunizationRecordList: [],
                    columns: columns,
                    studentLevelExempted: false
                });
            }
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to get student immunization", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* updateStudentVaccination({ firebase, studentVaccination }) {
    studentVaccination["id"] = studentVaccination["studentVaccinationRecordId"];
    delete studentVaccination["studentVaccinationRecordId"];
    delete studentVaccination["vaccineName"];
    delete studentVaccination["instruction"];
    delete studentVaccination["mandatory"];
    try {
        let endpoint = "woodlandApi/studentVaccineRecord?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, studentVaccination);

        if (response && response.statusCode && response.statusCode == 200) {
            notification("success", formatMsg("notif.immunizationDetailUpdatedSuccessfully"));
            yield put({
                type: actions.UPDATE_STUDENT_VACCINATION_SUCCESS,
            });
            yield fork(getImmunizationRecordForStudent, {
                studentId: studentVaccination.studentId,
                firebase: firebase,
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to update Student immunization detail", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* sendVaccinesReminder({ firebase, studentId }) {
    try {
        let endpoint = "woodlandApi/sendVaccinesReminder/" + studentId + "?centerId=" + firebase.sbDbName;
        let response = yield call(callApi, firebase, "post", endpoint, {});

        if (response && response.status === 200) {
            notification("success", response.body.msg);
            yield put({
                type: actions.SEND_VACCINES_REMINDER_SUCCESS,
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to get vaccine", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* sendVaccineReminder({ firebase, studentId, vaccineId }) {
    try {
        let endpoint = `woodlandApi/sendVaccineReminder/${studentId}/${vaccineId}?centerId=${firebase.sbDbName}`;
        let response = yield call(callApi, firebase, "post", endpoint, {});

        if (response && response.status === 200) {
            notification("success", response.body.msg);
            yield put({
                type: actions.SEND_VACCINE_REMINDER_SUCCESS,
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to get vaccine", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* deleteDose({ firebase, doseId }) {
    try {
        let endpoint = `woodlandApi/dose/${doseId}?centerId=${firebase.sbDbName}`;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response && response.status === 200) {
            notification("success", response.body.response);
            yield put({
                type: actions.DELETE_DOSE_SUCCESS,
            });
            yield fork(fetchAllVaccine, {
                firebase: firebase,
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to delete vaccine", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* deleteVaccine({ firebase, vaccineId }) {
    try {
        let endpoint = `woodlandApi/vaccine/${vaccineId}?centerId=${firebase.sbDbName}`;
        let response = yield call(callApi, firebase, "delete", endpoint, {});

        if (response && response.statusCode && response.statusCode == 200) {
            notification("success", formatMsg("notif.vaccinationDeletedSuccessfully"));
            yield put({
                type: actions.DELETE_VACCINE_SUCCESS,
            });
            yield fork(fetchAllVaccine, {
                firebase: firebase,
            });
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to delete vaccine", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

function* exemptAllVaccines({ firebase, studentId, value }) {
    try {
        let endpoint = `woodlandApi/exemptAllVaccines/${studentId}?centerId=${firebase.sbDbName}`;
        let response = yield call(callApi, firebase, "post", endpoint, { studentLevelExempted: value });

        if (response && response.statusCode && response.statusCode == 200) {
            yield put({
                type: actions.EXEMPT_STUDENT_VACCINES_SUCCESS
            });
            yield fork(getImmunizationRecordForStudent, {
                firebase: firebase,
                studentId: studentId
            });
        } else {
            yield put({
                type: actions.REQUEST_FAILED,
            });
        }
    } catch (err) {
        console.log("failed to exempt all vaccines", err);
        yield put({
            type: actions.REQUEST_FAILED,
        });
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(actions.FETCH_ALL_VACCINE, fetchAllVaccine),
        yield takeLatest(actions.SAVE_VACCINE, saveVaccine),
        yield takeLatest(actions.DELETE_VACCINE, deleteVaccine),
        yield takeLatest(actions.SAVE_DOSE, saveDose),
        yield takeLatest(actions.DELETE_DOSE, deleteDose),
        yield takeLatest(actions.GET_IMMUNIZATION_FOR_STUDENT, getImmunizationRecordForStudent),
        yield takeLatest(actions.UPDATE_STUDENT_DOSE, updateStudentDose),
        yield takeLatest(actions.UPDATE_STUDENT_VACCINATION, updateStudentVaccination),
        yield takeLatest(actions.SEND_VACCINES_REMINDER, sendVaccinesReminder),
        yield takeLatest(actions.SEND_VACCINE_REMINDER, sendVaccineReminder),
        yield takeLatest(actions.EXEMPT_STUDENT_VACCINES, exemptAllVaccines),
    ]);
}