import CryptoJS from "crypto-js";

import Bugsnag from "@bugsnag/js";
const passphrase = "password";
export const keyVersion = "1.0.0";
export function setItem(key, data) {

  var version = localStorage.getItem("keyVersion");
  let ciphertext;
  try {
    if (version && data != "") {
      if (typeof data == "object")
        ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), passphrase);
      else {
        if (typeof data == "boolean" || typeof data == "number") {
          data = data.toString();
        }
        ciphertext = CryptoJS.AES.encrypt(data, passphrase);
      }
      localStorage.setItem(key, ciphertext);
    } else {
      localStorage.setItem(key, data);
    }
  } catch (err) {
    console.log(err);
    console.log("setItem error", key, data)
    Bugsnag.notify(err);
  }
}

export function getItem(key) {
  try {
    var version = localStorage.getItem("keyVersion");
    var val = null;
    if (version) {
      if (localStorage.getItem(key) && localStorage.getItem(key) != "") {
        const encryptedData = localStorage.getItem(key);
        const decryptedDataBytes = CryptoJS.AES.decrypt(encryptedData, passphrase);
        val = decryptedDataBytes.toString(CryptoJS.enc.Utf8);
      }
    } else {
      val = localStorage.getItem(key);
    }
    if (val == undefined || val == "") {
      return null;
    } else {
      return val;
    }
  } catch (err) {
    console.log(err);
    Bugsnag.notify(err);
  }
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}

export function encryptAllKeys() {
  let allLocalStorage = {
    ...localStorage,
  };

  for (let key in allLocalStorage) {
    setItem(key, allLocalStorage[key]);
  }
}
