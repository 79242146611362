const initialState = {
    schedules: [],
    filteredSchedules: [],
    scheduleChannel: undefined,
    staffSchedules: {},
    isLoading: false,
    selectedSchedule: null,
    selectedScheduleChannel: undefined,
    operationType: undefined,
    formLoading: false,
    affectedSchedules: [],
    affectedScheduleChannel: undefined,
    activeClassList: []
};

import actions from "./actions.js";

const staffScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CREATE_STAFF_SCHEDULE:
            return {
                ...state,
                formLoading: true
            }

        case actions.CREATE_STAFF_SCHEDULE_SUCCESS:
            return {
                ...state,
                operationType: "CREATE_STAFF_SCHEDULE_SUCCESS",
                formLoading: false
            }

        case actions.SHOW_ALL_STAFF_SCHEDULE:
            return {
                ...state,
                isLoading: true,
            };

        case actions.SAVE_ALL_SCHEDULES:
            return {
                ...state,
                isLoading: true,
                schedules: action.allSchedules,
                scheduleChannel: action.scheduleChannel,
            }

        case actions.GET_ALL_STAFF_SCHEDULE:
            return {
                ...state,
                filteredSchedules: action.payload,
                isLoading: false,
                scheduleChannel: action.scheduleChannel,
            };

        case actions.DELETE_STAFF_SCHEDULE:
            return {
                ...state,
                isLoading: true
            }

        case actions.DELETE_STAFF_SCHEDULE_SUCCESS:
            return {
                ...state,
                operationType: "DELETE_STAFF_SCHEDULE_SUCCESS",
                isLoading: false
            }

        case actions.FILTER_BY_STAFF_AND_BY_CLASSLIST_SUCCESS:
            return {
                ...state,
                filteredSchedules: action.payload,
            }
        case actions.GET_SELECTED_SCHEDULE:
            return {
                ...state,
                isLoading: true,
            }
        case actions.GET_SELECTED_SCHEDULE_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                selectedSchedule: action.selectedSchedule,
                selectedScheduleChannel: action.selectedScheduleChannel
            }
        case actions.RESET_SCHEDULE_OPERATION:
            return {
                ...state,
                operationType: undefined
            }
        case actions.GET_AFFECTED_SCHEDULES:
            return {
                ...state,
                isLoading: true
            }
        case actions.GET_AFFECTED_SCHEDULES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                affectedSchedules: action.affectedSchedules,
                affectedScheduleChannel: action.affectedScheduleChannel
            }
        case actions.GET_ALL_CLASSES_SUCCESS:
            return {
                ...state,
                activeClassList: action.activeClassList
            }
        default:
            return state;
    }
};
export default staffScheduleReducer;
