import React, { Component } from "react";
import { Menu, Dropdown, Icon } from "antd";
import { withFirebase } from "../firebase";
import bugsnagClient from "@bugsnag/js";
import notification from "../notification";
import colors from "../../Utility/colorFactory";
import { setItem, getItem, clear, removeItem } from "../../Utility/encryptedStorage";
import formatMsg from "../utility/formatMessageUtil";

class BranchPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranch: null,
    };
    this.getDropdownMenu = this.getDropdownMenu.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    var x = getItem("selectedBranchPath") ? getItem("selectedBranchPath") : "";
    if (x !== null) {
      x = x.charAt(0).toUpperCase() + x.slice(1);
    }
    this.setState({
      selectedBranch: x,
    });
  }

  getDropdownMenu() {
    const { firebase } = this.props;
    const { selectedBranch } = this.state;
    let spaced = {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    };

    let noMargin = {
      margin: 0,
    };

    let checkMark = {
      color: colors.v2_Cerulean,
    };

    return (
      <Menu onClick={this.onClick}>
        {firebase &&
          firebase.branchList &&
          firebase.branchList.map((item, index) => (
            <Menu.Item key={item.name}>
              <div style={spaced}>
                <p style={noMargin}>{item.name}</p>
                {selectedBranch && selectedBranch === item.name ? (
                  <Icon type="check" style={checkMark} />
                ) : null}
              </div>
            </Menu.Item>
          ))}
      </Menu>
    );
  }

  onClick = ({ key }) => {
    const { firebase, switchBranchName, attachmentProgress } = this.props;
    if (attachmentProgress) {
      notification("error", formatMsg("error.switchBranchWhileAttachmentUpload"));
    } else {
      notification("warning", formatMsg("warning.fetchDataWithBranchSwitch"));
      var selectedKey = key;
      this.setState({ selectedBranch: selectedKey }, () => {
        firebase
          .switchBranch(key)
          .then((val) => {
            switchBranchName(firebase);
          })
          .catch((err) => {
            bugsnagClient.notify("failed to switch branch ---->>" + err.message);
          });
      });
    }
  };

  getBranchName(selectedBranch) {
    return selectedBranch;
    // if (selectedBranch) {
    //   if (selectedBranch.length > 15) {
    //     return selectedBranch.substring(0, 15) + "..."
    //   } else {
    //     return selectedBranch
    //   }
    // }
  }

  render() {
    const { selectedBranch } = this.state;
    return (
      <Dropdown overlay={this.getDropdownMenu()} trigger={["click"]}>
        <span className="isoBranchName">
          {selectedBranch !== null ? this.getBranchName(selectedBranch) : "Select Branch"}
          <Icon
            type="down"
            style={{
              marginLeft: "2px",
              fontSize: "12px",
            }}
          />
        </span>
      </Dropdown>
    );
  }
}

export default withFirebase(BranchPicker);
