const actions = {
  GET_SCHEDULES: "GET_SCHEDULES",
  GET_SCHEDULES_SUCCESSFUL: "GET_SCHEDULES_SUCCESSFUL",
  SCHEDULE_REQUEST_FAILED: "SCHEDULE_REQUEST_FAILED",
  GET_SCHEDULE_CLASSROOM: "GET_SCHEDULE_CLASSROOM",
  GET_SCHEDULE_CLASSROOM_SUCCESSFUL: "GET_SCHEDULE_CLASSROOM_SUCCESSFUL",
  DELETE_SCHEDULE_ITEM: "DELETE_SCHEDULE_ITEM",
  DELETE_SCHEDULE_ITEM_SUCCESSFUL: "DELETE_SCHEDULE_ITEM_SUCCESSFUL",
  RESET_SCHEDULE_OPERATION_TYPE: "RESET_SCHEDULE_OPERATION_TYPE",
  ADD_SCHEDULE: "ADD_SCHEDULE",
  ADD_SCHEDULE_SUCCESSFUL: "ADD_SCHEDULE_SUCCESSFUL",
  EDIT_SCHEDULE: "EDIT_SCHEDULE",
  EDIT_SCHEDULE_SUCCESSFUL: "EDIT_SCHEDULE_SUCCESSFUL",
  DELETE_MULTIPLEDAYS_SCHEDULE: "DELETE_MULTIPLEDAYS_SCHEDULE",
  DELETE_MULTIPLEDAYS_SCHEDULE_SUCCESSFUL: "DELETE_MULTIPLEDAYS_SCHEDULE_SUCCESSFUL",
  GET_ACTIVE_ACTIVITY: "GET_ACTIVE_ACTIVITY",
  GET_ACTIVE_ACTIVITY_SUCCESS: "GET_ACTIVE_ACTIVITY_SUCCESS",
  DELETE_SCHEDULE_FOR_DATES: "DELETE_SCHEDULE_FOR_DATES",
  DELETE_SCHEDULE_FOR_DATES_SUCCESS: "DELETE_SCHEDULE_FOR_DATES_SUCCESS",
  RESET_COUNTER: "RESET_COUNTER",
  EDIT_SELETCED_SCHEDULE_SUCCESSFUL: "EDIT_SELETCED_SCHEDULE_SUCCESSFUL",
  getActiveActivities: (firebase, initVal) => ({
    type: actions.GET_ACTIVE_ACTIVITY,
    firebase,
    initVal,
  }),

  deleteMultipleShedule: (values, firebase) => ({
    type: actions.DELETE_MULTIPLEDAYS_SCHEDULE,
    values,
    firebase,
  }),

  getSchedule: (date, firebase) => ({
    type: actions.GET_SCHEDULES,
    date,
    firebase,
  }),

  getClassroomForSchedule: (firebase) => ({
    type: actions.GET_SCHEDULE_CLASSROOM,
    firebase,
  }),

  deleteSchedule: (scheduleItem, date, className, firebase) => ({
    type: actions.DELETE_SCHEDULE_ITEM,
    scheduleItem,
    date,
    className,
    firebase,
  }),

  resetScheduleOperation: () => ({
    type: actions.RESET_SCHEDULE_OPERATION_TYPE,
  }),

  addSchedule: (values, selectedDate, selectedClass, classrooms, firebase) => ({
    type: actions.ADD_SCHEDULE,
    values,
    selectedDate,
    selectedClass,
    classrooms,
    firebase,
  }),

  editSchedule: (values, selectedClass, editableRecord, firebase) => (
    {
      type: actions.EDIT_SCHEDULE,
      values,
      selectedClass,
      editableRecord,
      firebase,
    }),
  deleteScheduleForDates: (formData, firebase) => (

    {
      type: actions.DELETE_SCHEDULE_FOR_DATES,
      formData,
      firebase,
    }
  ),
  resetCounter: () => ({
    type: actions.RESET_COUNTER,
  }),
};
export default actions;
