import { all, call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ProgramApi } from "../../firestore-api/program";
import moment from "moment";
import bugsnagClient from "@bugsnag/js";
import UserFilterAction from "../../Utility/UserFilterActions";
import { StudentApi } from "../../firestore-api/student";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import FilterAction from "../../Utility/FilterAction";
const { Parser } = require("json2csv");

function* deleteSelectedStudentFromProgram({ selectedProgram, firebase }) {
  try {
    yield call(ProgramApi.deleteStudentFromProgram, selectedProgram, firebase);
    firebase.getPrograms();
    yield put({
      type: actions.DELETE_STUDENT_FROM_PROGRAM_SUCCESSFFUL,
    });
  } catch (err) {
    console.log("failed to delete student from program", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchStudentListForDetailedProgram({ firebase }) {
  try {
    //var students = yield call(AssessmentApi.getAllStudents, firebase);
    let students = FilterAction.getStudentList(firebase);
    if (students) {
      yield put({
        type: actions.DETAILED_PROGRAM_FETCH_STUDENT_SUCCESSFFUL,
        detailedProgramStudent: students,
      });
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED,
    });
  }
}

function* assignNewStudentToProgram({
  values,
  selectedStudentCheckbox,
  selectedProgram,
  firebase,
}) {
  try {
    yield call(ProgramApi.assignStudentsFromDetailedProgram, selectedProgram, firebase);
    yield put({
      type: actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM_SUCCESSFUL,
      // programOperationType: 'ASSIGN_STUDENT'
    });
    firebase.getPrograms();
  } catch (error) {
    console.log("failed to assign plan to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchDetailedProgramFeePlan({ firebase }) {
  try {
    var feePlan = yield call(ProgramApi.getAllFeePlan, firebase);
    if (feePlan) {
      yield put({
        type: actions.DETAILED_PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL,
        detailedProgramFeePlan: feePlan,
      });
    }
  } catch (error) {
    console.log("failed to fetch fee plan for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED,
    });
  }
}

function* addNewFeePlan({ selectedProgram, firebase }) {
  try {
    yield call(ProgramApi.addFeePlanToDetailedProgram, selectedProgram, firebase);
    yield put({
      type: actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to add fee plan to program detailed view", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DETAILED_PROGRAM_REQUEST_FAILED,
    });
  }
}

function getForecastProggression(program, student) {
  if (program.ageRange && student.birthDate) {
    let programMaxMonth = program.ageRange.maxMonth + program.ageRange.maxYear * 12;
    let birthDate = student.birthDate ? moment(student.birthDate, "DD[, ]MMMM[, ]YYYY") : undefined;

    let studentAge = moment.duration(moment().diff(birthDate));
    let studentAgeInYear = studentAge.years();
    let studentAgeInMonth = studentAge.months();

    let programStdMaxMonth = studentAgeInMonth + studentAgeInYear * 12;

    return programMaxMonth - programStdMaxMonth;
  } else {
    return "NA";
  }
}

function getFields(firebase) {
  if (firebase.schoolConfig.genderNeutral) {
    return [
      "programName",
      "programStatus",
      "programClassroomNames",
      "capacity",
      "studentName",
      "studentStatus",
      "deactivationDate",
      "admissionNo",
      "classroomName",
      "groups",
      "studentStartDate",
      "studentEndDate",
      "weekdays",
      "birthDate",
      "age",
      "gender",
      "bloodGroup",
      "allergies",
      "preferredName",
      "medication",
      "eligibleToPromote",
      "forecastForProgression",
      "parent1Name",
      "parent1Email",
      "parent1CountryCode",
      "parent1Number",
      "parent1Company",
      "parent2Name",
      "parent2Email",
      "parent2CountryCode",
      "parent2Number",
      "parent2Company",
      "address",
      "createdAt",
      "admissionDate",
      "kioskCode",
      "emergencyContactName",
      "emergencyContactNumber",
    ];
  } else {
    return [
      "programName",
      "programStatus",
      "programClassroomNames",
      "capacity",
      "studentName",
      "studentStatus",
      "deactivationDate",
      "admissionNo",
      "classroomName",
      "groups",
      "studentStartDate",
      "studentEndDate",
      "weekdays",
      "birthDate",
      "age",
      "gender",
      "bloodGroup",
      "allergies",
      "preferredName",
      "medication",
      "eligibleToPromote",
      "forecastForProgression",
      "fatherName",
      "fatherEmail",
      "fatherCountryCode",
      "fatherNumber",
      "fatherCompany",
      "motherName",
      "motherEmail",
      "motherCountryCode",
      "motherNumber",
      "motherCompany",
      "address",
      "createdAt",
      "admissionDate",
      "kioskCode",
      "emergencyContactName",
      "emergencyContactNumber",
    ];
  }
}
function getProgramStatus(startDate, endDate) {
  var status = "";
  if (moment().isBetween(startDate, endDate, undefined, "[]")) {
    status = "Active";
  } else if (moment().isBefore(startDate, "day")) {
    status = "Upcoming";
  } else {
    status = "Expired";
  }
  return status;
}
function getParentFields(firebase, student, row) {
  var motherPrefix = firebase.schoolConfig.genderNeutral ? "parent1" : "mother";
  var fatherPrefix = firebase.schoolConfig.genderNeutral ? "parent2" : "father";

  row[fatherPrefix + "Name"] = student.fatherName;
  row[fatherPrefix + "Email"] = student.fatherEmail;
  row[fatherPrefix + "Number"] = student.fatherNumber;
  row[fatherPrefix + "Company"] = student.fatherCompany;

  row[motherPrefix + "Name"] = student.motherName;
  row[motherPrefix + "Email"] = student.motherEmail;
  row[motherPrefix + "Number"] = student.motherNumber;
  row[motherPrefix + "Company"] = student.motherCompany;
  row[fatherPrefix + "CountryCode"] = student.fatherCountryCode
    ? student.fatherCountryCode
    : student.code
    ? student.code
    : "";
  row[motherPrefix + "CountryCode"] = student.motherCountryCode
    ? student.motherCountryCode
    : student.code
    ? student.code
    : "";

  row[fatherPrefix + "Company"] = student.fatherCompanyName ? student.fatherCompanyName : "";
  row[motherPrefix + "Company"] = student.motherCompanyName ? student.motherCompanyName : "";
}
function* downloadSingleProgramExcel({ selectedProgram, firebase }) {
  try {
    const fields = getFields(firebase);
    const opts = { fields };
    let programList = [];
    let i = selectedProgram;
    let studentsMap = firebase.studentsMap;
    let tags = JSON.parse(getItem("groupList"));
    let tagNameList = [];
    tags.forEach((ele) => {
      tagNameList.push(ele.name);
    });

    let programClasses = [];
    i.classroom &&
      i.classroom.forEach((ele) => {
        programClasses.push(ele.name);
      });
    if (i.student) {
      for (let index in i.student) {
        let stu = i.student[index];
        var student = {};
        if (studentsMap.has(stu.studentId)) {
          student = studentsMap.get(stu.studentId);
        }
        var row = {};
        row.programName = i.name;
        row.programStatus = getProgramStatus(i.startDate, i.endDate);
        row.capacity = i.maxCapacity;
        row.programClassroomNames = programClasses.toString();
        row.studentStartDate = moment(stu.startDate).format("DD-MM-YYYY");
        row.studentEndDate = moment(stu.endDate).format("DD-MM-YYYY");
        row.studentName = student.name;
        row.admissionNo = student.admissionNumber;
        row.birthDate = student.birthDate;
        row.bloodGroup = student.bloodGroup;
        row.allergies = student.allergies;
        row.address = student.address;
        row.classroomName = student.classList
          ? student.classList.toString()
          : student.classroomName;
        row.weekdays = stu.weekdays ? stu.weekdays.toString() : "";
        row.forecastForProgression = getForecastProggression(i, student);
        let ageObj = UserFilterAction.eligibleToPromote(stu, i, student, firebase);
        row.age = ageObj.age ? ageObj.age : "";
        row.eligibleToPromote = ageObj.eligibleToPromote ? "Eligible" : "Not eligible";

        row.gender = student.gender;
        row.createdAt = student.dateCreated ? moment(student.dateCreated).toDate() : "";
        row.admissionDate = student.admissionDate ? moment(student.admissionDate).toDate() : "";

        row.kioskCode = student.kioskCode ? student.kioskCode : "";

        row.groups = "";
        if (student.tags) {
          student.tags.forEach((tagObject) => {
            if (tagNameList.includes(tagObject.name)) {
              row.groups += tagObject.name + ",";
            }
          });
        }
        row.emergencyContactName = student.emergencyContactName ? student.emergencyContactName : "";
        row.emergencyContactNumber = student.emergencyNumber ? student.emergencyNumber : "";
        row.medication = student.medicine ? student.medicine : "";
        row.preferredName = student.preferredName ? student.preferredName : "";
        if (student.deactivated) {
          row.deactivationDate = student.deactivationDate
            ? moment(student.deactivationDate).toDate()
            : 0;
        }
        row.studentStatus = student.status ? student.status : "Active";

        if (student.childConfig) {
          for (let ind in student.childConfig) {
            let propVal = student.childConfig[ind];
            let propsName = propVal.name;
            let propsValue = propVal.value;
            if (!fields.includes(propsName)) {
              fields.push(propsName);
            }

            if (propVal.dataType.toLowerCase() === "date") {
              row[propsName] = propsValue
                ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                : "";
            } else {
              row[propsName] = propsValue ? propsValue : "";
            }
          }
        }

        if (student.fatherConfig) {
          for (let ind in student.fatherConfig) {
            let propVal = student.fatherConfig[ind];
            let propsName = firebase.schoolConfig.genderNeutral
              ? "Parent2_" + propVal.name
              : "Father_" + propVal.name;
            let propsValue = propVal.value;
            if (!fields.includes(propsName)) {
              fields.push(propsName);
            }

            if (propVal.dataType.toLowerCase() === "date") {
              row[propsName] = propsValue
                ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                : "";
            } else {
              row[propsName] = propsValue ? propsValue : "";
            }
          }
        }

        if (student.motherConfig) {
          for (let ind in student.motherConfig) {
            let propVal = student.motherConfig[ind];
            let propsName = firebase.schoolConfig.genderNeutral
              ? "Parent1_" + propVal.name
              : "Mother_" + propVal.name;
            let propsValue = propVal.value;
            if (!fields.includes(propsName)) {
              fields.push(propsName);
            }

            if (propVal.dataType.toLowerCase() === "date") {
              row[propsName] = propsValue
                ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                : "";
            } else {
              row[propsName] = propsValue ? propsValue : "";
            }
          }
        }
        getParentFields(firebase, student, row);
        programList.push(row);
      }
    }

    FilterAction.downloadCsvAsExcel("programs", programList, fields);

    yield put({
      type: actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download all program excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedProgramDetail({ id, firebase, selectedProgram }) {
  try {
    if (selectedProgram) {
      yield put({
        type: actions.GET_SELECTED_PROGRAM_DETAIL_SUCCESS,
        selectedProgram: populateRandomId(selectedProgram),
      });
    } else {
      let data = yield call(ProgramApi.fetchProgramById, id, firebase);
      if (data) {
        yield put({
          type: actions.GET_SELECTED_PROGRAM_DETAIL_SUCCESS,
          selectedProgram: populateRandomId(data),
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch selected program detail view", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function populateRandomId(selectedProgram) {
  let tempProgram = selectedProgram;
  let tempStudents = tempProgram.student ? tempProgram.student : [];
  for (let index in tempStudents) {
    tempStudents[index].randomId = getRandomId();
  }
  tempProgram.student = tempStudents;
  return tempProgram;
}

function getRandomId() {
  return Math.random().toString(36).slice(2);
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.DELETE_STUDENT_FROM_PROGRAM, deleteSelectedStudentFromProgram),
    yield takeLatest(actions.DETAILED_PROGRAM_FETCH_STUDENT, fetchStudentListForDetailedProgram),
    yield takeLatest(actions.ASSIGN_STUDENT_FROM_DETAILED_PROGRAM, assignNewStudentToProgram),
    yield takeLatest(actions.DETAILED_PROGRAM_FETCH_FEE_PLAN, fetchDetailedProgramFeePlan),
    yield takeLatest(actions.ADD_FEE_PLAN_FROM_DETAILED_VIEW, addNewFeePlan),
    yield takeLatest(actions.DOWNLOAD_SINGLE_PROGRAM_EXCEL, downloadSingleProgramExcel),
    yield takeLatest(actions.GET_SELECTED_PROGRAM_DETAIL, fetchSelectedProgramDetail),
  ]);
}
