import antdIT from "antd/lib/locale-provider/it_IT";
import appLocaleData from "react-intl/locale-data/it";
import itMessages from "../locales/it_IT.json";

const itLang = {
  messages: {
    ...itMessages,
  },
  antd: antdIT,
  locale: "it",
  data: appLocaleData,
};
export default itLang;
