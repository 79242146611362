import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import FilterAction from "../Utility/FilterAction";
import { UserSettingApi } from "./userSetting";
const superagent = require("superagent");
//const endpoint = firebase.endpoint
function createChatNode(firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/conversations/" + id).push().key;
  return key;
}

function getParentChatList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var endpoint = firebase.endPointUrl;
  let chatListRef = rsf.ref(branchPath + "/chatList").orderByChild("inverseDate");
  return eventChannel((emitter) => {
    chatListRef.on("value", (snap) => {
      var allChatListModel = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          let classList =
            firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (item.val().chatListModel && item.val().chatListModel.id) {
                let parentId = item.val().chatListModel.id;
                let studentId = item.val().chatListModel.studentId
                  ? item.val().chatListModel.studentId
                  : undefined;
                let allStudents = FilterAction.getStudentList(firebase);
                let student = allStudents.filter((s) => {
                  if (studentId && s.id === studentId) {
                    return s;
                  } else if (s.fatherProfileId && s.fatherProfileId === parentId) {
                    return s;
                  } else if (s.motherProfileId && s.motherProfileId === parentId) {
                    return s;
                  }
                });

                if (student && student.length > 0) {
                  let studentClass = student[0].classList;
                  for (let i in classList) {
                    if (studentClass && studentClass.includes(classList[i])) {
                      allChatListModel.push(item.val());
                      break;
                    }
                  }
                }
              } else {
                allChatListModel.push(item.val());
              }
            }
          } else {
            allChatListModel.push(item.val());
          }
        }
      });
      emitter(allChatListModel);
    });
    return () => {
      chatListRef.off();
      console.log("unsubscribing channel");
    };
  });
}
function getSelectedGroup(firebase, chatList) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var endpoint = firebase.endPointUrl;
  let groupRef = rsf.ref(branchPath + "/broadcastGroups/" + chatList.groupId);

  return eventChannel((emitter) => {
    groupRef.on("value", (snap) => {
      if (snap.val()) emitter(snap.val());
      else emitter({});
    });
    return () => {
      groupRef.off();
      console.log("unsubscribing channel");
    };
  });
}

function getUnreadMessageKey(parentId, teacherId, firebase) {
  var endpoint = firebase.endPointUrl;

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let msgRef = rsf
    .ref(branchPath + "/unreadKey/" + parentId)
    .orderByChild("teacherId")
    .equalTo(teacherId);

  let chatVal = new Map();

  let promise = new Promise((resolve, reject) => {
    msgRef.once("value").then((snapshot) => {
      snapshot.forEach((snap) => {
        if (snap.val() !== null && snap.val().count && snap.val().count > 0) {
          chatVal.set(parentId, snap.val().count);
        }
      });
      resolve(chatVal);
    });
  });
  return promise;
}

function resetTeacherUnread(teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/totalUnread/" + teacherId).set(0);
}

function getParentChats(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf.ref(branchPath + "/conversations/" + parentId).orderByChild("inversdate");
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() && item.val().id && item.val().createdAt) {
          x.push({
            ...item.val(),
            nodeId: item.key,
          });
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}
function getChats(conversationId, firebase, chatListModel) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf
    .ref(branchPath + "/conversations/" + conversationId)
    .orderByChild("inversdate");
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() && item.val().id && item.val().createdAt) {
          if (chatListModel.mode.toLowerCase() == "group") {
            if (item.val().participantIds?.includes(firebase.user.id)) {
              x.push({
                ...item.val(),
                nodeId: item.key,
              });
            }
          } else
            x.push({
              ...item.val(),
              nodeId: item.key,
            });
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}
async function sendMessage(chatList, message, urls, firebase) {
  let parentName;
  var endpoint = firebase.endPointUrl;

  if (firebase.selectedMode == "parent")
    parentName =
      firebase.student.fatherProfileId == firebase.user.id
        ? firebase.student.fatherName
        : firebase.student.motherName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  superagent
    .post(endpoint + "woodlandApi/message/sendMessage?centerId=" + firebase.sbDbName)
    .send({
      chatListModel: chatList,
      message,
      userId: firebase.user.id,
      imageUrl: urls,
      userName: firebase.selectedMode == "parent" ? parentName : firebase.teacher.name,
      platform: "Web",
    })

    .set("Content-Type", "application/json")
    .set("accept", "json")

    .set({
      Authorization: "Bearer " + authToken,
    })
    .end(function (err, res) { });
}
function putChatMessage(parentId, message, nodeKey, urls, firebase, isPersonalMessage) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageDate = new Date();
  // var message = message;
  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf.ref(branchPath + "/conversations/" + parentId + "/" + nodeKey).update({
    createdAt: createdAt,
    id: firebase?.teacher?.id ?? firebase.user.id,
    name:
      firebase?.teacher?.name ??
      (firebase.student.motherProfileId == firebase.user.id
        ? firebase.student.motherName
        : firebase.student.fatherName),
    inversdate: -Math.abs(messageDate.getTime()),
    senderId: isPersonalMessage ? firebase.user.id : "school",
    status: "Sent",
    imageUrl: urls.length > 0 ? urls[0] : null,
    text: message,
    read: false,
    platform: "web",
  });
}

function getChatListOfSelectedParent(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var promise = new Promise(function (resolve, reject) {
    let chatList = {};
    rsf
      .ref(branchPath + "/chatList/" + parentId)
      .once("value")
      .then((snap) => {
        chatList = snap.val();
        resolve(chatList);
      });
  });
  return promise;
}
function getChatListOfParentPersonal(parentId, firebase, chatListModalId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var promise = new Promise(function (resolve, reject) {
    let chatList = {};
    rsf
      .ref(branchPath + "/privateChatRef/" + parentId + "/" + chatListModalId)
      .once("value")
      .then((snap) => {
        chatList = snap.val();
        resolve(chatList);
      });
  });
  return promise;
}

function updateUnreadCount(chatListObject, parentId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var newChatListObject = {
    chatListModel: chatListObject.chatListModel ? chatListObject.chatListModel : null,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: "school",
    parentUnreadMessageCount: chatListObject.parentUnreadMessageCount
      ? chatListObject.parentUnreadMessageCount + 1
      : 1,
    teacherUnreadMessageCount: 0,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}
function updatePrivateChatList(chatListObject, parentId, message, firebase, id) {
  let teacherId = firebase.user.id;

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var newChatListObject = {
    conversationId: chatListObject.conversationId,
    groupId: null,
    id: id ? id : chatListObject.id,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: firebase.user.id,
    mode: "single",
    parentId: parentId,
    parentLastAccess: chatListObject.parentLastAccess
      ? chatListObject.parentLastAccess
      : moment().valueOf(),
    parentName: chatListObject.parentName,

    read: false,
    relation: chatListObject.relation,
    studentId: chatListObject.studentId,
    studentName: chatListObject.studentName,
    teacherId: firebase.user.id,
    teacherLastAccess: moment().valueOf(),
    teacherName: firebase.teachersMap[teacherId].name,
    teacherProfileUrl: firebase.teachersMap[teacherId].profileImageUrl,
    unreadCount: (chatListObject.unreadCount += 1),
  };
  let parentProfileUrl = FilterAction.getPrivateChatProfileIcon(chatListObject, firebase);
  if (parentProfileUrl) newChatListObject.parentProfileUrl = parentProfileUrl;
  let chatId = id ? id : chatListObject.id;
  rsf.ref(branchPath + "/privateChatRef/" + parentId + "/" + chatId).update(newChatListObject);

  newChatListObject.unreadCount = 0;

  rsf.ref(branchPath + "/privateChatRef/" + teacherId + "/" + chatId).update(newChatListObject);
}
function updateChatListForBothParent(chatListObject, parentId, message, firebase, id, student) {
  let teacherId = firebase.user.id;

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var newChatListObject = {
    conversationId: chatListObject.conversationId,
    groupId: null,
    id: id ? id : chatListObject.id,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: firebase.user.id,
    mode: "Both",
    parentId: chatListObject.studentId,
    parentLastAccess: chatListObject.parentLastAccess
      ? chatListObject.parentLastAccess
      : moment().valueOf(),
    parentName: chatListObject.parentName,

    read: false,
    relation: chatListObject.relation,
    studentId: chatListObject.studentId,
    studentName: chatListObject.studentName,
    teacherId: firebase.user.id,
    teacherLastAccess: moment().valueOf(),
    teacherName: firebase.teachersMap[teacherId].name,
    teacherProfileUrl: firebase.teachersMap[teacherId].profileImageUrl,
    unreadCount: (chatListObject.unreadCount += 1),
  };

  let chatId = id ? id : chatListObject.id;
  rsf
    .ref(branchPath + "/privateChatRef/" + student.fatherProfileId + "/" + chatId)
    .update(newChatListObject);
  rsf
    .ref(branchPath + "/privateChatRef/" + student.motherProfileId + "/" + chatId)
    .update(newChatListObject);

  newChatListObject.unreadCount = 0;

  rsf.ref(branchPath + "/privateChatRef/" + teacherId + "/" + chatId).update(newChatListObject);
}
function updatePrivateChatTeacher(
  chatListObject,

  message,
  firebase,
  teacher,
  id
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  if (chatListObject.mode.toLowerCase() == "single") {
    var newChatListObject = {
      conversationId: chatListObject.conversationId,
      groupId: null,
      id: id ? id : chatListObject.id,
      inverseDate: -moment().valueOf(),
      lastMessage: message,
      lastSenderId: firebase.user.id,
      mode: "single",
      parentId: firebase.user.id,
      parentLastAccess: chatListObject.parentLastAccess
        ? chatListObject.parentLastAccess
        : moment().valueOf(),
      parentName: chatListObject.parentName,

      read: false,
      relation: chatListObject.relation,
      studentId: chatListObject.studentId,
      studentName: chatListObject.studentName,
      teacherId: teacher.id,
      teacherLastAccess: moment().valueOf(),
      teacherName: teacher.name,
      teacherProfileUrl: teacher.profileImageUrl ? teacher.profileImageUrl : null,

      unreadCount: (chatListObject.unreadCount += 1),
      parentProfileUrl: chatListObject.parentProfileUrl ? chatListObject.parentProfileUrl : null,
    };

    let chatId = id ? id : chatListObject.id;
    rsf.ref(branchPath + "/privateChatRef/" + teacher.id + "/" + chatId).update(newChatListObject);

    newChatListObject.unreadCount = 0;

    rsf
      .ref(branchPath + "/privateChatRef/" + firebase.user.id + "/" + chatId)
      .update(newChatListObject);
  } else {
    // in case of both parent chatList object is always old
    chatListObject.lastMessage = message;
    chatListObject.parentLastAccess = moment().valueOf();
    chatListObject.unreadCount += 1;
    rsf.ref(branchPath + "/privateChatRef/" + teacher.id + "/" + chatId).update(chatListObject);
    chatListObject.unreadCount = 0;
    rsf
      .ref(branchPath + "/privateChatRef/" + firebase.student.fatherProfileId + "/" + chatId)
      .update(chatListObject);
    rsf
      .ref(branchPath + "/privateChatRef/" + firebase.student.motherProfileId + "/" + chatId)
      .update(chatListObject);
  }
}

function resetTeacherUnreadMessageCount(parentId, chatListObject, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let newChatListObject = chatListObject;
  newChatListObject.teacherUnreadMessageCount = 0;

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function resetUnreadMessageKey(parentId, teacherId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/unreadKey/" + parentId + "/" + teacherId).update({ count: 0 });
}

function putChatMessageByParent(parentId, message, nodeKey, urls, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageDate = new Date();

  let studentData = firebase.student;

  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf.ref(branchPath + "/conversations/" + parentId + "/" + nodeKey).update({
    createdAt: createdAt,
    id: parentId,
    name:
      parentId === studentData.fatherProfileId
        ? studentData.fatherName
        : studentData.motherName
          ? studentData.motherName
          : "",
    inversdate: -Math.abs(messageDate.getTime()),
    senderId: parentId,
    status: "Sent",
    imageUrl: urls.length > 0 ? urls[0] : null,
    text: message,
    platform: "web",
  });
}

function updateUnreadCountByParent(chatListObject, parentId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var count = 0;
  if (chatListObject !== undefined && chatListObject.teacherUnreadMessageCount !== undefined) {
    count = chatListObject.teacherUnreadMessageCount;
    count = count + 1;
  } else {
    count = count + 1;
  }

  var newChatListObject = {
    chatListModel: chatListObject.chatListModel ? chatListObject.chatListModel : null,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: parentId,
    parentUnreadMessageCount: 0,
    teacherUnreadMessageCount: count,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function resetParentUnreadMessageInChatlist(parentId, chatListObject, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let newChatListObject = chatListObject;
  newChatListObject.parentUnreadMessageCount = 0;
  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function updateTeacherUnreadKey(parentId, teacherId, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/unreadKey/" + parentId + "/" + teacherId).update(obj);
}

function getParentSideChats(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf.ref(branchPath + "/conversations/" + parentId).orderByChild("inversdate");
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() && item.val().id && item.val().createdAt) {
          x.push({
            ...item.val(),
            nodeId: item.key,
          });
        }
      });

      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}

function createChatListByParent(parentId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let student = firebase.student;
  let parentName;
  let relation = "Mother";
  if (student.motherProfileId && student.motherProfileId === parentId) {
    parentName = student.motherName;
  } else if (student.fatherProfileId && student.fatherProfileId === parentId) {
    parentName = student.fatherName;
    relation = "Father";
  }

  let chatListModel = {
    id: parentId,
    inverseDate: 0,
    name: parentName ? parentName : "",
    parentunreadMessageCount: 0,
    relation: relation,
    studentName: student.name,
    unreadMessageCount: 0,
    studentId: student.id,
  };

  var newChatListObject = {
    chatListModel: chatListModel,
    inverseDate: -moment().valueOf(),
    lastMessage: message,
    lastSenderId: parentId,
    parentUnreadMessageCount: 0,
    teacherUnreadMessageCount: 1,
  };

  rsf.ref(branchPath + "/chatList/" + parentId).update(newChatListObject);
}

function getStaffChatList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let teacher = firebase.teacher;
  let teacherId = teacher.id;

  let messageRef = rsf.ref(branchPath + "/teacherChatList/" + teacherId);
  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      let x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing staff query");
    };
  });
}

function getGroup(firebase, groupId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf.ref(branchPath + "/broadcastGroups/" + groupId);
  let group;
  var groupPromise = new Promise(function (resolve, reject) {
    messageRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        group = snap.val();
      }
      resolve(group);
    });
  });
  return groupPromise;
}
function getTeacherChats(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let messageRef = rsf
    .ref(branchPath + "/teacherConversationsReference/" + id)
    .orderByChild("inverseDate");

  return eventChannel((emit) => {
    messageRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      messageRef.off();
      console.log("unsubscribing query");
    };
  });
}

function getTeacherConversationUniqueNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/teacherConversationsReference").push().key;
  return key;
}

function putStaffChatMessage(nodeKey, teacherId, message, uniqueNode, urls, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var messageDate = new Date();

  var singleMessage = message[0];

  let createdAt = {
    date: messageDate.getDate(),
    day: messageDate.getDay(),
    hours: messageDate.getHours(),
    minutes: messageDate.getMinutes(),
    month: messageDate.getMonth(),
    seconds: messageDate.getSeconds(),
    time: messageDate.getTime(),
    timezoneOffset: messageDate.getTimezoneOffset(),
    year: messageDate.getFullYear(),
  };

  rsf.ref(branchPath + "/teacherConversationsReference/" + nodeKey + "/" + uniqueNode).update({
    createdAt: createdAt,
    id: uniqueNode,
    name: firebase.teacher.name,
    inversdate: -Math.abs(messageDate.getTime()),
    senderId: teacherId,
    status: "Sent",
    imageUrl: urls.length > 0 ? urls[0] : null,
    text: singleMessage,
    read: false,
    platform: "web",
  });
}

function updateStaffChatListModal(senderId, receiverId, chatObj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teacherChatList/" + senderId + "/" + receiverId).update(chatObj);
}

function getTeacherChatListById(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(branchPath + "/teacherChatList/" + teacherId + "/" + senderId);

  let chatVal = {};
  let promise = new Promise(function (resolve, reject) {
    messageRef.once("value").then(function (snapshot) {
      if (snapshot.val() !== null) {
        chatVal = snapshot.val();
      }
      resolve(chatVal);
    });
  });
  return promise;
}
function deleteTeacherMsg(msg, firebase, nodeId, type) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var messageRef;
  if (type == "parent" || type == "parentSide") {
    messageRef = rsf.ref(branchPath + "/conversations/" + nodeId + "/" + msg.nodeId);
  } else
    messageRef = rsf.ref(branchPath + "/teacherConversationsReference/" + nodeId + "/" + msg.id);
  messageRef.update(msg);
}
async function deletePrivateMessage(msg, firebase, chatListModel, type, isLatestMessage) {
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + "woodlandApi/message/deleteMessage" + "?centerId=" + firebase.sbDbName)

      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .send({
        message: msg,
        chatListModel,
        latestMessage: isLatestMessage,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}

function setReceiverReadStatus(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(branchPath + "/teacherChatList/" + teacherId + "/" + senderId);
  messageRef.update({ read: true });
}
function setSenderUnreadCount(teacherId, senderId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var messageRef = rsf.ref(branchPath + "/teacherChatList/" + teacherId + "/" + senderId);
  messageRef.update({ unreadCount: 0 });
}
function getConversationId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/conversations/").push().key;
  return key;
}
async function getChatList(firebase) {
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .get(
        endpoint +
        "woodlandApi/message/parentChatlistModels/" +
        firebase.user.id +
        "?centerId=" +
        firebase.sbDbName
      )

      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
async function getTeacherChat(firebase, studentId, parentId) {
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .get(
        endpoint +
        "woodlandApi/message/teacherChatlistModels?studentId=" +
        studentId +
        "&parentId=" +
        parentId +
        "&centerId=" +
        firebase.sbDbName
      )

      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
async function editGroup(group, firebase, groupId) {
  group["id"] = groupId;
  group["editedBy"] = firebase.teacher.name;
  group["editedOn"] = moment().valueOf();
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + "woodlandApi/message/editGroup" + "?centerId=" + firebase.sbDbName)
      .send({
        ...group,
        userId: firebase.user.id,
      })
      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
async function createGroup(group, firebase) {
  group["createdBy"] = firebase.user.id;
  group["dateCreated"] = moment.now();
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + "woodlandApi/message/createGroup" + "?centerId=" + firebase.sbDbName)
      .send({
        ...group,
        createdById: firebase.user.id,
      })

      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
async function resetUnreadCount(firebase, chatListId) {
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    var endpoint = firebase.endPointUrl;
    superagent
      .post(endpoint + "woodlandApi/message/resetUnreadCount" + "?centerId=" + firebase.sbDbName)
      .send({
        userId: firebase.user.id,
        chatListModelId: chatListId,
      })
      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
function createChatListId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/privateChatRef/" + firebase.user.id).push().key;
  return key;
}
function getInitialSelection(studentId, group) {
  let filteredItem = group?.items?.filter((i) => studentId == i.studentId) ?? [];
  if (filteredItem.length > 0) {
    let item = filteredItem[0];
    return {
      motherSelected: item.motherSelected,
      fatherSelected: item.fatherSelected,
    };
  } else {
    return {
      motherSelected: false,
      fatherSelected: false,
    };
  }
}
function getInitialData(firebase, group) {
  let studentArray = Array.from(firebase.studentsMap.values());

  let teacherMap = new Map();
  let teachersList = Object.values(firebase.teachersMap);
  let studentMapGroup = new Map();
  let studentList = studentArray
    .map((item) => {
      let { fatherSelected, motherSelected } = getInitialSelection(item.id, group);
      if (item.status.toLowerCase() == "active") {
        let obj = {
          id: item.id,
          name: item.name,
          fatherProfileId: item.fatherProfileId,
          motherProfileId: item.motherProfileId,
          fatherSelected,
          motherSelected,
          fatherName: item.fatherName,
          motherName: item.motherName,
          classList: item.classList,
          bothSelected: false,
          profileImageUrl: item.profileImageUrl,
          gender: item.gender,
          fatherImage: item.fatherImage,
          motherImage: item.motherImage,
          tags: item.tags
        };
        studentMapGroup.set(item.id, obj);
        return obj;
      }
    })
    .filter((i) => i);
  teachersList.map((t) => {
    let obj = {
      id: t.id,
      name: t.name,
      classList: t.classList,
      teacherSelected: group.teacherList.includes(t.id),
      profileImageUrl: t.profileImageUrl,
      gender: t.gender,
      groups: t.groups
    };
    let key = t.id;

    teacherMap.set(key, obj);
  });

  return {
    teacherMap,
    studentMapGroup,
  };
}
async function fetchGroupDetails(firebase, groupId) {
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .get(endpoint + "woodlandApi/message/groupInfo/" + groupId + "?centerId=" + firebase.sbDbName)

      .set("Content-Type", "application/json")
      .set("accept", "json")

      .set({
        Authorization: "Bearer " + authToken,
      })
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
function getPrivateChatList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let chatListRef = rsf
    .ref(branchPath + "/privateChatRef/" + firebase.user.id)
    .orderByChild("inverseDate");

  return eventChannel((emitter) => {
    chatListRef.on("value", (snap) => {
      var allChatListModel = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          switch (item.val().mode.toLowerCase()) {
            case "single":
            case "both":
              if (
                firebase.teacher &&
                !(firebase.selectedMode == "parent") &&
                item.val().userType == "teacher"
              ) {
                allChatListModel.push(item.val());
              } else if (
                firebase.student &&
                firebase.selectedMode == "parent" &&
                item.val().userType == "parent"
              ) {
                if (firebase.student.id == item.val().studentId) {
                  allChatListModel.push(item.val());
                }
              }
              break;
            case "group":
              if (
                firebase.teacher &&
                !(firebase.selectedMode == "parent") &&
                item.val().userType == "teacher"
              ) {
                allChatListModel.push(item.val());
              } else if (
                firebase.student &&
                firebase.selectedMode == "parent" &&
                item.val()?.studentIds?.includes(firebase.student.id)
              ) {
                allChatListModel.push(item.val());
              }
          }
        }
      });
      emitter(allChatListModel);
    });
    return () => {
      chatListRef.off();
      console.log("unsubscribing Private Chatlist channel");
    };
  });
}

function updateChatModel(firebase, chatModel, mode, otherTeacherChatModel) {
  let rsf, branchPath, chatModelRef;

  switch (mode) {
    case "parent":
    case "parentSide":
      chatModel.lastMessage = "Deleted Message";

      rsf = firebase.secondaryDb;
      branchPath = firebase.sbp;
      chatModelRef = rsf.ref(branchPath + "/chatList/" + chatModel.chatListModel.id);
      chatModelRef.update(chatModel);
      break;
    case "teacher":
      chatModel.lastMessage = "Deleted Message";
      rsf = firebase.secondaryDb;
      branchPath = firebase.sbp;
      chatModelRef = rsf.ref(
        branchPath + "/teacherChatList/" + firebase.user.id + "/" + chatModel.teacherId
      );
      chatModelRef.update(chatModel);

      rsf = firebase.secondaryDb;
      branchPath = firebase.sbp;
      chatModelRef = rsf.ref(
        branchPath + "/teacherChatList/" + chatModel.teacherId + "/" + firebase.user.id
      );
      chatModelRef.update(otherTeacherChatModel);
  }
}
function getParticipantIds(firebase, groupId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let groupParticipantIdRef = rsf.ref(branchPath + "/groupParticipant/" + groupId);

  return eventChannel((emitter) => {
    groupParticipantIdRef.on("value", (snap) => {
      if (snap.val()) emitter(snap.val());
      else emitter([]);
    });
    return () => {
      groupParticipantIdRef.off();
      console.log("unsubscribing channel");
    };
  });
}
async function getTotalUnreadCountPrivateMsg(firebase, requestPayload) {
  var endpoint = firebase.endPointUrl;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + "woodlandApi/message/totalUnreadCount" + "?centerId=" + firebase.sbDbName)

      .set("Content-Type", "application/json")
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(requestPayload)
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return p1;
}
function setConversation(conversationId, message, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/conversations/" + conversationId + "/" + message.id).set(message);
}

function getPrivateChatListById(firebase, teacherId){
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let chatRef = rsf.ref(branchPath + "/privateChatRef/" + teacherId).orderByChild("inverseDate");
  let chatList = [];
  var chatPromise = new Promise(function (resolve, reject) {
    chatRef.once("value").then(function (snapshot) {
      snapshot.forEach((item) => {
        if(item.val()){
          if(item.val().userType == "teacher"){
            chatList.push(item.val());
          }else if(item.val().mode && item.val().mode.toLowerCase() === "group"){
            chatList.push(item.val());
          }
        }
      });
      resolve(chatList)
    });
  });
  return chatPromise;


}

export const QueryApi = {
  getInitialData,
  resetUnreadCount,
  getGroup,
  fetchGroupDetails,
  getPrivateChatList,
  createGroup,
  updateChatModel,
  deleteTeacherMsg,
  getParentChatList,
  getUnreadMessageKey,
  resetTeacherUnread,
  getParentChats,
  createChatNode,
  putChatMessage,
  getChatListOfSelectedParent,
  updateUnreadCount,
  resetTeacherUnreadMessageCount,
  resetUnreadMessageKey,
  putChatMessageByParent,
  updateUnreadCountByParent,
  resetParentUnreadMessageInChatlist,
  updateTeacherUnreadKey,
  getParentSideChats,
  createChatListByParent,
  getStaffChatList,
  getTeacherChats,
  getTeacherConversationUniqueNodeId,
  putStaffChatMessage,
  updateStaffChatListModal,
  getTeacherChatListById,
  setReceiverReadStatus,
  setSenderUnreadCount,
  getChats,
  getChatListOfParentPersonal,
  createChatListId,
  updatePrivateChatList,
  getConversationId,
  deletePrivateMessage,
  updatePrivateChatTeacher,
  updateChatListForBothParent,
  getChatList,
  sendMessage,
  getTeacherChat,
  editGroup,
  getSelectedGroup,
  getTotalUnreadCountPrivateMsg,
  setConversation,
  getParticipantIds,
  getPrivateChatListById
};
