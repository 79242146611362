import { Layout, Progress, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import CounterBadge from "../../components/CounterBadge";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";
import IntlMessages from "../../components/utility/intlMessages";
import emailIcon from "../../image/emailIcon.svg";
import groupChat from "../../image/groupChat2.png";
import activityPageActions from "../../redux/activities/actions";
import allReportsActions from "../../redux/allReports/actions";
import appActions from "../../redux/app/actions";
import applicationActions from "../../redux/application/actions";
import assessmentActions from "../../redux/assessment/actions";
import assignmentsActions from "../../redux/assignments/actions";
import classroomActions from "../../redux/classroom/actions";
import complainsActions from "../../redux/complains/actions";
import customActivityActions from "../../redux/customActivity/actions";
import eventsActions from "../../redux/events/actions";
import expenseActions from "../../redux/ExpenseManagement/actions";
import feeActions from "../../redux/fee/actions";
import holidayActions from "../../redux/holiday/actions";
import invoiceActions from "../../redux/invoice/actions";
import invoiceTemplateActions from "../../redux/invoiceTemplate/actions";
import landingPageActions from "../../redux/landingPage/actions";
import leadFormsActions from "../../redux/leadForms/actions";
import leadsAction from "../../redux/leads/actions";
import learningActions from "../../redux/learning/actions";
import leavesActions from "../../redux/leaves/actions";
import lessonStatsActions from "../../redux/lessonStats/actions";
import newActivityActions from "../../redux/newActivity/actions";
import newsletterActions from "../../redux/Newsletter/actions";
import parentNotesActions from "../../redux/parentNotes/actions";
import permissionActions from "../../redux/permissions/actions";
import programActions from "../../redux/program/actions";
import queryActions from "../../redux/query/actions";
import scheduleActions from "../../redux/schedule/actions";
import schoolDetailsActions from "../../redux/schoolDetails/actions";
import sentNewsletterActions from "../../redux/SentNewsletter/actions";
import staffAttendanceActions from "../../redux/staffAttendance/actions";
import liveAttendanceRatioActions from "../../redux/staffToStudentAttendanceLiveRatio/actions";
import studentActions from "../../redux/student/actions";
import studentAttendanceActions from "../../redux/studentAttendance/actions";
import submissionsActions from "../../redux/submissions/actions";
import tagActions from "../../redux/tag/actions";
import teacherActions from "../../redux/teacher/actions";
import teacherDocumentActions from "../../redux/teacherDocument/actions";
import userSettingActions from "../../redux/userSetting/actions";
import virtualClassActions from "../../redux/virtualClass/actions";
import { themeConfig } from "../../settings";
import themes from "../../settings/themes";
import colors from "../../Utility/colorFactory";
import { getItem } from "../../Utility/encryptedStorage";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import SearchedStringActions from "../../Utility/SearchedStringActions";
import url from "../../Utility/urlBranchSwitch";
import UserFilterAction from "../../Utility/UserFilterActions";
import BranchPicker from "./../../components/branchPicker/branchPicker";
import { withFirebase } from "./../../components/firebase/context";
import { withNotification } from "./../../components/HOCNotificationComponent/context";
import TopbarWrapper from "./topbar.style";
import TopbarNotification from "./topbarNotification";
import TopbarUser from "./topbarUser";
import { Icon } from "antd";
import webFont from "../../Utility/fontSizes";
import formatMsg from "../../components/utility/formatMessageUtil";

const { Header } = Layout;

const { resetErrorStatus, getActivitySetting, getInitialValue } = newActivityActions;

const {
  toggleCollapsed,
  switchBranchName,
  resetAttachmentProgress,
  initBranchChangeLoader,
  stopBranchChangeLoader,
} = appActions;

const { initTagData, getStudentsToAssignTag, getClassroomForTag } = tagActions;

const { initClassroomData } = classroomActions;

const { initTeacherData, getTeacherClassroomDetails } = teacherActions;

const { initStudentData, getClassroomDetails, getTags } = studentActions;

const {
  initProgramData,
  getClassroomDataForProgram,
  getStudentLevelProgram,
  getStudentAttendanceData,
  reloadFte,
} = programActions;

const {
  getMilestones,
  getDevelopmentAreas,
  getLabel,
  getLessons,
  getWeeklyLesson,
  getClassroom,
  getCategory,
  getAssessmentProgressParam,
} = learningActions;

const { getStudentAssessment, getAllStudents } = assessmentActions;

const { getFeePlan, getFeeComponent, getFeePrograms } = feeActions;

const { initLeadsData, getLeadsAdditionalField, getStatus } = leadsAction;

const { getLeadsTableData, getLeadStatus } = leadFormsActions;

const {
  getUnreadAlertNotification,
  getDashboardMeetings,
  getDashboardStatistics,
  getActivityDashboardStats,
  getLearningDashboardStats,
  getLeadDashboardStats,
  getFinanceDashboardStats,
  callBrowserRefresh,
  resetBrowserRefresh,
} = landingPageActions;

const {
  getActivityClassrooms,
  getActivityTodaysPost,
  getUpcomingMeetingReminders,
  getStudentLevelActivities,
  getStudentForActivityView,
  getCustomLabels,
  getMeetingReport,
  getMeetingActivity,
  getStaffActivityTodaysPost,
} = activityPageActions;

const {
  initStudentAttendanceData,
  getAllStudentsForStudentAttendance,
  getAllClassroomsForStudentAttendance,
  getStudentNewAttendance,
} = studentAttendanceActions;

const {
  initStaffAttendanceData,
  getAllStaffs,
  getAllClassroomsForStaffAttendance,
  getNewStaffAttendance,
} = staffAttendanceActions;

const {
  initInvoiceData,
  getInvoiceAggregated,
  getAggregatedInvoiceDetail,
  fetchStudentData,
  fetchStudentsFeePlan,
  fetchTaxRefList,
} = invoiceActions;

const { initInvoiceTemplateData } = invoiceTemplateActions;

const { initDocumentData } = teacherDocumentActions;

const { getAllComplains } = complainsActions;

const { getAllLeaves, getAllStaffLeaves } = leavesActions;

const { getAllParentNotes } = parentNotesActions;

const { getAllHolidays, getAllClassrooms } = holidayActions;

const { getAllEvents } = eventsActions;

const { getSchoolDetails } = schoolDetailsActions;

const {
  getSchoolSettingDetails,
  getUserDetails,
  getCameraReferences,
  schoolConfiguration,
  getActiveActivities,
} = userSettingActions;

const { getClassroomForSchedule, getSchedule } = scheduleActions;

const { getVirtualClasses, getFilterClassrooms } = virtualClassActions;

const { getAllNewsLetter, fetchClassrooms, getAllTemplates } = newsletterActions;

const { initNewsletterData } = sentNewsletterActions;

const { getLessonStats } = lessonStatsActions;

const { getAssignments } = assignmentsActions;

const { getQueryList, getStaffQueryList } = queryActions;

const { getPermissionList } = permissionActions;

const { getStudentSubmissionStats } = submissionsActions;

const { getApplicationForms } = applicationActions;
const { getLiveRatio, getLiveRatioClassroom, getLiveRatioTeacher, fetchAlert } =
  liveAttendanceRatioActions;

const { getCustomActivities } = customActivityActions;

const { showReports } = allReportsActions;

const { getRegisters } = expenseActions;

const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchChangeRequest: false,
    };
    this.handleWindowClose = this.handleWindowClose.bind(this);
  }

  changeUrl(firebase) {
    var category = "applications";
    if (
      window.location.pathname.includes("formBuilder") ||
      window.location.pathname.includes("formPreview")
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      if (category !== urlParams.get("category")) {
        category = urlParams.get("category");
      }
    }

    const removedSearchUrl = window.location.href.replace(window.location.search, "");
    history.pushState(null, null, removedSearchUrl);
    for (const key in url) {
      if (window.location.pathname.includes(key)) {
        if (key.toLowerCase() === "comments") {
          var newUrl = `/${url[key]}`;
        } else if (
          key.toLowerCase() === "detailedtemplate" ||
          key.toLowerCase() === "invoicetemplate"
        ) {
          if (UserFilterAction.checkPermission(firebase, "invoiceTemplate")) {
            var newUrl = `/dashboard/${url[key]}`;
          } else {
            var newUrl = `/dashboard/${url["detailedFeePlan"]}`;
          }
        } else if (
          key.toLowerCase() === "detailedfeeplan" ||
          key.toLowerCase() === "feestructure"
        ) {
          if (UserFilterAction.checkPermission(firebase, "feeStructure")) {
            var newUrl = `/dashboard/${url[key]}`;
          } else {
            var newUrl = `/dashboard/${url["detailedTemplate"]}`;
          }
        } else if (key.toLowerCase() === "formbuilder" || key.toLowerCase() === "formpreview") {
          if (category === "applications") {
            var newUrl = url[key][0];
          } else {
            var newUrl = url[key][1];
          }
        } else {
          var newUrl = url[key];
        }

        history.pushState(null, null, newUrl);
        return;
      }
    }
  }

  handleWindowClose(e) {
    const { attachmentProgress } = this.props;

    var message = "Warning!\n\nClosing the application will terminate the media being uploaded";
    e.returnValue = message;
    return message;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.attachmentProgress || nextProps.newActivityOperationType) {
      window.addEventListener("beforeunload", this.handleWindowClose);
    } else {
      window.removeEventListener("beforeunload", this.handleWindowClose);
    }

    if (
      !nextProps.error &&
      nextProps.newActivityOperationType === "POST_ACTIVITY_SUCCESS" &&
      this.props.newActivityOperationType !== nextProps.newActivityOperationType
    ) {
      this.props.resetErrorStatus();
      this.props.notification("success", formatMsg("success.postActivity"));
    } else if (
      !nextProps.error &&
      nextProps.newActivityOperationType === "POST_ACTIVITY_SUCCESS_WITH_ATTACHMENT" &&
      this.props.newActivityOperationType !== nextProps.newActivityOperationType
    ) {
      this.props.resetErrorStatus();
      this.props.notification("success", formatMsg("success.postActivityWithAttachment"));
    } else if (
      !nextProps.error &&
      nextProps.newActivityOperationType === "ALL_CENTER_POST_ACTIVITY_SUCCESS" &&
      this.props.newActivityOperationType !== nextProps.newActivityOperationType
    ) {
      this.props.notification("success", formatMsg("success.activityPostAllCenters"));
      this.props.resetErrorStatus();
    } else if (nextProps.error) {
      this.props.notification("error", formatMsg("settings.errorSettingsSaved"));
      this.props.resetErrorStatus();
    }

    let newThis = this;
    if (nextProps.attachmentProgress) {
      if (
        nextProps.attachmentProgress === 100 &&
        newThis.props.attachmentProgress &&
        nextProps.allFilesUploaded
      ) {
        newThis.props.notification("success", formatMsg("notif.attachmentUploadSuccess"));
        newThis.props.resetAttachmentProgress();
      }

      setTimeout(function () {
        newThis.props.resetAttachmentProgress();
      }, 55000);
    }
  }

  shouldComponentUpdate(nextProps) {
    return true;
  }

  initData(firebase) {
    const {
      initStudentData,
      getClassroomDetails,
      getTags,
      initClassroomData,
      initTagData,
      getStudentsToAssignTag,
      getClassroomForTag,
      getActivitySetting,
      getInitialValue,
      initTeacherData,
      getTeacherClassroomDetails,
      initProgramData,
      reloadFte,
      getStudentLevelProgram,
      getStudentAttendanceData,
      getClassroomDataForProgram,
      getMilestones,
      getDevelopmentAreas,
      getLabel,
      getLessons,
      getCategory,
      getAssessmentProgressParam,
      getWeeklyLesson,
      getClassroom,
      getStudentAssessment,
      getAllStudents,
      getFeePlan,
      getFeeComponent,
      getFeePrograms,
      initLeadsData,
      getLeadsAdditionalField,
      getStatus,
      getActivityClassrooms,
      getActivityTodaysPost,
      getStaffActivityTodaysPost,
      getCustomLabels,
      getMeetingReport,
      getMeetingActivity,
      getUpcomingMeetingReminders,
      getStudentLevelActivities,
      getStudentForActivityView,
      initStudentAttendanceData,
      getStudentNewAttendance,
      getAllStudentsForStudentAttendance,
      getAllClassroomsForStudentAttendance,
      initStaffAttendanceData,
      getAllStaffs,
      getAllClassroomsForStaffAttendance,
      initInvoiceData,
      fetchTaxRefList,
      getInvoiceAggregated,
      getAggregatedInvoiceDetail,
      fetchStudentData,
      fetchStudentsFeePlan,
      initInvoiceTemplateData,
      getUnreadAlertNotification,
      getDashboardMeetings,
      getDashboardStatistics,
      getActivityDashboardStats,
      getLearningDashboardStats,
      getLeadDashboardStats,
      getFinanceDashboardStats,
      callBrowserRefresh,
      resetBrowserRefresh,
      initDocumentData,
      getAllComplains,
      getAllLeaves,
      getAllStaffLeaves,
      getAllParentNotes,
      getAllHolidays,
      getAllClassrooms,
      getAllEvents,
      getSchoolDetails,
      getSchoolSettingDetails,
      getUserDetails,
      getCameraReferences,
      schoolConfiguration,
      getActiveActivities,
      getClassroomForSchedule,
      getSchedule,
      getVirtualClasses,
      getFilterClassrooms,
      getAllNewsLetter,
      fetchClassrooms,
      getAllTemplates,
      initNewsletterData,
      getLessonStats,
      getAssignments,
      resetAttachmentProgress,
      resetErrorStatus,
      getQueryList,
      getStaffQueryList,
      getPermissionList,
      getStudentSubmissionStats,
      getApplicationForms,
      getLiveRatio,
      getLiveRatioClassroom,
      getLiveRatioTeacher,
      fetchAlert,
      getCustomActivities,
      getLeadsTableData,
      getLeadStatus,
      showReports,
      getRegisters,

      classChannel,
      tagsChannel,
      teacherChannel,
      studentsChannel,
      programsChannel,
      studentAssessmentChannel,
      learningLessonChannel,
      learningMilestoneChannel,
      weeklyPlanLessonChannel,
      feePlanChannel,
      feeComponentChannel,
      leadsChannel,
      attendanceChannel,
      staffAttendanceChannel,
      invoiceTemplateChannel,
      dashboardStaffAtdChannel,
      dashboardStudentAtdChannel,
      upcomingMeetingChan,
      alertNotificationChan,
      documentChannel,
      complainsChannel,
      leavesChannel,
      parentNotesChannel,
      holidaysChannel,
      eventsChannel,
      schedulesChannel,
      todaysPostChannel,
      meetingReminderChan,
      virtualClassChan,
      newsletterDraftChannel,
      templateChannel,
      sentNewsletterChannel,
      studentAggregatedInvoiceChan,
      assessmentMilestoneChannel,
      queryListChannel,
      parentChatChannel,
      permissionListChan,
      classrooms,
      labelChannel,
      customLabelsChan,
      staffLeavesChannel,
      newDashboardStatsChannel,
      dashboardActivityChannel,
      dashboardLearningChan,
      dashboardLeadsChan,
      dashboardFinanceChan,
      submissionStatsMapChannel,
      staffPostChan,
      staffQueryListChannel,
      teacherChatChannel,
      newDashboardAttendanceStatsChannel,
      newDashboardStaffAttendanceStatsChannel,
      newAttendanceChannel,
      newStaffAttendanceChannel,
      applicationFormChan,
      fetchAlertChannel,
      stopBranchChangeLoader,
      initBranchChangeLoader,
      reportsChannel,
      registerChannel,
    } = this.props;

    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);

    var lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    lastDay.setHours(23, 59, 59, 999);

    let weeklyStart = new Date();
    let weeklyEnd = new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000);

    let weeklyClasses = JSON.parse(getItem("classList"));
    let weeklyClassname = weeklyClasses.length > 0 ? weeklyClasses[0].className : undefined;

    let scheduleDate = new Date();
    var scheduleDateFormat = moment(scheduleDate.getTime()).format("YYYY[-]MM[-]DD");

    let connectStartDate = moment().startOf("month");
    let connectEndDate = moment().endOf("month");

    let initialBranch = [
      {
        dbName: firebase.sbDbName,
        name: firebase.sbp,
      },
    ];

    let selectedFrequency = "Pending";

    if (assessmentMilestoneChannel) {
      assessmentMilestoneChannel.close();
    }

    if (classChannel !== undefined) {
      classChannel.close();
    }

    if (tagsChannel !== undefined) {
      tagsChannel.close();
    }

    if (teacherChannel !== undefined) {
      teacherChannel.close();
    }

    if (studentsChannel !== undefined) {
      studentsChannel.close();
    }

    if (programsChannel !== undefined) {
      programsChannel.close();
    }

    if (studentAssessmentChannel !== undefined) {
      studentAssessmentChannel.close();
    }

    if (learningLessonChannel !== undefined) {
      learningLessonChannel.close();
    }

    if (learningMilestoneChannel !== undefined) {
      learningMilestoneChannel.close();
    }

    if (weeklyPlanLessonChannel !== undefined) {
      weeklyPlanLessonChannel.close();
    }

    if (feePlanChannel !== undefined) {
      feePlanChannel.close();
    }

    if (feeComponentChannel !== undefined) {
      feeComponentChannel.close();
    }

    if (leadsChannel !== undefined) {
      leadsChannel.close();
    }

    if (attendanceChannel !== undefined) {
      attendanceChannel.close();
    }

    if (staffAttendanceChannel !== undefined) {
      staffAttendanceChannel.close();
    }

    if (invoiceTemplateChannel !== undefined) {
      invoiceTemplateChannel.close();
    }

    if (dashboardStudentAtdChannel !== undefined) {
      dashboardStudentAtdChannel.close();
    }

    if (upcomingMeetingChan !== undefined) {
      upcomingMeetingChan.close();
    }

    if (dashboardStaffAtdChannel !== undefined) {
      dashboardStaffAtdChannel.close();
    }

    if (alertNotificationChan !== undefined) {
      alertNotificationChan.close();
    }

    if (documentChannel !== undefined) {
      documentChannel.close();
    }

    if (complainsChannel !== undefined) {
      complainsChannel.close();
    }

    if (leavesChannel !== undefined) {
      leavesChannel.close();
    }

    if (parentNotesChannel !== undefined) {
      parentNotesChannel.close();
    }

    if (holidaysChannel !== undefined) {
      holidaysChannel.close();
    }

    if (eventsChannel !== undefined) {
      eventsChannel.close();
    }

    if (schedulesChannel !== undefined) {
      schedulesChannel.close();
    }

    if (todaysPostChannel !== undefined) {
      todaysPostChannel.close();
    }

    if (meetingReminderChan !== undefined) {
      meetingReminderChan.close();
    }

    if (virtualClassChan !== undefined) {
      virtualClassChan.close();
    }

    if (newsletterDraftChannel !== undefined) {
      newsletterDraftChannel.close();
    }
    if (templateChannel !== undefined) {
      templateChannel.close();
    }

    if (sentNewsletterChannel !== undefined) {
      sentNewsletterChannel.close();
    }

    if (studentAggregatedInvoiceChan) {
      studentAggregatedInvoiceChan.close();
    }

    if (queryListChannel) {
      queryListChannel.close();
    }

    if (parentChatChannel) {
      parentChatChannel.close();
    }

    if (labelChannel) {
      labelChannel.close();
    }

    if (permissionListChan) {
      permissionListChan.close();
    }

    if (customLabelsChan) {
      customLabelsChan.close();
    }

    if (staffLeavesChannel) {
      staffLeavesChannel.close();
    }

    if (newDashboardStatsChannel) {
      newDashboardStatsChannel.close();
    }

    if (newDashboardAttendanceStatsChannel) {
      newDashboardAttendanceStatsChannel.close();
    }

    if (staffQueryListChannel) {
      staffQueryListChannel.close();
    }

    if (teacherChatChannel) {
      teacherChatChannel.close();
    }

    if (newDashboardStaffAttendanceStatsChannel) {
      newDashboardStaffAttendanceStatsChannel.close();
    }

    if (dashboardActivityChannel) {
      dashboardActivityChannel.close();
    }

    if (dashboardLearningChan) {
      dashboardLearningChan.close();
    }

    if (dashboardLeadsChan) {
      dashboardLeadsChan.close();
    }

    if (dashboardFinanceChan) {
      dashboardFinanceChan.close();
    }

    if (submissionStatsMapChannel) {
      submissionStatsMapChannel.close();
    }

    if (staffPostChan) {
      staffPostChan.close();
    }

    if (newAttendanceChannel) {
      newAttendanceChannel.close();
    }

    if (newStaffAttendanceChannel) {
      newStaffAttendanceChannel.close();
    }

    if (applicationFormChan) {
      applicationFormChan.close();
    }
    if (fetchAlertChannel) {
      fetchAlertChannel.close();
    }

    if (reportsChannel) {
      reportsChannel.close();
    }

    if (registerChannel) {
      registerChannel.close();
    }

    resetAttachmentProgress();
    resetErrorStatus();
    initStudentData(25, firebase, "initialCall");
    getClassroomDetails(firebase);
    getTags(firebase);
    initTagData(firebase);
    getStudentsToAssignTag(firebase);
    getClassroomForTag(firebase);
    getActivitySetting(firebase);
    getInitialValue();
    initClassroomData(firebase);
    initTeacherData(firebase, "initialCall");

    getTeacherClassroomDetails(firebase);
    initProgramData(firebase);
    reloadFte(firebase);
    getStudentLevelProgram(firebase);
    getStudentAttendanceData(firebase, moment(), "initCall");
    getClassroomDataForProgram(firebase);
    getClassroom(firebase);
    getMilestones(firebase);
    getDevelopmentAreas(firebase);
    getLabel(firebase);
    getLessons(firebase, [], "INITIAL_LESSON_FETCH");
    getCategory(firebase);
    getAssessmentProgressParam(firebase);
    getWeeklyLesson(weeklyStart, weeklyEnd, weeklyClassname, firebase);
    getAllStudents(firebase);
    getStudentAssessment(firebase);
    getFeePlan(firebase);
    getFeeComponent(firebase);
    getFeePrograms(firebase);
    initLeadsData(startDate, lastDay, firebase, "This Month", "INITIAL_FETCH");
    getLeadsAdditionalField(firebase);
    getStatus(firebase);
    getActivityClassrooms(firebase);
    getActivityTodaysPost(moment().startOf("day"), moment().endOf("day"), firebase);
    getStaffActivityTodaysPost(moment().startOf("day"), moment().endOf("day"), firebase);

    getCustomLabels(firebase, "Activity");
    getMeetingReport(moment(), firebase);
    getMeetingActivity(moment(), firebase);
    getUpcomingMeetingReminders(moment().startOf("day"), moment().endOf("day"), firebase);
    getStudentLevelActivities(
      moment().startOf("day"),
      moment().endOf("day"),
      firebase,
      undefined,
      "INIT_ACTIVITY_LIST"
    );
    getStudentForActivityView(new Date(), firebase);
    initStudentAttendanceData(new Date(), firebase, "INITIAL_STUDENT_ATD_FETCH");
    getStudentNewAttendance(new Date(), firebase, "INITIAL_STUDENT_ATD_FETCH");

    getAllStudentsForStudentAttendance(firebase);

    getAllClassroomsForStudentAttendance(firebase);
    initStaffAttendanceData(new Date(), firebase, "INITIAL_STAFF_ATD_FETCH");
    getNewStaffAttendance(new Date(), firebase, "INITIAL_STAFF_ATD_FETCH");
    getAllStaffs(firebase);
    getAllClassroomsForStaffAttendance(firebase);
    initInvoiceData(firebase);
    getInvoiceAggregated(firebase);
    getAggregatedInvoiceDetail(
      moment().startOf("year"),
      moment().endOf("year"),
      firebase,
      "initial_call"
    );
    fetchStudentData(firebase);
    fetchStudentsFeePlan(firebase);
    initInvoiceTemplateData(firebase);
    getUnreadAlertNotification(firebase);
    getDashboardMeetings(firebase, moment());
    getDashboardStatistics(firebase, moment());
    getActivityDashboardStats(firebase, moment());
    getLearningDashboardStats(firebase, moment());
    getLeadDashboardStats(firebase, moment());
    getFinanceDashboardStats(firebase, moment());
    callBrowserRefresh();
    resetBrowserRefresh();
    initDocumentData(firebase);
    getAllComplains(firebase, connectStartDate, connectEndDate, "initialCall", selectedFrequency);
    getAllLeaves(firebase, connectStartDate, connectEndDate, "initialCall", selectedFrequency);
    getAllStaffLeaves(firebase, undefined, undefined, undefined, selectedFrequency);

    getAllParentNotes(firebase, connectStartDate, connectEndDate, "initialCall", selectedFrequency);

    getAllHolidays(firebase, "initialCall");
    getAllClassrooms(firebase);
    getAllEvents(firebase);
    getSchoolDetails(firebase);
    getSchoolSettingDetails(firebase, "initialCall");
    getUserDetails(firebase);
    getCameraReferences(firebase);
    schoolConfiguration(firebase);
    getActiveActivities(firebase);
    getClassroomForSchedule(firebase);
    getSchedule(scheduleDateFormat, firebase);
    getVirtualClasses(30, firebase, "All Room", new Date(), "initialCall");
    getFilterClassrooms(firebase);
    getAllNewsLetter(firebase);
    fetchClassrooms(firebase);
    getAllTemplates(firebase);

    initNewsletterData(firebase);
    getLessonStats(firebase);
    getAssignments(
      firebase,
      "All Room",
      20,
      "intial_call",
      [],
      moment().startOf("day"),
      moment().endOf("day")
    );

    getQueryList(firebase);

    getStaffQueryList(firebase);

    getPermissionList(firebase);

    getStudentSubmissionStats(moment().startOf("day"), moment().endOf("day"), firebase);

    getApplicationForms(firebase);

    getLiveRatio(firebase);

    getLiveRatioClassroom();

    getLiveRatioTeacher();

    fetchAlert(firebase);

    getCustomActivities(firebase);
    getLeadsTableData(firebase);
    getLeadStatus(firebase);
    fetchTaxRefList(firebase);
    showReports(firebase);
    getRegisters(firebase, moment().startOf("month"), moment().endOf("month"), "This Month");
  }

  getStaffUnreadCount() {
    const { staffQueryList } = this.props;
    if (staffQueryList && staffQueryList.length > 0) {
      let count = 0;
      staffQueryList.forEach((chat) => {
        if (chat.unreadCount && chat.unreadCount > 0) {
          count = count + 1;
        }
      });
      return count;
    }
  }
  render() {
    const { toggleCollapsed, branchName, locale, attachmentProgress, firebase, parentUnreadCount,totalUnreadCountPrivateMsg } =
      this.props;

    let branchList = getItem("branchList");
    branchList = JSON.parse(branchList);
    let schoolName = getItem("schoolName");
    if (schoolName) {
      schoolName = schoolName.charAt(0).toUpperCase() + schoolName.slice(1);
    }

    let selectedMode = getItem("selectedMode");

    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: colors.v2_Cerulean,
      position: "fixed",
      width: "100%",
      lineHeight: "normal",
      // backgroundImage: `url(${v2_mask})`,
      // backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
    };

    let unreadMsgCount = 0;
    if(parentUnreadCount && !isNaN(parentUnreadCount) && FilterPermission.checkIfPermission(
      PermissionStrings.QUERIES_VIEW_REPLY,
      firebase
    )){
      unreadMsgCount = parentUnreadCount;
    }

    if(
      totalUnreadCountPrivateMsg &&
      !isNaN(totalUnreadCountPrivateMsg) &&
       (
        FilterPermission.checkIfPermission(PermissionStrings.PERSONAL_MESSAGING,firebase) || 
        FilterPermission.checkIfPermission(PermissionStrings.GROUP_MESSAGING_VIEW_REPLY,firebase)))
    {
      unreadMsgCount = unreadMsgCount+totalUnreadCountPrivateMsg;
    }

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}
        >
          <div className="isoLeft">
            {this.props.collapsed ? (
              <Icon
                className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
                style={{
                  color: colors.white,
                  cursor: "pointer",
                  fontSize: webFont.doubePica,
                  marginLeft: "10px",
                }}
                type="double-right"
                onClick={toggleCollapsed}
              />
            ) : null}
            <div
              style={{
                height: 50,
                minWidth: 200,
                backgroundColor: colors.v2_blizzard_blue,
                borderRadius: 8,
                border: "1px solid",
                marginLeft: 20,
                borderColor: colors.v2_blizzard_blue,
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  !branchList || (branchList.length && branchList.length === 0)
                    ? "center"
                    : "flex-start",
                alignItems: "center",
              }}
            >
              <p className="isoSchoolName">
                {schoolName !== undefined ? schoolName : null}
                {branchList !== null && selectedMode.toLowerCase() != "parent" ? "," : " "}
              </p>
              {branchList !== null && selectedMode.toLowerCase() != "parent" ? (
                <BranchPicker
                  switchBranchName={(firebase) => {
                    SearchedStringActions.resetSearchValue();
                    this.changeUrl(firebase);
                    window.location.reload();
                  }}
                  branchName={branchName}
                  attachmentProgress={attachmentProgress}
                />
              ) : null}
            </div>
          </div>

          <ul className="isoRight">
            <li className="isoNotifyProgress">
              {attachmentProgress ? (
                <Tooltip
                  placement="bottom"
                  title={"Upload in progress : " + attachmentProgress + " %"}
                >
                  <Progress
                    type="circle"
                    format={() => (
                      <div
                        style={{
                          color: colors.white,
                        }}
                      >
                        {attachmentProgress}
                      </div>
                    )}
                    width={40}
                    status={"normal"}
                    strokeColor={colors.white}
                  />
                </Tooltip>
              ) : null}
            </li>

            {selectedMode !== "parent" ? (
              firebase && firebase.schoolConfig && firebase.schoolConfig.trailStartDate > 0 ? (
                <li className="isoNotifyProgress">
                  <Link
                    to={{
                      pathname: "/dashboard/scheduleDemo",
                      search: "?signUp=no" + "&display=no",
                    }}
                  >
                    <PrefixIconButton
                      title={<IntlMessages id="topbar.bookADemo" />}
                      onClick={() => null}
                      fontColor={colors.white}
                      border={true}
                      borderColor={colors.white}
                      backgroundColor={colors.v2_Cerulean}
                      borderRadius={10}
                      fontWeight={"bold"}
                      fontSize={13}
                    />
                  </Link>
                </li>
              ) : null
            ) : null}

            {selectedMode !== "parent" ? (
              FilterPermission.checkIfPermission(
                PermissionStrings.POST_ACTIVITY_ADD_NEW_POST,
                firebase
              ) ? (
                window.location.href.includes("/activities") ? null : (
                  <li className="isoNotifyProgress">
                    <Link to="/dashboard/activities">
                      <PrefixIconButton
                        title={<IntlMessages id="topbar.postActivity" />}
                        onClick={() => null}
                        fontColor={colors.v2_alt_fiord}
                        border={true}
                        borderColor={colors.lightYellow}
                        backgroundColor={colors.lightYellow}
                        borderRadius={10}
                        fontWeight={"bold"}
                        fontSize={13}
                      />
                    </Link>
                  </li>
                )
              ) : null
            ) : null}

            {selectedMode !== "parent" ? (
              <li className="isoNotifyProgress">
                <Link to="/dashboard/staffMessages">
                  <Tooltip title={<IntlMessages id="sidebar.staffMessages" />}>
                    <div style={styles.iconDiv}>
                      <img
                        src={groupChat}
                        style={{
                          height: 25,
                          width: 25,
                          objectFit: "contain",
                        }}
                        alt="chat"
                      />
                    </div>
                    <CounterBadge counter={this.getStaffUnreadCount()} />
                  </Tooltip>
                </Link>
              </li>
            ) : null}

            {selectedMode !== "parent" ? (
              FilterPermission.checkIfPermission(PermissionStrings.QUERIES_VIEW_REPLY, firebase) ? (
                <li className="isoNotifyProgress">
                  <Link to="/dashboard/messages?tab=message">
                    <Tooltip title={<IntlMessages id="topbar.parentMessages" />}>
                      <div style={styles.iconDiv}>
                        <img
                          src={emailIcon}
                          style={{
                            height: 25,
                            width: 25,
                            objectFit: "contain",
                          }}
                          alt="chat"
                        />
                      </div>
                      <CounterBadge counter={unreadMsgCount} />
                    </Tooltip>
                  </Link>
                </li>
              ) : null
            ) : null}

            <li className="isoNotify">
              <TopbarNotification locale={locale} />
            </li>

            <li
              onClick={() =>
                this.setState({
                  selectedItem: "user",
                })
              }
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

const a = compose(
  connect(
    (state) => ({
      ...state.App,
      ...state.Tags,
      ...state.Classrooms,
      ...state.Teachers,
      ...state.Students,
      ...state.Programs,
      ...state.Learning,
      ...state.Assessment,
      ...state.SelectedStudentAssessment,
      ...state.Fees,
      ...state.Leads,
      ...state.LandingPage,
      ...state.Activities,
      ...state.StudentAttendance,
      ...state.NewStudentAttendance,
      ...state.StaffAttendance,
      ...state.NewStaffAttendance,
      ...state.Invoice,
      ...state.InvoiceTemplate,
      ...state.NewActivity,
      ...state.TeacherDocument,
      ...state.Complains,
      ...state.Leaves,
      ...state.ParentNotes,
      ...state.Holiday,
      ...state.Events,
      ...state.SchoolDetails,
      ...state.UserSetting,
      ...state.Schedule,
      ...state.VirtualClass,
      ...state.NewsletterComponent,
      ...state.Sent,
      ...state.LessonDashboard,
      ...state.AllSubmission,
      ...state.Assignments,
      ...state.NewActivity,
      ...state.QueryList,
      ...state.SingleChat,
      ...state.EnableActivity,
      ...state.StudentLevelActivity,
      ...state.CustomLabels,
      ...state.Applications,
      ...state.Permissions,
      ...state.StaffQueryList,
      ...state.LeadForms,
      ...state.StaffToStudentAttendanceLiveRatio,
      ...state.CustomActivities,
      ...state.AllReports,
      ...state.ExpenseManagement,
    }),
    {
      toggleCollapsed,
      switchBranchName,
      initTagData,
      getStudentsToAssignTag,
      getClassroomForTag,
      getActivitySetting,
      getInitialValue,
      initClassroomData,
      initTeacherData,
      getTeacherClassroomDetails,
      initStudentData,
      getClassroomDetails,
      getTags,
      initProgramData,
      reloadFte,
      getStudentLevelProgram,
      getStudentAttendanceData,
      getClassroomDataForProgram,
      getMilestones,
      getDevelopmentAreas,
      getLabel,
      getLessons,
      getCategory,
      getAssessmentProgressParam,
      getWeeklyLesson,
      getClassroom,
      getStudentAssessment,
      getAllStudents,
      getFeePlan,
      getFeeComponent,
      getFeePrograms,
      initLeadsData,
      getLeadsAdditionalField,
      getStatus,
      getActivityClassrooms,
      getActivityTodaysPost,
      getStaffActivityTodaysPost,
      getUpcomingMeetingReminders,
      getStudentLevelActivities,
      getStudentForActivityView,
      initStudentAttendanceData,
      getStudentNewAttendance,
      getAllStudentsForStudentAttendance,
      getAllClassroomsForStudentAttendance,
      initStaffAttendanceData,
      getNewStaffAttendance,
      getAllStaffs,
      getAllClassroomsForStaffAttendance,
      initInvoiceData,
      getInvoiceAggregated,
      getAggregatedInvoiceDetail,
      fetchStudentData,
      fetchStudentsFeePlan,
      initInvoiceTemplateData,
      getUnreadAlertNotification,
      getDashboardMeetings,
      getDashboardStatistics,
      getActivityDashboardStats,
      getLearningDashboardStats,
      getLeadDashboardStats,
      getFinanceDashboardStats,
      callBrowserRefresh,
      resetBrowserRefresh,
      initDocumentData,
      getAllComplains,
      getAllLeaves,
      getAllStaffLeaves,
      getAllParentNotes,
      getAllHolidays,
      getAllClassrooms,
      getAllEvents,
      getSchoolDetails,
      getSchoolSettingDetails,
      getUserDetails,
      getCameraReferences,
      schoolConfiguration,
      getActiveActivities,
      getClassroomForSchedule,
      getSchedule,
      getVirtualClasses,
      getFilterClassrooms,
      getAllNewsLetter,
      fetchClassrooms,
      getAllTemplates,
      initNewsletterData,
      getLessonStats,
      getAssignments,
      resetAttachmentProgress,
      resetErrorStatus,
      getQueryList,
      getStaffQueryList,
      getPermissionList,
      getStudentSubmissionStats,
      getApplicationForms,
      getLiveRatio,
      getLiveRatioClassroom,
      getLiveRatioTeacher,
      fetchAlert,

      getCustomLabels,
      getMeetingReport,
      getMeetingActivity,

      getCustomActivities,
      getLeadsTableData,
      getLeadStatus,
      fetchTaxRefList,
      showReports,
      getRegisters,
    }
  ),
  withNotification,
  withFirebase
);
export default a(Topbar);
const styles = {
  iconDiv: {
    height: 40,
    width: 40,
    backgroundColor: colors.v2_scooter,
    borderRadius: 8,
    border: "1px solid",
    borderColor: colors.v2_scooter,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  schoolName: {
    textAlign: "center",
  },
};
