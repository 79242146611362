import { Layout } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { compose } from "redux";
import { ThemeProvider } from "styled-components";
import packageJson from "../../../package.json";
import appActions from "../../redux/app/actions";
import authAction from "../../redux/auth/actions";
import { siteConfig, themeConfig } from "../../settings";
import themes from "../../settings/themes";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { withFirebase } from "./../../components/firebase/context";
import AppRouter from "./AppRouter";
import AppHolder from "./commonStyle";
import "./global.css";
import { dir } from "../../index";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  componentDidMount() {}

  render() {
    const { height, firebase, branchChangeLoader } = this.props;
    const { url } = this.props.match;
    const urlParams = new URLSearchParams(window.location.search);
    let displayReq = urlParams.get("display");

    window.Intercom("boot", {
      app_id: "n8r4fl63",
      user_id: firebase.loggedInEmail,
      email: firebase.teacher && firebase.teacher.email ? firebase.teacher.email : null,
      name: firebase.teacher ? firebase.teacher.name : "",
      schoolName: firebase.schoolName,
      user_version: packageJson.version,
      user_name: firebase.teacher ? firebase.teacher.name : "",
      role:
        firebase.selectedMode === "teacher" && firebase.teacher
          ? firebase.teacher.role
          : firebase.selectedMode === "parent"
          ? "parent"
          : "teacher",
    });
    if(firebase?.loggedInEmail){
    window.Intercom("update", {
      hide_default_launcher:
        window.location.href.includes("queryList") ||
        window.location.href.includes("staffMessages") ||
        window.location.href.includes("comments") ||
        window.location.href.includes("schoolMessages") ||
        window.location.href.includes("invoiceDetail") ||
        window.location.href.includes("messages") ||
        window.location.href.includes("parentMessages")
          ? true
          : false,
          alignment: dir === "rtl" ? 'left': 'right', 
    });

  }


    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout
            style={{
              height: appHeight,
            }}
          >
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={(windowSize) =>
                  this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight)
                }
              />
            </Debounce>
            {displayReq && displayReq === "no" ? null : <Topbar url={url} height={height} />}
            <Layout
              style={{
                flexDirection: "row",
                overflowX: "hidden",
              }}
            >
              <Sidebar noDisplay={displayReq && displayReq === "no" ? true : false} url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height,
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: "70px 0 0",
                    flexShrink: "0",
                    background: "#f1f3f6",
                    position: "relative",
                  }}
                >
                    <AppRouter url={url} firebase={this.props.firebase} />
                </Content>
                <Footer
                  style={{
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                    borderTop: "1px solid #ededed",
                  }}
                >
                  {siteConfig.footerText}, Curr. Version: {packageJson.version}
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.Auth,
    height: state.App.height,
    branchChangeLoader: state.App.branchChangeLoader,
  };
}
const a = compose(
  connect(mapStateToProps, {
    logout,
    toggleAll,
  }),
  withFirebase
);

export default a(App);
