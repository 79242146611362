import boyIcon from "../image/boy.svg";
import { getItem, setItem, removeItem, clear } from "./encryptedStorage";
import girlIcon from "../image/girl.svg";
import userColored from "../image/userColored.png";
export default class UserImageAction {
  constructor() {}
}

UserImageAction.getStudentProfileImage = (studentId, firebase) => {
  let students = firebase.studentsList;
  let student = students.filter((item) => {
    return item.id === studentId;
  });

  if (student && student.length > 0) {
    let filteredStd = student[0];
    if (filteredStd.profileImageUrl) {
      return filteredStd.profileImageUrl;
    } else {
      if (filteredStd.gender.toLowerCase() === "male") {
        return boyIcon;
      } else if (filteredStd.gender.toLowerCase() === "female") {
        return girlIcon;
      } else {
        return userColored;
      }
    }
  } else {
    return userColored;
  }
};

UserImageAction.getStudentProfileImageFromMap = (studentId, firebase) => {
  let studentsMap = firebase.studentsMap;
  let student = studentsMap.get(studentId);
  if (student) {
    if (student.profileImageUrl) {
      return student.profileImageUrl;
    } else {
      if (student.gender.toLowerCase() === "male") {
        return boyIcon;
      } else if (student.gender.toLowerCase() === "female") {
        return girlIcon;
      } else {
        return userColored;
      }
    }
  } else {
    return userColored;
  }
};

UserImageAction.getStaffProfileImage = (teacherId) => {
  let teachers = JSON.parse(getItem("teacherList"));
  let teacher = teachers.filter((item) => {
    return item.id === teacherId;
  });

  if (teacher && teacher.length > 0) {
    let filteredStd = teacher[0];
    if (filteredStd.profileImageUrl) {
      return filteredStd.profileImageUrl;
    } else {
      if (filteredStd.gender.toLowerCase() === "male") {
        return boyIcon;
      } else if (filteredStd.gender.toLowerCase() === "female") {
        return girlIcon;
      } else {
        return userColored;
      }
    }
  } else {
    return userColored;
  }
};

UserImageAction.ifClassAccess = (teacherClassList, studentId, firebase) => {
  let status = false;
  let students = firebase.studentsList;
  let student = students.filter((std) => {
    return std.id === studentId;
  });

  if (student && student.length > 0) {
    let classList = student[0].classList ? student[0].classList : [student[0].classroomName];
    for (let i in teacherClassList) {
      if (classList.includes(teacherClassList[i])) {
        status = true;
        break;
      }
    }
  }

  return status;
};

UserImageAction.ifClassExistsForStudent = (classname, studentId, firebase) => {
  let status = false;
  let students = firebase.studentsList;
  let student = students.filter((std) => {
    return std.id === studentId;
  });

  if (student && student.length > 0) {
    let classList = student[0].classList ? student[0].classList : [student[0].classroomName];

    if (classList.includes(classname)) {
      status = true;
    }
  }

  return status;
};

UserImageAction.getRelation = (relation, firebase) => {
  if (firebase && firebase.schoolConfig.genderNeutral) {
    if (relation.toLowerCase() === "mother") {
      return "Parent One";
    } else if (relation.toLowerCase() === "father") {
      return "Parent Two";
    } else {
      return relation;
    }
  } else {
    return relation;
  }
};
