import antdES from "antd/lib/locale-provider/ca_ES";
import appLocaleData from "react-intl/locale-data/es";
import esMessages from "../locales/es_ES.json";

const Eslang = {
  messages: {
    ...esMessages,
  },
  antd: antdES,
  locale: "es",
  data: appLocaleData,
};
export default Eslang;
