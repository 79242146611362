import { Popover, Icon, List } from "antd";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import { withFirebase } from "../../components/firebase/context";
import formatMsg from "../../components/utility/formatMessageUtil";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import deleteImage from "../../image/deleteImage.png";
import IntlMessages from "../../components/utility/intlMessages";
import editImage from "../../image/editImage.png";
import { confirmAlert } from "react-confirm-alert";
import DoseForm from "./AddDoseForm";


function DoseView(props) {
    let dose = props.dose;
    let years = [formatMsg("immunization.year"), formatMsg("immunization.years")];
    let months = [formatMsg("immunization.month"), formatMsg("immunization.months")];
    let weeks = [formatMsg("immunization.week"), formatMsg("immunization.weeks")];

    const [popover, setPopover] = useState(false);


    const [addDoseModal, setAddDoseModal] = useState(false);
    function modalCancel() {
        setAddDoseModal(false);
    }

    function popAction() {
        return (
            <List
                split={false}
                style={{ cursor: "pointer" }}
                size="small"
                dataSource={["a"]}
                renderItem={(item, index) => (
                    <div>
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.v2_Cerulean,
                                    borderBottom: ".8px solid " + colors.v2_light_grey
                                },
                            }}
                            key="Change Log"
                            onClick={() => { setAddDoseModal(true), setPopover(false) }}
                        >
                            <img src={editImage} alt="edit" style={styles.listItemIcon} />
                            <IntlMessages id="events.edit" />
                        </List.Item>
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.redTextHeader
                                },
                            }}
                            key="Change Log"
                            onClick={deleteDose}
                        >
                            <img src={deleteImage} alt="delete" style={styles.listItemIcon} />
                            <IntlMessages id="document.delete" />
                        </List.Item>
                    </div>
                )}
            />
        )
    };
    function deleteDose() {
        const { firebase, deleteDose } = props;
        let message = formatMsg("students.confirmToSubmit");
        confirmAlert({
            closeOnClickOutside: false,
            title: (
                <p
                    style={{
                        fontSize: 20,
                        color: colors.v2_fiord,
                    }}
                >
                    {formatMsg("confirmModal.areYouSure")}
                </p>
            ),
            message: message,
            buttons: [
                {
                    label: formatMsg("yes"),
                    onClick: () => deleteDose(firebase, dose.doseId),
                },
                {
                    label: formatMsg("no"),
                },
            ],
        });
        setPopover(!popover);
    }
    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={styles.headerDiv}>
                    <span ><span style={{ marginLeft: "3px", fontSize: webFont.extraLarge }} >{dose.scheduledDate.years}</span> {years[dose.scheduledDate.years >= 2 ? 1 : 0]}</span>
                    <span style={styles.month} >|</span>
                    <span ><span style={{ marginLeft: "3px", fontSize: webFont.extraLarge }} >{dose.scheduledDate.months}</span> {months[dose.scheduledDate.months >= 2 ? 1 : 0]}</span>
                    <span style={styles.month} >|</span>
                    <span><span style={{ marginLeft: "3px", fontSize: webFont.extraLarge }} >{dose.scheduledDate.weeks}</span> {weeks[dose.scheduledDate.weeks >= 2 ? 1 : 0]}</span>
                    <span>
                        <Popover
                            content={popAction()}
                            trigger="click"
                            visible={popover}
                            onVisibleChange={(visible) =>
                                setPopover(visible)
                            }
                            placement="bottomLeft"
                        >
                            <div style={{}}>
                                <Icon
                                    type={!popover ? "caret-down" : "caret-up"}
                                    style={{
                                        marginTop: "4px",
                                        marginLeft: "4px",
                                        color: colors.v2_Cerulean,
                                        cursor: "pointer",
                                        fontSize: webFont.fll,
                                    }}
                                />
                            </div>
                        </Popover>
                    </span>
                </div>
                {
                    dose.proofMandatory
                        ?
                        <p style={styles.mandatory} >{formatMsg("immunization.proofMandatory")}</p>
                        :
                        null
                }
            </div>
            <DoseForm
                modalVisible={addDoseModal}
                modalCancel={modalCancel}
                dose={dose}
            />
        </div >
    )
}

function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);
export default a(DoseView);
const styles = {
    headerDiv: {
        display: "flex",
        flexDirection: "row",
        fontSize: "15px"
    },
    month: {
        marginLeft: "4px",
        marginRight: "4px",
        fontSize: webFont.extraLarge
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    listItemIcon: {
        height: 15,
        width: 15,
        marginRight: 10,
        objectFit: "contain",
    },
    actionStyle: {
        border: "1.5px solid #D0D0D0",
        backgroundColor: "#F0F0F0",
        borderRadius: 6,
        display: "flex",
        width: 26,
        height: 26,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 8
    },
    mandatory: {
        fontSize: webFont.small,
        fontWeight: "normal",
        color: colors.lightBlue,
    }
}