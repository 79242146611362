import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { TimelineApi } from "../../firestore-api/timeline";
import FilterAction from "../../Utility/FilterAction";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";
import activityActions from "../newActivity/actions";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
function* updateFilterNode(firebase) {
  try {
    let vc = yield call(ActivityApi.getAllRepeatClass, moment(), firebase);
    if (vc) {
      for (let index in vc) {
        let vClass = vc[index];
        yield fork(updateStudentFilterNode, vClass, firebase);
      }
    }
  } catch (err) {
    console.log("failed", err);
  }
}

function* updateStudentFilterNode(vc, firebase) {
  try {
    let activityType = "Virtual Class";
    let studentIds = vc.studentIds;
    let activityId = vc.id;
    let activityDate = moment(vc.meetingTime);
    let meetingTime = moment(vc.meetingTime).valueOf();

    console.log("vc", moment(vc.repeatStartDate).format("DD[ ]MMMM[ ]YYYY"));
    console.log("studentIds", studentIds);

    for (let index in studentIds) {
      let studentId = studentIds[index];

      let activityTypeNodeId = yield call(
        ActivityApi.generateActivityTypeNode,
        activityType,
        studentId,
        firebase
      );
      if (activityTypeNodeId) {
        let creator = undefined;
        yield call(
          ActivityApi.updateActivityType,
          activityDate,
          studentId,
          activityId,
          activityTypeNodeId,
          activityType,
          firebase,
          undefined,
          meetingTime ? meetingTime : undefined,
          creator
        );
      }
    }
  } catch (err) {
    console.log("filter node update failed", err);
  }
}

// function* fetchVirtualClass({ fetchAll, firebase, classroom, date, initialCall }) {
//     yield fork(updateFilterNode, firebase);
// }

function* fetchVirtualClass({ fetchAll, firebase, classroom, date, initialCall }) {
  try {
    let data;
    let activities = [];
    let activityIds = {};

    data = yield call(ActivityApi.getVirtualClassActivityRef, fetchAll, firebase, date);

    let activitiesTask = [];
    let parentActivityMap = [];
    for (let index in data) {
      let task = call(ActivityApi.getActivityById, data[index].activityId, firebase);
      activitiesTask.push(task);
    }

    let newVal = yield all([activitiesTask]);

    for (let i in newVal[0]) {
      let val = newVal[0][i];

      if (val.id && !activityIds[val.id]) {
        if (val.parentActivityId) {
          parentActivityMap.push(val.parentActivityId);
        }

        if (!fetchAll) {
          if (classroom !== "All Room") {
            if (val.classNames && val.classNames.includes(classroom)) {
              activities.push(val);
            }
          } else {
            activities.push(val);
          }
        } else {
          if (classroom !== "All Room") {
            if (
              val.classNames &&
              val.classNames.includes(classroom) &&
              val.createdBy.toLowerCase() === firebase.teacher.name.toLowerCase()
            ) {
              activities.push(val);
            }
          } else {
            if (val.createdBy.toLowerCase() === firebase.teacher.name.toLowerCase()) {
              activities.push(val);
            }
          }
        }
        activityIds[val.id] = val;
      } else {
        console.log("already pushed activity");
      }
    }

    yield fork(fetchSeenCount, activities, firebase);

    if (moment(date).isAfter(moment(), "day")) {
      let repeatData = yield call(ActivityApi.getRepeatClass, date, firebase);

      for (let i in repeatData) {
        let val = repeatData[i];
        if (!parentActivityMap.includes(val.id)) {
          //check parentActivityId from activities and match
          if (val.id && !activityIds[val.id]) {
            let a = moment(val.meetingTime).startOf("day");
            let b = moment(date).startOf("day");
            let diff = Math.abs(b.diff(a, "days"));

            let weekDays = val.daysOfWeek
              ? val.daysOfWeek
              : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
            if (val.includeSaturday) {
              if (!weekDays.includes("Saturday")) {
                weekDays.push("Saturday");
              }
            }

            let newMeetingTime = moment(val.meetingTime).add(diff, "day").valueOf();
            val.meetingTime = newMeetingTime;
            val.nodeType = "Repeat";

            if (weekDays.includes(moment(val.meetingTime).format("dddd"))) {
              if (!fetchAll) {
                if (classroom !== "All Room") {
                  if (val.classNames && val.classNames.includes(classroom)) {
                    activities.push(val);
                  }
                } else {
                  activities.push(val);
                }
              } else {
                if (classroom !== "All Room") {
                  if (
                    val.classNames &&
                    val.classNames.includes(classroom) &&
                    val.createdBy.toLowerCase() === firebase.teacher.name.toLowerCase()
                  ) {
                    activities.push(val);
                  }
                } else {
                  if (val.createdBy.toLowerCase() === firebase.teacher.name.toLowerCase()) {
                    activities.push(val);
                  }
                }
              }
              activityIds[val.id] = val;
            }
          } else {
            console.log("already pushed activity");
          }
        }
      }
    }

    activities.sort(function (a, b) {
      var dateA = a.meetingTime,
        dateB = b.meetingTime;
      return dateB - dateA;
    });

    if (firebase && firebase.schoolConfig && firebase.schoolConfig.classBookingEnabled) {
      yield fork(fetchTrialStudentCount, activities, firebase);
    }

    yield put({
      type: actions.GET_VIRTUAL_CLASS_SUCCESSFUL,
      virtualClass: activities,
      virtualClassChan: undefined,
      operationType: initialCall ? "INITIAL_CALL" : undefined,
    });
  } catch (err) {
    console.log("failed to fetch meeting ref", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* fetchTrialStudentCount(activities, firebase) {
  console.log("activities ---", activities);
  try {
    let bookingRefTask = [];
    let trialStudentMap = new Map();

    for (let index in activities) {
      let activity = activities[index];
      if (activity.activityType.toLowerCase() === "virtual class") {
        let meetingTime = activity.meetingTime;
        let task = call(ActivityApi.getBookingRefByTime, activity.id, meetingTime, firebase);
        bookingRefTask.push(task);
      }
    }

    let newVal = yield all([bookingRefTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      trialStudentMap.set(val.activityId, val.studentIds ? val.studentIds.length : 0);
    }

    yield put({
      type: actions.GET_V_CLASS_TRIAL_STUDENT_COUNT,
      trialStudentsMap: trialStudentMap,
    });
  } catch (err) {
    console.log("failed to fetch trial student count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchSeenCount(activities, firebase) {
  try {
    let seenCount = [];
    for (let index in activities) {
      let data = yield call(ActivityApi.getSeenCount, activities[index].id, firebase);
      if (data) {
        seenCount = [...seenCount, ...data];
      }
    }
    yield put({
      type: actions.GET_VIRTUAL_SEEN_COUNT_SUCCESSFUL,
      seenCount: seenCount,
    });
  } catch (err) {
    console.log("failed to fetch seen count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchTaggedStudent({ activityId, firebase, trial, record }) {
  try {
    let students = FilterAction.getStudentList(firebase);
    var namesMap = [];
    if (record && record.studentIds) {
      students.filter((s) => {
        if (record.studentIds.includes(s.id)) {
          namesMap.push(s);
        }
      });
    }

    if (trial) {
      let bookingRef = yield call(
        ActivityApi.getBookingRefByTime,
        activityId,
        record.meetingTime,
        firebase
      );

      let activityStudentIds = [];
      if (bookingRef && bookingRef.studentIds) {
        activityStudentIds = bookingRef.studentIds;
      }

      let studentNames = [];
      students.filter((s) => {
        if (activityStudentIds.includes(s.id)) {
          studentNames.push(s);
        }
      });

      yield put({
        type: actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
        taggedStudent: studentNames,
      });
    } else {
      yield put({
        type: actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
        taggedStudent: namesMap,
      });
    }
  } catch (error) {
    console.log("failed to fetch tagged student detail", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* updateSelectedVirtualActivity({ values, record, firebase }) {
  try {
    record.message = values.note;
    record.htmlText = null;
    record.updatedBy = firebase.teacher.name;
    record.updatedOn = moment().valueOf();
    yield call(
      ActivityApi.editActivityObj,
      record,
      firebase,
      record.repeatStartDate > 0 && record.repeatEndDate > 0 ? true : false
    );

    let repeatVirtualClass = record.repeatStartDate > 0 && record.repeatEndDate > 0 ? true : false;
    if (repeatVirtualClass) {
      let repeatClassId;
      if (record.parentActivityId) {
        repeatClassId = record.parentActivityId;
      } else {
        repeatClassId = record.id;
      }

      if (repeatClassId) {
        let repeatClass = yield call(ActivityApi.getRepeatClassById, repeatClassId, firebase);
        if (repeatClass && repeatClass.id) {
          repeatClass.updatedBy = firebase.teacher.name;
          repeatClass.updatedOn = moment().valueOf();
          repeatClass.message = values.note;
          repeatClass.htmlText = null;
          yield call(ActivityApi.updateIndRepeatClass, repeatClass, firebase);
        }
      }
    }

    yield put({
      type: actions.UPDATE_VIRTUAL_ACTIVITY_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to update activity", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedVirtualActivity({ record, firebase }) {
  try {
    yield call(ActivityApi.deleteActivity, record, firebase);
    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", new Date());
    yield put({
      type: actions.DELETE_VIRTUAL_ACTIVITY_SUCCESSFUL,
    });
    if (record.activityType === "Virtual Class") {
      yield fork(deleteMeetingRef, record.id, firebase);
      yield fork(deleteBookingRef, record, firebase);
    }
  } catch (error) {
    console.log("failed to delete activity", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* deleteBookingRef(record, firebase) {
  try {
    // delete all booking ref for future dates (not past)
    // let bookingRef = yield call(ActivityApi.getBookingRefByTime, record.id, record.meetingTime, firebase);
    let bookingRef = yield call(ActivityApi.getBookingRefByActivityId, record.id, firebase);
    if (bookingRef && bookingRef.length > 0) {
      for (let index in bookingRef) {
        yield call(ActivityApi.deleteBookingRef, bookingRef[index], firebase);
      }
    }
  } catch (err) {
    console.log("failed to delete booking reference", err);
    bugsnagClient.notify(err);
  }
}

function* deleteMeetingRef(activityId, firebase) {
  try {
    let data = yield call(ActivityApi.getMeetingRefById, activityId, firebase);
    if (data && data.length > 0) {
      for (let index in data) {
        if (data[index].activityId) {
          yield fork(ActivityApi.deleteMeetingReference, data[index].id, firebase);
        }
      }
    }
  } catch (err) {
    console.log("failed to delete meeting ref", err);
    bugsnagClient.notify(err);
  }
}

function* fetchFilterClassroom({ firebase }) {
  try {
    //let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    let data = JSON.parse(getItem("classList"));
    if (data) {
      yield put({
        type: actions.GET_FILTER_CLASSROOM_SUCCESS,
        filterClassroom: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch filter classroom", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

// function* uploadBookingRef(firebase) {
//     let repeatClasses = yield call(ActivityApi.getAllRepeatClasses, firebase);
//     if (repeatClasses && repeatClasses.length > 0) {
//         console.log("repeatClasses ----", repeatClasses);
//         let totalCounter = 0;
//         for (let index in repeatClasses) {
//             yield fork(putBooking, repeatClasses[index], firebase, totalCounter);
//         }
//     }
//     console.log("finish ----!!");
//     yield put({
//         type: actions.STOP_V_CLASS_REPETITION_SUCCESS
//     })
// }

// function* putBooking(rClass, firebase, totalCounter) {
//     // let rClass = repeatClasses[index];
//     let meetingTime = rClass.meetingTime;
//     let activityId = rClass.id;
//     let classNames = rClass.classNames ? rClass.classNames : [];
//     let meetingCapacity = rClass.meetingCapacity;
//     let meetingDuration = rClass.meetingDuration;
//     let createdBy = rClass.createdBy;

//     let repeatWeekDays = rClass.daysOfWeek ? rClass.daysOfWeek : [];
//     if (rClass.includeSaturday && !repeatWeekDays.includes("Saturday")) {
//         repeatWeekDays.push("Saturday");
//     }

//     let repeatStart = moment(rClass.repeatStartDate).startOf('day').valueOf();
//     let repeatEnd = moment(rClass.repeatEndDate).endOf('day').valueOf();
//     let diff = Math.abs(moment(repeatEnd).diff(moment(repeatStart), 'days'));
//     totalCounter = totalCounter + diff;
//     console.log("totalCounter", totalCounter, diff, activityId);

//     for (let i = 0; i <= diff; i++) {

//         let repeatMeetingTime = moment(meetingTime).add(i, 'day');

//         let update = false;
//         if (repeatWeekDays && repeatWeekDays.length > 0) {
//             if (repeatWeekDays.includes(repeatMeetingTime.format("dddd"))) {
//                 update = true;
//             }
//         } else {
//             update = true;
//         }

//         if (update) {
//             let trialBookingNode = yield call(ActivityApi.createBookingRefNode, firebase);
//             yield call(ActivityApi.updateTrialBookingRef, repeatMeetingTime.valueOf(), activityId, trialBookingNode, classNames, firebase, undefined, meetingCapacity, [], meetingDuration, createdBy);
//         }

//     }
// }

function* stopVirtualClassRepeat({ record, firebase, repeatVirtualClass }) {
  try {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    let activity = record;
    activity.updatedBy = firebase.teacher.name;
    activity.updatedOn = moment().valueOf();
    activity.repeatEndDate = 0;
    activity.repeatStartDate = 0;
    activity.repeat = false;

    rsf.ref(branchPath + "/activities/" + activity.id).update({
      updatedBy: activity.updatedBy,
      updatedOn: activity.updatedOn,
      repeatEndDate: activity.repeatEndDate,
      repeatStartDate: activity.repeatStartDate,
      repeat: activity.repeat,
    });

    if (repeatVirtualClass) {
      let repeatClassId;
      if (activity.parentActivityId) {
        repeatClassId = activity.parentActivityId;
      } else {
        repeatClassId = activity.id;
      }

      if (repeatClassId) {
        let repeatClass = yield call(ActivityApi.getRepeatClassById, repeatClassId, firebase);
        if (repeatClass && repeatClass.id) {
          repeatClass.updatedBy = firebase.teacher.name;
          repeatClass.updatedOn = moment().valueOf();
          repeatClass.repeatEndDate = 0;
          repeatClass.repeatStartDate = 0;
          repeatClass.repeat = false;

          rsf.ref(branchPath + "/repeatClasses/" + repeatClass.id).update({
            updatedBy: repeatClass.updatedBy,
            updatedOn: repeatClass.updatedOn,
            repeatEndDate: repeatClass.repeatEndDate,
            repeatStartDate: repeatClass.repeatStartDate,
            repeat: repeatClass.repeat,
          });

          rsf.ref(branchPath + "/activities/" + repeatClass.id).update({
            updatedBy: repeatClass.updatedBy,
            updatedOn: repeatClass.updatedOn,
            repeatEndDate: repeatClass.repeatEndDate,
            repeatStartDate: repeatClass.repeatStartDate,
            repeat: repeatClass.repeat,
          });
        }
      }
    }
    yield put({
      type: actions.STOP_V_CLASS_REPETITION_SUCCESS,
    });
  } catch (err) {
    console.log("failed to stop virtual class", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* approveVClassActivity({ item, firebase }) {
  try {
    yield call(ActivityApi.approveSelectedActivity, item, firebase);
    let selectedActivity = item.activityType;
    let studentIds = item.studentIds;
    let activityId = item.id;
    let parentEmails = [];
    for (let index in studentIds) {
      let selectedStudent = yield call(StudentApi.getStudentById, studentIds[index], firebase);
      if (selectedStudent && selectedStudent.id && selectedStudent.name) {
        if (selectedStudent.fatherEmail) {
          parentEmails.push(selectedStudent.fatherEmail);
        }
        if (selectedStudent.motherEmail) {
          parentEmails.push(selectedStudent.motherEmail);
        }

        if (selectedStudent.fatherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent.fatherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            activityId,
            selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
            selectedStudent.id,
            selectedStudent.fatherProfileId,
            firebase
          );

          if (
            selectedStudent.fatherUUid !== undefined ||
            selectedStudent.ios_fatherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              activityId,
              selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
              selectedActivity + " activity added for " + selectedStudent.name,
              alertNode,
              selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
              selectedStudent.id,
              selectedStudent.fatherProfileId,
              firebase
            );
          }
        }

        if (selectedStudent.motherProfileId) {
          let alertNode = yield call(
            NotificationApi.createAlertReferenceNode,
            selectedStudent.motherProfileId,
            firebase
          );
          yield fork(
            NotificationApi.createAlertNotification,
            selectedActivity,
            activityId,
            selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
            selectedStudent.id,
            selectedStudent.motherProfileId,
            firebase
          );

          if (
            selectedStudent.motherUUid !== undefined ||
            selectedStudent.ios_motherUUid !== undefined
          ) {
            yield fork(
              NotificationApi.sendPushNotification,
              selectedActivity,
              activityId,
              selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
              selectedActivity + " activity added for " + selectedStudent.name,
              alertNode,
              selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
              selectedStudent.id,
              selectedStudent.motherProfileId,
              firebase
            );
          }
        }
      }
    }

    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", new Date());
    yield put({
      type: actions.APPROVE_V_CLASS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to approve virtual class", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* addNewStudentToMeeting({ activity, students, firebase, selectedTimezone }) {
  try {
    let studentIds = [];
    let existingStudentIds = activity.studentIds ? activity.studentIds : [];
    for (let index in students) {
      let id = students[index].id;
      if (!existingStudentIds.includes(id)) {
        studentIds.push(id);
      }
    }

    let bookingRef = undefined;
    let response = yield call(
      NotificationApi.addStudentToMeeting,
      activity,
      bookingRef,
      studentIds,
      firebase,
      selectedTimezone
    );
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.ADD_STUDENT_TO_MEETING_SUCCESS,
      });
    } else {
      yield put({
        type: actions.VIRTUAL_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to add student to meeting", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* removeStudentsFromOnlineClass({
  student,
  activity,
  studentList,
  firebase,
  trial,
  activityList,
}) {
  try {
    if (student.status && student.status.toLowerCase() === "trial") {
      let studentBookings = yield call(ActivityApi.getStudentBookingById, student.id, firebase);
      if (studentBookings) {
        for (let index in studentBookings) {
          let bookingRefId = studentBookings[index].bookingRefId;
          let bookingRef = yield call(ActivityApi.getBookingRefById, bookingRefId, firebase);
          if (bookingRef && bookingRef.id) {
            let bookingStudentIds = bookingRef.studentIds ? bookingRef.studentIds : [];
            let filteredBookingStudentIds = bookingStudentIds.filter((b) => {
              return b !== student.id;
            });
            bookingRef.studentIds = filteredBookingStudentIds;
            yield call(ActivityApi.updatedBookingRef, bookingRef, firebase);
            yield call(
              NotificationApi.updateJoinedMeeting,
              activity,
              student,
              firebase,
              "TRIAL_CANCELLED",
              bookingRef
            );
          }
        }
      } else {
        yield call(
          NotificationApi.updateJoinedMeeting,
          activity,
          student,
          firebase,
          "TRIAL_CANCELLED"
        );
      }

      yield fork(ActivityApi.removeCompleteStudentBookingRef, student.id, firebase);
      yield fork(TimelineApi.cancelMeetingReminder, student.id, firebase);

      student.meetingDate = null;
      student.meetingDuration = null;
      student.activityId = null;
      student.trialCount = student.trialCount ? Number(student.trialCount + 1) : 1;
      student.meetingJoinedDate = null;
      student.platform = "web";
      student.updatedOn = moment().valueOf();
      student.updatedBy = firebase.teacher.name;
      student.timezone = null;
      yield fork(StudentApi.updateStudentWithUpdatedFormFields, student, firebase);
    } else {
      const rsf = firebase.secondaryDb;
      let branchPath = firebase.sbp;

      activity.platform = "web";
      activity.updatedBy = firebase.teacher.name;

      let activityStudentIds = activity.studentIds;
      let filteredActivityStudentIds = activityStudentIds.filter((a) => {
        return a !== student.id;
      });
      activity.studentIds = filteredActivityStudentIds;

      // update only student id;
      rsf.ref(branchPath + "/activities/" + activity.id).update({
        studentIds: activity.studentIds ? activity.studentIds : [],
      });

      let repeatVirtualClass =
        activity.repeatStartDate > 0 && activity.repeatEndDate > 0 ? true : false;
      if (repeatVirtualClass) {
        let repeatClassId;
        if (activity.parentActivityId) {
          repeatClassId = activity.parentActivityId;
        } else {
          repeatClassId = activity.id;
        }

        if (repeatClassId) {
          let repeatClass = yield call(ActivityApi.getRepeatClassById, repeatClassId, firebase);
          if (repeatClass && repeatClass.id) {
            repeatClass.updatedBy = firebase.teacher.name;
            repeatClass.updatedOn = moment().valueOf();
            repeatClass.studentIds = activity.studentIds ? activity.studentIds : [];
            //update only student id;
            // yield call(ActivityApi.updateIndRepeatClass, repeatClass, firebase);

            rsf.ref(branchPath + "/repeatClasses/" + repeatClass.id).update({
              studentIds: repeatClass.studentIds,
            });
          }

          rsf
            .ref(branchPath + "/activities/" + repeatClassId)
            .child("studentIds")
            .set(activity.studentIds ? activity.studentIds : []);
        }
      }

      //remove student from filter node
      yield fork(removeStudentFromFilterNode, student, activity, firebase);
    }

    studentList = studentList.filter((st) => {
      return st.id !== student.id;
    });
    yield put({
      type: actions.REMOVE_STUDENT_FROM_CLASSS_SUCCESS,
    });

    yield put({
      type: actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL_SUCCESSFUL,
      taggedStudent: studentList,
    });

    yield fork(fetchTrialStudentCount, activityList, firebase);
  } catch (err) {
    console.log("failed to remove students from online class", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.VIRTUAL_REQUEST_FAILED,
    });
  }
}

function* removeStudentBookingRef(bookingRef, student, firebase) {
  try {
    let studentBookingRef = yield call(
      ActivityApi.getStudentBookingRef,
      student.id,
      bookingRef.id,
      firebase
    );

    if (studentBookingRef && studentBookingRef.id) {
      yield call(ActivityApi.removeStudentBookingRef, student.id, studentBookingRef.id, firebase);
    }
  } catch (err) {
    console.log("failed to remove student booking reference", err);
    bugsnagClient.notify(err);
  }
}

function* removeStudentFromFilterNode(student, activity, firebase) {
  try {
    let activityType = "Virtual Class";
    let data = [];
    data = yield call(
      ActivityApi.getStudentFilterNodeByActivityId,
      activityType,
      student.id,
      activity.id,
      firebase
    );

    if (activity.parentActivityId) {
      let parentData = yield call(
        ActivityApi.getStudentFilterNodeByActivityId,
        activityType,
        student.id,
        activity.parentActivityId,
        firebase
      );
      if (parentData) {
        data = [...data, ...parentData];
      }
    }

    if (data && data.length > 0) {
      for (let index in data) {
        yield call(
          ActivityApi.removeFilterNode,
          student.id,
          data[index].id,
          activityType,
          firebase
        );
      }
    }
  } catch (err) {
    console.log("failed to remove student from the filter node", err);
    bugsnagClient.notify(err);
  }
}

function* getClassLabel({ firebase }) {
  try {
    let data = yield call(ActivityApi.getCustomLabelByCategory, "Activity", firebase);

    if (data) {
      yield put({
        type: activityActions.GET_ACTIVITY_CUSTOM_LABEL_SUCCESS,
        activityLabel: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch custom labels", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_VIRTUAL_CLASS, fetchVirtualClass),
    yield takeLatest(actions.GET_VIRTUAL_TAGGED_STUDENT_DETAIL, fetchTaggedStudent),
    yield takeLatest(actions.UPDATE_VIRTUAL_ACTIVITY, updateSelectedVirtualActivity),
    yield takeLatest(actions.DELETE_VIRTUAL_ACTIVITY, deleteSelectedVirtualActivity),
    yield takeLatest(actions.GET_FILTER_CLASSROOM, fetchFilterClassroom),
    yield takeLatest(actions.STOP_V_CLASS_REPETITION, stopVirtualClassRepeat),
    yield takeLatest(actions.APPROVE_V_CLASS, approveVClassActivity),
    yield takeLatest(actions.ADD_STUDENT_TO_MEETING, addNewStudentToMeeting),
    yield takeLatest(actions.REMOVE_STUDENT_FROM_CLASS, removeStudentsFromOnlineClass),
    yield takeLatest(actions.FETCH_CLASS_LABEL, getClassLabel),
  ]);
}
