import React from "react";
import activityIcon from "../../Utility/activityIcons";
import "./styles.css";
const ActivityIconSelector = (activityType) => {
  let externalUrl = activityType.activityIcon.imageUrl
    ? activityType.activityIcon.imageUrl
    : undefined;
  let externalStyle = activityType.externalStyle;
  let enableClassName = activityType.enableClassName;
  let imgStyle = externalStyle ? externalStyle : styles.activityIcon;
  let customClassName = enableClassName
    ? enableClassName === "black"
      ? enableClassName === "black"
        ? "imageFilter"
        : "imageDropFilter"
      : "imageDropFilter"
    : "none";

  let val;
  if (activityType.activityIcon.activityType) {
    val = activityType.activityIcon.activityType.toLowerCase();
  }

  if (val) {
    switch (val) {
      case "photo":
        return (
          <img
            src={activityIcon.photo}
            alt="imageActivity"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "notification":
        return (
          <img
            src={activityIcon.announcement}
            alt="Notification"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "food":
        return (
          <img src={activityIcon.food} alt="Food" style={imgStyle} className={customClassName} />
        );
      case "diary":
        return (
          <img src={activityIcon.diary} alt="Diary" style={imgStyle} className={customClassName} />
        );
      case "finemotor":
        return (
          <img
            src={activityIcon.fineMotor}
            alt="Fine Motor"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "video":
        return (
          <img src={activityIcon.video} alt="Video" style={imgStyle} className={customClassName} />
        );
      case "incident":
        return (
          <img
            src={activityIcon.incident}
            alt="Incident"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "accident":
        return (
          <img
            src={activityIcon.accident}
            alt="Accident"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "medicine":
        return (
          <img
            src={activityIcon.medicine}
            alt="Medicine"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "artcraft":
        return (
          <img
            src={activityIcon.artAndCraft}
            alt="Art and Craft"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "art":
        return (
          <img
            src={activityIcon.artAndCraft}
            alt="Art and Craft"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "grossmotor":
        return (
          <img
            src={activityIcon.grossMotor}
            alt="Gross Motor"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "selfreliance":
        return (
          <img
            src={activityIcon.selfReliance}
            alt="Self Reliance"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "blockhour":
        return (
          <img
            src={activityIcon.blockHour}
            alt="Block Hour"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "language":
        return (
          <img
            src={activityIcon.language}
            alt="Language"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "math":
        return (
          <img src={activityIcon.math} alt="Math" style={imgStyle} className={customClassName} />
        );
      case "rhyme":
        return (
          <img src={activityIcon.rhyme} alt="Rhyme" style={imgStyle} className={customClassName} />
        );
      case "science":
        return (
          <img src={activityIcon.stem} alt="Science" style={imgStyle} className={customClassName} />
        );
      case "stories":
        return (
          <img
            src={activityIcon.stories}
            alt="Stories"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "nap":
        return (
          <img src={activityIcon.nap} alt="Nap" style={imgStyle} className={customClassName} />
        );
      case "nap (time mode)":
        return (
          <img src={activityIcon.nap} alt="Nap" style={imgStyle} className={customClassName} />
        );
      case "potty":
        return (
          <img src={activityIcon.potty} alt="Potty" style={imgStyle} className={customClassName} />
        );
      case "milestone":
        return (
          <img
            src={activityIcon.milestone}
            alt="Milestone"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "kudos":
        return (
          <img src={activityIcon.kudos} alt="Kudos" style={imgStyle} className={customClassName} />
        );
      case "circle time":
        return (
          <img
            src={activityIcon.circleTime}
            alt="Circle Time"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "sensorial":
        return (
          <img
            src={activityIcon.sensorial}
            alt="Sensorial"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "stem":
        return (
          <img src={activityIcon.stem} alt="Stem" style={imgStyle} className={customClassName} />
        );
      case "dramatics":
        return (
          <img
            src={activityIcon.dramatics}
            alt="Dramatics"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "epl":
        return (
          <img src={activityIcon.epl} alt="EPL" style={imgStyle} className={customClassName} />
        );
      case "file upload":
        return (
          <img
            src={activityIcon.fileUpload}
            alt="File Upload"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "newsletter":
        return (
          <img
            src={activityIcon.newsletter}
            alt="Newsletter"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "reminder":
        return (
          <img
            src={activityIcon.reminder}
            alt="Reminder"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "happy":
        return (
          <img src={activityIcon.happy} alt="Happy" style={imgStyle} className={customClassName} />
        );
      case "excited":
        return (
          <img
            src={activityIcon.excited}
            alt="Excited"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "sleepy":
        return (
          <img
            src={activityIcon.sleepy}
            alt="Sleepy"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "sad":
        return (
          <img src={activityIcon.sad} alt="Sad" style={imgStyle} className={customClassName} />
        );
      case "grumpy":
        return (
          <img
            src={activityIcon.grumpy}
            alt="Grumpy"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "today's mood":
        return (
          <img src={activityIcon.happy} alt="Mood" style={imgStyle} className={customClassName} />
        );
      case "attendance":
        return (
          <img
            src={activityIcon.studentAttendance}
            alt="Attendance"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "staffattendance":
        return (
          <img
            src={activityIcon.staffAttendance}
            alt="Staff Attendance"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "technology":
        return (
          <img
            src={activityIcon.technology}
            alt="Technology"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "observations":
        return (
          <img
            src={activityIcon.observations}
            alt="Observations"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "research":
        return (
          <img
            src={activityIcon.research}
            alt="Research"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "environment":
        return (
          <img
            src={activityIcon.environment}
            alt="Environment"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "virtual class":
        return (
          <img
            src={activityIcon.virtualClass}
            alt="Environment"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "medical log":
        return (
          <img
            src={activityIcon.medicalRecord}
            alt="Medical_Record"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "portfolio":
        return (
          <img
            src={activityIcon.portfolio}
            alt="Portfolio"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "check in form":
        return (
          <img
            src={activityIcon.assignment}
            alt="assignment"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "checkout form":
        return (
          <img
            src={activityIcon.assignment}
            alt="assignment"
            style={imgStyle}
            className={customClassName}
          />
        );
      case "assignment":
        return (
          <img
            src={activityIcon.assignment}
            alt="assignment"
            style={imgStyle}
            className={customClassName}
          />
        );
      default:
        if (externalUrl) {
          return (
            <img src={externalUrl} alt="custom_icon" style={imgStyle} className={customClassName} />
          );
        } else {
          return (
            <img
              src={activityIcon.grossMotor}
              alt="default"
              style={imgStyle}
              className={customClassName}
            />
          );
        }
    }
  } else {
    return (
      <img
        src={activityIcon.announcement}
        alt="default"
        style={imgStyle}
        className={customClassName}
      />
    );
  }
};
export default ActivityIconSelector;
const styles = {
  activityIcon: {
    height: "50px",
    width: "50px",
    objectFit: "contain",
  },
};
