import { all, put, call, take, takeLatest, fork, select } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { LearningApi } from "../../firestore-api/learning";
import { NotificationApi } from "../../firestore-api/notification";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { ActivityApi } from "../../firestore-api/activity";
import bugsnagClient from "@bugsnag/js";
import milestoneValue from "./jsonFile";
import moment from "moment-timezone";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import FilterAction from "../../Utility/FilterAction";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import { callApi } from "../../Utility/superAgentUntil";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
function* fetchMilestones({ firebase, milestones }) {
  const chan = yield call(LearningApi.getMilestones, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_MILESTONES_SUCCESSFUL,
        milestones: data,
        learningMilestoneChannel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch milestones", err);
    bugsnagClient.notify(err);
  }
}

function* fetchDevelopmentArea({ firebase }) {
  const chan = yield call(LearningApi.getDevelopmentAreas, firebase);
  try {
    // firebase.getDevelopmentAreas();
    // let developmentAreasMap = new Map();
    // let data = JSON.parse(getItem("developmentAreas"));

    // if (data) {
    //   for (let index in data) {
    //     developmentAreasMap.set(data[index].id, data[index]);
    //   }

    //   yield put({
    //     type: actions.LIST_DEV_AREA_SUCCESS,
    //     developmentAreas: data,
    //     developmentAreasMap: developmentAreasMap,
    //   });
    // }
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_DEV_AREA_SUCCESS,
        developmentAreas: data,
        developmentAreasChannel: chan
      });
    }

  } catch (err) {
    console.log("failed to fetch development", err);
    bugsnagClient.notify(err);
  }
}
// function* fetchLessons({ firebase, lessons, operationType }) {
//   if (lessons && lessons.length > 0) {
//     yield put({
//       type: actions.LIST_LESSONS_SUCCESSFUL,
//       lessons: lessons,
//       learningLessonChannel: operationType,
//     });
//   }
//   const chan = yield call(LearningApi.getLessons, firebase);
//   try {
//     while (true) {
//       let data = yield take(chan);
// yield put({
//   type: actions.LIST_LESSONS_SUCCESSFUL,
//   lessons: data,
//   learningLessonChannel: chan,
//   learningOperationType: operationType,
// });
//     }
//   } finally {
//     console.log("end tag channel");
//   }
//   let endpoint = `woodlandApi/learning/milestone/${element.id}` + "?centerId=" + firebase.sbDbName;
//   let response = yield call(callApi, firebase, "delete", endpoint, {});
//   if (response.status == 200) {
// yield put({
//   type: actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL,
//   area : "milestone",
// })
//   }
// }
function* fetchLessons({ firebase, lessons, operationType }) {
  try {
    let endpoint = `woodlandApi/learning/lesson` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.LIST_LESSONS_SUCCESSFUL,
        lessons: response.body.response,
        learningOperationType: operationType,
      });
    }
  } catch {
    console.log("end tag channel");
  }

}

// function* fetchLessons({ firebase, lessons, operationType }) {
//   if (lessons && lessons.length > 0) {
//     yield put({
//       type: actions.LIST_LESSONS_SUCCESSFUL,
//       lessons: lessons,
//       learningLessonChannel: operationType,
//     });
//   }

//   const chan = yield call(LearningApi.getLessons, firebase);
//   try {
//     while (true) {
//       let data = yield take(chan);
//       // console.log("lessons data", data);

//       yield put({
//         type: actions.LIST_LESSONS_SUCCESSFUL,
//         lessons: data,
//         learningLessonChannel: chan,
//         learningOperationType: operationType,
//       });
//     }
//   } finally {
//     console.log("end tag channel");
//   }
// }

function* saveNewMilestoneDomain({ values, firebase }) {
  try {
    // var nodeId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
    let endpoint = "woodlandApi/learning/developmentArea/" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, values);
    // yield call(LearningApi.saveMilestoneDomain, values, nodeId, firebase);
    yield put({
      type: actions.SAVE_MILESTONE_DOMAIN_SUCCESSFUL,
      areaEdited: values.id ? "dev" : "",
    });
  } catch (err) {
    console.log("failed to add milestone domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

/**migrate milestones */
// function* uploadExternalMilestones(firebase) {
//     let data = milestoneValue.values;

//     for (let i in data) {
//         let daId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
//         let domainName = data[i].name;
//         let shortName = domainName.substring(0, 3).trim();
//         let subDevelopmentArea = [];

//         let subDomainArr = data[i].values;
//         for (let index in subDomainArr) {
//             let sdaId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
//             subDevelopmentArea.push(sdaId);

//             let subDomainName = subDomainArr[index].name;

//             let subDomainShortName = subDomainName.substring(0, 3);
//             let subDomainMilestones = subDomainArr[index].values;

//             let milestoneIds = [];
//             for (let p in subDomainMilestones) {
//                 let mId = yield call(LearningApi.createMilestoneId, firebase);
//                 let singleMilestone = subDomainMilestones[p].name;

//                 let values = { description: singleMilestone };
//                 yield call(LearningApi.saveMilestoneAlt, values, firebase, mId);
//                 milestoneIds.push(mId);
//             }

//             let subDomainObj = {
//                 id: sdaId,
//                 name: subDomainName,
//                 shortName: subDomainShortName,
//                 milestones: milestoneIds,
//                 parentId: daId
//             }
//             yield call(LearningApi.updateDAreas, subDomainObj, firebase);

//         }

//         let daObj = {
//             id: daId,
//             name: domainName,
//             shortName: shortName,
//             subDevelopmentArea: subDevelopmentArea
//         }
//         yield call(LearningApi.updateDAreas, daObj, firebase);
//     }
// }

function* saveNewMilestoneSubDomain({ values, record, firebase }) {
  try {
    // let parentId = record.id;
    // var nodeId = yield call(LearningApi.createMilestoneDomainNodeId, firebase);
    // yield call(LearningApi.saveMilestoneDomain, values, nodeId, firebase, parentId);

    // let subDomainIds = [];
    // if (record.subDevelopmentArea) {
    //   subDomainIds = record.subDevelopmentArea;
    // }

    // subDomainIds.push(nodeId);
    // record.subDevelopmentArea = subDomainIds;
    // yield call(LearningApi.updateMilestoneDomainWithSubDomain, record, firebase);

    let endpoint = "woodlandApi/learning/subDevelopmentArea" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, values);
    let newDevelopmentAreaObj = record ? { ...record } : null
    if (newDevelopmentAreaObj && !values.id) {
      if (newDevelopmentAreaObj.subDevelopmentAreaList) {
        newDevelopmentAreaObj.subDevelopmentAreaList.push(response.body.response.id)
      }
      else {
        newDevelopmentAreaObj.subDevelopmentAreaList = [response.body.response.id]
      }
      //  newDevelopmentAreaObj.id = record.id;

      yield call(saveNewMilestoneDomain, { values: newDevelopmentAreaObj, firebase })
    }

    yield put({
      type: actions.ADD_SUB_DOMAIN_SUCCESS,
      areaEdited: values.id ? "subdev" : "",
    });
  } catch (err) {
    console.log("failed to add milestone sub domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateNewMilestoneSubDomain({ values, record, firebase }) { }

function* saveNewMilestone({ values, selectedMilestoneData, parentType, firebase }) {
  try {
    // let milestonesId = yield call(LearningApi.createMilestoneId, firebase);
    // yield call(LearningApi.saveMilestone, values, firebase, milestonesId);
    // yield call(LearningApi.updatedMilestoneInDA, milestonesId, selectedMilestoneData, firebase);
    let endpoint = "woodlandApi/learning/milestone" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, values);
    if (!values.id) {
      let newDevelopmentAreaObj = { ...selectedMilestoneData }
      if (newDevelopmentAreaObj.milestones) {
        newDevelopmentAreaObj.milestones.push(response.body.response.id)
      }
      else {
        newDevelopmentAreaObj.milestones = [response.body.response.id]
      }
      if (parentType.toLowerCase() === "development") {
        yield call(saveNewMilestoneDomain, { values: newDevelopmentAreaObj, firebase })
      }
      else if (parentType.toLowerCase() === "subdevelopment") {
        yield call(saveNewMilestoneSubDomain, { values: newDevelopmentAreaObj, firebase })

      }
    }
    yield put({
      type: actions.SAVE_MILESTONE_SUCCESSFUL,
      areaEdited: values.id ? "milestone" : "",
    });
  } catch (err) {
    console.log("failed to add milestone", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewLabel({ value, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createLabelNodeId, firebase);
    yield call(LearningApi.addLabel, value, nodeId, firebase);
    yield put({
      type: actions.ADD_LABEL_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add label", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchLabel({ category, firebase }) {
  const chan = yield call(LearningApi.getLabel, category, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_LABEL_SUCCESSFUL,
        labels: data,
        labelChannel: chan,
      });
    }
  } finally {
    console.log("end label channel");
  }
}

function* updateExistingMilestoneDomain({ values, record, firebase }) {
  try {
    let subDomainIds = [];
    if (record.subDevelopmentArea) {
      subDomainIds = record.subDevelopmentArea;
    }

    yield call(LearningApi.updateMilestoneDomain, values, record, firebase, subDomainIds);
    yield put({
      type: actions.UPDATE_MILESTONE_DOMAIN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update milestone domain", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingMilestone({ values, editableMilestone, index, firebase }) {
  try {
    yield call(LearningApi.updateMilestone, values, editableMilestone, index, firebase);
    yield put({
      type: actions.UPDATE_MILESTONE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update milestone ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewCategory({ value, firebase }) {
  try {
    var nodeId = yield call(LearningApi.createCategoryNodeId, firebase);
    yield call(LearningApi.addCategory, value, nodeId, firebase);
    yield put({
      type: actions.ADD_CATEGORY_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add category", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingCategory({ name, record, firebase }) {
  try {
    var nodeId = record.id;
    yield call(LearningApi.addCategory, name, nodeId, firebase);
    yield put({
      type: actions.UPDATE_CATEGORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update category", err);
    bugsnagClient.notify(err);
  }
}

function* fetchCategory({ firebase }) {
  try {
    var category = yield call(LearningApi.getCategory, firebase);
    if (category) {
      yield put({
        type: actions.GET_CATEGORY_SUCCESSFUL,
        categories: category,
      });
    }
  } catch (err) {
    console.log("failed to get category", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteCategory({ record, firebase }) {
  try {
    yield call(LearningApi.deleteCategory, record, firebase);
    yield put({
      type: actions.DELETE_CATEGORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete category", err);
    bugsnagClient.notify(err);
  }
}

function* fetchLearningClassroom({ firebase }) {
  try {
    //var classrooms = yield call(LearningApi.getLearningClassroom, firebase);
    let classrooms = JSON.parse(getItem("classList"));
    if (classrooms) {
      yield put({
        type: actions.GET_LEARNING_CLASSROOM_SUCCESSFUL,
        classrooms: classrooms,
      });
    }
  } catch (err) {
    console.log("failed to get classrooms", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function getYoutubeCode(url) {
  var ID = "";
  url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = "not valid";
  }
  return ID;
}
function getLessonFieldRef(value, lessonAdditionalFields) {
  let newObj = JSON.parse(JSON.stringify(lessonAdditionalFields));
  newObj.forEach((lItem) => {
    let dataType = lItem.dataType.toLowerCase();
    if (dataType === "date") {
      lItem.value = value[lItem.id]
        ? moment(value[lItem.id]).format("DD[ ]MMM[ ]YY")
        : null;
    } else if (dataType === "time") {
      lItem.value = value[lItem.id]
        ? moment(value[lItem.id]).format("h:mm A")
        : null;
    } else if (dataType === "yes/no checkbox") {
      lItem.value =
        value[lItem.id] === "Yes"
          ? "Yes"
          : "No";
    } else if (dataType === "multi select") {
      lItem.value = value[lItem.id]
        ? value[lItem.id]
        : [];
    } else {
      lItem.value = value[lItem.id]
        ? value[lItem.id].toString()
        : null;
    }
  });
  return newObj;
}

function* addNewLesson({ value, selectedMilestones, classroom, firebase, lessonAdditionalFields }) {

  try {
    let x = [];
    if (value.lessonCategory && value.lessonCategory.length > 0) {
      var data = {};
      data.id = value.lessonCategory[1];
      data.name = value.lessonCategory[0];
      x.push(data);
    }
    let fieldRef = getLessonFieldRef(value, lessonAdditionalFields);
    let reqBody = {
      name: value.lessonTitle ? value.lessonTitle : null,
      description: value.description ? value.description : null,
      htmlText: value.htmlText ? value.htmlText : null,
      classroom: (classroom && classroom.length > 0) ? classroom : null,
      category: (x && x.length > 0) ? x : null,
      developmentAreasList: value.developmentAreasList ? Array.from(value.developmentAreasList) : null,
      subDevelopmentAreasList: value.subDevelopmentAreasList ? Array.from(value.subDevelopmentAreasList) : null,
      milestonesList: value.milestoneList ? Array.from(value.milestoneList) : null,
      duration: value.duration ? value.duration : null,
      labelName: value.label ? value.label : null,
    };

    if (lessonAdditionalFields) {
      reqBody.fieldConfigModels = fieldRef;
    }

    if (value.attachment && value.attachment.fileList && value.attachment.fileList.length > 0) {
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        value.attachment,
        firebase,
      );
      let urlList = [];
      let tempMediaType = "none";
      urls.map((m) => {
        urlList.push(m.path);
        tempMediaType = m.type ? m.type : "none";
      });
      reqBody.mediaProfiles = urls;
      reqBody.mediaUrls = urlList;
      reqBody.mediaType = urls.length > 0 ? tempMediaType : null;
    }

    if (value.youtubeLink) {
      let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Invaid youtube url",
        });
        return;
      } else {
        reqBody.youtubeUrl = youtubeUrlCode;
      }
    }



    let endpoint = "woodlandApi/learning/lesson" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.ADD_LESSON_SUCCESSFUL,
      });
    }


  } catch (err) {
    console.log("failed to add lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}
function* AddNewLesson({ value, selectedMilestones, classroom, firebase }) {
  try {

    var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
    let fieldRef = getLessonFieldRef(value, lessonAdditionalFields);
    yield call(LearningApi.addLesson, value, selectedMilestones, classroom, nodeId, firebase, undefined, fieldRef);
    yield put({
      type: actions.ADD_LESSON_SUCCESSFUL,
    });
    if (value.attachment && value.attachment.fileList && value.attachment.fileList.length > 0) {
      // let urls = yield call(LearningApi.getDownloadUrlForAttachment, value.attachment, firebase, undefined, value.mediaType);
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        value.attachment,
        firebase,
        nodeId,
        undefined,
        "lesson"
      );

      if (urls && urls.length > 0) {
        yield fork(ActivityApi.generateThumbnail, urls, nodeId, "lesson", undefined, firebase);

        yield call(
          LearningApi.updateLessonMedia,
          nodeId,
          urls,
          value.mediaType,
          firebase,
          undefined
        );
      } else {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Failed to upload attachment",
        });
        return;
      }
    }
    if (value.youtubeLink) {
      let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Invaid youtube url",
        });
        return;
      } else {
        yield call(LearningApi.updateYoutubeToLesson, nodeId, youtubeUrlCode, firebase, undefined);
      }
    }
  } catch (err) {
    console.log("failed to add lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewLessonToAllCenter({ value, selectedMilestones, branchList, firebase, lessonAdditionalFields }) {

  try {
    let centerClasses = JSON.parse(getItem("classmap"));
    let tempBranchMap = new Map();
    let branches = branchList;
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
    // for (let i in branchList) {

    for (let [k, v] of tempBranchMap) {
      //let bPath = branchList[i].name;
      let bPath = k;
      let classList = v;

      let classNames = [];
      if (classList.length > 0) {
        classList.map((c) => {
          classNames.push({
            id: c.id ? c.id : null,
            name: c.className,
          });
        });
      }
      let fieldRef = getLessonFieldRef(value, lessonAdditionalFields);
      yield call(
        LearningApi.addLesson,
        value,
        selectedMilestones,
        classNames,
        nodeId,
        firebase,
        bPath,
        fieldRef
      );
      // classsroom  : null,
      // branchList =  [{branchPath : string ,classrrom:[strings]},{branchPath : string ,classrrom:[strings]},{branchPath : string ,classrrom:[strings]}]

      if (
        value.attachment &&
        value.attachment.fileList &&
        value.attachment.fileList.length > 0 &&
        (!value.mediaType || value.mediaType.toLowerCase() !== "youtube link")
      ) {
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          value.attachment,
          firebase,
          nodeId,
          bPath,
          "lesson"
        );

        // let urls = yield call(LearningApi.getDownloadUrlForAttachment, value.attachment, firebase, bPath, value.mediaType);
        if (urls && urls.length > 0) {
          yield fork(ActivityApi.generateThumbnail, urls, nodeId, "lesson", undefined, firebase);

          yield call(LearningApi.updateLessonMedia, nodeId, urls, value.mediaType, firebase, bPath);
        } else {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Failed to upload attachment",
          });
          return;
        }
      }
      if (value.youtubeLink) {
        let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Invaid youtube url",
          });
          return;
        } else {
          yield call(LearningApi.updateYoutubeToLesson, nodeId, youtubeUrlCode, firebase, bPath);
        }
      }
    }
    yield put({
      type: actions.ADD_LESSON_TO_ALL_CENTERS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add lesson to all centers", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateExistingLesson({
  values,
  editableLessonRecord,
  selectedMilestones,
  classroom,
  attachedFilesUrl,
  firebase,
  updateStoreLast,
  lessonAdditionalFields
}) {
  try {

    let x = [];
    let value = values;
    if (value.lessonCategory && value.lessonCategory.length > 0) {
      var data = {};
      data.id = value.lessonCategory[1];
      data.name = value.lessonCategory[0];
      x.push(data);
    }
    let fieldRef = getLessonFieldRef(value, lessonAdditionalFields);
    let reqBody = {
      id: editableLessonRecord.id,
      name: value.lessonTitle ? value.lessonTitle : null,
      description: value.description ? value.description : null,
      htmlText: value.htmlText ? value.htmlText : null,
      classroom: (classroom && classroom.length > 0) ? classroom : null,
      category: (x && x.length > 0) ? x : null,
      developmentAreasList: value.developmentAreasList ? Array.from(value.developmentAreasList) : null,
      subDevelopmentAreasList: value.subDevelopmentAreasList ? Array.from(value.subDevelopmentAreasList) : null,
      milestonesList: value.milestoneList ? Array.from(value.milestoneList) : null,
      duration: value.duration ? value.duration : null,
      labelName: value.label ? value.label : null,
    };

    if (lessonAdditionalFields) {
      reqBody.fieldConfigModels = fieldRef;
    }
    if (attachedFilesUrl && attachedFilesUrl.length > 0) {    // filling old  data;
      reqBody.mediaProfiles = [...attachedFilesUrl];
      let urlList1 = [];
      let tempMediaType1 = "none";
      attachedFilesUrl.map((m) => {
        urlList1.push(m.path);
        tempMediaType1 = m.type ? m.type : "none";
      });
      reqBody.mediaUrls = urlList1;
      reqBody.mediaType = tempMediaType1;
    }


    if (value.attachment && value.attachment.fileList && value.attachment.fileList.length > 0) { // filling new data
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        value.attachment,
        firebase,
      );

      let urlList = [];
      let tempMediaType = "none";
      urls.map((m) => {
        urlList.push(m.path);
        tempMediaType = m.type ? m.type : "none";
      });

      if (reqBody.mediaProfiles && reqBody.mediaProfiles.length > 0) {
        reqBody.mediaProfiles = [...reqBody.mediaProfiles, ...urls];
      }
      else {
        reqBody.mediaProfiles = urls;
      }

      if (reqBody.mediaUrls && reqBody.mediaUrls.length > 0) {
        reqBody.mediaUrls = [...reqBody.mediaUrls, ...urlList];
      }
      else {
        reqBody.mediaUrls = urlList;
      }
      reqBody.mediaType = urls.length > 0 ? tempMediaType : null;
    }

    if (value.youtubeLink) {
      let youtubeUrlCode = getYoutubeCode(value.youtubeLink);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
          errorMessage: "Invaid youtube url",
        });
        return;
      } else {
        reqBody.youtubeUrl = youtubeUrlCode;
      }
    }


    let endpoint = "woodlandApi/learning/lesson" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.ADD_LESSON_SUCCESSFUL,
      });
    }


  } catch (err) {
    console.log("failed to add lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }

}

// function* updateExistingLesson({    
//   values,
//   editableLessonRecord,
//   selectedMilestones,
//   classroom,
//   attachedFilesUrl,
//   firebase,
//   updateStoreLast,
//   lessonAdditionalFields
// }) {
//   try {
//     let fieldRef = getLessonFieldRef(values,lessonAdditionalFields);


//    console.log(values,editableLessonRecord,selectedMilestones,classroom, attachedFilesUrl,firebase,updateStoreLast)

//     yield call(
//       LearningApi.updateLesson,
//       values,
//       editableLessonRecord,
//       selectedMilestones,
//       classroom,
//       attachedFilesUrl,
//       firebase,
//       undefined,
//       fieldRef
//     );

//     if (!updateStoreLast) {
//       yield put({
//         type: actions.UPDATE_LESSON_SUCCESSFUL,
//       });
//     }

//     if (
//       (values.attachment || attachedFilesUrl.length > 0) &&
//       (!values.mediaType || values.mediaType.toLowerCase() !== "youtube link")
//     ) {
//       // let urls = yield call(LearningApi.updateLessonAttachment, values.attachment, editableLessonRecord, attachedFilesUrl, firebase, undefined, values.mediaType);
//       let storagePath = firebase.sbp + "/media/mediaProfiles/";
//       let prevUrls = [];
//       if (attachedFilesUrl) {
//         prevUrls = attachedFilesUrl;
//       }

//       let urls = [];
//       if (values.attachment) {
//         urls = yield call(
//           ActivityApi.getAttachmentMediaPath,
//           storagePath,
//           values.attachment,
//           firebase,
//           editableLessonRecord.id,
//           undefined,
//           "lesson"
//         );
//       }

//       if (urls) {
//         let newUrls = JSON.parse(JSON.stringify(urls));
//         urls = [...urls, ...prevUrls];
//         yield call(
//           LearningApi.updateLessonMedia,
//           editableLessonRecord.id,
//           urls,
//           values.mediaType,
//           firebase,
//           undefined
//         );

//         yield fork(
//           ActivityApi.generateThumbnail,
//           newUrls,
//           editableLessonRecord.id,
//           "lesson",
//           undefined,
//           firebase
//         );
//       } else {
//         yield put({
//           type: actions.LEARNING_REQUEST_FAILED,
//           errorMessage: "Failed to upload attachment",
//         });
//         return;
//       }
//     }

//     if (values.youtubeLink) {
//       let youtubeUrlCode = getYoutubeCode(values.youtubeLink);
//       if (youtubeUrlCode === "not valid") {
//         yield put({
//           type: actions.LEARNING_REQUEST_FAILED,
//           errorMessage: "Invaid youtube url",
//         });
//         return;
//       } else {
//         yield call(
//           LearningApi.updateYoutubeToLesson,
//           editableLessonRecord.id,
//           youtubeUrlCode,
//           firebase,
//           undefined
//         );
//       }
//     }

//     if (updateStoreLast) {
//       yield put({
//         type: actions.UPDATE_LESSON_SUCCESSFUL,
//       });
//     }
//   } catch (err) {
//     console.log("failed to update lesson ", err);
//     bugsnagClient.notify(err);
//     yield put({
//       type: actions.LEARNING_REQUEST_FAILED,
//     });
//   }
// }

function* updateExistingLessonToAllCenter({
  values,
  editableLessonRecord,
  selectedMilestones,
  branchList,
  attachedFilesUrl,
  firebase,
  lessonAdditionalFields
}) {
  try {
    let centerClasses = JSON.parse(getItem("classmap"));
    let tempBranchMap = new Map();
    let branches = branchList;
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    // for (let i in branchList) {
    for (let [k, value] of tempBranchMap) {
      //let bPath = branchList[i].name;
      let bPath = k;

      let classList = value;
      let classNames = [];
      if (classList.length > 0) {
        classList.map((c) => {
          classNames.push({
            id: c.id ? c.id : null,
            name: c.className,
          });
        });
      }

      let fieldRef = getLessonFieldRef(values, lessonAdditionalFields);
      yield call(
        LearningApi.updateLesson,
        values,
        editableLessonRecord,
        selectedMilestones,
        classNames,
        attachedFilesUrl,
        firebase,
        bPath,
        fieldRef
      );

      yield put({
        type: actions.UPDATE_LESSON_TO_ALL_CENTERS_SUCCESSFUL,
      });

      if (
        values.attachment &&
        (!values.mediaType || values.mediaType.toLowerCase() !== "youtube link")
      ) {
        // let urls = yield call(LearningApi.updateLessonAttachment, values.attachment, editableLessonRecord, attachedFilesUrl, firebase, bPath, values.mediaType);
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let prevUrls = [];
        if (attachedFilesUrl) {
          prevUrls = attachedFilesUrl;
        }

        let urls = [];

        if (value.attachment) {
          urls = yield call(
            ActivityApi.getAttachmentMediaPath,
            storagePath,
            values.attachment,
            firebase,
            editableLessonRecord.id,
            undefined,
            "lesson"
          );
        }

        if (urls && urls.length > 0) {
          urls = [...urls, ...prevUrls];
          yield call(
            LearningApi.updateLessonMedia,
            editableLessonRecord.id,
            urls,
            values.mediaType,
            firebase,
            bPath
          );

          yield fork(
            ActivityApi.generateThumbnail,
            urls,
            editableLessonRecord.id,
            "lesson",
            undefined,
            firebase
          );
        } else {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Failed to upload attachment",
          });
          return;
        }
      }
      if (values.youtubeLink) {
        let youtubeUrlCode = getYoutubeCode(values.youtubeLink);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.LEARNING_REQUEST_FAILED,
            errorMessage: "Invaid youtube url",
          });
          return;
        } else {
          yield call(
            LearningApi.updateYoutubeToLesson,
            editableLessonRecord.id,
            youtubeUrlCode,
            firebase,
            bPath
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to update lesson to all centers ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* addNewWeeklyPlan({
  selectedLesson,
  selectedDate,
  weeklyPlanData,
  selectedDropdownValue,
  firebase,
  roomList,
}) {
  try {
    for (let index in roomList) {
      let classroomName = roomList[index];
      yield call(
        LearningApi.addWeeklyPlan,
        selectedLesson,
        selectedDate,
        weeklyPlanData,
        // selectedDropdownValue,
        classroomName,
        firebase
      );

      if (firebase.schoolConfig.studentLessonShare) {
        // yield put({
        //   type: actions.ADD_WEEKLY_PLAN_SUCCESSFUL,
        // });
        //do nothing
      } else {
        if (!firebase.schoolConfig.disableAutomaticLessonShare) {
          let obj = {
            date: selectedDate.toDateString(),
            lessons: [selectedLesson],
          };
          yield fork(
            manageStudentAssignment,
            obj,
            //selectedDropdownValue,
            classroomName,
            firebase,
            "add"
          );
        }

        // yield put({
        //   type: actions.ADD_WEEKLY_PLAN_SUCCESSFUL,
        // });
      }
    }
    yield put({
      type: actions.ADD_WEEKLY_PLAN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add weekly plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchWeeklyPlan({ from, to, selectedRoom, firebase }) {
  const chan = yield call(LearningApi.getWeeklyLesson, from, to, selectedRoom, firebase);
  try {
    while (true) {
      let weeklyPlan = yield take(chan);
      yield put({
        type: actions.GET_WEEKLY_PLAN_SUCCESSFUL,
        weeklyPlan: weeklyPlan,
        weeklyPlanLessonChannel: chan,
      });
    }
  } finally {
    console.log("end weekly plan channel");
  }
}

function* deleteSelectedPlan({
  selectedItem,
  item,
  weeklyPlan,
  classDropdown,
  firebase,
  studentList,
}) {
  try {
    let removableId = selectedItem[0].id;
    let searchableDate = item.date.toDateString().trim();
    let lessons = [];
    let selectedWeeklyPlan;
    for (let i = 0; i < weeklyPlan.length; i++) {
      if (weeklyPlan[i].date) {
        if (searchableDate === weeklyPlan[i].date.trim()) {
          selectedWeeklyPlan = weeklyPlan[i];
          let x = weeklyPlan[i].lessons;
          for (let j = 0; j < x.length; j++) {
            if (removableId !== x[j]) {
              lessons.push(x[j]);
            }
          }
        }
      }
    }

    let plan = JSON.parse(JSON.stringify(selectedWeeklyPlan));
    plan.lessons = [];
    plan.lessons.push(selectedItem);

    yield call(LearningApi.deleteWeeklyPlan, searchableDate, classDropdown, lessons, firebase);
    yield fork(manageStudentAssignment, plan, classDropdown, firebase, "delete", studentList);
    yield put({
      type: actions.DELETE_PLAN_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete weekly plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedLessonRecord({ value, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/lesson/${value.id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_LESSON_RECORD_SUCCESSFUL,
      });
    }
  } catch (err) {
    console.log("failed to delete lesson record", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedLessonRecordForAllCenter({ value, firebase, branchList }) {
  try {
    for (let i in branchList) {
      let bPath = branchList[i].name;
      yield call(LearningApi.deleteLessonRecord, value, firebase, bPath);
    }
    yield put({
      type: actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to delete lesson record for all center", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

// function* deleteSelectedMilestone({ element, index, item, firebase }) {
//   try {
//     yield call(LearningApi.deleteSingleMilestone, element, index, item, firebase);
//     yield put({
//       type: actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL,
//     });
//   } catch (err) {
//     console.log("failed to delete single milestone", err);
//     bugsnagClient.notify(err);
//     yield put({
//       type: actions.LEARNING_REQUEST_FAILED,
//     });
//   }
// }
function* deleteSelectedMilestone({ element, index, item, firebase }) {
  try {

    let endpoint = `woodlandApi/learning/milestone/${element.id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_SINGLE_MILESTONE_SUCCESSFUL,
        area: "milestone",
      })
    }
  }
  catch (err) {
    console.log("failed to delete Milestone", err);
    bugsnagClient.notify(err);
  }
}

// function* deleteSelectedDomain({ item, firebase }) {
//   try {
//     yield call(LearningApi.deleteSingleDomain, item, firebase);
//     yield put({
//       type: actions.DELETE_SINGLE_DOMAIN_SUCCESSFUL,
//     });

//   } catch (err) {
//     console.log("failed to delete single domain", err);
//     bugsnagClient.notify(err);
//     yield put({
//       type: actions.LEARNING_REQUEST_FAILED,
//     });
//   }
// }
function* deleteSelectedDomain({ item, firebase, area }) {

  try {
    let endpoint;
    if (area === "dev") {
      endpoint = `woodlandApi/learning/developmentArea/${item.id}` + "?centerId=" + firebase.sbDbName;
    }
    else {
      endpoint = `woodlandApi/learning/subDevelopmentArea/${item.id}` + "?centerId=" + firebase.sbDbName;
    }
    // let endpoint = `woodlandApi/learning/developmentArea/${item.id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});

    if (response.status == 200) {
      yield put({
        type: actions.DELETE_SINGLE_DOMAIN_SUCCESSFUL,
        areaDeleted: area,
      })
    }
  }
  catch (err) {

    console.log("failed to delete Development area", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentForLesson({ firebase }) {
  try {
    //var data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = FilterAction.getStudentList(firebase);

    if (data) {
      data = data.filter((std) => {
        return !std.status || std.status.toLowerCase() === "active";
      });
      yield put({
        type: actions.GET_STUDENT_FOR_LESSON_SUCCESSFUL,
        students: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch students", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* shareSelectedLesson({ students, record, values, selectedClass, firebase, shareDate }) {
  // console.log("shareSelectedLesson ------", students, record, values, firebase);
  try {
    let schoolAssignmentId = yield call(LearningApi.generateSchoolAssignmentNode, firebase);

    let studentIds = [];
    let selectedStudent = students;
    for (let index in selectedStudent) {
      let id = selectedStudent[index].id;
      studentIds.push(id);
    }

    let classNames = [];
    if (selectedClass === "All Classrooms") {
      classNames = firebase.teacher.classList ? firebase.teacher.classList : [];
    } else {
      classNames.push(selectedClass);
    }

    let schoolAssignmentObj = {
      allowDownload: values.allowDownload,
      classNames: classNames,
      classroom: selectedClass,
      delete: false,
      dueDate: values.dueDate ? moment(values.dueDate).valueOf() : 0,
      extraNote: values.notes && values.notes !== "" ? values.notes : null,
      id: schoolAssignmentId,
      inverseDate: -new Date().getTime(),
      lessonId: record.id,
      shareOn: shareDate
        ? moment(shareDate).startOf("day").add(20, "second").valueOf()
        : moment().startOf("day").add(20, "second").valueOf(),
      sharedBy: firebase.teacher.name,
      studentIds: studentIds,
      submissionRequired: values.type === "Task" ? "Yes" : "No",
      type: values.type,
    };

    yield call(
      LearningApi.updateSchoolAssignment,
      schoolAssignmentObj,
      schoolAssignmentId,
      firebase
    );

    for (let i = 0; i < selectedStudent.length; i++) {
      let studentAssignmentId = yield call(LearningApi.generateStudentAssignmentNode, firebase);
      let studentAssignmentObj = {
        assignmentId: schoolAssignmentId,
        done: false,
        id: studentAssignmentId,
        inversedSharedDate: shareDate
          ? -moment(shareDate).startOf("day").add(20, "second").valueOf()
          : -moment().startOf("day").add(20, "second").valueOf(),
        lessonId: record.id,
        seen: false,
        sharedOn: shareDate
          ? moment(shareDate).startOf("day").add(20, "second").valueOf()
          : moment().startOf("day").add(20, "second").valueOf(),
        teacherId: firebase.teacher.id,
        type: values.type,
      };

      yield call(
        LearningApi.updateStudentAssignment,
        studentAssignmentObj,
        studentAssignmentId,
        selectedStudent[i].id,
        firebase
      );

      if (selectedStudent[i].fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "StudentLearning",
          schoolAssignmentId,
          selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          "New lesson plan added",
          alertNode,
          selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUUid !== undefined ||
          selectedStudent[i].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "StudentLearning",
            schoolAssignmentId,
            selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            "New lesson plan added",
            alertNode,
            selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent[i].motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          "StudentLearning",
          schoolAssignmentId,
          selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          "New lesson plan added",
          alertNode,
          selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUUid !== undefined ||
          selectedStudent[i].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            "StudentLearning",
            schoolAssignmentId,
            selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
            "New lesson plan added",
            alertNode,
            selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }
    }
    yield fork(
      NotificationApi.sendStats,
      shareDate ? shareDate : new Date(),
      "updateDailyPostStats",
      firebase
    );
    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "lessons",
      shareDate ? shareDate : new Date()
    );
    yield put({
      type: actions.SHARE_LESSON_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to share lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* uploadLessonCsv({ data, category, firebase }) {
  try {
    let initialCounter = data.length;
    let actualCounter = 0;
    for (let i = 0; i < data.length; i++) {
      let inputCat = data[i].category.trim();
      let filteredCategory = category.filter((ele) => {
        return ele.name.trim() === inputCat;
      });

      let lessonCategory = [];
      if (filteredCategory && filteredCategory.length > 0) {
        lessonCategory = [filteredCategory[0].name, filteredCategory[0].id];
      } else {
        var categoryId = yield call(LearningApi.createCategoryNodeId, firebase);
        yield call(LearningApi.addCategory, inputCat, categoryId, firebase);
        lessonCategory = [inputCat, categoryId];
      }

      let milestones = [];
      if (data[i].milestones) {
        let mArray = data[i].milestones.split(",");
        for (let index in mArray) {
          milestones.push({
            name: mArray[index],
          });
        }
      }

      let classrooms = [];
      if (data[i].classroom) {
        let cArray = data[i].classroom.split(",");
        for (let index in cArray) {
          classrooms.push({
            name: cArray[index],
          });
        }
      }

      actualCounter++;
      let lessonVal = {
        lessonTitle: data[i].title.trim(),
        description: data[i].description.trim(),
        lessonCategory: lessonCategory,
        duration: data[i].duration,
        label: [],
      };
      var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
      yield call(LearningApi.addLesson, lessonVal, milestones, classrooms, nodeId, firebase);
    }

    yield put({
      type: actions.UPLOAD_LESSON_DATA_SUCCESSFUl,
      feedbackMessage: actualCounter + " out of " + initialCounter + " lessons uploaded.",
    });
  } catch (err) {
    console.log("upload lesson csv failed", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* fetchAssessmentProgressParam({ firebase }) {
  try {
    let data = yield call(AssessmentApi.getProgressParams, firebase);
    if (data) {
      yield put({
        type: actions.GET_ASSESSMENT_PROGRESS_PARAM_SUCCESS,
        assessmentProgressParams: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch assessment progress param", err);
    bugsnagClient.notify(err);
  }
}

function* addNewAssProgressParam({ name, firebase, background, textColor }) {
  try {
    let nodeId = yield call(AssessmentApi.generateProgressParamNode, firebase);
    yield call(AssessmentApi.addProgressParam, nodeId, name, firebase, background, textColor);
    yield put({
      type: actions.SAVE_ASS_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add new progress param", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedAssProgressParam({ record, firebase }) {
  try {
    yield call(AssessmentApi.deleteProgressParams, record, firebase);
    yield put({
      type: actions.DELETE_ASS_PROGRESS_PARAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete progress params", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedMilestoneLabel({ record, firebase }) {
  try {
    yield call(LearningApi.deleteLabel, record, firebase);
    yield put({
      type: actions.DELETE_LABEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete label", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* getLessonShareHistory({ lessons, firebase }) {
  try {
    let assignmentTask = [];
    let historyMap = new Map();
    for (let index in lessons) {
      let task = call(lessonAssignmentApi.getAssignmentByLessonId, lessons[index].id, firebase);
      assignmentTask.push(task);
      historyMap.set(lessons[index].id, 0);
    }

    let newVal = yield all([assignmentTask]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      if (val && val.length > 0 && val[0].lessonId) {
        if (historyMap.has(val[0].lessonId)) {
          historyMap.set(val[0].lessonId, val.length);
        }
      }
    }
    console.log("historyMap", historyMap);
    yield put({
      type: actions.FETCH_COMPLETE_SHARE_HISTORY_SUCCESS,
      shareHistoryMap: historyMap,
    });
  } catch (err) {
    console.log("failed to fetch lesson share history", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateLessonShareStatus({ sharedStatus, lesson, classroom, firebase, students }) {
  try {
    let studentLessonShare =
      firebase && firebase.schoolConfig.studentLessonShare
        ? firebase.schoolConfig.studentLessonShare
        : false;
    if (studentLessonShare) {
      if (sharedStatus) {
        let studentIds = [];
        if (lesson.students && lesson.students.length > 0) {
          studentIds = lesson.students;
        }

        students.forEach((std) => {
          if (!studentIds.includes(std.id)) {
            studentIds.push(std.id);
          }
        });

        yield call(
          LearningApi.updateweeklyPlanShareStatus,
          sharedStatus,
          lesson,
          classroom,
          firebase,
          studentIds
        );
        yield fork(manageStudentAssignment, lesson, classroom, firebase, "add", students);
      } else {
        let studentIds = [];
        if (lesson.students && lesson.students.length > 0) {
          studentIds = lesson.students;
        }

        students.forEach((std) => {
          if (studentIds.includes(std.id)) {
            studentIds = studentIds.filter((s) => {
              return s !== std.id;
            });
          }
        });

        yield call(
          LearningApi.updateweeklyPlanShareStatus,
          sharedStatus,
          lesson,
          classroom,
          firebase,
          studentIds
        );

        yield fork(manageStudentAssignment, lesson, classroom, firebase, "delete", students);
      }
    } else {
      yield call(
        LearningApi.updateweeklyPlanShareStatus,
        sharedStatus,
        lesson,
        classroom,
        firebase
      );

      if (!sharedStatus) {
        yield fork(manageStudentAssignment, lesson, classroom, firebase, "add");
      } else {
        yield fork(manageStudentAssignment, lesson, classroom, firebase, "delete");
      }
    }
    yield put({
      type: actions.UPDATE_SHARE_STATUS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update share status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* manageStudentAssignment(lesson, classroom, firebase, operationType, studentData) {
  try {
    let selectedStudent = [];
    let students = FilterAction.getStudentList(firebase);
    if (studentData) {
      selectedStudent = studentData;
    } else {
      selectedStudent = students.filter((s) => {
        if (s.classList.includes(classroom) && (!s.status || s.status.toLowerCase() === "active")) {
          return s.id;
        }
      });
    }
    console.log("selectedStudent ---- ", selectedStudent);

    let lessonArr = lesson.lessons;
    for (let i in lessonArr) {
      let lsnArr = lessonArr[i];
      for (let index in lsnArr) {
        let lessonId = lsnArr[index].id;
        let schoolAssignmentId = lessonId + moment(lesson.date).format("DD[]MM[]YYYY");

        let studentNotificationSent = {};
        for (let i = 0; i < selectedStudent.length; i++) {
          if (operationType === "add") {
            let studentAssignmentObj = {
              assignmentId: schoolAssignmentId,
              done: false,
              id: schoolAssignmentId,
              inversedSharedDate: -moment().startOf("day").add(20, "second").valueOf(),
              lessonId: lessonId,
              seen: false,
              sharedOn: moment().startOf("day").add(20, "second").valueOf(),
              teacherId: firebase.teacher.id,
              type: "Task",
              platform: "web",
            };
            yield fork(
              LearningApi.updateStudentAssignment,
              studentAssignmentObj,
              schoolAssignmentId,
              selectedStudent[i].id,
              firebase
            );

            let shareDate = moment().startOf("day").add(1, "minute").valueOf();
            let planStartDate = moment(lesson.date).startOf("week").add(1, "minute").valueOf();
            let planEndDate = moment(lesson.date).endOf("week").valueOf();
            let branchPath = undefined;

            if (!studentNotificationSent[selectedStudent[i].id]) {
              let bodyMessage = "Lesson plan shared for " + moment(lesson.date).format("DD[ ]MMMM");
              if (selectedStudent[i].fatherProfileId) {
                let alertNode = yield call(
                  NotificationApi.createAlertReferenceNode,
                  selectedStudent[i].fatherProfileId,
                  firebase
                );
                yield fork(
                  NotificationApi.createAlertNotification,
                  "StudentLessonPlanShared",
                  schoolAssignmentId,
                  selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                  bodyMessage,
                  alertNode,
                  selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                  selectedStudent[i].id,
                  selectedStudent[i].fatherProfileId,
                  firebase,
                  branchPath,
                  shareDate,
                  planStartDate,
                  planEndDate
                );

                if (
                  selectedStudent[i].fatherUUid !== undefined ||
                  selectedStudent[i].ios_fatherUUid !== undefined
                ) {
                  yield fork(
                    NotificationApi.sendPushNotification,
                    "StudentLessonPlanShared",
                    schoolAssignmentId,
                    selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                    bodyMessage,
                    alertNode,
                    selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                    selectedStudent[i].id,
                    selectedStudent[i].fatherProfileId,
                    firebase,
                    branchPath,
                    shareDate,
                    planStartDate,
                    planEndDate
                  );
                }
              }

              if (selectedStudent[i].motherProfileId) {
                let alertNode = yield call(
                  NotificationApi.createAlertReferenceNode,
                  selectedStudent[i].motherProfileId,
                  firebase
                );
                yield fork(
                  NotificationApi.createAlertNotification,
                  "StudentLessonPlanShared",
                  schoolAssignmentId,
                  selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                  bodyMessage,
                  alertNode,
                  selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                  selectedStudent[i].id,
                  selectedStudent[i].motherProfileId,
                  firebase,
                  branchPath,
                  shareDate,
                  planStartDate,
                  planEndDate
                );

                if (
                  selectedStudent[i].motherUUid !== undefined ||
                  selectedStudent[i].ios_motherUUid !== undefined
                ) {
                  yield fork(
                    NotificationApi.sendPushNotification,
                    "StudentLessonPlanShared",
                    schoolAssignmentId,
                    selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                    bodyMessage,
                    alertNode,
                    selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                    selectedStudent[i].id,
                    selectedStudent[i].motherProfileId,
                    firebase,
                    branchPath,
                    shareDate,
                    planStartDate,
                    planEndDate
                  );
                }
              }
            }
            studentNotificationSent[selectedStudent[i].id] = true;
          } else if (operationType === "delete") {
            yield fork(
              LearningApi.deleteStudentAssignment,
              schoolAssignmentId,
              selectedStudent[i].id,
              firebase
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to create school assignment", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanExtraDetails({ plan, firebase }) {
  try {
    yield fork(fetchPlanComment, plan, firebase);
    yield fork(fetchPlanSubmission, plan, firebase);
  } catch (err) {
    console.log("failed to fetch plan extra details", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanComment(plan, firebase) {
  try {
    let commentMap = new Map();
    let commentTask = [];
    for (let index in plan) {
      if (plan[index].shared) {
        let lessonArr = plan[index].lessons;
        for (let i in lessonArr) {
          let lsnArr = lessonArr[i];
          for (let ind in lsnArr) {
            let lessonId = lsnArr[ind].id;
            let schoolAssignmentId = lessonId + moment(plan[index].date).format("DD[]MM[]YYYY");
            let task = call(lessonAssignmentApi.getCommentsCount, schoolAssignmentId, firebase);
            commentTask.push(task);
          }
        }
      }
    }

    let newVal = yield all([commentTask]);
    for (let i in newVal[0]) {
      let singleActivity = newVal[0][i];
      if (singleActivity && singleActivity.length > 0) {
        commentMap.set(singleActivity[0].sourceId, singleActivity.length);
      }
    }
    yield put({
      type: actions.GET_PLAN_COMMENT_SUCCESS,
      planComment: commentMap,
    });

    console.log("commentMap", commentMap);
  } catch (err) {
    console.log("failed to fetch plan comment", err);
    bugsnagClient.notify(err);
  }
}

function* fetchPlanSubmission(plan, firebase) {
  try {
    let submissionMap = new Map();
    let submissionTask = new Map();
    for (let index in plan) {
      if (plan[index].shared) {
        let lessonArr = plan[index].lessons;
        for (let i in lessonArr) {
          let lsnArr = lessonArr[i];
          for (let ind in lsnArr) {
            let lessonId = lsnArr[ind].id;
            let schoolAssignmentId = lessonId + moment(plan[index].date).format("DD[]MM[]YYYY");
            let task = call(lessonAssignmentApi.getLessonSubmission, schoolAssignmentId, firebase);
            submissionTask.set(schoolAssignmentId, task);
          }
        }
      }
    }

    for (let [key, value] of submissionTask) {
      let newLessonVal = yield all([value]);
      let submission = newLessonVal[0];
      if (submission && submission.length > 0) {
        submissionMap.set(key, submission.length);
      }
    }

    console.log("submissionMap", submissionMap);
    yield put({
      type: actions.GET_PLAN_SUBMISSION_SUCCESS,
      planSubmission: submissionMap,
    });
  } catch (err) {
    console.log("failed to fetch plan assignment", err);
    bugsnagClient.notify(err);
  }
}

// function* createLessonCopy({ lesson, firebase }) {
//   try {
//     let copiedLesson = JSON.parse(JSON.stringify(lesson));
//     var nodeId = yield call(LearningApi.createLessonsNodeId, firebase);
//     copiedLesson.id = nodeId;
//     copiedLesson.name = lesson.name + "_Copy";
//     yield call(LearningApi.updateShareLesson, copiedLesson, firebase);
//     yield put({
//       type: actions.COPY_LESSON_SUCCESS,
//     });
//   } catch (err) {
//     console.log("failed to copy lesson", err);
//     bugsnagClient.notify(err);
//     yield put({
//       type: actions.LEARNING_REQUEST_FAILED,
//     });
//   }
// }



function* createLessonCopy({ lesson, firebase }) {
  try {
    let reqBody = {
      id: lesson.id
    }
    let endpoint = "woodlandApi/learning/lesson/clone" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.COPY_LESSON_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to copy lesson", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    });
  }
}

function* updateStudentList({ students }) {
  yield put({
    type: actions.REPLACE_STUDENT_SUCCESS,
    students: students,
  });
}

function* fetchLessonExtraFields({ firebase }) {
  try {
    let data = yield call(LearningApi.getLessonAdditionalFields, firebase);
    if (data) {
      yield put({
        type: actions.GET_LESSON_EXTRA_FIELDS_SUCCESS,
        lessonAdditionalFields: data
      });
    }
  } catch (err) {
    console.log("failed to fetch lesson extra details", err);
    bugsnagClient.notify(err);
  }
}

function* getSubdevelopmentAreas({ firebase }) {
  const chan = yield call(LearningApi.getSubdevelopmentAreas, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_SUB_DEV_AREA_SUCCESS,
        subDevelopmentArea: data,
        subDevelopmentAreaChannel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch subdevelopmentareas", err);
    bugsnagClient.notify(err);
  }
}
function* getDevelopmentAreasByLabels({ labels, selectedDevelopmentAreaList, selectedSubDevelopmentAreaList, selectedMilestoneList, firebase }) {
  try {
    let reqBody = {
      labels: labels,
      selectedDevelopmentAreaList: selectedDevelopmentAreaList,
      selectedSubDevelopmentAreaList: selectedSubDevelopmentAreaList,
      selectedMilestoneList: selectedMilestoneList
    }
    let endpoint = "woodlandApi/learning/getLabeledDevelopmentArea" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      yield put({
        type: actions.GET_DEVELOPMENTAREAS_BY_LABELS_SUCCESSFUL,
        developmentAreas: response.body.response.developmentAreas,
        subDevelopmentAreas: response.body.response.subDevelopmentAreas,
        milestones: response.body.response.milestones

      })
    }
  } catch (err) {
    console.log("failed to fetch development areas", err);
    bugsnagClient.notify(err);
  }
}
function* saveForm({ requestObj, formType, firebase }) {
  try {

    if (requestObj.attachments) {
      let attachmentWithoutUrl = requestObj.attachments.filter((item) => {
        if (!item.path) {
          return item
        }
      })
      let attachmentWithUrl = requestObj.attachments.filter((item) => {
        if (item.path) {
          return item
        }
      })
      let storagePath = firebase.sbp + "/media/images/";
      if (attachmentWithoutUrl && attachmentWithoutUrl.length > 0) {
        let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, { file: requestObj.attachments.file, fileList: attachmentWithoutUrl }, firebase);
        if (urls) {
          requestObj.attachments = urls.concat(attachmentWithUrl)
        }
      }
    }

    let formName
    switch (formType) {
      case "Observations":
        formName = "observation"
        break;
      case "Reflections":
        formName = "reflection"
        break;
      case "Concerns":
        formName = "concern"
        break;
    }
    if (formName) {
      let endpoint = "woodlandApi/learning/" + formName + "/" + "?centerId=" + firebase.sbDbName;
      let response = yield call(callApi, firebase, "post", endpoint, requestObj);
      if (response.status == 200) {
        yield put({
          type: actions.SAVE_FORM_SUCCESSFUL
        })
      }
      else {
        yield put({
          type: actions.SAVE_FORM_FAILURE
        })
      }
    }


  } catch (err) {
    console.log("failed to add observation", err);
    bugsnagClient.notify(err);
  }
}

function* getAssessmentTemplates({ firebase }) {
  let chan = yield call(LearningApi.getAssessmentTemplates, firebase);
  try {
    while (true) {
      let datas = yield take(chan);
      let data = [...datas].reverse();
      yield put({
        type: actions.GET_ASSESSMENT_TEMPLATES_SUCCESS,
        assessmentTemplates: data,
        assessmentTemplatesChannel: chan,
      });
    }
  }
  catch (err) {
    console.log("failed to fetch assessment templates", err);
  }
}
function* fetchObservationData({ firebase, dateRange }) {
  try {
    let endpoint = "woodlandApi/learning/dashboard" + "?centerId=" + firebase.sbDbName;
    let classList = FilterAction.getClassList() ? FilterAction.getClassList().map((item, index) => (item.className)) : null;
    let reqBody = {
      classList: classList,
      startDate: dateRange && dateRange[0] ? dateRange[0].valueOf() : null,
      endDate: dateRange && dateRange[1] ? dateRange[1].valueOf() : null,
    }
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response && response.status == 200) {
      yield put({
        type: actions.GET_OBSERVATION_DATA_SUCCESS,
        observationData: response.body.response
      })
      yield fork(getObservationComments, response.body.response, firebase);
    } else {
      let errorMsg =
        response.body && response.body.response
          ? response.body.response
          : "Failed to fetch data";
      notification("error", formatMsg("error.fetchRequest"));
      yield put({
        type: actions.GET_OBSERVATION_DATA_FAIL
      });
    }
  } catch (err) {
    console.log("failed to fetch observation data", err);
    notification("error", formatMsg("error.fetchRequest"));
    yield put({
      type: actions.GET_OBSERVATION_DATA_FAIL
    });
    bugsnagClient.notify(err);
  }
}
function* fetchObservationDataByIds({ firebase, ids, field }) {
  try {
    let reqBody = {
      observations: field === "obs" ? ids : [],
      reflections: field === "ref" ? ids : [],
      concerns: field === "con" ? ids : [],
    }
    let endpoint = "woodlandApi/learning/dashboard" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response && response.status == 200) {
      yield put({
        type: actions.GET_OBSERVATION_DATA_BY_IDS_SUCCESS,
        observationData: response.body.response
      })
    } else {
      let errorMsg =
        response.body && response.body.response
          ? response.body.response
          : "Failed to fetch data";
      notification("error", errorMsg);
      yield put({
        type: actions.LEARNING_REQUEST_FAILED
      });
    }
  } catch (err) {
    console.log("failed to fetch observation data", err);
    bugsnagClient.notify(err);
  }
}

function* getSelectedDevelopmentAreas({ firebase, preset }) {
  try {
    let reqBody = preset;
    let endpoint = "woodlandApi/learning/getDevelopmentArea" + "?centerId=" + firebase.sbDbName;
    if (!preset.developmentAreaIds || preset.developmentAreaIds.length === 0) {
      yield put({
        type: actions.GET_SELECTED_DEVELOPMENT_AREAS_SUCCESS,
        selectedDevelopmentAreas: [],
        selectedSubDevelopmentAreas: [],
        selectedMilestones: [],
      })
      return;
    }
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      yield put({
        type: actions.GET_SELECTED_DEVELOPMENT_AREAS_SUCCESS,
        selectedDevelopmentAreas: response.body.response.developmentAreas,
        selectedSubDevelopmentAreas: response.body.response.subDevelopmentAreas,
        selectedMilestones: response.body.response.milestones,
      })
    }
  }
  catch (err) {
    console.log("failed to fetch selected development areas", err);
    bugsnagClient.notify(err);
  }
}

function* saveTemplate({ firebase, template }) {
  try {
    let reqBody = template;
    let endpoint = "woodlandApi/learning/assessmentTemplate" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);

    if (response.status == 200) {

      yield put({
        type: actions.SAVE_TEMPLATE_SUCCESS,
      })
    }
  }
  catch (err) {

    console.log("failed to save template", err);
    bugsnagClient.notify(err);
  }
}

function* deleteAssessmentTemplate({ firebase, id }) {
  try {
    let endpoint = `woodlandApi/learning/assessmentTemplate/${id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_ASSESSMENT_TEMPLATE_SUCCESS,
      })
    }
  }
  catch (err) {
    console.log("failed to delete template", err);
    bugsnagClient.notify(err);
  }
}

function* getAssessmentTemplateData({ id, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/assessmentTemplate/${id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_ASSESSMENT_TEMPLATE_DATA_SUCCESS,
        templateData: response.body.response,
      })
    }
  }
  catch (err) {
    console.log("failed to Fetch template", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentObservationAttachments({ studentId, developmentAreaId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentObservationAttachments/${studentId}/${developmentAreaId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_OBSERVATION_ATTACHEMENTS_SUCCESSFUL,
        observationAttachments: response.body.response,
      })
    }
  } catch (err) {
    console.log("failed to Fetch student observation attachments", err);
    bugsnagClient.notify(err);
  }
}

function* saveAssessmentForm({ requestObj, firebase }) {
  try {

    if (requestObj.attachments) {
      let attachmentWithoutUrl = requestObj.attachments.filter((item) => {
        if (!item.path) {
          return item
        }
      })
      let attachmentWithUrl = requestObj.attachments.filter((item) => {
        if (item.path) {
          return item
        }
      })
      let storagePath = firebase.sbp + "/media/images/";
      if (attachmentWithoutUrl && attachmentWithoutUrl.length > 0) {
        let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, { file: requestObj.attachments.file, fileList: attachmentWithoutUrl }, firebase);
        if (urls) {
          requestObj.attachments = urls.concat(attachmentWithUrl)
        }
      }
    }
    if (requestObj.developmentAreasList) {
      yield requestObj.developmentAreasList.map(async (dev) => {
        if (dev.attachments) {
          let attachmentWithoutUrl = dev.attachments.filter((item) => {
            if (!item.path && !item.downloadUrl) {
              return item
            }
          })
          let attachmentWithUrl = dev.attachments.filter((item) => {
            if (item.path || item.downloadUrl) {
              if (item.mediaType) {
                item.type = item.mediaType
                item.mediaType = undefined
              }
              if (item.downloadUrl) {
                item.path = item.downloadUrl
                item.downloadUrl = undefined
              }
              if (!item.name && item.fileName && item.id) {
                item.name = item.fileName + item.id
              }
              if (item.inverseDate) {
                item.inverseDate = undefined
              }
              if (item.date) {
                item.date = undefined
              }
              if (item.activityId) {
                item.activityId = undefined
              }
              if (item.id) {
                item.id = undefined
              }
              return item
            }
          })
          let storagePath = firebase.sbp + "/media/images/";
          if (attachmentWithoutUrl && attachmentWithoutUrl.length > 0) {
            let urls = await ActivityApi.getAttachmentMediaPath(storagePath, { file: "", fileList: attachmentWithoutUrl }, firebase);
            if (urls) {
              dev.attachments = urls.concat(attachmentWithUrl)
            }
          }
        }
      })
    }
    let endpoint = "woodlandApi/learning/studentAssessment/" + "?centerId=" + firebase.sbDbName;

    let response = yield call(callApi, firebase, "post", endpoint, requestObj);
    if (response.status == 200) {
      yield put({
        type: actions.SAVE_ASSESSMENT_SUCCESSFUL,
        assessmentId: response.body.response.id
      })
      if (requestObj.id) {
        notification("success", formatMsg("assessment.updated"))
      } else {
        notification("success", formatMsg("assessment.add"))

      }
    } else {
      yield put({
        type: actions.SAVE_ASSESSMENT_FORM_FAILURE,
      })
      notification("error", formatMsg("saveAssessmentFail"))
    }

  } catch (err) {
    console.log("failed to save student assessment", err);
    yield put({
      type: actions.SAVE_ASSESSMENT_FORM_FAILURE,
    })
    notification("error", formatMsg("saveAssessmentFail"));
  }
}
function* getStudentAssessmentData({ firebase }) {
  let endpoint = "woodlandApi/learning/studentAssessment" + "?centerId=" + firebase.sbDbName;
  let chan = yield call(LearningApi.getStudentAssessmentData, firebase);
  try {
    while (true) {
      let datas = yield take(chan);
      let data = [...datas].reverse();
      yield put({
        type: actions.GET_STUDENT_ASSESSMENT_DATA_SUCCESS,
        studentsAssessmentsData: data,
        studentsAssessmentsDataChannel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch student assessmentData", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentObservation({ firebase, startDate, endDate }) {
  try {
    let reqBody = (startDate && endDate) ? {
      "startDate": startDate,
      "endDate": endDate,
    } : {}

    let endpoint = "woodlandApi/learning/studentObservationTracker" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.body.code == 200 && response.body.response) {
      let filteredData = response.body.response.filter((record) => {
        let stdObj = FilterAction.getStudentObj(record.studentId, firebase);
        if (stdObj && stdObj.id) {
          return true;
        }
        else {
          return false;
        }
      })
      yield put({
        type: actions.GET_STUDENT_OBSERVATION_SUCCESS,
        studentObservation: filteredData,
        // developmentAreaData : 
      })
    }
  } catch (err) {
    yield put({
      type: actions.GET_STUDENT_OBSERVATION_FAILURE,
    })
    console.log("failed to fetch student observation", err);
    bugsnagClient.notify(err);
  }
}


function* populatingTerminologyTerm({ firebase }) {
  const chan = yield call(LearningApi.getTerminologyTerms, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.POPULATE_TERMINOLOGY_TERMS_SUCCESS,
        terminologyTerms: data,
        terminologyTermsChannel: chan,
      });
    }
  } catch (err) {
    yield put({
      type: actions.POPULATE_TERMINOLOGY_TERMS_FAILURE,
    })
    console.log("failed to fetch terminology terms", err);
    bugsnagClient.notify(err);
  }
}

function* editTerminologyTerms({ key, newTerm, firebase }) {
  try {
    let reqBody = {
      defaultKey: key,
      customKey: newTerm,
    }
    let endpoint = "woodlandApi/learning/assessmentTerminology" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      let updatedTerm = {
        key: key.replace(/\s/g, ''),
        customKey: newTerm,
      }
      yield put({
        type: actions.EDIT_TERMINOLOGY_TERM_SUCCESS,
        terminologyData: updatedTerm,
      })
    }
  } catch (err) {
    yield put({
      type: actions.EDIT_TERMINOLOGY_TERM_FAILURE,
    })
    console.log("failed to edit", err);
    bugsnagClient.notify(err);
  }
}

function* populatingProgressName({ firebase }) {
  const chan = yield call(LearningApi.getProgressName, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.POPULATE_PROGRESS_NAME_SUCCESS,
        progressName: data,
        progressNameChannel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch terminology terms", err);
    bugsnagClient.notify(err);
  }
}

function* addingProgressName({ val, firebase }) {
  try {
    let reqBody = {
      title: val,
      deleted: false,
    }
    let endpoint = "woodlandApi/learning/assessmentProgressStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.ADD_PROGRESS_NAME_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.ADD_PROGRESS_NAME_FAILURE,
    })
    console.log("failed to add progress name", err);
    bugsnagClient.notify(err);
  }
}

function* deletingProgressName({ id, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/assessmentProgressStatus/${id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_PROGRESS_NAME_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.DELETE_PROGRESS_NAME_FAILURE,
    })
    console.log("failed to delete progress name", err);
    bugsnagClient.notify(err);
  }
}

function* populatingProgressStatus({ firebase }) {
  const chan = yield call(LearningApi.getProgressStatus, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      const newData = data.filter((item) => item.deleted === false);
      yield put({
        type: actions.POPULATE_PROGRESS_STATUS_SUCCESS,
        progressName: newData,
        progressNameChannel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch progress status", err);
    bugsnagClient.notify(err);
  }
}

function* addingProgressStatus({ val, isDefault, firebase }) {
  try {
    let reqBody = {
      title: val,
      default: isDefault,
      deleted: false,
    }
    let endpoint = "woodlandApi/learning/developmentAreaProgressStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.ADD_PROGRESS_STATUS_VALUE_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.ADD_PROGRESS_STATUS_VALUE_FAILURE,
    })
    console.log("failed to add progress name", err);
    bugsnagClient.notify(err);
  }
}

function* deletingProgressStatusValue({ id, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/developmentAreaProgressStatus/${id}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_PROGRESS_STATUS_VALUE_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.DELETE_PROGRESS_STATUS_VALUE_FAILURE,
    })
    console.log("failed to delete progress name", err);
    bugsnagClient.notify(err);
  }
}

function* setDefaultStatusValue({ prevId, newId, firebase }) {
  try {
    let reqBody = {
      previousDefaultProgressId: prevId,
      newDefaultProgressId: newId
    }
    let endpoint = "woodlandApi/learning/updateDefaultDevelopmentAreaProgressStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.SET_DEFAULT_STATUS_VALUE_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.SET_DEFAULT_STATUS_VALUE_FAILURE,
    })
    console.log("failed to set default key", err);
    bugsnagClient.notify(err);
  }
}

function* updatingProgressNameColorCode({ item, color, firebase }) {
  try {
    let reqBody = {
      id: item.id,
      title: item.title,
      colorCode: color,
      default: item.default,
      deleted: item.deleted
    }
    let endpoint = "woodlandApi/learning/assessmentProgressStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      yield put({
        type: actions.UPDATE_COLOR_CODE_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.UPDATE_COLOR_CODE_FAILURE,
    })
    console.log("failed to update color", err);
    bugsnagClient.notify(err);
  }
}

function* updatingProgressStatusColorCode({ item, color, firebase }) {
  try {
    let reqBody = {
      id: item.id,
      title: item.title,
      colorCode: color,
      default: item.default,
      deleted: item.deleted,
    }
    let endpoint = "woodlandApi/learning/developmentAreaProgressStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      yield put({
        type: actions.UPDATE_PROGRESS_STATUS_COLOR_CODE_SUCCESS,
      })
    }
  } catch (err) {
    yield put({
      type: actions.UPDATE_PROGRESS_STATUS_COLOR_CODE_FAILURE,
    })
    console.log("failed to update color", err);
    bugsnagClient.notify(err);
  }
}

function* getDevelopmentAreasNamebyId({ firebase }) {
  const chan = yield call(LearningApi.getDevelopmentAreaNames, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID_SUCCESS,
        data: data,
        channel: chan,
      });
    }
  } catch (err) {
    yield put({
      type: actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID_FAILURE,
    });
    console.log("failed to fetch development areas", err);
    bugsnagClient.notify(err);
  }
}

function* getDevelopmentAreaProgressStatus({ firebase }) {
  const chan = yield call(LearningApi.getDevelopmentAreaProgressStatus, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_DEVELOPMENT_AREA_PROGRESS_STATUS_SUCCESS,
        data: data,
        channel: chan,
      });
    }
  } catch (err) {
    console.log("failed to fetch progress status", err);
    bugsnagClient.notify(err);
  }
}

function* postNoteInStatus({ secondCallObj, firebase }) {
  try {
    let reqBody = {
      statusId: secondCallObj.statusId,
      date: secondCallObj.timestamp,
      developmentAreaId: secondCallObj.timelineData.developmentAreaId,
      studentId: secondCallObj.timelineData.studentId,
      note: secondCallObj.note ? secondCallObj.note : null,
    }

    let endpoint = "woodlandApi/learning/studentObservationTracker/changeStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {
      yield fork(getStudentObservation, { firebase });
      notification("success", "Status changed successfully");
    }

  } catch (err) {
    yield put({
      type: actions.ADD_NOTE_PROGRESS_STATUS_FAILURE,
    })
    console.log("failed to update status", err);
    bugsnagClient.notify(err);
  }
}

function* postNoteProgressStatus({ note, statusId, timestamp, timelineData, firebase, isNoteEdit, timelineDataNode, isTimelineDataNull, secondCallObj }) {
  try {
    let reqBody;
    if (isNoteEdit) {
      reqBody = {
        id: timelineDataNode.id,
        statusId: statusId,
        date: timestamp,
        developmentAreaId: timelineData.developmentAreaId,
        studentId: timelineData.studentId,
        note: note ? note : null,
      }
    } else {
      reqBody = {
        statusId: statusId,
        date: timestamp,
        developmentAreaId: timelineData.developmentAreaId,
        studentId: timelineData.studentId,
        note: note ? note : null,
      }
    }

    let endpoint = "woodlandApi/learning/studentObservationTracker/changeStatus" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200 && response.body.response) {

      if (isTimelineDataNull) {
        yield fork(postNoteInStatus, { secondCallObj, firebase });
      }
      else {
        yield fork(getStudentObservation, { firebase });
      }
      isNoteEdit ? notification("success", "Note edited successfully") : notification("success", "Status changed successfully");
    }
  } catch (err) {
    yield put({
      type: actions.ADD_NOTE_PROGRESS_STATUS_FAILURE,
    })
    console.log("failed to update status", err);
    bugsnagClient.notify(err);
  }
}
function* getStudentAssessmentById({ firebase, id }) {
  try {
    let endpoint = `woodlandApi/learning/studentAssessment/${id}` + "?centerId=" + firebase.sbDbName;
    const response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      let responseObj = response.body.response
      // if (responseObj && responseObj.assessmentId) {
      //   let templeteEndpoint = `woodlandApi/learning/assessmentTemplate/${responseObj.assessmentId}` + "?centerId=" + firebase.sbDbName;
      //   let templeteResponse = yield call(callApi, firebase, "get", templeteEndpoint, {})
      // }
      if (response.body.response && Object.keys(response.body.response).length > 0) {
        yield put({
          type: actions.GET_STUDENT_ASSESSMENT_BY_ID_SUCCESSFUL,
          studentAssessmentById: response.body.response
        })
      }

    }

  } catch (err) {
    yield put({
      type: actions.GET_STUDENT_ASSESSMENT_BY_ID_FAILURE,
    });
    console.log("failed to fetch student assessment", err);
    bugsnagClient.notify(err);
  }
}

function* deleteStudentAssessment({ firebase, id }) {
  try {
    let endpoint = `woodlandApi/learning/studentAssessment/${id}` + "?centerId=" + firebase.sbDbName;
    const response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_STUDENT_ASSESSMENT_SUCCESS,
      })
      notification("success", "Assessment deleted successfully")
    }
  } catch (err) {
    yield put({
      type: actions.DELETE_STUDENT_ASSESSMENT_FAILURE,
    });
    console.log("failed to delete student assessment", err);
    bugsnagClient.notify(err);
  }
}
function* getProgressData({ firebase }) {
  const chan = yield call(LearningApi.getProgressData, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_PROGRESS_DATA_SUCCESS,
        progressData: data,
        progressDataChannel: chan,
      });
    }
  }
  catch (err) {
    console.log("failed to get progress data", err);
    bugsnagClient.notify(err);
  }
}

function* viewObservations({ developmentAreaId, studentId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentObservation/${studentId}/${developmentAreaId}` + "?centerId=" + firebase.sbDbName;
    const response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      console.log("view observations", response.body.response)
      yield put({
        type: actions.VIEW_OBSERVATIONS_SUCCESS,
        viewObservations: response.body.response
      })
    }

  } catch (err) {
    console.log("failed to fetch student observations", err);
    bugsnagClient.notify(err);
  }
}

function* deleteSelectedObservation({ item, firebase, dateRange }) {
  try {
    let endpoint = "woodlandApi/learning/" + item.type + "/" + item.id + "?centerId=" + firebase.sbDbName;
    const response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_STUDENT_ASSESSMENT_SUCCESS,
        deleteOperationType: item.type.toLowerCase(),
      })
      switch (item.type.toLowerCase()) {
        case "observation":
          notification("success", formatMsg("observation.delete"));
          break;
        case "reflection":
          notification("success", formatMsg("reflection.delete"));
          break;
        case "concern":
          notification("success", formatMsg("concern.delete"));
          break;
      }

      yield fork(fetchObservationData, { firebase, dateRange });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : "Failed to delete observation"
      );
      yield put({
        type: actions.DELETE_OBSERVATION_SUCCESS
      })
    }

  } catch (err) {
    console.log("failed to delete observation", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DELETE_OBSERVATION_SUCCESS
    })
  }
}

function* fetchFormDataById({ id, formType, firebase }) {
  try {
    let formName;
    switch (formType) {
      case "Observations":
        formName = "observation"
        break;
      case "Reflections":
        formName = "reflection"
        break;
      case "Concerns":
        formName = "concern"
        break;
    }

    if (formName) {
      let endpoint = "woodlandApi/learning/" + formName + "/" + id + "?centerId=" + firebase.sbDbName;
      let response = yield call(callApi, firebase, "get", endpoint, {});
      if (response.status == 200) {
        yield put({
          type: actions.GET_FORM_DATA_BY_ID_SUCCESS,
          selectedFormData: response.body.response
        })
      } else {
        notification(
          "error",
          response.body && response.body.response
            ? response.body.response
            : "Failed to fetch form data"
        );
        yield put({
          type: actions.LEARNING_REQUEST_FAILED,
        })
      }
    }
  } catch (err) {
    console.log("failed to fetch form by id", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEARNING_REQUEST_FAILED,
    })
  }
}

function* getObservationComments(observations, firebase) {
  try {
    let activities = observations;
    let commentTaskList = [];
    var commentsCount = new Map();
    for (const item in activities) {
      let task = call(lessonAssignmentApi.getCommentsCount, activities[item].id, firebase);
      commentTaskList.push(task);
      commentsCount.set(activities[item].id, []);
    }

    let newVal = yield all([commentTaskList]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      for (let j in val) {
        if (commentsCount.has(val[j].sourceId)) {
          commentsCount.set(val[j].sourceId, val);
          break;
        }
      }
    }
    yield put({
      type: actions.GET_OBSERVATION_COMMENT_SUCCESS,
      observationComment: commentsCount,
    });
  } catch (err) {
    console.log("failed to get observation comments", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentProgressData({ studentId, firebase }) {
  try {
    let endpoint = "woodlandApi/learning/studentAssessmentsProgress/" + studentId + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response && response.status == 200) {

      let data = response.body.response;
      yield put({
        type: actions.GET_STUDENT_PROGRESS_TRACK_SUCCESS,
        studentProgressTracker: data
      })
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : "Failed to fetch student progress tracker"
      );
      yield put({
        type: actions.LEARNING_REQUEST_FAILED,
      })
    }
  } catch (err) {
    console.log("failed to fetch student progress data", err);
    bugsnagClient.notify(err);
    yield put({ type: actions.LEARNING_REQUEST_FAILED });
  }
}


function* fetchStudentObservationData({ studentId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentObservation/${studentId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_OBSERVATION_DATA_SUCCESS,
        studentObservationData: response.body.response,
      })
    }
  }
  catch (err) {
    console.log("failed to Fetch template", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentReflectionData({ studentId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentReflection/${studentId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_REFLECTION_DATA_SUCCESS,
        studentReflectionData: response.body.response,
      })
    }
  }
  catch (err) {
    console.log("failed to Fetch template", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentConcernData({ studentId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentConcern/${studentId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_CONCERN_DATA_SUCCESS,
        studentConcernData: response.body.response,
      })
    }
  }
  catch (err) {
    console.log("failed to Fetch template", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentObservationByDevarea({ studentId, devId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentObservation/${studentId}/${devId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_OBSERVATION_BY_DEVAREA_SUCCESS,
        studentObservationBydevarea: response.body.response
      })
    }
    else {
      yield put({
        type: actions.GET_STUDENT_OBSERVATION_BY_DEVAREA_FAILURE
      })
    }

  } catch (err) {
    console.log("failed to Fetch student observation by development area", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_STUDENT_OBSERVATION_BY_DEVAREA_FAILURE
    })
  }
}

function* savePdfTemplate({ pdfTemplateObj, firebase }) {
  try {

    let endpoint = "woodlandApi/learning/pdfTemplateSettings" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, pdfTemplateObj);
    if (response.status == 200) {
      yield put({
        type: actions.SAVE_PDF_TEMPLATE_SUCCESS
      })
      if (pdfTemplateObj.assessmentTemplateId) {
        yield fork(getPDfTemplate, { assessmentTemplateId: pdfTemplateObj.assessmentTemplateId, firebase: firebase })
      } else {
        yield fork(getPDfTemplate, { assessmentTemplateId: "school", firebase: firebase })
      }

      notification("success", formatMsg("saveAssessmentPdfSuccess"))
    } else {
      notification("error", formatMsg("saveAssessmentPdfFail"))
    }
  } catch (err) {
    console.log("failed to save pdf template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SAVE_PDF_TEMPLATE_FAILURE
    })
    notification("error", formatMsg("saveAssessmentPdfFail"))
  }
}

function* getPDfTemplate({ assessmentTemplateId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/pdfTemplateSettings/${assessmentTemplateId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_PDF_TEMPLATE_SUCCESS,
        pdfStyle: response.body.response
      })
    }
    else {
      yield put({
        type: actions.GET_PDF_TEMPLATE_FAILURE
      })
    }
  }
  catch (err) {
    console.log("failed to fetch pdf template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_PDF_TEMPLATE_FAILURE
    })
    notification("error", formatMsg("getAssessmentPdfFail"))
  }
}
function* migrateDevelopmentAreaData({ firebase, developmentAreaData, removePreviousDevAreas }) {
  try {
    let endpoint = `woodlandApi/learning/migrate` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, {
      removePreviousData: removePreviousDevAreas,
      data: developmentAreaData
    });
    if (response.status == 200) {
      yield put({
        type: actions.MIGRATE_DEVELOPMENT_AREA_DATA_SUCCESS,
      });
    }
    else {
      yield put({
        type: actions.MIGRATE_DEVELOPMENT_AREA_DATA_FAILURE,
      });
    }

  } catch (err) {
    console.log("failed to migrate development area data", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.MIGRATE_DEVELOPMENT_AREA_DATA_FAILURE,
    });
  }
}

function* getSuggestions({ suggestionData, firebase }) {
  try {
    const reqBody = {
      formType: suggestionData.formType ? suggestionData.formType : "Normal",
      formTitle: suggestionData.formTitle,
      developmentAreasList: [],
      staffName: suggestionData.staffName ? suggestionData.staffName : "Teacher",
      staffNote: suggestionData.inputLabel ? suggestionData.inputLabel : null,
      languageType: suggestionData.languageType ? suggestionData.languageType : "Formal",
      toneType: suggestionData.toneType ? suggestionData.toneType : "Happy",
      language: suggestionData.language ? suggestionData.language : "English",
      noteType: suggestionData.noteType ? suggestionData.noteType : "Staff Note",
      developmentAreasList: suggestionData.developmentAreasList ? suggestionData.developmentAreasList : [],
    }

    let endpoint = `woodlandApi/learning/noteSuggestion` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    let sample = ["note 1", "note 2", "note 3"];
    //  let response = {
    //   status : 200,
    //   body : {
    //     response : sample,
    //   }
    // }
    if (response.status == 200) {
      yield put({
        type: actions.GET_SUGGESTIONS_SUCCESS,
        staffNoteSuggestions: response.body.response,
      })
    }
    else {
      notification("error", "Please try again");
      yield put({
        type: actions.GET_SUGGESTIONS_SUCCESS,
        staffNoteSuggestions: [],
      })
    }

  } catch (err) {

    console.log("failed to Fetch suggestions", err);
    bugsnagClient.notify(err);
    notification("error", "Please Try again");
    yield put({
      type: actions.GPT_REQUEST_FAILED
    });
  }
}


function* sharePdf({ pdfObj, assessmentId, emailTemplate, studentId, firebase }) {
  try {
    let storagePath = firebase.sbp + "/media/images/";
    let url = yield call(ActivityApi.getAttachmentMediaPath, storagePath, { fileList: [pdfObj] }, firebase);
    let reqObj = {
      pdfUrl: url[0].path,
      studentId: studentId,
      emailTemplate: emailTemplate,
      assessmentId: assessmentId
    }
    let endpoint = "woodlandApi/learning/sharePdf" + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", "PDF Shared successfully")
    }

  } catch (err) {
    console.log("failed to share pdf", err);
    bugsnagClient.notify(err);

    console.log("failed to Fetch suggesstions", err);
    notification("error", "Please try again");
    bugsnagClient.notify(err);
    yield put({
      type: actions.GPT_REQUEST_FAILED
    })
  }

}

function* getParaphraseText({ text, firebase }) {

  try {
    const reqBody = {
      text: text,
    }

    let endpoint = `woodlandApi/gpt/paraphrase` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    const currentDate = new Date();
    const randomString = 'random_' + currentDate.getTime().toString(36);
    // let response = {
    //   status : 200,
    //   body : {
    //     response : randomString,
    //   }
    // }
    if (response.status == 200) {
      const resultString = response.body.response.replace(/^\s+/, '');
      yield put({
        type: actions.GET_PARAPHRASE_TEXT_SUCCESS,
        text: resultString.trim(),
      })
    }

  } catch (err) {

    console.log("failed to Fetch paraphrase text", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GPT_REQUEST_FAILED
    });
  }
}

function* getStudentAttendanceForAssessment({ startDate, endDate, studentId, firebase }) {
  try {
    const reqBody = {
      startDate: startDate,
      endDate, endDate,
      entityId: [studentId],
    }
    let endpoint = `reportsApi/report/totalAttendance` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, reqBody);
    if (response.status == 200) {
      yield put({
        type: actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_SUCCESS,
        studentAttendanceData: response.body.response.data
      })
    } else {
      yield put({
        type: actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_FAILURE,
      })
      notification("error", formatMsg("failStudentAssessmentAttendance"));
    }

  } catch (err) {

    console.log("failed to Fetch student attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT_FAILURE
    });
  }
}

function* getDevareaforStudentAssessment({ assessmentTemplateId, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/studentAssessment/developmentAreaData/${assessmentTemplateId}` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_SUCCESS,
        devareaForStudentAssessment: response.body.response,
      })
    }
  } catch (err) {
    console.log("failed to Fetch development area for student assessment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT_FAIL
    });
  }
}

function* getProgressReportData({ classList, assessmentIds, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/report/assessmentProgress` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, { classList: classList, templateIds: assessmentIds });
    if (response.status == 200) {
      yield put({
        type: actions.GET_PROGRESS_REPORT_DATA_SUCCESS,
        progressReportData: response.body.response,
      })
    } else {
      yield put({
        type: actions.GET_PROGRESS_REPORT_DATA_FAIL,
      })
      notification("error", formatMsg("error.fetchRequest"))
    }

  } catch (err) {
    console.log("failed to Fetch progress report data", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_PROGRESS_REPORT_DATA_FAIL
    });
    notification("error", formatMsg("error.fetchRequest"))
  }
}

function* getAssessmentReportData({ classList, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/report/assessmentReport` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, { classList: classList });
    if (response.status == 200) {
      yield put({
        type: actions.GET_ASSESSMENT_REPORT_DATA_SUCCESS,
        assessmentReportData: response.body.response,
      })
    } else {
      yield put({
        type: actions.GET_ASSESSMENT_REPORT_DATA_FAIL,
      })
      notification("error", formatMsg("error.fetchRequest"))
    }

  } catch (err) {
    console.log("failed to Fetch progress report data", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_ASSESSMENT_REPORT_DATA_FAIL
    });
    notification("error", formatMsg("error.fetchRequest"))
  }
}

function* getComparisonReport({ requestBody, firebase }) {
  try {
    let endpoint = `woodlandApi/learning/report/assessmentComparator` + "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpoint, { comparatorData: requestBody });
    if (response.status == 200) {
      yield put({
        type: actions.GET_COMPARISON_REPORT_SUCCESS,
        comparsionReport: response.body.response,
      })
    } else {
      yield put({
        type: actions.GET_COMPARISON_REPORT_FAIL,
      })
    }
  } catch (err) {
    console.log("failed to Fetch comparsion report", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.GET_COMPARISON_REPORT_FAIL
    });
    notification("error", formatMsg("error.fetchRequest"))
  }
}
function* editProgressValue({ obj, value, firebase, valueType }) {
  try {
    if (valueType === "progress") {
      let reqBody = {
        id: obj.id,
        title: value,
        deleted: false,
        colorCode: obj.colorCode,
      }
      let endpoint = "woodlandApi/learning/assessmentProgressStatus" + "?centerId=" + firebase.sbDbName;
      let response = yield call(callApi, firebase, "post", endpoint, reqBody);
      if (response.status == 200) {
        yield put({
          type: actions.EDIT_PROGRESS_VALUE_SUCCESS
        });
      }
    }
    else {
      let reqBody = {
        id: obj.id,
        title: value,
        deleted: false,
        colorCode: obj.colorCode,
      }
      if (obj.default) {
        reqBody.default = obj.default;
      }
      let endpoint = "woodlandApi/learning/developmentAreaProgressStatus" + "?centerId=" + firebase.sbDbName;
      let response = yield call(callApi, firebase, "post", endpoint, reqBody);
      if (response.status == 200) {
        yield put({
          type: actions.EDIT_PROGRESS_VALUE_SUCCESS
        });
      }
    }

  }
  catch (err) {
    console.log("Failed to edit progress value");
    bugsnagClient.notify(err);
    notification("error", "Failed to perform action");
    yield put({
      type: actions.EDIT_PROGRESS_VALUE_FAILURE
    });

  }
}


export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_MILESTONES, fetchMilestones),
    yield takeLatest(actions.LIST_DEV_AREA, fetchDevelopmentArea),
    yield takeLatest(actions.LIST_LESSONS, fetchLessons),
    yield takeLatest(actions.SAVE_MILESTONE_DOMAIN, saveNewMilestoneDomain),
    yield takeLatest(actions.ADD_SUB_DOMAIN, saveNewMilestoneSubDomain),
    yield takeLatest(actions.UPDATE_SUB_DOMAIN, updateNewMilestoneSubDomain),
    yield takeLatest(actions.SAVE_MILESTONE, saveNewMilestone),
    yield takeLatest(actions.ADD_LABEL, addNewLabel),
    yield takeLatest(actions.GET_LABEL, fetchLabel),
    yield takeLatest(actions.UPDATE_MILESTONE_DOMAIN, updateExistingMilestoneDomain),
    yield takeLatest(actions.UPDATE_MILESTONE, updateExistingMilestone),
    yield takeLatest(actions.ADD_CATEGORY, addNewCategory),
    yield takeLatest(actions.GET_CATEGORY, fetchCategory),
    yield takeLatest(actions.GET_LEARNING_CLASSROOM, fetchLearningClassroom),
    yield takeLatest(actions.ADD_LESSON, addNewLesson),
    yield takeLatest(actions.ADD_LESSON_TO_ALL_CENTERS, addNewLessonToAllCenter),
    yield takeLatest(actions.UPDATE_LESSON, updateExistingLesson),
    yield takeLatest(actions.UPDATE_LESSON_TO_ALL_CENTERS, updateExistingLessonToAllCenter),
    yield takeLatest(actions.ADD_WEEKLY_PLAN, addNewWeeklyPlan),
    yield takeLatest(actions.GET_WEEKLY_PLAN, fetchWeeklyPlan),
    yield takeLatest(actions.DELETE_PLAN, deleteSelectedPlan),
    yield takeLatest(actions.DELETE_LESSON_RECORD, deleteSelectedLessonRecord),
    yield takeLatest(
      actions.DELETE_LESSON_RECORD_FOR_ALL_CENTER,
      deleteSelectedLessonRecordForAllCenter
    ),
    yield takeLatest(actions.DELETE_SINGLE_MILESTONE, deleteSelectedMilestone),
    yield takeLatest(actions.DELETE_SINGLE_DOMAIN, deleteSelectedDomain),
    yield takeLatest(actions.GET_STUDENT_FOR_LESSON, fetchStudentForLesson),
    yield takeLatest(actions.SHARE_LESSON, shareSelectedLesson),
    yield takeLatest(actions.UPLOAD_LESSON_DATA, uploadLessonCsv),
    yield takeLatest(actions.UPDATE_CATEGORY, updateExistingCategory),
    yield takeLatest(actions.DELETE_CATEGORY, deleteCategory),
    yield takeLatest(actions.GET_ASSESSMENT_PROGRESS_PARAM, fetchAssessmentProgressParam),
    yield takeLatest(actions.SAVE_ASS_PROGRESS_PARAM, addNewAssProgressParam),
    yield takeLatest(actions.DELETE_ASS_PROGRESS_PARAM, deleteSelectedAssProgressParam),
    yield takeLatest(actions.DELETE_LABEL, deleteSelectedMilestoneLabel),
    yield takeLatest(actions.FETCH_COMPLETE_SHARE_HISTORY, getLessonShareHistory),
    yield takeLatest(actions.UPDATE_SHARE_STATUS, updateLessonShareStatus),
    yield takeLatest(actions.GET_PLAN_EXTRA_DETAIL, fetchPlanExtraDetails),
    yield takeLatest(actions.COPY_LESSON, createLessonCopy),
    yield takeLatest(actions.REPLACE_STUDENT, updateStudentList),
    yield takeLatest(actions.LIST_SUB_DEV_AREA, getSubdevelopmentAreas),
    yield takeLatest(actions.GET_DEVELOPMENTAREAS_BY_LABELS, getDevelopmentAreasByLabels),
    yield takeLatest(actions.SAVE_FORM, saveForm),
    yield takeLatest(actions.GET_ASSESSMENT_TEMPLATES, getAssessmentTemplates),
    yield takeLatest(actions.GET_SELECTED_DEVELOPMENT_AREAS, getSelectedDevelopmentAreas),
    yield takeLatest(actions.SAVE_TEMPLATE, saveTemplate),
    yield takeLatest(actions.DELETE_ASSESSMENT_TEMPLATE, deleteAssessmentTemplate),
    yield takeLatest(actions.GET_ASSESSMENT_TEMPLATE_DATA, getAssessmentTemplateData),
    yield takeLatest(actions.GET_STUDENT_OBSERVATION_ATTACHEMENTS, getStudentObservationAttachments),
    yield takeLatest(actions.SAVE_ASSESSMENT_FORM, saveAssessmentForm),

    yield takeLatest(actions.GET_OBSERVATION_DATA, fetchObservationData),
    yield takeLatest(actions.GET_OBSERVATION_DATA_BY_IDS, fetchObservationDataByIds),
    yield takeLatest(actions.GET_STUDENT_ASSESSMENT_DATA, getStudentAssessmentData),
    yield takeLatest(actions.POPULATE_TERMINOLOGY_TERMS, populatingTerminologyTerm),
    yield takeLatest(actions.EDIT_TERMINOLOGY_TERM, editTerminologyTerms),
    yield takeLatest(actions.POPULATE_PROGRESS_NAME, populatingProgressName),
    yield takeLatest(actions.ADD_PROGRESS_NAME, addingProgressName),
    yield takeLatest(actions.ADD_PROGRESS_STATUS_VALUE, addingProgressStatus),
    yield takeLatest(actions.DELETE_PROGRESS_NAME, deletingProgressName),
    yield takeLatest(actions.POPULATE_PROGRESS_STATUS, populatingProgressStatus),
    yield takeLatest(actions.DELETE_PROGRESS_STATUS_VALUE, deletingProgressStatusValue),
    yield takeLatest(actions.SET_DEFAULT_STATUS_VALUE, setDefaultStatusValue),
    yield takeLatest(actions.UPDATE_PROGRESS_NAME_COLOR_CODE, updatingProgressNameColorCode),
    yield takeLatest(actions.UPDATE_PROGRESS_STATUS_COLOR_CODE, updatingProgressStatusColorCode),
    yield takeLatest(actions.GET_STUDENT_OBSERVATION, getStudentObservation),
    yield takeLatest(actions.GET_DEVELOPMENT_AREAS_NAMES_BY_ID, getDevelopmentAreasNamebyId),
    yield takeLatest(actions.GET_DEVELOPMENT_AREA_PROGRESS_STATUS, getDevelopmentAreaProgressStatus),
    yield takeLatest(actions.ADD_NOTE_PROGRESS_STATUS, postNoteProgressStatus),
    yield takeLatest(actions.GET_STUDENT_ASSESSMENT_BY_ID, getStudentAssessmentById),
    yield takeLatest(actions.DELETE_STUDENT_ASSESSMENT, deleteStudentAssessment),
    yield takeLatest(actions.GET_PROGRESS_DATA, getProgressData),
    yield takeLatest(actions.DELETE_OBSERVATION, deleteSelectedObservation),
    yield takeLatest(actions.GET_FORM_DATA_BY_ID, fetchFormDataById),

    yield takeLatest(actions.GET_STUDENT_OBSERVATION_DATA, fetchStudentObservationData),
    yield takeLatest(actions.GET_STUDENT_REFLECTION_DATA, fetchStudentReflectionData),
    yield takeLatest(actions.GET_STUDENT_CONCERN_DATA, fetchStudentConcernData),


    yield takeLatest(actions.VIEW_OBSERVATIONS, viewObservations),
    yield takeLatest(actions.GET_PROGRESS_DATA, getProgressData),
    // yield takeLatest(actions.GET_STATUS_NAME_BY_ID, getStatusNameById),
    yield takeLatest(actions.GET_STUDENT_PROGRESS_TRACK, fetchStudentProgressData),
    yield takeLatest(actions.GET_STUDENT_OBSERVATION_BY_DEVAREA, getStudentObservationByDevarea),
    yield takeLatest(actions.SAVE_PDF_TEMPLATE, savePdfTemplate),
    yield takeLatest(actions.GET_PDF_TEMPLATE, getPDfTemplate),
    yield takeLatest(actions.SHARE_PDF, sharePdf),
    yield takeLatest(actions.MIGRATE_DEVELOPMENT_AREA_DATA, migrateDevelopmentAreaData),
    yield takeLatest(actions.GET_LESSON_EXTRA_FIELDS, fetchLessonExtraFields),
    yield takeLatest(actions.GET_SUGGESTIONS, getSuggestions),
    yield takeLatest(actions.GET_PARAPHRASE_TEXT, getParaphraseText),
    yield takeLatest(actions.GET_STUDENT_ATTENDANCE_FOR_ASSESSMENT, getStudentAttendanceForAssessment),
    yield takeLatest(actions.GET_DEV_AREA_FOR_STUDENT_ASSESSMENT, getDevareaforStudentAssessment),
    yield takeLatest(actions.GET_PROGRESS_REPORT_DATA, getProgressReportData),
    yield takeLatest(actions.GET_ASSESSMENT_REPORT_DATA, getAssessmentReportData),
    yield takeLatest(actions.GET_COMPARISON_REPORT, getComparisonReport),
    yield takeLatest(actions.EDIT_PROGRESS_VALUE, editProgressValue),
  ]);
}