import actions from "./actions";
const initState = {
  feeComponent: [],
  feePlan: [],
  students: [],
  error: false,
  isLoading: false,
  feePlanChannel: undefined,
  feeComponentChannel: undefined,
  editableSelectedComponent: undefined,
  feeOperationType: "",
  addFeePlanSuccessful: false,
  updateFeePlanSuccessful: false,
  addFeeComponentSuccessful: false,
  updateFeeComponentSuccessful: false,
  programs: [],
  componentInvoices: [],
  feePlanDetail: {},
  feePlanId: undefined,
  selectedFeeComponent: [],
  feeComponentIDs: [],
};
export default function feeReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESET_REDIRECT:
      return {
        ...state,

        feePlanId: undefined,
      };
    case actions.RESET_EDIT:
      return {
        ...state,

        feePlanDetail: {},
      };

    case actions.LIST_FEE_COMPONENTS:
      return {
        ...state,
        isLoading: true,
        feeOperationType: "",
        error: false,
      };
    case actions.LIST_FEE_COMPONENTS_SUCCESSFFUL:
      return {
        ...state,
        feeComponent: action.feeComponent,
        isLoading: false,
        feeComponentChannel: action.feeComponentChannel,
        feeOperationType: "",
        error: false,
      };
    case actions.GET_COMPLETE_FEE_COMPONENTS:
      return {
        ...state,
        isLoading: true,
        feeOperationType: "",
      };
    case actions.GET_COMPLETE_FEE_COMPONENTS_SUCCESSFUL:
      return {
        ...state,
        feeComponent: action.feeComponent,
        isLoading: false,
      };
    case actions.LIST_FEE_PLANS:
      return {
        ...state,
        isLoading: true,
        feeOperationType: "",
        error: false,
      };
    case actions.LIST_FEE_PLANS_SUCCESSFFUL:
      return {
        ...state,
        feePlan: action.feePlan,
        isLoading: false,
        feePlanChannel: action.feePlanChannel,
        feeOperationType: "",
        error: false,
      };
    case actions.ADD_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
        addFeePlanSuccessful: false,
        feeOperationType: "",
        feePlanId: undefined,
      };
    case actions.ADD_FEE_PLAN_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        addFeePlanSuccessful: true,
        feeOperationType: "ADD_FEE_PLAN",
        //here
        feePlanId: action.feePlanId,
      };
    case actions.UPDATE_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
        updateFeePlanSuccessful: false,
        feeOperationType: "",
      };
    case actions.UPDATE_FEE_PLAN_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        updateFeePlanSuccessful: true,
        feeOperationType: "UPDATE_FEE_PLAN",
      };
    case actions.ADD_FEE_COMPONENT:
      return {
        ...state,
        isLoading: true,
        addFeeComponentSuccessful: false,
        feeOperationType: "",
      };
    case actions.ADD_FEE_COMPONENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        addFeeComponentSuccessful: true,
        feeOperationType: "ADD_FEE_COMPONENT",
      };
    case actions.UPDATE_FEE_COMPONENT:
      return {
        ...state,
        isLoading: true,
        updateFeeComponentSuccessful: false,
        feeOperationType: "",
      };
    case actions.UPDATE_FEE_COMPONENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        updateFeeComponentSuccessful: true,
        feeOperationType: "UPDATE_FEE_COMPONENT",
      };
    case actions.POP_EDIT:
      return {
        ...state,
        editableSelectedComponent: undefined,
        feeOperationType: "",
      };
    case actions.POP_EDIT_SUCCESSFFUL:
      return {
        ...state,
        editableSelectedComponent: action.editableSelectedComponent,
        feeOperationType: "",
        isLoading: false,
      };
    case actions.RESET_POP_EDIT:
      return {
        ...state,
      };
    case actions.RESET_POP_EDIT_SUCCESSFFUL:
      return {
        ...state,
        editableSelectedComponent: action.editableSelectedComponent,
        isLoading: false,
      };
    case actions.FEE_PLAN_FETCH_STUDENT:
      return {
        ...state,
        isLoading: true,
        feeOperationType: "",
      };
    case actions.FEE_PLAN_FETCH_STUDENT_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        students: action.students,
        feeOperationType: "",
      };
    case actions.ASSIGN_STUDENT_TO_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
        feeOperationType: "",
      };
    case actions.ASSIGN_STUDENT_TO_FEE_PLAN_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        feeOperationType: "ASSIGN_STUDENT",
      };
    case actions.FEE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case actions.GET_FEE_PROGRAMS:
      return {
        ...state,
      };
    case actions.GET_FEE_PROGRAMS_SUCCESSFUL:
      return {
        ...state,
        programs: action.programs,
      };

    case actions.DELETE_FEE_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_FEE_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feeOperationType: "DELETE_PLAN",
      };

    case actions.RESET_FEE_OPERATION:
      return {
        ...state,
        isLoading: false,
        feeOperationType: "",
        error: false,
      };

    case actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED:
      return {
        ...state,
        isLoading: true,
        componentInvoices: [],
      };
    case actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        componentInvoices: action.componentInvoices,
      };
    // here
    case actions.FETCH_FEE_PLAN_DETAILS:
      return {
        ...state,
        feePlanDetail: {},
        selectedFeeComponent: [],
      };
    case actions.FETCH_FEE_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        feePlanDetail: action.feePlanDetail,
        selectedFeeComponent: action.selectedFeeComponent,
      };
    case actions.SELECTED_FEE_COMPONENT:
      return {
        ...state,
        feeComponentIDs: [],
      };
    case actions.SELECTED_FEE_COMPONENT:
      return {
        ...state,
        feeComponentIDs: action.feeComponentIDs,
      };
    default:
      return state;
  }
}
