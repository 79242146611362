import antdLT from "antd/lib/locale-provider/en_US";
import appLocaleData from "react-intl/locale-data/lt";
import ltMessages from "../locales/lt_LT.json";

const ltLang = {
  messages: {
    ...ltMessages,
  },
  antd: antdLT,
  locale: "lt",
  data: appLocaleData,
};
export default ltLang;