import leadActivity from "../../containers/LeadsDetailedView/leadActivity";

const actions = {
  LIST_LEADS: "LIST_LEADS",
  LIST_LEADS_SUCCESSFULL: "LIST_LEADS_SUCCESSFULL",

  GET_FEE_PROGRAM_FOR_LEADS: "GET_FEE_PROGRAM_FOR_LEADS",
  GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL: "GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL",

  SAVE_NEW_LEAD: "SAVE_NEW_LEAD",
  SAVE_NEW_LEAD_SUCCESSFUL: "SAVE_NEW_LEAD_SUCCESSFUL",

  UPDATE_LEAD: "UPDATE_LEAD",
  UPDATE_LEAD_SUCCESSFUL: "UPDATE_LEAD_SUCCESSFUL",

  ADD_STATUS: "ADD_STATUS",
  ADD_STATUS_SUCCESSFUL: "ADD_STATUS_SUCCESSFUL",
  MANAGE_LEAD: "MANAGE_LEAD",
  MANAGE_LEAD_SUCCESS: "MANAGE_LEAD_SUCCESS",

  GET_STATUS: "GET_STATUS",
  GET_STATUS_SUCCESSFUL: "GET_STATUS_SUCCESSFUL",

  GET_LEAD_DETAIL: "GET_LEAD_DETAIL",
  GET_LEAD_DETAIL_SUCCESSFUL: "GET_LEAD_DETAIL_SUCCESSFUL",

  ADD_ACTIVITY: "ADD_ACTIVITY",
  ADD_ACTIVITY_SUCCESSFUL: "ADD_ACTIVITY_SUCCESSFUL",

  ADD_TASK: "ADD_TASK",
  ADD_TASK_SUCCESSFUL: "ADD_TASK_SUCCESSFUL",



  GET_LEAD_ACTIVITY: "GET_LEAD_ACTIVITY",
  GET_LEAD_ACTIVITY_SUCCESSFUL: "GET_LEAD_ACTIVITY_SUCCESSFUL",

  UPDATE_LEAD_STATUS_CHANGE: "UPDATE_LEAD_STATUS_CHANGE",
  UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL: "UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL",

  LEAD_REQUEST_FAILED: "LEAD_REQUEST_FAILED",

  RESET_LEADS_OPERATION_TYPE: "RESET_LEADS_OPERATION_TYPE",

  CREATE_NEW_ACCOUNT: "CREATE_NEW_ACCOUNT",
  CREATE_NEW_ACCOUNT_SUCCESS: "CREATE_NEW_ACCOUNT_SUCCESS",

  DELETE_LEAD: "DELETE_LEAD",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",

  DELETE_LEAD_STATUS: "DELETE_LEAD_STATUS",
  DELETE_LEAD_STATUS_SUCCESS: "DELETE_LEAD_STATUS_SUCCESS",

  DELETE_LEAD_CATEGORY: "DELETE_LEAD_CATEGORY",
  DELETE_LEAD_CATEGORY_SUCCESS: "DELETE_LEAD_CATEGORY_SUCCESS",

  GET_LEADS_ADDITIONAL_FIELD: "GET_LEADS_ADDITIONAL_FIELD",
  GET_LEADS_ADDITIONAL_FIELD_SUCCESS: "GET_LEADS_ADDITIONAL_FIELD_SUCCESS",

  DOWNLOAD_LEADS_EXCEL: "DOWNLOAD_LEADS_EXCEL",
  DOWNLOAD_LEADS_EXCEL_SUCCESS: "DOWNLOAD_LEADS_EXCEL_SUCCESS",

  DELETE_LEAD_ACTIVITY: "DELETE_LEAD_ACTIVITY",
  DELETE_LEAD_ACTIVITY_SUCCESS: "DELETE_LEAD_ACTIVITY_SUCCESS",

  DELETE_LEAD_TASK: "DELETE_LEAD_TASK",
  DELETE_LEAD_TASK_SUCCESS: "DELETE_LEAD_TASK_SUCCESS",



  LEAD_STUDENT_ADDED: "LEAD_STUDENT_ADDED",

  LEAD_ADD_PARENT_ONE: "LEAD_ADD_PARENT_ONE",
  LEAD_PARENT_ONE_ADDED: "LEAD_PARENT_ONE_ADDED",
  LEAD_PARENT_ONE_FAILED: "LEAD_PARENT_ONE_FAILED",

  LEAD_ADD_PARENT_TWO: "LEAD_ADD_PARENT_TWO",
  LEAD_PARENT_TWO_ADDED: "LEAD_PARENT_TWO_ADDED",
  LEAD_PARENT_TWO_FAILED: "LEAD_PARENT_TWO_FAILED",

  STUDENT_ADD_REQUEST_FAILED: "STUDENT_ADD_REQUEST_FAILED",
  STUDENT_LINK_FOUND: "STUDENT_LINK_FOUND",

  RESET_LEAD_EXISTING_DETAIL: "RESET_LEAD_EXISTING_DETAIL",

  LEAD_LINK_PARENT: "LEAD_LINK_PARENT",
  LEAD_LINK_PARENT_SUCCESS: "LEAD_LINK_PARENT_SUCCESS",

  GET_LEAD_DOC: "GET_LEAD_DOC",
  GET_LEAD_DOC_SUCCESS: "GET_LEAD_DOC_SUCCESS",

  UPLOAD_LEAD_DOC: "UPLOAD_LEAD_DOC",
  UPLOAD_LEAD_DOC_SUCCESS: "UPLOAD_LEAD_DOC_SUCCESS",

  ADD_LEAD_DOC_FOLDER: "ADD_LEAD_DOC_FOLDER",
  ADD_LEAD_DOC_FOLDER_SUCCESS: "ADD_LEAD_DOC_FOLDER_SUCCESS",

  RESET_OPERATION_REQ: "RESET_OPERATION_REQ",

  DELETE_LEAD_DOC: "DELETE_LEAD_DOC",
  DELETE_LEAD_DOC_SUCCESS: "DELETE_LEAD_DOC_SUCCESS",

  RENAME_LEAD_DOC_FOLDER: "RENAME_LEAD_DOC_FOLDER",
  RENAME_LEAD_DOC_FOLDER_SUCCESS: "RENAME_LEAD_DOC_FOLDER_SUCCESS",

  FETCH_ALLLEAD_TASK: "FETCH_ALLLEAD_TASK",
  FETCH_ALLLEAD_TASK_SUCESS: "FETCH_ALLLEAD_TASK_SUCESS",
  GET_NEW_LEAD_DASHBOARD_STATS: "GET_NEW_LEAD_DASHBOARD_STATS",
  NEW_DASHBOARD_TOTAL_LEAD: "NEW_DASHBOARD_TOTAL_LEAD",
  NEW_DASHBOARD_TOTAL_CONVERSION_COUNT: "NEW_DASHBOARD_TOTAL_CONVERSION_COUNT",
  NEW_DASHBOARD_TOTAL_FOLLOW_UP: "NEW_DASHBOARD_TOTAL_FOLLOW_UP",
  NEW_DASHBOARD_TASK_DUE_COUNT: "NEW_DASHBOARD_TASK_DUE_COUNT",
  NEW_DASHBOARD_LAST_7_DAYS: "NEW_DASHBOARD_LAST_7_DAYS",
  NEW_DASHBOARD_TOTAL_CONVERSION: "NEW_DASHBOARD_TOTAL_CONVERSION",
  NEW_DASHBOARD_SOURCE_REPORT: "NEW_DASHBOARD_SOURCE_REPORT",
  NEW_DASHBOARD_TOTAL_FOLLOW_UP_LIST: "NEW_DASHBOARD_TOTAL_FOLLOW_UP_LIST",
  NEW_DASHBOARD_TOTAL_DUE_TASK_LIST: "NEW_DASHBOARD_TOTAL_DUE_TASK_LIST",
  NEW_DASHBOARD_TOTAL_UPCOMING_ADMISSION: "NEW_DASHBOARD_TOTAL_UPCOMING_ADMISSION",
  NEW_DASHBOARD_DEAL_STAGE_LIST: "NEW_DASHBOARD_DEAL_STAGE_LIST",
  NEW_DASHBOARD_COMMUNICATION_REPORT: "NEW_DASHBOARD_COMMUNICATION_REPORT",

  GET_TASK_LABEL: "GET_TASK_LABEL",
  GET_TASK_LABEL_SUCESS: "GET_TASK_LABEL_SUCESS",

  TASK_REQUEST_FAILED: "TASK_REQUEST_FAILED",

  UPDATE_TASK: "UPDATE_TASK",
  UPDATE_TASK_SUCESS: "UPDATE_TASK_SUCESS",

  GET_COMMENT_FOR_TASK: "GET_COMMENT_FOR_TASK",
  GET_COMMENT_FOR_TASK_SUCCESSFUL: "GET_COMMENT_FOR_TASK_SUCCESSFUL",

  GET_TEACHER_LIST: "GET_TEACHER_LIST",
  GET_TEACHER_LIST_SUCESS: "GET_TEACHER_LIST_SUCESS",
  MARK_DONE: "MARK_DONE",
  MARK_DONE_SUCESS: "MARK_DONE_SUCESS",

  ADD_EMAIL: "ADD_EMAIL",
  ADD_EMAIL_SUCCESSFUL: "ADD_EMAIL_SUCCESSFUL",
  GET_LEAD_EMAIL: "GET_LEAD_EMAIL",

  GET_LEAD_EMAIL_SUCCESSFUL: "GET_LEAD_EMAIL_SUCCESSFUL",

  ADD_LEAD_EMAIL_TEMPLATE: " ADD_LEAD_EMAIL_TEMPLATE",
  ADD_LEAD_EMAIL_TEMPLATE_SUCCESSFUL: " ADD_LEAD_EMAIL_TEMPLATE_SUCCESSFUL",

  GET_EMAIL_TEMPLATE_REGISTER: "GET_EMAIL_TEMPLATE_REGISTER",
  GET_EMAIL_TEMPLATE_REGISTER_SUCCESSFUL: "GET_EMAIL_TEMPLATE_REGISTER_SUCCESSFUL",

  DELETE_EMAIL_TEMPLATE: "DELETE_EMAIL_TEMPLATE",
  DELETE_EMAIL_TEMPLATE_SUCCESSFUL: "DELETE_EMAIL_TEMPLATE_SUCCESSFUL",

  UPDATE_EMAIL_TEMPLATE: "UPDATE_EMAIL_TEMPLATE",
  UPDATE_EMAIL_TEMPLATE_SUCCESSFUL: "UPDATE_EMAIL_TEMPLATE_SUCCESSFUL",

  DELETE_LEAD_EMAIL: "DELETE_LEAD_EMAIL",
  DELETE_LEAD_EMAIL_SUCCESS: "DELETE_LEAD_EMAIL_SUCCESS",

  FETCH_EMAIL_TEMPLATE: "FETCH_EMAIL_TEMPLATE",
  FETCH_EMAIL_TEMPLATE_SUCCESSFUL: "FETCH_EMAIL_TEMPLATE_SUCCESSFUL",

  RESET_REDIRECT: "RESET_REDIRECT",
  RESET_EDIT: "REST_EDIT",
  RESET_STUDENTNAME: "RESET_STUDENTNAME",
  FETCH_STUDENT: "FETCH_STUDENT",
  FETCH_STUDENT_SUCCESS: "FETCH_STUDENT_SUCCESS",
  FETCH_TASK_BY_ID: "FETCH_TASK_BY_ID",
  FETCH_TASK_BY_ID_SUCCESS: "FETCH_TASK_BY_ID_SUCCESS",

  UPDATE_NEXTFOLLOWUP: "UPDATE_NEXTFOLLOWUP",
  UPDATE_NEXTFOLLOWUP_SUCCESS: "UPDATE_NEXTFOLLOWUP_SUCCESS",

  MARK_DONE_NEXTFOLLOWUP: "MARK_DONE_NEXTFOLLOWUP",
  MARK_DONE_NEXTFOLLOWUP_SUCCESS: "MARK_DONE_NEXTFOLLOWUP_SUCCESS",

  GET_SOURCE: "GET_SOURCE",
  GET_SOURCE_SUCCESS: "GET_SOURCE_SUCCESS",

  GET_SUBSOURCE: "GET_SUBSOURCE",
  GET_SUBSOURCE_SUCCESS: "GET_SUBSOURCE_SUCCESS",

  START_LEAD_DASHBOARD_SPINNER: "START_LEAD_DASHBOARD_SPINNER",
  STOP_LEAD_DASHBOARD_SPINNER: "STOP_LEAD_DASHBOARD_SPINNER",
  FETCH_MANAGE_LEAD: "FETCH_MANAGE_LEAD",
  FETCH_MANAGE_LEAD_SUCCESS: "FETCH_MANAGE_LEAD_SUCCESS",

  FETCH_LEAD_FORM_DASBOARD: "FETCH_LEAD_FORM_DASBOARD",
  FETCH_LEAD_FORM_DASBOARD_SUCCESS: "FETCH_LEAD_FORM_DASBOARD_SUCCESS",

  FETCH_LEAD_AUTOMATION_COUNT: "FETCH_LEAD_AUTOMATION_COUNT",
  FETCH_LEAD_AUTOMATION_COUNT_SUCCESS: "FETCH_LEAD_AUTOMATION_COUNT_SUCCESS",
  GET_SHARED_FORMS: "GET_SHARED_FORMS",
  GET_SHARED_FORMS_SUCCESS: "GET_SHARED_FORMS_SUCCESS",
  LEAD_ASSIGN_TO: "LEAD_ASSIGN_TO",
  LEAD_ASSIGN_TO_SUCCESS: "LEAD_ASSIGN_TO_SUCCESS",

  DELETE_FOLLOW_UP: "DELETE_FOLLOW_UP",
  DELETE_FOLLOW_UP_SUCCESS: "DELETE_FOLLOW_UP_SUCCESS",

  GET_COMMENT_FOR_LEAD_ACTIVITY: "GET_COMMENT_FOR_LEAD_ACTIVITY",
  GET_COMMENT_FOR_LEAD_ACTIVITY_SUCCESS: "GET_COMMENT_FOR_LEAD_ACTIVITY_SUCCESS",
  RESET_REDIRECT_FOR_DELETE: "RESET_REDIRECT_FOR_DELETE",

  RELOAD_PAGE_DATA: "RELOAD_PAGE_DATA",
  RELOAD_PAGE_DATA_SUCCESS: "RELOAD_PAGE_DATA_SUCCESS",
  ADD_STATUS_VALUE: "ADD_STATUS_VALUE",

  GET_ADMITTED_LEADS: "GET_ADMITTED_LEADS",
  GET_ADMITTED_LEADS_SUCCESS: "GET_ADMITTED_LEADS_SUCCESS",

  restRedirectForDelete: (firebase) => ({
    type: actions.RESET_REDIRECT_FOR_DELETE,
    firebase

  }),
  getSharedForms: (firebase, id) => ({
    type: actions.GET_SHARED_FORMS,
    id,
    firebase,
  }),
  saveAssignTo: (leadRecord, firebase) => ({
    type: actions.LEAD_ASSIGN_TO,
    leadRecord, firebase
  }),

  fetchLeadAutomationCount: (firebase) => ({
    type: actions.FETCH_LEAD_AUTOMATION_COUNT,
    firebase,
  }),

  nextFollowUpMarkDone: (values, leadRecord, firebase) => ({
    type: actions.MARK_DONE_NEXTFOLLOWUP,
    values,
    leadRecord,
    firebase,
  }),
  nextFollowUpDate: (values, record, firebase) => ({
    type: actions.UPDATE_NEXTFOLLOWUP,
    values,
    record,
    firebase,
  }),
  fetchTaskByID: (taskId, firebase) => ({
    type: actions.FETCH_TASK_BY_ID,
    taskId,
    firebase,
  }),

  resetStudentName: () => ({
    type: actions.RESET_STUDENTNAME,
  }),
  fetchStudentName: (record, firebase) => ({
    type: actions.FETCH_STUDENT,
    record,
    firebase,
  }),

  resetEdit: () => ({
    type: actions.RESET_EDIT,
  }),
  resetRedirect: () => ({
    type: actions.RESET_REDIRECT,
  }),
  fetchLeadEmailTemplate: (firebase) => ({
    type: actions.FETCH_EMAIL_TEMPLATE,
    firebase,
  }),

  deleteLeadEmail: (item, leadId, firebase) => ({
    type: actions.DELETE_LEAD_EMAIL,
    item,
    leadId,
    firebase,
  }),
  addEmail: (value, leadRecord, firebase, editorState, isLog, prevAttachments) => ({
    type: actions.ADD_EMAIL,
    value,
    leadRecord,
    firebase,
    editorState,
    isLog,
    prevAttachments
  }),
  addTemplate: (value, firebase, editorState) => ({
    type: actions.ADD_LEAD_EMAIL_TEMPLATE,
    value,
    firebase,
    editorState,
  }),

  getEmailTemplateRegister: (firebase, templateType) => ({
    type: actions.GET_EMAIL_TEMPLATE_REGISTER,
    firebase,
    templateType,
  }),

  deleteEmailTemplate: (record, firebase) => ({
    type: actions.DELETE_EMAIL_TEMPLATE,
    record,
    firebase,
  }),

  addTemplateChanges: (value, editorState, firebase, id) => ({
    type: actions.UPDATE_EMAIL_TEMPLATE,
    value,
    editorState,
    firebase,
    id,
  }),


  getTeacherList: () => ({
    type: actions.GET_TEACHER_LIST,
  }),

  getTaskComments: (task, firebase) => ({
    type: actions.GET_COMMENT_FOR_TASK,
    task,
    firebase,
  }),
  getCommentsForleadActivity: (leadActivity, firebase) => ({
    type: actions.GET_COMMENT_FOR_LEAD_ACTIVITY,
    leadActivity,
    firebase
  }),
  updateTask: (values, record, firebase, lead) => ({
    type: actions.UPDATE_TASK,
    values,
    record,
    firebase,
    lead
  }),
  getTaskLabel: (firebase) => ({
    type: actions.GET_TASK_LABEL,
    firebase,
  }),

  fetchAllTaskLists: (firebase, startDate, endDate) => ({
    type: actions.FETCH_ALLLEAD_TASK,
    firebase,
    startDate,
    endDate,
  }),
  //
  renameDocument: (docName, record, firebase, id, documents, currentFilePath) => ({
    type: actions.RENAME_LEAD_DOC_FOLDER,
    docName,
    record,
    firebase,
    id,
    documents,
    currentFilePath
  }),

  deleteUserDocument: (record, id, firebase, filePath) => ({
    type: actions.DELETE_LEAD_DOC,
    record,
    id,
    firebase,
    filePath
  }),

  resetOperationRequest: () => ({
    type: actions.RESET_OPERATION_REQ,
  }),

  addNewFolder: (folderName, filePath, firebase, id) => ({
    type: actions.ADD_LEAD_DOC_FOLDER,
    folderName,
    filePath,
    firebase,
    id,
  }),
  getLeadDocument: (leadId, firebase) => ({
    type: actions.GET_LEAD_DOC,
    leadId,
    firebase,
  }),

  uploadLeadDocument: (fileList, mediaType, id, firebase, filePath) => ({
    type: actions.UPLOAD_LEAD_DOC,
    fileList,
    mediaType,
    id,
    firebase,
    filePath,
  }),
  fetchSelectedLeadFormDashboard: (leadId, firebase) => ({
    type: actions.FETCH_LEAD_FORM_DASBOARD,
    leadId,
    firebase,
  }),
  initLeadsData: (
    startDate,
    endDate,
    firebase,
    frequency,
    initialCall,
    customRange,
    selectedLead,
    selectedStatus,
    leadFormId
  ) => ({
    type: actions.LIST_LEADS,
    startDate,
    endDate,
    firebase,
    frequency,
    initialCall,
    customRange,
    selectedLead,
    selectedStatus,
    leadFormId
  }),

  addTask: (value, leadRecord, firebase) => ({
    type: actions.ADD_TASK,
    value,
    leadRecord,
    firebase,
  }),



  getFeeProgram: (firebase) => ({
    type: actions.GET_FEE_PROGRAM_FOR_LEADS,
    firebase,
  }),

  getStatus: (firebase) => ({
    type: actions.GET_STATUS,
    firebase,
  }),
  getSource: (firebase) => ({
    type: actions.GET_SOURCE,
    firebase,
  }),
  getSubSource: (firebase) => ({
    type: actions.GET_SUBSOURCE,
    firebase,
  }),
  fetchStatusSourceSubsource: (firebase, category) => ({
    type: actions.FETCH_MANAGE_LEAD,
    firebase,
    category,
  }),

  saveNewLead: (values, firebase, leadAdditionalField, childConfig, parentConfig, isAdmitted, newStudentInfo) => ({
    type: actions.SAVE_NEW_LEAD,
    values,
    firebase,
    leadAdditionalField,
    childConfig,
    parentConfig,
    isAdmitted,
    newStudentInfo
  }),

  updateLead: (incomingLeadsValue, record, firebase, isAddAccount, newStudentData, isStudentDelete) => ({
    type: actions.UPDATE_LEAD,
    incomingLeadsValue,
    record,
    firebase,
    isAddAccount,
    newStudentData,
    isStudentDelete
  }),

  addStatus: (statusInput, firebase) => ({
    type: actions.ADD_STATUS,
    statusInput,
    firebase,
  }),
  addStatusSourceSubsource: (value, category, firebase) => ({
    type: actions.MANAGE_LEAD,
    value,
    category,
    firebase,
  }),

  fetchSelectedLeadDetail: (selectedLead, firebase) => ({
    type: actions.GET_LEAD_DETAIL,
    selectedLead,
    firebase,
  }),

  addActivity: (value, leadRecord, firebase, record) => ({
    type: actions.ADD_ACTIVITY,
    value,
    leadRecord,
    firebase,
    record
  }),

  fetchSelectedLeadActivity: (selectedLead, firebase) => ({
    type: actions.GET_LEAD_ACTIVITY,
    selectedLead,
    firebase,
  }),

  updateLeadStatusChange: (selectedLeadStatus, lead, firebase, deleteStudent) => ({
    type: actions.UPDATE_LEAD_STATUS_CHANGE,
    selectedLeadStatus,
    lead,
    firebase,
    deleteStudent
  }),

  resetLeadsOperationType: () => ({
    type: actions.RESET_LEADS_OPERATION_TYPE,
  }),

  createNewAccount: (record, firebase, incomingGender, incomingStatus) => ({
    type: actions.CREATE_NEW_ACCOUNT,
    record,
    firebase,
    incomingGender,
    incomingStatus
  }),

  deleteLead: (record, firebase) => ({
    type: actions.DELETE_LEAD,
    record,
    firebase,
  }),

  deleteSelectedStatus: (record, firebase) => ({
    type: actions.DELETE_LEAD_STATUS,
    record,
    firebase,
  }),
  deleteCategory: (record, category, firebase) => ({
    type: actions.DELETE_LEAD_CATEGORY,
    record,
    category,
    firebase,
  }),

  getLeadsAdditionalField: (firebase) => ({
    type: actions.GET_LEADS_ADDITIONAL_FIELD,
    firebase,
  }),

  downloadLeadsExcel: (firebase, values) => ({
    type: actions.DOWNLOAD_LEADS_EXCEL,
    firebase,
    values,
  }),

  deleteLeadActivity: (item, leadId, firebase) => ({
    type: actions.DELETE_LEAD_ACTIVITY,
    item,
    leadId,
    firebase,
  }),

  deleteLeadTask: (item, leadId, firebase) => ({
    type: actions.DELETE_LEAD_TASK,
    item,
    leadId,
    firebase,
  }),


  addParentOne: (studentObj, firebase) => ({
    type: actions.LEAD_ADD_PARENT_ONE,
    studentObj,
    firebase,
  }),

  addParentTwo: (studentObj, firebase) => ({
    type: actions.LEAD_ADD_PARENT_TWO,
    studentObj,
    firebase,
  }),

  resetLeadExistingDetail: () => ({
    type: actions.RESET_LEAD_EXISTING_DETAIL,
  }),

  leadLinkParent: (firebase, studentObj, linkForParentOne) => ({
    type: actions.LEAD_LINK_PARENT,
    firebase,
    studentObj,
    linkForParentOne,
  }),

  getNewLeadDashboardStats: (start, end, firebase, category) => ({
    type: actions.GET_NEW_LEAD_DASHBOARD_STATS,
    start,
    end,
    firebase,
    category,
  }),

  startDashboardSpinner: () => ({
    type: actions.START_LEAD_DASHBOARD_SPINNER,
  }),
  deleteNextFollowUp: (leadRecord, firebase) => ({
    type: actions.DELETE_FOLLOW_UP,
    leadRecord,
    firebase

  }),
  reloadPage: () => ({
    type: actions.RELOAD_PAGE_DATA
  }),
  addStatusValues: (status, statusKey, statusValue, firebase) => ({
    type: actions.ADD_STATUS_VALUE,
    status,
    statusKey,
    statusValue,
    firebase
  }),
};
export default actions;
