import { Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";
import FilterAction from "../../Utility/FilterAction";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import Modal from "../../components/feedback/modal";
import formatMsg from "../../components/utility/formatMessageUtil";
const { Option } = Select;
const { TextArea } = Input;
const CollectionAddActivityForm = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        activityType: [
          "Phone call",
          "Log email",
          "Invoice sent",
          "Show around",
          "Quote sent",
          "Note",
          // "Status Update",
          "Text",
          "Others",
        ],
        nextFollowUp: ["Follow up"],
        logEmail: ["Email"],
      };
      this.disabledDate = this.disabledDate.bind(this);
    }

    disabledDate(current) {
      let startDate = moment();
      return current && current > startDate;
    }

    render() {
      const {
        isActivityModalVisible,
        saveActivity,
        handleAddActivityCancel,
        form,
        handleActivity,

        followup,
        email,
        editableRecord,
        activity,
      } = this.props;


      const { activityType, logEmail } = this.state;
      const { getFieldDecorator } = form;
      return (
        <Modal
          width="450px"
          title={!followup ? editableRecord ? formatMsg("activity.updateActivity") : formatMsg("leads.addActivity") : formatMsg("completeFollowUp")}
          visible={isActivityModalVisible}
          onOk={saveActivity}
          onCancel={handleAddActivityCancel}
          okText={formatMsg("submit")}
          cancelText={formatMsg("cancel")}
          maskClosable={false}
        >
          <Form colon={false}>
            {!followup ? (
              <Form.Item
                label={<span style={styles.headerText}>{formatMsg("leads.ActivityType")}</span>}
              >
                {getFieldDecorator("activityType", {
                  initialValue: editableRecord && editableRecord.activityType ? editableRecord.activityType : null,
                  rules: [
                    {
                      required: true,
                      message: formatMsg("customizeActivity.errorMsgActivityType"),
                    },
                  ],
                })(
                  <Select
                    dropdownMatchSelectWidth={false}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    style={{
                      width: "100%",
                      "font-size": "13px",
                    }}
                    placeholder={formatMsg("leads.ActivityType")}
                    onChange={(value) => handleActivity(value)}
                  >
                    {activityType.map((item) => (
                      <Option
                        value={item}
                        style={{
                          "font-size": "12px",
                        }}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : null}
            <Form.Item
              label={
                <span style={styles.headerText}>
                  {!followup ? formatMsg("activity.note") : formatMsg("leads.activityNotes")}
                </span>
              }
            >
              {getFieldDecorator("additionalDescription", {
                initialValue: editableRecord && editableRecord.description
                  ? editableRecord.description
                  : null,
                rules: [
                  {
                    required: false,
                    message: formatMsg("message.inputDescription"),
                  },
                ],
              })(<TextArea rows={6} placeholder={formatMsg("placeholder.description")} />)}
            </Form.Item>
            {followup || editableRecord && editableRecord.activityType.toLowerCase() === "follow up" ? null : (
              <Row>
                <Col span={11}>
                  <Form.Item
                    label={<span style={styles.headerText}>{formatMsg("leadActivityDate")}</span>}
                  >
                    {getFieldDecorator("date", {
                      initialValue: editableRecord && editableRecord.date ? moment(editableRecord.date) : null,
                      rules: [
                        {
                          required: true,
                          message: formatMsg("cctv.pleaseInput") + " " + formatMsg("leadActivityDate"),
                        },
                      ],

                    })(
                      <DatePicker
                        // disabledDate={this.disabledDate}
                        disabledDate={(d) => d.isAfter(moment().endOf("day"))}
                        getCalendarContainer={(trigger) => trigger.parentNode}
                        format={FilterAction.getDateFormat(this.props.firebase)}
                      />
                    )}
                  </Form.Item>
                </Col>
                {/* <Col span={11} offset={2}>
                  <Form.Item
                    label={<span style={styles.headerText}>{formatMsg("leadActivityTime")}</span>}
                  >
                    {getFieldDecorator("time", {
                      initialValue: editableRecord && editableRecord.time ? moment(editableRecord.time) : null,
                      rules: [
                        {
                          required: true,
                          message: formatMsg("message.inputName"),
                        },
                      ],
                    })(
                      <TimePicker
                        style={{
                          width: "100%",
                        }}
                        use12Hours
                        format="h:mm A"
                      />
                    )}
                  </Form.Item>
                </Col> */}
              </Row>)

            }
            {followup ? (
              <Row>
                <Form.Item
                  label={
                    <span style={styles.headerText}>
                      {!followup
                        ? formatMsg("setNextFollowUpForLead")
                        : formatMsg("leads.nextFollowUpOptional")}
                    </span>
                  }
                >
                  {getFieldDecorator("nextFollowUpDate", {
                    rules: [
                      {
                        required: false,
                        message: formatMsg("message.selectDate"),
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                      getCalendarContainer={(trigger) => trigger.parentNode}
                      placeholder={formatMsg("placeholder.selectNextFollowUpDate")}
                      disabledDate={(d) => d.isBefore(moment().startOf("day"))}
                      format={FilterAction.getDateFormat(this.props.firebase)}
                    />
                  )}
                </Form.Item>
              </Row>
            ) : null}
            {followup || editableRecord && editableRecord.activityType.toLowerCase() === "follow up" ? (<Row>
              <Form.Item
                label={
                  <span style={styles.headerText}>

                    {formatMsg("followUpCompletedon")}
                  </span>
                }
              >
                {getFieldDecorator("date", {
                  initialValue: editableRecord && editableRecord.date ? moment(editableRecord.date) : moment(),
                  rules: [
                    {
                      required: false,
                      message: formatMsg("message.selectDate"),
                    },
                  ],
                })(
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    getCalendarContainer={(trigger) => trigger.parentNode}
                    placeholder={formatMsg("placeholder.selectNextFollowUpDate")}
                    disabledDate={(d) => d.isAfter(moment().endOf("day"))}
                    format={FilterAction.getDateFormat(this.props.firebase)}
                    allowClear={false}
                  />
                )}
              </Form.Item>
            </Row>) : null}
          </Form>
        </Modal>
      );
    }
  }
);
export default CollectionAddActivityForm;

const styles = {
  header: {
    fontSize: webFont.lm,
    margin: "0px",
    fontWeight: "500",
    color: colors.v2_fiord,
  },

  headerText: {
    fontWeight: "500",
    color: colors.v2_fiord,
    fontSize: webFont.lm,
    marginRight: 10,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
  },

  inputStyle: {
    borderRadius: "5px",
    width: "50%",
  },
};
