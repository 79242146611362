const actions = {
  SEND_NEWSLETTER: "SEND_NEWSLETTER",
  SEND_NEWSLETTER_SUCCESS: "SEND_NEWSLETTER_SUCCESS",
  SEND_NEWSLETTER_FAILED: "SEND_NEWSLETTER_FAILED",
  SAVE_NEWSLETTER: "SAVE_NEWSLETTER",
  SAVE_NEWSLETTER_SUCCESS: "SAVE_NEWSLETTER_SUCCESS",
  SAVE_NEWSLETTER_FAILED: "SAVE_NEWSLETTER_FAILED",
  GET_STUDENT_BY_CENTER_NAME: "GET_STUDENT_BY_CENTER_NAME",
  GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL: "GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL",
  GET_STUDENT_BY_CENTER_NAME_FAILED: "GET_STUDENT_BY_CENTER_NAME_FAILED",
  GET_STUDENT_BY_ROOM_NAME: "GET_STUDENT_BY_ROOM_NAME",
  GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL: "GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL",
  GET_STUDENT_BY_ROOM_NAME_FAILED: "GET_STUDENT_BY_ROOM_NAME_FAILED",
  GET_SEARCHED_STUDENT: "GET_SEARCHED_STUDENT",
  GET_SEARCHED_STUDENT_SUCCESSFUL: "GET_SEARCHED_STUDENT_SUCCESSFUL",
  GET_SEARCHED_STUDENT_FAILED: "GET_SEARCHED_STUDENT_FAILED",
  GET_STUDENT_DATA: "GET_STUDENT_DATA",
  GET_STUDENT_DATA_SUCCESSFUL: "GET_STUDENT_DATA_SUCCESSFUL",
  GET_STUDENT_DATA_FAILED: "GET_STUDENT_DATA_FAILED",
  FILE_UPLOAD_START: "FILE_UPLOAD_START",
  FILE_UPLOAD_COMPLETED: "FILE_UPLOAD_COMPLETED",
  FILE_UPLOAD_PROGRESS: "FILE_UPLOAD_PROGRESS",
  FILE_UPLOAD_FAILED: "FILE_UPLOAD_FAILED",
  FETCH_CLASSROOM: "FETCH_CLASSROOM",
  FETCH_CLASSROOM_SUCCESSFUL: "FETCH_CLASSROOM_SUCCESSFUL",
  NEWSLETTER_REQUEST_FAILED: "NEWSLETTER_REQUEST_FAILED",
  RESET_ERROR: "RESET_ERROR",
  SEND_NEWSLETTER_ALL_CENTER: "SEND_NEWSLETTER_ALL_CENTER",
  SEND_NEWSLETTER_ALL_CENTER_SUCCESS: "SEND_NEWSLETTER_ALL_CENTER_SUCCESS",
  GET_ALL_NEWSLETTER: "GET_ALL_NEWSLETTER",
  GET_ALL_NEWSLETTER_SUCCESS: "GET_ALL_NEWSLETTER_SUCCESS",
  DELETE_NEWSLETTER: "DELETE_NEWSLETTER",
  DELETE_NEWSLETTER_SUCCESS: "DELETE_NEWSLETTER_SUCCESS",
  DELETE_NEWSLETTER_FAILED: "DELETE_NEWSLETTER_FAILED",
  RESET_UPLOAD_IMAGE: "RESET_UPLOAD_IMAGE",

  uploadImage: (fileUrl, firebase, newsletterType) => ({
    type: actions.FILE_UPLOAD_START,
    fileUrl,
    firebase,
    newsletterType,
  }),

  saveNewsletter: (newsletter, firebase, container) => ({
    type: actions.SAVE_NEWSLETTER,
    newsletter,
    firebase,
    container,
  }),

  sendNewsletter: (newsletter, firebase,container) => (
    {
      type: actions.SEND_NEWSLETTER,
      newsletter,
      firebase,
      container
    }),

  getStudentByCenterName: (centerName, option) => ({
    type: actions.GET_STUDENT_BY_CENTER_NAME,
    centerName,
    option,
  }),

  getStudentByRoomName: (roomName, option) => ({
    type: actions.GET_STUDENT_BY_ROOM_NAME,
    roomName,
    option,
  }),

  getStudentByName: (studentName) => ({
    type: actions.GET_SEARCHED_STUDENT,
    studentName,
  }),

  fetchStudentData: (firebase) => ({
    type: actions.GET_STUDENT_DATA,
    firebase,
  }),

  fetchClassrooms: (firebase) => ({
    type: actions.FETCH_CLASSROOM,
    firebase,
  }),

  resetError: () => ({
    type: actions.RESET_ERROR,
  }),

  sendNewsletterToAllCenter: (newsletter, firebase, filteredBranch, selectedStatus, container) => ({
    type: actions.SEND_NEWSLETTER_ALL_CENTER,
    newsletter,
    firebase,
    filteredBranch,
    selectedStatus,
    container
  }),
  
  getAllNewsletterData: (firebase) => ({
    type: actions.GET_ALL_NEWSLETTER,
    firebase,
  }),

  deleteNewsletter: (id, firebase) => ({
    type: actions.DELETE_NEWSLETTER,
    id,
    firebase,
  }),
  
  resetUploadImage : () => ({
    type: actions.RESET_UPLOAD_IMAGE,
  })
};
export default actions;
