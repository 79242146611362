import actions from "./actions";

const initState = {
  allSentNewspaper: [],
  isLoading: false,
  autoCompleteCenter: [],
  autoCompleteClassroom: [],
  sentNewsletterChannel: undefined,
};

export default function newsletterReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_SENT_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_SENT_NEWSLETTER_SUCCESS:
      return {
        ...state,
        allSentNewspaper: action.allSentNewspaper,
        isLoading: false,
        autoCompleteClassroom: [],
        autoCompleteCenter: [],
        sentNewsletterChannel: action.sentNewsletterChannel,
      };
    case actions.SAVE_NEWSLETTER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
