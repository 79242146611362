import actions from "./actions";
const initState = {
  lessonLoading: false,
  lessonNameLoading: false,
  assignmentsSchool: [],
  assignmentLessons: [],
  // assignmentChannel: undefined,
  lessonSubmission: new Map(),
  lessonComment: new Map(),
  error: false,
  classDropDown: [],
  assignmentOperation: undefined,
  showLoader: false,
  sharedAssignment: [],
  sharedLessonSubmission: new Map(),
  sharedLessonComment: new Map(),
  students: [],
};

export default function assignmentsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SCHOOL_ASSIGNMENT:
      return {
        ...state,
        lessonLoading: true,
        lessonNameLoading: true,
        assignmentsSchool: [],
        assignmentLessons: [],
        students: [],
        showLoader: true,
      };
    case actions.GET_SCHOOL_ASSIGNMENT_SUCCESSFUL:
      return {
        ...state,
        assignmentsSchool: action.assignmentsSchool,
        assignmentLessons: action.assignmentLessons,
        // assignmentChannel: action.assignmentChannel,
        lessonLoading: false,
        assignmentOperation: action.assignmentOperation,
        lessonNameLoading: action.lessonNameLoading,
        showLoader: action.showLoader,
        students: action.students,
      };

    case actions.GET_SUBMISSION:
      return {
        ...state,
      };

    case actions.GET_SUBMISSION_SUCCESSFUL:
      return {
        ...state,
        lessonSubmission: action.lessonSubmission,
      };

    case actions.GET_ASSIGNMENT_COMMENT:
      return {
        ...state,
      };

    case actions.GET_ASSIGNMENT_COMMENT_SUCCESSFUL:
      return {
        ...state,
        lessonComment: action.lessonComment,
      };

    case actions.REQUEST_FAIL:
      return {
        ...state,
        error: true,
        lessonLoading: false,
      };

    case actions.GET_CLASS_DROP_DOWN:
      return {
        ...state,
      };

    case actions.GET_CLASS_DROP_DOWN_SUCCESS:
      return {
        ...state,
        classDropDown: action.classDropDown,
      };

    case actions.RESET_ASSIGNMENT_OPERATION:
      return {
        ...state,
        assignmentOperation: undefined,
        error: false,
        lessonLoading: false,
      };

    case actions.DELETE_SCHOOL_ASSIGNMENT:
      return {
        ...state,
      };

    case actions.DELETE_SCHOOL_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignmentOperation: "delete_record",
      };

    case actions.GET_ASSIGNMENT_BY_LESSON_ID:
      return {
        ...state,
        lessonLoading: true,
      };

    case actions.GET_ASSIGNMENT_BY_LESSON_ID_SUCCESS:
      return {
        ...state,
        lessonLoading: false,
        sharedAssignment: action.sharedAssignment,
      };

    case actions.GET_SHARED_ASSIGNMENT_SUBMISSION:
      return {
        ...state,
      };

    case actions.GET_SHARED_ASSIGNMENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        sharedLessonSubmission: action.sharedLessonSubmission,
      };

    case actions.GET_SHARED_ASSIGNMENT_COMMENT:
      return {
        ...state,
      };

    case actions.GET_SHARED_ASSIGNMENT_COMMENT_SUCCESS:
      return {
        ...state,
        sharedLessonComment: action.sharedLessonComment,
      };

    case actions.DELETE_SHARED_SCHOOL_ASSIGNMENT:
      return {
        ...state,
      };

    case actions.DELETE_SHARED_SCHOOL_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignmentOperation: "delete_record",
      };

    case actions.RESET_SHARED_ASSIGNMENT_OPERATION:
      return {
        ...state,
        assignmentOperation: undefined,
        error: false,
        lessonLoading: false,
      };

    default:
      return state;
  }
}
