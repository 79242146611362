import { Drawer, Spin } from "antd";
import React from 'react';
import colors from "../../Utility/colorFactory";
import Box from "../../components/utility/box";
import AffectedScheduleView from "./AffectedScheduleView";
import formatMsg from "../../components/utility/formatMessageUtil";


function AffectedScheduleDrawer({ showDrawer, isLoading, closeDrawer, affectedSchedules, firebase }) {
    return (
        <Drawer
            width={520}
            placement="right"
            closable={true}
            onClose={() => {
                closeDrawer()
            }}
            visible={showDrawer}
            closeIcon={true}
            title={<p style={styles.title} >{formatMsg("affectedSchedules")}</p>}
        >
            <Box
                style={styles.box}
            >
                <Spin
                    spinning={isLoading}
                    style={{
                        height: window.innerHeight,
                    }}
                >
                    <div>
                        {
                            affectedSchedules.map((schedule) => {
                                return (
                                    <AffectedScheduleView
                                        schedule={schedule}
                                        firebase={firebase}
                                    />
                                )
                            })
                        }
                    </div>
                </Spin>
            </Box>
        </Drawer>
    )
}

export default AffectedScheduleDrawer;
const styles = {
    box: {
        backgroundColor: colors.white,
        borderRadius: "7px",
    },
    title: {
        color: colors.v2_Cerulean,
        marginBottom: "0px"
    }
};
