import { all, takeEvery, put, call, fork, take } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore";
import { AllReportsApi } from "../../firestore-api/allReports";
import bugsnagClient from "@bugsnag/js";
import notification from "../../components/notification";

function* showReport({ firebase }) {
  try {
    let channel = yield call(AllReportsApi.fetchReports, firebase);

    while (true) {
      let fetchedReports = yield take(channel);
      yield put({
        type: actions.SHOW_REPORT_SUCCESS,
        generatedReports: fetchedReports,
        reportsChannel: channel,
      });
    }
  } finally {
    console.log("Terminating channel");
  }
}

function* submitForm({ reportName, values, firebase, endpoint }) {
  try {
    var genericMessage = "Failed to generate report. Please contact school or Illumine";
    let addResponse = yield call(
      AllReportsApi.addFormFields,
      reportName,
      values,
      firebase,
      endpoint
    );

    if (addResponse && addResponse.status === 200) {
      yield put({
        type: actions.SUBMIT_FORM_SUCCESS,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    notification("error", genericMessage);
  }
}

function* deleteReport({ id, firebase }) {
  try {
    yield call(AllReportsApi.deleteReportsGenerated, id, firebase);

    yield put({
      type: actions.DELETE_REPORT_SUCCESS,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    notification("error", "Failed to delete generated report.");
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.SHOW_REPORT, showReport),
    yield takeEvery(actions.SUBMIT_FORM, submitForm),
    yield takeEvery(actions.DELETE_REPORT, deleteReport),
  ]);
}
