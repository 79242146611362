export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CLOSE_ALL: "CLOSE_ALL",
  CLEAR_MENU: "CLEAR_MENU",
  SWITCH_BRANCH_NAME: "SWITCH_BRANCH_NAME",
  ATTACHHMENT_UPLOAD_PROGRESS: "ATTACHHMENT_UPLOAD_PROGRESS",
  RESET_ATTACHMENT_PROGRESS: "RESET_ATTACHMENT_PROGRESS",
  INIT_BRANCH_CHANGE_LOADER: "INIT_BRANCH_CHANGE_LOADER",
  STOP_BRANCH_CHANGE_LOADER: "STOP_BRANCH_CHANGE_LOADER",
  SIDEBAR_OPTION_COLOR: "SIDEBAR_OPTION_COLOR",
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== "DesktopView";
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  closeAll: () => ({
    type: actions.CLOSE_ALL,
  }),
  clearMenu: () => ({
    type: actions.CLEAR_MENU,
  }),
  switchBranchName: (branchName) => ({
    type: actions.SWITCH_BRANCH_NAME,
    branchName,
  }),

  resetAttachmentProgress: () => ({
    type: actions.RESET_ATTACHMENT_PROGRESS,
  }),

  initBranchChangeLoader: () => ({
    type: actions.INIT_BRANCH_CHANGE_LOADER,
  }),

  stopBranchChangeLoader: () => ({
    type: actions.STOP_BRANCH_CHANGE_LOADER,
  }),

  changeSideBarColor: (currentOptionSelected) => ({
    type: actions.SIDEBAR_OPTION_COLOR,
    currentOptionSelected,
  }),
};
export default actions;
