import actions from "./actions";
const initState = {
  events: [],
  eventComments: [],
  eventPhotos: [],
  eventsChannel: undefined,
  isLoading: false,
  operationType: undefined,
  error: false,
  activityComments: new Map(),
  likesCount: new Map(),
  eventPhotoChan: undefined,
};

export default function eventsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_EVENTS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_ALL_EVENTS_SUCCESSFUL:
      return {
        ...state,
        events: action.events,
        eventsChannel: action.eventsChannel,
        isLoading: false,
        operationType: undefined,
      };

    case actions.GET_EVENT_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_COMMENT_FOR_EVENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_COMMENT_FOR_EVENT_SUCCESSFUL:
      return {
        ...state,
        eventComments: action.eventComments,
        isLoading: false,
      };

    case actions.GET_LIKES_COUNT_SUCCESSFUL:
      return {
        ...state,
        likesCount: action.likesCount,
      };

    case actions.ADD_EVENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_EVENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_EVENT",
      };

    case actions.UPDATE_EVENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.UPDATE_EVENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_EVENT",
      };

    case actions.GET_PHOTOS_FOR_EVENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        eventPhotos: [],
      };
    case actions.GET_PHOTOS_FOR_EVENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        eventPhotos: action.eventPhotos,
        eventPhotoChan: action.eventPhotoChan,
      };

    case actions.EVENT_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case actions.ADD_EVENT_FOR_ALL_CENTERS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_EVENT_FOR_ALL_CENTERS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ALL_CENTER_ADD_EVENT",
      };

    case actions.RESET_EVENT_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        error: false,
      };

    case actions.ADD_EVENT_IMAGES:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_EVENT_IMAGES_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_EVENT_IMAGE",
      };

    case actions.DELETE_EVENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_EVENT",
      };

    default:
      return state;
  }
}
