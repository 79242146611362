import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { NotificationApi } from "../../firestore-api/notification";
import { LearningApi } from "../../firestore-api/learning";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";
import FilterAction from "../../Utility/FilterAction";

function* fetchSubmissionActivity({ assignmentId, firebase, lessonId, studentId }) {
  try {
    let lessonSub = yield call(
      lessonAssignmentApi.getLessonSubmission,
      assignmentId,
      firebase,
      studentId
    );
    let activities = [];
    let lesson = {};
    if (lessonSub && lessonSub.length > 0) {
      for (let index in lessonSub) {
        let val = yield call(ActivityApi.getActivityById, lessonSub[index].activityId, firebase);
        if (val && val.id) {
          activities.push(val);
        }
      }
    }

    lesson = yield call(LearningApi.getLessonById, lessonId, firebase);

    yield put({
      type: actions.GET_SUBMISSION_ACTIVITY_SUCCESSFUL,
      submissionActivity: activities,
      submissionActivityLesson: lesson,
    });
  } catch (err) {
    console.log("failed to fetch submission activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SINGLE_SUBMISSION_REQUEST_FAIL,
    });
  }
}

function* fetchPendingStudent({ assignmentId, firebase, assignmentRecord }) {
  try {
    let data;
    if (assignmentRecord) {
      data = assignmentRecord;
    } else {
      data = yield call(lessonAssignmentApi.getSchoolAssignmentById, assignmentId, firebase);
    }
    let students = [];
    if (data && data.id) {
      let studentIds = data.studentIds;
      for (let index in studentIds) {
        let student = yield call(StudentApi.getStudentById, studentIds[index], firebase);
        if (student && student.id && student.name) {
          students.push(student);
        }
      }
    }
    let lessonSub = yield call(lessonAssignmentApi.getLessonSubmission, assignmentId, firebase);

    if (lessonSub.length > 0) {
      for (let i in lessonSub) {
        students = students.filter((item) => lessonSub[i].studentId !== item.id);
      }
    }

    let studentNames = [];
    students.map((ele) => {
      studentNames.push(ele.name);
    });

    yield put({
      type: actions.GET_PENDING_STUDENT_FOR_SUBMISSION_SUCCESSFUL,
      taggedStudent: students,
      singleAssignmentSubmission: lessonSub,
      pendingStudents: studentNames,
    });
  } catch (err) {
    console.log("failed to fetch tagged student to assignment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SINGLE_SUBMISSION_REQUEST_FAIL,
    });
  }
}

function* fetchSingleSubmissionComment({ activities, firebase }) {
  try {
    //let activities = submissionList;
    let commentTaskList = [];
    var commentsCount = new Map();
    for (const item in activities) {
      let task = call(lessonAssignmentApi.getCommentsCount, activities[item].id, firebase);
      commentTaskList.push(task);
      commentsCount.set(activities[item].id, []);
    }

    let newVal = yield all([commentTaskList]);
    for (let i in newVal[0]) {
      let val = newVal[0][i];
      for (let j in val) {
        if (commentsCount.has(val[j].sourceId)) {
          commentsCount.set(val[j].sourceId, val);
          break;
        }
      }
    }
    yield put({
      type: actions.GET_SINGLE_SUBMISSION_COMMENT_SUCCESS,
      singleSubmissionComments: commentsCount,
    });
  } catch (err) {
    console.log("failed to fetch single submission list comment", err);
    bugsnagClient.notify(err);
  }
}

function* putSingleAssignmentActivityStar({ activity, firebase }) {
  try {
    let activityObj = activity;
    activityObj.updatedBy = firebase.teacher.name;
    activityObj.updatedOn = moment().valueOf();
    activityObj.favt = true;
    activityObj.platform = "web";
    yield call(ActivityApi.updateActivityObj, activityObj, activityObj.id, firebase);
    yield put({
      type: actions.MARK_SINGLE_ASSIGNMENT_STAR_SUCCESS,
    });

    if (activityObj.studentIds) {
      let studentId = activityObj.studentIds[0];
      let studentAssignment = yield call(
        lessonAssignmentApi.getStudentAssignmentById,
        activityObj.lessonId,
        studentId,
        firebase
      );
      if (
        studentAssignment &&
        studentAssignment.length > 0 &&
        studentAssignment[0] &&
        studentAssignment[0].id
      ) {
        let tempAssignment = studentAssignment[0];
        tempAssignment.star = true;
        yield fork(
          lessonAssignmentApi.updateStudentAssignment,
          studentId,
          tempAssignment.id,
          tempAssignment,
          firebase
        );
      }

      let allStudents = FilterAction.getStudentList(firebase);

      let selectedStudent = allStudents.filter((s) => {
        return s.id === studentId;
      });

      if (selectedStudent && selectedStudent.length > 0) {
        for (let i = 0; i < selectedStudent.length; i++) {
          let selectedActivity = "Assignment";
          let activityId = activityObj.id;

          if (selectedStudent[i].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              undefined,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedStudent[i].name + " has been awarded a star for the assignment",
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase,
              undefined
            );

            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                selectedStudent[i].name + " has been awarded a star for the assignment",
                alertNode,
                selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase,
                undefined
              );
            }
          }

          if (selectedStudent[i].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              undefined,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedStudent[i].name + " has been awarded a star for the assignment",
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase,
              undefined
            );

            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                selectedStudent[i].name + " has been awarded a star for the assignment",
                alertNode,
                selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase,
                undefined
              );
            }
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to mark assignment star", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_SUBMISSION_ACTIVITY, fetchSubmissionActivity),
    yield takeLatest(actions.GET_PENDING_STUDENT_FOR_SUBMISSION, fetchPendingStudent),
    yield takeLatest(actions.GET_SINGLE_SUBMISSION_COMMENT, fetchSingleSubmissionComment),
    yield takeLatest(actions.MARK_SINGLE_ASSIGNMENT_STAR, putSingleAssignmentActivityStar),
  ]);
}
