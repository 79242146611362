import { all, put, call, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { SchoolDetailApi } from "../../firestore-api/schoolDetails";
import bugsnagClient from "@bugsnag/js";

function* fetchSchoolDetail({ firebase }) {
  try {
    let data = yield call(SchoolDetailApi.getSchoolDetails, firebase);
    if (data) {
      yield put({
        type: actions.GET_SCHOOL_DETAILS_SUCCESSFUL,
        schoolDetails: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch school details", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_DETAILS_REQUEST_FAILED,
    });
  }
}

function* updateSchoolDetails({ values, firebase }) {
  try {
    yield call(SchoolDetailApi.updateSchoolDetail, values, firebase);
    yield put({
      type: actions.SAVE_SCHOOL_DETAILS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update school details", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_DETAILS_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_SCHOOL_DETAILS, fetchSchoolDetail),
    yield takeLatest(actions.SAVE_SCHOOL_DETAILS, updateSchoolDetails),
  ]);
}
