const actions = {
  GET_PERMISSIONS: "GET_PERMISSIONS",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",

  RESET_PERMISSION_OPERATION_TYPE: "RESET_PERMISSION_OPERATION_TYPE",

  ADD_ROLE: "ADD_ROLE",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  UPDATE_PERMISSIONS: "UPDATE_PERMISSIONS",
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_PERMISSIONS_SUCCESS",

  PERMISSION_REQUEST_FAIL: "PERMISSION_REQUEST_FAIL",

  GET_PARENT_UNREAD_MESG_COUNT: "GET_PARENT_UNREAD_MESG_COUNT",

  TRANSFER_STAFF_ROLE: "TRANSFER_STAFF_ROLE",
  TRANSFER_STAFF_ROLE_SUCCESS: "TRANSFER_STAFF_ROLE_SUCCESS",
  TRANSFER_STAFF_ROLE_FAIL: "TRANSFER_STAFF_ROLE_FAIL",

  getPermissionList: (firebase) => ({
    type: actions.GET_PERMISSIONS,
    firebase,
  }),

  addRoleToPermissions: (permissions, newRole, firebase) => ({
    type: actions.ADD_ROLE,
    permissions,
    newRole,
    firebase,
  }),

  deleteRoleFromPermissions: (permissions, roleToBeDeleted, firebase) => ({
    type: actions.DELETE_ROLE,
    permissions,
    roleToBeDeleted,
    firebase,
  }),

  updatePermissions: (permissions, firebase) => ({
    type: actions.UPDATE_PERMISSIONS,
    permissions,
    firebase
  }),

  resetPermissionOperation: () => ({
    type: actions.RESET_PERMISSION_OPERATION_TYPE,
  }),

  transferStaffRole: (firebase, roleToDelete, roleToTransfer, permissions) => ({
    type: actions.TRANSFER_STAFF_ROLE,
    firebase,
    roleToDelete,
    roleToTransfer,
    permissions
  })
};
export default actions;
