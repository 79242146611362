import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import { HolidayApi } from "../../firestore-api/holiday";
import { NotificationApi } from "../../firestore-api/notification";
import { getItem } from "../../Utility/encryptedStorage";
import FilterAction from "../../Utility/FilterAction";
import { callApi } from "../../Utility/superAgentUntil";
import actions from "./actions";

function* fetchAllHolidays({ firebase, initialCall }) {
  const chan = yield call(HolidayApi.getNewHolidayList, firebase);
  try {
    while (true) {
      const data = yield take(chan);
      console.log("holiday chan data ------", data);
      let holidayList = data;

      yield put({
        type: actions.GET_ALL_HOLIDAYS_SUCCESSFUL,
        holidayList: holidayList,
        holidaysChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
      });
    }
  } finally {
    console.log("end holidays channel");
  }
}

function* fetchAllClassrooms({ firebase }) {
  try {
    let data = JSON.parse(getItem("classList"));
    if (data) {
      yield put({
        type: actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classrooms", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* addNewHoliday({ formValue, firebase, classrooms }) {
  let startDate = moment(formValue.dateRange[0]).startOf("day").valueOf();
  let modStartDate = new Date(startDate);

  let lastDate = moment(formValue.dateRange[1]).endOf("day").valueOf();
  let modEndDate = new Date(lastDate);

  let fromDate = moment(modStartDate).valueOf();
  let endDate = moment(modEndDate).valueOf();

  try {
    let classToBeUpdated = [];
    if (formValue.classrooms.includes("All Classrooms")) {
      // classToBeUpdated.push("All Classrooms");
      classrooms.map((c) => {
        classToBeUpdated.push(c.className);
      });
    } else {
      classToBeUpdated = formValue.classrooms;
    }

    // let key = yield call(HolidayApi.createHolidayNode, firebase);
    let holidayObj = {
      classList: classToBeUpdated,
      endDate: endDate,
      fromDate: fromDate,
      name: formValue.name,
      notifyParents: formValue.notifyParents,
      timestamp: modEndDate.getTime(),
      platform: "web",
      updatedOn: moment().valueOf(),
      updatedBy: firebase.teacher.name,
    };
    let endpoint = "woodlandApi/holiday?centerId=" + firebase.sbDbName;

    let response = yield call(callApi, firebase, "post", endpoint, holidayObj);

    if (response?.body?.code === 200) {
      yield put({
        type: actions.ADD_HOLIDAY_SUCCESSFUL,
      });
    } else {
      throw new Error("Failed to add holiday");
    }
  } catch (err) {
    console.log("failed to add holiday", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* updateExistingHoliday({
  formValue,
  selectedRecord,
  classrooms,
  holidayClassMapArray,
  firebase,
}) {
  try {
    let selectedHoliday = selectedRecord;
    // let allClassMap = holidayClassMapArray;

    let formClassrooms = [];
    if (formValue.classrooms.includes("All Classrooms")) {
      formClassrooms.push("All Classrooms");
      classrooms.map((c) => {
        formClassrooms.push(c.className);
      });
    } else {
      formClassrooms = formValue.classrooms;
    }

    let startDate = moment(formValue.dateRange[0]).startOf("day").valueOf();
    let modStartDate = new Date(startDate);

    let lastDate = moment(formValue.dateRange[1]).endOf("day").valueOf();
    let modEndDate = new Date(lastDate);

    let fromDate = moment(modStartDate).valueOf();
    let endDate = moment(modEndDate).valueOf();

    let id = selectedHoliday.id;
    let holidayObj = {
      classList: formClassrooms,
      endDate: endDate,
      fromDate: fromDate,
      id: id,
      name: formValue.name,
      notifyParents: formValue.notifyParents ? formValue.notifyParents : false,
      timestamp: modEndDate.getTime(),
      platform: "web",
      updatedOn: moment().valueOf(),
      updatedBy: firebase.teacher.name,
    };

    let endpoint = "woodlandApi/holiday?centerId=" + firebase.sbDbName;

    let response = yield call(callApi, firebase, "post", endpoint, holidayObj);

    if (response.body.code == 200) {
      yield put({
        type: actions.UPDATE_HOLIDAY_SUCCESSFUL,
      });
    } else {
      throw new Error("Failed to update holiday");
    }
  } catch (err) {
    console.log("failed to update holiday", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* deleteExistingHoliday({ selectedRecord, holidayClassMapArray, firebase, deleteAll }) {
  try {
    let endpoint = "woodlandApi/holiday/" + selectedRecord.id + "?centerId=" + firebase.sbDbName;

    let response = yield call(callApi, firebase, "delete", endpoint, selectedRecord);
    if (response.body.code == 200) {
      yield put({
        type: actions.DELETE_HOLIDAY_SUCCESS,
      });
    } else {
      throw new Error("failed to delete holiday ");
    }
  } catch (err) {
    console.log("failed to delete holiday ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.HOLIDAY_REQUEST_FAILED,
    });
  }
}

function* sendHolidayNotification(
  className,
  firebase,
  operationType,
  holidayName,
  holidayStart,
  activityId
) {
  let message;
  if (operationType === "add") {
    message =
      "Holiday added: " +
      holidayName +
      " : " +
      moment(new Date(holidayStart)).format("DD[, ]MMMM[, ]YYYY");
  } else if (operationType === "update") {
    message =
      "Holiday updated: " +
      holidayName +
      " : " +
      moment(new Date(holidayStart)).format("DD[, ]MMMM[, ]YYYY");
  }

  let selectedActivity = "Holiday";
  let allStudents = FilterAction.getStudentList(firebase);
  let selectedStudent = allStudents.filter((s) => {
    return s.classroomName === className;
  });

  if (selectedStudent !== undefined && selectedStudent.length > 0) {
    for (let i = 0; i < selectedStudent.length; i++) {
      if (selectedStudent[i].fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          message,
          alertNode,
          selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
          selectedStudent[i].id,
          selectedStudent[i].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[i].fatherUUid !== undefined ||
          selectedStudent[i].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            message,
            alertNode,
            selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent[i].motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[i].motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
          message,
          alertNode,
          selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
          selectedStudent[i].id,
          selectedStudent[i].motherProfileId,
          firebase
        );

        if (
          selectedStudent[i].motherUUid !== undefined ||
          selectedStudent[i].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
            message,
            alertNode,
            selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
            selectedStudent[i].id,
            selectedStudent[i].motherProfileId,
            firebase
          );
        }
      }
    }
  }
}

export default function* rootSaga() {
  yield all([yield takeLatest(actions.GET_ALL_HOLIDAYS, fetchAllHolidays)]);
  yield all([
    yield takeEvery(actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY, fetchAllClassrooms),
    yield takeLatest(actions.ADD_HOLIDAY, addNewHoliday),
    yield takeLatest(actions.UPDATE_HOLIDAY, updateExistingHoliday),
    yield takeLatest(actions.DELETE_HOLIDAY, deleteExistingHoliday),
  ]);
}
