import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import * as FileSaver from "file-saver";
import moment from "moment-timezone";
import ReactPixel from "react-facebook-pixel";
import { all, call, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import * as XLSX from "xlsx";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { FeeApi } from "../../firestore-api/fee";
import { InvoiceApi } from "../../firestore-api/invoice";
import { InvoiceTemplateApi } from "../../firestore-api/invoiceTemplate";
import { NotificationApi } from "../../firestore-api/notification";
import { ProgramApi } from "../../firestore-api/program";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { TagApi } from "../../firestore-api/tag";
import { UserSettingApi } from "../../firestore-api/userSetting";
import { getItem, setItem } from "../../Utility/encryptedStorage";
import FilterAction from "../../Utility/FilterAction";
import { callApi } from "../../Utility/superAgentUntil";
import UserFilterAction from "../../Utility/UserFilterActions";
import actions from "./actions";
const { Parser } = require("json2csv");
const superagent = require("superagent");
import FilterActionInvoice from "../../components/Invoice_New/InvoiceAction";

function* fetchInvoices({ firebase }) {
  try {
    var data = yield call(InvoiceApi.getAllInvoice, firebase);
    if (data) {
      var allData = data;

      if (allData) {
        allData.sort((a, b) => {
          return FilterAction.sortIntegerValue(a, b, "inverseDate");
        });
      }

      yield put({
        type: actions.LIST_INVOICE_SUCCESSFULL,
        invoice: allData,
        singleInvoiceChannel: undefined
      });
    }
    yield fork(fetchTaxRef, firebase);
  } catch (err) {
    console.log("failed to fetch invoices", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchTaxRef(firebase) {
  try {
    let data = yield call(InvoiceApi.getTaxReference, firebase);
    if (data) {
      yield put({
        type: actions.GET_TAX_REF_SUCCESS,
        taxReference: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch tax ref", err);
    bugsnagClient.notify(err);
  }
}

/**code to populate admission number */
// function* updateAdmissionNumber(invoices, firebase) {
//   for (let i = 0; i < invoices.length; i++) {
//     console.log("--- invoice at index ----", invoices[i]);
//     let studentId = invoices[i].studentId;
//     if (studentId) {
//       let studentObj = yield call(StudentApi.getStudentById, studentId, firebase);
//       if (studentObj && studentObj.id) {
//         let invoiceObj = invoices[i];
//         let invoiceId = invoices[i].id;
//         invoiceObj.admissionNumber = studentObj.admissionNumber ? studentObj.admissionNumber : null;
//         console.log("--- invoice object after admission number ----", invoiceObj);
//         // yield call(
//         //   InvoiceApi.updateInvoice,
//         //   invoiceObj,
//         //   studentId,
//         //   invoiceId,
//         //   firebase
//         // );
//       }
//     }
//   }
// }

function* fetchAggregatedInvoices({ firebase }) {
  try {
    var data = yield call(InvoiceApi.getAllAggregatedInvoice, firebase);

    if (data) {
      let creditMap = new Map();
      for (let index in data) {
        if (data[index].studentId && data[index].creditAmt && data[index].creditAmt > 0) {
          creditMap.set(data[index].studentId, data[index].creditAmt);
        }
      }

      yield put({
        type: actions.GET_INVOICE_AGGREGATED_SUCCESSFULL,
        aggregatedInvoice: data,
        creditMap: creditMap,
      });
    }
  } catch (err) {
    console.log("failed to fetch aggregated invoices", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchInvoiceDownloadUrl({ record, firebase, userType }) {
  try {
    let data;

    if (userType && userType.toLowerCase() === "student") {
      if (record.pending === 0) {
        data = yield call(InvoiceApi.getInvoiceReceiptDownloadUrl, record, firebase);
      } else if (record.pending !== 0 && record.paid !== 0) {
        data = yield call(InvoiceApi.getInvoiceReceiptDownloadUrl, record, firebase);
      } else {
        data = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
      }
    } else {
      data = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
    }

    if (data) {
      if (Object.keys(data).length === 0) {
        let response;
        if (firebase.schoolConfig.newPdfAPI) {
          let requestPayload = { id: record.id, studentId: record.studentId, sendEmail: false };
          let url = "woodlandApi/invoiceEmail?centerId=" + firebase.sbDbName;
          response = yield callApi(firebase, "post", url, requestPayload);
        } else {
          response = yield call(
            InvoiceApi.sendEmailOfInvoiceToParent,
            record,
            firebase,
            "noEmail"
          );
        }

        if (response.status && response.status === 200) {
          let newData = yield call(InvoiceApi.getInvoiceDownloadUrl, record, firebase);
          yield put({
            type: actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL,
            invoiceDownloadUrl: newData,
          });
        } else {
          yield put({
            type: actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL,
            invoiceDownloadUrl: data,
          });
        }
      } else {
        yield put({
          type: actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL,
          invoiceDownloadUrl: data,
        });
      }
    }
  } catch (error) {
    console.log("failed to fetch download url", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchReceiptList({ record, firebase }) {
  try {
    let receiptList = yield call(InvoiceApi.getInvoiceReceiptWithName, record, firebase);

    if (receiptList && receiptList.length > 0) {
      yield put({
        type: actions.GET_RECEIPT_LIST_SUCCESS,
        receiptList: receiptList,
      });
    } else {
      let data = yield call(InvoiceApi.getInvoiceReceiptList, record, firebase);

      if (data && data.length > 0) {
        yield put({
          type: actions.GET_RECEIPT_LIST_SUCCESS,
          receiptList: data,
        });
      } else {
        let data = yield call(InvoiceApi.getInvoiceReceiptDownloadUrlAlt, record, firebase);

        if (data && data.length > 0) {
          yield put({
            type: actions.GET_RECEIPT_LIST_SUCCESS,
            receiptList: data,
          });
        } else {
          notification("warning", formatMsg("error.regenerateRecieptPdf"));
          let response;
          if (firebase.schoolConfig.newPdfAPI) {
            let requestPayload = { id: record.id, studentId: record.studentId, sendEmail: false };
            let url = "woodlandApi/receiptEmail?centerId=" + firebase.sbDbName;
            response = yield callApi(firebase, "post", url, requestPayload);
          } else {
            response = yield call(InvoiceApi.generateInvoiceReceipt, record, firebase);
          }

          if (response && response.status && response.status === 200) {
            let refreshedReceiptList = yield call(
              InvoiceApi.getInvoiceReceiptList,
              record,
              firebase
            );

            yield put({
              type: actions.GET_RECEIPT_LIST_SUCCESS,
              receiptList: refreshedReceiptList,
            });
          } else {
            yield put({
              type: actions.GET_RECEIPT_LIST_SUCCESS,
              receiptList: [],
            });
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to fetch receipt list", err);
    bugsnagClient.notify("failed to fetch receipt list --->>>>" + err.message ? err.message : err);
  }
}

function* fetchStudentForInvoice({ firebase }) {
  try {
    let student = firebase.studentsMap;

    var students = [];
    if (student && student.size > 0) {
      students = Array.from(student.values());
    }
    if (students) {
      students = students.filter((std) => {
        return (
          !std.deactivated &&
          (!std.status ||
            (std.status.toLowerCase() !== "inactive" && std.status.toLowerCase() !== "withdrawn" && std.status.toLowerCase() !== "transferred"))
        );
      });

      students = students.sort((a, b) => a.name.localeCompare(b.name));

      yield put({
        type: actions.GET_STUDENT_FOR_INVOICE_SUCCESSFUL,
        studentData: students,
      });

      let data = yield call(ProgramApi.fetchPrograms, firebase);
      if (data) {
        yield put({
          type: actions.GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL,
          invoicePrograms: data,
        });
      }
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function discountOnLineItem(lineItem) {
  if (lineItem && lineItem["discounts"] && lineItem["discounts"].length > 0) return true;
  return false;
}
function getAfterDiscountedValue(lineItem) {
  let amt = lineItem.rate * lineItem.quantity;
  let discountAmt = 0;
  if (discountOnLineItem(lineItem)) {
    discountAmt = FilterActionInvoice.getDiscountedAmt(amt, lineItem["discounts"][0]);
  }

  return FilterAction.getFloorDecimalToTwo(amt - discountAmt);
}

function calculateEquivalentTaxRate(taxRates, singleWeight) {
  // Calculate the weighted sum of tax rates
  taxRates = taxRates.map((tax) => tax.rate);
  const weightedSum = taxRates.reduce((sum, taxRate) => sum + (taxRate * singleWeight), 0);
  // Calculate the equivalent tax rate
  const equivalentTaxRate = weightedSum / singleWeight;
  return equivalentTaxRate;
}


function* addInvoice({
  values,
  firebase,
  rows,
  selectedStudentCheckbox,
  autoChargeChecked,
  invDiscounts,
  invTaxes,
  settleUsingCredit,
  creditMap,
  sendInvoiceEmail,
  invoiceStudentId,
  sendPushNotification,

}) {
  try {
    let tempTemplateList = [];
    // if (selectedFeeTemplates && selectedFeeTemplates.length > 0) {
    //   selectedFeeTemplates.map((ele) => {
    //     tempTemplateList.push({
    //       id: ele.id,
    //       name: ele.name,
    //     });
    //   });
    // }

    let dueDate = moment(values.raisedOn).add(values.dueDate, "days").valueOf();

    let studentIdList = [];
    if (invoiceStudentId) {
      studentIdList.push(invoiceStudentId);
    } else if (selectedStudentCheckbox && selectedStudentCheckbox.length > 0) {
      selectedStudentCheckbox.forEach((ele) => {
        studentIdList.push(ele.id);
      });
    }

    if (rows) {
      rows = rows.map((ele) => ({
        amount: (ele.rate * ele.quantity).toFixed(2),
        rate: ele.rate,
        name: ele.name || "",
        description: ele.description,
        discountType: discountOnLineItem(ele) ? (ele.discounts[0]?.discountType === "Percentage" ? "Percentage" : "Number") : null,
        discountValue: discountOnLineItem(ele) ? ele.discounts[0]?.value : null,
        discountDescription: discountOnLineItem(ele) ? ele.discounts[0]?.name : null,
        discountItemCode: discountOnLineItem(ele) ? ele.discounts[0]?.itemCode : null,
        feeTemplateId: ele.feeTemplateId || null,
        feePlanId: ele.feePlanId || null,
        afterDiscountValue: getAfterDiscountedValue(ele),
        quantity: ele.quantity || 1,
        taxes: ele.taxes || null,
        presetItemId: ele.presetItemId || null,
        type: ele.type || null
      }));
    }
    let obj = {
      dueDate: dueDate,
      studentIds: studentIdList,
      startPeriod:
        values.billingPeriod && values.billingPeriod.length > 0
          ? moment(values.billingPeriod[0]).valueOf()
          : null,
      endPeriod:
        values.billingPeriod && values.billingPeriod.length > 0
          ? moment(values.billingPeriod[1]).valueOf()
          : null,
      invoiceRaiseDate: moment(values.raisedOn).valueOf(),
      lineItems: rows,
      schoolNote: values.schoolNote,
      invoiceNote: values.invoiceNote,
      taxes: invTaxes,
      taxRate: invTaxes && invTaxes.length > 0 ? calculateEquivalentTaxRate(invTaxes, values["subTotal"]) : null,
      taxDescription: invTaxes && invTaxes.length > 0 ? invTaxes.map(obj => obj.name).join(', ') : null,
      discount: invDiscounts && invDiscounts.length > 0 ? invDiscounts[0] : null,
      totalWithoutTax: values.subTotal,
      sendEmail: sendInvoiceEmail,
      feeTemplateList: values["selectedTemplate"] ? [{ "id": values["selectedTemplate"] }] : null,
      useCredit: values.useCredit,
      newInvoice: values["newInvoice"] ? values["newInvoice"] : true,
      sendPushNotification: sendPushNotification
    };

    let url = "woodlandApi/invoice" + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);
    var allStudents = firebase.studentsMap;
    if (response && response.status && response.status === 200) {
      // if (
      //   response.body &&
      //   response.body.response &&
      //   response.body.response.length > 0
      // ) {
      // for (let i = 0; i < response.body.response.length; i++) {
      //   let studentId = response.body.response[i].studentId;
      //   let student = allStudents.get(studentId);
      //   if (student && response.body.response[i].id) {
      //     yield fork(
      //       sendInvoiceNotification,
      //       "Payment",
      //       response.body.response[i].id,
      //       "Payment reminder for",
      //       student,
      //       firebase
      //     );
      //   }
      // }

      yield put({
        type: actions.ADD_NEW_INVOICE_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSettingFailed")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* updateSelectedInvoice({
  values,
  firebase,
  rows,
  studentId,
  invoiceId,
  invDiscounts,
  invTaxes,
  sendInvoiceEmail,
  invoiceToEdit,
  sendPushNotification
}) {
  debugger;
  try {
    if (values.raisedOn && values.dueDate) {
      var dueDate = moment(values.raisedOn).add(values.dueDate, "days").valueOf();
    } else {
      var dueDate = null;
    }
    let billingPeriod =
      values.billingPeriod && values.billingPeriod.length > 0
        ? moment(values.billingPeriod[0]).format("DD[ ]MMMM[ ]YYYY") +
        " - " +
        moment(values.billingPeriod[1]).format("DD[ ]MMMM[ ]YYYY")
        : null;

    let raisedOn = values.raisedOn ? moment(values.raisedOn).valueOf() : null;

    let endPeriod =
      values.billingPeriod && values.billingPeriod.length > 0
        ? moment(values.billingPeriod[1]).valueOf()
        : null;

    let startPeriod =
      values.billingPeriod && values.billingPeriod.length > 0
        ? moment(values.billingPeriod[0]).valueOf()
        : null;

    if (rows) {
      rows = rows.map((ele) => ({
        amount: (ele.rate * ele.quantity).toFixed(2),
        rate: ele.rate,
        name: ele.name || "",
        description: ele.description,
        discountType: discountOnLineItem(ele) ? (ele.discounts[0]?.discountType === "Percentage" ? "Percentage" : "Number") : null,
        discountValue: discountOnLineItem(ele) ? ele.discounts[0]?.value : null,
        discountDescription: discountOnLineItem(ele) ? ele.discounts[0]?.name : null,
        discountItemCode: discountOnLineItem(ele) ? ele.discounts[0]?.itemCode : null,
        feeTemplateId: ele.feeTemplateId || null,
        feePlanId: ele.feePlanId || null,
        afterDiscountValue: getAfterDiscountedValue(ele),
        quantity: ele.quantity || 1,
        taxes: ele.taxes || null,
        presetItemId: ele.presetItemId || null,
        type: ele.type || null
      }));
    }

    let obj = {
      ...invoiceToEdit[0],
      billingPeriod: billingPeriod,
      dueDate: dueDate,
      endPeriod: endPeriod,
      inverseDate: -moment().valueOf(),
      invoiceRaiseDate: raisedOn,
      lineItems: rows,
      pending: values.total,
      startPeriod: startPeriod,
      total: values.total,
      paid: 0,
      feeTemplateList: values["selectedTemplate"] ? [{ "id": values["selectedTemplate"] }] : null,
      schoolNote: values.schoolNote,
      invoiceNote: values.invoiceNote,
      taxes: invTaxes,
      taxRate: invTaxes && invTaxes.length > 0 ? calculateEquivalentTaxRate(invTaxes, values["subTotal"]) : null,
      taxDescription: invTaxes && invTaxes.length > 0 ? invTaxes.map(obj => obj.name).join(', ') : null,
      discount: invDiscounts && invDiscounts.length > 0 ? invDiscounts[0] : null,
      totalWithoutTax: values.subTotal,
      sendEmail: sendInvoiceEmail,
      paymentRecords: [],
      newInvoice: values["newInvoice"] ? values["newInvoice"] : true,
      sendPushNotification: sendPushNotification,
    };

    let url = "woodlandApi/invoice/" + invoiceId + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.UPDATE_SELECTED_INVOICE_SUCCESSFFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateInvoice")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* receivePayment({ values, selectedInvoice, firebase, sendRecordEmail }) {
  try {
    var requestBody = {
      paymentRecords: [],
      sendEmail: false,
    };
    var paymentList = [];

    if (values.amount > 0) {
      paymentList.push({
        amount: values.amount,
        mode: values.mode,
        referenceNumber: values.referenceNumber ? values.referenceNumber : null,
        invoiceId: selectedInvoice.id,
        timestamp: moment(values.paymentDate).valueOf(),
        updatedBy: firebase.teacher.name,
        studentId: selectedInvoice.studentId,
      });
    }

    if (values.inputCreditAmount) {
      paymentList.push({
        amount: values.inputCreditAmount,
        mode: "school credit amount",
        referenceNumber: values.referenceNumber ? values.referenceNumber : null,
        invoiceId: selectedInvoice.id,
        timestamp: moment(values.paymentDate).valueOf(),
        useCredit: true,
        updatedBy: firebase.teacher.name,
        studentId: selectedInvoice.studentId,
        paidByCredit: true
      });
    }

    requestBody.paymentRecords = paymentList;
    requestBody.sendEmail = sendRecordEmail;
    let url = "woodlandApi/payment/" + selectedInvoice.id + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, requestBody, url, firebase);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.RECEIVE_PAYMENT_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addPayment")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* refundPaidAmount({ values, selectedInvoice, firebase }) {
  try {
    let newRefundRecord = [];
    let initialRefund = 0;
    if (selectedInvoice.refundRecords) {
      newRefundRecord = selectedInvoice.refundRecords;
    }

    if (selectedInvoice.totalRefund) {
      initialRefund = selectedInvoice.totalRefund;
    }

    let transactionId = yield call(InvoiceApi.createTransactionUniqueId, firebase);

    newRefundRecord.push({
      afterDiscountValue: 0,
      amount: FilterAction.getFloorDecimalToTwo(values.amount),
      description: "Refunded by " + values.mode + " on",
      discountValue: 0,
      mode: values.mode,
      timestamp: new Date(values.paymentDate).getTime(),
      referenceNumber: values.referenceNumber ? values.referenceNumber : null,
      transactionId: transactionId,
    });
    selectedInvoice.refundRecords = newRefundRecord;
    selectedInvoice.totalRefund = initialRefund + FilterAction.getFloorDecimalToTwo(values.amount);
    selectedInvoice.platform = "web";
    yield call(InvoiceApi.recordPayment, selectedInvoice, firebase);

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        selectedInvoice.studentId,
        undefined,
        firebase,
        "refund",
        values.amount
      );
    }

    var transactionObject = {
      actualTransferAmount: FilterAction.getFloorDecimalToTwo(values.amount) * 100,
      amount: FilterAction.getFloorDecimalToTwo(values.amount) * 100,
      date: new Date(values.paymentDate).getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: selectedInvoice.id,
      paymentMode: values.mode,
      status: "successful",
      studentId: selectedInvoice.studentId,
      userId: firebase.user.id,
    };

    yield fork(InvoiceApi.recordTransaction, transactionObject, transactionId, firebase);

    yield fork(InvoiceApi.sendEmailOfInvoiceReceiptToParent, transactionObject, firebase);

    yield put({
      type: actions.REFUND_AMT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to refund payment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedInvoice({ invoiceRecord, firebase }) {
  try {
    let url =
      "woodlandApi/invoice/" +
      invoiceRecord.id +
      "?studentId=" +
      invoiceRecord.studentId +
      "&centerId=";
    let response = yield call(StudentAttendanceApi.deleteApi, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_INVOICE_SUCCESSFFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSettingFailed")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchOneTimeInvoiceTemplates({ firebase }) {
  try {
    let data = yield call(InvoiceTemplateApi.getOneTimeInvoiceTemplate, firebase);
    if (data) {
      yield put({
        type: actions.GET_INVOICE_TEMPLATES_SUCCESSFUL,
        invoiceTemplates: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch one time invoice templates", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function getInvoiceNumber(invNumber) {
  let i = invNumber.lastIndexOf("-");
  let prefix = invNumber.split("-")[0];
  if (prefix && prefix.length > 0) {
    prefix = prefix + "-";
  } else {
    prefix = "";
  }

  if (i >= 0) {
    return (
      prefix +
      Number(invNumber.substring(i + 1, invNumber.length)).toLocaleString("en-US", {
        minimumIntegerDigits: 8,
        useGrouping: false,
      })
    );
  } else {
    return prefix + invNumber;
  }
}

function* fetchAndDownloadExcelSheet({ formValue, firebase, downloadType }) {
  try {
    let data;
    if (downloadType.toLowerCase() === "billing period") {
      data = yield call(InvoiceApi.getInvoiceByBillingPeriod, formValue.dateRange, firebase);
    } else {
      data = yield call(InvoiceApi.getInvoiceByDateRange, formValue.dateRange, firebase);
    }
    if (data) {
      const fields = [
        "invoiceId",
        "templatesUsed",
        "invoiceRaiseDate",
        "invoiceRaisedBy",
        "dueDate",
        "billingStartDate",
        "billingEndDate",
        "className",
        "studentName",
        "admissionNumber",
        "invoiceNumber",
        "total",
        "mode",
        "receiptNumber",
        "paymentDate",
        "paymentAmount",
        "paymentReferenceNo",
        "pending",
        "schoolNote",
        "invoiceNote",
        "refundAmount",
        "refundMode",
        "refundDate",
        "refundReferenceNo",
        "discountAmount",
      ];
      const opts = { fields };
      let invoices = [];
      let timezone = moment.tz.guess();
      let studentList = FilterAction.getStudentList(firebase);
      data.forEach((i) => {
        var student = firebase.studentsMap.get(i.studentId);

        var paymentRecords = i.paymentRecords ? i.paymentRecords : undefined;
        if (paymentRecords) {
          paymentRecords.forEach(function (record) {
            var row = {};
            row.invoiceId = "inv" + i.id;
            if (i.feeTemplateList) {
              let templateNames = [];
              i.feeTemplateList.forEach((temp) => {
                templateNames.push(temp.name);
              });
              row.templatesUsed = templateNames.toString();
            }

            if (i.invoiceRaiseDate) {
              row.invoiceRaiseDate = moment.tz(i.invoiceRaiseDate, timezone).format("DD-MMM-YY");
            }
            if (i.dueDate) {
              row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MMM-YY");
            }
            row.className = i.classList ? i.classList.toString() : i.classname;
            row.studentName = student ? student.name : i.studentName;
            row.total = i.total;
            row.mode = record.mode;
            row.paymentReferenceNo = record.referenceNumber ? record.referenceNumber : "";
            row.paymentDate = moment.tz(record.timestamp, timezone).format("DD-MMM-YY");
            row.paymentAmount = record.amount;
            row.pending = i.pending;
            row.receiptNumber = record.receiptNumber
              ? // firebase.schoolConfig.receiptPrefix +
              // "-" +
              getInvoiceNumber(record.receiptNumber)
              : "";
            row.invoiceNumber = i.invoiceNumber
              ? // firebase.schoolConfig.invoicePrefix +
              // "-" +
              getInvoiceNumber(i.invoiceNumber)
              : "";
            row.admissionNumber = student && student.admissionNumber ? student.admissionNumber : "";
            // row.billingPeriod = i.billingPeriod;
            row.billingStartDate = moment.tz(i.startPeriod, timezone).format("DD-MMM-YY");
            row.billingEndDate = moment.tz(i.endPeriod, timezone).format("DD-MMM-YY");
            row.invoiceRaisedBy = i.updatedBy
              ? i.updatedBy
              : i.feePlanId
                ? "Fee Plan"
                : i.templateId
                  ? "Fee Template"
                  : "";
            invoices.push(row);
          });

          if (i.refundRecords) {
            let refundRecords = i.refundRecords;
            refundRecords.forEach(function (r) {
              var row = {};
              row.invoiceId = "inv" + i.id;
              if (i.feeTemplateList) {
                let templateNames = [];
                i.feeTemplateList.forEach((temp) => {
                  templateNames.push(temp.name);
                });
                row.templatesUsed = templateNames.toString();
              }

              if (i.invoiceRaiseDate) {
                row.invoiceRaiseDate = moment.tz(i.invoiceRaiseDate, timezone).format("DD-MMM-YY");
              }
              if (i.dueDate) {
                row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MMM-YY");
              }
              row.className = i.classname;
              row.studentName = student ? student.name : i.studentName;
              row.total = i.total;
              row.pending = i.pending;
              row.refundReferenceNo = r.referenceNumber ? r.referenceNumber : "";

              row.receiptNumber = i.receiptNumber
                ? // firebase.schoolConfig.receiptPrefix +
                // "-" +
                getInvoiceNumber(i.receiptNumber)
                : "";
              row.invoiceRaisedBy = i.updatedBy
                ? i.updatedBy
                : i.feePlanId
                  ? "Fee Plan"
                  : i.templateId
                    ? "Fee Template"
                    : "";
              row.refundAmount = r.amount;
              row.refundMode = r.mode;
              row.refundDate = moment.tz(r.timestamp, timezone).format("DD-MMM-YY");

              invoices.push(row);
            });
          }
        } else {
          var row = {};
          row.invoiceId = "inv" + i.id;
          if (i.feeTemplateList) {
            let templateNames = [];
            i.feeTemplateList.forEach((temp) => {
              templateNames.push(temp.name);
            });
            row.templatesUsed = templateNames.toString();
          }

          if (i.lineItems) {
            let discount = 0;
            i.lineItems.map((item) => {
              discount = discount + getDiscountedValueForExcel(item);
            });
            row.discountAmount = discount;
          }

          if (i.invoiceRaiseDate)
            row.invoiceRaiseDate = moment.tz(i.invoiceRaiseDate, timezone).format("DD-MMM-YY");
          if (i.dueDate) {
            row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MMM-YY");
          }
          row.schoolNote = i.schoolNote ? i.schoolNote : "";
          row.invoiceNote = i.invoiceNote ? i.invoiceNote : "";

          row.invoiceNumber = i.invoiceNumber
            ? // firebase.schoolConfig.invoicePrefix +
            // "-" +
            getInvoiceNumber(i.invoiceNumber)
            : "";
          row.billingPeriod = i.billingPeriod;
          row.billingStartDate = moment.tz(i.startPeriod, timezone).format("DD-MMM-YY");
          row.billingEndDate = moment.tz(i.endPeriod, timezone).format("DD-MMM-YY");
          row.admissionNumber = student && student.admissionNumber ? student.admissionNumber : "";
          row.className = i.classList ? i.classList.toString() : i.classname;
          row.studentName = student ? student.name : i.studentName;
          row.total = i.total;
          row.pending = i.pending;
          row.receiptNumber = "N/A";
          row.mode = "N/A";
          row.paymentDate = "N/A";
          row.paymentAmount = "N/A";
          row.invoiceRaisedBy = i.updatedBy
            ? i.updatedBy
            : i.feePlanId
              ? "Fee Plan"
              : i.templateId
                ? "Fee Template"
                : "";
          invoices.push(row);
        }
      });

      // if (i.refundRecords) {
      //   let refundRecords = i.refundRecords;
      //   refundRecords.forEach(function (r) {
      //     row.refundAmount = r.amount;
      //     row.refundMode = r.mode;
      //     row.refundDate = moment.tz(r.timestamp, timezone).format("DD-MM-YY")
      //   })
      // }

      // try {
      //   const parser = new Parser(opts);
      //   const csv = parser.parse(invoices);
      //   console.log(csv);

      //   var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      //   var csvURL = window.URL.createObjectURL(csvData);
      //   var tempLink = document.createElement("a");
      //   tempLink.href = csvURL;
      //   tempLink.setAttribute("download", "invoices.csv");
      //   tempLink.click();
      // } catch (err) {
      //   console.error("failed to parse csv", err);
      // }

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "invoices-" + formValue.dateRange[0] + "-" + formValue.dateRange[1];

      var ws = XLSX.utils.json_to_sheet(invoices, {
        header: fields,
        dateNF: "DD-MMM-YYYY",
      });

      const wb = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const newData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(newData, fileName + fileExtension);

      yield put({
        type: actions.DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL,
      });
    }
  } catch (err) {
    notification("error", formatMsg("error.fetchInvoiceDateRange"));
    console.log("failed to fetch invoice for date range", err);
    bugsnagClient.notify(
      "failed to fetch invoice for date range" + err.message ? err.message : err
    );
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchAndDownloadLineItemExcelSheet({ formValue, firebase, downloadType }) {
  try {
    let data;
    if (downloadType.toLowerCase() === "billing period") {
      data = yield call(InvoiceApi.getInvoiceByBillingPeriod, formValue.dateRange, firebase);
    } else {
      data = yield call(InvoiceApi.getInvoiceByDateRange, formValue.dateRange, firebase);
    }
    if (data) {
      const fields = [
        "invoiceId",
        "templatesUsed",
        "invoiceRaiseDate",
        "invoiceRaisedBy",
        "dueDate",
        "billingStartDate",
        "billingEndDate",
        "className",
        "studentName",
        "admissionNumber",
        "invoiceNumber",
        "lineitemDescription",
        "lineitemAmount",
        "schoolNote",
        "invoiceNote",
        "refundAmount",
        "refundMode",
        "refundDate",
        "refundReferenceNo",
        "discountDescription",
        "discountAmount",
      ];
      const opts = { fields };
      let invoices = [];
      let timezone = moment.tz.guess();
      let studentList = FilterAction.getStudentList(firebase);
      data.forEach((i) => {
        var student = firebase.studentsMap.get(i.studentId);

        if (i.lineItems) {
          i.lineItems.forEach((lit) => {
            var row = {};
            row.invoiceId = "inv" + i.id;
            if (i.feeTemplateList) {
              let templateNames = [];
              i.feeTemplateList.forEach((temp) => {
                templateNames.push(temp.name);
              });
              row.templatesUsed = templateNames.toString();
            }
            row.lineitemDescription = lit.description;
            row.lineitemAmount = lit.afterDiscountValue;
            row.discountDescription = lit.discountDescription;
            row.discountAmount = lit.amount - lit.afterDiscountValue;

            if (i.invoiceRaiseDate)
              row.invoiceRaiseDate = moment.tz(i.invoiceRaiseDate, timezone).format("DD-MMM-YY");
            if (i.dueDate) {
              row.dueDate = moment.tz(i.dueDate, timezone).format("DD-MMM-YY");
            }
            row.schoolNote = i.schoolNote ? i.schoolNote : "";
            row.invoiceNote = i.invoiceNote ? i.invoiceNote : "";

            row.invoiceNumber = i.invoiceNumber
              ? firebase.schoolConfig.invoicePrefix + "-" + getInvoiceNumber(i.invoiceNumber)
              : "";
            row.billingPeriod = i.billingPeriod;
            row.billingStartDate = moment.tz(i.startPeriod, timezone).format("DD-MMM-YY");
            row.billingEndDate = moment.tz(i.endPeriod, timezone).format("DD-MMM-YY");
            row.admissionNumber = student && student.admissionNumber ? student.admissionNumber : "";
            row.className = i.classList ? i.classList.toString() : i.classname;
            row.studentName = student ? student.name : "";
            row.total = i.total;
            row.pending = i.pending;
            row.invoiceRaisedBy = i.updatedBy
              ? i.updatedBy
              : i.feePlanId
                ? "Fee Plan"
                : i.templateId
                  ? "Fee Template"
                  : "";
            invoices.push(row);
          });
        }
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "lineitems-" + formValue.dateRange[0] + "-" + formValue.dateRange[1];

      var ws = XLSX.utils.json_to_sheet(invoices, {
        header: fields,
        dateNF: "DD-MMM-YYYY",
      });

      const wb = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const newData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(newData, fileName + fileExtension);

      yield put({
        type: actions.DOWNLOAD_LINEITEM_EXCEL_SHEET_SUCCESSFUL,
      });
    }
  } catch (err) {
    notification("error", formatMsg("error.fetchInvoiceDateRange"));
    console.log("failed to fetch invoice for date range", err);
    bugsnagClient.notify(
      "failed to fetch invoice for date range" + err.message ? err.message : err
    );
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* editFeeReminder({ firebase }) {
  try {
    let checkDefault = yield call(InvoiceApi.getFeeReminder, firebase);
    if (checkDefault && checkDefault.updatedEmail) {
      yield put({
        type: actions.EDIT_FEE_REMINDER_SUCCESS,
        updatedEmail: checkDefault.updatedEmail,
        defaultEmail: UserFilterAction.getFeeReminderHtml(),
      });
    } else {
      yield put({
        type: actions.EDIT_FEE_REMINDER_SUCCESS,
        updatedEmail: UserFilterAction.getFeeReminderHtml(),
        defaultEmail: UserFilterAction.getFeeReminderHtml(),
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateFeeReminder({ updatedEmail, firebase }) {
  try {
    yield call(InvoiceApi.updateFeeReminderEmail, updatedEmail, firebase);
    notification("success", formatMsg("success.updateEmail"));
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* sendFeePaymentReminder({ email, sms, invoice, firebase }) {
  try {
    var currency = firebase.schoolConfig.currency ? firebase.schoolConfig.currency : "Rs. ";
    let message =
      firebase.schoolName +
      " reminder. Payment of amount " +
      currency +
      ". " +
      invoice.pending +
      " is due.";
    if (firebase.schoolConfig.lateFee !== 0) {
      let lateFeeMessage =
        "Late fee of " +
        currency +
        ". " +
        firebase.schoolConfig.lateFee +
        " is applicable on late payments.";
      message = message + lateFeeMessage;
    }
    let addMessage = "Please install the app for latest update and payment history.";
    if (sms === true) {
      let contact = [];
      if (invoice.fatherNumber) {
        contact.push(invoice.fatherNumber);
      }

      if (invoice.motherNumber) {
        contact.push(invoice.motherNumber);
      }

      if (contact.length > 0) {
        yield fork(NotificationApi.sendReminderMessage, contact, message);
      }
    }

    if (email === true) {
      let parentEmails = [];

      if (invoice.fatherEmail) {
        parentEmails.push(invoice.fatherEmail);
      }

      if (invoice.motherEmail) {
        parentEmails.push(invoice.motherEmail);
      }

      var htmlBody = `<div><p>${message}</p><p>${addMessage}</p></div>`;

      let stdId = invoice.studentId;
      let studentsMap = firebase.studentsMap;
      let stdObj;
      if (studentsMap.has(stdId)) {
        stdObj = studentsMap.get(stdId);
      }
      if (stdObj && stdObj.fatherEmail) {
        parentEmails.push(stdObj.fatherEmail);
      }
      if (stdObj && stdObj.motherEmail) {
        parentEmails.push(stdObj.motherEmail);
      }

      let subject = "";
      subject = "Fee reminder by " + firebase.schoolName + " of amount " + invoice.pending;

      if (stdObj || invoice.studentName) {
        subject =
          subject +
          " for " +
          (invoice.studentName ? invoice.studentName : stdObj ? stdObj.name : "");
      }

      if (parentEmails.length === 0) {
        notification("error", "Parent email missing.");
        return;
      }

      let invoiceReminderObj = [
        {
          additionalCommEmail: stdObj.additionalCommunicationEmails
            ? stdObj.additionalCommunicationEmails
            : undefined,
          studentId: invoice.studentId,
          studentName: invoice.studentName,
          emailIds: parentEmails,
          id: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          pending: invoice.pending,
          dueDate: invoice.dueDate,
        },
      ];

      let obj = {
        list: invoiceReminderObj,
      };

      let endpoint = "woodlandApi/reminder/fee?centerId=";

      yield call(StudentAttendanceApi.requestApi, obj, endpoint, firebase);
    }

    yield put({
      type: actions.SEND_PAYMENT_REMINDER_SUCCESSFUL,
    });

    let allStudents = FilterAction.getStudentList(firebase);
    let studentId = invoice.studentId;
    let filteredStudent = allStudents.filter((s) => {
      return s.id === studentId;
    });

    if (filteredStudent && filteredStudent.length > 0) {
      yield fork(
        sendInvoiceNotification,
        "Payment",
        invoice.id,
        "Payment reminder for",
        filteredStudent[0],
        firebase
      );
    }
  } catch (err) {
    console.log("failed to send fee payment reminder", err);
    bugsnagClient.notify("failed to send fee payment reminder" + err.message ? err.message : err);
  }
}
function* sendFeePaymentReminderToAll({ email, sms, invoiceList, firebase, reminderStatus }) {
  try {
    let newInvoiceList = invoiceList;
    if (reminderStatus?.toLowerCase() === "overdue") {
      newInvoiceList = FilterAction.filterAllOverdue(newInvoiceList);
    }
    for (let i = 0; i < newInvoiceList.length; i++) {
      let invoiceReminderList = [];
      let invoice = newInvoiceList[i];
      if (!invoice.pending || invoice.pending <= 0) {
        continue;
      }
      var currency = firebase.schoolConfig.currency ? firebase.schoolConfig.currency : "Rs. ";
      let message =
        firebase.schoolName +
        " reminder. Payment of amount " +
        currency +
        ". " +
        invoice.pending +
        " is due.";
      if (firebase.schoolConfig.lateFee !== 0) {
        let lateFeeMessage =
          "Late fee of " +
          currency +
          ". " +
          firebase.schoolConfig.lateFee +
          " is applicable on late payments.";
        message = message + lateFeeMessage;
      }
      let addMessage = "Please install the app for latest update and payment history.";
      if (sms === true) {
        let contact = [];
        if (invoice.fatherNumber) {
          contact.push(invoice.fatherNumber);
        }

        if (invoice.motherNumber) {
          contact.push(invoice.motherNumber);
        }

        if (contact.length > 0) {
          yield fork(NotificationApi.sendReminderMessage, contact, message);
        }
      }

      if (email === true) {
        let parentEmails = [];

        if (invoice.fatherEmail) {
          parentEmails.push(invoice.fatherEmail);
        }

        if (invoice.motherEmail) {
          parentEmails.push(invoice.motherEmail);
        }

        var htmlBody = `<div><p>${message}</p><p>${addMessage}</p></div>`;

        let stdId = invoice.studentId;
        let studentsMap = firebase.studentsMap;
        let stdObj;
        if (studentsMap.has(stdId)) {
          stdObj = studentsMap.get(stdId);
        }

        let subject = "";
        subject = "Fee reminder by " + firebase.schoolName + " of amount " + invoice.pending;

        if (stdObj || invoice.studentName) {
          subject =
            subject +
            " for " +
            (invoice.studentName ? invoice.studentName : stdObj ? stdObj.name : "");
        }

        let tempReminderInvoiceObj = {
          additionalCommEmail: stdObj.additionalCommunicationEmails
            ? stdObj.additionalCommunicationEmails
            : undefined,
          studentId: invoice.studentId,
          studentName: invoice.studentName,
          emailIds: parentEmails,
          id: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          pending: invoice.pending,
          dueDate: invoice.dueDate,
        };

        invoiceReminderList.push(tempReminderInvoiceObj);

        var reminderListObj = {
          list: invoiceReminderList,
        };

        let endpoint = "woodlandApi/reminder/fee?centerId=";
        if (parentEmails.length > 0) {
          yield call(StudentAttendanceApi.requestApi, reminderListObj, endpoint, firebase);
        }
      }

      let allStudents = FilterAction.getStudentList(firebase);
      let studentId = invoice.studentId;
      let filteredStudent = allStudents.filter((s) => {
        return s.id === studentId;
      });

      if (filteredStudent && filteredStudent.length > 0) {
        yield fork(
          sendInvoiceNotification,
          "Payment",
          invoice.id,
          "Payment reminder for",
          filteredStudent[0],
          firebase
        );
      }
    }
    yield put({
      type: actions.SEND_PAYMENT_REMINDER_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to send fee payment reminder", err);
    bugsnagClient.notify("failed to send fee payment reminder" + err.message ? err.message : err);
  }
}

function* sendInvoiceNotification(activityN, node, upcomingMessage, selectedStudent, firebase) {
  let selectedActivity = activityN;
  let activityId = node;
  let message = upcomingMessage + " " + selectedStudent.name;

  try {
    if (selectedStudent.fatherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.fatherProfileId,
        firebase
      );

      yield fork(
        NotificationApi.createAlertNotification,
        selectedActivity,
        activityId,
        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
        message,
        alertNode,
        selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
        selectedStudent.id,
        selectedStudent.fatherProfileId,
        firebase
      );

      if (
        selectedStudent.fatherUUid !== undefined ||
        selectedStudent.ios_fatherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          selectedActivity,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          message,
          alertNode,
          selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );
      }
    }

    if (selectedStudent.motherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.motherProfileId,
        firebase
      );

      yield fork(
        NotificationApi.createAlertNotification,
        selectedActivity,
        activityId,
        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
        message,
        alertNode,
        selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
        selectedStudent.id,
        selectedStudent.motherProfileId,
        firebase
      );

      if (
        selectedStudent.motherUUid !== undefined ||
        selectedStudent.ios_motherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          selectedActivity,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          message,
          alertNode,
          selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );
      }
    }
  } catch (err) {
    bugsnagClient.notify("failed to send invoice notification" + err.message ? err.message : err);
  }
}

function* deleteSelectedPaymentRecord({ lineItem, invoice, firebase, addCredit, addDeposit }) {
  try {

    let obj = {
      ...lineItem,
      addCredit: addCredit,
      invoiceId: invoice.id,
      referenceNumber: lineItem.referenceNumber ? lineItem.referenceNumber : "",
      studentId: invoice.studentId,
      addDeposit: addDeposit
    };

    let url = "woodlandApi/payment/" + invoice.id + "?centerId=";
    let response = yield call(StudentAttendanceApi.deleteApiWithObj, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_PAYMENT_RECORD_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSetting")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete payment record", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}
function isValidStatus(std) {
  return (
    !std.deactivated &&
    (!std.status ||
      (std.status.toLowerCase() !== "inactive" && std.status.toLowerCase() !== "withdrawn" && std.status.toLowerCase() !== "transferred"))
  );
}
function* fetchStudentAggregatedInvoice({ startDate, endDate, firebase, initialCall }) {
  try {
    let studentsMap = firebase.studentsMap;

    let data = studentsMap
    // if (startDate && endDate) {
    let aggMap = new Map();
    let finalAggregatedInvoice = [];
    let invoiceTask = [];
    for (const [key, value] of data) {
      let studentId = key;
      if (isValidStatus(value)) {
        let task = call(
          InvoiceApi.getInvoiceByStudentId,
          startDate,
          endDate,
          studentId,
          firebase
        );
        invoiceTask.push(task);

        let aggData = {};
        aggData.studentId = studentId;
        aggData.studentName = value.name;
        aggData.classname = value.classname;
        aggData.classList = value.classList ? value.classList : [];
        aggData.gender = value.gender;
        aggData.fatherNumber = value.fatherNumber;
        aggData.motherNumber = value.motherNumber;
        aggData.pending = 0;
        aggData.paid = 0;
        aggData.total = 0;
        aggMap.set(studentId, aggData);
      }
    }

    let newVal = yield all([invoiceTask]);

    for (let i in newVal[0]) {
      let invoices = newVal[0][i];
      if (invoices && invoices.length > 0) {
        let paid = 0;
        let pending = 0;
        let total = 0;

        for (let ind in invoices) {
          paid =
            paid +
            (invoices[ind].paid && isNaN(invoices[ind].paid) === false ? invoices[ind].paid : 0);
          pending =
            pending + (isNaN(invoices[ind].pending) === false ? invoices[ind].pending : 0);
          total = total + (isNaN(invoices[ind].total) === false ? invoices[ind].total : 0);
        }

        var myData = {};
        myData.studentId = invoices[0].studentId;
        myData.studentName = invoices[0].studentName;
        myData.classname = invoices[0].classname;
        myData.classList = invoices[0].classList ? invoices[0].classList : [];
        myData.gender = invoices[0].gender;
        myData.fatherNumber = invoices[0].fatherNumber;
        myData.motherNumber = invoices[0].motherNumber;
        myData.pending = pending;
        myData.paid = paid;
        myData.total = total;
        finalAggregatedInvoice.push(myData);
        aggMap.set(invoices[0].studentId, undefined);
      }
    }
    // adding data for student not have any invoice
    for (let [key, value] of aggMap) {
      if (value) {
        finalAggregatedInvoice.push(value);
      }
    }

    yield put({
      type: actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS,
      studentAggregatedInvoice: finalAggregatedInvoice,
      studentAggregatedInvoiceChan: null,
      operationType: initialCall,
      creditMap: null,
    });

  } finally {
    console.log("terminating student billing aggregated invoices");
  }
}


function* getTaxRefList({ firebase }) {
  try {
    yield fork(fetchTaxRef, firebase);
  } catch (err) {
    bugsnagClient.notify(err);
  }
}

function* saveInvoiceNotes({
  values,
  firebase,
  invoiceId,
  studentId,
  selectedInvoice,
  sendEmailToParents,
}) {
  try {
    let obj = {
      ...selectedInvoice,
      sendEmail: sendEmailToParents,
      schoolNote: values.schoolNote,
      invoiceNote: values.invoiceNote,
    };

    let url = "woodlandApi/invoice/" + invoiceId + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.SAVE_INVOICE_NOTES_SUCCESS,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateNotes")
      );
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchSingleStudentInvoices({ studentId, firebase }) {
  try {
    yield fork(fetchTaxRef, firebase);
    let data = yield call(
      InvoiceApi.getInvoiceByStudentId,
      undefined,
      undefined,
      studentId,
      firebase
    );
    //let  feePlan =   yield call(FeeApi.getStudentFeePlan, studentId, firebase);

    //yield call(InvoiceApi.getUpcommingInvoice,studentId,firebase,feePlan.id);

    if (data) {
      yield put({
        type: actions.GET_SINGLE_STUDENT_INVOICES_SUCCESS,
        invoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch invoices for a single student", err);
    bugsnagClient.notify(
      "failed to fetch invoices for a single student" + err.message ? err.message : err
    );
  }
}



function* removeAllRefundedAmt({ invoice, firebase }) {
  try {
    let selectedInvoice = JSON.parse(JSON.stringify(invoice));
    selectedInvoice.platform = "web";
    selectedInvoice.refundRecords = [];
    selectedInvoice.totalRefund = 0;
    yield call(InvoiceApi.updateInvoice, selectedInvoice, invoice.studentId, invoice.id, firebase);

    let transactionId;
    if (
      invoice.refundRecords &&
      invoice.refundRecords.length > 0 &&
      invoice.refundRecords[0].transactionId
    ) {
      transactionId = invoice.refundRecords[0].transactionId;
    } else if (
      invoice.paymentRecords &&
      invoice.paymentRecords.length > 0 &&
      invoice.paymentRecords[0].transactionId
    ) {
      transactionId = invoice.paymentRecords[0].transactionId;
    }

    if (transactionId) {
      let transaction = yield call(InvoiceApi.getTransactionById, transactionId, firebase);
      if (transaction && transaction.id) {
        yield fork(InvoiceApi.sendEmailOfInvoiceReceiptToParent, transaction, firebase);
      }
    }

    yield put({
      type: actions.REMOVE_ALL_REFUND_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete all refund items", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* removeRefundedAmt({ record, index, invoice, firebase }) {
  try {
    let selectedInvoice = invoice;
    selectedInvoice.platform = "web";

    let prevRecord = selectedInvoice.refundRecords;
    const currIndex = prevRecord.indexOf(record);
    let filteredPaymentRecord = prevRecord.filter((item, i) => {
      return item.timestamp !== record.timestamp && i !== currIndex;
    });

    selectedInvoice.refundRecords = filteredPaymentRecord;
    selectedInvoice.totalRefund = invoice.totalRefund > 0 ? invoice.totalRefund - record.amount : 0;

    yield call(InvoiceApi.updateInvoice, selectedInvoice, invoice.studentId, invoice.id, firebase);

    let transactionId;
    if (
      selectedInvoice.refundRecords &&
      selectedInvoice.refundRecords.length > 0 &&
      selectedInvoice.refundRecords[0].transactionId
    ) {
      transactionId = selectedInvoice.refundRecords[0].transactionId;
    } else if (
      selectedInvoice.paymentRecords &&
      selectedInvoice.paymentRecords.length > 0 &&
      selectedInvoice.paymentRecords[0].transactionId
    ) {
      transactionId = selectedInvoice.paymentRecords[0].transactionId;
    }

    if (transactionId) {
      let transaction = yield call(InvoiceApi.getTransactionById, transactionId, firebase);
      if (transaction && transaction.id) {
        yield fork(InvoiceApi.sendEmailOfInvoiceReceiptToParent, transaction, firebase);
      }
    }
    yield put({
      type: actions.REMOVE_REFUND_SUCCESS,
    });
  } catch (err) {
    console.log("failed to remove refunded amount", err);
    bugsnagClient.notify(err);
  }
}

function* creditAmount({ amount, students, firebase, approvedBy, reason, sendCreditEmail }) {
  try {
    let studentList = students.map((s) => s.id)
    let requestPayload = {
      students: studentList,
      amount,
      reason,
      sendEmail: sendCreditEmail,
      approvedBy
    }
    let url = "woodlandApi/credit?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", url, requestPayload);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.SAVE_CREDIT_SUCCESS,
      });
    }
  } catch (err) {
    yield put({
      type: actions.SAVE_CREDIT_FAILED,
    });
    console.log("failed to add credit amount to student's aggregated invoice", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentCreditHistory({ studentId, firebase }) {
  try {

    let chan = yield call(InvoiceApi.getCreditHistory, studentId, firebase);
    while (true) {
      const data = yield take(chan);
      if (data) {

        data.sort(function (a, b) {
          var dateA = a.lastUpdatedOn,
            dateB = b.lastUpdatedOn;
          return dateB - dateA;
        });
        let creditAmount = FilterAction.getAmount(data);
        yield put({
          type: actions.GET_CREDIT_HISTORY_SUCCESS,
          creditHistory: data,
          chan,
          creditAmount
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch credit history", err);
    bugsnagClient.notify(err);
  }
}

function* getTotalCreditHistory({ firebase }) {
  try {
    let data = yield call(InvoiceApi.getAllCreditHistory, firebase);
    if (data) {
      data.sort(function (a, b) {
        var dateA = a.lastUpdatedOn,
          dateB = b.lastUpdatedOn;
        return dateB - dateA;
      });

      yield put({
        type: actions.GET_ALL_CREDIT_HISTORY_SUCCESS,
        totalCreditHistory: data,
      });
    }
  } catch (err) {
    console.log("failed to get total credit history", err);
    bugsnagClient.notify(err);
  }
}

function* downloadTotalCreditHistory({ creditHistory, firebase }) {
  try {
    const fields = [
      "id",
      "studentName",
      "description",
      "amountAdded",
      "amountWithdrawn",
      "dateAdded",
      "addedBy",
      "approvedBy",
      "reason",
    ];
    let report = [];
    let studentsMap = firebase.studentsMap;

    for (let index in creditHistory) {
      let singleRecord = creditHistory[index];
      var row = {};
      var student = studentsMap.get(singleRecord.studentId);
      row.studentName = student && student.name ? student.name : "";
      row.description = singleRecord.description;
      row.amountAdded = singleRecord.amountAdded;
      row.id = singleRecord.id;
      row.dateAdded = moment(-singleRecord.inverseDate).format("DD-MMM-YYYY");
      row.amountWithdrawn = singleRecord.amountWithdrawn;
      row.addedBy = singleRecord.lastUpdatedBy;
      row.approvedBy = singleRecord.approvedBy;
      row.reason = singleRecord.reason;
      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "CreditStatement";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    yield put({
      type: actions.DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download credit statement", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOWNLOAD_ALL_CREDIT_HISTORY_FAILED,
    });
  }
}

function* requestCreditRefund({ record, firebase, currentAmount }) {
  try {
    let requestPayload = {
      studentId: record.studentId,
      amount: record.amountAdded,
      mode: "credit",
      reason: record.description,
      refundDate: record.refundDate
    }

    let endPointUrl = "woodlandApi/credit/refund?centerId=" + firebase.sbDbName
    let response = yield callApi(firebase, "post", endPointUrl, requestPayload)
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_CREDIT_SUCCESS,
      });
    }
  } catch (err) {
    yield put({
      type: actions.DELETE_CREDIT_DENIED,
    });
    console.log("failed to delete credit", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentOnlineClassInvoices({ studentId, firebase }) {
  try {
    let data = yield call(
      InvoiceApi.getInvoiceByStudentId,
      undefined,
      undefined,
      studentId,
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS,
        studentOnlineClassInvoice: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student online class invoices", err);
    bugsnagClient.notify(err);
  }
}

function* startTransaction({
  actualTransferAmount,
  amount,
  invoiceId,
  studentId,
  userParentId,
  firebase,
}) {
  try {
    var transactionId = yield call(InvoiceApi.createTransactionUniqueId, firebase);
    var transactionObject = {
      actualTransferAmount: FilterAction.getFloorDecimalToTwo(actualTransferAmount),
      amount: FilterAction.getFloorDecimalToTwo(amount),
      date: new Date().getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: invoiceId,
      paymentMode: "online-stripe",
      status: "pending",
      studentId: studentId,
      userId: userParentId,
    };

    yield call(InvoiceApi.recordTransaction, transactionObject, transactionId, firebase);
    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "finance", moment());
    yield put({
      type: actions.GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS,
      transactionId: transactionId,
    });
  } catch (err) {
    console.log("failed to generate initiate transaction", err);
    bugsnagClient.notify(err);
  }
}

function getDiscountedValue(val) {
  if (val.discountType.toLowerCase() === "number") {
    let finalAmount = val.amount - val.discount;
    return finalAmount;
  } else if (val.discountType.toLowerCase() === "percentage") {
    let finalAmount = val.amount - (val.amount * val.discount) / 100;
    return finalAmount;
  }
}

function getDiscountedValueForExcel(val) {
  if (!val.discountType) {
    return 0;
  } else {
    if (val.discountType.toLowerCase() === "number") {
      let finalAmount = val.discountValue;
      return finalAmount;
    } else if (val.discountType.toLowerCase() === "percentage") {
      let finalAmount = (val.amount * val.discountValue) / 100;
      return finalAmount;
    }
  }
}

function createBillingPeriod(feePlan, firebase) {
  let timezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();
  var startDate = moment.tz(timezone).format("DD/MM/YYYY");
  var endDate;
  var endDateEpoch;
  if (feePlan.frequency == "Monthly") {
    endDate = moment.tz(timezone).add(1, "months").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(1, "months").subtract(1, "days");
  } else if (feePlan.frequency == "Quaterly") {
    endDate = moment.tz(timezone).add(3, "months").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(3, "months").subtract(1, "days");
  } else if (feePlan.frequency == "Quarterly") {
    endDate = moment.tz(timezone).add(3, "months").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(3, "months").subtract(1, "days");
  } else if (feePlan.frequency == "Half Yearly") {
    endDate = moment.tz(timezone).add(6, "months").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(6, "months").subtract(1, "days");
  } else if (feePlan.frequency == "Weekly") {
    endDate = moment.tz(timezone).add(1, "weeks").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(1, "weeks").subtract(1, "days");
  } else if (feePlan.frequency == "Yearly") {
    endDate = moment.tz(timezone).add(1, "years").subtract(1, "days").format("DD/MM/YYYY");
    endDateEpoch = moment.tz(timezone).add(1, "years").subtract(1, "days");
  } else if (feePlan.frequency == "Custom Date") {
    var sequence = feePlan.sequence ? feePlan.sequence + 1 : 0;
    endDate = moment
      .tz(feePlan.generationDates[sequence], timezone)
      .subtract(1, "days")
      .format("DD/MM/YYYY");
    endDateEpoch = moment.tz(feePlan.generationDates[sequence], timezone).subtract(1, "days");
  } else {
    console.log("invalid frequency");
  }

  return {
    startDate: startDate,
    endDate: endDate,
  };
}

function* settleInvoicePayment({ invoice, paymentMode, parentName, firebase }) {
  try {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;

    let studentData = firebase.student;
    let transactionId = yield call(InvoiceApi.createTransactionUniqueId, firebase);
    let selectedInvoice;

    if (invoice.feePlan) {
      let invoiceCounter = yield call(InvoiceApi.getInvoiceCounter, firebase);
      var nodeId = yield call(InvoiceApi.generateInvoiceNode, studentData.id, firebase);

      let rows = invoice.feePlan.feeComponentDetails;
      var newLineItems = [];
      rows.map((item) => {
        newLineItems.push({
          afterDiscountValue: getDiscountedValue(item),
          amount: FilterAction.getFloorDecimalToTwo(item.amount),
          description: item.name,
          discountType: item.discountType === "Percentage" ? "PERCENTAGE" : "NUMBER",
          discountValue: FilterAction.getFloorDecimalToTwo(item.discount),
          timestamp: 0,
        });
      });

      var invoiceObject = {
        billingPeriod:
          createBillingPeriod(invoice.feePlan, firebase).startDate &&
            createBillingPeriod(invoice.feePlan, firebase).endDate
            ? createBillingPeriod(invoice.feePlan, firebase).startDate +
            "-" +
            createBillingPeriod(invoice.feePlan, firebase).endDate
            : null,
        classname: studentData.classroomName
          ? studentData.classroomName
          : studentData.classList
            ? studentData.classList[0]
            : null,
        classList: studentData.classList ? studentData.classList : [],
        dueDate: new Date().getTime(),
        endPeriod: 0,
        fatherEmail: studentData.fatherEmail ? studentData.fatherEmail : null,
        fatherNumber: studentData.fatherNumber ? studentData.fatherNumber : 0,
        gender: studentData.gender,
        id: nodeId,
        ignoreLateFee: false,
        inverseDate: -new Date().getTime(),
        invoiceRaiseDate: new Date().getTime(),
        lateFee: 0,
        lineItems: newLineItems,
        motherEmail: studentData.motherEmail ? studentData.motherEmail : null,
        motherNumber: studentData.motherNumber ? studentData.motherNumber : 0,
        paid: 0,
        pending: FilterAction.getFloorDecimalToTwo(invoice.pending),
        profileImageUrl: studentData.profileImageUrl ? studentData.profileImageUrl : null,
        startPeriod: 0,
        studentId: studentData.id,
        studentName: studentData.name,
        admissionNumber: studentData.admissionNumber ? studentData.admissionNumber : null,
        total: FilterAction.getFloorDecimalToTwo(invoice.pending),
        schoolNote: null,
        feeTemplateList: null,
        invoiceNumber:
          firebase.schoolConfig && firebase.schoolConfig.invoicePrefix
            ? (firebase.schoolConfig.invoicePrefix + "-" + (invoiceCounter + 1)).toString()
            : null,
        platform: "web",
        // updatedBy: parentName ? parentName : null,
        updatedOn: new Date().getTime(),
      };

      selectedInvoice = invoiceObject;

      yield call(InvoiceApi.addNewInvoice, invoiceObject, studentData, nodeId, firebase);

      yield call(InvoiceApi.incrementCounter, invoiceCounter, firebase);
      let aggregatedInvoice = yield call(InvoiceApi.getAggregatedInvoice, studentData.id, firebase);

      if (aggregatedInvoice !== false) {
        yield call(
          InvoiceApi.updatedAmountToAggregatedInvoice,
          aggregatedInvoice,
          studentData.id,
          invoice.pending,
          firebase,
          "addInvoice",
          receivePayment
        );
      } else {
        yield call(
          InvoiceApi.addAmountToNewAggregatedInvoice,
          studentData,
          nodeId,
          invoice.pending,
          firebase
        );
      }

      //update student to fee plan
      yield fork(
        assignStudentToFeePlan,
        [studentData],
        invoice.feePlan,
        invoice.feePlan.feeComponentDetails,
        firebase
      );
    } else {
      selectedInvoice = JSON.parse(JSON.stringify(invoice));
    }

    let inputAmt = JSON.parse(JSON.stringify(FilterAction.getFloorDecimalToTwo(selectedInvoice.pending)));
    let newPaymentRecord = [];
    if (selectedInvoice.paymentRecords) {
      newPaymentRecord = selectedInvoice.paymentRecords;
    }

    newPaymentRecord.push({
      afterDiscountValue: 0,
      amount: FilterAction.getFloorDecimalToTwo(inputAmt),
      description: "Paid online on ",
      discountValue: 0,
      mode: "Online",
      timestamp: new Date().getTime(),
      transactionId: transactionId,
    });

    selectedInvoice.paid = FilterAction.getFloorDecimalToTwo((selectedInvoice.paid ? selectedInvoice.paid : 0) + inputAmt);
    selectedInvoice.pending = Number(0);
    selectedInvoice.paymentRecords = newPaymentRecord;
    selectedInvoice.platform = "web";
    // selectedInvoice.updatedBy = parentName;
    selectedInvoice.updatedOn = new Date().getTime();
    yield call(InvoiceApi.recordPayment, selectedInvoice, firebase);

    yield put({
      type: actions.SETTLE_PAYMENT_SUCCESS,
    });

    let aggregatedInvoice = yield call(
      InvoiceApi.getAggregatedInvoice,
      selectedInvoice.studentId,
      firebase
    );
    if (aggregatedInvoice) {
      yield call(
        InvoiceApi.updatedAmountToAggregatedInvoice,
        aggregatedInvoice,
        selectedInvoice.studentId,
        undefined,
        firebase,
        "receivePayment",
        inputAmt
      );
    }

    var transactionObject = {
      // actualTransferAmount: Number(selectedInvoice.pending) * 100,
      actualTransferAmount: FilterAction.getFloorDecimalToTwo(inputAmt) * 100,
      amount: FilterAction.getFloorDecimalToTwo(inputAmt) * 100,
      date: new Date().getTime(),
      errorCode: 0,
      id: transactionId,
      invoiceId: selectedInvoice.id,
      paymentMode: paymentMode,
      status: "successful",
      studentId: selectedInvoice.studentId,
      userId: parentName,
    };

    yield fork(InvoiceApi.recordTransaction, transactionObject, transactionId, firebase);

    yield fork(InvoiceApi.sendEmailOfInvoiceReceiptToParent, transactionObject, firebase);

    if (invoice.feePlan) {
      //pixel purchase
      ReactPixel.init("2786923028084527");
      ReactPixel.track("IlluminePurchase");

      //class, address & status of  student
      studentData.status = "Active";
      studentData.updatedBy = parentName
        ? parentName
        : firebase.teacher
          ? firebase.teacher.name
          : "";
      studentData.updatedOn = new Date().getTime();
      studentData.address = invoice.address ? invoice.address : "";

      {
        /**check if student object has activity id. Fetch and update if recurring*/
      }
      if (studentData.activityId) {
        let activityId = studentData.activityId;
        let activity = yield call(ActivityApi.getActivityById, activityId, firebase);
        if (activity && activity.id) {
          if (activity.studentIds && !activity.studentIds.includes(studentData.id)) {
            activity.studentIds.push(studentData.id);
          } else {
            activity.studentIds = [studentData.id];
          }

          //update activity node
          rsf.ref(branchPath + "/activities/" + activity.id).update({
            studentIds: activity.studentIds,
          });

          let repeatVirtualClass =
            activity.repeatStartDate > 0 && activity.repeatEndDate > 0 ? true : false;
          if (repeatVirtualClass) {
            let repeatClassId;
            if (activity.parentActivityId) {
              repeatClassId = activity.parentActivityId;
            } else {
              repeatClassId = activity.id;
            }

            if (repeatClassId) {
              let repeatClass = yield call(ActivityApi.getRepeatClassById, repeatClassId, firebase);
              if (repeatClass && repeatClass.id) {
                repeatClass.updatedBy = firebase.teacher.name;
                repeatClass.updatedOn = new Date().getTime();
                repeatClass.studentIds = activity.studentIds ? activity.studentIds : [];
                yield call(ActivityApi.updateIndRepeatClass, repeatClass, firebase);
              }
            }
          }

          if (activity.classNames && activity.classNames.length > 0) {
            studentData.classroomName = activity.classNames[0];
            studentData.classList = activity.classNames;
          }
        }
      }

      firebase.student = studentData;
      setItem("student", JSON.stringify(studentData));
      yield fork(StudentApi.updateStudentWithUpdatedFormFields, studentData, firebase);
      callStatusChangeWebHook(studentData, firebase, "ENROLLED");
      yield fork(addGroupToStudent, firebase);
    }
    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "finance", moment());
  } catch (err) {
    console.log("failed to settle invoice payment", err);
    bugsnagClient.notify(err);
  }
}

function* addGroupToStudent(firebase) {
  try {
    let student = firebase.student;
    if (student.meetingDate) {
      let meetingTime = student.meetingDate;
      let timeString = moment(meetingTime).format("ha");

      let groups = JSON.parse(getItem("groupList"));
      if (groups) {
        for (let index in groups) {
          let group = groups[index];
          if (group.name.toLowerCase().includes(timeString.toLowerCase())) {
            let studentIds = [];
            if (group.studentIds) {
              studentIds = [...studentIds, ...group.studentIds];
            }
            studentIds.push(student.id);
            yield call(TagApi.assignStudentToTag, group.id, [...new Set(studentIds)], firebase);

            let studentTag = student.tags ? student.tags : [];
            studentTag.push({
              id: group.id,
              name: group.name,
            });
            student.tags = studentTag;
            firebase.student = student;
            setItem("student", JSON.stringify(student));
            yield fork(StudentApi.updateStudentWithUpdatedFormFields, student, firebase);
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to add groups to student detail", err);
    bugsnagClient.notify(err);
  }
}

function* assignStudentToFeePlan(selectedStudentCheckbox, selectedPlan, rows, firebase) {
  try {
    let studentList = selectedStudentCheckbox;
    var studentArray = [];
    if (selectedPlan.student !== undefined) {
      studentArray = selectedPlan.student;
    }

    for (let index in studentList) {
      let filterVal = studentArray.filter((i) => {
        return i.studentId === studentList[index].id;
      });

      if (filterVal === undefined || filterVal.length === 0) {
        studentArray.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment().startOf("day").valueOf(),
          endDate: moment().add(1, "year").valueOf(),
          classroomName: studentList[index].classroomName,
          classList: studentList[index].classList ? studentList[index].classList : [],
          active: true,
        });
      }
    }

    let presentFeePlan = JSON.parse(JSON.stringify(selectedPlan));
    presentFeePlan.student = studentArray;

    presentFeePlan.feeComponentDetails = null;

    yield call(FeeApi.assignStudentsToFeePlan, presentFeePlan, firebase);

    let lineItems = [];
    for (let ind in rows) {
      lineItems.push({
        amount: FilterAction.getFloorDecimalToTwo(rows[ind].amount),
        discount: FilterAction.getFloorDecimalToTwo(rows[ind].discount),
        discountType: rows[ind].discountType,
        id: rows[ind].id,
        inverseDate: -new Date(),
        isRefundable: rows[ind].isRefundable,
        name: rows[ind].name,
        paymentFrequency: rows[ind].paymentFrequency,
        paymentSchedule: rows[ind].paymentSchedule,
      });
    }

    presentFeePlan.studentFeeComponent = lineItems;
    for (let i in studentList) {
      yield call(FeeApi.updateStudentFeePlan, studentList[i].id, presentFeePlan, firebase);
    }
  } catch (error) {
    console.log("failed to assign trial student to fee plan", error);
    bugsnagClient.notify(error);
  }
}

async function callStatusChangeWebHook(student, firebase, event) {
  if (firebase.dbName == "GetReadyEdu_Master-Branch") {
    var studentBody = {};
    let parentEmail;
    let parentName;
    let parentNumber;
    if (student.fatherProfileId) {
      parentEmail = student.fatherEmail ? student.fatherEmail : undefined;
      parentName = student.fatherName;
      parentNumber = student.fatherNumber;
    } else if (student.motherProfileId) {
      parentEmail = student.motherEmail ? student.motherEmail : undefined;
      parentName = student.motherName;
      parentNumber = student.motherNumber;
    }
    studentBody.studentId = student.id;
    studentBody.studentName = student.name;
    studentBody.event = event;
    studentBody.enrolledDate = moment().valueOf();
    studentBody.email = parentEmail;
    studentBody.phoneNumber = parentNumber;
    studentBody.parentName = parentName;
    studentBody.meetingTime = student.meetingDate ? student.meetingDate : undefined;
    studentBody.classroomName = student.classroomName;
    // studentBody.teacherName = firebase.teacher ? firebase.teacher.name : undefined;
    studentBody.birthDate = student.birthDate;
    studentBody.timezone = moment.tz.guess();
    let endPoint = "https://hook.integromat.com/fsqrbavgku7hmtabdnx9rxy959lhhmox";
    let authToken = await UserSettingApi.getAuthToken(firebase);
    var p1 = new Promise(function (resolve, reject) {
      superagent
        .post(endPoint)
        .send(FilterAction.getSuperagentBody(studentBody, firebase))
        .set("accept", "json")
        .set({
          Authorization: "Bearer " + authToken,
        })
        .end((err, res) => {
          console.log("error status change -----", err);
          if (err) {
            bugsnagClient.notify(err);
          }
          console.log("res status change -----", res);
          resolve(res);
        });
    });
    return p1;
  }
}

function* recordFailedPayment({ invoice, parentName, firebase }) {
  try {
    if (invoice.id) {
      let transactionId = yield call(InvoiceApi.createTransactionUniqueId, firebase);
      let selectedInvoice = JSON.parse(JSON.stringify(invoice));
      let inputAmt = JSON.parse(JSON.stringify(FilterAction.getFloorDecimalToTwo(selectedInvoice.pending)));

      var transactionObject = {
        actualTransferAmount: FilterAction.getFloorDecimalToTwo(inputAmt) * 100,
        amount: FilterAction.getFloorDecimalToTwo(inputAmt) * 100,
        date: new Date().getTime(),
        errorCode: 0,
        errorResponse: "Payment Cancelled",
        id: transactionId,
        invoiceId: selectedInvoice.id,
        status: "pending",
        studentId: selectedInvoice.studentId,
        userId: parentName,
      };

      yield fork(InvoiceApi.recordTransaction, transactionObject, transactionId, firebase);
    }

    yield put({
      type: actions.UPDATED_FAILED_PAYMENT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update failed payment status", err);
    bugsnagClient.notify(err);
  }
}

function* getOnlineClassPlanDetail({ feePlanId, firebase }) {
  try {
    let data = yield call(FeeApi.getSelectedFeePlanDetailById, feePlanId, firebase);
    if (data) {
      if (data && data.id && data.feeComponent) {
        let feeComponents = data.feeComponent;
        let feeComponentDetails = [];
        let total = 0;
        for (let index in feeComponents) {
          let val = yield call(FeeApi.getAssignedFeeComponentById, feeComponents[index], firebase);
          if (val && val.id) {
            feeComponentDetails.push(val);
            if (val.discountType.toLowerCase() === "number") {
              let finalAmount = val.amount - val.discount;
              total = total + finalAmount;
            } else if (val.discountType.toLowerCase() === "percentage") {
              let finalAmount = val.amount - (val.amount * val.discount) / 100;
              total = total + finalAmount;
            }
          }
        }

        data.feeComponentDetails = feeComponentDetails;
        data.pending = total;

        yield put({
          type: actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS,
          feePlanDetail: data,
        });
      } else {
        yield put({
          type: actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS,
          feePlanDetail: undefined,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch online class plan detail", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* saveParentCardDetails({ record, firebase, country }) {
  try {
    if (record.studentId) {
      yield call(InvoiceApi.saveCardDetails, record, firebase, country);
    }
  } catch (err) {
    console.log("failed to save card details", err);
    bugsnagClient.notify(err);
  }
}

function* fetchSavedCardDetail({ studentId, firebase }) {
  try {
    let data = yield call(InvoiceApi.getCardDetails, studentId, firebase);
    if (data && data.cardHolderName) {
      yield put({
        type: actions.FETCH_CARD_DETAILS_SUCCESS,
        savedCardDetail: data,
      });
    } else {
      yield put({
        type: actions.FETCH_CARD_DETAILS_SUCCESS,
        savedCardDetail: undefined,
      });
    }
  } catch (err) {
    console.log("failed to fetch saved card details", err);
    bugsnagClient.notify(err);
  }
}

function* autoChargeInvoicePayment({ invoice, firebase }) {
  try {
    let response = yield call(NotificationApi.callAutoChargePaymentApi, invoice, firebase);
    if (response && response.status === 200) {
      yield put({
        type: actions.AUTO_CHARGE_PAYMENT_SUCCESS,
      });
    } else {
      notification("error", formatMsg("error.autoPayment"));
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to auto charge payment", err);
    bugsnagClient.notify(err);
  }
}

function* getStudentsFeePlan({ firebase }) {
  try {
    yield fork(fetchTaxRef, firebase);
    if (
      firebase.schoolConfig &&
      firebase.schoolConfig.billingMode &&
      firebase.schoolConfig.billingMode === "Fee Plan"
    ) {
      let students = FilterAction.getStudentList(firebase);
      if (students && students.length > 0) {
        let activeStudents = students.filter((std) => {
          return std.status && std.status.toLowerCase() === "active";
        });

        let studentFeePlanMap = new Map();
        let feePlanTask = new Map();
        for (let index in activeStudents) {
          let studentId = activeStudents[index].id;
          let task = call(FeeApi.getStudentFeePlan, studentId, firebase);
          feePlanTask.set(studentId, task);
        }

        for (let [key, value] of feePlanTask) {
          let newVal = yield all([value]);
          let feePlan = newVal[0];
          if (feePlan && feePlan.length > 0) {
            studentFeePlanMap.set(key, feePlan);
          } else {
            studentFeePlanMap.set(key, undefined);
          }
        }

        yield put({
          type: actions.GET_STUDENTS_FEE_PLAN_SUCCESS,
          studentFeePlanMap: studentFeePlanMap,
        });
      }
    }
  } catch (err) {
    console.log("failed to fetch students fee plan", err);
    bugsnagClient.notify(err);
  }
}

function* refreshPdfRequest({ invoice, firebase }) {
  try {
    if (firebase.schoolConfig.newPdfAPI) {
      let requestPayload = { id: invoice.id, studentId: invoice.studentId, sendEmail: false };
      if (invoice.paid && invoice.paid > 0) {
        let url = "woodlandApi/receiptEmail?centerId=" + firebase.sbDbName;
        yield callApi(firebase, "post", url, requestPayload);
      }


      let pdfUrl = "woodlandApi/invoiceEmail?centerId=" + firebase.sbDbName;
      yield callApi(firebase, "post", pdfUrl, requestPayload);

    } else {
      yield fork(InvoiceApi.sendEmailOfInvoiceToParent, invoice, firebase, "sendNoEmail");

      if (invoice.pending === 0) {
        yield fork(InvoiceApi.generateInvoiceReceipt, invoice, firebase);
      }
    }

    yield put({
      type: actions.REFRESH_PDF_SUCCESS,
    });
  } catch (err) {
    console.log("failed to refresh pdf", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchCreditPdf({ record, firebase }) {
  try {
    let pdf = yield call(InvoiceApi.getCreditPdf, record.id, firebase);
    // console.log("pdf ------", pdf);
    if (pdf && pdf.pdfUrl) {
      yield put({
        type: actions.GET_CREDIT_PDF_SUCCESS,
        creditPdf: pdf,
      });
    } else {
      notification("error", formatMsg("error.noPdf"));
    }
  } catch (err) {
    console.log("failed to fetch credit pdf", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* fetchDepositPdf({ record, firebase }) {
  try {
    let pdf = yield call(InvoiceApi.getDepositPdf, record.id, firebase);

    if (pdf && pdf.pdfUrl) {
      yield put({
        type: actions.GET_DEPOSIT_PDF_SUCCESS,
        depositPdf: pdf,
      });
    } else {
      notification("error", formatMsg("error.noPdf"));
    }
  } catch (err) {
    console.log("failed to fetch deposit pdf", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}

function* depositAmount({ amount, students, firebase, reason, sendDepositEmail, approvedBy }) {
  try {
    let studentList = students.map((s) => s.id)
    let requestPayload = {
      students: studentList,
      amount,
      reason,
      sendEmail: sendDepositEmail,
      approvedBy
    }
    let url = "woodlandApi/deposit?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", url, requestPayload);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.SAVE_DEPOSIT_SUCCESS,
      });
    }
  } catch (err) {
    yield put({
      type: actions.SAVE_DEPOSIT_FAILED,
    });
    console.log("failed to deposit amount", err);
    bugsnagClient.notify(err);
  }
}

function* getTotalDepositHistory({ firebase }) {
  try {
    let data = yield call(InvoiceApi.getAllDepositHistory, firebase);
    if (data) {
      data.sort(function (a, b) {
        var dateA = a.lastUpdatedOn,
          dateB = b.lastUpdatedOn;
        return dateB - dateA;
      });

      yield put({
        type: actions.GET_ALL_DEPOSIT_HISTORY_SUCCESS,
        totalDepositHistory: data,
      });
    }
  } catch (err) {
    console.log("failed to get total deposit history", err);
    bugsnagClient.notify(err);
  }
}

function* downloadTotalDepositHistory({ depositHistory, firebase }) {
  try {
    const fields = [
      "id",
      "studentName",
      "transaction",
      "amountAdded",
      "amountWithdrawn",
      "dateAdded",
      "addedBy",
      "description",
    ];
    let report = [];
    for (let index in depositHistory) {
      let singleRecord = depositHistory[index];
      var row = {};
      var student = firebase.studentsMap.get(singleRecord.studentId);
      row.studentName = student.name;
      row.transaction = singleRecord.description;
      row.amountAdded = singleRecord.amountAdded;
      row.id = singleRecord.id;
      row.dateAdded = moment(-singleRecord.inverseDate).format("DD-MMM-YYYY");
      row.amountWithdrawn = singleRecord.amountWithdrawn;
      row.addedBy = singleRecord.lastUpdatedBy;
      row.description = singleRecord.reason;
      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "DepositStatement";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    yield put({
      type: actions.DOWNLOAD_ALL_DEPOSIT_HISTORY_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download deposit statement", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOWNLOAD_ALL_DEPOSIT_HISTORY_FAILED,
    });
  }
}

function* getStudentDepositHistory({ studentId, firebase }) {
  try {
    let chan = yield call(InvoiceApi.getDepositHistory, studentId, firebase);
    while (true) {
      const data = yield take(chan);
      if (data) {
        data.sort(function (a, b) {
          var dateA = a.lastUpdatedOn,
            dateB = b.lastUpdatedOn;
          return dateB - dateA;
        });
        let depositAmount = FilterAction.getAmount(data);

        yield put({
          type: actions.GET_DEPOSIT_HISTORY_SUCCESS,
          depositHistory: data,
          chan,
          depositAmount
        });
      }
    }

  } catch (err) {
    console.log("failed to fetch credit history", err);
    bugsnagClient.notify(err);
  }
}

function* refundDepositedAmount({ record, firebase, currentDepositAmount }) {
  try {
    let requestPayload = {
      studentId: record.studentId,
      amount: record.amountAdded,
      mode: "deposit",
      reason: record.description,
      refundDate: record.refundDate
    }

    let endPointUrl = "woodlandApi/deposit/refund?centerId=" + firebase.sbDbName
    let response = yield callApi(firebase, "post", endPointUrl, requestPayload)
    if (response.status == 200) {
      yield put({
        type: actions.REFUND_DEPOSIT_SUCCESS,
      });
    }
  } catch (err) {
    yield put({
      type: actions.DEPOSIT_REQUEST_FAILED,
    });
    console.log("failed to refund deposited amount", err);
    bugsnagClient.notify(err);
  }
}

function* transferDepositToCredit({ record, firebase, currentDepositAmount }) {
  try {
    if (currentDepositAmount < Number(record.amountAdded)) {
      notification("error", formatMsg("error.depositRecordMoreThanDeposited"));
      return;
    }
    let requestPayload = {
      amount: record.amountAdded,
      studentId: record.studentId,
      sendEmail: record.sendEmail,
      reason: record.description
    }
    let url = "woodlandApi/deposit/transfer?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", url, requestPayload);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.TRANSFER_CREDIT_SUCCESS,
      });
    }
  } catch (err) {
    notification("error", formatMsg("error.errorOccured"))
    yield put({
      type: actions.TRANSFER_CREDIT_FAILED,
    });
    console.log("failed to transfer deposited amount", err);
    bugsnagClient.notify(err);
  }
}
function* fetchPayment({ studentId, firebase }) {
  try {
    let data = yield call(InvoiceApi.getPaymentMethod, studentId, firebase);
    // let data = [
    //   {
    //     id: "pm_1LaxBq2eZvKYlo2C2tNB6yxR",
    //     object: "payment_method",
    //     billing_details: {
    //       address: {
    //         city: null,
    //         country: "CN",
    //         line1: null,
    //         line2: null,
    //         postal_code: null,
    //         state: null,
    //       },
    //       email: "jenny@example.com",
    //       name: null,
    //       phone: "+15555555555",
    //     },
    //     card: {
    //       brand: "visa",
    //       checks: {
    //         address_line1_check: null,
    //         address_postal_code_check: null,
    //         cvc_check: "pass",
    //       },
    //       country: "US",
    //       exp_month: 8,
    //       exp_year: 2023,
    //       fingerprint: "Xt5EWLLDS7FJjR1c",
    //       funding: "credit",
    //       generated_from: null,
    //       last4: "4242",
    //       networks: {
    //         available: ["visa"],
    //         preferred: null,
    //       },
    //       three_d_secure_usage: {
    //         supported: true,
    //       },
    //       wallet: null,
    //     },
    //     created: 123456789,
    //     customer: null,
    //     livemode: false,
    //     metadata: {
    //       order_id: "123456789",
    //     },
    //     redaction: null,
    //     type: "card",
    //   },
    // ];
    if (data) {
      yield put({
        type: actions.GET_PAYMENT_METHOD_SUCCESS,
        paymentMethods: data,
      });
    }
  } catch (err) {
    console.log("failed to payment Methods", err);
    bugsnagClient.notify(err);
  }
}

function* settleInvoiceUsingDeposit({ deposit, invoice, firebase, currentDepositAmount, sendEmail }) {
  try {
    if (FilterAction.getFloorDecimalToTwo(currentDepositAmount) < FilterAction.getFloorDecimalToTwo(deposit.amountAdded)) {
      notification("error", formatMsg("error.errorOccured"))
      return;
    }
    let requestPayload = {
      sendEmail,
      invoiceId: invoice.id,
      studentId: deposit.studentId,
      amount: deposit.amountAdded,
    }
    let endPointUrl = "woodlandApi/deposit/settle?centerId=" + firebase.sbDbName
    let response = yield callApi(firebase, "post", endPointUrl, requestPayload)
    if (response.status == 200) {
      yield put({
        type: actions.SETTLE_INVOICE_WITH_DEPOSIT_SUCCESS,
      });
    }
  } catch (err) {
    console.log("failed to settle invoice using deposit", err);
    yield put({
      type: actions.DEPOSIT_REQUEST_FAILED,
    });
    bugsnagClient.notify(err);
  }
}
function* getUpcommingInvoice({ firebase, studentId, feePlanId }) {
  try {
    yield put({
      type: actions.RESET_UPCOMMING_INVOICE,
    });
    if (!feePlanId) return;
    for (let i = 0; i < feePlanId.length; i++) {
      let data = yield call(InvoiceApi.getUpcommingInvoice, studentId, firebase, feePlanId[i].id);
      if (data.length > 0) {
        let withPlanName = data.map((inv) => ({
          ...inv,
          planName: feePlanId[i].planName,
          repeatType: feePlanId[i].frequency,
        }));
        yield put({
          type: actions.GET_UPCOMMING_INVOICE_SUCCESS,
          data: withPlanName,
        });
      }
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.INVOICE_REQUEST_FAILED,
    });
  }
}
function* deleteCreditDeposit({ record, deleteType, studentId, firebase, currentAmount }) {
  try {

    let requestPayload = { mode: deleteType, studentId, id: record.id }
    let endPointUrl = deleteType === "credit" ? "woodlandApi/credit?centerId=" + firebase.sbDbName : "woodlandApi/deposit?centerId=" + firebase.sbDbName
    let response = yield callApi(firebase, "delete", endPointUrl, requestPayload)
    if (response.status == 200) {
      yield put({
        type: actions.DELETE_CREDIT_DEPOSIT_SUCCESS,
        data: deleteType === "credit" ? "CREDIT_DELETE" : "DEPOSIT_DELETE",
      });
    }
  } catch (error) {
    console.log("failed to delete credit or deposit", error);
    notification("error", formatMsg("error.errorOccured"))
    yield put({
      type: actions.DELETE_CREDIT_DEPOSIT_FAILURE,
      data: deleteType === "credit" ? "CREDIT_DELETE" : "DEPOSIT_DELETE",
    });
    bugsnagClient.notify(error);
  }
}

function* ignoreInvoiceLateFee({ firebase, invoice }) {
  try {
    let obj = {
      ...invoice,
      ignoreLateFee: true,
    };

    let url = "woodlandApi/invoice/" + invoice.id + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    if (response && response.status && response.status === 200) {
      notification("success", "Late fee ignored successfully");
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : "Failed to update invoice"
      );
    }
    yield put({
      type: actions.IGNORE_LATE_FEE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);

    yield put({
      type: actions.IGNORE_LATE_FEE_FAILURE,
    });
  }
}

function* getFormInvoiceData({ invoiceId, studentId, firebase }) {
  let channel = yield call(InvoiceApi.getInvoiceById, studentId, invoiceId, firebase);
  try {
    while (true) {
      let data = yield take(channel);
      if (data && data.length > 0) {
        yield put({
          type: actions.LIST_INVOICE_SUCCESSFULL,
          invoice: data,
          singleInvoiceChannel: channel
        });
      }
      yield fork(fetchTaxRef, firebase);
    }

  } finally {
    console.log("successful");
  }


}

function* resendEmailInvoice({ obj, firebase }) {
  try {
    let newObj = {
      ...obj,
      sendEmail: true,
    };

    if (firebase.schoolConfig.newPdfAPI) {
      let requestPayload = { id: obj.id, studentId: obj.studentId, sendEmail: true };
      let url = "woodlandApi/invoiceEmail?centerId=" + firebase.sbDbName;
      let response = yield callApi(firebase, "post", url, requestPayload);
      if (response && response.status && response.status === 200) {
        notification("success", "Invoice pdf email Sent");
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to send email"
        );
      }

      if (obj.paid && obj.paid > 0) {
        let requestPayload = { id: obj.id, studentId: obj.studentId, sendEmail: true };
        let url = "woodlandApi/receiptEmail?centerId=" + firebase.sbDbName;
        let response = yield callApi(firebase, "post", url, requestPayload);
        if (response && response.status && response.status === 200) {
          notification("success", "Receipt email Sent");
        } else {
          notification(
            "error",
            response && response.body && response.body.response
              ? response.body.response
              : "Failed to send email"
          );
        }
      }
    } else {

      let url = "woodlandApi/invoice/" + newObj.id + "?centerId=";
      let response = yield call(StudentAttendanceApi.requestApi, newObj, url, firebase);
      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.RESEND_INVOICE_EMAIL_SUCCESS,
        });
        notification("success", "Email Sent");
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to send email"
        );
      }
    }
  } catch (err) {
    bugsnagClient.notify(err);
  }
}
function* fetchInvoiceDashboardStats({ start, end, firebase, category }) {
  let endpoint = "";
  switch (category) {
    case "total billed":
      endpoint = "reportsApi/report/invoice/totalBilled?centerId=";
      break;

    case "total outstanding":
      endpoint = "reportsApi/report/invoice/totalOutstanding?centerId=";
      break;

    case "total collected":
      endpoint = "reportsApi/report/invoice/totalCollected?centerId=";
      break;

    case "total overdue":
      endpoint = "reportsApi/report/invoice/totalOverdue?centerId=";
      break;

    case "ageingReport":
      endpoint = "reportsApi/report/invoice/ageingReport?centerId=";
      break;

    case "created today":
      endpoint = "reportsApi/report/invoice/createdToday?centerId=";
      break;

    case "monthOnMonth":
      endpoint = "reportsApi/report/invoice/collectionMonthOnMonth?centerId=";
      break;

    case "recent transactions":
      endpoint = "reportsApi/report/invoice/totalCollected?centerId=";
      break;

    case "recent due":
      endpoint = "reportsApi/report/invoice/totalOverdue?centerId=";
      break;
  }

  if (endpoint) {
    yield fork(getInvoiceDashboardData, start, end, firebase, endpoint, category);
  }
}
function* getInvoiceDashboardData(start, end, firebase, endpoint, category) {
  try {
    let obj = {
      startDate: moment(start).valueOf(),
      endDate: moment(end).valueOf(),
    };

    let response = yield call(StudentAttendanceApi.requestApi, obj, endpoint, firebase);
    if (response && response.status === 200) {
      yield fork(putInvoiceDashboardData, response, category);
      yield put({
        type: actions.STOP_INVOICE_DASHBOARD_SPINNER,
      });
    } else {
      yield put({
        type: actions.INVOICE_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : "Failed to get" + category + " data. Please contact school or Illumine.",
      });
    }
  } catch (err) {
    console.log("failed to get lead dashboard data" + endpoint + " " + err);
    bugsnagClient.notify(err);
  }
}

function* putInvoiceDashboardData(response, category) {
  try {
    switch (category) {
      case "total billed":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_INVOICE,
          totalBilled: response.body.reportRequest.totalBilled,
        });
        break;

      case "total collected":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_COLLECTED_COUNT,
          totalCollected: response.body.reportRequest.totalCollected,
        });
        break;

      case "total overdue":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_OVERDUE_COUNT,
          totalOverdue: response.body.reportRequest.totalOverdue,
        });
        break;

      case "total outstanding":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_OUTSTANDING_COUNT,
          totalOutstanding: response.body.reportRequest.totalOutstanding,
        });
        break;

      case "created today":
        yield put({
          type: actions.NEW_DASHBOARD_CREATED_TODAY,
          createdToday: response.body.reportRequest.rawData.length,
          createdTodayAmount: response.body.reportRequest.totalBilled,
        });
        break;

      case "monthOnMonth":
        yield put({
          type: actions.NEW_DASHBOARD_MONTH_ON_MONTH,
          monthOnMonth: response.body.reportRequest.map,
          monthOnMonthRaw: response.body.reportRequest.map,
        });
        break;

      case "ageingReport":
        yield put({
          type: actions.NEW_DASHBOARD_AGEING_REPORT,
          ageingBucket: response.body.reportRequest.map,
          ageingBucketRaw: response.body.reportRequest.map
        });
        break;

      case "recent transactions":
        yield put({
          type: actions.NEW_DASHBOARD_RECENT_TRANSACTIONS,
          recentTransactions: response.body.reportRequest.rawData.reverse(),
        });
        break;

      case "recent due":
        yield put({
          type: actions.NEW_DASHBOARD_RECENT_DUE,
          recentDue: response.body.reportRequest.rawData.reverse(),
        });
        break;

    }
  } catch (err) {
    console.log("faile to put lead dashboard data " + category + " " + err);
    bugsnagClient.notify(err);
  }
}

function* fetchPaymentsData({ startDate, endDate, firebase }) {
  try {
    let response = yield call(
      InvoiceApi.fetchPayments,
      startDate,
      endDate,
      firebase
    );
    if (response) {
      yield put({
        type: actions.GET_PAYMENTS_DATA_SUCCESS,
        paymentReceiptList: response,
      });
    } else {
      notification("error", formatMsg("error.fetchPayments"));
      yield put({ type: actions.INVOICE_REQUEST_FAILED });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({ type: actions.INVOICE_REQUEST_FAILED });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_INVOICE, fetchInvoices),
    yield takeLatest(actions.GET_PAYMENT_METHOD, fetchPayment),

    yield takeLatest(actions.GET_INVOICE_AGGREGATED, fetchAggregatedInvoices),
    yield takeLatest(actions.GET_INVOICE_DOWNLOAD_URL, fetchInvoiceDownloadUrl),
    yield takeLatest(actions.GET_STUDENT_FOR_INVOICE, fetchStudentForInvoice),
    yield takeLatest(actions.ADD_NEW_INVOICE, addInvoice),
    yield takeLatest(actions.UPDATE_SELECTED_INVOICE, updateSelectedInvoice),
    yield takeLatest(actions.RECEIVE_PAYMENT, receivePayment),
    yield takeLatest(actions.DELETE_INVOICE, deleteSelectedInvoice),
    yield takeLatest(actions.EDIT_FEE_REMINDER, editFeeReminder),
    yield takeLatest(actions.UPDATE_FEE_REMINDER, updateFeeReminder),
    yield takeLatest(actions.GET_INVOICE_TEMPLATES, fetchOneTimeInvoiceTemplates),
    yield takeLatest(actions.DOWNLOAD_INVOICE_EXCEL_SHEET, fetchAndDownloadExcelSheet),
    yield takeLatest(actions.DOWNLOAD_LINEITEM_EXCEL_SHEET, fetchAndDownloadLineItemExcelSheet),

    yield takeLatest(actions.SEND_PAYMENT_REMINDER, sendFeePaymentReminder),
    yield takeLatest(actions.SEND_PAYMENT_REMINDER_TO_ALL, sendFeePaymentReminderToAll),
    yield takeLatest(actions.DELETE_PAYMENT_RECORD, deleteSelectedPaymentRecord),
    yield takeLatest(actions.REFUND_AMT, refundPaidAmount),
    yield takeLatest(actions.GET_RECEIPT_LIST, fetchReceiptList),
    yield takeLatest(actions.GET_STUDENT_AGGREAGTED_INVOICES, fetchStudentAggregatedInvoice),
    yield takeLatest(actions.GET_SINGLE_STUDENT_INVOICES, fetchSingleStudentInvoices),


    yield takeLatest(actions.REMOVE_REFUND, removeRefundedAmt),
    yield takeLatest(actions.REMOVE_ALL_REFUND, removeAllRefundedAmt),
    yield takeLatest(actions.SAVE_CREDIT, creditAmount),
    yield takeLatest(actions.GET_CREDIT_HISTORY, getStudentCreditHistory),
    yield takeLatest(actions.GET_ALL_CREDIT_HISTORY, getTotalCreditHistory),
    yield takeLatest(actions.DOWNLOAD_ALL_CREDIT_HISTORY, downloadTotalCreditHistory),
    yield takeLatest(actions.DELETE_CREDIT, requestCreditRefund),
    yield takeLatest(actions.GET_STUDENT_ONLINE_CLASS_INVOICE, fetchStudentOnlineClassInvoices),
    yield takeLatest(actions.GENERATE_PAYMENT_TRANSACTION_ID, startTransaction),
    yield takeLatest(actions.SETTLE_PAYMENT, settleInvoicePayment),
    yield takeLatest(actions.UPDATED_FAILED_PAYMENT, recordFailedPayment),
    yield takeLatest(actions.GET_ONLINE_CLASS_PLAN_DETAIL, getOnlineClassPlanDetail),
    yield takeLatest(actions.SAVE_CARD_DETAILS, saveParentCardDetails),
    yield takeLatest(actions.FETCH_CARD_DETAILS, fetchSavedCardDetail),
    yield takeLatest(actions.AUTO_CHARGE_PAYMENT, autoChargeInvoicePayment),
    yield takeLatest(actions.GET_STUDENTS_FEE_PLAN, getStudentsFeePlan),
    yield takeLatest(actions.REFRESH_PDF, refreshPdfRequest),
    yield takeLatest(actions.GET_CREDIT_PDF, fetchCreditPdf),
    yield takeLatest(actions.SAVE_DEPOSIT, depositAmount),
    yield takeLatest(actions.GET_ALL_DEPOSIT_HISTORY, getTotalDepositHistory),

    yield takeLatest(actions.DOWNLOAD_ALL_DEPOSIT_HISTORY, downloadTotalDepositHistory),
    yield takeLatest(actions.GET_DEPOSIT_HISTORY, getStudentDepositHistory),
    yield takeLatest(actions.REFUND_DEPOSIT, refundDepositedAmount),
    yield takeLatest(actions.TRANSFER_CREDIT, transferDepositToCredit),
    yield takeLatest(actions.SETTLE_INVOICE_WITH_DEPOSIT, settleInvoiceUsingDeposit),
    yield takeLatest(actions.GET_DEPOSIT_PDF, fetchDepositPdf),
    yield takeLatest(actions.FETCH_TAX_REF_LIST, getTaxRefList),
    yield takeLatest(actions.SAVE_INVOICE_NOTES, saveInvoiceNotes),
    yield takeLatest(actions.GET_UPCOMMING_INVOICE, getUpcommingInvoice),
    yield takeLatest(actions.DELETE_CREDIT_DEPOSIT, deleteCreditDeposit),
    yield takeLatest(actions.IGNORE_LATE_FEE, ignoreInvoiceLateFee),
    yield takeLatest(actions.GET_INV_FORM_DATA, getFormInvoiceData),
    yield takeLatest(actions.RESEND_INVOICE_EMAIL, resendEmailInvoice),
    yield takeEvery(actions.GET_NEW_INVOICE_DASHBOARD_STATS, fetchInvoiceDashboardStats),
    yield takeLatest(actions.GET_PAYMENTS_DATA, fetchPaymentsData),
  ]);
}
