import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
import FilterAction from "../Utility/FilterAction";

function createCommentNode(eventId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/comments/" + eventId).push().key;
  return key;
}

function updateComment(commentObj, eventId, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/comments/" + eventId + "/" + nodeId).update(commentObj);
}

function getComments(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/comments/" + id)
      .orderByChild("inverseTimestamp")
      .on("value", (snapshot) => {
        var x = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null && !snap.val().deleted) {
            x.push(snap.val());
          }
        });
        emit(x);
      });
    return () => {
      rsf.ref(branchPath + "/comments/" + id).off();
      console.log("unsubscribe comments");
    };
  });
}

function deleteComment(itemId, activityId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/comments/" + activityId + "/" + itemId).update({
    deleted: true,
    updatedBy:
      firebase.selectedMode.toLowerCase() === "teacher" && firebase.teacher
        ? firebase.teacher.name
        : FilterAction.getParentRelation(firebase.user.id, firebase),
    updatedOn: moment().valueOf(),
    platform: "web",
  });
}

export const CommentApi = {
  createCommentNode,
  updateComment,
  getComments,
  deleteComment,
};
