import moment from "moment-timezone";
import FilterPermission from "./FilterPermission";
import PermissionStrings from "./PermissionStrings";
export default class UserFilterAction {
  constructor() { }
}

UserFilterAction.eligibleToPromote = (record, selectedProgram, externalStudent, firebase) => {
  let studentId = record.studentId;
  let ageRange = selectedProgram.ageRange ? selectedProgram.ageRange : undefined;

  let student;
  if (externalStudent) {
    student = [externalStudent];
  } else {
    let students = firebase.studentsList;
    student = students.filter((std) => {
      return std.id === studentId;
    });
  }

  if (student && student.length > 0) {
    let obj = {};
    let birthDate = student[0].birthDate
      ? moment(student[0].birthDate, "DD[, ]MMMM[, ]YYYY")
      : undefined;
    if (birthDate) {
      let studentAge = moment.duration(moment().diff(birthDate));
      let studentAgeInYear = studentAge.years();

      let studentAgeInMonth = studentAge.months();
      if (studentAgeInYear > 0 || studentAgeInMonth > 0) {
        obj.age = studentAgeInYear + " year " + studentAgeInMonth + " month";
        if (birthDate && ageRange) {
          let studentAge = moment.duration(moment().diff(birthDate));
          let studentAgeInYear = studentAge.years();

          let studentAgeInMonth = studentAge.months();

          if (Number(studentAgeInYear) >= Number(ageRange.maxYear)) {
            if (Number(studentAgeInYear) > Number(ageRange.maxYear)) {
              obj.eligibleToPromote = true;
            } else {
              if (Number(studentAgeInMonth) > Number(ageRange.maxMonth)) {
                obj.eligibleToPromote = true;
              } else {
                obj.eligibleToPromote = false;
              }
            }
          } else {
            obj.eligibleToPromote = false;
          }
        } else {
          obj.eligibleToPromote = false;
        }
      }
    }
    return obj;
  }
};

UserFilterAction.getDateFormat = () => {
  return "DD[-]MMM[-]YY";
};
UserFilterAction.getTimeFormat = () => {
  return "h:mm A";
};

UserFilterAction.getDateTimeFormat = () => {
  return "DD[-]MMM[-]YY[, ]h:mm A";
};

UserFilterAction.getAttendanceType = (firebase) => {
  let predefinedTypes = ["Sick Leave", "Medical Leave", "Casual Leave", "Others"];

  if (firebase && firebase.schoolConfig.absentType) {
    let val = firebase.schoolConfig.absentType.split(",");
    predefinedTypes = val;
  }

  return predefinedTypes;
};

UserFilterAction.getStudentAbsentType = (firebase) => {
  // let config = Utility.config;
  let typeVal = ["Sick Leave", "Medical Leave", "Casual Leave", "Others"];

  if (firebase && firebase.schoolConfig.absentType) {
    let val = firebase.schoolConfig.absentType.split(",");
    let dropdownObj = [];
    if (val && val.length > 0) {
      val.forEach((item) => {
        dropdownObj.push(item);
      });

      typeVal = dropdownObj;
    }
  }

  return typeVal;
};

UserFilterAction.getStaffAbsentType = (firebase) => {
  // let config = Utility.config;
  let typeVal = ["paid", "unpaid"];

  if (firebase && firebase.schoolConfig.staffAbsentType) {
    let val = firebase.schoolConfig.staffAbsentType.split(",");
    let dropdownObj = [];
    if (val && val.length > 0) {
      val.forEach((item) => {
        dropdownObj.push(item);
      });

      typeVal = dropdownObj;
    }
  }

  return typeVal;
};

UserFilterAction.getParentName = (verifiedUser, studentData) => {
  let parentName;
  if (studentData.fatherProfileId && verifiedUser.id === studentData.fatherProfileId) {
    parentName = studentData.fatherName;
  } else if (studentData.fatherNumber && studentData.fatherNumber === verifiedUser.phoneNumber) {
    parentName = studentData.fatherName;
  } else if (studentData.motherProfileId && verifiedUser.id === studentData.motherProfileId) {
    parentName = studentData.motherName;
  } else if (studentData.motherNumber && verifiedUser.phoneNumber === studentData.motherNumber) {
    parentName = studentData.motherName;
  } else {
    parentName = undefined;
  }
  return parentName;
};

UserFilterAction.getAge = (dob) => {
  let birthDate = dob ? moment(dob, UserFilterAction.getDateFormatList()) : undefined;
  
  if (birthDate) {
    let currentDate = moment().add(1, 'days');
    let studentAge = moment.duration(currentDate.diff(birthDate));
    let studentAgeInYear = studentAge.years();

    let studentAgeInMonth = studentAge.months();

    if (studentAgeInYear > 0 || studentAgeInMonth > 0) {
      return studentAgeInYear + " year " + studentAgeInMonth + " month";
    } else if (studentAgeInYear === 0 && studentAgeInMonth === 0) {
      return studentAgeInYear + " year " + studentAgeInMonth + " month";
    } else {
      return "--";
    }
  }
};

UserFilterAction.getApiDateFormat = () => {
  return "YYYY-MM-DD";
};

UserFilterAction.getApplicationStatus = () => {
  return ["Approved", "Rejected", "In-review", "Submitted", "Pending"];
};

UserFilterAction.getDateFormatList = () => {
  return [
    "D/MM/YYYY",
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD,MMMM,YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    "YYYY/M/D",
    "YYYY-M-D",
    "MM/DD/YYYY",
    "MM/DD/YY",
    "M/D/YY",
    "MMM D, YY",
    "MMM D, YYYY",
    "MMM DD, YYYY",
  ];
};
UserFilterAction.getTimeFormatList = () => {
  return [
    "h:mm A"
  ];
};

UserFilterAction.getFeeReminderHtml = () => {
  const feeReminderHtml = `<p><span style="font-weight: 400;">Dear Parent,</span></p>

    <p><span style="font-weight: 400;">This is a gentle reminder regarding the fee payment of <strong>{{studentName}}</strong> which is due on {{dueDate}} for <strong>Invoice No: {{invoiceNumber}}</strong>. 
    <br> We request you pay outstanding fees on time.</span></p>

    <p> The total due amount is <strong>{{pendingAmount}}.</strong></span></p>



    <p><span style="font-weight: 400;">Thank you for your co-operation.</span></p>

    <p><span style="font-weight: 400;">Best Regards,&nbsp;</span></p>
    <p><span style="font-weight: 400;">{{schoolName}}</span></p>`;

  return feeReminderHtml;
};

UserFilterAction.installationInviteHtml = () => {
  // const installationInviteHtml = `<p style="text-align: left;">Hello Parent,<br></p>
  // <h3 style="text-align: left;">Welcome to {{schoolName}} !</h3>
  // <p style="text-align: left;"><br /><strong>{{schoolName}}</strong> has invited you on the Illumine app.<br /><br />It helps parents stay connected with their child throughout the day.</p>
  // <p style="text-align: left;">It only takes two steps to get started.<br /><br /><strong>Instructions:</strong><br /><br />1. Download the Illumine app from the Apple App Store or Google Play.</p>
  // <div style="color: #003300;"> <a style="color: #003300;" href="https://play.google.com/store/apps/details?id=com.illumine.app"> <img style="width: 135px; height: 60px; margin: 10px 0px 0px; float: left;" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" alt="Get it on Google Play" /></a> <a style="color: #003300;" href="https://apps.apple.com/in/app/illumine-parent/id1459249394"><img style="width: 120px; height: 40px;margin: 20px 10px 10px 10px; margin-bottom: 0px;float: left;" src="https://firebasestorage.googleapis.com/v0/b/masterproject-a832a.appspot.com/o/media%2Funnamed%20(5).png?alt=media&amp;token=c5361cf7-123f-4410-8794-f1c58c2e01b3" alt="Get it on AppStore" /></a> </div> <br/><br/><br>or <br /><br /> Open in Web: <a href="https://school.illumine.app" target="_blank" rel="noopener">https://school.illumine.app</a> <br /><br /><br /></p>2. Login using your registered mobile number, provided to the school. You can also login with the mentioned credentials. <br /><br /><strong>Email :</strong>{{email}} <br /><strong>Password :</strong>{{password}} <br /><br />Quick things to do after sign-in:<br />Child's profile: Go to your profile and add your photo. Make sure your contact info is<br />updated as well.<br /><br /><br />Questions? Contact us at info@illumine.app
  // <p style="text-align: left;"><br /><span style="color: #003300;"> We hope you will enjoy our service. </span><br /><br /><span style="color: #003300;"> Thanks . </span><br />{{schoolName}}`;

  const installationInviteHtml = `<p style="text-align: left;">Hello Parent,<br></p>
  <h3 style="text-align: left;">Welcome to {{schoolName}} !</h3>
  <p style="text-align: left;"><br /><strong>{{schoolName}}</strong> has invited you on the Illumine app.<br /><br />It helps parents stay connected with their child throughout the day.</p>
  <p style="text-align: left;">It only takes two steps to get started.<br /><br /><strong>Instructions:</strong><br /><br />1. Download the Illumine app from the Apple App Store or Google Play.</p>
  <br /><br
  <div style="color: #00AACC;"> 
  <a style="color: #00AACC;text-decoration:underline;" href="https://play.google.com/store/apps/details?id=com.illumine.app">Get it on Google Play</a>
  <br/><br/>
  <a style="color: #00AACC;text-decoration:underline;" href="https://apps.apple.com/in/app/illumine-parent/id1459249394">Get it on AppStore</a>
  </div> <br/><br/><br>or <br /><br /> Open in Web: <a href="https://school.illumine.app" target="_blank" rel="noopener">https://school.illumine.app</a> <br /><br /><br /></p>2. Login using your registered mobile number, provided to the school. You can also login with the mentioned credentials. <br /><br /><strong>Email :</strong>{{email}} <br /><strong>Password :</strong>{{password}} <br /><br />Quick things to do after sign-in:<br />Child's profile: Go to your profile and add your photo. Make sure your contact info is<br />updated as well.<br /><br /><br />Questions? Contact us at info@illumine.app
  <p style="text-align: left;"><br /><span style="color: #003300;"> We hope you will enjoy our service. </span><br /><br /><span style="color: #003300;"> Thanks . </span><br />{{schoolName}}`;
  return installationInviteHtml;
};

UserFilterAction.checkPermission = (firebase, key) => {
  var permission = true;

  switch (key) {
    case "feeStructure":
      if (
        firebase &&
        !firebase.isParent &&
        firebase.schoolConfig.billingMode &&
        firebase.schoolConfig.billingMode === "Fee Plan" &&
        FilterPermission.checkIfPermission(
          PermissionStrings.BILLING_Create_Edit_Fee_Template,
          firebase
        )
      ) {
        permission = true;
        break;
      } else {
        permission = false;
        break;
      }

    case "invoiceTemplate":
      if (
        !firebase.isParent &&
        !firebase.schoolConfig.billingMode &&
        FilterPermission.checkIfPermission(PermissionStrings.BILLING_View, firebase)
      ) {
        permission = true;
        break;
      } else {
        permission = false;
        break;
      }
  }
  return permission;
};
