import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import * as FileSaver from "file-saver";
import {
  getItem,
  setItem,
  removeItem,
  clear,
} from "../../Utility/encryptedStorage";
import { format } from "mathjs";
import moment from "moment";
import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { LeadsApi } from "../../firestore-api/leads";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { NotificationApi } from "../../firestore-api/notification";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { TeacherApi } from "../../firestore-api/teacher";
import actions from "./actions";
import { delay } from "redux-saga";
import FilterAction from "../../Utility/FilterAction";
import { ApplicationApi } from "../../firestore-api/application";
import { toLower } from "lodash";
import leadActivity from "../../containers/LeadsDetailedView/leadActivity";
import { func } from "prop-types";

const { Parser } = require("json2csv");
import { callApi } from "../../Utility/superAgentUntil";

function* fetchLeads({
  startDate,
  endDate,
  firebase,
  frequency,
  initialCall,
  customRange,
  selectedLead,
  selectedStatus,
  leadFormId
}) {
  let chan;
  if (leadFormId) {
    chan = yield call(
      LeadsApi.getLeadsByFormId,
      firebase,
      leadFormId,
    );
  }
  else if (startDate && endDate) {
    chan = yield call(
      LeadsApi.getAllLeads,
      startDate,
      endDate,
      firebase,
      frequency,
      selectedStatus
    );
  } else if (
    startDate === undefined &&
    endDate === undefined &&
    frequency === undefined &&
    selectedLead
  ) {
    chan = yield call(LeadsApi.fetchSelectedLead, selectedLead, firebase);
  } else {
    chan = yield call(
      LeadsApi.getLifetimeLeads,
      firebase,
      frequency,
      selectedStatus
    );
  }



  try {
    while (true) {
      let data = yield take(chan);

      let sourceOptions = [];
      let subSourceOptions = [];

      if (data.length > 0) {
        data.map((d) => {
          if (d.source) {
            sourceOptions.push(d.source);
          }
          if (d.subSource) {
            subSourceOptions.push(d.subSource);
          }
        });
      }
      if (customRange === "Custom Range" && startDate && endDate) {
        let nextFollowUpDate = data.filter((item) => {
          return (
            item.nextFollowUpDate &&
            item.nextFollowUpDate >= startDate &&
            item.nextFollowUpDate <= endDate
          );
        });
        data = nextFollowUpDate;
      }
      data = data.sort((a, b) => {
        return FilterAction.sortIntegerValue(b, a, "createdDate")
      })
      yield put({
        type: actions.LIST_LEADS_SUCCESSFULL,
        leads: data,
        leadsChannel: chan,
        prevFrequency: frequency,
        operationType: initialCall,
        sourceOptions: [...new Set(sourceOptions)],
        subSourceOptions: [...new Set(subSourceOptions)],
      });
      yield* getAdmittedLeads({
        firebase: firebase,
        startDate: startDate,
        endDate: endDate
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* fetchFeePrograms({ firebase }) {
  try {
    var data = yield call(LeadsApi.getFeePrograms, firebase);
    if (data) {
      yield put({
        type: actions.GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL,
        feeProgram: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee programs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function createObjectForLead(
  values,
  leadsConfig,
  studentConfig,
  parentOneConfig,
  parentTwoConfig,

) {

  let obj = {
    id: values.id ? values.id : null,
    createdDate: values.createdDate,
    createdBy: values.createdBy,
    student: {
      name: values.studentName,
      gender: values.gender ? values.gender : null,
      birthDate: values.birthDate ? moment(values.birthDate).valueOf() : null,
      school: values.schoolName ? values.schoolName : null,
      preferredName: values.prefName ? values.prefName : null,
      address: values.leadAddress ? values.leadAddress : null,
      emergencyContactName: values.emergencyContactName ? values.emergencyContactName : null,
      emergencyNumber: values.emergencyNumber ? values.emergencyNumber : null,
    },
    feeProgram: values.feeProgram ? values.feeProgram : null,
    leadStatus: values.leadStatus ? values.leadStatus : null,
    nextFollowUpDate: values.nextFollowUpDate ? moment(values.nextFollowUpDate).valueOf() : null,
    parentOne: {
      name: values.motherName ? values.motherName : null,
      email: values.parentOneEmail ? values.parentOneEmail : null,
      phone: values.parentOnePhone ? Number(values.parentOnePhone) : null,
      organization: values.motherOrganization ? values.motherOrganization : null,
      countryCode: values.parentOneCountryCode ? values.parentOneCountryCode : null,
    },
    parentTwo: {
      name: values.fatherName ? values.fatherName : null,
      email: values.parentTwoEmail ? values.parentTwoEmail : null,
      phone: values.parentTwoPhone ? Number(values.parentTwoPhone) : null,
      organization: values.fatherOrganization ? values.fatherOrganization : null,
      countryCode: values.parentTwoCountryCode ? values.parentTwoCountryCode : null,
    },
    source: values.source ? values.source : null,
    subSource: values.subSource ? values.subSource : null,
    additionalDescription: values.additionalDescription ? values.additionalDescription : null,
    expectedStartDate: values.expectedStartDate ? moment(values.expectedStartDate).valueOf() : null,
    additionalProps: leadsConfig,
    studentAdditionalFields: studentConfig ? studentConfig : [],
    parentOneAdditionalFields: parentOneConfig ? parentOneConfig : [],
    parentTwoAdditionalFields: parentTwoConfig ? parentTwoConfig : [],
    studentId: values.studentId ? values.studentId : null,
    admissionDate: values.admissionDate ? values.admissionDate : null,
    assignTo: values.assignTo ? values.assignTo : null,
    lastFollowUpDate: values.lastFollowUpDate ? values.lastFollowUpDate : null,
    followUpActivityId: values.followUpActivityId ? values.followUpActivityId : null
  };


  return obj;
}


function* addNewLead({ values, firebase, leadAdditionalField, childConfig, parentConfig, isAdmitted, newStudentInfo }) {
  try {
    let leadsConfig = getLeadAdditionalFieldData(
      leadAdditionalField ? leadAdditionalField : [],
      values
    );
    let studentConfig = getLeadAdditionalFieldData(childConfig ? childConfig : [], values);

    let parentOneConfig = getLeadAdditionalFieldData(
      parentConfig ? parentConfig : [],
      values,
      "p1"
    );

    let parentTwoConfig = getLeadAdditionalFieldData(
      parentConfig ? parentConfig : [],
      values,
      "p2"
    );
    values.createdDate = moment().valueOf();
    values.createdBy = firebase.teacher.name;
    let object = createObjectForLead(
      values,

      leadsConfig,
      studentConfig,
      parentOneConfig,
      parentTwoConfig
    );
    let url = "woodlandApi/leadsApi/save/?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, object, url, firebase);

    if (response && response.status === 200) {
      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "leads", new Date());
      if (isAdmitted && response.body.lead) {
        let record = response.body.lead;
        record.classroomName = newStudentInfo.classroomName ? newStudentInfo.classroomName : "";
        yield* addAccount({
          record: record,
          firebase: firebase,
          incomingGender: newStudentInfo.incomingGender,
          incomingStatus: newStudentInfo.incomingStatus
        });
      }
      // notification("success", formatMsg("success.leadAdded"));
      yield put({
        type: actions.SAVE_NEW_LEAD_SUCCESSFUL,
      });
      // yield fork(triggerStatusChangeApi, nodeId, firebase);
    } else {
      notification(
        "error",
        response.body && response.body.response ? response.body.response : "failed to add new lead"
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (error) {
    console.log("failed to add new lead", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function getFieldId(f, parentType) {
  if (parentType) {
    return parentType + "***" + f.id;
  } else {
    return f.id;
  }
}

function getLeadAdditionalFieldData(config, leadsValue, parentType) {
  let newObj = JSON.parse(JSON.stringify(config));
  newObj.forEach((lItem) => {
    let dataType = lItem.dataType.toLowerCase();
    if (dataType === "date") {
      lItem.value = leadsValue[getFieldId(lItem, parentType)]
        ? moment(leadsValue[getFieldId(lItem, parentType)]).format("DD[ ]MMM[ ]YY")
        : null;
    } else if (dataType === "time") {
      lItem.value = leadsValue[getFieldId(lItem, parentType)]
        ? moment(leadsValue[getFieldId(lItem, parentType)]).format("h:mm A")
        : null;
    } else if (dataType === "yes/no checkbox") {
      lItem.value =
        leadsValue[getFieldId(lItem, parentType)] &&
          leadsValue[getFieldId(lItem, parentType)] === "Yes"
          ? "Yes"
          : leadsValue[getFieldId(lItem, parentType)] &&
            leadsValue[getFieldId(lItem, parentType)] === "No" ? "No" : null;
    } else if (dataType === "multi select") {
      lItem.value = leadsValue[getFieldId(lItem, parentType)]
        ? leadsValue[getFieldId(lItem, parentType)].toString()
        : [];
    } else {
      lItem.value = leadsValue[getFieldId(lItem, parentType)]
        ? leadsValue[getFieldId(lItem, parentType)].toString()
        : null;
    }
  });
  return newObj;
}

function* updateExistingLead({ incomingLeadsValue, record, firebase, isAddAccount, newStudentData, isStudentDelete }) {
  try {
    let leadAdditionalData = yield call(LeadsApi.getAdditionalFields, firebase);
    if (isAddAccount === "addAcount") {
      incomingLeadsValue.createdDate = record.createdDate ? record.createdDate : moment().valueOf();
      incomingLeadsValue.createdBy = firebase.teacher.name;
      incomingLeadsValue.studentId = record.studentId ? record.studentId : null;
      incomingLeadsValue.admissionDate = record.admissionDate ? record.admissionDate : null;
      incomingLeadsValue.id = record.id
    } else {
      let leadsConfig = getLeadAdditionalFieldData(
        record.additionalProps ? record.additionalProps : leadAdditionalData ? leadAdditionalData : [],
        incomingLeadsValue
      );
      let studentConfig = getLeadAdditionalFieldData(
        record.studentAdditionalFields ? record.studentAdditionalFields : [],
        incomingLeadsValue
      );
      let parentOneConfig = getLeadAdditionalFieldData(
        record.parentOneAdditionalFields ? record.parentOneAdditionalFields : [],
        incomingLeadsValue,
        "p1"
      );
      let parentTwoConfig = getLeadAdditionalFieldData(
        record.parentTwoAdditionalFields ? record.parentTwoAdditionalFields : [],
        incomingLeadsValue,
        "p2"
      );

      incomingLeadsValue.createdDate = record.createdDate ? record.createdDate : moment().valueOf();
      incomingLeadsValue.createdBy = firebase.teacher.name;
      incomingLeadsValue.studentId = record.studentId ? record.studentId : null;
      incomingLeadsValue.admissionDate = record.admissionDate ? record.admissionDate : null;
      incomingLeadsValue.id = record.id;
      incomingLeadsValue.lastFollowUpDate = record.lastFollowUpDate ? record.lastFollowUpDate : null;
      incomingLeadsValue.followUpActivityId = record.followUpActivityId ? record.followUpActivityId : null;

      incomingLeadsValue = createObjectForLead(
        incomingLeadsValue,
        leadsConfig,
        studentConfig,
        parentOneConfig,
        parentTwoConfig,

      );
      incomingLeadsValue.lastActivityDate = record.lastActivityDate;
      incomingLeadsValue.lastActivityName = record.lastActivityName;
      incomingLeadsValue.lastActivityId = record.lastActivityId;
    }
    if (isStudentDelete) {
      var studentDeleteResponse = yield call(StudentApi.deleteStudentNewApi, firebase, incomingLeadsValue.studentId);
      incomingLeadsValue.studentId = null;
      incomingLeadsValue.admissionDate = null;
    }

    if (newStudentData) {
      // any status -> admitted (edit case)
      incomingLeadsValue.classroomName = newStudentData.classroomName;
      yield* addAccount({
        record: incomingLeadsValue,
        firebase: firebase,
        incomingGender: newStudentData.incomingGender,
        incomingStatus: newStudentData.incomingStatus
      });
    }
    else {
      let url = "woodlandApi/leadsApi/save/?centerId=";
      let response = yield call(StudentAttendanceApi.requestApi, incomingLeadsValue, url, firebase);
      if (response && response.status === 200) {

        notification("success", formatMsg("success.updateLead"));
        yield put({
          type: actions.UPDATE_LEAD_SUCCESSFUL,
        });
        yield fork(NotificationApi.callDashboardRefreshApi, firebase, "leads", new Date());

        // yield fork(triggerStatusChangeApi, record.id, firebase);
      } else {
        notification(
          "error",
          response.body && response.body.response ? response.body.response : "failed to update lead"
        );
        yield put({
          type: actions.LEAD_REQUEST_FAILED,
        });
      }
    }
  } catch (error) {
    console.log("faile to update lead", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }

}

function* addNewStatus({ statusInput, firebase }) {
  try {
    var nodeId = yield call(LeadsApi.createNewLeadNode, firebase);
    yield call(LeadsApi.addStatus, statusInput, nodeId, firebase);
    yield put({
      type: actions.ADD_STATUS_SUCCESSFUL,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}
function* addNewStatusSourceSubsource({ value, category, firebase }) {
  try {
    var nodeId = yield call(LeadsApi.createNewLeadNode, firebase);
    var nodeEndPoint;

    if (category === "status") {
      nodeEndPoint = "/leadsStatus/";
    } else if (category === "source") {
      nodeEndPoint = "/leadsSource/";
    } else if (category === "subsource") {
      nodeEndPoint = "/leadsSubsource/";
    }
    yield call(
      LeadsApi.addNewStatusSourceSubsource,
      value,
      nodeEndPoint,
      nodeId,
      firebase
    );
    notification("success", formatMsg("success.saved"));
    yield put({
      type: actions.MANAGE_LEAD_SUCCESS,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}
function* getmanageLead({ firebase, category }) {
  var nodeEndPoint;
  if (category === "status") {
    nodeEndPoint = "/leadsStatus/";
  } else if (category === "source") {
    nodeEndPoint = "/leadsSource/";
  } else if (category === "subsource") {
    nodeEndPoint = "/leadsSubsource/";
  }

  const chan = yield call(LeadsApi.getManageLead, firebase, nodeEndPoint);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.FETCH_MANAGE_LEAD_SUCCESS,
        manageLead: data,
        manageLeadsChannel: chan,
      });
    }
  } finally {
    console.log("end leads  manage channel");
  }
}

function* fetchLeadsStatus({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsStatus, firebase);
    if (data) {
      yield put({
        type: actions.GET_STATUS_SUCCESSFUL,
        status: data,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchLeadsSource({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsSource, firebase);
    if (data) {
      yield put({
        type: actions.GET_SOURCE_SUCCESS,
        source: data,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchLeadsSubSource({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsSubsource, firebase);
    if (data) {
      yield put({
        type: actions.GET_SUBSOURCE_SUCCESS,
        subsource: data,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedLeadDetail({ selectedLead, firebase }) {
  const chan = yield call(LeadsApi.getSelectedLead, selectedLead, firebase);

  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_LEAD_DETAIL_SUCCESSFUL,
        detailedLead: data,
        detailedLeadChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}




function createobjectForLeadActivityOrTask(value, leadRecord, firebase, record) {
  let obj = {
    leadId: leadRecord.id,
    activityType: value.activityType,
    description: value.additionalDescription
      ? value.additionalDescription
      : null,
    date: value.activityType.toLowerCase() === "task" && value.nextFollowUpDate ? moment(value.nextFollowUpDate).valueOf() : value.date ? moment(value.date).valueOf() : null,
    time: value.activityType.toLowerCase() === "task" && value.nextFollowUpDate ? moment(value.nextFollowUpDate).valueOf() : value.activityType.toLowerCase() === "follow up" && value.date ? moment(value.date).valueOf() : value.time ? moment(value.time).valueOf() : null,
    createdBy: record && record.createdBy ? record.createdBy : firebase.teacher.name,
    nextFollowUpDate: value.nextFollowUpDate
      ? moment(value.nextFollowUpDate).valueOf()
      : null,
    createdOn: moment().valueOf(),

  };
  if (value.activityType.toLowerCase() === "task") {
    obj["label"] = value.label ? value.label : null;
    obj["teacher"] = value.teacher ? value.teacher : null;
    obj["completed"] = value.completed;

  }
  if (record && record.id) {
    obj["id"] = record.id;
    obj["createdOn"]
      = record.createdOn;
  }
  return obj;

};
function* addNewActivity({ value, leadRecord, firebase, record }) {
  try {
    let object = createobjectForLeadActivityOrTask(value, leadRecord, firebase, record);
    object["time"] = object["date"];

    let url = "woodlandApi/leadsApi/saveLeadActivities/?centerId=";

    let response = yield call(
      StudentAttendanceApi.requestApi,
      object,
      url,
      firebase
    );
    if (response && response.status === 200) {


      // yield call(LeadsApi.updateFollowUpDate, value, leadRecord.id, firebase);

      yield fork(
        NotificationApi.callDashboardRefreshApi,
        firebase,
        "leads",
        new Date()
      );
      if (record) {
        notification("success", formatMsg("activity.updateActivity"));
      }
      else {
        notification("success", formatMsg("success.saveActivity"));
      }

      yield put({
        type: actions.ADD_ACTIVITY_SUCCESSFUL,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addActivity")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* addNewTask({ value, leadRecord, firebase }) {
  try {

    let obj = createobjectForLeadActivityOrTask(value, leadRecord, firebase);
    obj["time"] = obj["date"];
    let url = "woodlandApi/leadsApi/saveLeadActivities/?centerId=";

    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      url,
      firebase
    );

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "leads",
      new Date()
    );
    if (response && response.status === 200) {


      notification("success", formatMsg("success.saveTask"));

      yield put({
        type: actions.ADD_TASK_SUCCESSFUL,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addTask")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}


function* fetchSelectedLeadActivity({ selectedLead, firebase }) {
  const chan = yield call(LeadsApi.getSelectedLeadActivity, selectedLead, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      var valA;
      var valB;

      data.sort(function (a, b) {
        if (a.activityType.toLowerCase() === "email" && !a.date) {
          a.date = a.createdOn
        }
        if (b.activityType.toLowerCase() === "email" && !b.date) {
          b.date = b.createdOn
        }
        if (!a.date)
          a.date = a.createdOn
        if (!b.date)
          b.date = b.createdOn
        if (a.activityType.toLowerCase() === "task")
          a.date = a.date ? a.date : a.nextFollowUpDate
        if (b.activityType.toLowerCase() === "task")
          a.date = a.date ? a.date : a.nextFollowUpDate
        valA = a.date;
        valB = b.date;
        if (valA && valB) {
          return valB - valA;
        }
        if (valA && !valB) {
          return valA;
        }
        if (valB && !valA) {
          return valB
        }
      });
      // activity type email and have subject must
      for (let activity of data) {
        if (activity.activityType.toLowerCase() === "email" && activity.subject && activity.mailOptionResultId) {
          let logData = yield call(LeadsApi.getMailDeliveryReportStatus, activity.mailOptionResultId, firebase);
          activity.parentEmailStatus = [];
          logData.map((currentData) => {
            activity.parentEmailStatus.push({ email: currentData.email, status: currentData.event });
          })
        }
      }
      yield put({
        type: actions.GET_LEAD_ACTIVITY_SUCCESSFUL,
        detailedLeadActivity: data ? data : [],
        detailedLeadActivityChannel: chan,
      });
    }
  } finally {
    console.log("end selected leads channel");
  }
}

function linkify(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;
  // replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacePattern1 = /(\b(https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
  // //URLs starting with http://, https://, or ftp://
  // replacePattern1 =
  //   /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  // replacedText = inputText.replace(
  //   replacePattern1,
  //   '<a href="$1" target="_blank">$1</a>'
  // );

  // //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  // replacedText = replacedText.replace(
  //   replacePattern2,
  //   '$1<a href="http://$2" target="_blank">$2</a>'
  // );

  // //Change email addresses to mailto:: links.
  // replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  // replacedText = replacedText.replace(
  //   replacePattern3,
  //   '<a href="mailto:$1">$1</a>'
  // );
  // // const onlyText = replacedText.substring(6);
  // // return onlyText
  return replacedText;
}
function getNoteHtml(editorState) {
  if (editorState) {
    let rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const linkedMarkUp = linkify(markup);
    return linkedMarkUp;
  } else {
    return null;
  }
}
function getNotePlainContent(editorState) {
  if (editorState) {
    let rawContentState = convertToRaw(editorState.getCurrentContent());

    if (rawContentState.blocks && rawContentState.blocks.length > 0) {
      let allBlocks = rawContentState.blocks;
      let data = "";
      allBlocks.map((b, i) => {
        if (b.text) {
          if (i === 0) {
            data = b.text + " \n";
          } else {
            data = data + b.text + " \n";
          }
        }
      });

      return data;
    } else {
      return null;
    }
  } else {
    return editorState;
  }
}

function* addNewEmail({ value, leadRecord, firebase, editorState, isLog, prevAttachments }) {

  try {

    const html = getNoteHtml(editorState);
    const description = getNotePlainContent(editorState);
    var storagePath = undefined;
    var attachmentUrl;
    let attachmentArray = [];
    if (value.attachment) {

      let file = { fileList: value.attachment };

      attachmentUrl = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        file,
        firebase
      );
      attachmentUrl.forEach((item) => {
        let attachmentObj = {};
        attachmentObj.url = item.path;
        attachmentObj.fileName = item.fileName;
        attachmentObj.type = item.type === "file" ? "application/pdf" : item.type === "video" ? "video" : "image",
          attachmentArray.push(attachmentObj);
      });

    }
    if (prevAttachments.length > 0) {
      for (let index in prevAttachments) {
        attachmentArray.push(prevAttachments[index]);
      }
    }
    let parentEmails = [];
    if (
      (leadRecord[0].parentOne && leadRecord[0].parentOne.email) ||
      (leadRecord[0].parentTwo && leadRecord[0].parentTwo.email) ||
      value.sendTo.length > 0
    ) {
      let parent = value.sendTo;
      parent.map((item) => {
        parentEmails.push(item);
      });

    }


    let object = {
      activityType: "Email",
      subject: value.subject,
      description: description,
      htmlText: html,
      leadId: leadRecord[0].id,
      createdBy: firebase.teacher.name,
      createdOn: moment().valueOf(),
      platform: "web",
      sendTo: value.sendTo,
      replyTo: value.replyTo,
      parentEmails: parentEmails,
      attachment: attachmentArray.length > 0 ? attachmentArray : null,
      date: moment().valueOf(),
      time: moment().valueOf()

    };
    let url = "woodlandApi/leadsApi/saveLeadActivities/?centerId=";
    let response = yield call(
      StudentAttendanceApi.requestApi,
      object,
      url,
      firebase
    );
    if (response && response.status === 200) {
      ;

      notification("success", formatMsg("error.saveEmail"));
      yield put({
        type: actions.ADD_EMAIL_SUCCESSFUL,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addActivity")
      );
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}
function* addNewTemplate({ value, firebase, editorState }) {
  try {
    const htmlText = getNoteHtml(editorState);

    var nodeId = yield call(LeadsApi.createNewTemplateNode, firebase);

    yield call(LeadsApi.addTemplate, value, nodeId, firebase, htmlText);
    notification("success", formatMsg("success.saveEmailTemplate"));

    yield put({
      type: actions.ADD_LEAD_EMAIL_TEMPLATE_SUCCESSFUL,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* getEmailTemplateRegister({ firebase, templateType }) {
  try {
    const chan = yield call(
      LeadsApi.getAllEmailTemplate,
      firebase,
      templateType
    );
    while (true) {
      const response = yield take(chan);
      yield put({
        type: actions.GET_EMAIL_TEMPLATE_REGISTER_SUCCESSFUL,
        detailedLeadEmailTemplateChannel: chan,
        leadEmailTemplateData: response,
      });
    }
  } catch (err) {
    console.log("failed to fetch lead Email Templates", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      payLoad: false,
    });
  }
}

function* deleteSelectedEmailTemplate({ record, firebase }) {
  try {
    yield call(LeadsApi.deleteEmailTemplateStatus, record, firebase);
    notification("success", formatMsg("success.deleteTemplate"));
    yield put({
      type: actions.DELETE_EMAIL_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to delete lead email template", err);
  }
}

function* updateSelectedEmailTemplate({ value, editorState, firebase, id }) {
  try {
    const htmlTemplate = getNoteHtml(editorState);

    yield call(
      LeadsApi.updateEmailTemplateStatus,
      value,
      htmlTemplate,
      firebase,
      id
    );
    notification("success", formatMsg("success.updateTemplate"));
    yield put({
      type: actions.UPDATE_EMAIL_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update lead email template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* deleteLeadDocument({ record, id, firebase, filePath }) {
  try {
    yield call(StudentApi.deleteDocument, record, id, firebase, filePath);
    yield put({
      type: actions.DELETE_LEAD_DOC_SUCCESS,
      operationRequest: "DOCUMENT_DELETE",
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* addDocumentFolder({ folderName, filePath, firebase, id }) {
  try {
    let nodeId = yield call(StudentApi.createNewNoteId, firebase);
    yield call(
      StudentApi.addDocumentFolder,
      nodeId,
      id,
      folderName,
      filePath,
      firebase
    );
    yield put({
      type: actions.ADD_LEAD_DOC_FOLDER_SUCCESS,
      operationRequest: "DOCUMENT_ADD",
    });
  } catch (err) {
    console.log("failed to add document folder", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* renamedDocStudent({ docName, record, firebase, id, documents, currentFilePath }) {
  try {
    yield call(StudentApi.renameDoc, docName, record.id, firebase, id, currentFilePath);
    let allFiles = documents;
    let oldPath = record.name + "/";
    let newPath = docName + "/";
    if (record.folder) {
      for (let index in allFiles) {
        if (allFiles[index].id !== record.id) {
          if (allFiles[index].filePath) {
            let filePath = allFiles[index].filePath;
            if (currentFilePath) {
              let splittedPath = filePath.split(currentFilePath);
              if (splittedPath[1]) {
                filePath = splittedPath[1].toString();
              }
              else {
                filePath = ""
              }
            }
            if (filePath.startsWith(oldPath)) {
              allFiles[index].filePath =
                (currentFilePath ? currentFilePath : "") + filePath.replace(oldPath, newPath);
              yield call(StudentApi.renameDoc, allFiles[index].name, allFiles[index].id, firebase, id, allFiles[index].filePath);
            }
          }
        }
      }
    }

    yield put({
      type: actions.RENAME_LEAD_DOC_FOLDER_SUCCESS,
      operationRequest: "RENAME_DOC",
    });
  } catch (err) {
    console.log("failed to rename lead document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* uploadLeadDocument({ fileList, mediaType, id, firebase, filePath }) {
  try {
    let storagePath;
    if (mediaType === "File") {
      storagePath = firebase.sbp + "/media/file/";
    } else {
      storagePath = firebase.sbp + "/media/images/";
    }
    let file = { fileList: fileList };
    let urls = yield call(
      ActivityApi.getAttachmentMediaPath,
      storagePath,
      file,
      firebase
    );

    if (urls) {
      for (let i = 0; i < urls.length; i++) {
        let nodeId = yield call(StudentApi.getStudentDocNode, firebase);

        let obj = {
          filePath: filePath ? filePath : null,
          createdBy: firebase.teacher.name,
          createdOn: moment().valueOf(),
          downloadUrl: urls[i].path,
          id: nodeId,
          inverseDate: -moment().valueOf(),
          name: urls[i].fileName,
          nameWithExtension: urls[i].fileName,
          type: urls[i].type && urls[i].type === "file" ? "doc" : "image",
        };
        yield call(StudentApi.uploadDocument, id, obj, firebase);

      }
      yield put({
        type: actions.UPLOAD_LEAD_DOC_SUCCESS,
        operationRequest: "UPLOAD_DOC",
      });
    } else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload lead document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* fetchLeadDocument({ leadId, firebase }) {
  try {
    let data = yield call(LeadsApi.getLeadDocument, leadId, firebase);
    if (data) {
      yield put({
        type: actions.GET_LEAD_DOC_SUCCESS,
        leadDoc: data,
      });
    }
  } catch (err) {
    console.log("failed to get lead document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* triggerStatusChangeApi(leadId, firebase) {
  try {
    let requestBody = { leadId: leadId };
    let endpoint = "woodlandApi/queueLeadTasks/?centerId=";

    yield call(
      StudentAttendanceApi.requestApi,
      requestBody,
      endpoint,
      firebase
    );
  } catch (err) {
    console.log("failed to trigger status change api", err);
    bugsnagClient.notify(err);
  }
}

function* updateChangedLeadStatus({ selectedLeadStatus, lead, firebase, deleteStudent }) {
  try {
    if (deleteStudent) {
      var studentDeleteResponse = yield call(StudentApi.deleteStudentNewApi, firebase, lead.studentId);
      lead.studentId = null;
      lead.admissionDate = null;

    }


    let url = "woodlandApi/leadsApi/save/?centerId=";
    lead.leadStatus = selectedLeadStatus.key;
    let response = yield call(
      StudentAttendanceApi.requestApi,
      lead,
      url,
      firebase
    );

    if (response.status && response.status === 200) {

      yield fork(
        NotificationApi.callDashboardRefreshApi,
        firebase,
        "leads",
        new Date()
      );
      yield put({
        type: actions.UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateLead")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to update lead status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* linkParent({ studentObj, firebase, linkForParentOne }) {
  try {
    let parentId = studentObj.parentId;
    let relation = studentObj.parentType;

    let response = yield call(
      StudentApi.linkParentApi,
      firebase,
      parentId,
      studentObj.originalStudent.id,
      relation
    );

    if (response && response.status === 200) {
      if (
        studentObj.originalStudent &&
        studentObj.originalStudent.parentTwoName &&
        linkForParentOne
      ) {
        yield put({
          type: actions.LEAD_PARENT_ONE_ADDED,
          tempStudentData: studentObj.originalStudent,
        });
      } else {
        yield put({
          type: actions.LEAD_LINK_PARENT_SUCCESS,
          tempStudentData: undefined,
        });
      }
    } else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : "Failed to link parent. Please contact school or Illumine",
      });
    }
  } catch (err) {
    console.log("failed to link parent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* addParentTwo({ studentObj, firebase }) {
  try {
    if (studentObj.parentTwoPhone || studentObj.parentTwoEmail) {
      let p2AddResponse = yield call(
        StudentApi.addParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        studentObj.parentTwoName,
        Number(studentObj.parentTwoPhone),
        studentObj.parentTwoEmail,
        "parent2",
        studentObj,
        studentObj.parentTwoCountryCode ? studentObj.parentTwoCountryCode : null,
        studentObj.parentTwoConfig ? studentObj.parentTwoConfig : null,
        studentObj.parentTwoCompany ? studentObj.parentTwoCompany : null
      );
      if (p2AddResponse && p2AddResponse.status === 200) {
        yield put({
          type: actions.LEAD_PARENT_TWO_ADDED,
          tempStudentData: undefined,
        });
      } else if (p2AddResponse && (p2AddResponse.status === 201 || p2AddResponse.status === 202)) {
        let siblingObj;
        let parentId;
        if (p2AddResponse.status === 201) {
          siblingObj = p2AddResponse.body.student;
          parentId = p2AddResponse.body.parentId;
        }
        else if (p2AddResponse.status === 202) {
          siblingObj = {};
          parentId = p2AddResponse.body.userNode.id;
        }
        siblingObj.originalStudent = studentObj;
        siblingObj.parentType = "parent2";
        siblingObj.parentId = parentId;
        if (p2AddResponse.status === 202) {
          siblingObj.message = "Already added as a teacher. Do you want to link \"" + studentObj.name + "\" as a child ?";
        } else {
          siblingObj.message = "Already added as a parent of " + siblingObj.name + ". Do you want to add \"" + studentObj?.name + "\" as a sibling of \"" + siblingObj.name + "\" ?";
        }
        yield put({
          type: actions.STUDENT_LINK_FOUND,
          existingDetail: siblingObj,
        });
      } else {
        yield put({
          type: actions.LEAD_PARENT_TWO_FAILED,
          tempStudentData: undefined,
          errorMessage:
            p2AddResponse.body && p2AddResponse.body.response
              ? p2AddResponse.body.response
              : "Failed to add parent two. Please contact school or Illumine",
        });
      }
    }
  } catch (err) {
    console.log("failed to add parent two", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_PARENT_TWO_FAILED,
      tempStudentData: undefined,
      errorMessage: "Failed to add parent two. Please contact school or Illumine",
    });
  }
}

function* addParentOne({ studentObj, firebase }) {
  try {
    if (studentObj.parentOnePhone || studentObj.parentOneEmail) {
      let p1AddResponse = yield call(
        StudentApi.addParentApi,
        firebase,
        studentObj.name,
        studentObj.id,
        studentObj.parentOneName,
        Number(studentObj.parentOnePhone),
        studentObj.parentOneEmail,
        "parent1",
        studentObj,
        studentObj.parentOneCountryCode ? studentObj.parentOneCountryCode : null,
        studentObj.parentOneConfig ? studentObj.parentOneConfig : null,
        studentObj.parentOneCompany ? studentObj.parentOneCompany : null
      );

      if (p1AddResponse && p1AddResponse.status === 200) {
        studentObj.parentOneAdded = true;

        yield put({
          type: actions.LEAD_PARENT_ONE_ADDED,
          tempStudentData: studentObj.parentTwoName ? studentObj : undefined,
        });
      } else if (p1AddResponse && (p1AddResponse.status === 201 || p1AddResponse.status === 202)) {
        let siblingObj;
        let parentId;
        if (p1AddResponse.status === 201) {
          siblingObj = p1AddResponse.body.student;
          parentId = p1AddResponse.body.parentId;
        }
        else if (p1AddResponse.status === 202) {
          siblingObj = {};
          parentId = p1AddResponse.body.userNode.id;
        }
        siblingObj.originalStudent = studentObj;
        siblingObj.parentType = "parent1";
        siblingObj.parentId = parentId;
        if (p1AddResponse.status === 202) {
          siblingObj.message = "Already added as a teacher. Do you want to link \"" + studentObj.name + "\" as a child ?";
        } else {
          siblingObj.message =
            "Already added as a parent of " + siblingObj.name + ". Do you want to add \"" + studentObj.name + "\" as a sibling of \"" + siblingObj.name + "\" ?";
        }
        yield put({
          type: actions.STUDENT_LINK_FOUND,
          existingDetail: siblingObj,
          linkForParentOne: true,
        });
      } else {
        studentObj.parentOneAdded = false;
        yield put({
          type: actions.LEAD_PARENT_ONE_FAILED,
          tempStudentData: studentObj.parentTwoName ? studentObj : undefined,
          errorMessage:
            p1AddResponse.body && p1AddResponse.body.response
              ? p1AddResponse.body.response
              : "Failed to add parent one. Please contact school or Illumine",
        });
      }
    } else {
      studentObj.parentOneAdded = false;
      yield put({
        type: actions.LEAD_PARENT_ONE_FAILED,
        tempStudentData: studentObj,
        errorMessage: undefined,
      });
    }
  } catch (err) {
    console.log("failed to add parent one", err);
    bugsnagClient.notify(err);
    studentObj.parentOneAdded = false;
    yield put({
      type: actions.LEAD_PARENT_ONE_FAILED,
      tempStudentData: studentObj,
      errorMessage: "Failed to add parent one. Please contact school or Illumine",
    });
  }
}


function* addAccount({ record, firebase, incomingGender, incomingStatus }) {
  try {
    let studentName = record.student.name;
    let studentGender = incomingGender;
    let studentClass = record.classroomName;
    let birthDate = record.student.birthDate ? record.student.birthDate : undefined;
    let address = record.student && record.student.address ? record.student.address : null;
    let preferredName =
      record.student && record.student.preferredName ? record.student.preferredName : null;

    let parentOnePhone = record.parentOne && record.parentOne.phone ? record.parentOne.phone : null;
    let parentOneEmail = record.parentOne && record.parentOne.email ? record.parentOne.email : null;
    let parentOneName = record.parentOne && record.parentOne.name ? record.parentOne.name : null;
    let parentOneCompany =
      record.parentOne && record.parentOne.organization ? record.parentOne.organization : null;
    let parentOneCountryCode = record.parentOne && record.parentOne.countryCode ? record.parentOne.countryCode : null;

    let parentTwoPhone = record.parentTwo && record.parentTwo.phone ? record.parentTwo.phone : null;
    let parentTwoEmail = record.parentTwo && record.parentTwo.email ? record.parentTwo.email : null;
    let parentTwoName = record.parentTwo && record.parentTwo.name ? record.parentTwo.name : null;
    let parentTwoCountryCode = record.parentTwo && record.parentTwo.countryCode ? record.parentTwo.countryCode : null;

    let parentTwoCompany =
      record.parentTwo && record.parentTwo.organization ? record.parentTwo.organization : null;

    let childConfig = record.studentAdditionalFields ? record.studentAdditionalFields : null;

    let parentOneConfig = record.parentOneAdditionalFields
      ? record.parentOneAdditionalFields
      : null;

    let parentTwoConfig = record.parentTwoAdditionalFields
      ? record.parentTwoAdditionalFields
      : null;

    let studentObj = {
      studentName: studentName,
      birthDate: birthDate ? moment(birthDate).format("YYYY[-]MM[-]DD") : null,
      classroom: [studentClass],
      classList: [studentClass],
      gender: studentGender,
      platform: "web",
      emergencyContactName: record.student.emergencyContactName,
      emergencyNumber: record.student.emergencyNumber,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
      dateCreated: moment().valueOf(),
      deactivated: false,
      deactivationDate: 0,
      deleted: false,
      status: incomingStatus ? incomingStatus : "Active",
      present: false,
      section: false,
      address: address,
      prefName: preferredName,
      additionalFields: childConfig,
      leadId: record.id,
    };

    let genericMessage =
      "Failed to add student. Please contact school or Illumine";
    let addResponse = yield call(
      StudentApi.addStudentApi,
      firebase,
      studentObj
    );

    if (addResponse && addResponse.status === 200) {
      let studentRecord = addResponse.body.response;

      studentRecord.studentAdded = true;
      studentRecord.parentOneName = parentOneName;
      studentRecord.parentOnePhone = parentOnePhone;
      studentRecord.parentOneEmail = parentOneEmail;
      studentRecord.parentTwoName = parentTwoName;
      studentRecord.parentTwoPhone = parentTwoPhone;
      studentRecord.parentTwoEmail = parentTwoEmail;
      studentRecord.leadId = record.id;
      studentRecord.parentOneConfig = parentOneConfig;
      studentRecord.parentTwoConfig = parentTwoConfig;
      studentRecord.parentOneCompany = parentOneCompany;
      studentRecord.parentTwoCompany = parentTwoCompany;
      studentRecord.parentOneCountryCode = parentOneCountryCode;
      studentRecord.parentTwoCountryCode = parentTwoCountryCode;

      yield put({
        type: actions.LEAD_STUDENT_ADDED,
        tempStudentData: studentRecord,
      });
      var studentId = studentRecord.id;
      let leadId = studentRecord.leadId;
      record.studentId = studentRecord.id;
      record.admissionDate = moment().valueOf();
      let leadsValue = record;
      leadsValue.leadStatus = "Admitted";


      let incomingLeadsValue = leadsValue;
      yield fork(updateExistingLead, {
        incomingLeadsValue,
        record: record,
        firebase: firebase,
        isAddAccount: "addAcount",
        newStudentData: undefined,
        isStudentDelete: undefined,
      });
      let leadDocument = yield call(LeadsApi.getLeadDocument, leadId, firebase);
      if (leadDocument && studentRecord.id && studentRecord.leadId) {
        for (let l in leadDocument) {
          let docObj = leadDocument[l];
          yield fork(StudentApi.pushLeadDocument, studentId, firebase, docObj);
        }
      }
    } else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
        errorMessage:
          addResponse.body && addResponse.body.response
            ? addResponse.body.response
            : genericMessage,
      });
    }

    yield fork(
      NotificationApi.callDashboardRefreshApi,
      firebase,
      "leads",
      new Date()
    );
  } catch (err) {
    console.log("Failed to add leads account to illumine", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* deleteSelectedLead({ record, firebase }) {
  try {
    // yield call(LeadsApi.deleteLead, record, firebase);
    let url = "woodlandApi/leadsApi/delete/" + record.id + "?centerId=";
    let obj = {
      id: record.id,
    };
    let response = yield call(ApplicationApi.deleteApi, obj, url, firebase);
    if (response && response.status === 200) {
      yield call(LeadsApi.deleteAllTaskForSelectedLead, record, firebase);
      yield put({
        type: actions.DELETE_LEAD_SUCCESS,
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : "failed to delete lead"
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete lead", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* deleteSelectedLeadStatus({ record, firebase }) {
  try {
    yield call(LeadsApi.deleteLeadStatus, record, firebase);
    yield put({
      type: actions.DELETE_LEAD_STATUS_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to delete lead status", err);
  }
}

function* deleteSelectedLeadCategory({ record, category, firebase }) {
  try {
    let nodeEndPoint;
    if (category === "status") {
      nodeEndPoint = "/leadsStatus/";
    } else if (category === "source") {
      nodeEndPoint = "/leadsSource/";
    } else if (category === "subsource") {
      nodeEndPoint = "/leadsSubsource/";
    }
    yield call(
      LeadsApi.deleteSelectedLeadCategory,
      record,
      nodeEndPoint,
      firebase
    );
    notification("success", formatMsg("success.deleteCategory"));
    yield put({
      type: actions.DELETE_LEAD_CATEGORY_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to delete lead status", err);
  }
}
function* fetchLeadAdditionalField({ firebase }) {
  try {
    let data = yield call(LeadsApi.getAdditionalFields, firebase);
    if (data) {
      yield put({
        type: actions.GET_LEADS_ADDITIONAL_FIELD_SUCCESS,
        leadAdditionalField: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch leads additional fields", err);
    bugsnagClient.notify(err);
  }
}

function* getLeadsExcel({ firebase, values }) {
  try {
    let data = yield call(LeadsApi.getLifetimeLeadsData, firebase);
    let startDate = values.dateRange[0];
    let endDate = values.dateRange[1];
    FilterAction.downloadLeadsExcel(data, startDate, endDate);
    yield put({
      type: actions.DOWNLOAD_LEADS_EXCEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download leads excel", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}
function* fetchAllLeadsTasksList({ firebase, startDate, endDate }) {
  const chan = yield call(
    LeadsApi.fetchAllLeadsTasks,
    "Task",
    firebase,
    startDate,
    endDate
  );
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.FETCH_ALLLEAD_TASK_SUCESS,
        fetchAllLeadsTasks: data,
        fetchAllLeadStudentName: {},
        fetchAllLeadsTaskchannel: chan,
      });

      let leadMap = {};
      let taskList = [];

      for (let i = 0; i < data.length; i++) {
        let task = call(LeadsApi.fetchLeadsForTask, data[i].leadId, firebase);
        taskList.push(task);
      }
      let newVal = yield all([taskList]);
      for (let i in newVal[0]) {
        let data = newVal[0][i];
        if (data && data.id) {
          leadMap[data.id] = data;
        }
      }

      yield put({
        type: actions.FETCH_ALLLEAD_TASK_SUCESS,
        fetchAllLeadsTasks: data,
        fetchAllLeadStudentName: leadMap,
        fetchAllLeadsTaskchannel: chan,
      });
    }
  } finally {
    console.log("end leadsTask  channel");
  }
}

function* updateNextFollowup({ values, record, firebase }) {

  try {

    let url = "woodlandApi/leadsApi/save/?centerId=";

    record.nextFollowUpDate = values.nextFollowUpDate

      ? moment(values.nextFollowUpDate).valueOf()
      : null;

    let response = yield call(
      StudentAttendanceApi.requestApi,
      record,
      url,
      firebase
    );
    if (response && response.status === 200) {
      notification("success", formatMsg("success.updateLead"));
      yield put({
        type: actions.UPDATE_NEXTFOLLOWUP_SUCCESS,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateLead")
      );
    }
  } catch (err) {
    console.log("failed to update  nextfollowupdate", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* markdoneNextFollowup({ values, leadRecord, firebase }) {
  try {
    let object = {
      id: leadRecord.id,
      description: values.additionalDescription
        ? values.additionalDescription
        : undefined,
      date: values.date ? moment(values.date).valueOf() : null,
      time: values.date ? moment(values.date).valueOf() : null,
      createdBy: firebase.teacher.name,
      nextFollowUpDate: values.nextFollowUpDate
        ? moment(values.nextFollowUpDate).valueOf()
        : null,
    };

    let endPointUrl = "woodlandApi/leadsApi/completeFollowup/?centerId=";
    let activityResponse = yield call(
      StudentAttendanceApi.requestApi,
      object,
      endPointUrl,
      firebase
    );
    if (activityResponse.status && activityResponse.status === 200) {
      notification("success", formatMsg("markDoneSuccessfully"));
      yield put({
        type: actions.MARK_DONE_NEXTFOLLOWUP_SUCCESS,

      });
    }
    else {
      notification(
        "error",
        activityResponse && activityResponse.body && activityResponse.body.response
          ? activityResponse.body.response
          : formatMsg("error.updateLead")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }

  } catch (err) {
    console.log("failed to update lead status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}





function* fetchStudent({ record, firebase }) {
  try {
    let data = yield call(LeadsApi.FetchStudent, record.leadId, firebase);

    if (data) {
      yield put({
        type: actions.FETCH_STUDENT_SUCCESS,
        fetchstudent: data,
      });
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* fetchTaskLabel({ firebase }) {
  try {
    let data = yield call(
      ActivityApi.getCustomLabelByCategory,
      "Lead Task",
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_TASK_LABEL_SUCESS,
        tasksLabel: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch template label", err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}
function* updateTask({ values, record, firebase, lead }) {
  try {


    let obj = createobjectForLeadActivityOrTask(values, lead, firebase, record);
    obj["time"] = obj["date"];
    let url = "woodlandApi/leadsApi/saveLeadActivities/?centerId=";

    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      url,
      firebase
    );
    if (response && response.status === 200) {
      notification("success", formatMsg("success.updateTask"));
      yield put({
        type: actions.UPDATE_TASK_SUCESS,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateTask")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to update task activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}
function* fetchTeacherList() {
  let teachers = JSON.parse(getItem("teacherList"));
  yield put({
    type: actions.GET_TEACHER_LIST_SUCESS,
    teacherLists: teachers,
  });
}

function* fetchCommentsForSelectedTask({ task, firebase }) {
  try {
    let activities = task;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }

    yield put({
      type: actions.GET_COMMENT_FOR_TASK_SUCCESSFUL,
      taskComment: commentsCount,
    });
  } catch (error) {
    console.log("failed to fetch comment for selected task", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}
function* deleteLeadsActivity({ item, leadId, firebase }) {

  try {
    let url = "woodlandApi/leadsApi/deleteLeadActivity/" + item.id + "?centerId=";
    let obj = {
      id: item.id,
    };
    let response = yield call(ApplicationApi.deleteApi, obj, url, firebase);

    if (response && response.status === 200) {

      notification("success", formatMsg("success.deleteSelectedActivity"));
      yield put({
        type: actions.DELETE_LEAD_ACTIVITY_SUCCESS,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteLeadActivity")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete leads activity", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}

function* deleteLeadsTask({ item, leadId, firebase }) {

  try {
    let url = "woodlandApi/leadsApi/deleteLeadActivity/" + item.id + "?centerId=";
    let obj = {
      id: item.id,
    };
    let response = yield call(ApplicationApi.deleteApi, obj, url, firebase);

    if (response && response.status === 200) {


      notification("success", formatMsg("success.deleteTask"));

      yield put({
        type: actions.DELETE_LEAD_TASK_SUCCESS,
      });
    }
    else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteLeadTask")
      );
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete leads task", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}



function* fetchEmailTemplate({ firebase }) {
  try {
    var data = yield call(LeadsApi.fetchAllEmailTemplate, firebase);

    if (data) {
      yield put({
        type: actions.FETCH_EMAIL_TEMPLATE_SUCCESSFUL,
        emailTemplateData: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee programs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* deleteLeadsEmail({ item, leadId, firebase }) {
  try {
    yield call(LeadsApi.deleteLeadEmail, item, leadId, firebase);
    notification("success", formatMsg("success.deleteEmail"));
    yield put({
      type: actions.DELETE_LEAD_EMAIL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete leads email", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
      errorMessage: err.message ? err.message : undefined,
    });
  }
}
function* fetchSelectedTask({ taskId, firebase }) {
  const chan = yield call(LeadsApi.fetchSelectedTask, taskId, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.FETCH_TASK_BY_ID_SUCCESS,
        selectedTask: data,
        selectedTaskChannel: chan,
      });
    }
  } finally {
    console.log("end leads channel");
  }
}

function* fetchLeadDashboardStats({ start, end, firebase, category }) {
  let endpoint;
  switch (category) {
    case "total leads":
      endpoint = "reportsApi/report/lead/leadByDateRange?centerId=";
      break;

    case "total coversion count":
      endpoint = "reportsApi/report/lead/conversion?centerId=";
      break;

    case "followup due":
      endpoint = "reportsApi/report/lead/dueFollowUp?centerId=";
      break;

    case "task due count":
      endpoint = "reportsApi/report/lead/dueTask?centerId=";
      break;

    case "lastSevenDays":
      endpoint = "reportsApi/report/lead/lastSevenDays?centerId=";
      break;

    case "sourceReport":
      endpoint = "reportsApi/report/lead/sourceReport?centerId=";
      break;

    case "conversion":
      endpoint = "reportsApi/report/lead/conversion?centerId=";
      break;

    case "followup due list":
      endpoint = "reportsApi/report/lead/dueAndUpcomingFollowup?centerId=";
      break;

    case "dueTask":
      endpoint = "reportsApi/report/lead/dueTask?centerId=";
      break;

    case "upcomingAdmissions":
      endpoint = "reportsApi/report/lead/upcomingAdmissions?centerId=";
      break;

    case "dealStage":
      endpoint = "reportsApi/report/lead/status?centerId=";
      break;

    case "communicationReport":
      endpoint = "reportsApi/report/lead/communicationReport?centerId=";
      break;
  }

  if (endpoint) {
    yield fork(getLeadDashboardData, start, end, firebase, endpoint, category);
  }
}

function* getLeadDashboardData(start, end, firebase, endpoint, category) {
  try {
    let obj = {
      startDate: moment(start).valueOf(),
      endDate: moment(end).valueOf(),
    };

    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      endpoint,
      firebase
    );

    if (response && response.status === 200) {
      yield fork(putLeadDashboardData, response, category);
      yield put({
        type: actions.STOP_LEAD_DASHBOARD_SPINNER,
      });
    } else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : "Failed to get" +
            category +
            " data. Please contact school or Illumine.",
      });
    }
  } catch (err) {
    console.log("failed to get lead dashboard data" + endpoint + " " + err);
    bugsnagClient.notify(err);
  }
}

function* putLeadDashboardData(response, category) {
  try {
    switch (category) {
      case "total leads":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_LEAD,
          totalLead: response.body.reportRequest.rawData.length,
        });
        break;

      case "total coversion count":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_CONVERSION_COUNT,
          totalCoversionCount: getConversionCountFromMap(response),
        });
        break;

      case "followup due":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_FOLLOW_UP,
          totalFollowUpDue: response.body.reportRequest.rawData.length,
        });
        break;

      case "task due count":
        yield put({
          type: actions.NEW_DASHBOARD_TASK_DUE_COUNT,
          taskDueCount: getDueTaskList(response, "overDueTask").length,
        });
        break;

      case "lastSevenDays":
        yield put({
          type: actions.NEW_DASHBOARD_LAST_7_DAYS,
          lastSevenDays: response.body.reportRequest.rawData,
        });
        break;

      case "sourceReport":
        yield put({
          type: actions.NEW_DASHBOARD_SOURCE_REPORT,
          sourceReport: getDataFromDashboardMap(
            response,
            "Total Leads",
            "Conversions",
            true,
            false,
            true
          ),
          sourceReportRaw: response.body.reportRequest.rawData,
        });
        break;

      case "conversion":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_CONVERSION,
          totalConversion: getDataFromDashboardMap(
            response,
            "Total Leads",
            "Conversions"
          ),
          totalConversionRaw: response.body.reportRequest.rawData,
        });
        break;

      case "followup due list":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_FOLLOW_UP_LIST,
          followUpDueList: getLeadObjectFromResponse(response),
          followUpDueListRaw: response.body.reportRequest.rawData,
        });
        break;

      case "dueTask":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_DUE_TASK_LIST,
          dueTaskList: getDueTaskList(response, "upcomingTask"),
          dueTaskListRaw: response.body.reportRequest.rawData,
        });
        break;

      case "upcomingAdmissions":
        yield put({
          type: actions.NEW_DASHBOARD_TOTAL_UPCOMING_ADMISSION,
          upcomingAdmission: getLeadObjectFromResponse(response, true),
          upcomingAdmissionRaw: response.body.reportRequest.rawData,
        });
        break;

      case "dealStage":
        yield put({
          type: actions.NEW_DASHBOARD_DEAL_STAGE_LIST,
          dealStageList: getDataFromDashboardMap(
            response,
            undefined,
            undefined,
            false,
            true,
            true
          ),
          dealStageListRaw: response.body.reportRequest.rawData,
        });
        break;

      case "communicationReport":
        let commList = getDataFromDashboardMap(
          response,
          undefined,
          undefined,
          false,
          true
        );

        commList.forEach((ele) => {
          if (ele.name.toLowerCase() === "email") {
            ele.color = "#2B99F7";
          } else if (ele.name.toLowerCase() === "phone call") {
            ele.color = "#FD8E56";
          } else if (ele.name.toLowerCase() === "text") {
            ele.color = "#F8C545";
          }
        });

        yield put({
          type: actions.NEW_DASHBOARD_COMMUNICATION_REPORT,
          communicationReport: commList,
          communicationReportRaw: response.body.reportRequest.rawData,
        });
        break;
    }
  } catch (err) {
    console.log("faile to put lead dashboard data " + category + " " + err);
    bugsnagClient.notify(err);
  }
}

function getConversionCountFromMap(response) {
  let responseMap = response.body.reportRequest.map;
  let count = 0;
  for (var key of Object.keys(responseMap)) {
    count = count + responseMap[key]["conversions"];
  }

  return count;
}

function getDataFromDashboardMap(
  response,
  key1,
  key2,
  sourceCaculation,
  generateKeyDynamically,
  sort
) {
  let responseMap = response.body.reportRequest.map;
  let result = [];
  for (var key of Object.keys(responseMap)) {
    if (generateKeyDynamically) {
      result.push({
        name: key,
        value: responseMap[key],
      });
    } else {
      result.push({
        // name: sort ? key.substr(0, 6) : key,
        name: key,
        [key1]: sourceCaculation
          ? responseMap[key].count
          : responseMap[key].totalLeads,
        [key2]: sourceCaculation
          ? responseMap[key].conversion
          : responseMap[key].conversions,
      });
    }
  }

  if (sort && sourceCaculation) {
    result = result.sort((a, b) => {
      if (!a || !a[key1]) {
        return 1;
      }

      if (!b || !b[key1]) {
        return -1;
      }

      return b[key1] - a[key1];
    });

    result = result.splice(0, 5);
  } else {
    if (sort) {
      result.sort((a, b) => FilterAction.sortStringValue(a, b, "name"));
    }
  }

  return result;
}

function getDueTaskList(response, category) {
  let dueTaskResp = response.body.reportRequest[category];

  let dueTaskResult = [];
  dueTaskResp.forEach((dueEle) => {
    dueTaskResult.push({
      name: dueEle.leadName,
      lastFollowUp: dueEle.lastFollowUpDate
        ? moment(dueEle.lastFollowUpDate).format("DD[ ]MMM[ ']YY")
        : null,
      dueOn: dueEle.nextFollowUpDate
        ? moment(dueEle.nextFollowUpDate).format("DD[ ]MMM[ ']YY")
        : null,
      id: dueEle.id,
      leadId: dueEle.leadId,
      description: dueEle.description,
    });
  });
  return dueTaskResult;
}

function getLeadObjectFromResponse(response, pickExpStartDate) {
  let followUpResp = response.body.reportRequest.rawData;
  let followUpResult = [];
  followUpResp.forEach((ele) => {
    followUpResult.push({
      name: ele.student.name,
      lastFollowUp: ele.lastFollowUpDate
        ? moment(ele.lastFollowUpDate).format("DD[ ]MMM[ ']YY")
        : null,
      dueOn: pickExpStartDate
        ? ele.expectedStartDate
          ? moment(ele.expectedStartDate).format("DD[ ]MMM[ ']YY")
          : null
        : ele.nextFollowUpDate
          ? moment(ele.nextFollowUpDate).format("DD[ ]MMM[ ']YY")
          : null,
      gender: ele.student.gender,
      id: ele.id,
    });
  });

  return followUpResult;
}

function* getTotalLeadAutomation({ firebase }) {
  try {
    var data = yield call(LeadsApi.fetchAllLeadAutomation, firebase);
    if (data) {
      yield put({
        type: actions.FETCH_LEAD_AUTOMATION_COUNT_SUCCESS,
        totalLeadAutomationCount: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch leadAutomation", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* saveAssignTo({
  leadRecord, firebase
}) {
  try {
    let url = "woodlandApi/leadsApi/save/?centerId=";

    let response = yield call(
      StudentAttendanceApi.requestApi,
      leadRecord,
      url,
      firebase
    );
    if (response && response.status === 200) {
      notification("success", formatMsg("success.updateLead"));
      yield put({
        type: actions.LEAD_ASSIGN_TO_SUCCESS
      });
    }
    else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : formatMsg("error.assignTo")

      });
    }
  }
  catch (err) {
    console.log("Failed add to assign lead", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }

}
function* getSharedForms({ firebase, id }) {
  try {
    var data = yield call(LeadsApi.getSharedForms, firebase, id);
    if (data) {
      yield put({
        type: actions.GET_SHARED_FORMS_SUCCESS,
        sharedForms: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch leadAutomation", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* deleteFollowUpInLead({ leadRecord, firebase }) {
  try {
    let url = "woodlandApi/leadsApi/save/?centerId=";

    let response = yield call(
      StudentAttendanceApi.requestApi,
      leadRecord,
      url,
      firebase
    );
    if (response && response.status === 200) {
      notification("success", formatMsg("success.updateLead"));
      yield put({
        type: actions.DELETE_FOLLOW_UP_SUCCESS
      });
    }
    else {
      yield put({
        type: actions.LEAD_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : formatMsg("error.assignTo")

      });
    }
  }
  catch (err) {
    console.log("Failed delete follow up lead", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }

}

function* fetchCommentsForLeadsActivity({ leadActivity, firebase }) {
  try {
    let activities = leadActivity;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }

    yield put({
      type: actions.GET_COMMENT_FOR_LEAD_ACTIVITY_SUCCESS,
      leadActivityComment: commentsCount,
    });
  } catch (error) {
    console.log("failed to fetch comment for selected task", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAD_REQUEST_FAILED,
    });
  }
}

function* reloadPage({ }) {
  yield put({
    type: actions.RELOAD_PAGE_DATA_SUCCESS,
  });
}
function* addStatusValues({ status, statusKey, statusValue, firebase }) {
  try {
    yield call(LeadsApi.addStatusValues, status, statusKey, statusValue, firebase)
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    notification("error", error);

  }

}

function* getAdmittedLeads({ firebase, startDate, endDate }) {
  try {
    let sDate = null;
    let eDate = null;
    if (!startDate) {
      sDate = 946665000000;
    }
    else {
      sDate = moment(startDate).valueOf();
    }
    if (!endDate) {
      eDate = moment().valueOf();
    }
    else {
      eDate = moment(endDate).valueOf();
    }

    let url = "reportsApi/report/lead/conversion?centerId=" + firebase.sbDbName;

    let response = yield callApi(firebase, "post", url, { "startDate": sDate, "endDate": eDate });
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.GET_ADMITTED_LEADS_SUCCESS,
        admittedLeads: response.body?.reportRequest?.rawData ?? [],
      });
    }
    else {
      yield put({
        type: actions.GET_ADMITTED_LEADS_SUCCESS,
        admittedLeads: []
      });
    }
  }
  catch (err) {
    console.log("failed to get addmitted leads data " + err);
    bugsnagClient.notify(err);
  }
}


export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.FETCH_EMAIL_TEMPLATE, fetchEmailTemplate),
    yield takeLatest(actions.DELETE_LEAD_EMAIL, deleteLeadsEmail),
    yield takeLatest(actions.ADD_EMAIL, addNewEmail),
    yield takeLatest(actions.GET_EMAIL_TEMPLATE_REGISTER, getEmailTemplateRegister),
    yield takeLatest(actions.DELETE_EMAIL_TEMPLATE, deleteSelectedEmailTemplate),
    yield takeLatest(actions.UPDATE_EMAIL_TEMPLATE, updateSelectedEmailTemplate),
    yield takeLatest(actions.ADD_LEAD_EMAIL_TEMPLATE, addNewTemplate),

    yield takeLatest(actions.LIST_LEADS, fetchLeads),
    yield takeLatest(actions.GET_FEE_PROGRAM_FOR_LEADS, fetchFeePrograms),
    yield takeLatest(actions.GET_STATUS, fetchLeadsStatus),
    yield takeLatest(actions.GET_SOURCE, fetchLeadsSource),
    yield takeLatest(actions.GET_SUBSOURCE, fetchLeadsSubSource),
    yield takeEvery(actions.SAVE_NEW_LEAD, addNewLead),
    yield takeEvery(actions.UPDATE_LEAD, updateExistingLead),
    yield takeLatest(actions.ADD_STATUS, addNewStatus),
    yield takeLatest(actions.MANAGE_LEAD, addNewStatusSourceSubsource),

    yield takeLatest(actions.GET_LEAD_DETAIL, fetchSelectedLeadDetail),
    yield takeLatest(actions.ADD_ACTIVITY, addNewActivity),
    yield takeLatest(actions.ADD_TASK, addNewTask),

    yield takeLatest(actions.GET_LEAD_ACTIVITY, fetchSelectedLeadActivity),
    yield takeLatest(actions.UPDATE_LEAD_STATUS_CHANGE, updateChangedLeadStatus),
    yield takeLatest(actions.CREATE_NEW_ACCOUNT, addAccount),
    yield takeLatest(actions.DELETE_LEAD, deleteSelectedLead),
    yield takeLatest(actions.DELETE_LEAD_STATUS, deleteSelectedLeadStatus),
    yield takeLatest(actions.DELETE_LEAD_CATEGORY, deleteSelectedLeadCategory),
    yield takeLatest(actions.GET_LEADS_ADDITIONAL_FIELD, fetchLeadAdditionalField),
    yield takeLatest(actions.DOWNLOAD_LEADS_EXCEL, getLeadsExcel),
    yield takeLatest(actions.DELETE_LEAD_ACTIVITY, deleteLeadsActivity),
    yield takeLatest(actions.DELETE_LEAD_TASK, deleteLeadsTask),



    yield takeLatest(actions.LEAD_ADD_PARENT_ONE, addParentOne),
    yield takeLatest(actions.LEAD_ADD_PARENT_TWO, addParentTwo),
    yield takeLatest(actions.LEAD_LINK_PARENT, linkParent),
    yield takeLatest(actions.GET_LEAD_DOC, fetchLeadDocument),
    yield takeLatest(actions.ADD_LEAD_DOC_FOLDER, addDocumentFolder),
    yield takeLatest(actions.DELETE_LEAD_DOC, deleteLeadDocument),
    yield takeLatest(actions.RENAME_LEAD_DOC_FOLDER, renamedDocStudent),
    yield takeLatest(actions.UPLOAD_LEAD_DOC, uploadLeadDocument),
    yield takeLatest(actions.FETCH_ALLLEAD_TASK, fetchAllLeadsTasksList),
    yield takeLatest(actions.GET_TASK_LABEL, fetchTaskLabel),
    yield takeLatest(actions.UPDATE_TASK, updateTask),
    yield takeLatest(actions.GET_COMMENT_FOR_TASK, fetchCommentsForSelectedTask),
    yield takeLatest(actions.GET_TEACHER_LIST, fetchTeacherList),
    //yield takeLatest(actions.MARK_DONE, markdonetask),
    yield takeLatest(actions.UPDATE_NEXTFOLLOWUP, updateNextFollowup),
    yield takeLatest(actions.MARK_DONE_NEXTFOLLOWUP, markdoneNextFollowup),
    yield takeLatest(actions.FETCH_STUDENT, fetchStudent),
    yield takeLatest(actions.FETCH_TASK_BY_ID, fetchSelectedTask),
    yield takeEvery(actions.GET_NEW_LEAD_DASHBOARD_STATS, fetchLeadDashboardStats),
    yield takeLatest(actions.FETCH_MANAGE_LEAD, getmanageLead),
    yield takeLatest(
      actions.FETCH_LEAD_AUTOMATION_COUNT,
      getTotalLeadAutomation
    ),
    yield takeLatest(
      actions.GET_SHARED_FORMS,
      getSharedForms
    ),
    yield takeLatest(actions.LEAD_ASSIGN_TO, saveAssignTo),
    yield takeLatest(actions.DELETE_FOLLOW_UP, deleteFollowUpInLead),
    yield takeLatest(actions.GET_COMMENT_FOR_LEAD_ACTIVITY, fetchCommentsForLeadsActivity),
    yield takeLatest(actions.RELOAD_PAGE_DATA, reloadPage),
    yield takeLatest(actions.ADD_STATUS_VALUE, addStatusValues),
    yield takeLatest(actions.GET_ADMITTED_LEADS, getAdmittedLeads),
  ]);

}
