import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { UserSettingApi } from "../../firestore-api/userSetting";
import actions from "./actions";
import { callApi } from "../../Utility/superAgentUntil";


import formatMsg from "../../components/utility/formatMessageUtil";
import moment from "moment";

function* fetchUserSettingDetail({ firebase, initialCall }) {
  try {
    yield put({
      type: actions.GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL,
      schoolSettingDetails: firebase.schoolConfig,
      operationRequest: initialCall ? "INIT_CALL" : "",
    });
  } catch (err) {
    console.log("Failed to fetch user setting", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* uploadSchoolLogo({ file, firebase }) {
  try {
    var url = yield call(UserSettingApi.updateSchoolLogoToStorage, file, firebase);
    if (url) {
      let config = firebase.schoolConfig;
      config.logoUrl = url[0];
      config.updatedBy = firebase.teacher.name;
      config.platform = "web";

      let endpoint = "woodlandApi/firebaseAccount?centerId=";

      let response = yield call(StudentAttendanceApi.requestApi, config, endpoint, firebase);

      if (response && response.status && response.status === 200) {
        notification("success", formatMsg("success.logoUpload"));
        yield put({
          type: actions.UPLOAD_SCHOOL_LOGO_SUCCESSFUL,
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : formatMsg("error.logoUpload")
        );
        yield put({
          type: actions.SCHOOL_SETTING_REQUEST_FAILED,
        });
      }
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateUserSettingInfo({ userInfo, firebase }) {
  try {
    // firebase.updateSchoolName(userInfo.schoolName);

    let config = firebase.schoolConfig;
    config.updatedBy = firebase.teacher.name;
    config.platform = "web";
    config.address = userInfo.address;
    config.adminEmail = userInfo.email;
    config.schoolName = userInfo.schoolName;
    config.invoicePrefix = userInfo.invoicePrefix ? userInfo.invoicePrefix : null;
    config.receiptPrefix = userInfo.receiptPrefix ? userInfo.receiptPrefix : null;

    let endpoint = "woodlandApi/firebaseAccount?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, config, endpoint, firebase);
    if (response && response.status && response.status === 200) {
      notification("success", formatMsg("success.saved"));
      yield put({
        type: actions.UPDATE_USER_INFO_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateUserInfo")
      );
      yield put({
        type: actions.SCHOOL_SETTING_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateSchoolAddressInfo({ address, firebase }) {
  try {
    // firebase.updateSchoolName(userInfo.schoolName);

    let config = firebase.schoolConfig;
    config.updatedBy = firebase.teacher.name;
    config.platform = "web";
    config.address = address;

    let endpoint = "woodlandApi/firebaseAccount?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, config, endpoint, firebase);
    if (response && response.status && response.status === 200) {
      notification("success", formatMsg("success.saved"));
      yield put({
        type: actions.UPDATE_SCHOOL_ADDRESS_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSchoolAddress")
      );
      yield put({
        type: actions.SCHOOL_SETTING_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchUserDetail({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getUserDetail, firebase);
    if (data) {
      yield put({
        type: actions.GET_USER_DETAILS_SUCCESSFUL,
        userDetail: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchTaxDetail({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getTaxDetail, firebase);
    if (data) {
      yield put({
        type: actions.GET_TAX_DETAILS_SUCCESSFUL,
        taxDetails: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateTeacherInfo({ value, firebase }) {
  try {
    yield call(UserSettingApi.updateTeacherDetail, value, firebase);
    yield put({
      type: actions.UPDATE_TEACHER_INFO_SUCCESSFUL,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* getCameraData({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getAllCameraData, firebase);
    if (data) {
      yield put({
        type: actions.GET_CAMERA_DETAILS_SUCCESSFUL,
        cameraDetails: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* getAllClassroomForCameraAssign({ firebase }) {
  try {
    const classroomData = yield call(StudentApi.getAutoCompleteClassrooms, firebase);
    if (classroomData) {
      yield put({
        type: actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL,
        classroomList: classroomData,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to fetch  classroom", err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* assignCameraToClassroom({ values, cameraId, firebase }) {
  try {
    yield call(UserSettingApi.updateCameraToClass, values, cameraId, firebase);
    notification("success", formatMsg("success.saved"));
    yield put({
      type: actions.UPDATE_CAMERA_TO_CLASS_SUCCESSFUL,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateSchoolConfig({ config, firebase }) {
  try {
    let url = "woodlandApi/firebaseAccount?centerId=";
    config.updatedBy = firebase.teacher.name;
    config.platform = "web";
    let response = yield call(StudentAttendanceApi.requestApi, config, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.SAVE_CONFIG_SETTING_SUCCESSFUL,
      });
      firebase.getSchoolSetting();
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSchoolSetting")
      );
      yield put({
        type: actions.SCHOOL_SETTING_REQUEST_FAILED,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to update school config setting", err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateActivitySetting({
  selectedActivityConfig,
  selectedActivity,
  checkedObj,
  formItems,
  firebase,
}) {
  try {
    yield call(
      UserSettingApi.updateActivityConfig,
      selectedActivityConfig,
      selectedActivity,
      checkedObj,
      formItems,
      firebase
    );

    yield fork(
      ActivityApi.createLogReport,
      "customiseActivityFormLog",
      "EDIT",
      firebase,
      undefined,
      selectedActivity
    );

    firebase.getCustomActivityFormSetting();
    yield put({
      type: actions.SAVE_ACTIVITY_SETTING_SUCCESSFUL,
    });
    notification("success", formatMsg("success.settingUpdated"));
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.ACTIVITY_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateInvoiceCounter({ invoiceCounterText, firebase }) {
  try {
    yield call(UserSettingApi.updateInvoiceCounter, invoiceCounterText, firebase);
    yield put({
      type: actions.UPDATE_INVOICE_COUNTER_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.BILLING_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateTax({ values, firebase }) {
  try {
    let nodeId = yield call(UserSettingApi.getTaxId, firebase);

    yield call(UserSettingApi.updateTax, values, firebase, nodeId);
    yield put({
      type: actions.SAVE_TAX_SUCCESSFUL,
      operationRequest: "TAX_SAVE",
    });
    notification("success", formatMsg("success.taxSaved"));
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* updateReceiptCounter({ receiptCounterText, firebase }) {
  try {
    yield call(UserSettingApi.updateReceiptCounter, receiptCounterText, firebase);
    yield put({
      type: actions.UPDATE_RECEIPT_COUNTER_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.BILLING_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchSchoolConfig({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getSchoolConfiguration, firebase);
    yield put({
      type: actions.GET_SCHOOL_CONFIG_SUCCESS,
      schoolConfig: data,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to fetch school config", err);
    yield put({
      type: actions.SCHOOL_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchInvoiceCounter({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getInvoiceCounter, firebase);
    // let receiptData = yield call(UserSettingApi.getReceiptCounter, firebase);
    yield put({
      type: actions.GET_INVOICE_COUNTER_SUCCESS,
      invoiceCounter: data,
      // receiptCounter:receiptData,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to fetch invoice counter", err);
    yield put({
      type: actions.BILLING_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchReceiptCounter({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getReceiptCounter, firebase);
    // let receiptData = yield call(UserSettingApi.getReceiptCounter, firebase);
    yield put({
      type: actions.GET_RECEIPT_COUNTER_SUCCESS,
      receiptCounter: data,
      // receiptCounter:receiptData,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to fetch receipt counter", err);
    yield put({
      type: actions.BILLING_SETTING_REQUEST_FAILED,
    });
  }
}

function* fetchActivityConfig({ firebase }) {
  try {
    let data = yield call(UserSettingApi.getActivityConfiguration, firebase);
    yield put({
      type: actions.GET_ACTIVITY_CONFIG_SUCCESS,
      activityConfig: data,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("Failed to fetch activity config", err);
    yield put({
      type: actions.ACTIVITY_SETTING_REQUEST_FAILED,
    });
  }
}
function* submitParentHandbook({ attachedFile, config, firebase }) {
  try {
    let storagePath = firebase.sbp + "/media/file/";
    let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, attachedFile, firebase);
    if (mediaPaths) {
      let schoolConfig = config;
      schoolConfig.handbookUrl = mediaPaths[0];
      let url = "woodlandApi/firebaseAccount?centerId=";
      schoolConfig.updatedBy = firebase.teacher.name;
      schoolConfig.platform = "web";
      let response = yield call(StudentAttendanceApi.requestApi, schoolConfig, url, firebase);

      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.SUBMIT_HANDBOOK_SUCCESS,
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : formatMsg("error.updateParentHandbook")
        );
        yield put({
          type: actions.SCHOOL_SETTING_REQUEST_FAILED,
        });
      }
    }
  } catch (err) {
    console.log("Failed to submit parent handbook", err);
    bugsnagClient.notify(err);
  }
}
function* submitTermsConditions({ attachedFile, config, firebase }) {
  try {
    let storagePath = firebase.sbp + "/media/file/";
    let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, attachedFile, firebase);
    if (mediaPaths) {
      let schoolConfig = config;
      schoolConfig.termsConditions = mediaPaths[0];

      let url = "woodlandApi/firebaseAccount?centerId=";
      schoolConfig.updatedBy = firebase.teacher.name;
      schoolConfig.platform = "web";

      let response = yield call(StudentAttendanceApi.requestApi, schoolConfig, url, firebase);

      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.SUBMIT_TERMS_SUCCESS,
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : formatMsg("error.updateTermsConditions")
        );
        yield put({
          type: actions.SCHOOL_SETTING_REQUEST_FAILED,
        });
      }
    }
  } catch (err) {
    console.log("Failed to submit terms & conditions document", err);
    bugsnagClient.notify(err);
  }
}

function* fetchActiveActivity({ firebase }) {
  yield fork(fetchAllActiveActivities, firebase);
}

function* fetchActiveCommentActivity({ firebase }) {
  yield fork(fetchAllActiveCommentActivities, firebase);
}

function* deleteTax({ val, firebase }) {
  try {
    yield call(UserSettingApi.delSelectedTax, val, firebase);
    yield put({
      type: actions.DELETE_TAX_SUCCESS,
      operationRequest: "TAX_DELETE",
    });
    notification("success", formatMsg("error.deleted"));
  } catch (err) {
    console.log("failed to save active activities", err);
    bugsnagClient.notify(err);
  }
}

function* deleteSelectedHoliday({ record, firebase }) {
  try {
    yield call(UserSettingApi.delSelectedHoliday, record, firebase);

    yield put({
      type: actions.DELETE_STAFF_HOLIDAY_SUCCESS,
      operationRequest: "DELETE_STAFF_HOLIDAY",
    });
    notification("success", formatMsg("error.deleted"));
  } catch (err) {
    console.log("failed to delete", err);
    bugsnagClient.notify(err);
  }
}

function* saveLeaveSetting({ values, firebase, rows, weekendNewObj, leaveSettingFromDb }) {
  try {
    let nodeId = values.id ? values.id : yield call(UserSettingApi.getLeaveId, firebase);

    let obj = {};
    obj.startDate = values.startDateVal ? values.startDateVal.valueOf() : null;
    obj.updatedBy = leaveSettingFromDb ? firebase.teacher?.name : null;
    obj.autoSelectDate = values.autoSelect !== undefined ? values.autoSelect : null;
    obj.endDate = values.endDate ? values.endDate.valueOf() : null;
    obj.id = nodeId ? nodeId : null;
    obj.leaveCategories = rows && rows.length > 0 ? rows : null;
    obj.weekend = weekendNewObj ? weekendNewObj : null;
    obj.allowNegativeLeaveBalance =
      values.allowNegativeLeaveBalance !== undefined ? values.allowNegativeLeaveBalance : null;
    let body = {
      newSetting: obj,
      oldSetting: leaveSettingFromDb,
    };
    let endpoint = "woodlandApi/leaveSetting?centerId=";

    let res = yield call(StudentAttendanceApi.requestApi, body, endpoint, firebase);
    if (res && res.status && res.status === 200) {
      notification("success", formatMsg("settings.successSettingsSaved"));

      yield put({
        type: actions.SAVE_LEAVE_SETTING_SUCCESS,
        operationRequest: "LEAVE_SETTING_SAVE",
      });
    } else {
      notification(
        "error",
        res && res.body && res.body.res
          ? res.body.res
          : "Failed to save leave setting. Contact school or Illumine"
      );
      yield put({
        type: actions.SCHOOL_SETTING_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to save leave setting", err);
    bugsnagClient.notify(err);
  }
}

function* saveHolidayCalander({ value, firebase }) {
  try {
    if (value && value.id) {
      let nodeId = value.id;

      yield call(UserSettingApi.saveStaffHoliday, value, nodeId, firebase);
    } else {
      let nodeId = yield call(UserSettingApi.getHolidayId, firebase);

      yield call(UserSettingApi.saveStaffHoliday, value, nodeId, firebase);
    }

    yield put({
      type: actions.SAVE_HOLIDAY_SUCCESS,
      operationRequest: "HOLIDAY_SAVE",
    });

    notification("success", formatMsg("success.saved"));
  } catch (err) {
    console.log("failed to save holiday setting", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAllActiveCommentActivities(firebase, initVal) {
  try {
    let data = yield call(ActivityApi.getCommentActivitySetting, firebase);
    let customData = yield call(ActivityApi.getCustomActivitySetting, firebase);
    let customActivityList = [];
    for (let [key, val] of customData) {
      customActivityList.push(val);
    }

    if (data) {
      yield put({
        type: actions.GET_ACTIVE_COMMENT_ACTIVITY_SUCCESS,
        activeCommentActivities: data,
        customActivityList: customActivityList,
        // operationRequest:initVal ? "INIT_CALL":""
      });
    }
  } catch (err) {
    console.log("failed to fetch active activities", err);
    bugsnagClient.notify(err);
  }
}
function* getRelationsSaga({ firebase }) {
  try {
    let relations = yield call(UserSettingApi.getRelations, firebase);
    yield put({
      type: actions.GET_RELATIONS_SUCCESS,
      relations,
    });
  } catch (err) {
    console.log("failed to fetch active activities", err);
    bugsnagClient.notify(err);
  }
}

function* fetchAllActiveActivities(firebase, initVal) {
  try {
    let data = yield call(ActivityApi.getActivitySetting, firebase);
    let customData = yield call(ActivityApi.getCustomActivitySetting, firebase);
    let customActivityList = [];
    for (let [key, val] of customData) {
      customActivityList.push(val);
    }

    if (data) {
      yield put({
        type: actions.GET_ACTIVE_ACTIVITY_SUCCESS,
        activeActivities: data,
        customActivityList: customActivityList,
        // operationRequest:initVal ? "INIT_CALL":""
      });
    }
  } catch (err) {
    console.log("failed to fetch active activities", err);
    bugsnagClient.notify(err);
  }
}

function* saveAllActiveActivies({ activities, firebase }) {
  try {
    yield call(UserSettingApi.saveActiveActivies, activities, firebase);
    yield fork(ActivityApi.createLogReport, "enableDisableActivityLog", "EDIT", firebase);
    notification("success", formatMsg("success.saved"));
    yield fork(fetchAllActiveActivities, firebase);
  } catch (err) {
    console.log("failed to save active activities", err);
    bugsnagClient.notify(err);
  }
}

function* saveAllActiveCommentActivies({ activities, firebase }) {
  try {
    yield call(UserSettingApi.saveActiveCommentActivies, activities, firebase);
    yield fork(ActivityApi.createLogReport, "enableDisableCommentLog", "EDIT", firebase);
    notification("success", formatMsg("success.saved"));
    yield fork(fetchAllActiveCommentActivities, firebase);
  } catch (err) {
    console.log("failed to save active activities", err);
    bugsnagClient.notify(err);
  }
}
function* addRelationsSaga({ firebase, relations }) {
  try {
    yield call(UserSettingApi.addRelations, firebase, relations);
    yield put({
      type: actions.ADD_RELATIONS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add relation types", err);
    bugsnagClient.notify(err);
  }
}

function* fetchHolidaysList({ firebase, getCurrentYear }) {
  try {
    let data = yield call(ActivityApi.getStaffHolidays, firebase, getCurrentYear);
    data.sort(function (a, b) {
      var dateA = a.startDate,
        dateB = b.startDate;
      return dateA - dateB;
    });

    if (data) {
      yield put({
        type: actions.GET_STAFF_HOLIDAYS_LIST_SUCCESS,
        holidaysList: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch holidays list", err);
    bugsnagClient.notify(err);
  }
}

function* fetchLeaveSetting({ firebase, value }) {
  try {
    let endpoint = "woodlandApi/fetchLeaveSetting?centerId=";

    let obj = {};
    if (value) {
      obj.leaveSettingId = value;
    }
    obj.date = moment().valueOf();

    let res = yield call(StudentAttendanceApi.requestApi, obj, endpoint, firebase);

    if (res && res.status && res.status === 200) {
      yield put({
        type: actions.GET_LEAVES_SETTING_SUCCESS,
        leaveSetting: res.body,
      });
    } else {
      notification(
        "error",
        res && res.body && res.body.res
          ? res.body.res
          : "Failed to fetch holidays setting. Contact school or Illumine"
      );
      yield put({
        type: actions.SCHOOL_SETTING_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to fetch holidays setting", err);
    bugsnagClient.notify(err);
  }
}

function* fetchActivityTemplateValue({ firebase, activityType }) {
  try {
    let data = yield call(ActivityApi.getActivityTemplateValue, activityType, firebase);
    if (data) {
      yield put({
        type: actions.GET_ACTIVITY_TEMPLATE_SUCCESS,
        savedTemplateList: data
      });
    }
  } catch (err) {
    console.log("failed to fetch activity template values", err);
    bugsnagClient.notify(err);
  }
}

function* saveActivityTemplateValue({ value, activityType, firebase, id }) {
  try {
    let nodeId;
    nodeId = id ? id : yield call(ActivityApi.generateUniqueNodeId, firebase, "activityTemplateValues");
    yield call(ActivityApi.addActivityTemplateVal, value, nodeId, activityType, firebase);
    notification("success", "Saved");
    yield put({
      type: actions.SAVE_TEMPLATE_VALUE_SUCCESS,
    });
    yield fork(fetchActivityTemplateValue, { firebase, activityType });
  } catch (err) {
    notification("error", "Failed to save value");
    console.log("failed to add activity template value", err);
    bugsnagClient.notify(err);
  }
}

function* addCCTVCameraInfo({ values, firebase }) {
  try {
    let endpoint = "woodlandApi/addCCTVInfo?centerId=" + firebase.sbDbName;

    let res = yield call(callApi, firebase, "post", endpoint, values);

    if (res && res.status && res.status === 200) {
      yield put({
        type: actions.ADD_CCTV_CAMERA_INFO_SUCCESS,
      });
      notification(
        "success",
        res && res.body && res.body.response
          ? res.body.response
          : formatMsg("cctv.addDetail")
      );
    } else {
      notification(
        "error",
        res && res.body && res.body.response
          ? res.body.response
          : formatMsg("cctv.failedToAdd")
      );
    }
  } catch (err) {
    console.log("failed to add cctv info", err);
    bugsnagClient.notify(err);
  }
}

function* deleteCameraSetting({ id, firebase }) {
  try {
    let endpoint = "woodlandApi/deleteCCTVInfo?centerId=" + firebase.sbDbName;

    let res = yield call(callApi, firebase, "delete", endpoint, { id: id });

    if (res && res.status && res.status === 200) {
      yield put({
        type: actions.DELETE_CAMERA_SETTING_SUCCESS,
      });
      notification(
        "success",
        res && res.body && res.body.response
          ? res.body.response
          : formatMsg("cctv.deleteSuccessfully")
      );
    } else {
      notification(
        "error",
        res && res.body && res.body.response
          ? res.body.response
          : formatMsg("cctv.failedToDelete")
      );
    }
  } catch (err) {
    console.log("failed to delete cctv info", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.UPLOAD_SCHOOL_LOGO, uploadSchoolLogo),
    yield takeEvery(actions.GET_SCHOOL_SETTING_DETAILS, fetchUserSettingDetail),
    yield takeLatest(actions.UPDATE_USER_INFO, updateUserSettingInfo),
    yield takeLatest(actions.UPDATE_SCHOOL_ADDRESS, updateSchoolAddressInfo),
    yield takeLatest(actions.GET_USER_DETAILS, fetchUserDetail),
    yield takeLatest(actions.GET_TAX_DETAILS, fetchTaxDetail),
    yield takeLatest(actions.UPDATE_TEACHER_INFO, updateTeacherInfo),
    yield takeLatest(actions.GET_CAMERA_DETAILS, getCameraData),
    yield takeLatest(actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN, getAllClassroomForCameraAssign),
    yield takeLatest(actions.UPDATE_CAMERA_TO_CLASS, assignCameraToClassroom),
    yield takeLatest(actions.SAVE_CONFIG_SETTING, updateSchoolConfig),
    yield takeLatest(actions.SAVE_ACTIVITY_SETTING, updateActivitySetting),
    yield takeLatest(actions.SAVE_TAX, updateTax),
    yield takeLatest(actions.UPDATE_INVOICE_COUNTER, updateInvoiceCounter),
    yield takeLatest(actions.UPDATE_RECEIPT_COUNTER, updateReceiptCounter),
    yield takeLatest(actions.GET_SCHOOL_CONFIG, fetchSchoolConfig),
    yield takeLatest(actions.GET_ACTIVITY_CONFIG, fetchActivityConfig),
    yield takeLatest(actions.GET_INVOICE_COUNTER, fetchInvoiceCounter),
    yield takeLatest(actions.GET_RECEIPT_COUNTER, fetchReceiptCounter),
    yield takeLatest(actions.SUBMIT_HANDBOOK, submitParentHandbook),
    yield takeLatest(actions.SUBMIT_TERMS, submitTermsConditions),
    yield takeLatest(actions.GET_ACTIVE_ACTIVITY, fetchActiveActivity),
    yield takeLatest(actions.SAVE_ACTIVE_ACTVITIES, saveAllActiveActivies),
    yield takeLatest(actions.SAVE_ACTIVE_COMMENT_ACTIVITIES, saveAllActiveCommentActivies),

    yield takeLatest(actions.GET_ACTIVE_COMMENT_ACTIVITY, fetchActiveCommentActivity),

    yield takeLatest(actions.GET_STAFF_HOLIDAYS_LIST, fetchHolidaysList),
    yield takeLatest(actions.GET_LEAVES_SETTING, fetchLeaveSetting),
    yield takeLatest(actions.DELETE_STAFF_HOLIDAY, deleteSelectedHoliday),

    yield takeLatest(actions.DELETE_TAX, deleteTax),
    yield takeLatest(actions.SAVE_LEAVE_SETTING, saveLeaveSetting),
    yield takeLatest(actions.SAVE_HOLIDAY, saveHolidayCalander),
    yield takeLatest(actions.ADD_RELATIONS, addRelationsSaga),
    yield takeLatest(actions.GET_RELATIONS, getRelationsSaga),
    yield takeLatest(actions.GET_ACTIVITY_TEMPLATE, fetchActivityTemplateValue),
    yield takeLatest(actions.SAVE_TEMPLATE_VALUE, saveActivityTemplateValue),
    yield takeLatest(actions.ADD_CCTV_CAMERA_INFO, addCCTVCameraInfo),
    yield takeLatest(actions.DELETE_CAMERA_SETTING, deleteCameraSetting),


  ]);
}
