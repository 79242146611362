const actions = {
  GET_COMMENTS: "GET_COMMENTS",
  GET_COMMENTS_SUCCESSFULL: "GET_COMMENTS_SUCCESSFULL",

  ADD_COMMENT: "ADD_COMMENT",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",

  ADD_PARENT_COMMENT: "ADD_PARENT_COMMENT",
  ADD_PARENT_COMMENT_SUCCESS: "ADD_PARENT_COMMENT_SUCCESS",

  COMMENT_REQ_FAILED: "COMMENT_REQ_FAILED",
  RESET_COMMENT_OPERATION: "RESET_COMMENT_OPERATION",

  DELETE_COMMENT: "DELETE_COMMENT",
  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",

  RESET_STATE: "RESET_STATE",

  getComments: (id, firebase) => ({
    type: actions.GET_COMMENTS,
    id,
    firebase,
  }),

  addComment: (
    commentObj,
    firebase,
    commentType,
    activityName,
    studentId,
    itemId,
    studentIds,
    classList
  ) => ({
    type: actions.ADD_COMMENT,
    commentObj,
    firebase,
    commentType,
    activityName,
    studentId,
    itemId,
    studentIds,
    classList
  }),

  addParentComment: (
    commentObj,
    firebase,
    commentType,
    selectedActivityName,
    itemId,
    student,
    disableNotification,
    lessonId,
    teacherId
  ) => ({
    type: actions.ADD_PARENT_COMMENT,
    commentObj,
    firebase,
    commentType,
    selectedActivityName,
    itemId,
    student,
    disableNotification,
    lessonId,
    teacherId,
  }),

  resetCommentOperation: () => ({
    type: actions.RESET_COMMENT_OPERATION,
  }),

  deleteComment: (item, activityId, firebase) => ({
    type: actions.DELETE_COMMENT,
    item,
    activityId,
    firebase,
  }),
  resetState: () => ({
    type: actions.RESET_STATE
  })
};
export default actions;
