import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { all, put, call, take, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ActivityApi } from "../../firestore-api/activity";
import { DashboardApi } from "../../firestore-api/dashboard";
import bugsnagClient from "@bugsnag/js";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { FormattedHTMLMessage } from "react-intl";
import moment from "moment";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
function* fetchAttendanceLiveRatio({ firebase }) {
  yield fork(getStudentAttendanceStats, firebase);
  yield fork(getStaffAttendanceStats, firebase);
}

function* getStudentAttendanceStats(firebase) {
  try {
    let data = yield call(
      DashboardApi.fetchLiveAttdStudentRatio,
      "roomAttendance",
      moment(),
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_STUDENT_LIVE_ATTD_SUCCESS,
        liveStudentAttd: data,
        liveStudentAttdChannel: undefined,
      });
    }
  } catch (error) {
    console.log("terminating student attendance stats", error);
  }
}

function* getStaffAttendanceStats(firebase) {
  try {
    let data = yield call(
      DashboardApi.fetchLiveAttdStaffRatio,
      "roomStaffAttendance",
      moment(),
      firebase
    );
    if (data) {
      yield put({
        type: actions.GET_STAFF_LIVE_ATTD_SUCCESS,
        liveStaffAttd: data,
        liveStaffAttdChannel: undefined,
      });
    }
  } catch (error) {
    console.log("terminating staff attendance stats", error);
  }
}

function* fetchLiveAttdClassroom() {
  let classroomData = JSON.parse(getItem("classList"));
  yield put({
    type: actions.GET_LIVE_RATIO_CLASSROOM_SUCCESSFULL,
    liveRatioClassroom: classroomData,
  });
}
function* fetchLiveRatioTeacher() {
  let teachers = JSON.parse(getItem("teacherList"));
  yield put({
    type: actions.GET_LIVE_RATIO_TEACHER_SUCESS,
    liveRatioTeacher: teachers,
  });
}
function* submitAlert({
  addStudent,
  classRoom,
  daysOfWeek,
  teacherList,
  startTime,
  endTime,
  alertName,
  firebase,
}) {
  try {
    let id = yield call(StudentAttendanceApi.createAtdNode, firebase);

    var obj = {
      studentCount: addStudent,
      classroom: classRoom,
      daysOfWeek: daysOfWeek,
      teacherList: teacherList,
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      name: alertName,
      id: id,
    };
    console.log("objjjjj", obj);
    let data = yield call(
      StudentAttendanceApi.submitAttdLiveRatio,
      obj,

      firebase
    );
    yield put({
      type: actions.GET_ALERT_SUCCESS,
      liveAlert: data,
    });
  } catch (error) {
    console.log("not sumbit", error);
  }
}

function* fetchAlert({ firebase }) {
  const chan = yield call(
    StudentAttendanceApi.fetchAlert,

    firebase
  );

  try {
    while (true) {
      let data = yield take(chan);
      console.log("data", data);
      yield put({
        type: actions.FETCH_ALERT_SUCCESS,
        fetchAllAlert: data,
        fetchAlertChannel: chan,
      });
    }
  } finally {
    console.log("fetcherror");
  }
}
function* deleteSelectedAlert({ record, firebase }) {
  try {
    yield call(StudentAttendanceApi.deleteAlert, record, firebase);
    yield put({
      type: actions.DELETE_SELECTED_ALERT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete Alert", err);
  }
}
function* updateExistingALert({
  addStudent,
  classRoom,
  daysOfWeek,
  teacherList,
  startTime,
  endTime,
  alertName,
  record,
  firebase,
}) {
  try {
    yield call(
      StudentAttendanceApi.updateAlert,
      addStudent,
      classRoom,
      daysOfWeek,
      teacherList,
      startTime,
      endTime,
      alertName,
      record,
      firebase
    );
    yield put({
      type: actions.UPDATE_ALERT_SUCCESS,
      AlertOperationType: "UPDATE_ALERT",
    });
  } catch (error) {
    console.log("failed to update alert", error);
    bugsnagClient.notify(error);
  }
}
export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_LIVE_ATTENDANCE_RATIO, fetchAttendanceLiveRatio),
    yield takeLatest(actions.GET_LIVE_RATIO_CLASSROOM, fetchLiveAttdClassroom),
    yield takeLatest(actions.GET_LIVE_RATIO_TEACHER, fetchLiveRatioTeacher),
    yield takeLatest(actions.GET_ALERT, submitAlert),
    yield takeLatest(actions.FETCH_ALERT, fetchAlert),
    yield takeLatest(actions.DELETE_SELECTED_ALERT, deleteSelectedAlert),
    yield takeLatest(actions.UPDATE_ALERT, updateExistingALert),
  ]);
}
