import React, { useState } from "react";
import { Form, Checkbox, Row, Col, Spin, Modal, InputNumber } from "antd";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import IntlMessages from "../../components/utility/intlMessages";
import formatMsg from "../../components/utility/formatMessageUtil";
import notification from "../../components/notification";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "./../../components/firebase/context";

const AddDoseForm = Form.create()((props) => {
    const { form } = props;
    const { getFieldDecorator } = form;

    const [editableRecord, setEditableRecord] = useState(props.dose);

    function handleSubmit() {
        form.validateFields((err, values) => {
            if (err) {
                notification("error", formatMsg("error.allDetail"));
                return;
            }
            if (values.instruction && values.instruction.length == 0) {
                values.instruction = null;
            }
            let operationType = "add";
            if (editableRecord) {
                operationType = "edit";
                values["id"] = editableRecord["doseId"];
                values["vaccineId"] = editableRecord["vaccineId"];
                values["doseOrder"] = editableRecord["doseOrder"];
            }
            else {
                values["doseOrder"] = props.doseOrder;
                values["vaccineId"] = props.vaccine["vaccineId"];
            }
            values["scheduledDate"] = { weeks: Number(values.weeks ? values.weeks : 0), months: Number(values.months ? values.months : 0), years: Number(values.years ? values.years : 0) };
            delete values["weeks"];
            delete values["months"];
            delete values["years"];
            const { saveDose, firebase } = props;
            console.log(values);
            saveDose(firebase, values, operationType);
            handleCancel();
        });

    }

    function handleCancel() {
        form.resetFields();
        props.modalCancel()
    }


    return (
        <Modal
            width="600px"
            height="auto"
            title={formatMsg(editableRecord ? "immunization.editDose" : "immunization.addDose")}
            visible={props.modalVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText={formatMsg("submit")}
            cancelText={formatMsg("cancel")}
            centered={true}
            maskClosable={false}
        >
            <Spin spinning={props.isLoading}>
                <Form colon={false}>
                    <Row>
                        <Col span={8} style={styles.colStyle}>
                            <Form.Item
                                label={
                                    <label style={styles.contentLabelStyle}>
                                        {<IntlMessages id="immunization.weeksC" />}
                                    </label>
                                }
                            >
                                {getFieldDecorator("weeks", {
                                    initialValue: editableRecord && editableRecord.scheduledDate && editableRecord.scheduledDate.weeks ? editableRecord.scheduledDate.weeks : undefined,
                                })(<InputNumber style={{
                                    width: "100%",
                                }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={8} style={styles.colStyle}>
                            <Form.Item
                                label={
                                    <label style={styles.contentLabelStyle}>
                                        {<IntlMessages id="immunization.monthsC" />}
                                    </label>
                                }
                            >
                                {getFieldDecorator("months", {
                                    initialValue: editableRecord && editableRecord.scheduledDate && editableRecord.scheduledDate.months ? editableRecord.scheduledDate.months : undefined,
                                })(<InputNumber style={{
                                    width: "100%",
                                }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={8} style={styles.colStyle}>
                            <Form.Item
                                label={
                                    <label style={styles.contentLabelStyle}>
                                        {<IntlMessages id="program.years" />}
                                    </label>
                                }
                            >
                                {getFieldDecorator("years", {
                                    initialValue: editableRecord && editableRecord.scheduledDate && editableRecord.scheduledDate.years ? editableRecord.scheduledDate.years : undefined,
                                })(<InputNumber style={{
                                    width: "100%",
                                }} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                    >
                        {getFieldDecorator("proofMandatory", {
                            initialValue: editableRecord && editableRecord.proofMandatory ? editableRecord.proofMandatory : false,
                            valuePropName: "checked"
                        })(
                            <Checkbox style={styles.contentLabelStyle}>{formatMsg("immunization.proofMandatory")}</Checkbox>)
                        }
                    </Form.Item>
                </Form>
            </Spin>
        </Modal >
    );
});


function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);

export default a(AddDoseForm);


const styles = {
    contentLabelStyle: {
        margin: 0,
        color: colors.v2_Cerulean,
        fontSize: webFont.lm,
        fontWeight: "normal",
    },
    cardStyle: {
        width: "80%"
    },
    mainDiv: {
        display: "flex",
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    addDoseBtn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    deleteButtonView: {
        backgroundColor: colors.v2_red_background,
        borderRadius: "8px",
        padding: 10,
        cursor: "pointer",
        width: "40px"
    },
    deleteButton: {
        height: 20,
        width: 20,
        objectFit: "contain",
    },
    colStyle: {
        paddingRight: 8,
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 8,
    },

};