import actions from "./actions";
const initState = {
  holidayList: [],
  holidayClassMapArray: new Map(),
  classrooms: [],
  holidaysChannel: undefined,
  isLoading: false,
  operationType: undefined,
  error: false,
};

export default function holidaysReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_ALL_HOLIDAYS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_ALL_HOLIDAYS_SUCCESSFUL:
      return {
        ...state,
        holidayList: action.holidayList,
        holidayClassMapArray: action.holidayClassMapArray,
        holidaysChannel: action.holidaysChannel,
        isLoading: false,
        operationType: action.operationType,
      };

    case actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_ALL_CLASSROOMS_FOR_HOLIDAY_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        isLoading: false,
        operationType: undefined,
      };

    case actions.ADD_HOLIDAY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_HOLIDAY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_HOLIDAY",
      };

    case actions.UPDATE_HOLIDAY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.UPDATE_HOLIDAY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_HOLIDAY",
      };

    case actions.HOLIDAY_REQUEST_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case actions.RESET_HOLIDAY_OPERATION:
      return {
        ...state,
        operationType: undefined,
      };

    case actions.DELETE_HOLIDAY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_HOLIDAY",
      };

    default:
      return state;
  }
}
