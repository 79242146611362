import formatMsg from "../components/utility/formatMessageUtil";


export default class AssessmentTerminology{
    constructor() {}
  }
AssessmentTerminology.getDevAreaName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.DevelopmentArea && assessmentTerminology.DevelopmentArea.customKey !== assessmentTerminology.DevelopmentArea.defaultKey){
    return assessmentTerminology.DevelopmentArea.customKey;
  }
  else{
    return formatMsg("terminology.developmentArea");
  }
}

AssessmentTerminology.getSubDevAreaName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.SubDevelopmentArea&& assessmentTerminology.SubDevelopmentArea.customKey !== assessmentTerminology.SubDevelopmentArea.defaultKey){
    return assessmentTerminology.SubDevelopmentArea.customKey;
  }
  else{
    return formatMsg("terminology.subDevelopmentArea");
  }
}

AssessmentTerminology.getMilestoneName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.Milestone && assessmentTerminology.DevelopmentArea.Milestone !== assessmentTerminology.Milestone.defaultKey){
    return assessmentTerminology.Milestone.customKey;
  }
  else{
    return formatMsg("terminology.milestone");
  }
}


AssessmentTerminology.getAssessmentName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.Assessment && assessmentTerminology.Assessment.customKey !== assessmentTerminology.Assessment.defaultKey){
    return assessmentTerminology.Assessment.customKey;
  }
  else{
    return formatMsg("terminology.assessment");
  }
}

AssessmentTerminology.getAssessmentTemplateName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.AssessmentTemplate && assessmentTerminology.AssessmentTemplate.customKey !== assessmentTerminology.AssessmentTemplate.defaultKey){
    return assessmentTerminology.AssessmentTemplate.customKey;
  }
  else{
    return formatMsg("terminology.assessmentTemplate");
  }
}

AssessmentTerminology.getObservationName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.Observation && assessmentTerminology.Observation.customKey !== assessmentTerminology.Observation.defaultKey){
    return assessmentTerminology.Observation.customKey;
  }
  else{
    return formatMsg("terminology.observation");
  }
}

AssessmentTerminology.getReflectionName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.Reflection && assessmentTerminology.Reflection.customKey !== assessmentTerminology.Reflection.defaultKey){
    return assessmentTerminology.Reflection.customKey;
  }
  else{
    return  formatMsg("terminology.reflection");
  }
}

AssessmentTerminology.getConcernName = (assessmentTerminology)=>{
  if(assessmentTerminology && assessmentTerminology.Concerns && assessmentTerminology.Concerns.customKey !== assessmentTerminology.Concerns.defaultKey){
    return assessmentTerminology.Concerns.customKey;
  }
  else{
    return formatMsg("terminology.concern");
  }
}

