import { all, put, call, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { customActivityApi } from "../../firestore-api/customActivity";
import { takeEvery } from "redux-saga";
import { ActivityApi } from "../../firestore-api/activity";
import notification from "../../components/notification";
import bugsnagClient from "@bugsnag/js";
import formatMsg from "../../components/utility/formatMessageUtil";

function* getCustomActivities({ firebase }) {
  let customActivities = [];
  try {
    customActivities = yield call(customActivityApi.getCustomActivitiesApi, firebase);

    yield put({
      type: actions.GET_CUSTOM_ACTIVITIES_SUCCESS,
      payLoad: customActivities,
    });
  } catch (error) {
    bugsnagClient.notify(error);
    notification("error", formatMsg("customActivity.errorFetchActivity"));
    yield put({
      type: actions.SETLODING,
      payLoad: false,
    });
    console.log(error);
  }
}
function* addCustomActivitySaga({ firebase, activityName, values, fileList }) {
  try {
    const key = yield call(customActivityApi.createCustomActivityUniqueNodeId, firebase);
    let storagePath = firebase.sbp + "/media/mediaProfiles/";
    let urls = yield call(
      ActivityApi.getAttachmentMediaPath,
      storagePath,
      { fileList },
      firebase,
      undefined,
      undefined,
      undefined,
      undefined
    );

    if (urls) {
      yield call(customActivityApi.addCustomActivity, firebase, activityName, values, key, urls);

      yield fork(ActivityApi.createLogReport, "customActivityLog", "ADD", firebase, key);
    }
    notification("success", formatMsg("customActivity.successCreateActivity"));
    yield put({
      type: actions.GET_CUSTOM_ACTIVITIES,
      firebase,
    });
  } catch (error) {
    notification("error", formatMsg("customActivity.errorCreateActivity"));
    yield put({
      type: actions.SETLODING,
      payLoad: false,
    });
    bugsnagClient.notify(error);
    console.log(error);
  }
}
function* deleteCustomActivitySaga({ firebase, activityKey }) {
  try {
    yield put({
      type: actions.SETLODING,
      payLoad: true,
    });
    const key = yield call(customActivityApi.deleteCustomActivity, firebase, activityKey);

    yield fork(ActivityApi.createLogReport, "customActivityLog", "DELETE", firebase, activityKey);

    notification("success", formatMsg("customActivity.successDeleteActivity"));
    yield put({
      type: actions.GET_CUSTOM_ACTIVITIES,
      firebase,
    });
  } catch (error) {
    notification("error", formatMsg("customActivity.errorDeleteActivity"));
    yield put({
      type: actions.SETLODING,
      payLoad: false,
    });
    bugsnagClient.notify(error);
    console.log(error);
  }
}
function* editCustomActivitySaga({ firebase, activityName, values, activity }) {
  try {
    if (values.upload) {
      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        values.upload,
        firebase,
        undefined,
        undefined,
        undefined,
        undefined
      );

      if (urls) {
        yield call(
          customActivityApi.editCustomActivity,
          firebase,
          activityName,
          values,

          urls,
          activity
        );

        yield fork(ActivityApi.createLogReport, "customActivityLog", "EDIT", firebase, activity.id);
      }
    } else {
      yield call(
        customActivityApi.editCustomActivity,
        firebase,
        activityName,
        values,
        values.upload,
        activity
      );

      yield fork(ActivityApi.createLogReport, "customActivityLog", "EDIT", firebase, activity.id);
    }
    notification("success", formatMsg("customActivity.successEditActivity"));
    yield put({
      type: actions.GET_CUSTOM_ACTIVITIES,
      firebase,
    });
  } catch (error) {
    yield put({
      type: actions.SETLODING,
      payLoad: false,
    });
    bugsnagClient.notify(error);
    notification("error", formatMsg("customActivity.errorEditActivity"));
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_CUSTOM_ACTIVITIES, getCustomActivities),
    yield takeEvery(actions.ADD_CUSTOM_ACTIVITY, addCustomActivitySaga),
    yield takeLatest(actions.DELETE_CUSTOM_ACTIVITY, deleteCustomActivitySaga),
    yield takeEvery(actions.EDIT_CUSTOM_ACTIVITY, editCustomActivitySaga),
  ]);
}
