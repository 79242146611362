import React, { useState } from 'react'
import colors from "../../Utility/colorFactory";
import FilterAction from "../../Utility/FilterAction";
import webFont from "../../Utility/fontSizes";
import { Icon, List, Popover, Upload, Modal, DatePicker, Input } from "antd";
import IntlMessages from "../../components/utility/intlMessages";
import formatMsg from "../../components/utility/formatMessageUtil";
import CustomPdfViewer from "../../components/CustomPdfViewer/pdfDrawer";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "../../components/firebase/context";
import moment from "moment";
import { imageExtension } from "../../Utility/mimeTypeDirectory";
import notification from "../../components/notification";
import { confirmAlert } from "react-confirm-alert";
import Viewer from "react-viewer";
import { getItem } from "../../../src/Utility/encryptedStorage";


const { TextArea } = Input;
function StudentDoseView(props) {
    let doseRecord = props.doseRecord;
    let vaccine = props.vaccine;
    const [popActionVisible, setPopActionVisible] = useState(false);
    const [isModalOpen, setIsModelOpen] = useState(false);
    const [updateModalSubject, setUpdateModalSubject] = useState(undefined);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [vaccinationDate, setVaccinationDate] = useState(moment().valueOf());
    const [vaccinationDocument, setVaccinationDocument] = useState(doseRecord.proof);
    const acceptedFormat = "image/*,application/pdf";
    const [textModal, setTextModal] = useState(false);
    const [doseNote, setDoseNote] = useState(doseRecord.doseNote ? doseRecord.doseNote : "");
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [profileImages, setProfileImages] = useState([]);
    const [fileList, setFileList] = useState([]);

    function closeProfileImgViewer() {
        setProfileImages([]);
        setIsProfileOpen(false)
    }


    function closeActionPop(visible) {
        setPopActionVisible(visible);
    }
    function getStudentId(firebase) {
        let studentId = null;
        if (firebase.selectedMode.toLowerCase() === "parent") {
            let localStudentId = getItem("currentStudentId");
            if (localStudentId) {
                studentId = localStudentId;
            }
        }
        else {
            studentId = FilterAction.getUrlParam("studentId");
        }

        return studentId;
    }

    function handleResetStudentDosageRecord() {
        const { firebase, updateStudentDose, } = props;
        let updatedDoseRecord = { ...doseRecord };
        updatedDoseRecord.doseNote = props?.firebase?.selectedMode === "teacher" ? null : doseRecord.doseNote,
            updatedDoseRecord.exempted = props?.firebase?.selectedMode === "teacher" ? false : doseRecord.exempted,
            updatedDoseRecord.proof = null;
        updatedDoseRecord.vaccinationDate = null;
        updatedDoseRecord.status = "missing";
        updatedDoseRecord.studentVaccinationRecordId = vaccine.studentVaccinationRecordId;
        let studentId = getStudentId(firebase);
        updateStudentDose(firebase, updatedDoseRecord, "updateProof", vaccine.vaccineId, studentId, vaccine.vaccineName);
        setPopActionVisible(false);
    }

    function handleSubmit() {
        if (!vaccinationDate) {
            notification("error", formatMsg("error.pleaseAddVaccinationDate"));
            return;
        }
        if (doseRecord.proofMandatory && !vaccinationDocument) {
            notification("error", formatMsg("error.pleaseAddVaccinationProof"));
            return;
        }
        const { firebase, updateStudentDose, } = props;
        let updatedDoseRecord = { ...doseRecord };
        updatedDoseRecord.proof = vaccinationDocument;
        updatedDoseRecord.vaccinationDate = vaccinationDate;
        updatedDoseRecord.status = "completed";
        updatedDoseRecord.studentVaccinationRecordId = vaccine.studentVaccinationRecordId;
        let studentId = getStudentId(firebase);
        updateStudentDose(firebase, updatedDoseRecord, "updateProof", vaccine.vaccineId, studentId, vaccine.vaccineName);
        setFileList([]);
        setVaccinationDocument(undefined);
        setPdfUrl("");
        handleCancel();
    }
    function handleCancel() {
        setIsModelOpen(false);
        setTextModal(false);
        setFileList([]);
    }
    function handleUpload(info) {
        if (!imageExtension.includes(info.file.type) && info.file.type != "application/pdf") {
            notification("error", formatMsg("error.onlyImageAttachmentOrPdf"));
            return;
        } else {

            const { file, fileList } = info;
            if (fileList.length > 1) {
                fileList.shift();
                setVaccinationDocument(undefined);
            }
            if (file.status == "removed") {
                setFileList([]);
                setVaccinationDocument(undefined);

            }
            else {
                setFileList([file]);
                setVaccinationDocument({ file: file, fileList: fileList });
            }
        }
    };

    function handleShowProof() {
        if (doseRecord.proof) {
            setShowPdfViewer(!showPdfViewer);
            let proof = doseRecord.proof;
            console.log(proof.includes("pdf"));
            if (proof.includes("pdf")) {
                setPdfUrl(doseRecord.proof);
            }
            else {
                setProfileImages([{ src: doseRecord.proof }]);
                setIsProfileOpen(true);
            }
        }
        else {
            notification("error", formatMsg("error.proofNotFound"));
        }
        setPopActionVisible(!popActionVisible);
    }

    const popComponent = (
        <List
            split={false}
            text
            style={{ cursor: "pointer" }}
            size="small"
            dataSource={["a"]}
            renderItem={(item, index) => (
                <div style={{ width: 150 }}>
                    {
                        !doseRecord.exempted
                            ?
                            <>
                                <>
                                    {
                                        doseRecord.status === "completed" ?
                                            <>
                                                {
                                                    doseRecord.proof ? <List.Item
                                                        style={{
                                                            ...styles.listItem,
                                                            ...{
                                                                borderBottom: ".8px solid " + colors.v2_light_grey
                                                            }
                                                        }}
                                                        key="showProof"
                                                        onClick={() => { handleShowProof() }}
                                                    >
                                                        <IntlMessages id="immunization.showProof" />
                                                    </List.Item> : null
                                                }

                                                <List.Item
                                                    style={{
                                                        ...styles.listItem,
                                                        ...{
                                                            borderBottom: ".8px solid " + colors.v2_light_grey
                                                        }
                                                    }}
                                                    key="updateVaccinatedDate"
                                                    onClick={() => {
                                                        setIsModelOpen(!isModalOpen),
                                                            setUpdateModalSubject("updateVaccinationInfo"),
                                                            setPopActionVisible(!popActionVisible),
                                                            setVaccinationDate(doseRecord && doseRecord.vaccinationDate ? doseRecord.vaccinationDate : undefined),
                                                            setVaccinationDocument(doseRecord && doseRecord.proof ? doseRecord.proof : undefined)
                                                    }}
                                                >
                                                    <IntlMessages id="immunization.updateVaccinatedInfo" />
                                                </List.Item>
                                                <List.Item
                                                    style={{
                                                        ...styles.listItem,
                                                        ...{
                                                            borderBottom: ".8px solid " + colors.v2_light_grey
                                                        }
                                                    }}
                                                    key="reset"
                                                    onClick={() => { handleResetStudentDosageRecord() }}
                                                >
                                                    <IntlMessages id="attendance.markPending" />
                                                </List.Item>
                                            </>
                                            :
                                            <List.Item
                                                style={{
                                                    ...styles.listItem,
                                                    ...{
                                                        borderBottom: ".8px solid " + colors.v2_light_grey
                                                    }
                                                }}
                                                key="addVaccinatedInfo"
                                                onClick={() => {
                                                    setIsModelOpen(!isModalOpen),
                                                        setUpdateModalSubject("addVaccinationInfo"),
                                                        setPopActionVisible(!popActionVisible)
                                                }}
                                            >
                                                <IntlMessages id="immunization.uploadVaccinationDetails" />
                                            </List.Item>
                                    }
                                </>
                                <List.Item
                                    style={{
                                        ...styles.listItem,
                                        ...{
                                            borderBottom: props?.firebase?.selectedMode === "teacher" ? ".8px solid " + colors.v2_light_grey : "0px"
                                        }
                                    }}
                                    key="doseNote"
                                    onClick={() => { setTextModal(true), setPopActionVisible(false) }}
                                >
                                    <IntlMessages id="immunization.doseNote" />
                                </List.Item>
                            </>
                            :
                            null
                    }
                    {
                        props?.firebase?.selectedMode === "teacher"
                            ?
                            <List.Item
                                style={styles.listItem}
                                key="exemptDose"
                                onClick={exemptDose}
                            >
                                {!doseRecord.exempted ? <IntlMessages id="immunization.exemptDose" /> : formatMsg("immunization.removeDoseExemption")}
                            </List.Item>
                            :
                            null
                    }
                </div >
            )}
        />
    )

    function exemptDose() {
        setPopActionVisible(false);

        const { firebase, updateStudentDose } = props;
        let message = formatMsg("students.confirmToSubmit");
        confirmAlert({
            closeOnClickOutside: false,
            title: (
                <p
                    style={{
                        fontSize: 20,
                        color: colors.v2_fiord,
                    }}
                >
                    {formatMsg("confirmModal.areYouSure")}
                </p>
            ),
            message: message,
            buttons: [
                {
                    label: formatMsg("yes"),
                    onClick: () => {
                        let updatedDoseRecord = { ...doseRecord };
                        updatedDoseRecord.exempted = !doseRecord.exempted;
                        updatedDoseRecord.studentVaccinationRecordId = vaccine.studentVaccinationRecordId;
                        updateStudentDose(firebase, updatedDoseRecord, "updateExempt", vaccine.vaccineId, getStudentId(firebase), vaccine.vaccineName)
                    },
                },
                {
                    label: formatMsg("no"),
                },
            ],
        });
    }



    function handleNoteSubmit() {
        const { firebase, updateStudentDose } = props;
        let updatedDoseRecord = { ...doseRecord };
        updatedDoseRecord.doseNote = doseNote;
        updatedDoseRecord.studentVaccinationRecordId = vaccine.studentVaccinationRecordId;
        updateStudentDose(firebase, updatedDoseRecord, "updateDoseNote", vaccine.vaccineId, getStudentId(firebase), vaccine.vaccineName)
        handleCancel();
    }
    return (

        <>{
            !(vaccine.vaccineLevelExempted || vaccine.studentLevelExempted) ?

                <div div >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {
                            doseRecord.exempted
                                ?
                                <div style={styles.exemptDiv}>
                                    {formatMsg("immunization.exempt")}
                                </div>
                                :
                                <div style={{ ...styles.statusTyle, color: doseRecord.status == "completed" ? colors.v2_mountain_meadow : colors.red_primary }} >{doseRecord.status == "completed" ? formatMsg("immunization.completed") : formatMsg("immunization.missing")}</div>
                        }
                        <Popover
                            content={popComponent}
                            trigger="click"
                            placement="right"
                            visible={popActionVisible}
                            onVisibleChange={(visible) => closeActionPop(visible)}
                            key={props?.doseRecord}
                        >
                            <div style={{}}>
                                <Icon
                                    type={!popActionVisible ? "caret-down" : "caret-up"}
                                    style={{
                                        marginTop: doseRecord.exempted ? "5px" : "2px",
                                        marginLeft: "4px",
                                        color: colors.v2_Cerulean,
                                        cursor: "pointer",
                                        fontSize: webFont.fll,
                                    }}
                                />
                            </div>
                        </Popover>
                    </div>
                    {
                        doseRecord.proofMandatory
                            ?
                            <p style={styles.mandatory} >{formatMsg("immunization.proofMandatory")}</p>
                            :
                            null
                    }
                    {
                        doseRecord.exempted
                            ?
                            null
                            :
                            <span style={styles.statusDiv} >
                                <p>{doseRecord.status !== "completed" ? formatMsg("assignment.dueOn") + ":" : null}</p>
                                <p style={{ marginLeft: "3px" }} > {
                                    doseRecord.status == "completed" ? moment(doseRecord.vaccinationDate).format(FilterAction.getDateFormat(props.firebase)) :
                                        doseRecord.scheduledDate
                                            ? moment(doseRecord.scheduledDate).format(FilterAction.getDateFormat(props.firebase)) : ""}</p>
                            </span>

                    }
                    <Modal
                        title={updateModalSubject == "addVaccinationInfo" ? formatMsg("immunization.uploadVaccinationDetails") : formatMsg("immunization.updateVaccinatedInfo")}
                        visible={isModalOpen}
                        onOk={handleSubmit}
                        onCancel={handleCancel}
                        okText={updateModalSubject == "addVaccinationInfo" ? formatMsg("accessControl.save") : formatMsg("submit.update")}
                        cancelText={formatMsg("cancel")}
                        maskClosable={false}
                    >
                        <DatePicker
                            getCalendarContainer={(trigger) => trigger.parentNode}
                            onChange={(event) => setVaccinationDate(moment(event).valueOf())}
                            format={props.firebase ? FilterAction.getDateFormat(props.firebase) : "DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            value={vaccinationDate ? moment(vaccinationDate) : moment()}
                        />
                        <div style={styles.uploadDiv}>
                            <Upload.Dragger
                                accept={acceptedFormat}
                                fileList={fileList}
                                onChange={(info) => (handleUpload(info))}
                                showUploadList={true}
                                beforeUpload={() => {
                                    return false;
                                }}
                                multiple={false}
                                style={{
                                    marginTop: "20px"
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">{formatMsg("immunization.uploadOrDragFile")}</p>
                                <p className="ant-upload-hint">
                                    {formatMsg("immunization.canUploadOneFile")}
                                </p>
                            </Upload.Dragger>
                        </div>
                    </Modal>
                    <CustomPdfViewer
                        visible={pdfUrl && showPdfViewer}
                        onClose={() =>
                            setShowPdfViewer(!showPdfViewer)
                        }
                        downloadAllowed={true}
                        invoiceUrl={pdfUrl}
                    />
                </div >
                : null
        }
            {
                (props?.firebase?.selectedMode?.toLowerCase() === "parent")
                    ?
                    <Modal
                        title={formatMsg("immunization.doseNote")}
                        visible={textModal}
                        onCancel={handleCancel}
                        footer={null}
                        maskClosable={false}
                    >
                        <p>
                            {!doseNote ? "No dose note" : doseNote}
                        </p>
                    </Modal >
                    :
                    <Modal
                        title={formatMsg("immunization.doseNote")}
                        visible={textModal}
                        maskClosable={false}
                        onOk={handleNoteSubmit}
                        onCancel={handleCancel}
                        okText={formatMsg("accessControl.save")}
                        cancelText={formatMsg("cancel")}
                    >
                        <TextArea
                            style={styles.draftStyle}
                            placeholder={formatMsg("immunization.doseNote")}
                            value={doseNote}
                            autosize={{
                                minRows: 4,
                                maxRows: 6,
                            }}
                            onChange={(e) => {
                                setDoseNote(e.target.value);
                            }}
                        />
                    </Modal >
            }
            <Viewer
                visible={isProfileOpen}
                onClose={closeProfileImgViewer}
                images={profileImages}
                downloadable={true}
                activeIndex={0}
            />
        </>
    )
}
function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);
export default a(StudentDoseView);
const styles = {
    statusTyle: {
        fontWeight: "normal",
        fontSize: webFont.lm
    },
    listItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        color: colors.v2_Cerulean
    },
    uploadDiv: {
        marginTop: "20px"
    },
    statusDiv: {
        fontSize: webFont.medium,
        display: "flex"
    },
    exemptDiv: {
        backgroundColor: colors.grey_border_primary,
        color: colors.black636E80,
        borderRadius: "5px",
        width: "fit-content",
        padding: "3px 7px 3px 7px",
        marginRight: "5px",
        fontSize: webFont.medium,
        fontWeight: "normal",
        marginTop: "1px"
    },
    mandatory: {
        fontSize: webFont.small,
        fontWeight: "normal",
        color: colors.lightBlue,
    }
}