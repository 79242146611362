import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore";
import * as FileSaver from "file-saver";
import moment from "moment";
import { delay } from "redux-saga";
import { all, call, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import * as XLSX from "xlsx";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { ApplicationApi } from "../../firestore-api/application";
import { FeeApi } from "../../firestore-api/fee";
import { InvoiceTemplateApi } from "../../firestore-api/invoiceTemplate";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { NotificationApi } from "../../firestore-api/notification";
import { ProgramApi } from "../../firestore-api/program";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { TagApi } from "../../firestore-api/tag";
import { TeacherApi } from "../../firestore-api/teacher";
import { UserSettingApi } from "../../firestore-api/userSetting";
import { getItem } from "../../Utility/encryptedStorage";
import FilterAction from "../../Utility/FilterAction";
import UserFilterAction from "../../Utility/UserFilterActions";
import actions from "./actions";
const { Parser } = require("json2csv");
import { callApi } from "../../Utility/superAgentUntil";

function* listStudents({ fetchLimit, firebase, initialCall }) {
  let data = FilterAction.getStudentList(firebase);

  yield put({
    type: actions.LIST_STUDENT_SUCCESSFUL,
    students: data ? data : [],
    studentsChannel: undefined,
    operationType: initialCall ? "INITIAL_STUDENT_FETCH" : undefined,
  });
}

function* fetchParentDetail({ studentRecord, firebase }) {
  try {
    let combinedData = yield call(StudentApi.getAllParents, studentRecord, firebase);
    if (combinedData) {
      yield put({
        type: actions.GET_PARENT_DETAIL_OF_SELECTED_STUDENT_SUCCESSFUL,
        completeStudentDetail: combinedData,
      });
    }
  } catch (error) {
    console.log("failed to fetch parent details", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* getClassroms({ firebase }) {
  try {
    const classroomData = JSON.parse(getItem("classList"));
    if (classroomData) {
      yield put({
        type: actions.GET_STUDENT_CLASSROOM_DATA_SUCCESSFUL,
        classroomData: classroomData,
      });
    }
  } catch (err) {
    console.log("failed to fetch autocomplete classroom", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchTags({ firebase }) {
  try {
    const data = JSON.parse(getItem("groupList"));

    if (data) {
      yield put({
        type: actions.GET_TAGS_SUCCESSFUL,
        groups: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch tag in student", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* submitStudentData({ values, firebase }) {
  try {
    let genericMessage = "Failed to add student. Please contact school or Illumine";
    let addResponse = yield call(StudentApi.addStudentApi, firebase, values);
    if (addResponse && addResponse.status === 200) {
      yield put({
        type: actions.SUBMIT_STUDENT_SUCCESSFUL,
        newStudentId: addResponse?.body?.response?.id,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          addResponse.body && addResponse.body.response
            ? addResponse.body.response
            : genericMessage,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* submitParentData({ values, relation, studentRecord, firebase }) {
  try {
    let studentObj = studentRecord;
    let contact = relation === "Father" ? Number(values.fatherNumber) : Number(values.motherNumber);
    let parentName = relation === "Father" ? values.fatherName : values.motherName;
    let parentType = relation === "Father" ? "parent2" : "parent1";
    let parentEmail = relation === "Father" ? values.fatherEmail : values.motherEmail;
    let genericMessage = "Failed to add details. Please contact school or Illumine";

    let addResponse = yield call(
      StudentApi.addParentApi,
      firebase,
      studentRecord.name,
      studentRecord.id,
      parentName,
      contact,
      parentEmail,
      parentType,
      studentRecord,
      values.countryCode,
      null,
      null,
      values.relationship
    );

    if (addResponse && addResponse.status === 200) {
      if (relation === "Father") {
        studentObj.fatherProfileId = addResponse.body.parent.id;
      }
      if (relation === "Mother") {
        studentObj.motherProfileId = addResponse.body.parent.id;
      }
      let studentValues = [studentObj];

      yield put({
        type: actions.FORCE_LOADER_START,
      });
      yield delay(3000);
      yield put({
        type: actions.INVITE_PARENT_SUCCESSFUL,
        emailRelation: relation,
        parentEmailChange: parentEmail,
        studentDetailFormTable: studentValues,
      });
    } else if (addResponse && (addResponse.status === 201 || addResponse.status === 202)) {
      let siblingObj;
      let parentId;

      if (addResponse.status === 201) {
        siblingObj = addResponse.body.student;
        parentId = addResponse.body.parentId;
      } else if (addResponse.status === 202) {
        siblingObj = {};
        parentId = addResponse.body.userNode.id;
      }

      siblingObj.originalStudent = studentRecord;
      siblingObj.parentType = parentType;
      siblingObj.parentId = parentId;
      if (addResponse.status === 202) {
        siblingObj.message = "This profile is already added as a teacher. Do you want to link \"" + studentObj.name + "\" as their child ?";
      } else {
        siblingObj.message =
          "Already added as a parent of " + siblingObj.name + ". Do you want to add \"" + studentObj.name + "\" as a sibling of \"" + siblingObj.name + "\" ?";
      }
      yield put({
        type: actions.LINK_FOUND,
        existingDetail: siblingObj,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          addResponse.body && addResponse.body.response
            ? addResponse.body.response
            : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to verify parent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateParentDetailIfSibling({ siblingStudent, originalStudent, firebase }) { }

function* updateParentDetailIfTeacher({ studentData, teacherData, relation, firebase }) { }

function* updateSiblingToStudent({ values, existingStudent, firebase }) {
  try {
    let genericMessage = "Failed to add details. Please contact school or Illumine";
    let parentIds = [];
    for (let index in values.linkParent) {
      if (values.linkParent[index] === "father" && existingStudent.fatherProfileId) {
        parentIds.push(existingStudent.fatherProfileId);
      }

      if (values.linkParent[index] === "mother" && existingStudent.motherProfileId) {
        parentIds.push(existingStudent.motherProfileId);
      }
    }
    let newStudentObj = {
      name: values.studentName,
      classroomName: values.classroom[0],
      classList: values.classroom ? values.classroom : [],
      status: values.status,
      gender: values.gender,
      preferredName: values.prefName ? values.prefName : null,
    };

    let teacherBranch = firebase.teacher.newBranches ? firebase.teacher.newBranches : [];
    let newStudentBranchPath = values.studentBranchPath ? values.studentBranchPath : undefined;

    let schoolDbName;
    if (newStudentBranchPath) {
      let filteredBranch = teacherBranch.filter((b) => {
        return b.name === newStudentBranchPath;
      });

      if (filteredBranch && filteredBranch.length > 0) {
        schoolDbName = filteredBranch[0].dbName;
      }
    }

    let response = yield call(
      StudentApi.addSiblingApi,
      firebase,
      newStudentObj,
      existingStudent.id,
      existingStudent.name,
      schoolDbName,
      parentIds
    );

    if (response && response.status === 200) {
      // firebase.refreshStudents();
      yield put({
        type: actions.ADD_SIBLING_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentData({ values, record, tags, firebase, updatedBy }) {
  try {
    var childConfig = record.childConfig ? record.childConfig : null;
    if (childConfig !== null) {
      for (let i = 0; i < childConfig.length; i++) {
        let id = childConfig[i].id;
        if (id) {
          if (childConfig[i].dataType === "Date") {
            childConfig[i].value = values[id]
              ? moment(new Date(values[id])).format(UserFilterAction.getApiDateFormat())
              : null;
          }
          else {
            childConfig[i].value = values[id] ? values[id].toString() : null;

          }
        }
      }
    }
    record.childConfig = childConfig;

    let groups = JSON.parse(getItem("groupList"));
    let existingTags = [];
    let removableTags = record.tags ? record.tags : [];
    if (values.tags) {
      let formTags = values.tags;
      formTags.forEach((t) => {
        let val = groups.filter((g) => {
          return g.id === t;
        });
        existingTags = [...existingTags, ...val];
      });
    }

    let obj = {
      additionalFields:
        record.childConfig && record.childConfig.length > 0 ? record.childConfig : null,
      address: values.address !== undefined ? values.address : null,
      birthDate: values.birthDate
        ? moment(values.birthDate, UserFilterAction.getDateFormatList()).format(UserFilterAction.getApiDateFormat())
        : null,
      chatPermission: false,
      classroomName: values.classroom && values.classroom.length > 0 ? values.classroom[0] : null,
      classList: values.classroom,
      gender: values.gender,
      id: record.id,
      name: values.name,
      admissionNumber: values.admissionNumber ? values.admissionNumber : null,
      platform: "web",
      status: values.status ? values.status : (record.status ? record.status : "Active"),
      admissionDate: values.admissionDate
        ? moment(values.admissionDate).format(UserFilterAction.getApiDateFormat())
        : null,

      updatedBy: firebase.teacher ? firebase.teacher.name : FilterAction.getParentRelation(firebase.user.id, firebase),
      updatedOn: moment().valueOf(),
      preferredName: values.prefName ? values.prefName : null,
      tags: existingTags && existingTags.length > 0 ? existingTags : null,
      emergencyContactName: values.emergencyContactName ? values.emergencyContactName : null,
      emergencyNumber: values.emergencyContactNumber ? values.emergencyContactNumber : null,
    };

    if (firebase?.selectedMode?.toLowerCase() === "parent") {
      delete obj.admissionNumber
      delete obj.classroomName
      delete obj.classList
      delete obj.admissionDate
      delete obj.tags
    }

    let genericMessage = "Failed to update student. Please contact school or Illumine";
    let response = yield call(StudentApi.updateStudentApi, firebase, obj.id, obj);

    if (response && response.status === 200) {
      yield delay(2000);
      for (let index in removableTags) {
        let grp = yield call(TagApi.getTagById, removableTags[index].id, firebase);
        if (grp && grp.studentIds) {
          let studentIds = grp.studentIds;
          studentIds = studentIds.filter((stdId) => {
            return stdId !== record.id;
          });

          grp.studentIds = studentIds;
          yield call(TagApi.updateTagWithStudents, grp, firebase);
        }
      }

      for (let k in existingTags) {
        let eGrp = yield call(TagApi.getTagById, existingTags[k].id, firebase);
        if (eGrp) {
          let studentIds = eGrp.studentIds ? eGrp.studentIds : [];
          if (!studentIds.includes(record.id)) {
            studentIds.push(record.id);
          }
          eGrp.studentIds = studentIds;
          yield call(TagApi.updateTagWithStudents, eGrp, firebase);
        }
      }

      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);

      yield call(
        NotificationApi.callProfileUpdateWebHook,
        record,
        firebase,
        "PROFILE_UPDATE",
        values
      );
      yield put({
        type: actions.UPDATE_STUDENT_SUCCESSFUL,
      });

      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, obj, firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    console.log("failed to update student data", error);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* sendProfileUpdateNotification(studentDetail, firebase) {
  try {
    let teachers = yield call(
      lessonAssignmentApi.getTeachersByStudentClassList,
      studentDetail.classList ? studentDetail.classList : [studentDetail.classroomName],
      firebase
    );

    let studentId = studentDetail.id;

    let parentName =
      firebase.user && firebase.user.id === studentDetail.fatherProfileId
        ? studentDetail.fatherName
        : studentDetail.motherName;

    if (teachers && teachers.length > 0) {
      for (let index in teachers) {
        let teacher = teachers[index];
        let alertActivityName = "PROFILE_EDIT";
        let alertMessage = "Profile updated for " + studentDetail.name;
        let alertActivityType = "PROFILE_EDIT";
        let userType = "teacher";

        let permissionStatus = yield call(
          NotificationApi.receiveNotificationPermission,
          alertActivityName,
          alertActivityName,
          teacher,
          firebase
        );
        if (permissionStatus) {
          let sendNotification = false;
          if (teacher.groups) {
            sendNotification = yield call(
              NotificationApi.checkIfStudentInGroup,
              teacher,
              studentDetail
            );
          } else {
            sendNotification = true;
          }

          if (sendNotification) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              teacher.id,
              firebase
            );

            yield fork(
              NotificationApi.createSimpleAlertNotification,
              alertActivityName,
              null,
              teacher.uid ? teacher.uid : null,
              alertMessage,
              alertNode,
              teacher.ios_uid ? teacher.ios_uid : null,
              studentId,
              teacher.id,
              alertActivityType,
              undefined,
              firebase,
              parentName,
              userType
            );

            if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
              yield fork(
                NotificationApi.sendSimplePushNotification,
                alertActivityName,
                null,
                teacher.uid ? teacher.uid : null,
                alertMessage,
                alertNode,
                teacher.ios_uid ? teacher.ios_uid : null,
                studentId,
                teacher.id,
                alertActivityType,
                undefined,
                firebase,
                parentName,
                userType
              );
            }
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to send profile update notification", err);
    bugsnagClient.notify(err);
  }
}

function* deleteStudentData({ studentRecord, firebase }) {
  try {
    let genericMessage = "Failed to delete student. Please contact school or Illumine";
    let response = yield call(StudentApi.deleteStudentNewApi, firebase, studentRecord.id);
    if (response && response.status === 200) {
      yield put({
        type: actions.DELETE_STUDENT_SUCCESSFFUL,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to delete student", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchSelectedStudentDetail({ studentId, firebase, mode }) {
  let students = FilterAction.getStudentList(firebase);

  let data = [];
  let email;
  // for parent side
  // if (mode) {
  //   data = [firebase.student];
  // } else {
  let fetchedStd = yield call(StudentApi.getStudentById, studentId, firebase);
  if (fetchedStd && fetchedStd.id) {
    data = [fetchedStd];
  } else {
    data = students.filter((s) => {
      return s.id === studentId;
    });
  }

  yield put({
    type: actions.FETCH_STUDENT_DETAILS_SUCCESSFUL,
    studentDetail: data,
    // parentEmailChange: email,
    studentDetailChannel: undefined,
  });

  yield fork(fetchStudentAssignedPrograms, studentId, firebase);
  yield fork(fetchStudentNotesCount, studentId, firebase);
}

function* fetchSelectedStudentMedia({ studentId, firebase }) {
  try {
    let data = yield call(StudentApi.getStudentMedia, studentId, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_MEDIA_SUCCESS,
        selectedStudentMedia: data,
        // setMediaList: true,
      });
    }
  } catch (err) {
    console.log("failed to fetch student media", err);
    bugsnagClient.notify(err);
  }
}

function* fetchSelectedStudentFavtMedia({ studentId, firebase }) {
  try {
    let data = yield call(StudentApi.getStudentFavtMedia, studentId, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_FAVT_MEDIA_SUCCESS,
        selectedStudentFavtMedia: data,
        // setMediaList: true,
      });
    }
  } catch (err) {
    console.log("failed to fetch student media", err);
    bugsnagClient.notify(err);
  }
}
// function* fetchSelectedStudentMedia({ studentId, firebase }) {
//   const chan = yield call(StudentApi.getStudentMedia, studentId, firebase);
//   try {
//     while (true) {
//       let data = yield take(chan);
//       yield put({
//         type: actions.GET_STUDENT_MEDIA_SUCCESS,
//         selectedStudentMedia: data,

//         studentMediaChan: chan,
//       });
//     }
//   } finally {
//     console.log("end media channel");
//   }
// };

function* fetchStudentNotesCount(studentId, firebase) {
  try {
    let data = yield call(StudentApi.getStudentNotesCount, studentId, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_NOTE_COUNT,
        studentNotesCount: data.length,
      });
    }
  } catch (err) {
    console.log("failed to fetch student notes count", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentAssignedPrograms(studentId, firebase) {
  try {
    let data = yield call(ProgramApi.fetchPrograms, firebase);

    let programList = [];
    if (data) {
      for (let index in data) {
        if (data[index].student) {
          let students = data[index].student;
          let tempVal = students.filter((s) => {
            return s.studentId === studentId;
          });

          if (tempVal && tempVal.length > 0) {
            programList.push(data[index].name);
          }
        }
      }
    }

    yield put({
      type: actions.GET_PROGRAM_BY_STUDENT_ID,
      studentProgramList: programList,
    });
  } catch (err) {
    console.log("failed to fetch student detailed view program list", err);
    bugsnagClient.notify(err);
  }
}

function* fetchUserVersion({ firebase }) {
  try {
    let data = yield call(StudentApi.getUserVersions, firebase);
    if (data) {
      yield put({
        type: actions.GET_USER_VERSION_SUCCESSFUL,
        userVersion: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch user version", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* sentInviteToParents({ parentList, firebase, changeEmail, relation }) {
  try {
    var response = yield call(
      StudentApi.sendInviteToParents,
      parentList,
      firebase,
      undefined,
      changeEmail,
      relation
    );

    if (response) {
      yield put({
        type: actions.SEND_INVITE_SUCCESSFUL,
        status: response,
      });
    }
  } catch (error) {
    console.log("failed to send invite to parents", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function leavesDetail(leaves) {
  let leaveDates = [];
  leaves.forEach((l) => {
    let dates = getAllDatesBetween(moment(l.startDate), moment(l.endDate));
    leaveDates = [...leaveDates, ...dates];
  });
  return leaveDates;
}

function getAllDatesBetween(startDate, endDate) {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push({
      date: now.valueOf(),
      type: "leave",
    });
    now.add(1, "days");
  }
  return dates;
}

function* fetchSelectedStudentNewAttendance(studentId, month, firebase) {
  try {
    var month1 = new Date(month).getMonth();
    var year = new Date(month).getFullYear();
    var firstDay = new Date(year, month1, 1);
    var lastDay = new Date(year, month1 + 1, 0);

    let data = yield call(StudentApi.getSelectedStudentNewAttendance, studentId, month, firebase);

    let leaves = yield call(
      StudentApi.getSelectedStudentLeave,
      firstDay,
      lastDay,
      studentId,
      firebase
    );
    var studentAttendance = [];

    if (data) {
      var presentCount = 0;
      var absentCount = 0;
      var lateCheckIn = 0;
      var lateCheckOut = 0;

      for (let i = 0; i < data.length; i++) {
        studentAttendance.push(data[i]);
        let eleVal = data[i];
        if (eleVal.roomRecords) {
          presentCount++;
        } else {
          if (eleVal.absent) {
            absentCount++;
          }
        }
      }

      studentAttendance.sort(function (a, b) {
        var dateA = a.date,
          dateB = b.date;
        return dateB - dateA;
      });

      let leavesCount = 0;
      for (let i = 0; i < leaves.length; i++) {
        let loop = new Date(leaves[i].startDate);
        let endDate = new Date(leaves[i].endDate);

        while (loop <= endDate) {
          leavesCount++;

          var newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
      }

      yield put({
        type: actions.GET_SELECTED_STUDENT_ATTENDANCE_SUCCESSFUL,
        studentAttendance: studentAttendance,
        presentCount: presentCount,
        absentCount: absentCount,
        leavesCount: leavesCount,
        lateCheckIn: lateCheckIn,
        lateCheckOut: lateCheckOut,
        studentLeaves: leaves,
      });
    }
  } catch (err) {
    console.log("failed to fetch selected student new attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchSelectedStudentAttendance({ studentId, month, firebase, oldAttendance }) {
  if (firebase.schoolConfig.roomAttendanceMode && !oldAttendance) {
    yield fork(fetchSelectedStudentNewAttendance, studentId, month, firebase);
  } else {
    var month1 = new Date(month).getMonth();
    var year = new Date(month).getFullYear();
    var firstDay = new Date(year, month1, 1);
    var lastDay = new Date(year, month1 + 1, 0);
    try {
      let data = yield call(StudentApi.getSelectedStudentAttendance, studentId, month, firebase);

      let leaves = yield call(
        StudentApi.getSelectedStudentLeave,
        firstDay,
        lastDay,
        studentId,
        firebase
      );

      if (data) {
        var studentAttendance = [];
        var presentCount = 0;
        var absentCount = 0;
        var lateCheckIn = 0;
        var lateCheckOut = 0;

        for (let i = 0; i < data.length; i++) {
          studentAttendance.push(data[i]);

          if (data[i].absent === false) {
            presentCount++;

            if (data[i].late !== undefined && data[i].late === true) {
              lateCheckIn++;
            }

            if (data[i].lateCheckout !== undefined && data[i].lateCheckout === true) {
              lateCheckOut++;
            }
          } else {
            absentCount++;
          }
        }

        studentAttendance.sort(function (a, b) {
          var dateA = a.date,
            dateB = b.date;
          return dateB - dateA;
        });

        let leavesCount = 0;
        for (let i = 0; i < leaves.length; i++) {
          let loop = new Date(leaves[i].startDate);
          let endDate = new Date(leaves[i].endDate);

          while (loop <= endDate) {
            leavesCount++;

            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
        }
        yield put({
          type: actions.GET_SELECTED_STUDENT_ATTENDANCE_SUCCESSFUL,
          studentAttendance: studentAttendance,
          presentCount: presentCount,
          absentCount: absentCount,
          leavesCount: leavesCount,
          lateCheckIn: lateCheckIn,
          lateCheckOut: lateCheckOut,
          studentLeaves: leaves,
        });
      }
    } catch (error) {
      console.log("failed to fetch selected student attendance", error);
      bugsnagClient.notify(error);
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  }
}

function* updateStudentMedications({ medicines, student, firebase }) {
  try {
    let genericMessage = "Failed to update medicine. Please contact school or Illumine";
    let medicineObject = {
      medication: medicines,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(StudentApi.updateStudentApi, firebase, student[0].id, medicineObject);
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_STUDENT_ALLERGY_SUCCESSFUL,
      });

      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, student[0], firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update medicines", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentCommunicationEmail({ communicationText, student, firebase }) {
  try {
    let genericMessage = "Failed to update communication email. Please contact school or Illumine";
    let commObject = {
      additionalCommunicationEmails: communicationText,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(StudentApi.updateStudentApi, firebase, student[0].id, commObject);
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);

      yield put({
        type: actions.UPDATE_STUDENT_COMMUNICATION_EMAIL_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update communication email", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentAllergies({ allergy, student, firebase }) {
  try {
    let genericMessage = "Failed to update allergy. Please contact school or Illumine";
    let allergyObject = {
      allergies: allergy,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(StudentApi.updateStudentApi, firebase, student[0].id, allergyObject);
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_STUDENT_ALLERGY_SUCCESSFUL,
      });

      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, student[0], firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update allergies", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentStatus({ studentRecord, firebase }) {
  try {
    let studentObject = {
      deactivated: studentRecord.deactivated === true ? false : true,
      deactivationDate: moment().valueOf(),
      platform: "web",
      id: studentRecord.id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(
      StudentApi.updateStudentApi,
      firebase,
      studentRecord.id,
      studentObject
    );
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_STUDENT_ACTIVE_STATUS_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : "Failed to update status. Please contact school or Illumine.",
      });
    }
  } catch (err) {
    console.log("failed to update student status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentBloodGroup({ bloodGroup, student, firebase }) {
  try {
    let genericMessage = "Failed to update blood group. Please contact school or Illumine";

    let bloodGroupObject = {
      bloodGroup: bloodGroup,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(
      StudentApi.updateStudentApi,
      firebase,
      student[0].id,
      bloodGroupObject
    );

    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_BLOOD_GROUP_SUCCESSFUL,
      });

      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, student[0], firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update blood group", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentAdmissionNumber({ admissionNumber, student, firebase }) {
  try {
    let genericMessage = "Failed to update admission number. Please contact school or Illumine";
    let admissionObject = {
      admissionNumber: admissionNumber,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(
      StudentApi.updateStudentApi,
      firebase,
      student[0].id,
      admissionObject
    );
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_ADMISSION_NUMBER_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStudentTrialCount({ trialCountText, student, firebase }) {
  try {
    let genericMessage = "Failed to update trial count. Please contact school or Illumine";
    let trialObject = {
      trialCount: Number(trialCountText),
      meetingJoinedDate: null,
      meetingDate: null,
      meetingDuration: null,
      activityId: null,
      timezone: null,
      id: student[0].id,
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };

    let response = yield call(StudentApi.updateStudentApi, firebase, student[0].id, trialObject);
    if (response && response.status === 200) {
      let std = student[0];
      let studentBookings = yield call(ActivityApi.getStudentBookingById, std.id, firebase);
      if (studentBookings) {
        console.log("studentBookings ----", studentBookings);
        for (let index in studentBookings) {
          let bookingRefId = studentBookings[index].bookingRefId;
          let bookingRef = yield call(ActivityApi.getBookingRefById, bookingRefId, firebase);
          if (bookingRef && bookingRef.id) {
            let bookingStudentIds = bookingRef.studentIds;
            let filteredBookingStudentIds = bookingStudentIds.filter((b) => {
              return b !== std.id;
            });
            bookingRef.studentIds = filteredBookingStudentIds;
            yield call(ActivityApi.updatedBookingRef, bookingRef, firebase);
          }
        }
      }

      //remove complete booking ref
      yield call(ActivityApi.removeCompleteStudentBookingRef, std.id, firebase);

      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.UPDATE_TRIAL_COUNT_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

// function* removeStudentBookingRef(bookingRef, student, firebase) {
//   try {
//     let studentBookingRef = yield call(ActivityApi.getStudentBookingRef, student.id, bookingRef.id, firebase);

//     if (studentBookingRef && studentBookingRef.id) {
//       yield call(ActivityApi.removeStudentBookingRef, student.id, studentBookingRef.id, firebase);
//     }
//   } catch (err) {
//     console.log("failed to remove student booking reference", err);
//     bugsnagClient.notify(err);
//   }
// }

function* updateStudentFormFields({ record, firebase }) {
  try {
    let studentData = record;
    // if (studentData.childConfig) {
    //   let sortedConfig = studentData.childConfig.sort(
    //     (a, b) => a.order - b.order
    //   );
    //   console.log("sortedConfig ---", sortedConfig);
    // }
    // let data = yield call(StudentApi.getAllFormFields, firebase);
    // if (data) {
    //   let parentConfig = data.filter((f) => {
    //     return f.fieldType.toLowerCase() === "parent" && !f.deleted;
    //   });

    //   let filteredFatherConfig = [];
    //   let filteredMotherConfig = [];
    //   let fatherConfig = record.fatherConfig ? record.fatherConfig : [];
    //   let motherConfig = record.motherConfig ? record.motherConfig : [];

    //   for (let i = 0; i < parentConfig.length; i++) {
    //     let fatherStatus = false;
    //     for (let j = 0; j < fatherConfig.length; j++) {
    //       if (fatherConfig[j].id === parentConfig[i].id) {
    //         if (parentConfig[i].dataType === "Dropdown") {
    //           fatherConfig[j].dropdownValues = parentConfig[i].dropdownValues
    //             ? parentConfig[i].dropdownValues
    //             : [];
    //         }
    //         fatherStatus = true;
    //         break;
    //       } else {
    //         fatherStatus = false;
    //       }
    //     }
    //     if (fatherStatus === false) {
    //       filteredFatherConfig.push(parentConfig[i]);
    //     }
    //   }

    //   if (filteredFatherConfig.length > 0) {
    //     //fatherConfig.push(filteredFatherConfig);
    //     fatherConfig = [...fatherConfig, ...filteredFatherConfig];
    //   }
    //   studentData.fatherConfig = fatherConfig;

    //   for (let i = 0; i < parentConfig.length; i++) {
    //     let motherStatus = false;
    //     for (let j = 0; j < motherConfig.length; j++) {
    //       if (motherConfig[j].id === parentConfig[i].id) {
    //         if (parentConfig[i].dataType === "Dropdown") {
    //           motherConfig[j].dropdownValues = parentConfig[i].dropdownValues
    //             ? parentConfig[i].dropdownValues
    //             : [];
    //         }
    //         motherStatus = true;
    //         break;
    //       } else {
    //         motherStatus = false;
    //       }
    //     }
    //     if (motherStatus === false) {
    //       filteredMotherConfig.push(parentConfig[i]);
    //     }
    //   }

    //   if (filteredMotherConfig.length > 0) {
    //     // motherConfig.push(filteredMotherConfig);
    //     motherConfig = [...motherConfig, ...filteredMotherConfig];
    //   }
    //   studentData.motherConfig = motherConfig;
    //   studentData.platform = "web";
    //   studentData.updatedBy =
    //     firebase.teacher && firebase.teacher.name
    //       ? firebase.teacher.name
    //       : null;
    //   studentData.updatedOn = moment().valueOf();
    //   yield fork(
    //     StudentApi.updateStudentWithUpdatedFormFields,
    //     studentData,
    //     firebase
    //   );

    //   yield fork(
    //     StudentAttendanceApi.lastUpdateTimestamp,
    //     new Date(),
    //     firebase
    //   );
    yield put({
      type: actions.REFRESH_FORM_FIELDS_SUCCESSFUL,
      selectedStudent: studentData,
    });
    // }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to refresh form field of student", err);
  }
}

function* uploadParentPic({ file, parentId, firebase, parentType, studentDetail }) {
  try {
    let genericMessage = "Failed to update profile picture. Please contact school or Illumine";
    let storagePath = firebase.sbp + "/media/profileimages/";
    let urls = yield call(ActivityApi.getMediaPath, storagePath, file, firebase);
    if (urls) {
      if (urls.length > 0) {
        if (parentType === "Father") {
          studentDetail[0].fatherImage = urls[0];
          let fatherprofileImageUrlObj = {
            url: urls[0],
            platform: "web",
            id: parentId,
            updatedBy: firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent" ? FilterAction.getParentRelation(firebase.user.id, firebase) : (firebase.teacher ? firebase.teacher.name : null),
            updatedOn: moment().valueOf(),
            parentType: parentType,
          };

          let response = yield call(
            StudentApi.updateParentPicApi,
            firebase,
            parentId,
            fatherprofileImageUrlObj
          );
          if (response && response.status === 200) {
            yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
            yield put({
              type: actions.UPLOAD_PARENT_PROFILE_SUCCESSFUL,
            });
          } else {
            yield put({
              type: actions.REQUEST_FAILED,
              errorMessage:
                response.body && response.body.response ? response.body.response : genericMessage,
            });
          }
        } else {
          studentDetail[0].motherImage = urls[0];
          let motherprofileImageUrlObj = {
            url: urls[0],
            platform: "web",
            id: parentId,
            updatedBy: firebase.teacher ? firebase.teacher.name : null,
            updatedOn: moment().valueOf(),
            parentType: parentType,
          };

          let response = yield call(
            StudentApi.updateParentPicApi,
            firebase,
            parentId,
            motherprofileImageUrlObj
          );
          if (response && response.status === 200) {
            yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
            yield put({
              type: actions.UPLOAD_PARENT_PROFILE_SUCCESSFUL,
            });
          } else {
            yield put({
              type: actions.REQUEST_FAILED,
              errorMessage:
                response.body && response.body.response ? response.body.response : genericMessage,
            });
          }
        }
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload display pic", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateParentAdditionalDetails({ values, selectedParentType, editableRecord, firebase }) {
  try {
    let genericMessage = "Failed to update parent details. Please contact school or Illumine";

    let parentName;
    let parentNumber;
    let parentEmail;
    let parentCompanyName;
    let parentConfig;
    let countryCode = values.countryCode ? values.countryCode : null;
    let parentType = selectedParentType === "Father" ? "parent2" : "parent1";
    let oldPhoneNumber;
    let oldEmail;
    let parentId;
    let relationship = values.relationship;
    let check;
    let motherEmailChange;
    let fatherEmailChange;
    let emailRelation;
    let selectedStudent = [editableRecord];

    if (selectedParentType === "Father") {
      parentId = editableRecord.fatherProfileId;
      parentName = values.fatherName;
      parentNumber = values.fatherNumber;
      parentEmail = values.fatherEmail ? values.fatherEmail : null;
      oldPhoneNumber = editableRecord.fatherNumber ? editableRecord.fatherNumber : null;
      oldEmail = editableRecord.fatherEmail ? editableRecord.fatherEmail : null;
      parentCompanyName = values.fatherCompanyName ? values.fatherCompanyName : null;

      parentConfig = editableRecord.fatherConfig ? editableRecord.fatherConfig : [];

      if (parentEmail !== null) {
        fatherEmailChange = parentEmail.localeCompare(oldEmail);
        if (fatherEmailChange !== 0) {
          check = parentEmail;
          emailRelation = "father";
        }
      }
      for (let i = 0; i < parentConfig.length; i++) {
        let id = parentConfig[i].id;
        if (id) {
          if (parentConfig[i].dataType === "Date") {
            parentConfig[i].value = values[id]
              ? moment(new Date(values[id])).format(UserFilterAction.getApiDateFormat())
              : null;
          }
          else {
            parentConfig[i].value = values[id] ? values[id].toString() : null;

          }
        }
      }

    } else {
      parentId = editableRecord.motherProfileId;
      parentName = values.motherName;
      parentNumber = values.motherNumber;
      parentEmail = values.motherEmail ? values.motherEmail : null;
      oldPhoneNumber = editableRecord.motherNumber ? editableRecord.motherNumber : null;
      oldEmail = editableRecord.motherEmail ? editableRecord.motherEmail : null;
      parentCompanyName = values.motherCompanyName ? values.motherCompanyName : null;

      parentConfig = editableRecord.motherConfig ? editableRecord.motherConfig : [];
      if (parentEmail !== null) {
        motherEmailChange = parentEmail.localeCompare(oldEmail);
        if (motherEmailChange !== 0) {
          check = parentEmail;
          emailRelation = "mother";
        }
      }

      for (let i = 0; i < parentConfig.length; i++) {
        let id = parentConfig[i].id;
        if (id) {
          if (parentConfig[i].dataType === "Date") {
            parentConfig[i].value = values[id]
              ? moment(new Date(values[id])).format(UserFilterAction.getApiDateFormat())
              : null;
          }
          else {
            parentConfig[i].value = values[id] ? values[id].toString() : null;

          }
        }
      }
    }
    let response = yield call(
      StudentApi.updateParentApi,
      firebase,
      editableRecord.name,
      editableRecord.id,
      parentName,
      Number(parentNumber),
      parentEmail,
      parentCompanyName,
      parentConfig,
      countryCode,
      parentType,
      oldPhoneNumber,
      oldEmail,
      editableRecord,
      parentId,
      relationship
    );
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.FORCE_LOADER_START,
        });
        if (values.fatherEmail) {
          yield fork(updateStripeEmail, values.fatherEmail, editableRecord, firebase, "father");
        }

        if (values.motherEmail) {
          yield fork(updateStripeEmail, values.motherEmail, editableRecord, firebase, "mother");
        }

        yield delay(2000);

        yield put({
          type: actions.SAVE_PARENT_EXTRA_DETAILS_SUCCESSFUL,
          parentEmailChange: check,
          emailRelation: emailRelation,
          studentDetailFormTable: selectedStudent,
        });
      } else if (response && (response.status === 201 || response.status === 202)) {
        // let siblingObj = response.body.student;
        // let parentId = response.body.parentId;

        if (response.status === 201) {
          let showError = false;
          if (selectedParentType === "Father" && editableRecord.fatherProfileId) {
            showError = true;
          } else if (selectedParentType === "Mother" && editableRecord.motherProfileId) {
            showError = true;
          }

          if (showError) {
            yield put({
              type: actions.REQUEST_FAILED,
              errorMessage: "Please remove the parent and add again",
            });
          }
        } else {
          let siblingObj;
          let parentId;

          if (response.status === 202) {
            siblingObj = {};
            parentId = response.body.userNode.id;
            siblingObj.message = "This profile is already added as a teacher. Do you want to link \"" + editableRecord.name + "\" as their child ?";
          } else {
            siblingObj = response.body.student;
            parentId = response.body.parentId;
            siblingObj.message = "Already added as a parent of " + siblingObj.name + ". Do you want to add \"" + editableRecord.name + "\" as a sibling of \"" + siblingObj.name + "\" ?";
          }

          siblingObj.originalStudent = editableRecord;
          siblingObj.parentType = parentType;
          siblingObj.parentId = parentId;
          yield put({
            type: actions.LINK_FOUND,
            existingDetail: siblingObj,
            parentEmailChange: check,
          });
        }
      } else {
        yield put({
          type: actions.REQUEST_FAILED,
          errorMessage:
            response.body && response.body.response ? response.body.response : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to update parent detail", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateStripeEmail(email, student, firebase, relation) {
  try {
    if (
      firebase.schoolConfig.paymentAccount &&
      firebase.schoolConfig.paymentAccount.toLowerCase() === "stripe"
    ) {
      let stripeCustomer = yield call(StudentApi.getStripeCustomer, student.id, firebase);
      if (stripeCustomer && stripeCustomer.customerId) {
        let custId;
        if (relation === "mother") {
          if (stripeCustomer.motherCustomerId) {
            custId = stripeCustomer.motherCustomerId;
          }
        } else if (relation === "father") {
          if (stripeCustomer.fatherCustomerId) {
            custId = stripeCustomer.fatherCustomerId;
          }
        }

        if (custId) {
          let response = yield call(
            NotificationApi.updateEmailInStripe,
            custId,
            email,
            student.id,
            firebase
          );
          if (!response) {
            bugsnagClient.notify(new Error("Failed to update email to stripe account"));
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to update email in stripe", err);
    bugsnagClient.notify(err);
  }
}

function* updateChildFormFields({ record, firebase }) {
  try {
    let studentData = record;

    // let data = yield call(StudentApi.getAllFormFields, firebase);
    // if (data) {
    //   let myData = data;
    //   let studentConfig = myData.filter((i) => {
    //     return (
    //       i.fieldType.toLowerCase() === "Student".toLowerCase() && !i.deleted
    //     );
    //   });

    //   let childConfig = record.childConfig ? record.childConfig : [];

    //   let filteredChildConfig = [];
    //   for (let i = 0; i < studentConfig.length; i++) {
    //     let status = false;
    //     for (let j = 0; j < childConfig.length; j++) {
    //       if (childConfig[j].id === studentConfig[i].id) {
    //         if (studentConfig[i].dataType === "Dropdown") {
    //           childConfig[j].dropdownValues = studentConfig[i].dropdownValues
    //             ? studentConfig[i].dropdownValues
    //             : [];
    //         }
    //         status = true;
    //         break;
    //       } else {
    //         status = false;
    //       }
    //     }
    //     if (status === false) {
    //       filteredChildConfig.push(studentConfig[i]);
    //     }
    //   }

    //   if (filteredChildConfig.length > 0) {
    //     childConfig = [...childConfig, ...filteredChildConfig];
    //   }
    //   studentData.childConfig = childConfig;
    //   studentData.platform = "web";
    //   studentData.updatedOn = moment().valueOf();
    //   studentData.updatedBy =
    //     firebase.teacher && firebase.teacher.name
    //       ? firebase.teacher.name
    //       : null;

    //   yield fork(
    //     StudentApi.updateStudentWithUpdatedFormFields,
    //     studentData,
    //     firebase
    //   );

    //   yield fork(
    //     StudentAttendanceApi.lastUpdateTimestamp,
    //     new Date(),
    //     firebase
    //   );
    yield put({
      type: actions.REFRESH_CHILD_FORM_FIELDS_SUCCESSFUL,
      selectedStudent: studentData,
    });
  } catch (err) {
    console.log("failed to refresh child form fields", err);
    bugsnagClient.notify(err);
  }
}

function* updateStudentClassChange({ students, classroom, firebase }) {
  try {
    let studentList = [];
    students.map((s) => {
      var data = {};
      data.id = s.id;
      studentList.push(data);
    });
    let response = yield call(
      StudentApi.updateStudentClassChange,
      studentList,
      classroom,
      firebase
    );
    if (response && response.statusCode === 200) {
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.STUDENT_CLASS_CHANGE_SUCCESSFUL,
        operationType: "CLASS_CHANGE",
      });
    } else {
      yield put({
        type: actions.STUDENT_CLASS_CHANGE_SUCCESSFUL,
        operationType: "CLASS_CHANGE_UNSUCCESSFUL",
      });
    }
  } catch (err) {
    console.log("failed to update student classroom", err);
    bugsnagClient.notify(err);
  }
}

function* updateStudentDisplayPic({ file, studentDetail, firebase }) {
  try {
    let genericMessage = "Failed to update profile picture. Please contact school or Illumine";
    let storagePath = firebase.sbp + "/media/profileimages/";
    let urls = yield call(ActivityApi.getMediaPath, storagePath, file, firebase);
    if (urls) {
      if (urls.length > 0) {
        studentDetail[0].profileImageUrl = urls[0];
        let profileImageUrlObj = {
          profileImageUrl: urls[0],
          platform: "web",
          id: studentDetail[0].id,
          updatedBy: firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent" ? FilterAction.getParentRelation(firebase.user.id, firebase) : (firebase.teacher ? firebase.teacher.name : null),
          updatedOn: moment().valueOf(),
        };

        let response = yield call(
          StudentApi.updateStudentApi,
          firebase,
          studentDetail[0].id,
          profileImageUrlObj
        );
        if (response && response.status === 200) {
          yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
          yield put({
            type: actions.UPLOAD_PROFILE_SUCCESSFUL,
          });
        } else {
          yield put({
            type: actions.REQUEST_FAILED,
            errorMessage:
              response.body && response.body.response ? response.body.response : genericMessage,
          });
        }
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload display pic", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* createAndDownloadAllergyExcelSheet({ students, firebase }) {
  try {
    const fields = ["name", "classroomName", "allergies"];
    const opts = { fields };
    let studentList = [];
    let data = students;
    data.forEach((i) => {
      var row = {};
      row.name = i.name;
      row.classroomName = i.classList ? i.classList.toString() : i.classroomName;

      row.allergies = i.allergies;
      if (row.allergies) studentList.push(row);
    });

    const parser = new Parser(opts);
    const csv = parser.parse(studentList);
    console.log(csv);

    var csvData = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "students_allergies.csv");
    tempLink.click();
    yield put({
      type: actions.DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to download excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function getParentFields(firebase, student, row) {
  var motherPrefix = firebase.schoolConfig.genderNeutral ? "parent1" : "mother";
  var fatherPrefix = firebase.schoolConfig.genderNeutral ? "parent2" : "father";

  row[fatherPrefix + "Name"] = student.fatherName;
  row[fatherPrefix + "Email"] = student.fatherEmail;
  row[fatherPrefix + "Number"] = student.fatherNumber;
  row[fatherPrefix + "Company"] = student.fatherCompany;

  row[motherPrefix + "Name"] = student.motherName;
  row[motherPrefix + "Email"] = student.motherEmail;
  row[motherPrefix + "Number"] = student.motherNumber;
  row[motherPrefix + "Company"] = student.motherCompany;
  row[fatherPrefix + "CountryCode"] = student.fatherCountryCode
    ? student.fatherCountryCode
    : student.code
      ? student.code
      : "";
  row[motherPrefix + "CountryCode"] = student.motherCountryCode
    ? student.motherCountryCode
    : student.code
      ? student.code
      : "";

  row[fatherPrefix + "Company"] = student.fatherCompanyName ? student.fatherCompanyName : "";
  row[motherPrefix + "Company"] = student.motherCompanyName ? student.motherCompanyName : "";
}

function getFields(firebase) {
  var fields;
  if (firebase.schoolConfig.genderNeutral) {
    if (firebase.schoolConfig.billingMode == "Fee Plan") {
      fields = [
        "name",
        "gender",
        "createdAt",
        "admissionNumber",
        "admissionDate",
        "birthDate",
        "bloodGroup",
        "classroomName",
        "parent1Name",
        "parent1Email",
        "parent1CountryCode",
        "parent1Number",
        "parent1Company",
        "parent2Name",
        "parent2Email",
        "parent2CountryCode",
        "parent2Number",
        "parent2Company",
        "address",
        "status",
        "deactivationDate",
        "kioskCode",
        "feePlans",
        "groups",
        "emergencyContactName",
        "emergencyContactNumber",
        "preferredName",
        "medication",
      ];
    } else {
      fields = [
        "name",
        "gender",
        "createdAt",
        "admissionNumber",
        "admissionDate",
        "birthDate",
        "bloodGroup",
        "classroomName",
        "parent1Name",
        "parent1Email",
        "parent1CountryCode",
        "parent1Number",
        "parent1Company",
        "parent2Name",
        "parent2Email",
        "parent2CountryCode",
        "parent2Number",
        "parent2Company",
        "address",
        "status",
        "deactivationDate",
        "kioskCode",
        "groups",
        "emergencyContactName",
        "emergencyContactNumber",
        "preferredName",
        "medication",
      ];
    }
  } else {
    if (firebase.schoolConfig.billingMode == "Fee Plan") {
      fields = [
        "name",
        "gender",
        "createdAt",
        "admissionNumber",
        "admissionDate",
        "birthDate",
        "bloodGroup",
        "classroomName",
        "fatherName",
        "fatherEmail",
        "fatherNumber",
        "fatherCountryCode",
        "fatherCompany",
        "motherName",
        "motherEmail",
        "motherNumber",
        "motherCountryCode",
        "motherCompany",
        "address",
        "status",
        "deactivationDate",
        "kioskCode",
        "feePlans",

        "groups",
        "emergencyContactName",
        "emergencyContactNumber",
        "preferredName",
        "medication",
      ];
    } else {
      fields = [
        "name",
        "gender",
        "createdAt",
        "admissionNumber",
        "admissionDate",
        "birthDate",
        "bloodGroup",
        "classroomName",
        "fatherName",
        "fatherEmail",
        "fatherNumber",
        "fatherCountryCode",
        "fatherCompany",
        "motherName",
        "motherEmail",
        "motherNumber",
        "motherCountryCode",
        "motherCompany",
        "address",
        "status",
        "deactivationDate",
        "kioskCode",

        "groups",
        "emergencyContactName",
        "emergencyContactNumber",
        "preferredName",
        "medication",
      ];
    }
  }
  return fields;
}
function* createAndDownloadExcelSheet({ students, firebase }) {
  try {
    var fields = getFields(firebase);
    const opts = { fields };
    let studentList = [];
    let data = students;
    let tags = JSON.parse(getItem("groupList"));
    let tagNameList = [];
    tags.forEach((ele) => {
      tagNameList.push(ele.name);
    });

    for (let index in data) {
      let i = data[index];
      let feePlanList = [];
      // if (firebase.schoolConfig.billingMode == "Fee Plan") {
      // feePlanList = yield call(FeeApi.getStudentFeePlan, i.id, firebase);

      // }

      feePlanList = firebase.studentFeePlanMap[i.id] ? firebase.studentFeePlanMap[i.id] : [];
      let planName = "";
      if (feePlanList && feePlanList.length > 0) {
        for (let f in feePlanList) {
          planName =
            planName +
            (planName !== "" ? ", " : "") +
            feePlanList[f].planName +
            "(" +
            feePlanList[f].frequency +
            ")";
        }
      }

      var row = {};
      row.createdAt = i.dateCreated ? moment(i.dateCreated).toDate() : "";
      row.name = i.name;
      row.preferredName = i.preferredName ? i.preferredName : "";
      row.gender = i.gender;
      row.admissionNumber = i.admissionNumber;
      row.birthDate = i.birthDate
        ? moment(i.birthDate, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
        : "";
      row.bloodGroup = i.bloodGroup;
      row.classroomName = i.classList ? i.classList.toString() : i.classroomName;
      getParentFields(firebase, i, row);
      // row.fatherName = i.fatherName;
      // row.fatherEmail = i.fatherEmail;
      // row.fatherNumber = i.fatherNumber;
      // row.fatherCountryCode = i.fatherCountryCode
      //   ? i.fatherCountryCode
      //   : i.code
      //   ? i.code
      //   : "";
      // row.motherName = i.motherName;
      // row.motherEmail = i.motherEmail;
      // row.motherNumber = i.motherNumber;
      // row.motherCountryCode = i.motherCountryCode
      //   ? i.motherCountryCode
      //   : i.code
      //   ? i.code
      //   : "";
      row.address = i.address;
      // row.fatherCompany = i.fatherCompanyName ? i.fatherCompanyName : "";
      // row.motherCompany = i.motherCompanyName ? i.motherCompanyName : "";
      row.medication = i.medicine ? i.medicine : "";
      row.kioskCode = i.kioskCode ? i.kioskCode : "";
      if (firebase.schoolConfig.billingMode == "Fee Plan") {
        row.feePlans = planName;
      }
      row.status = i.status ? i.status : "Active";
      if (i.deactivated) {
        row.deactivationDate = i.deactivationDate ? moment(i.deactivationDate).toDate() : 0;
      }
      if (i.childConfig) {
        for (let ind in i.childConfig) {
          let propVal = i.childConfig[ind];
          let propsName = propVal.name;
          let propsValue = propVal.value;
          if (!fields.includes(propsName)) {
            fields.push(propsName);
          }

          if (propVal.dataType.toLowerCase() === "date") {
            row[propsName] = propsValue
              ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
              : "";
          } else {
            row[propsName] = propsValue ? propsValue : "";
          }
        }
      }

      if (i.fatherConfig) {
        for (let ind in i.fatherConfig) {
          let propVal = i.fatherConfig[ind];
          let propsName = firebase.schoolConfig.genderNeutral
            ? "Parent2_" + propVal.name
            : "Father_" + propVal.name;

          let propsValue = propVal.value;
          if (!fields.includes(propsName)) {
            fields.push(propsName);
          }

          if (propVal.dataType.toLowerCase() === "date") {
            row[propsName] = propsValue
              ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
              : "";
          } else {
            row[propsName] = propsValue ? propsValue : "";
          }
        }
      }

      if (i.motherConfig) {
        for (let ind in i.motherConfig) {
          let propVal = i.motherConfig[ind];
          let propsName = firebase.schoolConfig.genderNeutral
            ? "Parent1_" + propVal.name
            : "Mother_" + propVal.name;

          let propsValue = propVal.value;
          if (!fields.includes(propsName)) {
            fields.push(propsName);
          }

          if (propVal.dataType.toLowerCase() === "date") {
            row[propsName] = propsValue
              ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
              : "";
          } else {
            row[propsName] = propsValue ? propsValue : "";
          }
        }
      }
      row.groups = "";
      if (i.tags) {
        i.tags.forEach((tagObject) => {
          if (tagNameList.includes(tagObject.name)) {
            row.groups += tagObject.name + ",";
          }
        });
      }

      row.admissionDate = i.admissionDate ? moment(i.admissionDate).format("DD-MMM-YY") : "";
      row.emergencyContactName = i.emergencyContactName ? i.emergencyContactName : "";
      row.emergencyContactNumber = i.emergencyNumber ? i.emergencyNumber : "";
      studentList.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "StudentList";

    var ws = XLSX.utils.json_to_sheet(studentList, {
      header: fields,
      dateNF: "DD-MMM-YYYY",
    });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const newData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(newData, fileName + fileExtension);

    yield put({
      type: actions.DOWNLOAD_STUDENT_EXCEL_SHEET_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to download excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchStudentFeePlan({ studentId, firebase }) {
  try {
    let data = yield call(FeeApi.getStudentFeePlan, studentId, firebase);
    // console.log("student fee plann -----", data);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_FEE_PLAN_SUCCESS,
        studentFeePlanList: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* deleteFeePlan({ record, studentId, firebase }) {
  try {
    let feePlanId = record.id;
    let feePlan = yield call(FeeApi.getSelectedFeePlanDetailById, feePlanId, firebase);
    if (feePlan && feePlan.id) {
      if (firebase.dbName === "GetReadyEdu_Master-Branch") {
        let studentRecord;

        if (feePlan.student && feePlan.student.length > 0) {
          let singleStudentRecord = feePlan.student.filter((std) => {
            return std.studentId === studentId;
          });

          if (singleStudentRecord && singleStudentRecord.length > 0) {
            studentRecord = singleStudentRecord[0];
          }
        }

        if (studentRecord) {
          let studentList = FilterAction.getStudentList(firebase);
          let existingStudent = studentList.filter((s) => {
            return s.id === studentRecord.studentId;
          });

          if (existingStudent && existingStudent.length > 0) {
            let filteredStudent = existingStudent[0];
            let data = yield call(
              FeeApi.getStudentFeePlanByPlanId,
              filteredStudent.id,
              feePlan.id,
              firebase
            );
            if (data) {
              yield call(
                NotificationApi.callFeePlanStudentAssignWebHook,
                filteredStudent,
                data,
                studentRecord.startDate,
                studentRecord.endDate,
                firebase,
                "Subscription_Removed"
              );
            }
          }
        }
      }

      yield call(FeeApi.deleteStudentFromFeePlan, { studentId: studentId }, feePlan, firebase);

      yield call(FeeApi.deleteStudentFromStudentFeePlan, studentId, feePlan.id, firebase);

      yield put({
        type: actions.DELETE_STUDENT_SELECTED_FEE_PLAN_SUCCESS,
      });
    } else {
      notification("error", formatMsg("error.missingFeePlan"));
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete student fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* verifyUserAccountCreation({ students, firebase }) {
  try {
    for (let index in students) {
      if (students[index].fatherNumber && students[index].fatherNumber !== 0) {
        let fatherUser = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          students[index].fatherNumber,
          firebase
        );
        console.log(students[index].name + " father number --->>", fatherUser);
      } else {
        console.log(students[index].name + " father number not added");
      }

      if (students[index].motherNumber && students[index].motherNumber !== 0) {
        let motherUser = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          students[index].motherNumber,
          firebase
        );
        console.log(students[index].name + " mother number --->>", motherUser);
      } else {
        console.log(students[index].name + " mother number not added");
      }
    }
  } catch (err) {
    console.log("failed to check user account creation", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentDocument({ studentId, firebase }) {
  try {
    let data = yield call(StudentApi.getUserDocument, studentId, firebase);

    if (data) {
      yield put({
        type: actions.GET_STUDENT_DOC_SUCCESS,
        studentDoc: data,
      });
    }
    // else {
    //   let handbooks = [];
    //   let config = firebase.schoolConfig;

    //   if (config && config.handbookUrl && studentId === "handbook") {
    //     handbooks.push({
    //       name: "Handbook",
    //       downloadUrl: config.handbookUrl,
    //       type: "doc",
    //     });
    //     yield put({
    //       type: actions.GET_STUDENT_DOC_SUCCESS,
    //       studentDoc: handbooks,
    //     });
    //   }
    // }
  } catch (err) {
    console.log("failed to get student document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* uploadStudentDocument({ fileList, mediaType, id, firebase, filePath }) {
  try {
    let storagePath;
    if (mediaType === "File") {
      storagePath = firebase.sbp + "/media/file/";
    } else {
      storagePath = firebase.sbp + "/media/images/";
    }
    let file = { fileList: fileList };
    let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, file, firebase);
    if (urls) {
      for (let i = 0; i < urls.length; i++) {
        let nodeId = yield call(StudentApi.getStudentDocNode, firebase);

        let obj = {
          filePath: filePath ? filePath : null,
          createdBy: firebase.teacher.name,
          createdOn: new Date().getTime(),
          downloadUrl: urls[i].path,
          id: nodeId,
          inverseDate: -new Date().getTime(),
          name: urls[i].fileName,
          nameWithExtension: urls[i].fileName,
          type: urls[i].type && urls[i].type === "file" ? "doc" : "image",
        };
        yield call(StudentApi.uploadDocument, id, obj, firebase);

        yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);

        if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
          let studentDetail = firebase.student;
          let studentId = studentDetail.id;
          let parentName =
            firebase.user && firebase.user.id === studentDetail.fatherProfileId
              ? studentDetail.fatherName
              : studentDetail.motherName;
          let teachers = yield call(
            lessonAssignmentApi.getTeachersByStudentClassList,
            studentDetail.classList ? studentDetail.classList : [studentDetail.classroomName],
            firebase
          );

          yield fork(
            NotificationApi.updateLog,
            "fileLogs",
            firebase.user.id,
            parentName,
            studentDetail.id,
            "ADD_FILE",
            firebase,
            nodeId
          );

          if (teachers && teachers.length > 0) {
            for (let index in teachers) {
              let teacher = teachers[index];
              let alertActivityName = "File Upload";
              let alertMessage = "New file uploaded by parent of " + studentDetail.name;
              let alertActivityType = "File Upload";
              let userType = "teacher";

              let permissionStatus = yield call(
                NotificationApi.receiveNotificationPermission,
                alertActivityName,
                alertActivityName,
                teacher,
                firebase
              );
              if (permissionStatus) {
                let sendNotification = false;
                if (teacher.groups) {
                  sendNotification = yield call(
                    NotificationApi.checkIfStudentInGroup,
                    teacher,
                    studentDetail
                  );
                } else {
                  sendNotification = true;
                }

                if (sendNotification) {
                  let alertNode = yield call(
                    NotificationApi.createAlertReferenceNode,
                    teacher.id,
                    firebase
                  );

                  yield fork(
                    NotificationApi.createSimpleAlertNotification,
                    alertActivityName,
                    nodeId,
                    teacher.uid ? teacher.uid : null,
                    alertMessage,
                    alertNode,
                    teacher.ios_uid ? teacher.ios_uid : null,
                    studentId,
                    teacher.id,
                    alertActivityType,
                    undefined,
                    firebase,
                    parentName,
                    userType
                  );

                  if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
                    yield fork(
                      NotificationApi.sendSimplePushNotification,
                      alertActivityName,
                      nodeId,
                      teacher.uid ? teacher.uid : null,
                      alertMessage,
                      alertNode,
                      teacher.ios_uid ? teacher.ios_uid : null,
                      studentId,
                      teacher.id,
                      alertActivityType,
                      undefined,
                      firebase,
                      parentName,
                      userType
                    );
                  }
                }
              }
            }
          }
        }
      }
      yield put({
        type: actions.UPLOAD_STUDENT_DOC_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload student document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* deleteStudentDocument({ record, id, firebase, filePath }) {
  try {
    yield call(StudentApi.deleteDocument, record, id, firebase, filePath);
    yield put({
      type: actions.DELETE_STUDENT_DOC_SUCCESS,
    });

    if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
      let studentDetail = firebase.student;
      let studentId = studentDetail.id;
      let parentName =
        firebase.user && firebase.user.id === studentDetail.fatherProfileId
          ? studentDetail.fatherName
          : studentDetail.motherName;

      yield fork(
        NotificationApi.updateLog,
        "fileLogs",
        firebase.user.id,
        parentName,
        studentId,
        "DELETE_FILE",
        firebase,
        record.id
      );
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* changeSelectedStudentStatus({ student, firebase }) {
  try {
    // yield call(
    //   StudentApi.updateStudentWithUpdatedFormFields,
    //   student,
    //   firebase
    // );

    let response = yield call(StudentApi.updateStudentApi, firebase, student.id, student);
    if (response && response.status === 200) {
      if (firebase.schoolConfig && firebase.schoolConfig.classBookingEnabled) {
        if (student.status && student.status.toLowerCase() !== "on hold") {
          yield call(NotificationApi.callStatusChangeWebHook, student, firebase, "CHANGE_STATUS");
        }

        if (student.status && student.status.toLowerCase() !== "active") {
          yield call(NotificationApi.callStatusChangeApi, student, firebase);
        }
      }
      yield put({
        type: actions.CHANGE_STUDENT_STATUS_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response
            ? response.body.response
            : "Failed to update status. Please contact school or Illumine.",
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to change student status", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchStudentPrograms({ firebase }) {
  try {
    let data = yield call(ProgramApi.fetchPrograms, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_PROGRAMS_SUCCESS,
        studentPrograms: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to fetch student programs", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* parentInviteSmsReminder({ students, reminderMode, firebase, forcedUpdate }) {
  try {
    let schoolName = firebase.sbDbName ? firebase.sbDbName : firebase.schoolName;
    let textMessage =
      "Welcome to " +
      schoolName +
      ". Click on http://bit.ly/myillumine (android) , https://apple.co/2DgqUhE (IOS) to download the illumine app. Login with your phone number";
    if (reminderMode === "email") {
      yield call(StudentApi.sendInviteToParents, students, firebase, forcedUpdate);
    } else if (reminderMode === "sms") {
      for (let index in students) {
        let p = students[index];
        if (p.motherName && p.motherNumber && p.motherNumber !== 0) {
          if (!p.motherUUid || !p.ios_motherUUid) {
            yield fork(NotificationApi.sendReminderMessage, [p.motherNumber], textMessage);
          }
        }

        if (p.fatherName && p.fatherNumber && p.fatherNumber !== 0) {
          if (!p.fatherUUid || !p.ios_fatherUUid) {
            yield fork(NotificationApi.sendReminderMessage, [p.fatherNumber], textMessage);
          }
        }
      }
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to send innvite sms reminder", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* fetchStudentOneTimeFeeComponent({ studentId, firebase }) {
  try {
    let feeComponents = [];
    let oneTimeRecord = yield call(FeeApi.getOneTimeCostRecord, studentId, firebase);

    if (oneTimeRecord && oneTimeRecord.length > 0) {
      for (let i in oneTimeRecord) {
        let val = yield call(FeeApi.getFeeComponentById, oneTimeRecord[i].feeComponentId, firebase);
        if (val && val.id) {
          feeComponents.push(val);
        }
      }
    }
    yield put({
      type: actions.GET_STUDENT_ONE_TIME_FEE_COMPONENT_SUCCESS,
      oneTimeComponent: feeComponents,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* addAuthorisedPickup({ value, selectedStudent, firebase, pickupImageInfo }) {
  try {
    let contact = value.phoneNumber ? Number(value.phoneNumber) : null;
    let parentEmail = value.email ? value.email : null;
    let genericMessage = "Failed to add authorized pickup";
    let student = selectedStudent[0];
    if (student && student.id) {
      let profileImage;
      if (pickupImageInfo) {
        let storagePath = firebase.sbp + "/media/profileimages/";
        let urls = yield call(ActivityApi.getMediaPath, storagePath, pickupImageInfo, firebase);
        if (urls) {
          profileImage = urls[0];
        }
      }

      let data = {};
      data.name = value.name;
      data.phoneNumner = value.phoneNumber ? value.phoneNumber : null;
      data.email = value.email ? value.email : null;
      data.realtion = value.relation;
      data.url = profileImage ? profileImage : null;
      data.countryCode = value.countryCode ? value.countryCode : null;

      let response = yield call(StudentApi.addAuthorizedPickupApi, firebase, data, student.id);

      if (response && response.status) {
        if (response.status === 200) {
          yield put({
            type: actions.FORCE_LOADER_START,
          });
          yield call(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
          yield delay(2000);
          yield put({
            type: actions.SAVE_AUTHORISED_PICKUP_SUCCESS,
          });
        } else if (response.status === 201 && response.body.parentId) {
          let parentId = response.body.parentId;

          if (parentId) {
            let linkResp = yield call(
              StudentApi.linkParentApi,
              firebase,
              parentId,
              student.id,
              "pickup",
              value.relation,
              profileImage
            );
            if (linkResp && linkResp.status === 200) {
              yield put({
                type: actions.FORCE_LOADER_START,
              });
              yield delay(2000);
              yield put({
                type: actions.SAVE_AUTHORISED_PICKUP_SUCCESS,
              });
            } else {
              yield put({
                type: actions.REQUEST_FAILED,
                errorMessage:
                  linkResp.body && linkResp.body.response ? linkResp.body.response : genericMessage,
              });
            }
          } else {
            yield put({
              type: actions.REQUEST_FAILED,
              errorMessage: genericMessage,
            });
          }
        } else {
          yield put({
            type: actions.REQUEST_FAILED,
            errorMessage:
              response.body && response.body.response ? response.body.response : genericMessage,
          });
        }
      } else {
        yield put({
          type: actions.REQUEST_FAILED,
          errorMessage:
            response.body && response.body.response ? response.body.response : genericMessage,
        });
      }
    } else {
      bugsnagClient.notify(new Error("student object not found to update authorised pickup"));
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to add authorised pickup", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
    bugsnagClient.notify("failed to add authorised pickup" + err.message ? err.message : err);
  }
}

function* updatedAuthorisedPickup({ value, index, selectedStudent, firebase, pickupImageInfo }) {
  try {
    let genericMessage = "Failed to update authorized pickup";
    let student = selectedStudent[0];
    if (student && student.id) {
      let pickup = [];
      if (student.authorizedPickups) {
        pickup = student.authorizedPickups;
      }

      let profileImage;
      if (pickupImageInfo) {
        let storagePath = firebase.sbp + "/media/profileimages/";
        let urls = yield call(ActivityApi.getMediaPath, storagePath, pickupImageInfo, firebase);
        if (urls) {
          profileImage = urls[0];
        }
      }

      let data = {};
      data.name = value.name;
      data.phoneNumner = value.phoneNumber ? value.phoneNumber : null;
      data.realtion = value.relation;
      data.countryCode = value.countryCode ? value.countryCode : null;
      data.email = value.email ? value.email : null;
      if (pickupImageInfo) {
        data.url = profileImage ? profileImage : null;
      } else {
        data.url = pickup[index].url ? pickup[index].url : null;
      }

      let response = yield call(
        StudentApi.updateAuthorizedPickupApi,
        firebase,
        pickup[index].id,
        data
      );
      if (response && response.status) {
        if (response.status === 200) {
          yield put({
            type: actions.FORCE_LOADER_START,
          });
          yield call(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
          yield delay(2000);
          yield put({
            type: actions.SAVE_AUTHORISED_PICKUP_SUCCESS,
          });
        } else {
          yield put({
            type: actions.REQUEST_FAILED,
            errorMessage:
              response.body && response.body.response ? response.body.response : genericMessage,
          });
        }
      } else {
        yield put({
          type: actions.REQUEST_FAILED,
          errorMessage:
            response.body && response.body.response ? response.body.response : genericMessage,
        });
      }
    } else {
      bugsnagClient.notify(new Error("student object not found to update authorised pickup"));
      yield put({
        type: actions.REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to update authorised pickup", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
    bugsnagClient.notify("failed to update authorised pickup" + err.message ? err.message : err);
  }
}

function* deleteAuthorisedPickup({ value, studentDetail, firebase }) {
  try {
    let genericMessage = "Failed to delete authorized pickup";
    let student = studentDetail[0];
    if (!value.id) {
      let pickup = [];
      if (student.authorizedPickups) {
        pickup = student.authorizedPickups;
      }

      pickup = pickup.filter((item) => {
        return (
          item.name.toLowerCase() !== value.name.toLowerCase() &&
          item.realtion.toLowerCase() !== value.realtion.toLowerCase()
        );
      });

      student.authorizedPickups = pickup;
      student.platform = "web";
      student.updatedBy = firebase.teacher && firebase.teacher.name ? firebase.teacher.name : null;
      student.updatedOn = new Date().getTime();
      yield call(StudentApi.updateStudentWithUpdatedFormFields, student, firebase);
      yield put({
        type: actions.DELETE_AUTHORISED_PICKUP_SUCCESS,
      });
    } else {
      let response = yield call(
        StudentApi.unlinkParentApi,
        firebase,
        value.id,
        student.id,
        "pickup"
      );

      if (response && response.status) {
        if (response.status === 200) {
          yield put({
            type: actions.FORCE_LOADER_START,
          });
          yield delay(2000);
          yield call(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);

          yield put({
            type: actions.DELETE_AUTHORISED_PICKUP_SUCCESS,
          });
        } else {
          yield put({
            type: actions.REQUEST_FAILED,
            errorMessage:
              response.body && response.body.response ? response.body.response : genericMessage,
          });
        }
      } else {
        yield put({
          type: actions.REQUEST_FAILED,
          errorMessage:
            response.body && response.body.response ? response.body.response : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to delete auth pickup", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* downloadTermConditionExcel({ students, firebase }) {
  try {
    const fields = [
      "name",
      "fatherName",
      "fatherNumber",
      "termsAcceptedByFather",
      "motherName",
      "motherNumber",
      "termsAcceptedByMother",
    ];
    // const opts = { fields };
    let report = [];
    for (let index in students) {
      var row = {};
      row.name = students[index].name;
      row.fatherName = students[index].fatherName ? students[index].fatherName : "";
      row.fatherNumber = students[index].fatherNumber ? students[index].fatherNumber : "";

      if (students[index].fatherNumber && students[index].fatherNumber !== 0) {
        let fatherUser = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          students[index].fatherNumber,
          firebase
        );
        if (fatherUser && fatherUser.accepted) {
          row.termsAcceptedByFather = "YES";
        } else {
          row.termsAcceptedByFather = "NO";
        }
      }

      row.motherName = students[index].motherName ? students[index].motherName : "";
      row.motherNumber = students[index].motherNumber ? students[index].motherNumber : "";
      if (students[index].motherNumber && students[index].motherNumber !== 0) {
        let motherUser = yield call(
          TeacherApi.checkIfUserExistInNewUser,
          students[index].motherNumber,
          firebase
        );
        if (motherUser && motherUser.accepted) {
          row.termsAcceptedByMother = "YES";
        } else {
          row.termsAcceptedByMother = "NO";
        }
      }

      report.push(row);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "Term&ConditionReport";

    var ws = XLSX.utils.json_to_sheet(report, { header: fields });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    yield put({
      type: actions.DOWNLOAD_TERMS_CONDITIONS_REPORT_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download terms condition excel", err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
    bugsnagClient.notify(err);
  }
}
function createObjectForStudentNotes(value, firebase, urls, record) {
  let obj = {

    description: value.additionalDescription
      ? value.additionalDescription
      : null,
    createdBy: firebase.teacher.name,
    teacherId: firebase.teacher.id,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    profileImage: firebase.teacher.profileImageUrl
      ? firebase.teacher.profileImageUrl
      : null,
    platform: "web",
    uploadedImage: urls ? urls[0] : null,
  };
  if (record && record.id) {
    obj["id"] = record.id;
    obj["uploadedImage"] =
      urls
        ? urls[0]
        : value.attachment
          ? value.attachment
          : null;
  }
  return obj;
}

function* updateStudentNote({ values, studentId, firebase, record, attachedImage, operation }) {
  try {

    if (values && values.attachment && !attachedImage) {
      var urls = yield call(ActivityApi.getMediaPath, undefined, values.attachment, firebase);
    }

    if (operation !== "delete") {

      let object = createObjectForStudentNotes(values, firebase, urls, record);

      let obj = {
        studentNotes: object,
        studentId: studentId

      };

      let url = "woodlandApi/saveStudentNotes/?centerId=";
      let response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );
      if (response && response.status === 200) {

        yield put({
          type: actions.ADD_STUDENT_NOTE_SUCCESS,
        });

      }
      else if (response && response.status === 400) {

        notification("error", response.body && response.body.message
          ? response.body.message
          : formatMsg("error.failedToUpdateNotes"));
        yield put({
          type: actions.REQUEST_FAILED,
        });
      }

      else {
        notification(
          "error",
          response.body && response.body.message ? response.body.message : formatMsg("error.failedToUpdateNotes"));
        yield put({
          type: actions.REQUEST_FAILED,
        });
      }

    } else if (record && operation === "delete") {
      let notesUrl = "woodlandApi/deleteNotes/" + record.id + "?centerId=";
      let obj = {
        id: record.id,
        studentId: studentId
      };
      let response = yield call(ApplicationApi.deleteApi, obj, notesUrl, firebase);

      if (response && response.status === 200) {

        yield put({
          type: actions.ADD_STUDENT_NOTE_SUCCESS,
        });

      }
      else {
        notification(
          "error",
          response.body && response.body.message
            ? response.body.message
            : formatMsg("error.failedToDeleteNotes")
        );
        yield put({
          type: actions.REQUEST_FAILED,
        });
      }

    }

  } catch (err) {
    yield put({
      type: actions.REQUEST_FAILED,
    });
    bugsnagClient.notify(err);
  }
}

function* fetchStudentNotes({ studentId, firebase }) {
  const chan = yield call(StudentApi.getStudentNotes, studentId, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      data.sort(function (a, b) {
        var dateA = a.updatedOn,
          dateB = b.updatedOn;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_STUDENT_NOTE_SUCCESS,
        studentNotes: data,
        studentNotesChan: chan,
      });

      yield put({
        type: actions.GET_STUDENT_NOTE_COUNT,
        studentNotesCount: data.length,
      });
    }
  } finally {
    console.log("terminating fetch student notes");
  }
}

function* addDocumentFolder({ folderName, filePath, firebase, id, privateFolderRecord }) {
  try {
    let nodeId = yield call(StudentApi.createNewNoteId, firebase);
    yield call(StudentApi.addDocumentFolder, nodeId, id, folderName, filePath, firebase, privateFolderRecord);
    yield put({
      type: actions.ADD_DOC_FOLDER_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add document folder", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* renamedDocStudent({ docName, record, firebase, id, documents, currentFilePath }) {
  try {
    yield call(StudentApi.renameDoc, docName, record.id, firebase, id, currentFilePath);
    let allFiles = documents;
    let oldPath = record.name + "/";
    let newPath = docName + "/";
    if (record.folder) {
      for (let index in allFiles) {
        if (allFiles[index].id !== record.id) {
          if (allFiles[index].filePath) {
            let filePath = allFiles[index].filePath;
            if (currentFilePath) {
              let splittedPath = filePath.split(currentFilePath);
              if (splittedPath[1]) {
                filePath = splittedPath[1].toString();
              }
              else {
                filePath = ""
              }
            }
            if (filePath.startsWith(oldPath)) {
              allFiles[index].filePath =
                (currentFilePath ? currentFilePath : "") + filePath.replace(oldPath, newPath);
              yield call(StudentApi.renameDoc, allFiles[index].name, allFiles[index].id, firebase, id, allFiles[index].filePath);
            }
          }
        }
      }
    }

    yield put({
      type: actions.RENAME_STUDENT_DOC_FOLDER_SUCCESS,
    });

    if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
      let studentDetail = firebase.student;
      let studentId = studentDetail.id;
      let parentName =
        firebase.user && firebase.user.id === studentDetail.fatherProfileId
          ? studentDetail.fatherName
          : studentDetail.motherName;

      yield fork(
        NotificationApi.updateLog,
        "fileLogs",
        firebase.user.id,
        parentName,
        studentId,
        "EDIT_FILE",
        firebase,
        record.id
      );
    }
  } catch (err) {
    console.log("failed to rename student document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* deleteSelectedParent({ firebase, relation, student }) {
  try {
    let genericMessage = "Failed to delete parent";
    let parentId;
    if (relation.toLowerCase() === "mother") {
      parentId = student.motherProfileId;
    } else if (relation.toLowerCase() === "father") {
      parentId = student.fatherProfileId;
    }

    let response = yield call(StudentApi.deleteParentApi, firebase, parentId);

    if (response && response.status === 200) {
      yield put({
        type: actions.FORCE_LOADER_START,
      });
      yield delay(2000);
      yield put({
        type: actions.DELETE_PARENT_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to delete parent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* unlinkSelectedParent({ firebase, relation, student }) {
  try {
    let genericMessage = "Failed to unlink parent";
    let parentId;
    let parentType;

    if (relation.toLowerCase() === "mother") {
      parentId = student.motherProfileId;
      parentType = "parent1";
    } else if (relation.toLowerCase() === "father") {
      parentId = student.fatherProfileId;
      parentType = "parent2";
    }

    let response = yield call(
      StudentApi.unlinkParentApi,
      firebase,
      parentId,
      student.id,
      parentType
    );
    if (response && response.status === 200) {
      yield put({
        type: actions.FORCE_LOADER_START,
      });

      yield delay(2000);
      yield put({
        type: actions.UNLINK_PARENT_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to unlink parent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* updateEmailInvite({ updatedEmail, firebase }) {
  try {
    if (updatedEmail) {
      const rsf = firebase.secondaryDb;
      let branchPath = firebase.sbp;

      const myRef = rsf.ref(branchPath + "/emails/invitationEmail");
      myRef.update({
        updatedEmail: updatedEmail,
      });
      yield put({
        type: actions.UPDATE_EMAIL_INVITE_SUCCESS,
      });
      notification("success", formatMsg("success.updateEmail"));
    } else {
      let checkDefault = yield call(StudentApi.getEmailInvite, firebase);

      if (checkDefault && checkDefault.updatedEmail) {
        yield put({
          type: actions.UPDATE_EMAIL_INVITE_SUCCESS,
          updatedEmail: checkDefault.updatedEmail,
          defaultEmail: UserFilterAction.installationInviteHtml(),
        });
      } else {
        yield put({
          type: actions.UPDATE_EMAIL_INVITE_SUCCESS,
          updatedEmail: UserFilterAction.installationInviteHtml(),
          defaultEmail: UserFilterAction.installationInviteHtml(),
        });
      }
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* linkSelectedParent({ firebase, parentId, student, relation }) {
  try {
    let genericMessage = "Failed to link parent";

    let response = yield call(StudentApi.linkParentApi, firebase, parentId, student.id, relation);

    if (response && response.status === 200) {
      yield put({
        type: actions.FORCE_LOADER_START,
      });

      yield delay(2000);
      yield put({
        type: actions.LINK_PARENT_SUCCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to unlink parent", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* showStudentLogs({ studentId, firebase }) {
  try {
    let data = yield call(StudentApi.FetchStudentLogs, studentId, firebase);
    data.sort(function (a, b) {
      var dateA = a.date,
        dateB = b.date;
      return dateB - dateA;
    });

    if (data) {
      yield put({
        type: actions.FETCH_STUDENT_LOG_SUCESS,
        changeStudentlogs: data,
      });
    }
  } catch (error) {
    console.log("logs", error);
  }
}
function* showParentLogs({ parentId, firebase }) {
  try {
    let data = yield call(StudentApi.FetchParentLogs, parentId, firebase);
    data.sort(function (a, b) {
      var dateA = a.date,
        dateB = b.date;
      return dateB - dateA;
    });

    if (data) {
      yield put({
        type: actions.FETCH_PARENT_LOG_SUCESS,
        changeParentLogs: data,
      });
    }
  } catch (error) {
    console.log("logs", error);
  }
}

function* getInviteLogs({ firebase }) {
  try {
    let data = yield call(StudentApi.getInvitationLog, firebase);

    data.sort(function (a, b) {
      var dateA = a.date,
        dateB = b.date;
      return dateB - dateA;
    });

    if (data) {
      yield put({
        type: actions.FETCH_INVITE_LOG_SUCCESS,
        inviteLogs: data,
      });
      yield fork(getDeliveryReport, data, firebase);
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* getDeliveryReport(data, firebase) {
  try {
    let taskList = [];
    var deliveryObj = {};

    for (let i in data) {
      if (data[i].mailResult) {
        let task = call(UserSettingApi.getMailDeliveryReport, data[i].mailResult, firebase);

        taskList.push(task);
      }
    }

    let newVal = yield all([taskList]);

    for (let i in newVal[0]) {
      let val = newVal[0][i];
      if (val.id) {
        deliveryObj[val.id] = val;
      }
    }

    yield put({
      type: actions.FETCH_INVITE_DEL_STATUS_SUCCESS,
      deliveryStatus: deliveryObj,
    });
  } catch (err) {
    console.log("error in fetching delivery report ", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* getDeliveryStatus({ id, firebase }) {
  try {
    let data = yield call(UserSettingApi.getMailDeliveryReport, id, firebase);
    let statusObj = { [id]: data };
    yield put({
      type: actions.FETCH_INVITE_DEL_STATUS_SUCCESS,
      deliveryStatus: statusObj,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* getDocLogs({ id, firebase }) {
  try {
    let data = yield call(StudentApi.getDocLog, id, firebase);

    data.sort(function (a, b) {
      var dateA = a.updatedOn,
        dateB = b.updatedOn;
      return dateB - dateA;
    });
    if (data) {
      yield put({
        type: actions.FETCH_DOC_LOG_SUCCESS,
        docLogs: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch doc logs");
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* getStudentsSaga({ firebase }) {
  const chan = yield call(StudentApi.getStudents, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_STUDENTS_SUCCESS,
        students: data,
        studentsChan: chan,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log(err);
  }
}
function* addStudentInNewBranch({ values, existingStudent, firebase }) {
  let genericMessage = "Failed to transfer student. Please contact school or Illumine";
  try {
    let teacherBranch = firebase.teacher.newBranches ? firebase.teacher.newBranches : [];
    let newStudentBranchPath = values.studentBranchPath ? values.studentBranchPath : undefined;
    var schoolDbName;
    if (newStudentBranchPath) {
      let filteredBranch = teacherBranch.filter((b) => {
        return b.name === newStudentBranchPath;
      });

      if (filteredBranch && filteredBranch.length > 0) {
        schoolDbName = filteredBranch[0].dbName;
      }
    }

    var obj = {
      id: existingStudent.id,
      destinationCenterId: schoolDbName,
      status: values.status,
      classList: values.classroom,
      updatedBy: firebase.teacher.name,
    };

    let url = "woodlandApi/student/transfer/" + obj.id + "?centerId=";
    console.log(url, "urlss");
    let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

    console.log("response", response);
    if (response && response.status === 200) {
      yield put({
        type: actions.ADD_STUDENT_IN_NEW_BRANCH_SUCESS,
      });
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* getStudentInvoiceTemplates({ firebase }) {
  try {
    let data = yield call(InvoiceTemplateApi.getInvoiceTemplatesPromise, firebase);
    if (data) {
      yield put({
        type: actions.GET_INVOICETEMPLATE_SUCCESFUL,
        invoiceTemplateList: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch student invoice templates", err);
    bugsnagClient.notify(err);
  }
}

function* updateStudentHealthAndEmergencyInfo({ values, student, firebase }) {
  try {
    let genericMessage = "Failed to update information. Please contact school or Illumine";
    let obj = {
      bloodGroup: values.bloodGroup ? values.bloodGroup : null,
      allergies: values.allergies ? values.allergies : null,
      medication: values.medicine ? values.medicine : "",
      emergencyContactName: values.emergencyContactName ? values.emergencyContactName : null,
      emergencyNumber: values.emergencyNumber ? values.emergencyNumber : null,
      id: student.id,
      updatedBy: firebase.teacher ? firebase.teacher.name : FilterAction.getParentRelation(firebase.user.id, firebase),
      updatedOn: moment().valueOf(),
    };
    let response = yield call(StudentApi.updateStudentApi, firebase, student.id, obj);
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      notification("success", formatMsg("student.detailUpdatedSuccessfully"));
      yield put({
        type: actions.UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO_SUCCESS,
      });
      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, student, firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update health and Emergency info", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* removeProfileImage({ parentType, student, firebase }) {
  try {
    let genericMessage = "Failed to update information. Please contact school or Illumine";
    let response;
    let obj = {
      url: null,
      platform: "web",
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    };
    if (parentType.toLowerCase() == "father") {
      obj["fatherImage"] = null;
      obj["parentType"] = "parent2";
      obj["id"] = student.fatherProfileId;
      response = yield call(
        StudentApi.updateParentPicApi,
        firebase,
        student.fatherProfileId,
        obj
      );
    }
    else if (parentType.toLowerCase() == "mother") {
      obj["motherImage"] = null;
      obj["parentType"] = "parent1";
      obj["id"] = student.motherProfileId;
      response = yield call(
        StudentApi.updateParentPicApi,
        firebase,
        student.motherProfileId,
        obj
      );
    }
    else if (parentType.toLowerCase() == "student") {
      obj["profileImageUrl"] = null;
      obj["id"] = student.id;
      response = yield call(StudentApi.updateStudentApi, firebase, student.id, obj);
    }
    else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage: genericMessage,
      });
    }
    if (response && response.status === 200) {
      yield delay(2000);
      yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
      yield put({
        type: actions.REMOVE_PROFILE_IMAGE_SUCCESS,
      });
      if (firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
        yield fork(sendProfileUpdateNotification, student, firebase);
      }
    } else {
      yield put({
        type: actions.REQUEST_FAILED,
        errorMessage:
          response.body && response.body.response ? response.body.response : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to update health and Emergency info", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* refreshPage({ }) {
  yield put({
    type: actions.REFRESH_PAGE_SUCCESS,
  });
}

function* doOperation(firebase, operationType, studentList, returnObject) {
  studentList = studentList.map(({ platform, updatedBy, updatedOn, ...rest }) => rest);
  if (operationType == "update groups") {
    let removableTagsMap = returnObject.removableTagsMap;
    let newTagsMap = returnObject.newTagsMap;
    for (const [grpId, deletedStudentIds] of removableTagsMap) {

      let grp = yield call(TagApi.getTagById, grpId, firebase);
      if (grp && grp.studentIds) {
        let studentIds = grp.studentIds;
        studentIds = studentIds.filter((stdId) => {
          return !deletedStudentIds.includes(stdId)
        });
        grp.studentIds = studentIds;
        yield call(TagApi.updateTagWithStudents, grp, firebase);
      }

    }
    for (const [grpId, newStudentIds] of newTagsMap) {
      let grp = yield call(TagApi.getTagById, grpId, firebase);
      if (grp && grp.studentIds) {
        let studentIds = grp.studentIds;
        newStudentIds.map((id) => {
          if (!studentIds.includes(id)) {
            studentIds.push(id);
          }
        })
        grp.studentIds = studentIds;
        yield call(TagApi.updateTagWithStudents, grp, firebase);
      }

    }
    yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
    let previousStudentDataMap = returnObject.previousStudentDataMap ? returnObject.previousStudentDataMap : new Map();
    for (const student of studentList) {
      if (previousStudentDataMap.has(student.id)) {
        let record = previousStudentDataMap.get(student.id);
        yield call(
          NotificationApi.callProfileUpdateWebHook,
          record,
          firebase,
          "PROFILE_UPDATE",
          student
        );
      }
    }
  }
  else if (operationType == "update classrooms") {
    yield fork(StudentAttendanceApi.lastUpdateTimestamp, new Date(), firebase);
    for (const student of studentList) {
      let previousStudentDataMap = returnObject.previousStudentDataMap ? returnObject.previousStudentDataMap : new Map();
      if (previousStudentDataMap.has(student.id)) {
        let record = previousStudentDataMap.get(student.id);
        yield call(
          NotificationApi.callProfileUpdateWebHook,
          record,
          firebase,
          "PROFILE_UPDATE",
          student
        );
      }
    }
  }

}

function getStudentModifiedList(firebase, studentIdsList, operationType, value, returnObject) {
  let studentMap = firebase.studentsMap;
  let result = [];
  let previousStudentDataMap = new Map();
  switch (operationType) {
    case "update classrooms":
      studentIdsList.map((id) => {
        if (studentMap.has(id)) {
          let student = studentMap.get(id);
          student.classList = value;
          student.classroomName = value[0];
          student.platform = "web";
          student.updatedOn = moment().valueOf();
          student.updatedBy = firebase.teacher.name;
          result.push(student);
          previousStudentDataMap.set(id, student);
        }
      })
      break;
    case "update status":
      studentIdsList.map((id) => {
        if (studentMap.has(id)) {
          let student = studentMap.get(id);
          let key = value;
          student.status = key;
          student.platform = "web";
          student.updatedOn = moment().valueOf();
          student.updatedBy = firebase.teacher.name;
          student.deactivated = false;
          if (key.toLowerCase() === "inactive" || key.toLowerCase() === "withdrawn") {
            student.deactivated = true;
            student.deactivationDate = moment().valueOf();
          }
          result.push(student);
        }
      })
      break;
    case "update groups":
      let removableTagsMap = new Map();
      let newTagsMap = new Map();
      let groups = JSON.parse(getItem("groupList"));
      let groupMap = new Map();
      groups.map((grp) => {
        groupMap.set(grp.id, grp)
      })
      studentIdsList.map((stuId) => {
        if (studentMap.has(stuId)) {
          let student = studentMap.get(stuId);
          let removableTags = student.tags ? student.tags : [];
          removableTags.map((tag) => {
            let arr = removableTagsMap.has(tag.id) ? removableTagsMap.get(tag.id) : [];
            arr.push(stuId);
            removableTagsMap.set(tag.id, arr);
          })
          let newTags = [];
          if (value) {
            value.map((id) => {
              if (groupMap.has(id)) {
                let grp = groupMap.get(id);
                if (!grp.studentIds) {
                  grp.studentIds = [];
                }
                if (!grp.studentIds.includes(stuId)) {
                  grp.studentIds.push(stuId);
                  grp.updatedBy = firebase.teacher.name;
                  grp.updatedOn = moment().valueOf();
                  let arr = newTagsMap.has(id) ? newTagsMap.get(id) : [];
                  arr.push(stuId);
                  newTagsMap.set(id, arr);
                }
                newTags.push(grp);
              }
            })
          }
          student.tags = newTags && newTags.length > 0 ? newTags : null;
          student.platform = "web";
          student.updatedOn = moment().valueOf();
          student.updatedBy = firebase.teacher.name;
          result.push(student);
          previousStudentDataMap.set(stuId, student);
        }
      })
      returnObject.removableTagsMap = removableTagsMap;
      returnObject.newTagsMap = newTagsMap;
      break;
  }
  returnObject.previousStudentDataMap = previousStudentDataMap;
  return result;
}

function* bulkStudentOperation({ firebase, studentIdsList, operationType, value, extraObj }) {

  try {
    let studentList = [];
    let url = "";
    let response;
    let returnObject = {};
    if (operationType == "delete students") {
      url = "woodlandApi/students?centerId=" + firebase.sbDbName;
      response = yield callApi(firebase, "delete", url, studentIdsList);
    }
    else if (operationType == "create folder") {
      let privateFolderRecord = {};
      if (extraObj && extraObj.private) {
        privateFolderRecord = { ...extraObj };
      }
      url = "woodlandApi/createFolderForStudents?centerId=" + firebase.sbDbName;
      response = yield callApi(firebase, "post", url, { list: studentIdsList, value, privateFolderRecord });
    }
    else {
      studentList = getStudentModifiedList(firebase, studentIdsList, operationType, value, returnObject);
      url = "woodlandApi/studentList?centerId="
      response = yield call(StudentAttendanceApi.requestApi, studentList, url, firebase);
    }
    if (response && response.status && response.status === 200) {
      if (operationType != "create folder" && operationType != "delete students") {
        yield call(doOperation, firebase, operationType, studentList, returnObject)
      }
      if (operationType === "create folder") {
        notification(
          "success",
          formatMsg("notif.folderCreatedSuccessfully")
        );
      }
      else if (operationType != "delete students") {
        notification(
          "success",
          formatMsg("success.updateStudents")
        );
      }
      else {
        notification(
          "success",
          formatMsg("success.studentsDeletedSucessfully")
        );
      }
      yield put({
        type: actions.BULK_STUDENT_OPERATION_SUCCESS,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateBulkStudents")
      );
      yield put({
        type: actions.BULK_STUDENT_OPERATION_SUCCESS,
      });
    }
  }
  catch (err) {
    console.log("failed to update students", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

function* deleteGalleryMedia({ studentId, firebase, media }) {
  try {
    yield call(StudentApi.deleteGalleryMediaApi, studentId, firebase, media);
    yield put({
      type: actions.DELETE_GALLERY_MEDIA_SUCCESS,
    });
    yield fork(fetchSelectedStudentMedia, { studentId: studentId, firebase: firebase });
  }
  catch (err) {
    console.log("Error While Deleting Media: ", err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_STUDENT, listStudents),
    yield takeEvery(actions.GET_PARENT_DETAIL_OF_SELECTED_STUDENT, fetchParentDetail),
    yield takeEvery(actions.GET_STUDENT_CLASSROOM_DATA, getClassroms),
    yield takeEvery(actions.GET_TAGS, fetchTags),
    yield takeEvery(actions.SUBMIT_STUDENT, submitStudentData),
    yield takeEvery(actions.INVITE_PARENT, submitParentData),
    yield takeEvery(actions.UPDATE_STUDENT, updateStudentData),
    yield takeEvery(actions.DELETE_STUDENT, deleteStudentData),
    yield takeEvery(actions.FETCH_STUDENT_DETAILS, fetchSelectedStudentDetail),
    yield takeEvery(actions.GET_STUDENT_MEDIA, fetchSelectedStudentMedia),
    yield takeEvery(actions.GET_STUDENT_FAVT_MEDIA, fetchSelectedStudentFavtMedia),
    yield takeEvery(actions.GET_USER_VERSION, fetchUserVersion),
    yield takeEvery(actions.SEND_INVITE, sentInviteToParents),
    yield takeEvery(actions.GET_SELECTED_STUDENT_ATTENDANCE, fetchSelectedStudentAttendance),
    yield takeEvery(actions.UPDATE_STUDENT_MEDICATION, updateStudentMedications),
    yield takeEvery(actions.UPDATE_STUDENT_ALLERGY, updateStudentAllergies),
    yield takeEvery(actions.UPDATE_STUDENT_COMMUNICATION_EMAIL, updateStudentCommunicationEmail),
    yield takeEvery(actions.UPDATE_BLOOD_GROUP, updateStudentBloodGroup),
    yield takeEvery(actions.UPDATE_ADMISSION_NUMBER, updateStudentAdmissionNumber),
    yield takeEvery(actions.UPDATE_STUDENT_ACTIVE_STATUS, updateStudentStatus),
    yield takeEvery(actions.SAVE_PARENT_EXTRA_DETAILS, updateParentAdditionalDetails),
    yield takeEvery(actions.REFRESH_FORM_FIELDS, updateStudentFormFields),
    yield takeEvery(actions.REFRESH_CHILD_FORM_FIELDS, updateChildFormFields),
    yield takeEvery(actions.SAVE_PARENT_IF_SIBLING, updateParentDetailIfSibling),
    yield takeEvery(actions.SAVE_PARENT_IF_TEACHER, updateParentDetailIfTeacher),
    yield takeEvery(actions.ADD_SIBLING, updateSiblingToStudent),
    yield takeEvery(actions.STUDENT_CLASS_CHANGE, updateStudentClassChange),
    yield takeEvery(actions.UPLOAD_PROFILE, updateStudentDisplayPic),
    yield takeEvery(actions.DOWNLOAD_STUDENT_EXCEL_SHEET, createAndDownloadExcelSheet),
    yield takeEvery(
      actions.DOWNLOAD_STUDENT_ALLERGY_EXCEL_SHEET,
      createAndDownloadAllergyExcelSheet
    ),
    yield takeEvery(actions.GET_STUDENT_FEE_PLAN, fetchStudentFeePlan),
    yield takeEvery(actions.VERIFY_USER_ACCOUNTS_CREATION, verifyUserAccountCreation),
    yield takeEvery(actions.GET_STUDENT_DOC, fetchStudentDocument),
    yield takeEvery(actions.UPLOAD_STUDENT_DOC, uploadStudentDocument),
    yield takeEvery(actions.DELETE_STUDENT_DOC, deleteStudentDocument),
    yield takeEvery(actions.CHANGE_STUDENT_STATUS, changeSelectedStudentStatus),
    yield takeEvery(actions.GET_STUDENT_PROGRAMS, fetchStudentPrograms),
    yield takeEvery(actions.SEND_INVITE_SMS_REMINDER, parentInviteSmsReminder),
    yield takeEvery(actions.GET_STUDENT_ONE_TIME_FEE_COMPONENT, fetchStudentOneTimeFeeComponent),
    yield takeEvery(actions.SAVE_AUTHORISED_PICKUP, addAuthorisedPickup),
    yield takeEvery(actions.EDIT_AUTHORISED_PICKUP, updatedAuthorisedPickup),
    yield takeEvery(actions.DELETE_AUTHORISED_PICKUP, deleteAuthorisedPickup),
    yield takeEvery(actions.DOWNLOAD_TERMS_CONDITIONS_REPORT, downloadTermConditionExcel),
    yield takeEvery(actions.UPDATE_TRIAL_COUNT, updateStudentTrialCount),
    yield takeEvery(actions.ADD_STUDENT_NOTE, updateStudentNote),
    yield takeEvery(actions.GET_STUDENT_NOTE, fetchStudentNotes),
    yield takeEvery(actions.DELETE_STUDENT_SELECTED_FEE_PLAN, deleteFeePlan),
    yield takeEvery(actions.ADD_DOC_FOLDER, addDocumentFolder),
    yield takeEvery(actions.RENAME_STUDENT_DOC_FOLDER, renamedDocStudent),
    yield takeEvery(actions.DELETE_PARENT, deleteSelectedParent),
    yield takeEvery(actions.UNLINK_PARENT, unlinkSelectedParent),
    yield takeEvery(actions.LINK_PARENT, linkSelectedParent),
    yield takeEvery(actions.UPDATE_EMAIL_INVITE, updateEmailInvite),
    yield takeEvery(actions.UPLOAD_PARENT_PROFILE, uploadParentPic),
    yield takeEvery(actions.DELETE_GALLERY_MEDIA, deleteGalleryMedia),

    yield takeLatest(actions.FETCH_STUDENT_LOG, showStudentLogs),
    yield takeLatest(actions.FETCH_PARENT_LOG, showParentLogs),
    // yield takeEvery(actions.UPDATE_EMAIL_INVITE, updateEmailInvite),
    yield takeEvery(actions.FETCH_INVITE_LOG, getInviteLogs),
    yield takeLatest(actions.FETCH_INVITE_DEL_STATUS, getDeliveryStatus),
    yield takeLatest(actions.FETCH_DOC_LOG, getDocLogs),
    yield takeLatest(actions.ADD_STUDENT_IN_NEW_BRANCH, addStudentInNewBranch),
    yield takeLatest(actions.GET_STUDENTS, getStudentsSaga),
    yield takeLatest(actions.GET_INVOICETEMPLATE, getStudentInvoiceTemplates),
    yield takeLatest(actions.UPDATE_SUDENT_HEALTH_AND_EMERGENCY_INFO, updateStudentHealthAndEmergencyInfo),
    yield takeLatest(actions.REMOVE_PROFILE_IMAGE, removeProfileImage),
    yield takeLatest(actions.REFRESH_PAGE, refreshPage),
    yield takeLatest(actions.BULK_STUDENT_OPERATION, bulkStudentOperation),
    yield takeLatest(actions.REFRESH_PAGE, refreshPage)

  ]);
}
