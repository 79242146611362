import actions from "./actions";
const initState = {
  reports: [],
  reportChan: undefined,
  isLoading: false,
};

export default function reportReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.REQUEST_REPORT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.REQUEST_REPORT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        reports: action.reports,
        reportChan: action.reportChan,
      };

    default:
      return state;
  }
}
