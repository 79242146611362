import actions from "./actions";
const initState = {
  todaysPost: [],
  classrooms: [],
  taggedStudent: [],
  isLoading: false,
  staffActivityLoading: false,
  operationType: undefined,
  error: false,
  todaysPostChannel: undefined,
  seenCount: [],
  likesCount: [],
  meetingReminder: [],
  meetingReminderChan: undefined,
  activityComments: new Map(),
  deleteOperationType: undefined,
  activityMap: new Map(),
  studentList: [],
  individualStudentActivities: [],
  trialStudentMap: new Map(),
  bookingActivities: new Map(),
  meetingReport: [],
  meetingTime: [],
  meetingActivityTime: [],
  meetingActivityReport: [],
  customLabelsVal: [],
  customLabelsChan: undefined,
  studentLevelActivityList: [],
  activityLabels: [],
  activityListLoading: false,
  staffPosts: [],
  staffPostChan: undefined,
  limit: 10,
  lastId: undefined,
  loadFirst: true,
  showLoder: false,
  hasLast: false,
  approvalPending: false,
  assessmentActivityChannel: undefined,
  assessmentActivities: []
};
export default function landingPageReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_TODAYS_POST_FOR_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activityListLoading: true,
        operationType: undefined,
      };
    case actions.GETPOST:
      return {
        ...state,
      };
    case actions.HASLAST:
      return {
        ...state,
        hasLast: false,
      };
    case actions.GET_TODAYS_POST_FOR_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        todaysPost: action.todaysPost,
        isLoading: false,
        activityListLoading: false,
        operationType: action.operationType ? action.operationType : state.operationType,
        todaysPostChannel: action.todaysPostChannel,
        lastId: action.lastId ? action.lastId : null,
        loadFirst: action.loadFirst != undefined ? action.loadFirst : null,
        hasLast: action.hasLast != undefined ? action.hasLast : false,
      };

    case actions.GET_STAFF_POSTS:
      return {
        ...state,
        staffActivityLoading: true,
        operationType: undefined,
      };
    case actions.GET_STAFF_POSTS_SUCCESS:
      return {
        ...state,
        staffPosts: action.staffPosts,
        staffActivityLoading: false,
        staffPostChan: action.staffPostChan,
      };

    case actions.GET_ACTIVITY_MAP:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_ACTIVITY_MAP_SUCCESS:
      return {
        ...state,
        activityMap: action.activityMap,
        isLoading: false,
        operationType: action.operationType,
        todaysPostChannel: action.todaysPostChannel,
      };

    case actions.GET_NOTIFICATION_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.GET_ACTIVITIES_BY_CLASSROOM:
      return {
        ...state,
        isLoading: true,
        activityListLoading: true,
        operationType: undefined,
        showLoder: true,
      };

    case actions.GET_CLASSROOM_FOR_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activityListLoading: false,
      };
    case actions.GET_CLASSROOM_FOR_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        isLoading: false,
      };

    case actions.GET_TAGGED_STUDENT_DETAIL:
      return {
        ...state,
        taggedStudent: [],
        // isLoading: true
      };
    case actions.GET_TAGGED_STUDENT_DETAIL_SUCCESSFUL:
      return {
        ...state,
        taggedStudent: action.taggedStudent,
        // isLoading: false
      };

    case actions.APPROVE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.APPROVE_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isLoading: false,
      };

    case actions.UPDATE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isLoading: false,
      };

    case actions.DELETE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        deleteOperationType: "REFRESH_ACTIVITY",
        isLoading: false,
      };

    case actions.ACTIVITY_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.RESET_ACTIVITY_OPERATION:
      return {
        ...state,
        isLoading: false,
        activityListLoading: false,
        operationType: undefined,
        deleteOperationType: undefined,
        assessmentActivities: []
      };

    case actions.GET_SEEN_COUNT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        seenCount: action.seenCount,
      };

    case actions.GET_LIKES_COUNT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        likesCount: action.likesCount,
      };

    case actions.GET_MEETING_REMINDER:
      return {
        ...state,
      };
    case actions.GET_MEETING_REMINDER_SUCCESSFUL:
      return {
        ...state,
        meetingReminder: action.meetingReminder,
        meetingReminderChan: action.meetingReminderChan,
      };

    case actions.STOP_CLASS_REPETITION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.STOP_CLASS_REPETITION_SUCCESS:
      return {
        ...state,
        operationType: "STOP_REPEAT",
        isLoading: false,
      };

    case actions.GET_ACTIVITY_COMMENT_SUCCESSFUL:
      return {
        ...state,
        activityComments: action.activityComments,
      };

    case actions.GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW:
      return {
        ...state,
      };
    case actions.GET_STUDENTS_LIST_FOR_ACTIVITY_LEVEL_VIEW_SUCCESS:
      return {
        ...state,
        studentList: action.studentList,
      };

    case actions.SET_IND_STUDENT_ACTIVITIES:
      return {
        ...state,
      };
    case actions.SET_IND_STUDENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        individualStudentActivities: action.individualStudentActivities,
      };

    case actions.GET_TRIAL_STUDENT_COUNT:
      return {
        ...state,
        trialStudentMap: action.trialStudentMap,
      };

    case actions.REMOVE_STUDENT_FROM_V_CLASS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.REMOVE_STUDENT_FROM_V_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.GET_BOOKING_ACTIVITY:
      return {
        ...state,
        // isLoading: true
      };
    case actions.GET_BOOKING_ACTIVITY_SUCCESS:
      return {
        ...state,
        // isLoading: false
        bookingActivities: action.bookingActivities,
      };

    case actions.ADD_NEW_STUDENT_TO_MEETING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_NEW_STUDENT_TO_MEETING_SUCCESS:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isLoading: false,
      };

    case actions.REMOVE_TRIAL_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.REMOVE_TRIAL_STUDENT_SUCCESS:
      return {
        ...state,
        operationType: "REFRESH_ACTIVITY",
        isLoading: false,
      };

    case actions.BOOKING_NOT_FOUND:
      return {
        ...state,
        operationType: "BOOKING_NOT_FOUND",
        isLoading: false,
      };

    case actions.GET_MEETING_REPORT:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_MEETING_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        meetingReport: action.meetingReport,
        meetingTime: action.meetingTime,
      };

    case actions.GET_MEETING_ACTIVITY:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_MEETING_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        meetingActivityTime: action.meetingActivityTime,
        meetingActivityReport: action.meetingActivityReport,
      };

    case actions.UPDATE_CAPACITY:
      return {
        ...state,
        isLoading: true,
      };

    case actions.UPDATE_CAPACITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "REFRESH_ACTIVITY",
      };

    case actions.GET_CUSTOM_LABELS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_CUSTOM_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customLabelsVal: action.customLabelsVal,
        customLabelsChan: action.customLabelsChan,
      };

    case actions.DELETE_CUSTOM_LABELS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_CUSTOM_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_CUSTOM_LABEL",
      };

    case actions.EDIT_CUSTOM_LABELS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.EDIT_CUSTOM_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "EDIT_CUSTOM_LABEL",
      };

    case actions.ADD_CUSTOM_LABEL_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_CUSTOM_LABEL_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_CUSTOM_LABEL_CATEGORY",
      };

    case actions.UPDATE_LAST_STUDENT_LEVEL_ACTIVITY_SUCCESS:
      return {
        ...state,
        studentLevelActivityList: action.studentLevelActivityList,
      };

    case actions.GET_ACTIVITY_LABEL:
      return {
        ...state,
      };

    case actions.GET_ACTIVITY_LABEL_SUCCESS:
      return {
        ...state,
        activityLabels: action.activityLabels,
      };

    case actions.APPROVE_ALL_ACTIVITY:
      return {
        ...state,
        activityListLoading: true,
        approvalPending: true,
      };

    case actions.APPROVE_ALL_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityListLoading: false,
        approvalPending: false,
        operationType: "ALL_APPROVED",
      };
    case actions.GET_ASSESSMENT_ACTIVITIES:
      return {
        ...state,
        activityListLoading: true,
      };
    case actions.GET_ASSESSMENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityListLoading: false,
        assessmentActivities: action.assessmentActivities,
        operationType: "ASSESSMENT_ACTIVITY_FETCHED"
      }
    case actions.GET_ASSESSMENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        activityListLoading: false,
      }
    default:
      return state;
  }
}
