import { UserSettingApi } from "../firestore-api/userSetting";
import FilterAction from "./FilterAction";
const superagent = require("superagent");
async function callApi(firebase, method, callUrl, requestPayload) {
  var endpoint = firebase.endPointUrl;
  let promise;
  requestPayload = FilterAction.getSuperagentBody(requestPayload, firebase);
  let authToken = await UserSettingApi.getAuthToken(firebase);
  switch (method.toLowerCase()) {
    case "post":
      promise = new Promise(function (resolve, reject) {
        superagent
          .post(endpoint + callUrl)
          .set("Content-Type", "application/json")
          .set("accept", "json")
          .set({
            Authorization: "Bearer " + authToken,
          })
          .send(requestPayload)
          .end(function (err, res) {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          });
      });
      return promise;

    case "get":
      promise = new Promise(function (resolve, reject) {
        superagent
          .get(endpoint + callUrl)
          .set("Content-Type", "application/json")
          .set("accept", "json")
          .set({
            Authorization: "Bearer " + authToken,
          })
          .send(requestPayload)
          .end(function (err, res) {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          });
      });
      return promise;

    case "delete":
      let promise = new Promise(function (resolve, reject) {
        superagent
          .delete(endpoint + callUrl)
          .set("Content-Type", "application/json")
          .set("accept", "json")
          .set({
            Authorization: "Bearer " + authToken,
          })
          .send(requestPayload)
          .end(function (err, res) {
            if (err) {
              reject(err);
            } else {
              resolve(res);
            }
          });
      });
      return promise;
  }
}
export { callApi };
