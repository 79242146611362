import actions from "./actions";
const initState = {
  schoolDetails: [],
  isLoading: false,
  error: false,
  schoolDetailsChannel: undefined,
  schoolDetailOperationRequest: "",
};
export default function schoolDetailsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SCHOOL_DETAILS:
      return {
        ...state,
        isLoading: true,
        schoolDetails: [],
        schoolDetailOperationRequest: "",
      };

    case actions.GET_SCHOOL_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        schoolDetails: action.schoolDetails,
        schoolDetailsChannel: action.schoolDetailsChannel,
        schoolDetailOperationRequest: "SCHOOL_DETAIl_FETCH_SUCCESS",
      };

    case actions.SAVE_SCHOOL_DETAILS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.SAVE_SCHOOL_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        schoolDetailOperationRequest: "SAVE_DETAIL",
      };

    case actions.SCHOOL_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
}
