import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import moment from "moment-timezone";
import formatMsg from "../components/utility/formatMessageUtil";

function createNewLeadNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var key = rsf.ref(branchPath + "/leads").push().key;

  return key;
}

function getAllLeads(startDate, endDate, firebase, frequency, selectedStatus) {
  // let startTime = startDate.getTime();
  // let endTime = endDate.getTime();
  let startTime = moment(startDate).startOf("day").valueOf();
  let endTime = moment(endDate).endOf("day").valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";
  if (frequency && selectedStatus === "Admitted") {
    myRef = rsf
      .ref(branchPath + "/leads/")
      .orderByChild("admissionDate")
      .startAt(startTime)
      .endAt(endTime);
  } else {
    myRef = rsf
      .ref(branchPath + "/leads/")
      .orderByChild("createdDate")
      .startAt(startTime)
      .endAt(endTime);
  }

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/leads/").off();
      console.log("unsubscribe leads");
    };
  });
}

function getLifetimeLeads(firebase, frequency, selectedStatus) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";
  if (frequency && selectedStatus === "Admitted") {
    myRef = rsf.ref(branchPath + "/leads/").orderByChild("admissionDate");
  } else {
    myRef = rsf.ref(branchPath + "/leads/").orderByChild("createdDate");
  }

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          x.push(element.val());
        }
      });
      x.reverse();
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/leads/").off();

      console.log("unsubscribe leads");
    };
  });
}

// function getMonthDateRange(year, month) {
//   var moment = require('moment');

//   var startDate = moment([year, month - 1]);

//   var endDate = moment(startDate).endOf('month');

//   console.log(startDate.toDate());
//   console.log(endDate.toDate());

//   return { start: startDate, end: endDate };
// }

function getFeePrograms(firebase) {
  let feeProgram = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/programs");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let activityClasses = element.val().classroom;
              if (activityClasses) {
                for (let index in classList) {
                  let filteredVal = activityClasses.filter((f) => {
                    return f.name.toLowerCase() === classList[index].toLowerCase();
                  });

                  if (filteredVal && filteredVal.length > 0) {
                    feeProgram.push(element.val());
                    break;
                  }
                }
              } else {
                feeProgram.push(element.val());
              }
            } else {
              feeProgram.push(element.val());
            }
          } else {
            feeProgram.push(element.val());
          }
        }
      });
      resolve(feeProgram);
    });
  });
  return promise1;
}



function createStatusNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadsStatus/").push().key;
  return key;
}
function createSourceNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadsSource/").push().key;
  return key;
}
function createSubSourceNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadsSubsource/").push().key;
  return key;
}

function addStatus(value, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsStatus/" + nodeId).set({
    id: nodeId,
    name: value,
  });
}
function addStatusValues(status, statusKey, statusValue, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf.ref(branchPath + "/leadsStatus/" + status.id).once("value").then((snap) => {
    if (snap.val() && snap.val().name && snap.val().id) {
      let statusObj = snap.val()
      if (statusKey && statusKey.toLowerCase() === "color") {

        statusObj.color = statusValue

      }
      else if (statusKey && statusKey.toLowerCase() === "statusindex") {
        statusObj.statusIndex = statusValue
      }
      rsf.ref(branchPath + "/leadsStatus/" + status.id).update(statusObj);
    } else {
      throw formatMsg("manageLeadError");
    }
  }
  )
}
function addNewStatusSourceSubsource(value, category, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + category + nodeId).set({
    id: nodeId,
    name: value,
  });
}
function getManageLead(firebase, endPoint) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + endPoint);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });

    return () => {
      myRef.off();
      console.log("unsubscribe manage leads");
    };
  });
}

function getLeadsStatus(firebase, endPoint) {
  let status = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadsStatus");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id && element.val().name) {
          status.push(element.val());
        }
      });
      resolve(status);
    });
  });
  return promise1;
}
function getLeadsSource(firebase) {
  let status = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadsSource");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null) {
          status.push(element.val());
        }
      });
      resolve(status);
    });
  });
  return promise1;
}
function getLeadsSubsource(firebase) {
  let status = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadsSubsource");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element !== null) {
          status.push(element.val());
        }
      });
      resolve(status);
    });
  });
  return promise1;
}
function getSelectedLead(selectedLead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/leads/" + selectedLead.id).on("value", (snap) => {
      var x = [];
      if (snap.val() !== null) {
        x.push(snap.val());
      }
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/leads/" + selectedLead.id).off();

      console.log("unsubscribe leads");
    };
  });
}

function createNewActivityNode(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/newLeadsActivity/").push().key;
  return key;
}



function createNewTemplateNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/leadEmailTemplates/").push().key;
  return key;
}

function addTemplate(value, nodeId, firebase, htmlText) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadEmailTemplates/" + nodeId).set({
    templateName: value.templateName,
    subject: value.subject,
    description: htmlText,
    id: nodeId,
    createdBy: firebase.teacher.name,
    platform: "web",
    createdDate: moment().valueOf(),
    templateFor: value.templateFor,
  });
}

function getAllEmailTemplate(firebase, type) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/emailTemplates");
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var templates = [];
      snap.forEach((element) => {
        if (element !== null && element.val().type == type) {
          templates.push(element.val());
        }
      });
      emit(templates);
    });
    return () => {
      myRef.off();

      console.log("unsubscribe templates");
    };
  });
}

function deleteEmailTemplateStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadEmailTemplates/" + record.id).set(null);
}

function updateEmailTemplateStatus(value, htmlTemplate, firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/emailTemplates/" + id).update({
    id: id,
    templateName: value.templateName,
    subject: value.subject,
    description: htmlTemplate,
    createdBy: firebase.teacher.name,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    templateFor: value.templateFor,
  });
}


function getSelectedLeadActivity(selectedLead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let leadRef = rsf
    .ref(branchPath + "/newLeadsActivity/")
    .orderByChild("leadId")
    .equalTo(selectedLead.id);
  return eventChannel((emit) => {
    leadRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      leadRef.off();

      console.log("unsubscribe leads");
    };
  });
}

function getLeadDocument(leadId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var docs = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/documents/" + leadId)
      .orderByChild("inverseDate")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            if (snap.val().type.toLowerCase() === "folder") {
              docs.push(snap.val());
            } else if (snap.val().downloadUrl) {
              docs.push(snap.val());
            }
          }
        });
        resolve(docs);
      });
  });
  return studentPromise;
}

function updateLeadStatusChange(selectedLeadStatus, lead, nodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/newLeadsActivity/" + nodeId).update({
    id: nodeId,
    leadId: lead.id,
    activityType: "Status Update",
    description: "Status changed to " + selectedLeadStatus.key,
    date: moment().valueOf(),
    time: moment().valueOf(),
    createdOn: moment().valueOf(),
    createdBy: firebase.teacher.name
  });
}


function getLeadsForSingleDay(startDate, endDate, firebase) {
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("createdDate")
    .startAt(startTime)
    .endAt(endTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      return x;
    });
}

function getFollowUpDueToday(startDate, endDate, firebase) {
  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("nextFollowUpDate")
    .startAt(startTime)
    .endAt(endTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      return x;
    });
}

function followUpOverdue(startDate, endDate, firebase) {
  let startTime = startDate.getTime();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/leads/")
    .orderByChild("nextFollowUpDate")
    .endAt(startTime)
    .once("value")
    .then((snap) => {
      var x = [];
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().nextFollowUpDate &&
          (element.val().leadStatus !== "Admitted" || element.val().leadStatus !== "Accepted")
        ) {
          x.push(element.val());
        }
      });
      return x;
    });
}


function deleteAllTaskForSelectedLead(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/newLeadsActivity/")
    .orderByChild("leadId")
    .equalTo(record.id)
    .once("value")
    .then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null) {
          rsf.ref(branchPath + "/newLeadsActivity/" + element.val().id).set(null);
        }
      });
    });
}

function deleteLeadStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leadsStatus/" + record.id).set(null);
}

function deleteSelectedLeadCategory(record, category, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + category + record.id).set(null);
}
function getAdditionalFields(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var formField = [];
  var formPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/leadsFormRef")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null) {
            formField.push(item.val());
          }
        });
        resolve(formField);
      });
  });
  return formPromise;
}

function getLifetimeLeadsData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var leadsData = [];
  var leadPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/leads/")
      .once("value")
      .then((snap) => {
        snap.forEach((element) => {
          leadsData.push(element.val());
        });
        resolve(leadsData);
      });
  });
  return leadPromise;
}

function updateLeadStudentAddition(record, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leads/" + record.id).update({ studentId: studentId });
}

function updateLeadObject(id, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leads/" + id).update(obj);
}





function deleteLeadEmail(item, leadId, firebase) {
  let id = item.id;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/newLeadsActivity/" + id).set(null);
}
function fetchAllEmailTemplate(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadEmailTemplates");
  var templates = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val()) {
          templates.push(element.val());
        }
      });
      resolve(templates);
    });
  });
  return promise;
}

function fetchAllLeadsTasks(task, firebase, startDate, endDate) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/newLeadsActivity/")
      .orderByChild("activityType")
      .equalTo(task)
      .on("value", (snap) => {
        var x = [];
        snap.forEach((item) => {
          if (item.val() !== null) {
            if (startDate && endDate) {
              if (
                item.val().createdOn &&
                moment(item.val().createdOn).isBetween(startDate, endDate, "day", "[]")
              ) {
                x.push(item.val());
              }
            } else {
              x.push(item.val());
            }
          }
        });
        emit(x);
      });

    return () => {
      rsf.ref(branchPath + "/newLeadsActivity/").off();
      console.log("unsubscribe leads");
    };
  });
}
function FetchStudent(leadId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var student = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/leads")
      .orderByChild("id")
      .equalTo(leadId)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            student.push(snap.val());
          }
        });
        resolve(student);
      });
  });
  return promise;
}

function fetchLeadsForTask(leadId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let lead = {};
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/leads")
      .orderByChild("id")
      .equalTo(leadId)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            lead = snap.val();
          }
        });
        resolve(lead);
      });
  });
  return promise;
}

function fetchSelectedTask(taskId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/newLeadsActivity/" + taskId).on("value", (snap) => {
      var x = [];
      if (snap.val() !== null) {
        x.push(snap.val());
      }
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/newLeadsActivity/" + taskId).off();

      console.log("unsubscribe leads");
    };
  });
}
function fetchSelectedLead(selectedLead, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leads/" + selectedLead);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      if (snap.val() !== null && snap.val().id) {
        x.push(snap.val());
      }

      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe selected leads");
    };
  });
}
function fetchAllLeadAutomation(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/automationRules");
  var templates = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val()) {
          templates.push(element.val());
        }
      });
      resolve(templates);
    });
  });
  return promise;
}
function getSharedForms(firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/leadsApplicationShareRef").orderByChild("entityId").equalTo(id)
  var forms = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val()) {
          forms.push(element.val());
        }
      });
      resolve(forms);
    });
  });
  return promise;
}

function getMailDeliveryReportStatus(id, firebase) {
  var status = [];
  var myPromise = new Promise(function (resolve, reject) {
    firebase.db
      .ref("emailDeliveryReportList" + "/" + id)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            status.push(snap.val());
          }
        });
        resolve(status);
      });
  });
  return myPromise;
}
function getLeadsByFormId(firebase, leadFormId) {
  console.log(leadFormId);
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = "";
  myRef = rsf.ref(branchPath + "/leads/").orderByChild("formId").equalTo(leadFormId);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          x.push(element.val());
        }
      });
      x.reverse();
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/leads/").off();
      console.log("unsubscribe leads");
    };
  });
}


export const LeadsApi = {
  deleteLeadEmail,
  fetchAllEmailTemplate,
  fetchAllLeadAutomation,

  getAllEmailTemplate,
  deleteEmailTemplateStatus,
  updateEmailTemplateStatus,
  addTemplate,
  createNewTemplateNode,

  createNewLeadNode,
  getAllLeads,
  getLifetimeLeads,
  getFeePrograms,

  createStatusNodeId,
  createSourceNodeId,
  createSubSourceNodeId,
  addStatus,
  addStatusValues,
  addNewStatusSourceSubsource,
  getLeadsStatus,
  getManageLead,
  getLeadsSource,
  getLeadsSubsource,
  getSelectedLead,
  createNewActivityNode,


  fetchLeadsForTask,

  FetchStudent,

  getSelectedLeadActivity,
  getLeadDocument,

  getLeadsForSingleDay,
  getFollowUpDueToday,
  followUpOverdue,

  deleteAllTaskForSelectedLead,
  deleteLeadStatus,
  deleteSelectedLeadCategory,
  getAdditionalFields,
  getLifetimeLeadsData,
  updateLeadStudentAddition,


  fetchAllLeadsTasks,

  fetchSelectedTask,
  updateLeadObject,
  fetchSelectedLead,
  getSharedForms,

  getMailDeliveryReportStatus,
  getLeadsByFormId
};
