import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";
import { getItem } from "../Utility/encryptedStorage";
import { UserSettingApi } from "./userSetting";
const superagent = require("superagent");


function getAllClassrooms(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/classrooms").on("value", (snap) => {
      const fullClassroomData = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          fullClassroomData.push(element.val());
        }
      });
      emitter(fullClassroomData);
    });
    return () => {
      rsf.ref(branchPath + "/classrooms").off();
      console.log("END");
    };
  });
}




function updateExistingClassroom(updatedName, classroomId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/classrooms/" + classroomId).update({
    className: updatedName,
  });
}

function* disableClassroom(disableStatus, docId) {
  // const firebaseName = getItem('projectId');
  // const rsf = new ReduxSagaFirebase(firebase.app(firebaseName));
  // if (disableStatus === false) {
  //     yield call(rsf.firestore.updateDocument, 'room/' + docId, 'isDisable', true);
  // } else {
  //     yield call(rsf.firestore.updateDocument, 'room/' + docId, 'isDisable', false);
  // }
}

function getClassroomsByCenter(firebase, bPath) {
  let classroomData = [];
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  const myRef = rsf.ref(branchPath + "/classrooms");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          classroomData.push(element.val());
        }
      });
      resolve(classroomData);
    });
  });
  return promise1;
}
async function renameClassoom(firebase, classroom, newName) {
  let obj = { className: newName };
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "/woodlandApi/classroom/rename/" +
    classroom.id +
    "/?centerId=" +
    accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(obj)
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("rename classroom error =>", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function deleteClassroomWithTransfer(
  firebase,
  classroomToDelete,
  classroomToTransferStudent,
  classroomToTransferTeacher
) {
  let obj = {
    studentBackupClass: classroomToTransferStudent.className,
    staffBackupClass: classroomToTransferTeacher.className,
  };

  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl +
    "/woodlandApi/classroom/" +
    classroomToDelete.id +
    "/?centerId=" +
    accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .delete(endPointUrl)

      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(obj)
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete classroom error =>", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function deleteClassroom(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/classrooms/" + record.id).set(null);
}

export const ClassroomApi = {
  getAllClassrooms,

  updateExistingClassroom,
  disableClassroom,

  getClassroomsByCenter,
  deleteClassroom,
  renameClassoom,
  deleteClassroomWithTransfer,
};
