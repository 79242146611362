import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { FeeApi } from "../../firestore-api/fee";
import { ProgramApi } from "../../firestore-api/program";
import { InvoiceApi } from "../../firestore-api/invoice";
import bugsnagClient from "@bugsnag/js";
import { NotificationApi } from "../../firestore-api/notification";
import moment from "moment-timezone";
import FilterAction from "../../Utility/FilterAction";
import formatMsg from "../../components/utility/formatMessageUtil";
import notification from "../../components/notification";
function* fetchFeeComponent({ firebase }) {
  const chan = yield call(FeeApi.getFeeComponent, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_FEE_COMPONENTS_SUCCESSFFUL,
        feeComponent: data,
        feeComponentChannel: chan,
      });
    }
  } finally {
    console.log("end fee component channel");
  }
}

function* fetchFeePlan({ firebase }) {
  const chan = yield call(FeeApi.getFeePlan, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_FEE_PLANS_SUCCESSFFUL,
        feePlan: data,
        feePlanChannel: chan,
      });
    }
  } finally {
    console.log("end fee plan channel");
  }
}

function* addNewFeePlan({ value, customDates, firebase, rows, monthType, selectedMonth }) {
  try {
    var nodeId;
    var feeComponent = [];
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].feeId) {
        nodeId = yield call(FeeApi.createNewFeeComponentNode, firebase);
        feeComponent.push(nodeId);

        yield call(FeeApi.addFeeComponent, rows[i], nodeId, firebase);
      } else {
        feeComponent.push(rows[i].feeId);
      }
    }
    var newRow = [];

    rows.map((newItem) => {
      newRow.push({
        name: newItem.name,
        paymentSchedule: newItem.paymentSchedule,
        paymentFrequency: newItem.paymentFrequency,

        amount: newItem.amount,
        feeId: newItem.id ? newItem.id : null,
      });
    });

    var key = yield call(FeeApi.createNewFeePlanNode, firebase);

    yield call(
      FeeApi.addFeePlan,
      value,
      key,
      firebase,
      feeComponent,
      customDates,
      monthType,
      selectedMonth
    );
    firebase.getAllStudentsFeePlan();
    yield put({
      type: actions.ADD_FEE_PLAN_SUCCESSFFUL,
      feePlanId: key,
    });
  } catch (error) {
    console.log("failed to add new fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* updateExistingFeePlan({
  value,
  editableFeePlan,
  customDateArr,
  firebase,
  rows,
  monthType,
  selectedMonth,
}) {
  try {
    var nodeId;
    var feeComponent = [];
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].feeId) {
        nodeId = yield call(FeeApi.createNewFeeComponentNode, firebase);
        feeComponent.push(nodeId);
        rows[i].feeId = nodeId;
        yield call(FeeApi.addFeeComponent, rows[i], nodeId, firebase);
      } else {
        feeComponent.push(rows[i].feeId);
      }
    }

    yield call(
      FeeApi.updateFeePlan,
      value,
      editableFeePlan,
      firebase,
      customDateArr,
      feeComponent,
      monthType,
      selectedMonth
    );

    let students = editableFeePlan.student ? editableFeePlan.student : [];
    let feePlanId = editableFeePlan.id;

    for (let index in students) {
      let stdFeePlan = yield call(
        FeeApi.getStudentFeePlanByPlanId,
        students[index].studentId,
        feePlanId,
        firebase
      );

      if (stdFeePlan) {
        let newStudentFeePlan = stdFeePlan;
        let prevStudentFeeComponent = [];

        newStudentFeePlan.planName = value.name.charAt(0).toUpperCase() + value.name.slice(1);
        if (editableFeePlan.paymentMode.toLowerCase() === "subscription") {
          // do nothing
        } else {
          newStudentFeePlan.generationDate = value.generationDate
            ? moment(value.generationDate).valueOf()
            : null;
        }
        newStudentFeePlan.startDate = value.dateRange
          ? moment(value.dateRange[0]).startOf("day").valueOf()
          : null;
        newStudentFeePlan.endDate = value.dateRange
          ? moment(value.dateRange[1]).startOf("day").valueOf()
          : null;

        newStudentFeePlan.feeComponent = feeComponent;

        /**iterate through the rows */
        for (let i in rows) {
          let rowVal = rows[i];

          var data = {};
          data.amount = FilterAction.getFloorDecimalToTwo(rowVal.amount);
          data.discount = rowVal.discount !== undefined ? FilterAction.getFloorDecimalToTwo(rowVal.discount) : 0;
          data.discountType = rowVal.discountType !== undefined ? rowVal.discountType : null;
          data.id = rowVal.feeId;
          data.inverseDate = -moment().valueOf();
          data.isRefundable =
            rowVal.paymentSchedule === "One Time" ? rowVal.paymentFrequency : "Non Refundable";
          data.name = rowVal.name.charAt(0).toUpperCase() + rowVal.name.slice(1);
          data.paymentFrequency = rowVal.paymentFrequency;
          data.paymentSchedule = rowVal.paymentSchedule;

          prevStudentFeeComponent.push(data);
        }
        newStudentFeePlan.studentFeeComponent = prevStudentFeeComponent;

        yield call(
          FeeApi.updateStudentFeePlan,
          students[index].studentId,
          newStudentFeePlan,
          firebase
        );
      }
    }
    yield put({
      type: actions.UPDATE_FEE_PLAN_SUCCESSFFUL,
    });
    firebase.getAllStudentsFeePlan();
  } catch (error) {
    console.log("failed to update fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* addNewFeeComponent({ value, firebase }) {
  try {
    var key = yield call(FeeApi.createNewFeeComponentNode, firebase);
    yield call(FeeApi.addFeeComponent, value, key, firebase);
    yield put({
      type: actions.ADD_FEE_COMPONENT_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to add new fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* updateExistingFeeComponent({ value, editableFeeComponent, firebase }) {
  try {
    yield call(FeeApi.updateFeeComponent, value, editableFeeComponent, firebase);
    yield put({
      type: actions.UPDATE_FEE_COMPONENT_SUCCESSFFUL,
    });
  } catch (error) {
    console.log("failed to update fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* attachFeeComponentToFeePlan({ selectedComponent, record, firebase }) {
  try {
    yield call(FeeApi.attachFeeComponentToFeePlan, selectedComponent, record.id, firebase);

    if (record.student) {
      let students = record.student;
      let feePlanId = record.id;
      for (let index in students) {
        let stdFeePlan = yield call(
          FeeApi.getStudentFeePlanByPlanId,
          students[index].studentId,
          feePlanId,
          firebase
        );
        if (stdFeePlan) {
          let newStudentFeePlan = stdFeePlan;
          let prevStudentFeeComponent = [];

          for (let i in selectedComponent) {
            let feeComponent = yield call(
              FeeApi.getFeeComponentById,
              selectedComponent[i],
              firebase
            );
            if (feeComponent && feeComponent.id) {
              var data = {};
              data.amount = feeComponent.amount;
              data.discount = feeComponent.discount;
              data.discountType = feeComponent.discountType ? feeComponent.discountType : null;
              data.id = feeComponent.id;
              data.inverseDate = -moment().valueOf();
              data.isRefundable = feeComponent.isRefundable;
              data.name = feeComponent.name;
              data.paymentFrequency = feeComponent.paymentFrequency;
              data.paymentSchedule = feeComponent.paymentSchedule;
              prevStudentFeeComponent.push(data);
            }
          }
          newStudentFeePlan.studentFeeComponent = prevStudentFeeComponent;
          newStudentFeePlan.feeComponent = selectedComponent;
          yield call(
            FeeApi.updateStudentFeePlan,
            students[index].studentId,
            newStudentFeePlan,
            firebase
          );
        }
      }
    }
    firebase.getAllStudentsFeePlan();
  } catch (error) {
    console.log("failed to attach fee component", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* editPop({ feeComponent }) {
  yield put({
    type: actions.POP_EDIT_SUCCESSFFUL,
    editableSelectedComponent: feeComponent,
  });
}

function* resetPop() {
  yield put({
    type: actions.RESET_POP_EDIT_SUCCESSFFUL,
    editableSelectedComponent: undefined,
  });
}

function* getStudentsForFeePlan({ firebase }) {
  try {
    //let data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = FilterAction.getStudentList(firebase);
    if (data) {
      yield put({
        type: actions.FEE_PLAN_FETCH_STUDENT_SUCCESSFFUL,
        students: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch students for fee plan", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* assignSelectedStudentToFeePlan({
  formValue,
  studentList,
  selectedFeePlan,
  rows,
  total,
  showGeneratedInvoice,
  firebase,
}) {
  console.log("formValue", formValue);
  console.log("studentList", studentList);
  console.log("selectedFeePlan", selectedFeePlan);
  console.log("rows", rows);
  console.log("total", total);
  console.log("firebase", firebase);
  try {
    var invoiceStudentArr = [];
    var studentArray = [];
    if (selectedFeePlan.student !== undefined) {
      studentArray = selectedFeePlan.student;
    }

    for (let index in studentList) {
      let filterVal = studentArray.filter((i) => {
        return i.studentId === studentList[index].id;
      });
      if (filterVal === undefined || filterVal.length === 0) {
        studentArray.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(formValue.dateRange[0]).valueOf(),
          endDate: moment(formValue.dateRange[1]).valueOf(),
          classroomName: (studentList[index].classList && studentList[index].classList.length > 0) ? studentList[index].classList[0] : null,
          classList: studentList[index].classList ? studentList[index].classList : [],
          active: true,
        });

        invoiceStudentArr.push({
          name: studentList[index].name,
          studentId: studentList[index].id,
          startDate: moment(formValue.dateRange[0]).valueOf(),
          endDate: moment(formValue.dateRange[1]).valueOf(),
          classroomName: (studentList[index].classList && studentList[index].classList.length > 0) ? studentList[index].classList[0] : null,
          classList: studentList[index].classList ? studentList[index].classList : [],
          active: true,
        });
      }
    }

    let presentFeePlan = JSON.parse(JSON.stringify(selectedFeePlan));
    presentFeePlan.student = studentArray;


    yield call(
      FeeApi.assignStudentsToFeePlan,
      // formValue,
      // studentList,
      // selectedFeePlan,
      presentFeePlan,
      firebase
    );

    let lineItems = [];
    for (let ind in rows) {
      lineItems.push({
        amount: FilterAction.getFloorDecimalToTwo(rows[ind].grossValue),
        discount: FilterAction.getFloorDecimalToTwo(rows[ind].discount),
        discountType: rows[ind].discountType ? rows[ind].discountType : null,
        id: rows[ind].id,
        inverseDate: -new Date(),
        isRefundable: rows[ind].isRefundable,
        name: rows[ind].description,
        paymentFrequency: rows[ind].paymentFrequency,
        paymentSchedule: rows[ind].paymentSchedule,
      });
    }

    if (selectedFeePlan.paymentMode && selectedFeePlan.paymentMode === "Subscription") {
      presentFeePlan.generationDate = moment(formValue.dateRange[0]).valueOf();
    }

    // start date assign student date > presentFeePlan gen date
    //presentFeePlan.generationDate will be
    if (
      selectedFeePlan.paymentMode &&
      selectedFeePlan.paymentMode.toLowerCase() === "prepaid" &&
      moment(formValue.dateRange[0]).isAfter(moment(presentFeePlan.generationDate), "day")
    ) {
      presentFeePlan.generationDate = FilterAction.updateNextGenerationDate(
        presentFeePlan,
        moment(presentFeePlan.generationDate)
      ).valueOf();
    }

    presentFeePlan.studentFeeComponent = lineItems;
    for (let i in studentList) {
      yield call(FeeApi.updateStudentFeePlan, studentList[i].id, presentFeePlan, firebase);
      yield call(
        NotificationApi.callFeePlanStudentAssignWebHook,
        studentList[i],
        presentFeePlan,
        moment(formValue.dateRange[0]).valueOf(),
        moment(formValue.dateRange[1]).valueOf(),
        firebase,
        "Subscription_Added"
      );
    }

    if (showGeneratedInvoice) {
      let feePlanObject = JSON.parse(JSON.stringify(presentFeePlan));
      feePlanObject.student = invoiceStudentArr;
      yield call(NotificationApi.invoiceGenerationRequest, feePlanObject, firebase);
    }

    yield put({
      type: actions.ASSIGN_STUDENT_TO_FEE_PLAN_SUCCESSFUL,
      programOperationType: "ASSIGN_STUDENT",
    });
    firebase.getAllStudentsFeePlan();
  } catch (error) {
    console.log("failed to assign student to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* getCompleteFeeComponent({ firebase }) {
  try {
    let data = yield call(FeeApi.getAllFeeComponent, firebase);
    if (data) {
      yield put({
        type: actions.GET_COMPLETE_FEE_COMPONENTS_SUCCESSFUL,
        feeComponent: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch fee components", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* fetchFeePrograms({ firebase }) {
  try {
    let data = yield call(ProgramApi.fetchPrograms, firebase);
    yield put({
      programs: data,
      type: actions.GET_FEE_PROGRAMS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to fetch fee programs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedFeePlan({ record, firebase }) {
  try {
    yield call(FeeApi.deleteFeePlan, record, firebase);
    yield put({
      type: actions.DELETE_FEE_PLAN_SUCCESS,
    });
    firebase.getAllStudentsFeePlan();
  } catch (err) {
    console.log("failed to delete fee plan", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* fetchComponentAmountCollection({ startDate, endDate, feeComponent, firebase }) {
  try {
    let allInvoices = [];
    let feePlans = yield call(FeeApi.getFeeAllPlans, firebase);
    if (feePlans && feePlans.length > 0) {
      for (let f in feePlans) {
        if (feePlans[f].feeComponent && feePlans[f].feeComponent.includes(feeComponent.id)) {
          let invoice = yield call(
            InvoiceApi.getInvoiceByFeePlanIdAndDateRange,
            feePlans[f].id,
            startDate,
            endDate,
            firebase,
            feeComponent.name
          );
          allInvoices = [...allInvoices, ...invoice];
        }
      }
    }
    yield put({
      type: actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED_SUCCESS,
      componentInvoices: allInvoices,
    });
  } catch (err) {
    console.log("failed to fee component amount collection", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedFeePlan({ feePlanId, firebase }) {
  try {
    let data = yield call(FeeApi.fetchSelectedFeePlanDetails, feePlanId, firebase);

    var selectedComponent = [];
    console.log(data.feeComponent, "id");
    for (let i = 0; i < data?.feeComponent?.length; i++) {
      selectedComponent = yield call(
        FeeApi.fetchSelectedFeeComponent,
        data.feeComponent[i],
        firebase
      );
    }

    if (data) {
      yield put({
        type: actions.FETCH_FEE_PLAN_DETAILS_SUCCESS,
        feePlanDetail: data,
        selectedComponent: selectedComponent,
      });
    }
  } catch (error) {
    notification("error", formatMsg("error.fetchRequest"));
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}
function* fetchSelectedFeeComponent({ feeComponentId, firebase }) {
  try {
    var feeComponent;
    for (let i in feeComponentId) {
      feeComponent = yield call(FeeApi.getFeeComponentById, feeComponentId[i], firebase);
    }

    if (feeComponent) {
      yield put({
        type: actions.SELECTED_FEE_COMPONENT_SUCCESS,
        feeComponentIDs: feeComponent,
      });
    }
  } catch (error) {
    notification("error", formatMsg("error.fetchRequest"));
    bugsnagClient.notify(error);
    yield put({
      type: actions.FEE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_FEE_COMPONENTS, fetchFeeComponent),
    yield takeLatest(actions.LIST_FEE_PLANS, fetchFeePlan),
    yield takeLatest(actions.ADD_FEE_PLAN, addNewFeePlan),
    yield takeLatest(actions.UPDATE_FEE_PLAN, updateExistingFeePlan),
    yield takeLatest(actions.ADD_FEE_COMPONENT, addNewFeeComponent),
    yield takeLatest(actions.UPDATE_FEE_COMPONENT, updateExistingFeeComponent),
    yield takeLatest(actions.ATTACH_FEE_COMPONENT, attachFeeComponentToFeePlan),
    yield takeLatest(actions.POP_EDIT, editPop),
    yield takeLatest(actions.RESET_POP_EDIT, resetPop),
    yield takeLatest(actions.FEE_PLAN_FETCH_STUDENT, getStudentsForFeePlan),
    yield takeLatest(actions.ASSIGN_STUDENT_TO_FEE_PLAN, assignSelectedStudentToFeePlan),
    yield takeLatest(actions.GET_COMPLETE_FEE_COMPONENTS, getCompleteFeeComponent),
    yield takeLatest(actions.GET_FEE_PROGRAMS, fetchFeePrograms),
    yield takeLatest(actions.DELETE_FEE_PLAN, deleteSelectedFeePlan),
    yield takeLatest(
      actions.GET_SELECTED_COMPONENT_AMOUNT_COLLECTED,
      fetchComponentAmountCollection
    ),
    yield takeLatest(actions.FETCH_FEE_PLAN_DETAILS, fetchSelectedFeePlan),
    yield takeLatest(actions.SELECTED_FEE_COMPONENT, fetchSelectedFeeComponent),
  ]);
}
