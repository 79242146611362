const actions = {
  GET_SUBMISSION_ACTIVITY: "GET_SUBMISSION_ACTIVITY",
  GET_SUBMISSION_ACTIVITY_SUCCESSFUL: "GET_SUBMISSION_ACTIVITY_SUCCESSFUL",

  GET_PENDING_STUDENT_FOR_SUBMISSION: "GET_PENDING_STUDENT_FOR_SUBMISSION",
  GET_PENDING_STUDENT_FOR_SUBMISSION_SUCCESSFUL: "GET_PENDING_STUDENT_FOR_SUBMISSION_SUCCESSFUL",

  SINGLE_SUBMISSION_REQUEST_FAIL: "SINGLE_SUBMISSION_REQUEST_FAIL",

  GET_SINGLE_SUBMISSION_COMMENT: "GET_SINGLE_SUBMISSION_COMMENT",
  GET_SINGLE_SUBMISSION_COMMENT_SUCCESS: "GET_SINGLE_SUBMISSION_COMMENT_SUCCESS",

  MARK_SINGLE_ASSIGNMENT_STAR: "MARK_SINGLE_ASSIGNMENT_STAR",
  MARK_SINGLE_ASSIGNMENT_STAR_SUCCESS: "MARK_SINGLE_ASSIGNMENT_STAR_SUCCESS",

  RESET_SINGLE_SUBMISSION_OPERATION: "RESET_SINGLE_SUBMISSION_OPERATION",

  getSubmissionActivity: (assignmentId, firebase, lessonId, studentId) => ({
    type: actions.GET_SUBMISSION_ACTIVITY,
    assignmentId,
    firebase,
    lessonId,
    studentId,
  }),

  pendingStudent: (assignmentId, firebase, assignmentRecord) => ({
    type: actions.GET_PENDING_STUDENT_FOR_SUBMISSION,
    assignmentId,
    firebase,
    assignmentRecord,
  }),

  getSingleSubmissionComments: (activities, firebase) => ({
    type: actions.GET_SINGLE_SUBMISSION_COMMENT,
    activities,
    firebase,
  }),

  markSingleAssignmentStar: (activity, firebase) => ({
    type: actions.MARK_SINGLE_ASSIGNMENT_STAR,
    activity,
    firebase,
  }),

  resetSingleSubmissionOperation: () => ({
    type: actions.RESET_SINGLE_SUBMISSION_OPERATION,
  }),
};
export default actions;
