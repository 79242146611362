import React from 'react'
import FilterAction from "../../Utility/FilterAction";
import moment from "moment";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import formatMsg from '../../components/utility/formatMessageUtil';
import { Row, Col, Tooltip } from "antd";


function AffectedScheduleView(props) {
    let schedule = props.schedule;

    let teacher = FilterAction.getTeacherObject(schedule.assignedTo);
    return (
        teacher && teacher.length > 0 ? <div style={styles.mainDiv} >
            <div style={styles.avtarDiv}>
                <img
                    src={FilterAction.getStudentIcon({ profileImageUrl: teacher[0].profileImageUrl ? teacher[0].profileImageUrl : "", gender: teacher[0].gender })}
                    style={styles.statusImageStyle}
                    alt="icon"
                />
            </div>
            <div style={styles.dataDiv}>
                <div style={styles.heading} >
                    <div style={styles.belowText}>{teacher[0].name}</div>
                    <div style={styles.dateDiv}>
                        {moment(schedule.startDate).format(FilterAction.getDateFormat(props.firebase))}
                    </div>
                </div>
                <Row type='flex' style={styles.dataRow1} >
                    <Col xs={7} sm={7} md={7} lg={7} >
                        <div style={styles.shift} >
                            <p>{formatMsg("schedule.shiftName")}</p>
                            <p>:</p>
                        </div>
                    </Col>
                    <Col xs={16} sm={16} md={16} lg={16} style={styles.dataCol} >
                        {schedule.shiftName.length > 20 ? (
                            <Tooltip placement="topLeft" title={schedule.shiftName}>
                                {schedule.shiftName.substring(0, 20) + "..."}
                            </Tooltip>
                        ) : (
                            schedule.shiftName
                        )}
                    </Col>
                </Row>
                <Row type='flex' style={styles.dataRow2} >
                    <Col xs={7} sm={7} md={7} lg={7} >
                        <div style={styles.shift} >
                            <p>{formatMsg("schedule.shiftTime")}</p>
                            <p>:</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} style={styles.dataCol} >
                        {moment(schedule.startTime).format("hh:mm A")} -{" "}
                        {moment(schedule.endTime).format("hh:mm A")}
                    </Col>
                </Row>
            </div>
        </div > :
            null
    );
};

export default AffectedScheduleView;
const styles = {
    mainDiv: {
        display: "flex",
        flexDirection: "row",
        border: "1px solid " + colors.v2_silver,
        borderRadius: "7px",
        marginBottom: "15px",
        paddingLeft: "20px",
        paddingTop: "8px",
        backgroundColor: "white"
    },
    avtarDiv: {
        marginTop: "8px",
        alignItems: "center",
    },
    dataDiv: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
    },
    statusImageStyle: {
        height: "45px",
        width: "45px",
        borderRadius: "50%",
        marginRight: 8,
        border: "1px solid rgb(170, 171, 173)"
    },
    belowText: {
        lineHeight: "27px",
        fontWeight: 600,
        fontSize: webFont.fl,
        color: colors.v2_scorpion,
        marginLeft: "10px",
    },
    dateDiv: {
        fontSize: webFont.large,
        color: "rgb(170, 171, 173)",
        fontWeight: 600,
        lineHeight: "26px"
    },
    heading: {
        display: "flex",
        width: "330px",
        justifyContent: "space-between",
        marginTop: "14px"
    },
    dataRow1: {
        marginTop: "10px",
        marginLeft: "10px",
        fontSize: webFont.large,
        marginBottom: "-9px"
    },
    dataRow2: {
        marginLeft: "10px",
        fontSize: webFont.large,
    },
    dataCol: {
        marginLeft: "10px",
        color: "rgb(170, 171, 173)",
        cursor: "pointer"
    },
    shift: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: colors.v2_Cerulean,

    }
};
