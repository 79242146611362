import "@firebase/firestore"; // 👈 If you're using firestore
import { getColor } from "../components";
import { ActivityApi } from "./activity.js";

function createCustomActivityUniqueNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/customActivities").push().key;
  return key;
}

function getCustomActivitiesApi(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var value = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/customActivities")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          //   if (snap.val()) {
          //     value.set(snap.key, snap.val());
          //   }
          value.push(snap.val());
        });
        resolve(value);
      });
  });
  return studentPromise;
}
function deleteCustomActivity(firebase, activityKey) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/customActivities/" + activityKey).set(null);
  ActivityApi.getActivitySetting(firebase).then((enableActivities) => {
    let newEnableActivityList = enableActivities.filter((a) => a.id != activityKey);
    rsf.ref(branchPath + "/schoolSettingActivity").set(newEnableActivityList);
  });
}
function addCustomActivity(firebase, name, values, nodeId, imageUrl) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/customActivities/" + nodeId).set({
    ...getColor(values.activityColor),
    activityDisplayName: name,
    activityType: name,
    image: 0,
    imageUrl: imageUrl[0].path,
    id: nodeId,
    title: name,
    colorName: values.activityColor,
  });
}
function editCustomActivity(firebase, name, values, imageUrl, activity) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/customActivities/" + activity.id).update({
    ...getColor(values.activityColor),
    activityDisplayName: name,
    activityType: name,
    image: 0,
    imageUrl: imageUrl ? imageUrl[0].path : activity.imageUrl,
    title: name,
    colorName: values.activityColor,
  });
}
export const customActivityApi = {
  getCustomActivitiesApi,
  createCustomActivityUniqueNodeId,
  addCustomActivity,
  deleteCustomActivity,
  editCustomActivity,
};
