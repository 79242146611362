import html2canvas from "html2canvas";
import moment from "moment";
import { eventChannel } from "redux-saga";

function getAllNewsLetterV2(firebase){
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    const newsletterRef = rsf.ref(branchPath + "/newsletterV2/")
    newsletterRef.orderByChild("inverseDate")
      .on("value", (snap) => {
        var x = [];
        snap.forEach((element) => {
          if (element.val() !== null) {
            x.push(element.val());
          }
        });

        emit(x);
      });
    return () => {
      newsletterRef.off();
      console.log("unsubscribe newsletter");
    };
  });
}
function uploadImageAttachment(file, firebase) {
  var uploadTimestamp = new Date().getTime();
  var fileName = moment().valueOf() + file.name;
  var metadata = {
    contentType: file.type,
  };

  let branchPath = firebase.sbp;
  const storage = firebase.secondaryStorage;
  const storageRef = storage.ref();
  let attachmentRef = storageRef.child(
    branchPath + "/media/images/" + fileName + ":" + uploadTimestamp
  );

  var p1 = new Promise(function (resolve, reject) {
    attachmentRef.put(file, metadata).then(function (snapshot) {
      attachmentRef.getDownloadURL().then((url) => {
        console.log("found url", url);
        resolve(url);
      });
    });
  });
  return p1;
}

function getCanvaFromNewsletter(newsletter, container){
  container.innerHTML = newsletter.html;
  document.body.appendChild(container);

  let promise = new Promise(function(resolve, reject){
    html2canvas(container,{
      allowTaint : true,
      useCORS: true,
      foreignObjectRendering: false
    }).then((canvas) => {
        resolve(canvas);
    }).catch((err) => {
        resolve(undefined);   
    });
  });
  return promise;
}

function uploadCanvasImage(canvas,firebase, container) {
  var uploadTimestamp = new Date().getTime();
  var fileName = uploadTimestamp + "-canvas.png";
  var metadata = {
    contentType: "image/png",
  };

  let branchPath = firebase.sbp;
  const storage = firebase.secondaryStorage;
  const storageRef = storage.ref();
  let attachmentRef = storageRef.child(
    branchPath + "/media/images/" + fileName + ":" + uploadTimestamp
  );

  var p1 = new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      attachmentRef.put(blob, metadata).then(function (snapshot) {
        attachmentRef.getDownloadURL().then((url) => {
          console.log("Found URL", url);
          resolve(url);
          document.body.removeChild(container);
        });
      });
    }, "image/png");
  });
  return p1;
}

function getStudentFromClassroom(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let student = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null && snap.val().classList.includes(className)) {
            student.push(snap.val());
          }
        });
        resolve(student);
      });
  });
  return studentPromise;
}


export const NewsletterApi = {
  uploadImageAttachment,
  getStudentFromClassroom,
  getAllNewsLetterV2,
  uploadCanvasImage,
  getCanvaFromNewsletter
};
