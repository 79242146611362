import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { eventChannel } from "redux-saga";

function getStaffAttendance(date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate).on("value", (snap) => {
      let attendance = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          attendance.push(element.val());
        }
      });
      emit(attendance);
    });
    return () => {
      rsf.ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate).off();
      console.log("unsubscribe staff checkInOut");
    };
  });
}

function createUniqueNodeInStaffCheckInOutReference(date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/staffCheckInOutReference/" + searchableDate).push().key;
  return key;
}

function markPresentToCheckInOutReference(date, nodeId, attendanceObj, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/staffCheckInOutReference/" + searchableDate + "/" + nodeId)
    .update(attendanceObj);
}

function markPresentToTeacherAttendanceUpdates(date, nodeId, attendanceObj, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate + "/" + nodeId)
    .update(attendanceObj);
}

function addRecordToStaffAttendance(date, attendanceObj, selectedStudent, firebase) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/staffAttendanceReference/" +
        selectedStudent.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update(attendanceObj);
}

function updateCheckOutTimeInStaffCheckInOutReference(
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/staffCheckInOutReference/" + searchableDate + "/" + selectedStaff.userId)
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout: checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function updateCheckOutTimeInTeacherAttendanceUpdate(
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate + "/" + selectedStaff.userId)
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout: checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function updateCheckOutTimeToStaffAttendance(
  selectedStaff,
  date,
  time,
  firebase,
  checkInOutStatus
) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/staffAttendanceReference/" +
        selectedStaff.userId +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update({
      checkOutTime: moment(time).format("h:mm a"),
      checkOutEpoch: moment(time).valueOf(),
      platform: "web",
      lateCheckout: checkInOutStatus && checkInOutStatus === "late" ? true : false,
      checkoutBy: firebase.teacher.name,
    });
}

function removeStaffRecordFromStaffCheckInOutReference(selectedStaff, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/staffCheckInOutReference/" + searchableDate + "/" + selectedStaff.id)
    .set(null);
}

function updateRecordToTeacherAttendanceUpdates(selectedStaff, date, firebase, obj) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate + "/" + selectedStaff.id)
    .update(obj);
}

function updateRecordToStaffAttendance(selectedStaff, date, firebase, obj) {
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(
      branchPath +
        "/staffAttendanceReference/" +
        selectedStaff.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .update(obj);
}

function getStaffByClassName(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var teacher = [];
  var teacherPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/teachers")
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            teacher.push(snap.val());
          }
        });
        resolve(teacher);
      });
  });
  return teacherPromise;
}

function getStaffAttendanceByClassName(className, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf.ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate).on("value", (snap) => {
      let attendance = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().classrooms.includes(className)) {
          attendance.push(element.val());
        }
      });
      emit(attendance);
    });
    return () => {
      rsf.ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate).off();
      console.log("unsubscribe checkInOut");
    };
  });
}

function getStaffAttendanceByMonth(date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let searchableMonth = moment(date).format("MMM");
  let searchableYear = moment(date).format("YYYY");

  let atdRecord = new Map();
  var atdRef = rsf
    .ref(branchPath + "/staffMonthAttendance")
    .orderByChild("month")
    .equalTo(searchableMonth);
  var promise = new Promise(function (resolve, reject) {
    atdRef.once("value").then(function (snapshot) {
      snapshot.forEach((ele) => {
        if (ele.val() !== null && moment(ele.val().date).format("YYYY") === searchableYear) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              let teacherClassList = ele.val().classrooms;
              let ifAdd = false;
              for (let i in classList) {
                if (teacherClassList && teacherClassList.includes(classList[i])) {
                  ifAdd = true;
                  break;
                }
              }

              if (ifAdd) {
                if (atdRecord.has(ele.val().userId)) {
                  let childVal = atdRecord.get(ele.val().userId);
                  childVal.push(ele.val());
                  atdRecord.set(ele.val().userId, childVal);
                } else {
                  let childVal = [];
                  childVal.push(ele.val());
                  atdRecord.set(ele.val().userId, childVal);
                }
              }
            } else {
              if (atdRecord.has(ele.val().userId)) {
                let childVal = atdRecord.get(ele.val().userId);
                childVal.push(ele.val());
                atdRecord.set(ele.val().userId, childVal);
              } else {
                let childVal = [];
                childVal.push(ele.val());
                atdRecord.set(ele.val().userId, childVal);
              }
            }
          } else {
            if (atdRecord.has(ele.val().userId)) {
              let childVal = atdRecord.get(ele.val().userId);
              childVal.push(ele.val());
              atdRecord.set(ele.val().userId, childVal);
            } else {
              let childVal = [];
              childVal.push(ele.val());
              atdRecord.set(ele.val().userId, childVal);
            }
          }
        }
      });
      resolve(atdRecord);
    });
  });
  return promise;
}

function getStaffAttendanceById(date, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  var atdRef = rsf.ref(
    branchPath +
      "/staffAttendanceReference/" +
      id +
      "/" +
      searchableMonthYear +
      "/" +
      searchableDate
  );

  var atd = {};

  var promise = new Promise(function (resolve, reject) {
    atdRef.once("value").then(function (snapshot) {
      if (snapshot.val() !== null) {
        atd = snapshot.val();
        resolve(atd);
      }
    });
  });
  return promise;
}

function markStaffPending(selectedStaff, date, firebase) {
  let searchableDate = moment(date).format(" DD[-]MM[-]YY");
  let searchableMonthYear = moment(date).format("MMMM[ ]YYYY");

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf
    .ref(branchPath + "/teacherAttendanceUpdates/" + searchableDate + "/" + selectedStaff.id)
    .set(null);

  rsf
    .ref(
      branchPath +
        "/staffAttendanceReference/" +
        selectedStaff.id +
        "/" +
        searchableMonthYear +
        "/" +
        searchableDate
    )
    .set(null);
}

function updateTeacherLastAtd(teacherId, atdTime, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherId).update({
    lastAttendanceDate: atdTime ? atdTime : null,
    platform: "web",
  });
}

function updateTeacherLastAtdCheckout(teacherId, atdTime, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/teachers/" + teacherId).update({
    lastAttendanceCheckout: atdTime ? atdTime : null,
    platform: "web",
  });
}

function getNewStaffAttendance(date, firebase) {
  let searchableDate = moment(date).format("DD[-]MM[-]YY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    rsf
      .ref(branchPath + "/staffRoomRecords")
      .orderByChild("dateKey")
      .equalTo(searchableDate)
      .on("value", (snap) => {
        let attendance = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().id) {
            attendance.push(element.val());
          }
        });
        emit(attendance);
      });
    return () => {
      rsf.ref(branchPath + "/staffRoomRecords").off();
      console.log("unsubscribe staffRoomRecords");
    };
  });
}

function getStaffNewAttendanceByMonth(date, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let searchableMonth = moment(date).format("MMM[-]YYYY");

  let atdRecord = new Map();
  var atdRef = rsf
    .ref(branchPath + "/staffRoomRecords")
    .orderByChild("monthYearKey")
    .equalTo(searchableMonth);
  var promise = new Promise(function (resolve, reject) {
    atdRef.once("value").then(function (snapshot) {
      snapshot.forEach((ele) => {
        if (ele.val() !== null && ele.val().entityId) {
          if (atdRecord.has(ele.val().entityId)) {
            let childVal = atdRecord.get(ele.val().entityId);
            childVal.push(ele.val());
            atdRecord.set(ele.val().entityId, childVal);
          } else {
            let childVal = [];
            childVal.push(ele.val());
            atdRecord.set(ele.val().entityId, childVal);
          }
        }
      });
      resolve(atdRecord);
    });
  });
  return promise;
}
function getStaffLeavesOfMonth(date, firebase, type, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let startDateOfMonth = moment(date).startOf("month").valueOf();
  let endDateOfMonth = moment(date).endOf("month").valueOf();

  let leavesOfSelectedMonth = [];
  let node = type == "student" ? "/leaves" : "/staffLeaveReference";

  var leaveRef;
  if (id)
    leaveRef = rsf
      .ref(branchPath + node)
      .orderByChild("studentId")
      .equalTo(id);
  else leaveRef = rsf.ref(branchPath + node);
  var promise = new Promise(function (resolve, reject) {
    leaveRef.once("value").then(function (snapshot) {
      snapshot.forEach((ele) => {
        if (ele.val() !== null) {
          let leave = ele.val();
          if (leave.status.toLowerCase() == "pending" || leave.status.toLowerCase() == "approved") {
            if (
              moment(leave.startDate).isBetween(startDateOfMonth, endDateOfMonth, "day", "[]") ||
              moment(leave.endDate).isBetween(startDateOfMonth, endDateOfMonth, "day", "[]") ||
              (leave.startDate < startDateOfMonth && leave.endDate > endDateOfMonth)
            )
              leavesOfSelectedMonth.push(leave);
          }
        }
      });

      resolve(leavesOfSelectedMonth);
    });
  });
  return promise;
}

export const StaffAttendanceApi = {
  getStaffAttendance,
  createUniqueNodeInStaffCheckInOutReference,
  markPresentToCheckInOutReference,
  markPresentToTeacherAttendanceUpdates,
  addRecordToStaffAttendance,
  updateCheckOutTimeInStaffCheckInOutReference,
  updateCheckOutTimeInTeacherAttendanceUpdate,
  updateCheckOutTimeToStaffAttendance,
  removeStaffRecordFromStaffCheckInOutReference,
  updateRecordToTeacherAttendanceUpdates,
  updateRecordToStaffAttendance,
  getStaffByClassName,
  getStaffAttendanceByClassName,
  getStaffAttendanceByMonth,
  getStaffAttendanceById,
  markStaffPending,
  updateTeacherLastAtd,
  updateTeacherLastAtdCheckout,
  getNewStaffAttendance,
  getStaffNewAttendanceByMonth,
  getStaffLeavesOfMonth,
};
