import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ClassroomApi } from "../../firestore-api/classroom";
import { TeacherApi } from "../../firestore-api/teacher";
import bugsnagClient from "@bugsnag/js";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import { callApi } from "../../Utility/superAgentUntil";
function* listClassrooms({ firebase }) {
  let fullClassroomData = JSON.parse(getItem("classList"));

  yield put({
    type: actions.LIST_CLASSROOM,
    classrooms: fullClassroomData,
    classChannel: undefined,
  });

  // let fullClassroomData = [];
  // const chan = yield call(ClassroomApi.getAllClassrooms, firebase);
  // try {
  //   while (true) {
  //     let data = yield take(chan);
  //     fullClassroomData = data;
  //     fullClassroomData.sort((a, b) => a.className.localeCompare(b.className));
  //     yield put({
  //       type: actions.LIST_CLASSROOM,
  //       classrooms: fullClassroomData,
  //       classChannel: chan
  //     });
  //   }
  // } finally {
  //   console.log("close channel");
  // }
}

function* addClassroom({ name, firebase, values }) {
  try {
    let ageRange = {
      maxMonth: values.maxAgeMonth ? Number(values.maxAgeMonth) : 0,
      maxYear: values.maxAgeYear ? Number(values.maxAgeYear) : 0,
      minMonth: values.minAgeMonth ? Number(values.minAgeMonth) : 0,
      minYear: values.minAgeYear ? Number(values.minAgeYear) : 0,
    };
    let requestPayload = {
      ageRange,
      className: name,
      strength: values.capacity ? Number(values.capacity) : null,
      ageRange: ageRange,
      createdById: firebase.teacher.id
    }
    let endPointUrl =
      "woodlandApi/classrooms?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, requestPayload);
    if (response.status == 200) {

      yield put({
        type: actions.ADD_CLASSROOM_COMPLETED,
        operationType: "ADD_CLASSROOM",
      });
    }

  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    console.log("addClassroom -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

export function* updateClassroom({ updatedName, classroomId, firebase }) {
  try {
    yield call(ClassroomApi.updateExistingClassroom, updatedName, classroomId, firebase);
    yield put({
      type: actions.UPDATE_CLASSROOM_SUCCESSFUL,
      operationType: "UPDATE_CLASSROOM",
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    console.log("updateClassroom -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

function* deleteClass({ record, firebase }) {
  try {
    yield call(ClassroomApi.deleteClassroom, record, firebase);
    yield put({
      type: actions.DELETE_CLASS_SUCCESS,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    console.log("deleteClassroom -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
      errorInfo: error.message ? error.message : error,
    });
  }
}

function* addClassToAllTeachers({ values, firebase }) {
  try {
    let requestPayload = {
      className: values.name,
      strength: values.capacity ? Number(values.capacity) : null,
      createdById: firebase.teacher.id
    }
    let endPointUrl =
      "woodlandApi/addClassroomToAllTeachers?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, requestPayload);
    if (response && response.status == 200) {
      yield put({
        type: actions.ADD_CLASS_TO_TEACHERS_SUCCESSFUL,
      });
    }
  } catch (err) {
    console.log("failed to add class to all the teachers", err);
    bugsnagClient.notify(err);
    console.log("addClassToAllTeachers -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* renameClassroom({ firebase, classroom, newName }) {
  try {
    yield call(ClassroomApi.renameClassoom, firebase, classroom, newName);
  } catch (err) {
    console.log("failed to rename the classroom", err);
    bugsnagClient.notify(err);
    console.log("renameClassroom -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* deleteClassroom({
  firebase,
  classroomToDelete,
  classroomToTransferStudent,
  classroomToTransferTeachers,
}) {
  try {
    yield call(
      ClassroomApi.deleteClassroomWithTransfer,
      firebase,
      classroomToDelete,
      classroomToTransferStudent,
      classroomToTransferTeachers
    );
  } catch (err) {
    console.log("failed to Delete classroom", err);
    bugsnagClient.notify(err);
    console.log("deleteClassroom -------->>>>>>");
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.LIST_CLASSROOMS, listClassrooms),
    yield takeEvery(actions.ADD_CLASSROOM, addClassroom),
    yield takeEvery(actions.UPDATE_CLASSROOM, updateClassroom),
    yield takeEvery(actions.DELETE_CLASS, deleteClass),
    yield takeEvery(actions.ADD_CLASS_TO_TEACHERS, addClassToAllTeachers),
    yield takeEvery(actions.RENAME_CLASSROOM, renameClassroom),
    yield takeEvery(actions.DELETE_CLASSROOM_WITH_TRASFER, deleteClassroom),
  ]);
}
