import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore";
import { delay } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "../../Utility/superAgentUntil";

function* fetchPermissionList({ firebase }) {
  let permissionList = copyMap(firebase.permissionList);
  let permissionRoles = firebase.permissionRoles;
  let permissionTypes = firebase.permissionTypes;
  yield delay(600);
  yield put({
    type: actions.GET_PERMISSIONS_SUCCESS,
    permissionList: permissionList,
    permissionRoles: permissionRoles,
    permissionTypes: permissionTypes,
    permissionListChan: undefined,
  });
}

function copyMap(originalMap) {
  const newMap = new Map();

  originalMap.forEach((value, key) => {
    let copiedValue;
    
    if (typeof value === 'object' && value !== null) {
      copiedValue = JSON.parse(JSON.stringify(value));
    } else {
      copiedValue = value;
    }

    newMap.set(key, copiedValue);
  });

  return newMap;
}

function* addRole({ permissions, newRole, firebase }) {
  try {
    let endpoint = "woodlandApi/role?centerId=" + firebase.sbDbName;
    let obj = {
      permissions: permissions,
      newRole: newRole ? newRole : null,
    }
    let response = yield call(callApi, firebase, "post", endpoint, obj);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.ADD_ROLE_SUCCESS
      });
    } else {
      yield put({
        type: actions.PERMISSION_REQUEST_FAIL,
        errorMessage:
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to add role",
      });
    }
  } catch (err) {
    console.log("failed to add role", err);
    yield put({
      type: actions.PERMISSION_REQUEST_FAIL,
      errorMessage: err.message ? err.message : "Failed to add role",
    });
    bugsnagClient.notify(err);
  }
}

function* deleteRole({ permissions, roleToBeDeleted, firebase }) {
  try {
    let endpoint = "woodlandApi/role?centerId=" + firebase.sbDbName;
    let obj = {
      permissions: permissions,
      roleToBeDeleted: roleToBeDeleted ? roleToBeDeleted : null,
    }
    let response = yield call(callApi, firebase, "delete", endpoint, obj);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_ROLE_SUCCESS
      });
    } else {
      yield put({
        type: actions.PERMISSION_REQUEST_FAIL,
        errorMessage:
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to delete role",
      });
    }
  } catch (err) {
    console.log("failed to delete role", err);
    yield put({
      type: actions.PERMISSION_REQUEST_FAIL,
      errorMessage: err.message ? err.message : "Failed to delete role",
    });
    bugsnagClient.notify(err);
  }
}

function* updatePermissions({ permissions, firebase }) {
  try {
    let endpoint = "woodlandApi/updatePermissions?centerId=" + firebase.sbDbName;
    let obj = {
      permissions: permissions,
    }
    let response = yield call(callApi, firebase, "post", endpoint, obj);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.UPDATE_PERMISSIONS_SUCCESS
      });
    } else {
      yield put({
        type: actions.PERMISSION_REQUEST_FAIL,
        errorMessage:
          response && response.body && response.body.response
            ? response.body.response
            : "Failed update permissions",
      });
    }
  } catch (err) {
    console.log("Failed update permissions", err);
    yield put({
      type: actions.PERMISSION_REQUEST_FAIL,
      errorMessage: err.message ? err.message : "Failed update permissions",
    });
    bugsnagClient.notify(err);
  }
}

function* tranferRole({ firebase, roleToDelete, roleToTransfer, permissions}) {
  try {
    let endpoint = "woodlandApi/transferRole?centerId=" + firebase.sbDbName;
    let obj = {
      role: roleToDelete,
      newRole: roleToTransfer,
      permissions
    }
    let response = yield call(callApi, firebase, "post", endpoint, obj);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.TRANSFER_STAFF_ROLE_SUCCESS,
      });
    } else {
      yield put({
        type: actions.TRANSFER_STAFF_ROLE_FAIL,
        errorMessage:
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to transfer role",
      });
    }
  } catch (err) {
    console.log("Failed to transfer role", err);
    yield put({
      type: actions.TRANSFER_STAFF_ROLE_FAIL,
      errorMessage: err.message ? err.message : "Failed to transfer role",
    });
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_PERMISSIONS, fetchPermissionList),
    yield takeLatest(actions.ADD_ROLE, addRole),
    yield takeLatest(actions.TRANSFER_STAFF_ROLE, tranferRole),
    yield takeLatest(actions.DELETE_ROLE, deleteRole),
    yield takeLatest(actions.UPDATE_PERMISSIONS, updatePermissions),
  ]);
}
