import actions from "./actions";
const initState = {
  invoice: [],
  singleInvoiceChannel: undefined,
  invoiceTemplates: [],
  aggregatedInvoice: [],
  studentData: [],
  invoiceDownloadUrl: {},
  isLoading: false,
  isInvoiceLoading: false,
  error: false,
  invoiceError: false,
  operationType: "",
  receiptList: [],
  studentAggregatedInvoice: [],
  studentAggregatedInvoiceChan: undefined,
  creditHistory: [],
  totalCreditHistory: [],
  studentOnlineClassInvoice: [],
  transactionId: undefined,
  feePlanDetail: undefined,
  savedCardDetail: undefined,
  studentFeePlanMap: new Map(),
  feePlanLoading: false,
  taxReference: [],
  creditPdf: undefined,
  invoicePrograms: [],
  totalDepositHistory: [],
  depositHistory: [],
  creditMap: new Map(),
  depositPdf: undefined,
  updatedEmail: {},
  defaultEmail: {},
  paymentMethods: [],
  upcomingInvoice: [],
  creditChannel: undefined,
  depositChannel: undefined,
  depositAmount: 0,
  creditAmount: 0,
  invoiceDashboardLoading: false,
  totalBilled: 0,
  totalCollected: 0,
  totalOverdue: 0,
  totalOutstanding: 0,
  createdToday: 0,
  createdTodayAmount: 0,
  monthOnMonth: {},
  ageingBucketRaw: {},
  monthOnMonthRaw: {},
  ageingBucket: {},
  recentTransactions: [],
  recentDue: [],
  totalBilledAutomationCount: [],
  prevFrequency: undefined,
  errorMessage: undefined,
  paymentReceiptList: [],
  paymentTableSpinner: false
};
export default function invoiceReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.IGNORE_LATE_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.IGNORE_LATE_FEE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.IGNORE_LATE_FEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "LATE_FEE_IGNORED",
      };

    case actions.EDIT_FEE_REMINDER:
      return {
        ...state,
        updatedEmail: undefined,
        defaultEmail: undefined,
      };

    case actions.EDIT_FEE_REMINDER_SUCCESS:
      return {
        ...state,
        updatedEmail: action.updatedEmail,
        defaultEmail: action.defaultEmail,
      };

    case actions.UPDATE_FEE_REMINDER:
      return {
        ...state,
      };

    case actions.LIST_INVOICE:
      return {
        ...state,
        isLoading: true,
        operationType: "",
        invoiceDownloadUrl: {},
        isInvoiceLoading: true,
      };
    case actions.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentMethods: action.paymentMethods,
        isInvoiceLoading: false,
      };
    case actions.LIST_INVOICE_SUCCESSFULL:
      return {
        ...state,
        invoice: action.invoice,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "",
        invoiceDownloadUrl: {},
        singleInvoiceChannel: action.singleInvoiceChannel ? action.singleInvoiceChannel : undefined
      };
    case actions.RESET_UPCOMMING_INVOICE:
      return {
        ...state,
        upcomingInvoice: [],
      };
    case actions.GET_INVOICE_AGGREGATED:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_INVOICE_AGGREGATED_SUCCESSFULL:
      return {
        ...state,
        aggregatedInvoice: action.aggregatedInvoice,
        creditMap: action.creditMap,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "",
      };
    case actions.DELETE_CREDIT_DEPOSIT_SUCCESS:
      return {
        ...state,
        operationType: action.data,
        isLoading: false,
        isInvoiceLoading: false,
      };

    case actions.GET_INVOICE_TEMPLATES:
      return {
        ...state,
        invoiceTemplates: [],
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_INVOICE_TEMPLATES_SUCCESSFUL:
      return {
        ...state,
        invoiceTemplates: action.invoiceTemplates,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "",
      };

    case actions.GET_INVOICE_DOWNLOAD_URL:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        invoiceDownloadUrl: {},
        operationType: "",
      };
    case actions.GET_INVOICE_DOWNLOAD_URL_SUCCESSFUL:
      return {
        ...state,
        invoiceDownloadUrl: action.invoiceDownloadUrl,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DOWNLOAD_URL",
      };

    case actions.GET_STUDENT_FOR_INVOICE:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.GET_STUDENT_FOR_INVOICE_SUCCESSFUL:
      return {
        ...state,
        studentData: action.studentData,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "",
      };

    case actions.GET_PROGRAMS_FOR_INVOICE_SUCCESSFUL:
      return {
        ...state,
        invoicePrograms: action.invoicePrograms,
      };

    case actions.ADD_NEW_INVOICE:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.ADD_NEW_INVOICE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "ADD_INVOICE",
      };

    case actions.UPDATE_SELECTED_INVOICE:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.UPDATE_SELECTED_INVOICE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "UPDATE_INVOICE",
      };

    case actions.RECEIVE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.RECEIVE_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "RECEIVE_PAYMENT",
      };

    case actions.DELETE_INVOICE:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.DELETE_INVOICE_SUCCESSFFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DELETE_INVOICE",
      };

    case actions.INVOICE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        error: true,
        invoiceError: true,
      };

    case actions.RESET_INVOICE_URL:
      return {
        ...state,
        operationType: undefined,
        invoiceDownloadUrl: {},
        error: false,
        isLoading: false,
        isInvoiceLoading: false,
        feePlanLoading: false,
        invoiceError: false,
      };

    case actions.DOWNLOAD_INVOICE_EXCEL_SHEET:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.DOWNLOAD_INVOICE_EXCEL_SHEET_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };

    case actions.SEND_PAYMENT_REMINDER:
      return {
        ...state,
      };

    case actions.SEND_PAYMENT_REMINDER_SUCCESSFUL:
      return {
        ...state,
        operationType: "PAYMENT_REMINDER",
      };


    case actions.DELETE_PAYMENT_RECORD:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.DELETE_PAYMENT_RECORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DELETE_SINGLE_RECORD",
      };

    case actions.REFUND_AMT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.REFUND_AMT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "REFUND_PAYMENT",
      };

    case actions.GET_RECEIPT_LIST:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
        receiptList: [],
      };
    case actions.GET_RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        receiptList: action.receiptList,
      };

    case actions.GET_PAYMENTS_DATA:
      return {
        ...state,
        paymentTableSpinner: true,
        paymentReceiptList: [],
      };
    case actions.GET_PAYMENTS_DATA_SUCCESS:
      return {
        ...state,
        paymentTableSpinner: false,
        paymentReceiptList: action.paymentReceiptList,
      };

    case actions.GET_STUDENT_AGGREAGTED_INVOICES:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_STUDENT_AGGREAGTED_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        studentAggregatedInvoice: action.studentAggregatedInvoice,
        studentAggregatedInvoiceChan: action.studentAggregatedInvoiceChan,
        operationType: action.operationType,
        creditMap: action.creditMap,
      };

    case actions.GET_SINGLE_STUDENT_INVOICES:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: undefined,
        invoiceDownloadUrl: {},
        invoice: [],
      };
    case actions.GET_UPCOMMING_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        upcomingInvoice: [...state.upcomingInvoice, ...action.data],
      };

    case actions.GET_SINGLE_STUDENT_INVOICES_SUCCESS:
      return {
        ...state,
        invoice: action.invoice,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: undefined,
        invoiceDownloadUrl: {},
      };



    case actions.REMOVE_REFUND:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.REMOVE_REFUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "REFUND_REMOVED",
      };

    case actions.REMOVE_ALL_REFUND:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.REMOVE_ALL_REFUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "REFUND_REMOVED",
      };

    case actions.SAVE_CREDIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.SAVE_CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "CREDIT_UPDATE",
      };
    case actions.SAVE_CREDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false
      }

    case actions.GET_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        creditHistory: action.creditHistory,
        creditChannel: action.chan,
        creditAmount: action.creditAmount
      };


    case actions.GET_ALL_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_ALL_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        totalCreditHistory: action.totalCreditHistory,
      };

    case actions.DOWNLOAD_ALL_CREDIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.DOWNLOAD_ALL_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };
    case actions.DOWNLOAD_ALL_CREDIT_HISTORY_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };
    case actions.DELETE_CREDIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.DELETE_CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "CREDIT_DELETE",
      };
    case actions.DELETE_CREDIT_DEPOSIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      }
    case actions.DELETE_CREDIT_DENIED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };

    case actions.GET_STUDENT_ONLINE_CLASS_INVOICE:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
        invoiceDownloadUrl: {},
      };
    case actions.GET_STUDENT_ONLINE_CLASS_INVOICE_SUCCESS:
      return {
        ...state,
        studentOnlineClassInvoice: action.studentOnlineClassInvoice,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "",
        invoiceDownloadUrl: {},
      };

    case actions.GENERATE_PAYMENT_TRANSACTION_ID:
      return {
        ...state,
        transactionId: undefined,
      };

    case actions.GENERATE_PAYMENT_TRANSACTION_ID_SUCCESS:
      return {
        ...state,
        transactionId: action.transactionId,
      };

    case actions.SETTLE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.SETTLE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "PAYMENT_SUCCESS",
      };

    case actions.UPDATED_FAILED_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.UPDATED_FAILED_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "PAYMENT_FAILED",
      };

    case actions.GET_ONLINE_CLASS_PLAN_DETAIL:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        feePlanDetail: undefined,
      };

    case actions.GET_ONLINE_CLASS_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        feePlanDetail: action.feePlanDetail,
      };

    case actions.SAVE_CARD_DETAILS:
      return {
        ...state,
      };

    case actions.FETCH_CARD_DETAILS:
      return {
        ...state,
        savedCardDetail: undefined,
      };

    case actions.FETCH_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        savedCardDetail: action.savedCardDetail,
      };

    case actions.AUTO_CHARGE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.AUTO_CHARGE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "RECEIVE_PAYMENT",
      };

    case actions.GET_STUDENTS_FEE_PLAN:
      return {
        ...state,
        feePlanLoading: true,
      };

    case actions.GET_STUDENTS_FEE_PLAN_SUCCESS:
      return {
        ...state,
        feePlanLoading: false,
        studentFeePlanMap: action.studentFeePlanMap,
      };

    case actions.GET_TAX_REF_SUCCESS:
      return {
        ...state,
        taxReference: action.taxReference,
      };

    case actions.REFRESH_PDF:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.REFRESH_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "REFRESH_PDF",
      };

    case actions.GET_CREDIT_PDF:
      return {
        ...state,
        // isLoading: true,
        creditPdf: undefined,
      };

    case actions.GET_CREDIT_PDF_SUCCESS:
      return {
        ...state,
        // isLoading: false,
        operationType: "CREDIT_PDF",
        creditPdf: action.creditPdf,
      };

    case actions.GET_DEPOSIT_PDF:
      return {
        ...state,
        creditPdf: undefined,
      };

    case actions.GET_DEPOSIT_PDF_SUCCESS:
      return {
        ...state,
        operationType: "DEPOSIT_PDF",
        depositPdf: action.depositPdf,
      };

    case actions.SAVE_DEPOSIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
        operationType: "",
      };
    case actions.SAVE_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DEPOSIT_UPDATE",
      };
    case actions.SAVE_DEPOSIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      }
    case actions.GET_ALL_DEPOSIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_ALL_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        totalDepositHistory: action.totalDepositHistory,
      };

    case actions.DELETE_DEPOSIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.DELETE_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DEPOSIT_DELETE",
      };
    case actions.DELETE_CREDIT_DEPOSIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      }
    case actions.DELETE_DEPOSIT_DENIED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };

    case actions.DOWNLOAD_ALL_DEPOSIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.DOWNLOAD_ALL_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };
    case actions.DOWNLOAD_ALL_DEPOSIT_HISTORY_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      };

    case actions.GET_DEPOSIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.GET_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        depositHistory: action.depositHistory,
        depositChannel: action.chan,
        depositAmount: action.depositAmount
      };

    case actions.REFUND_DEPOSIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.REFUND_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DEPOSIT_REFUND",
      };

    case actions.DEPOSIT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "DEPOSIT_REQUEST_FAILED",
      };

    case actions.TRANSFER_CREDIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.TRANSFER_CREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "TRANSFER_CREDIT",
      };

    case actions.SETTLE_INVOICE_WITH_DEPOSIT:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };
    case actions.SETTLE_INVOICE_WITH_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "SETTLE_INV_DEPOSIT",
      };

    case actions.SEND_PAYMENT_REMINDER_TO_ALL:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.SEND_PAYMENT_REMINDER_TO_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "PAYMENT_REMINDER",
      };

    case actions.SAVE_INVOICE_NOTES:
      return {
        ...state,
        isLoading: true,
        isInvoiceLoading: true,
      };

    case actions.SAVE_INVOICE_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
        operationType: "NOTE_SAVE",
      };

    case actions.GET_INV_FORM_DATA:
      return {
        ...state,
        isLoading: true,
        operationType: "",
        invoiceDownloadUrl: {},
        isInvoiceLoading: true,
        invoice: [],
      };

    case actions.RESEND_INVOICE_EMAIL:
      return {
        ...state,
      };

    case actions.RESEND_INVOICE_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case actions.TRANSFER_CREDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isInvoiceLoading: false,
      }

    case actions.GET_NEW_INVOICE_DASHBOARD_STATS:
      return {
        ...state,
      };

    case actions.START_INVOICE_DASHBOARD_SPINNER:
      return {
        ...state,
        invoiceDashboardLoading: true,
      };

    case actions.STOP_INVOICE_DASHBOARD_SPINNER:
      return {
        ...state,
        invoiceDashboardLoading: false,
      };

    case actions.START_INVOICE_DASHBOARD_SPINNER:
      return {
        ...state,
        invoiceDashboardLoading: true,
      };

    case actions.NEW_DASHBOARD_TOTAL_INVOICE:
      return {
        ...state,
        totalBilled: action.totalBilled,
      };

    case actions.NEW_DASHBOARD_TOTAL_COLLECTED_COUNT:
      return {
        ...state,
        totalCollected: action.totalCollected,
      };

    case actions.NEW_DASHBOARD_TOTAL_OVERDUE_COUNT:
      return {
        ...state,
        totalOverdue: action.totalOverdue,
      };

    case actions.NEW_DASHBOARD_TOTAL_OUTSTANDING_COUNT:
      return {
        ...state,
        totalOutstanding: action.totalOutstanding,
      };

    case actions.NEW_DASHBOARD_CREATED_TODAY:
      return {
        ...state,
        createdToday: action.createdToday,
        createdTodayAmount: action.createdTodayAmount,
      };

    case actions.NEW_DASHBOARD_MONTH_ON_MONTH:
      return {
        ...state,
        monthOnMonth: action.monthOnMonth,
        monthOnMonthRaw: action.monthOnMonthRaw,
      };

    case actions.NEW_DASHBOARD_AGEING_REPORT:
      return {
        ...state,
        ageingBucket: action.ageingBucket,
        ageingBucketRaw: action.ageingBucketRaw,
      };

    case actions.NEW_DASHBOARD_RECENT_TRANSACTIONS:
      return {
        ...state,
        recentTransactions: action.recentTransactions,
      };

    case actions.NEW_DASHBOARD_RECENT_DUE:
      return {
        ...state,
        recentDue: action.recentDue,
      };

    case actions.RESET_INVOICE_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        error: false,
        operationType: undefined,
        prevFrequency: undefined,
        errorMessage: undefined,
      };

    default:
      return state;
  }
}
