import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import FilterPermission from "../Utility/FilterPermission";
import PermissionStrings from "../Utility/PermissionStrings";
import UserImageAction from "../Utility/userImageAction";

function getAllComplains(firebase, startTime, endTime, selectedFrequency) {
  let start = moment(startTime).startOf("day").valueOf();
  let end = moment(endTime).endOf("day").valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let comRef;
  if (selectedFrequency === "Pending") {
    comRef = rsf
      .ref(branchPath + "/complaints")
      .orderByChild("status")
      .equalTo("pending");
  } else {
    comRef = rsf
      .ref(branchPath + "/complaints")
      .orderByChild("appliedDate")
      .startAt(start)
      .endAt(end);
  }

  return eventChannel((emit) => {
    comRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        }
      });
      emit(x);
    });
    return () => {
      comRef.off();
      console.log("unsubscribe complaints");
    };
  });
}

function leaveFilteredData(element, firebase) {
  let x = [];

  let classList =
    firebase && firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
  if (firebase.teacher && !firebase.teacher.superUser) {
    if (classList.length > 0) {
      if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
        x.push(element.val());
      }
    } else {
      x.push(element.val());
    }
  } else {
    x.push(element.val());
  }
  return x;
}

function getAllLeaves(firebase, startTime, endTime, selectedFrequency, dayLevelFilter) {
  let start = moment(startTime).startOf("day").valueOf();
  let end = moment(endTime).endOf("day").valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let comRef;
  if (selectedFrequency === "Pending") {
    comRef = rsf
      .ref(branchPath + "/leaves/")
      .orderByChild("status")
      .equalTo("pending");
  } else {
    comRef = rsf
      .ref(branchPath + "/leaves/")
      .orderByChild("endDate")
      .startAt(start);
  }

  return eventChannel((emit) => {
    comRef.on("value", (snap) => {
      var x = [];
      let leaveData = [];
      snap.forEach((element) => {
        if (selectedFrequency === "Pending") {
          if (element.val() !== null && firebase.studentsMap.has(element.val().studentId)) {
            x = [...x, ...leaveFilteredData(element, firebase)];
          }
        } else {
          if (element.val() !== null && element.val().startDate < end && firebase.studentsMap.has(element.val().studentId)) {
            x = [...x, ...leaveFilteredData(element, firebase)];
          }
        }
      });

      if (dayLevelFilter) {
        for (let index in x) {
          let singleLeave = x[index];

          if (
            moment(start).isSame(moment(singleLeave.startDate), "day") ||
            moment(start).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            leaveData.push(singleLeave);
          }
        }
      } else {
        leaveData = x;
      }

      emit(leaveData);
    });
    return () => {
      comRef.off();
      console.log("unsubscribe leaves");
    };
  });
}

function getAllPendingLeaves(firebase, startDate, endDate, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let comRef = rsf
    .ref(branchPath + "/leaves")
    .orderByChild("endDate")
    .startAt(startDate);
  let leaveData = [];
  let promise = new Promise(function (resolve, reject) {
    comRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().status.toLowerCase() !== "denied" &&
          element.val().startDate < endDate &&
          firebase.studentsMap.has(element.val().studentId)
        ) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                leaveData.push(element.val());
              }
            } else {
              leaveData.push(element.val());
            }
          } else {
            leaveData.push(element.val());
          }
        }
      });
      resolve(leaveData);
    });
  });
  return promise;
}

function getAllParentNotes(firebase, startTime, endTime, selectedFrequency) {
  let start = moment(startTime).startOf("day").valueOf();
  let end = moment(endTime).endOf("day").valueOf();

  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let comRef;
  if (selectedFrequency === "Pending") {
    comRef = rsf
      .ref(branchPath + "/noteReference/")
      .orderByChild("status")
      .equalTo("pending");
  } else {
    comRef = rsf
      .ref(branchPath + "/noteReference/")
      .orderByChild("appliedDate")
      .startAt(start)
      .endAt(end);
  }

  return eventChannel((emit) => {
    comRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                x.push(element.val());
              }
            } else {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        }
      });
      emit(x);
    });
    return () => {
      rsf.ref(branchPath + "/noteReference").off();
      console.log("unsubscribe notes");
    };
  });
}

function getComment(recordId, firebase) {
  let comments = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/comments/" + recordId).orderByChild("inverseTimestamp");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          comments.push(element.val());
        }
      });
      resolve(comments);
    });
  });
  return promise1;
}

function updateComplaint(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/complaints/" + record.id).update(record);
}

function updateLeave(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/leaves/" + record.id).update(record);
}

function updateNote(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/noteReference/" + record.id).update(record);
}

function getPendingQueries(firebase, querType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/" + querType);
  let value = [];

  var promise1 = new Promise(function (resolve, reject) {
    myRef
      .orderByChild("status")
      .equalTo("pending")
      .once("value")
      .then(function (snap) {
        snap.forEach((element) => {
          if (element !== null) {
            let classList =
              firebase && firebase.teacher && firebase.teacher.classList
                ? firebase.teacher.classList
                : [];
            if (firebase.teacher && !firebase.teacher.superUser) {
              if (classList.length > 0) {
                if (querType === "complaints") {
                  if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                    let newValue = checkHierarchy(element.val(), firebase);
                    if (newValue) {
                      value.push(element.val());
                    }
                  }
                } else {
                  if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                    value.push(element.val());
                  }
                }
              } else {
                if (querType === "complaints") {
                  let newValue = checkHierarchy(element.val(), firebase);
                  if (newValue) {
                    value.push(element.val());
                  }
                } else {
                  value.push(element.val());
                }
              }
            } else {
              if (querType === "complaints") {
                let newValue = checkHierarchy(element.val(), firebase);
                if (newValue) {
                  value.push(element.val());
                }
              } else {
                value.push(element.val());
              }
            }
          }
        });
        resolve(value);
      });
  });
  return promise1;
}

function checkHierarchy(value, firebase) {
  let teacher = firebase.teacher;
  if (
    teacher &&
    teacher.role.toLowerCase() === "teacher" &&
    value.consultTo &&
    value.consultTo.toLowerCase() === "teacher"
  ) {
    return value;
  } else if (
    teacher &&
    teacher.role.toLowerCase() === "center head" &&
    value.consultTo &&
    (value.consultTo.toLowerCase() === "teacher" || value.consultTo.toLowerCase() === "center head")
  ) {
    return value;
  } else if (teacher && teacher.role.toLowerCase() === "administrator" && value.consultTo) {
    return value;
  } else if (teacher && teacher.role.toLowerCase() === "principal" && value.consultTo) {
    return value;
  } else if (
    teacher &&
    FilterPermission.checkIfPermission(PermissionStrings.CONCERN_VIEW, firebase)
  ) {
    return value;
  } else {
    return undefined;
  }
}

function getUnreadParentMsgCounter(firebase) {
  let teacherId = firebase.teacher.id;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let count = 0;

  const countRef = rsf.ref(branchPath + "/totalUnread/" + teacherId);

  var promise1 = new Promise(function (resolve, reject) {
    countRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        count = snap.val();
      }
      resolve(count);
    });
  });
  return promise1;
}

function getComplaintById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/complaints/" + id);
  return eventChannel((emit) => {
    myRef.on("value", (element) => {
      var x = [];
      // snap.forEach(element => {
      if (element.val() !== null) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        } else {
          x.push(element.val());
        }
      }
      // });
      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe complaints");
    };
  });
}

function getLeaveById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let comRef = rsf.ref(branchPath + "/leaves/" + id);

  return eventChannel((emit) => {
    comRef.on("value", (element) => {
      var x = [];
      // snap.forEach(element => {
      if (element.val() !== null) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        } else {
          x.push(element.val());
        }
      }
      // });
      emit(x);
    });
    return () => {
      comRef.off();
      console.log("unsubscribe leaves");
    };
  });
}

function getParentNoteById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let comRef = rsf.ref(branchPath + "/noteReference/" + id);

  return eventChannel((emit) => {
    comRef.on("value", (element) => {
      var x = [];
      // snap.forEach(element => {
      if (element.val() !== null) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
              x.push(element.val());
            }
          } else {
            x.push(element.val());
          }
        } else {
          x.push(element.val());
        }
      }
      // });
      emit(x);
    });
    return () => {
      comRef.off();
      console.log("unsubscribe notes");
    };
  });
}

function getAllPendingStaffLeaves(firebase, startDate, endDate, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let comRef = rsf
    .ref(branchPath + "/staffLeaveReference/")
    .orderByChild("endDate")
    .startAt(startDate);

  let leaveData = [];
  let promise = new Promise(function (resolve, reject) {
    comRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().status.toLowerCase() !== "denied" &&
          element.val().startDate < endDate &&
          firebase.teachersMap[element.val().studentId]
        ) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            for (let i in classList) {
              if (element.val().classlist && element.val().classlist.includes(classList[i])) {
                leaveData.push(element.val());
                break;
              }
            }
          } else {
            leaveData.push(element.val());
          }
        }
      });
      resolve(leaveData);
    });
  });
  return promise;
}

function getStaffFilteredLeave(element, firebase) {
  let x = [];
  let classList =
    firebase && firebase.teacher && firebase.teacher.classList ? firebase.teacher.classList : [];
  if (firebase.teacher && !firebase.teacher.superUser) {
    if (classList.length > 0) {
      for (let i in classList) {
        if (element.val().classlist && element.val().classlist.includes(classList[i])) {
          x.push(element.val());
          break;
        }
      }
    } else {
      x.push(element.val());
    }
  } else {
    x.push(element.val());
  }

  return x;
}

function getAllStaffLeaves(firebase, startTime, endTime, selectedFrequency, dayLevelFilter) {
  let start = moment(startTime).startOf("day").valueOf();
  let end = moment(endTime).endOf("day").valueOf();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let comRef;
  if (selectedFrequency === "Lifetime") {
    comRef = rsf
      .ref(branchPath + "/staffLeaveReference/")
      .orderByChild("endDate");

  }
  else {
    comRef = rsf
      .ref(branchPath + "/staffLeaveReference/")
      .orderByChild("endDate")
      .startAt(start);
  }

  return eventChannel((emit) => {
    comRef.on("value", (snap) => {
      var x = [];
      let leaveData = [];
      snap.forEach((element) => {
        if (selectedFrequency === "Lifetime" && element.val() !== null && firebase.teachersMap[element.val().studentId]) {
          x = [...x, ...getStaffFilteredLeave(element, firebase)];
        } else {
          if (element.val() !== null && element.val().startDate < end && firebase.teachersMap[element.val().studentId]) {
            x = [...x, ...getStaffFilteredLeave(element, firebase)];
          }

        }
      });

      if (dayLevelFilter) {
        for (let index in x) {
          let singleLeave = x[index];

          if (
            moment(start).isSame(moment(singleLeave.startDate), "day") ||
            moment(start).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            leaveData.push(singleLeave);
          }
        }
      } else {
        leaveData = x;
      }

      emit(leaveData);
    });
    return () => {
      comRef.off();
      console.log("unsubscribe staff leaves");
    };
  });
}

function updateStaffLeave(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/staffLeaveReference/" + record.id).update(record);
}

function getCurrentDateStudentLeave(firebase, startDate, endDate, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let comRef = rsf
    .ref(branchPath + "/leaves")
    .orderByChild("endDate")
    .startAt(startDate);
  let leaveData = [];
  let currentDayLeave = [];
  let promise = new Promise(function (resolve, reject) {
    comRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null && element.val().startDate < endDate && firebase.studentsMap.has(element.val().studentId)) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            if (classList.length > 0) {
              if (UserImageAction.ifClassAccess(classList, element.val().studentId, firebase)) {
                leaveData.push(element.val());
              }
            } else {
              leaveData.push(element.val());
            }
          } else {
            leaveData.push(element.val());
          }
        }
      });

      for (let index in leaveData) {
        let singleLeave = leaveData[index];
        if (singleLeave.status.toLowerCase() !== "denied") {
          if (
            moment(startDate).isSame(moment(singleLeave.startDate), "day") ||
            moment(startDate).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            currentDayLeave.push(singleLeave.studentId);
          }
        }
      }
      resolve(currentDayLeave);
    });
  });
  return promise;
}

function getCurrentDayStaffLeaves(firebase, startDate, endDate, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;

  let comRef = rsf
    .ref(branchPath + "/staffLeaveReference")
    .orderByChild("endDate")
    .startAt(startDate);
  let leaveData = [];
  let currentDayLeave = [];
  let promise = new Promise(function (resolve, reject) {
    comRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null && element.val().startDate < endDate && firebase.teachersMap[element.val().studentId]) {
          let classList =
            firebase && firebase.teacher && firebase.teacher.classList
              ? firebase.teacher.classList
              : [];
          if (firebase.teacher && !firebase.teacher.superUser) {
            for (let i in classList) {
              if (element.val().classlist && element.val().classlist.includes(classList[i])) {
                leaveData.push(element.val());
                break;
              }
            }
          } else {
            leaveData.push(element.val());
          }
        }
      });

      for (let index in leaveData) {
        let singleLeave = leaveData[index];
        if (singleLeave.status.toLowerCase() !== "denied") {
          if (
            moment(startDate).isSame(moment(singleLeave.startDate), "day") ||
            moment(startDate).isBetween(
              moment(singleLeave.startDate),
              moment(singleLeave.endTime),
              "day",
              "[]"
            )
          ) {
            currentDayLeave.push(singleLeave);
          }
        }
      }

      resolve(currentDayLeave);
    });
  });
  return promise;
}

function getStaffLeaveById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let leaveRef = rsf.ref(branchPath + "/staffLeaveReference/" + id);

  return eventChannel((emit) => {
    leaveRef.on("value", (element) => {
      var x = [];

      if (element.val() !== null) {
        let classList =
          firebase && firebase.teacher && firebase.teacher.classList
            ? firebase.teacher.classList
            : [];
        if (firebase.teacher && !firebase.teacher.superUser) {
          if (classList.length > 0) {
            for (let i in classList) {
              if (element.val().classlist && element.val().classlist.includes(classList[i])) {
                x.push(element.val());
                break;
              }
            }
          } else {
            x.push(element.val());
          }
        } else {
          x.push(element.val());
        }
      }

      emit(x);
    });
    return () => {
      leaveRef.off();
      console.log("unsubscribe staff leaves");
    };
  });
}

export const ComplainsApi = {
  getAllComplains,
  getAllLeaves,
  getAllParentNotes,
  getComment,
  updateComplaint,
  updateLeave,
  updateNote,
  getPendingQueries,
  getUnreadParentMsgCounter,
  getComplaintById,
  getLeaveById,
  getParentNoteById,
  getAllPendingLeaves,
  getAllPendingStaffLeaves,
  getAllStaffLeaves,
  updateStaffLeave,
  getCurrentDateStudentLeave,
  getCurrentDayStaffLeaves,
  getStaffLeaveById,
};
