import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import FilterAction from "../Utility/FilterAction";
import { UserSettingApi } from "./userSetting";

const superagent = require("superagent");
function getAllApplications(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let formRef = rsf
    .ref(branchPath + "/applications")
    .orderByChild("deleted")
    .equalTo(false);
  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      let forms = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          forms.push(element.val());
        }
      });
      emit(forms);
    });
    return () => {
      formRef.off();
    };
  });
}
function getTeacherApplications(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let formRef = rsf
    .ref(branchPath + "/teacherApplication")
    .orderByChild("deleted")
    .equalTo(false);
  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      let forms = [];
      snap.forEach((element) => {
        if (element.val() !== null && element.val().id) {
          forms.push(element.val());
        }
      });
      emit(forms);
    });
    return () => {
      formRef.off();
    };
  });
}

function downloadtStudentFormSubmission(id, firebase, entityType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let studentsMap = firebase.studentsMap;
  let shareNode = FilterAction.getShareRefByEntityType(entityType)
  let teachersMap = firebase.teachersMap
  let formRef = rsf
    .ref(branchPath + "/" + shareNode)
    .orderByChild("applicationId")
    .equalTo(id);
  let forms = [];
  let promise = new Promise(function (resolve, rejected) {
    formRef.once("value").then((snapshot) => {

      snapshot.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().id &&
          !element.val().deleted
        ) {
          if (entityType == "student" && studentsMap?.has(element?.val()?.studentId)) {
            forms.push(element.val());
          }

          if (entityType == "teacher" && teachersMap?.[element?.val()?.entityId]) {
            forms.push(element.val())
          }
        }
      });
      resolve(forms);
    });
  });


  return promise
}

function getStudentFormSubmission(id, firebase, entityType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let studentsMap = firebase.studentsMap;
  let shareNode = FilterAction.getShareRefByEntityType(entityType)
  let teachersMap = firebase.teachersMap
  let formRef = rsf
    .ref(branchPath + "/" + shareNode)
    .orderByChild("applicationId")
    .equalTo(id);
  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      let forms = [];
      snap.forEach((element) => {
        if (
          element.val() !== null &&
          element.val().id &&
          !element.val().deleted
        ) {
          if (entityType == "student" && studentsMap?.has(element?.val()?.studentId)) {
            forms.push(element.val());
          }

          if (entityType == "teacher" && teachersMap?.[element?.val()?.entityId]) {
            forms.push(element.val())
          }
        }
      })
      emit(forms)
    })
    return () => {
      formRef.off()
    }
  })
  // formRef.once("value").then((snapshot) => {
  //   snapshot.forEach((element) => {
  //     if (
  //       element.val() !== null &&
  //       element.val().id &&
  //       !element.val().deleted
  //     ) {
  //       if (entityType == "student" && studentsMap?.has(element?.val()?.studentId)) {
  //         forms.push(element.val());
  //       }

  //       if (entityType == "teacher" && teachersMap?.[element?.val()?.entityId]) {
  //         forms.push(element.val())
  //       }
  //     }
  //   });
  //   resolve(forms);
  // });
}
function getSharedApplications(entityId, firebase, entityType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let shareNode = FilterAction.getShareRefByEntityType(entityType)

  let formRef = rsf
    .ref(branchPath + "/" + shareNode)
    .orderByChild("entityId")
    .equalTo(entityId);

  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      let forms = [];
      snap.forEach((element) => {

        if (

          element.val() !== null &&
          element.val().id &&
          !element.val().deleted
        ) {
          forms.push(element.val())
        }
      });
      emit(forms);
    });
    return () => {
      formRef.off();
      console.log("unsubscribe shared forms");
    };
  });
}

function updateApplication(obj, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/applications/" + id).update(obj);
}

function updateStudentApplication(obj, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentApplicationShareRef/" + id).update(obj);
}

function getSingleStudentFormSubmission(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let formRef = rsf
    .ref(branchPath + "/studentApplicationShareRef")
    .orderByChild("studentId")
    .equalTo(id);

  let forms = [];

  let promise = new Promise(function (resolve, rejected) {
    formRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val() !== null && element.val().id && !element.val().deleted) {
          forms.push(element.val());
        }
      });
      resolve(forms);
    });
  });

  return promise;
}
function getSingleTeacherFormSubmission(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formRef = rsf
    .ref(branchPath + "/teacherApplicationShareRef")
    .orderByChild("entityId")
    .equalTo(id);

  let forms = [];
  let promise = new Promise(function (resolve, rejected) {
    formRef.once("value").then((snapshot) => {
      snapshot.forEach((element) => {
        if (element.val() !== null && element.val().id && !element.val().deleted) {
          forms.push(element.val());
        }
      });
      resolve(forms);
    });
  });

  return promise;
}

function getStudentFormSubmissionById(id, firebase, entityType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let sharedRef = FilterAction.getShareRefByEntityType(entityType)
  let formRef = rsf.ref(branchPath + "/" + sharedRef + "/" + id);

  let forms = [];

  let promise = new Promise(function (resolve, rejected) {
    formRef.once("value").then((snapshot) => {
      if (snapshot.val() && snapshot.val().id && !snapshot.val().deleted) {
        forms.push(snapshot.val());
      }

      resolve(forms);
    });
  });

  return promise;
}
function getFirebaseObjectOnCategory(
  values,
  formItems,
  category,
  nodeId,
  firebase,
  selectedDbList,
  applicationType,
  createdDate
) {
  let obj = {};
  switch (category) {
    case "leadForm":
      obj["name"] = values["formName"];
      obj["fieldConfigModels"] = formItems.customFields ? formItems.customFields : [];

      obj["branches"] = {
        dropdownValues: selectedDbList ? selectedDbList : null,
      };

      obj["student"] = formItems.student ? formItems.student : [];
      obj["childConfig"] = formItems.childConfig ? formItems.childConfig : [];
      obj["parentOne"] = formItems.parentOne ? formItems.parentOne : [];
      obj["parentOneConfig"] = formItems.parentOneConfig ? formItems.parentOneConfig : [];
      obj["parentTwo"] = formItems.parentTwo ? formItems.parentTwo : [];
      obj["parentTwoConfig"] = formItems.parentTwoConfig ? formItems.parentTwoConfig : [];
      obj["id"] = nodeId;
      obj["createdDate"] = createdDate ? createdDate : moment().valueOf();
      obj["deleted"] = false;
      obj["platform"] = "web";
      obj["updatedBy"] = firebase.teacher.name;
      obj["updatedOn"] = moment().valueOf();
      obj["status"] = formItems.status ? formItems.status : "Live";
      obj["leadStatus"] = formItems.leadStatus ? formItems.leadStatus : null;
      obj["teacherIds"] = formItems.teacherIds ? formItems.teacherIds : null;
      obj["redirectUrl"] = formItems.redirectUrl ? formItems.redirectUrl : null;
      obj["formDisplayName"] = formItems.formDisplayName
        ? formItems.formDisplayName
        : null;
      obj["sendEmail"] = formItems.sendEmail;
      obj["type"] = "lead"
      obj["formDisplayName"] = formItems.formDisplayName
        ? formItems.formDisplayName
        : null;
      obj["entity"] = category;
      break;
    case "applications":
    case "studentApplication":
    case "teacherApplication":
    case "bookingApplication":
      obj["name"] = values["formName"];
      obj["fieldConfigModels"] = formItems.customFields ? formItems.customFields : [];
      obj["updatedBy"] = firebase.teacher.name;
      obj["updatedOn"] = moment().valueOf();
      obj["createdDate"] = createdDate ? createdDate : moment().valueOf();
      obj["deleted"] = false;
      obj["platform"] = "web";
      obj["id"] = nodeId;
      obj["student"] = formItems.student ? formItems.student : [];
      obj["childConfig"] = formItems.childConfig ? formItems.childConfig : [];
      obj["parentOne"] = formItems.parentOne ? formItems.parentOne : [];
      obj["parentOneConfig"] = formItems.parentOneConfig ? formItems.parentOneConfig : [];
      obj["parentTwo"] = formItems.parentTwo ? formItems.parentTwo : [];
      obj["parentTwoConfig"] = formItems.parentTwoConfig ? formItems.parentTwoConfig : [];
      obj["file"] = false;
      // obj["type"] = "Application";
      obj["type"] = applicationType;
      obj["status"] = formItems.status ? formItems.status : "Live";
      obj["teacherIds"] = formItems.teacherIds ? formItems.teacherIds : null;
      obj["formDisplayName"] = formItems.formDisplayName
        ? formItems.formDisplayName
        : null;
      obj["alwaysAvailable"] = formItems.alwaysAvailable
        ? formItems.alwaysAvailable
        : false;
      obj["entity"] = category
  }

  return obj;
}

async function saveBuildForm(
  firebase,
  values,
  formItems,
  category,
  nodeId,
  selectedDbList,
  applicationType,
  appliedBranches
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  //here se
  let obj = getFirebaseObjectOnCategory(
    values,
    formItems,
    category,
    nodeId,
    firebase,
    selectedDbList,
    applicationType
  );
  let entityType = getEntityTypeByCategory(category)
  obj.disableResponse = false
  obj.entityType = entityType;
  obj.branchList = appliedBranches ? appliedBranches : null;
  var endpoint = firebase.endPointUrl;

  let callUrl = "woodlandApi/saveForm/" + "?centerId=" + firebase.sbDbName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let promise = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + callUrl)
      .set("Content-Type", "application/json")
      .set("accept", "json")
      .set({ Authorization: "Bearer " + authToken })
      .send(obj)
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return promise;

}
function getEntityTypeByCategory(category) {
  switch (category.toLowerCase()) {
    case "leadform":
      return "lead"
    case "applications":
      return "student"
    case "teacherapplication":
      return "teacher"
  }
}

async function editBuildForm(
  firebase,
  values,
  formItems,
  category,
  id,
  selectedDbList,
  applicationType,
  oldApplicationObj,
  appliedBranches
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = getFirebaseObjectOnCategory(
    values,
    formItems,
    category,
    id,
    firebase,
    selectedDbList,
    applicationType,
    oldApplicationObj?.createdDate
  );
  let entityType = getEntityTypeByCategory(category)
  obj = { ...oldApplicationObj, ...obj, entityType }
  obj.branchList = appliedBranches ? appliedBranches : null;
  var endpoint = firebase.endPointUrl;

  let callUrl = "woodlandApi/application/" + obj.id + "?centerId=" + firebase.sbDbName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  let promise = new Promise(function (resolve, reject) {
    superagent
      .post(endpoint + callUrl)
      .set("Content-Type", "application/json")
      .set("accept", "json")
      .set({ Authorization: "Bearer " + authToken })
      .send(obj)
      .end(function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
  });
  return promise;
  // rsf.ref(branchPath + "/" + category + "/" + id).update(obj);
}
function generatesaveBuildFormNode(firebase, category) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/" + category).push().key;
  return key;
}
function fetchFormDetail(firebase, formItems, category, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf.ref(branchPath + "/" + category + "/" + id);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      if (snap.val()) {
        emit(snap.val());
      }
    });
    return () => {
      console.log("closing channel");
      myRef.off();
    };
  });
}
function createAtdNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/studentApplicationShareRef/").push().key;
  return key;
}
function shareSelectedApplication(obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // rsf.ref(branchPath + "/studentApplicationShareRef/" +obj.id).update(obj);
  rsf.ref(branchPath + "/studentApplicationShareRef/" + obj.id).set(obj);
}

function getApplicationLogs(applicationForm, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var log = [];
  var promise = new Promise(function (resolve, reject) {
    if (firebase.schoolName.toLowerCase().includes("woodland")) {
      rsf
        .ref(branchPath + "/studentApplicationLog")
        .once("value")
        .then(function (snapshot) {
          snapshot.forEach((snap) => {
            if (snap.val()) {
              log.push(snap.val());
            }
          });
          resolve(log);
        });
    } else {
      rsf
        .ref(branchPath + "/studentApplicationLog")
        .orderByChild("applicationId")
        .equalTo(applicationForm.id)
        .once("value")
        .then(function (snapshot) {
          snapshot.forEach((snap) => {
            if (snap.val()) {
              log.push(snap.val());
            }
          });
          resolve(log);
        });
    }

  });
  return promise;
}

function getFormItem(firebase, formCategory) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var items = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/" + formCategory)
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            items.push(snap.val());
          }
        });
        resolve(items);
      });
  });
  return promise;
}

function saveFormItems(values, firebase, formCategory) {
  values["html"] = false;
  let id = values.id;
  let tempObj = FilterAction.getSuperagentBody(values, firebase);
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/" + formCategory + "/" + id).update(tempObj);
}

function deleteFormItem(formCategory, item, firebase) {
  let id = item.id;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/" + formCategory + "/" + id).set(null);
}
function createNewNode(values, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/admissionProcess/").push().key;
  return key;
}

function getAllAdmissionProcess(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let formRef = rsf.ref(branchPath + "/admissionProcess").orderByChild("createdOn");

  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      let forms = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          forms.push(element.val());
        }
      });
      emit(forms);
    });
    return () => {
      formRef.off();
    };
  });
}
function getAdmissionProcess(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/admissionProcess/" + id);
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      let obj = {};
      if (snap.val()) {
        obj = snap.val();
      }
      emit(obj);
    });
    return () => {
      myRef.off();
    };
  });
}

async function deleteApi(obj, fieldUrl, firebase) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + fieldUrl + accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("request api error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function getAllStudentWithProcessShare(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let formRef = rsf
    .ref(branchPath + "/studentApplicationShareRef")
    .orderByChild("process")
    .equalTo(true);
  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      formRef.off();
    };
  });
}

function fetchAllProcessForStudent(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var log = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/admissionProcess")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            log.push(snap.val());
          }
        });
        resolve(log);
      });
  });
  return promise;
}

function getStudentProgress(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formRef = rsf
    .ref(branchPath + "/admissionProcessStats")
    .orderByChild("processId")
    .equalTo(id);
  return eventChannel((emit) => {
    formRef.on("value", (snap) => {
      var x = [];
      snap.forEach((item) => {
        if (item.val() !== null) {
          x.push(item.val());
        }
      });
      emit(x);
    });
    return () => {
      formRef.off();
    };
  });
}


export const ApplicationApi = {
  getAllApplications,
  getTeacherApplications,
  getAllAdmissionProcess,
  getAdmissionProcess,
  getStudentFormSubmission,
  updateApplication,
  updateStudentApplication,
  getSingleStudentFormSubmission,
  getSingleTeacherFormSubmission,
  getStudentFormSubmissionById,
  saveBuildForm,
  generatesaveBuildFormNode,
  fetchFormDetail,
  editBuildForm,
  createAtdNode,
  shareSelectedApplication,
  getApplicationLogs,
  saveFormItems,
  getFormItem,
  deleteFormItem,
  createNewNode,
  getAllStudentWithProcessShare,
  fetchAllProcessForStudent,
  getStudentProgress,
  deleteApi,
  getSharedApplications,
  downloadtStudentFormSubmission
};
