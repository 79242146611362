import React from "react";
import colors from "../../Utility/colorFactory";

const CounterBadge = (props) => {
  if (props.counter) {
    return (
      <p
        style={{
          margin: 0,
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: colors.v2_fuzzy_brown,
          borderRadius: "50%",
          color: colors.white,
          border: "1px solid",
          borderColor: colors.v2_fuzzy_brown,
          height: 20,
          minWidth: 20,
          textAlign: "center",
        }}
      >
        {props.counter}
      </p>
    );
  } else {
    return null;
  }
};
export default CounterBadge;
