// import { classroom } from "./reducer";
const actions = {
  LIST_CLASSROOMS: "LIST_CLASSROOMS",
  LIST_CLASSROOM: "LIST_CLASSROOM",
  UPDATE_CLASSROOM: "UPDATE_CLASSROOM",
  UPDATE_CLASSROOM_SUCCESSFUL: "UPDATE_CLASSROOM_SUCCESSFUL",
  ADD_CLASSROOM: "ADD_CLASSROOM",
  ADD_CLASSROOM_COMPLETED: "ADD_CLASSROOM_COMPLETED",
  GET_CENTER_DATA: "GET_CENTER_DATA",
  GET_CENTER_DATA_SUCCESSFUL: "GET_CENTER_DATA_SUCCESSFUL",
  AUTOSEARCH_CENTER_NAME: "AUTOSEARCH_CENTER_NAME",
  TOGGLE_DATA: "TOGGLE_DATA",
  TOGGLE_DATA_SUCCESSFUL: "TOGGLE_DATA_SUCCESSFUL",
  REQUEST_FAILED: "REQUEST_FAILED",
  DELETE_CLASS: "DELETE_CLASS",
  DELETE_CLASS_SUCCESS: "DELETE_CLASS_SUCCESS",
  START_CLASS_LOADER: "START_CLASS_LOADER",
  ADD_CLASS_TO_TEACHERS: "ADD_CLASS_TO_TEACHERS",
  ADD_CLASS_TO_TEACHERS_SUCCESSFUL: "ADD_CLASS_TO_TEACHERS_SUCCESSFUL",
  RENAME_CLASSROOM: "RENAME_CLASSROOM",
  DELETE_CLASSROOM_WITH_TRASFER: "DELETE_CLASSROOM_WITH_TRANSFER",
  RESET_CLASSROOM_OPERATION: "RESET_CLASSROOM_OPERATION",

  initClassroomData: (firebase) => ({
    type: actions.LIST_CLASSROOMS,
    firebase,
  }),

  // fetchAutoCompleteCenter: () => ({type: actions.GET_CENTER_DATA}),

  addClassroom: (name, firebase, values) => ({
    type: actions.ADD_CLASSROOM,
    name,
    firebase,
    values,
  }),

  updateClassroom: (updatedName, classroomId, firebase) => ({
    type: actions.UPDATE_CLASSROOM,
    updatedName,
    classroomId,
    firebase,
  }),

  updateToggleData: (disableStatus, docId) => ({
    type: actions.TOGGLE_DATA,
    disableStatus,
    docId,
  }),

  deleteClassroom: (record, firebase) => ({
    type: actions.DELETE_CLASS,
    record,
    firebase,
  }),
  addClassToTeachers: (values, firebase) => ({
    type: actions.ADD_CLASS_TO_TEACHERS,
    values,
    firebase,
  }),
  renameClassroom: (firebase, classroom, newName) => ({
    type: actions.RENAME_CLASSROOM,
    firebase,
    classroom,
    newName,
  }),
  deleteClassroomWithTranser: (
    firebase,
    classroomToDelete,
    classroomToTransferStudent,
    classroomToTransferTeachers
  ) => ({
    type: actions.DELETE_CLASSROOM_WITH_TRASFER,
    firebase,
    classroomToDelete,
    classroomToTransferStudent,
    classroomToTransferTeachers,
  }),
  resetClassroomOperation: () => ({
    type: actions.RESET_CLASSROOM_OPERATION,
  }),
};

export default actions;
