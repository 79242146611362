import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { ClassroomApi } from "../../firestore-api/classroom";
import { DashboardApi } from "../../firestore-api/dashboard";
import { NotificationApi } from "../../firestore-api/notification";
import { StudentApi } from "../../firestore-api/student";
import { AssessmentApi } from "../../firestore-api/studentAssessment";
import { TeacherApi } from "../../firestore-api/teacher";
import { zoomSettingApi } from "../../firestore-api/zoomSettingApi";
import { getItem } from "../../Utility/encryptedStorage";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import actions from "./actions";

function* fetchAllStudent({ firebase }) {
  try {
    let data = yield call(AssessmentApi.getAllStudents, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_TO_POST_NEW_ACTIVITY_SUCCESSFUL,
        students: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch students", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchAllClassroom({ firebase }) {
  try {
    let data = yield call(TeacherApi.getClassroomsForTeacher, firebase);
    if (data) {
      yield put({
        type: actions.GET_CLASSROOM_FOR_NEW_ACTIVITY_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch classroom", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchActivityTemplate({ activityName, firebase }) {
  try {
    let data = yield call(ActivityApi.getActivityTemplate, activityName, firebase);

    if (data) {
      yield put({
        type: actions.GET_ACTIVITY_TEMPLATE_SUCCESSFUL,
        activityTemplate: data,
      });
    }

    yield fork(fetchCustomLabels, "Activity", firebase);
  } catch (err) {
    console.log("failed to fetch activity template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
    });
  }
}

// function* getVC(firebase) {

//   const rsf = firebase.secondaryDb;
//   let branchPath = firebase.sbp;

//   rsf.ref(branchPath + "/actvities").orderByChild("activityType").equalTo("Virtual Class").once("value").then(snapshot => {
//     console.log("snapshot --", snapshot.val());
//     snapshot.forEach(snap => {
//       console.log("snap value", snap.val());
//       // if (snap.val() && snap.val().meetingId && snap.val().meetingTime !== Math.abs(snap.val().inverseMeetingtTime)) {
//       //   console.log("snap value", snap.val());
//       // }
//     })
//   })
// }

function* fetchCustomLabels(category, firebase) {
  try {
    let data = yield call(ActivityApi.getCustomLabelByCategory, category, firebase);
    if (data) {
      yield put({
        type: actions.GET_ACTIVITY_CUSTOM_LABEL_SUCCESS,
        activityLabel: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch custom labels", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
    });
  }
}

function* fetchFoodMenuForToday({ firebase }) {
  try {
    let data = yield call(ActivityApi.getFoodMenuToday, firebase);
    if (data) {
      yield put({
        type: actions.GET_FOOD_MENU_SUCCESS,
        todaysFoodMenu: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch today's food menu for activity post", err);
    bugsnagClient.notify(
      "failed to fetch today's food menu for activity post --->>>" + err.message ? err.message : err
    );
  }
}

function getYoutubeCode(url) {
  var ID = "";
  url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = "not valid";
  }
  return ID;
}

function getYoutubeThumbnail(videoId) {
  return "http://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg";
}

function getNoteHtml(editorState, activity) {
  if (editorState && activity !== "Nap (Time Mode)") {
    let rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    
    const linkedMarkUp = linkify(markup);
    let replacedVal = replaceAll(linkedMarkUp, "</p>\n<p>", "<br>");
    return replacedVal;
  } else {
    return null;
  }
}



function linkify(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;
  //URLs starting with http://, https://, or ftp://
  // replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacePattern1 = /(\b(https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  
  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  // replacedText = replacedText.replace(
  //   replacePattern2,
  //   '$1<a href="http://$2" target="_blank">$2</a>'
  // );

  //Change email addresses to mailto:: links.
  // replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

function getNotePlainContent(editorState, activity) {
  if (editorState) {
    if (editorState && activity !== "Nap (Time Mode)") {
      let rawContentState = convertToRaw(editorState.getCurrentContent());

      if (rawContentState.blocks && rawContentState.blocks.length > 0) {
        let allBlocks = rawContentState.blocks;
        let data = "";
        allBlocks.map((b, i) => {
          if (b.text) {
            if (i === 0) {
              data = b.text + " \n";
            } else {
              data = data + b.text + " \n";
            }
          }
        });

        return data;
      } else {
        return null;
      }
    } else {
      return editorState;
    }
  } else {
    return null;
  }
}

function* postStaffActivity(
  selectedActivity,
  selectedStudent,
  selectedClassroom,
  formValue,
  firebase,
  mediaType
) {
  try {
    let userExists = true;
    let activityId = yield call(ActivityApi.generateNewStaffActivityNode, firebase);

    let activityDate = new Date();
    let activityType = "Notification";
    let classNames = [];
    let mediaPaths = [];
    let message;
    let htmlText;

    let formMsg = formValue.note ? getNotePlainContent(formValue.note, "staffAnnouncement") : null;
    message = formMsg;
    htmlText = formValue.note ? getNoteHtml(formValue.note, selectedActivity) : null;

    let name = "Announcement";

    let studentIds = [];
    selectedStudent.map((item) => {
      studentIds.push(item.id);
    });
    if (!studentIds.includes(firebase.teacher.id)) {
      userExists = false;
      studentIds.push(firebase.teacher.id);
      selectedStudent.push(firebase.teacher);
    }
    studentIds = studentIds.filter(Boolean);

    let enableParentComments = formValue.enableComments ? formValue.enableComments : false;

    let thumbNail;
    let youtubeUrlCode;
    if (formValue.youtubeUrl) {
      youtubeUrlCode = getYoutubeCode(formValue.youtubeUrl);
      if (youtubeUrlCode === "not valid") {
        yield put({
          type: actions.NEW_ACTIVITY_REQUEST_FAILED,
        });
        return;
      }
      thumbNail = getYoutubeThumbnail(youtubeUrlCode);
    } else {
      thumbNail = null;
      youtubeUrlCode = null;
    }

    let activityLabel = formValue.activityLabel ? formValue.activityLabel : null;

    let staffOnly = formValue.staffOnly ? formValue.staffOnly : false;

    yield call(
      ActivityApi.addNewActivityToStaffActivities,
      activityDate,
      activityType,
      classNames,
      mediaPaths,
      null,
      null,
      message,
      name,
      null,
      staffOnly,
      studentIds,
      activityId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      firebase,
      undefined,
      null,
      null,
      null,
      thumbNail,
      youtubeUrlCode,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      htmlText,
      enableParentComments,
      activityLabel
    );

    if (formValue.attachment) {
      let attachmentObj = {
        fileList: formValue.attachment.file,
      };

      let storagePath = firebase.sbp + "/media/mediaProfiles/";
      let urls = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        formValue.attachment,
        firebase,
        !staffOnly &&
          FilterPermission.checkIfPermission(
            PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
            firebase
          )
          ? activityId
          : undefined,
        undefined,
        undefined,
        studentIds
      );

      if (urls) {
        mediaPaths = urls;

        yield call(ActivityApi.updateStaffMedia, mediaPaths, undefined, activityId, firebase);
      }
    }

    let staffEmails = [];

    for (let i = 0; i < selectedStudent.length; i++) {
      let teacher = selectedStudent[i];
      if (teacher.email) {
        staffEmails.push(teacher.email);
      }

      /**staff timeline */
      let staffTimeline = yield call(
        ActivityApi.getStudentTimeline,
        activityDate,
        teacher.id,
        firebase
      );

      if (staffTimeline) {
        let newActivityIds =
          staffTimeline.activityIds && staffTimeline.activityIds.length > 0
            ? staffTimeline.activityIds
            : [];
        newActivityIds.push(activityId);

        let newTimelineData = {
          activityIds: newActivityIds,
          date: new Date().getTime(),
          inverseDate: -new Date().getTime(),
        };

        yield call(
          ActivityApi.updateStudentTimeline,
          activityDate,
          teacher.id,
          newTimelineData,
          firebase
        );
      } else {
        console.log("studentTimeline --------------", staffTimeline);
        bugsnagClient.notify(new Error("failed to upload activity to staff timeline"));
      }
      /**end of staff timeline code */

      let alertMessage = "Staff announcement added for " + teacher.name;
      let alertNode = yield call(NotificationApi.createAlertReferenceNode, teacher.id, firebase);

      yield fork(
        NotificationApi.createSimpleAlertNotification,
        "staffAnnouncement",
        activityId,
        teacher.uid ? teacher.uid : null,
        alertMessage,
        alertNode,
        teacher.ios_uid ? teacher.ios_uid : null,
        undefined,
        teacher.id,
        "staffAnnouncement",
        undefined,
        firebase,
        undefined,
        "teacher"
      );

      if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
        yield fork(
          NotificationApi.sendSimplePushNotification,
          "staffAnnouncement",
          activityId,
          teacher.uid ? teacher.uid : null,
          alertMessage,
          alertNode,
          teacher.ios_uid ? teacher.ios_uid : null,
          undefined,
          teacher.id,
          "staffAnnouncement",
          undefined,
          firebase,
          undefined,
          "teacher"
        );
      }
    }

    yield put({
      type: actions.POST_ACTIVITY_SUCCESSFUL,
      newActivityOperationType: formValue.attachment
        ? "POST_ACTIVITY_SUCCESS_WITH_ATTACHMENT"
        : "POST_ACTIVITY_SUCCESS",
      staffActivityOperation: "POSTED",
    });

    if (formValue.sendEmailCopy === "Email") {
      var imageBody = [];
      var fileBody = [];
      var videoBody = [];
      mediaPaths.forEach((url) => {
        if (url.type.toLowerCase() === "photo" || url.type.toLowerCase() === "image") {
          imageBody.push(`<img src=${url.path} style={{ width: "80%", height: "80%" }} /> <br />`);
        } else if (url.type.toLowerCase() === "file") {
          fileBody.push(
            `<a href=${url.path}><p>Click to view file</p></a><br />
          <p>If the above link does not work then copy paste this url in the browser</p>
          <a href=${url.path}><p>${url.path}</p></a>`
          );
        } else if (url.type.toLowerCase() === "video") {
          videoBody.push(`<a href=${url.path}><p>Click to view video</p></a><br />`);
        }
      });

      var youtubeLink = formValue.youtubeUrl
        ? `<a href=${formValue.youtubeUrl}>Youtube Link</a>`
        : `<div />`;

      var htmlBody = htmlText + `${imageBody}` + `${youtubeLink}` + `${fileBody}` + `${videoBody}`;

      let htmlString = replaceAll(htmlBody, "\r", "<br/>");
      let htmlString2 = replaceAll(htmlString, "\n", "<br/>");

      yield fork(
        NotificationApi.sendAnnouncementEmails,
        htmlString2,
        selectedActivity + " " + firebase.schoolName,
        staffEmails,
        firebase
      );
    }
  } catch (err) {
    console.log("failed to post staff new activity", err);
    bugsnagClient.notify("post new staff activity error" + err.message);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* postActivity({
  selectedActivity,
  selectedStudent,
  selectedClassroom,
  formValue,
  firebase,
  mediaType,
  staffActivity,
}) {
  if (staffActivity) {
    yield fork(
      postStaffActivity,
      selectedActivity,
      selectedStudent,
      selectedClassroom,
      formValue,
      firebase,
      mediaType
    );
  } else {
    try {
      let activityNode;
      let activityCreatedBy;
      if (formValue.selectedRecord) {
        activityNode = formValue.selectedRecord.id;
        activityCreatedBy = formValue.selectedRecord.createdBy;
      } else {
        activityNode = yield call(ActivityApi.generateNewActivityNode, firebase);
      }

      let meetingId;
      let meetingTime;
      let meetingUrl = "";
      let joinUrl = "";
      if (selectedActivity === "Virtual Class") {
        let response = yield call(
          zoomSettingApi.createZoomMeetinng,
          new Date(formValue.meetingDate),
          "Agenda",
          activityNode,
          firebase,
          formValue.meetingDuration
        );
        if (response && response.body && response.body.id) {
          meetingId = response.body.id.toString();
          meetingTime = formValue.meetingDate.valueOf();
          meetingUrl = response.body.start_url;
          joinUrl = response.body.join_url;
        } else {
          console.log("create meeting response ---", response);

          if (response && response.body && response.body.error) {
            yield put({
              type: actions.NEW_ACTIVITY_REQUEST_FAILED,
              errorMessage: response.body.error,
            });
          } else {
            yield put({
              type: actions.NEW_ACTIVITY_REQUEST_FAILED,
              errorMessage: "Failed to create zoom meeting",
            });
          }
          bugsnagClient.notify("Failed to create zoom meeting , " + response);
          return;
        }
      }

      let activityDate;
      if (selectedActivity === "Announcement") {
        if (formValue.selectedRecord) {
          activityDate = new Date(formValue.selectedRecord.date.time);
        } else {
          activityDate = new Date();
        }
      } else if (selectedActivity === "Virtual Class") {
        activityDate = new Date();
      } else {
        if (formValue.time) {
          activityDate = new Date(formValue.time);
        } else {
          activityDate = new Date();
        }
      }

      let activityType;
      if (selectedActivity === "Announcement") {
        activityType = "Notification";
      } else if (selectedActivity === "Gross Motor") {
        activityType = "GrossMotor";
      } else if (selectedActivity === "Fine Motor") {
        activityType = "FineMotor";
      } else if (selectedActivity === "Self Reliance") {
        activityType = "SelfReliance";
      } else if (selectedActivity === "Block Hour") {
        activityType = "BlockHour";
      } else if (selectedActivity === "Art & Craft") {
        activityType = "Artcraft";
      } else {
        activityType = selectedActivity;
      }

      yield fork(
        ActivityApi.updateActivityLog,
        activityNode,
        formValue.selectedRecord ? "EDIT" : "ADD",
        firebase,
        activityType
      );

      let classNames = [];
      if (formValue.selectedRecord) {
        classNames = formValue.selectedRecord.classNames;
      } else {
        if (selectedActivity === "Announcement" || selectedActivity === "Notification") {
          if (selectedStudent) {
            selectedStudent.forEach((std) => {
              if (std.classList) {
                classNames = [...classNames, ...std.classList];
              }
            });

            classNames = [...new Set(classNames)];
          } else {
            selectedClassroom.map((item) => {
              classNames.push(item.className);
            });
          }
        } else {
          selectedStudent.forEach((std) => {
            if (std.classList) {
              classNames = [...classNames, ...std.classList];
            }
          });
          classNames = [...new Set(classNames)];
        }
      }

      /**for trial bookings */
      // if (formValue.classes && formValue.classes.length > 0) {
      if (firebase.schoolConfig.classBookingEnabled && activityType === "Virtual Class") {
        let trialClassrooms = firebase.teacher.classList ? firebase.teacher.classList : [];
        let tempclasses = [...classNames, ...trialClassrooms];
        classNames = [...new Set(tempclasses.map((iClass) => iClass))];
      }

      let mediaFormat;
      if (selectedActivity === "Photo") {
        mediaFormat = "none";
      } else if (selectedActivity === "Video") {
        mediaFormat = "none";
      } else {
        if (mediaType === "Photo") {
          mediaFormat = "none";
        } else if (mediaType === "Video") {
          mediaFormat = "video";
        } else if (mediaType === "File") {
          mediaFormat = "file";
        } else {
          mediaFormat = "none";
        }
      }

      let mediaPaths = []; //need to generate url

      let templateMessage;
      if (activityType === "Medicine") {
        templateMessage = null;
      } else if (activityType === "Incident") {
        templateMessage = null;
      } else if (activityType === "Food") {
        templateMessage = formValue.mealQuantity ? formValue.mealQuantity : null;
      } else {
        templateMessage = formValue.milestones ? formValue.milestones[0] : null;
      }

      let message;
      let htmlText;
      let newMessage = null;
      if (selectedActivity === "Announcement") {
        message = formValue.annoucementMessage
          ? getNotePlainContent(formValue.annoucementMessage, selectedActivity)
          : null;
        newMessage = message;
        htmlText = formValue.annoucementMessage
          ? getNoteHtml(formValue.annoucementMessage, selectedActivity)
          : null;
      } else {
        message = formValue.note ? getNotePlainContent(formValue.note, selectedActivity) : null;

        if (formValue.newNote) {
          newMessage = formValue.newNote;
        }
        htmlText = formValue.note ? getNoteHtml(formValue.note, selectedActivity) : null;
      }

      let name = selectedActivity;

      let fieldConfigModels = formValue.fieldConfigModels ? formValue.fieldConfigModels : null;

      if (fieldConfigModels) {
        fieldConfigModels.forEach((fItem) => {
          let dataType = fItem.dataType.toLowerCase();
          if (dataType === "date") {
            fItem.value = formValue[fItem.id]
              ? moment(formValue[fItem.id]).format("DD[ ]MMM[ ]YY")
              : "";
          } else if (dataType === "time") {
            fItem.value = formValue[fItem.id] ? moment(formValue[fItem.id]).format("h:mm A") : "";
          } else if (dataType === "yes/no checkbox") {
            fItem.value = formValue[fItem.id] ? "Yes" : "No";
          } else if (dataType === "multi select") {
            fItem.value = formValue[fItem.id] ? formValue[fItem.id].toString() : [];
          } else {
            fItem.value = formValue[fItem.id] ? formValue[fItem.id] : "";

            if (
              activityType.toLowerCase() === "today's mood" &&
              fItem.name.toLowerCase() === "today's mood"
            ) {
              templateMessage = fItem.value;
            }
          }
          message = (message ? message : "") + "\n" + "\n" + fItem.name + "\n" + fItem.value;
        });
      }

      let staffOnly = formValue.staffOnly ? formValue.staffOnly : false;

      let studentIds = [];
      if (formValue.selectedRecord) {
        studentIds = formValue.selectedRecord.studentIds ? formValue.selectedRecord.studentIds : [];
      } else {
        if (selectedStudent && selectedStudent.length > 0) {
          selectedStudent.map((item) => {
            studentIds.push(item.id);
          });

          studentIds = studentIds.filter(Boolean);
        }
      }

      let activityId = activityNode;
      let foodMenu = formValue.foodMenu ? formValue.foodMenu.toString() : null;
      let foodSource = activityType === "Food" ? "Meal" : null;
      let meal = formValue.mealType ? formValue.mealType : null;
      let quantity = formValue.mealQuantity ? formValue.mealQuantity : null;
      let pottyDestination = formValue.pottyDestination ? formValue.pottyDestination : null;
      let pottyType = formValue.pottyType ? formValue.pottyType : null;
      let napStart = formValue.nap ? moment(activityDate).format("h:mm A") : null;

      if (formValue.nap && formValue.nap.toLowerCase() === "nap ended") {
        napStart = "  ";
      }
      let napEnd =
        formValue.nap && formValue.nap.toLowerCase() === "nap ended"
          ? moment(activityDate).format("h:mm A")
          : null;

      if (formValue.napStartTime) {
        napStart = moment(formValue.napStartTime).format("h:mm A");
      }

      if (formValue.napEndTime) {
        napEnd = moment(formValue.napEndTime).format("h:mm A");
      }

      let repeatStartDate = 0;
      let repeatEndDate = 0;
      let includeSaturday = false;
      let daysOfWeek = formValue.daysOfWeek ? formValue.daysOfWeek : null;
      let meetingCapacity = formValue.classCapacity ? formValue.classCapacity : null;
      let meetingBookable = formValue.trialClass;
      let meetingDuration = formValue.meetingDuration ? formValue.meetingDuration : null;
      let enableParentComments = formValue.enableComments ? formValue.enableComments : false;
      if (formValue.repeatClass && formValue.repeatClass[0] && formValue.repeatClass[1]) {
        repeatStartDate = moment(formValue.repeatClass[0]).valueOf();
        repeatEndDate = moment(formValue.repeatClass[1]).valueOf();
      }

      if (formValue.includeSaturday) {
        includeSaturday = formValue.includeSaturday;
      }

      let thumbNail;
      let youtubeUrlCode;
      if (formValue.youtubeUrl) {
        youtubeUrlCode = getYoutubeCode(formValue.youtubeUrl);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.NEW_ACTIVITY_REQUEST_FAILED,
          });
          return;
        }
        thumbNail = getYoutubeThumbnail(youtubeUrlCode);
      } else {
        thumbNail = null;
        youtubeUrlCode = null;
      }

      let activityLabel = formValue.activityLabel ? formValue.activityLabel : null;

      let taggedTeachers = [];
      if (formValue.taggedTeachers) {
        taggedTeachers = formValue.taggedTeachers;
      }

      let medicineGivenBy = formValue.medicineGivenBy ? formValue.medicineGivenBy : null;

      let activityApproved;
      if(formValue.selectedRecord){
        activityApproved = formValue.selectedRecord.approved;
      }

      yield call(
        ActivityApi.addNewActivityToActivities,
        activityDate,
        activityType,
        classNames,
        mediaPaths,
        meetingId,
        meetingTime,
        message,
        name,
        templateMessage,
        staffOnly,
        studentIds,
        activityId,
        foodSource,
        meal,
        quantity,
        foodMenu,
        pottyDestination,
        pottyType,
        napStart,
        firebase,
        undefined,
        meetingUrl,
        joinUrl,
        mediaFormat,
        thumbNail,
        youtubeUrlCode,
        repeatStartDate,
        repeatEndDate,
        includeSaturday,
        daysOfWeek,
        meetingCapacity,
        meetingBookable,
        meetingDuration,
        htmlText,
        enableParentComments,
        activityLabel,
        taggedTeachers,
        medicineGivenBy,
        formValue.lessonMilestoneValues,
        formValue.draft,
        fieldConfigModels,
        formValue.noApproval,
        napEnd,
        newMessage,
        activityCreatedBy,
        activityApproved
      );

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", activityDate);

      if (activityType === "Virtual Class" && !formValue.selectedRecord) {
        let meetingRefNode = yield call(ActivityApi.createMeetingRefNode, firebase);
        yield fork(ActivityApi.updateMeetingRef, meetingTime, activityId, meetingRefNode, firebase);
      }

      if (
        firebase.schoolConfig.classBookingEnabled &&
        meetingCapacity &&
        activityType === "Virtual Class" &&
        !formValue.selectedRecord
      ) {
        if (repeatStartDate && repeatStartDate > 0 && repeatEndDate && repeatEndDate > 0) {
          let repeatWeekDays = daysOfWeek ? daysOfWeek : [];
          if (includeSaturday && !repeatWeekDays.includes("Saturday")) {
            repeatWeekDays.push("Saturday");
          }

          let repeatStart = moment(repeatStartDate).startOf("day").valueOf();
          let repeatEnd = moment(repeatEndDate).endOf("day").valueOf();
          let diff = Math.abs(moment(repeatEnd).diff(moment(repeatStart), "days"));

          for (let i = 0; i <= diff; i++) {
            let repeatMeetingTime = moment(meetingTime).add(i, "day");

            let update = false;
            if (repeatWeekDays && repeatWeekDays.length > 0) {
              if (repeatWeekDays.includes(repeatMeetingTime.format("dddd"))) {
                update = true;
              }
            } else {
              update = true;
            }

            if (update) {
              if (i === 0) {
                let markUpdate = true;
                let trialBookingNode = yield call(ActivityApi.createBookingRefNode, firebase);
                yield fork(
                  ActivityApi.updateTrialBookingRef,
                  repeatMeetingTime.valueOf(),
                  activityId,
                  trialBookingNode,
                  classNames,
                  firebase,
                  undefined,
                  meetingCapacity,
                  [],
                  meetingDuration,
                  firebase.teacher.name,
                  markUpdate
                );
              } else {
                let trialBookingNode = yield call(ActivityApi.createBookingRefNode, firebase);
                yield fork(
                  ActivityApi.updateTrialBookingRef,
                  repeatMeetingTime.valueOf(),
                  activityId,
                  trialBookingNode,
                  classNames,
                  firebase,
                  undefined,
                  meetingCapacity,
                  [],
                  meetingDuration,
                  firebase.teacher.name
                );
              }
            }
          }
        } else {
          let markUpdate = true;
          let trialBookingNode = yield call(ActivityApi.createBookingRefNode, firebase);
          yield fork(
            ActivityApi.updateTrialBookingRef,
            meetingTime,
            activityId,
            trialBookingNode,
            classNames,
            firebase,
            undefined,
            meetingCapacity,
            [],
            meetingDuration,
            firebase.teacher.name,
            markUpdate
          );
        }
      }

      //update stats count for dashboard
      yield fork(NotificationApi.sendStats, activityDate, "updateDailyPostStats", firebase);

      let parentEmails = [];
      let names = [];

      if (!formValue.selectedRecord) {
        for (let i = 0; i < selectedStudent.length; i++) {
          let studentId = selectedStudent[i].id;

          if (selectedStudent[i].fatherEmail) {
            parentEmails.push(selectedStudent[i].fatherEmail);
          }

          if (selectedStudent[i].motherEmail) {
            parentEmails.push(selectedStudent[i].motherEmail);
          }

          names.push({
            className:
              selectedStudent[i].classroomName &&
              selectedStudent[i].classroomName.length === undefined
                ? selectedStudent[i].classroomName
                : null,
            classList: selectedStudent[i].classList ? selectedStudent[i].classList : [],
            date: new Date().getTime(),
            fatherAndroidId: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            fatherId: selectedStudent[i].fatherProfileId
              ? selectedStudent[i].fatherProfileId
              : null,
            fatherIosId: selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            gender: selectedStudent[i].gender ? selectedStudent[i].gender : "Male",
            motherId: selectedStudent[i].motherProfileId
              ? selectedStudent[i].motherProfileId
              : null,
            parentName: firebase.teacher.name,
            profileImageUrl: selectedStudent[i].profileImageUrl
              ? selectedStudent[i].profileImageUrl
              : null,
            studentId: selectedStudent[i].id,
            studentName: selectedStudent[i].name,
          });

          let studentTimeline = yield call(
            ActivityApi.getStudentTimeline,
            activityDate,
            studentId,
            firebase
          );

          if (studentTimeline) {
            let newActivityIds =
              studentTimeline.activityIds && studentTimeline.activityIds.length > 0
                ? studentTimeline.activityIds
                : [];
            newActivityIds.push(activityId);

            let newTimelineData = {
              activityIds: newActivityIds,
              date: new Date().getTime(),
              inverseDate: -new Date().getTime(),
            };

            yield call(
              ActivityApi.updateStudentTimeline,
              activityDate,
              studentId,
              newTimelineData,
              firebase
            );
          } else {
            console.log("studentTimeline --------------", studentTimeline);
            bugsnagClient.notify(new Error("failed to upload activity to student timeline"));
          }
          //handle else case

          let activityTypeNodeId = yield call(
            ActivityApi.generateActivityTypeNode,
            activityType,
            studentId,
            firebase
          );
          if (activityTypeNodeId) {
            let creator = undefined;
            yield call(
              ActivityApi.updateActivityType,
              activityDate,
              studentId,
              activityId,
              activityTypeNodeId,
              activityType === "Notification" ? "Announcement" : activityType,
              firebase,
              undefined,
              meetingTime ? meetingTime : undefined,
              creator
            );
          }

          if (
            !staffOnly &&
            (FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            ) ||
              formValue.noApproval) &&
            !formValue.draft
          ) {
            if (selectedStudent[i].fatherProfileId) {
              let alertNode = yield call(
                NotificationApi.createAlertReferenceNode,
                selectedStudent[i].fatherProfileId,
                firebase
              );
              yield fork(
                NotificationApi.createAlertNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase
              );

              if (
                selectedStudent[i].fatherUUid !== undefined ||
                selectedStudent[i].ios_fatherUUid !== undefined
              ) {
                yield fork(
                  NotificationApi.sendPushNotification,
                  selectedActivity,
                  activityId,
                  selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                  selectedActivity + " activity added for " + selectedStudent[i].name,
                  alertNode,
                  selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                  selectedStudent[i].id,
                  selectedStudent[i].fatherProfileId,
                  firebase
                );
              }
            }

            if (selectedStudent[i].motherProfileId) {
              let alertNode = yield call(
                NotificationApi.createAlertReferenceNode,
                selectedStudent[i].motherProfileId,
                firebase
              );
              yield fork(
                NotificationApi.createAlertNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase
              );

              if (
                selectedStudent[i].motherUUid !== undefined ||
                selectedStudent[i].ios_motherUUid !== undefined
              ) {
                yield fork(
                  NotificationApi.sendPushNotification,
                  selectedActivity,
                  activityId,
                  selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                  selectedActivity + " activity added for " + selectedStudent[i].name,
                  alertNode,
                  selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                  selectedStudent[i].id,
                  selectedStudent[i].motherProfileId,
                  firebase
                );
              }
            }
          }
        }
      } else if (formValue.selectedRecord) {
        if (formValue.selectedRecord && formValue.selectedRecord.staffOnly && !staffOnly) {
          yield fork(
            sendStudentNotification,
            selectedActivity,
            activityId,
            formValue.selectedRecord.studentIds,
            firebase
          );
        }

        for (let index in studentIds) {
          let stdObj = firebase.studentsMap.get(studentIds[index]);
          if (stdObj && stdObj.fatherEmail) {
            parentEmails.push(stdObj.fatherEmail);
          }

          if (stdObj && stdObj.motherEmail) {
            parentEmails.push(stdObj.motherEmail);
          }
        }
      }

      if (formValue.taggedTeachers) {
        let taggedStaffs = [];
        if (formValue.selectedRecord && formValue.selectedRecord.taggedTeachers) {
          formValue.taggedTeachers.forEach((t) => {
            if (!formValue.selectedRecord.taggedTeachers.includes(t)) {
              taggedStaffs.push(t);
            }
          });
        } else {
          taggedStaffs = formValue.taggedTeachers;
        }
        yield fork(
          sendTeacherIncidentNotification,
          activityId,
          selectedActivity,
          taggedStaffs, //formValue.taggedTeachers,
          firebase
        );
      }

      if (!formValue.selectedRecord) {
        yield fork(ActivityApi.addUpdatedStudent, activityNode, names, firebase);
      }

      /**send approval request if no auto approval permission */
      if (
        (!FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ) &&
          !formValue.noApproval) ||
        formValue.draft
      ) {
        yield fork(sendActivityApprovalRequest, activityId, firebase);
      }

      yield put({
        type: actions.POST_ACTIVITY_SUCCESSFUL,
        newActivityOperationType: formValue.attachment
          ? "POST_ACTIVITY_SUCCESS_WITH_ATTACHMENT"
          : "POST_ACTIVITY_SUCCESS",
      });

      //attachment upload flow

      if (formValue.attachment) {
        let attachmentObj = {
          fileList: formValue.attachment.file,
        };

        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          formValue.attachment,
          firebase,
          !staffOnly &&
            FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            ) &&
            !formValue.draft
            ? activityId
            : undefined,
          undefined,
          undefined,
          studentIds
        );

        if (urls) {
          mediaPaths = urls;
          if (
            !staffOnly &&
            (FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            ) ||
              formValue.noApproval) &&
            !formValue.draft
          ) {
            yield fork(
              ActivityApi.generateThumbnail,
              mediaPaths,
              activityId,
              "activity",
              studentIds,
              firebase
            );
            yield fork(updateStudentMedia, urls, selectedStudent, firebase, undefined, activityId);
          }

          if (formValue.prevAttachments) {
            mediaPaths = [...mediaPaths, ...formValue.prevAttachments];
          }

          yield call(ActivityApi.updateMedia, mediaPaths, mediaFormat, activityId, firebase);

          if (formValue.assessment) {
            yield fork(
              updatePortfolioDocument,
              mediaPaths,
              studentIds,
              firebase,
              formValue.attachment
            );
          }
        }
      } else if (formValue.selectedRecord) {
        mediaPaths = formValue.prevAttachments;
        yield call(
          ActivityApi.updateMedia,
          formValue.prevAttachments,
          mediaFormat,
          activityId,
          firebase
        );
      }
      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", activityDate);

      if (
        formValue.sendEmailCopy === "Email" &&
        firebase.teacher &&
        (FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ) ||
          formValue.noApproval) &&
        !formValue.draft
      ) {
        var imageBody = [];
        var fileBody = [];
        var videoBody = [];
        mediaPaths.forEach((url) => {
          if (url.type.toLowerCase() === "photo" || url.type.toLowerCase() === "image") {
            imageBody.push(
              `<img src=${url.path} style={{ width: "80%", height: "80%" }} /> <br />`
            );
          } else if (url.type.toLowerCase() === "file") {
            fileBody.push(
              `<a href=${url.path}><p>Click to view file</p></a><br />
            <p>If the above link does not work then copy paste this url in the browser</p>
            <a href=${url.path}><p>${url.path}</p></a>`
            );
          } else if (url.type.toLowerCase() === "video") {
            videoBody.push(`<a href=${url.path}><p>Click to view video</p></a><br />`);
          }
        });

        var youtubeLink = formValue.youtubeUrl
          ? `<a href=${formValue.youtubeUrl}>Youtube Link</a>`
          : `<div />`;

        var htmlBody =
          htmlText + `${imageBody}` + `${youtubeLink}` + `${fileBody}` + `${videoBody}`;

        let htmlString = replaceAll(htmlBody, "\r", "<br/>");
        let htmlString2 = replaceAll(htmlString, "\n", "<br/>");

        yield fork(
          NotificationApi.sendAnnouncementEmails,
          htmlString2,
          selectedActivity + " " + firebase.schoolName,
          parentEmails,
          firebase
        );
      }
    } catch (err) {
      console.log("failed to post new activity", err);
      bugsnagClient.notify(err);
      bugsnagClient.leaveBreadcrumb("error object", err);
      yield put({
        type: actions.NEW_ACTIVITY_REQUEST_FAILED,
        errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
      });
    }
  }
}

function* updatePortfolioDocument(mediaPath, studentIds, firebase, attachment) {
  try {
    let originalDocName = undefined;
    if (
      attachment &&
      attachment.fileList &&
      attachment.fileList[0] &&
      attachment.fileList[0].name
    ) {
      originalDocName = attachment.fileList[0].name;
    }

    for (let index in studentIds) {
      let stdId = studentIds[index];

      let data = yield call(StudentApi.getAssessmentFolder, stdId, firebase);

      let status = false;

      for (let d in data) {
        let singleData = data[d];
        if (singleData && singleData.type && singleData.type === "folder") {
          status = true;
          break;
        }
      }

      if (!status) {
        let nodeId = yield call(StudentApi.createNewNoteId, firebase);

        yield call(StudentApi.addDocumentFolder, nodeId, stdId, "assessment", "", firebase);
      }

      let filePath = "assessment/";
      for (let ind in mediaPath) {
        let url = mediaPath[ind].path;
        let docId = yield call(StudentApi.getStudentDocNode, firebase);
        let docName = "portfolio_" + moment().format("DD[-]MMM[-]YYYY");
        if (originalDocName) {
          docName = originalDocName;
        }
        let obj = {
          filePath: filePath,
          createdBy: firebase.teacher.name,
          createdOn: new Date().getTime(),
          downloadUrl: url,
          id: docId,
          inverseDate: -new Date().getTime(),
          name: docName,
          nameWithExtension: docName,
          type: "doc",
        };

        yield call(StudentApi.uploadDocument, stdId, obj, firebase);
      }
    }
  } catch (err) {
    console.log("failed to update portfolio document", err);
    bugsnagClient.notify(err);
  }
}

function* sendStudentNotification(selectedActivity, activityId, studentIds, firebase) {
  for (let index in studentIds) {
    let selectedStudent = yield call(StudentApi.getStudentById, studentIds[index], firebase);
    if (selectedStudent && selectedStudent.id && selectedStudent.name) {
      if (selectedStudent.fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          selectedActivity + " activity added for " + selectedStudent.name,
          alertNode,
          selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );

        if (
          selectedStudent.fatherUUid !== undefined ||
          selectedStudent.ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
            selectedStudent.id,
            selectedStudent.fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent.motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          selectedActivity,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          selectedActivity + " activity added for " + selectedStudent.name,
          alertNode,
          selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );

        if (
          selectedStudent.motherUUid !== undefined ||
          selectedStudent.ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            selectedActivity,
            activityId,
            selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
            selectedActivity + " activity added for " + selectedStudent.name,
            alertNode,
            selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
            selectedStudent.id,
            selectedStudent.motherProfileId,
            firebase
          );
        }
      }
    }
  }
}

function* sendTeacherIncidentNotification(activityId, selectedActivity, taggedTeachers, firebase) {
  try {
    let teachers = JSON.parse(getItem("teacherList"));

    for (let index in taggedTeachers) {
      let teacherId = taggedTeachers[index];
      let filteredTeachers = teachers.filter((t) => {
        return t.id === teacherId;
      });

      if (filteredTeachers && filteredTeachers.length > 0) {
        let singleTeacher = filteredTeachers[0];

        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          singleTeacher.id,
          firebase
        );

        let obj = {
          activityName: selectedActivity,
          androidId: singleTeacher.uid ? singleTeacher.uid : null,
          iosId: singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
          body: "You have been tagged to  " + selectedActivity,
          id: alertNode,
          inverseTime: -new Date().getTime(),
          nodeId: activityId,
          read: false,
          senderName: firebase.teacher.name,
          type: "Activity",
          userType: "teacher",
          teacherId: singleTeacher.id,
        };

        yield fork(
          NotificationApi.createSimpleAlertNotification,
          obj.activityName,
          obj.nodeId,
          obj.androidId,
          obj.body,
          alertNode,
          obj.iosId,
          undefined,
          obj.teacherId,
          obj.type,
          undefined,
          firebase,
          undefined,
          obj.userType
        );

        if (obj.androidId !== undefined || obj.iosId !== undefined) {
          yield fork(
            NotificationApi.sendSimplePushNotification,
            obj.activityName,
            obj.nodeId,
            obj.androidId,
            obj.body,
            alertNode,
            obj.iosId,
            undefined,
            obj.teacherId,
            obj.type,
            undefined,
            firebase,
            undefined,
            obj.userType
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to send teacher notification for incident", err);
    bugsnagClient.notify(err);
  }
}

function* sendActivityApprovalRequest(activityId, firebase) {
  let allTeachers = JSON.parse(getItem("teacherList"));
  if (allTeachers && allTeachers.length > 0) {
    let admins = allTeachers.filter((item) => {
      if (
        FilterPermission.checkIfPermissionByTeacherRole(
          firebase,
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          item.role
        )
      ) {
        return item;
      }
    });

    let activityName = "Approval";
    for (let i in admins) {
      if (admins[i].id !== firebase.teacher.id) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          admins[i].id,
          firebase
        );

        let obj = {
          activityName: activityName,
          androidId: admins[i].uid ? admins[i].uid : null,
          iosId: admins[i].ios_uid ? admins[i].ios_uid : null,
          body: "Activity posted by " + firebase.teacher.name + " is waiting for approval",
          id: alertNode,
          inverseTime: -new Date().getTime(),
          nodeId: activityId,
          read: false,
          senderName: firebase.teacher.name,
          type: activityName,
          userType: "teacher",
          teacherId: admins[i].id,
        };

        yield fork(
          NotificationApi.createSimpleAlertNotification,
          obj.activityName,
          obj.nodeId,
          obj.androidId,
          obj.body,
          alertNode,
          obj.iosId,
          undefined,
          obj.teacherId,
          obj.type,
          undefined,
          firebase,
          undefined,
          obj.userType
        );

        if (obj.androidId !== undefined || obj.iosId !== undefined) {
          yield fork(
            NotificationApi.sendSimplePushNotification,
            obj.activityName,
            obj.nodeId,
            obj.androidId,
            obj.body,
            alertNode,
            obj.iosId,
            undefined,
            obj.teacherId,
            obj.type,
            undefined,
            firebase,
            undefined,
            obj.userType
          );
        }
      }
    }
  }
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function* fetchAllStaffs({ firebase }) {
  try {
    let data = yield call(DashboardApi.getAllStaff, firebase);
    if (data) {
      yield put({
        type: actions.GET_STAFF_FOR_NEW_ACTIVITY_SUCCESSFUL,
        staffs: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch staffs", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: error.message ? error.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* fetchActivitySetting({ firebase, activitySetting }) {
  try {
    if (activitySetting && activitySetting.length > 0) {
      yield put({
        type: actions.GET_ACTIVITY_SETTING_SUCCESSFUL,
        activitySetting: activitySetting,
        activitySettingMap: getActivitySettingMap(activitySetting),
      });
    }

    let data = yield call(ActivityApi.getActivitySetting, firebase);
    if (data) {
      let activities = data;
      activities.sort((a, b) => a.priority - b.priority);
      yield put({
        type: actions.GET_ACTIVITY_SETTING_SUCCESSFUL,
        activitySetting: activities,
        activitySettingMap: getActivitySettingMap(activities),
      });
    }
  } catch (err) {
    console.log("failed to fetch activity setting", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function getActivitySettingMap(activitySetting) {
  let obj = {};
  activitySetting.forEach((ele) => {
    obj[ele.activityType] = ele;
  });

  return obj;
}

function* fetchCommentSetting({ firebase }) {
  try {
    let data = yield call(ActivityApi.getCommentSettings, firebase);
    
    if (data) {
      yield put({
        type: actions.GET_COMMENT_SETTING_SUCCESS,
        commentSetting: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch comment setting", err);
    bugsnagClient.notify(err);
  }
}

// function* getCustomActivityFormSetting(firebase) {
//   try {
//     let data = yield call(ActivityApi.getCustomActivityFormSetting, firebase);
//     console.log("getCustomActivityFormSetting -----", data);
//     if (data) {
//       yield put({
//         type: actions.GET_CUSTOM_ACTIVITY_SETTING_SUCCESS,
//         customActivitySetting: data,
//       });
//     }
//   } catch (err) {
//     console.log("failed to fetch custom activity settings", err);
//     bugsnagClient.notify(err);
//   }
// }

function* fetchMedicalFields({ firebase }) {
  try {
    let data = yield call(ActivityApi.getMedicalField, firebase);
    if (data) {
      console.log("medical field -----", data);
      yield put({
        type: actions.GET_MEDICAL_FIELD_SUCCESS,
        medicalFields: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch medical fields", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* fetchPottyField({ firebase }) {
  try {
    let data = yield call(ActivityApi.getPottyField, firebase);
    if (data) {
      console.log("potty field -----", data);
      yield put({
        type: actions.GET_POTTY_FIELD_SUCCESS,
        pottyFields: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch potty fields", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* allCenterAnnouncementPost({
  selectedActivity,
  formValue,
  firebase,
  branchList,
  mediaType,
  selectedMode,
}) {
  try {
    let centerClasses = {};
    if (selectedMode === "group") {
      centerClasses = firebase.groupMap;
    } else {
      centerClasses = JSON.parse(getItem("classmap"));
    }

    let branches = branchList;
    let tempBranchMap = new Map();
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    // let branches = branchList;
    //for (let i in branches) {
    for (let [key, value] of tempBranchMap) {
      let bPath = key;
      let activityNode = yield call(ActivityApi.generateNewActivityNodeByCenter, firebase, bPath);

      let activityDate =
        selectedActivity === "Announcement" ? new Date() : new Date(formValue.date);

      let activityType;
      if (selectedActivity === "Announcement") {
        activityType = "Notification";
      } else {
        activityType = selectedActivity;
      }

      let classNames = [];
      let groupBasedStudents = [];
      if (selectedActivity === "Announcement") {
        if (selectedMode === "group") {
          let allGroups = value;
          for (let c in allGroups) {
            let singleStd = yield call(
              StudentApi.getStudentByGroup,
              allGroups[c].className,
              firebase,
              bPath
            );
            groupBasedStudents = [...new Set([...groupBasedStudents, ...singleStd])];
          }
          classNames = [...new Set(groupBasedStudents.map((item) => item.classroomName ? item.classroomName : item.classList[0]))];
        } else {
          let allClassrooms = value;
          if (allClassrooms) {
            allClassrooms.map((item) => {
              classNames.push(item.className);
            });
          }
        }
      }

      let mediaFormat;
      if (selectedActivity === "Photo") {
        mediaFormat = "none";
      } else if (selectedActivity === "Video") {
        mediaFormat = "none";
      } else {
        if (mediaType === "Photo") {
          mediaFormat = "none";
        } else if (mediaType === "Video") {
          mediaFormat = "video";
        } else if (mediaType === "File") {
          mediaFormat = "file";
        }
      }

      let mediaPaths = [];

      let message;
      let htmlText;
      let newMessage;
      if (selectedActivity === "Announcement") {
        message = formValue.annoucementMessage
          ? getNotePlainContent(formValue.annoucementMessage, selectedActivity)
          : null;
        newMessage = message;
        htmlText = formValue.annoucementMessage
          ? getNoteHtml(formValue.annoucementMessage, selectedActivity)
          : null;
      } else {
        message = formValue.note ? getNotePlainContent(formValue.note, selectedActivity) : null;
        newMessage = message;
        if (formValue.newNote) {
          newMessage = formValue.newNote;
        }
        htmlText = formValue.note ? getNoteHtml(formValue.note, selectedActivity) : null;
      }

      let name = selectedActivity;

      let templateMessage = null;

      let staffOnly = formValue.staffOnly ? formValue.staffOnly : false;

      let studentIds = [];
      if (selectedActivity === "Announcement") {
        studentIds = null;
      }

      let activityId = activityNode;
      let foodSource = activityType === "Food" ? "Meal" : null;
      let meal = formValue.mealType ? formValue.mealType : null;
      let foodMenu = null;
      let quantity = formValue.mealQuantity ? formValue.mealQuantity : null;
      let pottyDestination = formValue.pottyDestination ? formValue.pottyDestination : null;
      let pottyType = formValue.pottyType ? formValue.pottyType : null;
      let napStart = formValue.nap ? formValue.nap : null;
      let napEnd = null;
      let meetingId;
      let meetingTime;

      let repeatStartDate = 0;
      let repeatEndDate = 0;
      let includeSaturday = false;
      let daysOfWeek = formValue.daysOfWeek ? formValue.daysOfWeek : null;
      let meetingCapacity = null;
      let meetingBookable = false;
      let meetingDuration = formValue.meetingDuration ? formValue.meetingDuration : null;
      let enableParentComments = formValue.enableComments ? formValue.enableComments : false;
      let activityLabel = formValue.activityLabel ? formValue.activityLabel : null;
      let activityCreatedBy;

      let thumbNail;
      let youtubeUrlCode;
      if (formValue.youtubeUrl) {
        youtubeUrlCode = getYoutubeCode(formValue.youtubeUrl);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.NEW_ACTIVITY_REQUEST_FAILED,
          });
          return;
        }
        thumbNail = getYoutubeThumbnail(youtubeUrlCode);
      } else {
        thumbNail = null;
        youtubeUrlCode = null;
      }

      let taggedTeachers = [];
      if (formValue.taggedTeachers) {
        taggedTeachers = formValue.taggedTeachers;
      }

      let medicineGivenBy = formValue.medicineGivenBy ? formValue.medicineGivenBy : null;

      if (formValue.attachment) {
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          formValue.attachment,
          firebase,
          !staffOnly &&
            FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            )
            ? activityId
            : undefined,
          bPath,
          undefined,
          studentIds
        );

        if (urls) {
          mediaPaths = urls;
          yield call(
            ActivityApi.updateMedia,
            mediaPaths,
            mediaFormat,
            activityId,
            firebase,
            bPath,
            undefined,
            studentIds
          );
        }
      }

      yield fork(
        ActivityApi.addNewActivityToActivities,
        activityDate,
        activityType,
        classNames,
        mediaPaths,
        meetingId,
        meetingTime,
        message,
        name,
        templateMessage,
        staffOnly,
        studentIds,
        activityId,
        foodSource,
        meal,
        quantity,
        foodMenu,
        pottyDestination,
        pottyType,
        napStart,
        firebase,
        bPath,
        "",
        "",
        mediaFormat,
        thumbNail,
        youtubeUrlCode,
        repeatStartDate,
        repeatEndDate,
        includeSaturday,
        daysOfWeek,
        meetingCapacity,
        meetingBookable,
        meetingDuration,
        htmlText,
        enableParentComments,
        activityLabel,
        taggedTeachers,
        medicineGivenBy,
        formValue.lessonMilestoneValues,
        formValue.draft,
        undefined,
        formValue.noApproval,
        napEnd,
        newMessage,
        activityCreatedBy
      );

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", activityDate);

      let parentEmails = [];
      let names = [];
      let selectedStudent = [];

      if (selectedMode === "group") {
        selectedStudent = groupBasedStudents;
      } else {
        for (let i in classNames) {
          let students = yield call(
            StudentApi.getStudentByClassroomName,
            classNames[i],
            firebase,
            bPath
          );
          if (students) {
            selectedStudent = [...selectedStudent, ...students];
          }
        }
      }

      if (mediaPaths) {
        if (
          !staffOnly &&
          (FilterPermission.checkIfPermission(
            PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
            firebase
          ) ||
            formValue.noApproval) &&
          !formValue.draft
        ) {
          yield fork(
            ActivityApi.generateThumbnail,
            mediaPaths,
            activityId,
            "activity",
            studentIds,
            firebase
          );

          yield fork(updateStudentMedia, mediaPaths, selectedStudent, firebase, bPath, activityId);
        }
      }

      //student loop starts
      for (let i = 0; i < selectedStudent.length; i++) {
        let studentId = selectedStudent[i].id;

        if (selectedStudent[i].fatherEmail) {
          parentEmails.push(selectedStudent[i].fatherEmail);
        }
        if (selectedStudent[i].motherEmail) {
          parentEmails.push(selectedStudent[i].motherEmail);
        }

        if (
          selectedStudent[i].name &&
          selectedStudent[i].gender &&
          selectedStudent[i].classroomName
        ) {
          names.push({
            className:
              selectedStudent[i].classroomName &&
              selectedStudent[i].classroomName.length === undefined
                ? selectedStudent[i].classroomName
                : null,
            date: new Date().getTime(),
            fatherAndroidId: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
            fatherId: selectedStudent[i].fatherProfileId
              ? selectedStudent[i].fatherProfileId
              : null,
            fatherIosId: selectedStudent[i].ios_fatherUUid
              ? selectedStudent[i].ios_fatherUUid
              : null,
            gender: selectedStudent[i].gender ? selectedStudent[i].gender : "Male",
            motherId: selectedStudent[i].motherProfileId
              ? selectedStudent[i].motherProfileId
              : null,
            parentName: firebase.teacher.name,
            profileImageUrl: selectedStudent[i].profileImageUrl
              ? selectedStudent[i].profileImageUrl
              : null,
            studentId: selectedStudent[i].id,
            studentName: selectedStudent[i].name,
          });
        }

        let studentTimeline = yield call(
          ActivityApi.getStudentTimeline,
          activityDate,
          studentId,
          firebase,
          bPath
        );
        if (studentTimeline) {
          let newActivityIds =
            studentTimeline.activityIds && studentTimeline.activityIds.length > 0
              ? studentTimeline.activityIds
              : [];
          newActivityIds.push(activityId);

          let newTimelineData = {
            activityIds: newActivityIds,
            date: new Date().getTime(),
            inverseDate: -new Date().getTime(),
          };
          yield call(
            ActivityApi.updateStudentTimeline,
            activityDate,
            studentId,
            newTimelineData,
            firebase,
            bPath
          );
        }

        let activityTypeNodeId = yield call(
          ActivityApi.generateActivityTypeNode,
          activityType,
          studentId,
          firebase,
          bPath
        );
        if (activityTypeNodeId) {
          let creator = undefined;
          yield call(
            ActivityApi.updateActivityType,
            activityDate,
            studentId,
            activityId,
            activityTypeNodeId,
            activityType === "Notification" ? "Announcement" : activityType,
            firebase,
            bPath,
            meetingTime ? meetingTime : undefined,
            creator
          );
        }

        if (
          !staffOnly &&
          (FilterPermission.checkIfPermission(
            PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
            firebase
          ) ||
            formValue.noApproval) &&
          !formValue.draft
        ) {
          if (selectedStudent[i].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              bPath,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedActivity + " activity added for " + selectedStudent[i].name,
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase,
              bPath
            );

            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase,
                bPath
              );
            }
          }

          if (selectedStudent[i].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              bPath,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedActivity + " activity added for " + selectedStudent[i].name,
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase,
              bPath
            );

            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase,
                bPath
              );
            }
          }
        }
      }
      //student loop ends at top
      yield fork(ActivityApi.addUpdatedStudent, activityNode, names, firebase, bPath);

      /**send approval request if no auto approval permission */
      if (
        (!FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ) &&
          !formValue.noApproval) ||
        formValue.draft
      ) {
        yield fork(sendActivityApprovalRequest, activityId, firebase);
      }

      if (
        formValue.sendEmailCopy === "Email" &&
        (FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ) ||
          formValue.noApproval) &&
        !formValue.draft
      ) {
        var imageBody = [];
        var fileBody = [];
        var videoBody = [];
        mediaPaths.forEach((url) => {
          if (url.type.toLowerCase() === "photo" || url.type.toLowerCase() === "image") {
            imageBody.push(
              `<img src=${url.path} style={{ width: "80%", height: "80%" }} /> <br />`
            );
          } else if (url.type.toLowerCase() === "file") {
            fileBody.push(
              `<a href=${url.path}><p>Click to view file</p></a><br />
              <p>If the above link does not work then copy paste this url in the browser</p>
              <a href=${url.path}><p>${url.path}</p></a>`
            );
          } else if (url.type.toLowerCase() === "video") {
            videoBody.push(`<a href=${url.path}><p>Click to view video</p></a><br />`);
          }
        });

        var youtubeLink = formValue.youtubeUrl
          ? `<a href=${formValue.youtubeUrl}>Youtube Link</a>`
          : `<div />`;

        var htmlBody =
          htmlText + `${imageBody}` + `${youtubeLink}` + `${fileBody}` + `${videoBody}`;

        let htmlString = replaceAll(htmlBody, "\r", "<br/>");
        let htmlString2 = replaceAll(htmlString, "\n", "<br/>");

        yield fork(
          NotificationApi.sendAnnouncementEmails,
          htmlString2,
          selectedActivity + " " + firebase.schoolName,
          parentEmails,
          firebase
        );
      }
      yield fork(NotificationApi.sendStats, activityDate, "updateDailyPostStats", firebase, bPath);
    }
    yield put({
      type: actions.POST_ACTIVITY_TO_ALL_CENTER_SUCCESSFUL,
    });
  } catch (err) {
    bugsnagClient.notify("all center announncement error" + err.message ? err.message : err);
    console.log("failed to post all center announcement", err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* fetchCenterBasedClasses({ centers, firebase }) {
  try {
    let centerMap = new Map();
    for (let index in centers) {
      let bPath = centers[index];
      let allClasses = yield call(ClassroomApi.getClassroomsByCenter, firebase, bPath);
      if (allClasses) {
        centerMap.set(bPath, allClasses);
      }
    }

    yield put({
      type: actions.GET_CENTER_BASED_CLASSES_SUCCESS,
      centerClasses: centerMap,
    });
  } catch (err) {
    notification("error", formatMsg("error.somethingWrong"));
    console.log("failed to fetch center's classes", err);
    bugsnagClient.notify("failed to fetch center's classes" + err.message ? err.message : err);
  }
}

function* postActivityAllCenter({
  selectedActivity,
  selectedStudentList,
  selectedClassroom,
  formValue,
  firebase,
  mediaType,
  branchList,
  selectedMode,
}) {
  try {
    /**common activity node */
    let activityNode = yield call(ActivityApi.generateNewActivityNode, firebase);

    /**get meeting id, common to all centers */
    let meetingId;
    let meetingTime;
    let meetingUrl = "";
    let joinUrl = "";
    if (selectedActivity === "Virtual Class") {
      let response = yield call(
        zoomSettingApi.createZoomMeetinng,
        new Date(formValue.meetingDate),
        "Agenda",
        activityNode,
        firebase,
        formValue.meetingDuration
      );
      if (response && response.body && response.body.id) {
        meetingId = response.body.id.toString();
        meetingTime = new Date(formValue.meetingDate).getTime();
        meetingUrl = response.body.start_url;
        joinUrl = response.body.join_url;
      } else {
        if (response && response.body && response.body.error) {
          yield put({
            type: actions.NEW_ACTIVITY_REQUEST_FAILED,
            errorMessage: response.body.error,
          });
        } else {
          yield put({
            type: actions.NEW_ACTIVITY_REQUEST_FAILED,
            errorMessage: "Failed to create zoom meeting",
          });
        }
        bugsnagClient.notify("Failed to create zoom meeting" + response.text);
        return;
      }
    }

    /**center map  */
    let centerClasses = {};
    if (selectedMode === "group") {
      centerClasses = firebase.groupMap;
    } else {
      centerClasses = JSON.parse(getItem("classmap"));
    }

    let branches = branchList;
    let tempBranchMap = new Map();
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    for (let [key, val] of tempBranchMap) {
      let bPath = key;

      let activityDate;
      if (selectedActivity === "Announcement") {
        activityDate = new Date();
      } else if (selectedActivity === "Virtual Class") {
        activityDate = new Date();
      } else {
        if (formValue.time) {
          activityDate = new Date(formValue.time);
        } else {
          activityDate = new Date();
        }
      }

      let activityType;
      if (selectedActivity === "Announcement") {
        activityType = "Notification";
      } else if (selectedActivity === "Gross Motor") {
        activityType = "GrossMotor";
      } else if (selectedActivity === "Fine Motor") {
        activityType = "FineMotor";
      } else if (selectedActivity === "Self Reliance") {
        activityType = "SelfReliance";
      } else if (selectedActivity === "Block Hour") {
        activityType = "BlockHour";
      } else if (selectedActivity === "Art & Craft") {
        activityType = "Artcraft";
      } else {
        activityType = selectedActivity;
      }

      let classNames = [];
      let groupBasedStudents = [];

      if (val.length > 0) {
        if (selectedMode === "group") {
          let allGroups = val;
          for (let c in allGroups) {
            let singleStd = yield call(
              StudentApi.getStudentByGroup,
              allGroups[c].className,
              firebase,
              bPath
            );
            groupBasedStudents = [...new Set([...groupBasedStudents, ...singleStd])];
          }
          classNames = [...new Set(groupBasedStudents.map((item) => item.classroomName))];
        } else {
          val.map((c) => {
            classNames.push(c.className);
          });
        }
      }

      let selectedStudent = [];
      if (selectedMode === "group") {
        selectedStudent = groupBasedStudents;
      } else {
        for (let i in classNames) {
          let students = yield call(
            StudentApi.getStudentByClassroomName,
            classNames[i],
            firebase,
            bPath
          );
          if (students) {
            selectedStudent = [...selectedStudent, ...students];
          }
        }
      }

      let mediaFormat;
      if (selectedActivity === "Photo") {
        mediaFormat = "none";
      } else if (selectedActivity === "Video") {
        mediaFormat = "none";
      } else {
        if (mediaType === "Photo") {
          mediaFormat = "none";
        } else if (mediaType === "Video") {
          mediaFormat = "video";
        } else if (mediaType === "File") {
          mediaFormat = "file";
        } else {
          mediaFormat = "none";
        }
      }

      let mediaPaths = []; //need to generate url

      let message;
      let htmlText;
      let newMessage;
      if (selectedActivity === "Announcement") {
        message = formValue.annoucementMessage
          ? getNotePlainContent(formValue.annoucementMessage, selectedActivity)
          : null;
        newMessage = message;
        htmlText = formValue.annoucementMessage
          ? getNoteHtml(formValue.annoucementMessage, selectedActivity)
          : null;
      } else if (selectedActivity === "Virtual Class") {
        let formMsg = formValue.note ? getNotePlainContent(formValue.note, selectedActivity) : null;
        message = formMsg;
        newMessage = formMsg;
        htmlText = formValue.note ? getNoteHtml(formValue.note, selectedActivity) : null;
      } else {
        message = formValue.note ? getNotePlainContent(formValue.note, selectedActivity) : null;
        newMessage = message;

        if (formValue.newNote) {
          newMessage = formValue.newNote;
        }
        htmlText = formValue.note ? getNoteHtml(formValue.note, selectedActivity) : null;
      }

      let name = selectedActivity;
      let fieldConfigModels = formValue.fieldConfigModels ? formValue.fieldConfigModels : null;

      if (fieldConfigModels) {
        fieldConfigModels.forEach((fItem) => {
          if (formValue[fItem.id]) {
            let dataType = fItem.dataType.toLowerCase();
            if (dataType === "date") {
              fItem.value = moment(formValue[fItem.id]).format("DD[ ]MMM[ ]YY");
            } else if (dataType === "time") {
              fItem.value = moment(formValue[fItem.id]).format("h:mm A");
            } else if (dataType === "yes/no checkbox") {
              fItem.value = formValue[fItem.id] ? "Yes" : "No";
            } else if (dataType === "multi select") {
              fItem.value = formValue[fItem.id] ? formValue[fItem.id].toString() : [];
            } else {
              fItem.value = formValue[fItem.id];
            }
            message = (message ? message : "") + "\n" + "\n" + fItem.name + "\n" + fItem.value;
          }
        });
      }

      let templateMessage;
      if (activityType === "Medicine") {
        templateMessage = formValue.medicineName + " | " + formValue.medicineQuantity;
      } else if (activityType === "Incident") {
        templateMessage = formValue.incidentAction;
      } else if (activityType === "Food") {
        templateMessage = formValue.mealQuantity ? formValue.mealQuantity : null;
      } else {
        templateMessage = formValue.milestones ? formValue.milestones[0] : null;
      }

      let staffOnly = formValue.staffOnly ? formValue.staffOnly : false;

      let studentIds = [];
      if (selectedActivity === "Announcement") {
        studentIds = null;
      } else {
        selectedStudent.map((item) => {
          studentIds.push(item.id);
        });
        studentIds = studentIds.filter(Boolean);
      }

      let activityId = activityNode;
      let foodMenu = formValue.foodMenu ? formValue.foodMenu : null;
      let foodSource = activityType === "Food" ? "Meal" : null;
      let meal = formValue.mealType ? formValue.mealType : null;
      let quantity = formValue.mealQuantity ? formValue.mealQuantity : null;
      let pottyDestination = formValue.pottyDestination ? formValue.pottyDestination : null;
      let pottyType = formValue.pottyType ? formValue.pottyType : null;
      let napStart = formValue.nap ? formValue.nap : null;
      let napEnd = null;

      let repeatStartDate = 0;
      let repeatEndDate = 0;
      let includeSaturday = false;
      let daysOfWeek = formValue.daysOfWeek ? formValue.daysOfWeek : null;
      let meetingCapacity = null;
      let meetingBookable = false;
      let meetingDuration = formValue.meetingDuration ? formValue.meetingDuration : null;
      let enableParentComments = formValue.enableComments ? formValue.enableComments : false;
      if (formValue.repeatClass && formValue.repeatClass[0] && formValue.repeatClass[1]) {
        repeatStartDate = new Date(formValue.repeatClass[0]).getTime();
        repeatEndDate = new Date(formValue.repeatClass[1]).getTime();
      }
      let activityLabel = formValue.activityLabel ? formValue.activityLabel : null;

      if (formValue.includeSaturday) {
        includeSaturday = formValue.includeSaturday;
      }

      let thumbNail;
      let youtubeUrlCode;
      if (formValue.youtubeUrl) {
        youtubeUrlCode = getYoutubeCode(formValue.youtubeUrl);
        if (youtubeUrlCode === "not valid") {
          yield put({
            type: actions.NEW_ACTIVITY_REQUEST_FAILED,
          });
          return;
        }
        thumbNail = getYoutubeThumbnail(youtubeUrlCode);
      } else {
        thumbNail = null;
        youtubeUrlCode = null;
      }
      let activityCreatedBy;
      let taggedTeachers = [];
      if (formValue.taggedTeachers) {
        taggedTeachers = formValue.taggedTeachers;
      }

      let medicineGivenBy = formValue.medicineGivenBy ? formValue.medicineGivenBy : null;

      yield call(
        ActivityApi.addNewActivityToActivities,
        activityDate,
        activityType,
        classNames,
        mediaPaths,
        meetingId,
        meetingTime,
        message,
        name,
        templateMessage,
        staffOnly,
        studentIds,
        activityId,
        foodSource,
        meal,
        quantity,
        foodMenu,
        pottyDestination,
        pottyType,
        napStart,
        firebase,
        bPath,
        meetingUrl,
        joinUrl,
        mediaFormat,
        thumbNail,
        youtubeUrlCode,
        repeatStartDate,
        repeatEndDate,
        includeSaturday,
        daysOfWeek,
        meetingCapacity,
        meetingBookable,
        meetingDuration,
        htmlText,
        enableParentComments,
        activityLabel,
        taggedTeachers,
        medicineGivenBy,
        formValue.lessonMilestoneValues,
        formValue.draft,
        fieldConfigModels,
        formValue.noApproval,
        napEnd,
        newMessage,
        activityCreatedBy
      );

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "activity", activityDate);

      if (activityType === "Virtual Class") {
        let meetingRefNode = yield call(ActivityApi.createMeetingRefNode, firebase, bPath);
        yield fork(
          ActivityApi.updateMeetingRef,
          meetingTime,
          activityId,
          meetingRefNode,
          firebase,
          bPath
        );
      }

      //update stats count for dashboard
      yield fork(NotificationApi.sendStats, activityDate, "updateDailyPostStats", firebase, bPath);

      let parentEmails = [];
      let names = [];
      for (let i = 0; i < selectedStudent.length; i++) {
        let studentId = selectedStudent[i].id;

        if (selectedStudent[i].fatherEmail) {
          parentEmails.push(selectedStudent[i].fatherEmail);
        }

        if (selectedStudent[i].motherEmail) {
          parentEmails.push(selectedStudent[i].motherEmail);
        }

        names.push({
          className:
            selectedStudent[i].classroomName &&
            selectedStudent[i].classroomName.length === undefined
              ? selectedStudent[i].classroomName
              : null,
          date: new Date().getTime(),
          fatherAndroidId: selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
          fatherId: selectedStudent[i].fatherProfileId ? selectedStudent[i].fatherProfileId : null,
          fatherIosId: selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
          gender: selectedStudent[i].gender ? selectedStudent[i].gender : "Male",
          motherId: selectedStudent[i].motherProfileId ? selectedStudent[i].motherProfileId : null,
          parentName: firebase.teacher.name,
          profileImageUrl: selectedStudent[i].profileImageUrl
            ? selectedStudent[i].profileImageUrl
            : null,
          studentId: selectedStudent[i].id,
          studentName: selectedStudent[i].name,
        });

        let studentTimeline = yield call(
          ActivityApi.getStudentTimeline,
          activityDate,
          studentId,
          firebase,
          bPath
        );

        if (studentTimeline) {
          let newActivityIds =
            studentTimeline.activityIds && studentTimeline.activityIds.length > 0
              ? studentTimeline.activityIds
              : [];
          newActivityIds.push(activityId);

          let newTimelineData = {
            activityIds: newActivityIds,
            date: new Date().getTime(),
            inverseDate: -new Date().getTime(),
          };

          yield call(
            ActivityApi.updateStudentTimeline,
            activityDate,
            studentId,
            newTimelineData,
            firebase,
            bPath
          );
        } else {
          bugsnagClient.notify(new Error("failed to upload activity to student timeline"));
        }
        //handle else case

        let activityTypeNodeId = yield call(
          ActivityApi.generateActivityTypeNode,
          activityType,
          studentId,
          firebase,
          bPath
        );
        if (activityTypeNodeId) {
          let creator = undefined;
          yield call(
            ActivityApi.updateActivityType,
            activityDate,
            studentId,
            activityId,
            activityTypeNodeId,
            activityType === "Notification" ? "Announcement" : activityType,
            firebase,
            bPath,
            meetingTime ? meetingTime : undefined,
            creator
          );
        }

        if (
          !staffOnly &&
          (FilterPermission.checkIfPermission(
            PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
            firebase
          ) ||
            formValue.noApproval) &&
          !formValue.draft
        ) {
          if (selectedStudent[i].fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].fatherProfileId,
              bPath,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
              selectedActivity + " activity added for " + selectedStudent[i].name,
              alertNode,
              selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].fatherProfileId,
              firebase,
              bPath
            );

            if (
              selectedStudent[i].fatherUUid !== undefined ||
              selectedStudent[i].ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].fatherUUid ? selectedStudent[i].fatherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_fatherUUid ? selectedStudent[i].ios_fatherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].fatherProfileId,
                firebase,
                bPath
              );
            }
          }

          if (selectedStudent[i].motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createSimpleAlertReferenceNode,
              selectedStudent[i].motherProfileId,
              bPath,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              selectedActivity,
              activityId,
              selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
              selectedActivity + " activity added for " + selectedStudent[i].name,
              alertNode,
              selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
              selectedStudent[i].id,
              selectedStudent[i].motherProfileId,
              firebase,
              bPath
            );

            if (
              selectedStudent[i].motherUUid !== undefined ||
              selectedStudent[i].ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                selectedActivity,
                activityId,
                selectedStudent[i].motherUUid ? selectedStudent[i].motherUUid : null,
                selectedActivity + " activity added for " + selectedStudent[i].name,
                alertNode,
                selectedStudent[i].ios_motherUUid ? selectedStudent[i].ios_motherUUid : null,
                selectedStudent[i].id,
                selectedStudent[i].motherProfileId,
                firebase,
                bPath
              );
            }
          }
        }
      }

      yield fork(ActivityApi.addUpdatedStudent, activityNode, names, firebase, bPath);

      /**send approval request if no auto approval permission */
      if (
        (!FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ) &&
          !formValue.noApproval) ||
        formValue.draft
      ) {
        yield fork(sendActivityApprovalRequest, activityId, firebase);
      }

      //attachment upload flow
      if (formValue.attachment) {
        let storagePath = firebase.sbp + "/media/mediaProfiles/";
        let urls = yield call(
          ActivityApi.getAttachmentMediaPath,
          storagePath,
          formValue.attachment,
          firebase,
          !staffOnly &&
            FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            )
            ? activityId
            : undefined,
          bPath,
          undefined,
          studentIds
        );

        if (urls) {
          mediaPaths = urls;

          if (
            !staffOnly &&
            (FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            ) ||
              formValue.noApproval) &&
            !formValue.draft
          ) {
            yield fork(
              ActivityApi.generateThumbnail,
              mediaPaths,
              activityId,
              "activity",
              studentIds,
              firebase
            );

            yield fork(
              updateStudentMedia,
              mediaPaths,
              selectedStudent,
              firebase,
              bPath,
              activityId
            );
          }

          yield call(ActivityApi.updateMedia, mediaPaths, mediaFormat, activityId, firebase, bPath);
        }
      }
    }
    yield put({
      type: actions.POST_ACTIVITY_ALL_CENTER_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to post multi center activity", err);
    bugsnagClient.notify("failed to post multi center activity", err);
    yield put({
      type: actions.NEW_ACTIVITY_REQUEST_FAILED,
      errorMessage: err.message ? err.message : formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* updateInitialVal() {
  yield put({
    type: actions.GET_INITIAL_VALUE_SUCCESS,
  });
}

function* updateStudentMedia(urls, students, firebase, bPath, activityId) {
  try {
    for (let index in students) {
      for (let i in urls) {
        if (urls[i].type && urls[i].type.toLowerCase() !== "file") {
          let nodeId = yield call(ActivityApi.createStudentMediaNode, firebase, bPath);
          let photoDate = new Date();
          let singleUrl = urls[i];
          let localMediaType;
          if (singleUrl.type.toLowerCase() === "image") {
            localMediaType = "photo";
          } else {
            localMediaType = singleUrl.type;
          }

          let createdAt = {
            date: photoDate.getDate(),
            day: photoDate.getDay(),
            hours: photoDate.getHours(),
            minutes: photoDate.getMinutes(),
            month: photoDate.getMonth(),
            seconds: photoDate.getSeconds(),
            time: photoDate.getTime(),
            timezoneOffset: photoDate.getTimezoneOffset(),
            year: photoDate.getFullYear(),
          };

          let photoObj = {
            date: createdAt,
            downloadUrl: singleUrl.path,
            id: nodeId,
            inverseDate: -photoDate.getTime(),
            mediaType: localMediaType.toUpperCase(),
            activityId: activityId ? activityId : null,
          };

          if (photoObj.mediaType && photoObj.mediaType.toLowerCase() !== "video") {
            yield fork(
              ActivityApi.updateStudentMediaAttachment,
              photoObj,
              nodeId,
              students[index].id,
              firebase,
              bPath
            );
          }
        }
      }
    }
  } catch (err) {
    console.log("failed to upload activity media to student", err);
    bugsnagClient.notify("failed to upload activity media to student", err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_STUDENT_TO_POST_NEW_ACTIVITY, fetchAllStudent),
    yield takeLatest(actions.GET_CLASSROOM_FOR_NEW_ACTIVITY, fetchAllClassroom),
    yield takeLatest(actions.GET_ACTIVITY_TEMPLATE, fetchActivityTemplate),
    yield takeEvery(actions.POST_ACTIVITY, postActivity),
    yield takeLatest(actions.GET_STAFF_FOR_NEW_ACTIVITY, fetchAllStaffs),
    yield takeLatest(actions.GET_ACTIVITY_SETTING, fetchActivitySetting),
    yield takeLatest(actions.GET_COMMENT_SETTING, fetchCommentSetting),
    yield takeEvery(actions.POST_ACTIVITY_TO_ALL_CENTER, allCenterAnnouncementPost),
    yield takeLatest(actions.GET_MEDICAL_FIELD, fetchMedicalFields),
    yield takeLatest(actions.GET_FOOD_MENU, fetchFoodMenuForToday),
    yield takeLatest(actions.GET_CENTER_BASED_CLASSES, fetchCenterBasedClasses),
    yield takeEvery(actions.POST_ACTIVITY_ALL_CENTER, postActivityAllCenter),
    yield takeEvery(actions.GET_INITIAL_VALUE, updateInitialVal),
    yield takeEvery(actions.GET_POTTY_FIELD, fetchPottyField),
  ]);
}
