import antdHu from "antd/lib/locale-provider/hu_HU";
import appLocaleData from "react-intl/locale-data/hu";
import huMessages from "../locales/hu_HU.json";

const Hulang = {
  messages: {
    ...huMessages,
  },
  antd: antdHu,
  locale: "hu",
  data: appLocaleData,
};
export default Hulang;
