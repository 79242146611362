import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore";
import moment from "moment-timezone";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { CommentApi } from "../../firestore-api/comment";
import { ComplainsApi } from "../../firestore-api/consult";
import { LeavesApi } from "../../firestore-api/leaves";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { NotificationApi } from "../../firestore-api/notification";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { getItem } from "../../Utility/encryptedStorage";
import actions from "./actions";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import FilterAction from "../../Utility/FilterAction";

function* fetchAllTeachersForLeave({ firebase }) {
  let data = JSON.parse(getItem("teacherList"));

  if (data && data.length > 0) {
    data = data.filter((ele) => {
      return !ele.deactivated;
    });
  }

  yield put({
    type: actions.GET_ALL_TEACHERS_FOR_LEAVE_SUCCESSFUL,
    allTeachersList: data,
  });
}

function* fetchAllStudentsForLeave({ firebase }) {
  let data = firebase.studentsList;
  let classroomData = JSON.parse(getItem("classList"));

  data = data.filter((item) => {
    return (!item.status || item.status.toLowerCase() === "active") && !item.deactivated;
  });

  yield put({
    type: actions.GET_ALL_STUDENTS_FOR_LEAVE_SUCCESSFUL,
    allStudentsList: data,
    classrooms: classroomData,
  });
}

function* sendStudentLeaveNotification(selectedStudent, activityName, activityId, body, firebase) {
  if (selectedStudent && selectedStudent.id) {
    if (selectedStudent.fatherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.fatherProfileId,
        firebase
      );
      yield fork(
        NotificationApi.createAlertNotification,
        activityName,
        activityId,
        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
        body,
        alertNode,
        selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
        selectedStudent.id,
        selectedStudent.fatherProfileId,
        firebase
      );

      if (
        selectedStudent.fatherUUid !== undefined ||
        selectedStudent.ios_fatherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          activityName,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );
      }
    }

    if (selectedStudent.motherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        selectedStudent.motherProfileId,
        firebase
      );
      yield fork(
        NotificationApi.createAlertNotification,
        activityName,
        activityId,
        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
        body,
        alertNode,
        selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
        selectedStudent.id,
        selectedStudent.motherProfileId,
        firebase
      );

      if (
        selectedStudent.motherUUid !== undefined ||
        selectedStudent.ios_motherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,
          activityName,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );
      }
    }
  }
}

function* fetchLeaveDetails({ leaveId, isStaff, firebase }) {
  try {
    let data = {};

    data = yield call(LeavesApi.fetchSelectedLeaveDetails, leaveId, isStaff, firebase);

    yield put({
      type: actions.FETCH_LEAVE_DETAILS_SUCCESSFUL,
      leaveDetails: data,
    });
  } catch (error) {
    notification("error", formatMsg("error.fetchRequest"));
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* submitStaffLeaveDetails({
  values,
  startDate,
  endDate,
  selectedStaffForLeave,
  leaveId,
  firebase,
  halfDayLeave,
  selectedTeacherLeave,
  leaveSetting,
}) {
  try {
    if (!leaveId) {
      let url = "woodlandApi/saveStaffLeave/" + "?centerId=";

      var staffLeave = {
        createdBy: firebase.teacher.name ? firebase.teacher.name : null,
        endDate: endDate ? endDate : null,
        leaveCategoryId: values.leaveTypeId ? values.leaveTypeId : null,
        includeSaturday: false,
        leaveType: values.leaveType ? values.leaveType : null,
        mediaType: null,
        platform: "web",
        reason: values.reason ? values.reason : null,
        reopened: false,
        repeat: false,
        repeatEndDate: 0,
        repeatStartDate: 0,
        staff: true,
        startDate: startDate ? startDate : null,
        status: "pending",
        createdOn: moment().valueOf(),
        type: "leave",
        uploadStatus: "",
        halfDayLeave: values.halfDayLeave ? values.halfDayLeave : null,
        createdById: firebase.teacher.id
      };

      let obj = {
        staffList: selectedStaffForLeave,
        staffLeave: staffLeave,
      };

      let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);

      if (response && response.status && response.status === 200) {
        let approvePermission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_LEAVES_APPROVE,
          firebase
        )
        let notificationMessage = formatMsg("success.leavesPosted");
        if (approvePermission) {
          notificationMessage = formatMsg("success.leavesApproved")
        }
        notification("success", notificationMessage);
        yield put({
          type: actions.SUBMIT_STAFF_LEAVE_SUCCESSFUL,
          redirectStatus: true,
        });
      } else if (response && response.status && response.status === 424) {
        notification("error", formatMsg("leave.unsuccessful"));

        yield put({
          type: actions.LEAVE_REQUEST_FAILED,
          unsuccesfulAppliedstaffs: response.body.staffList,
          moreThanAllocatedLeavesTaken: true,
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to save leave. Contact school or Illumine"
        );
        yield put({
          type: actions.LEAVE_REQUEST_FAILED,
        });
      }
    } else {
      selectedTeacherLeave.startDate = startDate ? startDate : null;
      selectedTeacherLeave.endDate = endDate ? endDate : null;
      selectedTeacherLeave.leaveCategoryId = values.leaveTypeId ? values.leaveTypeId : null;
      selectedTeacherLeave.leaveType = values.leaveType ? values.leaveType : null;
      selectedTeacherLeave.reason = values.reason;
      selectedTeacherLeave.status = "pending";
      selectedTeacherLeave.updatedOn = moment().valueOf();
      selectedTeacherLeave.updatedBy = firebase.teacher.name;
      selectedTeacherLeave.platform = "web";
      selectedTeacherLeave.halfDayLeave = values.halfDayLeave;
      selectedTeacherLeave.updatedById = firebase.teacher.id
      let staffLeave = selectedTeacherLeave;
      let obj = {
        staffLeave: staffLeave,
        staffList: selectedStaffForLeave,
      };

      let url = "woodlandApi/saveStaffLeave/" + "?centerId=";
      let response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);
      if (response && response.status && response.status === 200) {
        notification("success", formatMsg("success.leavesUpdated"));
        yield put({
          type: actions.SUBMIT_STAFF_LEAVE_SUCCESSFUL,
          redirectStatus: true,
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to update leave. Contact school or Illumine"
        );
        yield put({
          type: actions.LEAVE_REQUEST_FAILED,
        });
      }
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* submitStudentLeaveDetails({
  values,
  startDate,
  endDate,
  selectedStudentList,
  leaveId,
  firebase,
}) {
  try {
    if (!leaveId) {
      for (let i in selectedStudentList) {
        var leaveRefId = yield call(LeavesApi.generateUniqueLeavesId, false, firebase);

        yield call(
          LeavesApi.submitNewStudentLeave,
          values,
          startDate,
          endDate,
          selectedStudentList[i],
          leaveRefId,
          firebase
        );
        let alertMessage = firebase.teacher.name + " created leave request";

        yield fork(
          sendStudentLeaveNotification,
          selectedStudentList[i],
          "LEAVE",
          leaveRefId,
          alertMessage,
          firebase
        );
      }
      notification("success", formatMsg("success.leavesPosted"));
    } else {
      leaveRefId = leaveId;

      yield call(
        LeavesApi.updateSelectedLeave,
        values,
        startDate,
        endDate,
        leaveRefId,
        false,
        firebase
      );
      let alertMessage = firebase.teacher.name + " updated leave request";

      yield fork(
        sendStudentLeaveNotification,
        selectedStudentList[0],
        "LEAVE",
        leaveRefId,
        alertMessage,
        firebase
      );

      notification("success", formatMsg("success.leavesUpdated"));
    }

    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "communication", moment());

    yield put({
      type: actions.SUBMIT_STUDENT_LEAVE_SUCCESSFUL,
      redirectStatus: true,
    });
  } catch (error) {
    notification("error", formatMsg("error.submitLeave"));
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* fetchAllLeaves({
  firebase,
  startDate,
  endDate,
  initialCall,
  selectedFrequency,
  dayLevelFilter,
  check,
}) {
  const chan = yield call(
    ComplainsApi.getAllLeaves,
    firebase,
    startDate,
    endDate,
    selectedFrequency,
    dayLevelFilter
  );
  try {
    while (true) {
      let data = yield take(chan);
      let x = data;
      x.sort(function (a, b) {
        var dateA = a.appliedDate,
          dateB = b.appliedDate;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_LEAVES_SUCCESSFUL,
        leaves: x,
        leavesChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
        check: check ? 1 : undefined,
      });
    }
  } finally {
    console.log("end leaves channel");
  }
}

function* fetchNotificationLeave({ id, firebase }) {
  const chan = yield call(ComplainsApi.getLeaveById, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_LEAVES_SUCCESSFUL,
        leaves: data,
        leavesChannel: chan,
        operationType: undefined,
      });
    }
  } finally {
    console.log("terminating notification leaves");
  }
}

function* fetchNotificationStaffLeave({ id, firebase }) {
  const chan = yield call(ComplainsApi.getStaffLeaveById, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_ALL_STAFF_LEAVES_SUCCESSFUL,
        staffLeaves: data,
        staffLeavesChannel: chan,
        operationType: undefined,
      });
    }
  } finally {
    console.log("terminating notification leaves");
  }
}

function* fetchCommentsForSelectedLeave({ leaves, firebase }) {
  try {
    let activities = leaves;
    var commentsCount = new Map();
    for (const item in activities) {
      var commentCountObj = yield call(
        lessonAssignmentApi.getCommentsCount,
        activities[item].id,
        firebase
      );
      if (commentCountObj) {
        commentsCount.set(activities[item].id, commentCountObj);
      }
    }
    yield put({
      type: actions.GET_COMMENT_FOR_LEAVE_SUCCESSFUL,
      leavesComment: commentsCount,
    });
  } catch (error) {
    console.log("failed to fetch comment for selected leave", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* resolveSelectedLeave({ record, status, firebase }) {
  try {
    let activityId = record.id;
    let activityName = "LEAVE";
    let body;
    let task = record;
    if (status === "deny") {
      body = "Admin denied leave request";
      task.status = "denied";
    } else if (status === "approve") {
      body = "Admin updated leave request";
      task.status = "approved";
    }

    yield fork(ComplainsApi.updateLeave, task, firebase);
    let selectedStudent = yield call(StudentApi.getStudentById, record.studentId, firebase);
    if (selectedStudent && selectedStudent.id) {
      if (selectedStudent.fatherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.fatherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
          selectedStudent.id,
          selectedStudent.fatherProfileId,
          firebase
        );

        if (
          selectedStudent.fatherUUid !== undefined ||
          selectedStudent.ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
            body,
            alertNode,
            selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
            selectedStudent.id,
            selectedStudent.fatherProfileId,
            firebase
          );
        }
      }

      if (selectedStudent.motherProfileId) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent.motherProfileId,
          firebase
        );
        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
          body,
          alertNode,
          selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
          selectedStudent.id,
          selectedStudent.motherProfileId,
          firebase
        );

        if (
          selectedStudent.motherUUid !== undefined ||
          selectedStudent.ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
            body,
            alertNode,
            selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
            selectedStudent.id,
            selectedStudent.motherProfileId,
            firebase
          );
        }
      }
    }

    yield fork(NotificationApi.callDashboardRefreshApi, firebase, "communication", moment());
    yield put({
      type: actions.RESOLVE_LEAVE_SUCCESSFUL,
      operationType: "REFETCH_LEAVE",
    });
  } catch (err) {
    console.log("failed to resolve leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* addLeaveComment({ comment, dataSource, firebase }) {
  try {
    var commentObject = {
      author: firebase.teacher.name,
      authorId: firebase.teacher.id,
      id: "",
      gender: firebase.teacher.gender ? firebase.teacher.gender : null,
      inverseTimestamp: -new Date().getTime(),
      sourceId: dataSource.id,
      src: "COMMENT",
      text: comment.comment,
      timestamp: new Date().getTime(),
      userType: "TEACHER",
    };
    let nodeId = yield call(CommentApi.createCommentNode, dataSource.id, firebase);
    commentObject.id = nodeId;
    yield call(CommentApi.updateComment, commentObject, dataSource.id, nodeId, firebase);
    yield put({
      type: actions.SAVE_LEAVE_COMMENT_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to add leave comment", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* fetchStudentOnLeave({ firebase }) {
  try {
    let startTime = moment().startOf("week").valueOf();
    let endTime = moment().endOf("week").valueOf();
    let leaves = yield call(ComplainsApi.getAllPendingLeaves, firebase, startTime, endTime);

    let currentDayLeave = [];
    let currentWeekLeave = [];
    if (leaves) {
      currentWeekLeave = leaves;
      for (let index in leaves) {
        let singleLeave = leaves[index];
        if (
          moment(moment().startOf("day").valueOf()).isSame(moment(singleLeave.startDate), "day") ||
          moment(moment().startOf("day").valueOf()).isBetween(
            moment(singleLeave.startDate),
            moment(singleLeave.endDate),
            "day",
            "[]"
          )
        ) {
          currentDayLeave.push(singleLeave);
        }
      }
    }

    console.log("studentLeaveToday", currentDayLeave);
    console.log("studentLeaveWeek", currentWeekLeave);

    yield put({
      type: actions.GET_STUDENTS_ON_LEAVE_SUCCESS,
      studentLeaveToday: currentDayLeave,
      studentLeaveWeek: currentWeekLeave,
    });
  } catch (err) {
    console.log("failed to fetch student on leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* fetchAllStaffLeaves({
  firebase,
  startDate,
  endDate,
  initialCall,
  selectedFrequency,
  dayLevelFilter,
  check,
}) {
  const chan = yield call(
    ComplainsApi.getAllStaffLeaves,
    firebase,
    startDate,
    endDate,
    selectedFrequency,
    dayLevelFilter
  );
  try {
    while (true) {
      let data = yield take(chan);
      let x = data;
      x.sort(function (a, b) {
        var dateA = a.appliedDate,
          dateB = b.appliedDate;
        return dateB - dateA;
      });
      yield put({
        type: actions.GET_ALL_STAFF_LEAVES_SUCCESSFUL,
        staffLeaves: x,
        staffLeavesChannel: chan,
        operationType: initialCall ? "INITIAL_CALL" : undefined,
        checkforStaff: check ? 1 : undefined,
      });
    }
  } finally {
    console.log("end leaves channel");
  }
}

function* resolveSelectedStaffLeave({ record, status, firebase }) {
  try {
    let activityId = record.id;
    let activityName = "Staff Leave";
    let body;
    let task = record;
    if (status === "deny") {
      body = "Admin denied  leave request";
      task.status = "denied";
    } else if (status === "approve") {
      body = "Admin updated  leave request";
      task.status = "approved";
    }

    yield call(ComplainsApi.updateStaffLeave, task, firebase);
    yield fork(sendTeacherNotification, record, activityName, activityId, body, firebase);
    yield put({
      type: actions.RESOLVE_STAFF_LEAVE_SUCCESSFUL,
      operationType: "REFETCH_STAFF_LEAVE",
    });
  } catch (err) {
    console.log("failed to resolve staff leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* sendTeacherNotification(record, activityName, activityId, body, firebase) {
  try {
    let activityType = "Staff Leave";
    let teachers = JSON.parse(getItem("teacherList"));
    let teacher = teachers.filter((item) => {
      return item.id === record.studentId;
    });

    if (teacher && teacher.length > 0) {
      let singleTeacher = teacher[0];
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,
        singleTeacher.id,
        firebase
      );
      let creator = undefined;
      yield fork(
        NotificationApi.createSimpleAlertNotification,
        activityName,
        activityId,
        singleTeacher.uid ? singleTeacher.uid : null,
        body,
        alertNode,
        singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
        null,
        singleTeacher.id,
        activityType,
        undefined,
        firebase,
        creator,
        "teacher"
      );

      if (singleTeacher.uid !== undefined || singleTeacher.ios_uid !== undefined) {
        yield fork(
          NotificationApi.sendSimplePushNotification,
          activityName,
          activityId,
          singleTeacher.uid ? singleTeacher.uid : null,
          body,
          alertNode,
          singleTeacher.ios_uid ? singleTeacher.ios_uid : null,
          null,
          singleTeacher.id,
          activityType,
          undefined,
          firebase,
          creator
        );
      }
    }
  } catch (err) {
    console.log("failed to send staff leave notification", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStaffOnLeave({ firebase }) {
  try {
    let startTime = moment().startOf("week").valueOf();
    let endTime = moment().endOf("week").valueOf();
    let leaves = yield call(ComplainsApi.getAllPendingStaffLeaves, firebase, startTime, endTime);

    let currentDayLeave = [];
    let currentWeekLeave = [];
    if (leaves) {
      currentWeekLeave = leaves;
      for (let index in leaves) {
        let singleLeave = leaves[index];
        if (
          moment(moment().startOf("day").valueOf()).isSame(moment(singleLeave.startDate), "day") ||
          moment(moment().startOf("day").valueOf()).isBetween(
            moment(singleLeave.startDate),
            moment(singleLeave.endDate),
            "day",
            "[]"
          )
        ) {
          currentDayLeave.push(singleLeave);
        }
      }
    }

    yield put({
      type: actions.GET_STAFFS_ON_LEAVE_SUCCESS,
      staffLeaveToday: currentDayLeave,
      staffLeaveWeek: currentWeekLeave,
    });
  } catch (err) {
    console.log("failed to fetch staffs on leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedLeave({ item, firebase, isStaff }) {
  try {
    if (isStaff) {
      let url =
        "woodlandApi/deleteStaffLeave/" +
        item.id +
        "?staffId=" +
        item.studentId +
        "&leaveCategoryId=" +
        item.leaveCategoryId +
        "&centerId=";
      let response = yield call(StudentAttendanceApi.deleteApi, url, firebase);

      if (response && response.status && response.status === 200) {
        notification("success", formatMsg("leave.delete"));
        yield put({
          type: actions.DELETE_LEAVE_SUCCESS,
          operationType: "REFETCH_STAFF_LEAVE",
        });
      } else {
        notification(
          "error",
          response && response.body && response.body.response
            ? response.body.response
            : "Failed to delete leave. Contact school or Illumine"
        );
        yield put({
          type: actions.LEAVE_REQUEST_FAILED,
        });
      }
    } else {
      yield call(LeavesApi.deleteLeave, item, firebase, isStaff);
      notification("success", formatMsg("success.deleteLeave"));
      yield put({
        type: actions.DELETE_LEAVE_SUCCESS,
        operationType: "REFETCH_LEAVE",
      });
    }
  } catch (err) {
    console.log("failed to delete leave", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.LEAVE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_ALL_TEACHERS_FOR_LEAVE, fetchAllTeachersForLeave),
    yield takeLatest(actions.GET_ALL_STUDENTS_FOR_LEAVE, fetchAllStudentsForLeave),
    yield takeLatest(actions.GET_ALL_LEAVES, fetchAllLeaves),
    yield takeLatest(actions.GET_COMMENT_FOR_LEAVE, fetchCommentsForSelectedLeave),
    yield takeLatest(actions.RESOLVE_LEAVE, resolveSelectedLeave),
    yield takeLatest(actions.SAVE_LEAVE_COMMENT, addLeaveComment),
    yield takeLatest(actions.GET_NOTIFICATION_LEAVE, fetchNotificationLeave),
    yield takeLatest(actions.GET_NOTIFICATION_STAFF_LEAVE, fetchNotificationStaffLeave),
    yield takeLatest(actions.GET_STUDENTS_ON_LEAVE, fetchStudentOnLeave),
    yield takeLatest(actions.GET_ALL_STAFF_LEAVES, fetchAllStaffLeaves),
    yield takeLatest(actions.RESOLVE_STAFF_LEAVE, resolveSelectedStaffLeave),
    yield takeLatest(actions.GET_STAFFS_ON_LEAVE, fetchStaffOnLeave),
    yield takeLatest(actions.SUBMIT_STAFF_LEAVE, submitStaffLeaveDetails),
    yield takeLatest(actions.SUBMIT_STUDENT_LEAVE, submitStudentLeaveDetails),
    yield takeLatest(actions.FETCH_LEAVE_DETAILS, fetchLeaveDetails),
    yield takeLatest(actions.DELETE_LEAVE, deleteSelectedLeave),
  ]);
}
