import actions from "./actions";

const initState = {
  isSaved: false,
  closeEditor: false,
  isLoading: false,
  studentList: [],
  autoCompleteCenter: [],
  autoCompleteClassroom: [],
  classrooms: [],
  isSent: false,
  uploadProgress: undefined,
  uploadFileUrl: undefined,
  isSpinning: false,
  error: false,
  errorInfo: undefined,
  allNewsletters: [],
  allNewslettersChannel: undefined,
};

export default function newsletterReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SAVE_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaved: true,
      };
    case actions.SAVE_NEWSLETTER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SEND_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isSent: true,
        isLoading: false,
        //allDraftNewspaper: []
      };
    case actions.NEWSLETTER_REQUEST_FAILED:
      return {
        ...state,
        isSent: false,
        isLoading: false,
        error: true,
        errorInfo: action.errorInfo,
      };
    case actions.FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.progress,
      };
    case actions.FILE_UPLOAD_COMPLETED:
      return {
        ...state,
        uploadFileUrl: action.uploadFileUrl,
        newsletterType: action.newsletterType,
        uploadProgress: 100,
        isSaved: false,
      };
    case actions.GET_STUDENT_BY_CENTER_NAME:
      return {
        ...state,
        isSpinning: true,
      };
    case actions.GET_STUDENT_BY_CENTER_NAME_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false,
      };
    case actions.GET_STUDENT_BY_ROOM_NAME:
      return {
        ...state,
        isSpinning: true,
      };
    case actions.GET_STUDENT_BY_ROOM_NAME_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false,
      };
    case actions.GET_SEARCHED_STUDENT:
      return {
        ...state,
        isSpinning: true,
      };
    case actions.GET_SEARCHED_STUDENT_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        isSpinning: false,
      };
    case actions.GET_STUDENT_DATA:
      return {
        ...state,
        isSpinning: true,
      };
    case actions.GET_STUDENT_DATA_SUCCESSFUL:
      return {
        ...state,
        studentList: action.studentList,
        autoCompleteClassroom: action.autoCompleteClassroom,
        isSpinning: false,
      };

    case actions.FETCH_CLASSROOM:
      return {
        ...state,
        isSpinning: true,
      };
    case actions.FETCH_CLASSROOM_SUCCESSFUL:
      return {
        ...state,
        classrooms: action.classrooms,
        isSpinning: false,
      };

    case actions.RESET_ERROR:
      return {
        ...state,
        isSaved: false,
        isSpinning: false,
        isLoading: false,
        error: false,
        errorInfo: undefined,
        isSent: false,
      };

    case actions.SEND_NEWSLETTER_ALL_CENTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_NEWSLETTER_ALL_CENTER_SUCCESS:
      return {
        ...state,
        isSent: true,
        isLoading: false,
      };
    case actions.GET_ALL_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ALL_NEWSLETTER_SUCCESS:
      return {
        ...state,
        allNewsletters: action.allNewsletters,
        isLoading: false,
        allNewslettersChannel: action.allNewslettersChannel,
      };
    case actions.DELETE_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actions.DELETE_NEWSLETTER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case actions.RESET_UPLOAD_IMAGE:
      return {
        ...state,
        uploadFileUrl: undefined,
        uploadProgress: undefined,
      }
    default:
      return state;
  }
}
