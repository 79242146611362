import { all, put, call, take, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { ProgramApi } from "../../firestore-api/program";
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from "@bugsnag/js";
import moment from "moment-timezone";
import UserFilterAction from "../../Utility/UserFilterActions";
import FilterAction from "../../Utility/FilterAction";
import { ActivityApi } from "../../firestore-api/activity";
import { DashboardApi } from "../../firestore-api/dashboard";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
const { Parser } = require("json2csv");

function* fetchPrograms({ firebase }) {
  const chan = yield call(ProgramApi.getAllProgram, firebase);
  let studentsMap = firebase.studentsMap;
  let programNameList = [];
  try {
    while (true) {
      let data = yield take(chan);
      if (data && data.length > 0) {
        data.forEach((d) => {
          programNameList.push(d.name);
          let startDate = moment(d.startDate);
          let endDate = moment(d.endDate);

          if (moment().isBetween(startDate, endDate, "day", "[]")) {
            d.status = "Active";
          } else if (moment().isBefore(startDate, "day")) {
            d.status = "Upcoming";
          } else {
            d.status = "Expired";
          }
          let data = [];
          if (d.student)
            d.student.forEach((student) => {
              let studentActive = studentsMap.get(student.studentId);
              if (studentActive && studentActive.status == "Active" && !studentActive.deleted) {
                data.push(studentActive);
              }
            });
          d.studentActiveCount = data.length;
        });
      }

      data = data.sort((a, b) => a.status.localeCompare(b.status));

      yield put({
        type: actions.LIST_PROGRAMS_SUCCESSFUL,
        programs: data,
        programsChannel: chan,
        programNameList: programNameList,
      });
    }
  } finally {
    console.log("END CHANNEL PROGRAM");
  }
}

function* getStudentAttendanceData({ firebase, selectedDate, initCall }) {
  try {
    let data = yield call(
      DashboardApi.fetchLiveAttdStudentRatio,
      "roomAttendance",
      selectedDate,
      firebase
    );

    // if (data) {

    yield put({
      type: actions.GET_STUDENT_ATTENDANCE_DATA_SUCCESS,
      studentAttendanceData: data,
      occupancyOperationType: initCall ? "RELOAD_OCC_REPORT" : undefined,
    });
    // }
  } catch (error) {
    console.log("terminating student attendance stats", error);
  }
}

function* fetchProgramLabel({ firebase }) {
  try {
    let data = yield call(ActivityApi.getCustomLabelByCategory, "Program", firebase);

    if (data) {
      yield put({
        type: actions.GET_PROGRAM_LABEL_SUCCESSFUL,
        programLabel: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch program labels", err);
    bugsnagClient.notify(err);
  }
}

function* fetchProgramClassroom({ firebase }) {
  try {
    //var classroom = yield call(ProgramApi.getProgramClassroom, firebase);
    let classroom = JSON.parse(getItem("classList"));
    if (classroom) {
      yield put({
        type: actions.GET_PROGRAM_CLASSROOMS_SUCCESSFFUL,
        programClassroom: classroom,
      });
    }
  } catch (err) {
    console.log("falied to fetch program classroom", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* addNewProgram({ values, classroom, firebase }) {
  try {
    var nodeId = yield call(ProgramApi.createNewProgramNode, firebase);
    if (nodeId) {
      yield call(ProgramApi.addProgram, values, classroom, nodeId, firebase);
    }
    yield put({
      type: actions.ADD_PROGRAM_SUCCESSFUL,
      programOperationType: "ADD_PROGRAM",
    });
    firebase.getPrograms();
  } catch (err) {
    console.log("failed to add program", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* updateExistingProgram({ values, classroom, record, firebase }) {
  try {
    yield call(ProgramApi.updateProgram, values, classroom, record, firebase);
    yield put({
      type: actions.UPDATE_PROGRAM_SUCCESSFUL,
      programOperationType: "UPDATE_PROGRAM",
    });
    firebase.getPrograms();
  } catch (error) {
    console.log("failed to update program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchFeePlanForProgram({ firebase }) {
  try {
    var feePlan = yield call(ProgramApi.getAllFeePlan, firebase);
    if (feePlan) {
      yield put({
        type: actions.PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL,
        programFeePlans: feePlan,
      });
    }
  } catch (error) {
    console.log("failed to fetch fee plan for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* fetchStudentForProgram({ firebase }) {
  try {
    // var students = yield call(AssessmentApi.getAllStudents, firebase);
    let students = FilterAction.getStudentList(firebase);
    if (students) {
      yield put({
        type: actions.PROGRAM_FETCH_STUDENT_SUCCESSFFUL,
        programStudents: students,
      });
    }
  } catch (error) {
    console.log("failed to fetch student for program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* assignStudentToProgram({ values, selectedStudentCheckbox, selectedProgram, firebase }) {
  try {
    yield call(
      ProgramApi.assignStudentsToProgram,
      values,
      selectedStudentCheckbox,
      selectedProgram,
      firebase
    );
    yield put({
      type: actions.ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL,
      programOperationType: "ASSIGN_STUDENT",
    });
    firebase.getPrograms();
  } catch (error) {
    console.log("failed to assign student to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* addNewFeePlanToProgram({ values, selectedFeePlan, selectedProgram, firebase }) {
  try {
    yield call(ProgramApi.addFeePlanToProgram, values, selectedFeePlan, selectedProgram, firebase);
    yield put({
      type: actions.ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to add fee plan to program", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function getForecastProggression(program, student) {
  if (program.ageRange && student.birthDate) {
    let programMaxMonth = program.ageRange.maxMonth + program.ageRange.maxYear * 12;
    let birthDate = student.birthDate ? moment(student.birthDate, "DD[, ]MMMM[, ]YYYY") : undefined;

    let studentAge = moment.duration(moment().diff(birthDate));
    let studentAgeInYear = studentAge.years();
    let studentAgeInMonth = studentAge.months();

    let programStdMaxMonth = studentAgeInMonth + studentAgeInYear * 12;

    return programMaxMonth - programStdMaxMonth;
  } else {
    return "NA";
  }
}
function getFields(firebase) {
  if (firebase.schoolConfig.genderNeutral) {
    return [
      "programName",
      "programStatus",
      "programStartDate",
      "programEndDate",
      "programClassroomNames",
      "startTime",
      "endTime",
      "programDays",
      "programAgeRange",
      "hours",
      "capacity",
      "studentName",
      "studentStatus",
      "deactivationDate",
      "admissionNo",
      "classroomName",
      "groups",
      "studentStartDate",
      "studentEndDate",
      "weekdays",
      "birthDate",
      "age",
      "gender",
      "bloodGroup",
      "allergies",
      "preferredName",
      "medication",
      "eligibleToPromote",
      "forecastForProgression",
      "parent1Name",
      "parent1Email",
      "parent1CountryCode",
      "parent1Number",
      "parent1Company",
      "parent2Name",
      "parent2Email",
      "parent2CountryCode",
      "parent2Number",
      "parent2Company",
      "address",
      "createdAt",
      "admissionDate",
      "kioskCode",
      "emergencyContactName",
      "emergencyContactNumber",
    ];
  } else {
    return [
      "programName",
      "programStatus",
      "programStartDate",
      "programEndDate",
      "programClassroomNames",
      "startTime",
      "endTime",
      "programDays",
      "programAgeRange",
      "hours",
      "capacity",
      "studentName",
      "studentStatus",
      "deactivationDate",
      "admissionNo",
      "classroomName",
      "groups",
      "studentStartDate",
      "studentEndDate",
      "weekdays",
      "birthDate",
      "age",
      "gender",
      "bloodGroup",
      "allergies",
      "preferredName",
      "medication",
      "eligibleToPromote",
      "forecastForProgression",
      "fatherName",
      "fatherEmail",
      "fatherCountryCode",
      "fatherNumber",
      "fatherCompany",
      "motherName",
      "motherEmail",
      "motherCountryCode",
      "motherNumber",
      "motherCompany",
      "address",
      "createdAt",
      "admissionDate",
      "kioskCode",
      "emergencyContactName",
      "emergencyContactNumber",
    ];
  }
}

const getOccupancyForDate = function (data) {
  var date = moment.now();
  var hourlyMap = new Map();
  if (data && data.length > 0) {
    data.forEach((d) => {
      let startDate = moment(d.startDate);
      let endDate = moment(d.endDate);

      var selectedDayOfWeek = moment().format("dddd");

      if (d.weekDay && d.weekDay.filter((e) => e === moment(date).format("dddd")).length == 0)
        return;

      if (moment(date).isBetween(startDate, endDate, "day", "[]")) {
        if (!hourlyMap.has(d.name))
          hourlyMap.set(d.name, {
            studentList: [],
            presentList: [],
            absentList: [],
            checkedOutList: [],
            hourValue: d.name,
            id: d.name,
            programList: [],
            program: d,
          });

        if (d.student)
          d.student.forEach((student) => {
            if (
              student.weekdays &&
              student.weekdays.filter((e) => e === selectedDayOfWeek).length == 0
            )
              return;
            if (
              moment(date).isBetween(
                moment(student.startDate),
                moment(student.endDate),
                "day",
                "[]"
              )
            ) {
              hourlyMap.forEach((value, key) => {
                var row = value;

                if (row.studentList.filter((e) => e.studentId === student.studentId).length == 0) {
                  row.studentList.push(student);

                  hourlyMap.set(key, row);
                }

                if (row.studentList) {
                  row.studentList.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  );
                }

                console.log(value, key);
              });
            }
          });
      }
    });
  }
  if (data && data.length > 0) {
    data.forEach((d) => {
      var value = hourlyMap.get(d.name);
      if (value && value.studentList) {
        d.studentActiveCount = value.studentList.length;
        d.studentList = value.studentList;
      } else d.studentActiveCount = 0;
    });
  }
  return data;
};

function getParentFields(firebase, student, row) {
  var motherPrefix = firebase.schoolConfig.genderNeutral ? "parent1" : "mother";
  var fatherPrefix = firebase.schoolConfig.genderNeutral ? "parent2" : "father";

  row[fatherPrefix + "Name"] = student.fatherName;
  row[fatherPrefix + "Email"] = student.fatherEmail;
  row[fatherPrefix + "Number"] = student.fatherNumber;
  row[fatherPrefix + "Company"] = student.fatherCompany;

  row[motherPrefix + "Name"] = student.motherName;
  row[motherPrefix + "Email"] = student.motherEmail;
  row[motherPrefix + "Number"] = student.motherNumber;
  row[motherPrefix + "Company"] = student.motherCompany;
  row[fatherPrefix + "CountryCode"] = student.fatherCountryCode
    ? student.fatherCountryCode
    : student.code
    ? student.code
    : "";
  row[motherPrefix + "CountryCode"] = student.motherCountryCode
    ? student.motherCountryCode
    : student.code
    ? student.code
    : "";

  row[fatherPrefix + "Company"] = student.fatherCompanyName ? student.fatherCompanyName : "";
  row[motherPrefix + "Company"] = student.motherCompanyName ? student.motherCompanyName : "";
}

function* downloadAllProgramExcel({ programs, firebase }) {
  try {
    const fields = getFields(firebase);

    const opts = { fields };
    let programList = [];

    let studentsMap = firebase.studentsMap;
    let tags = JSON.parse(getItem("groupList"));
    let tagNameList = [];
    tags.forEach((ele) => {
      tagNameList.push(ele.name);
    });

    for (let k in programs) {
      var i = programs[k];
      let programClasses = [];
      i.classroom &&
        i.classroom.forEach((ele) => {
          programClasses.push(ele.name);
        });

      if (programs[k].student) {
        for (let j in programs[k].student) {
          var stu = programs[k].student[j];
          var student = {};
          if (studentsMap.has(stu.studentId)) {
            student = studentsMap.get(stu.studentId);
          }

          var row = {};
          row.programName = i.name;
          row.programStatus = getProgramStatus(i.startDate, i.endDate);
          row.capacity = i.maxCapacity;
          row.programClassroomNames = programClasses.toString();
          row.programStartDate = moment(i.startDate).format("DD-MM-YYYY");
          row.programEndDate = moment(i.endDate).format("DD-MM-YYYY");
          if (i.weekDay) {
            row.programDays = "";
            i.weekDay.forEach((day) => {
              row.programDays += day + ",";
            });
          }

          if (i.ageRange) {
            row.programAgeRange =
              i.ageRange.minYear +
              " Year " +
              i.ageRange.minMonth +
              " Month - " +
              i.ageRange.maxYear +
              " Year " +
              i.ageRange.maxMonth +
              " Month";
          }

          row.startTime = moment(i.startTime).format("hA");
          row.endTime = moment(i.endTime).format("hA");
          row.hours = moment(i.endTime).diff(moment(i.startTime), "hours");
          row.studentStartDate = moment(stu.startDate).format("DD-MM-YYYY");
          row.studentEndDate = moment(stu.endDate).format("DD-MM-YYYY");
          row.studentName = student.name;
          row.admissionNo = student.admissionNumber;
          row.birthDate = student.birthDate;
          row.bloodGroup = student.bloodGroup;
          row.allergies = student.allergies;
          row.medication = student.medicine ? student.medicine : "";
          row.classroomName = student.classList
            ? student.classList.toString()
            : student.classroomName;
          row.address = student.address;
          row.gender = student.gender;
          row.createdAt = student.dateCreated ? moment(student.dateCreated).toDate() : "";
          row.admissionDate = student.admissionDate ? moment(student.admissionDate).toDate() : "";

          row.kioskCode = student.kioskCode ? student.kioskCode : "";

          row.groups = "";
          if (student.tags) {
            student.tags.forEach((tagObject) => {
              if (tagNameList.includes(tagObject.name)) {
                row.groups += tagObject.name + ",";
              }
            });
          }

          row.emergencyContactName = student.emergencyContactName
            ? student.emergencyContactName
            : "";
          row.emergencyContactNumber = student.emergencyNumber ? student.emergencyNumber : "";
          row.medication = student.medicine ? student.medicine : "";
          row.preferredName = student.preferredName ? student.preferredName : "";
          let ageObj = UserFilterAction.eligibleToPromote(stu, i, student, firebase);
          row.age = ageObj.age ? ageObj.age : "";
          row.forecastForProgression = getForecastProggression(i, student);
          row.eligibleToPromote = ageObj.eligibleToPromote ? "Eligible" : "Not eligible";
          row.weekdays = stu.weekdays ? stu.weekdays.toString() : "";
          row.studentStatus = student.status;
          if (student.deactivated) {
            row.deactivationDate = moment(student.deactivationDate).format("DD-MM-YYYY");
          }

          if (student.childConfig) {
            for (let ind in student.childConfig) {
              let propVal = student.childConfig[ind];
              let propsName = propVal.name;
              let propsValue = propVal.value;
              if (!fields.includes(propsName)) {
                fields.push(propsName);
              }

              if (propVal.dataType.toLowerCase() === "date") {
                row[propsName] = propsValue
                  ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                  : "";
              } else {
                row[propsName] = propsValue ? propsValue : "";
              }
            }
          }

          if (student.fatherConfig) {
            for (let ind in student.fatherConfig) {
              let propVal = student.fatherConfig[ind];
              let propsName = firebase.schoolConfig.genderNeutral
                ? "Parent2_" + propVal.name
                : "Father_" + propVal.name;

              let propsValue = propVal.value;
              if (!fields.includes(propsName)) {
                fields.push(propsName);
              }

              if (propVal.dataType.toLowerCase() === "date") {
                row[propsName] = propsValue
                  ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                  : "";
              } else {
                row[propsName] = propsValue ? propsValue : "";
              }
            }
          }

          if (student.motherConfig) {
            for (let ind in student.motherConfig) {
              let propVal = student.motherConfig[ind];
              let propsName = firebase.schoolConfig.genderNeutral
                ? "Parent1_" + propVal.name
                : "Mother_" + propVal.name;

              let propsValue = propVal.value;
              if (!fields.includes(propsName)) {
                fields.push(propsName);
              }

              if (propVal.dataType.toLowerCase() === "date") {
                row[propsName] = propsValue
                  ? moment(propsValue, ["YYYY-MM-DD", "DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate()
                  : "";
              } else {
                row[propsName] = propsValue ? propsValue : "";
              }
            }
          }
          getParentFields(firebase, student, row);
          programList.push(row);
        }
      } else {
        let row = {};
        row.programName = i.name;
        row.capacity = i.maxCapacity;
        row.programClassroomNames = programClasses.toString();
        row.programStartDate = moment(i.startDate).format("DD-MM-YYYY");
        row.programEndDate = moment(i.endDate).format("DD-MM-YYYY");
        row.programStatus = getProgramStatus(i.startDate, i.endDate);
        row.startTime = moment(i.startTime).format("hA");
        row.endTime = moment(i.endTime).format("hA");
        row.hours = moment(i.endTime).diff(moment(i.startTime), "hours");

        if (i.weekDay) {
          row.programDays = "";
          i.weekDay.forEach((day) => {
            row.programDays += day + ",";
          });
        }
        if (i.ageRange) {
          row.programAgeRange =
            i.ageRange.MaxYear +
            " Year " +
            i.ageRange.MaxMonth +
            " Month - " +
            i.ageRange.MinYear +
            " Year " +
            i.ageRange.MinMonth +
            " Month";
        }

        programList.push(row);
      }
    }

    FilterAction.downloadCsvAsExcel("programs", programList, fields);

    yield put({
      type: actions.DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download all program excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function* downloadAllProgramTableData({ programs, firebase }) {
  try {
    const fields = [
      "programName",
      "programStatus",
      "programStartDate",
      "programEndDate",
      "programClassroomNames",
      "startTime",
      "endTime",
      "programDays",
      "programAgeRange",
      "hours",
      "maxCapacity",
      "studentActiveCount",
    ];

    const opts = { fields };
    let programList = [];

    // let studentsMap = firebase.studentsMap;
    // console.log(studentsMap, "mapp");
    programs = getOccupancyForDate(programs);
    for (let k in programs) {
      var program = programs[k];
      let programClasses = [];
      program.classroom &&
        program.classroom.forEach((ele) => {
          programClasses.push(ele.name);
        });

      //  if (programs[k].student) {
      //    for (var j in programs[k].student) {
      var row = {};
      row.programName = program.name;
      row.programStatus = getProgramStatus(program.startDate, program.endDate);
      row.maxCapacity = program.maxCapacity;
      row.programClassroomNames = programClasses.toString();
      row.programStartDate = moment(program.startDate).format("DD-MM-YYYY");
      row.programEndDate = moment(program.endDate).format("DD-MM-YYYY");
      if (program.weekDay) {
        row.programDays = "";
        program.weekDay.forEach((day) => {
          row.programDays += day + ",";
        });
      }

      if (program.ageRange) {
        row.programAgeRange =
          program.ageRange.minYear +
          " Year " +
          program.ageRange.minMonth +
          " Month - " +
          program.ageRange.maxYear +
          " Year " +
          program.ageRange.maxMonth +
          " Month";
      }

      row.startTime = moment(program.startTime).format("hA");
      row.endTime = moment(program.endTime).format("hA");
      row.hours = moment(program.endTime).diff(moment(program.startTime), "hours");
      row.studentActiveCount = program.studentActiveCount;

      programList.push(row);
    }

    FilterAction.downloadCsvAsExcel("programsTableData", programList, fields);

    yield put({
      type: actions.DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS,
    });
  } catch (err) {
    console.log("failed to download all program excel sheet", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.PROGRAM_REQUEST_FAILED,
    });
  }
}

function getProgramStatus(startDate, endDate) {
  var status = "";
  if (moment().isBetween(startDate, endDate, undefined, "[]")) {
    status = "Active";
  } else if (moment().isBefore(startDate, "day")) {
    status = "Upcoming";
  } else {
    status = "Expired";
  }
  return status;
}
function* deleteSelectedProgram({ record, firebase }) {
  try {
    yield call(ProgramApi.deleteProgram, record, firebase);
    yield put({
      type: actions.DELETE_SELECTED_PROGRAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to delete program", err);
    bugsnagClient.notify(err);
  }
}

function* fetchStudentLevelProgram({ firebase }) {
  try {
    let studentLevelProgram = [];

    let studentProgramMap = firebase.studentProgramMap;

    let studentsMap = firebase.studentsMap;
    for (let [key, val] of Object.entries(studentProgramMap)) {
      let studentId = key;
      let student = studentsMap.get(studentId);

      if (student) {
        let data = {};
        data.id = key;
        data.name = student.name;
        data.gender = student.gender;
        data.profileImageUrl = student.profileImageUrl ? student.profileImageUrl : undefined;
        data.status = student.status;
        data.programs = val;
        if (!student.deleted) studentLevelProgram.push(data);
      }
    }

    yield put({
      type: actions.GET_STD_LEVEL_PROGRAM_SUCCESS,
      studentLevelProgram: studentLevelProgram,
    });
  } catch (err) {
    console.log("failed to fetch student level program", err);
    bugsnagClient.notify(err);
  }
}

function* reloadFteReport({ firebase }) {
  try {
    yield put({
      type: actions.RELOAD_FTE_SUCCESS,
    });
  } catch (err) {
    console.log("failed to reload fte");
  }
}
function* saveProgram({ firebase, program }) {
  try {
    yield call(ProgramApi.saveProgram, firebase, program);

    yield put({
      type: actions.SAVE_PROGRAM_SUCCESS,
    });
  } catch (err) {
    console.log("failed to save Program");
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.SAVE_PROGRAM, saveProgram),

    yield takeLatest(actions.LIST_PROGRAMS, fetchPrograms),
    yield takeLatest(actions.GET_PROGRAM_CLASSROOMS, fetchProgramClassroom),
    yield takeLatest(actions.ADD_PROGRAM, addNewProgram),
    yield takeLatest(actions.UPDATE_PROGRAM, updateExistingProgram),
    yield takeLatest(actions.PROGRAM_FETCH_FEE_PLAN, fetchFeePlanForProgram),
    yield takeLatest(actions.PROGRAM_FETCH_STUDENT, fetchStudentForProgram),
    yield takeLatest(actions.ASSIGN_STUDENT_TO_PROGRAM, assignStudentToProgram),
    yield takeLatest(actions.ADD_FEE_PLAN_TO_PROGRAM, addNewFeePlanToProgram),
    yield takeLatest(actions.DOWNLOAD_ALL_PROGRAM_EXCEL, downloadAllProgramExcel),
    yield takeLatest(actions.DOWNLOAD_ALL_PROGRAM_TABLE_DATA, downloadAllProgramTableData),
    yield takeLatest(actions.GET_PROGRAM_LABEL, fetchProgramLabel),

    yield takeLatest(actions.DELETE_SELECTED_PROGRAM, deleteSelectedProgram),
    yield takeLatest(actions.GET_STUDENT_ATTENDANCE_DATA, getStudentAttendanceData),

    yield takeLatest(actions.GET_STD_LEVEL_PROGRAM, fetchStudentLevelProgram),
    yield takeLatest(actions.RELOAD_FTE, reloadFteReport),
  ]);
}
