const actions = {
  UPLOAD_STUDENT_CSV: "UPLOAD_STUDENT_CSV",
  UPLOAD_STUDENT_CSV_SUCCESSFUL: "UPLOAD_STUDENT_CSV_SUCCESSFUL",
  UPLOAD_HOLIDAY_CSV: "UPLOAD_HOLIDAY_CSV",
  UPLOAD_HOLIDAY_CSV_SUCCESSFUL: "UPLOAD_HOLIDAY_CSV_SUCCESSFUL",
  UPLOAD_TEACHER_CSV: "UPLOAD_TEACHER_CSV",
  UPLOAD_TEACHER_CSV_SUCCESSFUL: "UPLOAD_TEACHER_CSV_SUCCESSFUL",
  UPLOAD_SCHEDULE_CSV: "UPLOAD_SCHEDULE_CSV",
  UPLOAD_SCHEDULE_CSV_SUCCESSFUL: "UPLOAD_SCHEDULE_CSV_SUCCESSFUL",
  UPLOAD_SINGLE_DAY_SCHEDULE_CSV: "UPLOAD_SINGLE_DAY_SCHEDULE_CSV",
  UPLOAD_SINGLE_DAY_SCHEDULE_CSV_SUCCESSFUL: "UPLOAD_SINGLE_DAY_SCHEDULE_CSV_SUCCESSFUL",
  UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV: "UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV",
  UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV_SUCCESSFUL: "UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV_SUCCESSFUL",
  ONBOARDINGG_REQUEST_FAILED: "ONBOARDINGG_REQUEST_FAILED",
  GET_ONBOARDING_CLASSROOM: "GET_ONBOARDING_CLASSROOM",
  GET_ONBOARDING_CLASSROOM_SUCCESSFUL: "GET_ONBOARDING_CLASSROOM_SUCCESSFUL",
  RESET_OPERATION: "RESET_OPERATION",

  uploadStudentCsvData: (studentCsvData, firebase) => ({
    type: actions.UPLOAD_STUDENT_CSV,
    studentCsvData,
    firebase,
  }),

  uploadHolidayCsvData: (holidayCsvData, classrooms, firebase) => ({
    type: actions.UPLOAD_HOLIDAY_CSV,
    holidayCsvData,
    classrooms,
    firebase,
  }),

  getClassroom: (firebase) => ({
    type: actions.GET_ONBOARDING_CLASSROOM,
    firebase,
  }),

  uploadTeacherCsvData: (teacherCsvData, firebase) => ({
    type: actions.UPLOAD_TEACHER_CSV,
    teacherCsvData,
    firebase,
  }),

  uploadScheduleCsvData: (scheduleCsvData, formValue, firebase) => ({
    type: actions.UPLOAD_SCHEDULE_CSV,
    scheduleCsvData,
    formValue,
    firebase,
  }),

  uploadSingleDayScheduleCsvData: (scheduleCsvData, formValue, firebase) => ({
    type: actions.UPLOAD_SINGLE_DAY_SCHEDULE_CSV,
    scheduleCsvData,
    formValue,
    firebase,
  }),

  uploadSpecificDateScheduleCsvData: (scheduleCsvData, formValue, firebase) => ({
    type: actions.UPLOAD_SPECIFIC_DATE_SCHEDULE_CSV,
    scheduleCsvData,
    formValue,
    firebase,
  }),

  resetOperation: () => ({
    type: actions.RESET_OPERATION,
  }),
};
export default actions;
