import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";

function getProgramList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/programs")
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getLabelList(firebase, labelTypeName) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/labelRef/" + labelTypeName)
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function getDiscountList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/feePlanDiscount")
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function getTaxList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/feePlanTax")
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getFeePlanList(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef = rsf
    .ref(branchPath + "/newFeePlan")
  return eventChannel((emitter) => {
    myRef.on("value", (snap) => {
      let list = [];
      snap.forEach((item) => {
        if (item.val()) {
          list.push(item.val());
        }
      });
      emitter(list);
    });
    return () => {
      console.log("fee plan list channel closed")
      myRef.off();
    };
  });
}

function getFeeComponentList(firebase, currentBillingType) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/newFeeComponent").orderByChild("type")
      .equalTo(currentBillingType)
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getDiscountData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    const discountRef = rsf.ref(branchPath + "/feePlanDiscount/");
    discountRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });

    return () => {
      discountRef.off();
      console.log("unsubscribed fee plan discounts");
    };
  });
}

function isDiscountAssigned(firebase, discountId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    const discountRef = rsf.ref(branchPath + "/assignedStudentDiscount/");
    discountRef
      .orderByChild("discountId")
      .equalTo(discountId)
      .once("value")
      .then(function (snapshot) {
        let bool = false;
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            bool = true;
          }
        });
        resolve(bool);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getTaxData(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    const taxRef = rsf.ref(branchPath + "/feePlanTax/");
    taxRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });

    return () => {
      taxRef.off();
      console.log("unsubscribed tax data");
    };
  });
}

function isTaxAssigned(firebase, taxId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    const taxAssignmentRef = rsf.ref(branchPath + "/assignedStudentTax/");
    taxAssignmentRef
      .orderByChild("taxId")
      .equalTo(taxId)
      .once("value")
      .then(function (snapshot) {
        let bool = false;
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            bool = true;
          }
        });
        resolve(bool);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function isFeeComponentUsed(firebase, feeComponentId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    const feePlanLineItemsRef = rsf.ref(branchPath + "/feePlanLineItems/");
    feePlanLineItemsRef
      .orderByChild("presetItemId")
      .equalTo(feeComponentId)
      .once("value")
      .then(function (snapshot) {
        let bool = false;
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            bool = true;
          }
        });
        resolve(bool);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getFeeComponents(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emit) => {
    const fcRef = rsf.ref(branchPath + "/newFeeComponent/");
    fcRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });

    return () => {
      fcRef.off();
      console.log("unsubscribed tax data");
    };
  });
}

function getSchoolInvoicePdfStyle(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/invoicePdfSettings/")
      .once("value")
      .then(function (snapshot) {
        let val = snapshot.val();
        resolve(val);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getFeePlanInvoicePdfStyle(firebase, feePlanId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return new Promise(function (resolve, reject) {
    rsf.ref(branchPath + "/feePlanInvoicePdfSettings/")
      .orderByChild("feePlanId")
      .equalTo(feePlanId)
      .once("value")
      .then(function (snapshot) {
        let val = snapshot.val();
        if (val) {
          const values = Object.values(val);
          resolve(values[0]);
        } else {
          resolve(undefined);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getGeneratedInvoicesWithFeePlanId(firebase, feePlanId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  return eventChannel(emit => {
    const invoiceListRef = rsf.ref(branchPath + '/invoiceList/');
    const query = invoiceListRef
      .orderByChild('feePlanId')
      .equalTo(feePlanId);

    const listener = query.on('value', snapshot => {
      const list = [];
      snapshot.forEach(snap => {
        if (snap.val() !== null) {
          list.push(snap.val());
        }
      });
      emit(list);
    });

    return () => {
      query.off('value', listener);
      console.log('Unsubscribed generated invoice');
    };
  });
}
function getAllLineItemByFeePlanId(firebase, feePlanId) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/feePlanLineItems").orderByChild("feePlanId").equalTo(feePlanId)
      .once("value")
      .then(function (snapshot) {
        let list = [];
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            list.push(snap.val());
          }
        });
        resolve(list);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function dataURLtoFile(dataURL, filename) {
  var arr = dataURL.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var extension = mime.split('/')[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename + '.' + extension, { type: mime });
}

function uploadImageAttachment(base64Image, firebase) {
  var uploadTimestamp = new Date().getTime();
  var filename = moment().valueOf();
  let branchPath = firebase.sbp;
  const storage = firebase.secondaryStorage;
  const storageRef = storage.ref();
  let attachmentRef = storageRef.child(
    branchPath + "/media/images/" + filename + ":" + uploadTimestamp
  );

  const file = dataURLtoFile(base64Image, filename);

  var p1 = new Promise(function (resolve, reject) {
    attachmentRef.put(file).then(function (snapshot) {
      attachmentRef.getDownloadURL().then((url) => {
        console.log("found url", url);
        resolve(url);
      });
    });
  });
  return p1;
}

export const newFeePlanApi = {
  getProgramList,
  getLabelList,
  getDiscountList,
  getTaxList,
  getFeePlanList,
  getFeeComponentList,
  getDiscountData,
  isDiscountAssigned,
  getTaxData,
  isTaxAssigned,
  getFeeComponents,
  isFeeComponentUsed,
  getGeneratedInvoicesWithFeePlanId,
  getAllLineItemByFeePlanId,
  uploadImageAttachment,
  getSchoolInvoicePdfStyle,
  getFeePlanInvoicePdfStyle
}