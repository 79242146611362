const actions = {
  GET_CUSTOM_ACTIVITIES: "GET_CUSTOM_ACTIVITIES",
  GET_CUSTOM_ACTIVITIES_SUCCESS: "GET_CUSTOM_ACTIVITIES_SUCCESS",
  GET_CUSTOM_ACTIVITIES_FAILED: "GET_CUSTOM_ACTIVITIES_FAILED",
  ADD_CUSTOM_ACTIVITY: "ADD_CUSTOM_ACTIVITY",
  ADD_CUSTOM_ACTIVITY_SUCCESS: "ADD_CUSTOM_ACTIVITY_SUCCESS",
  ADD_CUSTOM_ACTIVITY_FAILED: "ADD_CUSTOM_ACTIVITY_FAILED",
  SETLODING: "SETLOADING",
  DELETE_CUSTOM_ACTIVITY: "DELETE_CUSTOM_ACTIVITY",
  DELETE_CUSTOM_ACTIVITY_SUCCESS: "DELETE_CUSTOM_ACTIVITY_SUCCESS",
  DLETE_CUSTOM_ACTIVITY_FAILED: "DELETE_CUSTOM_ACTIVITY_FAILED",
  EDIT_CUSTOM_ACTIVITY: "EDIT_CUSTOM_ACTIVITY",
  EDIT_CUSTOM_ACTIVITY_SUCCESS: "EDIT_CUSTOM_ACTIVITY_SUCCESS",
  EDIT_CUSTOM_ACTIVITY_FAILED: "EDIT_CUSTOM_ACTIVITY_FAILED",
  getCustomActivities: (firebase) => {
    return {
      type: actions.GET_CUSTOM_ACTIVITIES,
      firebase,
    };
  },

  addCustomActivity: (firebase, activityName, values, fileList) => {
    return {
      type: actions.ADD_CUSTOM_ACTIVITY,
      firebase,
      activityName,
      values,
      fileList,
    };
  },
  editCustomActivity: (firebase, activityName, values, activity) => {
    return {
      type: actions.EDIT_CUSTOM_ACTIVITY,
      firebase,
      activityName,
      values,
      activity,
    };
  },
  setLoading: (payLoad) => {
    return {
      type: actions.SETLODING,
      payLoad,
    };
  },
  deleteCustomActivity: (firebase, activityKey) => {
    return {
      type: actions.DELETE_CUSTOM_ACTIVITY,
      firebase,
      activityKey,
    };
  },
};
export default actions;
