import React, { useState, useEffect } from "react";
import { Popover, Tooltip, Modal, Row, DatePicker, Col, Drawer, Spin } from "antd";
import moment from "moment";
import colors from "../../Utility/colorFactory";
import formatMsg from "../../components/utility/formatMessageUtil";
import classImg from "../../image/Classroom.png";
import schoolImg from "../../image/School.png";
import menuVertical from "../../image/MenuVertical.png";
import moreThan from "../../image/MoreThan.png";
import { Link } from "react-router-dom";
import webFont from "../../Utility/fontSizes";
import FilterAction from "../../Utility/FilterAction";
import notification from "../../components/notification";
import { withFirebase } from "../../components/firebase/context";
import staffScheduleTableActions from "../../redux/staffScheduleTable/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";
import AffectedScheduleDrawer from "./AffectedScheduleDrawer";

function StaffSchedule(props) {
  const [visible1stPopOver, setVisible1stPopOver] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteFrom, setDeleteFrom] = useState(undefined);
  const [deleteTill, setDeleteTill] = useState(undefined);
  const [deletePopover, setDeletePopover] = useState(false);
  const [editPopover, setEditPopover] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  let theme = colors.skyBlue;
  const time = moment(moment(props.shiftObject.startTime).format("HH:mm"), "HH:mm");
  const morningShiftStart = moment("00:00", "HH:mm");
  const morningShiftEnd = moment("11:58", "HH:mm");
  const afternoonShiftStart = moment("11:59", "HH:mm");
  const afternoonShiftEnd = moment("14:58", "HH:mm");
  const eveningShiftStart = moment("14:59", "HH:mm");
  if (time.isBetween(morningShiftStart, morningShiftEnd)) {
    theme = "#FFD21B";
  } else if (time.isBetween(afternoonShiftStart, afternoonShiftEnd)) {
    theme = "#FF8E5E";
  } else if (time.isSameOrAfter(eveningShiftStart)) {
    theme = "#9F9FFF";
  } else {
    theme = colors.skyBlue;
  }
  function deleteMultiple() {
    setDeleteFrom(moment(props.shiftObject.startDate));
    setDeleteTill(props.shiftObject.endDate ? moment(props.shiftObject.endDate) : moment(props.shiftObject.startDate))
    setVisible1stPopOver(false);
    setDeleteVisible(true);
    setDeletePopover(false);
  }
  function deleteSchedule() {
    if (moment(deleteFrom).isAfter(moment(deleteTill), 'day')) {
      notification("error", formatMsg("dateRangeIsInvalid"));
      return;
    }
    setDeleteVisible(false);
    let deleteFromDate = null;
    let deleteTillDate = null;
    let id = props.shiftObject.id;
    if (props.shiftObject.parentId) {
      id = props.shiftObject.parentId;
      deleteFromDate = moment(deleteFrom).startOf('day').valueOf();
      deleteTillDate = moment(deleteTill).endOf('day').valueOf();
    }
    props.deleteStaffSchedule(id, deleteFromDate, deleteTillDate, props.firebase);
    setShowDrawer(false);

  }
  function disabledDate(e, fieldVal, fieldType) {
    let startDate = moment(fieldVal);
    if (fieldType.toLowerCase() == "before") {
      return e && e < startDate;
    }
    return e && e > startDate;
  }
  const deleteOneAndMultiplePopover = (
    <div
      style={{
        width: "115px",
        whiteSpace: "nowrap",
        fontSize: webFont.medium,
        verticalAlign: "baseline",
        margin: "4px 0 -8px 0px",

      }}
    >
      <p style={styles.secondPopoverOption} onClick={() => {
        props.deleteStaffSchedule(props.shiftObject.id, null, null, props.firebase);
        setDeletePopover(false),
          setVisible1stPopOver(false);
      }}>
        {formatMsg("deleteThis")}
      </p>
      <p style={{ borderBottom: "2px solid #eee", marginTop: "1px" }}></p>
      <p style={styles.secondPopoverOption} onClick={deleteMultiple}>
        {formatMsg("Delete Multiple")}
      </p>
    </div>
  );
  const editOneAndMultiplePopover = (
    <div
      style={{
        width: "115px",
        whiteSpace: "nowrap",
        fontSize: webFont.medium,
        verticalAlign: "baseline",
        margin: "4px 0 -8px 0px",

      }}
    >
      <p style={styles.secondPopoverOption} onClick={() => {
        setEditPopover(false),
          setVisible1stPopOver(false);
      }}>
        <Link
          to={{
            pathname: "staffScheduleForm",
            search: "?type=edit&scheduleId=" + props.shiftObject.id + "&editCase=editThis",
          }}
          style={styles.secondPopoverOption}
        >
          {formatMsg("editThis")}
        </Link>
      </p>
      <p style={{ borderBottom: "2px solid #eee", marginTop: "1px" }}></p>
      <p style={styles.secondPopoverOption} onClick={() => {
        setVisible1stPopOver(false);
        setEditPopover(false);
      }}>
        <Link
          to={{
            pathname: "staffScheduleForm",
            search: "?type=edit&scheduleId=" + props.shiftObject.id + "&editCase=" + (props.shiftObject.parentId ? "editMultiple" : "editThis"),
          }}
          style={styles.secondPopoverOption}
        >
          {formatMsg("editMultiple")}
        </Link>
      </p>
    </div >
  );
  const deleteContent = <div style={styles.popoverDiv}>{deleteOneAndMultiplePopover}</div>;
  const editContent = <div style={styles.popoverDiv}>{editOneAndMultiplePopover}</div>;


  const copyEditDeletePopover = (
    <div
      style={{
        width: "100px",
        padding: "14px 10px 10px 0",
        whiteSpace: "nowrap",
        margin: "4px 0 4px 18px",
        fontSize: webFont.medium,
        verticalAlign: "baseline",
      }}
    >
      <Link
        to={{
          pathname: "staffScheduleForm",
          search: "?type=copy&scheduleId=" + props.shiftObject.id,
        }}
        style={styles.secondPopoverOption}
      >
        {formatMsg("copy")}
      </Link>
      <p style={{ borderBottom: "2px solid #eee", marginTop: "1px" }}></p>
      <Popover
        placement="bottomLeft"
        content={editContent}
        trigger="click"
        visible={editPopover && visible1stPopOver}
        onClick={() => {
          setEditPopover(!editPopover)
        }}
        onVisibleChange={() => {
          setEditPopover(!editPopover)
        }}
        overlayClassName="my-popover"
        overlayStyle={{
          padding: 0,
          borderLeft: "6px solid " + colors.skyBlue,
          backgroundColor: "rgb(244, 244, 244)",
        }}
      >
        <div
          style={{
            height: "25px",
            marginTop: "12px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          <p style={styles.secondPopoverOption} >
            {formatMsg("status.edit")}
          </p>
        </div>
      </Popover>
      <p style={{ borderBottom: "2px solid #eee", marginTop: "1px" }}></p>
      <Popover
        placement="bottomLeft"
        content={deleteContent}
        trigger="click"
        visible={deletePopover && visible1stPopOver}
        onClick={() => {
          setDeletePopover(!deletePopover)
        }}
        onVisibleChange={() => {
          setDeletePopover(!deletePopover)
        }}
        overlayClassName="my-popover"
        overlayStyle={{
          padding: 0,
          borderLeft: "6px solid " + colors.skyBlue,
          backgroundColor: "rgb(244, 244, 244)",
        }}
      >
        <div
          style={{
            height: "25px",
            marginTop: "12px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          <p style={styles.secondPopoverOption} >
            {formatMsg("fee.delete")}
          </p>
        </div>
      </Popover>
    </div>
  );

  const content = <div style={styles.popoverDiv}>{copyEditDeletePopover}</div>;

  function showAffectedScheudles() {
    let idType = "id";
    let id = props.shiftObject.id;
    if (props.shiftObject.parentId) {
      id = props.shiftObject.parentId;
      idType = "parentId";
    }
    const { affectedScheduleChannel } = props.schedule;
    if (affectedScheduleChannel) {
      affectedScheduleChannel.close();
    }
    const { getAffectedSchedules, firebase } = props;
    getAffectedSchedules(firebase, deleteFrom, deleteTill, id, idType);
    setShowDrawer(true);
  }

  useEffect(() => {
    return () => {
      const { affectedScheduleChannel } = props.schedule;
      if (affectedScheduleChannel) {
        affectedScheduleChannel.close();
      }
    }
  }, [props.schedule.affectedScheduleChannel]);

  function closeDrawer() {
    const { affectedScheduleChannel } = props.schedule;
    if (affectedScheduleChannel) {
      affectedScheduleChannel.close();
    }
    setShowDrawer(false);
  }

  return (
    <div>
      <div style={{ ...styles.staffMainDiv, borderLeftColor: theme }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={styles.timeDiv}>
            {moment(props.shiftObject.startTime).format("hh:mm A")} -{" "}
            {moment(props.shiftObject.endTime).format("hh:mm A")}
          </div>
          <Popover
            placement="rightTop"
            content={content}
            onClick={(e) => {
              setVisible1stPopOver(true);
            }}
            trigger="click"
            visible={visible1stPopOver}
            onVisibleChange={() => {
              setVisible1stPopOver(!visible1stPopOver)
              if (visible1stPopOver) {
                setDeletePopover(false),
                  setEditPopover(false);
              }
            }
            }
            overlayClassName="my-popover"
            overlayStyle={{
              padding: 0,
              borderLeft: "6px solid " + colors.skyBlue,
              backgroundColor: "rgb(244, 244, 244)",
            }}
          >
            <div
              style={{
                height: "25px",
                marginTop: "12px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              <img src={menuVertical} style={{ height: "20px", width: "20px" }} />
            </div>
          </Popover>
        </div>
        <div style={styles.nameDiv}>
          {props.shiftObject.shiftName.length > 20 ? (
            <Tooltip placement="topLeft" title={props.shiftObject.shiftName}>
              {props.shiftObject.shiftName.substring(0, 20) + "..."}
            </Tooltip>
          ) : (
            props.shiftObject.shiftName
          )}
        </div>
        <div style={{ ...styles.lineStyle, borderColor: theme }}></div>
        <div style={styles.nameDiv}>
          <img
            src={classImg}
            style={{ height: "26px", width: "21px", marginRight: "10px", marginTop: "1px", cursor: "pointer" }}
          />
          {props.shiftObject.classList
            ?
            <Tooltip placement="topLeft" title={props.shiftObject.classList.join(", ")}>
              {props.shiftObject.classList.join(", ").slice(0, 20) +
                (props.shiftObject.classList.join(", ").length > 20 ? "..." : "")}
            </Tooltip>
            : formatMsg("noClassroom")}
        </div>
        <div style={{ ...styles.nameDiv, justifyContent: "space-between" }}>
          <div style={{ marginTop: "8px" }}>
            <img src={schoolImg} style={{ marginRight: "10px", height: "21px", width: "21px" }} />
            {props.shiftObject.lessonName ? (
              <Link
                to={{
                  pathname: "lessonView",
                  search: "?lessonId=" + props.shiftObject.lessonId,
                }}
                style={{ color: "#8D8D8D", textDecoration: "underline" }}
              >
                <Tooltip placement="topLeft" title={props.shiftObject.lessonName}>
                  {props.shiftObject.lessonName.length > 12
                    ? props.shiftObject.lessonName.substring(0, 12) + "..."
                    : props.shiftObject.lessonName}
                  <img src={moreThan} style={{ height: "16px", width: "16px" }} />
                </Tooltip>

              </Link>
            ) : (
              formatMsg("noLesson")
            )}
          </div>
          {props.shiftObject.onLeave ? (
            <div style={styles.leaveBox}> {formatMsg("onLeave")} </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        centered={true}
        width="500px"
        title={<p style={{ textAlign: "start" }}>{formatMsg("placeholder.pickDateToDeleteSchedules")}</p>}
        visible={deleteVisible}
        onOk={deleteSchedule}
        onCancel={() => { setDeleteVisible(false) }}
        okText={formatMsg("document.delete")}
        cancelText={formatMsg("cancel")}
        maskClosable={false}
      >
        <Row>
          <Col lg={12} md={24} xs={24} style={styles.colStyle}>
            <DatePicker
              value={deleteFrom}
              placeholder={formatMsg("deleteFrom")}
              size="large"
              style={styles.pickerStyle}
              allowClear={false}
              disabledDate={(current) => disabledDate(current, props.shiftObject.startDate, "before")}
              format={FilterAction.getDateFormat(props.firebase)}
              onChange={(date) => { setDeleteFrom(date) }}
            />
          </Col>
          <Col lg={12} md={24} xs={24} style={styles.colStyle}>
            <DatePicker
              disabledDate={(current) => disabledDate(current, props.shiftObject.endDate ? props.shiftObject.endDate : props.shiftObject.startDate, "after")}
              value={deleteTill}
              placeholder={formatMsg("deleteTill")}
              size="large"
              style={styles.pickerStyle}
              allowClear={false}
              format={FilterAction.getDateFormat(props.firebase)}
              onChange={(date) => { setDeleteTill(date) }}

            />
            <Row type="flex" justify="end" style={styles.affectedRowBtn}>
              <Col lg={24} md={24} xs={24}
                style={styles.affectedBtn}
                onClick={() => showAffectedScheudles()}
              >
                {formatMsg("showAffectedSchedules")}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal >
      <AffectedScheduleDrawer
        isLoading={props.schedule.isLoading}
        showDrawer={showDrawer}
        closeDrawer={closeDrawer}
        affectedSchedules={props.schedule.affectedSchedules}
        firebase={props.firebase}
      />
    </div >
  );
};
function mapStateToProps(state) {
  return {
    schedule: state.ScheduleData,
  };
}
const a = compose(
  connect(mapStateToProps, {
    ...staffScheduleTableActions,
  }),
  withFirebase
);
export default a(StaffSchedule);

const styles = {
  staffMainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: colors.white,
    borderRadius: "8px",
    height: "158px",
    border: "1px solid #D0D0D0",
    borderLeft: "12px solid",
    boxSizing: "border-box",
    width: "300px",
  },
  lineStyle: {
    border: "1.5px solid",
    width: "288px",
    height: "0px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  timeDiv: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: webFont.fl,
    lineHeight: "27px",
    color: colors.v2_scorpion,
    marginTop: "10px",
    marginLeft: "20px",
  },
  nameDiv: {
    display: "flex",
    flexDirection: "row",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: webFont.fl,
    lineHeight: "27px",
    color: "#8D8D8D",
    marginLeft: "20px",
  },
  dropdownDiv: {
    textAlign: "right",
  },
  secondPopoverOption: {
    color: colors.v2_scorpion,
    fontSize: webFont.large,
    fontWeight: "550",
    cursor: "pointer",
  },
  leaveBox: {
    boxSizing: "border-box",
    width: "75px",
    height: "24px",
    background: "#FFEAEA",
    border: "1px solid #C53A3A",
    borderRadius: "6px",
    fontSize: webFont.medium,
    color: "#C53A3A",
    marginRight: "18px",
    marginTop: "7px",
    lineHeight: "21px",
    fontWeight: 600,
    fontStyle: "normal",
  },
  colStyle: {
    paddingRight: 8,
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: 8,
  },
  rowStyle: {
    marginTop: "25px"
  },
  affectedBtn: {
    color: colors.v2_Cerulean,
    textDecoration: "underline",
    fontSize: webFont.large,
    display: "flex",
    justifyContent: "end",
    marginTop: "9px",
    cursor: "pointer",
    marginRight: "20px"
  },
  affectedRowBtn: {

  }
};
