import styled from "styled-components";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
const CustomButtonFilled = styled.button`
  background: ${colors.v2_Cerulean};
  border: 2px solid ${colors.v2_Cerulean};
  color: white;
  padding: 0.25em 1em;
  height: 50px;
  font-size: ${webFont.large};
  border-radius: 10px;
  text-transform: uppercase;
  width: 280px;
  outline: none;

  @media only screen and (max-width: 600px) {
    font-size: ${webFont.small};
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;
export default CustomButtonFilled;
