const actions = {
  ADD_ZOOM_CONFIG: "ADD_ZOOM_CONFIG",
  ADD_ZOOM_CONFIG_SUCCESSFUL: "ADD_ZOOM_CONFIG_SUCCESSFUL",
  REQUEST_FAILED: "REQUEST_FAILED",
  DISCONNECT_ZOOM_CONFIG: "DISCONNECT_ZOOM_CONFIG",
  DISCONNECT_ZOOM_CONFIG_SUCCESSFUL: "DISCONNECT_ZOOM_CONFIG_SUCCESSFUL",
  INIT_FIREBASE_CONFIG: "INIT_FIREBASE_CONFIG",
  INIT_FIREBASE_CONFIG_SUCCESS: "INIT_FIREBASE_CONFIG_SUCCESS",
  GET_TEACHER: "GET_TEACHER",
  GET_TEACHER_SUCCESS: "GET_TEACHER_SUCCESS",

  getZoomAccessAndRefreshToken: (authToken, firebase) => ({
    type: actions.ADD_ZOOM_CONFIG,
    authToken,
    firebase,
  }),

  disconnectZoomAccount: (firebase) => ({
    type: actions.DISCONNECT_ZOOM_CONFIG,
    firebase,
  }),

  initialiseFirebase: (firebase) => ({
    type: actions.INIT_FIREBASE_CONFIG,
    firebase,
  }),

  getTeacher: (firebase) => ({
    type: actions.GET_TEACHER,
    firebase,
  }),
};
export default actions;
