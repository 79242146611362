import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore

import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";

import { emailTemplateApi } from "../../firestore-api/emailTemplates";

import actions from "./actions";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { ActivityApi } from "../../firestore-api/activity";
import formatMsg from "../../components/utility/formatMessageUtil";
const { Parser } = require("json2csv");

function linkify(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  // replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  // replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  replacePattern1 = /(\b(https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  // replacedText = replacedText.replace(
  //   replacePattern2,
  //   '$1<a href="http://$2" target="_blank">$2</a>'
  // );

  //Change email addresses to mailto:: links.
  // replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
  // const onlyText = replacedText.substring(6);
  // return onlyText
  return replacedText;
}
function getNoteHtml(editorState) {
  if (editorState) {
    let rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const linkedMarkUp = linkify(markup);
    return linkedMarkUp;
  } else {
    return null;
  }
}

function* addNewTemplate({ value, firebase, editorState }) {
  try {
    const htmlText = getNoteHtml(editorState);

    var nodeId = yield call(emailTemplateApi.createNewTemplateNode, firebase);
    var storagePath = undefined;
    var attachmentUrl;
    if (value.attachment) {

      let file = { fileList: value.attachment };

      attachmentUrl = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        file,
        firebase
      );


      let attachmentArray = [];
      attachmentUrl.forEach((item) => {
        let attachmentObj = {};
        attachmentObj.url = item.path;
        attachmentObj.fileName = item.fileName;
        attachmentObj.type = item.type === "file" ? "application/pdf" : item.type === "video" ? "video" : "image",
          attachmentArray.push(attachmentObj);
      });
      value.attachment = attachmentArray.length > 0 ? attachmentArray : null;
    }
    yield call(emailTemplateApi.addTemplate, value, nodeId, firebase, htmlText, "create");
    notification("success", formatMsg("success.saveEmailTemplate"));

    yield put({
      type: actions.ADD_EMAIL_TEMPLATE_SUCCESSFUL,
    });
  } catch (error) {
    console.log(error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.ADD_REQUEST_FAILED,
    });
  }
}

function* getEmailTemplateRegister({ firebase }) {
  try {
    const chan = yield call(emailTemplateApi.getAllEmailTemplate, firebase);
    while (true) {
      const response = yield take(chan);
      yield put({
        type: actions.GET_EMAIL_TEMPLATE_SUCCESSFUL,
        emailTemplateChannel: chan,
        emailTemplateData: response,
      });
    }
  } catch (err) {
    console.log("failed to fetch Email Templates", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
      payLoad: false,
    });
  }
}
function* getEmailTemplateByType({ firebase, templateType }) {
  try {
    const chan = yield call(emailTemplateApi.getAllEmailTemplateByType, firebase, templateType);
    while (true) {
      const response = yield take(chan);
      yield put({
        type: actions.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESSFUL,
        emailTemplateChannel: chan,
        emailTemplateData: response,
      });
    }
  } catch (err) {
    console.log("failed to fetch  Email Templates", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
      payLoad: false,
    });
  }
}

function* deleteSelectedEmailTemplate({ record, firebase }) {
  try {
    yield call(emailTemplateApi.deleteEmailTemplateStatus, record, firebase);
    notification("success", "Template successfully deleted");
    yield put({
      type: actions.DELETE_TEMPLATE_SUCCESSFUL,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to delete  email template", err);
  }
}

function* updateSelectedEmailTemplate({ value, editorState, firebase, id, prevAttachments, }) {
  try {
    let attachmentArray = [];
    const htmlTemplate = getNoteHtml(editorState);
    value.updatedBy = firebase.user.id;

    var storagePath = undefined;
    var attachmentUrl;
    if (value.attachment) {

      let file = { fileList: value.attachment };

      attachmentUrl = yield call(
        ActivityApi.getAttachmentMediaPath,
        storagePath,
        file,
        firebase
      );



      attachmentUrl.forEach((item) => {
        let attachmentObj = {};
        attachmentObj.url = item.path;
        attachmentObj.fileName = item.fileName;
        attachmentObj.type = item.type === "file" ? "application/pdf" : item.type === "video" ? "video" : "image",
          attachmentArray.push(attachmentObj);
      });
    }
    //prevattachment case
    if (prevAttachments && prevAttachments.length > 0) {
      for (let index in prevAttachments) {
        attachmentArray.push(prevAttachments[index]);
      }
    }
    value.attachment = attachmentArray.length > 0 ? attachmentArray : null;



    yield call(emailTemplateApi.addTemplate, value, id, firebase, htmlTemplate, "edit");


    notification("success", formatMsg("success.updateTemplate"));
    yield put({
      type: actions.UPDATE_EMAIL_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to update email template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* getEmailTemplateByIdSaga({ firebase, id }) {
  try {
    const template = yield call(emailTemplateApi.getEmailTemplateById, firebase, id);
    yield put({
      type: actions.GET_EMAIL_TEMPLATE_BY_ID_SUCCESSFUL,
      template,
    });
  } catch (err) {
    console.log("failed to update email template", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* getLabels({ firebase, labelType }) {
  try {
    const labels = yield call(emailTemplateApi.getCustomLabelByCategory, labelType, firebase);
    yield put({
      type: actions.GET_LABEL_SUCCESS,
      labels,
    });
  } catch (err) {
    console.log("failed to get", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_EMAIL_TEMPLATE, getEmailTemplateRegister),
    yield takeLatest(actions.DELETE_TEMPLATE, deleteSelectedEmailTemplate),
    yield takeLatest(actions.UPDATE_EMAIL, updateSelectedEmailTemplate),
    yield takeLatest(actions.ADD_EMAIL_TEMPLATE, addNewTemplate),
    yield takeLatest(actions.GET_EMAIL_TEMPLATE_BY_TYPE, getEmailTemplateByType),
    yield takeLatest(actions.GET_EMAIL_TEMPLATE_BY_ID, getEmailTemplateByIdSaga),
    yield takeLatest(actions.GET_LABEL, getLabels),
  ]);
}
