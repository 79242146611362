import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import { confirmAlert } from "react-confirm-alert";

import { LeadsApi } from "../../firestore-api/leads";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import { ActivityApi } from "../../firestore-api/activity";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ApplicationApi } from "../../firestore-api/application";
import { NotificationApi } from "../../firestore-api/notification";
import { ProgramApi } from "../../firestore-api/program";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import FilterAction from "../../Utility/FilterAction";
import actions from "./actions";
import { callApi } from "../../Utility/superAgentUntil";
import { LearningApi } from "../../firestore-api/learning";
function* addBuildForm({ firebase, values, formItems, category, selectedDbList, applicationType, appliedBranches }) {
  try {
    let nodeId = yield call(ApplicationApi.generatesaveBuildFormNode, firebase, category);

    yield call(
      ApplicationApi.saveBuildForm,
      firebase,
      values,
      formItems,
      category,
      nodeId,
      selectedDbList,
      applicationType,
      appliedBranches
    );

    notification("success", formatMsg("success.saveForm"));

    yield put({
      type: actions.SAVE_FORM_FIELDS_SUCCESSFUL,
      externalKey: nodeId,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SAVE_FORM_FIELDS_FAILED,
    });
  }
}
function* editBuildForm({
  firebase,
  values,
  formItems,
  category,
  id,
  selectedDbList,
  applicationType,
  oldApplicationObj,
  appliedBranches
}) {

  try {
    yield call(
      ApplicationApi.editBuildForm,
      firebase,
      values,
      formItems,
      category,
      id,
      selectedDbList,
      applicationType,
      oldApplicationObj,
      appliedBranches
    );

    notification("success", formatMsg("success.updateForm"));

    yield put({
      type: actions.EDIT_BUILDING_FORM_SUCCESSFUL,
    });
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.EDIT_BUILDING_FORM_FAILED,
    });
  }
}

function* fetchFormDetail({ firebase, formItems, category, id }) {
  const chan = yield call(ApplicationApi.fetchFormDetail, firebase, formItems, category, id);
  try {
    while (true) {
      let detail = yield take(chan);

      yield put({
        type: actions.FETCH_BUILDING_FORM_SUCCESSFUL,
        formDetail: detail,
        formDetailChan: chan,
      });

      if (category === "leadForm") {
        yield fork(fetchLeadStatus, {
          firebase,
        });
      }
    }
  } finally {
    console.log("terminatig channel");
  }
}

function* fetchLeadStatus({ firebase }) {
  try {
    var data = yield call(LeadsApi.getLeadsStatus, firebase);

    if (data) {
      data = data.filter(e => {
        return e.name && e.name.toLowerCase() !== "admitted"
      })
      yield put({
        type: actions.FETCH_LEAD_STATUS_LIST_SUCCESS,
        leadStatusList: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
  }
}

function* fetchApplicationForms({ firebase }) {
  const chan = yield call(ApplicationApi.getAllApplications, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_ALL_APPLICATIONS_SUCCESS,
        applicationForms: data,
        applicationFormChan: chan,
      });
    }
  } finally {
  }
}
function* fetchTeacherApplications({ firebase }) {
  const chan = yield call(ApplicationApi.getTeacherApplications, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_TEACHER_APPLICATION_SUCCESS,
        teacherApplications: data,
        teacherApplicationChan: chan,
      });
    }
  } finally {
  }
}

function* fetchStudentFormSubmission({ applications, firebase, entityType }) {
  try {
    let formsMap = new Map();
    let allTasks = new Map();
    for (let index in applications) {
      let id = applications[index].id;
      let task = call(ApplicationApi.getStudentFormSubmission, id, firebase);
      allTasks.set(id, task);
    }

    for (let [key, value] of allTasks) {
      let newVal = yield all([value]);

      let appVal = newVal[0];
      formsMap.set(key, appVal);
    }

    yield put({
      type: actions.GET_STUDENT_FORMS_SUCCESS,
      studentForms: formsMap,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* fetchSingleApplicationForm({ id, firebase, entityType }) {
  try {
    const chan = yield call(
      ApplicationApi.getStudentFormSubmission,
      id,
      firebase,
      entityType
    );
    while (true) {
      let data = yield take(chan);
      if (data) {
        data.sort((a, b) => {
          if (!a.modifedDate || a.modifedDate === 0) {
            return 1;
          }

          if (!b.modifedDate || b.modifedDate === 0) {
            return -1;
          }

          return b.modifedDate - a.modifedDate;
        });

        yield put({
          type: actions.GET_SINGLE_APPLICATION_FORM_SUCCESS,
          singleApplicationForm: data,
          singleApplicationFormChan: chan
        });
      }
    }

  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}
function* fetchSharedApplications({ entityId, firebase, entityType }) {
  try {
    const formChan = yield call(
      ApplicationApi.getSharedApplications,
      entityId,
      firebase,
      entityType
    );

    while (true) {
      let data = yield take(formChan);

      if (data) {
        data.sort((a, b) => {
          return FilterAction.sortIntegerValue(a, b, "modifedDate");
        });

        yield put({
          type: actions.GET_SHARED_APPLICATIONS_SUCCESS,
          sharedApplications: data,
          chan: formChan,
        });
      }
    }
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({ type: actions.APPLICATION_ERROR });
  }
}

function* fetchApplicationById({
  applicationId,
  studentId,
  firebase,
  nodeId,
  entityType,
}) {
  try {
    if (!entityType)
      entityType = "student";
    // studentId will be entityId in case of other entity types
    let data = [];

    if (nodeId) {
      data = yield call(
        ApplicationApi.getStudentFormSubmissionById,
        nodeId,
        firebase,
        entityType
      );
    } else {
      data = yield call(
        ApplicationApi.getStudentFormSubmission,
        applicationId,
        firebase,
        entityType
      );
    }

    if (entityType == "student") {
      data = data && data.filter((d) => {
        return d.studentId === studentId;
      });
    } else {
      data = data && data.filter((d) => {
        return d.entityId === studentId;
      });
    }

    let studentForm = {};

    if (data && data.length > 0) {
      studentForm = data[0];
    }

    yield put({
      type: actions.GET_APP_BY_ID_SUCCESS,
      studentApplication: studentForm,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* updateAppSetting({ values, record, firebase }) {
  try {
    record.status = values.status;
    record.teacherIds = values.teachers ? values.teachers : null;
    record.alwaysAvailable = values.alwaysAvailable ? values.alwaysAvailable : false;
    record.updatedBy = firebase.teacher.name;
    record.platform = "web";
    record.entityType = values.entityType;

    let url = "woodlandApi/application/" + record.id + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, record, url, firebase);

    // yield call(ApplicationApi.updateApplication, obj, record.id, firebase);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.UPDATE_APPLICATION_SETTING_SUCCESS,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSettingFailed")
      );
      yield put({
        type: actions.APPLICATION_ERROR,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}
function* cloneApplication({ id, firebase, entityType }) {
  try {

    let requestPayload = {
      id,
      entityType
    };
    requestPayload = FilterAction.getSuperagentBody(requestPayload, firebase);
    let url = "woodlandApi/cloneApplication?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", url, requestPayload);

    if (response && response.status && response.status === 200) {
      notification(
        "success",
        formatMsg("application.cloneSuccess")
      );
      yield put({
        type: actions.CLONE_APPLICATION_SUCCESS,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSettingFailed")
      );
      yield put({ type: actions.APPLICATION_ERROR });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({ type: actions.APPLICATION_ERROR });
  }
}

function* updateFormActionByTeacher({ record, status, firebase, entityType }) {
  try {
    let obj = {};
    if (status === "changeRequest") {
      obj = {
        reviewComments: record.reviewComments,
        status: "in-review",
        id: record.id,
        applicationId: record.applicationId,
        updatedBy: firebase.teacher.name,
        platform: "web",
        updatedOn: moment().valueOf(),

      };
    } else if (status === "rejected") {
      obj = {
        rejectionNote: record.rejectionNote,
        status: "rejected",
        id: record.id,
        applicationId: record.applicationId,
        updatedBy: firebase.teacher.name,
        platform: "web",
        updatedOn: moment().valueOf(),

      };
    } else {
      obj = {
        status: status,
        id: record.id,
        applicationId: record.applicationId,
        updatedBy: firebase.teacher.name,
        platform: "web",
        updatedOn: moment().valueOf(),

      };
    }

    let response;
    if (entityType == "student") {
      let url = "woodlandApi/studentApplication/?centerId=";
      response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );
    } else {
      obj["entityType"] = entityType;
      let url = "woodlandApi/saveApplication/?centerId=";
      response = yield call(
        StudentAttendanceApi.requestApi,
        obj,
        url,
        firebase
      );
    }
    if (response && response.status && response.status === 200) {
      if (status === "approved") {
        // if (entityType == "lead") {
        //   notification("success", formatMsg("success.statusUpdate"));
        // }
        yield put({
          type: actions.UPDATE_FORM_ACTION_SUCCESS,
          applicationOperationType: "APPROVED"
        });
      } else {
        // if (entityType == "lead") {
        //   notification("success", formatMsg("success.statusUpdate"));
        // }
        yield put({
          type: actions.UPDATE_FORM_ACTION_SUCCESS,
          applicationOperationType: status === "changeRequest" ? "REQUEST_CHANGE" : "STATUS_UPDATED",
        });

      }
      if (entityType == "student") {
        let studentsMap = firebase.studentsMap;
        let selectedStudent = studentsMap.get(record.studentId);

        let activityName = "Application";
        let activityId = record.id;
        let body = "";
        if (status === "approved") {
          body = "Application has been approved";
        } else if (status === "rejected") {
          body = "Application has been rejected";
        } else if (status === "changeRequest") {
          body = "School has requested a review of the application";
        }

        if (selectedStudent && selectedStudent.id) {
          if (selectedStudent.fatherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent.fatherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              activityName,
              activityId,
              selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
              body,
              alertNode,
              selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
              selectedStudent.id,
              selectedStudent.fatherProfileId,
              firebase
            );

            if (
              selectedStudent.fatherUUid !== undefined ||
              selectedStudent.ios_fatherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                activityName,
                activityId,
                selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,
                body,
                alertNode,
                selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,
                selectedStudent.id,
                selectedStudent.fatherProfileId,
                firebase
              );
            }
          }

          if (selectedStudent.motherProfileId) {
            let alertNode = yield call(
              NotificationApi.createAlertReferenceNode,
              selectedStudent.motherProfileId,
              firebase
            );
            yield fork(
              NotificationApi.createAlertNotification,
              activityName,
              activityId,
              selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
              body,
              alertNode,
              selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
              selectedStudent.id,
              selectedStudent.motherProfileId,
              firebase
            );

            if (
              selectedStudent.motherUUid !== undefined ||
              selectedStudent.ios_motherUUid !== undefined
            ) {
              yield fork(
                NotificationApi.sendPushNotification,
                activityName,
                activityId,
                selectedStudent.motherUUid ? selectedStudent.motherUUid : null,
                body,
                alertNode,
                selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,
                selectedStudent.id,
                selectedStudent.motherProfileId,
                firebase
              );
            }
          }
        }
      }
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateFormStatus")
      );
      yield put({
        type: actions.APPLICATION_ERROR,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* fetchSingleStudentForms({ studentId, firebase }) {
  try {
    let data = yield call(ApplicationApi.getSingleStudentFormSubmission, studentId, firebase);
    yield put({
      type: actions.GET_SINGLE_STUDENT_FORM_SUCCESS,
      singleStudentForms: data,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* fetchSingleTeacherForms({ teacherId, firebase }) {
  try {
    let data = yield call(ApplicationApi.getSingleTeacherFormSubmission, teacherId, firebase);
    yield put({
      type: actions.GET_SINGLE_TEACHER_FORM_SUCCESS,
      singleTeacherForms: data,
    })

  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}
function* downloadFormSubmissionExcelForTeacher({ record, entityType, firebase }) {
  let recordData = undefined;
  if (record.id) {
    recordData = yield call(
      ApplicationApi.downloadtStudentFormSubmission,
      record.id,
      firebase,
      entityType
    );
  }
  else {
    recordData = record;
  }
  try {
    var fields = [];
    var teachersMap = firebase.teachersMap;

    fields = [
      "teacherName",
      "phoneNumber",
      "email",
      "teacherId",
      "employeeCode",
      "formTitle",
      "sharedOn",
      "status",
      "submissionDate",
      "submittedBy",
    ];
    let formList = [];
    let isTableAdded = false
    let tableColumns = ['teacherName']
    let tableRows = []

    for (let index in recordData) {
      let i = recordData[index];
      let teacher = teachersMap[i?.entityId];
      // let teachers = teachersMap.get(i.entityId);
      var row = {};
      row.teacherName = teacher.name;
      row.phoneNumber = teacher.phoneNumber ? teacher.phoneNumber : "";
      row.email = teacher.email ? teacher.email : "";
      row.teacherId = teacher.id,
        row.employeeCode = teacher.employeeCode ? teacher.employeeCode : "";
      row.formTitle = i.name;
      row.sharedOn = i.sharedOn ? moment(i.sharedOn).format("DD-MMM-YY") : "";
      row.status = i.status ? i.status : "";
      if (i.modifedDate) {
        row.submissionDate = i.modifedDate ? moment(i.modifedDate).format("DD-MMM-YY") : "";
      }
      if (i.submittedBy) {
        row.submittedBy = i.submittedBy ? i.submittedBy : "";
      } else if (i.updatedBy) {
        row.submittedBy = i.updatedBy ? i.updatedBy : "";
      }
      if (i.response) {
        if (i.response) {
          isTableAdded = processRow(i, row, fields, isTableAdded, teacher.name, tableRows);
        }
      }
      formList.push(row);
    }
    FilterAction.downloadCsvAsExcel("formSubmissions", formList, fields);

    yield put({
      type: actions.DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* downloadFormSubmissionExcel({ record, entityType, firebase }) {

  let recordData = undefined
  if (record.id) {
    recordData = yield call(
      ApplicationApi.downloadtStudentFormSubmission,
      record.id,
      firebase,
      entityType
    );
  }
  else {
    recordData = record
  }
  try {
    var fields = [];
    var studentsMap = firebase.studentsMap;
    fields = [
      "studentName",
      "classList",
      "admissionNumber",
      "formTitle",
      "sharedOn",
      "status",
      "submissionDate",
      "submittedBy",
    ];
    let formList = [];
    let isTableAdded = false
    let tableColumns = ['studentName']
    let tableRows = [];
    if (recordData[0]?.response) {
      let columnObjs = recordData[0].response.filter((item) => {
        if (item.dataType.toLowerCase() === "table")
          return item.columns
      })
      if (columnObjs.length > 0) {
        for (let col in columnObjs[0].columns) {
          tableColumns.push(columnObjs[0].columns[col].name)
        }
      }
    }


    for (let index in recordData) {
      const item = recordData[index];
      const student = studentsMap.get(item.studentId);

      const row = {
        studentName: student.name,
        classList: student.classList ? student.classList.toString() : student.classroomName,
        admissionNumber: student.admissionNumber || "",
        formTitle: item.name,
        sharedOn: !item.initiatedByParent ? getFormattedDate(item.sharedOn, "DD-MMM-YY") : "",
        status: item.status || "",
        submissionDate: getFormattedDate(item.modifedDate, "DD-MMM-YY"),
        submittedBy: item.submittedBy || item.updatedBy || ""
      };

      if (item.response) {
        isTableAdded = processRow(item, row, fields, isTableAdded, student.name, tableRows);
      }
      formList.push(row);
    }

    if (isTableAdded) {
      FilterAction.downloadTableAsExcel("formSubmissions", formList, fields, tableRows, tableColumns);
    }
    else {
      FilterAction.downloadCsvAsExcel("formSubmissions", formList, fields);

    }
    yield put({
      type: actions.DOWNLOAD_APP_FORM_SUBMISSION_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function processRow(item, row, fields, isTableAdded, entityName, tableRows) {
  for (let ind in item.response) {
    const { name, value, dataType: rawDataType, mediaProfiles } = item.response[ind];
    const dataType = rawDataType.toLowerCase();
    const propsName = `${name}(${ind})`;
    if (dataType === "digital signature") {
      const attachments = mediaProfiles ? mediaProfiles[0].path : undefined;
      row[propsName] = attachments || "";
    } else if (dataType == "document request") {
      handleAttachments(item.response[ind], propsName, row);
    }

    if (["document request", "contract", "digital signature", "section", "parent note"].includes(dataType)) continue;

    if (dataType === "date") {
      row[propsName] = value ? moment(value, ["DD-MM-YYYY", "DD, MMMM, YYYY"]).toDate() : "";
    } else {
      row[propsName] = value || "";
    }
    if (!fields.includes(propsName))
      fields.push(propsName);


    if (dataType === "table") {
      isTableAdded = true;
      item.response[ind].rows.forEach(tableRow => {
        const tempRow = { name: entityName };
        item.response[ind].columns.forEach(column => {
          if (column.type.toLowerCase() === "date") {
            tempRow[column.name] = getFormattedDate(tableRow[column.id], "DD,MMM YYYY");
          } else if (column.type.toLowerCase() === "time") {
            tempRow[column.name] = getFormattedDate(tableRow[column.id], "hh:mm A");
          } else {
            tempRow[column.name] = tableRow[column.id];
          }
        });
        tableRows.push(tempRow);
      });
    }
  }
  return isTableAdded;
}

function getFormattedDate(date, format) {
  return date ? moment(date).format(format) : "";
}

function handleAttachments(propVal, propsName, row) {
  if (propVal.mediaProfiles) {
    propVal.mediaProfiles.forEach((profile, index) => {
      const pathName = `${propsName} ${index + 1}`;
      row[pathName] = profile.path || "";
    });
  }
}


function* fetchApplicationClassroom({ firebase }) {
  try {
    //var classrooms = yield call(LearningApi.getLearningClassroom, firebase);
    let classrooms = JSON.parse(getItem("classList"));
    if (classrooms) {
      yield put({
        type: actions.GET_CLASSROOM_FOR_APPLICATION_SUCCESSFUL,
        classrooms: classrooms,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}
function* fetchStudentForApplication({ firebase }) {
  try {
    let data = FilterAction.getStudentList(firebase);

    if (data) {
      data = data.filter((std) => {
        return !std.status || std.status.toLowerCase() === "active";
      });
      yield put({
        type: actions.GET_STUDENT_FOR_APPLICATION_SUCCESSFUL,
        students: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}

function* shareApplication(
  firebase,
  record,
  studentId,
  selectedStudent,
  selectedStudentName
) {
  try {
    var id = yield call(ApplicationApi.createAtdNode, firebase);
    let obj = {
      applicationId: record.id,
      deleted: record.deleted,
      forceUpdate: false, ///
      initiatedByParent: false, //
      modifedDate: 0,
      name: record.name,
      response: record.fieldConfigModels,
      sharedBy: record.updatedBy,
      sharedOn: moment().valueOf(),
      status: "pending",
      studentId: studentId,
      studentName: selectedStudentName,
      id: id,
      type: record.type,
      updatedOn: moment().valueOf(),
    };

    let url = "woodlandApi/shareApplication/?centerId=";
    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      url,
      firebase
    );

    if (response && response.status === 200) {
      notification("success", formatMsg("success.shareForm"));
      yield put({
        type: actions.SHARE_APPLICATION_SUCCESSFUL,
      });
      let alertMessage =
        "New " + response.body.studentApplication.type + " Shared";

      yield fork(
        sendStudentApplicationNotification,
        selectedStudent,
        "Application",
        id,
        alertMessage,
        firebase
      );
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.shareApplication")
      );
      yield put({
        type: actions.APPLICATION_REQUEST_FAILED,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}


function* shareAdmissionProcess({ students, record, firebase, admissionProcess }) {
  try {
    let studentIds = [];
    students.forEach((std) => {
      studentIds.push(std.id);
    });
    let obj = {
      processid: record.id,
      studentIds: studentIds,
      admissionProcess: record,
    };
    var url = "woodlandApi/admissionProcess/shareProcess/?centerId=";
    var response = yield call(StudentAttendanceApi.requestApi, obj, url, firebase);
    if (response && response.status === 200) {
      let alreadysharedCount = response.body.admissionProcess.alreadySharedWithStudent;
      let newSharedCount = response.body.admissionProcess.newSharedWithStudent;
      let studentSize = studentIds.length;
      if (alreadysharedCount === studentSize && newSharedCount !== alreadysharedCount) {
        notification("error", formatMsg("error.alreadyShare"));
        yield put({
          type: actions.APPLICATION_REQUEST_FAILED,
        });
      } else {
        notification("success", newSharedCount === 1 ? formatMsg("admissionProcessShareMsg") + " " + newSharedCount + " " + formatMsg("student") : formatMsg("admissionProcessShareMsg") + " " + newSharedCount + " " + formatMsg("students"));
        yield put({
          type: actions.SHARE_ADMISSION_PROCESS_SUCCESS,
        });
      }
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.shareProcess")
      );
      yield put({
        type: actions.APPLICATION_REQUEST_FAILED,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* shareSelectedApplication({
  students,
  record,
  firebase,
  admissionProcess,
  entityType,
}) {
  try {

    switch (entityType) {
      case "applications": {
        yield fork(shareApplicationWithStudent, students, record, firebase);
      }
        break;
      case "teacherApplication":
        {
          yield fork(
            shareApplicationWithEntity,
            students,
            record,
            firebase,
            entityType
          );
        }
        break;
      case "lead": {
        yield fork(
          shareApplicationWithEntity,
          students,
          record,
          firebase,
          entityType
        );
      }
    }

  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}

function* shareApplicationWithStudent(students, record, firebase) {
  try {
    let selectedStudent = students;
    let entities = selectedStudent.map((i) => ({ id: i.id, name: i.name }));

    let obj = {
      application: record,
      deleted: record.deleted,
      forcedUpdate: record.forceUpdate ? true : false, ///
      initiatedByParent: false, //
      modifedDate: 0,
      name: record.name,
      response: record.fieldConfigModels,
      sharedBy: record.updatedBy,
      sharedOn: moment().valueOf(),
      status: "pending",
      // studentId: studentId,
      // studentName: selectedStudentName,

      type: record.type,
      updatedOn: moment().valueOf(),

      entityType: "student",
      entities: entities,
    };

    let endPointUrl =
      "woodlandApi/shareApplication?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, obj);
    if (response?.body?.code == 201) {
      yield put({
        type: actions.SHARE_APPLICATION_ALREADY_SHARED,
        alreadySharedIds: response.body?.response
      });
    }
    if (response.body.code == 200) {
      notification("success", formatMsg("application.sharedSuccess"));
      yield put({
        type: actions.SHARE_APPLICATION_SUCCESSFUL,
      });
    } else if (response?.body?.code !== 201) {
      throw new Error("Failed to share application");
    }
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}

function* unshareApplication({ record, firebase }) {
  try {

    let obj = FilterAction.getSuperagentBody(record, firebase);

    let endPointUrl =
      "woodlandApi/studentApplication/" + record.id + "?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "delete", endPointUrl, obj);

    if (response.body.code == 200) {
      notification("success", formatMsg("application.unshareSuccess"));
      yield put({
        type: actions.UNSHARE_APPLICATION_SUCCESS,
      });
    } else {
      throw new Error("response is not 200");
    }

  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}
function* copyApplicationFiles({ record, firebase }) {
  try {

    let obj = FilterAction.getSuperagentBody(record, firebase);

    let endPointUrl =
      "woodlandApi/copyStudentApplicationFiles/?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, obj);

    if (response.body.code == 200) {
      notification("success", formatMsg("application.copyFilesSuccess"));
      yield put({
        type: actions.COPY_APPLICATION_FILES_SUCCESS,
      });
    } else {
      throw new Error("response is not 200");
    }

  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}

function* syncApplicationFormData({ record, firebase }) {
  try {
    let obj = FilterAction.getSuperagentBody(record, firebase);

    let endPointUrl =
      "woodlandApi/syncApplicationWithEntity/?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, obj);
    if (response.status == 200) {
      notification("success", formatMsg("Form Synced"));
      yield put({
        type: actions.COPY_APPLICATION_FILES_SUCCESS,
      });
    } else {
      throw new Error("response is not 200");
    }

  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}

function* shareApplicationWithEntity(teachers, record, firebase, entityType) {
  try {
    let selectedTeachers = teachers;
    let entities = selectedTeachers.map((i) => ({ id: i.id, name: i.name }));

    let obj = {
      application: record,
      deleted: record.deleted,
      forcedUpdate: record.forceUpdate ? true : false, ///
      initiatedByParent: false, //
      modifedDate: 0,
      name: record.name,
      response: record.fieldConfigModels,
      sharedBy: record.updatedBy,
      sharedOn: moment().valueOf(),
      status: "pending",
      // studentId: studentId,
      // studentName: selectedStudentName,

      type: record.type,
      updatedOn: moment().valueOf(),

      entityType,
      entities: entities,
    };

    let endPointUrl =
      "woodlandApi/shareApplication?centerId=" + firebase.sbDbName;
    let response = yield callApi(firebase, "post", endPointUrl, obj);

    if (response?.body?.code == 201) {

      yield put({
        type: actions.SHARE_APPLICATION_ALREADY_SHARED,
        alreadySharedIds: response.body?.response
      });
    }


    if (response?.body?.code == 200) {
      notification("success", "Application shared successfully");

      yield put({
        type: actions.SHARE_APPLICATION_SUCCESSFUL,
      });

    }
    else if (response?.body?.code !== 201) {
      throw new Error("Failed to share application");
    }
  } catch (err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_REQUEST_FAILED,
    });
  }
}
function* sendStudentApplicationNotification(
  selectedStudent,
  activityName,
  activityId,
  body,
  firebase
) {
  if (selectedStudent && selectedStudent.id) {
    if (selectedStudent.fatherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,

        selectedStudent.fatherProfileId,

        firebase
      );

      yield fork(
        NotificationApi.createAlertNotification,

        activityName,

        activityId,

        selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,

        body,

        alertNode,

        selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,

        selectedStudent.id,

        selectedStudent.fatherProfileId,

        firebase
      );

      if (
        selectedStudent.fatherUUid !== undefined ||
        selectedStudent.ios_fatherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,

          activityName,

          activityId,

          selectedStudent.fatherUUid ? selectedStudent.fatherUUid : null,

          body,

          alertNode,

          selectedStudent.ios_fatherUUid ? selectedStudent.ios_fatherUUid : null,

          selectedStudent.id,

          selectedStudent.fatherProfileId,

          firebase
        );
      }
    }

    if (selectedStudent.motherProfileId) {
      let alertNode = yield call(
        NotificationApi.createAlertReferenceNode,

        selectedStudent.motherProfileId,

        firebase
      );

      yield fork(
        NotificationApi.createAlertNotification,

        activityName,

        activityId,

        selectedStudent.motherUUid ? selectedStudent.motherUUid : null,

        body,

        alertNode,

        selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,

        selectedStudent.id,

        selectedStudent.motherProfileId,

        firebase
      );

      if (
        selectedStudent.motherUUid !== undefined ||
        selectedStudent.ios_motherUUid !== undefined
      ) {
        yield fork(
          NotificationApi.sendPushNotification,

          activityName,

          activityId,

          selectedStudent.motherUUid ? selectedStudent.motherUUid : null,

          body,

          alertNode,

          selectedStudent.ios_motherUUid ? selectedStudent.ios_motherUUid : null,

          selectedStudent.id,

          selectedStudent.motherProfileId,

          firebase
        );
      }
    }
  }
}

function* getAppLogs({ applicationForm, firebase }) {
  try {
    let data = yield call(ApplicationApi.getApplicationLogs, applicationForm, firebase);

    data.sort(function (a, b) {
      var dateA = a.date,
        dateB = b.date;
      return dateB - dateA;
    });
    if (data) {
      yield put({
        type: actions.FETCH_APP_LOG_SUCCESS,
        appLogs: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch app logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* saveFormItems({ values, firebase, formCategory }) {
  
  try {
    if(formCategory.toLowerCase()==="lessonfieldref"){
      values["html"] = false;
      let reqBody = FilterAction.getSuperagentBody(values, firebase);
      let endPointUrl = "woodlandApi/learning/lessonAdditionalField?centerId=" + firebase.sbDbName;
      let response = yield callApi(firebase, "post", endPointUrl, reqBody);
    
      if(response && response.status ===  200){
        notification("success", formatMsg("success.saveFormItem"));
        yield put({
          type: actions.SAVE_FORM_ITEMS_SUCCESSFUL,
          operationRequest: "ITEM_SAVE",
        });
      }
    }
    else{
      let response = yield call(ApplicationApi.saveFormItems, values, firebase, formCategory);
      if(response && response.status ===  200){
        notification("success", formatMsg("success.saveFormItem"));
        yield put({
          type: actions.SAVE_FORM_ITEMS_SUCCESSFUL,
          operationRequest: "ITEM_SAVE",
        });
      }
    }
  
  } catch (err) {
    console.log("failed to fetch app logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* deleteFormItem({ formCategory, item, firebase }) {
  try {
  if(formCategory.toLowerCase()==="lessonfieldref"){
   let endpoint = "woodlandApi/learning/lessonAdditionalField/"+item.id+"?centerId=" + firebase.sbDbName;
   let response = yield callApi(firebase, "delete", endpoint, {});
   if(response && response.status ===  200){
    yield put({
      type: actions.DELETE_FORM_ITEM_SUCCESS,
      operationRequest: "ITEM_DELETE",
     });
     notification("success", formatMsg("success.deleteFormItem"));
    }
  }
  else{
    let response  = yield call(ApplicationApi.deleteFormItem, formCategory, item, firebase);
    if(response.status ===  200){
     yield put({
       type: actions.DELETE_FORM_ITEM_SUCCESS,
       operationRequest: "ITEM_DELETE",
     });
     notification("success", formatMsg("success.deleteFormItem"));
   }
  }
  
  } catch (err) {
    console.log("failed to fetch app logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* fetchFormItems({ firebase, formCategory }) {
  try {
    let data = yield call(ApplicationApi.getFormItem, firebase, formCategory);

    if (data) {
      yield put({
        type: actions.FETCH_FORM_ITEMS_SUCCESSFUL,
        formItem: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch app logs", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* addFreshAdmissionForm({ values, firebase }) {
  try {
    let obj = {
      ...values,
      dataType: values.dataType === "Text" ? "String" : values.dataType,
      deleted: false,
      // mandatory: values.mandatory,
      // name: values.name,
      // fieldType:va
    };

    let fieldUrl;
    if (values.fieldType.toLowerCase() === "student") {
      fieldUrl = "woodlandApi/additionField/student/?centerId=";
    } else {
      fieldUrl = "woodlandApi/additionField/parent/?centerId=";
    }

    let response = yield call(StudentAttendanceApi.requestApi, obj, fieldUrl, firebase);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.ADD_ADMISSION_FORM_SUCCESS,
        operationRequest: "ITEM_SAVE",
      });
      notification("success", formatMsg("success.saveFormItem"));
    } else {
      yield put({
        type: actions.APPLICATION_ERROR,
      });
      Utility.showAlert(
        "Failed",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addAdmissionForm")
      );
    }
  } catch (err) {
    console.log("failed to add admission form", err);
  }
}

function* saveFormStyles({ styleObj, id, firebase }) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  rsf.ref(branchPath + "/leadForm/" + id).update(styleObj);
  notification("success", formatMsg("success.saveCustomizeStyle"));
}

function* updateAdmissionFormExisting({ values, firebase }) {
  try {
    let fieldUrl = "woodlandApi/additionField/" + values.id + "?centerId=";
    if (values.deleted) {
      let response = yield call(StudentAttendanceApi.deleteApi, fieldUrl, firebase);
      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.UPDATE_ADMISSION_FORM_SUCCESS,
          operationRequest: "ITEM_DELETE",
        });
        notification("success", formatMsg("success.deleteFormItem"));
      } else {
        yield put({
          type: actions.APPLICATION_ERROR,
        });
        Utility.showAlert(
          "Failed",
          response.body && response.body.response
            ? response.body.response
            : formatMsg("error.deleteAdmissionForm")
        );
      }
    } else {
      let response = yield call(StudentAttendanceApi.requestApi, values, fieldUrl, firebase);
      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.UPDATE_ADMISSION_FORM_SUCCESS,
          operationRequest: "ITEM_SAVE",
        });
        notification("success", formatMsg("success.updateFormItem"));
      } else {
        yield put({
          type: actions.APPLICATION_ERROR,
        });

        Utility.showAlert(
          "Failed",
          response.body && response.body.response
            ? response.body.response
            : "Failed to update admission form. Contact school or Illumine"
        );
      }
    }
  } catch (err) {
    console.log("failed to update or delete admission form", err);
  }
}

function* saveAdmissionProcess({ values, nodeId, firebase }) {
  try {
    let object;
    if (!nodeId) {
      object = {
        description: values.additionalDescription ? values.additionalDescription : null,
        dueDate: values.dueDate ? moment(values.dueDate).valueOf() : null,
        processName: values.processName,
        status: values.status,
        createdBy: firebase.teacher.name,
        platform: "web",
        createdOn: moment().valueOf(),
      };
    } else {
      object = values;
    }
    let url = "woodlandApi/admissionProcess/saveAdmissionProcess/?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, object, url, firebase);
    if (response && response.status === 200) {
      notification("success", formatMsg("success.saveAdmissionProcess"));

      yield put({
        type: actions.ADMISSION_PROCESS_SUCCESS,
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.saveProcess")
      );
      yield put({
        type: actions.APPLICATION_REQUEST_FAILED,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}
function* fetchAdmissionProcess({ firebase }) {
  const chan = yield call(ApplicationApi.getAllAdmissionProcess, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.FETCH_ADMISSION_PROCESS_SUCCESS,
        process: data,
        processChannel: chan,
      });
    }
  } finally {
    console.log("end admission channel");
  }
}
function* fetchSelectedAdmissionProcess({ id, firebase }) {
  const chan = yield call(ApplicationApi.getAdmissionProcess, id, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.FETCH_SELECTED_ADMISSION_PROCESS_SUCCESS,
        selectedProcess: data,
        selectedProcessChannel: chan,
      });
    }
  } finally {
    console.log("end selected admission channel");
  }
}

function* deleteAdmissionProcess({ item, id, firebase }) {
  try {
    let url = "woodlandApi/admissionProcess/deleteProcess/" + id + "?centerId=";
    let obj = {
      id: id,
    };
    let response = yield call(ApplicationApi.deleteApi, obj, url, firebase);

    if (response && response.status === 200) {
      notification("success", formatMsg("success.processDeleted"));
      yield put({
        type: actions.DELETE_ADMISSION_PROCESS_SUCCESS,
        deleteRedirect: true,
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteProcess")
      );
      yield put({
        type: actions.APPLICATION_ERROR,
      });
    }
  } catch (err) {
    console.log("failed to delete process", err);
    bugsnagClient.notify(err);
  }
}

function* getAllStudentWithProcessShare({ firebase }) {
  const chan = yield call(
    ApplicationApi.getAllStudentWithProcessShare,

    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_TOTAL_STUDENT_SUCCESS,
        shareProcessWithStudent: data,
        shareProcessWithStudentChannel: chan,
      });
    }
  } finally {
    console.log("end total application share channel");
  }
}

function* fetchStudentPrograms({ firebase }) {
  try {
    let data = yield call(ProgramApi.fetchPrograms, firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_PROGRAMS_SUCCESS,
        studentPrograms: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to fetch student programs", err);
  }
}

function* deleteSelectedStudentFromProcess({ studentId, processId, firebase, studentIds }) {
  try {
    let url = "woodlandApi/admissionProcess/removeStudentFromProcess" + "?centerId=";
    let obj = {
      studentId: studentId,
      processId: processId,
      studentIdsList: studentIds,
    };

    let response = yield call(ApplicationApi.deleteApi, obj, url, firebase);
    if (response && response.status === 200) {
      notification("success", formatMsg("success.studentDeleted"));
      yield put({
        type: actions.DELETE_SELECTED_STUDENT_SUCCESS,
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteStudent")
      );
      yield put({
        type: actions.APPLICATION_ERROR,
      });
    }
  } catch (err) {
    console.log("failed to delete student", err);
    bugsnagClient.notify(err);
  }
}
function* fetchAllProcessForStudent({ firebase }) {
  try {
    let data = yield call(ApplicationApi.fetchAllProcessForStudent, firebase);

    if (data) {
      yield put({
        type: actions.GET_ALL_PROCESS_FOR_STUDENT_SUCCESS,
        allAdmissionprocess: data,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    console.log("failed to fetch process ", err);
  }
}
function* fetchStudentProgress({ processId, firebase }) {
  const chan = yield call(
    ApplicationApi.getStudentProgress,

    processId,
    firebase
  );
  try {
    while (true) {
      let data = yield take(chan);

      if (data) {
        yield put({
          type: actions.GET_STUDENT_PROGRESS_DATA_SUCCESS,
          studentProgressData: data.length > 0 ? data[0].studentProgressList : [],
          stepLength: data.length > 0 ? data[0].lengthOfProcess : [],
          studentList: data.length > 0 ? data[0].studentsList : [],
          studentProgressChannel: chan,
        });
      }
    }
  } finally {
    console.log("end student Progress share channel");
  }
}

function* uploadContractInForm({ mediaFile, firebase }) {
  var storagePath = firebase.sbp + "/media/file/";
  let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, mediaFile, firebase);
  if (urls) {
    yield put({
      type: actions.UPLOAD_CONTRACT_IN_FORM_SUCCESS,
      mediaFile: urls,
    });
  }
}

function* addColumns({ columnObj, firebase }) {
  let endpoint = "woodlandApi/applicationTable?centerId=" + firebase.sbDbName;
  let response = yield call(callApi, firebase, "post", endpoint, columnObj);
  if (response.status == 200 && columnObj.columnId) {
    notification("success", "Column edited successfully");
    yield put({
      type: actions.ADD_COLUMNS_SUCCESSFULL
    })
  }
  else if (response.status == 200 && !columnObj.columnId) {
    notification("success", "Column saved successfully");
    yield put({
      type: actions.ADD_COLUMNS_SUCCESSFULL
    })
  }
}

function* saveTable({ firebase, rows, formId, tableId, operationType }) {
  let tableObj = {
    formId: formId,
    tableId: tableId,
    rows: rows
  }
  let endpoint = "woodlandApi/applicationTable/saveTable?centerId=" + firebase.sbDbName;
  let response = yield call(callApi, firebase, "post", endpoint, tableObj);
  if (response.status == 200 && operationType.toLowerCase() == "savetable") {
    notification("success", "Table saved successfully");
    yield put({
      type: actions.SAVE_TABLE_SUCCESSFULL
    })

  }
  else if (response.status == 200 && operationType.toLowerCase() == "deleterow") {
    notification("success", "Row deleted successfully");
    yield put({
      type: actions.SAVE_TABLE_SUCCESSFULL
    })

  }

}
function* deleteColumn({ firebase, index, tableId, formId }) {
  let columnToDelete = {
    formId: formId,
    tableId: tableId,
    index: index
  }
  let endpoint = "woodlandApi/applicationTable?centerId=" + firebase.sbDbName;
  let response = yield call(callApi, firebase, "delete", endpoint, columnToDelete);
  if (response.status == 200) {
    notification("success", "Column deleted successfully");
    yield put({
      type: actions.DELETE_COLUMN_SUCCESSFULL
    })
  }
}
function* deleteApplication({ firebase, applicationType, id }) {
  try {
    let url = "woodlandApi/deleteApplication?centerId=" + firebase.sbDbName;
    let requestPayload = {
      entityType: applicationType,
      applicationId: id
    };
    let response = yield callApi(firebase, "delete", url, requestPayload);
    if (response && response.status === 200) {
      notification("success", formatMsg("application.deleteSuccess"));
      yield put({
        type: actions.DELETE_APPLICATION_SUCCESS
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.errorOccured")
      );
      yield put({
        type: actions.DELETE_APPLICATION_ERROR,
      });
    }
  } catch (err) {
    yield put({
      type: actions.DELETE_APPLICATION_ERROR,
    });
    console.log("failed to delete application", err);
    bugsnagClient.notify(err);
  }
}

function* sendApplicationEmail({ firebase, application, entityType }) {
  try {
    let url = "woodlandApi/sendApplicationShareEmail?centerId=" + firebase.sbDbName;
    let requestPayload = {
      shareRefId: application.id,
      entityType: entityType
    };
    let response = yield callApi(firebase, "post", url, requestPayload)
    if (response && response.status === 200) {
      yield put({
        type: actions.SEND_APPLICATION_EMAIL_SUCCESS
      });
    } else {
      notification(
        "error",
        response.body && response.body.response
          ? response.body.response
          : formatMsg("error.errorOccured")
      );
      yield put({
        type: actions.SEND_APPLICATION_EMAIL_ERROR,
      });
    }
  } catch (err) {
    yield put({
      type: actions.SEND_APPLICATION_EMAIL_ERROR,
    });
    console.log("failed to delete application", err);
    bugsnagClient.notify(err);
  }
}
function* disableAcceptingResponses({ val, record, firebase }) {
  try {
    record.disableResponse = val;

    let url = "woodlandApi/application/" + record.id + "?centerId=";
    let response = yield call(StudentAttendanceApi.requestApi, record, url, firebase);

    // yield call(ApplicationApi.updateApplication, obj, record.id, firebase);
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DISABLE_ACCEPTING_RESPONSES_SUCCESSFUL,
        msg: val ? "ENABLED_APPCEPTING_RESPONSES" : "DISABLED_ACCEPTING_RESPONSES"
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.updateSettingFailed")
      );
      yield put({
        type: actions.APPLICATION_ERROR,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.APPLICATION_ERROR,
    });
  }
}

function* fetchFormUserName({entityId,entityType,studentId,firebase}){
  try{
    let formEntityType = entityType ? entityType.toLowerCase() : undefined;
    let userName;
    switch(formEntityType){
      case "student":
        let stdId = entityId ? entityId : studentId;
        userName = firebase.studentsMap.has(stdId) ? firebase.studentsMap.get(stdId).name : undefined;
        break;
      
      case "teacher":
        userName = firebase.teachersMap[(entityId)] ? firebase.teachersMap[(entityId)].name : undefined;
        break;

      case "lead":
        let leadVal = yield call(LeadsApi.fetchLeadsForTask, entityId, firebase);
        if(leadVal && leadVal.student && leadVal.student.name){
          userName = leadVal.student.name;
        };
        break;

      default:
        let sId = entityId ? entityId : studentId;
        if(sId){
          userName = firebase.studentsMap.has(entityId) ? firebase.studentsMap.get(entityId).name : undefined;
        }
    }
    yield put({
      type: actions.GET_FORM_USER_NAME_SUCCESS,
      formUserName:userName
    });
  }catch(err){
    console.log("failed to fetch form user name",err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_ALL_APPLICATIONS, fetchApplicationForms),
    yield takeLatest(actions.GET_STUDENT_FORMS, fetchStudentFormSubmission),
    yield takeLatest(actions.GET_SINGLE_APPLICATION_FORM, fetchSingleApplicationForm),
    yield takeLatest(actions.GET_APP_BY_ID, fetchApplicationById),
    yield takeLatest(actions.UPDATE_APPLICATION_SETTING, updateAppSetting),
    yield takeLatest(actions.UPDATE_FORM_ACTION, updateFormActionByTeacher),
    yield takeLatest(actions.GET_SINGLE_STUDENT_FORM, fetchSingleStudentForms),
    yield takeLatest(actions.DOWNLOAD_APP_FORM_SUBMISSION, downloadFormSubmissionExcel),
    yield takeLatest(actions.SAVE_FORM_FIELDS, addBuildForm),
    yield takeLatest(actions.FETCH_BUILDING_FORM, fetchFormDetail),
    yield takeLatest(actions.EDIT_BUILDING_FORM, editBuildForm),
    yield takeLatest(actions.FETCH_LEAD_STATUS_LIST, fetchLeadStatus),
    yield takeLatest(actions.GET_CLASSROOM_FOR_APPLICATION, fetchApplicationClassroom),
    yield takeLatest(actions.GET_STUDENT_FOR_APPLICATION, fetchStudentForApplication),
    yield takeLatest(actions.SHARE_APPLICATION, shareSelectedApplication),
    yield takeLatest(actions.SHARE_ADMISSION_PROCESS, shareAdmissionProcess),
    yield takeLatest(actions.FETCH_APP_LOG, getAppLogs),
    yield takeLatest(actions.SAVE_FORM_ITEMS, saveFormItems),
    yield takeLatest(actions.DELETE_FORM_ITEM, deleteFormItem),
    yield takeLatest(actions.FETCH_FORM_ITEMS, fetchFormItems),
    yield takeLatest(actions.UPDATE_FORM_STYLE, saveFormStyles),
    yield takeLatest(actions.ADD_ADMISSION_FORM, addFreshAdmissionForm),
    yield takeLatest(actions.UPDATE_ADMISSION_FORM, updateAdmissionFormExisting),
    yield takeLatest(actions.ADMISSION_PROCESS, saveAdmissionProcess),
    yield takeLatest(actions.FETCH_ADMISSION_PROCESS, fetchAdmissionProcess),
    yield takeLatest(actions.FETCH_SELECTED_ADMISSION_PROCESS, fetchSelectedAdmissionProcess),

    yield takeLatest(actions.DELETE_ADMISSION_PROCESS, deleteAdmissionProcess),

    yield takeLatest(actions.GET_TOTAL_STUDENT, getAllStudentWithProcessShare),

    yield takeEvery(actions.GET_STUDENT_PROGRAMS, fetchStudentPrograms),

    yield takeLatest(actions.DELETE_SELECTED_STUDENT, deleteSelectedStudentFromProcess),
    yield takeLatest(actions.GET_ALL_PROCESS_FOR_STUDENT, fetchAllProcessForStudent),
    yield takeLatest(actions.GET_STUDENT_PROGRESS_DATA, fetchStudentProgress),
    yield takeLatest(actions.UPLOAD_CONTRACT_IN_FORM, uploadContractInForm),
    yield takeLatest(actions.ADD_COLUMNS, addColumns),
    yield takeLatest(actions.SAVE_TABLE, saveTable),
    yield takeLatest(actions.DELETE_COLUMN, deleteColumn),
    yield takeLatest(actions.GET_TEACHER_APPLICATION, fetchTeacherApplications),
    yield takeLatest(actions.GET_SHARED_APPLICATIONS, fetchSharedApplications),
    yield takeLatest(actions.UNSHARE_APPLICATION, unshareApplication),
    yield takeLatest(actions.COPY_APPLICATION_FILES, copyApplicationFiles),
    yield takeLatest(actions.SYNC_FORM_DATA, syncApplicationFormData),
    yield takeLatest(actions.CLONE_APPLICATION, cloneApplication),
    yield takeLatest(actions.DELETE_APPLICATION, deleteApplication),
    yield takeLatest(actions.SEND_APPLICATION_EMAIL, sendApplicationEmail),
    yield takeLatest(actions.DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER, downloadFormSubmissionExcelForTeacher),
    yield takeLatest(actions.GET_SINGLE_TEACHER_FORM, fetchSingleTeacherForms),
    yield takeLatest(actions.DISABLE_ACCEPTING_RESPONSES, disableAcceptingResponses),
    yield takeLatest(actions.GET_FORM_USER_NAME, fetchFormUserName),

  ]);
}
