import React from "react";
import { confirmAlert } from "react-confirm-alert";
import colors from "./../Utility/colorFactory";
import Bugsnag from "@bugsnag/js";
import formatMsg from "../components/utility/formatMessageUtil";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      chunckError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("error boundary caught exception", error);
    if (error) {
      if (
        error &&
        error.message &&
        (error.message.includes("ChunkLoadError") || error.message.includes("Loading chunk"))
      ) {
        return {
          hasError: true,
          chunckError: true,
        };
      } else {
        return { hasError: true };
      }
    } else {
      return { hasError: true };
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Bugsnag.notify(error);
  }

  showError() {
    if (this.state.chunckError) {
      return "New release!";
    } else {
      return "Uh oh,there was an error on our side!";
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {confirmAlert({
            closeOnClickOutside:false,
            title: (
              <p
                style={{
                  fontSize: 20,
                  color: colors.v2_fiord,
                }}
              >
                {this.showError()}
              </p>
            ),
            message: formatMsg("errorBoundary.tryAgain"),
            buttons: [
              {
                label: "OK",
                onClick: () => {
                  window.location.reload(false);
                },
              },
            ],
          })}
        </div>
      );
    }

    return this.props.children;
  }
}
