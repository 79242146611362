import { Input, Modal, Tooltip } from "antd";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import { withFirebase } from "../../components/firebase/context";
import formatMsg from "../../components/utility/formatMessageUtil";
import file from "../../image/file.png"
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
const { TextArea } = Input;

function TitleView(props) {
    let vaccine = props.vaccine;

    const [isModalOpen, setIsModelOpen] = useState(false);
    const [note, setNote] = useState("");

    function handleSubmit() {
        handleCancel();
    }
    function handleCancel() {
        setIsModelOpen(false);
    }
    return (
        <div>
            <span style={styles.titleDiv}>{vaccine.vaccineName.toLocaleUpperCase()}</span>
            <div
                style={styles.vaccineInfoDiv}
                onClick={() => {
                    setIsModelOpen(true),
                        setNote(vaccine.instruction)
                }} >
                <Tooltip title={vaccine.instruction} placement="top">
                    <div style={styles.headerDiv}>
                        {
                            vaccine.mandatory
                                ?
                                <p style={styles.mandatory} >{formatMsg("field.mandatory")}</p>
                                :
                                null
                        }
                        {
                            vaccine?.instruction?.length > 0
                                ?
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: vaccine.mandatory ? "0px" : "5px"
                                }}>
                                    <img
                                        alt={formatMsg("immunization.instruction")}
                                        style={{
                                            marginRight: "5px",
                                            height: 15,
                                            width: 12,
                                            marginTop: "5px",
                                            cursor: "pointer"
                                        }}
                                        src={file}
                                    />
                                    <p style={styles.noteP} >
                                        {formatMsg("immunization.instruction")}
                                    </p>
                                </div>
                                :
                                null
                        }
                    </div>

                </Tooltip>
                {
                    (vaccine.vaccineLevelExempted || vaccine.studentLevelExempted) ?
                        <div style={styles.exemptDiv}>
                            {formatMsg("immunization.exempt")}
                        </div>
                        : null
                }
            </div>
            <Modal
                title={formatMsg("immunization.vaccineNote")}
                visible={isModalOpen}
                onOk={handleSubmit}
                onCancel={handleCancel}
                cancelText={formatMsg("cancel")}
                footer={null}
                maskClosable={false}
            >
                <TextArea
                    style={styles.draftStyle}
                    placeholder={formatMsg("immunization.vaccineNote")}
                    value={note}
                    autosize={{
                        minRows: 4,
                        maxRows: 6,
                    }}
                    disabled={true}
                />
            </Modal>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);
export default a(TitleView);
const styles = {
    headerDiv: {
        display: "flex",
        flexDirection: "column"
    },
    titleDiv: {
        fontWeight: "normal",
        fontSize: webFont.extraLarge
    },
    draftStyle: {
        padding: "8px 8px 8px 8px",
        fontWeight: 600,
        color: "#565f68",
    },
    exemptDiv: {
        backgroundColor: colors.grey_border_primary,
        color: colors.black636E80,
        borderRadius: "5px",
        width: "fit-content",
        padding: "3px 7px 3px 7px",
        marginRight: "5px",
        fontSize: webFont.medium,
        fontWeight: "normal",
        marginTop: "5px"
    },
    vaccinationNoteDiv: {
        display: "flex",
        flexDirection: "row",
        marginTop: "5px"
    },
    mandatoryText: {
        fontSize: webFont.medium,
        fontWeight: "normal",
        color: colors.lightBlue,
        cursor: "pointer",
        marginTop: "5px",
    },
    noteP: {
        fontSize: webFont.medium,
        fontWeight: "normal",
        color: colors.v2_Cerulean,
        cursor: "pointer",
        marginTop: "3px",
    },
    vaccineInfoDiv: {
        display: "flex",
        flexDirection: "column",
        marginTop: "-5px"
    },
    mandatory: {
        fontSize: webFont.small,
        fontWeight: "normal",
        color: colors.lightBlue,
        cursor: "pointer",
        marginTop: "5px",
    }
}
