import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ActivityApi } from "../../firestore-api/activity";
import { NotificationApi } from "../../firestore-api/notification";
import { TeacherApi } from "../../firestore-api/teacher";
import { DocumentApi } from "../../firestore-api/teacherDocument";
import { StudentApi } from "../../firestore-api/student";
import actions from "./actions";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";

function* fetchDocuments({ firebase }) {
  const chan = yield call(DocumentApi.getAllDocuments, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.LIST_DOCUMENTS_SUCCESSFUL,
        documents: data,
        documentChannel: chan,
      });
    }
  } finally {
    console.log("end document channel");
  }
}

function* uploadAndSaveDocument({ fileList, firebase, mediaType, filePath }) {
  try {
    let storagePath;
    if (mediaType === "File") {
      storagePath = firebase.sbp + "/media/file/";
    } else {
      storagePath = firebase.sbp + "/media/images/";
    }
    let attachment = {
      fileList: fileList,
    };
    let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, attachment, firebase);
    if (urls) {
      for (let index = 0; index < urls.length; index++) {
        var uniqueNodeId = yield call(DocumentApi.createDocumentNode, undefined, firebase);

        let mediaPaths = [];
        mediaPaths = urls[index];

        if (mediaPaths) {
          yield call(
            DocumentApi.addDocument,
            mediaPaths,
            uniqueNodeId,
            mediaType,
            firebase,
            fileList,
            filePath,
            index
          );

          //let teachers = yield call(DashboardApi.getAllStaff, firebase);
          let teachers = JSON.parse(getItem("teacherList"));

          if (teachers) {
            for (let index in teachers) {
              if (
                teachers[index].role &&
                (teachers[index].role === "Administrator" ||
                  teachers[index].role === "Principal" ||
                  teachers[index].role === "Teacher")
              ) {
                let alertNode = yield call(
                  NotificationApi.createAlertReferenceNode,
                  teachers[index].id,
                  firebase
                );

                let creator = undefined;
                yield fork(
                  NotificationApi.createSimpleAlertNotification,
                  "documents",
                  uniqueNodeId,
                  teachers[index].uid ? teachers[index].uid : null,
                  "Document added by " + firebase.teacher.name,
                  alertNode,
                  teachers[index].ios_uid ? teachers[index].ios_uid : null,
                  null,
                  teachers[index].id,
                  "documents",
                  undefined,
                  firebase,
                  creator
                );

                if (teachers[index].uid !== undefined || teachers[index].ios_uid !== undefined) {
                  yield fork(
                    NotificationApi.sendSimplePushNotification,
                    "documents",
                    uniqueNodeId,
                    teachers[index].uid ? teachers[index].uid : null,
                    "Document added by " + firebase.teacher.name,
                    alertNode,
                    teachers[index].ios_uid ? teachers[index].ios_uid : null,
                    null,
                    teachers[index].id,
                    "documents",
                    undefined,
                    firebase,
                    creator
                  );
                }
              }
            }
          }
        }
      }
      yield put({
        type: actions.SAVE_DOCUMENT_SUCCESSFUL,
      });
    } else {
      yield put({
        type: actions.DOCUMENT_REQUEST_FAILED,
      });
    }
  } catch (error) {
    console.log("failed to add teacher document", error);
    bugsnagClient.notify("curriculum document upload error --->>" + error.message);
    yield put({
      type: actions.DOCUMENT_REQUEST_FAILED,
    });
  }
}

function* deleteSelectedDocument({ record, firebase, filePath }) {
  try {
    yield call(DocumentApi.deleteDocument, record, firebase, filePath);
    yield put({
      type: actions.DELETE_DOCUMENT_SUCCESSFUL,
    });
  } catch (error) {
    console.log("failed to delete teacher document", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.DOCUMENT_REQUEST_FAILED,
    });
  }
}

function* uploadAndSaveDocumentInAllCenters({ fileList, firebase, branches, mediaType, filePath }) {
  try {
    let centerClasses = JSON.parse(getItem("classmap"));
    let tempBranchMap = new Map();
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    let attachment = {
      fileList: fileList,
    };
    //for (let i in branches) {
    for (let [k, value] of tempBranchMap) {
      console.log(value);
      //let bPath = branches[i].name;
      let bPath = k;
      var uniqueNodeId = yield call(DocumentApi.createDocumentNodeByCenter, firebase, bPath);
      let mediaPaths = [];
      if (mediaType === "Photo") {
        let storagePath = bPath + "/media/images/";
        let urls = yield call(ActivityApi.getMediaPath, storagePath, attachment, firebase);
        if (urls) {
          mediaPaths = urls[0];
        }
      } else if (mediaType === "File") {
        let storagePath = bPath + "/media/file/";
        let urls = yield call(ActivityApi.getMediaPath, storagePath, attachment, firebase);
        if (urls) {
          mediaPaths = urls[0];
        }
      }
      // let url = yield call(DocumentApi.uploadDocumentByCenter, fileList, bPath, firebase);
      if (mediaPaths && mediaPaths.length > 0) {
        yield fork(
          DocumentApi.addDocumentByCenter,
          mediaPaths,
          fileList,
          uniqueNodeId,
          bPath,
          firebase,
          mediaType,
          filePath
        );

        let teachers = yield call(TeacherApi.getAllStaffByCenter, bPath, firebase);
        if (teachers) {
          for (let index in teachers) {
            if (
              teachers[index].role &&
              (teachers[index].role === "Administrator" ||
                teachers[index].role === "Principal" ||
                teachers[index].role === "Teacher")
            ) {
              let alertNode = yield call(
                NotificationApi.createSimpleAlertReferenceNode,
                teachers[index].id,
                bPath,
                firebase
              );
              yield fork(
                NotificationApi.createSimpleAlertNotification,
                "documents",
                uniqueNodeId,
                teachers[index].uid ? teachers[index].uid : null,
                "Document added by " + firebase.teacher.name,
                alertNode,
                teachers[index].ios_uid ? teachers[index].ios_uid : null,
                null,
                teachers[index].id,
                "documents",
                bPath,
                firebase
              );

              if (teachers[index].uid !== undefined || teachers[index].ios_uid !== undefined) {
                yield fork(
                  NotificationApi.sendSimplePushNotification,
                  "documents",
                  uniqueNodeId,
                  teachers[index].uid ? teachers[index].uid : null,
                  "Document added by " + firebase.teacher.name,
                  alertNode,
                  teachers[index].ios_uid ? teachers[index].ios_uid : null,
                  null,
                  teachers[index].id,
                  "documents",
                  bPath,
                  firebase
                );
              }
            }
          }
        }
      } else {
        yield put({
          type: actions.DOCUMENT_REQUEST_FAILED,
        });
      }
    }
    yield put({
      type: actions.SAVE_DOCUMENT_IN_ALL_CENTERS_SUCCESSFUL,
    });
  } catch (err) {
    console.log("failed to upload in all centers", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOCUMENT_REQUEST_FAILED,
    });
  }
}

function* addCommonDocumentFolderTeacher({ folderName, filePath, firebase, id }) {
  try {
    let nodeId = yield call(StudentApi.createNewNoteId, firebase);
    yield call(StudentApi.addDocumentFolder, nodeId, id, folderName, filePath, firebase);
    yield put({
      type: actions.ADD_COMMON_TEACHER_DOC_FOLDER_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add document folder", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOCUMENT_REQUEST_FAILED,
    });
  }
}

function* renameSelectedDoc({ docName, record, firebase, documents, currentFilePath }) {
  try {
    yield call(StudentApi.renameDoc, docName, record.id, firebase, "teacher", currentFilePath);
    let allFiles = documents;
    let oldPath = record.name + "/";
    let newPath = docName + "/";
    if (record.folder) {
      for (let index in allFiles) {
        if (allFiles[index].id !== record.id) {
          if (allFiles[index].filePath) {
            let filePath = allFiles[index].filePath;
            if (currentFilePath) {
              let splittedPath = filePath.split(currentFilePath);
              if (splittedPath[1]) {
                filePath = splittedPath[1].toString();
              }
              else {
                filePath = ""
              }
            }
            if (filePath.startsWith(oldPath)) {
              allFiles[index].filePath =
                (currentFilePath ? currentFilePath : "") + filePath.replace(oldPath, newPath);
              yield call(StudentApi.renameDoc, allFiles[index].name, allFiles[index].id, firebase, "teacher", allFiles[index].filePath);
            }
          }
        }
      }
    }

    yield put({
      type: actions.RENAME_DOC_SUCCESS,
    });
  } catch (err) {
    console.log("failed to rename document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.DOCUMENT_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_DOCUMENTS, fetchDocuments),
    yield takeLatest(actions.SAVE_DOCUMENT, uploadAndSaveDocument),
    yield takeLatest(actions.DELETE_DOCUMENT, deleteSelectedDocument),
    yield takeLatest(actions.SAVE_DOCUMENT_IN_ALL_CENTERS, uploadAndSaveDocumentInAllCenters),
    yield takeLatest(actions.ADD_COMMON_TEACHER_DOC_FOLDER, addCommonDocumentFolderTeacher),
    yield takeLatest(actions.RENAME_DOC, renameSelectedDoc),
  ]);
}
