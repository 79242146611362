import "@firebase/firestore"; // 👈 If you're using firestore
import { eventChannel } from "redux-saga";

function getActionData(actionId, firebase) {
  let emailActionData = {};
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/feeAutomationSteps/" + actionId);
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      if (snap.val() !== null) {
        emailActionData = snap.val();
      }
      resolve(emailActionData);
    });
  });
  return promise1;
}
function createAction(values, id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  const myRef = rsf.ref(branchPath + "/feeAutomationSteps/" + id);
  myRef.set(values);
}

function generateUniqueActionId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/feeAutomationSteps").push().key;
  return key;
}
function getFeeActions(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/feeAutomationSteps");
  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      let actions = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          actions.push(element.val());
        }
      });
      emit(actions);
    });
    return () => {
      myRef.off();
    };
  });
}
function deleteAutomationAction(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/feeAutomationSteps/" + id);
  myRef.set(null);
}
function changeStatus(record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/feeAutomationSteps/" + record.id);
  myRef.update({
    status: record.status.toLowerCase() == "active" ? "inactive" : "active",
  });
}
export const feeAutomationApi = {
  deleteAutomationAction,
  getActionData,
  getFeeActions,
  generateUniqueActionId,
  createAction,
  changeStatus,
};
