import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import { getItem } from "../Utility/encryptedStorage";
import { UserSettingApi } from "./userSetting";
const superagent = require("superagent");

async function addFormFields(reportName, values, firebase, endPoint) {
  let centerId = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "reportsApi";
  //let endPointUrl = "http://localhost:5001/masterproject-a832a/us-central1/reportsApi";
  var sendNotifficationEndPointUrl = "";
  reportName = reportName.toLowerCase();
  endPointUrl = endPointUrl + endPoint + "?centerId=" + centerId;
  var classes = [];
  let classList = JSON.parse(getItem("classList"));
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    for (let index in classList) {
      classes.push(classList[index].className);
    }
    var classListValue =
      values.Classroom && values.Classroom.length != 0 ? values.Classroom : classes;
    var entityList = values.Student ? values.Student : values.staffs;
    if (values.centerIds && values.centerIds.length == 0) values.centerIds = null;

    if (entityList != undefined && entityList.length > 0) classListValue = undefined;

    if (!values.nilBalance) values.nilBalance = "Yes";
    superagent
      // postman
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send({
        startDate: values.date ? values.date[0].startOf("day").valueOf() : moment().valueOf(),
        endDate: values.date ? values.date[1].endOf("day").valueOf() : moment().valueOf(),
        classList: classListValue,
        filterZeroBalance: values.nilBalance == "Yes" ? false : true,
        showAllInvoices: values.showAllInvoices == "Yes" ? true : false,

        createdBy: firebase.teacher.name,
        type: values.invoiceType,
        statusList: values.status,
        entityId: entityList, //name of students
        centerIds: values.centerIds,
        activityType: values.activityType,
      })
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("Generate form fields error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function fetchReports(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const reportRef = rsf.ref(branchPath + "/" + "reportRequests").orderByChild("createdOn");
  return eventChannel((emit) => {
    reportRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x.reverse());
    });
    return () => {
      reportRef.off();
      console.log("unsubscribe reports");
    };
  });
}

function deleteReportsGenerated(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  if (id.toLowerCase() === "clear all") {
    rsf.ref(branchPath + "/" + "reportRequests").set(null);
  } else {
    rsf.ref(branchPath + "/" + "reportRequests" + "/" + id).set(null);
  }
}

export const AllReportsApi = {
  addFormFields,
  fetchReports,
  deleteReportsGenerated,
};
