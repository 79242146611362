import Auth from "./auth/reducer";
import LeadAbout from "./leads/reducer";
import SignUp from "./auth/reducer";
import App from "./app/reducer";
import Centers from "./center/reducer";
import Classrooms from "./classroom/reducer";
import Curriculums from "./curriculum/reducer";
import Parents from "./parent/reducer";
import Students from "./student/reducer";
import Foods from "./food/reducer";
import Teachers from "./teacher/reducer";
import NewsletterComponent from "./Newsletter/reducer";
import Sent from "./SentNewsletter/reducer";
import Tags from "./tag/reducer";
import Learning from "./learning/reducer";
import Programs from "./program/reducer";
import Assessment from "./assessment/reducer";
import SelectedStudentAssessment from "./selectedStudentAssessment/reducer";
import Fees from "./fee/reducer";
import DetailedFeePlan from "./detailedFeePlan/reducer";
import DetailedProgram from "./detailedProgram/reducer";
import Onboarding from "./onboarding/reducer";
import UserSetting from "./userSetting/reducer";
import EnableComments from "./userSetting/reducer";
import CustomizeActivityForm from "./userSetting/reducer";
import ActivitySettings from "./userSetting/reducer";
import BillingSettings from "./userSetting/reducer";
import StaffLeaveManagement from "./userSetting/reducer";
import LessonSettings from "./userSetting/reducer";
import LeaveSettings from "./userSetting/reducer";
import OtherSettings from "./userSetting/reducer";
import AttendanceSettings from "./userSetting/reducer";
import TaxSettings from "./userSetting/reducer";
import LeaveTable from "./teacher/reducer";
import Invoice from "./invoice/reducer";
import Leads from "./leads/reducer";
import LeadsDetailedView from "./leads/reducer";
import StudentDetailedView from "./student/reducer";
import StudentPhotos from "./student/reducer";
import TeacherDetailedView from "./teacher/reducer";
import LandingPage from "./landingPage/reducer";
import TeacherDocument from "./teacherDocument/reducer";
import Activities from "./activities/reducer";
import InvoiceTemplate from "./invoiceTemplate/reducer";
import DetailedTemplate from "./invoiceTemplate/reducer";
import StudentAttendance from "./studentAttendance/reducer";
import NewStudentAttendance from "./studentAttendance/reducer";
import StaffAttendance from "./staffAttendance/reducer";
import NewStaffAttendance from "./staffAttendance/reducer";
import NewActivity from "./newActivity/reducer";
import Complains from "./complains/reducer";
import Leaves from "./leaves/reducer";
import StaffLeaves from "./leaves/reducer";
import ParentNotes from "./parentNotes/reducer";
import Holiday from "./holiday/reducer";
import Events from "./events/reducer";
import SchoolDetails from "./schoolDetails/reducer";
import Schedule from "./schedule/reducer";
import ZoomConfiguration from "./zoomConfig/reducer";
import StudentTimeline from "./studentTimeline/reducer";
import VirtualClass from "./virtualClass/reducer";
import Assignments from "./assignments/reducer";
import SingleSubmission from "./singleSubmission/reducer";
import LessonDashboard from "./lessonStats/reducer";
import AllSubmission from "./submissions/reducer";
import Comment from "./comment/reducer";
import LessonView from "./viewLesson/reducer";
import StudentFeePlan from "./student/reducer";
import StudentDocument from "./student/reducer";
import TeacherPersonalDocument from "./teacher/reducer";
import Installations from "./student/reducer";
import Connect from "./complains/reducer";
import MinorSettings from "./userSetting/reducer";
import StudentLessonDashboard from "./studentLesson/reducer";
import LearningExperience from "./studentLesson/reducer";
import SubmissionForm from "./studentLesson/reducer";
import StudentActivityView from "./studentLesson/reducer";
import SingleAssignmentNotification from "./studentLesson/reducer";
import LessonShareHistory from "./assignments/reducer";
import Milestone from "./learning/reducer";
import SavedAsessmentView from "./selectedStudentAssessment/reducer";
import StudentBilling from "./invoice/reducer";
import StudentInvoice from "./invoice/reducer";
import QueryList from "./query/reducer";
import StaffQueryList from "./query/reducer";
import SingleChat from "./query/reducer";
import TeacherAttendance from "./teacher/reducer";
import SingleStudentAttendance from "./student/reducer";
import ParentComment from "./comment/reducer";
import EnableActivity from "./userSetting/reducer";
import Permissions from "./permissions/reducer";
import NotificationActivityView from "./activities/reducer";
import NotificationComplaints from "./complains/reducer";
import NotificationLeave from "./leaves/reducer";
import NotificationStaffLeave from "./leaves/reducer";
import NotificationNote from "./parentNotes/reducer";
import NotificationEvent from "./events/reducer";
import NotificationDocument from "./student/reducer";
import StudentNotificationActivityView from "./studentTimeline/reducer";
import CreditHistory from "./invoice/reducer";
import DepositHistory from "./invoice/reducer";
import StudentLevelActivity from "./activities/reducer";
import IndividualStudentActivity from "./activities/reducer";
import OnlineClassManagement from "./studentTimeline/reducer";
import StudentVirtualClass from "./studentTimeline/reducer";
import AllCreditHistory from "./invoice/reducer";
import AllDepositHistory from "./invoice/reducer";
import ParentMessage from "./query/reducer";
import StudentNotes from "./student/reducer";
import Lesson from "./learning/reducer";
import StudentOnlineClassInvoice from "./invoice/reducer";
import StripePaymentMethod from "./invoice/reducer";
import EnrollmentDetail from "./invoice/reducer";
import OnlineClassCalendar from "./studentTimeline/reducer";
import BookingReport from "./activities/reducer";
import SchoolStripePaymentMethod from "./invoice/reducer";
import InvoiceAdditionalDetail from "./invoice/reducer";
import MeetingReport from "./activities/reducer";
import WeeklyPlan from "./learning/reducer";
import AllSubmissionStats from "./submissions/reducer";
import ActiveStudentMeetingReport from "./activities/reducer";
import CustomLabels from "./activities/reducer";
import WeeklyPlanSubmission from "./submissions/reducer";
import SingleNotificationLesson from "./viewLesson/reducer";
import Reports from "./reports/reducer";
import EmailButton from "./student/reducer";
import EditFeeButton from "./invoice/reducer";
import Applications from "./application/reducer";
import SharedForms from "./application/reducer";
import FormView from "./application/reducer";
import StudentForm from "./application/reducer";
import StudentProgram from "./program/reducer";
import StaffToStudentAttendanceLiveRatio from "./staffToStudentAttendanceLiveRatio/reducer";
import ManageAlert from "./staffToStudentAttendanceLiveRatio/reducer";
import AllReports from "./allReports/reducer";
import CustomActivities from "./customActivity/reducer";
import LeadForms from "./leadForms/reducer";
import CommentDrawer from "./comment/reducer";
import NewStaffLeave from "./leaves/reducer";
import NewStudentLeave from "./leaves/reducer";
import ExpenseManagement from "./ExpenseManagement/reducer";
import LeadDocument from "./leads/reducer";
import AllLeadTasks from "./leads/reducer";
import parentHandbookSetting from "./userSetting/reducer";
import CctvSetting from "./userSetting/reducer";
import LeadEmailSend from "./leads/reducer";
import LeadEmailTemplates from "./leads/reducer";
import LeadSettings from "./leadForms/reducer";
import LeadsAutomation from "./leadForms/reducer";
import RuleDetailedView from "./leadForms/reducer";
import ActionDetail from "./leadForms/reducer";
import NewFeePlan from "./fee/reducer";
import FeeAutomation from "./feeAutomation/reducer";
import LikeCommentParent from "./studentTimeline/reducer";
import EmailTemplates from "./emailTemplates/reducer";
import LeadSideAbout from "./leads/reducer";
import LeadAboutEdit from "./leads/reducer";
import LeadTask from "./leads/reducer";
import LeadActivity from "./leads/reducer";
import ManageLead from "./leads/reducer";

import AdmissionProcess from "./application/reducer";
import Process from "./application/reducer";
import StudentProgress from "./application/reducer";
import UpcomingBirthday from "./student/reducer";
import ScheduleData from "./staffScheduleTable/reducer";
import DependencyRule from "./application/reducer";
import staffInstallations from "./staffInstallations/reducer";
import Payments from "./invoice/reducer";
import TeacherApplications from "./application/reducer";
import StaffForm from "./application/reducer";
import ActivityTemplateValue from "./userSetting/reducer";
import StudentImmunization from "./studentImmunization/reducer";
import FeePlan from "./newFeePlan/reducer";
export default {
  Auth,
  SignUp,
  App,
  Centers,
  Classrooms,
  Curriculums,
  Parents,
  Students,
  StudentDetailedView,
  Foods,
  Teachers,
  TeacherDetailedView,
  LeaveTable,
  NewsletterComponent,
  Sent,
  Tags,
  Learning,
  Programs,
  Assessment,
  SelectedStudentAssessment,
  Fees,
  DetailedFeePlan,
  DetailedProgram,
  Onboarding,
  UserSetting,
  Invoice,
  Leads,
  LeadsDetailedView,
  LandingPage,
  TeacherDocument,
  Activities,
  InvoiceTemplate,
  DetailedTemplate,
  StudentAttendance,
  NewStudentAttendance,
  StaffAttendance,
  NewStaffAttendance,
  NewActivity,
  Complains,
  Leaves,
  ParentNotes,
  Holiday,
  Events,
  SchoolDetails,
  Schedule,
  ZoomConfiguration,
  StudentTimeline,
  VirtualClass,
  LessonDashboard,
  Assignments,
  SingleSubmission,
  AllSubmission,
  Comment,
  LessonView,
  StudentFeePlan,
  StudentDocument,
  TeacherPersonalDocument,
  Installations,
  Connect,
  MinorSettings,
  StudentLessonDashboard,
  LearningExperience,
  SubmissionForm,
  StudentActivityView,
  LessonShareHistory,
  Milestone,
  SavedAsessmentView,
  StudentBilling,
  StudentInvoice,
  QueryList,
  StaffQueryList,
  SingleChat,
  TeacherAttendance,
  SingleStudentAttendance,
  ParentComment,
  EnableActivity,
  Permissions,
  NotificationActivityView,
  NotificationComplaints,
  NotificationLeave,
  NotificationNote,
  NotificationEvent,
  NotificationDocument,
  StudentNotificationActivityView,
  CreditHistory,
  DepositHistory,
  StudentLevelActivity,
  IndividualStudentActivity,
  OnlineClassManagement,
  StudentVirtualClass,
  AllCreditHistory,
  AllDepositHistory,
  ParentMessage,
  StudentNotes,
  Lesson,
  StudentOnlineClassInvoice,
  StripePaymentMethod,
  EnrollmentDetail,
  OnlineClassCalendar,
  BookingReport,
  SchoolStripePaymentMethod,
  InvoiceAdditionalDetail,
  MeetingReport,
  WeeklyPlan,
  AllSubmissionStats,
  ActiveStudentMeetingReport,
  CustomLabels,
  WeeklyPlanSubmission,
  StaffLeaves,
  SingleNotificationLesson,
  SingleAssignmentNotification,
  Reports,
  EmailButton,
  EditFeeButton,
  Applications,
  SharedForms,
  FormView,
  StudentForm,
  StudentProgram,
  StaffToStudentAttendanceLiveRatio,
  ManageAlert,
  AllReports,
  LeadForms,
  EnableComments,
  CustomizeActivityForm,
  ActivitySettings,
  BillingSettings,
  parentHandbookSetting,
  LessonSettings,
  LeaveSettings,
  OtherSettings,
  AttendanceSettings,
  TaxSettings,
  CustomActivities,
  LeadForms,
  CommentDrawer,
  NewStaffLeave,
  NewStudentLeave,
  ExpenseManagement,
  EnableComments,
  CustomizeActivityForm,
  ActivitySettings,
  BillingSettings,
  StaffLeaveManagement,
  LessonSettings,
  LeaveSettings,
  OtherSettings,
  AttendanceSettings,
  TaxSettings,
  LeadAbout,
  LeadDocument,
  StudentPhotos,
  NotificationStaffLeave,
  AllLeadTasks,
  CctvSetting,
  LeadEmailSend,
  LeadEmailTemplates,
  LeadSettings,
  LeadsAutomation,
  RuleDetailedView,
  ActionDetail,
  LeadSideAbout,
  LeadAboutEdit,
  LeadTask,
  LeadActivity,
  NewFeePlan,
  ManageLead,
  LikeCommentParent,
  AdmissionProcess,
  Process,
  StudentProgress,
  FeeAutomation,
  EmailTemplates,
  UpcomingBirthday,
  ScheduleData,
  DependencyRule,
  staffInstallations,
  Payments,
  TeacherApplications,
  StaffForm,
  ActivityTemplateValue,
  StudentImmunization,
  FeePlan
};
