import { Popover, List } from "antd";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import { withFirebase } from "../../components/firebase/context";
import formatMsg from "../../components/utility/formatMessageUtil";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import deleteImage from "../../image/deleteImage.png";
import IntlMessages from "../../components/utility/intlMessages";
import editImage from "../../image/editImage.png";
import plus from "../../image/awesome-plus.png"
import { confirmAlert } from "react-confirm-alert";
import VaccineForm from "./AddVaccineForm";
import DoseForm from "./AddDoseForm";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";


function VaccineTableAction(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [popover, setPopover] = useState(false);
    const [addDoseModal, setAddDoseModal] = useState(false);
    function modalCancel() {
        setModalVisible(false);
        setAddDoseModal(false);
    }
    let vaccine = props.vaccine;
    let doseOrder = props.doseOrder;
    function popAction() {
        return (
            <List
                split={false}
                style={{ cursor: "pointer" }}
                size="small"
                dataSource={["a"]}
                renderItem={(item, index) => (
                    <div>
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.v2_Cerulean,
                                    borderBottom: ".8px solid " + colors.v2_light_grey
                                },
                            }}
                            key="addDose"
                            onClick={() => { setAddDoseModal(true), setPopover(false) }}
                        >
                            <img src={plus} alt="plus" style={styles.listItemIcon} />
                            <IntlMessages id="immunization.addDose" />
                        </List.Item>
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.v2_Cerulean,
                                    borderBottom: ".8px solid " + colors.v2_light_grey
                                },
                            }}
                            key="editVaccine"
                            onClick={() => { setModalVisible(true), setPopover(false) }}
                        >
                            <img src={editImage} alt="edit" style={styles.listItemIcon} />
                            <IntlMessages id="immunization.editVaccine" />
                        </List.Item>
                        <List.Item
                            style={{
                                ...styles.inline,
                                ...{
                                    color: colors.redTextHeader,
                                },
                            }}
                            key="deleteVaccine"
                            onClick={deleteVaccine}
                        >
                            <img src={deleteImage} alt="delete" style={styles.listItemIcon} />
                            <IntlMessages id="immunization.deleteVaccine" />
                        </List.Item>
                    </div>
                )}
            />
        )
    };
    function deleteVaccine() {
        const { firebase, deleteVaccine } = props;
        let message = formatMsg("students.confirmToSubmit");
        confirmAlert({
            closeOnClickOutside: false,
            title: (
                <p
                    style={{
                        fontSize: 20,
                        color: colors.v2_fiord,
                    }}
                >
                    {formatMsg("confirmModal.areYouSure")}
                </p>
            ),
            message: message,
            buttons: [
                {
                    label: formatMsg("yes"),
                    onClick: () => deleteVaccine(firebase, props.vaccine.vaccineId),
                },
                {
                    label: formatMsg("no"),
                },
            ],
        });
        setPopover(false);
    }
    return (
        <>
            <div style={styles.headerDiv}>
                <Popover
                    content={popAction()}
                    trigger="click"
                    visible={popover}
                    onVisibleChange={() =>
                        setPopover(!popover)
                    }
                    placement="bottomLeft"
                >
                    <PrefixIconButton
                        title={formatMsg("invoice.action")}
                        onClick={() => null}
                        backgroundColor={colors.white}
                        icon={!popover ? "caret-down" : "caret-up"}
                        fontColor={colors.v2_Cerulean}
                        border={true}
                        borderColor={colors.v2_Cerulean}
                        fontWeight={650}
                        borderRadius={6}
                        paddingLeft={15}
                        paddingRight={19}
                        fontSize={webFont.medium}
                        size={"default"}
                    />
                </Popover>
            </div>
            <VaccineForm
                modalVisible={modalVisible}
                modalCancel={modalCancel}
                vaccine={vaccine}
            />
            <DoseForm
                modalVisible={addDoseModal}
                modalCancel={modalCancel}
                doseOrder={doseOrder}
                vaccine={vaccine}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);
export default a(VaccineTableAction);
const styles = {
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    listItemIcon: {
        height: 15,
        width: 15,
        marginRight: 10,
        objectFit: "contain",
    },
    actionStyle: {
        border: "1.5px solid #D0D0D0",
        backgroundColor: "#F0F0F0",
        borderRadius: 6,
        display: "flex",
        width: 26,
        height: 26,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 8
    }
}