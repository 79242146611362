import actions from "./actions";
const initState = {
  schoolSettingDetails: [],
  userDetail: [],
  isLoading: false,
  isSettingSaveLoading: false,
  isHolidaysTableLoading: false,
  error: false,
  schoolUserChannel: undefined,
  operationRequest: "",
  schoolConfig: undefined,
  activityConfig: undefined,
  activeActivities: [],
  activeCommentActivities: [],
  customActivityList: [],
  invoiceCounter: undefined,
  receiptCounter: undefined,
  taxDetails: [],
  holidaysList: [],
  leaveSetting: {},
  relations: undefined,
  relationsUpdated: false,
  savedTemplateList: [],
  savedTemplateLoading: false,
};
export default function userSettingReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SCHOOL_SETTING_DETAILS:
      return {
        ...state,
        isLoading: true,
        schoolSettingDetails: [],
        operationRequest: "",
        cameraDetails: [],
        classroomList: [],
        configLoading: false,
      };
    case actions.GET_RELATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        relations: action.relations,
        relationsUpdated: true,
      };
    case actions.RESET_RELATION_UPDATED:
      return {
        ...state,
        relationsUpdated: false,
      };
    case actions.ADD_RELATIONS_SUCCESS:
      return {
        ...state,
        // relationsUpdated: true,
      };
    case actions.GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        schoolSettingDetails: action.schoolSettingDetails,
        schoolUserChannel: action.schoolUserChannel,
        operationRequest: action.operationRequest,
      };
    case actions.UPLOAD_SCHOOL_LOGO:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPLOAD_SCHOOL_LOGO_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPLOAD_LOGO",
      };
    case actions.UPDATE_SCHOOL_ADDRESS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SCHOOL_ADDRESS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };
    case actions.UPDATE_USER_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_USER_INFO_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };

    case actions.GET_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_USER_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        userDetail: action.userDetail,
      };

    case actions.GET_TAX_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_TAX_DETAILS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        taxDetails: action.taxDetails,
      };

    case actions.UPDATE_TEACHER_INFO:
      return {
        ...state,
        // isLoading: true,
      };
    case actions.UPDATE_TEACHER_INFO_SUCCESSFUL:
      return {
        ...state,
        // isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };

    case actions.GET_CAMERA_DETAILS:
      return {
        ...state,
      };
    case actions.GET_CAMERA_DETAILS_SUCCESSFUL:
      return {
        ...state,
        cameraDetails: action.cameraDetails,
      };

    case actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN:
      return {
        ...state,
      };
    case actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL:
      return {
        ...state,
        classroomList: action.classroomList,
      };

    case actions.SCHOOL_SETTING_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        isSettingSaveLoading: false,
        isHolidaysTableLoading: false,
      };

    case actions.UPDATE_CAMERA_TO_CLASS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_CAMERA_TO_CLASS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };

    case actions.GET_SCHOOL_CONFIG:
      return {
        ...state,
        isLoading: true,
        configLoading: true,
      };
    case actions.GET_SCHOOL_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        configLoading: false,
        schoolConfig: action.schoolConfig,
      };

    case actions.GET_INVOICE_COUNTER:
      return {
        ...state,
        isLoading: true,
        configLoading: true,
      };
    case actions.GET_INVOICE_COUNTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        configLoading: false,
        invoiceCounter: action.invoiceCounter,
      };

    case actions.GET_RECEIPT_COUNTER:
      return {
        ...state,
        isLoading: true,
        configLoading: true,
      };
    case actions.GET_RECEIPT_COUNTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        configLoading: false,
        receiptCounter: action.receiptCounter,
      };

    case actions.GET_ACTIVITY_CONFIG:
      return {
        ...state,
        isLoading: true,
        configLoading: true,
      };
    case actions.GET_ACTIVITY_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        configLoading: false,
        activityConfig: action.activityConfig,
      };

    case actions.SAVE_ACTIVITY_SETTING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_ACTIVITY_SETTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "SAVE_ACTIVITY",
      };
    case actions.GET_INVOICE_RECEIPT_COUNTER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_INVOICE_RECEIPT_COUNTER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SAVE_CONFIG_SETTING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_CONFIG_SETTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationRequest: "SETTINGS_UPDATED",
      };

    case actions.RESET_OPERATION_REQ:
      return {
        ...state,
        isLoading: false,
        isSettingSaveLoading: false,
        operationRequest: "",
        error: false,
        isHolidaysTableLoading: false,
      };

    case actions.SUBMIT_HANDBOOK:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUBMIT_HANDBOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };
    case actions.SUBMIT_TERMS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUBMIT_TERMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: "UPDATE_DETAIL",
      };

    case actions.GET_ACTIVE_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activeActivities: [],
        customActivityList: [],
      };
    case actions.GET_ACTIVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeActivities: action.activeActivities,
        customActivityList: action.customActivityList,
      };

    case actions.GET_ACTIVE_COMMENT_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        activeCommentActivities: [],
        customActivityList: [],
      };
    case actions.GET_ACTIVE_COMMENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeCommentActivities: action.activeCommentActivities,
        customActivityList: action.customActivityList,
      };

    case actions.SAVE_TAX:
      return {
        ...state,
      };
    case actions.SAVE_TAX_SUCCESSFUL:
      return {
        ...state,
        operationRequest: action.operationRequest,
      };

    case actions.DELETE_TAX:
      return {
        ...state,
      };
    case actions.DELETE_TAX_SUCCESS:
      return {
        ...state,
        operationRequest: action.operationRequest,
      };

    case actions.SAVE_LEAVE_SETTING:
      return {
        ...state,
        isSettingSaveLoading: true,
        operationRequest: "",
      };
    case actions.SAVE_LEAVE_SETTING_SUCCESS:
      return {
        ...state,
        isSettingSaveLoading: false,

        operationRequest: "LEAVE_SETTING_SAVE",
      };

    case actions.SAVE_HOLIDAY:
      return {
        ...state,
        isHolidaysTableLoading: true,
        operationRequest: "",
      };
    case actions.SAVE_HOLIDAY_SUCCESS:
      return {
        ...state,
        isHolidaysTableLoading: false,
        operationRequest: "HOLIDAY_SAVE",
      };

    case actions.GET_STAFF_HOLIDAYS_LIST:
      return {
        ...state,
        isHolidaysTableLoading: true,
        holidaysList: [],
        operationRequest: "",
      };
    case actions.GET_STAFF_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        isHolidaysTableLoading: false,
        holidaysList: action.holidaysList,
        operationRequest: "STAFF_HOLIDAYS_FETCH",
      };

    case actions.GET_LEAVES_SETTING:
      return {
        ...state,
        isLoading: true,
        leaveSetting: {},
        operationRequest: "",
      };
    case actions.GET_LEAVES_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leaveSetting: action.leaveSetting,
        operationRequest: "LEAVES_FETCH",
      };

    case actions.DELETE_STAFF_HOLIDAY:
      return {
        ...state,
        isLoading: true,
        operationRequest: "",
      };
    case actions.DELETE_STAFF_HOLIDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: "DELETE_STAFF_HOLIDAY",
      };

    // case actions.SAVE_ACTIVE_ACTVITIES:
    //     return {
    //         ...state,
    //         isLoading: true,
    //     };
    // case actions.SAVE_ACTIVE_ACTVITIES_SUCCESS:
    //     return {
    //         ...state,
    //         isLoading: false,
    //     };

    case actions.GET_ACTIVITY_TEMPLATE:
      return {
        ...state,
        savedTemplateLoading: true,
        savedTemplateList: []
      };

    case actions.GET_ACTIVITY_TEMPLATE_SUCCESS:
      return {
        ...state,
        savedTemplateLoading: false,
        savedTemplateList: action.savedTemplateList
      };

    case actions.RESET_ACTIVITY_TEMPLATE:
      return {
        ...state,
        savedTemplateLoading: false,
      };

    case actions.SAVE_TEMPLATE_VALUE:
      return {
        ...state,
        savedTemplateLoading: true,
      };

    case actions.SAVE_TEMPLATE_VALUE_SUCCESS:
      return {
        ...state,
        savedTemplateLoading: false,
      };
    case actions.ADD_CCTV_CAMERA_INFO:
      return {
        ...state,
        isLoading: true
      }
    case actions.ADD_CCTV_CAMERA_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: "ADD_DETAIL"
      }

    case actions.DELETE_CAMERA_SETTING:
      return {
        ...state,
        isLoading: true
      }
    case actions.DELETE_CAMERA_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: "DELETE_DETAIL",

      }

    default:
      return state;
  }
}
