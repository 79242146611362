import actions from "./actions";
const initState = {
  lessonDetailLoading: false,
  editableLessonRecord: undefined,
  viewLessonStudents: [],
};
export default function viewLessonReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.VIEW_LESSON:
      return {
        ...state,
        lessonDetailLoading: true,
      };

    case actions.VIEW_LESSON_SUCCESSFUL:
      return {
        ...state,
        lessonDetailLoading: false,
        editableLessonRecord: action.editableLessonRecord,
      };

    case actions.VIEW_REQUEST_FAILED:
      return {
        ...state,
        lessonDetailLoading: false,
      };

    case actions.GET_VIEW_LESSON_STUDENT:
      return {
        ...state,
      };

    case actions.GET_VIEW_LESSON_STUDENT_SUCCESS:
      return {
        ...state,
        viewLessonStudents: action.viewLessonStudents,
      };

    default:
      return state;
  }
}
