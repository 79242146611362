import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { eventChannel } from "redux-saga";
import { call } from "redux-saga/effects";
import notification from "../components/notification";
import formatMsg from "../components/utility/formatMessageUtil";
import { getItem } from "../Utility/encryptedStorage";
import FilterAction from "../Utility/FilterAction";
import { UserSettingApi } from "./userSetting";

const superagent = require("superagent");
function createStudentNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/students").push().key;
  return key;
}

function pushLeadDocument(studentId, firebase, leadObj) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/documents/" + studentId + "/" + leadObj.id);
  myRef.update(leadObj);
}

function getAllStudents(fetchLimit, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // let start = new Date().getTime();
  return eventChannel((emitter) => {
    rsf
      .ref(branchPath + "/students")
      // .limitToFirst(fetchLimit)
      .on("value", (snap) => {
        const studentData = [];
        snap.forEach((element) => {
          if (element.val() !== null && element.val().deleted === false) {
            studentData.push(element.val());
          }
        });
        emitter(studentData);
      });
    return () => {
      rsf.ref(branchPath + "/students").off();
      console.log("END");
    };
  });
}
function getStudents(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  // let start = new Date().getTime();
  return eventChannel((emitter) => {
    rsf
      .ref(branchPath + "/students")
      // .limitToFirst(fetchLimit)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          let students = [];
          snapshot.forEach((snap) => {
            if (snap.val() !== null && !snap.val().deleted && snap.val().id) {
              if (firebase.teacher && firebase.teacher.classList) {
                if (firebase.teacher.superUser) {
                  students.push(snap.val());
                } else {
                  let classList = firebase.teacher.classList;
                  for (let i in classList) {
                    if (snap.val().classList && snap.val().classList.includes(classList[i])) {
                      students.push(snap.val());
                      break;
                    }
                  }
                }
              } else {
                students.push(snap.val());
              }
            }
          });
          students = students.sort((a, b) => a.name.localeCompare(b.name));

          emitter(students);
        }
      });
    return () => {
      rsf.ref(branchPath + "/students").off();
      console.log("END");
    };
  });
}

function* getAllParents(students, firebase) {
  var allStudent = students;
  var fatherDataPromise = [];
  var motherDataPromise = [];

  // for (var i = 0; i < allStudent.length; i++) {
  if (allStudent.fatherProfileId !== undefined) {
    var fatherDetail = yield call(
      getFatherDetail,
      allStudent.fatherProfileId,
      allStudent,
      firebase
    );
    fatherDataPromise.push(fatherDetail);
  }
  if (allStudent.motherProfileId !== undefined) {
    var motherDetail = yield call(
      getMotherDetail,
      allStudent.motherProfileId,
      allStudent,
      firebase
    );
    motherDataPromise.push(motherDetail);
  }
  // }
  Promise.all(fatherDataPromise).then(function (values) {
    console.log("father promise", values);
  });

  Promise.all(motherDataPromise).then(function (values) {
    console.log("mother promise", values);
  });

  return allStudent;
}

function getFatherDetail(fatherId, student, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/parents/" + fatherId)
    .once("value")
    .then(function (snapshot) {
      if (snapshot.val() !== null) {
        student.fatherName = snapshot.val().name;
        student.address = snapshot.val().address;
      }
    });
}

function getMotherDetail(motherId, student, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return rsf
    .ref(branchPath + "/parents/" + motherId)
    .once("value")
    .then(function (snap) {
      if (snap.val() !== null) {
        student.motherName = snap.val().name;
      }
    });
}

function getEmailInvite(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {};
  const myRef = rsf.ref(branchPath + "/emails/invitationEmail");
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        obj = snapshot.val();
      }
      resolve(obj);
    });
  });
  return promise;
}

function getAutoCompleteClassrooms(firebase) {
  let classroomData = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  const myRef = rsf.ref(branchPath + "/classrooms");
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element.val() !== null) {
          classroomData.push(element.val());
        }
      });
      resolve(classroomData);
    });
  });
  return promise1;
}

function getAllStudentTags(firebase) {
  var x = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var tagPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/tags")
      .once("value")
      .then(function (snap) {
        snap.forEach((item) => {
          if (item.val() !== null) {
            x.push(item.val());
          }
        });
        resolve(x);
      });
  });
  return tagPromise;
}

function addStudentData(values, tags, studentNodeId, fatherNodeId, motherNodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentNodeId).set({
    address: values.address !== undefined ? values.address : null,
    birthDate: moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY"),
    //classId: values.classroom[1],
    classroomName: values.classroom,
    dateCreated: new Date().getTime(),
    deactivated: false,
    deleted: false,
    fatherEmail: values.fatherEmail !== undefined ? values.fatherEmail : null,
    fatherName: values.father ? values.father : null,
    fatherNumber: values.fatherContact !== undefined ? Number(values.fatherContact) : null,
    fatherProfileId: fatherNodeId !== undefined ? fatherNodeId : null,
    gender: values.gender,
    id: studentNodeId,
    motherEmail: values.motherEmail !== undefined ? values.motherEmail : null,
    motherName: values.mother ? values.mother : null,
    motherNumber: values.motherContact !== undefined ? Number(values.motherContact) : null,
    motherProfileId: motherNodeId !== undefined ? motherNodeId : null,
    name: values.name,
    chatPermission: false,
    tags: tags !== undefined && tags.length > 0 ? tags : null,
    admissionNumber: values.admissionNumber ? values.admissionNumber : null,
    fatherCompanyName: values.fatherCompanyName ? values.fatherCompanyName : null,
    motherCompanyName: values.motherCompanyName ? values.motherCompanyName : null,
    platform: "web",
    status: "Active",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function updateStudentData(values, record, tags, firebase, config, updatedBy) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + record.id).update({
    childConfig: record.childConfig && record.childConfig.length > 0 ? record.childConfig : null,
    address: values.address !== undefined ? values.address : null,
    birthDate: values.birthDate
      ? moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY")
      : null,
    chatPermission: false,
    classroomName: values.classroom && values.classroom.length > 0 ? values.classroom[0] : null,
    classList: values.classroom,
    gender: values.gender,
    id: record.id,
    name: values.name,
    admissionNumber: values.admissionNumber ? values.admissionNumber : null,
    platform: "web",
    status: record.status ? record.status : "Active",
    admissionDate: values.admissionDate ? moment(values.admissionDate).valueOf() : null,

    updatedBy: firebase && firebase.teacher ? firebase.teacher.name : null,
    updatedOn: moment().valueOf(),
    preferredName: values.prefName ? values.prefName : null,
    tags: tags && tags.length > 0 ? tags : null,
  });
}

function updateFatherUser(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.fatherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.fatherProfileId,
    name: values.father,
    notification: false,
    phoneNumber: Number(values.fatherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateFatherUserWithoutNumberUpdate(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.fatherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.fatherProfileId,
    name: values.father,
    notification: false,
    // phoneNumber: Number(values.fatherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateMotherUser(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.motherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.motherProfileId,
    name: values.mother,
    notification: false,
    phoneNumber: Number(values.motherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function updateMotherUserWithoutNumberUpdate(values, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/users/" + values.motherContact).set({
    chatPermission: false,
    dateCreated: 0,
    deactivated: false,
    deleted: false,
    id: record.motherProfileId,
    name: values.mother,
    notification: false,
    // phoneNumber: Number(values.motherContact),
    studentId: record.id,
    userType: "STUDENT",
    platform: "web",
  });
}

function deleteStudent(studentRecord, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentRecord.id).update({
    deleted: true,
    platform: "web",
    deletedOn: new Date().getTime(),
    updatedOn: new Date().getTime(),
    updatedBy: firebase.teacher.name,
  });
}

function* detailedStudentList(record) { }

function addStudentFromCsv(values, classroom, studentNodeId, fatherNodeId, motherNodeId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formattedDOB = moment(values.birthDate, [
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      //birthDate: moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY"),
      birthDate: moment(new Date(formattedDOB)).format("DD[, ]MMMM[, ]YYYY"),
      bloodGroup: values["bloodGroup(Optional)"] ? values["bloodGroup(Optional)"] : null,
      chatPermission: false,
      //classId: classroom[0].id,
      classroomName: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherName: values.fatherName,
      fatherEmail: values.fatherEmail ? values.fatherEmail : null,
      fatherNumber: values.fatherNumber ? Number(values.fatherNumber) : null,
      fatherProfileId: fatherNodeId ? fatherNodeId : null,
      gender: values.gender.charAt(0).toUpperCase() + values.gender.slice(1),
      id: studentNodeId,
      motherName: values.motherName,
      motherEmail: values.motherEmail ? values.motherEmail : null,
      motherNumber: values.motherNumber ? Number(values.motherNumber) : null,
      motherProfileId: motherNodeId ? motherNodeId : null,
      name: values.studentName,
      platform: "web_onboarding",
      updatedBy: firebase.teacher.name,
      updatedOn: new Date().getTime(),
      status: "Active",
    },
    function (error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function getClassroomByName(className, firebase) {
  // let newClassName = className.trim();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var classroom = [];
  var classroomPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/classrooms/")
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== undefined) {
            classroom.push(item.val());
          }
        });
        resolve(classroom);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
  return classroomPromise;
}

function addSecondChildOfExistingParent(
  values,
  tags,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  existingStudentName,
  existingStudentId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      birthDate: moment(new Date(values.birthDate)).format("DD[, ]MMMM[, ]YYYY"),
      //classId: values.classroom[1],
      classroomName: values.classroom[0],
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherEmail: values.fatherEmail !== undefined ? values.fatherEmail : null,
      fatherName: values.father,
      fatherNumber: values.fatherContact !== undefined ? Number(values.fatherContact) : null,
      fatherProfileId: fatherNodeId !== undefined ? fatherNodeId : null,
      gender: values.gender,
      id: studentNodeId,
      motherEmail: values.motherEmail !== undefined ? values.motherEmail : null,
      motherName: values.mother,
      motherNumber: values.motherContact !== undefined ? Number(values.motherContact) : null,
      motherProfileId: motherNodeId !== undefined ? motherNodeId : null,
      name: values.name,
      chatPermission: false,
      tags: tags !== undefined && tags.length > 0 ? tags : null,
      siblingId: existingStudentId,
      siblingName: existingStudentName,
      platform: "web",
      status: "Active",
    },
    function (error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function addSecondChildOfExistingParentUsingCsv(
  values,
  classroom,
  studentNodeId,
  fatherNodeId,
  motherNodeId,
  existingStudentName,
  existingStudentId,
  firebase
) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let formattedDOB = moment(values.birthDate, [
    "DD/MM/YY",
    "DD-MM-YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  rsf.ref(branchPath + "/students/" + studentNodeId).set(
    {
      address: values.address !== undefined ? values.address : null,
      birthDate: moment(new Date(formattedDOB)).format("DD[, ]MMMM[, ]YYYY"),
      bloodGroup: values["bloodGroup(Optional)"] ? values["bloodGroup(Optional)"] : null,
      chatPermission: false,
      //classId: classroom[0].id,
      classroomName: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deleted: false,
      fatherName: values.fatherName,
      fatherEmail: values.fatherEmail ? values.fatherEmail : null,
      fatherNumber: values.fatherNumber ? Number(values.fatherNumber) : null,
      fatherProfileId: fatherNodeId ? fatherNodeId : null,
      gender: values.gender,
      id: studentNodeId,
      motherName: values.motherName,
      motherEmail: values.motherEmail ? values.motherEmail : null,
      motherNumber: values.motherNumber ? Number(values.motherNumber) : null,
      motherProfileId: motherNodeId ? motherNodeId : null,
      name: values.studentName,
      siblingId: existingStudentId,
      siblingName: existingStudentName,
      platform: "web",
      status: "Active",
    },
    function (error) {
      if (error) {
        console.log("failed to update student");
      } else {
        console.log("student updated successfully");
      }
    }
  );
}

function addSibling(studentData, siblingId, siblingName, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentData.id).update(
    {
      siblingId: siblingId,
      siblingName: siblingName,
      platform: "web",
    },
    function (error) {
      if (error) {
        console.log("failed to add student");
      } else {
        console.log("student added successfully");
      }
    }
  );
}

function generateUniqueCalendarReferenceId(className, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/calendarReference/" + className).push().key;
  return key;
}

function addCsvHolidayToClass(className, holidayNodeId, values, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  //let startDate = new Date(values.startDate);
  let startDate = moment(values.startDate, [
    "DD/MM/YY",
    "DD-MM-YY",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "DD, MMMM, YYYY",
    "DD.MM.yyyy",
    "YYYY-MM-DD",
    "DD[-]MM[-]YYYY",
  ]).toDate();
  let endDate = null;
  let fromDate = {
    date: startDate.getDate(),
    day: startDate.getDay(),
    hours: startDate.getHours(),
    minutes: startDate.getMinutes(),
    month: startDate.getMonth(),
    seconds: startDate.getSeconds(),
    time: startDate.getTime(),
    timezoneOffset: startDate.getTimezoneOffset(),
    year: startDate.getFullYear() - 1900,
  };
  if (values["endDate(Optional)"]) {
    endDate = moment(values["endDate(Optional)"], "DD[/]MM[/]YYYY").toDate();
    endDate = {
      date: endDate.getDate(),
      day: endDate.getDay(),
      hours: endDate.getHours(),
      minutes: endDate.getMinutes(),
      month: endDate.getMonth(),
      seconds: endDate.getSeconds(),
      time: endDate.getTime(),
      timezoneOffset: endDate.getTimezoneOffset(),
      year: endDate.getFullYear() - 1900,
    };
  } else {
    endDate = {
      date: startDate.getDate(),
      day: startDate.getDay(),
      hours: startDate.getHours(),
      minutes: startDate.getMinutes(),
      month: startDate.getMonth(),
      seconds: startDate.getSeconds(),
      time: startDate.getTime(),
      timezoneOffset: startDate.getTimezoneOffset(),
      year: startDate.getFullYear() - 1900,
    };
  }
  rsf.ref(branchPath + "/calendarReference/" + className + "/" + holidayNodeId).update({
    classroomName: className,
    fromDate: fromDate,
    endDate: endDate ? endDate : null,
    id: holidayNodeId,
    name: values.holidayName,
    timestamp: startDate.getTime(),
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  });
}

function getStudentDetail(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  return eventChannel((emitter) => {
    rsf.ref(branchPath + "/students/" + studentId).on("value", (snap) => {
      const studentData = [];
      if (snap.val() !== null) {
        studentData.push(snap.val());
      }
      emitter(studentData);
    });
    return () => {
      rsf.ref(branchPath + "/students/" + studentId).off();
      console.log("END");
    };
  });
}

function getUserVersions(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var users = [];
  var userPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/userVersion")
      .once("value")
      .then(function (snapshot) {
        // snapshot.forEach(item => {
        if (snapshot.val() !== null) {
          users.push(snapshot.val());
        }
        // });
        resolve(users);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
  return userPromise;
}

async function sendInviteToParents(parentList, firebase, forcedUpdate, changeEmail, relation) {
  let userList = [];
  for (let i = 0; i < parentList.length; i++) {
    if (changeEmail && relation) {
      let changedRelation;
      let parentId;
      if (relation.toLowerCase() === "father") {
        changedRelation = "PARENT2";
        parentId = parentList[i].fatherProfileId;
      } else if (relation.toLowerCase() === "mother") {
        changedRelation = "PARENT1";
        parentId = parentList[i].motherProfileId;
      }
      userList.push({
        studentId: parentList[i].id,
        email: changeEmail,
        id: parentId,
        type: changedRelation,
      });
    } else {
      if (parentList[i].fatherEmail) {
        userList.push({
          studentId: parentList[i].id,
          email: parentList[i].fatherEmail,
          id: parentList[i].fatherProfileId,
          type: "PARENT2",
        });
      }

      if (parentList[i].motherEmail) {
        userList.push({
          studentId: parentList[i].id,
          email: parentList[i].motherEmail,
          id: parentList[i].motherProfileId,
          type: "PARENT1",
        });
      }
    }
  }

  let config = firebase.schoolConfig;
  let schoolName = config.schoolName;
  let accountName = firebase.sbDbName;
  let teacherEmail = firebase.schoolConfig.adminEmail;
  let inviteEndPointUrl =
    firebase.endPointUrl + "woodlandApi/invite/parent/?centerId=" + accountName;

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    // parentEmails: emails,
    list: userList,
    ccEmail: teacherEmail,
    schoolName: schoolName,
    accountName: accountName,
    timezone: localTimezone,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(inviteEndPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")

      .end((err, res) => {
        console.log("send invite response --------", res);
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("send invite error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function generateUserNode(firebase) {
  const rsf = firebase.db;
  var key = rsf.ref("newUser").push().key;
  return key;
}

function addToNewUser(userId, userContact, childId, userType, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + userContact).set(
    {
      id: userId,
      phoneNumber: Number(userContact),
      schoolName: firebase.sbDbName,
      studentId: childId,
      userType: userType,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", formatMsg("error.sessionExpiredRelogin"));
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function updateSecondChildToNewUser(user, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + user.phoneNumber).update(
    {
      child2Branch: firebase.schoolConfig.branchPath,
      child2Id: user.child2Id,
      child2inactive: user.child2inactive,
      child1inactive: user.child1inactive,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", formatMsg("error.sessionExpiredRelogin"));
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function updateUserForTeacherChild(contact, student, firebase) {
  const rsf = firebase.db;
  rsf.ref("/newUser/" + contact).update(
    {
      studentId: student.id,
      platform: "web",
    },
    (error) => {
      if (error) {
        notification("error", formatMsg("error.sessionExpiredRelogin"));
        firebase.signOut();
      } else {
        // Data saved successfully!
      }
    }
  );
}

function getStudentById(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var student = {};
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students/" + studentId)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          student = snapshot.val();
        }
        resolve(student);
      });
  });
  return studentPromise;
}

function getSelectedStudentAttendance(studentId, month, firebase) {
  let searchableMonth = moment(month).format("MMMM[ ]YYYY");
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentAttendance = [];
  var studentAttendancePromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/" + "student-attendance/" + studentId + "/" + searchableMonth)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null && item.val().id) {
            studentAttendance.push(item.val());
          }
        });
        resolve(studentAttendance);
      });
  });
  return studentAttendancePromise;
}

function getSelectedStudentLeave(firstDay, lastDay, studentId, firebase) {
  var firstDayTime = new Date(firstDay).getTime();
  var lastDayTime = new Date(lastDay).getTime();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentLeave = [];
  var studentLeavePromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/leaves/")
      .orderByChild("startDate")
      .startAt(firstDayTime)
      .endAt(lastDayTime)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (
            item.val() !== null &&
            item.val().studentId === studentId &&
            item.val().status.toLowerCase() === "approved"
          ) {
            studentLeave.push(item.val());
          }
        });
        resolve(studentLeave);
      });
  });
  return studentLeavePromise;
}

function updateAdditionalInfo(additionalObj, studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId).update(additionalObj);
}

function updateAdditionalFields(userType, updatedObject, studentId, firebase) {
  let config;
  if (userType === "Father") {
    config = "fatherConfig";
  } else if (userType === "Mother") {
    config = "motherConfig";
  } else if (userType === "Child") {
    config = "childConfig";
  }
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentId + "/" + config).update(updatedObject);
}

function getAllFormFields(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var formField = [];
  var formPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/fieldRef")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null) {
            formField.push(item.val());
          }
        });
        resolve(formField);
      });
  });
  return formPromise;
}

function updateStudentWithUpdatedFormFields(studentData, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/students/" + studentData.id).update(studentData);
}

function addNewStudent(values, id, firebase, admissionNumber) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf
    .ref(branchPath + "/students/" + id)
    .update({
      classroomName: values.classroom[0],
      classList: values.classroom,
      dateCreated: new Date().getTime(),
      deactivated: false,
      deactivationDate: 0,
      deleted: false,
      deletedOn: 0,
      // fatherNumber: 0,
      gender: values.gender,
      id: id,
      // motherNumber: 0,
      name: values.studentName,
      napEndTime: 0,
      napStartTime: 0,
      present: false,
      section: false,
      platform: "web",
      status: values.status ? values.status : "Active",
      updatedBy: firebase.teacher.name,
      updatedOn: new Date().getTime(),
      admissionNumber: admissionNumber ? admissionNumber : null,
      preferredName: values.prefName ? values.prefName : null,
    })
    .then((res) => {
      firebase.refreshStudents();
    });
}

function updateNewUser(contact, userObj, firebase) {
  const rsf = firebase.db;
  //change here
  rsf.ref("newUser/" + contact).set(userObj, (error) => {
    if (error) {
      notification("error", formatMsg("error.sessionExpiredRelogin"));
      firebase.signOut();
    } else {
      // Data saved successfully!
    }
  });
}

function getAllStudentByCenter(firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath;
  var student = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            snap.val().deleted === false &&
            snap.val().deactivated === false
          ) {
            if (snap.val().status && snap.val().status.toLowerCase() === "active") {
              student.push(snap.val());
            } else if (!snap.val().status) {
              student.push(snap.val());
            }
          }
        });
        resolve(student);
      });
  });
  return studentPromise;
}

async function updateStudentClassChange(studentsList, classroom, firebase) {
  let studentChangeUrl = firebase.endPointUrl + "updateClassroom";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    branchPath: firebase.sbp,
    accountName: firebase.sbDbName,
    newClassroom: classroom,
    studentList: studentsList,
    timezone: localTimezone,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(studentChangeUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        console.log("error", err);
        console.log("res", res);
        if (res) {
          resolve(res);
        } else if (err) {
          reject(err);
        }
      });
  });
  return p1;
}

function getUserDocument(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var docs = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/documents/" + studentId)
      .orderByChild("inverseDate")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            if (snap.val().type.toLowerCase() === "folder") {
              docs.push(snap.val());
            } else if (snap.val().downloadUrl) {
              docs.push(snap.val());
            }
          }
        });
        resolve(docs);
      });
  });
  return studentPromise;
}

function getStudentDocNode(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/documents").push().key;
  return key;
}

function uploadDocument(id, obj, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + id + "/" + obj.id).set(obj);
}

function deleteDocument(record, id, firebase, filePath) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + id + "/" + record.id).set(null);
  if (record.type && record.type.toLowerCase() === "folder" && filePath) {
    rsf.ref(branchPath + "/documents/" + id).once("value").then(snapshot => {
      snapshot.forEach(snap => {
        if (snap.val() !== null && !snap.val().deleted && snap.val().filePath && snap.val().filePath.includes(filePath)) {
          rsf.ref(branchPath + "/documents/" + id + "/" + snap.val().id).set(null);
        }
      });
    });
  }
}

function getStudentByIdFromMainBranch(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.schoolConfig.branchPath;
  var student = {};
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students/" + studentId)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() !== null && snapshot.val().deleted === false) {
          student = snapshot.val();
        }
        resolve(student);
      });
  });
  return studentPromise;
}

async function sendLoginInviteToParents(emails, firebase) {
  let schoolName = getItem("schoolName");
  let teacherEmail = firebase.schoolConfig.adminEmail;
  let inviteEndPointUrl = firebase.endPointUrl + "sendParentInvite";

  let localTimezone = firebase.schoolConfig.timezone
    ? firebase.schoolConfig.timezone
    : moment.tz.guess();

  let obj = {
    parentEmails: emails,
    ccEmail: teacherEmail,
    schoolName: schoolName,
    timezone: localTimezone,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(inviteEndPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        console.log("send invite response --------", res);
        resolve(res);
      });
  });
  return p1;
}

function getStudentByClassroomName(classroom, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  var students = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() !== null &&
            snap.val().classList &&
            snap.val().classList.includes(classroom) &&
            !snap.val().deactivated
          ) {
            students.push(snap.val());
          }
        });
        resolve(students);
      });
  });
  return studentPromise;
}

function createNewNoteId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/studentNotes").push().key;
  return key;
}




function getStudentNotes(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/studentNotes/" + studentId);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var x = [];
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      emit(x);
    });
    return () => {
      myRef.off();
      console.log("unsubscribe student notes");
    };
  });
}


function deleteStudentNotes(studentId, firebase, record) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/studentNotes/" + studentId + "/" + record.id).set(null);
}

function getAdmissionCounter(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var counter = rsf
    .ref(branchPath + "/admissionNumberCounter")
    .once("value")
    .then(function (snap) {
      return snap.val();
    });
  return counter;
}

function incrementAdmissionCounter(prevCounter, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/admissionNumberCounter").set(prevCounter + 1);
}

function getStudentHolidays(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let holidays = [];

  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/calendarReference/All Classrooms")
      .once("value")
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          if (snap.val() !== null) {
            holidays.push(snap.val());
          }
        });
        resolve(holidays);
      });
  });
  return promise;
}

function getStripeCustomer(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {};

  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/stripeCustomer/" + studentId)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          obj = snapshot.val();
        }
        resolve(obj);
      });
  });
  return promise;
}

async function verifyParentApi(
  firebase,
  studentName,
  studentId,
  parentNumber,
  parentName,
  parentEmail,
  parentType
) {
  let endPointUrl = firebase.endPointUrl + "userApi/verifyParent";

  let accountName = firebase.sbDbName;
  let obj = {
    accountName: accountName,
    studentName: studentName,
    studentId: studentId,
    parentName: parentName,
    parentNumber: parentNumber,
    parentEmail: parentEmail ? parentEmail : null,
    parentType: parentType,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("verify parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function addParentApi(
  firebase,
  studentName,
  studentId,
  parentName,
  parentNumber,
  parentEmail,
  parentType,
  studentRecord,
  countryCode,
  config,
  companyName,
  relationship
) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/parents/?centerId=" + accountName;
  let obj = {
    name: parentName,
    countryCode: countryCode,
    phoneNumber: parentNumber,
    email: parentEmail ? parentEmail : null,
    relation: parentType,
    studentIds: [studentId],
    studentId: studentId,
    additionalFields: config ? config : null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    companyName: companyName,
    relationship,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function addSiblingApi(firebase, studentObj, siblingId, siblingName, dbName, parentIds) {
  let accountName = dbName ? dbName : firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/siblings/?centerId=" + accountName;
  let obj = {
    student: studentObj,
    siblingId: siblingId,
    siblingBranchPath: firebase.sbp,
    parentIds: parentIds,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add sibling error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function updateParentApi(
  firebase,
  studentName,
  studentId,
  parentName,
  parentNumber,
  parentEmail,
  parentCompanyName,
  parentConfig,
  countryCode,
  parentType,
  oldPhoneNumber,
  oldEmail,
  studentRecord,
  parentId,
  relationship
) {
  // let endPointUrl = firebase.endPointUrl + "userApi/updateParent";
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/" + parentId + "?centerId=" + accountName;

  let obj = {
    id: parentId,
    name: parentName,
    relation: parentType,
    phoneNumber: parentNumber,
    email: parentEmail,
    additionalFields: parentConfig,
    countryCode: countryCode,
    companyName: parentCompanyName,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    relationship,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("update parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function deleteStudentApi(firebase, studentObj) {
  let endPointUrl = firebase.endPointUrl + "userApi/deleteStudent";

  let accountName = firebase.sbDbName;
  let obj = {
    accountName: accountName,
    branchPath: firebase.sbp,
    student: studentObj,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .set({
        Authorization: "Bearer " + authToken,
      })
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function addDocumentFolder(nodeId, id, folderName, filePath, firebase, privateFolderRecord) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {
    createdBy: firebase.teacher.name,
    createdOn: new Date().getTime(),
    filePath: filePath ? filePath : "",
    downloadUrl: null,
    id: nodeId,
    inverseDate: -new Date().getTime(),
    folder: true,
    name: folderName,
    nameWithExtension: folderName,
    type: "folder",
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    privateFolderRecord: privateFolderRecord ? privateFolderRecord : null
  };
  rsf.ref(branchPath + "/documents/" + id + "/" + obj.id).set(obj);
}

function renameDoc(docName, id, firebase, userId, filePath) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/documents/" + userId + "/" + id).update({
    name: docName,
    platform: "web",
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    filePath: filePath
  });

  if (userId === "teacher") {
    rsf.ref(branchPath + "/documents/admin/" + id).update({
      name: docName,
      platform: "web",
      updatedBy: firebase.teacher.name,
      updatedOn: moment().valueOf(),
    });
  }
}

function getStudentByGroup(groupName, firebase, bPath) {
  const rsf = firebase.secondaryDb;
  let branchPath = bPath ? bPath : firebase.sbp;
  let students = [];
  let promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/students")
      .orderByChild("deleted")
      .equalTo(false)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val() !== null && !snap.val().deactivated && snap.val().tags) {
            let studentTags = snap.val().tags;
            let status = false;
            for (let index in studentTags) {
              if (studentTags[index].name === groupName) {
                status = true;
                break;
              }
            }

            if (status) {
              students.push(snap.val());
            }
          }
        });
        resolve(students);
      });
  });
  return promise;
}

async function addAuthorizedPickupApi(firebase, data, studentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/parents/?centerId=" + accountName;
  let obj = {
    name: data.name,
    phoneNumber: data.phoneNumner ? data.phoneNumner : null,
    email: data.email ? data.email : null,
    relation: "pickup",
    studentIds: [studentId],
    relationship: data.realtion ? data.realtion : null,
    url: data.url ? data.url : null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    countryCode: data.countryCode,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function deleteAuthorizedPickupApi(firebase, pickupId, studentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/" + pickupId + "?centerId=" + accountName;
  let obj = {
    studentIds: [studentId],
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function updateAuthorizedPickupApi(firebase, pickupId, data) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/" + pickupId + "?centerId=" + accountName;

  let obj = {
    id: pickupId,
    name: data.name,
    phoneNumber: data.phoneNumner ? data.phoneNumner : null,
    email: data.email ? data.email : null,
    relation: "pickup",
    relationship: data.realtion ? data.realtion : null,
    url: data.url ? data.url : null,
    countryCode: data.countryCode,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add auth pickup error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function addStudentApi(firebase, values) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/students/?centerId=" + accountName;

  let obj = {
    classroomName: values.classroom[0],
    classList: values.classroom,
    gender: values.gender,
    name: values.studentName,
    emergencyContactName: values.emergencyContactName,
    emergencyNumber: values.emergencyNumber,

    status: values.status ? values.status : "Active",
    updatedBy: firebase.teacher.name,
    updatedOn: new Date().getTime(),
    preferredName: values.prefName ? values.prefName : null,
    birthDate: values.birthDate ? values.birthDate : null,
    address: values.address ? values.address : null,
    additionalFields: values.additionalFields ? values.additionalFields : null,
    leadRecordId: values.leadId ? values.leadId : null,
  };

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function addFullStudentApi(firebase, values) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/students/?centerId=" + accountName;

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(values)
      .set("accept", "json")

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function deleteStudentNewApi(firebase, id) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/students/" + id + "?centerId=" + accountName;
  let obj = {
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .set("accept", "json")
      .send(FilterAction.getSuperagentBody(obj, firebase))

      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function updateParentPicApi(firebase, parentId, data) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/" + parentId + "?centerId=" + accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(data, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("update parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function updateStudentApi(firebase, id, data) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/students/" + id + "?centerId=" + accountName;

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(data, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("add student error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function deleteParentApi(firebase, parentId) {
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "woodlandApi/parents/" + parentId + "?centerId=" + accountName;

  let obj = {
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };

  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .delete(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .set("accept", "json")
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("delete parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function unlinkParentApi(firebase, parentId, studentId, relation) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/unlink/?centerId=" + accountName;
  let obj = {
    id: studentId,
    studentId: studentId,
    parentId: parentId,
    relation: relation ? relation : null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("unlink parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

async function linkParentApi(firebase, parentId, studentId, relation, relationship, url) {
  let accountName = firebase.sbDbName;
  let endPointUrl = firebase.endPointUrl + "woodlandApi/link/?centerId=" + accountName;
  let obj = {
    id: studentId,
    studentId: studentId,
    parentId: parentId,
    relation: relation ? relation : null,
    relationship: relationship ? relationship : null,
    updatedBy: firebase.teacher.name,
    updatedOn: moment().valueOf(),
    url: url ? url : null,
  };
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .post(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(FilterAction.getSuperagentBody(obj, firebase))
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res);
        } else {
          if (err) {
            console.log("link parent error", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function getSelectedStudentNewAttendance(studentId, month, firebase) {
  let searchableMonth = moment(month).format("MMM[-]YYYY");
  let serachableId = searchableMonth + "-" + studentId;
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var studentAttendance = [];
  var studentAttendancePromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/" + "studentRoomRecords")
      .orderByChild("monthIdKey")
      .equalTo(serachableId)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((item) => {
          if (item.val() !== null && item.val().id) {
            studentAttendance.push(item.val());
          }
        });
        resolve(studentAttendance);
      });
  });
  return studentAttendancePromise;
}

function getStudentNotesCount(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  let myRef = rsf.ref(branchPath + "/studentNotes/" + studentId);

  var x = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

function getStudentMedia(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/photos/" + studentId);
  var x = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

function getStudentFavtMedia(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/favtPhotos/" + studentId);
  var x = [];
  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snap) => {
      snap.forEach((element) => {
        if (element.val() !== null) {
          x.push(element.val());
        }
      });
      resolve(x);
    });
  });
  return promise;
}

// function getStudentMedia(studentId, firebase) {
//   const rsf = firebase.secondaryDb;
//   let branchPath = firebase.sbp;
//   // let start = new Date().getTime();
//   return eventChannel((emitter) => {
//     rsf
//       .ref(branchPath + "/photos/" + studentId)
//       // .limitToFirst(fetchLimit)
//       .on("value", (snap) => {
//         const mediaData = [];
//         snap.forEach((element) => {
//           if (element.val() !== null) {
//             mediaData.push(element.val());
//           }
//         });
//         emitter(mediaData);
//       });
//     return () => {
//       rsf.ref(branchPath + "/photos/" + studentId).off();
//       console.log("END");
//     };
//   });
// }

function getAssessmentFolder(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var docs = [];
  var studentPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/documents/" + studentId)
      .orderByChild("name")
      .equalTo("assessment")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            docs.push(snap.val());
          }
        });

        resolve(docs);
      });
  });
  return studentPromise;
}
async function getAbsentMoreThanThreeDays(firebase) {
  let obj = {};
  let accountName = firebase.sbDbName;
  let endPointUrl =
    firebase.endPointUrl + "/woodlandApi/attendance/stats" + "/?centerId=" + accountName;
  let authToken = await UserSettingApi.getAuthToken(firebase);
  var p1 = new Promise(function (resolve, reject) {
    superagent
      .get(endPointUrl)
      .set({
        Authorization: "Bearer " + authToken,
      })
      .send(obj)
      .set("accept", "json")
      .end((err, res) => {
        if (res) {
          resolve(res.body);
        } else {
          if (err) {
            console.log("get student absent error=>", err);
            reject(err);
          }
        }
      });
  });
  return p1;
}

function FetchStudentLogs(studentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var log = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/studentUpdateLog")
      .orderByChild("userId")
      .equalTo(studentId)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            log.push(snap.val());
          }
        });
        resolve(log);
      });
  });
  return promise;
}

function FetchParentLogs(parentId, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var log = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/parentUpdateLog")
      // .ref(branchPath + node)
      .orderByChild("userId")
      .equalTo(parentId)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            log.push(snap.val());
          }
        });
        resolve(log);

        console.log(log, "logg");
      });
  });
  return promise;
}

function getInvitationLog(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var log = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/emailInviteLog")
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (
            snap.val() &&
            snap.val().userType &&
            snap.val().userType.toLowerCase() !== "teacher"
          ) {
            log.push(snap.val());
          }
        });
        resolve(log);
      });
  });
  return promise;
}

function getDocLog(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var log = [];
  var promise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/fileLogs")
      .orderByChild("studentId")
      .equalTo(id)
      .once("value")
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (snap.val()) {
            log.push(snap.val());
          }
        });
        resolve(log);

        console.log(log, "logg");
      });
  });
  return promise;
}

function getStudentNotesById(studentId, record, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let myRef = rsf.ref(branchPath + "/studentNotes/" + studentId + "/" + record.id);

  let data = {};

  let promise = new Promise(function (resolve, reject) {
    myRef.once("value").then((snap) => {
      if (snap.val()) {
        data = snap.val();
      }
      resolve(data);
    });
  });
  return promise;
}

function deleteGalleryMediaApi(studentId, firebase, media) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  media.forEach((media) => {
    rsf.ref(branchPath + "/photos/" + studentId + "/" + media.id).set(null);
  });

}

export const StudentApi = {
  getAllStudents,
  getAbsentMoreThanThreeDays,
  getAllParents,
  addStudentData,
  detailedStudentList,
  getAllStudentTags,
  getAutoCompleteClassrooms,
  createStudentNodeId,
  pushLeadDocument,
  updateStudentData,
  updateFatherUser,
  updateFatherUserWithoutNumberUpdate,
  updateMotherUser,
  updateMotherUserWithoutNumberUpdate,
  deleteStudent,
  addStudentFromCsv,
  getClassroomByName,
  addSecondChildOfExistingParent,
  addSecondChildOfExistingParentUsingCsv,
  addSibling,
  addCsvHolidayToClass,
  generateUniqueCalendarReferenceId,
  getStudentDetail,
  getUserVersions,
  sendInviteToParents,
  generateUserNode,
  addToNewUser,
  updateSecondChildToNewUser,
  getStudentById,
  getSelectedStudentAttendance,
  getSelectedStudentLeave,
  updateAdditionalInfo,
  updateAdditionalFields,
  getAllFormFields,
  updateStudentWithUpdatedFormFields,
  addNewStudent,
  updateUserForTeacherChild,
  updateNewUser,
  getAllStudentByCenter,
  updateStudentClassChange,
  getUserDocument,
  getStudentDocNode,
  uploadDocument,
  deleteDocument,
  getStudentByIdFromMainBranch,
  sendLoginInviteToParents,
  getStudentByClassroomName,
  createNewNoteId,

  getStudentNotes,

  deleteStudentNotes,
  getAdmissionCounter,
  incrementAdmissionCounter,
  getStudentHolidays,
  getStripeCustomer,
  verifyParentApi,
  addParentApi,
  addSiblingApi,
  updateParentApi,
  deleteStudentApi,
  addDocumentFolder,
  renameDoc,
  getStudentByGroup,
  addAuthorizedPickupApi,
  deleteAuthorizedPickupApi,
  updateAuthorizedPickupApi,
  addStudentApi,
  deleteStudentNewApi,
  updateStudentApi,
  updateParentPicApi,
  deleteParentApi,
  unlinkParentApi,
  linkParentApi,
  getSelectedStudentNewAttendance,
  addFullStudentApi,
  getEmailInvite,
  getStudentNotesCount,
  getStudentMedia,
  getStudentFavtMedia,
  getAssessmentFolder,
  FetchStudentLogs,
  FetchParentLogs,
  getInvitationLog,
  getDocLog,
  getStudents,
  getStudentNotesById,
  deleteGalleryMediaApi,
};
