const actions = {
  LIST_PROGRAMS: "LIST_PROGRAMS",
  LIST_PROGRAMS_SUCCESSFUL: "LIST_PROGRAMS_SUCCESSFUL",
  GET_PROGRAM_CLASSROOMS: "GET_PROGRAM_CLASSROOMS",
  GET_PROGRAM_CLASSROOMS_SUCCESSFFUL: "GET_PROGRAM_CLASSROOMS_SUCCESSFFUL",
  ADD_PROGRAM: "ADD_PROGRAM",
  ADD_PROGRAM_SUCCESSFUL: "ADD_PROGRAM_SUCCESSFUL",
  UPDATE_PROGRAM: "UPDATE_PROGRAM",
  UPDATE_PROGRAM_SUCCESSFUL: "UPDATE_PROGRAM_SUCCESSFUL",
  PROGRAM_REQUEST_FAILED: "PROGRAM_REQUEST_FAILED",
  PROGRAM_FETCH_STUDENT: "PROGRAM_FETCH_STUDENT",
  PROGRAM_FETCH_STUDENT_SUCCESSFFUL: "PROGRAM_FETCH_STUDENT_SUCCESSFFUL",
  PROGRAM_FETCH_FEE_PLAN: "PROGRAM_FETCH_FEE_PLAN",
  PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL: "PROGRAM_FETCH_FEE_PLAN_SUCCESSFUL",
  ASSIGN_STUDENT_TO_PROGRAM: "ASSIGN_STUDENT_TO_PROGRAM",
  ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL: "ASSIGN_STUDENT_TO_PROGRAM_SUCCESSFUL",
  ADD_FEE_PLAN_TO_PROGRAM: "ADD_FEE_PLAN_TO_PROGRAM",
  ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL: "ADD_FEE_PLAN_TO_PROGRAM_SUCCESSFUL",
  DOWNLOAD_ALL_PROGRAM_EXCEL: "DOWNLOAD_ALL_PROGRAM_EXCEL",
  DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS: "DOWNLOAD_ALL_PROGRAM_EXCEL_SUCCESS",
  DOWNLOAD_ALL_PROGRAM_TABLE_DATA: "DOWNLOAD_ALL_PROGRAM_TABLE_DATA",
  DOWNLOAD_ALL_PROGRAM_TABLE_DATA_SUCCESS: "DOWNLOAD_ALL_PROGRAM_TABLE_DATA_SUCCESS",
  RESET_PROGRAM_OPERATION: "RESET_PROGRAM_OPERATION",
  DELETE_SELECTED_PROGRAM: "DELETE_SELECTED_PROGRAM",
  DELETE_SELECTED_PROGRAM_SUCCESS: "DELETE_SELECTED_PROGRAM_SUCCESS",
  GET_STUDENT_ATTENDANCE_DATA: "GET_STUDENT_ATTENDANCE_DATA",
  GET_STUDENT_ATTENDANCE_DATA_SUCCESS: "GET_STUDENT_ATTENDANCE_DATA_SUCCESS",
  GET_STD_LEVEL_PROGRAM: "GET_STD_LEVEL_PROGRAM",
  GET_STD_LEVEL_PROGRAM_SUCCESS: "GET_STD_LEVEL_PROGRAM_SUCCESS",
  GET_PROGRAM_LABEL: "GET_PROGRAM_LABEL",
  GET_PROGRAM_LABEL_SUCCESSFUL: "GET_PROGRAM_LABEL_SUCCESSFUL",
  RELOAD_FTE: "RELOAD_FTE",
  RELOAD_FTE_SUCCESS: "RELOAD_FTE_SUCCESS",
  RESET_OCCUPANCY_OPERATION: "RESET_OCCUPANCY_OPERATION",
  SAVE_PROGRAM: "SAVE_PROGRAM",
  SAVE_PROGRAM_SUCCESS: "SAVE_PROGRAM_SUCCESS",

  initProgramData: (firebase) => ({
    type: actions.LIST_PROGRAMS,
    firebase,
  }),

  reloadFte: (firebase) => ({
    type: actions.RELOAD_FTE,
    firebase,
  }),

  getClassroomDataForProgram: (firebase) => ({
    type: actions.GET_PROGRAM_CLASSROOMS,
    firebase,
  }),

  addProgram: (values, classroom, firebase) => ({
    type: actions.ADD_PROGRAM,
    values,
    classroom,
    firebase,
  }),

  updateProgram: (values, classroom, record, firebase) => ({
    type: actions.UPDATE_PROGRAM,
    values,
    classroom,
    record,
    firebase,
  }),

  getStudentsToAssignProgram: (firebase) => ({
    type: actions.PROGRAM_FETCH_STUDENT,
    firebase,
  }),

  getFeePlanToAssignProgram: (firebase) => ({
    type: actions.PROGRAM_FETCH_FEE_PLAN,
    firebase,
  }),

  assignStudentToProgram: (values, selectedStudentCheckbox, selectedProgram, firebase) => ({
    type: actions.ASSIGN_STUDENT_TO_PROGRAM,
    values,
    selectedStudentCheckbox,
    selectedProgram,
    firebase,
  }),

  addFeePlanToProgram: (values, selectedFeePlan, selectedProgram, firebase) => ({
    type: actions.ADD_FEE_PLAN_TO_PROGRAM,
    values,
    selectedFeePlan,
    selectedProgram,
    firebase,
  }),

  downloadProgramExcel: (programs, firebase) => ({
    type: actions.DOWNLOAD_ALL_PROGRAM_EXCEL,
    programs,
    firebase,
  }),
  downloadProgramTableData: (programs, firebase) => ({
    type: actions.DOWNLOAD_ALL_PROGRAM_TABLE_DATA,
    programs,
    firebase,
  }),
  resetProgramOperation: () => ({
    type: actions.RESET_PROGRAM_OPERATION,
  }),

  resetOccupancyOperation: () => ({
    type: actions.RESET_OCCUPANCY_OPERATION,
  }),

  deletedProgram: (record, firebase) => ({
    type: actions.DELETE_SELECTED_PROGRAM,
    record,
    firebase,
  }),

  getStudentLevelProgram: (firebase) => ({
    type: actions.GET_STD_LEVEL_PROGRAM,
    firebase,
  }),

  getProgramLabel: (firebase) => ({
    type: actions.GET_PROGRAM_LABEL,
    firebase,
  }),
  saveProgram: (firebase, program) => ({
    type: actions.SAVE_PROGRAM,
    firebase,
    program,
  }),
  saveProgramSuccess: () => ({
    type: actions.SAVE_PROGRAM_SUCCESS,
  }),
  getStudentAttendanceData: (firebase, selectedDate, initCall) => ({
    type: actions.GET_STUDENT_ATTENDANCE_DATA,
    firebase,
    selectedDate,
    initCall,
  }),
};

export default actions;
