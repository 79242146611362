import bugsnagClient from "@bugsnag/js";
import * as FileSaver from "file-saver";
import moment from "moment";
import { all, call, put, take, takeLatest } from "redux-saga/effects";
import * as XLSX from "xlsx";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import StaffSchedule from "../../containers/StaffScheduleCalendar/StaffSchedule";
import { StaffScheduleEmpty } from "../../containers/StaffScheduleCalendar/StaffScheduleEmpty";
import { TeacherDetailView } from "../../containers/StaffScheduleCalendar/TeacherDetailView";
import { ComplainsApi } from "../../firestore-api/consult";
import { staffScheduleApi } from "../../firestore-api/StaffScheduleApi";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import FilterAction from "../../Utility/FilterAction";
import { callApi } from "../../Utility/superAgentUntil";
import actions from "./actions";

function* downloadScheduleTableData({ filteredSchedules, calenderDate }) {
  try {
    let startDate = moment(calenderDate.startDate).valueOf();
    let endDate = moment(calenderDate.endDate).valueOf();
    let header = ["Teacher"];
    while (startDate <= endDate) {
      header.push(moment(startDate).format("Do") + " " + moment(startDate).format("MMM") + " " + moment(startDate).format("ddd"));
      startDate = moment(startDate).add(1, "days");
    }
    let excelSheet = [];
    for (let i = 0; i < filteredSchedules.length; i++) {
      if (!filteredSchedules[i].hasOwnProperty("key"))
        break
      let teacherSchdules = filteredSchedules[i];
      let index = 0;
      while (true) {
        let getSchedule = false;
        let row = {};
        let rowIndex = 0;
        row[header[rowIndex]] = teacherSchdules["col0"].props.teacherName
        rowIndex++;
        for (let j = 1; j <= 7; j++) {
          let columnIndex = "col" + j
          if (teacherSchdules[columnIndex][index] && teacherSchdules[columnIndex][index].props.shiftObject.shiftName) {
            getSchedule = true;
            let schedule = teacherSchdules[columnIndex][index];
            let startTime = schedule.props.shiftObject.startTime
            let endTime = schedule.props.shiftObject.endTime
            let activityName = schedule.props.shiftObject.shiftName
            let shiftData = moment(startTime).format("hh:mm A") + " - " + moment(endTime).format("hh:mm A") + " " + activityName
            row[header[rowIndex]] = shiftData
          }
          else {
            row[header[rowIndex]] = ""
          }
          rowIndex++;
        }
        index++;
        if (!getSchedule)
          break
        excelSheet.push(row)
      }
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "ScheduleTableData";
    let ws = XLSX.utils.json_to_sheet(excelSheet, {
      header: header,
      dateNF: "DD-MMM-YYYY",
    });
    const webSheet = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(webSheet, { bookType: "xlsx", type: "array" });
    const newData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(newData, fileName + fileExtension);
  } catch (err) {
    console.log("failed to download excel sheet", err);
    bugsnagClient.notify(err);
  }
}

function getTeacher(currentStaffId) {
  let teachers = FilterAction.getTeacherList();
  let result = teachers.filter((teacher) => teacher.id == currentStaffId);
  if (result != 0) {
    return result[0];
  }
  return undefined;
}

function isStaffOnLeave(leaves, staffId, dateVal) {
  let onLeave = false;
  for (let e of leaves) {
    if (
      e.studentId === staffId &&
      dateVal >= moment(e.startDate).startOf("day").valueOf() &&
      dateVal <= moment(e.endDate).endOf("day").valueOf()
    ) {
      onLeave = true;
      break;
    }
  }
  return onLeave;
}

function shiftCalculator(startTime, teacherWithShifts, assignedTo) {
  const time = moment(moment(startTime).format("HH:mm"), "HH:mm");
  const morningShiftStart = moment("00:00", "HH:mm");
  const morningShiftEnd = moment("11:58", "HH:mm");
  const afternoonShiftStart = moment("11:59", "HH:mm");
  const afternoonShiftEnd = moment("14:58", "HH:mm");
  const eveningShiftStart = moment("14:59", "HH:mm");
  if (time.isBetween(morningShiftStart, morningShiftEnd)) {
    teacherWithShifts[assignedTo].morningShift = 1;
  } else if (time.isBetween(afternoonShiftStart, afternoonShiftEnd)) {
    teacherWithShifts[assignedTo].afternoonShift = 1;
  } else if (time.isSameOrAfter(eveningShiftStart)) {
    teacherWithShifts[assignedTo].eveningShift = 1;
  }
}

function findScheduleForTeacher(
  teacherScheduleArray,
  schedule,
  dateWithIndexMap,
  teacherWithTotalTime,
  teacherWithShifts,
  currentDate,
  leaves
) {
  if (teacherScheduleArray.hasOwnProperty(schedule.assignedTo) == false) {
    teacherScheduleArray[schedule.assignedTo] = new Map();
    teacherWithTotalTime[schedule.assignedTo] = 0;
    teacherWithShifts[schedule.assignedTo] = {
      morningShift: 0,
      afternoonShift: 0,
      eveningShift: 0,
    };
  }

  let colName = dateWithIndexMap.get(currentDate);
  const currDate = moment(currentDate).startOf("day").valueOf();
  colName = colName.slice(3);
  let minute = moment(schedule.endTime).diff(moment(schedule.startTime), "minute");
  teacherWithTotalTime[schedule.assignedTo] += minute;
  shiftCalculator(schedule.startTime, teacherWithShifts, schedule.assignedTo);

  if (
    teacherScheduleArray.hasOwnProperty(schedule.assignedTo) == true &&
    !teacherScheduleArray[schedule.assignedTo].has(colName)
  ) {
    teacherScheduleArray[schedule.assignedTo].set(colName, []);
  }
  const onLeave = isStaffOnLeave(leaves, schedule.assignedTo, currDate);
  teacherScheduleArray[schedule.assignedTo].get(colName).push({ ...schedule, onLeave: onLeave });
}

function processDataForUI(
  response,
  startDate,
  endDate,
  deleteStaffSchedule,
  firebase,
  leaves,
  staffId
) {
  let loop = new Date(startDate);
  let end = new Date(endDate);
  let columnIndex = 1;
  const dateWithIndexMap = new Map();
  const teacherSet = new Set();
  while (loop <= end) {
    let currentIndex = "col" + columnIndex;
    columnIndex++;
    dateWithIndexMap.set(loop.toDateString(), currentIndex);
    let newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(newDate);
  }
  const teacherScheduleArray = {};
  const teacherWithTotalTime = {};
  const teacherWithShifts = {};
  response &&
    response.forEach((schedule, index) => {
      let currentDate = moment(schedule.startDate).startOf("day")._d.toDateString();
      findScheduleForTeacher(
        teacherScheduleArray,
        schedule,
        dateWithIndexMap,
        teacherWithTotalTime,
        teacherWithShifts,
        currentDate,
        leaves
      );
    });
  let scheduleList = [];
  let index = 0;
  let maxSizeList = {};
  for (const key in teacherScheduleArray) {
    let teacheId = key;
    let result = teacherScheduleArray[key];
    let totalTime = teacherWithTotalTime[key] / 60;
    let totalShifts =
      teacherWithShifts[key].morningShift +
      teacherWithShifts[key].afternoonShift +
      teacherWithShifts[key].eveningShift;
    totalTime = Number(totalTime).toFixed(0);
    let currentTeacher = getTeacher(key);
    if (!currentTeacher)
      continue
    teacherSet.add(currentTeacher.id);
    let tableData = {
      key: index,
      col0: (
        <TeacherDetailView
          teacherName={currentTeacher.name}
          totalTime={totalTime + " " + formatMsg("hours")}
          totalShifts={totalShifts + " " + formatMsg("shifts")}
          teacherId={key}
          profileImageUrl={currentTeacher.profileImageUrl}
          gender={currentTeacher.gender}
        />
      ),
    };
    let maxSize = 0;
    for (let [key, value] of result) {
      let columnIndex = "col" + key;
      let staffDataArray = [];
      staffDataArray = value.map((staffData) => (
        <StaffSchedule
          shiftObject={staffData}
          deleteStaffSchedule={deleteStaffSchedule}
          firebase={firebase}
        />
      ));
      tableData[columnIndex] = staffDataArray;
      maxSize = Math.max(maxSize, staffDataArray.length);
    }
    maxSizeList[teacheId] = maxSize;

    for (let weekIndex = 1; weekIndex <= 7; weekIndex++) {
      const columnIndex = "col" + weekIndex;
      const emptyShiftObj = {
        teacherId: key,
        currentDate: moment(startDate)
          .startOf("day")
          .add(weekIndex - 1, "days")
          .add(2, "minutes"),
      };
      const newHeight = maxSize * 130 + 30 + maxSize * 15;
      tableData[columnIndex] = tableData[columnIndex] || [];
      tableData[columnIndex].push(
        <StaffScheduleEmpty
          shiftObject={emptyShiftObj}
          newHeight={tableData[columnIndex].length === 0 ? newHeight : 30}
          onLeave={isStaffOnLeave(leaves, key, emptyShiftObj.currentDate.valueOf())}
        />
      );
    }

    index++;
    scheduleList.push(tableData);
  }
  scheduleList.sort((a, b) => {
    let key = "col0";
    if (!a || !a[key] || !a[key].props.teacherName) {
      return 1;
    }

    if (!b || !b[key] || !b[key].props.teacherName) {
      return -1;
    }
    return a[key].props.teacherName.toString().localeCompare(b[key].props.teacherName.toString());
  })
  if (!staffId || (staffId && staffId == "All staff")) {
    scheduleList = setEmptyScheduleForTeacher(
      scheduleList,
      teacherSet,
      firebase,
      startDate,
      leaves
    );
  }
  return scheduleList;
}

function filterByStaffAndClassList(scheduleList, currentStaffId, classList) {
  return scheduleList.filter((item) => {
    if (currentStaffId != "All staff" && classList != "All classrooms") {
      return (
        item.assignedTo == currentStaffId &&
        (item.classList ? (item.classList.includes(classList) ? true : false) : true)
      );
    }
    if (currentStaffId != "All staff") {
      return item.assignedTo == currentStaffId;
    }
    if (classList != "All classrooms") {
      return item.classList ? (item.classList.includes(classList) ? true : false) : true;
    } else {
      return true;
    }
  });
}

function* getAllStaffSchedules({
  firebase,
  currentStaffId,
  startDate,
  endDate,
  deleteStaffSchedule,
  classList,
}) {
  try {
    const channel = yield call(staffScheduleApi.getStaffSchedule, firebase, startDate, endDate);
    while (true) {
      let response = yield take(channel);
      yield put({
        type: actions.SAVE_ALL_SCHEDULES,
        allSchedules: response,
        scheduleChannel: channel,
      });
      let leaves = [];

      leaves = yield call(
        ComplainsApi.getAllPendingStaffLeaves,
        firebase,
        startDate.valueOf(),
        endDate.valueOf()
      );
      let filteredData = filterByStaffAndClassList(response, currentStaffId, classList);
      filteredData = filteredData.sort((a, b) => {
        const startTimeA = moment(a?.startTime);
        const startTimeB = moment(b?.startTime);

        const timeA = moment(startTimeA).format("HH:mm:ss");
        const timeB = moment(startTimeB).format("HH:mm:ss");

        if (moment(timeA, "HH:mm:ss").isAfter(moment(timeB, "HH:mm:ss"))) {
          return 1;
        } else if (moment(timeA, "HH:mm:ss").isBefore(moment(timeB, "HH:mm:ss"))) {
          return -1;
        } else {
          return 0;
        }
      });
      // modify this data for UI
      let modifiedSchedules = processDataForUI(
        filteredData,
        startDate,
        endDate,
        deleteStaffSchedule,
        firebase,
        leaves,
        currentStaffId
      );
      if (modifiedSchedules.length == 0 && currentStaffId != "All staff") {
        modifiedSchedules = getListForSelectedTeacher(firebase, currentStaffId, startDate, leaves);
      }
      yield put({
        type: actions.GET_ALL_STAFF_SCHEDULE,
        payload: modifiedSchedules,
        scheduleChannel: channel,
      });
    }
  } finally {
    console.log("terminating staff schedule");
  }
}

function* getSelectedScheduleDetails({ scheduleId, firebase }) {
  const channel = yield call(staffScheduleApi.getSelectedStaffSchedule, scheduleId, firebase)
  try {
    while (true) {
      let selectedSchedule = yield take(channel);
      yield put({
        type: actions.GET_SELECTED_SCHEDULE_SUCCESSFUL,
        selectedSchedule: selectedSchedule,
        selectedScheduleChannel: channel
      })
    }
  } finally {
    console.log("successful");
  }
}

function setEmptyScheduleForTeacher(scheduleList, teacherSet, firebase, startDate, leaves) {
  let teacherList = firebase.teachersMap;
  teacherList = Object.values(teacherList);
  for (let teacher of teacherList) {
    if (teacher.deactivated) {
      continue;
    }
    if (teacherSet.has(teacher.id)) continue;
    teacherSet.add(teacher.id);
    let teacherRow = {};

    teacherRow["col0"] = (
      <TeacherDetailView
        teacherName={teacher.name}
        totalTime={0 + " " + formatMsg("hours")}
        totalShifts={0 + " " + formatMsg("shifts")}
        teacherId={teacher.id}
        profileImageUrl={teacher.profileImageUrl}
        gender={teacher.gender}
      />
    );
    for (let weekIndex = 1; weekIndex <= 7; weekIndex++) {
      let emptyShiftObj = {
        teacherId: teacher.id,
        currentDate: moment(startDate)
          .add(weekIndex - 1, "days")
          .add(2, "minutes"),
      };
      teacherRow["col" + weekIndex] = [
        <StaffScheduleEmpty
          shiftObject={emptyShiftObj}
          newHeight={75}
          onLeave={isStaffOnLeave(leaves, emptyShiftObj.teacherId, emptyShiftObj.currentDate.valueOf())}
        />,
      ];
    }
    scheduleList.push(teacherRow);
  }
  return scheduleList;
}

function* deleteStaffSchedule({ id, startDate, endDate, firebase }) {
  try {
    let url = "woodlandApi/staffSchedule/" + "?centerId=";
    let response = yield call(
      StudentAttendanceApi.deleteApiWithObj, //general API call
      { id, startDate, endDate },
      url,
      firebase
    );
    if (response && response.status && response.status === 200) {
      notification("success", formatMsg("success.deleteStaffSchedule"));
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteStaffSchedule")
      );
    }
    yield put({
      type: actions.DELETE_STAFF_SCHEDULE_SUCCESS,
    });
  } catch (err) {
    notification("error", formatMsg("error.deleteStaffSchedule"));
    bugsnagClient.notify(err);
  }
}

function* createStaffSchedule({ scheduleObj, firebase }) {
  try {
    let endpointUrl = "woodlandApi/saveSchedule";
    if (scheduleObj.operationType === "copy") {
      endpointUrl = "woodlandApi/cloneSchedule";
    }
    else if (scheduleObj.editStartDate && scheduleObj.editEndDate && scheduleObj.parentId) {
      endpointUrl = "woodlandApi/updateMultipleSchedule";
    }
    endpointUrl += "?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "post", endpointUrl, scheduleObj);

    if (response && response.status && response.status === 200) {
      if (scheduleObj.id) {
        if (scheduleObj.operationType) {
          notification("success", formatMsg("success.copyStaffSchedule"));
        } else {
          notification("success", formatMsg("success.editStaffSchedule"));
        }
      } else notification("success", formatMsg("success.createStaffSchedule"));
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.createStaffSchedule")
      );
    }

    yield put({
      type: actions.CREATE_STAFF_SCHEDULE_SUCCESS,
    });
  } catch (err) {
    console.log("failed " + err);
    notification("error", formatMsg("error.createStaffSchedule"));
    bugsnagClient.notify(err);
  }
}
function getListForSelectedTeacher(firebase, staffId, startDate, leaves) {
  let teacherMap = firebase.teachersMap;
  let teacher;
  let result = [];
  if (teacherMap.hasOwnProperty(staffId)) {
    teacher = teacherMap[staffId];
  }
  else {
    return result;
  }
  let teacherRow = {};
  teacherRow["col0"] = (
    <TeacherDetailView
      teacherName={teacher.name}
      totalTime={0 + " " + formatMsg("hours")}
      totalShifts={0 + " " + formatMsg("shifts")}
      teacherId={teacher.id}
      profileImageUrl={teacher.profileImageUrl}
      gender={teacher.gender}
    />
  );
  for (let weekIndex = 1; weekIndex <= 7; weekIndex++) {
    let emptyShiftObj = {
      teacherId: teacher.id,
      currentDate: moment(startDate)
        .add(weekIndex - 1, "days")
        .add(2, "minutes"),
    };
    teacherRow["col" + weekIndex] = [
      <StaffScheduleEmpty
        shiftObject={emptyShiftObj}
        newHeight={75}
        onLeave={isStaffOnLeave(leaves, emptyShiftObj.teacherId, emptyShiftObj.currentDate.valueOf())}
      />,
    ];
  }
  result.push(teacherRow);
  return result;

}

function* filterScheduleByStaffAndClasslist({
  firebase,
  staffId,
  classList,
  schedules,
  deleteStaffSchedule,
  startDate,
  endDate,
}) {
  let leaves = [];
  try {
    leaves = yield call(
      ComplainsApi.getAllPendingStaffLeaves,
      firebase,
      startDate.valueOf(),
      endDate.valueOf()
    );

    let filteredData = filterByStaffAndClassList(schedules, staffId, classList);
    let processedDataForUi = processDataForUI(
      filteredData,
      startDate,
      endDate,
      deleteStaffSchedule,
      firebase,
      leaves,
      staffId
    );
    if (processedDataForUi.length == 0 && staffId != "All staff") {
      processedDataForUi = getListForSelectedTeacher(firebase, staffId, startDate, leaves);
    }
    yield put({
      type: actions.FILTER_BY_STAFF_AND_BY_CLASSLIST_SUCCESS,
      payload: processedDataForUi,
    });
  } finally {
  }
}

function* getAffectedSchedules({ firebase, startDate, endDate, id, idType }) {
  try {
    const channel = yield call(staffScheduleApi.getAffectedSchedules, firebase, startDate, endDate, id, idType);
    while (true) {
      let response = yield take(channel);
      response = response.sort((a, b) => { return FilterAction.sortIntegerValue(a, b, "startDate") })
      yield put({
        type: actions.GET_AFFECTED_SCHEDULES_SUCCESS,
        affectedSchedules: response,
        affectedScheduleChannel: channel
      });

    }
  } finally {
    console.log("terminating affected schedule channel");
  }
}
function* getAllClasses({ firebase }) {
  try {
    let activeClassList = yield call(staffScheduleApi.getAllClasses, firebase);
    yield put({
      type: actions.GET_ALL_CLASSES_SUCCESS,
      activeClassList: activeClassList
    });
  }
  catch (err) {
    console.log("failed to get classlist ", err);
    bugsnagClient.notify(err);
  }
}


function* scheduleSaga() {
  yield all([
    yield takeLatest(actions.DELETE_STAFF_SCHEDULE, deleteStaffSchedule),
    yield takeLatest(actions.SHOW_ALL_STAFF_SCHEDULE, getAllStaffSchedules),
    yield takeLatest(actions.EXPORT_STAFF_SCHEDULE, downloadScheduleTableData),
    yield takeLatest(actions.CREATE_STAFF_SCHEDULE, createStaffSchedule),
    yield takeLatest(actions.FILTER_BY_STAFF_AND_BY_CLASSLIST, filterScheduleByStaffAndClasslist),
    yield takeLatest(actions.GET_SELECTED_SCHEDULE, getSelectedScheduleDetails),
    yield takeLatest(actions.GET_AFFECTED_SCHEDULES, getAffectedSchedules),
    yield takeLatest(actions.GET_ALL_CLASSES, getAllClasses)
  ]);
}
export default scheduleSaga;
