const actions = {
  GET_ALL_APPLICATIONS: "GET_ALL_APPLICATIONS",
  GET_ALL_APPLICATIONS_SUCCESS: "GET_ALL_APPLICATIONS_SUCCESS",
  GET_STUDENT_FORMS: "GET_STUDENT_FORMS",
  GET_STUDENT_FORMS_SUCCESS: "GET_STUDENT_FORMS_SUCCESS",

  RESET_APPLICATION_OPERATION: "RESET_APPLICATION_OPERATION",

  GET_SINGLE_APPLICATION_FORM: "GET_SINGLE_APPLICATION_FORM",
  GET_SINGLE_APPLICATION_FORM_SUCCESS: "GET_SINGLE_APPLICATION_FORM_SUCCESS",

  GET_APP_BY_ID: "GET_APP_BY_ID",
  GET_APP_BY_ID_SUCCESS: "GET_APP_BY_ID_SUCCESS",

  UPDATE_APPLICATION_SETTING: "UPDATE_APPLICATION_SETTING",
  UPDATE_APPLICATION_SETTING_SUCCESS: "UPDATE_APPLICATION_SETTING_SUCCESS",

  UPDATE_FORM_ACTION: "UPDATE_FORM_ACTION",
  UPDATE_FORM_ACTION_SUCCESS: "UPDATE_FORM_ACTION_SUCCESS",

  APPLICATION_ERROR: "APPLICATION_ERROR",

  GET_SINGLE_STUDENT_FORM: "GET_SINGLE_STUDENT_FORM",
  GET_SINGLE_STUDENT_FORM_SUCCESS: "GET_SINGLE_STUDENT_FORM_SUCCESS",

  DOWNLOAD_APP_FORM_SUBMISSION: "DOWNLOAD_APP_FORM_SUBMISSION",
  DOWNLOAD_APP_FORM_SUBMISSION_SUCCESS: "DOWNLOAD_APP_FORM_SUBMISSION_SUCCESS",

  SAVE_FORM_FIELDS: "SAVE_FORM_FIELDS",
  SAVE_FORM_FIELDS_SUCCESSFUL: "SAVE_FORM_FIELDS_SUCCESSFUL",
  SAVE_FORM_FIELDS_FAILED: "SAVE_FORM_FIELDS_FAILED",

  FETCH_BUILDING_FORM: "FETCH_BUILDING_FORM",
  FETCH_BUILDING_FORM_SUCCESSFUL: "FETCH_BUILDING_FORM_SUCCESSFUL",
  FETCH_BUILDING_FORM_FAILED: "FETCH_BUILDING_FORM_FAILED",

  EDIT_BUILDING_FORM: "EDIT_BUILDING_FORM",
  EDIT_BUILDING_FORM_SUCCESSFUL: "EDIT_BUILDING_FORM_SUCCESSFUL",
  EDIT_BUILDING_FORM_FAILED: "EDIT_BUILDING_FORM_FAILED",

  RESET_EXTERNAL_KEY: "RESET_EXTERNAL_KEY",

  RESET_FORM_DETAIL: "RESET_FORM_DETAIL",

  FETCH_LEAD_STATUS_LIST: "FETCH_LEAD_STATUS_LIST",
  FETCH_LEAD_STATUS_LIST_SUCCESS: "FETCH_LEAD_STATUS_LIST_SUCCESS",

  GET_CLASSROOM_FOR_APPLICATION: "GET_CLASSROOM_FOR_APPLICATION",
  GET_CLASSROOM_FOR_APPLICATION_SUCCESSFUL: "GET_CLASSROOM_FOR_APPLICATION_SUCCESSFUL",

  GET_STUDENT_FOR_APPLICATION: "GET_STUDENT_FOR_APPLICATION",
  GET_STUDENT_FOR_APPLICATION_SUCCESSFUL: "GET_STUDENT_FOR_APPLICATION_SUCCESSFUL",
  APPLICATION_REQUEST_FAILED: "APPLICATION_REQUEST_FAILED",
  SHARE_APPLICATION: "SHARE_APPLICATION",
  SHARE_APPLICATION_SUCCESSFUL: "SHARE_APPLICATION_SUCCESSFUL",

  SHARE_ADMISSION_PROCESS: "SHARE_ADMISSION_PROCESS",
  SHARE_ADMISSION_PROCESS_SUCCESS: "SHARE_ADMISSION_PROCESS_SUCCESS",

  SAVE_FORM_ITEMS: "SAVE_FORM_ITEMS",
  SAVE_FORM_ITEMS_SUCCESSFUL: "SAVE_FORM_ITEMS_SUCCESSFUL",

  FETCH_FORM_ITEMS: "FETCH_FORM_ITEMS",
  FETCH_FORM_ITEMS_SUCCESSFUL: "FETCH_FORM_ITEMS_SUCCESSFUL",

  DELETE_FORM_ITEM: "DELETE_FORM_ITEM",
  DELETE_FORM_ITEM_SUCCESS: "DELETE_FORM_ITEM_SUCCESS",

  ADD_ADMISSION_FORM: "ADD_ADMISSION_FORM",
  ADD_ADMISSION_FORM_SUCCESS: "ADD_ADMISSION_FORM_SUCCESS",

  UPDATE_ADMISSION_FORM: "UPDATE_ADMISSION_FORM",
  UPDATE_ADMISSION_FORM_SUCCESS: "UPDATE_ADMISSION_FORM_SUCCESS",

  UPDATE_FORM_STYLE: "UPDATE_FORM_STYLE",
  UPDATE_FORM_STYLE_SUCCESS: "UPDATE_FORM_STYLE_SUCCESS",
  FETCH_APP_LOG: "FETCH_APP_LOG",
  FETCH_APP_LOG_SUCCESS: "FETCH_APP_LOG_SUCCESS",
  ADMISSION_PROCESS: "ADMISSION_PROCESS",
  ADMISSION_PROCESS_SUCCESS: "ADMISSION_PROCESS_SUCCESS",

  FETCH_ADMISSION_PROCESS: "FETCH_ADMISSION_PROCESS",
  FETCH_ADMISSION_PROCESS_SUCCESS: "FETCH_ADMISSION_PROCESS_SUCCESS",

  DELETE_ADMISSION_PROCESS: "DELETE_ADMISSION_PROCESS",
  DELETE_ADMISSION_PROCESS_SUCCESS: "DELETE_ADMISSION_PROCESS_SUCCESS",

  FETCH_SELECTED_ADMISSION_PROCESS: "FETCH_SELECTED_ADMISSION_PROCESS",
  FETCH_SELECTED_ADMISSION_PROCESS_SUCCESS: "FETCH_SELECTED_ADMISSION_PROCESS_SUCCESS",

  GET_TOTAL_STUDENT: "GET_TOTAL_STUDENT",
  GET_TOTAL_STUDENT_SUCCESS: "GET_TOTAL_STUDENT_SUCCESS",

  RESET_DELETE_REDIRECT: "RESET_DELETE_REDIRECT",

  GET_STUDENT_PROGRAMS: "GET_STUDENT_PROGRAMS",
  GET_STUDENT_PROGRAMS_SUCCESS: "GET_STUDENT_PROGRAMS_SUCCESS",

  DELETE_SELECTED_STUDENT: "DELETE_SELECTED_STUDENT",
  DELETE_SELECTED_STUDENT_SUCCESS: "DELETE_SELECTED_STUDENT_SUCCESS",
  GET_ALL_PROCESS_FOR_STUDENT: "GET_ALL_PROCESS_FOR_STUDENT",
  GET_ALL_PROCESS_FOR_STUDENT_SUCCESS: "GET_ALL_PROCESS_FOR_STUDENT_SUCCESS",

  GET_STUDENT_PROGRESS_DATA: "GET_STUDENT_PROGRESS_DATA",
  GET_STUDENT_PROGRESS_DATA_SUCCESS: "GET_STUDENT_PROGRESS_DATA_SUCCESS",

  RESET_STUDENT_PROGRESS: "RESET_STUDENT_PROGRESS",

  UPLOAD_CONTRACT_IN_FORM: "UPLOAD_CONTRACT_IN_FORM",
  UPLOAD_CONTRACT_IN_FORM_SUCCESS: "UPLOAD_CONTRACT_IN_FORM_SUCCESS",

  RESET_UPLOAD_CONTRACT: "RESET_UPLOAD_CONTRACT",
  ADD_COLUMNS: "ADD_COLUMNS",
  ADD_COLUMNS_SUCCESSFULL: "ADD_COLUMNS_SUCCESSFULL",
  SAVE_TABLE: "SAVE_TABLE",
  SAVE_TABLE_SUCCESSFULL: "SAVE_TABLE_SUCCESSFULL",
  DELETE_COLUMN: "DELETE_COLUMN",
  DELETE_COLUMN_SUCCESSFULL: "DELETE_COLUMN_SUCCESSFULL",
  GET_TEACHER_APPLICATION_SUCCESS: "GET_TEACHER_APPLICATION_SUCCESS",
  GET_TEACHER_APPLICATION: "GET_TEACHER_APPLICATION",
  GET_SHARED_APPLICATIONS: "GET_SHARED_APPLICATIONS",
  GET_SHARED_APPLICATIONS_SUCCESS: "GET_SHARED_APPLICATIONS_SUCCESS",
  SHARE_APPLICATION_ALREADY_SHARED: "SHARE_APPLICATION_ALREADY_SHARED",
  RESET_APPLICATION_SHARE: "RESET_APPLICATION_SHARE",
  UNSHARE_APPLICATION: "UNSHARE_APPLICATION",
  UNSHARE_APPLICATION_SUCCESS: "UNSHARE_APPLICATION_SUCCESS",
  COPY_APPLICATION_FILES: "COPY_APPLICATION_FILES",
  COPY_APPLICATION_FILES_SUCCESS: "COPY_APPLICATION_FILES_SUCCESS",
  CLONE_APPLICATION: "CLONE_APPLICATION",
  CLONE_APPLICATION_SUCCESS: "CLONE_APPLICATION_SUCCESS",
  DELETE_APPLICATION: "DELETE_APPLICATION",
  DELETE_APPLICATION_SUCCESS: "DELETE_APPLICATION_SUCCESS",
  DELETE_APPLICATION_ERROR: "DELETE_APPLICATION_ERROR",
  SEND_APPLICATION_EMAIL: "SEND_APPLICATION_EMAIL",
  SEND_APPLICATION_EMAIL_ERROR: "SEND_APPLICATION_EMAIL_ERROR",
  SEND_APPLICATION_EMAIL_SUCCESS: "SEND_APPLICATION_EMAIL_SUCCESS",
  DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER: "DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER",
  DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER_SUCCESS: "DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER_SUCCESS",
  GET_SINGLE_TEACHER_FORM: "GET_SINGLE_TEACHER_FORM",
  GET_SINGLE_TEACHER_FORM_SUCCESS: "GET_SINGLE_TEACHER_FORM_SUCCESS",
  DISABLE_ACCEPTING_RESPONSES: "DISABLE_ACCEPTING_RESPONSES",
  DISABLE_ACCEPTING_RESPONSES_SUCCESSFUL: "DISABLE_ACCEPTING_RESPONSES_SUCCESSFUL",
  SYNC_FORM_DATA: "SYNC_FORM_DATA",
  SYNC_FORM_DATA_SUCCESS: "SYNC_FORM_DATA_SUCCESS",
  RESET_SINGLE_APPLICATION_FORM: "RESET_SINGLE_APPLICATION_FORM",
  GET_FORM_USER_NAME:"GET_FORM_USER_NAME",
  GET_FORM_USER_NAME_SUCCESS:"GET_FORM_USER_NAME_SUCCESS",
  RESET_FORM_USER_NAME:"RESET_FORM_USER_NAME",
  sendApplicationEmail: (firebase, application, entityType) => ({
    type: actions.SEND_APPLICATION_EMAIL,
    firebase,
    application,
    entityType
  }),
  deleteApplication: (firebase, applicationType, id) => ({
    type: actions.DELETE_APPLICATION,
    firebase,
    applicationType,
    id
  }),

  cloneApplication: (firebase, id, entityType) => ({
    type: actions.CLONE_APPLICATION,
    firebase,
    id,
    entityType,
  }),

  copyApplicationFiles: (record, firebase) => ({
    type: actions.COPY_APPLICATION_FILES,
    record,
    firebase,
  }),

  syncFormData: (record, firebase) => ({
    type: actions.SYNC_FORM_DATA,
    record,
    firebase,
  }),

  unshareApplication: (record, firebase) => ({
    type: actions.UNSHARE_APPLICATION,
    record,
    firebase,
  }),

  resetUploadContract: (firebase) => ({
    type: actions.RESET_UPLOAD_CONTRACT,
    firebase,
  }),
  uploadContractInForm: (mediaFile, firebase) => ({
    type: actions.UPLOAD_CONTRACT_IN_FORM,
    mediaFile,
    firebase,
  }),

  resetStudentProgress: (firebase) => ({
    type: actions.RESET_STUDENT_PROGRESS,
    firebase,
  }),

  getStudentProgressData: (processId, firebase) => ({
    type: actions.GET_STUDENT_PROGRESS_DATA,
    processId,
    firebase,
  }),

  deleteStudentFromProcess: (studentId, processId, firebase, studentIds) => ({
    type: actions.DELETE_SELECTED_STUDENT,
    studentId,
    processId,
    firebase,
    studentIds,
  }),

  getProgramForProcess: (firebase) => ({
    type: actions.GET_STUDENT_PROGRAMS,
    firebase,
  }),

  getAllStudentWithProcessShare: (firebase) => ({
    type: actions.GET_TOTAL_STUDENT,

    firebase,
  }),

  deleteProcess: (item, id, firebase) => ({
    type: actions.DELETE_ADMISSION_PROCESS,
    item,
    id,
    firebase,
  }),
  restRedirect: (firebase) => ({
    type: actions.RESET_DELETE_REDIRECT,
    firebase,
  }),

  fetchSelectedAdmissionProcess: (id, firebase) => ({
    type: actions.FETCH_SELECTED_ADMISSION_PROCESS,
    id,
    firebase,
  }),
  fetchAdmissionProcess: (firebase) => ({
    type: actions.FETCH_ADMISSION_PROCESS,
    firebase,
  }),
  saveAdmissionProcess: (values, nodeId, firebase) => ({
    type: actions.ADMISSION_PROCESS,
    values,
    nodeId,
    firebase,
  }),

  updateAdmissionForm: (values, firebase) => ({
    type: actions.UPDATE_ADMISSION_FORM,
    values,
    firebase,
  }),

  saveFormStyle: (styleObj, id, firebase) => ({
    type: actions.UPDATE_FORM_STYLE,
    styleObj,
    id,
    firebase,
  }),

  addAdmissionForm: (values, firebase) => ({
    type: actions.ADD_ADMISSION_FORM,
    values,
    firebase,
  }),

  deleteFormItem: (formCategory, item, firebase) => ({
    type: actions.DELETE_FORM_ITEM,
    formCategory,
    item,
    firebase,
  }),

  fetchFormItems: (firebase, formCategory) => ({
    type: actions.FETCH_FORM_ITEMS,
    firebase,
    formCategory,
  }),

  saveFormItem: (values, firebase, formCategory) => ({
    type: actions.SAVE_FORM_ITEMS,
    values,
    firebase,
    formCategory,
  }),

  getClassroomForApplicaton: (firebase) => ({
    type: actions.GET_CLASSROOM_FOR_APPLICATION,
    firebase,
  }),
  getStudentForApplication: (firebase) => ({
    type: actions.GET_STUDENT_FOR_APPLICATION,
    firebase,
  }),
  shareApplication: (
    students,
    record,
    firebase,
    admissionProcess,
    entityType
  ) => ({
    type: actions.SHARE_APPLICATION,
    students,
    record,
    firebase,
    admissionProcess,
    entityType,
  }),
  shareAdmissionProcess: (students, record, firebase, admissionProcess) => ({
    type: actions.SHARE_ADMISSION_PROCESS,
    students,
    record,
    firebase,
    admissionProcess,
  }),

  getLeadStatusList: (firebase) => ({
    type: actions.FETCH_LEAD_STATUS_LIST,
    firebase,
  }),

  fetchBuildingForm: (firebase, formItems, category, id) => ({
    type: actions.FETCH_BUILDING_FORM,
    firebase,
    formItems,
    category,
    id,
  }),

  editBuildingForm: (
    firebase,
    values,
    formItems,
    category,
    id,
    selectedDbList,
    applicationType,
    oldApplicationObj,
    appliedBranches
  ) => ({
    type: actions.EDIT_BUILDING_FORM,
    firebase,
    values,
    formItems,
    category,
    id,
    selectedDbList,
    applicationType,
    oldApplicationObj,
    appliedBranches
  }),

  saveBuildingForm: (firebase, values, formItems, category, selectedDbList, applicationType, appliedBranches) => ({
    type: actions.SAVE_FORM_FIELDS,
    firebase,
    values,
    formItems,
    category,
    selectedDbList,
    applicationType,
    appliedBranches
  }),

  getApplicationForms: (firebase) => (
    {
      type: actions.GET_ALL_APPLICATIONS,
      firebase,
    }),
  getTeacherApplications: (firebase) => ({
    type: actions.GET_TEACHER_APPLICATION,
    firebase,
  }),

  getStudentFormSubmissions: (applications, firebase, entityType) => ({
    type: actions.GET_STUDENT_FORMS,
    applications,
    firebase,
    entityType,
  }),

  resetApplicationOperation: () => ({
    type: actions.RESET_APPLICATION_OPERATION,
  }),
  resetApplicationShare: () => ({
    type: actions.RESET_APPLICATION_SHARE,
  }),

  getSingleApplicationForms: (id, firebase, entityType) => (
    {
      type: actions.GET_SINGLE_APPLICATION_FORM,
      id,
      firebase,
      entityType,
    }),
  getSharedApplications: (entityId, firebase, entityType) => ({
    type: actions.GET_SHARED_APPLICATIONS,
    entityId,
    firebase,
    entityType,
  }),

  getApplicationById: (
    applicationId,
    studentId,
    firebase,
    nodeId,
    entityType
  ) => ({
    type: actions.GET_APP_BY_ID,
    applicationId,
    studentId,
    firebase,
    nodeId,
    entityType,
  }),

  updateApplicationSetting: (values, record, firebase) => ({
    type: actions.UPDATE_APPLICATION_SETTING,
    values,
    record,
    firebase,
  }),

  updateFormAction: (record, status, firebase, entityType) => ({
    type: actions.UPDATE_FORM_ACTION,
    record,
    status,
    firebase,
    entityType,
  }),

  getSingleStudentForms: (studentId, firebase) => ({
    type: actions.GET_SINGLE_STUDENT_FORM,
    studentId,
    firebase,
  }),
  getSingleTeacher: (teacherId, firebase) => ({
    type: actions.GET_SINGLE_TEACHER_FORM,
    teacherId,
    firebase,
  }),
  getAllProcessForStudent: (firebase) => ({
    type: actions.GET_ALL_PROCESS_FOR_STUDENT,
    firebase,
  }),

  downloadFormSubmissionExcel: (record, entityType, firebase) => ({
    type: actions.DOWNLOAD_APP_FORM_SUBMISSION,
    record,
    entityType,
    firebase,
  }),
  downloadFormSubmissionExcelForTeacher: (record, entityType, firebase) => ({
    type: actions.DOWNLOAD_APP_FORM_SUBMISSION_FOR_TEACHER,
    record,
    entityType,
    firebase
  }),
  resetExternalKey: () => ({
    type: actions.RESET_EXTERNAL_KEY,
  }),

  resetFormDetail: () => (
    {
      type: actions.RESET_FORM_DETAIL,
    }),

  getApplicationLogs: (applicationForm, firebase) => ({
    type: actions.FETCH_APP_LOG,
    applicationForm,
    firebase,
  }),
  addColumns: (firebase, columnObj) => ({
    type: actions.ADD_COLUMNS,
    firebase,
    columnObj
  }),
  saveTable: (firebase, rows, formId, tableId, operationType) => ({
    type: actions.SAVE_TABLE,
    firebase,
    rows,
    formId,
    tableId,
    operationType
  }),
  deleteColumn: (firebase, index, tableId, formId) => ({
    type: actions.DELETE_COLUMN,
    firebase,
    index,
    tableId,
    formId
  }),
  disableAcceptingResponses: (val, record, firebase) => ({
    type: actions.DISABLE_ACCEPTING_RESPONSES,
    val,
    record,
    firebase
  }),
  resetSingleApplicationForm: () => (
    {
      type: actions.RESET_SINGLE_APPLICATION_FORM,
    }),

  getFormUserName: (entityId,entityType,studentId,firebase) => ({
    type:actions.GET_FORM_USER_NAME,
    entityId,
    entityType,
    studentId,
    firebase
  }),

  resetFormUserName: () => ({
    type:actions.RESET_FORM_USER_NAME
  })

};
export default actions;
