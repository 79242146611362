const actions = {
  GET_SCHOOL_SETTING_DETAILS: "GET_SCHOOL_SETTING_DETAILS",
  GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL: "GET_SCHOOL_SETTING_DETAILS_SUCCESSFUL",

  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESSFUL: "GET_USER_DETAILS_SUCCESSFUL",

  GET_TAX_DETAILS: "GET_TAX_DETAILS",
  GET_TAX_DETAILS_SUCCESSFUL: "GET_TAX_DETAILS_SUCCESSFUL",

  UPLOAD_SCHOOL_LOGO: "UPLOAD_SCHOOL_LOGO",
  UPLOAD_SCHOOL_LOGO_SUCCESSFUL: "UPLOAD_SCHOOL_LOGO_SUCCESSFUL",

  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_USER_INFO_SUCCESSFUL: "UPDATE_USER_INFO_SUCCESSFUL",

  UPDATE_SCHOOL_ADDRESS: "UPDATE_SCHOOL_ADDRESS",
  UPDATE_SCHOOL_ADDRESS_SUCCESSFUL: "UPDATE_SCHOOL_ADDRESS_SUCCESSFUL",

  UPDATE_TEACHER_INFO: "UPDATE_TEACHER_INFO",
  UPDATE_TEACHER_INFO_SUCCESSFUL: "UPDATE_TEACHER_INFO_SUCCESSFUL",

  SCHOOL_SETTING_REQUEST_FAILED: "SCHOOL_SETTING_REQUEST_FAILED",
  ACTIVITY_SETTING_REQUEST_FAILED: "ACTIVITY_SETTING_REQUEST_FAILED",
  BILLING_SETTING_REQUEST_FAILED: "BILLING_SETTING_REQUEST_FAILED",

  GET_CAMERA_DETAILS: "GET_CAMERA_DETAILS",
  GET_CAMERA_DETAILS_SUCCESSFUL: "GET_CAMERA_DETAILS_SUCCESSFUL",

  GET_CLASSROOM_FOR_CAMERA_ASSIGN: "GET_CLASSROOM_FOR_CAMERA_ASSIGN",
  GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL: "GET_CLASSROOM_FOR_CAMERA_ASSIGN_SUCCESSFFUL",

  UPDATE_CAMERA_TO_CLASS: "UPDATE_CAMERA_TO_CLASS",
  UPDATE_CAMERA_TO_CLASS_SUCCESSFUL: "UPDATE_CAMERA_TO_CLASS_SUCCESSFUL",

  GET_SCHOOL_CONFIG: "GET_SCHOOL_CONFIG",
  GET_SCHOOL_CONFIG_SUCCESS: "GET_SCHOOL_CONFIG_SUCCESS",

  GET_INVOICE_COUNTER: "GET_INVOICE_COUNTER",
  GET_INVOICE_COUNTER_SUCCESS: "GET_INVOICE_COUNTER_SUCCESS",

  GET_RECEIPT_COUNTER: "GET_RECEIPT_COUNTER",
  GET_RECEIPT_COUNTER_SUCCESS: "GET_RECEIPT_COUNTER_SUCCESS",

  UPDATE_INVOICE_COUNTER: "UPDATE_INVOICE_COUNTER",
  UPDATE_INVOICE_COUNTER_SUCCESS: "UPDATE_INVOICE_COUNTER_SUCCESS",

  UPDATE_RECEIPT_COUNTER: "UPDATE_RECEIPT_COUNTER",
  UPDATE_RECEIPT_COUNTER_SUCCESS: "UPDATE_RECEIPT_COUNTER_SUCCESS",

  SAVE_CONFIG_SETTING: "SAVE_CONFIG_SETTING",
  SAVE_CONFIG_SETTING_SUCCESSFUL: "SAVE_CONFIG_SETTING_SUCCESSFUL",

  SAVE_ACTIVITY_SETTING: "SAVE_ACTIVITY_SETTING",
  SAVE_ACTIVITY_SETTING_SUCCESSFUL: "SAVE_ACTIVITY_SETTING_SUCCESSFUL",

  SAVE_TAX: "SAVE_TAX",
  SAVE_TAX_SUCCESSFUL: "SAVE_TAX_SUCCESSFUL",

  RESET_OPERATION_REQ: "RESET_OPERATION_REQ",

  SUBMIT_HANDBOOK: "SUBMIT_HANDBOOK",
  SUBMIT_HANDBOOK_SUCCESS: "SUBMIT_HANDBOOK_SUCCESS",
  SUBMIT_TERMS: "SUBMIT_TERMS",
  SUBMIT_TERMS_SUCCESS: "SUBMIT_TERMS_SUCCESS",

  GET_ACTIVE_ACTIVITY: "GET_ACTIVE_ACTIVITY",
  GET_ACTIVE_ACTIVITY_SUCCESS: "GET_ACTIVE_ACTIVITY_SUCCESS",

  GET_STAFF_HOLIDAYS_LIST: "GET_STAFF_HOLIDAYS_LIST",
  GET_STAFF_HOLIDAYS_LIST_SUCCESS: "GET_STAFF_HOLIDAYS_LIST_SUCCESS",

  GET_LEAVES_SETTING: "GET_LEAVES_SETTING",
  GET_LEAVES_SETTING_SUCCESS: "GET_LEAVES_SETTING_SUCCESS",

  SAVE_ACTIVE_ACTVITIES: "SAVE_ACTIVE_ACTVITIES",
  SAVE_ACTIVE_ACTVITIES_SUCCESS: "SAVE_ACTIVE_ACTVITIES_SUCCESS",

  GET_ACTIVE_COMMENT_ACTIVITY: " GET_ACTIVE_COMMENT_ACTIVITY",
  GET_ACTIVE_COMMENT_ACTIVITY_SUCCESS: "GET_ACTIVE_COMMENT_ACTIVITY_SUCCESS",

  SAVE_ACTIVE_COMMENT_ACTIVITIES: " SAVE_ACTIVE_COMMENT_ACTIVITIES",
  SAVE_ACTIVE_COMMENT_ACTIVITIES_SUCCESS: "SAVE_ACTIVE_COMMENT_ACTIVITIES_SUCCESS",

  GET_ACTIVITY_CONFIG: "GET_ACTIVITY_CONFIG",
  GET_ACTIVITY_CONFIG_SUCCESS: "GET_ACTIVITY_CONFIG_SUCCESS",

  DELETE_TAX: "DELETE_TAX",
  DELETE_TAX_SUCCESS: "DELETE_TAX_SUCCESS",
  ADD_RELATIONS: "ADD_RELATIONS",
  ADD_RELATIONS_SUCCESS: "ADD_RELATIONS_SUCCESS",
  GET_RELATIONS: "GET_RELATIONS",
  GET_RELATIONS_SUCCESS: "GET_RELATIONS_SUCCESS",
  RESET_RELATION_UPDATED: "RESET_RELATION_UPDATED",

  GET_ACTIVITY_TEMPLATE: "GET_ACTIVITY_TEMPLATE",
  GET_ACTIVITY_TEMPLATE_SUCCESS: "GET_ACTIVITY_TEMPLATE_SUCCESS",
  RESET_ACTIVITY_TEMPLATE: "RESET_ACTIVITY_TEMPLATE",
  SAVE_TEMPLATE_VALUE: "SAVE_TEMPLATE_VALUE",
  SAVE_TEMPLATE_VALUE_SUCCESS: "SAVE_TEMPLATE_VALUE_SUCCESS",

  ADD_CCTV_CAMERA_INFO: "ADD_CCTV_CAMERA_INFO",
  ADD_CCTV_CAMERA_INFO_SUCCESS: "ADD_CCTV_CAMERA_INFO_SUCCESS",

  DELETE_CAMERA_SETTING: "DELETE_CAMERA_SETTING",
  DELETE_CAMERA_SETTING_SUCCESS: "DELETE_CAMERA_SETTING_SUCCESS",


  resetRelationUpdated: () => ({
    type: actions.RESET_RELATION_UPDATED,
  }),
  getRelations: (firebase) => ({
    type: actions.GET_RELATIONS,
    firebase,
  }),

  addRelationTypes: (firebase, relations) => ({
    type: actions.ADD_RELATIONS,
    firebase,
    relations,
  }),

  SAVE_LEAVE_SETTING: "SAVE_LEAVE_SETTING",
  SAVE_LEAVE_SETTING_SUCCESS: "SAVE_LEAVE_SETTING_SUCCESS",

  SAVE_HOLIDAY: "SAVE_HOLIDAY",
  SAVE_HOLIDAY_SUCCESS: "SAVE_HOLIDAY_SUCCESS",

  DELETE_STAFF_HOLIDAY: "DELETE_STAFF_HOLIDAY",
  DELETE_STAFF_HOLIDAY_SUCCESS: "DELETE_STAFF_HOLIDAY_SUCCESS",

  deleteStaffHoliday: (record, firebase) => ({
    type: actions.DELETE_STAFF_HOLIDAY,
    record,
    firebase,
  }),

  fetchLeaveSetting: (firebase, value) => ({
    type: actions.GET_LEAVES_SETTING,
    firebase,
    value,
  }),

  fetchHolidayList: (firebase, getCurrentYear) => ({
    type: actions.GET_STAFF_HOLIDAYS_LIST,
    firebase,
    getCurrentYear
  }),

  addStaffHolidays: (value, firebase) => ({
    type: actions.SAVE_HOLIDAY,
    value,
    firebase,
  }),

  deleteSelectedTax: (val, firebase) => ({
    type: actions.DELETE_TAX,
    val,
    firebase,
  }),

  addTax: (values, firebase) => ({
    type: actions.SAVE_TAX,
    values,
    firebase,
  }),

  getTaxData: (firebase) => ({
    type: actions.GET_TAX_DETAILS,
    firebase,
  }),

  updateSchoolLogo: (file, firebase) => ({
    type: actions.UPLOAD_SCHOOL_LOGO,
    file,
    firebase,
  }),

  updateInvoiceCounterNumber: (invoiceCounterText, firebase) => ({
    type: actions.UPDATE_INVOICE_COUNTER,
    invoiceCounterText,
    firebase,
  }),

  updateReceiptCounterNumber: (receiptCounterText, firebase) => ({
    type: actions.UPDATE_RECEIPT_COUNTER,
    receiptCounterText,
    firebase,
  }),

  getSchoolSettingDetails: (firebase, initialCall) => ({
    type: actions.GET_SCHOOL_SETTING_DETAILS,
    firebase,
    initialCall,
  }),

  updateSchoolInfo: (userInfo, firebase) => ({
    type: actions.UPDATE_USER_INFO,
    userInfo,
    firebase,
  }),

  updateSchoolAddress: (address, firebase) => ({
    type: actions.UPDATE_SCHOOL_ADDRESS,
    address,
    firebase,
  }),

  getUserDetails: (firebase) => ({
    type: actions.GET_USER_DETAILS,
    firebase,
  }),

  updateUserInfo: (value, firebase) => ({
    type: actions.UPDATE_TEACHER_INFO,
    value,
    firebase,
  }),

  getCameraReferences: (firebase) => ({
    type: actions.GET_CAMERA_DETAILS,
    firebase,
  }),

  getClassroomForCamera: (firebase) => ({
    type: actions.GET_CLASSROOM_FOR_CAMERA_ASSIGN,
    firebase,
  }),

  updateCameraToClass: (values, cameraId, firebase) => ({
    type: actions.UPDATE_CAMERA_TO_CLASS,
    values,
    cameraId,
    firebase,
  }),

  schoolConfiguration: (firebase) => ({
    type: actions.GET_SCHOOL_CONFIG,
    firebase,
  }),

  getInvoiceCounter: (firebase) => ({
    type: actions.GET_INVOICE_COUNTER,
    firebase,
  }),

  getReceiptCounter: (firebase) => ({
    type: actions.GET_RECEIPT_COUNTER,
    firebase,
  }),

  saveSchoolConfig: (config, firebase) => ({
    type: actions.SAVE_CONFIG_SETTING,
    config,
    firebase,
  }),

  saveActivityConfig: (
    selectedActivityConfig,
    selectedActivity,
    checkedObj,
    formItems,
    firebase
  ) => ({
    type: actions.SAVE_ACTIVITY_SETTING,
    selectedActivityConfig,
    selectedActivity,
    checkedObj,
    formItems,
    firebase,
  }),

  activityConfiguration: (firebase) => ({
    type: actions.GET_ACTIVITY_CONFIG,
    firebase,
  }),

  resetOperationRequest: () => ({
    type: actions.RESET_OPERATION_REQ,
  }),

  submitHandbook: (attachedFile, config, firebase) => ({
    type: actions.SUBMIT_HANDBOOK,
    attachedFile,
    config,
    firebase,
  }),
  submitTerms: (attachedFile, config, firebase) => ({
    type: actions.SUBMIT_TERMS,
    attachedFile,
    config,
    firebase,
  }),

  getActiveActivities: (firebase, initVal) => ({
    type: actions.GET_ACTIVE_ACTIVITY,
    firebase,
    initVal,
  }),

  getActiveCommentActivities: (firebase, initVal) => ({
    type: actions.GET_ACTIVE_COMMENT_ACTIVITY,
    firebase,
    initVal,
  }),

  saveActiveActivities: (activities, firebase) => ({
    type: actions.SAVE_ACTIVE_ACTVITIES,
    activities,
    firebase,
  }),

  saveActiveCommentActivities: (activities, firebase) => ({
    type: actions.SAVE_ACTIVE_COMMENT_ACTIVITIES,
    activities,
    firebase,
  }),

  saveLeaveSetting: (values, firebase, rows, weekendNewObj, leaveSettingFromDb) => ({
    type: actions.SAVE_LEAVE_SETTING,
    values,
    firebase,
    rows,
    weekendNewObj,
    leaveSettingFromDb,
  }),

  getActivityTemplateValue: (firebase, activityType) => ({
    type: actions.GET_ACTIVITY_TEMPLATE,
    firebase,
    activityType
  }),

  saveTemplateValue: (value, activityType, firebase, id) => ({
    type: actions.SAVE_TEMPLATE_VALUE,
    value,
    activityType,
    firebase,
    id
  }),
  addCCTVCameraInfo: (values, firebase) => ({
    type: actions.ADD_CCTV_CAMERA_INFO,
    values,
    firebase
  }),
  deleteCameraSetting: (id, firebase) => ({
    type: actions.DELETE_CAMERA_SETTING,
    id,
    firebase
  })

};
export default actions;
