import actions from "./actions";
const initState = {
  queryList: [],
  isLoading: false,
  queryListChannel: undefined,
  queryOperation: undefined,
  error: false,
  errorMessage: undefined,
  unreadMessages: new Map(),
  parentChat: [],
  parentChatChannel: undefined,
  schoolChat: [],
  schoolChatChannel: undefined,
  chat: [],
  chatChannel: undefined,
  staffQueryList: [],
  staffQueryListChannel: undefined,
  teacherChat: [],
  teacherChatChannel: undefined,
  privateQueryList: [],
  privateQueryListChannel: undefined,
  teachers: [],
  parentChatList: [],
  teacherChatlist: [],
  groupCreated: false,
  groupDetails: {},
  teacherMap: new Map(),
  studentMapGroup: new Map(),
  group: {},
  showChat: false,
  chatWithConversationId: undefined,
  selectedGroup: undefined,
  selectedGroupChannel: undefined,
  totalUnreadCountPrivateMsg: 0,
  parentSideUnreadCount: 0,
  participantIds: [],
  participantIdsChannel: undefined,
};
export default function queryReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_QUERYLIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_UNREAD_COUNT_PARENT_SIDE_SUCCESS:
      return {
        ...state,
        parentSideUnreadCount: action.parentSideUnreadCount,
      };
    case actions.GET_SELECTED_GROUP_SUCCESS:
      return {
        ...state,
        selectedGroup: action.selectedGroup,
        selectedGroupChannel: action.selectedGroupChannel,
      };
    case actions.RESET_GROUP_CREATED:
      return {
        ...state,
        groupCreated: action.payload,
      };
    case actions.GET_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentMapGroup: action.studentMapGroup,
        teacherMap: action.teacherMap,
        group: action.groupDetails,
      };

    case actions.GET_GROUP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SET_SHOW_CHAT_SUCCESS:
      return {
        ...state,
        showChat: action.payload,
      };

    case actions.FETCH_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupDetails: action.groupDetails,
      };
    case actions.SETLOADING:
      return {
        ...state,
        isLoading: action.bool,
      };
    case actions.CREATE_GROUP_FAILED:
      return {
        isLoading: false,
        error: true,
        errorMessage: action.err,
      };
    case actions.GET_QUERYLIST_SUCCESS:
      return {
        ...state,
        queryList: action.queryList,
        queryListChannel: action.queryListChannel,
        isLoading: false,
      };
    case actions.GET_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.chat,
        chatChannel: action.chatChannel,
      };
    case actions.GET_PARENT_CHATLIST_SUCCESS:
      return {
        ...state,
        parentChatList: action.parentChatList,
      };
    case actions.GET_PRIVATE_QUERYLIST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.GET_PRIVATE_QUERYLIST_SUCCESS:
      return {
        ...state,
        privateQueryList: action.queryList,
        privateQueryListChannel: action.queryListChannel,
        isLoading: false,
      };
    case actions.GET_PARTICIPANT_IDS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_PARTICIPANT_IDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        participantIds: action.participantIds,
        participantIdsChannel: action.participantIdsChannel,
      };
    case actions.QUERYLIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actions.RESET_QUERYLIST_OPERATION:
      return {
        ...state,
        isLoading: false,
        queryOperation: undefined,
        error: false,
        errorMessage: undefined,
        chatWithConversationId: undefined,
      };

    case actions.GET_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadMessages: action.unreadMessages,
      };

    case actions.GET_PARENT_CHAT:
      return {
        ...state,
        isLoading: true,
        parentChat: [],
      };
    case actions.GET_PARENT_CHAT_SUCCESS:
      return {
        ...state,
        parentChat: action.parentChat,
        parentChatChannel: action.parentChatChannel,
        isLoading: false,
      };
    case actions.GET_TEACHER_CHATLIST_SUCCESS:
      return {
        ...state,
        teacherChatlist: action.teacherChatList,
      };
    case actions.SEND_MESSAGE:
      return {
        ...state,
      };
    case actions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
      };
    case actions.ADD_CONVERSATIONID_SUCCESS:
      return {
        ...state,
        queryOperation: "ADDCONVERSATIONID",
        chatWithConversationId: action.chatModel,
      };

    case actions.RESET_TEACHER_UNREAD_COUNT:
      return {
        ...state,
      };

    case actions.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupCreated: true,
      };
    case actions.GET_SCHOOL_MSG:
      return {
        ...state,
        isLoading: true,
        schoolChat: [],
      };
    case actions.GET_SCHOOL_MSG_SUCCESS:
      return {
        ...state,
        schoolChat: action.schoolChat,
        schoolChatChannel: action.schoolChatChannel,
        isLoading: false,
      };

    case actions.SEND_SCHOOL_MSG:
      return {
        ...state,
      };
    case actions.SEND_SCHOOL_MSG_SUCCESS:
      return {
        ...state,
      };

    case actions.GET_STAFF_QUERYLIST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_STAFF_QUERYLIST_SUCCESS:
      return {
        ...state,
        staffQueryList: action.staffQueryList,
        staffQueryListChannel: action.staffQueryListChannel,
        isLoading: false,
      };

    case actions.GET_TEACHER_CHAT:
      return {
        ...state,
        isLoading: true,
        teacherChat: [],
      };
    case actions.GET_TEACHER_CHAT_SUCCESS:
      return {
        ...state,
        teacherChat: action.teacherChat,
        teacherChatChannel: action.teacherChatChannel,
        isLoading: false,
      };

    case actions.SEND_TEACHER_MESSAGE:
      return {
        ...state,
      };
    case actions.SEND_TEACHER_MESSAGE_SUCCESS:
      return {
        ...state,
      };

    case actions.RESET_STAFF_CHAT_UNREAD_COUNT:
      return {
        ...state,
      };
    case actions.GET_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: action.teachers,
      };
    case actions.GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE_SUCCESS:
      return {
        ...state,
        totalUnreadCountPrivateMsg: action.count,
        isLoading: false,
      };

    case actions.GET_READ_ONLY_PRIVATE_QUERY_LIST:
      return {
        ...state,
        readOnlyPrivateQueryList:[]
      };

    case actions.GET_READ_ONLY_PRIVATE_QUERY_LIST_SUCCESS:
      return {
        ...state,
        readOnlyPrivateQueryList:action.readOnlyPrivateQueryList
      };



    default:
      return state;
  }
}
