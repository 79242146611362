import actions from "./actions";

const initState = {
  generatedReports: [],
  addFormFields: false,
  isLoading: true,
  reportsChannel: undefined,
  isDeleteLoading: false,
};

export default function allReportsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SHOW_REPORT:
      return {
        ...state,
      };

    case actions.SHOW_REPORT_SUCCESS:
      return {
        ...state,
        generatedReports: action.generatedReports,
        reportsChannel: action.reportsChannel,
      };

    case actions.SUBMIT_FORM:
      return {
        ...state,
        isLoading: true,
        addFormFields: false,
      };
    case actions.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addFormFields: true,
      };

    case actions.DELETE_REPORT:
      return {
        ...state,
        isDeleteLoading: true,
      };

    case actions.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        isDeleteLoading: false,
      };

    default:
      return state;
  }
}
