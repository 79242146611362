const imageExtension = [
  "image/jpg",
  "image/jpeg",
  "image/bmp",
  "image/gif",
  "image/png",
  // "image/svg+xml",
  "image/apng",
  "image/x-icon",
  "image/tiff",
  "image/webp",
];

const videoExtension = [
  "video/3gpp",
  "video/mp4",
  "video/MP4V-ES",
  "video/MPV",
  "video/mpeg",
  "video/ogg",
  "video/quicktime",
  "video/webm",
  "video/x-m4v",
  "video/ms-asf",
  "video/x-ms-wmv",
  "video/x-msvideo",
];

const fileExtension = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
  "application/xhtml+xml",
  "application/pdf",
  "application/gzip",
  "application/json",
  "text/csv"
];

export { imageExtension, fileExtension, videoExtension };
