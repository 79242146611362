import React, { Component } from "react";
import {
  TitleWrapper,
  ButtonHolders,
  ComponentTitle,
} from "../../containers/Classroom/classroom.style";
import Box from "../../components/utility/box";
import { Drawer } from "antd";
import PrefixIconButton from "../../components/CustomElements/buttons/prefixIconButton";
import colors from "../../Utility/colorFactory";
import pdfImage from "../../image/document.png";

class CustomPdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  showErr(err, url) {
    const { downloadAllowed } = this.props;
    return (
      <div style={styles.centered}>
        <h3
          style={{
            fontWeight: "bolder",
            color: colors.v2_fuzzy_brown,
          }}
        >
          Unsupported format !!!
        </h3>

        {!downloadAllowed || downloadAllowed === true ? (
          <div style={{ marginTop: 20 }}>
            <a
              href={url}
              style={{
                color: colors.v2_Cerulean,
              }}
            >
              Click here to open document in browser
            </a>
          </div>
        ) : null}
      </div>
    );
  }

  stopSpinner() {
    this.setState({ loading: false });
  }

  render() {
    const { downloadAllowed, invoiceUrl, shareEnabled } = this.props;

    return (
      <Drawer
        width={640}
        title={null}
        placement="right"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
      >
        <Box>
          <TitleWrapper>
            <ComponentTitle />
            {!downloadAllowed || downloadAllowed === true || downloadAllowed === "true" ? (
              <ButtonHolders style={styles.inline}>
                {shareEnabled ? (
                  <PrefixIconButton
                    title="Share"
                    onClick={() => this.props.onShare()}
                    backgroundColor={colors.white}
                    fontColor={colors.v2_Cerulean}
                    imgSrc={pdfImage}
                    border={true}
                    borderColor={colors.v2_Cerulean}
                  />
                ) : null}

                {!shareEnabled ? (
                  <a href={invoiceUrl} target="_blank">
                    <PrefixIconButton
                      title="Download"
                      onClick={() => null}
                      backgroundColor={colors.white}
                      fontColor={colors.v2_Cerulean}
                      imgSrc={pdfImage}
                      border={true}
                      borderColor={colors.v2_Cerulean}
                    />
                  </a>
                ) : null}
              </ButtonHolders>
            ) : null}
          </TitleWrapper>

          <embed
            src={invoiceUrl + "#toolbar=0"}
            type="application/pdf"
            style={{
              minWidth: "100%",
              height: window.innerHeight - 50,
              border: "none",
              backgroundColor: "white",
            }}
            onError={(err) => this.showErr(err, invoiceUrl)}
            // onload={(e) => this.stopSpinner()}
          />
        </Box>
      </Drawer>
    );
  }
}
export default CustomPdfViewer;
const styles = {
  centered: {
    marginTop: window.innerHeight / 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};
