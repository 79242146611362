// import { parents } from "./reducer";

// function ascendingSort(contact1, contact2) {
//   const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
//   const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
//   return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
// }

const actions = {
  LIST_PARENTS: "LIST_PARENTS",
  LIST_PARENT: "LIST_PARENT",
  UPDATE_PARENT: "UPDATE_PARENT",
  UPDATE_PARENT_SAGA: "UPDATE_PARENT_SAGA",
  SELECT_CURRENT_PARENT: "SELECT_CURRENT_PARENT",
  TOGGLE_VIEW: "PARENT_TOGGLE_VIEW",
  UPDATE_EDIT_PARENT: "PARENT_UPDATE_EDIT_PARENT",
  CHANGE_PARENT: "CHANGE_PARENT",
  EDIT_VIEW: "EDIT_VIEW",
  initData: () => ({
    type: actions.LIST_PARENTS,
  }),

  changeParent: (id) => ({
    type: actions.CHANGE_PARENT,
    id,
  }),

  viewChange: (view) => ({
    type: actions.EDIT_VIEW,
    view,
  }),
};
export default actions;
