import moment from "moment-timezone";
import FilterAction from "../../Utility/FilterAction";
export default class FilterLeads {
  constructor() { }
}

FilterLeads.filterByStatus = (searchedValue, parentValue) => {
  const mainValue = parentValue;
  let text = searchedValue.toLowerCase();
  let allMainValue = mainValue;
  let filteredValue = allMainValue.filter((item) => {
    return item.leadStatus && item.leadStatus.toLowerCase() === text;
  });

  if (!text || text === "" || text === "all leads") {
    return parentValue;
  } else if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  } else if (Array.isArray(filteredValue)) {
    return filteredValue;
  }
};
FilterLeads.secondFilter = (firstFilter, text) => {
  let secondFilter = firstFilter.filter((item) => {
    return item.label && item.label.toLowerCase() === text;
  });
  return secondFilter;
};

FilterLeads.filterActivity = (activityDropDown, leadActivity) => {
  let text = activityDropDown.toLowerCase();
  let activityFilter = leadActivity;
  let filteredValue = activityFilter.filter((item) => {
    return item.activityType && item.activityType.toLowerCase() === text;
  });
  if (!text || text === "" || text === "all activities") {
    return activityFilter;
  } else if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  } else if (Array.isArray(filteredValue)) {
    return filteredValue;
  }
};
FilterLeads.filterTaskByStatusAndlabel = (taskDropdown, labelDropdown, taskObject) => {
  let text = taskDropdown;
  let text2 = labelDropdown.toLowerCase();
  let taskFilter = taskObject;
  if (taskDropdown !== "All Tasks") {
    var firstFilter = taskFilter.filter((item) => {
      if (text === "Done") {
        return item.completed;
      } else if (text === "Pending") {
        return (
          !item.completed &&
          moment(item.nextFollowUpDate).isSameOrAfter(moment(), "day"));
      } else if (text === "Due") {
        return (!item.completed)
      }
      else {
        return (
          !item.completed && moment(item.nextFollowUpDate).isBefore(moment(), "day")
        );
      }
    });

    if (text2 !== "all label") {
      let secondFilter = FilterLeads.secondFilter(firstFilter, text2);
      return secondFilter;
    } else {
      return firstFilter;
    }
  } else {
    if (text2 !== "all label") {
      let secondFilter = FilterLeads.secondFilter(taskFilter, text2);
      return secondFilter;
    } else {
      return taskFilter;
    }
  }
};

FilterLeads.filterBySource = (searchedValue, parentValue) => {
  const mainValue = parentValue;
  let text = searchedValue.toLowerCase();
  let allMainValue = mainValue;
  let filteredValue = allMainValue.filter((item) => {
    return item.source && item.source.toLowerCase() === text;
  });

  if (!text || text === "" || text === "all source") {
    return parentValue;
  } else if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  } else if (Array.isArray(filteredValue)) {
    return filteredValue;
  }
};

FilterLeads.filterBySubSource = (searchedValue, parentValue) => {
  const mainValue = parentValue;
  let text = searchedValue.toLowerCase();
  let allMainValue = mainValue;
  let filteredValue = allMainValue.filter((item) => {
    return item.subSource && item.subSource.toLowerCase() === text;
  });

  if (!text || text === "" || text === "all subsource") {
    return parentValue;
  } else if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  } else if (Array.isArray(filteredValue)) {
    return filteredValue;
  }
};

FilterLeads.filterByNextFollowUp = (startDate, endDate, lead) => {
  const mainValue = lead;

  let allMainValue = mainValue;
  let filteredValue = allMainValue.filter((item) => {
    return (
      item.nextFollowUpDate &&
      item.nextFollowUpDate >= startDate &&
      item.nextFollowUpDate <= endDate
    );
  });

  if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  } else if (Array.isArray(filteredValue)) {
    return filteredValue;
  }
};

FilterLeads.filterByCreatedDate = (selectedDate, parentValue) => {
  let startTime = moment(selectedDate).startOf("day").valueOf();
  let endTime = moment(selectedDate).endOf("day").valueOf();

  const allMainValue = parentValue;
  let filteredValue = allMainValue.filter((item) => {
    return item.createdDate > startTime && item.createdDate < endTime;
  });

  return filteredValue;
};

FilterLeads.filterByAdmissionDate = (start, end, leads) => {
  let startTime = moment(start).startOf("day").valueOf();
  let endTime = moment(end).endOf("day").valueOf();

  return leads.filter((l) => {
    return (
      l.admissionDate &&
      moment(l.admissionDate).isBetween(moment(startTime), moment(endTime), "day", "[]") &&
      l.studentId
    );
  });
};

FilterLeads.searchLeadByString = (searchedValue, parentValue) => {
  if (searchedValue && parentValue) {
    let text = searchedValue.toLowerCase().toString();
    let allMainValue = parentValue;
    let filterLeadsMap = {};

    let filteredValue = allMainValue.filter((ele) => {
      if (ele.student && ele.student.name && ele.student.name.toLowerCase().includes(text)) {
        filterLeadsMap[ele.id] = ele;
        return true;
      }
    }).sort((a, b) => a.student.name.toLowerCase().indexOf(text) - b.student.name.toLowerCase().indexOf(text));

    allMainValue.forEach((item) => {
      if (
        (item.student && item.student.name && item.student.name.toLowerCase().includes(text)) ||
        (item.parentOne &&
          item.parentOne.name &&
          item.parentOne.name.toLowerCase().includes(text)) ||
        (item.parentOne &&
          item.parentOne.email &&
          item.parentOne.email.toLowerCase().includes(text)) ||
        (item.parentTwo &&
          item.parentTwo.name &&
          item.parentTwo.name.toLowerCase().includes(text)) ||
        (item.parentTwo &&
          item.parentTwo.email &&
          item.parentTwo.email.toLowerCase().includes(text)) ||
        (item.parentOne &&
          item.parentOne.phone &&
          item.parentOne.phone.toString().includes(text)) ||
        (item.parentTwo &&
          item.parentTwo.phone &&
          item.parentTwo.phone.toString().includes(text)) ||
        (item.student &&
          item.student.preferredName &&
          item.student.preferredName.toLowerCase().includes(text)) ||
        (item.student && item.student.address && item.student.address.toLowerCase().includes(text))
      ) {
        if (!filterLeadsMap[item.id]) {
          filteredValue.push(item);
        }
      }
    });

    if (!text || text === "" || text === "all") {
      return parentValue;
    } else if (!Array.isArray(filteredValue) && !filteredValue.length) {
      console.log("No data");
    } else if (Array.isArray(filteredValue)) {
      return filteredValue;
    }
  } else {
    return parentValue;
  }

};

FilterLeads.sortByFilter = (leads, value) => {
  let filterValue = [];
  if (value.toLowerCase() === "activity date") {
    filterValue = leads.sort(function (a, b) {
      if (a.activityType.toLowerCase() === "email" && !a.date) {
        a.date = a.createdOn
      }
      if (b.activityType.toLowerCase() === "email" && !b.date) {
        b.date = b.createdOn
      }
      if (!a.date)
        a.date = a.createdOn
      if (!b.date)
        b.date = b.createdOn
      if (a.activityType.toLowerCase() === "task")
        a.date = a.date ? a.date : a.nextFollowUpDate
      if (b.activityType.toLowerCase() === "task")
        a.date = a.date ? a.date : a.nextFollowUpDate

      if (a.date && b.date) {
        return b.date - a.date;
      }
      if (a.date && !b.date) {
        return -1;
      }
      if (b.date && !a.date) {
        return 1
      }
    });
  }
  if (value.toLowerCase() === "created on") {
    filterValue = leads.sort((a, b) => { return FilterAction.sortIntegerValue(b, a, "createdOn") });
  }
  return filterValue;
};

FilterLeads.filterByAssignTo = (searchedValue, parentValue) => {
  let filteredValue = parentValue.filter((item) => {
    return item.assignTo && item.assignTo === searchedValue;
  });

  if (!searchedValue || searchedValue === "" || searchedValue === "All Staff") {
    return parentValue;
  }
  if (!Array.isArray(filteredValue) && !filteredValue.length) {
    console.log("No data");
  }
  return filteredValue;
};


