import actions from "./actions";
const initState = {
  studentLessonStats: {},
  loading: false,
  error: false,
  errorMessage: undefined,
  lessons: [],
  assignmentRef: [],
  schoolAssignmentRef: [],
  lessonSubmission: new Map(),
  learningChannel: undefined,
  lessonComment: new Map(),
  showLoader: false,
  operationType: undefined,
  singleSubmission: [],
  studentSingleSubmissionComments: new Map(),
  submissionActivityChan: undefined,
  statsChan: undefined,
  submissionActivityMap: new Map(),
  studentWeeklyPlan: [],
  studentWeeklyPlanChan: undefined,
  planLessonLoading: false,
  lessonList: [],
  planAssignmentRef: [],
};

export default function studentLessonReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_STUDENT_LESSON_STATS:
      return {
        ...state,
      };
    case actions.GET_STUDENT_LESSON_STATS_SUCCESSFUL:
      return {
        ...state,
        studentLessonStats: action.studentLessonStats,
        statsChan: action.statsChan,
      };

    case actions.GET_LEARNING_EXP:
      return {
        ...state,
        loading: true,
        showLoader: true,
      };
    case actions.GET_LEARNING_EXP_SUCCESS:
      return {
        ...state,
        loading: false,
        lessons: action.lessons,
        assignmentRef: action.assignmentRef,
        schoolAssignmentRef: action.schoolAssignmentRef,
        lessonSubmission: action.lessonSubmission,
        learningChannel: action.learningChannel,
        showLoader: action.showLoader,
        submissionActivityMap: action.submissionActivityMap,
      };

    case actions.GET_STUDENT_ASSIGNMENT_COMMENT:
      return {
        ...state,
      };
    case actions.GET_STUDENT_ASSIGNMENT_COMMENT_SUCCESS:
      return {
        ...state,
        lessonComment: action.lessonComment,
      };

    case actions.SUBMIT_ASSIGNMENT:
      return {
        ...state,
        loading: true,
      };
    case actions.SUBMIT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        operationType: "submission_succes",
      };

    case actions.RESET_OPERATION:
      return {
        ...state,
        loading: false,
        operationType: undefined,
        errorMessage: undefined,
        error: false,
      };

    case actions.STUDENT_LESSON_STAT_REQUEST_FAIL:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        operationType: undefined,
      };

    case actions.GET_STUDENT_SINGLE_SUBMISSION:
      return {
        ...state,
        loading: true,
        singleSubmission: [],
      };
    case actions.GET_STUDENT_SINGLE_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSubmission: action.singleSubmission,
        submissionActivityChan: action.submissionActivityChan,
      };

    case actions.UPDATE_ASSIGNMENT_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_ASSIGNMENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        operationType: "update_succes",
      };

    case actions.GET_STUDENT_SINGLE_ACTIVITY_COMMENTS_SUCCESS:
      return {
        ...state,
        studentSingleSubmissionComments: action.studentSingleSubmissionComments,
      };

    case actions.GET_STUDENT_WEEKLY_PLAN:
      return {
        ...state,
        lessonList: [],
        studentWeeklyPlan: [],
      };

    case actions.GET_STUDENT_WEEKLY_PLAN_SUCCESS:
      return {
        ...state,
        studentWeeklyPlan: action.studentWeeklyPlan,
        studentWeeklyPlanChan: action.studentWeeklyPlanChan,
      };

    case actions.GET_PLAN_LESSONS:
      return {
        ...state,
      };

    case actions.GET_PLAN_LESSONS_SUCCESS:
      return {
        ...state,
        lessonList: action.lessonList,
      };

    case actions.GET_PLAN_ASSIGNMENT:
      return {
        ...state,
        planLessonLoading: true,
      };

    case actions.GET_PLAN_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        planLessonLoading: false,
        planAssignmentRef: action.planAssignmentRef,
      };

    default:
      return state;
  }
}
