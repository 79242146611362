const actions = {
  GET_STUDENT_TO_POST_NEW_ACTIVITY: "GET_STUDENT_TO_POST_NEW_ACTIVITY",
  GET_STUDENT_TO_POST_NEW_ACTIVITY_SUCCESSFUL: "GET_STUDENT_TO_POST_NEW_ACTIVITY_SUCCESSFUL",

  GET_CLASSROOM_FOR_NEW_ACTIVITY: "GET_CLASSROOM_FOR_NEW_ACTIVITY",
  GET_CLASSROOM_FOR_NEW_ACTIVITY_SUCCESSFUL: "GET_CLASSROOM_FOR_NEW_ACTIVITY_SUCCESSFUL",

  GET_ACTIVITY_TEMPLATE: "GET_ACTIVITY_TEMPLATE",
  GET_ACTIVITY_TEMPLATE_SUCCESSFUL: "GET_ACTIVITY_TEMPLATE_SUCCESSFUL",

  GET_ACTIVITY_SETTING: "GET_ACTIVITY_SETTING",
  GET_ACTIVITY_SETTING_SUCCESSFUL: "GET_ACTIVITY_SETTING_SUCCESSFUL",

  POST_ACTIVITY: "POST_ACTIVITY",
  POST_ACTIVITY_SUCCESSFUL: "POST_ACTIVITY_SUCCESSFUL",

  POST_ACTIVITY_TO_ALL_CENTER: "POST_ACTIVITY_TO_ALL_CENTER",
  POST_ACTIVITY_TO_ALL_CENTER_SUCCESSFUL: "POST_ACTIVITY_TO_ALL_CENTER_SUCCESSFUL",

  GET_STAFF_FOR_NEW_ACTIVITY: "GET_STAFF_FOR_NEW_ACTIVITY",
  GET_STAFF_FOR_NEW_ACTIVITY_SUCCESSFUL: "GET_STAFF_FOR_NEW_ACTIVITY_SUCCESSFUL",

  NEW_ACTIVITY_REQUEST_FAILED: "NEW_ACTIVITY_REQUEST_FAILED",

  RESET_ERROR: "RESET_ERROR",
  RESET_STAFF_ACTIVITY: "RESET_STAFF_ACTIVITY",

  GET_MEDICAL_FIELD: "GET_MEDICAL_FIELD",
  GET_MEDICAL_FIELD_SUCCESS: "GET_MEDICAL_FIELD_SUCCESS",

  GET_FOOD_MENU: "GET_FOOD_MENU",
  GET_FOOD_MENU_SUCCESS: "GET_FOOD_MENU_SUCCESS",

  GET_CENTER_BASED_CLASSES: "GET_CENTER_BASED_CLASSES",
  GET_CENTER_BASED_CLASSES_SUCCESS: "GET_CENTER_BASED_CLASSES_SUCCESS",

  POST_ACTIVITY_ALL_CENTER: "POST_ACTIVITY_ALL_CENTER",
  POST_ACTIVITY_ALL_CENTER_SUCCESSFUL: "POST_ACTIVITY_ALL_CENTER_SUCCESSFUL",

  GET_COMMENT_SETTING: "GET_COMMENT_SETTING",
  GET_COMMENT_SETTING_SUCCESS: "GET_COMMENT_SETTING_SUCCESS",

  GET_ACTIVITY_CUSTOM_LABEL_SUCCESS: "GET_ACTIVITY_CUSTOM_LABEL_SUCCESS",

  GET_INITIAL_VALUE: "GET_INITIAL_VALUE",
  GET_INITIAL_VALUE_SUCCESS: "GET_INITIAL_VALUE_SUCCESS",

  GET_POTTY_FIELD: "GET_POTTY_FIELD",
  GET_POTTY_FIELD_SUCCESS: "GET_POTTY_FIELD_SUCCESS",

  GET_CUSTOM_ACTIVITY_SETTING_SUCCESS: "GET_CUSTOM_ACTIVITY_SETTING_SUCCESS",

  getStudentForNewActivity: (firebase) => ({
    type: actions.GET_STUDENT_TO_POST_NEW_ACTIVITY,
    firebase,
  }),

  getClassroomForNewActivity: (firebase) => ({
    type: actions.GET_CLASSROOM_FOR_NEW_ACTIVITY,
    firebase,
  }),

  getActivityTemplate: (activityName, firebase) => ({
    type: actions.GET_ACTIVITY_TEMPLATE,
    activityName,
    firebase,
  }),

  postNewActivity: (
    selectedActivity,
    selectedStudent,
    selectedClassroom,
    formValue,
    firebase,
    mediaType,
    staffActivity
  ) => ({
    type: actions.POST_ACTIVITY,
    selectedActivity,
    selectedStudent,
    selectedClassroom,
    formValue,
    firebase,
    mediaType,
    staffActivity,
  }),

  getStaffForNewActivity: (firebase) => ({
    type: actions.GET_STAFF_FOR_NEW_ACTIVITY,
    firebase,
  }),

  getActivitySetting: (firebase, activitySetting) => ({
    type: actions.GET_ACTIVITY_SETTING,
    firebase,
    activitySetting,
  }),

  postAnnouncementToAllCenters: (
    selectedActivity,
    formValue,
    firebase,
    branchList,
    mediaType,
    selectedMode
  ) => ({
    type: actions.POST_ACTIVITY_TO_ALL_CENTER,
    selectedActivity,
    formValue,
    firebase,
    branchList,
    mediaType,
    selectedMode,
  }),

  resetErrorStatus: () => ({
    type: actions.RESET_ERROR,
  }),

  resetStaffActivityStatus: () => ({
    type: actions.RESET_STAFF_ACTIVITY,
  }),

  getMedicalFields: (firebase) => ({
    type: actions.GET_MEDICAL_FIELD,
    firebase,
  }),

  getPottyFields: (firebase) => ({
    type: actions.GET_POTTY_FIELD,
    firebase,
  }),

  getFoodMenuForToday: (firebase) => ({
    type: actions.GET_FOOD_MENU,
    firebase,
  }),

  getCenterBasedClass: (centers, firebase) => ({
    type: actions.GET_CENTER_BASED_CLASSES,
    centers,
    firebase,
  }),

  postNewActivityToAllCenters: (
    selectedActivity,
    selectedStudentList,
    selectedClassroom,
    formValue,
    firebase,
    mediaType,
    branchList,
    selectedMode
  ) => ({
    type: actions.POST_ACTIVITY_ALL_CENTER,
    selectedActivity,
    selectedStudentList,
    selectedClassroom,
    formValue,
    firebase,
    mediaType,
    branchList,
    selectedMode,
  }),

  getCommentSetting: (firebase) => ({
    type: actions.GET_COMMENT_SETTING,
    firebase,
  }),

  getInitialValue: () => ({
    type: actions.GET_INITIAL_VALUE,
  }),
};
export default actions;
