import { Spin } from "antd";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import componentLoader from "../../componentLoader";

const childRoutes = [
  {
    path: "timeline",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentLandingPage"), 3)
    ),
  },
  {
    path: "parentMessages",
    component: React.lazy(() =>
      componentLoader(() => import("../Message/parentMessageContainer"), 3)
    ),
  },
  {
    path: "godMode",
    component: React.lazy(() => componentLoader(() => import("../Page/godMode"), 3)),
  },
  {
    path: "teacherMode",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentModeSwitchPage"), 3)
    ),
  },
  {
    path: "studentLesson",
    component: React.lazy(() => componentLoader(() => import("../StudentLessonView"), 3)),
  },
  {
    path: "assignmentNotification",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/assignmentNotification"), 3)
    ),
  },
  {
    path: "comments",
    component: React.lazy(() => componentLoader(() => import("../Comment/parentComment"), 3)),
  },
  {
    path: "submissionForm",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/submissionForm"), 3)
    ),
  },
  {
    path: "studentSubmission",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/studentActivityView"), 3)
    ),
  },
  {
    path: "notifications",
    component: React.lazy(() => componentLoader(() => import("../Notifications"), 3)),
  },
  {
    path: "singleActivity",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/StudentNotificationActivityView"), 3)
    ),
  },
  {
    path: "virtualClassManagement",
    component: React.lazy(() => componentLoader(() => import("../OnlineClassManagement"), 3)),
  },
  {
    path: "classManagement",
    component: React.lazy(() =>
      componentLoader(() => import("../OnlineClassManagement/manageClass"), 3)
    ),
  },
  {
    path: "virtualClass",
    component: React.lazy(() => componentLoader(() => import("../StudentVirtualClass"), 3)),
  },
  {
    path: "schoolMessages",
    component: React.lazy(() => componentLoader(() => import("../ParentMessage"), 3)),
  },
  {
    path: "pdfViewer",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/CustomPdfViewer"), 3)
    ),
  },
  {
    path: "customlearningplan",
    component: React.lazy(() => componentLoader(() => import("../CustomLearningPlan"), 3)),
  },
  {
    path: "studentInvoices",
    component: React.lazy(() => componentLoader(() => import("../StudentOnlineClassInvoice"), 3)),
  },
  {
    path: "stripePayment",
    component: React.lazy(() => componentLoader(() => import("../StripePaymentMethod/stripeWrapper"), 3)),
  },
  {
    path: "enrollmentDetail",
    component: React.lazy(() => componentLoader(() => import("../EnrollmentDetail"), 3)),
  },
  {
    path: "InvoiceAdditionalDetail",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentOnlineClassInvoice/invoiceAdditionalDetail"), 3)
    ),
  },
  {
    // path: "studentProfile",
    path: "studentDetailedView",
    component: React.lazy(() => componentLoader(() => import("../Student/studentDetailedView"), 3)),
  },
  {
    path: "schedulePlaydate",
    component: React.lazy(() => componentLoader(() => import("../SchedulePlayDate"), 3)),
  },
  {
    path: "contactUs",
    component: React.lazy(() => componentLoader(() => import("../ContactUs"), 3)),
  },
  {
    path: "virtualLibrary",
    component: React.lazy(() => componentLoader(() => import("../VirtualLibrary"), 3)),
  },
  {
    path: "singleLesson",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/singleLesson"), 3)
    ),
  },
  {
    path: "applicationFormView",
    component: React.lazy(() => componentLoader(() => import("../Applications/viewForm"), 3)),
  },
  {
    path: "parentHandbook",
    component: React.lazy(() => componentLoader(() => import("../ParentHandbook"), 3)),
  },
  {
    path: "parentSupport",
    component: React.lazy(() => componentLoader(() => import("../Support"), 3)),
  },
];

const routes = [
  {
    path: "",
    component: React.lazy(() => componentLoader(() => import("../LandingPage"), 3)),
  },
  // {
  //   path: "multiCenterDashboard",
  //   component: React.lazy(() =>
  //     componentLoader(() => import("../MultiCenterDashboard"), 3)
  //   ),
  // },
  {
    path: "scheduleDemo",
    component: React.lazy(() => componentLoader(() => import("../DemoWidget"), 3)),
  },
  {
    path: "roomNotification",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/roomNotification"), 3)
    ),
  },
  {
    path: "godMode",
    component: React.lazy(() => componentLoader(() => import("../Page/godMode"), 3)),
  },
  {
    path: "fteReport",
    component: React.lazy(() => componentLoader(() => import("../Program/fteReport"), 3)),
  },
  {
    path: "activities",
    component: React.lazy(() => componentLoader(() => import("../Activities"), 3)),
  },
  {
    path: "studentLevelView",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/studentLevelActivity"), 3)
    ),
  },
  {
    path: "newActivity",
    component: React.lazy(() => componentLoader(() => import("../Activities/newActivity"), 3)),
  },
  {
    path: "studentAttendance",
    component: React.lazy(() => componentLoader(() => import("../StudentAttendance"), 3)),
  },
  {
    path: "studentAttendanceRecord",
    component: React.lazy(() => componentLoader(() => import("../NewStudentAttendance"), 3)),
  },
  {
    path: "studentTostafftAttendanceRatio",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffToStudentAttendanceLiveRatio"), 3)
    ),
  },
  {
    path: "manageAlert",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffToStudentAttendanceLiveRatio/managealert"), 3)
    ),
  },

  {
    path: "staffAttendanceRecord",
    component: React.lazy(() => componentLoader(() => import("../NewStaffAttendance"), 3)),
  },
  {
    path: "staffAttendance",
    component: React.lazy(() => componentLoader(() => import("../StaffAttendance"), 3)),
  },
  {
    path: "students",
    component: React.lazy(() => componentLoader(() => import("../Student"), 3)),
  },
  {
    path: "studentDetailedView",
    component: React.lazy(() => componentLoader(() => import("../Student/studentDetailedView"), 3)),
  },
  {
    path: "leadAboutDetail",
    component: React.lazy(() => componentLoader(() => import("../LeadsDetailedView/leadAbout"), 3)),
  },
  {
    path: "staffScheduleForm",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffScheduleCalendar/staffScheduleForm"), 3)
    ),
  },

  {
    path: "manageLead",
    component: React.lazy(() => componentLoader(() => import("../Leads/manageLead"), 3)),
  },
  {
    path: "taxSettings",
    component: React.lazy(() => componentLoader(() => import("../TaxSettings"), 3)),
  },
  {
    path: "rooms",
    component: React.lazy(() => componentLoader(() => import("../Classroom"), 3)),
  },
  {
    path: "staff",
    component: React.lazy(() => componentLoader(() => import("../Teacher"), 3)),
  },
  {
    path: "teacherDetailedView",
    component: React.lazy(() => componentLoader(() => import("../Teacher/teacherDetailedView"), 3)),
  },
  {
    path: "tags",
    component: React.lazy(() => componentLoader(() => import("../Tag"), 3)),
  },
  {
    path: "lessons",
    component: React.lazy(() => componentLoader(() => import("../Lesson"), 3)),
  },
  {
    path: "lessonPlans",
    component: React.lazy(() => componentLoader(() => import("../WeeklyPlan"), 3)),
  },
  {
    path: "lessonView",
    component: React.lazy(() => componentLoader(() => import("../Lesson/viewLesson"), 3)),
  },
  {
    path: "assessments",
    component: React.lazy(() => componentLoader(() => import("../Assessment"), 3)),
  },
  {
    path: "milestones",
    component: React.lazy(() => componentLoader(() => import("../Milestone"), 3)),
  },

  {
    path: "selectedStudentAssessment",
    component: React.lazy(() =>
      componentLoader(() => import("../Assessment/selectedStudentAssessment"), 3)
    ),
  },
  {
    path: "savedAssessments",
    component: React.lazy(() =>
      componentLoader(() => import("../Assessment/savedAssessmentView"), 3)
    ),
  },
  {
    path: "studentProgress",
    component: React.lazy(() =>
      componentLoader(() => import("../Assessment/studentsProgress"), 3)
    ),
  },
  {
    path: "program",
    component: React.lazy(() => componentLoader(() => import("../Program"), 3)),
  },
  {
    path: "feeStructure",
    component: React.lazy(() => componentLoader(() => import("../Fee"), 3)),
  },
  {
    path: "detailedFeePlan/:id",
    component: React.lazy(() => componentLoader(() => import("../DetailedFeePlan"), 3)),
  },
  {
    path: "detailedTemplate/:id",
    component: React.lazy(() => componentLoader(() => import("../DetailedTemplate"), 3)),
  },
  {
    //path: "detailedProgram/:id",
    path: "detailedProgram",
    component: React.lazy(() => componentLoader(() => import("../DetailedProgram"), 3)),
  },
  {
    //path: "detailedProgram/:id",
    path: "autoTransfer",
    component: React.lazy(() =>
      componentLoader(() => import("../Program/AutoTransferClassrooms"), 3)
    ),
  },
  {
    path: "onboarding",
    component: React.lazy(() => componentLoader(() => import("../Onboarding"), 3)),
  },
  // {
  //   path: "setting",
  //   component: React.lazy(() =>
  //     componentLoader(() => import("../UserSetting"), 3)
  //   ),
  // },
  {
    path: "invoice",
    component: React.lazy(() => componentLoader(() => import("../Invoice"), 3)),
  },
  {
    path: "payments",
    component: React.lazy(() =>
      componentLoader(() => import("../Invoice/payments"), 3)
    ),
  },
  {
    path: "newFeeplan",
    component: React.lazy(() => componentLoader(() => import("../FeePlan/newFeePlan"), 3)),
  },
  {
    path: "upcomingBirthday",
    component: React.lazy(() => componentLoader(() => import("../UpcomingBirthday"), 3)),
  },

  {
    path: "studentBilling",
    component: React.lazy(() => componentLoader(() => import("../StudentBilling"), 3)),
  },
  {
    path: "feeSetting",
    component: React.lazy(() => componentLoader(() => import("../StudentBilling/feeSetting"), 3)),
  },
  {
    path: "studentInvoice",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/studentInvoices"), 3)
    ),
  },
  {
    path: "allLeads",
    component: React.lazy(() => componentLoader(() => import("../Leads"), 3)),
  },
  {
    path: "leadForms",
    component: React.lazy(() => componentLoader(() => import("../LeadForms"), 3)),
  },
  {
    path: "leadsAutomation",
    component: React.lazy(() => componentLoader(() => import("../LeadForms/leadsAutomation"), 3)),
  },
  {
    path: "automationRuleSteps",
    component: React.lazy(() => componentLoader(() => import("../LeadForms/ruleDetailedView"), 3)),
  },
  {
    path: "actionDetail",
    component: React.lazy(() => componentLoader(() => import("../LeadForms/actionDetail"), 3)),
  },
  {
    path: "allTasks",
    component: React.lazy(() => componentLoader(() => import("../Leads/allTasks"), 3)),
  },
  {
    path: "leadSettings",
    component: React.lazy(() => componentLoader(() => import("../LeadForms/leadsSettings"), 3)),
  },
  {
    path: "leadEmailTemplates",
    component: React.lazy(() => componentLoader(() => import("../Leads/leadEmailTemplates"), 3)),
  },
  {
    path: "sendLeadEmail",
    component: React.lazy(() =>
      componentLoader(() => import("../LeadsDetailedView/sendleadEmail"), 3)
    ),
  },
  {
    path: "leadsDetailedView",
    component: React.lazy(() => componentLoader(() => import("../LeadsDetailedView"), 3)),
  },
  {
    path: "document",
    component: React.lazy(() => componentLoader(() => import("../TeacherDocument"), 3)),
  },
  {
    path: "newsletter",
    component: React.lazy(() => componentLoader(() => import("../Newsletter"), 3)),
  },
  {
    path: "invoiceTemplate",
    component: React.lazy(() => componentLoader(() => import("../InvoiceTemplate"), 3)),
  },
  {
    path: "expenseLogs",
    component: React.lazy(() =>
      componentLoader(() => import("../ExpenseManagement/ExpenseLogs"), 3)
    ),
  },
  {
    path: "connect",
    component: React.lazy(() => componentLoader(() => import("../Connect"), 3)),
  },
  {
    path: "complains",
    component: React.lazy(() => componentLoader(() => import("../Complains"), 3)),
  },
  {
    path: "leaves",
    component: React.lazy(() => componentLoader(() => import("../Leaves"), 3)),
  },
  {
    path: "newStudentLeave",
    component: React.lazy(() => componentLoader(() => import("../Leaves/newStudentLeave"), 3)),
  },
  {
    path: "staffLeaves",
    component: React.lazy(() => componentLoader(() => import("../StaffLeaves"), 3)),
  },
  {
    path: "newStaffLeave",
    component: React.lazy(() => componentLoader(() => import("../StaffLeaves/newStaffLeave"), 3)),
  },
  {
    path: "staffActivities",
    component: React.lazy(() => componentLoader(() => import("../Activities/staffActivities"), 3)),
  },
  {
    path: "staffMessages",
    component: React.lazy(() => componentLoader(() => import("../StaffQueryList"), 3)),
  },
  {
    path: "parentNotes",
    component: React.lazy(() => componentLoader(() => import("../ParentNotes"), 3)),
  },
  {
    path: "holiday",
    component: React.lazy(() => componentLoader(() => import("../Holiday"), 3)),
  },
  {
    path: "events",
    component: React.lazy(() => componentLoader(() => import("../Events"), 3)),
  },
  {
    path: "schoolDetails",
    component: React.lazy(() => componentLoader(() => import("../SchoolDetails"), 3)),
  },
  {
    path: "schedule",
    component: React.lazy(() => componentLoader(() => import("../Schedule"), 3)),
  },
  {
    path: "zoomConfiguration",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/zoomConfigurationPage"), 3)
    ),
  },
  {
    path: "zoomPlayer",
    component: React.lazy(() => componentLoader(() => import("../Activities/zoomPlay"), 3)),
  },
  {
    path: "parentMode",
    component: React.lazy(() =>
      componentLoader(() => import("../LandingPage/parentModeSwitchPage"), 3)
    ),
  },
  {
    path: "virtualClasses",
    component: React.lazy(() => componentLoader(() => import("../VirtualClass"), 3)),
  },
  {
    path: "admissionProcess",
    component: React.lazy(() => componentLoader(() => import("../AdmissionProcess/index"), 3)),
  },
  {
    path: "addStep",
    component: React.lazy(() => componentLoader(() => import("../AdmissionProcess/process"), 3)),
  },
  {
    path: "assignments",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allAssignment"), 3)
    ),
  },
  {
    path: "submissionStats",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmissionStats"), 3)
    ),
  },
  {
    path: "assignmentList",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allAssignment"), 3)
    ),
  },
  {
    path: "submission",
    component: React.lazy(() => componentLoader(() => import("../LessonAssignment/submission"), 3)),
  },
  {
    path: "submissionList",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmission"), 3)
    ),
  },
  {
    path: "weeklyPlanSubmission",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/weeklyPlanSubmission"), 3)
    ),
  },
  {
    path: "comments",
    component: React.lazy(() => componentLoader(() => import("../Comment"), 3)),
  },
  {
    path: "installations",
    component: React.lazy(() => componentLoader(() => import("../Installations"), 3)),
  },
  {
    path: "staffInstallations",
    component: React.lazy(() => componentLoader(() => import("../staffInstallations"), 3)),
  },
  {
    path: "allReports",
    component: React.lazy(() => componentLoader(() => import("../AllReports"), 3)),
  },
  {
    path: "editEmailInvite",
    component: React.lazy(() =>
      componentLoader(() => import("../Installations/editEmailInvite"), 3)
    ),
  },
  {
    path: "editFeeReminder",
    component: React.lazy(() => componentLoader(() => import("../Invoice/editFeeReminder"), 3)),
  },
  {
    path: "customizeActivityForm",
    component: React.lazy(() => componentLoader(() => import("../CustomizeActivityForm"), 3)),
  },
  {
    path: "activitySetting",
    component: React.lazy(() => componentLoader(() => import("../ActivitySettings"), 3)),
  },
  {
    path: "billingSetting",
    component: React.lazy(() => componentLoader(() => import("../BillingSettings"), 3)),
  },
  {
    path: "parentHandbookSetting",
    component: React.lazy(() => componentLoader(() => import("../ParentHandbookSetting"), 3)),
  },
  {
    path: "cctvSetting",
    component: React.lazy(() => componentLoader(() => import("../CctvSetting"), 3)),
  },
  {
    path: "lessonSetting",
    component: React.lazy(() => componentLoader(() => import("../LessonSettings"), 3)),
  },
  {
    path: "leaveSetting",
    component: React.lazy(() => componentLoader(() => import("../LeaveSettings"), 3)),
  },
  {
    path: "otherSettings",
    component: React.lazy(() => componentLoader(() => import("../OtherSettings"), 3)),
  },
  {
    path: "staffLeaveManagement",
    component: React.lazy(() => componentLoader(() => import("../StaffLeaveManagement"), 3)),
  },
  {
    path: "attendanceSetting",
    component: React.lazy(() => componentLoader(() => import("../AttendanceSettings"), 3)),
  },
  {
    path: "staffSchedule",
    component: React.lazy(() =>
      componentLoader(() => import("../StaffScheduleCalendar"), 3)
    ),
  },
  // {
  //   path: "minorSettings",
  //   component: React.lazy(() =>
  //     componentLoader(() => import("../MinorSettings"), 3)
  //   ),
  // },
  {
    path: "fieldDependency",
    component: React.lazy(() => componentLoader(() => import("../FormBuilder/addDependency"), 3)),
  },
  {
    path: "shareHistory",
    component: React.lazy(() => componentLoader(() => import("../Lesson/lessonShareHistory"), 3)),
  },
  {
    path: "queryList",
    component: React.lazy(() => componentLoader(() => import("../QueryList"), 3)),
  },
  {
    path: "singleChat",
    component: React.lazy(() => componentLoader(() => import("../QueryList/singleChat"), 3)),
  },

  {
    path: "enableActivity",
    component: React.lazy(() =>
      componentLoader(() => import("../MinorSettings/enableActivity"), 3)
    ),
  },
  {
    path: "enableComment",
    component: React.lazy(() => componentLoader(() => import("../EnableComments"), 3)),
  },
  {
    path: "permissions",
    component: React.lazy(() => componentLoader(() => import("../Permissions"), 3)),
  },
  {
    path: "notifications",
    component: React.lazy(() => componentLoader(() => import("../Notifications"), 3)),
  },
  {
    path: "singleActivity",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationActivityView"), 3)
    ),
  },
  {
    path: "singleConcern",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationComplaint"), 3)
    ),
  },
  {
    path: "singleLeave",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationLeave"), 3)
    ),
  },
  {
    path: "singleStaffLeave",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationStaffLeave"), 3)
    ),
  },
  {
    path: "singleNote",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationNote"), 3)
    ),
  },
  {
    path: "singleEvent",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationEvent"), 3)
    ),
  },
  {
    path: "notificationDoc",
    component: React.lazy(() =>
      componentLoader(() => import("../Notifications/NotificationDocument"), 3)
    ),
  },
  {
    path: "creditHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/creditHistory"), 3)
    ),
  },
  {
    path: "depositHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/depositHistory"), 3)
    ),
  },
  {
    path: "help",
    component: React.lazy(() => componentLoader(() => import("../HelpBlog"), 3)),
  },
  {
    path: "studentActivities",
    component: React.lazy(() =>
      componentLoader(() => import("../Activities/individualStudentActivity"), 3)
    ),
  },
  {
    path: "totalCreditHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/allCreditHistory"), 3)
    ),
  },
  {
    path: "totalDepositHistory",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentBilling/allDepositHistory"), 3)
    ),
  },
  {
    path: "pdfViewer",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/CustomPdfViewer"), 3)
    ),
  },
  {
    path: "bookingReport",
    component: React.lazy(() => componentLoader(() => import("../BookingReport"), 3)),
  },
  {
    path: "InvoiceAdditionalDetails",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentOnlineClassInvoice/invoiceAdditionalDetail"), 3)
    ),
  },
  {
    path: "StripePaymentMethod",
    component: React.lazy(() =>
      componentLoader(() => import("../StripePaymentMethod/schoolSidePayment"), 3)
    ),
  },
  {
    path: "meetingReport",
    component: React.lazy(() => componentLoader(() => import("../BookingReport/meetingReport"), 3)),
  },
  {
    path: "studentSubmissionStats",
    component: React.lazy(() =>
      componentLoader(() => import("../LessonAssignment/allSubmissionStats"), 3)
    ),
  },
  {
    path: "meetingSummary",
    component: React.lazy(() =>
      componentLoader(() => import("../BookingReport/activeStudentReport"), 3)
    ),
  },
  {
    path: "contactUs",
    component: React.lazy(() => componentLoader(() => import("../ContactUs"), 3)),
  },
  {
    path: "virtualLibrary",
    component: React.lazy(() => componentLoader(() => import("../VirtualLibrary"), 3)),
  },
  {
    path: "customLabels",
    component: React.lazy(() => componentLoader(() => import("../CustomLabels"), 3)),
  },
  {
    path: "support",
    component: React.lazy(() => componentLoader(() => import("../Support"), 3)),
  },
  {
    path: "singleLesson",
    component: React.lazy(() =>
      componentLoader(() => import("../StudentLessonView/singleLesson"), 3)
    ),
  },
  {
    path: "reports",
    component: React.lazy(() => componentLoader(() => import("../Reports"), 3)),
  },
  {
    path: "applications",
    component: React.lazy(() => componentLoader(() => import("../Applications"), 3)),
  },
  {
    path: "teacherApplications",
    component: React.lazy(() => componentLoader(() => import("../Applications/teacherApplication"), 3)),
  },
  {
    path: "applicationForms",
    component: React.lazy(() => componentLoader(() => import("../Applications/sharedForms"), 3)),
  },
  {
    path: "applicationFormView",
    component: React.lazy(() => componentLoader(() => import("../Applications/viewForm"), 3)),
  },
  {
    path: "studentProgram",
    component: React.lazy(() => componentLoader(() => import("../Program/occupancyReport"), 3)),
  },
  {
    path: "customActivity",
    component: React.lazy(() => componentLoader(() => import("../CustomActivity/index"), 3)),
  },
  {
    path: "formBuilder",
    component: React.lazy(() => componentLoader(() => import("../FormBuilder"), 3)),
  },
  {
    path: "configureTable",
    component: React.lazy(() => componentLoader(() => import("../FormBuilder/configureTable"), 3)),
  },
  {
    path: "expenseManagement",
    component: React.lazy(() => componentLoader(() => import("../ExpenseManagement"), 3)),
  },
  {
    path: "formPreview",
    component: React.lazy(() => componentLoader(() => import("../FormBuilder/formPreview"), 3)),
  },
  {
    path: "expenseDetail",
    component: React.lazy(() =>
      componentLoader(() => import("../ExpenseManagement/ExpenseDetail"), 3)
    ),
  },
  {
    path: "assignProgram",
    component: React.lazy(() => componentLoader(() => import("../Program/assignProgram"), 3)),
  },
  {
    path: "form",
    component: React.lazy(() => componentLoader(() => import("../Forms/customFields"), 3)),
  },
  {
    path: "createGroup",
    component: React.lazy(() => componentLoader(() => import("../PrivateChat/createGroup"), 3)),
  },
  {
    path: "forms",
    component: React.lazy(() => componentLoader(() => import("../Forms"), 3)),
  },
  {
    path: "invoiceDetail",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/invoice/transactionModal"), 3)
    ),
  },
  {
    path: "paymentRecord",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/invoice/recordPayment"), 3)
    ),
  },
  {
    path: "invoiceForm",
    component: React.lazy(() =>
      componentLoader(() => import("../../components/invoice/completeInvoiceModal"), 3)
    ),
  },
  {
    path: "studentQrGenerator",
    component: React.lazy(() =>
      componentLoader(() => import("../../containers/Student/studentQrGenerator"), 3)
    ),
  },
  {
    path: "feeAutomationActions",
    component: React.lazy(() => componentLoader(() => import("../StudentBilling/feeActions"), 3)),
  },
  {
    path: "feeActionForm",
    component: React.lazy(() => componentLoader(() => import("../StudentBilling/actionForm"), 3)),
  },
  {
    path: "emailTemplates",
    component: React.lazy(() => componentLoader(() => import("../EmailTemplates/index"), 3)),
  },
  {
    path: "emailTemplateForm",
    component: React.lazy(() =>
      componentLoader(() => import("../EmailTemplates/templateForm.js"), 3)
    ),
  },
  {
    path: "idCards",
    component: React.lazy(() =>
      componentLoader(() => import("../IdCard"), 3)
    ),
  },
  {
    path: "certificates",
    component: React.lazy(() =>
      componentLoader(() => import("../Certificates"), 3)
    ),
  },
  {
    path: "leadDashboard",
    component: React.lazy(() => componentLoader(() => import("../Leads/leadDashboard"), 3)),
  },
  {
    path: "messages",
    component: React.lazy(() => componentLoader(() => import("../Message/index"), 3)),
  },
  {
    path: "privateMessages",
    component: React.lazy(() => componentLoader(() => import("../Message/privateMessage"), 3)),
  },
  {
    path: "groupMessages",
    component: React.lazy(() => componentLoader(() => import("../Message/groupMessage"), 3)),
  },
  {
    path: "allMessageView",
    component: React.lazy(() => componentLoader(() => import("../Message/readOnlyMessage"), 3)),
  },
  {
    path: "invoiceDashboard",
    component: React.lazy(() => componentLoader(() => import("../Invoice/invoiceDashboard"), 3)),
  },
  {
    path: "en",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "ar",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "es",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "ru",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "pt",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "de",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "fr",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "hu",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "it",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "lt",
    component: React.lazy(() => componentLoader(() => import("../Support/selectLanguage"), 3)),
  },
  {
    path: "activityTemplateValue",
    component: React.lazy(() => componentLoader(() => import("../ActivitySettings/activityTemplateValue.js"), 3)),
  },
  {
    path: "staffHoliday",
    component: React.lazy(() => componentLoader(() => import("../StaffLeaveManagement/index"), 3)),
  },
  {
    path: "studentObservationTracker",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/stdObservationTracker"), 3)),
  },
  {
    path: "assessmentDashboard",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/assessmentTable"), 3)),
  },
  {
    path: "createObservationForm",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/createObservationForm"), 3)),
  },
  {
    path: "createAssessmentForm",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/createAssessmentForm"), 3))
  },
  {
    path: "assessmentBuilder",
    component: React.lazy(() => componentLoader(() => import("../StudentAssessmentBuilder/AssessmentBuilder"), 3)),
  },
  {
    path: "assessmentBuilder/createAssessmentTemplate",
    component: React.lazy(() => componentLoader(() => import("../StudentAssessmentBuilder/CreateAssessmentTemplate"), 3)),
  },
  {
    path: "assessmentBuilder/view",
    component: React.lazy(() => componentLoader(() => import("../StudentAssessmentBuilder/AssessmentTemplate"), 3)),
  },
  {
    path: "observationDashboard",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/observationTable"), 3)),
  },
  {
    path: "manageProgress",
    component: React.lazy(() => componentLoader(() => import("../Milestone/manageProgress"), 3)),
  },
  {
    path: "manageTerminology",
    component: React.lazy(() => componentLoader(() => import("../Milestone/manageTerminology"), 3)),
  },
  {
    path: "studentImmunization",
    component: React.lazy(() => componentLoader(() => import("../StudentImmmunization/Index"), 3)),
  },
  {
    path: "feeplan",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/Index"), 3)),
  },
  {
    path: "addFeePlan",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/AddFeePlan"), 3)),
  },
  {
    path: "editFeePlan",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/AddFeePlan"), 3)),
  },
  {
    path: "feePlanDiscount",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/Discount"), 3))
  },
  {
    path: "feePlanTax",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/Tax"), 3))
  },
  {
    path: "feeComponent",
    component: React.lazy(() => componentLoader(() => import("../NewFeePlan/FeeComponent"), 3))
  },
  {
    path: "feePlanDetailedView",
    component: React.lazy(() => componentLoader(() => import("../newFeePlanDetailView/index"), 3))
  },
  {
    path: "feePlanPdf",
    component: React.lazy(() => componentLoader(() => import("../newFeePlanPdf/Index"), 3))
  },
  {
    path: "roles",
    component: React.lazy(() => componentLoader(() => import("../Roles")))
  },
  {
    path: "progressTracker",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/progressTracker"), 3)),
  },
  {
    path: "assessmentPdf",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/assessmentPdf"), 3)),
  },
  {
    path: "previewPdf",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/previewPdf"), 3)),
  },
  {
    path: "assessmentReports",
    component: React.lazy(() => componentLoader(() => import("../studentAssessment/assessmentReports"), 3)),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style, firebase } = this.props;
    if (
      firebase &&
      firebase.selectedMode &&
      firebase.selectedMode.toLowerCase() === "parent" &&
      (firebase.user.studentId || firebase.user.childProfiles)
    ) {
      return (
        <div style={style}>
          {childRoutes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <React.Suspense
                key={singleRoute.path}
                fallback={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: window.innerHeight / 2,
                    }}
                  >
                    <Spin />
                  </div>
                }
              >
                <Route
                  exact={exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  {...otherProps}
                />
              </React.Suspense>
            );
          })}
        </div>
      );
    } else {
      return (
        <div style={style}>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <React.Suspense
                key={singleRoute.path}
                fallback={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: window.innerHeight / 2,
                    }}
                  >
                    <Spin />
                  </div>
                }
              >
                <Route
                  exact={exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  {...otherProps}
                />
              </React.Suspense>
            );
          })}
        </div>
      );
    }
  }
}

export default AppRouter;
