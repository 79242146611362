import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore";
import moment from "moment-timezone";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import FilterAction from "../../Utility/FilterAction";
import { getItem, setItem, removeItem, clear } from "../../Utility/encryptedStorage";
import IntlMessages from "../../components/utility/intlMessages";
import { ActivityApi } from "../../firestore-api/activity";
import { lessonAssignmentApi } from "../../firestore-api/lessonAssignment";
import { NotificationApi } from "../../firestore-api/notification";
import { QueryApi } from "../../firestore-api/query";
import actions from "./actions";
function* fetchQueryList({ firebase }) {
  const chan = yield call(QueryApi.getParentChatList, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_QUERYLIST_SUCCESS,
        queryList: data,
        queryListChannel: chan,
      });

      yield fork(getUnreadCountKey, data, firebase);
      yield fork(resetParentUnreadNode, firebase);
    }
  } finally {
    console.log("end query list channel");
  }
}
function* getParticipantIds({ firebase, groupId }) {
  const chan = yield call(QueryApi.getParticipantIds, firebase, groupId);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_PARTICIPANT_IDS_SUCCESS,
        participantIds: data,
        participantIdsChannel: chan,
      });
    }
  } finally {
    console.log("end query list channel");
  }
}
function* getSelectedGroup({ firebase, chatList }) {
  const chan = yield call(QueryApi.getSelectedGroup, firebase, chatList);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_SELECTED_GROUP_SUCCESS,
        selectedGroup: data,
        selectedGroupChannel: chan,
      });

      yield fork(messageToolbarVisible, { firebase, chatList, data });
    }
  } finally {
    console.log("end query list channel");
  }
}
function* fetchPrivateQueryList({ firebase }) {
  const chan = yield call(QueryApi.getPrivateChatList, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_PRIVATE_QUERYLIST_SUCCESS,
        queryList: data,
        queryListChannel: chan,
      });
    }
  } finally {
    console.log("end Private query list channel");
  }
}
function* getUnreadCountKey(chatList, firebase) {
  try {
    let chats = chatList;
    let teacher = firebase.teacher;

    let unreadMessages = new Map();
    let chatTask = [];
    if (teacher && teacher.id) {
      let teacherId = teacher.id;
      for (let index in chats) {
        if (chats[index].chatListModel && chats[index].chatListModel.id) {
          let parentId = chats[index].chatListModel.id;
          let task = call(QueryApi.getUnreadMessageKey, parentId, teacherId, firebase);
          if (task) {
            chatTask.push(task);
          }
        }
      }

      let newVal = yield all([chatTask]);
      for (let i in newVal[0]) {
        let data = newVal[0][i];
        unreadMessages = new Map([...unreadMessages, ...data]);
      }

      yield put({
        type: actions.GET_UNREAD_MESSAGE_COUNT,
        unreadMessages: unreadMessages,
      });
    }
  } catch (err) {
    console.log("failed to fetch unread msg count", err);
    bugsnagClient.notify(err);
  }
}

function* resetParentUnreadNode(firebase) {
  try {
    let teacher = firebase.teacher;
    let teacherId = teacher.id;
    if (teacherId) {
      yield fork(QueryApi.resetTeacherUnread, teacherId, firebase);
    }
  } catch (err) {
    console.log("failed to reset teacher unread key", err);
    bugsnagClient.notify(err);
  }
}

function* fetchParentChat({ parentId, firebase }) {
  try {
    const chan = yield call(QueryApi.getParentChats, parentId, firebase);
    while (true) {
      let data = yield take(chan);
      let messages = data;
      yield put({
        type: actions.GET_PARENT_CHAT_SUCCESS,
        parentChat: messages,
        parentChatChannel: chan,
      });
    }
  } finally {
    console.log("terminating parent chat channel");
  }
}
function* fetchChat({ conversationId, firebase, chatListModel }) {
  try {
    const chan = yield call(QueryApi.getChats, conversationId, firebase, chatListModel);
    while (true) {
      let data = yield take(chan);

      let messages = data;

      yield put({
        type: actions.GET_CHAT_SUCCESS,
        chat: messages,
        chatChannel: chan,
      });
    }
  } finally {
    console.log("terminating  chat channel");
  }
}

function* sendChatMessage({ message, parentId, chatListModel, firebase, img }) {
  try {
    let urls = [];
    let nodeId = yield call(QueryApi.createChatNode, firebase);

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }

    yield call(QueryApi.putChatMessage, parentId, message, nodeId, urls, firebase);

    let chatList = yield call(QueryApi.getChatListOfSelectedParent, parentId, firebase);

    let freshChatList = chatListModel;
    if (chatList && chatList.chatListModel) {
      freshChatList = chatList;
    }

    if (freshChatList) {
      yield fork(QueryApi.updateUnreadCount, freshChatList, parentId, message, firebase);

      let activityName = "Message";
      let activityId = nodeId;
      let body = "New Message received from " + firebase.teacher.name + ": " + message;
      let students = FilterAction.getStudentList(firebase);

      let selectedStudent = students.filter((i) => {
        return (
          (i.fatherProfileId && i.fatherProfileId === parentId) ||
          (i.motherProfileId && i.motherProfileId === parentId)
        );
      });

      if (
        selectedStudent &&
        selectedStudent[0] &&
        selectedStudent[0].fatherProfileId &&
        selectedStudent[0].fatherProfileId === parentId
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[0].fatherProfileId,
          firebase
        );

        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent[0].fatherUUid ? selectedStudent[0].fatherUUid : null,
          body,
          alertNode,
          selectedStudent[0].ios_fatherUUid ? selectedStudent[0].ios_fatherUUid : null,
          selectedStudent[0].id,
          selectedStudent[0].fatherProfileId,
          firebase
        );

        if (
          selectedStudent[0].fatherUUid !== undefined ||
          selectedStudent[0].ios_fatherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent[0].fatherUUid ? selectedStudent[0].fatherUUid : null,
            body,
            alertNode,
            selectedStudent[0].ios_fatherUUid ? selectedStudent[0].ios_fatherUUid : null,
            selectedStudent[0].id,
            selectedStudent[0].fatherProfileId,
            firebase
          );
        }
      }

      if (
        selectedStudent &&
        selectedStudent[0] &&
        selectedStudent[0].motherProfileId &&
        selectedStudent[0].motherProfileId === parentId
      ) {
        let alertNode = yield call(
          NotificationApi.createAlertReferenceNode,
          selectedStudent[0].motherProfileId,
          firebase
        );

        yield fork(
          NotificationApi.createAlertNotification,
          activityName,
          activityId,
          selectedStudent[0].motherUUid ? selectedStudent[0].motherUUid : null,
          body,
          alertNode,
          selectedStudent[0].ios_motherUUid ? selectedStudent[0].ios_motherUUid : null,
          selectedStudent[0].id,
          selectedStudent[0].motherProfileId,
          firebase
        );

        if (
          selectedStudent[0].motherUUid !== undefined ||
          selectedStudent[0].ios_motherUUid !== undefined
        ) {
          yield fork(
            NotificationApi.sendPushNotification,
            activityName,
            activityId,
            selectedStudent[0].motherUUid ? selectedStudent[0].motherUUid : null,
            body,
            alertNode,
            selectedStudent[0].ios_motherUUid ? selectedStudent[0].ios_motherUUid : null,
            selectedStudent[0].id,
            selectedStudent[0].motherProfileId,
            firebase
          );
        }
      }
    }
    yield put({
      type: actions.SEND_MESSAGE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.QUERYLIST_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed to send chat message. Try again or contact Illumine",
    });
  }
}
function* sendMessage({ message, chatListModel, firebase, img, participantIds }) {
  //if mode of chatList model is both then parentId willbe student id

  try {
    if (!chatListModel.conversationId) {
      chatListModel.conversationId = yield call(QueryApi.getConversationId, firebase);

      yield fork(fetchChat, {
        conversationId: chatListModel.conversationId,
        firebase,
      });
    }
    let urls = [];

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }
    let messageId = yield call(QueryApi.createChatNode, firebase, chatListModel.conversationId);

    var messageDate = new Date();
    // var message = message;
    let createdAt = {
      date: messageDate.getDate(),
      day: messageDate.getDay(),
      hours: messageDate.getHours(),
      minutes: messageDate.getMinutes(),
      month: messageDate.getMonth(),
      seconds: messageDate.getSeconds(),
      time: messageDate.getTime(),
      timezoneOffset: messageDate.getTimezoneOffset(),
      year: messageDate.getFullYear(),
    };
    let name;
    let msgObj = {
      createdAt,
      delete: false,
      id: messageId,
      inversdate: -moment.now(),
      name:
        firebase?.teacher?.name ??
        (firebase.student.motherProfileId == firebase.user.id
          ? firebase.student.motherName
          : firebase.student.fatherName),
      platform: "Web",
      read: false,
      senderId: firebase.user.id,
      status: "Sent",
      text: message ? message : "Image shared",
      imageUrl: urls && urls.length > 0 ? urls[0] : null,
      participantIds,
    };

    yield call(QueryApi.setConversation, chatListModel.conversationId, msgObj, firebase);
    QueryApi.sendMessage(chatListModel, msgObj, urls, firebase);

    yield put({
      type: actions.SEND_MESSAGE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.QUERYLIST_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed to send chat message. Try again or contact Illumine",
    });
  }
}
function* sendMessageToTeacher({ message, teacherId, chatListModel, firebase, img, teacher }) {
  try {
    let urls = [];

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }
    yield call(QueryApi.sendMessage, chatListModel, message, urls, firebase);

    yield put({
      type: actions.SEND_MESSAGE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.QUERYLIST_REQUEST_FAILED,
      errorMessage: err.message
        ? err.message
        : "Failed to send chat message. Try again or contact Illumine",
    });
  }
}

function* resetUnreadMessageCountTeacher({ parentId, firebase }) {
  try {
    let chatList = yield call(QueryApi.getChatListOfSelectedParent, parentId, firebase);
    if (chatList && chatList.chatListModel) {
      yield fork(QueryApi.resetTeacherUnreadMessageCount, parentId, chatList, firebase);
    }

    if (firebase.teacher && firebase.teacher.id) {
      yield fork(QueryApi.resetUnreadMessageKey, parentId, firebase.teacher.id, firebase);
    }
  } catch (err) {
    bugsnagClient.notify(err);
  }
}

function* fetchSchoolMessage({ parentId, student, firebase }) {
  const chan = yield call(QueryApi.getParentSideChats, parentId, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      let messages = data;
      yield put({
        type: actions.GET_SCHOOL_MSG_SUCCESS,
        schoolChat: messages,
        schoolChatChannel: chan,
      });

      yield fork(resetSchoolUnreadMessage, firebase);
    }
  } finally {
    console.log("terminating fetch school message");
  }
}

function* resetSchoolUnreadMessage(firebase) {
  try {
    let parentId = firebase.user.id;
    let chatList = yield call(QueryApi.getChatListOfSelectedParent, parentId, firebase);
    if (chatList && chatList.parentUnreadMessageCount > 0 && !firebase.isGod) {
      yield call(QueryApi.resetParentUnreadMessageInChatlist, parentId, chatList, firebase);
    }
  } catch (err) {
    console.log("failed to reset school unread message", err);
    bugsnagClient.notify(err);
  }
}

function* postSchoolMessage({ message, parentId, firebase, img }) {
  try {
    let urls = [];
    let nodeId = yield call(QueryApi.createChatNode, firebase);

    if (img) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
      if (mediaPaths) {
        urls = mediaPaths;
      }
    }

    yield call(QueryApi.putChatMessageByParent, parentId, message, nodeId, urls, firebase);

    let chatList = yield call(QueryApi.getChatListOfSelectedParent, parentId, firebase);

    let freshChatList = chatList;
    if (freshChatList && chatList.chatListModel) {
      yield fork(QueryApi.updateUnreadCountByParent, freshChatList, parentId, message, firebase);
    } else {
      yield fork(QueryApi.createChatListByParent, parentId, message, firebase);
    }

    yield fork(updateSchoolTeacherUnreadCount, firebase, nodeId, message);
  } catch (err) {
    console.log("failed to post message send by parent", err);
    bugsnagClient.notify(err);
  }
}

function* updateSchoolTeacherUnreadCount(firebase, nodeId, message) {
  try {
    let studentDetail = firebase.student;
    let classroom = studentDetail.classList
      ? studentDetail.classList[0]
      : studentDetail.classroomName;
    let parentId = firebase.user.id;

    let teachers = yield call(lessonAssignmentApi.getTeachersByStudentClass, classroom, firebase);

    if (teachers && teachers.length > 0) {
      for (let index in teachers) {
        let data = yield call(QueryApi.getUnreadMessageKey, parentId, teachers[index].id, firebase);
        let unreadKeyObj = {};
        if (data && data.teacherId && data.count) {
          unreadKeyObj = data;
          unreadKeyObj.count = data.count + 1;
          yield fork(
            QueryApi.updateTeacherUnreadKey,
            parentId,
            teachers[index].id,
            unreadKeyObj,
            firebase
          );
        } else {
          unreadKeyObj.count = 1;
          unreadKeyObj.teacherId = teachers[index].id;
          unreadKeyObj.studentId = studentDetail.id;
          yield fork(
            QueryApi.updateTeacherUnreadKey,
            parentId,
            teachers[index].id,
            unreadKeyObj,
            firebase
          );
        }
      }

      yield fork(NotificationApi.callDashboardRefreshApi, firebase, "communication", moment());

      yield fork(sendNotificationToTeachers, teachers, firebase, nodeId, message);
    }
  } catch (err) {
    console.log("failed to update school teacher unread count", err);
    bugsnagClient.notify(err);
  }
}

function* sendNotificationToTeachers(teachers, firebase, nodeId, message) {
  let student = firebase.student;
  let studentId = student.id;
  let parentId = firebase.user.id;
  let parentName;
  if (student.fatherProfileId && student.fatherProfileId === parentId) {
    parentName = student.fatherName;
  } else if (student.motherProfileId && student.motherProfileId === parentId) {
    parentName = student.motherName;
  }

  let activityName = "Message";
  let activityType = "Message";
  let activityId = nodeId;
  let alertMessage = "New Message received from " + parentName + ": " + message;

  for (let index in teachers) {
    let teacher = teachers[index];
    let permissionStatus = yield call(
      NotificationApi.receiveNotificationPermission,
      activityName,
      activityType,
      teacher,
      firebase
    );
    if (permissionStatus) {
      let alertNode = yield call(NotificationApi.createAlertReferenceNode, teacher.id, firebase);

      yield fork(
        NotificationApi.createSimpleAlertNotification,
        activityName,
        activityId,
        teacher.uid ? teacher.uid : null,
        alertMessage,
        alertNode,
        teacher.ios_uid ? teacher.ios_uid : null,
        studentId,
        teacher.id,
        activityType,
        undefined,
        firebase,
        parentName
      );

      if (teacher.uid !== undefined || teacher.ios_uid !== undefined) {
        yield fork(
          NotificationApi.sendSimplePushNotification,
          activityName,
          activityId,
          teacher.uid ? teacher.uid : null,
          alertMessage,
          alertNode,
          teacher.ios_uid ? teacher.ios_uid : null,
          studentId,
          teacher.id,
          activityType,
          undefined,
          firebase,
          parentName
        );
      }
    }
  }
}

function* fetchStaffQueryList({ firebase }) {
  // const chan = yield call(QueryApi.getStaffChatList, firebase);
  // try {
  //   while (true) {
  //     let data = yield take(chan);
  //     yield put({
  //       type: actions.GET_STAFF_QUERYLIST_SUCCESS,
  //       staffQueryList: data,
  //       staffQueryListChannel: chan,
  //     });
  //   }
  // } finally {
  //   console.log("end query list channel");
  // }

  let staffQueryList = JSON.parse(getItem("staffQueryList"));
  yield put({
    type: actions.GET_STAFF_QUERYLIST_SUCCESS,
    staffQueryList: staffQueryList,
    staffQueryListChannel: undefined,
  });
}
function* deleteMessage({ firebase, msg, item, mode, isLatestMessage }) {
  try {
    let nodeId;
    if (mode == "parent") {
      nodeId = item.chatListModel.id;
    } else if (mode == "parentSide") nodeId = firebase.user.id;
    else nodeId = item.senderId + "**" + item.receiverId;

    msg = { ...msg, delete: true };

    yield call(QueryApi.deleteTeacherMsg, msg, firebase, nodeId, mode);

    if (isLatestMessage) {
      switch (mode) {
        case "parent":
          yield call(QueryApi.updateChatModel, firebase, item, mode);
          break;
        case "parentSide":
          item = yield call(QueryApi.getChatListOfSelectedParent, firebase.user.id, firebase);
          yield call(QueryApi.updateChatModel, firebase, item, mode);
          break;
        case "teacher":
          let otherTeacherChatModel = yield call(
            QueryApi.getTeacherChatListById,
            item.teacherId,
            firebase.user.id,
            firebase
          );
          otherTeacherChatModel.lastMessage = "Deleted Message";
          yield call(QueryApi.updateChatModel, firebase, item, mode, otherTeacherChatModel);
          break;
      }
    }
  } catch (e) {
    console.log("Error in delete message", e);
    bugsnagClient.notify(e);
  }
}
function* getTeachers({ firebase }) {
  try {
    let studentDetail = firebase.student;

    let parentName =
      firebase.user && firebase.user.id === studentDetail.fatherProfileId
        ? studentDetail.fatherName
        : studentDetail.motherName;

    let teachers = yield call(
      lessonAssignmentApi.getTeachersByStudentClassList,
      studentDetail.classList ? studentDetail.classList : [studentDetail.classroomName],
      firebase
    );

    yield put({
      type: actions.GET_TEACHERS_SUCCESS,
      teachers,
    });
  } catch (e) {
    console.log("Error in fetching teacher");
    bugsnagClient.notify(e);
  }
}
function* deletePrivateMessage({ firebase, msg, item, mode, isLatestMessage }) {
  try {
    yield call(QueryApi.deletePrivateMessage, msg, firebase, item, mode, isLatestMessage);
  } catch (e) {
    console.log("Error in delete message");
    bugsnagClient.notify(e);
  }
}

function* fetchTeacherChat({ item, firebase }) {
  let nodeId = item.senderId + "**" + item.receiverId;
  let teacherId = firebase.teacher.id;

  try {
    const chan = yield call(QueryApi.getTeacherChats, nodeId, firebase);
    while (true) {
      let x = yield take(chan);
      let msg = [];
      for (var i = x.length - 1; i >= 0; i--) {
        var chatDate = moment.utc(x[i].createdAt.time);
        msg.push({
          id: Math.round(Math.random() * 1000000),
          text: x[i].delete ? <IntlMessages id="message.delete" /> : x[i].text,
          image: x[i].imageUrl && !x[i].delete ? x[i].imageUrl : null,
          createdAt: new Date(chatDate),
          user: {
            id: x[i].senderId === teacherId ? 1 : 2,
            lastIndex: i === x.length - 1 ? true : false,
          },
          actualMsgObj: x[i],
        });
      }

      yield put({
        type: actions.GET_TEACHER_CHAT_SUCCESS,
        teacherChat: msg,
        teacherChatChannel: chan,
      });
    }
  } finally {
    console.log("terminating teacher chat channel");
  }
}

function* sendTeacherChatMessage({ message, chatListModel, firebase, img }) {
  try {
    let chatList = chatListModel;
    let nodeId = chatList.senderId + "**" + chatList.receiverId;
    let teacherId = firebase.teacher.id;
    let receiverId = chatList.teacherId;
    let urls = [];

    let uniqueNode = yield call(QueryApi.getTeacherConversationUniqueNodeId, firebase);
    if (nodeId) {
      if (img) {
        let storagePath = firebase.sbp + "/media/images/";
        let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, img, firebase);
        if (mediaPaths) {
          urls = mediaPaths;
        }
      }

      yield call(
        QueryApi.putStaffChatMessage,
        nodeId,
        teacherId,
        message,
        uniqueNode,
        urls,
        firebase
      );

      let singleMessage = message[0];

      let senderChatList = chatList;
      senderChatList.lastMessage = singleMessage;
      senderChatList.lastSenderId = teacherId;
      senderChatList.inverseDate = -new Date().getTime();
      senderChatList.read = false;
      yield fork(
        QueryApi.updateStaffChatListModal,
        teacherId,
        receiverId,
        senderChatList,
        firebase
      );

      let receiversChatList = yield call(
        QueryApi.getTeacherChatListById,
        receiverId,
        teacherId,
        firebase
      );

      let tempReceiversChatList = {};
      if (receiversChatList && receiversChatList.teacherId) {
        tempReceiversChatList = receiversChatList;
        tempReceiversChatList.unreadCount = receiversChatList.unreadCount
          ? receiversChatList.unreadCount + 1
          : 1;
        tempReceiversChatList.lastMessage = singleMessage;
        tempReceiversChatList.lastSenderId = teacherId;
        tempReceiversChatList.inverseDate = -new Date().getTime();
        tempReceiversChatList.read = true;

        yield fork(
          QueryApi.updateStaffChatListModal,
          receiverId,
          teacherId,
          tempReceiversChatList,
          firebase
        );
      } else {
        let teacher = firebase.teacher;

        if (teacher && teacher.id) {
          let receivingStaff = teacher;
          tempReceiversChatList.gender = receivingStaff.gender;
          tempReceiversChatList.inverseDate = -new Date().getTime();
          tempReceiversChatList.iosId = receivingStaff.ios_uid ? receivingStaff.ios_uid : null;
          tempReceiversChatList.lastAccess = receivingStaff.lastAccess
            ? receivingStaff.lastAccess
            : null;
          tempReceiversChatList.lastMessage = singleMessage;
          tempReceiversChatList.lastSenderId = teacherId;
          tempReceiversChatList.name = receivingStaff.name;
          tempReceiversChatList.profileImageUrl = receivingStaff.profileImageUrl
            ? receivingStaff.profileImageUrl
            : null;
          tempReceiversChatList.read = true;
          tempReceiversChatList.receiverId = senderChatList.receiverId;
          tempReceiversChatList.senderId = senderChatList.senderId;
          tempReceiversChatList.teacherId = receivingStaff.id;
          tempReceiversChatList.uid = receivingStaff.uid ? receivingStaff.uid : null;
          tempReceiversChatList.unreadCount = 1;

          yield fork(
            QueryApi.updateStaffChatListModal,
            receiverId,
            teacherId,
            tempReceiversChatList,
            firebase
          );
        }
      }

      //notification flow
      let allTeachers = JSON.parse(getItem("teacherList"));
      let senderName = firebase.teacher.name;
      let receiverProfile = allTeachers.filter((t) => {
        return t.id === receiverId;
      });

      if (receiverProfile && receiverProfile.length > 0) {
        let profile = receiverProfile[0];
        let activityName = "Staff_Message";

        let alertNode = yield call(NotificationApi.createAlertReferenceNode, profile.id, firebase);

        let obj = {
          activityName: activityName,
          androidId: profile.uid ? profile.uid : null,
          iosId: profile.ios_uid ? profile.ios_uid : null,
          body: "New Message received from " + senderName + " : " + singleMessage,
          id: alertNode,
          inverseTime: -new Date().getTime(),
          nodeId: uniqueNode,
          read: false,
          senderName: senderName,
          type: activityName,
          userType: "teacher",
          teacherId: profile.id,
          branchPath: firebase.sbp,
        };

        yield fork(
          NotificationApi.createSimpleAlertNotification,
          obj.activityName,
          obj.nodeId,
          obj.androidId,
          obj.body,
          alertNode,
          obj.iosId,
          undefined,
          obj.teacherId,
          obj.type,
          undefined,
          firebase,
          undefined,
          obj.userType,
          obj.branchPath
        );

        if (obj.androidId !== undefined || obj.iosId !== undefined) {
          yield fork(
            NotificationApi.sendSimplePushNotification,
            obj.activityName,
            obj.nodeId,
            obj.androidId,
            obj.body,
            alertNode,
            obj.iosId,
            undefined,
            obj.teacherId,
            obj.type,
            undefined,
            firebase,
            undefined,
            obj.userType,
            obj.branchPath
          );
        }
      }
    }
  } catch (err) {
    console.log("failed to put teacher chat message", err);
    bugsnagClient.notify(err);
  }
}
function* resetUnreadCount({ firebase, chatListId }) {
  try {
    yield call(QueryApi.resetUnreadCount, firebase, chatListId);
  } catch (err) {
    console.log("unable to reset unread count", err);
    bugsnagClient.notify(err);
  }
}
function* getParentChatList({ firebase }) {
  try {
    let parentChatList = yield call(QueryApi.getChatList, firebase);

    yield put({
      type: actions.GET_PARENT_CHATLIST_SUCCESS,
      parentChatList: parentChatList.body.response,
    });
  } catch (err) {
    console.log("unable to get parents", err);
    bugsnagClient.notify(err);
  }
}
function* getTeachersChat({ firebase, studentId, parentId }) {
  try {
    let teacherChatlist = yield call(QueryApi.getTeacherChat, firebase, studentId, parentId);

    yield put({
      type: actions.GET_TEACHER_CHATLIST_SUCCESS,
      teacherChatList: teacherChatlist.body.response,
    });
  } catch (err) {
    console.log("unable to get teacher chat", err);
    bugsnagClient.notify(err);
  }
}
function* createGroup({ firebase, group, fileList, attachedFilesUrl, edit, groupId }) {
  try {
    let urls = [];

    if (fileList && fileList.fileList.length > 0) {
      let storagePath = firebase.sbp + "/media/images/";
      let mediaPaths = yield call(ActivityApi.getMediaPath, storagePath, fileList, firebase);
      if (mediaPaths) {
        urls = mediaPaths;
      }
      group.imageUrl = urls[0];
    }
    if (attachedFilesUrl.length > 0 && attachedFilesUrl[0].path) {
      group.imageUrl = attachedFilesUrl[0].path;
    }

    if (edit) {
      let response = yield call(QueryApi.editGroup, group, firebase, groupId);
    } else {
      let response = yield call(QueryApi.createGroup, group, firebase);
    }

    yield put({
      type: actions.CREATE_GROUP_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.CREATE_GROUP_FAILED,
      err,
    });
  }
}

function* resetStaffUnreadMessageCount({ chatList, firebase }) {
  try {
    if (chatList && chatList.receiverId && chatList.senderId) {
      let teacherId = firebase.teacher.id;

      let sendersChatList = yield call(
        QueryApi.getTeacherChatListById,
        teacherId,
        chatList.teacherId,
        firebase
      );
      if (sendersChatList && sendersChatList.teacherId) {
        yield fork(QueryApi.setSenderUnreadCount, teacherId, chatList.teacherId, firebase);
      }

      let receiversChatList = yield call(
        QueryApi.getTeacherChatListById,
        chatList.teacherId,
        teacherId,
        firebase
      );
      if (receiversChatList && receiversChatList.teacherId) {
        yield fork(QueryApi.setReceiverReadStatus, chatList.teacherId, teacherId, firebase);
      }
    }
  } catch (err) {
    console.log("failed to reset staff unread message count", err);
    bugsnagClient.notify(err);
  }
}
function* fetchGroupDetails({ firebase, selectedChat }) {
  try {
    if (selectedChat && firebase) {
      let groupId = selectedChat.groupId;
      let res = yield call(QueryApi.fetchGroupDetails, firebase, groupId);

      yield put({
        type: actions.FETCH_GROUP_DETAILS_SUCCESS,
        groupDetails: res?.body?.response,
      });
    }
  } catch (err) {
    console.log("Error in fetching groupinfo", err);
    bugsnagClient.notify(err);
  }
}
function* addConversationId({ firebase, chatModel }) {
  let conversationId = yield call(QueryApi.getConversationId, firebase);
  chatModel.conversationId = conversationId;
  yield put({
    type: actions.ADD_CONVERSATIONID_SUCCESS,
    chatModel,
  });
}
function* getGroup({ firebase, groupId }) {
  try {
    if (groupId && firebase) {
      let groupDetails = yield call(QueryApi.getGroup, firebase, groupId);
      const { studentMapGroup, teacherMap } = yield call(
        QueryApi.getInitialData,
        firebase,
        groupDetails
      );
      yield put({
        type: actions.GET_GROUP_SUCCESS,
        studentMapGroup,
        teacherMap,
        groupDetails,
      });
    }
  } catch (err) {
    console.log("Error in getting intial group data", err);
    bugsnagClient.notify(err);
  }
}

function* messageToolbarVisible({ firebase, chatList, data }) {
  try {
    if (chatList && firebase) {
      switch (chatList.mode.toLowerCase()) {
        case "single":
        case "both":
          yield put({
            type: actions.SET_SHOW_CHAT_SUCCESS,
            payload: true,
          });
          break;
        case "group":
          let payload = false;

          let group = data;
          if (firebase.selectedMode == "parent") {
            let student = firebase.student;
            if (group?.items) {
              let filteredItem = group.items.filter((i) => i.studentId == student.id);
              if (filteredItem.length > 0) {
                let groupFilteredItem = filteredItem[0];
                let parentType = FilterAction.getparentType(firebase.user.id, firebase);

                if (parentType === "parent1" && groupFilteredItem.motherSelected) payload = true;
                if (parentType === "parent2" && groupFilteredItem.fatherSelected) payload = true;
              }
            }
          } else {
            payload = group.teacherList.includes(firebase.user.id);
          }

          yield put({
            type: actions.SET_SHOW_CHAT_SUCCESS,
            payload,
          });
      }
    }
  } catch (err) {
    console.log("Error in show chat saga", err);
    bugsnagClient.notify(err);
  }
}
function* getTotalUnreadCount({ firebase }) {
  let requestPayload = {};
  if (firebase.selectedMode.toLowerCase() === "parent") {
    requestPayload = {
      userType: "parent",
      userId: firebase.user.id,
      studentId: firebase.student.id,
    };
  } else {
    requestPayload = {
      userType: "teacher",
      userId: firebase.user.id,
    };
  }

  let response = yield QueryApi.getTotalUnreadCountPrivateMsg(firebase, requestPayload);

  if (response.body.code == 200) {
    yield put({
      type: actions.GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE_SUCCESS,
      count: response.body.response,
    });
  } else {
    console.log("failed to get total unread count of private message");
    bugsnagClient.notify(response.body);
  }
}

function* fetchReadOnlyPrivateQueryList({firebase, teacherId}){
  try{
    let data  = yield call(QueryApi. getPrivateChatListById, firebase, teacherId);
    if(data){
      yield put({
        type: actions.GET_READ_ONLY_PRIVATE_QUERY_LIST_SUCCESS,
        readOnlyPrivateQueryList: data,
      });
    }
  }catch(err){
    console.log("failed to fetch ready only private query list",err);
    bugsnagClient.notify(err);
  }
}

function* getUpdatedReadCountData({queryList, firebase}){
  try{
    if(queryList && queryList.length > 0){
      yield fork(getUnreadCountKey, queryList, firebase);
      yield fork(resetParentUnreadNode, firebase);
    }
  }catch(err){
    console.log("failed to get updated read count data",err);
    bugsnagClient.notify(err);
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_QUERYLIST, fetchQueryList),
    yield takeLatest(actions.GET_PARENT_CHAT, fetchParentChat),
    yield takeLatest(actions.SEND_MESSAGE, sendChatMessage),
    yield takeLatest(actions.RESET_TEACHER_UNREAD_COUNT, resetUnreadMessageCountTeacher),
    yield takeLatest(actions.GET_SCHOOL_MSG, fetchSchoolMessage),
    yield takeLatest(actions.SEND_SCHOOL_MSG, postSchoolMessage),
    yield takeLatest(actions.GET_STAFF_QUERYLIST, fetchStaffQueryList),
    yield takeLatest(actions.GET_TEACHER_CHAT, fetchTeacherChat),
    yield takeLatest(actions.SEND_TEACHER_MESSAGE, sendTeacherChatMessage),
    yield takeLatest(actions.RESET_STAFF_CHAT_UNREAD_COUNT, resetStaffUnreadMessageCount),
    yield takeLatest(actions.DELETE_MESSAGE, deleteMessage),
    yield takeLatest(actions.GET_PRIVATE_QUERYLIST, fetchPrivateQueryList),
    yield takeLatest(actions.GET_CHAT, fetchChat),
    yield takeLatest(actions.SEND_CHAT_MESSAGE, sendMessage),
    yield takeLatest(actions.GET_TEACHERS, getTeachers),
    yield takeLatest(actions.DELETE_PRIVATE_MESSAGE, deletePrivateMessage),
    yield takeLatest(actions.SEND_MESSAGE_TO_TEACHER, sendMessageToTeacher),
    yield takeLatest(actions.GET_PARENT_CHATLIST, getParentChatList),
    yield takeLatest(actions.GET_TEACHER_CHATLIST, getTeachersChat),
    yield takeLatest(actions.CREATE_GROUP, createGroup),
    yield takeLatest(actions.FETCH_GROUP_DETAILS, fetchGroupDetails),
    yield takeLatest(actions.GET_GROUP, getGroup),
    yield takeLatest(actions.SET_SHOW_CHAT, messageToolbarVisible),
    yield takeLatest(actions.RESET_UNREAD_COUNT, resetUnreadCount),
    yield takeLatest(actions.ADD_CONVERSATIONID, addConversationId),
    yield takeLatest(actions.GET_SELECTED_GROUP, getSelectedGroup),
    yield takeLatest(actions.GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE, getTotalUnreadCount),
    yield takeLatest(actions.GET_PARTICIPANT_IDS, getParticipantIds),
    yield takeLatest(actions.GET_READ_ONLY_PRIVATE_QUERY_LIST, fetchReadOnlyPrivateQueryList),
    yield takeLatest(actions.REFETCH_READ_COUNT_DATA, getUpdatedReadCountData)
  ]);
}
