import { Layout } from "antd";
import clone from "clone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withFirebase } from "../../components/firebase/context";
import Menu from "../../components/uielements/menu";
import Scrollbars from "../../components/utility/customScrollBar.js";
import formatMsg from "../../components/utility/formatMessageUtil";
import IntlMessages from "../../components/utility/intlMessages";
import Logo from "../../components/utility/logo";
import smallIllumineLogo from "../../image/illumine_logo.png";
import { dir } from "../../index";
import appActions from "../../redux/app/actions";
import { themeConfig } from "../../settings";
import themes from "../../settings/themes";
import colors from "../../Utility/colorFactory";
import { getItem, setItem } from "../../Utility/encryptedStorage";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";
import options from "./options";
import parentOptions from "./parentOptions";
import SidebarWrapper from "./sidebar.style";
import sidebar_new from "../../image/sidebar_new.png";
// import mixpanel from "mixpanel-browser";
//import mixpanel from "mixpanel-browser";
import AssessmentTerminology from "../../Utility/assessmentTerminology";
import { Tooltip } from "antd";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed, changeSideBarColor } =
  appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessmentTerminology: {},
    }
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  componentDidMount() {
    this.props.changeSideBarColor(getItem("sidebarOptionColor"));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assessmentTerminology !== this.props.assessmentTerminology) {
      this.setState({
        assessmentTerminology: this.props.assessmentTerminology,
      })

    }
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
    const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  checkIfPermission(key) {
    const { firebase } = this.props;
    var permission = true;
    switch (key) {
      case "timeline":
        permission =
          firebase.schoolConfig &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "students":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SM_View_Student_List,
          firebase
        );
        break;

      case "staff":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_Staff_List,
          firebase
        );
        break;

      case "rooms":
        permission = true;
        break;

      case "tags":
        permission = true;
        break;

      case "multiCenterDashboard":
        permission = firebase && firebase.teacher && firebase.teacher.newBranches ? true : false;
        break;

      case "attendance":
        permission =
          firebase &&
          !firebase.schoolConfig.roomAttendanceMode &&
          (FilterPermission.checkIfPermission(
            PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
            firebase
          ) ||
            (firebase &&
              FilterPermission.checkIfPermission(
                PermissionStrings.SM_RECORD_ATTENDANCE,
                firebase
              )));

        break;

      case "studentAttendance":
        permission =
          FilterPermission.checkIfPermission(PermissionStrings.SM_RECORD_ATTENDANCE, firebase) &&
          !firebase.schoolConfig.roomAttendanceMode;
        break;

      case "staffAttendance":
        permission =
          FilterPermission.checkIfPermission(
            PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
            firebase
          ) && !firebase.schoolConfig.roomAttendanceMode;
        break;

      case "attendanceRecord":
        permission =
          firebase.schoolConfig.roomAttendanceMode &&
          (FilterPermission.checkIfPermission(
            PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
            firebase
          ) ||
            FilterPermission.checkIfPermission(PermissionStrings.SM_RECORD_ATTENDANCE, firebase));

        break;

      case "studentAttendanceRecord":
        permission =
          FilterPermission.checkIfPermission(PermissionStrings.SM_RECORD_ATTENDANCE, firebase) &&
          firebase.schoolConfig.roomAttendanceMode;
        break;

      case "staffAttendanceRecord":
        permission =
          FilterPermission.checkIfPermission(
            PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
            firebase
          ) && firebase.schoolConfig.roomAttendanceMode;
        break;

      case "fee":
        permission = FilterPermission.checkIfPermission(PermissionStrings.BILLING_View, firebase);
        break;

      case "feeStructure":
        if (
          firebase &&
          !firebase.isParent &&
          firebase.schoolConfig.billingMode &&
          firebase.schoolConfig.billingMode === "Fee Plan" &&
          FilterPermission.checkIfPermission(
            PermissionStrings.BILLING_Create_Edit_Fee_Template,
            firebase
          )
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "invoiceTemplate":
        if (
          !firebase.isParent &&
          !firebase.schoolConfig.billingMode &&
          FilterPermission.checkIfPermission(PermissionStrings.BILLING_View, firebase)
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "schoolDetails":
        permission = true;
        break;

      case "parentMode":
        if (
          firebase &&
          firebase.user &&
          (firebase.user.studentId || firebase.user.childProfiles) &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "teacherMode":
        if (
          this.props.firebase &&
          this.props.firebase.selectedMode &&
          this.props.firebase.selectedMode === "parent" &&
          this.props.firebase.user.userType.toLowerCase() === "teacher"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "godMode":
        if (this.props.firebase && this.props.firebase.isGod) {
          permission = true;
          break;
        } else {
          permission = false;
          return;
        }

      case "activities":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "mySchool":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "studentLevelView":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_VIEW,
          firebase
        );
        break;

      case "installations":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.INSTALLATION_REPORT_VIEW,
          firebase
        );
        break;

      case "events":
        permission = FilterPermission.checkIfPermission(PermissionStrings.EVENTS_VIEW, firebase);
        break;

      case "holiday":
        permission = FilterPermission.checkIfPermission(PermissionStrings.HOLIDAYS_VIEW, firebase);
        break;

      case "lessons":
        permission =
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW, firebase) ||
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW_W_PLAN, firebase);
        break;

      case "lessonPlans":
        permission =
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW, firebase) ||
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW_W_PLAN, firebase);
        break;

      case "lessonAssignments":
        permission =
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW, firebase) ||
          FilterPermission.checkIfPermission(PermissionStrings.LESSON_VIEW_W_PLAN, firebase);
        break;

      case "schedule":
        permission = FilterPermission.checkIfPermission(PermissionStrings.SCHEDULE_VIEW, firebase);
        break;
      case "settings":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;
      case "permissions":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;
      case "roles":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;
      case "assessments":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.ASSESSMENT_VIEW,
          firebase
        );
        break;

      case "program":
        permission = FilterPermission.checkIfPermission(PermissionStrings.PROGRAM_View, firebase);
        break;

      case "leads":
        permission = FilterPermission.checkIfPermission(PermissionStrings.LEAD_View, firebase);
        break;

      case "studentBilling":
        permission = FilterPermission.checkIfPermission(PermissionStrings.BILLING_View, firebase);
        break;

      case "payments":
        permission = FilterPermission.checkIfPermission(PermissionStrings.BILLING_View, firebase);
        break;

      case "virtualClassManagement":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() === "trial";
        break;

      case "customlearningplan":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.student &&
          firebase.student.status.toLowerCase() === "trial";
        break;

      case "bookingReport":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher";
        break;

      case "meetingReport":
        permission =
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.selectedMode &&
          firebase.selectedMode === "teacher";
        break;

      case "studentLesson":
        permission =
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "virtualClass":
        permission =
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial";
        break;

      case "document":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_M_CURRICULUM_DOC_VIEW,
          firebase
        );
        break;

      case "schoolMessages":
        if (
          firebase &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "studentProfile":
        if (
          firebase &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() !== "trial"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "virtualLibrary":
        if (
          firebase &&
          firebase.schoolConfig &&
          firebase.schoolConfig.classBookingEnabled &&
          firebase.dbName == "GetReadyEdu_Master-Branch" &&
          firebase.student &&
          firebase.student.status &&
          firebase.student.status.toLowerCase() === "active"
        ) {
          permission = true;
          break;
        } else {
          permission = false;
          break;
        }

      case "staffLeaves":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_LEAVES_VIEW,
          firebase
        );
        break;

      case "staffCommunication":
        permission = true;
        break;

      case "complains":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.CONCERN_VIEW,
          firebase
        );
        break;

      case "queryList":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.QUERIES_VIEW_REPLY,
          firebase
        );
        break;

      case "leaves":
        permission = FilterPermission.checkIfPermission(PermissionStrings.LEAVES_VIEW, firebase);
        break;

      case "parentNotes":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.NOTES_VIEW,
          firebase
        );
        break;

      case "applications":
        permission = FilterPermission.checkIfPermission(PermissionStrings.APP_VIEW, firebase);
        break;
      case "admissionProcess":
        permission = FilterPermission.checkIfPermission(PermissionStrings.APP_VIEW, firebase);
        break;
      case "staffActivities":
        permission = true;
        break;

      case "staffMessages":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.STAFF_COMM_MESSAGING,
          firebase
        );
        break;

      case "studentProgram":
        permission =
          firebase && firebase.schoolConfig.roomAttendanceMode
            ? firebase.schoolConfig.roomAttendanceMode
            : false;
        break;

      case "allReports":
        permission = true;
        break;

      case "staffLeaveManagement":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;

      case "leadSettings":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;

      case "feeSetting":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;

      case "emailTemplates":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.SETTINGS_VIEW_CHANGE,
          firebase
        );
        break;

      case "messages":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.QUERIES_VIEW_REPLY,
          firebase
        )
        break;

      case "privateMessages":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.PERSONAL_MESSAGING,
          firebase
        )
        break;

      case "groupMessages":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.GROUP_MESSAGING_VIEW_REPLY,
          firebase
        );
        break;
      case "certificates":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.CERTIFICATE_GENERATION,
          firebase
        );
        break;
      case "idCards":
        permission = FilterPermission.checkIfPermission(
          PermissionStrings.ID_CARD_GENERATION,
          firebase
        );
        break;
      case "tools":
        permission = (FilterPermission.checkIfPermission(
          PermissionStrings.ID_CARD_GENERATION,
          firebase
        ) || FilterPermission.checkIfPermission(
          PermissionStrings.CERTIFICATE_GENERATION,
          firebase
        ))
        break;

      default:
        permission = true;
    }
    return permission;
  }
  getStaffUnreadCount() {
    const { staffQueryList } = this.props;
    let count = 0;
    if (staffQueryList && staffQueryList.length > 0) {
      staffQueryList.forEach((chat) => {
        if (chat.unreadCount && chat.unreadCount > 0) {
          count = count + 1;
        }
      });
    }
    return count;
  }
  getIntMessage(label) {
    const { firebase } = this.props;
    var parentUnreadMsgs = 0;
    if (FilterPermission.checkIfPermission(
      PermissionStrings.QUERIES_VIEW_REPLY,
      firebase
    )) {
      parentUnreadMsgs = this.props.parentUnreadCount;
    }

    let totalUnreadPrivateMessage = 0;
    if (FilterPermission.checkIfPermission(
      PermissionStrings.PERSONAL_MESSAGING,
      firebase
    ) || FilterPermission.checkIfPermission(
      PermissionStrings.GROUP_MESSAGING_VIEW_REPLY,
      firebase
    )) {
      totalUnreadPrivateMessage = this.props.totalUnreadCountPrivateMsg;
    }

    var staffUnreadMsgs = 0;
    if (FilterPermission.checkIfPermission(
      PermissionStrings.STAFF_COMM_MESSAGING,
      firebase
    )) {
      staffUnreadMsgs = this.getStaffUnreadCount();
    }

    let totalCount =
      firebase.selectedMode && firebase.selectedMode.toLowerCase() !== "parent"
        ? totalUnreadPrivateMessage + parentUnreadMsgs
        : totalUnreadPrivateMessage;

    if (label === "sidebar.Messages") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: parentUnreadMsgs ? `(${parentUnreadMsgs})` : undefined,
          }}
        />
      )
    } else if (label === "sidebar.privateMessages") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: totalUnreadPrivateMessage ? `(${totalUnreadPrivateMessage})` : undefined,
          }}
        />
      )
    } else if (label === "sidebar.staffMessage") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: staffUnreadMsgs ? `(${staffUnreadMsgs})` : undefined,
          }}
        />
      )
    } else if (label === "sidebar.staffCommunication") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: staffUnreadMsgs ? `(${staffUnreadMsgs})` : undefined,
          }}
        />
      )
    } else if (label === "sidebar.newMessage") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: totalUnreadPrivateMessage ? `(${totalUnreadPrivateMessage})` : undefined,
          }}
        />
      )
    } else if (label === "sidebar.parentCommunication") {
      return (
        <IntlMessages
          id={label}
          values={{
            count: totalCount ? `(${totalCount})` : undefined,
          }}
        />
      )
    } else {
      return (
        <IntlMessages
          id={label}
          values={{
            count: undefined,
          }}
        />
      )
    }
  }



  //   if (totalCount > 0 && staffUnreadMsgs > 0) {
  //     return (
  //       <IntlMessages
  //         id={label}
  //         values={{
  //           count: totalCount ? `(${totalCount})` : undefined,
  //           staffUnreadMsg: `(${staffUnreadMsgs})`,
  //           parentCount: totalUnreadPrivateMessage ? totalUnreadPrivateMessage : undefined,
  //         }}
  //       />
  //     );
  //   } else if (totalCount === 0 && staffUnreadMsgs > 0) {
  //     return (
  //       <IntlMessages
  //         id={label}
  //         values={{
  //           count: totalCount ? `(${totalCount})` : undefined,
  //           staffUnreadMsg: `(${staffUnreadMsgs})`,
  //           parentCount: totalUnreadPrivateMessage ? totalUnreadPrivateMessage : undefined,
  //         }}
  //       />
  //     );
  //   } else if (totalCount > 0 && staffUnreadMsgs === 0) {
  //     return (
  //       <IntlMessages
  //         id={label}
  //         values={{
  //           count: totalCount ? `(${totalCount})` : undefined,
  //           staffUnreadMsg: undefined,
  //           parentCount: totalUnreadPrivateMessage ? totalUnreadPrivateMessage : undefined,
  //         }}
  //       />
  //     );
  //   } else {
  //     return (
  //       <IntlMessages
  //         id={label}
  //         values={{
  //           count: undefined,
  //           staffUnreadMsg: undefined,
  //           parentCount: undefined,
  //         }}
  //       />
  //     );
  //   }
  // }
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, iconColor, leftImage, labelRightText, childLabelRightText } = singleOption;
    let studentAssessmentTitle = "Student" + " " + AssessmentTerminology.getAssessmentName(this.state.assessmentTerminology);
    let studentBuilderTitle = AssessmentTerminology.getAssessmentName(this.state.assessmentTerminology) + " " + "Builder";
    if (this.checkIfPermission(key)) {
      const url = stripTrailingSlash(this.props.url);
      if (children) {
        return (
          <SubMenu
            key={key}
            title={
              <span className="isoMenuHolder" style={submenuColor}>
                {leftImage ? (
                  <img
                    src={leftImage}
                    alt="icon"
                    style={{
                      height: 20,
                      width: 20,
                      marginRight: dir === "rtl" ? 0 : 20,
                      marginLeft: dir === "rtl" ? 20 : 0,
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <i
                    className={leftIcon}
                    style={{
                      color: iconColor,
                    }}
                  />
                )}
                <span className="nav-text">{key === "studentAssessments" ? studentAssessmentTitle.length > 18 ? studentAssessmentTitle.slice(0, 18) + "..." : studentAssessmentTitle : this.getIntMessage(label)}</span>
                {labelRightText ? <img src={sidebar_new} style={styles.sidebarNewLabel} /> : null}
              </span>
            }
          >
            {children.map((child) => {
              if (this.checkIfPermission(child.key)) {
                const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
                return (
                  <Menu.Item
                    style={{
                      backgroundColor:
                        this.props.app.sidebarOptionColor && this.props.app.sidebarOptionColor == child.key
                          ? colors.v2_Cerulean
                          : "white",
                    }}
                    key={child.key}
                  >
                    <Link
                      style={{
                        color:
                          this.props.app.sidebarOptionColor != child.key
                            ? colors.v2_Cerulean
                            : "white",
                      }}
                      to={linkTo}
                      onClick={() => {
                        // mixpanel.track("sidebar_" + child.key, {
                        //   'schoolName': this.props.firebase.schoolName,
                        //   'userType': this.props.user ? this.props.user.userType : "TEACHER"
                        // })
                        this.props.changeSideBarColor(child.key);
                        setItem("sidebarOptionColor", child.key);
                      }}
                    >
                      {child.key === "assessmentBuilder" ?
                        studentBuilderTitle.length > 18 ?
                          <Tooltip title={studentBuilderTitle}>
                            <span>{studentBuilderTitle.slice(0, 18) + "..."}</span>
                          </Tooltip>
                          :
                          studentBuilderTitle
                        :
                        child.key === "assessmentDashboard" ? AssessmentTerminology.getAssessmentName(this.state.assessmentTerminology) :
                          this.getIntMessage(child.label)}
                    </Link>
                    {child.childLabelRightText ? <img src={sidebar_new} style={styles.sidebarNewLabel} /> : null}
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={key}>
          <Link
            to={key ? `${url}/${key}` : `${url}`}
            onClick={() => {
              // mixpanel.track("sidebar_" + key, {
              //   'schoolName': this.props.firebase.schoolName,
              //   'userType': this.props.user ? this.props.user.userType : "TEACHER"
              // })
              this.props.changeSideBarColor(null);
            }}
          >
            <span className="isoMenuHolder" style={submenuColor}>
              {leftImage ? (
                <img
                  src={leftImage}
                  alt="icon"
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: dir === "rtl" ? 0 : 20,
                    marginLeft: dir === "rtl" ? 20 : 0,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <i
                  className={leftIcon}
                  style={{
                    color: iconColor,
                  }}
                />
              )}
              <span className="nav-text">
                <IntlMessages
                  id={label}
                  values={{
                    count:
                      this.props.totalUnreadCountPrivateMsg + this.props.parentSideUnreadCount
                        ? `(${this.props.totalUnreadCountPrivateMsg + this.props.parentSideUnreadCount})`
                        : undefined,
                  }}
                />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
  };

  getOptions() {
    const { firebase } = this.props;
    const customizedTheme = themes[themeConfig.theme];
    const submenuStyle = {
      backgroundColor: colors.v2_base,
      color: colors.v2_Cerulean,
    };
    const submenuColor = {
      color: colors.v2_Cerulean,
    };

    if (firebase && firebase.selectedMode && firebase.selectedMode.toLowerCase() === "parent") {
      return parentOptions.map((singleOption) =>
        this.getMenuItem({
          submenuStyle,
          submenuColor,
          singleOption,
        })
      );
    } else {
      return options.map((singleOption) =>
        this.getMenuItem({
          submenuStyle,
          submenuColor,
          singleOption,
        })
      );
    }
  }

  render() {
    const { app, toggleOpenDrawer, height, firebase, noDisplay } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      // backgroundColor: customizedTheme.backgroundColor
      backgroundColor: "white",
      display: noDisplay ? "none" : undefined,
    };

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={256}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo
            schoolLogoUrl={
              firebase && firebase.schoolConfig && firebase.schoolConfig.logoUrl
                ? firebase.schoolConfig.logoUrl
                : smallIllumineLogo
            }
            collapsed={collapsed}
            toggleCollapsed={this.props.toggleCollapsed}
          />

          <Scrollbars
            style={{
              height: height - 70,
            }}
          >
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            // defaultOpenKeys={['students']}
            // defaultSelectedKeys={['management']}
            >
              {this.getOptions()}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//       ...state.Learning,
//   };
// }

const a = compose(
  connect(
    (state) => ({
      app: state.App,
      height: state.App.height,
      ...state.Permissions,
      ...state.StaffQueryList,
      ...state.Learning,
    }),
    {
      toggleOpenDrawer,
      changeOpenKeys,
      changeCurrent,
      toggleCollapsed,
      changeSideBarColor,
    }
  ),
  withFirebase
);

export default a(Sidebar);
const styles = {
  sideIcon: {
    height: 20,
    width: 20,
    marginRight: dir === "rtl" ? 0 : 20,
    marginLeft: dir === "rtl" ? 20 : 0,
    objectFit: "contain",
  },
  sidebarNewLabel: {
    width: 35,
    height: 14,
    marginLeft: 10,
    marginRight: 5,
    objectFit: "contain",
  }
};
/*  */
