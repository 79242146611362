// import { connect } from 'react-redux';
import { Spin } from "antd";
import LogRocket from "logrocket";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import componentLoader from "./componentLoader";
import { withFirebase } from "./components/firebase";
import notification from "./components/notification";
import formatMsg from "./components/utility/formatMessageUtil";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import { getItem } from "./Utility/encryptedStorage";
import FilterPermission from "./Utility/FilterPermission";
import PermissionStrings from "./Utility/PermissionStrings";

function hasPermission(path, firebase) {
  path = path.toLowerCase();
  const routePermissionObject = {
    students: PermissionStrings.SM_View_Student_List,
    staff: PermissionStrings.STAFF_M_Staff_List,
    activities: PermissionStrings.POST_ACTIVITY_VIEW,
    studentlevelview: PermissionStrings.POST_ACTIVITY_VIEW,

    newactivity: PermissionStrings.POST_ACTIVITY_VIEW,

    zoomconfiguration: PermissionStrings.POST_ACTIVITY_VIEW,
    zoomplayer: PermissionStrings.POST_ACTIVITY_VIEW,
    studentactivities: PermissionStrings.POST_ACTIVITY_VIEW,

    holiday: PermissionStrings.HOLIDAYS_VIEW,
    lessons: PermissionStrings.LESSON_VIEW,
    lessonplans: PermissionStrings.LESSON_VIEW_W_PLAN,
    lessonview: PermissionStrings.LESSON_VIEW,
    sharehistory: PermissionStrings.LESSON_VIEW,

    studentattendance: PermissionStrings.SM_RECORD_ATTENDANCE,
    staffattendancerecord: PermissionStrings.STAFF_M_RECORD_ATTENDANCE,
    studentbilling: PermissionStrings.BILLING_View,
    studentinvoice: PermissionStrings.BILLING_View,
    credithistory: PermissionStrings.BILLING_View,

    studentdetailedview: PermissionStrings.SM_View_Profile,

    deposithistory: PermissionStrings.BILLING_View,
    totalcredithistory: PermissionStrings.BILLING_View,
    totaldeposithistory: PermissionStrings.BILLING_View,

    invoice: PermissionStrings.BILLING_View,
    feestructure: PermissionStrings.BILLING_View,

    expensemanagement: PermissionStrings.BILLING_View,
    expensedetail: PermissionStrings.BILLING_View,
    expenselogs: PermissionStrings.BILLING_View,
    payments: PermissionStrings.BILLING_View,

    schedule: PermissionStrings.SCHEDULE_VIEW,
    leaves: PermissionStrings.LEAVES_VIEW,
    staffleaves: PermissionStrings.STAFF_LEAVES_VIEW,
    // newstaffleave: PermissionStrings.STAFF_LEAVES_CREATE,
    newstudentleave: PermissionStrings.LEAVES_CREATE || PermissionStrings.LEAVES_EDIT,
    singleconcern: PermissionStrings.CONCERN_VIEW,
    //Notes permission confusion
    querylist: PermissionStrings.QUERIES_VIEW_REPLY,
    singlechat: PermissionStrings.QUERIES_VIEW_REPLY,
    activitysetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    attendancesetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    billingsetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    parenthandbooksetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    cctvsetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    lessonsetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    leavesetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    schooldetails: PermissionStrings.SETTINGS_VIEW_CHANGE,
    permissions: PermissionStrings.SETTINGS_VIEW_CHANGE,
    roles: PermissionStrings.SETTINGS_VIEW_CHANGE,
    othersettings: PermissionStrings.SETTINGS_VIEW_CHANGE,
    assessments: PermissionStrings.ASSESSMENT_VIEW,
    program: PermissionStrings.PROGRAM_View,
    detailedprogram: PermissionStrings.PROGRAM_View,
    studentprogram: PermissionStrings.PROGRAM_View,
    ftereport: PermissionStrings.PROGRAM_View,
    leadsdetailedview: PermissionStrings.LEAD_View,
    newsletter: PermissionStrings.POST_ACTIVITY_VIEW,
    cctvsetting: PermissionStrings.CCTV_CONFIGURE,
    installations: PermissionStrings.INSTALLATION_REPORT_VIEW,
    virtualclass: PermissionStrings.POST_ACTIVITY_VIEW,
    virtualclassmanagement: PermissionStrings.POST_ACTIVITY_VIEW,

    applications: PermissionStrings.APP_VIEW,
    applicationforms: PermissionStrings.APP_VIEW,
    applicationformview: PermissionStrings.APP_VIEW_SUBMISSIONS,

    staffmessages: PermissionStrings.STAFF_COMM_MESSAGING,

    savedassessments: PermissionStrings.ASSESSMENT_VIEW,
    selectedstudentassessment: PermissionStrings.ASSESSMENT_VIEW,
    admissionprocess: PermissionStrings.APP_VIEW,
    creategroup: PermissionStrings.GROUP_CREATE,
    staffleavemanagement: PermissionStrings.SETTINGS_VIEW_CHANGE,
    leadsettings: PermissionStrings.SETTINGS_VIEW_CHANGE,
    feesetting: PermissionStrings.SETTINGS_VIEW_CHANGE,
    emailtemplates: PermissionStrings.SETTINGS_VIEW_CHANGE,
    messages: PermissionStrings.QUERIES_VIEW_REPLY,
    privatemessages: PermissionStrings.PERSONAL_MESSAGING,
    groupmessages: PermissionStrings.GROUP_MESSAGING_VIEW_REPLY,
    allmessageview:PermissionStrings.PERSONAL_MESSAGING_VIEW_ALL_STAFF_MSGS,
    certificates : PermissionStrings.CERTIFICATE_GENERATION,
    idcards : PermissionStrings.ID_CARD_GENERATION,
    parentnotes: PermissionStrings.NOTES_VIEW,
    complains: PermissionStrings.CONCERN_VIEW,
  };
  const permissionMap = new Map(Object.entries(routePermissionObject));
  const parentProfileRoutes = [
    "timeline",
    "comments",
    "virtualclass",
    "studentlesson",
    "schoolmessages",
    "studentinvoices",
    "studentdetailedview",
    "parenthandbook",
    "personalmessanging",
    "parenthandbook",
    "applicationformview",
    "singlelesson",
    "virtuallibrary",
    "contactus",
    "scheduleplaydate",
    "invoiceadditionaldetail",
    "enrollmentdetail",
    "stripepayment",
    "studentinvoices",
    "customlearningplan",
    "pdfviewer",
    "schoolmessages",
    "virtualclass",
    "teachermode",
    "notifications",
    "singleactivity",
    "personalmessage",
    "parentmessages",
    "submissionform",
    "studentsubmission",
    "parentsupport",
  ];
  if (firebase.selectedMode.toLowerCase() == "parent") {
    let pathStringArray = path.split("/");
    if (pathStringArray.length > 2) {
      return parentProfileRoutes.includes(pathStringArray[2].toLowerCase());
    }
  }

  switch (path) {
    case "/dashboard":
      return true;

    default:
      let pathStringArray = path.split("/");
      if (pathStringArray.length > 2) {
        if (permissionMap.has(pathStringArray[2])) {
          if (FilterPermission.checkIfPermission(permissionMap.get(pathStringArray[2]), firebase))
            return true;
          else {
            notification("error", formatMsg("error.permission"));
            return false;
          }
        } else return true;
      }

      return false;
  }
}

const getComponent = (Component, props, firebase) => {
  let loggedIn = false;
  if (JSON.parse(getItem("user"))) {
    loggedIn = true;
  }

  if (loggedIn) {
    let pathPermission = hasPermission(props.location.pathname, firebase);
    if (pathPermission) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: {
              from: props.location,
            },
          }}
        />
      );
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: {
            from: props.location,
          },
        }}
      />
    );
  }
};

const RestrictedRoute = ({ component: Component, firebase, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (process.env.REACT_APP_BUILD_ENV === "production") {
          LogRocket.init("npotwr/112323");
        }

        return getComponent(Component, props, firebase);
      }}
    />
  );
};

const attemptsLeft = 3;
const signinType = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signinType"), attemptsLeft)
);
const phoneSignin = React.lazy(() =>
  componentLoader(() => import("./containers/Page/phoneSignin"), attemptsLeft)
);
const signin = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signin"), attemptsLeft)
);
const userSelector = React.lazy(() =>
  componentLoader(() => import("./containers/Page/userSelector"), attemptsLeft)
);
const signup = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signup"), attemptsLeft)
);
const forgotPassword = React.lazy(() =>
  componentLoader(() => import("./containers/Page/forgotPassword"), attemptsLeft)
);
const thankYou = React.lazy(() =>
  componentLoader(() => import("./containers/Page/thankYou"), attemptsLeft)
);
const signInLink = React.lazy(() =>
  componentLoader(() => import("./containers/Page/signInLink"), attemptsLeft)
);
const requestSignInLink = React.lazy(() =>
  componentLoader(() => import("./containers/Page/requestSignInLink"), attemptsLeft)
);
const godMode = React.lazy(() =>
  componentLoader(() => import("./containers/Page/godMode"), attemptsLeft)
);
const publicForm = React.lazy(() =>
  componentLoader(() => import("./containers/Page/publicForm"), attemptsLeft)
);
const resetPassword = React.lazy(() =>
  componentLoader(() => import("./containers/Page/resetPassword"), attemptsLeft)
);
const addNewCenter = React.lazy(() =>
  componentLoader(() => import("./containers/Page/newCenter"), attemptsLeft)
);

const PublicRoutes = ({ history, firebase }) => {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight / 2,
          }}
        >
          <Spin />
        </div>
      }
    >
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Switch>
            <Route exact path={"/"} component={signinType} />
            <Route exact path={"/dashboard/zoom"} />
            <Route exact path={"/dashboard/firebase-messaging-sw.js"} />
            <Route exact path={"/signin"} component={signinType} />
            <Route exact path={"/phoneSignin"} component={phoneSignin} />
            <Route exact path={"/emailSignin"} component={signin} />
            <Route exact path={"/userType"} component={userSelector} />
            <Route exact path={"/signup"} component={signup} />
            <Route exact path={"/forgotPassword"} component={forgotPassword} />
            <Route exact path={"/thankYou"} component={thankYou} />
            <Route exact path={"/publicForm"} component={publicForm} />
            <Route exact path={"/resetPassword"} component={resetPassword} />

            <RestrictedRoute path="/dashboard" component={App} firebase={firebase} />

            <Route exact path={"/signInLink"} component={signInLink} />
            <Route exact path={"/teacherSigin"} component={signInLink} />
            <Route exact path={"/requestSigninLink"} component={requestSignInLink} />

            <Route exact path={"/godMode"} component={godMode} />
            <Route exact path={"/firebase-messaging-sw.js"} />
            <Route exact path={"/firebase-cloud-messaging-push-scope"} />
            <Route exact path={"/godMode/addNewCenter"} component={addNewCenter} />
            <Route
              exact
              path={"*"}
              component={asyncComponent(() => import("./containers/Page/FourZeroFour"))}
            />
            
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </React.Suspense>
  );
};

function isSignedIn(firebase) {
  var user = JSON.parse(getItem("user"));
  if (user) {
    return true;
  } else {
    return false;
  }
}
export default withFirebase(PublicRoutes);
