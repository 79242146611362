
export default class FilterActionInvoice {
    constructor() { }
}

FilterActionInvoice.getDiscountedAmt = (netAmount, discountObj) => {
    let currentDiscountAmount = 0;
    if (discountObj.discountType.toLowerCase() === "number") {
        currentDiscountAmount = discountObj.value;
    }
    else {
        currentDiscountAmount = (discountObj.value * netAmount) / 100;
    }
    return currentDiscountAmount;

};

FilterActionInvoice.calculateSubTotal = (rows) => {
    let finalAmt = 0;
    for (let lineItem of rows) {
        let netAmount = (lineItem.rate ? lineItem.rate : 0) * (lineItem.quantity ? lineItem.quantity : 0);
        netAmount -= lineItem.discounts && lineItem.discounts.length > 0 ? FilterActionInvoice.getDiscountedAmt(netAmount, lineItem.discounts[0]) : 0;

        lineItem.taxes && lineItem.taxes.map((taxObj) => {
            let taxValue = (taxObj.rate * netAmount) / 100;
            netAmount += taxValue;
        })
        finalAmt += netAmount;
    }
    return finalAmt;
}