import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment";
import { getColor } from "../components";
import FilterAction from "../Utility/FilterAction";
import { eventChannel } from "redux-saga";
import { Switch } from "antd";
function createRegisterUniqueNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/registers").push().key;
  return key;
}

function createExpenseLogUniqueNodeId(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var key = rsf.ref(branchPath + "/expenseLog").push().key;
  return key;
}
function addNewExpenseLog(expenseLog, firebase, key, urls) {
  const rsf = firebase.secondaryDb;
  console.log(expenseLog, urls);

  let branchPath = firebase.sbp;
  let obj = {
    logTitle: expenseLog.title,
    id: key,
    registerId: expenseLog.registerId,
    createdDate: moment().valueOf(),
    createdBy: firebase.teacher.name,
    date: expenseLog.date,
    description: expenseLog.description ? expenseLog.description : "",
    amount: expenseLog.amount,
    fileList: urls,
    label: expenseLog.label ? expenseLog.label : "",
  };

  let newObj = FilterAction.getSuperagentBody(obj, firebase);

  rsf.ref(branchPath + "/expenseLog/" + key).set(newObj);
}
function addNewRegister(register, firebase, key) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {
    registerName: register.name,
    id: key,
    createdDate: moment().valueOf(),
    createdBy: firebase.teacher.name,
    deleted: false,
  };
  let newObj = FilterAction.getSuperagentBody(obj, firebase);

  rsf.ref(branchPath + "/registers/" + key).set(newObj);
}
function getAllRegisters(firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  const myRef = rsf.ref(branchPath + "/registers");

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var registers = [];
      snap.forEach((element) => {
        if (element.val().deleted == false) registers.push(element.val());
      });
      emit(registers);
    });
    return () => {
      myRef.off();

      console.log("unsubscribe Registers");
    };
  });
}

function getExpenseLogs(firebase, startDate, endDate, selectedFrequency, registerId) {
  let startTime = moment(startDate).startOf("day").valueOf();
  let endTime = moment(endDate).endOf("day").valueOf();
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef;
  if (selectedFrequency == "Life Time") {
    myRef = rsf.ref(branchPath + "/expenseLog").orderByChild("date");
  } else {
    myRef = rsf
      .ref(branchPath + "/expenseLog")
      .orderByChild("date")
      .startAt(startTime)
      .endAt(endTime);
  }

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      var logs = [];
      snap.forEach((element) => {
        if (element.val().registerId == registerId) logs.push(element.val());
      });
      emit(logs);
    });
    return () => {
      myRef.off();

      console.log("unsubscribe Expense Logs");
    };
  });
}
function deteteRegister(firebase, id, register) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  let obj = {
    ...register,
    deleted: true,
  };
  rsf.ref(branchPath + "/registers/" + id).set(obj);
}

function deleteExpenseLog(firebase, id) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  rsf.ref(branchPath + "/expenseLog/" + id).set(null);
}

function getExpenseLogOfDateRange(firebase, values, selectedFrequency, registerId) {
  let startTime = moment(values.start).startOf("day").valueOf();
  let endTime = moment(values.end).endOf("day").valueOf();

  let logs = [];
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef;
  if (selectedFrequency && selectedFrequency == "Life Time") {
    myRef = rsf.ref(branchPath + "/expenseLog").orderByChild("date");
  } else {
    myRef = rsf
      .ref(branchPath + "/expenseLog")
      .orderByChild("date")
      .startAt(startTime)
      .endAt(endTime);
  }
  if (!registerId) {
    var promise1 = new Promise(function (resolve, reject) {
      myRef.once("value").then(function (snap) {
        snap.forEach((element) => {
          logs.push(element.val());
        });
        resolve(logs);
      });
    });
    return promise1;
  }
  var promise1 = new Promise(function (resolve, reject) {
    myRef.once("value").then(function (snap) {
      snap.forEach((element) => {
        if (element.val().registerId == registerId) logs.push(element.val());
      });
      resolve(logs);
    });
  });
  return promise1;
}
function getExpenseById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;
  var myRef;

  myRef = rsf.ref(branchPath + "/expenseLog/" + id);

  return eventChannel((emit) => {
    myRef.on("value", (snap) => {
      emit(snap.val());
    });
    return () => {
      myRef.off();

      console.log("unsubscribe Expense Logs");
    };
  });
}
function getRegisterById(id, firebase) {
  const rsf = firebase.secondaryDb;
  let branchPath = firebase.sbp;

  var registerPromise = new Promise(function (resolve, reject) {
    rsf
      .ref(branchPath + "/registers/" + id)
      .once("value")
      .then(function (snapshot) {
        if (snapshot.val() !== null && !snapshot.val().deleted) {
          resolve(snapshot.val());
        } else {
          var data = {};
          resolve(data);
        }
      });
  });
  return registerPromise;
}
function editExpenseLog(firebase, id, expenseLog, urls) {
  const rsf = firebase.secondaryDb;
  console.log(expenseLog, urls);

  let branchPath = firebase.sbp;
  let obj = {
    logTitle: expenseLog.title,
    id,
    registerId: expenseLog.registerId,
    createdDate: expenseLog.createdDate,
    createdBy: firebase.teacher.name,
    date: expenseLog.date,
    description: expenseLog.description ? expenseLog.description : "",
    amount: expenseLog.amount,
    fileList: urls,
    label: expenseLog.label ? expenseLog.label : "",
  };

  let newObj = FilterAction.getSuperagentBody(obj, firebase);

  return rsf.ref(branchPath + "/expenseLog/" + id).set(newObj);
}
function editRegister(firebase, register) {
  const rsf = firebase.secondaryDb;

  let branchPath = firebase.sbp;

  return rsf.ref(branchPath + "/registers/" + register.id).set(register);
}

export const expenseApi = {
  createRegisterUniqueNodeId,
  addNewRegister,
  getAllRegisters,
  createExpenseLogUniqueNodeId,
  addNewExpenseLog,
  getExpenseLogs,
  deteteRegister,
  deleteExpenseLog,
  getExpenseLogOfDateRange,
  editExpenseLog,
  editRegister,
  getExpenseById,
  getRegisterById,
};
