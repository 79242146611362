import actions from "./actions";

const initState = {
    vaccineList: [],
    isLoading: false,
    operationType: undefined,
    studentImmunizationRecordList: [],
    columns: [],
    vaccineTableColumns: [],
    editableRecord: undefined,
    studentLevelExempted: false
};
export default function studentImmunizationReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.FETCH_ALL_VACCINE:
            return {
                ...state,
                isLoading: true,

            };
        case actions.FETCH_ALL_VACCINE_SUCCESS:
            return {
                ...state,
                vaccineList: action.vaccineList,
                vaccineTableColumns: action.vaccineTableColumns,
                isLoading: false,
            };
        case actions.SAVE_VACCINE:
            return {
                ...state,
                isLoading: true
            }
        case actions.SAVE_DOSE:
            return {
                ...state,
                isLoading: true
            }
        case actions.REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                operationType: undefined
            }
        case actions.RESET_OPERATION:
            return {
                ...state,
                operationType: undefined
            }
        case actions.GET_IMMUNIZATION_FOR_STUDENT:
            return {
                ...state,
                isLoading: true
            }
        case actions.GET_IMMUNIZATION_FOR_STUDENT_SUCCESS:
            return {
                ...state,
                studentImmunizationRecordList: action.studentImmunizationRecordList,
                columns: action.columns,
                isLoading: false,
                studentLevelExempted: action.studentLevelExempted
            }
        case actions.UPDATE_STUDENT_VACCINATION:
            return {
                ...state,
                isLoading: true
            }
        case actions.DELETE_VACCINE:
            return {
                ...state,
                isLoading: true
            }
        case actions.SEND_VACCINES_REMINDER:
            return {
                ...state,
                isLoading: true
            }
        case actions.SEND_VACCINES_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case actions.SEND_VACCINE_REMINDER:
            return {
                ...state,
                isLoading: true
            }
        case actions.SEND_VACCINE_REMINDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case actions.RESET_STORE_DATA:
            return {
                ...state,
                vaccineList: [],
                isLoading: false,
                operationType: undefined,
                studentImmunizationRecordList: [],
                columns: [],
                vaccineTableColumns: [],
                editableRecord: undefined,
                studentLevelExempted: false
            }
        case actions.DELETE_DOSE:
            return {
                ...state,
                isLoading: true
            }
        case actions.UPDATE_STUDENT_DOSE:
            return {
                ...state,
                isLoading: true
            }
        case actions.EXEMPT_STUDENT_VACCINES:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}
