import React, { useState } from "react";
import Input from "../../components/uielements/input";
import { Form, Checkbox, Spin, Modal } from "antd";
import colors from "../../Utility/colorFactory";
import webFont from "../../Utility/fontSizes";
import IntlMessages from "../../components/utility/intlMessages";
import formatMsg from "../../components/utility/formatMessageUtil";
import notification from "../../components/notification";
import StudentImmunizationActions from "../../redux/studentImmunization/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "./../../components/firebase/context";

const AddVaccineForm = Form.create()((props) => {
    const { form } = props;
    const { getFieldDecorator } = form;

    const [editableRecord, setEditableRecord] = useState(props.vaccine);

    function handleSubmit() {
        form.validateFields((err, values) => {
            if (err) {
                notification("error", formatMsg("error.allDetail"));
                return;
            }
            if (values.instruction && values.instruction.length == 0) {
                values.instruction = null;
            }
            let operationType = "add";
            if (editableRecord) {
                operationType = "edit";
                values["id"] = editableRecord["vaccineId"];
            }
            const { saveVaccine, firebase } = props;
            saveVaccine(firebase, values, operationType);
            handleCancel();
        });

    }

    function handleCancel() {
        form.resetFields();
        setEditableRecord(undefined);
        props.modalCancel()
    }


    return (
        <Modal
            width="600px"
            height="auto"
            title={formatMsg(editableRecord ? "immunization.editVaccine" : "immunization.addVaccine")}
            visible={props.modalVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText={formatMsg("submit")}
            cancelText={formatMsg("cancel")}
            centered={true}
            maskClosable={false}
        >
            <Spin spinning={props.isLoading}>
                <Form colon={false}>
                    <Form.Item
                        label={
                            <label style={styles.contentLabelStyle}>
                                {<IntlMessages id="immunization.vaccineTitle" />}
                            </label>
                        }
                    >
                        {getFieldDecorator("vaccineName", {
                            initialValue: editableRecord && editableRecord.vaccineName ? editableRecord.vaccineName : "",
                            rules: [
                                {
                                    required: true,
                                    message: formatMsg("cctv.pleaseInput") + " " + formatMsg("immunization.vaccineTitle"),
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={
                            <label style={styles.contentLabelStyle}>
                                {<IntlMessages id="immunization.instruction" />}
                            </label>
                        }
                    >
                        {getFieldDecorator("instruction", {
                            initialValue: editableRecord && editableRecord.instruction ? editableRecord.instruction : undefined,
                            rules: [
                                {
                                    message: formatMsg("cctv.pleaseInput") + " " + formatMsg("immunization.instruction"),
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                    >
                        {getFieldDecorator("mandatory", {
                            initialValue: editableRecord && editableRecord.mandatory ? editableRecord.mandatory : false,
                            valuePropName: "checked"
                        })(
                            <Checkbox style={styles.contentLabelStyle}>{formatMsg("immunization.vaccineMandatory")}</Checkbox>)
                        }
                    </Form.Item>
                </Form>
            </Spin>
        </Modal >
    );
});


function mapStateToProps(state) {
    return {
        ...state.StudentImmunization,
    };
}

const a = compose(
    connect(mapStateToProps, {
        ...StudentImmunizationActions,
    }),
    withFirebase
);

export default a(AddVaccineForm);


const styles = {
    contentLabelStyle: {
        margin: 0,
        color: colors.v2_Cerulean,
        fontSize: webFont.lm,
        fontWeight: "normal",
    },
    cardStyle: {
        width: "80%"
    },
    mainDiv: {
        display: "flex",
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    addDoseBtn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    deleteButtonView: {
        backgroundColor: colors.v2_red_background,
        borderRadius: "8px",
        padding: 10,
        cursor: "pointer",
        width: "40px"
    },
    deleteButton: {
        height: 20,
        width: 20,
        objectFit: "contain",
    },

};