// import { center } from "./reducer";

const actions = {
  LIST_CENTERS: "LIST_CENTERS",
  LIST_CENTER: "LIST_CENTER",
  UPDATE_CENTER: "UPDATE_CENTER",
  UPDATE_CENTER_SUCCESSFUL: "UPDATE_CENTER_SUCCESSFUL",
  ADD_CENTER: "ADD_CENTER",
  ADD_CENTER_SUCCESSFUL: "ADD_CENTER_SUCCESSFUL",
  TOGGLE_CENTER_DATA: "TOGGLE_CENTER_DATA",
  TOGGLE_CENTER_DATA_SUCCESSFUL: "TOGGLE_CENTER_DATA_SUCCESSFUL",
  CENTER_REQUEST_FAILED: "CENTER_REQUEST_FAILED",

  initData: () => ({
    type: actions.LIST_CENTERS,
  }),

  addCenter: (name, address) => ({
    type: actions.ADD_CENTER,
    name,
    address,
  }),

  updateCenters: (updatedName, updatedAddress, docId) => ({
    type: actions.UPDATE_CENTER,
    updatedName,
    updatedAddress,
    docId,
  }),

  updateToggleData: (disableStatus, docId) => ({
    type: actions.TOGGLE_CENTER_DATA,
    disableStatus,
    docId,
  }),
};

export default actions;
