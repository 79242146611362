import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Spin } from "antd";
import LogRocket from "logrocket";
import React from "react";
import ReactDOM from "react-dom";
import packageJson from "./../package.json";
import "./settings/react-confirm-alert.css";

import ErrorBoundary from "./components/ErrorBoundry";
import Firebase, { FirebaseContext } from "./components/firebase";
import DashApp from "./dashApp";
import { getItem } from "./Utility/encryptedStorage";
import mixpanel from "mixpanel-browser";
export var dir = getItem("lng") === "ar" ? "rtl" : "ltr";

import "./styles/antd.css";

Bugsnag.start({
  apiKey: "971dce35c211237450f324f7dd839b3c",
  plugins: [new BugsnagPluginReact()],
  appVersion: packageJson.version,
  releaseStage: process.env.REACT_APP_BUILD_ENV,
  notifyReleaseStages: ["production"],
  enabledReleaseStages: ["production"],
  onError: function (event) {
    event.addMetadata("user", {
      email: getItem("loggedInEmail"),
      schoolName: getItem("schoolName", ""),
      userName: getItem("teacher", ""),
    });
  },
  beforeSend: function (report) {
    report.updateMetaData("LogRocket", {
      sessionURL: LogRocket.sessionURL,
    });
  },
});

const firebasePromise = new Firebase();
mixpanel.init('cc911bfc29dd12d2e3005bc3069982e9', { debug: true, track_pageview: true, persistence: 'localStorage' });


firebasePromise
  .then(function (firebase) {
    if (!firebase) {
      Bugsnag.notify("failed to get firebase --->>>");
    }

    ReactDOM.render(
      <ErrorBoundary>
        <FirebaseContext.Provider value={firebase}>
          <Spin spinning={!firebase ? true : false}>
            <DashApp></DashApp>
          </Spin>
        </FirebaseContext.Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  })
  .catch(function (error) {
    Bugsnag.notify(error);
  });
document
  .getElementsByTagName("html")[0]
  .setAttribute("dir", getItem("lng") === "ar" ? "rtl" : "ltr");
document.documentElement.dir = getItem("lng") === "ar" ? "rtl" : "ltr";

// registerServiceWorker();
// if (process.env.REACT_APP_BUILD_ENV === "development")
//serviceWorker.unregister({});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if(process.env.REACT_APP_BUILD_ENV === "dev")
//     serviceWorker.register();
