import actions from "./actions";
const initState = {
  permissionList: [],
  isLoading: false,
  operationType: undefined,
  permissionListChan: undefined,
  permissionRoles: [],
  permissionTypes: [],
  error: false,
  errorMessage: undefined,
  parentUnreadCount: 0,
  transferSuccess: undefined
};

export default function permissionsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_PERMISSIONS:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        permissionList: [],
        permissionRoles: [],
        permissionTypes: [],
      };

    case actions.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionList: action.permissionList,
        permissionListChan: action.permissionListChan,
        permissionRoles: action.permissionRoles,
        permissionTypes: action.permissionTypes,
        isLoading: false,
        operationType: action.operationType,
      };

    case actions.GET_PERMISSIONS_CALL_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };

    case actions.ADD_ROLE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "add",
      };

    case actions.DELETE_ROLE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "delete",
      };

    case actions.UPDATE_PERMISSIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "update",
      };

    case actions.RESET_PERMISSION_OPERATION_TYPE:
      return {
        ...state,
        operationType: undefined,
        errorMessage: undefined,
        transferSuccess: undefined
      };

    case actions.PERMISSION_REQUEST_FAIL:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actions.GET_PARENT_UNREAD_MESG_COUNT:
      return {
        ...state,
        parentUnreadCount: action.parentUnreadCount,
      };
    
    case actions.TRANSFER_STAFF_ROLE: 
      return {
        ...state,
        isLoading: true,
      }
    
    case actions.TRANSFER_STAFF_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transferSuccess: "SUCCESS"
      }

    case actions.TRANSFER_STAFF_ROLE_FAIL: 
      return {
        ...state,
        isLoading: false,
        transferSuccess: "FAILED",
        errorMessage: action.errorMessage
      }

    default:
      return state;
  }
}
