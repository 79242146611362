import actions from "./actions";
const initState = {
  isLoading: false,
  error: false,
  operationType: undefined,
  emailTemplateChannel: undefined,
  emailTemplateData: [],
  template: undefined,
  customLabelsVal: undefined,
};
export default function emailTemplatesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_EMAIL_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_EMAIL_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        emailTemplateData: action.emailTemplateData,
        emailTemplateChannel: action.emailTemplateChannel,
      };
    case actions.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        emailTemplateData: action.emailTemplateData,
        emailTemplateChannel: action.emailTemplateChannel,
      };

    case actions.ADD_EMAIL:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_EMAIL",
      };

    case actions.DELETE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_TEMPLATE_SUCCESSFUL",
      };
    case actions.UPDATE_EMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_EMAIL_SUCCESSFUL",
      };
    case actions.GET_EMAIL_TEMPLATE_BY_ID_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        template: action.template,
      };
    case actions.GET_LABEL_SUCCESS:
      return {
        ...state,
        customLabelsVal: action.labels,
      };
    case actions.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
