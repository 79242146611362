import { all, takeEvery, put, call, take, fork } from "redux-saga/effects";
import actions from "./actions";
import "@firebase/firestore"; // 👈 If you're using firestore
import { NewsletterApi } from "../../firestore-api/newsletter";
import { StudentApi } from "../../firestore-api/student";
import bugsnagClient from "@bugsnag/js";
import FilterAction from "../../Utility/FilterAction";
import { getItem } from "../../Utility/encryptedStorage";
import formatMsg from "../../components/utility/formatMessageUtil";
import { callApi } from "../../Utility/superAgentUntil";
import { notification } from "../../components";
import moment from "moment";
import FilterPermission from "../../Utility/FilterPermission";
import PermissionStrings from "../../Utility/PermissionStrings";

export function* saveNewsletter({ newsletter, firebase, container, dbName }) {
  try {
    if(container){
      let canvaObject = yield call(NewsletterApi.getCanvaFromNewsletter,newsletter, container);
      if(canvaObject){
        let thumbnail = yield call(NewsletterApi.uploadCanvasImage,canvaObject,firebase,container );
        if(thumbnail){
          newsletter.imageBanner = thumbnail;
        }
      }
    }
    var createdOn = moment().valueOf();
    var modifiedOn = moment().valueOf();
    const newsletter1 = {
      createdOn: createdOn,
      modifiedOn: modifiedOn,
      name: newsletter.name,
      inverseDate: -new Date(),
      createdBy: firebase.teacher.name,
      status: newsletter.status,
      html: newsletter.html,
      json: newsletter.json,
      oldStatus: newsletter.oldStatus ? newsletter.oldStatus : null,
      id: newsletter.id ? newsletter.id : null,
      studentIds: newsletter.studentIds && newsletter.status==="sent" ? newsletter.studentIds : null,
      imageBanner: newsletter.imageBanner ? newsletter.imageBanner : null,
      activityId: newsletter.activityId ? newsletter.activityId : null
    }
    if(newsletter.status.toLowerCase() === "sent") {
      return newsletter1;
    }
    dbName = dbName ? dbName : firebase.sbDbName
    let endpoint = "woodlandApi/newsletterV2?centerId=" + dbName;
    let response = yield call(callApi, firebase, "post", endpoint, newsletter1);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.SAVE_NEWSLETTER_SUCCESS,
      })
    } else {
      yield put({
        type: actions.SAVE_NEWSLETTER_FAILED,
      })
      notification("error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("Error in newsletter"));
    }
  } catch (err) {
    yield put({
      type: actions.SAVE_NEWSLETTER_FAILED
    })
    console.log("errrrrrrrrr", err, newsletter);
    notification("error", err.toString());
    bugsnagClient.notify(err);
  }
}

export function* deleteNewsletter({id, firebase}){
  try{
    let endpoint = "woodlandApi/newsletterV2?centerId=" + firebase.sbDbName;
    let response = yield call(callApi, firebase, "delete", endpoint, {id});

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_NEWSLETTER_SUCCESS,
      })
      notification("success", "Deleted Successfully")
    } else {
      yield put({
        type: actions.DELETE_NEWSLETTER_FAILED,
      })
      notification("error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("Error Deleting in newsletter"));
      }
  } catch (err) {
    yield put({
      type: actions.DELETE_NEWSLETTER_FAILED
    })
    notification("error", err.toString());
    bugsnagClient.notify(err);
  }
}

export function* getStudentList({ firebase }) {
  try {
    // let data = yield call(AssessmentApi.getAllStudents, firebase);
    let data = FilterAction.getStudentList(firebase);
    if (data) {
      yield put({
        type: actions.GET_STUDENT_DATA_SUCCESSFUL,
        studentList: data,
        autoCompleteClassroom: [],
      });
    }
  } catch (error) {
    console.log("failed to fetch students for newsletter", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
    });
  }
}

export function* getStudentListByCenterName({ centerName, option }) { }

export function* getStudentListByRoomName({ roomName, option }) { }

export function* getSearchedStudent({ studentName }) { }

export function* sendNewsletter({newsletter, firebase, container}){
  try{
    let classrooms = newsletter.selectedClassrooms;
    let groups = newsletter.selectedGroups;
    let studentStatus = newsletter.selectedStatus.length>0 ? newsletter.selectedStatus : ["students.active"];
    let studentIds =[];
    let allStudents = FilterAction.getStudentList(firebase);

    for (let i = 0; i < allStudents.length; i++) {
      let student = allStudents[i];
      let classroomsMatch = true;
      let statusMatch = true;
      let groupsMatch = groups.length===0;
      
      classroomsMatch = classrooms.some(selectedClass => student.classList.includes(selectedClass));

      if (studentStatus.length > 0) {
        const statusMap = {"students.active": "Active", "students.onHold": "On Hold", "students.signedUp": "Signed Up"};
        statusMatch = studentStatus.some(status => {
          return student.status.toLowerCase() === statusMap[status].toLowerCase() && !student.deactivated;
        });
      }

      if (groups.length > 0) {
        const studGrp = student.tags ? student.tags.map(t => t.id) : [];
        for (const selectedGroup of groups) {
          if (studGrp.includes(selectedGroup.id)) {
            groupsMatch = true;
            break;
          }
        }
      }

      if (classroomsMatch && statusMatch && groupsMatch && !student.deactivated) {
        studentIds.push(student.id);
      }
    }
    
    studentIds = Array.from(new Set(studentIds));

    const activityDate = new Date()
    const date = {
      date: activityDate.getDate(),
      day: activityDate.getDay(),
      hours: activityDate.getHours(),
      minutes: activityDate.getMinutes(),
      month: activityDate.getMonth(),
      seconds: activityDate.getSeconds(),
      time: activityDate.getTime(),
      timezoneOffset: activityDate.getTimezoneOffset(),
      year: activityDate.getFullYear(),
    }
    var newClassroom = [];
    newsletter.selectedClassrooms.forEach((item) => {
      newClassroom.push(item);
    });
    let newsletterObj = yield saveNewsletter({ newsletter, firebase, container });
    let sendNewsletterObj = {
      sendActivityObj : {
        activityType: "Newsletter",
        approved: FilterPermission.checkIfPermission(
          PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
          firebase
        ),
        email: true,
        classNames: newClassroom,
        createdBy: firebase.teacher.name,
        date: date,
        dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
        deleted: false,
        dirty: false,
        html: newsletter.html,
        inverseDate: -activityDate.getTime(),
        likesCount: 0,
        longDate: 0,
        message: newsletter.name,
        name: "Newsletter",
        staffOnly: false,
        teacherId: firebase.teacher.id,
        studentIds: studentIds,
      },
      newsletterObj
    }
    if(studentIds.length > 0){
      let endpoint = "woodlandApi/saveActivity?centerId=" + firebase.sbDbName;
      let response = yield call(callApi, firebase, "post", endpoint, sendNewsletterObj);
      if (response && response.status && response.status === 200) {
        yield put({
          type: actions.SEND_NEWSLETTER_SUCCESS,
        })
      } else {
        notification("error",
          response && response.body && response.body.response
            ? response.body.response
            : formatMsg("Error in sending newsletter"));
        yield put({
          type: actions.NEWSLETTER_REQUEST_FAILED,
          errorInfo: "unable to send",
        });
      }
    } else {
      yield put({
        type: actions.NEWSLETTER_REQUEST_FAILED,
        errorInfo: formatMsg("newsletter.noStudents"),
      });
    }
  } catch(err) {
    console.log(err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: formatMsg("settings.errorSettingsSaved"),
    });
  }
}

function* uploadImageToStorage({ fileUrl, firebase, newsletterType }) {
  try {
    let url = yield call(NewsletterApi.uploadImageAttachment, fileUrl, firebase);
    if (url) {
      yield put({
        type: actions.FILE_UPLOAD_COMPLETED,
        uploadFileUrl: url,
        newsletterType: newsletterType,
      });
    }
  } catch (error) {
    console.log("failed to upload newsletter image attachment", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: error,
    });
  }
}

function* getAllClassrooms({ firebase }) {
  try {
    let data = JSON.parse(getItem("classList"));
    if (data) {
      yield put({
        type: actions.FETCH_CLASSROOM_SUCCESSFUL,
        classrooms: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch all classroom", error);
    bugsnagClient.notify(error);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
    });
  }
}

export function* sendNewsletterAllCenter({newsletter, firebase, filteredBranch, selectedStatus, container}){
  try{
    let newBranches = firebase.teacher.newBranches;
    let centerClasses = JSON.parse(getItem("classmap"));
    let branches = filteredBranch;
    let tempBranchMap = new Map();
    let sentToAllBranhces = true;
    let failedBranches = [];
    selectedStatus = selectedStatus.length>0 ? selectedStatus : ["students.active"]
    for (let i in branches) {
      let branchClassName = branches[i];
      if (centerClasses[branchClassName]) {
        let allClasses = centerClasses[branchClassName];
        tempBranchMap.set(branchClassName, allClasses);
      } else {
        let singleClass = branchClassName.split("*")[0];
        let branchName = branchClassName.split("*")[1];
        if (tempBranchMap.has(branchName)) {
          let allClasses = tempBranchMap.get(branchName);
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        } else {
          let allClasses = [];
          allClasses.push({
            className: singleClass,
          });
          tempBranchMap.set(branchName, allClasses);
        }
      }
    }

    for (let [key, val] of tempBranchMap) {
      let bPath = key;
      let dbName;
      let databaseName = newBranches.filter((nb) => {
        return nb.name === bPath;
      });

      if (databaseName && databaseName.length > 0) {
        dbName = databaseName[0].dbName;
      }
      

      newsletter.selectedClassrooms = val;
      var classrooms = val;
      let studentIds = [];
      const statusMap = {"active": "students.active", "on hold": "students.onHold", "signed up": "students.signedUp"};
      for (let i = 0; i < classrooms.length; i++) {
        let student = yield call(
          StudentApi.getStudentByClassroomName,
          classrooms[i].className,
          firebase,
          bPath
        );

        if (student && student.length > 0) {
          for (let index of student) {
            if(!index.deactivated && selectedStatus.includes(statusMap[index.status.toLowerCase()])){
              studentIds.push(index.id);
            }
          }
        }
      }
      if (studentIds.length > 0) {

        const activityDate = new Date()
        const date = {
          date: activityDate.getDate(),
          day: activityDate.getDay(),
          hours: activityDate.getHours(),
          minutes: activityDate.getMinutes(),
          month: activityDate.getMonth(),
          seconds: activityDate.getSeconds(),
          time: activityDate.getTime(),
          timezoneOffset: activityDate.getTimezoneOffset(),
          year: activityDate.getFullYear(),
        }
        var newClassroom = [];
        newsletter.selectedClassrooms.forEach((item) => {
          newClassroom.push(item.className);
        });
        let newsletterObj = yield saveNewsletter({ newsletter, firebase, container });
        let sendNewsletterObj = {
          sendActivityObj : {
            activityType: "Newsletter",
            approved: FilterPermission.checkIfPermission(
              PermissionStrings.POST_ACTIVITY_POST_WITHOUT_APPROVAL,
              firebase
            ),
            email: true,
            classNames: newClassroom,
            createdBy: firebase.teacher.name,
            date: date,
            dateString: moment(activityDate).format("YYYY[-]MM[-]DD"),
            deleted: false,
            dirty: false,
            html: newsletter.html,
            inverseDate: -activityDate.getTime(),
            likesCount: 0,
            longDate: 0,
            message: newsletter.name,
            name: "Newsletter",
            staffOnly: false,
            teacherId: firebase.teacher.id,
            studentIds: studentIds,
          },
          newsletterObj
        }
        newsletter = {
          ...newsletter,
          studentIds: studentIds
        }
        let endpoint = "woodlandApi/saveActivity?centerId=" + dbName;
        let response = yield call(callApi, firebase, "post", endpoint, sendNewsletterObj);
        if (response && response.status && response.status === 200) {
          sentToAllBranhces = sentToAllBranhces && response.status === 200
        } else {
          sentToAllBranhces = false
          failedBranches.push(branches[i])
        }
      }
    }
    if(sentToAllBranhces){
      yield put({
        type: actions.SEND_NEWSLETTER_ALL_CENTER_SUCCESS,
      });
    } else {
      yield put({
        type: actions.NEWSLETTER_REQUEST_FAILED,
        errorInfo: formatMsg("error.newsletterDidntSentToAllBranches")+failedBranches.join(", "),
      });
    }
  } catch(err) {
    console.log(err);
    bugsnagClient.notify("Failed to send newsletter ---->>>>" + err.message ? err.message : err);
    yield put({
      type: actions.NEWSLETTER_REQUEST_FAILED,
      errorInfo: formatMsg("settings.errorSettingsSaved"),
    });
  }
}

export function* getAllNewsletters({ firebase }) {
  let chan = yield call(NewsletterApi.getAllNewsLetterV2, firebase);
  try {
    while (true) {
      let data = yield take(chan);
      yield put({
        type: actions.GET_ALL_NEWSLETTER_SUCCESS,
        allNewsletters: data,
        allNewslettersChannel: chan,
      });
    }
  } finally {
    console.log("end tag channel");
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.SAVE_NEWSLETTER, saveNewsletter),
    yield takeEvery(actions.GET_STUDENT_BY_ROOM_NAME, getStudentListByRoomName),
    yield takeEvery(actions.GET_STUDENT_BY_CENTER_NAME, getStudentListByCenterName),
    yield takeEvery(actions.GET_SEARCHED_STUDENT, getSearchedStudent),
    yield takeEvery(actions.GET_STUDENT_DATA, getStudentList),
    yield takeEvery(actions.SEND_NEWSLETTER, sendNewsletter),
    yield takeEvery(actions.FILE_UPLOAD_START, uploadImageToStorage),
    yield takeEvery(actions.FETCH_CLASSROOM, getAllClassrooms),
    yield takeEvery(actions.SEND_NEWSLETTER_ALL_CENTER, sendNewsletterAllCenter),
    yield takeEvery(actions.GET_ALL_NEWSLETTER, getAllNewsletters),
    yield takeEvery(actions.DELETE_NEWSLETTER, deleteNewsletter),
  ]);
}
