const actions = {
  GET_QUERYLIST: "GET_QUERYLIST",
  GET_QUERYLIST_SUCCESS: "GET_QUERYLIST_SUCCESS",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  QUERYLIST_REQUEST_FAILED: "QUERYLIST_REQUEST_FAILED",

  RESET_QUERYLIST_OPERATION: "RESET_QUERYLIST_OPERATION",

  GET_UNREAD_MESSAGE_COUNT: "GET_UNREAD_MESSAGE_COUNT",

  GET_PARENT_CHAT: "GET_PARENT_CHAT",
  GET_PARENT_CHAT_SUCCESS: "GET_PARENT_CHAT_SUCCESS",

  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",

  RESET_TEACHER_UNREAD_COUNT: "RESET_TEACHER_UNREAD_COUNT",

  GET_SCHOOL_MSG: "GET_SCHOOL_MSG",
  GET_SCHOOL_MSG_SUCCESS: "GET_SCHOOL_MSG_SUCCESS",

  SEND_SCHOOL_MSG: "SEND_SCHOOL_MSG",
  SEND_SCHOOL_MSG_SUCCESS: "SEND_SCHOOL_MSG_SUCCESS",

  GET_STAFF_QUERYLIST: "GET_STAFF_QUERYLIST",
  GET_STAFF_QUERYLIST_SUCCESS: "GET_STAFF_QUERYLIST_SUCCESS",

  GET_TEACHER_CHAT: "GET_TEACHER_CHAT",
  GET_TEACHER_CHAT_SUCCESS: "GET_TEACHER_CHAT_SUCCESS",
  GET_TEACHER_CHATLIST: "GET_TEACHER_CHATLIST",
  GET_TEACHER_CHATLIST_SUCCESS: "GET_TEACHER_CHATLIST_SUCCESS",

  SEND_TEACHER_MESSAGE: "SEND_TEACHER_MESSAGE",
  SEND_TEACHER_MESSAGE_SUCCESS: "SEND_TEACHER_MESSAGE_SUCCESS",

  RESET_STAFF_CHAT_UNREAD_COUNT: "RESET_STAFF_CHAT_UNREAD_COUNT",
  GET_PRIVATE_QUERYLIST: "GET_PRIVATE_QUERYLIST",
  GET_PRIVATE_QUERYLIST_SUCCESS: "GET_PRIVATE_QUERYLIST_SUCCESS",
  GET_CHAT: "GET_CHAT",
  GET_CHAT_SUCCESS: "GET_CHAT_SUCCESS",
  SEND_CHAT_MESSAGE: "SEND_CHAT_MESSAGE",
  SEND_CHAT_MESSAGE_SUCCESS: "SEND_CHAT_MESSAGE_SUCCESS",
  DELETE_PRIVATE_MESSAGE: "DELETE_PRIVATE_MESSAGE",
  GET_TEACHERS: "GET_TEACHERS",
  GET_TEACHERS_SUCCESS: "GET_TEACHERS_SUCCESS",
  SEND_MESSAGE_TO_TEACHER: "SEND_MESSAGE_TO_TEACHER",
  SEND_MESSAGE_TO_TEACHER_SUCCESS: "SEND_MESSAGE_TO_TEACHER_SUCCESS",
  GET_PARENT_CHATLIST: "GET_PARENT_CHATLIST",
  GET_PARENT_CHATLIST_SUCCESS: "GET_PARENT_CHATLIST_SUCCESS",
  CREATE_GROUP: "CREATE_GROUP",
  CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
  SETLOADING: "SETLOADING",
  FETCH_GROUP_DETAILS: "FETCH_GROUP_DETAILS",
  FETCH_GROUP_DETAILS_SUCCESS: "FETCH_GROUP_DETAILS_SUCCESS",
  GET_GROUP: "GET_GROUP",
  GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
  SET_SHOW_CHAT: "SET_SHOW_CHAT",
  SET_SHOW_CHAT_SUCCESS: "SET_SHOW_CHAT_SUCCESS",
  RESET_GROUP_CREATED: "RESET_GROUP_CREATED",
  RESET_UNREAD_COUNT: "RESET_UNREAD_COUNT",
  ADD_CONVERSATIONID: "ADD_CONVERSATIONID",
  ADD_CONVERSATIONID_SUCCESS: "ADD_CONVERSATIONID_SUCCESS",
  GET_SELECTED_GROUP: "GET_SELECTED_GROUP",
  GET_SELECTED_GROUP_SUCCESS: "GET_SELECTED_GROUP_SUCCESS",
  GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE: "GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE",
  GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE_SUCCESS: "GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE_SUCCESS",
  GET_UNREAD_COUNT_PARENT_SIDE_SUCCESS: "GET_UNREAD_COUNT_PARENT_SIDE_SUCCESS",
  GET_PARTICIPANT_IDS: "GET_PARTICIPANT_IDS",
  GET_PARTICIPANT_IDS_SUCCESS: "GET_PARTICIPANT_IDS_SUCCESS",

  GET_READ_ONLY_PRIVATE_QUERY_LIST:"GET_READ_ONLY_PRIVATE_QUERY_LIST",
  GET_READ_ONLY_PRIVATE_QUERY_LIST_SUCCESS:"GET_READ_ONLY_PRIVATE_QUERY_LIST_SUCCESS",

  REFETCH_READ_COUNT_DATA:"REFETCH_READ_COUNT_DATA",

  getTotalUnreadCountPrivateMessage: (firebase) => ({
    type: actions.GET_TOTAL_UNREAD_COUNT_PRIVATE_MESSAGE,
    firebase,
  }),
  getParticipantsIds: (firebase, groupId) => ({
    type: actions.GET_PARTICIPANT_IDS,
    firebase,
    groupId: groupId,
  }),
  getSelectedGroup: (firebase, chatList) => ({
    type: actions.GET_SELECTED_GROUP,
    firebase,
    chatList,
  }),
  resetUnreadCount: (firebase, chatListId) => ({
    type: actions.RESET_UNREAD_COUNT,
    firebase,
    chatListId,
  }),
  addConversationId: (chatModel, firebase) => ({
    type: actions.ADD_CONVERSATIONID,
    chatModel,
    firebase,
  }),
  resetGroupCreated: (payload) => ({
    type: actions.RESET_GROUP_CREATED,
    payload,
  }),
  isReplyToShow: (firebase, chatList) => ({
    type: actions.SET_SHOW_CHAT,
    firebase,
    chatList,
  }),
  getGroupById: (firebase, groupId) => ({
    type: actions.GET_GROUP,
    firebase,
    groupId,
  }),

  fetchGroupDetails: (firebase, selectedChat) => ({
    type: actions.FETCH_GROUP_DETAILS,
    firebase,
    selectedChat,
  }),
  getQueryList: (firebase) => ({
    type: actions.GET_QUERYLIST,
    firebase,
  }),
  getPrivateQueryList: (firebase) => ({
    type: actions.GET_PRIVATE_QUERYLIST,
    firebase,
  }),
  createGroup: (group, firebase, fileList, attachedFilesUrl, edit, groupId) => ({
    type: actions.CREATE_GROUP,
    group,
    firebase,
    fileList,
    attachedFilesUrl,
    edit,
    groupId,
  }),
  setLoading: (bool) => ({
    type: actions.SETLOADING,
    bool,
  }),
  resetQueryOperation: () => ({
    type: actions.RESET_QUERYLIST_OPERATION,
  }),
  getTeacherList: (firebase) => {
    return {
      type: actions.GET_TEACHERS,
      firebase,
    };
  },

  getParentChat: (parentId, firebase) => ({
    type: actions.GET_PARENT_CHAT,
    parentId,
    firebase,
  }),
  getChat: (conversationId, firebase, chatListModel) => ({
    type: actions.GET_CHAT,
    conversationId,
    firebase,
    chatListModel,
  }),
  getTeacherChatlist: (firebase, studentId, parentId) => ({
    type: actions.GET_TEACHER_CHATLIST,
    firebase,
    studentId,
    parentId,
  }),

  sendMessage: (message, parentId, chatListModel, firebase, img) => ({
    type: actions.SEND_MESSAGE,
    message,
    parentId,
    chatListModel,
    firebase,
    img,
  }),
  sendChatMessage: (message, chatListModel, firebase, img, participantIds) => ({
    type: actions.SEND_CHAT_MESSAGE,
    message,
    chatListModel,
    firebase,
    img,
    participantIds,
  }),
  sendMsgToTeacher: (message, teacherId, chatListModel, firebase, img, teacher) => ({
    type: actions.SEND_MESSAGE_TO_TEACHER,
    message,
    teacherId,
    chatListModel,
    firebase,
    img,
    teacher,
  }),
  resetTeacherUnreadCount: (parentId, firebase) => ({
    type: actions.RESET_TEACHER_UNREAD_COUNT,
    parentId,
    firebase,
  }),
  getParentChatList: (firebase) => ({
    type: actions.GET_PARENT_CHATLIST,
    firebase,
  }),
  getSchoolMessage: (parentId, student, firebase) => ({
    type: actions.GET_SCHOOL_MSG,
    parentId,
    student,
    firebase,
  }),

  sendSchoolMessage: (message, parentId, firebase, img) => ({
    type: actions.SEND_SCHOOL_MSG,
    message,
    parentId,
    firebase,
    img,
  }),
  deleteMessage: (firebase, msg, item, mode, isLatestMessage) => ({
    type: actions.DELETE_MESSAGE,
    firebase,
    msg,
    item,
    mode,
    isLatestMessage,
  }),
  deletePrivateMessage: (firebase, msg, item, mode, isLatestMessage) => ({
    type: actions.DELETE_PRIVATE_MESSAGE,
    firebase,
    msg,
    item,
    mode,
    isLatestMessage,
  }),
  getStaffQueryList: (firebase) => ({
    type: actions.GET_STAFF_QUERYLIST,
    firebase,
  }),

  getTeacherChat: (item, firebase) => ({
    type: actions.GET_TEACHER_CHAT,
    item,
    firebase,
  }),

  sendTeacherMessage: (message, chatListModel, firebase, img) => ({
    type: actions.SEND_TEACHER_MESSAGE,
    message,
    chatListModel,
    firebase,
    img,
  }),

  resetStaffChatUnreadCount: (chatList, firebase) => ({
    type: actions.RESET_STAFF_CHAT_UNREAD_COUNT,
    chatList,
    firebase,
  }),

  getReadOnlyPrivateQueryList: (firebase, teacherId) => ({
    type:actions.GET_READ_ONLY_PRIVATE_QUERY_LIST,
    firebase,
    teacherId
  }),

  refetchReadCountData: (queryList, firebase) => ({
    type:actions.REFETCH_READ_COUNT_DATA,
    queryList,
    firebase
  })
};
export default actions;
