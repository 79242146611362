import "@firebase/firestore"; // 👈 If you're using firestore
import { all, call, put, takeLatest, take, fork } from "redux-saga/effects";
import { StudentApi } from "../../firestore-api/student";
import { ScheduleApi } from "../../firestore-api/schedule";
import actions from "./actions";
import moment from "moment";
import bugsnagClient from "@bugsnag/js";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import formatMsg from "../../components/utility/formatMessageUtil";
import {
  getItem,
  setItem,
  removeItem,
  clear,
} from "../../Utility/encryptedStorage";
import notification from "../../components/notification";

function* fetchSchedules({ date, firebase }) {
  const chan = yield call(ScheduleApi.getScheduleByDate, date, firebase);
  try {
    while (true) {
      let data = yield take(chan);

      yield put({
        type: actions.GET_SCHEDULES_SUCCESSFUL,
        schedules: data,
        schedulesChannel: chan,
      });
    }
  } finally {
    console.log("end schedule channel");
  }
}

function* deleteMultipleDaysSchedule({ values, firebase }) {
  try {
    let url = "woodlandApi/deleteSchedules/" + "?centerId=";
    let response = yield call(
      StudentAttendanceApi.deleteApiWithObj,
      values,
      url,
      firebase
    );
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_MULTIPLEDAYS_SCHEDULE_SUCCESSFUL,
        numberOfSchedulesUpdated: response.body.response,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteSchedule")
      );
      yield put({
        type: actions.SCHEDULE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* fetchScheduleClassrooms({ firebase }) {
  try {
    let classroomData = JSON.parse(getItem("classList"));
    if (classroomData) {
      yield put({
        type: actions.GET_SCHEDULE_CLASSROOM_SUCCESSFUL,
        classrooms: classroomData,
      });
    }
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* deleteScheduleItem({ scheduleItem, date, className, firebase }) {
  let tempClassName;
  if (className.toLowerCase() === "all classrooms") {
    tempClassName = scheduleItem.classname;
  } else {
    tempClassName = className;
  }
  try {
    let obj = {};
    let url = "woodlandApi/deleteSelectedSchedule/" + "?centerId=";
    obj["id"] = scheduleItem.id;
    obj["startDate"] = date;
    obj["classroom"] = tempClassName;

    let response = yield call(
      StudentAttendanceApi.deleteApiWithObj,
      obj,
      url,
      firebase
    );

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_SCHEDULE_ITEM_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteSchedule")
      );
      yield put({
        type: actions.SCHEDULE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* addScheduleItem({ values, firebase }) {
  try {
    let url = "woodlandApi/addSchedule/" + "?centerId=";
    let obj = {
      classrooms: values.classroom,
      type: values.type,
      title: values.title,
      description: values.description,
      startTime: values.startTime,
      endTime: values.endTime ? values.endTime : null,
      startDate: values.startDate,
      endDate: values.endDate ? values.endDate : values.startDate,
      daysOfWeek: values.daysOfWeek,
      taggedTeachers: values.taggedTeachers,
    };
    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      url,
      firebase
    );

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.ADD_SCHEDULE_SUCCESSFUL,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addSchedule")
      );
      yield put({
        type: actions.SCHEDULE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to add schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* repeatScheduleForWeek(
  className,

  type,
  title,
  desc,
  time,
  firebase,
  start,
  end,
  daysOfWeek,
  teachers
) {
  let weekArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  if (!daysOfWeek) {
    daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  }
  var now = start.clone();
  while (now.isSameOrBefore(end)) {
    if (daysOfWeek.includes(weekArr[now.day()])) {
      let node = yield call(
        ScheduleApi.createNewScheduleNode,
        className,
        new Date(moment(now).format("YYYY-MM-DD")),
        firebase
      );
      yield call(
        ScheduleApi.addSchedule,
        className,
        moment(now).format("YYYY-MM-DD"),
        type,
        title,
        desc,
        time,
        node,
        firebase,
        teachers
      );
    }
    now.add(1, "days");
  }
}

function* repeatScheduleForWeekForAllClasses(
  classrooms,

  type,
  title,
  desc,
  startTime,
  endTime,
  firebase,
  start,
  end,
  daysOfWeek,
  teachers
) {
  let weekArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  if (!daysOfWeek) {
    daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  }

  let classes = classrooms;
  for (let i in classes) {
    var now = start.clone();
    if (!end) {
      let node = yield call(
        ScheduleApi.createNewScheduleNode,
        classes[i],
        new Date(moment(now).format("YYYY-MM-DD")),
        firebase
      );
      yield call(
        ScheduleApi.addSchedule,

        classes[i],
        moment(now).format("YYYY-MM-DD"),
        type,
        title,
        desc,
        startTime,
        endTime,
        node,
        firebase,
        teachers,
        moment(start).format("YYYY-MM-DD"),
        moment(start).format("YYYY-MM-DD")
      );
    } else {
      while (now.isSameOrBefore(end)) {
        if (daysOfWeek.includes(weekArr[now.day()])) {
          let node = yield call(
            ScheduleApi.createNewScheduleNode,
            classes[i],
            new Date(moment(now).format("YYYY-MM-DD")),
            firebase
          );
          yield call(
            ScheduleApi.addSchedule,

            classes[i],
            moment(now).format("YYYY-MM-DD"),
            type,
            title,
            desc,
            startTime,
            endTime,
            node,
            firebase,
            teachers,
            moment(start).format("YYYY-MM-DD"),
            moment(end).format("YYYY-MM-DD")
          );
        }
        now.add(1, "days");
      }
    }
  }
}
function* editScheduleItem({
  values,
  selectedClass,
  editableRecord,
  firebase,
}) {
  try {
    let url = "";
    let obj = {};
    if (values.classroom) {
      url = "woodlandApi/editSchedule/" + "?centerId=";

    }
    else {
      url = "woodlandApi/editSelectedSchedule/" + "?centerId=";
    }
    obj = {
      values: values,
      editableRecord: editableRecord,
    };
    let response = yield call(
      StudentAttendanceApi.requestApi,
      obj,
      url,
      firebase
    );
    if (response && response.status && response.status === 200) {

      if (response.body) {
        yield put({
          type: actions.EDIT_SCHEDULE_SUCCESSFUL,
          numberOfSchedulesUpdated: response.body.response ? response.body.response : null,
        });
      }
      else {
        yield put({
          type: actions.EDIT_SELETCED_SCHEDULE_SUCCESSFUL
        });
      }

    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.addSchedule")
      );
      yield put({
        type: actions.SCHEDULE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to edit schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

function* fetchAllActiveActivities({ firebase, initVal }) {
  try {
    let data = yield call(ActivityApi.getActivitySetting, firebase);

    if (data) {
      yield put({
        type: actions.GET_ACTIVE_ACTIVITY_SUCCESS,
        activeActivities: data,

      });
    }
  } catch (err) {
    console.log("failed to fetch active activities", err);
    bugsnagClient.notify(err);
  }
}

function* deleteAllSchedule({ formData, firebase }) {
  try {
    let obj = {};
    obj.classroom = formData.classList;
    obj.startDate = moment(formData.dateRange[0]);

    obj.endDate = moment(formData.dateRange[1]);

    let url = "woodlandApi/deleteAllSchedules/" + "?centerId=";
    let response = yield call(
      StudentAttendanceApi.deleteApiWithObj,
      obj,
      url,
      firebase
    );
    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.DELETE_MULTIPLEDAYS_SCHEDULE_SUCCESSFUL,
        numberOfSchedulesUpdated: response.body.response,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("error.deleteSchedule")
      );
      yield put({
        type: actions.SCHEDULE_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to delete schedule", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.SCHEDULE_REQUEST_FAILED,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_SCHEDULES, fetchSchedules),
    yield takeLatest(actions.GET_SCHEDULE_CLASSROOM, fetchScheduleClassrooms),
    yield takeLatest(actions.DELETE_SCHEDULE_ITEM, deleteScheduleItem),
    yield takeLatest(actions.ADD_SCHEDULE, addScheduleItem),
    yield takeLatest(actions.EDIT_SCHEDULE, editScheduleItem),
    yield takeLatest(actions.DELETE_MULTIPLEDAYS_SCHEDULE, deleteMultipleDaysSchedule),
    yield takeLatest(actions.GET_ACTIVE_ACTIVITY, fetchAllActiveActivities),
    yield takeLatest(actions.DELETE_SCHEDULE_FOR_DATES, deleteAllSchedule),
  ]);
}
