import { Card, Col, Row } from "antd";
import moment from "moment-timezone";

import React, { useRef, useEffect, useState } from "react";
import CustomButtonFilled from "../../components/CustomElements/customBotton";
import formatMsg from "../../components/utility/formatMessageUtil";
import IntlMessages from "../../components/utility/intlMessages";
import colors from "../../Utility/colorFactory";
import "./cardWrapperStyle.css";
import FilterAction from "../../Utility/FilterAction";
import webFont from "../../Utility/fontSizes";
import CustomPdfViewer from "../../components/CustomPdfViewer/pdfDrawer";
import docIcon from "../../image/teacherDocument.svg";
import Viewer from "react-viewer";


const CardWrapper = (props) => {
  let parentEmailStatus = props.item.parentEmailStatus ? props.item.parentEmailStatus : [];
  let parentOneEmailStatus = undefined;
  let parentTwoEmailStatus = undefined;
  if (parentEmailStatus.length > 0) {
    parentOneEmailStatus = parentEmailStatus[0]
  }
  if (parentEmailStatus.length > 1) {
    parentTwoEmailStatus = parentEmailStatus[1]
  }

  const [pdfViewerVisible, setPdfViewerVisibility] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(undefined);

  const showPdf = (url) => {
    setPdfUrl(url);
    setPdfViewerVisibility(true);

  };

  const closePdfViewer = () => {
    setPdfViewerVisibility(false);
    setPdfUrl(undefined);
  };

  const [imageViewerVisible, setImageViewerVisibility] = useState(false);
  const [viewerImages, setImageUrls] = useState([]);

  const showImage = (url) => {
    setImageUrls([{ downloadUrl: url, src: url }]);
    setImageViewerVisibility(true);
  };

  const closeImgsViewer = () => {
    setImageViewerVisibility(false);
    setImageUrls([]);
  };

  const getDoneIcon = () => {
    let item = { completed: true }
    return (
      <div
        style={{
          ...styles.statusDiv,
          ...{
            backgroundColor: FilterAction.getBackGroundColor(item),
            color: FilterAction.getColor(item),
          },
        }}
      >
        <p
          style={{
            ...styles.status,
            ...{
              color: FilterAction.getColor(item),
            },
          }}
        >
          {FilterAction.getStatusForTask(item)}
        </p>
      </div>
    )
  }

  return (

    <Card
      key={props.key}
      style={{
        borderRadius: 5,
        marginBottom: 21,
        border: "1.5px solid #D0D0D0"
      }}
      bodyStyle={{ padding: 0 }}
    >

      <div>
        {/**icon, activit type, created on and delete/edit button */}
        <div className="spacedInline">
          <div className="imageInlineStyle">
            <img src={props.getIcon(props.item)} className="icon" alt="icon" />

            <div>
              <div className="inline">
                <p className="activityType">{props.item.activityType.toLowerCase() === "email" && props.item.subject ? formatMsg("sendEmail") : props.item.activityType}</p>
                {props.item.activityType.toLowerCase() === "task" ? (
                  <div style={{ marginLeft: "15px", }} >
                    {props.getStatus(props.item)}
                  </div>
                ) : props.item.activityType.toLowerCase() == "follow up" ? <div style={{ marginLeft: "15px", }} >{getDoneIcon()}</div> : null}
              </div>

              {props.item.createdOn || props.item.date ?
                <p className="sub_header">
                  {formatMsg("lead.loggedOn")}:{moment(props.item.createdOn ? props.item.createdOn : props.item.date).format("MMM[ ]Do[, ]YYYY")} at{" "}
                  {moment(props.item.createdOn ? props.item.createdOn : props.item.date).format("LT")}
                </p>
                : null}
            </div>
          </div>

          <div style={{ marginRight: 20 }}>
            {props.renderPopContent(props.item)}
          </div>
        </div>

        {/** subject */}
        {props.item.subject ? (
          <div className="subjectLine">
            <div className="infoTitle">
              <IntlMessages id="leads.EmailSubject" />{":"}
            </div>

            <div className="inline" >
              <p className="desc">{props.item.subject}</p>
            </div>
          </div>
        ) : null}

        {/**description */}
        <div className="despCommentInline">
          <div style={{ width: "80%" }}>
            {props.item.htmlText ? (
              <div className="subjectLine">
                <div className="infoTitle">
                  <IntlMessages id="expense.description" />{":"}
                </div>

                <div
                  style={{ overflow: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html: props.item.htmlText,
                  }}
                />
              </div>

            ) : (
              <div className="subjectLine">
                <div className="infoTitle">
                  <IntlMessages id="activity.note" />{":"}
                </div>
                <div>
                  <p className="desc">{props.item.description}</p>
                </div>
              </div>
            )}
          </div>


          <div className="commentDiv">
            {props.getCommentDiv(props.item)}
          </div>
        </div>
      </div>
      {
        props.item.attachment && props.item.attachment.length > 0 ? (
          <div className="subjectLine">
            <div className="infoTitle">
              <IntlMessages id="activity.attachment" />{":"}
            </div>

            <div className="inline wrapDiv" >
              {props.item.attachment.map((attachment, index) => (
                <div key={index} style={styles.endAligned}>
                  <img
                    src={attachment.type == "image" ? attachment.url : docIcon}
                    alt="icon"
                    style={styles.imgStyle}
                    onClick={() => {
                      if (attachment.type == "image") {
                        showImage(attachment.url)
                      }
                      else {
                        showPdf(attachment.url)
                      }
                    }}
                  />
                </div>
              ))}
            </div>

          </div>
        ) : null
      }

      <div
        style={{
          borderTop: "1px solid rgb(208 208 208 / 50%)",
        }}
        className="emailStatusDiv"
      >
        <Row >
          <Col lg={7} md={8} sm={24} xs={24} style={{ paddingTop: "13px", borderRight: "1px solid rgb(208 208 208 / 50%)", marginRight: "50px" }}>
            <div className="columnNameDiv">
              <p>{formatMsg("expense.createdBy")}</p>:
              <p style={styles.columnValueDiv} >{props.item.createdBy ? props.item.createdBy : ""}</p>
            </div>
          </Col>
          <Col lg={7} md={8} sm={24} xs={24} style={{ paddingTop: "13px", borderRight: "1px solid rgb(208 208 208 / 50%)", marginRight: "50px" }}>
            <div className="columnNameDiv">
              <p>{props.item.activityType.toLowerCase() === "task" ? formatMsg("leads.assignToStaff") : formatMsg("updatedBy")}{":"}</p>
              <p style={styles.columnValueDiv} >{props.item.activityType.toLowerCase() === "task" ? FilterAction.getTeacherNameById(props.item.teacher, props.firebase) : props.item.updatedBy ? props.item.updatedBy : ""}</p>
            </div>
          </Col>

          <Col lg={6} md={8} sm={24} xs={24} style={{ paddingTop: "13px", marginRight: "50px" }}>
            <div className="columnNameDiv">
              <p>{props.item.activityType.toLowerCase() === "task" ? <IntlMessages id="studentLesson.dueDate" /> : <IntlMessages id="lead.activityDate" />}{":"}</p>
              <p style={styles.columnValueDiv}>{props.item.activityType.toLowerCase() === "task" ? moment(props.item.nextFollowUpDate).format(
                FilterAction.getDateFormat(props.firebase)) :
                moment(props.item.date).format(FilterAction.getDateFormat(props.firebase))}</p>
            </div>
          </Col>
        </Row>
      </div>

      {
        parentEmailStatus.length > 0 ?
          <div
            style={{
              borderTop: "1px solid rgb(208 208 208 / 50%)", marginRight: "-11px",
            }}
            className="emailStatusDiv"
          >
            <Row >
              {parentOneEmailStatus ? <Col lg={11} md={12} sm={24} xs={24} style={styles.parentBoxStyle}>
                <div className="columnNameDiv">
                  <p>{parentOneEmailStatus.email}</p>:
                  <p style={styles.parentEmailBox} >{parentOneEmailStatus.status}</p>
                </div>
              </Col>
                : null}

              {parentTwoEmailStatus ? <Col lg={11} md={12} sm={24} xs={24} style={styles.parentBoxStyle2}>
                <div className="columnNameDiv">
                  <p>{parentTwoEmailStatus.email}</p>:
                  <p style={styles.parentEmailBox} >{parentTwoEmailStatus.status}</p>
                </div>
              </Col>
                : null}
            </Row>
          </div> :
          null
      }

      {props?.item?.automations && <>  <hr />  <div className="subjectLine">
        <div className="infoTitle">
          <IntlMessages id="lead.automation" />{":"}
        </div>
        <div>
          <p className="desc"><Row>

            <ul>
              {props?.item?.automations?.map((item) => {
                switch (item?.type?.toLowerCase()) {
                  case "task":
                    return <li>{item.name + " " + (item?.when == 0 ? formatMsg("lead.createdImmediately") : formatMsg("lead.scheduleForDate") + " " + moment(item?.date).format(FilterAction.getDateFormat(props.firebase))) + " " + formatMsg("lead.task")}</li>
                  case "email":
                    return <li>{item.name + " " + (item?.when == 0 ? formatMsg("lead.sentImmediately") : formatMsg("lead.scheduleForDate") + " " + moment(item?.date).format(FilterAction.getDateFormat(props.firebase))) + " " + formatMsg("lead.email")}</li>
                }
              })}
            </ul>
          </Row></p>
        </div>
      </div></>}

      <CustomPdfViewer
        visible={pdfViewerVisible}
        onClose={() => closePdfViewer()}
        downloadAllowed={true}
        invoiceUrl={pdfUrl}
      />
      <Viewer
        visible={imageViewerVisible}
        onClose={closeImgsViewer}
        images={viewerImages}
        downloadable={false}
        drag={false}
        zIndex={1000}
        activeIndex={0}
      />
    </Card >
  );
};
export default CardWrapper;

const styles = {
  columnNameDiv: {
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
    color: "#303030",
  },
  columnValueDiv: {
    color: "#8D8D8D",
    paddingLeft: "10px",
  },
  parentBoxStyle: {
    paddingTop: "10px",
    borderRight: "1px solid rgb(208 208 208 / 50%)",
    marginRight: "50px"
  },
  parentBoxStyle2: {
    paddingTop: "10px",
    // marginRight: "50px"
  },
  parentEmailBox: {
    paddingLeft: "10px",
    color: colors.v2_Cerulean,
    textTransform: "capitalize"
  },
  imgStyle: {
    height: 50,
    width: 50,
    objectFit: "cover",
    border: "1px solid " + colors.v2_Cerulean,
    marginTop: "8px",
  },
  endAligned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    cursor: "pointer",
    marginLeft: "10px",
    flexWrap: "wrap",
  },
  statusDiv: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
    borderRadius: 5,
    border: "1px solid",
  },
  status: {
    fontSize: webFont.defaultFont,
    textTransform: "uppercase",
  }
}

