import actions from "./actions";
const initState = {
  leadsTableData: [],
  leadFormOperationType: undefined,
  isLoading: false,
  error: false,
  leadStatusList: [],
  automationTableData: [],
  newRuleRedirect: false,
  ruleRefId: undefined,
  ruleDetails: {},
  ruleDetailChan: undefined,
  feeActions: [],
  feeActionsChan: undefined,
  newActionRedirect: false,
  allSteps: [],
  automationTableChan: undefined,
  actionDetails: {},
};
export default function leadFormsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESET_NEW_REDIRECT:
      return {
        ...state,
        newRuleRedirect: false,
        refId: undefined,
      };
    case actions.RESET_ACTION_REDIRECT:
      return {
        ...state,
        newActionRedirect: false,
      };
    case actions.GET_LEADS_TABLE_DATA:
      return {
        ...state,
      };

    case actions.GET_LEADS_TABLE_DATA_SUCCESSFUL:
      return {
        ...state,
        leadsTableData: action.leadsTableData,
        automationTableChan: action.automationTableChan,
      };

    case actions.GET_AUTOMATION_TABLE_DATA:
      return {
        ...state,
      };

    case actions.GET_AUTOMATION_TABLE_DATA_SUCCESS:
      return {
        ...state,
        automationTableData: action.automationTableData,
      };

    case actions.GET_RULE_DATA:
      return {
        ...state,
      };

    case actions.GET_RULE_DATA_SUCCESS:
      return {
        ...state,
        ruleDetails: action.ruleDetails,
        ruleDetailChan: action.ruleDetailChan,
      };

    case actions.RESET_ACTION_DATA:
      return {
        ...state,
        actionDetails: {},
      };

    case actions.GET_ACTION:
      return {
        ...state,
      };

    case actions.GET_ACTION_SUCCESS:
      return {
        ...state,
        actionDetails: action.actionDetails,
      };

    case actions.CHANGE_FEE_EMAIL_DETAILS:
      return {
        ...state,
      };

    case actions.CHANGE_FEE_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
      };

    case actions.GET_ALL_STEPS:
      return {
        ...state,
      };

    case actions.GET_ALL_STEPS_SUCCESS:
      return {
        ...state,
        allSteps: action.allSteps,
      };

    case actions.GET_FEE_ACTIONS:
      return {
        ...state,
      };

    case actions.GET_FEE_ACTIONS_SUCCESS:
      return {
        ...state,
        feeActions: action.feeActions,
        feeActionsChan: action.feeActionsChan,
      };

    case actions.SAVE_LEAD_AUTOMATION:
      return {
        ...state,
      };
    case actions.SAVE_LEAD_AUTOMATION_SUCCESS:
      return {
        ...state,
        newRuleRedirect: action.newRuleRedirect,
      };

    case actions.SAVE_FEE_EMAIL_DETAILS:
      return {
        ...state,
      };
    case actions.SAVE_FEE_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        newActionRedirect: action.newActionRedirect,
      };

    case actions.UPDATE_LEAD_FORM_SETTING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_LEAD_FORM_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leadFormOperationType: "SETTING_UPDATED",
      };

    case actions.LEAD_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case actions.RESET_LEAD_FORM_OPERATION:
      return {
        ...state,
        isLoading: false,
        error: false,
        leadFormOperationType: undefined,
      };

    case actions.DELETE_LEAD_FORM:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_LEAD_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leadFormOperationType: "SETTING_UPDATED",
      };

    case actions.GET_LEAD_STATUS:
      return {
        ...state,
      };

    case actions.GET_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        leadStatusList: action.leadStatusList,
      };

    default:
      return state;
  }
}
