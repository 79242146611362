import antdFR from "antd/lib/locale-provider/fr_FR";
import appLocaleData from "react-intl/locale-data/fr";
import frMessages from "../locales/fr_FR.json";

const frLang = {
  messages: {
    ...frMessages,
  },
  antd: antdFR,
  locale: "fr",
  data: appLocaleData,
};
export default frLang;
