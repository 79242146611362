import actions from "./actions";
const initState = {
  leads: [],
  feeProgram: [],
  status: [],
  source: [],
  subsource: [],
  isLoading: false,
  error: false,
  operationType: undefined,
  leadsChannel: undefined,
  detailedLead: [],
  detailedLeadActivity: [],
  detailedLeadChannel: undefined,
  selectedTaskChannel: undefined,
  detailedLeadActivityChannel: undefined,
  prevFrequency: undefined,
  errorMessage: undefined,
  leadAdditionalField: [],
  sourceOptions: [],
  subSourceOptions: [],
  tempStudentData: undefined,
  leadDoc: [],
  operationRequest: "",
  fetchAllLeadsTasks: [],
  fetchAllLeadStudentName: [],
  fetchAllLeadsTaskchannel: undefined,
  tasksLabel: [],
  taskComment: new Map(),
  teacherLists: [],
  leadEmailTemplateData: [],
  emailTemplateData: [],
  detailedLeadEmailChannel: undefined,
  detailedLeadEmailTemplateChannel: undefined,
  fetchstudent: [],
  selectedTask: [],
  totalLead: 0,
  totalCoversionCount: 0,
  totalFollowUpDue: 0,
  taskDueCount: 0,
  lastSevenDays: [],
  totalConversion: [],
  sourceReport: [],
  followUpDueList: [],
  dueTaskList: [],
  upcomingAdmission: [],
  dealStageList: [],
  communicationReport: [],
  linkForParentOne: false,
  leadDashboardLoading: false,
  manageLeadChannel: undefined,
  managelead: [],
  selectedLeadDashboard: [],
  selectedLeadDashboardChannel: undefined,
  sourceReportRaw: [],
  totalConversionRaw: [],
  followUpDueListRaw: [],
  dueTaskListRaw: [],
  upcomingAdmissionRaw: [],
  dealStageListRaw: [],
  communicationReportRaw: [],
  manageLeadsChannel: undefined,
  totalLeadAutomationCount: [],
  sharedForms: [],
  leadActivityComment: new Map(),
  redirect: false,
  isUpdateLeadLoading: false,
  admittedLeads: [],
};
export default function leadsReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.RESET_STUDENTNAME:
      return {
        ...state,

        fetchstudent: [],
      };
    case actions.RESET_EDIT:
      return {
        ...state,
        isLoading: false,
        detailedLead: [],
      };
    case actions.GET_SHARED_FORMS_SUCCESS:
      return {
        ...state,
        sharedForms: action.sharedForms
      }
    case actions.LIST_LEADS:

      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.LIST_LEADS_SUCCESSFULL:
      return {
        ...state,
        leads: action.leads,
        isLoading: false,
        operationType: action.operationType,
        leadsChannel: action.leadsChannel,
        prevFrequency: action.prevFrequency,
        sourceOptions: action.sourceOptions,
        subSourceOptions: action.subSourceOptions,
      };

    case actions.DELETE_LEAD_DOC:

      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_LEAD_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };

    case actions.GET_FEE_PROGRAM_FOR_LEADS:

      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_FEE_PROGRAM_FOR_LEADS_SUCCESSFUL:
      return {
        ...state,
        feeProgram: action.feeProgram,
        isLoading: false,
      };

    case actions.GET_STATUS:

      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_STATUS_SUCCESSFUL:
      return {
        ...state,
        status: action.status,
        manageLeadChannel: action.manageLeadChannel,
        isLoading: false,
        operationType: undefined,
      };
    case actions.GET_SOURCE:

      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_SOURCE_SUCCESS:
      return {
        ...state,
        source: action.source,
        isLoading: false,
        operationType: undefined,
      };
    case actions.GET_SUBSOURCE:

      return {
        ...state,
        isLoading: true,
        operationType: "",
      };
    case actions.GET_SUBSOURCE_SUCCESS:
      return {
        ...state,
        subsource: action.subsource,
        isLoading: false,
        operationType: undefined,
      };
    case actions.FETCH_MANAGE_LEAD:

      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_MANAGE_LEAD_SUCCESS:

      return {
        ...state,
        isLoading: false,
        manageLead: action.manageLead,
        manageLeadsChannel: action.manageLeadsChannel,
      };
    case actions.SAVE_NEW_LEAD:

      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.SAVE_NEW_LEAD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_LEAD",
      };

    case actions.UPDATE_LEAD:

      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        isUpdateLeadLoading: true
      };
    case actions.GET_LEAD_DOC:

      return {
        ...state,
        isLoading: true,
      };

    case actions.GET_LEAD_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leadDoc: action.leadDoc,
      };
    case actions.UPDATE_LEAD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        // operationType: "UPDATE_LEAD",
        isUpdateLeadLoading: false
      };

    case actions.UPLOAD_LEAD_DOC:


      return {
        ...state,
        isLoading: true,
      };

    case actions.UPLOAD_LEAD_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };

    case actions.ADD_LEAD_DOC_FOLDER:


      return {
        ...state,
        isLoading: true,
      };

    case actions.ADD_LEAD_DOC_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };

    case actions.RENAME_LEAD_DOC_FOLDER:


      return {
        ...state,
        isLoading: true,
      };

    case actions.RENAME_LEAD_DOC_FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationRequest: action.operationRequest,
      };

    case actions.RESET_OPERATION_REQ:
      return {
        ...state,
        isLoading: false,
        operationRequest: "",
        error: false,
      };
    case actions.ADD_STATUS:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_STATUS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_STATUS",
      };
    case actions.MANAGE_LEAD:


      return {
        ...state,
        isLoading: true,
        // operationType: undefined,
      };
    case actions.MANAGE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // operationType: "ADD_STATUS",
      };

    case actions.GET_LEAD_DETAIL:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_LEAD_DETAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        detailedLead: action.detailedLead,
        detailedLeadChannel: action.detailedLeadChannel,
      };

    case actions.GET_LEAD_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_LEAD_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        detailedLeadActivity: action.detailedLeadActivity,
        detailedLeadActivityChannel: action.detailedLeadActivityChannel,
      };

    case actions.ADD_ACTIVITY:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_TASK",
      };
    case actions.ADD_TASK:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_TASK_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_TASK",
      };


    case actions.UPDATE_LEAD_STATUS_CHANGE:


      return {
        ...state,
        isLoading: true,
        operationType: "dragged",
      };
    case actions.UPDATE_LEAD_STATUS_CHANGE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
      };

    case actions.LEAD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.errorMessage,
        leadDashboardLoading: false,
        isUpdateLeadLoading: false

      };

    case actions.RESET_LEADS_OPERATION_TYPE:
      return {
        ...state,
        isLoading: false,
        error: false,
        operationType: undefined,
        prevFrequency: undefined,
        errorMessage: undefined,
      };

    case actions.CREATE_NEW_ACCOUNT:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.CREATE_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "CREATE_ACCOUNNT_SUCCESS",
      };

    case actions.DELETE_LEAD:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_LEAD",
        redirect: true,
      };

    case actions.DELETE_LEAD_STATUS:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.DELETE_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_LEAD_STATUS",
      };
    case actions.DELETE_LEAD_CATEGORY:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };

    case actions.DELETE_LEAD_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_LEAD_CATEGORY",
      };

    case actions.GET_LEADS_ADDITIONAL_FIELD:
      return {
        ...state,
      };

    case actions.GET_LEADS_ADDITIONAL_FIELD_SUCCESS:
      return {
        ...state,
        leadAdditionalField: action.leadAdditionalField,
      };

    case actions.DOWNLOAD_LEADS_EXCEL:

      return {
        ...state,
        isLoading: true,
      };

    case actions.DOWNLOAD_LEADS_EXCEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DELETE_LEAD_ACTIVITY:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_LEAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actions.DELETE_LEAD_TASK:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_LEAD_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };


    case actions.LEAD_STUDENT_ADDED:
      return {
        ...state,
        isLoading: false,
        tempStudentData: action.tempStudentData,
        operationType: "STUDENT_ADDED",
      };

    case actions.LEAD_ADD_PARENT_ONE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.LEAD_PARENT_ONE_ADDED:
      return {
        ...state,
        isLoading: false,
        tempStudentData: action.tempStudentData,
        operationType: "PARENT_ONE_ADDED",
        linkForParentOne: false,
      };

    case actions.LEAD_ADD_PARENT_TWO:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.LEAD_PARENT_TWO_ADDED:
      return {
        ...state,
        isLoading: false,
        tempStudentData: action.tempStudentData,
        operationType: "PARENT_TWO_ADDED",
      };

    case actions.STUDENT_ADD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        tempStudentData: action.tempStudentData,
        operationType: "STUDENT_ADD_FAILED",
      };

    case actions.STUDENT_LINK_FOUND:
      return {
        ...state,
        isLoading: false,
        tempStudentData: action.existingDetail,
        operationType: "STUDENT_LINK_FOUND",
        linkForParentOne: action.linkForParentOne,
      };

    case actions.RESET_LEAD_EXISTING_DETAIL:
      return {
        ...state,
        isLoading: false,
        error: false,
        operationType: undefined,
        prevFrequency: undefined,
        errorMessage: undefined,
        tempStudentData: undefined,
      };

    case actions.LEAD_LINK_PARENT:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
        tempStudentData: undefined,
      };
    case actions.LEAD_LINK_PARENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "PARENT_LINKED",
      };

    case actions.LEAD_PARENT_ONE_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        tempStudentData: action.tempStudentData,
        operationType: "PARENT_ONE_FAILED",
      };

    case actions.LEAD_PARENT_TWO_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        tempStudentData: action.tempStudentData,
        operationType: "PARENT_TWO_FAILED",
      };

    case actions.FETCH_ALLLEAD_TASK:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_ALLLEAD_TASK_SUCESS:
      return {
        ...state,
        isLoading: false,
        fetchAllLeadsTasks: action.fetchAllLeadsTasks,
        fetchAllLeadStudentName: action.fetchAllLeadStudentName,
        fetchAllLeadsTaskchannel: action.fetchAllLeadsTaskchannel,
      };
    case actions.GET_TASK_LABEL:
      return {
        ...state,
      };
    case actions.GET_TASK_LABEL_SUCESS:
      return {
        ...state,
        tasksLabel: action.tasksLabel,
      };
    case actions.TASK_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case actions.UPDATE_TASK:


      return {
        ...state,
        isLoading: true,
        // operationType: undefined,
      };
    case actions.UPDATE_TASK_SUCESS:
      return {
        ...state,
        isLoading: false,
        // operationType: "UPDATE_TASK",
      };
    case actions.GET_COMMENT_FOR_TASK:
      return {
        ...state,
        operationType: undefined,
      };
    case actions.GET_COMMENT_FOR_TASK_SUCCESSFUL:
      return {
        ...state,
        taskComment: action.taskComment,
      };
    case actions.GET_COMMENT_FOR_LEAD_ACTIVITY:
      return {
        ...state,

      };
    case actions.GET_COMMENT_FOR_LEAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        leadActivityComment: action.leadActivityComment,
      };
    case actions.GET_TEACHER_LIST:
      return {
        ...state,
      };
    case actions.GET_TEACHER_LIST_SUCESS:
      return {
        ...state,

        teacherLists: action.teacherLists,
      };
    case actions.UPDATE_NEXTFOLLOWUP:


      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_NEXTFOLLOWUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.MARK_DONE_NEXTFOLLOWUP:


      return {
        ...state,
        isLoading: true,
      };
    case actions.MARK_DONE_NEXTFOLLOWUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operationType: "MARK_DONE_NEXTFOLLOWUP_SUCCESS",
      };

    case actions.GET_EMAIL_TEMPLATE_REGISTER:
      return {
        ...state,
      };
    case actions.GET_NEW_LEAD_DASHBOARD_STATS:
      return {
        ...state,
      };

    case actions.GET_EMAIL_TEMPLATE_REGISTER_SUCCESSFUL:
      return {
        ...state,
        leadEmailTemplateData: action.leadEmailTemplateData,
        detailedLeadEmailTemplateChannel: action.detailedLeadEmailTemplateChannel,
      };
    case actions.FETCH_EMAIL_TEMPLATE:
      return {
        ...state,
      };

    case actions.FETCH_EMAIL_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        emailTemplateData: action.emailTemplateData,
      };
    case actions.DELETE_LEAD_EMAIL:


      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.DELETE_LEAD_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.ADD_EMAIL:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_EMAIL",
      };
    case actions.GET_LEAD_EMAIL:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.GET_LEAD_EMAIL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: undefined,
        detailedLeadActivity: action.detailedLeadActivity,
        detailedLeadEmailChannel: action.detailedLeadEmailChannel,
      };

    case actions.ADD_LEAD_EMAIL_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        operationType: undefined,
      };
    case actions.ADD_LEAD_EMAIL_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "ADD_LEAD_EMAIL_TEMPLATE",
      };

    case actions.DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };

    case actions.DELETE_EMAIL_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "DELETE_EMAIL_TEMPLATE_SUCCESSFUL",
      };
    case actions.UPDATE_EMAIL_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_EMAIL_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        operationType: "UPDATE_EMAIL_TEMPLATE_SUCCESSFUL",
      };
    case actions.FETCH_STUDENT:


      return {
        ...state,

        // fetchstudent: action.fetchstudent,
        isloading: true,
      };
    case actions.FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        fetchstudent: action.fetchstudent,
        isloading: false,
      };
    case actions.FETCH_TASK_BY_ID:

      return {
        ...state,

        // fetchstudent: action.fetchstudent,
        isLoading: true,

        selectedTask: action.selectedTask,
        selectedTaskChannel: action.selectedTaskChannel,
      };
    case actions.FETCH_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedTask: action.selectedTask,
        selectedTaskChannel: action.selectedTaskChannel,
      };
    case actions.NEW_DASHBOARD_TOTAL_FOLLOW_UP:
      return {
        ...state,
        totalFollowUpDue: action.totalFollowUpDue,
      };

    case actions.NEW_DASHBOARD_TASK_DUE_COUNT:
      return {
        ...state,
        taskDueCount: action.taskDueCount,
      };

    case actions.NEW_DASHBOARD_TOTAL_LEAD:
      return {
        ...state,
        totalLead: action.totalLead,
      };

    case actions.NEW_DASHBOARD_TOTAL_CONVERSION_COUNT:
      return {
        ...state,
        totalCoversionCount: action.totalCoversionCount,
      };

    case actions.NEW_DASHBOARD_LAST_7_DAYS:
      return {
        ...state,
        lastSevenDays: action.lastSevenDays,
      };

    case actions.NEW_DASHBOARD_TOTAL_CONVERSION:
      return {
        ...state,
        totalConversion: action.totalConversion,
        totalConversionRaw: action.totalConversionRaw,
      };

    case actions.NEW_DASHBOARD_SOURCE_REPORT:
      return {
        ...state,
        sourceReport: action.sourceReport,
        sourceReportRaw: action.sourceReportRaw,
      };

    case actions.NEW_DASHBOARD_TOTAL_FOLLOW_UP_LIST:
      return {
        ...state,
        followUpDueList: action.followUpDueList,
        followUpDueListRaw: action.followUpDueListRaw,
      };

    case actions.NEW_DASHBOARD_TOTAL_DUE_TASK_LIST:
      return {
        ...state,
        dueTaskList: action.dueTaskList,
        dueTaskListRaw: action.dueTaskListRaw,
      };

    case actions.NEW_DASHBOARD_TOTAL_UPCOMING_ADMISSION:
      return {
        ...state,
        upcomingAdmission: action.upcomingAdmission,
        upcomingAdmissionRaw: action.upcomingAdmissionRaw,
      };

    case actions.NEW_DASHBOARD_DEAL_STAGE_LIST:
      return {
        ...state,
        dealStageList: action.dealStageList,
        dealStageListRaw: action.dealStageListRaw,
      };

    case actions.NEW_DASHBOARD_COMMUNICATION_REPORT:
      return {
        ...state,
        communicationReport: action.communicationReport,
        communicationReportRaw: action.communicationReportRaw,
      };

    case actions.START_LEAD_DASHBOARD_SPINNER:
      return {
        ...state,
        leadDashboardLoading: true,
      };

    case actions.STOP_LEAD_DASHBOARD_SPINNER:
      return {
        ...state,
        leadDashboardLoading: false,
      };
    case actions.FETCH_LEAD_FORM_DASBOARD:


      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_LEAD_FORM_DASBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedLeadDashboard: action.selectedLeadDashboard,
        selectedLeadDashboardChannel: action.selectedLeadDashboardChannel,
      };
    case actions.FETCH_LEAD_AUTOMATION_COUNT:


      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_LEAD_AUTOMATION_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalLeadAutomationCount: action.totalLeadAutomationCount,
      };
    case actions.LEAD_ASSIGN_TO:


      return {
        ...state,
        isLoading: true,

      };
    case actions.LEAD_ASSIGN_TO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actions.DELETE_FOLLOW_UP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.RESET_REDIRECT_FOR_DELETE:
      return {
        ...state,
        redirect: false
      }
    case actions.RELOAD_PAGE_DATA:
      return {
        ...state,
        isLoading: true
      }
    case actions.RELOAD_PAGE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.GET_ADMITTED_LEADS_SUCCESS:
      return {
        ...state,
        admittedLeads: action.admittedLeads
      }
    default:
      return state;
  }
}
