import bugsnagClient from "@bugsnag/js";
import "@firebase/firestore"; // 👈 If you're using firestore
import moment from "moment-timezone";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import notification from "../../components/notification";
import formatMsg from "../../components/utility/formatMessageUtil";
import { ActivityApi } from "../../firestore-api/activity";
import { StudentApi } from "../../firestore-api/student";
import { StudentAttendanceApi } from "../../firestore-api/studentAttendance";
import { TeacherApi } from "../../firestore-api/teacher";
import { getItem } from "../../Utility/encryptedStorage";
import actions from "./actions";
import { callApi } from "../../Utility/superAgentUntil";
import FilterAction from "../../Utility/FilterAction";


function* listTeachers({ firebase, initialCall }) {
  let data = JSON.parse(getItem("teacherList"));

  yield put({
    type: actions.LIST_TEACHER_SUCCESSFUL,
    teachers: data,
    teacherChannel: undefined,
    staffOperation: initialCall ? "INITIAL_STAFF_FETCH" : undefined,
  });
}

function* getAllClassromsForTeacher({ firebase }) {
  try {
    let data = JSON.parse(getItem("classList"));

    if (data) {
      yield put({
        type: actions.GET_TEACHER_CLASSROOM_DATA_SUCCESSFUL,
        teachersClassroomData: data,
      });
    }
  } catch (error) {
    console.log("failed to fetch classroom data for teacher ", error);
    bugsnagClient.notify(error);
  }
}

function* updateTeacherUserEmail(contact, staffObj, firebase, type, formEmail) {
  // try {
  //   if (staffObj.email || formEmail) {
  //     let email = formEmail
  //       ? formEmail.toLowerCase()
  //       : staffObj.email.toLowerCase();
  //     let editedId = replaceAll(email, "@", "%40");
  //     let encodedEmail = replaceAll(editedId, ".", "%2E");
  //     let existingUser = yield call(
  //       TeacherApi.checkIfUserExistInNewUser,
  //       Number(contact),
  //       firebase
  //     );
  //     if (existingUser.status) {
  //       let existingEmailUser = yield call(
  //         TeacherApi.checkIfUserExistInNewUser,
  //         encodedEmail,
  //         firebase
  //       );
  //       let ifUpdateNeeded = false;
  //       if (
  //         existingEmailUser.status &&
  //         existingEmailUser.id === existingUser.id
  //       ) {
  //         ifUpdateNeeded = true;
  //       } else if (!existingEmailUser.status) {
  //         ifUpdateNeeded = true;
  //       }
  //       if (ifUpdateNeeded) {
  //         let existingObj = JSON.parse(JSON.stringify(existingUser));
  //         existingObj.status = null;
  //         yield call(
  //           StudentApi.updateNewUser,
  //           encodedEmail,
  //           existingObj,
  //           firebase
  //         );
  //         if (type && type === "add") {
  //           addFirebaseAuth(email, firebase);
  //         } else if (
  //           formEmail &&
  //           (!staffObj.email || staffObj.email !== formEmail)
  //         ) {
  //           alert("auth created for" + email);
  //           addFirebaseAuth(email, firebase);
  //         }
  //         if (type && type === "add") {
  //           yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
  //         } else if (
  //           formEmail &&
  //           (!staffObj.email || staffObj.email !== formEmail)
  //         ) {
  //           alert("emailnode created for" + email);
  //           yield fork(TeacherApi.sendInviteToTeacher, [email], firebase);
  //         }
  //       }
  //     } else {
  //       bugsnagClient.notify(
  //         new Error(
  //           "Unable to create teacher's email login as no data was found with the registered number"
  //         )
  //       );
  //     }
  //   }
  // } catch (err) {
  //   notification(
  //     "error",
  //     "Failed to update email login",
  //     err.message ? err.message : ""
  //   );
  //   console.log("failed to upload teacher user email", err);
  //   bugsnagClient.notify(
  //     "failed to upload teacher email in new user ----->>>" + err.message
  //       ? err.message
  //       : err
  //   );
  // }
}

function addFirebaseAuth(email, firebase) {
  var username = email.split("@")[0];
  var password = username.substr(0, 8);
  if (password.length < 8) {
    password = password + "123456";
  }

  firebase.auth.createUserWithEmailAndPassword(email, password).then((res) => {
    console.log("auth ---", res);
  });
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function* addTeacher({
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  encodedEmail,
  password,
  firebase,
  countryCode,
  branchAccess,
  groups,
  birthDate,
  joiningDate,
  startTime,
  endTime
}) {
  try {
    let genericMessage = "Failed to add details. Please contact school or Illumine";
    let newBranches = [];
    let allBranches = firebase && firebase.branchList ? firebase.branchList : undefined;
    if (branchAccess && branchAccess.length > 0 && allBranches && allBranches.length > 0) {
      newBranches = getBranchesObject(branchAccess, allBranches);
    }

    if (newBranches && newBranches.length === 1) {
      if (newBranches[0].name.toLowerCase() === firebase.sbp.toLowerCase()) {
        newBranches = [];
      }
    }
    // let nodeId = yield call(TeacherApi.createTeacherNode, firebase);

    let response = yield call(
      TeacherApi.addTeacherApi,
      firebase,
      name,
      Number(contact),
      email,
      gender,
      classroom,
      role,
      address,
      countryCode,
      newBranches,
      groups,
      birthDate,
      joiningDate,
      startTime,
      endTime,

    );

    if (response && response.status) {
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: actions.ADD_TEACHER_SUCCESSFUL,
          newStaffId: response?.body?.teacher?.id,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message ? response.body.message : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to add teacher", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateTeacher({
  name,
  gender,
  classroom,
  contact,
  address,
  email,
  role,
  record,
  firebase,
  countryCode,
  branchAccess,
  groups,
  birthDate,
  joiningDate,
  employeeCode,
  startTime,
  endTime,
}) {
  try {
    let genericMessage = "Failed to update details. Please contact school or Illumine";
    let oldPhoneNumber = record.phoneNumber;
    let oldEmail = record.email ? record.email : null;
    let teacherObj = JSON.parse(JSON.stringify(record));
    let newBranches = [];
    let allBranches = firebase && firebase.branchList ? firebase.branchList : undefined;
    if (branchAccess && branchAccess.length > 0 && allBranches && allBranches.length > 0) {
      newBranches = getBranchesObject(branchAccess, allBranches);
    }

    // if ( newBranches && newBranches.length === 1) {
    //   if (newBranches[0].name.toLowerCase() === firebase.sbp.toLowerCase()) {
    //     newBranches = [];
    //   }
    // }

    if (record.newBranches && newBranches.length > 0) {
      console.log("branches changed");
    } else if (newBranches.length > 0 && !record.newBranches) {
      console.log("prev branch 0. New branches added");
    } else if (newBranches.length === 0 && record.newBranches) {
      console.log("branches removed");
    } else {
      // do nothing
    }
    teacherObj.name = name;
    teacherObj.gender = gender;
    teacherObj.classList = classroom;
    teacherObj.phoneNumber = contact ? Number(contact) : null;
    teacherObj.address = address ? address : null;
    teacherObj.email = email ? email : null;
    teacherObj.role = role;
    teacherObj.code = countryCode;
    teacherObj.newBranches = newBranches && newBranches.length > 0 ? newBranches : null;
    teacherObj.groups = groups && groups.length > 0 ? groups : null;
    teacherObj.birthDate = birthDate
      ? moment(new Date(birthDate)).format("DD[, ]MMMM[, ]YYYY")
      : null;
    teacherObj.updatedBy = firebase.teacher.name;
    teacherObj.platform = "web";
    teacherObj.joiningDate = joiningDate ? moment(joiningDate).valueOf() : null;
    teacherObj.employeeCode = employeeCode;
    teacherObj.startTime = startTime ? moment(startTime).valueOf() : null,
      teacherObj.endTime = endTime ? moment(endTime).valueOf() : null;
    let response = yield call(
      TeacherApi.updateTeacherApi,
      firebase,
      teacherObj,
      oldPhoneNumber,
      oldEmail
    );
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.UPDATE_TEACHER_SUCCESSFUL,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message ? response.body.message : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to update teacher detail", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function getBranchesObject(branchAccess, allBranches) {
  let newBranches = [];
  for (let index in branchAccess) {
    let branchName = branchAccess[index];
    let filteredBranch = allBranches.filter((b) => {
      return b.name === branchName;
    });

    if (filteredBranch && filteredBranch.length > 0) {
      newBranches = [...newBranches, ...filteredBranch];
    }
  }
  return newBranches;
}

function* deleteSelectedTeacher({ teacherRecord, firebase }) {
  try {
    let genericMessage = "Failed to delete teacher. Please contact school or Illumine";
    let response = yield call(TeacherApi.deleteTeacherApi, firebase, teacherRecord);
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.DELETE_TEACHER_SUCCESSFUL,
        });
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message ? response.body.message : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to delete teacher", err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchSelectedTeacherDetail({ teacherId, firebase }) {
  let teachers = JSON.parse(getItem("teacherList"));
  let data = teachers.filter((t) => {
    return t.id === teacherId;
  });
  yield put({
    type: actions.GET_TEACHER_DETAIL_SUCCESSFUL,
    teacherDetail: data,
    teacherDetailChannel: undefined,
  });
}

function* refreshTeacherDetail(teacherId) {
  let teachers = JSON.parse(getItem("teacherList"));
  let data = teachers.filter((t) => {
    return t.id === teacherId;
  });

  yield put({
    type: actions.GET_TEACHER_DETAIL_SUCCESSFUL,
    teacherDetail: data,
    teacherDetailChannel: undefined,
  });
}

function* updateStaffRole({ role, teacherId, firebase, record }) {
  try {
    // yield call(TeacherApi.changeStaffRole, role, teacherId, firebase);
    let genericMessage = "Failed to update role. Please contact school or Illumine";
    let oldPhoneNumber = record.phoneNumber;
    let oldEmail = record.email ? record.email : null;
    let teacherObj = record;
    teacherObj.role = role;
    teacherObj.updatedBy = firebase.teacher.name;
    teacherObj.platform = "web";

    let response = yield call(
      TeacherApi.updateTeacherApi,
      firebase,
      teacherObj,
      oldPhoneNumber,
      oldEmail
    );
    if (response && response.status) {
      if (response.status === 200) {
        yield put({
          type: actions.CHANGE_STAFF_ROLE_SUCCESSFUL,
        });
        yield call(refreshTeacherDetail, teacherId);

        yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
      } else {
        yield put({
          type: actions.TEACHER_REQUEST_FAILED,
          errorMessage:
            response.body && response.body.message ? response.body.message : genericMessage,
        });
      }
    }
  } catch (err) {
    console.log("failed to change role", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateStaffPermission({ category, status, teacherId, firebase }) {
  try {
    yield call(TeacherApi.changeStaffPermission, category, status, teacherId, firebase);
    yield put({
      type: actions.CHANGE_PERMISSION_SUCCESSFUL,
    });
    yield call(refreshTeacherDetail, teacherId);

    yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
  } catch (err) {
    console.log("failed to change permission", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* updateTeacherDisplayPic({ file, teacherDetail, firebase, isDelete }) {
  try {
    let storagePath = firebase.sbp + "/media/profileimages/";
    let urls = [];
    if (isDelete) {
      urls[0] = null;
    }
    else {
      urls = yield call(ActivityApi.getMediaPath, storagePath, file, firebase);
    }
    if (urls) {
      if (urls.length > 0) {
        teacherDetail[0].profileImageUrl = urls[0];
        let profileImageUrlObj = {
          profileImageUrl: urls[0],
          platform: "web",
          updatedBy: firebase.teacher.name,
          updatedOn: moment().valueOf(),
        };
        yield call(
          TeacherApi.updateTeacherObject,
          profileImageUrlObj,
          teacherDetail[0].id,
          firebase
        );

        yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
        yield put({
          type: actions.UPLOAD_TEACHER_PROFILE_SUCCESSFUL,
        });

        if (teacherDetail[0] && teacherDetail[0].id) {
          yield call(refreshTeacherDetail, teacherDetail[0].id);
        }
      }
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload display pic", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchTeacherFiles({ id, firebase }) {
  try {
    let data = yield call(StudentApi.getUserDocument, id, firebase);
    if (data) {
      yield put({
        type: actions.GET_TEACHER_FILES_SUCCESS,
        teacherDoc: data,
      });
    }
  } catch (err) {
    console.log("failed to fetch teacher files", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchTeacherLeaveStats({
  firebase,
  externalTeacherId,
  tempHolidayItemsId,
  leaveSettingId,
}) {
  try {
    let requestBody = {};
    requestBody.staffId = externalTeacherId;

    requestBody.settingId = leaveSettingId;
    let url = "woodlandApi/getStaffLeaveStats/" + "?centerId=";

    let response = yield call(StudentAttendanceApi.requestApi, requestBody, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.GET_LEAVE_STAT_SUCCESS,
        teacherLeaveStats: response.body,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : "Failed to fetch teacher leave stats. Contact school or Illumine"
      );
    }
  } catch (err) {
    console.log("failed to fetch leave stats", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchTeacherLeaveHistory({ record, externalTeacherId, firebase }) {
  try {
    let url = "woodlandApi/fetchLeaveList/" + "?centerId=";

    let response = yield call(StudentAttendanceApi.requestApi, record, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.GET_LEAVE_HISTORY_SUCCESS,
        teacherLeaveHistory: response.body.historyList,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : "Failed to fetch leave history. Contact school or Illumine"
      );
    }
  } catch (err) {
    console.log("failed to fetch leave stats", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* getAllLeavesSettingDateRange({ firebase }) {
  try {
    let url = "woodlandApi/fetchAllLeaveSetting/" + "?centerId=";

    let response = yield call(StudentAttendanceApi.requestApi, {}, url, firebase);

    if (response && response.status && response.status === 200) {
      yield put({
        type: actions.FETCH_ALL_LEAVE_SETTING_DATE_RANGE_SUCCESS,
        allLeaveSetting: response.body,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : "Failed to fetch date range. Contact school or Illumine"
      );
    }
  } catch (err) {
    console.log("failed to fetch leave stats", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* uploadTeacherDoc({ fileList, mediaType, id, firebase, filePath }) {
  try {
    let storagePath;
    if (mediaType === "File") {
      storagePath = firebase.sbp + "/media/file/";
    } else {
      storagePath = firebase.sbp + "/media/images/";
    }
    let file = { fileList: fileList };

    let urls = yield call(ActivityApi.getAttachmentMediaPath, storagePath, file, firebase);
    if (urls) {
      for (let i = 0; i < urls.length; i++) {
        let nodeId = yield call(StudentApi.getStudentDocNode, firebase);

        let obj = {
          filePath: filePath ? filePath : null,
          createdBy: firebase.teacher.name,
          createdOn: moment().valueOf(),
          downloadUrl: urls[i].path,
          id: nodeId,
          inverseDate: -moment().valueOf(),
          name: urls[i].fileName,
          nameWithExtension: urls[i].fileName,
          type: urls[i].type && urls[i].type === "file" ? "doc" : "image",
        };
        yield call(StudentApi.uploadDocument, id, obj, firebase);

        yield fork(StudentAttendanceApi.lastUpdateTimestamp, moment(), firebase);
      }
      yield put({
        type: actions.UPLOAD_TEACHER_FILE_SUCCESS,
      });
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
      });
    }
  } catch (err) {
    console.log("failed to upload teacher document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* deleteTeacherDoc({ record, id, firebase, filePath }) {
  try {
    yield call(StudentApi.deleteDocument, record, id, firebase, filePath);
    yield put({
      type: actions.DELETE_TEACHER_FILE_SUCCESS,
    });
  } catch (err) {
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* setTempTeacherDetail({ teacherDetail }) {
  yield put({
    type: actions.SET_TEMP_TEACHER_DETAIL_SUCCESS,
    teacherDetail: teacherDetail,
  });
}
function leavesDetail(leaves) {
  let leaveDates = [];
  leaves.forEach((l) => {
    let dates = getAllDatesBetween(moment(l.startDate), moment(l.endDate));
    leaveDates = [...leaveDates, ...dates];
  });
  return leaveDates;
}

function getAllDatesBetween(startDate, endDate) {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push({
      date: now.valueOf(),
      type: "leave",
    });
    now.add(1, "days");
  }
  return dates;
}

function* fetchTeacherNewAttendance(date, teacherId, firebase) {
  try {
    var month1 = new Date(date).getMonth();
    var year = new Date(date).getFullYear();
    var firstDay = new Date(year, month1, 1);
    var lastDay = new Date(year, month1 + 1, 0);

    let data = yield call(TeacherApi.getTeacherNewAttendance, date, teacherId, firebase);
    // let leaves = yield call(
    //   TeacherApi.getSelectedTeacherLeave,
    //   firstDay,
    //   lastDay,
    //   teacherId,
    //   firebase
    // );
    var staffAttendance = [];

    staffAttendance = [];

    if (data) {
      var presentCount = 0;
      var absentCount = 0;
      var lateCheckIn = 0;
      var lateCheckOut = 0;

      for (let i = 0; i < data.length; i++) {
        staffAttendance.push(data[i]);
        let eleVal = data[i];
        if (eleVal.roomRecords) {
          presentCount++;
        } else {
          if (eleVal.absent) {
            absentCount++;
          }
        }
      }

      staffAttendance.sort(function (a, b) {
        var dateA = a.date,
          dateB = b.date;
        return dateB - dateA;
      });

      yield put({
        type: actions.GET_TEACHER_ATD_SUCCESS,
        teacherAttendance: staffAttendance,
        presentCount: presentCount,
        absentCount: absentCount,
        leavesCount: null,
        lateCheckIn: lateCheckIn,
        lateCheckOut: lateCheckOut,
        teacherLeaves: null,
      });
    }
  } catch (err) {
    console.log("failed to get teacher new attendance", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* fetchTeacherAttendance({ date, teacherId, firebase, oldAttendance }) {
  if (firebase.schoolConfig.roomAttendanceMode && !oldAttendance) {
    yield fork(fetchTeacherNewAttendance, date, teacherId, firebase);
  } else {
    try {
      var month1 = new Date(date).getMonth();
      var year = new Date(date).getFullYear();
      var firstDay = new Date(year, month1, 1);
      var lastDay = new Date(year, month1 + 1, 0);

      let data = yield call(TeacherApi.getTeacherAttendance, date, teacherId, firebase);
      if (data) {
        console.log("staffAttendance ----", data);
        let staffAttendance = data.staffAttendance;
        staffAttendance.sort(function (a, b) {
          var dateA = a.date,
            dateB = b.date;
          return dateB - dateA;
        });

        let leaves = yield call(
          TeacherApi.getSelectedTeacherLeave,
          firstDay,
          lastDay,
          teacherId,
          firebase
        );

        let leavesCount = 0;
        for (let i = 0; i < leaves.length; i++) {
          let loop = new Date(leaves[i].startDate);
          let endDate = new Date(leaves[i].endDate);

          while (loop <= endDate) {
            leavesCount++;
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
        }

        yield put({
          type: actions.GET_TEACHER_ATD_SUCCESS,
          teacherAttendance: staffAttendance,
          presentCount: data.presentCount,
          absentCount: data.absentCount,
          leavesCount: leavesCount,
          lateCheckIn: data.lateCheckIn,
          lateCheckOut: data.lateCheckOut,
          teacherLeaves: leaves,
        });
      }
    } catch (err) {
      console.log("failed to get teacher attendance", err);
      bugsnagClient.notify(err);
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
      });
    }
  }
}

function* updateTeacherStatus({ teacher, firebase }) {
  try {
    let obj = {
      deactivated: teacher.deactivated,
      deactivationDate: teacher.deactivationDate ? teacher.deactivationDate : null,
      platform: "web",
      updatedOn: moment().valueOf(),
      updatedBy: firebase.teacher.name,
    };
    yield call(TeacherApi.updateTeacherObject, obj, teacher.id, firebase);
    yield put({
      type: actions.UPDATE_TEACHER_STATUS_SUCCESS,
    });
  } catch (err) {
    console.log("failed to update teacher status", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* requestTeacherInvite({ teacher, firebase }) {
  try {
    let status = yield call(TeacherApi.sendInviteToTeacher, [teacher], firebase);
    let genericMsg = "Failed to send invite. Please contact school or Illumine.";
    if (status) {
      if (status.status === 400) {
        let error = status?.text ?? null;
        notification("error", error ? error : genericMsg);
      } else if (status.status === 500) {
        let error = status.body.response;
        notification("error", error ? error : genericMsg);
      } else if (status.status === 200) {
        notification("success", formatMsg("success.sendTeacherInvite"));
      }
    }

    yield put({
      type: actions.SEND_TEACHER_INVITE_SUCCESS,
    });
  } catch (err) {
    console.log("failed to send teacher invite", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* addTeacherChild({ values, teacher, firebase }) {
  try {
    let newStudentObj = {
      name: values.studentName,
      classroomName: values.classroom[0],
      classList: values.classroom,
      status: values.status,
      gender: values.gender,
    };
    let newStudentBranchPath = undefined;
    let genericMessage = "Failed to add child details. Please contact school or Illumine";
    let parentType = values.gender === "Male" ? "parent2" : "parent1";

    let response = yield call(
      TeacherApi.addTeacherChildApi,
      firebase,
      teacher.id,
      newStudentObj,
      newStudentBranchPath,
      parentType
    );
    if (response && response.status === 200) {
      firebase.refreshStudents();
      yield put({
        type: actions.SAVE_TEACHER_CHILD_SUCCESS,
      });
    } else {
      yield put({
        type: actions.TEACHER_REQUEST_FAILED,
        errorMessage:
          response.body && response.body.message ? response.body.message : genericMessage,
      });
    }
  } catch (err) {
    console.log("failed to add teacher's child", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function* addDocumentFolderTeacher({ folderName, filePath, firebase, id }) {
  try {
    let nodeId = yield call(StudentApi.createNewNoteId, firebase);
    yield call(StudentApi.addDocumentFolder, nodeId, id, folderName, filePath, firebase);
    yield put({
      type: actions.ADD_DOC_FOLDER_TEACHER_SUCCESS,
    });
  } catch (err) {
    console.log("failed to add document folder", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}
//here
function* showTeacherLogs({ teacherId, firebase }) {
  try {
    let data = yield call(TeacherApi.fetchTeacherLogs, teacherId, firebase);

    if (data) {
      data.sort(function (a, b) {
        var dateA = a.date,
          dateB = b.date;
        return dateB - dateA;
      });
      yield put({
        type: actions.FETCH_TEACHER_LOG_SUCCESS,
        changeTeacherLogs: data,
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    console.log("logs", error);
  }
}

function* renameDocTeacher({ docName, record, firebase, id, documents, currentFilePath }) {
  try {
    yield call(StudentApi.renameDoc, docName, record.id, firebase, id, currentFilePath);
    let allFiles = documents;
    let oldPath = record.name + "/";
    let newPath = docName + "/";
    if (record.folder) {
      for (let index in allFiles) {
        if (allFiles[index].id !== record.id) {
          if (allFiles[index].filePath) {
            let filePath = allFiles[index].filePath;
            if (currentFilePath) {
              let splittedPath = filePath.split(currentFilePath);
              if (splittedPath[1]) {
                filePath = splittedPath[1].toString();
              }
              else {
                filePath = ""
              }
            }
            if (filePath.startsWith(oldPath)) {
              allFiles[index].filePath =
                (currentFilePath ? currentFilePath : "") + filePath.replace(oldPath, newPath);
              yield call(StudentApi.renameDoc, allFiles[index].name, allFiles[index].id, firebase, id, allFiles[index].filePath);
            }
          }
        }
      }
    }
    yield put({
      type: actions.RENAME_TEACHER_DOC_SUCCESS,
    });
  } catch (err) {
    console.log("failed to rename teacher document", err);
    bugsnagClient.notify(err);
    yield put({
      type: actions.TEACHER_REQUEST_FAILED,
    });
  }
}

function getModifiedStaffList(firebase, staffIdsList, operationType, value) {
  let teacherList = FilterAction.getTeacherList();
  let teachersMap = new Map();
  teacherList.map((t) => {
    teachersMap.set(t.id, t);
  })
  let result = [];
  switch (operationType) {
    case "update classrooms":
      staffIdsList.map((tId) => {
        if (teachersMap.has(tId)) {
          let teacher = teachersMap.get(tId);
          teacher.updatedBy = firebase.teacher.name;
          teacher.updatedOn = moment().valueOf();
          teacher.classList = value;
          teacher.className = value[0];
          result.push(teacher);
        }
      })
      break;
    case "update status":
      let status = value.toLowerCase();
      staffIdsList.map((tId) => {
        if (teachersMap.has(tId)) {
          let teacher = teachersMap.get(tId);
          teacher.updatedBy = firebase.teacher.name;
          teacher.updatedOn = moment().valueOf();
          teacher.deactivated = status == "inactive" ? true : false;
          if (status == "inactive") {
            teacher.deactivationDate = moment().valueOf();
          }
          result.push(teacher);
        }
      })
      break;
    case "update groups":
      staffIdsList.map((tId) => {
        if (teachersMap.has(tId)) {
          let teacher = teachersMap.get(tId);
          teacher.updatedBy = firebase.teacher.name;
          teacher.updatedOn = moment().valueOf();
          teacher.groups = value.length > 0 ? value : null;
          result.push(teacher);
        }
      })
      break;
    case "delete staffs":
      staffIdsList.map((tId) => {
        if (teachersMap.has(tId)) {
          let teacher = teachersMap.get(tId);
          teacher.updatedBy = firebase.teacher.name;
          teacher.updatedOn = moment().valueOf();
          teacher.deleted = true;
          result.push(teacher);
        }
      })
      break;

  }
  return result;
}

function* bulkStaffOperation({ firebase, staffIdsList, operationType, value }) {
  try {
    let response;
    let modifiedStaffList = getModifiedStaffList(firebase, staffIdsList, operationType, value);
    if (operationType == "create folder") {
      let url = "woodlandApi/createFolderForStaff?centerId=" + firebase.sbDbName;
      response = yield callApi(firebase, "post", url, { list: staffIdsList, value: value, privateFolderRecord: {} });
    }
    else {
      let url = "woodlandApi/updateStaffList?centerId=" + firebase.sbDbName;
      response = yield callApi(firebase, "post", url, { staffList: modifiedStaffList });
    }
    if (response && response.status && response.status === 200) {
      if (operationType === "create folder") {
        notification(
          "success",
          formatMsg("notif.folderCreatedSuccessfully")
        );
      }
      else if (operationType != "delete staffs") {
        notification(
          "success",
          formatMsg("notif.satffsUpdatedSuccessfully")
        );
      }
      else {
        notification(
          "success",
          formatMsg("notif.satffsDeletedSuccessfully")
        );
      }
      yield put({
        type: actions.BULK_STAFF_OPERATION_SUCCESS,
      });
    } else {
      notification(
        "error",
        response && response.body && response.body.response
          ? response.body.response
          : formatMsg("updateBulkStudents")
      );
      yield put({
        type: actions.BULK_STAFF_OPERATION_SUCCESS,
      });
    }
  }
  catch (err) {
    console.log("failed to update students", err);
    notification(
      "error",
      response && response.body && response.body.response
        ? response.body.response
        : formatMsg("failedToUpdateStaff")
    );
    bugsnagClient.notify(err);
    yield put({
      type: actions.REQUEST_FAILED,
    });
  }
}
function* refreshPage({ }) {
  yield put({
    type: actions.REFRESH_PAGE_SUCCESS,
  });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LIST_TEACHERS, listTeachers),
    yield takeLatest(actions.GET_TEACHER_CLASSROOM_DATA, getAllClassromsForTeacher),
    yield takeEvery(actions.ADD_TEACHER, addTeacher),
    yield takeEvery(actions.UPDATE_TEACHER, updateTeacher),
    yield takeEvery(actions.DELETE_TEACHER, deleteSelectedTeacher),
    yield takeEvery(actions.GET_TEACHER_DETAIL, fetchSelectedTeacherDetail),
    yield takeEvery(actions.CHANGE_STAFF_ROLE, updateStaffRole),
    yield takeEvery(actions.CHANGE_PERMISSION, updateStaffPermission),
    yield takeEvery(actions.UPLOAD_TEACHER_PROFILE, updateTeacherDisplayPic),
    yield takeEvery(actions.GET_TEACHER_FILES, fetchTeacherFiles),
    yield takeEvery(actions.UPLOAD_TEACHER_FILE, uploadTeacherDoc),
    yield takeEvery(actions.DELETE_TEACHER_FILE, deleteTeacherDoc),
    yield takeEvery(actions.SET_TEMP_TEACHER_DETAIL, setTempTeacherDetail),
    yield takeEvery(actions.GET_TEACHER_ATD, fetchTeacherAttendance),
    yield takeEvery(actions.UPDATE_TEACHER_STATUS, updateTeacherStatus),
    yield takeEvery(actions.SEND_TEACHER_INVITE, requestTeacherInvite),
    yield takeEvery(actions.SAVE_TEACHER_CHILD, addTeacherChild),
    yield takeEvery(actions.ADD_DOC_FOLDER_TEACHER, addDocumentFolderTeacher),
    yield takeEvery(actions.RENAME_TEACHER_DOC, renameDocTeacher),
    yield takeLatest(actions.FETCH_TEACHER_LOG, showTeacherLogs),
    yield takeEvery(actions.GET_LEAVE_STAT, fetchTeacherLeaveStats),
    yield takeEvery(actions.GET_LEAVE_HISTORY, fetchTeacherLeaveHistory),
    yield takeEvery(actions.FETCH_ALL_LEAVE_SETTING_DATE_RANGE, getAllLeavesSettingDateRange),
    yield takeEvery(actions.BULK_STAFF_OPERATION, bulkStaffOperation),
    yield takeLatest(actions.REFRESH_PAGE, refreshPage),

  ]);
}
